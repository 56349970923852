import { useState, useEffect } from "react";
import { createModel } from "hox";
import useStorageState from "@/hooks/useStorageState";
import { globalPrompt } from "@/components/message";
import { dictService, IVRM2DictDetail, IVRM2DictSearchs } from "./dictService";
import { forFind } from "@/utils/utils";
import { DictCodeEnmu, sourceAppOrBranch } from "../../../types/VrmSaDictEnums"
import useGlobalModel from "@/model/globalModel";
import useNavModel from "@/model/navModel";

type dictObj = {
    [key: string]: any
}
type ISelectOptions = {
    label: string,
    value: string,
    relationvalue?: string,//关联的Value值
    [key:string]:any

}
export type ITreeSelectData = {
    title: string,
    value: string,
    children: ITreeSelectData[]
}
function listChangeTree(list: IVRM2DictDetail[]) {
    let menuObj: dictObj = {}
    list.forEach(item => {
        item.children = []
        menuObj[item.code] = item
    })
    return list.filter(item => {
        if (item.pcode !== null && item.pcode !== "") {
            menuObj[item.pcode]?.children.push(item)
            return false
        }
        return true
    })
}

export const useDictModel = createModel(function () {
    const { shopAndOrg: { shopCode } } = useGlobalModel()
    const [treeSource, setTreeSource, clearTreeSource] = useStorageState<IVRM2DictDetail[]>("dictTree", []);  //字典数据源
    const [selectedNodes, setSelectedNodes] = useState<IVRM2DictDetail[]>([]);   //树选中的行信息
    const [mode, setMode] = useState<"add" | "edit" | "nextInsert" | "">("");
    const [formVal, setFormVal] = useState<IVRM2DictDetail>(selectedNodes[0]);  //表单缓存

    //实时缓存
    const onChangeFormVal = (changedValues: { [key: string]: any }) => {
        console.log(changedValues)
        setFormVal({ ...formVal, ...changedValues });
    }

    const initDictFormFields = {
        pcode: "",
        code: "",
        name: "",
        note: "",
        ver: 0,
        sourceAuthKey: "",
        children: [],
        enable: 0,
        branch: "",
        value: "",
        key: ""
    }
    useEffect(() => {
        if (mode === "add") {
            console.log(selectedNodes[0])
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].pcode, shopCode: [] })
        } else if (mode === "nextInsert") {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].code, shopCode: [] })
        }
    }, [mode])

    const loadData = async (shopCodes: string) => {
        let data: IVRM2DictSearchs = {
            "sourceApp": "vrm",
            // shopCode: shopCodes,
            // page: {
            //     pageSize: 10000000,
            //     pageNum: 1,
            // },
            pageSize: 10000000,
            pageNum: 1,
        }
        let { retData } = await dictService.pages(data);
        let { records } = retData;
        setTreeSource(listChangeTree(records));
    }

    //表单提交
    const onSubmit = async () => {
        // return console.log(formVal)
        let { code, pcode, note, name, enable, nameEn, status, value } = formVal;
        let data = {
            ...sourceAppOrBranch,
            branch: "main",
            code, pcode, note, name, nameEn, value,
            enable: enable ? 1 : 0
        }
        if (!data.code) {
            globalPrompt("message", { text: "编码为必填项", type: "warning" });
            return;
        }
        if (mode === "add" || mode === "nextInsert") {
            let { retData } = await dictService.insert(data);
            retData && globalPrompt("message", { type: "success", text: "字典增加成功" });
            loadData(shopCode);
            setSelectedNodes([]);
        } else if (mode === "edit") {
            let { retData } = await dictService.update(data);
            retData && globalPrompt("message", { type: "success", text: "字典编辑成功" });
            loadData(shopCode);
            setSelectedNodes([]);
        }
    }
    const onLocalSubmit = async () => {
        // return console.log(formVal)
        let { code, pcode, note, name, enable, nameEn, status, value, shopCode, key } = formVal;
        let data = {
            ...sourceAppOrBranch,
            branch: "main",
            code, pcode, note, name, nameEn, value,
            shopCode: shopCode.toString(),
            enable: enable ? 1 : 0
        }
        if (!data.code) {
            globalPrompt("message", { text: "编码为必填项", type: "warning" });
            return;
        }
        if (mode === "add" || mode === "nextInsert") {
            // let { retData } = await dictService.insertLocal(data);
            let { retData } = await dictService.shopInsert(data);
            retData && globalPrompt("message", { type: "success", text: "字典增加成功" });
            loadData(shopCode);
            setSelectedNodes([]);
        } else if (mode === "edit") {
            // let { retData } = await dictService.updateLocal(data);
            let { retData } = await dictService.shopUpdate({ ...data, key });
            retData && globalPrompt("message", { type: "success", text: "字典编辑成功" });
            loadData(shopCode);
            setSelectedNodes([]);
        }
    }
    //删除一条数据
    const removeOneDict = async (code: string) => {
        let data = {
            ...sourceAppOrBranch,
            code
        }
        let { retData } = await dictService.remove(data);
        retData && globalPrompt("message", { type: "success", text: "字典删除成功" });

        loadData(shopCode);
        setSelectedNodes([]);
    }

    //根据code返回 select框的options 格式；
    const getSelectedDictOne = (code: string): ISelectOptions[] => {
        let findTree: IVRM2DictDetail | undefined = forFind(treeSource, code, "code");
        return findTree ? findTree.children.filter(filItem => filItem.enable
            // filItem.shopCode?.indexOf(shopCode) !== -1 && filItem
        ).map(item => {
            let { value } = item
            return value ? { label: item.name, value: item.code, relationvalue: value } : { label: item.name, value: item.code }
        }) : []
    }

    //根据code 返回对应的node节点名称
    const getTreeNodeName = (code: string): string => {
        let obj: dictObj = {};
        let findTree: IVRM2DictDetail | undefined = forFind(treeSource, code, "code");
        return findTree ? findTree["name"] : "";
    }

    //根据code 返回对应树节点
    const getTreeNodeOne = (code: string): IVRM2DictDetail[] => {
        let findTree: IVRM2DictDetail | undefined = forFind(treeSource, code, "code");
        return findTree ? [findTree] : [];
    }

    const mapTree = (org: IVRM2DictDetail): ITreeSelectData => {
        const haveChildren = Array.isArray(org.children) && org.children.length > 0;
        return {
            title: org.name,
            value: org.code,
            //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
            children: haveChildren ? org.children.map(i => mapTree(i)) : []
        };
    }
    //根据code 返回treeSelect 数据格式
    const getTreeSelectOne = (code: DictCodeEnmu): ITreeSelectData[] => {
        let findTree: IVRM2DictDetail | undefined = forFind(treeSource, code, "code");
        return findTree ? mapTree(findTree).children : [];
    }
    return {
        /***field***/
        treeSource,
        selectedNodes,
        mode,
        formVal,
        /***method***/
        setTreeSource,
        setSelectedNodes,
        setMode,
        setFormVal,
        onChangeFormVal,
        loadData,
        onSubmit,
        getTreeNodeName,
        getSelectedDictOne,
        getTreeNodeOne,
        getTreeSelectOne,
        onLocalSubmit
    }
});