import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useTechnicianModel } from "../technicianModel";
import { globalPrompt } from "@/components/message";
import { DetailType } from "../../../../types/ScmEnums"
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const DataBox = () => {
    const { selectedRowKeys,
        detailDrawer: visible,
        setDetailDrawer,
        setDetailType,
    } = useTechnicianModel();

    const addDrawer = () => {
        setDetailDrawer(!visible)
        setDetailType(DetailType.add)
    }
    const onEdit1 = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setDetailDrawer(!visible)
        setDetailType(DetailType.edit)
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setDetailDrawer(!visible)
        setDetailType(DetailType.look)
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_T_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={addDrawer}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_T_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit1}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_T_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
    </>

    return (
        <YhBox title="维修技师列表" action={action}>
            <DataTable />
        </YhBox>
    )
}
