import React, { useEffect } from "react";
import { YhBox } from "@/components/YhBox";
import FontIcon from "@/components/YhIcon";
import useNavModel from "@/model/navModel";
import { useHomeModel } from "./homeModel"
import { Button } from "antd";
export function Navigation() {

    const { addNav } = useNavModel();
    const { optionsList, setVisible, setPage } = useHomeModel()
    useEffect(() => {
        setPage()
    }, [])
    const goMenu = (menu: string) => {
        addNav(menu)
    }
    const onDrawerNavigation = () => {
        setVisible(true)
    }
    const rightChildren = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onDrawerNavigation}
        ><FontIcon type="icon-shezhi" style={{ color: "white", fontSize: "20px" }} /></Button>
    </>
    const title = <span>快捷导航</span>
    return (
        <YhBox
            title={title}
            rightChildren={rightChildren}
            style={{ marginBottom: 10, verticalAlign: 'top' }}
        // contentClass="pt-40 overflow-y-auto h-100"
        >
            <div className="navigation">
                {optionsList.length !== 0 ? optionsList.map(item => (
                    <div className="navigation-item" onClick={() => goMenu(item.path)}>
                        <FontIcon type={item.icon} style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                        <span className="navigation-menu">{item.name}</span>
                    </div>
                )) : (
                        <>
                            <div className="navigation-item" onClick={() => goMenu("16-1")}>
                                <FontIcon type="icon-cheliang2" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">公交业务</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("5-2")}>
                                <FontIcon type="icon-xiaoshoudingdan1" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">销售单</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("11-2")}>
                                <FontIcon type="icon-guanli" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">采购单</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("4")}>
                                <FontIcon type="icon-tubiaozhizuomoban-117" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">维修单</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("13-5-1")}>
                                <FontIcon type="icon-kehu" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">客户档案</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("13-5-2")}>
                                <FontIcon type="icon-cheliang2" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">车辆档案</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("13-1")}>
                                <FontIcon type="icon-gongyingshang1" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">供应商档案</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("9-1")}>
                                <FontIcon type="icon-tongji" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">库存统计</span>
                            </div>
                            <div className="navigation-item" onClick={() => goMenu("9-2")}>
                                <FontIcon type="icon-kucunpandian" style={{ color: "white", lineHeight: "100px" }} className="navigation-icon" />
                                <span className="navigation-menu">库存盘点</span>
                            </div>

                        </>
                    )}
            </div>
        </YhBox>
    )
}