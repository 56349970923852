import React, { useRef } from "react";
import { message } from "antd";
import { useTechnicianModel } from "../technicianModel";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils"
import { DetailType } from "../../../../types/ScmEnums"
import { debounce } from "lodash";

export function DetailDrawer() {
    const { detailDrawer: visible, detailType, addFormVal, selectedRow, setDetailDrawer, setSelectedRowKeys, insertDetail, editDetail } = useTechnicianModel();
    const formRef = useRef<IYhFormRef>();

    const onCancel = () => {
        setDetailDrawer(false);
    }

    //表单提交
    const onSubmit = async () => {
        if (detailType === DetailType.look) {
            return setDetailDrawer(false)
        }
        formRef.current?.validateFields().then(res => {
            if (detailType === DetailType.add) {
                insertDetail(res).then(res => {
                    if (res) {
                        setSelectedRowKeys([res as string])
                        setDetailDrawer(false)
                    }
                })
            } else if (detailType === DetailType.edit) {
                editDetail({ ...res, id: selectedRow.id }).then(res => res && setDetailDrawer(false))
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }
    const typeForm: any = {
        add: addFormVal,
        edit: selectedRow,
        look: selectedRow
    }
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        disabled: detailType === DetailType.look,
        // formValues: detailType == DetailType.add ? addFormVal : selectedRow[0],
        formValues: { ...typeForm[detailType] },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "姓名",
                fieldName: "name",
                span: 24,
                rules: [{ required: true, message: "请输入姓名" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "手机号",
                fieldName: "phone",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "职务",
                fieldName: "title",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "分配点数",
                fieldName: "assignPoints",
                span: 24,
            }
        ]
    };
    const typeTitle: any = {
        add: "新增",
        edit: "编辑",
        look: "查看",
    }
    return (
        <YHDrawer
            {...{
                title: `维修技师${typeTitle[detailType]}`,
                visible,
                onCancel,
                okText: "保存",
                onOk: debounce(() => { onSubmit() }, 900)

            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
