import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useDeliveryModel } from "./deliveryModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import useGlobalModel from "@/model/globalModel";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";


export const DataBox = () => {
    const { selectedRowKeys, setWsConfirmModal } = useDeliveryModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const [visible, setVisible] = useState(false);
    const onWsConfirm = () => {
        if (selectedRowKeys.length !== 1) {
            return message.error("请选择一条数据")
        }
        setWsConfirmModal(true)
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => onWsConfirm()}
        >出库</Button>
    </>;

    return (
        <>
            <YhBox title={action} >
                <DataTable />
            </YhBox>
        </>
    )
}