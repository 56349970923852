import { httpRequest } from "@/utils/HttpRequest";
import { IPage, ISearchPage } from "@/types/ScmTypes";

//物料信息
export interface ISCmphotoMd5 {
    photoMd5: string
}
export interface IWarehouseMaterialDetal {
    id?: string, // "",
    materialCode?: string, // "",
    materialName?: string, // "",
    purchasePrice?: number, // 0,
    repairPrice?: number, // 0,
    shelfCode?: string, // "",
    shelfId?: string, // "",
    shopId?: string, // "",
    shopCode?:string,
    shopName?: string, // "",
    warehouseCode?: string, // "",
    warehouseId?: string, // "",
    warehouseName?: string, // ""
}
//详情
export interface IMaterialBase {
    brandCode: string,               // "",
    brandName: string,               // "",
    code: string,               // "",
    createTime: string,               // "",
    createUser: string,               // "",
    deleteFlag: number,               // 0,
    engineType: string,               // "",
    id: string,               // "",
    inventoryNum: number,               // 0,
    limitLower: number,               // 0,
    limitUpper: number,               // 0,
    madeFrom: string,               // "",
    model: string,               // "",
    name: string,               // "",
    nameEn: string,               // "",
    note: string,               // "",
    orderNum: number,               // 0,
    purchasePrice: number,               // 0,'
    latestPurPrice: number,
    replaceCode: string,               // "",
    retailPrice: number,               // 0,
    shelfCode: string,               // "",
    shelfId: string,               // "",
    status: string | number | boolean,               // 0,
    typeCode: string,               // "",
    typeName: string,               // "",
    unitCode: string,               // "",
    unitName: string,               // "",
    updateTime: string,               // "",
    updateUser: string,               // "",
    warehouseCode: string,               // "",
    warehouseId: string,               // "",
    warehouseName: string,               // "",
    wholesalePrice: number,               // 0     
    materialCode?: string,
    materialName?: string,
    materialNameEn?: string,
    price?: number,
    enable?: number,
    minOrderNum?: number,
    overstock?:number,
    materialDetal?:IWarehouseMaterialDetal[],
    isDefault?:string
}

export interface IMaterialDetail extends IMaterialBase {
    // photo: ISCmphotoMd5[],
}

//新增
export interface IFormGoodsFields extends IMaterialBase {
    photo: string
}

//搜索条件
export interface IMaterialSearchFields {
    code?: string,
    name?: string,
    replaceCode?: string,
    typeCode?: string,
    engineType?: string,
    orderStatus?: string,
    shopId?: string,
}

export interface IMaterialSearch
    extends IMaterialSearchFields, ISearchPage {

}

//采购物料查询searchVo
export interface IPurchaseMaterialSearch extends ISearchPage {
    codeOrName: string
}
//采购物料查询details
export interface IurchaseMaterialDetails {
    id: string,
    materialId: string,
    code: string,
    name: string,
    replaceCode: string,
    unitCode: string,
    brandCode: string,
    typeCode: string,
    retailPrice: number,
    wholesalePrice: number,
    distributorPrice: number,
    repairPrice: number,
    warehouseId: string,
    warehouseName: string,
    shelfId: string,
    shelfCode: string,
    inventoryNum: number,
}
export interface paramsDisable {
    id: string,
    enable: number
}
class MaterialService {
    async page(searchVo: IMaterialSearch) {
        const apiUrl = "/bcm/base/material/findPage";
        return await httpRequest.post<IPage<IMaterialDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/base/material/findById/${id}`;
        return await httpRequest.get<IMaterialDetail>(apiUrl);
    }
    async insert(insertVo: IFormGoodsFields) {
        const apiUrl = "/bcm/base/material/insert";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }
    async update(updateVo: IFormGoodsFields) {
        const apiUrl = "/bcm/base/material/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //启用禁用
    async disable(params: object) {
        const apiUrl = `/bcm/base/material/change`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导入
    async import(file: { file: File }) {
        const appPageUrl = "/bcm/base/material/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //导出
    async export(searchVo: IMaterialSearch) {
        const appPageUrl = "/bcm/base/material/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //分页查询物料列表-用于采购
    async findPageByPurchase(searchVo: IurchaseMaterialDetails) {
        const appPageUrl = `/bcm/base/material/findPageByPurchase`;
        return await httpRequest.post<IPage<IurchaseMaterialDetails>>(appPageUrl);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/bcm/base/material/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    //物料导入图片
    async importImg(file: { file: File }) {
        const appPageUrl = "/bcm/base/material/importImg";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
}

export const materialService = new MaterialService();