import React from "react";
import { DataTable } from "./DataTable";
import { Button, message } from "antd";
import { useShopModel } from "../shopModel";
import { YhBox } from "@/components/YhBox";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { setIShopDrawer, selectedRowKeys } = useShopModel();

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ORG_SHOP_AD}>

            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => setIShopDrawer("add")}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ORG_SHOP_ED}>

            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => {
                    if (!selectedRowKeys.length) {
                        message.warning("请选择你要操作的数据");
                        return;
                    }
                    setIShopDrawer("edit");
                }}
            >编辑</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="门店管理列表" action={action}>
            <DataTable />
        </YhBox>
    )
}