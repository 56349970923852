import React, { useState, FC, useEffect } from "react";
import { Button, message } from "antd";
import { YHModal } from "@/components/YHModal";
import Table, { ColumnsType } from "antd/lib/table";
import { useGlobalModel } from "@/model/globalModel";
import { usePsiModel } from "../psiModel";
import { YhBox } from "@/components/YhBox";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { formatNum, momentFormat } from "@/utils/utils";
import { IremainDetail, psiService } from "../psiService";
import { convertRes2Blob } from "@/utils/exportFile";

//补偿数
export const CompensateNumModal = () => {
	const { shopAndOrg, setLoadingInfo } = useGlobalModel();
	const {
		goodsRecordModal: { compensateVisible: visible, row }, onCancel, setDatailsVisible
	} = usePsiModel();
	const [dataSource, setDataSource] = useState<IremainDetail[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (visible && row) {
			loadData()
		}
	}, [visible])

	const loadData = async () => {
		setLoading(true);
		try {
			const searchParams = {
				shopId: shopAndOrg.shopId,
				materialCode: row?.materialCode,
				types: ["compensate"],
			}
			let { retData } = await psiService.purchaseSaleStorageDeatil(searchParams);
			setDataSource(retData ?? []);
			setLoading(false)
		} catch {
			setLoading(false);
			setDataSource([])
		}
	}

	//导出
	const exportDetail = async () => {
		if (!dataSource.length) {
			message.warning("无数据");
			return;
		}
		setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
		try {
			const searchParams = {
				shopId: shopAndOrg.shopId,
				materialCode: row?.materialCode,
				types: ["compensate"],
			}
			let result = await psiService.purchaseSaleStorageDeatilExport(searchParams);
			result && setLoadingInfo({ loadingVisible: false, txt: "" });
			convertRes2Blob(result);
		} catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
	}


	const columns: ColumnsType<any> = [
		{
			title: '序号',
			width: 66,
			align: "center",
			render: (t: any, r: any, i: number) => i + 1
		},
		// {
		//   title: '单据类型',
		//   width: 180,
		//   align: "center",
		//   dataIndex: 'createTime',
		// }, {
		//   title: '单位名称',
		//   width: 180,
		//   align: "center",
		//   dataIndex: 'createTime',
		// },
		{
			title: '业务日期',
			width: 180,
			align: "center",
			dataIndex: 'createTime',
		}, {
			title: '单据编号',
			width: 120,
			align: "center",
			dataIndex: 'code',
			render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => setDatailsVisible({
				visible: true,
				code: text,
				type: record.type
			})}> {text}</span >,
		}, {
			title: '仓库',
			width: 120,
			align: "center",
			dataIndex: 'warehouseName',
		},
		//  {
		//   title: '货位',
		//   width: 120,
		//   align: "center",
		//   dataIndex: 'num',
		// }, 
		{
			title: '单价',
			width: 160,
			align: "center",
			dataIndex: 'price',
			render: text => `￥${formatNum(text)}`
		}, {
			title: '数量',
			width: 140,
			align: "center",
			dataIndex: 'num',
		}, {
			title: '金额',
			width: 120,
			align: "center",
			render: (text, record) => `￥${formatNum(record.num * record.price)}`
		},
		// {
		//     title: '业务员',
		//     width: 180,
		//     align: "center",
		//     dataIndex: 'createTime',
		//   }, {
		//     title: '备注',
		//     width: 180,
		//     align: "center",
		//     dataIndex: 'createTime',
		//   },
	];

	const action = <Button
		className="mr-5 font14"
		type="link"
		size="small"
		shape="round"
		onClick={() => exportDetail()}
	>导出</Button>

	const desc: IDescription[] = [
		{
			label: "补偿数量",
			value: dataSource.reduce((c: any, n: IremainDetail) => { c = c + n.num; return c }, 0),
			color: DescriptionColorEnum.red
		},
	]

	return <YHModal
		visible={visible}
		onCancel={onCancel}
		title={<p><span>补偿数:{row?.compensateNum}</span><span className="ml-20">商品编号:{row?.materialCode}</span><span className="ml-20">商品名称:{row?.materialName}</span></p>}
		width={1300}
		bodyHeight={550}
	>
		<YhBox title={action} descriptions={desc}>
			<Table
				loading={loading}
				rowKey={record => record.id}
				columns={columns}
				dataSource={dataSource}
				pagination={false}
				scroll={{ x: 'max-content' }}
			/>
		</YhBox>
	</YHModal>
}
