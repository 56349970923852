import React from "react";
import { Button, message, Input, Select } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useSummaryCustomerDetailsModel } from "./summaryCustomerDetailsModels";

import { Description } from "@/components/YhBox/Description";

export const TopButs = () => {
    const { } = useSummaryCustomerDetailsModel();
    const { selectedNavId,  replaceNav } = useNavModel();
    const save = () => {
        goBack()
    }
    const goBack = () => {
        replaceNav(selectedNavId, "3")
    }
    const Options = [
        { label: "整单折扣", value: "1" },
        { label: "维修项目折扣", value: "2" },
        { label: "维修用料折扣", value: "3" }
    ]
    const onDiscount = () => {
        let discountNum: string = "1";
        let discountVal: string = "";

        globalPrompt("modal", {
            width: 380,
            title: "折扣",
            type: "info",
            // text: "",
            text: <>
                <span>请选择折扣类型</span>
                <Select
                    showSearch={true}
                    defaultValue="1"
                    options={Options}
                    onChange={(value, option) => discountNum = value}
                />
                <span>请输入折扣系数</span>
                <Input
                    onChange={(e) => { discountVal = e.target.value }}
                    suffix="%"
                />
            </>,
            okText: "确定",
            onOk: () => {
                console.log(discountNum, discountVal,)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onWhConfirm = () => {
        // setWsConfirmModal(true)
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onDiscount}
        >折扣</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onWhConfirm}
        >确认入库</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={save}
        >保存</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { message.success("刷新成功") }}
        >刷新</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>;


    const orderStatu: IDescription[] = [
        {
            label: "结算单",
            value: "YY202012301259",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: "已开单",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={action}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}