import  { useState } from "react";
import { createModel } from "hox";
import useStorageState from "@/hooks/useStorageState";
import { orgService, IVRMOrgDetail, IVRMOrgSearch } from "./orgService";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { message } from "antd";
import { ITreeSelectData } from "@/views/base/dict/dictModel";
import { defaultMdCmParam } from "@/utils/constants";

type dictObj = {
    [key: string]: any
}

function listChangeTree(list: IVRMOrgDetail[]) {
    let menuObj: dictObj = {}
    list.forEach(item => {
        item.children = []
        menuObj[item.code] = item
    })
    return list.filter(item => {
        if (item.pcode !== null && item.pcode !== "") {
            menuObj[item.pcode]?.children.push(item)
            return false
        }
        return true
    })
}

export const useOrgModel = createModel(function () {
    const { branch } = defaultMdCmParam;
    const [treeSource, setTreeSource, clearTreeSource] = useStorageState<IVRMOrgDetail[]>("orgTree", []);  //机构树数据源
    const [selectedNodes, setSelectedNodes] = useState<IVRMOrgDetail[]>([]);   //树选中的行信息

    //字典列表请求
    const loadData = async () => {
        let data: IVRMOrgSearch = {
            sourceApp:"main",
            branch,
            page: {
                pageSize: 10000000,
                pageNum: 1,
            },
        }
        let { retData } = await orgService.page(data);
        let { records } = retData;
        setTreeSource(listChangeTree(records));
        return true;
    }

    const syncData = async () => {
        let ret = await orgService.sync({ sourceApp: "main" });
        ret ? message.success("同步成功!重新加载数据...") : message.error("同步失败");
        ret && await loadData()
    }

    const mapTree = (org: IVRMOrgDetail): ITreeSelectData => {
        const haveChildren = Array.isArray(org?.children) && org?.children.length > 0;
        return {
            title: org?.name ?? "",
            value: org?.code ?? "",
            //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
            children: haveChildren ? org.children.map(i => mapTree(i)) : []
        };
    }

    //根据code 返回treeSelect 数据格式
    const getOrgTreeSelect = (code: DictCodeEnmu.ORG): ITreeSelectData[] => {
        return [mapTree(treeSource[0])]
    }

    return {
        /***field***/
        treeSource,
        selectedNodes,
        /***method***/
        setTreeSource,
        setSelectedNodes,
        loadData,
        syncData,
        getOrgTreeSelect
    }
});