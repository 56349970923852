import { useState, Key, useEffect } from "react";
import { createModel } from "hox";
import {  IStockBorrowField, IDetailList } from "../../stockOutAndInListType"
import { stockOutAndInListService } from "../../stockOutAndInListService"
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IStockStatisticSearch, stockStatisticService } from "@/views/stock/statistic/stockStatisticService";
import { ISearchPage } from "@/types/ScmTypes";
import { transPgToPagination } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import { DictCodeEnmu, sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { IVehicleDetail } from "@/views/base/customerFiles/vehicle/vehicleType";
import { IPurchaseStock } from '@/views/sale/order/saleOrderTypes';
import { saleOrderService } from '@/views/sale/order/saleOrderService';

//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IPurchaseStock {
    num: number,
}
export const useStockBorrowDetailModel = createModel(function () {
    const { user, shopAndOrg } = useGlobalModel()
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)  //新增商品弹框
    const [vehicleEditAll, setVehicleEditAll] = useState<IVehicleDetail[]>([])//实时切换车辆 编辑
    const [vehicleAddAll, setVehicleAddAll] = useState<IVehicleDetail[]>([])//实时切换车辆 新增
    const initForm = {}
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initSearchParam: IStockStatisticSearch = {};
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IStockBorrowField>(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState<IDetailList[]>([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState<Key[]>([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState<IDetailList[]>([]);
    //详情数据
    const [detailEditForm, setDetailEditForm] = useState<IStockBorrowField>({})
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState<IDetailList[]>([]);
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState<Key[]>([]);
    const [detailDataRow, setDetailDataRow] = useState<IDetailList[]>([]);
    // 配置列表配置
    const [workDataSource, setWorkDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkData, setSearchWorkData] = useState<IStockStatisticSearch>(initSearchParam)
    const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataSource, setAddDataSource] = useState<IMaterialDetailModel[]>([]);
    //新增表单缓存
    const updateAddFormVal = (val: IStockBorrowField) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val: IStockBorrowField) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await stockOutAndInListService.one(id);
        const { details, ...from } = retData
        setDetailDataSource(details as IDetailList[] ?? [])
        setDetailEditForm(from)
    }
    //关闭弹框
    const resetWorkloadData = async () => {
        setSearchWorkData({});
        workloadData(initPgCfg, {});
    }
    //加载配件数据
    const workloadData = async (page?: ISearchPage, searchValue: IStockStatisticSearch = searchWorkData) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            status:`${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            pageSize: page?.pageSize ?? workPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await saleOrderService.stock(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            return {
                ...item,
                num: 0,
                amount: Number(item.lateSalePrice) * 1,
            }
        })
        setWorkPgCfg(transPgToPagination(pg));
        setWorkDataSource(newRecords);
    }
    //筛选模块详情 详情和新增的列表已有数据的方法
    const modelScreenMethod = async (modelList: IMaterialDetailModel[], detailList: IDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IDetailList[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.materialCode === Item.materialCode);
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    borrowNum: Item.num,
                    inventoryNums: Item.availableStock,
                    id: "",
                    stockId: Item.id,
                    detailsId: Item.id,
                    purchaseId: Item.id,
                    purchaseCode:Item.purchaseCode
                });
            } else {
                repetition.push(Item.materialName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到详情列表
    const transformsToPoDetailList = async (mode: string) => {
        //添加列表
        if (mode == "add") {
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList)
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode == "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailDataSource)
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除详情列表数据
    const detailDetailList = async (mode: string) => {
        const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.materialCode))
        setDetailAddList(filterDetailList)
        globalPrompt("message", { text: `删除成功`, type: "success" });
        setDetaiAddDataRowKeys([]);
        setDetaiAddDataRow([]);
    }
    //新增维修借件
    const insertDetail = async (params: IStockBorrowField) => {
        let newParams = {
            ...params,
            details: detailAddList,
            createUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await stockOutAndInListService.insert(newParams);
        return retData;
    }
    //编辑维修借件
    const editDetail = async (params: IStockBorrowField) => {
        let newParams = {
            ...params,
            details: detailDataSource,
            updateUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await stockOutAndInListService.update(newParams);
        return retData;
    }
    //退料入库
    const storage = async (params: IStockBorrowField) => {
        let { retData } = await stockOutAndInListService.storage(params);
        return retData;

    }
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddList([]);
        setDetailAddForm({})
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailDataSource([]);
    }
    return {
        vehicleEditAll, setVehicleEditAll,
        vehicleAddAll, setVehicleAddAll,
        // data 
        addModalVisible,
        initPgCfg,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        detaiAddDataRowKeys,
        initForm,
        detailDetailList,
        // model
        setAddModalVisible,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetWorkloadData,
        workloadData,
        transformsToPoDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        setDetaiAddDataRowKeys,
        modelScreenMethod,
        storage
    }
})