import React from "react";
import { useStockEntryModel } from "../stockEntryModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "单位名称",
        fieldName: "userName"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "配件编码",
        fieldName: "materialCode"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "单据编号",
        fieldName: "tradeCode"
    },
    {
        type: SearchTypeEnum.RangePicker,
        fieldName: "",
        placeholder: ["入库开始", "入库结束"],
        rangePickerFieldName: ["startTime", "endTime"],
        format: "YYYY-MM-DD",
        span: 12,
        xxl: 6,
    },
]


export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useStockEntryModel();

    return (
        <YhPageHeader
            title="入库单"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}