import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IAppointmentDetail, IAppointmentSearch } from "./appointmentType";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table/interface";
import { transPgToPagination, formatNum } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { appointmentService } from "./appointmentService";
import { message } from "antd";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    num: number
    totalAmount: number
    receivableTotalAmount: number
}
export const useAppointmentModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const [print, setPrint] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const initDesc = {
        num: 0,
        totalAmount: 0,
        receivableTotalAmount: 0
    }
    const initSearchParam: IAppointmentSearch = {};
    const initDataSource: IAppointmentDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //查询条件
    const [searchParam, setSearchParam] = useState<IAppointmentSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IAppointmentDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IAppointmentDetail>({});
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<IAppointmentDetail[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item, index) => {
            let { totalAmount, receivableTotalAmount } = item
            newRowsDescTotal.num += +1 
            newRowsDescTotal.totalAmount += +formatNum(totalAmount)
            newRowsDescTotal.receivableTotalAmount += +formatNum(receivableTotalAmount)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IAppointmentDetail)
    }, [selectedRowKeys, dataSource])

    const updateSearchParam = (updateParam: IAppointmentSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }
    const loadData = async (page?: ISearchPage, searchValue: IAppointmentSearch = searchParam) => {
        setLoading(true);
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await appointmentService.page(searchParams);
        const { records, statistics, ...pg } = retData;
        setDescTotal(statistics)
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records);
        setLoading(false);
    }
    const invalidateData = async (cancelReason: string) => {
        let { id, appointmentCode } = selectedRow
        let { retData } = await appointmentService.invalidate({ id, appointmentCode, cancelReason })
        if (retData) {
            message.success("作废成功");
            loadData();
        }
    }
    const exportAppointmentRo = async (searchValue: IAppointmentSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await appointmentService.export(searchParams);
        convertRes2Blob(result)
    }
    const [scaniaPrint, setScaniaPrint] = useState<boolean>(false);
    const [settleScaniaPrint, setSettleScaniaPrint] = useState<boolean>(false);
    const detailsExport = async (searchValue: IAppointmentSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await appointmentService.detailsExport(searchParams);
        convertRes2Blob(result)
    }
    return {
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        initPgCfg,
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setSelectedRow,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        invalidateData,
        print, setPrint,
        descTotal, setDescTotal,
        exportAppointmentRo,
        loading, setLoading,
        scaniaPrint, setScaniaPrint,
        settleScaniaPrint, setSettleScaniaPrint,
        detailsExport,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows
    }
})