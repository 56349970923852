import React, { useEffect } from "react";
import { useTransferModel } from "./transferModel";
import { YhBox } from "@/components/YhBox";
import { TransferDataTable } from "./DataTable";

export const TransferDataBox = () => {
    const {
        // dataSource,
        // selectedRowKeys,
        loadData,
        // setSelectedData,
    } = useTransferModel();

    useEffect(() => {
        loadData({ pageNum: 1 })
    }, [])

    // useEffect(() => {
    //     let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
    //     const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
    //     setSelectedData(selectedData)
    // }, [selectedRowKeys]);

    return (
        <>
            <YhBox title={"转账记录"}>
                <TransferDataTable />
            </YhBox>
        </>
    )
}
