import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, message } from "antd";
import { StockDataTable } from "./DataTable"
import useNavModel from "@/model/navModel";
import { useStockModel } from "./stockModel";
export const StockDataBox = () => {
    const { addNav } = useNavModel();
    const { stockExport } = useStockModel()
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => addNav('16-2-1', { id: "", mode: "add" })}
        >
            预售
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => addNav('16-3-1', { id: "", mode: "add" })}
        >
            销售
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => addNav('16-4-1', { id: "", mode: "add" })}
        >
            调配
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => addNav('16-5-1', { id: "", mode: "add" })}
        >
            预退
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => stockExport().then()}
        >
            导出
        </Button>
    </>
    return <>
        <YhBox title="配件列表" action={action}>
            <StockDataTable />
        </YhBox>
    </>
}