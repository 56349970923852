import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMaintenanceModel } from "../maintenanceModel";
import { IMaintenanceDetail } from "../maintenanceType";
import { momentFormat } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import useNavModel from "@/model/navModel";

type statusType = {
    "maintenance.return-visit": string, //已回访
    "maintenance.write-off": string, //已结款
    "maintenance.underway": string,//在修
    "maintenance.await": string,//待修
    "maintenance.trans": string,//已转接
    "maintenance.stock-io": string,//已出库
    "maintenance.settle": string,//已结算
    "maintenance.create": string,//已开单
    "maintenance.change": string,//已作废
    // "maintenance.create": string,    
    "maintenance.completed": string,
    [key: string]: any
}
const statusColor: statusType = {
    "maintenance.return-visit": 'lime', //已回访
    "maintenance.write-off": "green", //已结款
    "maintenance.underway": "geekblue",//在修
    "maintenance.await": "purple",//待修
    "maintenance.trans": "cyan",//已转接
    "maintenance.stock-io": "magenta",//已出库
    "maintenance.settle": "gold",//已结算
    "maintenance.create": "blue",//已开单
    "maintenance.change": "red",//已作废
    "maintenance.completed": "green"//已完工,
}

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows
    } = useMaintenanceModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const columns: ColumnsType<IMaintenanceDetail> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1

        }, {
            title: '工单单号',
            width: 120,
            dataIndex: 'maintenanceCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => { e.stopPropagation(); onLook(text) }}>{text}</p></Tooltip>,
        }, {
            title: '预约单号',
            width: 120,
            dataIndex: 'appointmentCode',
            fixed: "left",
        }, {
            title: '工单状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '支付状态',
            width: 70,
            dataIndex: 'payStatus',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '客户名称',
            width: 150,
            dataIndex: 'customerName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '送修人',
            width: 120,
            dataIndex: 'contact',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '送车电话',
            width: 95,
            dataIndex: 'phone',
        }, {
            title: '工时费',
            width: 80,
            dataIndex: 'workingHoursAmount',
            render: (text) => `￥${text}`,
        }, {
            title: '配件费',
            width: 80,
            dataIndex: 'materialScienceAmount',
            render: (text) => `￥${text}`,
        }, {
            title: '费用合计',
            width: 80,
            dataIndex: 'totalAmount',
            render: (text) => `￥${text ?? 0}`,

        }, {
            title: '优惠金额',
            width: 80,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${text}`,
        }, {
            // title: '应收金额',
            title: '营业额',
            width: 80,
            dataIndex: 'receivableTotalAmount',
            render: (text) => `￥${text}`,
        // }, {
        //     title: '公交金额',
        //     width: 80,
        //     dataIndex: 'amountReceivable',
        //     render: (text) => `￥${text ?? 0}`,
        }, {
            title: '已收金额',
            width: 80,
            dataIndex: 'receivableAmount',
            render: (text) => `￥${text ?? 0}`,
        }, {
            title: '销售毛利',
            width: 80,
            dataIndex: 'grossProfit',
            render: (text) => `￥${text}`,
        }, {
            title: '销售成本',
            width: 80,
            dataIndex: 'totalCost',
            render: (text) => `￥${text}`,
        }, {
            title: '车牌号',
            width: 95,
            dataIndex: 'licensePlate',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: 'VIN码',
            width: 80,
            dataIndex: 'vin',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '车辆类型',
            width: 80,
            dataIndex: 'vehicleTypeName',
        }, {
            title: '车辆型号',
            width: 80,
            dataIndex: 'vehicleModel',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '维修类型',
            width: 80,
            dataIndex: 'serviceNatureName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '结算类型',
            width: 100,
            dataIndex: 'payType',
            // render: (text) => getTreeNodeName(text)，
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{getTreeNodeName(text)}</p></Tooltip>,
        }, {
            title: '结算方式',
            width: 80,
            dataIndex: 'paymentMethodName',
        }, {
            title: '结算方',
            width: 80,
            dataIndex: 'settlementPartyName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '发票类型',
            width: 80,
            dataIndex: 'invoiceTypeName',
        }, {
            title: '开票状态',
            width: 80,
            dataIndex: 'invoiceStatus',
            render: (text) => text === 1 ? "已开票" : "未开票"
        }, {
            title: '服务地点',
            width: 80,
            dataIndex: 'serviceLocationName',
        }, {
            title: '制单员',
            width: 60,
            dataIndex: 'createUser',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '制单时间',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结算员',
            width: 60,
            dataIndex: 'reckoner',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '结算时间',
            width: 130,
            dataIndex: 'settleTime',
            render: (text) => momentFormat(text)
        }, {
            title: '所属门店',
            width: 120,
            dataIndex: 'shopName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '作废原因',
            width: 120,
            dataIndex: 'cancelReason',
        }, {
            title: '维修技师',
            width: 120,
            dataIndex: 'technicianNames',

        }, {
            title: '故障描述',
            width: 120,
            dataIndex: 'faultDesc',
            fixed: "right",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, 
        // {
        //     title: '站点',
        //     width: 120,
        //     dataIndex: 'siteInformation',
        //     render: (text) => getTreeNodeName(text),
        //     fixed: "right"
        // },
         {
            title: '经营实体',
            width: 120,
            dataIndex: 'bentityName',
            fixed: 'right',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
            fixed: "right",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }
    ];

    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (record: IMaintenanceDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record: IMaintenanceDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IMaintenanceDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)

                }
            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
