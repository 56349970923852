import React, { useRef } from "react";
import { message } from "antd";
import { IVRMShopFormFields } from "../shopService";
import { useShopModel } from "../shopModel";
import { IYhFormRef, FormItemTypeEnum, IYhForm, ISelectOption } from "@/components/YhFormBox/Types";
import { ITreeSelectData, useDictModel } from "@/views/base/dict/dictModel";
import { getFormValidatorErrText } from "@/utils/utils";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useOrgModel } from "../../org/orgModel";
import { useGlobalModel } from "@/model/globalModel";


export function DetailDrawer () {
    const { shopDrawer: { visible, mode }, setIShopDrawer, insertShop, editShop, getSelectedRowData } = useShopModel();
    const formRef = useRef<IYhFormRef>();
    const { getOrgTreeSelect } = useOrgModel();
    const { getSelectedDictOne } = useDictModel();
    const { beDs } = useGlobalModel();
    const onCancel = () => {
        setIShopDrawer();
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then((val: any) => {
            console.log(val)
            let data = {
                ...val,
                status: val.status ? 0 : 1,
                details: val.details?.map((item: any) => ({
                    shopCode: "",
                    bentityCode: item,
                    bentityName: beDs?.find((origin: any) => origin.value === item)?.label ?? ""
                }))
            }
            mode === "edit" ? editShop(data) : insertShop(data);
        }).catch(err => {
            // console.log(err)
            message.warning(getFormValidatorErrText(err));
        })
    }

    //机构选中
    const onTreeSelectChange = (value: string, node: ITreeSelectData) => {
        console.log(node)
        node && formRef.current?.setFieldsValue({ orgName: node.title });
    }

    //门店类型
    const categoryChang = (value: string, option: ISelectOption) => {
        // debugger;
        option && formRef.current?.setFieldsValue({ categoryName: option.label });
    }

    const formConfig: IYhForm<IVRMShopFormFields> = {
        formRef,
        labelCol: { span: 6 },
        formValues: mode === "edit" ? getSelectedRowData() : { status: true, branch: "bcm" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                span: 24,
                fieldLabel: "分支编码",
                fieldName: "branch",
                disable: true
            },
            {
                type: FormItemTypeEnum.Input,
                span: 24,
                fieldLabel: "门店编码",
                fieldName: "code",
                placeholder: "自动生成",
                // disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "门店名称",
                fieldName: "name",
                span: 24,
                rules: [{ required: true, message: "请输入门店名称" }]
            }, {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "从属机构",
                fieldName: "orgCode",
                onTreeSelectChange,
                treeData: getOrgTreeSelect(DictCodeEnmu.ORG),
                span: 24,
                rules: [{ required: true, message: "请选择从属机构" }]
            },
            {
                type: FormItemTypeEnum.Select,
                span: 24,
                fieldLabel: "门店类型",
                onSelectChange: categoryChang,
                fieldName: "categoryCode",
                options: getSelectedDictOne(DictCodeEnmu.SHOP_TYPE),
                // rules: [{ required: true, message: "请输入门店类型" }]
            },
            {
                type: FormItemTypeEnum.Select,
                span: 24,
                fieldLabel: "关联实体",
                fieldName: "details",
                colClassName: "ant-col-h-auto",
                mode: "multiple",
                options: beDs,
                rules: [{ required: true, message: "请输入关联实体" }]
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "负责人",
                fieldName: "contact",
                span: 24,
                rules: [{ required: true, message: "请输入负责人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                span: 24,
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "公司地址",
                fieldName: "address",
                span: 24,
                rules: [{ required: true, message: "请输入公司地址" }]
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            }, {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "企业状态",
                fieldName: "status",
                span: 24,
                valuePropName: "checked",
                checkedChildren: "启用",
                unCheckedChildren: "禁用"
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "机构名称",
                fieldName: "orgName",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "门店类型名称",
                fieldName: "categoryName",
                hidden: true
            },
        ]
    };
    return (
        <YHDrawer
            {...{
                title: `门店${mode === "edit" ? "编辑" : "新增"}`,
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
