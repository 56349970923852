import React, { useEffect, FC } from "react";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { } from "@/model/navModel";
import { useStockCheckDetailModel } from "./stockCheckDetailsModel";
import { DetailType } from "@/types/ScmEnums";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { Button, message } from "antd";
import { globalPrompt } from "@/components/message";
import { ITopType } from "@/types/ScmTypes";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { debounce } from "lodash";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { useStockCheckModel } from "../../stockCheckModel";
export const FormBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const { selectedNavId, findNav, replaceNav } = useNavModel();
    const {
        loadData,
    } = useStockCheckModel();
    const { getSelectedDictOne } = useDictModel();
    const {
        detailAddForm,
        detailLookForm,
        detailEditForm,
        addDesc, lookDesc, editDesc,
        onFinalAudit,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        resetEditCatch,
        insertDetail,
        editDetail,
        onComplete,
        setDetailAddList,
        setDetailAddForm,
        setProfitAndLossForm
    } = useStockCheckDetailModel();
    const { warehouseList, user: { username }, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel()

    useEffect(() => {
        if (mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("9-2-1") && resetAddCatch(mode);
            !findNav("9-2-2") && resetEditCatch(mode);
        }
    }, [selectedNavId])
    const save = () => {
        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const resData = await insertDetail(res)
                if (resData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    resetAddCatch(mode)
                    // IFormRef.current?.resetFields()
                    goBack();
                }
            } else if (mode === "edit") {
                let { id } = detailEditForm
                const resData = await editDetail({ ...res, id })
                if (resData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    resetEditCatch(mode);
                    // IFormRef.current?.resetFields()
                    goBack();
                }
            }
        })
    }
    const Complete = async () => {
        let { stocktakingCode, id, status } = detailEditForm
        if (status === "stocktaking.complete") { return message.error("单据已完成，无法再次盘点") }
        if (status !== "stocktaking.create") { return message.error("单据不是进行中状态，无法再次盘点") }
        let retData = await onComplete({ stocktakingCode, id })
        if (retData) {
            message.success("盘点成功")
            goBack()
        } else {
            message.error("盘点失败")
        }
    }
    const finalAudit = async () => {
        let { stocktakingCode, id, status } = detailEditForm
        if (status === "stocktaking.complete") { return message.error("单据已完成，无法再次审核") }
        if (status !== "stocktaking.audit") { return message.error("单据不是待审核状态，无法审核") }
        let retData = await onFinalAudit({ stocktakingCode, id })
        if (retData) {
            message.success("审核成功")
            goBack()
        } else {
            message.error("审核失败")
        }
    }
    const goBack = () => {

        replaceNav(selectedNavId, "9-2")
        // loadData()
    }
    const onRefresh = () => {
        IFormRef.current?.resetFields()
        setDetailAddList([]);
        setDetailAddForm({})
        // if (mode === "add") {
        //     IFormRef.current?.resetFields()
        //     resetAddCatch("add")
        // } else if (mode === "edit") {
        //     IFormRef.current?.resetFields()
        //     getDetailEditOne(id, mode)
        // }
        // message.success("刷新成功")
    }
    const rightChildren = <>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_ED_SAVE}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { save() }, 900)}
            >保存</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_ED_FINISH}>
            {mode === "edit" && <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={Complete}
            >盘点完成</Button>}
        </YhAuth>
        {mode === "edit" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={finalAudit}
        >审核</Button>}
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    const warehousChange = (value: string, option: ISelectOption) => {
        option && onChange({ warehouseName: option.label, warehouseCode: option.value });
    }
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "盘库单号",
                disable: true,
                fieldName: "stocktakingCode",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "盘点仓库",
                fieldName: "warehouseCode",
                allowClear: true,
                showSearch: true,
                onSelectChange: warehousChange,
                options: transOptions(warehouseList?.filter((item: any) => item.type === "warehouse-category.big")),
                rules: [{ required: true, message: "请选择盘点仓库" }]

            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "盘点状态",
                disable: true,
                allowClear: true,
                showSearch: true,
                fieldName: "status",
                options: getSelectedDictOne(DictCodeEnmu.STOCKTAKING),
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                disable: true,
                fieldName: "shopName",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "盘点结果",
                fieldName: "result",
                disable: true,
                allowClear: true,
                showSearch: true,
                options: [{ label: "盘盈", value: "盘盈" }, { label: "盘亏", value: "盘亏" }, { label: "相符", value: "相符" }],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "盘点类型",
                allowClear: true,
                showSearch: true,
                // options: getSelectedDictOne(DictCodeEnmu.STOCKTAKING),
                options: [{ label: "盘点单", value: "trade-type.stocktaking.stt" }, { label: "期初单", value: "trade-type.stocktaking.init" }],
                fieldName: "type",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单人员",
                disable: true,
                fieldName: "createUser",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                disable: true,
                fieldName: "createTime",
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "开始时间",
                showTime: true,
                fieldName: "startTime",
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "结束时间",
                showTime: true,
                fieldName: "endTime",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "盘库时长",
                fieldName: "duration",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "仓库名称",
                fieldName: "warehouseName",
                hidden: true
            }
        ]
    };

    const onChange = (changedValues: { [key: string]: any }) => {

        if (mode === DetailType.add) {
            updateAddFormVal(changedValues)
        } else if (mode === DetailType.edit) {
            updatEditFormVal(changedValues)
        }
    }
    const typeDesc = {
        add: addDesc,
        edit: editDesc,
        look: lookDesc
    }
    const typeForm = {
        add: { ...detailAddForm, createUser: username, shopId, shopCode, shopName, result: setProfitAndLossForm(typeDesc[mode].differenceNumAll) },
        edit: { ...detailEditForm, result: setProfitAndLossForm(typeDesc[mode].differenceNumAll) },
        look: { ...detailLookForm, result: setProfitAndLossForm(typeDesc[mode].differenceNumAll) }
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === DetailType.look,
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }
    return (
        <YhFormBox {...poFromConfig} />
    )
}