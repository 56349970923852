import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { ChangeSaleDrawer } from "./details/changeSaleDrawer";
import { usePurchasePoModel } from "../purchasePoModel";
import { SalePrint } from "./SalePrint";

export default () => {
    const { print } = usePurchasePoModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            <ChangeSaleDrawer />
            {print && <SalePrint />}
        </>
    )
}