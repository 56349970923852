import React from "react";
import { ISearchField, ISearchProp } from "../Types";
import { Select } from "antd";

type PropType = ISearchProp & ISearchField;
export const SearchSelect = (props: PropType) => {
    return <Select
        size={props.size}
        allowClear
        className="ant-select-round"
        mode={props.mode}
        value={props.searchValues?.[props.fieldName] as string}
        placeholder={props.placeholder}
        showSearch={props.showSearch}
        optionFilterProp="label"
        onChange={(value) => {
            props.onChange?.({ [props.fieldName]: value })
            if (props.triggerSearch) props.handleSearch?.({ [props.fieldName]: value });
        }}
        disabled={props.disabled}
        options={props.options}
    />
}