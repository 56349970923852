import React, { useEffect } from "react";
import { YhBox } from "@/components/YhBox";
import { LogDataTable } from "./DataTable";
import { useLogModel } from "./logModel";

export const LogDataBox = () => {
    const {
        loadData,
        // dataSource,
        // selectedRowKeys,
        // setSelectedData,
    } = useLogModel()

    useEffect(() => {
        loadData({ pageNum: 1 }).then()
    }, [])

    // useEffect(() => {
    //     let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
    //     const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
    //     setSelectedData(selectedData)
    // }, [selectedRowKeys]);

    return (
        <>
            <YhBox title={"操作日志"} >
                <LogDataTable />
            </YhBox>
        </>
    )
}