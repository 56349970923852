import { httpRequest } from "../../utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {
    IFollowUpSearch,
    IFollowUpDetail,
    IFollowUpUpDate,
} from "./followUpType"

class ReturnService {
    async page(searchVo: IFollowUpSearch) {
        const apiUrl = "/bcm/customer/return/findPage";
        return await httpRequest.post<IPage<IFollowUpDetail>>(apiUrl,
            searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/customer/return/findById/${id}`;
        return await httpRequest.get<IFollowUpDetail>(apiUrl);
    }

    async update(insertVo: IFollowUpUpDate) {
        const apiUrl = "/bcm/customer/return/update";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //导出
    async settleExport(params: IFollowUpSearch) {
        const apiUrl = "/bcm/customer/return/settleExport";
        return await httpRequest.postFile(apiUrl, params);
    }
}
export const returnService = new ReturnService();
