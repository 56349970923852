import { useExportRecordModel } from './exportRecordModel'
import { ISearchValue, SearchTypeEnum } from '@/components/YhPageHeader/Types'
import { YhPageHeader } from '@/components/YhPageHeader'
import { StatusEnum } from './exportRecordTypes'
import { enumToOption } from '@/utils/utils'


export const PageHeader = () => {
    const { searchParam, updateSearchParam, resetSearchParam, loadData } = useExportRecordModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: '请输入文件名称',
            fieldName: 'fileName',
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: '请输入菜单',
            fieldName: 'menu',
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: '状态',
            fieldName: 'status',
            options: enumToOption(StatusEnum),
        },
    ]

    return (
        <YhPageHeader
            title="导出记录"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            onFmsChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    )
}
