import React, { useEffect } from "react";
import { useAdjustModel } from "./adjustModel";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { AdjustDataTable } from "./DataTable";
import { AdjustDrawer } from "./AdjustDrawer";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const AdjustDataBox = () => {
    const {
        dataSource,
        selectedData,
        selectedRowKeys,
        setAdjustDrawerVisible,
        loadData,
        setSelectedData,
    } = useAdjustModel();

    useEffect(() => {
        loadData({ pageNum: 1 }).then()
    }, [])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
    }, [selectedRowKeys]);


    const onApproval = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            if (selectedData?.status === 1) {
                setAdjustDrawerVisible(true);
            } else {
                message.error("该条记录不可审核!");
            }
        } else {
            message.error("请选择待审核记录!");
        }
    }

    const action = (
        <YhAuth resCode={ResourceAuthEnum.ACC_S_VW_ADJ_AUDIT}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onApproval}
            >
                审核
            </Button>
        </YhAuth>
    );
    return (
        <>
            <YhBox title={"调额记录"} action={action}>
                <AdjustDataTable />
            </YhBox>
            <AdjustDrawer />
        </>
    )
}
