import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Checkbox } from "antd";

export const __FormCheckbox: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            hidden={props.hidden}
            valuePropName="checked"
        >
            <Checkbox
                disabled={props.disable}
            />
        </Form.Item>
    )
}