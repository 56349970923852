import React from "react";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { YhBox } from "@/components/YhBox";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useStockModel } from "./stockModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export const StockSearchBox = () => {
    const {
        searchParam, updateSearchParam, initPgCfg, resetSearchParam, loadData
    } = useStockModel();
    const { getSelectedDictOne } = useDictModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "品牌",
            fieldName: "brandCode",
            options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND),
            xxl: 3,
            allowClear: true,
            showSearch: true,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "公交编码",
            fieldName: "materiaNo",
            xxl: 3,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "商品编码",
            fieldName: "materialCode",
            xxl: 3,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "替换编号",
            fieldName: "replaceCode",
            xxl: 3,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "商品名称",
            fieldName: "materialName",
            xxl: 3,
        },
    ]
    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title=""
                justify="end"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
        </YhBox>
    )
}