import React, { FC, useState } from "react";
import { Button, Table, Input, message, Upload, Checkbox, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { RouterParamType } from "@/model/navModel";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import useGlobalModel from "@/model/globalModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import { formatInt, formatNum, formatDiscount, bigNumber } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { IBCMSDcGoodsDetail } from "../../allotType";
import { useSaleDcDetailsModel } from "./saleAllotDetailsModel";
import { importPropsConfigScm, messageConfig } from "@/utils/importFile";
import { HistoryModel } from "@/views/historyModel";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { saleDcService } from "../../allotService";

type ISCMSDcGoodsSearch = {
    data: IBCMSDcGoodsDetail[],
    isSearch: boolean
}

export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const { shopAndOrg: { shopId }, setLoadingInfo } = useGlobalModel();
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const { addGoodsList, addFormVal, editFormVal, editFormVal: { status }, sDcDownload, setAddGoodsList, editGoodsList, setEditGoodsList, setAddGoodsModal, exportSDcdetail
        , setHistoryVisible, historyRow, historyVisible, setHistoryRow, setBtnContral } = useSaleDcDetailsModel();
    const onHistoryModel = (record: IBCMSDcGoodsDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMSDcGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据

    //删除商品
    const onRemove = () => {
        if (!selectedRowKeys.length) {
            message.warning("请选择你要删除的商品");
            return;
        }
        mode === "edit" ?
            setEditGoodsList((origin: any) => origin.filter((item: any) => selectedRowKeys.indexOf(item.key) < 0)) :
            setAddGoodsList((origin: any) => origin.filter((item: any) => selectedRowKeys.indexOf(item.key) < 0));

        let newSearchData = searchVal.data.filter(item => selectedRowKeys.indexOf(item.key) < 0)
        setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });

        setSelectedRowKeys([]);
        message.success("删除成功");
    }
    const tranShopId = mode === "edit" ? editFormVal.shopId : addFormVal.shopId;
    const branchIn = mode === "edit" ? editFormVal.branchIn : addFormVal.branchIn;

    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource: IBCMSDcGoodsDetail[] | null = null) => {
        const isEtit = mode === "edit";
        let goodsList = dataSource ? dataSource : [...(isEtit ? editGoodsList : addGoodsList)];
        if (searchParms) {
            let reg = new RegExp(searchParms);
            let arr: IBCMSDcGoodsDetail[] = [];
            goodsList.forEach(item => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })

            setSearchVal({ data: arr, isSearch: true });

            if (!arr.length) {
                message.warning("列表没有该商品！！")
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }

    //导入明细成功
    const upLoadCallback = async (list: any[]) => {
        // debugger
        setLoadingInfo({ loadingVisible: false, txt: "" });
        if (!list?.length) return message.warning('请导入正确格式的配件');
        const isEdit: boolean = mode === "edit";
        let newShelfRows: any[] = [];
        let str: string = "";
        let formVal = mode === 'add' ? addFormVal : editFormVal;
        let inShopId = formVal.shopId ?? shopId;
        const { retData: stockList } = await saleDcService.stockQuery({ details: list.map(item => ({ shopId: inShopId, materialCode: item.materialCode })) });
        list.forEach( item => {
            const stock = stockList.find(i => i.materialCode === item.materialCode);
            item.inShelfCode= stock?.inShelfCode
            item.inShelfId = stock?.inShelfId
            item.inWarehouseCode = stock?.inWarehouseCode
            item.inWarehouseName = stock?.inWarehouseName
        })
        list.forEach(item => {
            let num: number = item.num ?? 0;
            let price: number = item.price
            if (!item.success) {
                str += `【${item.msg}】`
            }
            if (item.inventoryDetailsList && item.inventoryDetailsList.length) {
                let data = item.inventoryDetailsList.map((item: any) => {
                    return {
                        ...item,
                        createTime: Number(item.createTime?.join(''))
                    }
                }).filter((list:any)=>list.inventoryNum!==0)
                const tempArr = data.sort((a: any, b: any) => a.createTime - b.createTime)
                for (let i = 0; i < tempArr.length; i++) {
                    let goods = tempArr[i];
                    // 公共字段
                    let publicData = {
                        id: "",
                        key: goods.id,
                        purchaseDetailsId: goods.id,
                        materialCode: goods.materialCode,
                        materialName: goods.materialName,  //商品名称
                        price: bigNumber.times(price!==0?price:goods.costPrice,(1 - Number(item.discountRate ?? 0))).toFixed(2) ,
                        prePrice:price!==0?price:goods.costPrice,
                        costPrice: goods.costPrice,
                        purchaseCode: goods.bussCode,
                        inventoryNum: goods.inventoryQuantity - goods.occupyNum,
                        typeName: goods.typeName,
                        typeCode: goods.typeCode,
                        outWarehouseCode: goods.warehouseCode,  //仓库编码
                        outWarehouseName: goods.warehouseName,  //仓库编码
                        outShelfCode: goods.shelfCode,
                        replaceCode: goods.replaceCode,
                        unitName: goods.unitName,
                        unitCode: goods.unitCode,
                        latestPurPrice: goods.latestPurPrice,
                        inShelfCode: item?.inShelfCode,
                        inShelfId: item?.inShelfId,
                        inWarehouseCode: item?.inWarehouseCode,
                        inWarehouseName: item?.inWarehouseName,
                        discountRate:item.discountRate
                    }
                    // 可用库存 = 库存数量 - 占用库存
                    let kynum = goods.inventoryQuantity - goods.occupyNum;
                    //当匹配到最后一个配件, 可用库存不满足用户填写数量, 那就设置该配件为异常配件, 数量设置为减完前面配件可用库存剩余的数量
                    if (i === tempArr.length - 1 && kynum < num) {
                        newShelfRows.push({
                            ...publicData,
                            isHave: true,
                            error: item.msg,
                            num, //开单数量
                            confirmNum: num ?? 1,
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            // amount: +formatNum(Number(price ?? 0) * (1 - Number(goods.discountRate ?? 0)) * (Number(num ?? 1))),
                            amount:+bigNumber.toFixed(bigNumber.times(
                                bigNumber.times(Number(price!==0?price:goods.costPrice),bigNumber.minus(1,Number(item.discountRate ?? 0),)),Number(num ?? 1))
                            ,2),
                            preAmount: +bigNumber.toFixed(bigNumber.times(Number(price!==0?price:goods.costPrice),Number(num ?? 1)),2),

                            
                        })
                        break
                    }
                    if (num <= 0) break;
                    if (kynum) {
                        num = num - kynum;
                        // 当 可用数量 小于
                        let tempNum = num < 0 ? kynum + num : kynum;
                        newShelfRows.push({
                            ...publicData,
                            isHave: item.success,
                            msg: item.msg,
                            num: tempNum, //开单数量
                            confirmNum: tempNum ?? 1,
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            //amount: +formatNum(Number(price ?? 0) * (1 - Number(goods.discountRate ?? 0)) * (Number(tempNum ?? 1)))
                          
                            //amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(+price ?? 0,bigNumber.minus(1,+goods.discountRate ?? 0)),+tempNum??1),2)
                            amount:+bigNumber.toFixed(bigNumber.times(
                                bigNumber.times(Number(price!==0?price:goods.costPrice),bigNumber.minus(1,Number(item.discountRate ?? 0),)),Number(tempNum ?? 1))
                            ,2),
                            preAmount: +bigNumber.toFixed(bigNumber.times(Number(price!==0?price:goods.costPrice),Number(tempNum ?? 1)),2),
                        })
                    }
                }
            }
        })
        
        isEdit ? setEditGoodsList(origin => [...origin, ...newShelfRows]) : setAddGoodsList(origin => [...origin, ...newShelfRows]);
        messageConfig(str ? str : "导入成功", 20, "info");
    }
    const action = <div className="flex justify-between white-space">
        <div>
            <YhAuth resCode={ResourceAuthEnum.S_DC_ED_ADD_M} isShow={mode === "add"}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    disabled={mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT}
                    onClick={() => {
                        if (mode === "add" && !addFormVal.shopCode) {
                            message.warning("请选择调入门店");
                            return false;
                        }
                        if (mode === "add" && !addFormVal.bentityCode) {
                            message.warning("请选择调入实体");
                            return false;
                        }
                        if (mode === "add" && !addFormVal.outBentityCode) {
                            message.warning("请选择调出实体");
                            return false;
                        }
                        setAddGoodsModal(true)
                    }}
                >新增商品</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.S_DC_ED_DEL_M} isShow={mode === "add"}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    disabled={mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT}
                    onClick={() => onRemove()}
                >删除商品</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.S_DC_ED_DLOAD} isShow={mode === "add"}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    disabled={mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT}
                    onClick={() => {
                        sDcDownload()
                    }}
                >下载模板</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.S_DC_ED_IM}>
                <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfigScm(`/bcm/stock/details/allocationImport/${shopId}/${tranShopId}/${branchIn}`, upLoadCallback, () => setLoadingInfo({ loadingVisible: true, txt: "快速导入中" }))} >
                    <Button className="mr-5" type="link" shape="round" disabled={mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT}>导入</Button>
                </Upload>
            </YhAuth>
        </div>
        <div className="flex">
            <Input
                allowClear
                datatype="round"
                className="border-r-16"
                placeholder="商品编号/商品名称"
                onChange={(e) => setSearchParms(e.target.value)}
                onPressEnter={() => goodsFilter()}
            />
            <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 检索</Button>
        </div>
    </div>;


    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '提示',
            width: 60,
            fixed: "left",
            align: "center",
            dataIndex: '',
            render: (t: any, r: any) => !r.isHave || r?.error ? <Tooltip placement="topLeft" title={!r.isHave ? r?.msg ?? '调入门店没有该商品, 保存时会自动添加该配件, 请及时维护对应的商品信息' : r.error} arrowPointAtCenter><ExclamationCircleOutlined style={{ color: '#FF4500', fontSize: '20px' }} /></Tooltip> : ''
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => { e.stopPropagation(); onHistoryModel(record) }}>{text}</p></Tooltip>,

        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
        },
        {
            title: '品牌',
            width: 160,
            dataIndex: 'brandName',
            fixed: "left",
        }, {
            title: '调拨数量',
            width: 100,
            dataIndex: 'num',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        },{
            title: '折前单价',
            width: 120,
            dataIndex: 'prePrice',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
            render: (text: any) => `￥${formatNum(text)}`
        },  {
            title: '折前调拨金额',
            width: 120,
            dataIndex: 'preAmount',
            render: (text: any) => `￥${formatNum(text)}`
        },{
            title: '下浮',
            width: 100,
            dataIndex: 'discountRate',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        }, {
            title: '单价',
            width: 130,
            dataIndex: 'price',
            // editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
            render: (text: any) => `￥${formatNum(text)}`
        }, {
            title: '调拨总计',
            width: 136,
            dataIndex: 'amount',
            render: (text: any) => `￥${formatNum(text)}`
        }, {
            title: '成本单价',
            width: 120,
            dataIndex: 'costPrice',
            render: (text: any) => `￥${formatNum(text ?? 0)}`
        }, {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryNum',
        }, {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'outShelfCode',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'outWarehouseName',
        }, {
            title: '调入货位',
            width: 120,
            dataIndex: 'inShelfCode',
        }, {
            title: '调入仓库',
            width: 120,
            dataIndex: 'inWarehouseName',
        }, {
            title: '商品类型',
            width: 120,
            dataIndex: 'typeName',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        }
    ];

    const newSelectColumns = columns.map((col: any, index) => {
        return {
            ...col,
            onCell: (record: any, rowIndex: number) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = (row: IBCMSDcGoodsDetail) => {
        console.log('row.amount1', row.amount)
        row.num = +formatInt(row.num, +(row?.inventoryNum ?? 0), "调拨数量>=库存");
        row.price = +bigNumber.times(row.prePrice,(1 - (+row.discountRate))).toFixed(2);
        row.prePrice = +formatNum(row.prePrice);
        row.preAmount = +bigNumber.times(row.prePrice,row.num).toFixed(2);
        // if(row.price < +formatNum(row.costPrice)){
        //     row.price = +formatNum(row.costPrice);
        //     message.warning("您填写的单价不能小于进货价,已为您重置为进货价");
        // }
        row.discountRate = +formatDiscount(row.discountRate);

        //总计=单价*（1-下浮）*开单数量
       // row.amount = +formatNum(row.price * (1 - row.discountRate) * row.num);
        row.amount=+bigNumber.toFixed(bigNumber.times(bigNumber.times(row.prePrice,1 - row.discountRate),row.num),2)
        console.log('row.amount', row.amount)
        let dataSource = mode === "edit" ? editGoodsList : addGoodsList
        const index = dataSource.findIndex((item: any) => row.key === item.key);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        mode === "edit" ? setEditGoodsList([...dataSource]) : setAddGoodsList([...dataSource]);

        // setWsConfirmDisable(true);

        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(dataSource)
        }
        setBtnContral(true)
    }

    return (
        <YhBox title="" action={action} style={{ marginBottom: "75px",minHeight:'500px'}}>
            <Table
                rowKey={record => record.key}
                rowClassName={(record) => {
                    if (!record?.isHave || record?.error) return "editable-row yh-edit-table-tr background-FFB4B4";
                    return "editable-row yh-edit-table-tr"
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys: React.Key[], selectedRows: IBCMSDcGoodsDetail[]) => {
                        setSelectedRowKeys(selectedRowKeys as string[]);
                    }
                }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns as ColumnsType<IBCMSDcGoodsDetail>}
                dataSource={searchVal.isSearch ? searchVal.data : mode === "edit" ? editGoodsList : addGoodsList}
                pagination={false}
                scroll={{ x: 'max-content', y: "calc(100vh - 350px)" }}
            />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
        </YhBox>
    )
}