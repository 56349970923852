import React from "react";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { YhBox } from "@/components/YhBox";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useSaleModel } from "./saleModel";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export const SaleSearchBox = () => {
    const {
        searchParam, updateSearchParam, initPgCfg, resetSearchParam, loadData
    } = useSaleModel();
    const { CustomerData,beShopDs } = useGlobalModel();
    const { getSelectedDictOne } = useDictModel();

    const searchFields = [
        
        {
            type: SearchTypeEnum.Select,
            placeholder: "销售实体",
            fieldName: "bentityCode",
            options: beShopDs,
            allowClear: true,
            showSearch: true,
            xxl: 6,
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            options: getSelectedDictOne(DictCodeEnmu.SALE),
            allowClear: true,
            showSearch: true,
            xxl: 3,
        }, 
        {
            type: SearchTypeEnum.Input,
            placeholder: "销售单号",
            fieldName: "saleCode",
            xxl: 3,
        },
        
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser",
            xxl: 3,
        },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "客户名称",
        //     fieldName: "customerCode",
        //     allowClear: true,
        //     showSearch: true,
        //     options: transOptions(CustomerData.filter((item: any) => item.categoryCode === "customer-type.transit")),
        //     xxl: 6,
        // }, 
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName",
            xxl: 6,
        },
        // {
        //     type: SearchTypeEnum.Input,
        //     placeholder: "商品编码",
        //     fieldName: "materialCode",
        //     xxl: 3, 
        // }, 
       
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS),
            allowClear: true,
            showSearch: true,
            xxl: 3,
        }, 
         {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["结算开始", "结算结束"],
            rangePickerFieldName: ["statSettleTime", "endSettleTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },    
    ]
    return (
            <YhPageHeader
                title="销售列表"
                justify="end"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
    )
}