import React, { useEffect } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useStockOutAndInListModel } from "../stockOutAndInListModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useStockBorrowDetailModel } from "./details/stockBorrowDetailsModel"
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { YhAuth } from "@/components/auth/YhAuth";
export const DataBox = () => {
    const { selectedRow, selectedRowKeys } = useStockOutAndInListModel();
    const {
        resetEditCatch,
    } = useStockBorrowDetailModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let saleOrderEditKey = "9-4-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow?.borrowCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "维修借件跳转详情",
                type: "warning",
                text: "您还有维修借件详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow?.borrowCode as string, mode: "edit" });
                    resetEditCatch();
                }
            })
        }
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ST_M_BORROW_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav('9-4-1', { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_M_BORROW_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
    </>;
    return (
        <YhBox title="维修借件列表" action={action}>
            <DataTable />
        </YhBox>
    )
}