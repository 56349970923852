import React, { FC } from "react";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel from "@/model/navModel";
import { useAppointmentDetailsModel } from "./appointmentDetailsModels";
import { Description } from "@/components/YhBox/Description";
import { useDictModel } from "@/views/base/dict/dictModel";
import { ITopType } from "@/types/ScmTypes";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const TopButs: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        transfer
    } = useAppointmentDetailsModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();

    const onTrunWork = async () => {
        IFormRef.current?.validateFields().then(async res => {
            let { id, appointmentCode } = detailEditForm
            let retData = await transfer({ ...res, id, appointmentCode })
            if (retData) {
                message.success("转接成功");
                replaceNav(selectedNavId, "4")
            }
        })

    }
    const action = mode == "edit" ? <>
        <YhAuth resCode={ResourceAuthEnum.AP_ED_VERIFY}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onTrunWork}
            >转工单核对</Button>
        </YhAuth>
    </> : false;

    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatu: IDescription[] = [
        {
            label: "预约单",
            value: typeTitle[mode].appointmentCode ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            // action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}