import React, { FC } from "react";
import { IFormItem, ISelectOption } from "../Types";
import { Form, Select } from "antd";

export const __FormSelect: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}

        >
            <Select
                allowClear={props.allowClear}
                showSearch={props.showSearch?? true}
                options={props.options}
                disabled={props.disable}
                mode={props.mode}
                optionFilterProp="label"
                onChange={(value: string, option) => props.onSelectChange?.(value, option as ISelectOption)}
                onFocus={() => props.onSelectFocus?.()}

            />
        </Form.Item>
    )
}