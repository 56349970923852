import React, { FC } from "react";
import { Button, message, Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { globalPrompt } from "@/components/message";
import { ColumnsType } from "antd/es/table";
import { useSaleRoDetailModel } from "./saleRoDetailModel";
import { DetailType } from "@/types/ScmEnums";
import { IDetailList } from "../../saleRoTypes";
import { formatNum, formatIntPoint, bigNumber } from "@/utils/utils";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { ITopType } from "@/types/ScmTypes";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import useGlobalModel from "@/model/globalModel";


export const TableBox: FC<ITopType> = ({ id, mode, IFormRef }) => {

    const {
        setAddModalVisible,
        detailAddList,
        detailDataSource,
        detailLookList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        detailDetailList,
        modelScreenMethod,
        setTopButsControl,
        setHistoryVisible,
        setHistoryRow
    } = useSaleRoDetailModel();
    const onHistoryModel = (record: IDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }

    const switchModelViaible = () => {
        IFormRef.current?.validateFields().then(async res => {
            setAddModalVisible(true)
        })
    }
    const action =
        <>
            {(mode !== "look" ? (mode === "add" || detailEditForm.status !== "sale-return-status.settle") : false) && <>
                <YhAuth resCode={ResourceAuthEnum.S_R_ED_ADD_M}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={switchModelViaible}
                    >新增商品</Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.S_R_ED_DEL_M}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={() => { detailDetailList(mode) }}
                    >删除商品</Button>
                </YhAuth>

            </>}
        </>

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '销售单号',
            width: 100,
            dataIndex: 'saleCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '配件编码',
            width: 100,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 100,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '销售数量',
            width: 80,
            dataIndex: 'saleNum',
        }, {
            title: '销售单价',
            width: 100,
            dataIndex: 'salePrice',
        }, {
            title: '退货数量',
            width: 100,
            dataIndex: 'num',
            editable: mode === DetailType.look || detailEditForm.status === "sale-return-status.stock-io" || (detailEditForm.status === "sale-return-status.settle" && mode === DetailType.edit) ? false : true,
        }, {
            title: '退货单价',
            width: 100,
            dataIndex: 'price',
            editable: mode === DetailType.look || detailEditForm.status === "sale-return-status.stock-io" || (detailEditForm.status === "sale-return-status.settle" && mode === DetailType.edit) ? false : true,
        }, {
            title: '退货折扣',
            width: 100,
            dataIndex: 'discountRate',
            render: (text: string) => text + "%"
        }, {
            title: '单项优惠',
            width: 100,
            // editable: mode === DetailType.look || (detailEditForm.status === "sale-return-status.settle" && mode === DetailType.edit) ? false : true,
            dataIndex: 'decreaseAmount',
        }, {
            title: '退货金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '已退数量',
            width: 100,
            dataIndex: 'sreturnNum',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '备注',
            width: 200,
            dataIndex: 'note',
            fixed: 'right',
            editable: mode != "look" ? true : false,
        }
    ];
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = (row: IDetailList) => {
        let { num, price, sreturnNum, saleNum, discountRate, salePrice, decreaseAmount } = row as { decreaseAmount: number, salePrice: number, num: number, price: number, sreturnNum: number, saleNum: number, discountRate: number }
        saleNum = + formatNum(saleNum)
        sreturnNum = + formatNum(sreturnNum)
        price = + formatIntPoint(price, salePrice, "退货单价>销售单价")
        discountRate = +formatIntPoint(discountRate, 100, '折扣不能高于100%')
        num = +formatIntPoint(num, saleNum - sreturnNum, "退货数量超出可退数量")
        decreaseAmount = +formatNum(decreaseAmount ?? 0)
        let newRow = {
            ...row,
            num,
            decreaseAmount,
            price: +formatNum(price),
            // amount: +formatNum(num * price * (discountRate / 100) - decreaseAmount)
            amount:+bigNumber.toFixed(bigNumber.minus(bigNumber.times(bigNumber.times(num,price),bigNumber.dividedBy(discountRate,100)),decreaseAmount),2)
        }
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.materialCode === item.materialCode);
        const item = newData[index];

        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
        setTopButsControl(true)
    }
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }

    return (
        <YhBox title="配件信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.detailId as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode == DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns() as ColumnsType<IDetailList>}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}