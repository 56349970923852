import React, { FC } from "react";
import useGlobalModel from "@/model/globalModel";

export interface IYhAuth {
    resCode: string;
    isShow?: boolean;  //强制渲染， 不去查询是否具有用户权限。
}


export const YhAuth: FC<IYhAuth> = (props) => {
    const { user, resourceAuth, setUser } = useGlobalModel();
    const { resCode, children } = props

    const hasAuth = (resCode: string) => {
        //判断当前用户是否具有资源访问权限
        // return !!resourceAuth.find((item: string) => item === resCode)
        return true
    }
    return hasAuth(resCode) ? <>{children}</> : null
}