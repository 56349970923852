import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface ITransferMonitorDetail {
    id: string, // string
    beginNum?: string,   // 2
    bookOccupyNum?: string,   // null
    busSaleNum?: string,   // null
    busSreturnNum?: string,   // null
    compensateNum?: string,   // null
    currentNum?: string,   // 2
    differenceNum?: string,   // 0
    maintainNum?: string,   // null
    maintainOccupyNum?: string,   // null
    materialCode?: string,   // "612600060133"
    materialName?: string,   // "外置节温器"
    mreturnNum?: string,   // null
    occupyDifference?: string,   // 0
    occupyNum?: string,   // 0
    preturnNum?: string,   // 0
    preturnOccupyNum?: string,   // 0
    purchasePrice?: string,   // 85
    purchasePriceLatest?: string,   // 85
    purhcaseNum?: string,   // 2
    remainNum?: string,   // 2
    saleNum?: string,   // 0
    saleOccupyNum?: string,   // 0
    shopId?: string,   // "64eec0c1d6f37078b6b460e4cb0ac9d7"
    sreturnNum?: string,   // 0
    stockCost?: string,   // 170
    stockCostLatest?: string,   // 170
    unusualSreturnNum?: string,   // 0
    [key: string]: any
}

//搜索条件
export interface ITransferMonitorSearchFields {
    [key: string]: any
}

export interface ITransferMonitorSearch
    extends ITransferMonitorSearchFields, ISearchPage {

}
export interface IStatistics {
    num: number;// 差异数量
    amount: number;// 差异金额
    costAmount: number;// 差异金额
    profit: number;// 差异毛利
}




//进销存报表
class TransferMonitorService {
    async page(searchVo: ITransferMonitorSearch) {
        const apiUrl = "/sms/transfer/to/allMonitoring";
        return await httpRequest.post<IPage<ITransferMonitorDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: ITransferMonitorSearch) {
        const apiUrl = "/sms/transfer/to/allMonitoring/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo: ITransferMonitorSearch) {
        const apiUrl = "/sms/transfer/to/top/allMonitoring";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }

}

export const transferMonitorService = new TransferMonitorService();