import React, {} from "react";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";
import { YhBox } from "@/components/YhBox";
import { useEntryDetailsModel } from "./stockEntryModelDetails";
export const TopButs = () => {
    const { detailLookForm } = useEntryDetailsModel()

    const orderStatu: IDescription[] = [
        {
            label: "单据编号",
            value: detailLookForm.tradeCode as string,
            color: DescriptionColorEnum.blue,
            status: true
        },
    ]
    return (
        <YhBox
            title={<>
                {orderStatu.map((item, index) => <Description key={index} {...item} />)}
            </>}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}