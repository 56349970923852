import React, { useEffect, FC } from "react";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum } from "@/components/YhFormBox/Types";
import { Button, message } from "antd";
import { useDeliveryDetailsModel } from "./deliveryDetailsModel"
import { ITopType } from "@/types/ScmTypes";
import { DetailType } from "@/types/ScmEnums";
import useNavModel from "@/model/navModel";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { YhAuth } from "@/components/auth/YhAuth";
export const FormBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const { technicianData } = useGlobalModel()
    const {
        detailEditForm,
        detailLookForm,
        resetEditCatch,
        getDetailEditOne,
        deliveryUpdate,
        getOutEntry,
    } = useDeliveryDetailsModel();
    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
            getOutEntry(id, mode)
        }
        if (mode === DetailType.look) {
            getDetailEditOne(id, mode)
            getOutEntry(id, mode)
        }

    }, [id, mode])
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    useEffect(() => {
        return () => {
            !findNav("9-3-2") && resetEditCatch();
        }
    }, [selectedNavId])
    const save = () => {
        IFormRef.current?.validateFields().then(async res => {
            let { id, maintenanceCode, materialHandler, storekeeper } = res
            let retData = await deliveryUpdate({ id, maintenanceCode, materialHandler, storekeeper })
            if (retData) {
                message.success("保存成功")
                getDetailEditOne(maintenanceCode, mode)
            }
        })
    }
    const goBack = () => {
        replaceNav(selectedNavId, "9-3")
    }
    const rightChildren = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.ST_M_OUT_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={save}
                >保存</Button>
            </YhAuth>
        }
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [{
            type: FormItemTypeEnum.Input,
            fieldLabel: "工单单号",
            fieldName: "maintenanceCode",
            disable: true
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "客户名称",
            fieldName: "customerName",
            disable: true
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "送修人",
            fieldName: "contact",
            disable: true
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "车牌号",
            fieldName: "licensePlate",
            disable: true
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "创建时间",
            fieldName: "createTime",
            disable: true
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "库管",
            fieldName: "storekeeper",
            disable: true
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "领料人",
            fieldName: "materialHandler",
            disable: true,
            // options: transOptions(technicianData)
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "vin码",
            fieldName: "vin",
            disable: true
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "id",
            fieldName: "id",
            hidden: true
        }]
    }
    const onChange = (changedValues: { [key: string]: any }) => {

    }
    const typeForm = {
        add: {},
        edit: detailEditForm,
        look: detailLookForm
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        disabled: mode === "look",
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }
    return (
        <YhFormBox {...poFromConfig} />
    )
}