import React, { useEffect } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useStockOutDispatchModel } from "../stockOutDispatchModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useDeliveryDetailsModel } from "../view/details/deliveryDetailsModel"
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const DataBox = () => {
    const {
        selectedRowKeys,
        selectedRow,
        setSelectedRowKeys
    } = useStockOutDispatchModel();
    const { resetEditCatch } = useDeliveryDetailsModel()
    const { selectedNavId, replaceNav, addNav, findNav, selectNav, switchNavState } = useNavModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        // if (selectedRow.status === "maintenance.settle" || selectedRow.status === "maintenance.completed") {
        //     globalPrompt("message", { text: "该工单已经是已结算或已完工状态，无法进行编辑", type: "warning" });
        //     return;
        // }
        let saleOrderEditKey = "9-3-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "维修出库跳转详情",
                type: "warning",
                text: "您还有维修出库详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "9-3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.maintenanceCode as string, mode: "look", },
            true
        );
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ST_M_OUT_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_M_OUT_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="维修出库列表" action={action}>
            <DataTable />
        </YhBox>
    )
}