import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { PaginationProps } from "antd/es/pagination";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { IVRMSupplierDetail, IVRMSupplierSearchFields, supplierService } from "./supplierService";
import { globalPrompt } from "@/components/message";
import { convertRes2Blob } from "@/utils/exportFile";
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { useDictModel } from "../dict/dictModel";
import { useLockFn } from "ahooks";
import { accountShopService } from "@/views/account/AccountShopService";
import { message } from "antd";
export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

export const useSupplierModel = createModel(function () {
    const { shopAndOrg, user, loadSupplierAllData } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const { getTreeNodeName } = useDictModel();
    /**
     * 列表
     */
    //列表数据源
    const [dataSource, setDataSource] = useState<IVRMSupplierDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IVRMSupplierSearchFields>({});
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //所有列表查询

    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IVRMSupplierDetail>();

    //列表查询
    const loadData = async (page?: ISearchPage, searchValue: IVRMSupplierSearchFields = searchParam) => {

        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await supplierService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        loadSupplierAllData(shopAndOrg.shopId)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: IVRMSupplierSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = async () => {
        await setSearchParam({});
        await setSelectedRowKeys([]);
        await loadData(initPgCfg, {});
    }
    /**
     * 表单详情
     */
    const initAddFormVal = {
        enable: 1
    }

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<IVRMSupplierDetail>({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState<IVRMSupplierDetail>(initAddFormVal);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState<IVRMSupplierDetail>({});

    //表单新增修改
    const updateAddFormVal = (val: object | null) => {
        val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal(initAddFormVal);
    }
    //表单新增修改
    const updateEditFormVal = (val: object | null) => {
        val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({});
    }
    //获取一条详情数据 Api
    const getSupplierOne = async (id: string, pattern: string) => {
        let { retData } = await supplierService.one(id);
        // retData && setEditFormVal(retData);
        if (retData && pattern === "edit") {
            setEditFormVal(retData);
        } else if (retData && pattern === "look") {
            setLookFormVal(retData);
        }
    }

    //表单新增 Api
    const insertSupplier = useLockFn(async (data: IVRMSupplierDetail) => {
        let params = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            createUser: user.username,
            typeName: getTreeNodeName(data.typeCode as string),
            enable: data.enable ? 1 : 0,


        }
        let { retData } = await supplierService.insert(params);
        retData ?
            globalPrompt("message", { text: "供应商新增成功", type: "success" })
            : globalPrompt("message", { text: "供应商新增失败", type: "error" });
        if (retData) {
            await accountShopService.supplierSync({ name: data.name }).then(res => {
                res ? message.success("供应商账户信息同步成功...") : message.error("同步失败");
            })
        }
        return retData;
    })

    //表单编辑 Api
    const editSupplier = useLockFn(async (data: IVRMSupplierDetail) => {
        let params = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            updateUser: user.username,
            typeName: getTreeNodeName(data.typeCode as string),
            enable: data.enable ? 1 : 0,

        }
        let { retData } = await supplierService.update(params);
        retData ?
            globalPrompt("message", { text: "供应商编辑成功", type: "success" })
            : globalPrompt("message", { text: "供应商编辑失败", type: "error" });
        if (retData) {
            await accountShopService.supplierSync({ name: data.name }).then(res => {
                res ? message.success("供应商账户信息同步成功...") : message.error("同步失败");
            })
        }
        return retData;
    })
    //供应商状态
    const onDisabled = async (id: string, checked: boolean) => {
        let { retData } = await supplierService.disable({ id, enable: checked ? 1 : 0 })
        if (retData) {
            loadData()
            globalPrompt("message", { text: "供应商编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "供应商编辑失败", type: "error" });
        }
        return retData;
    }
    //导入
    const importSupplier = async (file: { file: File }) => {
        let { retData } = await supplierService.import(file);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "导入成功", type: "success" })
        } else {
            globalPrompt("message", { text: "导入失败", type: "error" });
        }
        return retData;
    }

    //导出
    const exportSupplier = async (searchValue: IVRMSupplierSearchFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await supplierService.export(searchParams);
        convertRes2Blob(result)
    }


    //下载模板
    const downloadTpl = async () => {
        let result = await supplierService.downloadTpl();
        convertRes2Blob(result)
        // retData ?
        //     globalPrompt("message", { text: "下载模板成功", type: "success" })
        //     : globalPrompt("message", { text: "下载模板失败", type: "error" });
        // return retData;
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        editFormVal,
        addFormVal,
        lookFormVal,
        initPgCfg,
        /***method***/
        updateAddFormVal,
        updateEditFormVal,
        insertSupplier,
        editSupplier,
        getSupplierOne,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        importSupplier,
        exportSupplier,
        downloadTpl,
        setEditFormVal,
        setAddFormVal,
        onDisabled,
        setLookFormVal,
        setHistoryRow,setHistoryVisible
    }
});