import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import React, { useEffect, useState } from "react";
import { PaginationProps } from "antd/es/pagination";

import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";
import {
    IStockInAndOutField, IStockInAndOutSearchField,
    ITotalVo
} from "./stockInAndOutType"
import { stockInAndOutService } from "./stockInAndOutService"
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { convertRes2Blob } from "@/utils/exportFile";
import { bigNumber } from "@/utils/utils";

export interface IMdBeSearchField {
    code?: string;
    name?: string;
    corporation?: string;
    phone?: string;
    address?: string;
    bank?: string;
    bankAccount?: string;
}
export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

export const useStockInAndOutModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IStockInAndOutSearchField = {};
    const initDataSource: IStockInAndOutField[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc: ITotalVo = { num: 0, amount: 0 }

    //查询条件
    const [searchParam, setSearchParam] = useState<IStockInAndOutSearchField>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IStockInAndOutField[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IStockInAndOutField>({});
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //单据汇总
    const [descTotal, setDescTotal] = useState<ITotalVo>(initDesc);
    //出入库类型
    const [ioFlag, setIoFlag] = useState<number>(0);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<ITotalVo>(initDesc);
    const [descRows, setDescRows] = useState<IStockInAndOutField[]>([]);
    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item, index) => {
            let { inTotal } = item as any
            newRowsDescTotal.num = bigNumber.add(newRowsDescTotal.num, 1)
            newRowsDescTotal.amount = bigNumber.add(newRowsDescTotal.amount, inTotal as number)
            // newRowsDescTotal.num += +1
            // newRowsDescTotal.amount += inTotal as number
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IStockInAndOutField)
    }, [selectedRowKeys])


    const [drawerVisible, setDrawerVisible] = useState(false);

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IMdBeSearchField) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, { ioFlag });
        setSelectedRowKeys([])
    }
    const reset = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        setIoFlag(0);
    }
    const loadData = async (page?: ISearchPage, searchValue: IStockInAndOutSearchField = { ...searchParam, ioFlag }) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await stockInAndOutService.page(searchParams);
        let { retData: totalVo } = await stockInAndOutService.statistics(searchParams);
        const { records, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setDescTotal(totalVo)
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records ?? []);
    }
    const exportEntry = async (searchValue: IStockInAndOutSearchField = searchParam) => {
        const searchParams = {
            ...searchValue,
            ioFlag,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await stockInAndOutService.export(searchParams);
        convertRes2Blob(result)
    }
    return {
        /***field***/
        searchParam,
        initPgCfg,
        dataSource,
        selectedRowKeys,
        pgCfg,
        drawerVisible,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        descTotal, setDescTotal,
        selectedRow, setSelectedRow,
        exportEntry,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        reset,
        ioFlag, setIoFlag
    }
});