import React from "react";
import { YhBox } from "@/components/YhBox";
import { Table, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { formatNum } from "@/utils/utils";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePsiModel } from "../../psiModel";


export const DetailsTable = ({ dataSource, recordType, loading, exportDetail, ...props }: any) => {
	const {
		setDatailsVisible
	} = usePsiModel();
	const columns: ColumnsType<any> = [
		{
			title: '序号',
			width: 66,
			align: "center",
			render: (t: any, r: any, i: number) => i + 1
		}, {
			title: '业务日期',
			width: 180,
			align: "center",
			dataIndex: 'createTime',
		}, {
			title: '单据编号',
			width: 120,
			align: "center",
			dataIndex: 'code',
			render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => setDatailsVisible({
				visible: true,
				code: text,
				type: record.type
			})}> {text}</span >,
		}, {
			title: '单价',
			width: 160,
			align: "center",
			dataIndex: 'price',
			render: text => `￥${formatNum(text)}`
		}, {
			title: '占用数量',
			width: 140,
			align: "center",
			dataIndex: 'num',
		}, {
			title: '金额',
			width: 120,
			align: "center",
			render: (text, record) => `￥${formatNum(record.num * record.price)}`
		}
	];


	const action = <Button
		className="mr-5 font14"
		type="link"
		size="small"
		shape="round"
		onClick={() => exportDetail()}
	>导出</Button>

	const obj: any = {
		occupy_ssd: "销售",
		occupy_prd: "采退",
		occupy_vmm: "维修",
		occupy_van: "预约",
	}
	const desc: IDescription[] = [
		{
			label: `${obj[recordType]}占用数量`,
			value: dataSource.reduce((c: any, n: { num: any; }) => { c = c + n.num; return c }, 0),
			color: DescriptionColorEnum.red
		},
	]

	return <YhBox title={action} descriptions={desc}>
		<Table
			loading={loading}
			rowKey={record => record.id}
			columns={columns}
			dataSource={dataSource}
			pagination={false}
			scroll={{ x: 'max-content', y: 340 }}
		/>
	</YhBox>
}