import React, {FC} from "react";
import {YhBox} from "../YhBox";
import {Row} from "antd";
import {__FormItem as FormItem} from "./item";
import {IYhFormItemsBox} from "./Types";


export const __FormItemsBox: FC<IYhFormItemsBox> = ({items, ...props}) => {
    return <YhBox {...props}>
        <Row
            // gutter={[20, 20]}
            justify="start"
        >
            {items.map((el, index) => <FormItem key={index} {...el} disable={el.disable ?? props.disabled} />)}
        </Row>
    </YhBox>
}
