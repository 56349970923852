import React, { useRef,  useState } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils";
import { useWarehouseModel } from "../../warehouseModel";
import { IVRMAreaFormFields } from "../../warehouseTypes";
import { debounce } from "lodash";



export function AreaDrawer() {
    const { areaDrawer: { visible, mode }, setIAreaDrawer, selectedAreaRows, editFormVal,
        insertWarehouseArea, batchInserttWarehouseArea, updateWarehouseArea } = useWarehouseModel();
    const formRef = useRef<IYhFormRef>();
    const [isBatch, setIsBatch] = useState<boolean>(false);

    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        if ('isBatch' in changedValues) {
            setIsBatch(changedValues['isBatch']);
        }
    }

    const onCancel = () => {
        setIAreaDrawer();
        setIsBatch(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            formRef.current?.inst().submit();
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = (val: IVRMAreaFormFields) => {
        if (mode === "edit") {
            updateWarehouseArea(val)
        } else {
            isBatch ? batchInserttWarehouseArea(val) : insertWarehouseArea(val);
        }
    }

    const isBatchItem = (bool: boolean) => {
        return bool ? [{
            type: FormItemTypeEnum.Input,
            fieldLabel: "起始编码",
            fieldName: "startCode",
            span: 24,
            rules: [{ required: true, message: "请输入起始编码" }]
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "数量",
            fieldName: "num",
            span: 24,
            rules: [{ required: true, message: "请输入数量" }]
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "前缀",
            fieldName: "prefix",
            span: 24,
            rules: [{ required: true, message: "请输入前缀" }]
        }] : []
    }

    const formConfig: IYhForm<IVRMAreaFormFields> = {
        formRef,
        onFinish,
        formValues: mode === "edit" ? selectedAreaRows[0] : { warehouseName: editFormVal.name },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属仓库",
                fieldName: "warehouseName",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "区域编号",
                fieldName: "code",
                span: 24,
                disable: isBatch,
                rules: [{ required: !isBatch, message: "请输入区域编号" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "区域名称",
                fieldName: "name",
                span: 24,
                disable: isBatch,
                rules: [{ required: !isBatch, message: "请输入区域名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            },
            ...isBatchItem(isBatch),
            {
                type: FormItemTypeEnum.Checkbox,
                fieldLabel: "批量录入",
                fieldName: "isBatch",
                span: 24,
                hidden: mode === "edit" ? true : false
            },
        ]
    };
    return (
        <YHDrawer
            {...{
                title: `区域${mode === "edit" ? "编辑" : "新增"}`,
                visible,
                onCancel,
                okText: "保存",
                onOk: debounce(() => { onSubmit() }, 900)
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
