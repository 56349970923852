import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSoReportModel } from "../soReportModel";
import { IVRMSoReportDetail } from "../soReportService";
import { formatNum } from "@/utils/utils";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useSoReportModel();

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IVRMSoReportDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '客户名称',
            fixed: "left",
            width: 200,
            dataIndex: 'customerName',
            render: (text: any) => <YhTootip text={text} />,
            //render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '客户类型',
            fixed: "left",
            width: 120,
            dataIndex: 'customerTypeName',
        }, {
            title: '单据编号',
            fixed: "left",
            width: 180,
            dataIndex: 'code',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '单据类型',
            width: 100,
            fixed: "left",
            dataIndex: 'tradeTypeName',
        }, {
            title: '销售类型',
            width: 80,
            dataIndex: 'maintenanceTypeName',
        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payTypeName',
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '商品编号',
            width: 200,
            dataIndex: 'materialCode',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
        }, 
        {
            title: '销售价格',
            width: 100,
            dataIndex: 'price',
        }, {
            title: '销售数量',
            width: 100,
            dataIndex: 'num',
            // render: (text: number, record) => record.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_SRO ? `-${text}` : `${text}`
        }, {
            title: '销售金额',
            width: 110,
            dataIndex: 'amount',
            // render: (text: number, record) => record.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_SRO ? `-￥${text}` : `￥${text}`
        }, {
            title: '销售成本',
            width: 110,
            dataIndex: 'costAmount',
            render: (text) => `￥${text}`
        }, {
            title: '销售毛利',
            width: 110,
            dataIndex: 'profit',
            render: (text) => `￥${text}`
        },
        {
            title: '配件销售频次',
            width: 100,
            dataIndex: 'saleCount',
        }, {
            title: '配件销退频次',
            width: 100,
            dataIndex: 'sreturnCount',
        }, {
            title: '制单人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '结算日期',
            width: 160,
            dataIndex: 'settleTime',
        }, {
            title: '物料编号',
            width: 100,
            dataIndex: 'insideCode',
        }, {
            title: '物料名称',
            width: 100,
            dataIndex: 'insideName',   
        }, {
            title: '替换编号',
            width: 200,
            dataIndex: 'replaceCode',   
        }, {
            title: '销售订单号',
            width: 180,
            dataIndex: 'transferCode',                  
        }, {
            title: '积压标记',
            width: 120,
            dataIndex: 'overstock',

        },{
            title: '备注',
            width: 200,
            dataIndex: 'note',
        // }, {
        //     title: '成本价格',
        //     width: 110,
        //     dataIndex: 'costPrice',
        // }, {
        //     title: '业务员',
        //     width: 120,
        //     dataIndex: 'createUser',

        // }, {
        //     title: '从属门店',
        //     width: 120,
        //     dataIndex: 'shopName',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id)
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}