import React, { useEffect } from "react";
import { Button, Input, Table, message, Select, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useWarehouseModel } from "../../warehouseModel";
import { IVRMshelfDetail } from "../../warehouseTypes";
import { ColumnsType } from "antd/es/table";

import { ShelfDrawer } from "./ShelfDrawer";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";



//货位
export const ShelfTable = () => {
    const {
        shelfPgCfg, shelfDataSource, setSelectedShelfRows,
        selectedShelfRows, shelfLoadData, areaLoadData,
        shelfSearch, setShelfSearch, resetShelf, shelvesLoadData, areaDataSource,
        shelvesDataSource,
        setIShelfDrawer,
        shelfExport,
        downloadTpl
    } = useWarehouseModel();

    useEffect(() => {
        shelfLoadData({ pageNum: 1, pageSize: 15 });
        areaLoadData({ pageNum: 1, pageSize: 100000 });
        shelvesLoadData({ pageNum: 1, pageSize: 100000 });
    }, [])

    const action = <div className="flex justify-between white-space">
        <div>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELF_AD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setIShelfDrawer("add")}
                >新增</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELF_ED}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => {
                        if (selectedShelfRows.length !== 1) {
                            message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！");
                            return;
                        }
                        setIShelfDrawer("edit");
                    }}
                >编辑</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELF_IM}>
                <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/warehouse/shelf/import`, shelfLoadData)}>
                    <Button className="mr-5" type="link" shape="round">导入</Button>
                </Upload>
            </YhAuth>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={downloadTpl}
            >下载模板</Button>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELF_EX}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() =>  shelfExport() }
                >导出</Button>
            </YhAuth>
        </div>
        <div className="flex min-width-select">
            <Select
                allowClear
                showSearch
                optionFilterProp="label"
                className="ant-select-round ml-10"
                value={shelfSearch?.["areaId"]}
                placeholder="所属区域"
                onChange={(value) => {
                    shelvesLoadData({ pageNum: 1, pageSize: 100000 }, { areaId: value });
                    setShelfSearch({ ...shelfSearch, "areaId": value })
                }}
                options={(() => areaDataSource.map(item => ({ label: item.code, value: item.id })))()}
            />
            <Select
                allowClear
                showSearch
                optionFilterProp="label"
                className="ant-select-round ml-10"
                value={shelfSearch?.["shelvesId"]}
                placeholder="所属货架"
                onChange={(value) => setShelfSearch({ ...shelfSearch, "shelvesId": value })}
                options={(() => shelvesDataSource.map(item => ({ label: item.code, value: item.id })))()}
            />
            <Input
                allowClear
                datatype="round"
                className="border-r-16 ml-10"
                placeholder="货位编码"
                value={shelfSearch?.["code"]}
                onChange={(e) => setShelfSearch({ ...shelfSearch, "code": e.target.value })}
            // onPressEnter={aeraFilter}
            />
            <Button
                style={{ margin: "0px 12px 0px 15px" }}
                shape="round"
                onClick={() => shelfLoadData()}
            >检索</Button>
            <Button
                style={{ margin: "0px 0px 0px 12px" }}
                shape="round"
                onClick={() => resetShelf()}
            >重置</Button>

        </div>
    </div>;

    const columns: ColumnsType<IVRMshelfDetail> = [
        {
            title: '序号',
            width: 60,
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '货位编号',
            width: 300,
            dataIndex: 'code',
        },
        {
            title: '所属仓库',
            width: 300,
            dataIndex: 'warehouseName',
        },
        {
            title: '所属区域',
            width: 350,
            dataIndex: 'areaCode',
        },
        {
            title: '所属货架',
            width: 300,
            dataIndex: 'shelvesCode',
        },
        {
            title: '创建人',
            width: 300,
            align: "center",
            dataIndex: 'createUser',
        },
        {
            title: '创建时间',
            width: 350,
            align: "center",
            dataIndex: 'createTime',
        },
        {
            title: '备注',
            width: 300,
            align: "center",
            dataIndex: 'note',
        }
    ];

    const onRow = (record: IVRMshelfDetail) => {
        console.log(record)
        let findArr = selectedShelfRows.find(item => item.id === record.id);
        if (findArr) {
            let filterArr = selectedShelfRows.filter(item => item.id !== record.id);
            setSelectedShelfRows(filterArr);
        } else {
            setSelectedShelfRows(origin => [...origin, record]);
        }
    };


    return (
        <>
            <YhBox action={action} >
                <Table
                    rowKey={record => record.id}
                    rowSelection={{
                        selectedRowKeys: selectedShelfRows.map(item => item.id), //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IVRMshelfDetail[]) => {
                            setSelectedShelfRows(selectedRows);
                        }
                    }}
                    columns={columns}
                    dataSource={shelfDataSource}
                    pagination={{
                        ...shelfPgCfg,
                        position: ["bottomCenter"],
                        showTotal: () => `共${shelfPgCfg.total}条`,
                        onChange: (pageNum, pageSize) => {
                            shelfLoadData({ pageNum, pageSize })
                        }
                    }}
                    onRow={record => ({
                        onClick: () => onRow(record)
                    })}
                    scroll={{ x: 'max-content' }}
                />
            </YhBox>
            <ShelfDrawer />
        </>
    )
}

