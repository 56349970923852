import React from "react";
import { ISearchField, ISearchProp } from "../Types";
import { Input } from "antd";
import { trimParams } from "@/utils/utils";

type PropType = ISearchProp & ISearchField;
export const SearchInput = (props: PropType) => {
    return <Input
        size={props.size}
        allowClear
        datatype="round"
        className="border-r-16"
        placeholder={props.placeholder as string}
        onPressEnter={() => props.triggerSearch ? props.handleSearch?.() : null}
        value={props.searchValues?.[props.fieldName] as string}
        onChange={(e) => {
            // console.log(e.target.value,'监听输入框输入')
            props.onChange?.({ [props.fieldName]: trimParams(e.target.value) })
            // if (props.triggerSearch) props.handleSearch?.();
        }}
        disabled={props.disabled} />
}