import { httpRequest } from "../../../utils/HttpRequest";
import { IStockEntryField, IStockEntrySearch, IStockEntryDetail } from "./stockEntryType"

class StockEntryService {
    async page(searchVo: IStockEntrySearch) {
        const apiUrl = "/bcm/stockIo/in/findPage";
        return await httpRequest.post<IStockEntryDetail[]>(apiUrl, searchVo);
    }
    async one(id: string,ioFlag:number = 1) {
        const apiUrl = `/bcm/stockIo/findById`;
        return await httpRequest.post<IStockEntryField>(apiUrl,{id,ioFlag});
    }
    //导出
    async export(params: IStockEntrySearch) {
        const apiUrl = "/bcm/stockIo/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: IStockEntrySearch) {
        const apiUrl = "/bcm/stockIo/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
}

export const stockEntryService = new StockEntryService();