import React, { useState, useEffect } from "react";
import { Menu, Button } from "antd";
import menuData from "../data/menuData.json";
import useNavModel from "../model/navModel";
import useGlobalModel from "../model/globalModel";
import FontIcon from "../components/YhIcon";
import { Link } from "react-router-dom";
import { forFind } from "@/utils/utils";

//定义菜单参数类型
export interface IMenuNode {
    id: string,
    name: string,
    path: string,
    icon: string,
    parent: string,
    closable: boolean,
    isShow: boolean,
    children: IMenuNode[]
}

const { SubMenu } = Menu;

function VrmMenu () {
    const { addNav, selectedNavId } = useNavModel();  
    const { cm, toggleMenu, menuAuth } = useGlobalModel();
    const [selectedMenuKey, setSelectedMenuKey] = useState<string>("");
    const [openKeys, setOpenKeys] = useState<string[]>(["1"]);
    const [menusTree, setMenusTree] = useState<IMenuNode[]>([]);  //设置权限 后得菜单树

    //设置菜单权限
    // useEffect(() => {
    //     let originTree = [...menuData];
    //     function setMenuShow(array: IMenuNode[]) {
    //         array.forEach(item => {
    //             //有权限就设置为展示
    //             item.isShow = menuAuth.indexOf(item.path) >= 0 ? true : false;
    //             if (item.children && item.children.length > 0)
    //                 setMenuShow(item.children);
    //         })
    //     }
    //     setMenuShow(originTree);
    //     setMenusTree(originTree);
    // }, [menuAuth]);
    //对应详情展示及选中菜单展开
    useEffect(() => {
        let menuInfo = forFind(menuData, selectedNavId) as IMenuNode;
        let selectedMenuId: string = menuInfo.isShow ? menuInfo.id : menuInfo.parent ?? "";
        let newOpenKey: string = selectedMenuId.split('-')[0];
        if (openKeys.indexOf(newOpenKey) === -1) {
            // let newOpenKeys: string[] = [...openKeys];
            let newOpenKeys: string[] = [];
            newOpenKeys.push(newOpenKey);
            setOpenKeys(newOpenKeys)
        }
        setSelectedMenuKey(selectedMenuId)
    }, [selectedNavId]);

    //选中的submenu Key
    const onOpenChange = (keys: React.Key[]) => {
        setOpenKeys(keys as string[])
    };

    //菜单每项选渲染
    const MenuItem = (node: IMenuNode): JSX.Element | null => (
        <Menu.Item key={node.id} icon={<FontIcon type={node.icon} />} onClick={() => addNav(node.id)}>
            <Link to={{ pathname: node.path, state: { id: node.id } }}>{node.name}</Link>
        </Menu.Item>
    )
    //菜单渲染
    const AppSubMenu = (node: IMenuNode): JSX.Element | null => {
        if (node.isShow && node["children"].length > 0) {
            let filterNode = node["children"].filter(item => item.isShow);
            return filterNode.length > 0 ?
                <SubMenu
                    key={node.id}
                    icon={<FontIcon type={node.icon} />}
                    title={<span>{node.name}</span>}
                >
                    {
                        node.children.map(el => AppSubMenu(el))
                    }
                </SubMenu> : MenuItem(node)
        } else {
            return node.isShow ? MenuItem(node) : null
        }
    }

    return (
        <>
            <Menu
                mode="inline"
                theme="light"
                className="layout-sider-menu"
                onOpenChange={onOpenChange}
                openKeys={openKeys}
                selectedKeys={[selectedMenuKey]}
            >
                {
                    menuData.map(node => AppSubMenu(node))
                    // menusTree.map(node => AppSubMenu(node))
                }
            </Menu>
            <Button onClick={() => toggleMenu()} className={`block  ${cm.collapsed ? 'layout-sider-menu-button--small' : 'layout-sider-menu-button'}`} type="link" shape="round" icon={<FontIcon type="icon-mulu" />} />
        </>
    )
}


export default VrmMenu;