import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { stockStatisticService, IOccupyFidles, IFindBySorderSumDetail } from "../stockStatisticService"
import useGlobalModel from "@/model/globalModel";
import { transPgToPagination } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
interface IOccupyStocType {
    visible: boolean,
    onCancel: () => void,
    materialCode: string
}
export const PinModel = ({
    visible,
    materialCode,
    onCancel,
}: IOccupyStocType) => {
    const { shopAndOrg } = useGlobalModel();
    const { getTreeNodeName } = useDictModel();
    const [searchParam, setSearchParam] = useState<IOccupyFidles>({})
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [dataSource, setDataSource] = useState<IFindBySorderSumDetail[]>([]);

    useEffect(() => {
        if (visible) {
            loadData(initPgCfg, { materialCode })
            setSearchParam({ materialCode })
        }
    }, [visible])
    const loadData = async (page?: ISearchPage, searchValue: IOccupyFidles = searchParam) => {
        let searchParams = {
            // ...searchValue,
            // pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            // pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            materialCode
        }
        let { retData } = await stockStatisticService.findBySorderSum(searchParams)
        // let { records, ...pg } = retData
        // setPgCfg(transPgToPagination(pg));
        setDataSource(retData);
    }
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'saleOrderCode',
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'saleOrderType',
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
        }, {
            title: '配件名称',
            width: 180,
            dataIndex: 'materialName',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        // }, {
        //     title: '系统库存',
        //     width: 120,
        //     dataIndex: 'inventoryNum',
        }, {
            title: '销钉数量',
            width: 120,
            dataIndex: 'num',            
        }
    ]
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title="销钉明细查看"
        width={1000}
        bodyHeight={700}
        showFooter={false}
    >
        <YhBox title={""}>
            <Table
                style={{ cursor: "pointer" }}
                columns={columns}
                dataSource={dataSource}
                // pagination={{
                //     ...pgCfg,
                //     position: ["bottomCenter"],
                //     showTotal: () => `共${pgCfg.total}条`,
                //     onChange: (pageNum, pageSize) => {
                //         loadData({ pageNum, pageSize }).then();
                //     }
                // }}
                pagination= {false}
                scroll={{ x: 'max-content', y: 520 }}
            >
            </Table>
        </YhBox>

    </YHModal>
}