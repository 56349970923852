import React from "react";
import { useTechnicianModel } from "../technicianModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";


export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useTechnicianModel();
    const PageHeaderTitle = "维修技师"

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "技师名称",
            fieldName: "name",
            size: "small",
        },
        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "联系电话",
            fieldName: "phone",
            size: "small",
        },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "状态",
            fieldName: "enable",
            size: "small",
            options: [{ label: "开启", value: 1 }, { label: "关闭", value: 0 }]
        }
    ]
    return (
        <YhPageHeader
            // title="供应商管理"
            size="small"
            title={PageHeaderTitle}
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}