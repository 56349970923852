import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/lib/table";
import { IDetailsFindPageField, IStoreInventoryDetail } from "../stock/delivery/stockOutDispatchType";
import { stockOutDispatchService } from "../stock/delivery/stockOutDispatchService";
enum ShopTypeEnum {
    VRM,
    SCM,
    BCMS
}
interface IMaterialProps {
    materialCode: string,
    materialName: string,
}
export const ShopStock = ({
    materialCode,
    materialName
}: IMaterialProps) => {
    const [dataSource, setDataSource] = useState<IStoreInventoryDetail[]>([])
    const [shopType, setShopType] = useState<ShopTypeEnum>(ShopTypeEnum.BCMS)
    useEffect(() => {
        setShopDataSource(ShopTypeEnum.BCMS)
    }, [])
    const setShopDataSource = async (shopTypes: ShopTypeEnum) => {
        setShopType(shopTypes)
        let data: IStoreInventoryDetail[] = [];
        switch (shopTypes) {
            case ShopTypeEnum.SCM:
                const { retData: scmRetData } = await stockOutDispatchService.scm2Inventory(materialCode);
                data = scmRetData;
                break;
            case ShopTypeEnum.VRM:
                const { retData: vrmRetData } = await stockOutDispatchService.vrm2Inventory(materialCode);
                data = vrmRetData;
                break;
            case ShopTypeEnum.BCMS:
                const { retData: bcmRetData } = await stockOutDispatchService.bcmInventory(materialCode);
                data = bcmRetData;
                break;
            default:
                break;
        }
        setDataSource(data)
    }
    const action = <>
        <Button
            className={`mr-5 ${shopType === ShopTypeEnum.SCM ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => setShopDataSource(ShopTypeEnum.SCM)}
        >供应链系统</Button>
        <Button
            className={`mr-5 ${shopType === ShopTypeEnum.VRM ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => setShopDataSource(ShopTypeEnum.VRM)}
        >云修系统</Button>
        <Button
            className={`mr-5 ${shopType === ShopTypeEnum.BCMS ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => setShopDataSource(ShopTypeEnum.BCMS)}
        >客车系统</Button>
    </>
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        },
        {
            title: '从属门店',
            width: 120,
            dataIndex: 'shopName',
        },
        {
            title: '库存',
            width: 80,
            dataIndex: 'inventoryNum',
        },
        {
            title: '占用库存',
            width: 80,
            dataIndex: 'inventoryOccupied',
        },
        {
            title: '可用库存',
            width: 80,
            dataIndex: 'availableStock',
        },
    ]
    return <YhBox title={action}>
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 'max-content', y: 550 }}
            pagination={false}
        />

    </YhBox>
}