import React, { FC } from "react";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useSaleSoDetailModel } from "./saleSoDetailModel";
import { Description } from "@/components/YhBox/Description";
import { useDictModel } from "@/views/base/dict/dictModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { debounce } from "lodash";

export const TopButs: FC<RouterParamType> = ({ id, mode }) => {
    const { setSettleVisible, setWsConfirmModal,
        detailDataSource,
        detailAddForm,
        detailLookList,
        detailEditForm,
        detailLookForm,
        topButsControl,
        onTransfer,
    } = useSaleSoDetailModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onWhConfirm = () => {
        if (detailDataSource.length != 0 && mode === "edit") {
            setWsConfirmModal(true)
        }
        else if (detailLookList.length !== -1 && mode === "look") {
            setWsConfirmModal(true)
        } else {
            globalPrompt("message", { type: "error", text: `请添加商品详情` })
        }
    }
    const onSettle = () => {
        setSettleVisible(true)
    }
    const onTrunSaleRo = async () => {
        let retData = await onTransfer()
        if (retData) {
            replaceNav(selectedNavId, "5-3")
            message.success("转接销售退货单成功")
        }
    }
    const action = mode === "edit" ? <>

        <YhAuth resCode={ResourceAuthEnum.S_S_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onSettle}
                disabled={topButsControl || detailEditForm.status === "sale.settle"}
            >确认结算</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_S_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onWhConfirm}
                disabled={topButsControl || detailEditForm.status === "sale.stock-io" || detailEditForm.status === "sale.end"}
            >库房确认</Button>
        </YhAuth>
    </> : mode === "look" ? <>
        <YhAuth resCode={ResourceAuthEnum.S_S_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                // disabled={topButsControl}
                onClick={onSettle}
            >结算查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_S_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                // disabled={topButsControl}
                onClick={onWhConfirm}
            >出库查看</Button>
        </YhAuth>
    </> : false;

    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatu: IDescription[] = [
        {
            label: "销售单",
            value: typeTitle[mode]?.saleCode as string ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}