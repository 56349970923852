import React from "react";
import { useInventorySituationModel } from "../inventorySituationModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel from "@/model/globalModel";



export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useInventorySituationModel();
    // const { getSelectedDictOne } = useDictModel(() => []);
    const { whDs } = useGlobalModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "盘库单号",
            fieldName: "code"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品编号",
            fieldName: "materialCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "差异原因",
            fieldName: "differenceReason"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "仓库",
            fieldName: "warehouseCode",
            options: whDs
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["createStartTime", "createEndTime"],
            placeholder: ["制单起始日期", "制单结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },

    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品名称",
            fieldName: "materialName"
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["startTime", "endTime"],
            placeholder: ["完成起始日期", "完成结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "报废原因",
            fieldName: "scrapReason"
        },
        {
            type: SearchTypeEnum.CheckBox,
            placeholder: "差异数量>0",
            fieldName: "differenceNum",
        },
        {
            type: SearchTypeEnum.CheckBox,
            placeholder: "报废数量>0",
            fieldName: "scrapNum",
        },
    ]
    return (
        <YhPageHeader
            title="库存盘点情况"
            size="small"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}