import React, { useEffect, useState } from "react";
import { Table, Button, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { saleSoService } from "../sale/so/saleSoService";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { transPgToPagination, momentFormat } from "@/utils/utils";
import { useDictModel } from "../base/dict/dictModel";
import { commonModuleService, IUserDetail, IUseStatistics, IUserSearch } from "./commonModuleService"
import { convertRes2Blob } from "@/utils/exportFile";
interface IConSumeHistoryType {
    visible: boolean,
    name: string,
    onCancel: () => void
}
export const ConsumeHistoryModel = ({
    visible,
    name,
    onCancel,
}: IConSumeHistoryType) => {
    const initDesc = {
        amount: 0,
        totalNum: 0,
        inTotal: 0
    }
    const { shopAndOrg } = useGlobalModel();

    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [dataSource, setDataSource] = useState<IUserDetail[]>([]);
    const [descTotal, setDescTotal] = useState<IUseStatistics>(initDesc);
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [searchParam, setSearchParam] = useState<IUserSearch>({})

    useEffect(() => {
        if (visible) {
            loadData(initPgCfg, { userName: name })
            setSearchParam({ userName: name })
        }
    }, [visible])
    const loadData = async (page?: ISearchPage, searchValue: IUserSearch = searchParam) => {
        let searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await commonModuleService.userPage(searchParams);
        const { records, statistics, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)

    }
    const desc: IDescription[] = [
        {
            label: "应收金额",
            value: descTotal.amount,
            color: DescriptionColorEnum.red
        },
    ]
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '客户名称',
            width: 120,
            dataIndex: 'userName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'tradeCode',
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'tradeTypeName',
        }, {
            title: '单据日期',
            width: 120,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '应收金额',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '制单人',
            width: 120,
            dataIndex: 'billingClerk',

        }
    ]
    const onExport = async () => {
        const searchParams = {
            ...searchParam,
            ...shopAndOrg,
        }
        let result = await commonModuleService.stockIoExport(searchParams);
        convertRes2Blob(result)
    }
    const title = <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={onExport}
    >导出</Button >
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title="消费记录"
        width={1000}
        bodyHeight={700}
        showFooter={false}
    >
        <YhBox title={title} descriptions={desc}>
            <Table
                style={{ cursor: "pointer" }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize }).then();
                    }
                }}
                scroll={{ x: 'max-content', y: 520 }}
            >
            </Table>
        </YhBox>

    </YHModal>
}