import React from "react";
import { YHModal } from "@/components/YHModal";
import { useHomeModel } from "./homeModel";
import { Checkbox, message } from 'antd';
export const NavigationModel = () => {
    const {
        visible, setVisible,
        navOptions, setNavOptions,
        checkValues, setCheckValues,
        setUpdate
    } = useHomeModel()
    const onCancel = () => {

        setVisible(false)
    }
    const onOk = async () => {
        if (checkValues.length < 4) {
            return message.warning("请您最少选择4项")
        }
        let retData = await setUpdate()
        if (retData) {
            onCancel()
        }
        // setVisible(false)
    }
    const onChange = (checkedValues: any[]) => {
        console.log(checkedValues)
        setCheckValues(checkedValues)
        if (checkedValues.length >= 9) {
            let newNavOptions = navOptions.map(item => {
                return {
                    ...item,
                    disabled: checkedValues.indexOf(item.value) === -1 ? true : false
                }
            })
            console.log(newNavOptions)
            setNavOptions(newNavOptions)
            message.warning("最多选择9项")
        } else {
            setNavOptions(navOptions.map(item => ({ ...item, disabled: false })))
        }
    }
    return (
        <YHModal
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            title="快捷导航自定义"
            width={540}
            bodyHeight={300}
            showFooter={true}
        >
            <div style={{ marginLeft: "25px", marginTop: "20px" }}>
                <Checkbox.Group
                    options={navOptions}
                    defaultValue={checkValues}
                    onChange={onChange}
                />
            </div>

        </YHModal>
    )
}