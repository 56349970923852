import React, { useState } from "react";
import { Table, message } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { useStockStatisticModel } from "../stockStatisticModel"
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { IStockStatisticDetail } from "../stockStatisticService"
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import { ISelectOption } from "@/components/YhFormBox/Types";
import { warehouseService } from "@/views/base/warehouse/warehouseService";
import { ColumnsType } from "antd/lib/table";
import { globalPrompt } from "@/components/message";
import { formatNum, formatIntPoint } from "@/utils/utils";
interface IOccupyStocType {
    visible: boolean,
    onCancel: () => void,
}
export const RelocationModel = ({
    visible,
    onCancel,
}: IOccupyStocType) => {
    const {  warehouseList } = useGlobalModel();
    const { relocationList, setRelocationList, relocation } = useStockStatisticModel()
    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([])
    const wsChanges = async (value: string, option: ISelectOption, record: IStockStatisticDetail) => {
        let params = {
            pageSize: 1000,
            pageNum: 1,
            warehouseCode: value,
        }
        const { retData } = await warehouseService.shelfAllPage(params)
        // const { records, ...pg } = retData;
        const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
        setShelfAllList(newRecords)
    }
    const columns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: string, r: any, i: number) => i + 1
        }, {
            title: '配件编号',
            width: 120,
            dataIndex: 'materialCode',
        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
        }, {
            title: '原始仓库',
            width: 140,
            dataIndex: 'warehouseName',

        }, {
            title: '原始货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '可转库存',
            width: 100,
            dataIndex: 'availableStock',
        }, {
            title: '转移仓库',
            width: 120,
            dataIndex: 'warehouseCodeNew',
            editable: true,
            editType: "select",
            options: transOptions(warehouseList?.filter((item:any)=> item.type === "warehouse-category.big")),
            onSelectChange: wsChanges,
            // render: (text: string,render:any) =>  render.warehouseNameNew
            render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '转移货位',
            width: 120,
            dataIndex: 'shelfCodeNew',
            editable: true,
            editType: "select",
            options: transOptions(shelfAllList),
            render: (text: string) => text ?? "请选择货位",
        }, {
            title: '转移库存',
            width: 120,
            dataIndex: 'relocationNum',
            editable: true,
        }
    ]
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IStockStatisticDetail, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = (row: IStockStatisticDetail) => {
        let { warehouseCodeNew, shelfCodeNew, relocationNum, availableStock } = row
        relocationNum = +formatNum(relocationNum)
        availableStock = +formatNum(availableStock)

        let newRow = {
            ...row,
            warehouseNameNew: getFindItem(warehouseCodeNew as string, warehouseList)?.name,
            shelfIdNew: getFindItem(shelfCodeNew as string, shelfAllList)?.id,
            relocationNum: +formatIntPoint(relocationNum, availableStock, "转移库存>=可转库存")
        }
        const index = relocationList.findIndex((item) => row.materialCode === item.materialCode);
        const item = relocationList[index];
        relocationList.splice(index, 1, { ...item, ...newRow });
        setRelocationList([...relocationList])
    }
    const onOK = async () => {
        let materialNames: string[] = []
        await relocationList.forEach(item => {
            if (!!!item.shelfCode && !!!item.warehouseCode) {
                materialNames.push(item.materialName as string)
            }
        })
        if (materialNames.length === 0 && relocationList.length != 0) {
            let retData = await relocation()
            if (retData) {
                onCancel()
                message.success("移库成功")
            }
        } else {
            await globalPrompt("message", { type: "error", text: `请选择${materialNames.toString()}的仓库与货位` })
        }

    }
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        onOk={onOK}
        title="移库"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title={""}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                style={{ cursor: "pointer" }}
                columns={newSelectColumns() as ColumnsType<IStockStatisticDetail>}
                dataSource={relocationList}
                scroll={{ x: 'max-content', y: 520 }}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
            >
            </Table>
        </YhBox>

    </YHModal>
}