import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useBillMonitorModel } from "../billMonitorModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/ScmResourceAuthEnum";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";

export const DataBox = () => {
    const { exportBillMonitor, descTotal } = useBillMonitorModel();

    const action = <>
        {/* <YhAuth resCode={ResourceAuthEnum.STT_SPS_EX}> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportBillMonitor()}
        >导出</Button>
        {/* </YhAuth> */}
    </>;
    const desc: IDescription[] = [

        {
            label: "单数差异",
            value: descTotal.diffNum,
            color: descTotal.diffNum == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.diffNum != 0
        },
        {
            label: "状态差异",
            value: descTotal.diffStatus,
            color: descTotal.diffStatus == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.diffStatus != 0
        },
        {
            label: "金额差异",
            value: descTotal.diffAmount,
            color: descTotal.diffAmount == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.diffAmount != 0
        },

    ]
    return (
        <YhBox title={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}