import React, { useEffect } from "react";
import { Table,Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEntityModel } from "../entityModel";
import { IEntityDetail } from "../entityType";




export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useEntityModel();

    const columns: ColumnsType<IEntityDetail> = [
        {
            title: '序号',
            width: 60,
            render: (text, record, index) => `${index + 1}`
        }, {
            title: '编码',
            width: 160,
            dataIndex: 'code',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '名称',
            width: 200,
            dataIndex: 'name',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '分支',
            width: 80,
            dataIndex: 'branch',
        }, {
            title: '版本',
            width: 80,
            dataIndex: 'ver',
        }, {
            title: '法人',
            width: 160,
            dataIndex: 'corporation',
        }, {
            title: '联系电话',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '注册地址',
            width: 160,
            dataIndex: 'address',
        }, {
            title: '开户行',
            width: 200,
            dataIndex: 'bank',
        }, {
            title: '银行账户',
            width: 150,
            dataIndex: 'bankAccount',
        }, {
            title: '状态',
            width: 100,
            dataIndex: 'enable',
            render: (v) => v === 1 ? "可用" : "不可用"
        },
        {
            align: "center",
            width: 150,
            title: '备注',
            dataIndex: 'note',
        },
    ];
    useEffect(() => {
        loadData()
    }, []);

    //点击行选高亮
    const onRow = (key: string) => {
        let findIndex = selectedRowKeys.indexOf(key);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(key);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.key as string}
            rowSelection={{
                selectedRowKeys: selectedRowKeys, //记录选中
                onChange: (selectedRowKeys: React.Key[], selectedRows: IEntityDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                }
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.key as string)
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
