
import React, { FC } from "react";
import { Table, } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/lib/table";
import { RouterParamType } from "@/model/navModel";
import { useDeliveryDetailsModel } from "./deliveryDetailsModel";

export const WarehousingList: FC<RouterParamType> = ({ id, mode }) => {
    const {
        warehousingEditList,
        warehousingLookList,
    } = useDeliveryDetailsModel()
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '配件编码',
            width: 180,
            align: "center",
            dataIndex: 'materialCode',
        },
        {
            title: '配件名称',
            width: 180,
            align: "center",
            dataIndex: 'materialName',
        },
        {
            title: '库存数量',
            width: 180,
            align: "center",
            dataIndex: 'inventoryNum',
        },
        {
            title: '入库数量（退料）',
            width: 180,
            align: "center",
            dataIndex: 'num',
        },
        {
            title: '所属仓库',
            width: 180,
            align: "center",
            dataIndex: 'warehouseName',
        },
        {
            title: '所属货位',
            width: 180,
            align: "center",
            dataIndex: 'shelfCode',
        },
    ];
    return (
        <>
            <YhBox>
                <Table
                    rowKey={record => record.detailsId as string}
                    columns={columns}
                    pagination={false}
                    dataSource={mode === "edit" ? warehousingEditList : warehousingLookList}
                    scroll={{ x: 'max-content', y: 400 }}
                />
            </YhBox>
        </>
    )
}