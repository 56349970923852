import React, { useState, useRef } from "react";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { FormBox } from "./FormBox"
import { MaterialTableBox } from "./materialTableBox"
import { MaterialModal } from "./materialModel"
import { TopButs } from "./TopButs"
import { FixedBottom } from "./FixedBottom"
import { WhConfirmDrawer } from "./WhConfirmDrawer"
//import { HistoryModel } from "@/views/historyModel";
import { usePreSaleDetailModel } from "./presaleDetailModel";
import { HistoryModel } from "../../../../detailedInventory/view/HistoryModel";
import {useStockPoDetailModel} from "../../../../detailedInventory/InventoryDetailsModel"

export const PreSaleDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    const {historyRow} = usePreSaleDetailModel()
    const {historyVisible,setHistoryVisible} = useStockPoDetailModel()
    return (
        <>
            {/* <TopButs {...detailsInfo} /> */}
            <FormBox  {...detailsInfo} IFormRef={IFormRef} />
            <MaterialTableBox {...detailsInfo} IFormRef={IFormRef}/>
            <MaterialModal {...detailsInfo}/>
            <FixedBottom {...detailsInfo} />
            <WhConfirmDrawer {...detailsInfo} />
            {/* <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                isTransit={false}
                onCancel={() => setHistoryVisible(false)}
            /> */}
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow?.materialCode as string}
                materialName={historyRow?.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
        </>
    )
}