import React, { useEffect } from "react";
import { Table, Button, DatePicker, Input } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { useStockPoDetailModel } from "../InventoryDetailsModel"
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { momentFormat } from "@/utils/utils";
import { YhBut } from "@/components/YhButton";
const { RangePicker } = DatePicker;

interface IOccupyStocType {
    visible: boolean,
    onCancel: () => void,
}
export const TranRecord = ({
    visible,
    onCancel,

}: IOccupyStocType) => {
    const {
        recordsList,
        recordsSearch,
        records,
        setRecordsSearch,
     } = useStockPoDetailModel()

    useEffect(() => {
        visible && records().then()
    }, [visible])
    //实时详情列表货位数据
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: string, r: any, i: number) => i + 1
        }, {
            title: '配件编号',
            width: 120,
            fixed: "left",
            dataIndex: 'materialCode',
        }, {
            title: '配件名称',
            width: 120,
            fixed: "left",
            dataIndex: 'materialName',
        }, {
            title: '原始仓库',
            width: 140,
            dataIndex: 'warehouseName',

        }, {
            title: '原始货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '可转库存',
            width: 100,
            dataIndex: 'availableStock',
        }, {
            title: '转移仓库',
            width: 120,
            dataIndex: 'warehouseNameNew',
        }, {
            title: '转移货位',
            width: 120,
            dataIndex: 'shelfCodeNew',
        }, {
            title: '转移库存',
            width: 120,
            dataIndex: 'relocationNum'
        }, {
            title: '移库时间',
            width: 120,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }
    ]
    const rightChildren = <div className="flex">
        <RangePicker

            placeholder={['转移日期开始', '转移日期结束']}
            className="ant-picker-round border-r-16 mr-10"
            format={"YYYY-MM-DD"}
            value={!recordsSearch.startTime || !recordsSearch.endTime ? null : [moment(recordsSearch.startTime), moment(recordsSearch.endTime)]
            }
            // onChange={(a: any, arr: string[]) => setrecordsSearch({ ...recordsSearch, startTime: arr[0].replace(/\s+/g, "T"), endTime: arr[1].replace(/\s+/g, "T") })}
            onChange={(a: any, arr: string[]) => setRecordsSearch({ ...recordsSearch, startTime: arr[0] ? arr[0] + 'T00:00:00' : "", endTime: arr[1] ? arr[1] + 'T23:59:59' : "" })}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={recordsSearch.materialCode}
            style={{ width: "250px" }}
            onChange={(e) => { setRecordsSearch({ ...recordsSearch, materialCode: e.target.value }) }}
            placeholder="配件编码"
        />
        < Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => records()}> 检索</Button >

    </div>
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title="转移记录"

        width={1000}
        bodyHeight={700}
        showFooter={false}
    >
        <YhBox title={<></>}
            rightChildren={rightChildren}
        >
            <Table
                style={{ cursor: "pointer" }}
                columns={columns}
                dataSource={recordsList}
                scroll={{ x: 'max-content', y: 520 }}
                pagination={false}
            >
            </Table>
        </YhBox>

    </YHModal>
}