import React, { useRef, useEffect, useState } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils";
import { usePurchasePoDetailsModel } from "./purchasePoDetailsModel";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { IVehicleFields } from "@/views/base/customerFiles/vehicle/vehicleType";
import { vehicleService } from "@/views/base/customerFiles/vehicle/vehicleService";
import { globalPrompt } from "@/components/message";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import useNavModel from "@/model/navModel";
//返回列表韦options的格式
export const vehicleOption = (list: any[]) => {
    return list.length != 0 ? list.map(item => ({ label: item.label, value: item.value })) : []
}
// 返回对应车辆牌照的车辆所有信息
export const getVehicleItem = (licensePlate: string, list: any[]) => {
    return list.find(item => item.licensePlate === licensePlate)
}

export function ChangeSaleDrawer() {
    const { beShopDs, CustomerData, vehicleData } = useGlobalModel();
    const { getSelectedDictOne } = useDictModel();
    const [vehicleAll, setVehicleAll] = useState<IVehicleFields[]>([])
    const [changeSaleVal, setChangeSaleVal] = useState<object>({})
    const { selectedNavId, replaceNav, findNav } = useNavModel();

    const { changeSaleDrawer: visible, detailEditForm, serChangeSaleDrawer, insertSaleDetail, getDetailEditOne } = usePurchasePoDetailsModel();
    const formRef = useRef<IYhFormRef>();

    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        setChangeSaleVal(changedValues)
    }

    const onCancel = () => {
        serChangeSaleDrawer(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(async res => {
            console.log(res)
            let { purchaseCode, ...params } = res
            try {
                let retData = await insertSaleDetail({ ...params, saleOrderCode: purchaseCode, favorableAmount: 0 })
                message.success("转接成功");
                onCancel()
                replaceNav(selectedNavId, "5-2")
            } catch (error) {
                onCancel()
            }
        }).catch(err => {
            // message.warning(getFormValidatorErrText(err));
        })
    }
    useEffect(() => {
        visible && getVehicleAllList()
    }, [visible])

    const getVehicleAllList = async (param?: IVehicleFields) => {
        let params = {
            ...param,
            pageSize: 1000,
            pageNum: 1
        }
        const { retData } = await vehicleService.page(params)
        const { records } = retData
        if (records.length === 0) { globalPrompt("message", { text: "该客户下没有车辆，点击左侧车辆牌照去新增吧!", type: "warning" }) }
        let newRecords = records.map(item => {
            return {
                ...item,
                label: item.licensePlate,
                value: item.licensePlate
            }
        })
        setVehicleAll(newRecords)
    }
    const onFinish = (val: any) => {

    }
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {
            let { name, code, contact, phone, address, categoryCode, categoryName } = getFindItem(value, CustomerData)
            option && onChange({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address,
                customerTypeCode: categoryCode,
                customerTypeName: categoryName,
                licensePlate: null,
                vehicleTypeCode: null,
                vehicleTypeName: null,
                vehicleModel: null,
                vin: null
            });
            option && getVehicleAllList({ customerName: option.label })
        }

    }
    //客户类型
    const customerTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ customerTypeName: option.label, customerTypeCode: option.value });
    }
    //车辆类型
    const vehicleTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ vehicleTypeName: option.label, vehicleTypeCode: option.value });
    }
    //车辆牌照
    const vehicleChange = (value: string, option: ISelectOption) => {
        let VehicleIList = vehicleAll
        if (value && option) {
            let { customerName, customerCode, licensePlate, brandCode, brandName, categoryCode, categoryName, phone, contact, address, vin, typeCode, typeName, model } = getVehicleItem(value, VehicleIList)
            option && onChange({
                customerName,
                customerCode,
                contact,
                phone,
                address: address,
                vin,
                licensePlate,
                vehicleTypeCode: categoryCode,
                vehicleTypeName: categoryName,
                vehicleModel: model,
                customerTypeCode: typeCode,
                customerTypeName: typeName,
            });
        }
    }
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    selectedNavId,
                }
            },
            true)
    }
    const goVehicle = () => {
        replaceNav(
            selectedNavId,
            "13-5-2-1",
            {
                id: "", mode: "add", parentInfo: {
                    selectedNavId,
                }
            },
            true)
    }
    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        formValues: { ...changeSaleVal, purchaseCode: detailEditForm.purchaseCode },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购单号",
                fieldName: "purchaseCode",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                allowClear: true,
                showSearch: true,
                options: transOptions(CustomerData),
                onSelectChange: customerChange,
                span: 24,
                rules: [{ required: true, message: "请选择客户名称" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户类型",
                fieldName: "customerTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                onSelectChange: customerTypeChange,
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                span: 24,
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                span: 24,
                rules: [{ required: true, message: "请输入手机号" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goVehicle}>车辆牌照</span>,
                fieldName: "licensePlate",
                allowClear: true,
                showSearch: true,
                options: vehicleOption(vehicleAll),
                span: 24,
                onSelectChange: vehicleChange,
                // rules: [{ required: true, message: "请选择车牌号" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "vehicleTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
                onSelectChange: vehicleTypeChange,
                span: 24,
                // rules: [{ required: true, message: "请输入车辆类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                span: 24,

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "VIN码",
                fieldName: "vin",
                span: 24,
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆类型Name",
                fieldName: "vehicleTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "address",
                hidden: true
            }
        ]
    };
    return (
        <YHDrawer
            {...{
                title: '转销售单',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
