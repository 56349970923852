import React, { useState, useEffect } from "react";
import ReactECharts from 'echarts-for-react';
import { YhBox } from "@/components/YhBox";
import echarts from 'echarts/lib/echarts';
import { Button } from "antd";
import { homeService, ITransitFilder } from "./homeService"
import useGlobalModel from "@/model/globalModel";
export function Content() {
    const { shopAndOrg } = useGlobalModel()
    let { shopId } = shopAndOrg
    const [dayOption, setDayOption] = useState<any>([])
    const [EchartsWeek, setEchartsWeek] = useState<any>([])
    const [EchartsArr, setEchartsArr] = useState<any>([])
    const [preSaleOption, setpreSaleOption] = useState<any[]>([])
    const [SaleOption, setSaleOption] = useState<any[]>([])
    const [preSaleNum, setpreSaleNum] = useState<any>({})
    const [saleNum, setSaleNum] = useState<any>({})
    const [xais, setXais] = useState<string[]>([])

    useEffect(() => {
        homeService.transit(encodeURI(encodeURI(shopId))).then(res => {
            let { retData } = res
            let newXais: string[] = [];
            let newpreSaleOption: any = [];
            let newSaleOption: any = [];
            let newpreSaleNum: any = {};
            let newSaleNum: any = {};
            let newTranoption = retData.map((item: ITransitFilder) => {
                let { name, preSaleAmount, saleOrderAmount, preSaleNum, saleOrderNum } = item
                newXais.push(name);
                newpreSaleOption.push(preSaleNum);
                newSaleOption.push(saleOrderNum);
                newpreSaleNum[name] = preSaleAmount;
                newSaleNum[name] = saleOrderAmount;

            })
            setpreSaleOption(newpreSaleOption);
            setSaleOption(newSaleOption);
            setpreSaleNum(newpreSaleNum);
            setSaleNum(newSaleNum);
            setXais(newXais)
        })
    }, [shopId])
    const tranOption = {
        title: {
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            },
            formatter: (params: any, ticket: any) => {

                let [data1, data2] = params;
                return `
                    <span style="color: #515151">
                        ${data1.name}
                    </span>
                    <br />
                    <span style="color: #FE9025">
                    预售数量：${data1?.data ?? 0 as number}单<br />
                    预售金额：${preSaleNum[data1?.name] ?? 0 as number}元<br />
                    <span>                    
                    <span style="color: #0887FF">
                        销售数量：${data2?.data ?? 0 as number}单<br />
                        销售金额：${saleNum[data2?.name] ?? 0 as number}元<br />
                    <span>
 
                `;
                // 销售数量：${saleNum[data2?.name] ?? 0 as number}单<br />
            },
            textStyle: {
                fontSize: 12
            }
        },
        legend: {
            data: ["预售", "销售"],
            right: 60,
            top: 0,
        },
        grid: {
            left: 76,
            right: 60,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            // axisTick: { show: false },
            data: xais,
            // axisPointer: {
            //     type: 'shadow'
            // },
            axisLabel: {
                rotate: -25,
            }
        },
        yAxis: {
            type: 'value',
            name: '(单)',
            nameTextStyle: {
                align: "right",
            },
            min: 0,
            // max: 25,
            // interval: 5,
            axisLabel: {
                formatter: '{value}'
            }
        },
        series: [
            {
                name: '预售',
                type: 'line',
                itemStyle: {
                    normal: {
                        color: '#FE9025'
                    }
                },
                data: preSaleOption,
            },
            {
                name: '销售',
                type: 'line',
                itemStyle: {
                    normal: {
                        color: '#0887FF'
                    }
                },
                data: SaleOption,
            }
        ]
    };


    return (
        <div style={{ display: "flex" }}>
            <YhBox
                title={<></>}
                style={{ marginBottom: 10, verticalAlign: 'top', height: 420, width: "100%" }}
            >
                <ReactECharts
                    echarts={echarts}
                    theme="light"
                    option={tranOption}
                    style={{ height: 370 }}
                />
            </YhBox>
        </div>
    )
}