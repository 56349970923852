import React from "react";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDeploymentModel } from "./deploymentModel";

export const DeploymentSearchBox = () => {
    const {
        searchParam, updateSearchParam, initPgCfg, resetSearchParam, loadData
    } = useDeploymentModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "调配单号",
            fieldName: "allocateCode",
            xxl: 3,
        }, 
        {
            type: SearchTypeEnum.Input,
            placeholder: "调出仓库",
            fieldName: "sourceWarehouseName",
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "调入仓库",
            fieldName: "targetWarehouseName",
            xxl: 3,
        },
         {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        // {
        //     type: SearchTypeEnum.RangePicker,
        //     fieldName: "",
        //     placeholder: ["结算开始", "结算结束"],
        //     rangePickerFieldName: ["statSettleTime", "endSettleTime"],
        //     format: "YYYY-MM-DD",
        //     span: 12,
        //     xxl: 6,
        // },    
    ]
    return (
            <YhPageHeader
                title="调配列表"
                justify="end"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
    )
}