import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { useSaleOrderModel } from "../saleOrderModel";
import { SalePrint } from "./SalePrint"

export default () => {
    const {
        print
    } = useSaleOrderModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <SalePrint />}            
        </>
    )
}