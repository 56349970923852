import React, { FC, useEffect, useImperativeHandle } from "react";
import { Form } from "antd";
import { __FormItemsBox as FormItemsBox } from "./FormItemsBox";
import { IYhFormBox } from "./Types";


export const __FormBox: FC<IYhFormBox> = ({ formRef, boxs, ...props }) => {
    const [form] = Form.useForm();
    const { labelCol, labelAlign, disabled, formValues, onChange, onFinish } = props
    const onValuesChange = (changedValues: any, values: any) => {
        // disabled ? form.setFieldsValue(formValues) : onChange?.(changedValues);
        onChange?.(changedValues, values);
    }


    useEffect(() => {
        form.setFieldsValue(formValues);
    }, [formValues]);

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields();
        },
        setFieldsValue: (formValues) => {
            form.setFieldsValue(formValues);
        },
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        resetFields: form.resetFields,
        inst: () => {
            return form;
        }
    }));
    return <Form
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        labelCol={labelCol}
        // wrapperCol={labelAlign}
        labelAlign={labelAlign}
    >
        {
            boxs.map((el, index) => <FormItemsBox key={index} {...el} disabled={el.disabled ?? disabled} />)
        }
    </Form>
}
