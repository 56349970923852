import React, { useEffect, useRef, useState, FC } from "react";
import { Button, message } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { getFormValidatorErrText, switchNum1, formatNum, bigNumber } from "@/utils/utils"
import { useDictModel } from "@/views/base/dict/dictModel";
import { useSaleDcDetailsModel } from "./saleAllotDetailsModel";
import { IBCMSaleDcFormFields } from "../../allotType";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { debounce } from "lodash";
import { saleDcService } from "../../allotService";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { globalPrompt } from "@/components/message";

export const FormBox: FC<RouterParamType> = ({ id, mode }) => {
    const formRef = useRef<IYhFormRef>();
    const { fetchFmsShopList } = useGlobalModel();
    const {
        editFormVal, addFormVal,
        editDesc, addDesc, outWhConfirm,
        saveBtnLoading,
        btnContral,
        setPrint,
        insertSDc,
        updateSDc,
        getSDcDetailOne,
        updateEditFormVal,
        updateAddFormVal,
        resetEditCatch,
        resetAddCatch,
        verifyGoods,
        rejectDc,
        exportSDcdetail,
        setSaveBtnLoading,
        setBtnContral,
        setAddGoodsList,
        addGoodsList
    } = useSaleDcDetailsModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { getSelectedDictOne } = useDictModel(() => []);
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮

    //编辑时， 没有缓在调用详情接口    //回显对应门店核算单位的实体
    useEffect(() => {
        if (mode === "edit") {
            if (!editFormVal.id) {
                getSDcDetailOne(id).then(res => {
                    getShopOutUnitDetail(res.outShopCode)
                    getShopInUnitDetail({ label: res.shopName, value: res.shopCode }, true)
                });
                return;
            }
            editFormVal.shopCode && getShopInUnitDetail({ label: editFormVal.shopName ?? "", value: editFormVal.shopCode }, true);
            editFormVal.outShopCode && getShopOutUnitDetail(editFormVal.outShopCode);
        }
        if (mode === "add") {
            addFormVal.shopCode &&  getShopInUnitDetail({ label: addFormVal.shopName ?? "", value: addFormVal.shopCode }, true);
            addFormVal.outShopCode && getShopOutUnitDetail(addFormVal.outShopCode);
        }
        return () => {
            setBtnContral(false)
        }
    }, [mode]);

    //关闭页签的时候清除对应缓存
    useEffect(() => {
        return () => {
            !findNav("5-4-1") && resetAddCatch()
            !findNav("5-4-2") && resetEditCatch();
            setBtnContral(false)
        }
    }, [selectedNavId])

    //监听商品变化的时候 设置表单金额
    useEffect(() => {
        let values: IBCMSaleDcFormFields = formRef.current?.getFieldsValue();
        setFormAmount(values);
    }, [addDesc, editDesc])

    //保留缓存及回显表单
    const saveCache = (val: { [key: string]: any }, isFormItem: boolean = true) => {
        mode === "edit" ? updateEditFormVal(val) : updateAddFormVal(val);
    }

    /**
  * 表单或者商品操作
  */
    const setFormAmount = (values: IBCMSaleDcFormFields, changedValues?: { [key: string]: any }) => {
        // debugger;
        let { amountAll, actualAmount } = mode === "edit" ? editDesc : addDesc;
        let {
            favorableAmount, //整单优惠
            discountRate, //整单下浮
            aggregateAmount,  //应付金额
            paidAmount,   //已付金额
            discountTotalAmount, //优惠总额
        } = values;
        discountRate = switchNum1(discountRate, "0");
        favorableAmount = switchNum1(favorableAmount, "0");
        actualAmount = +formatNum(actualAmount);
        amountAll = +formatNum(amountAll);
        //应付金额=sum(总计)*（1-整单下浮）-整单优惠）
        //let isZero = +formatNum(amountAll * (1 - +discountRate) - +favorableAmount);
        let isZero=+bigNumber.toFixed(bigNumber.times(amountAll,bigNumber.minus(bigNumber.minus(1,discountRate),favorableAmount)),2)
        aggregateAmount = isZero < 0 ? `-${isZero}` : isZero;

        //优惠总额 = 所有商品的合计 - 总计 - 整单优惠 - 整单下浮优惠
        discountTotalAmount = formatNum(actualAmount - isZero);

        paidAmount = formatNum(paidAmount);
        // setWsConfirmDisable(true);
        saveCache(changedValues ? changedValues : { discountTotalAmount, aggregateAmount, favorableAmount, discountRate, paidAmount });
    }

    //表单change
    const onChange = (changedValues: { [key: string]: any }, values: any) => {
        if ("favorableAmount" in changedValues || "discountRate" in changedValues) {
            setFormAmount(values);
        } else {
            setFormAmount(values, { ...changedValues });
        }
        setBtnContral(true);
    }

    //表单提交
    const onSubmit = () => {
        if (!verifyGoods(mode)) return;
        // if(!formComputeRule(mode)) return;

        formRef.current?.validateFields().then((val: any) => {
            mode === "edit" ?
                updateSDc(val) :
                insertSDc(val).then(res => {
                    if (res) {
                        resetEditCatch();
                        replaceNav("5-4-1", "5-4-2", { id: res, mode: "edit" })
                    }
                });
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    };

    //回显选中调出门店带出来的数据
    const [outUnitBeList, setOutUnitBeList] = useState<ISelectOption[]>([]);  //调出门店对应的关联实体
    const getShopOutUnitDetail = async (shopId: string) => {
        let { retData } = await saleDcService.queryShopUnitBe(shopId as string);
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setOutUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
                if(!addFormVal.outBentityCode&&mode=='add'){
                   saveCache({ outBentityName: retData.bentityList[0].name, outBentityCode: retData.bentityList[0].code });
                }
            } else {
                setOutUnitBeList([]);
                message.warning("调出门店对应的核算单位没有关联实体")
            }
        } else {
            setOutUnitBeList([]);
            message.warning("调出门店没有关联的核算单位");
        }
    }

    const [inUnitBeList, setInUnitBeList] = useState<ISelectOption[]>([]);  //调入门店对应的关联实体
    //回显选中调入门店带出来的数据
    const getShopInUnitDetail = async (option: ISelectOption, echo: boolean = false) => {
        let { retData } = await saleDcService.queryShopUnitBe(option.id as string ?? option.value as string);
        let data = {
            shopCode: option.value,
            shopName: option.label,
            shopId: option.id,
            transferTypeName: option.branch === "scm" ? "备品调拨" : "其它调拨",
            transferTypeCode: option.branch === "scm" ? DictCodeEnmu.TRANSFER_TYPE_BP : DictCodeEnmu.TRANSFER_TYPE_OTHERS,
            bentityName: undefined,   //每次选中的时候对应的实体置空
            bentityCode: undefined,
            contact: undefined,
            phone: undefined,
            branchIn: option.branch,
            branchOut:'bcm'
        }
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setInUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
            } else {
                setInUnitBeList([]);
                message.warning("调入门店对应的核算单位没有关联实体")
            };
            //回显时不用执行一下操作
            if (echo) return;
            if(!addFormVal.bentityCode&&mode=='add'){
                saveCache({                     
                    ...data,
                    contact: retData.contact,
                    phone: retData.phone,
                    bentityName:retData.bentityList[0].name, 
                    bentityCode:retData.bentityList[0].code  
                });
             }else{
                saveCache({
                    ...data,
                    contact: retData.contact,
                    phone: retData.phone,
                });
             }
        } else {
            saveCache(data);
            setInUnitBeList([]);
            message.warning("调入门店没有关联的核算单位");
        }
    }
    function clearGoodsList() {
        if (mode === 'add') {
            if (addGoodsList.length > 0) globalPrompt('message', { text: '切换成功!需要重新选择配件信息', type: 'warning' })
            setAddGoodsList([])
        }
    }
    //调入门店
    const inShopChange = (value: string, option: ISelectOption) => {
        if (option && option.id) {
            getShopInUnitDetail(option)
        }
        clearGoodsList()
    }

    //调出实体
    const outBeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ outBentityName: option.label, outBentityCode: option.value });
        clearGoodsList()
    }
    //调入实体
    const inBeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ bentityName: option.label, bentityCode: option.value });
        clearGoodsList()
    }
    //调拨类行
    const transferTypeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ transferTypeName: option.label, transferTypeCode: option.value });
    }

    const onRefresh = debounce(() => {
        getSDcDetailOne(id).then(res => message.success("已刷新"));
    }, 500);

    const whConFirm = () => {
        if (!verifyGoods(mode)) return;
        formRef.current?.validateFields().then((val: any) => {
            outWhConfirm(val)
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const rightChildren = <>
        {mode === "edit" && <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportSDcdetail()}
            >导出</Button>
            <YhAuth resCode={ResourceAuthEnum.S_DC_ED_PRINT}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setPrint(true)}
                >打印</Button>
            </YhAuth>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onRefresh}
            >刷新</Button>
            <YhAuth resCode={ResourceAuthEnum.S_DC_ED_REJECT}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    hidden={editFormVal.status == DictCodeEnmu.TRANSFER_STATUS_OUT || btnContral}
                    onClick={() => rejectDc()}
                >驳回</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.S_DC_ED_DC}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    disabled={editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT || btnContral}
                    onClick={debounce(() => { whConFirm() }, 500)}
                >调出</Button>
            </YhAuth>
        </>}
        <YhAuth resCode={mode === "edit" ? ResourceAuthEnum.S_DC_ED_SAVE : ResourceAuthEnum.S_DC_AD_SAVE}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                loading={saveBtnLoading}
                onClick={debounce(() => {
                    onSubmit();
                }, 500)}
            >{saveBtnLoading ? '保存中' : '保存'}</Button>
        </YhAuth>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { replaceNav(selectedNavId, "5-4"); setSaveBtnLoading(false) }}
        >返回</Button>
    </>
    const [shopList, setShopList] = useState<ISelectOption[]>([])
    useEffect(() => {
        fetchFmsShopList('').then(arr => {
            setShopList(arr)
        })
    }, [])
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "10px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出单号",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成"
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: '调入门店',
                fieldName: "shopCode",
                onSelectChange: inShopChange,
                rules: [{ required: true, message: "请选择调入门店" }],
                disable: mode === "edit",
                // selectfetchList: fetchFmsShopList,
                options:shopList
                // defaultQuery: mode === "edit" ? editFormVal.shopName : addFormVal.shopName
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "整单下浮",
                fieldName: "discountRate",
                disable: mode === "edit" && editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT,
                rules: [{ required: true, message: "该项为必填" }, { pattern: /^0\.[0-9]{1,2}$|^0{1}$|^1{1}$|^1\.[0]{1,2}$/, message: '请输入0-1' }],
                hidden: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "应付金额",
                fieldName: "aggregateAmount",
                disable: true,
                hidden: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: '调出门店',
                fieldName: "outShopName",
                disable: true
                // }, {
                //     type: FormItemTypeEnum.Input,
                //     fieldLabel: "结算类型",
                //     fieldName: "payType",
                //     disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",

                // hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "调拨类型",
                fieldName: "transferTypeCode",
                onSelectChange: transferTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.TRANSFER_TYPE),
                disable: true,
                rules: [{ required: true, message: "请选择调拨类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "调入实体",
                fieldName: "bentityCode",
                options: inUnitBeList,
                onSelectChange: inBeChange,
                disable: mode === "edit" && editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT,
                rules: [{ required: true, message: "请选择调入实体" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "调出实体",
                fieldName: "outBentityCode",
                onSelectChange: outBeChange,
                options: outUnitBeList,
                disable: mode === "edit" && editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT,
                rules: [{ required: true, message: "请选择调出实体" }]
            }, {
                type: FormItemTypeEnum.Other,
                fieldLabel: "",
                fieldName: ""
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "applier",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入员",
                fieldName: "transferIn",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出员",
                fieldName: "transferOut",
                disable: true,
            }, {
                type: FormItemTypeEnum.Other,
                fieldLabel: "",
                fieldName: ""
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单日期",
                fieldName: "applyTime",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入日期",
                fieldName: "transferInTime",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出日期",
                fieldName: "transferOutTime",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "整单优惠",
                fieldName: "favorableAmount",
                disable: mode === "edit" && editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT,
                rules: [{ pattern: /^[0-9]+(.[0-9]{0,2})?$/, message: '请输入数字且可保留两位小数' }],
                hidden: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "已付金额",
                fieldName: "paidAmount",
                disable: true,
                hidden: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "优惠总额",
                fieldName: "discountTotalAmount",
                hidden: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入日期",
                fieldName: "transferInTime",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单人",
                fieldName: "createUser",
                disable: true,
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改人",
                fieldName: "updateUser",
                disable: true,
                hidden: true
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出门店Code",
                fieldName: "outShopCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入门店名称",
                fieldName: "shopName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入门店id",
                fieldName: "shopId",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出门店id",
                fieldName: "outShopId",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出实体名称",
                fieldName: "outBentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调拨类型名称",
                fieldName: "transferTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入分支",
                fieldName: "branchIn",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出分支",
                fieldName: "branchOut",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "状态",
                fieldName: "status",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出单号",
                fieldName: "transferOutCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入单号",
                fieldName: "transferInCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "成本金额",
                fieldName: "costAmount",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "毛利",
                fieldName: "grossProfit",
                hidden: true
            },
        ]
    };



    const poFromConfig: IYhFormBox<IBCMSaleDcFormFields> = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: mode === "edit" ? editFormVal : addFormVal,
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}