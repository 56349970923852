import React, { useState, useEffect } from "react"
import { InfoBox } from "./InfoBox"
import { ActionTypeEnum, useDetailModel } from "./detailModel";
// 检索区
import { StockSearchBox } from "./stock/SearchBox"
import { PreSaleSearchBox } from "./presale/SearchBox"
import { SaleSearchBox } from "./sale/SearchBox"
import { DeploymentSearchBox } from "./deployment/SearchBox"
import { PreWithdrawalSearchBox } from "./preWithdrawal/SearchBox"
import { SaleReturnSearchBox } from "./salesReturn/SearchBox"

//内容区
import { StockDataBox } from "./stock"
import { PreSaleDataBox } from "./presale"
import { SaleDataBox } from "./sale"
import { DeploymentDataBox } from "./deployment"
import { PreWithdrawalDataBox } from "./preWithdrawal"
import { SaleReturnDataBox } from "./salesReturn"
import useNavModel, { RouterParamType } from "@/model/navModel";


// 检索区展示
const DetailSearchBox = () => {
    const { actionType } = useDetailModel()
    switch (actionType) {
        case ActionTypeEnum.STOCK:
            return <StockSearchBox />
        case ActionTypeEnum.PRESALE:
            return <PreSaleSearchBox />
        case ActionTypeEnum.SALE:
            return <SaleSearchBox />
        case ActionTypeEnum.DEPLOYMENT:
            return <DeploymentSearchBox />
        case ActionTypeEnum.PREWITHDRAWAL:
            return <PreWithdrawalSearchBox />
        case ActionTypeEnum.SALESRETURN:
            return <SaleReturnSearchBox />
        default:
            return <></>
    }
}
//内容区展示
const DetailDataBox = () => {
    const { actionType } = useDetailModel()
    switch (actionType) {
        case ActionTypeEnum.STOCK:
            return <StockDataBox />
        case ActionTypeEnum.PRESALE:
            return <PreSaleDataBox />
        case ActionTypeEnum.SALE:
            return <SaleDataBox />
        case ActionTypeEnum.DEPLOYMENT:
            return <DeploymentDataBox />
        case ActionTypeEnum.PREWITHDRAWAL:
            return <PreWithdrawalDataBox />
        case ActionTypeEnum.SALESRETURN:
            return <SaleReturnDataBox />
        default:
            return <></>
    }
}

export const BusBusinessDetail = () => {
    const { actionType,setBusBusiness } = useDetailModel();
    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { id, mode, parentInfo } = detailsInfo;

    useEffect(()=>{
        if(parentInfo){
            setBusBusiness({...parentInfo})
        }
    },[parentInfo])

    return <>
        <InfoBox />
        <DetailSearchBox />
        <DetailDataBox />
    </>
}