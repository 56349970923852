import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IBusBusinessSearch, IBusBusinessDetail, IBusBusinessTotal } from "./busBusinessType"
import { TablePaginationConfig } from "antd/es/table/interface";
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { ISearchPage } from "@/types/ScmTypes";
import { busBusinesstService } from "./busBusinessService"
import { convertRes2Blob } from "@/utils/exportFile";
export const useBusBusinessModel = createModel(function () {
    const { shopAndOrg, } = useGlobalModel()
    const initSearchParam: IBusBusinessSearch = {};
    const initDataSource: IBusBusinessDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        saleNum: 0, // 0,
        saleAmount: 0, // 0,
        preNum: 0, // 0,
        availableStock: 0, // 0,
        inventoryAmount: 0, // 0
        inventoryNum: 0,

    }
    //查询条件
    const [searchParam, setSearchParam] = useState<IBusBusinessSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IBusBusinessDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IBusBusinessDetail>({});
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [descTotal, setDescTotal] = useState<IBusBusinessTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IBusBusinessTotal>(initDesc);
    const [descRows, setDescRows] = useState<IBusBusinessDetail[]>([]);
    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            let { saleNum, saleAmount, presaleNum, availableStock, inventoryAmount, inventoryNum } = item
            // newRowsDescTotal.saleNum += +formatNum(saleNum)
            newRowsDescTotal.saleNum = bigNumber.add(newRowsDescTotal.saleNum, saleNum as number)
            // newRowsDescTotal.saleAmount += +formatNum(saleAmount)
            newRowsDescTotal.saleAmount = bigNumber.add(newRowsDescTotal.saleAmount, saleAmount as number)
            // newRowsDescTotal.preNum += +formatNum(presaleNum)
            newRowsDescTotal.preNum = bigNumber.add(newRowsDescTotal.preNum, presaleNum as number)
            // newRowsDescTotal.availableStock += +formatNum(availableStock)
            newRowsDescTotal.availableStock = bigNumber.add(newRowsDescTotal.availableStock, availableStock as number)
            // newRowsDescTotal.inventoryAmount += +formatNum(inventoryAmount)
            newRowsDescTotal.inventoryAmount = bigNumber.add(newRowsDescTotal.inventoryAmount, inventoryAmount as number)
            // newRowsDescTotal.inventoryNum += +formatNum(inventoryNum)
            newRowsDescTotal.inventoryNum = bigNumber.add(newRowsDescTotal.inventoryNum, inventoryNum as number)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IBusBusinessDetail)
    }, [selectedRowKeys, dataSource])

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        // console.log(updateParam)
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IBusBusinessSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }

    const loadData = async (page?: ISearchPage, searchValue: IBusBusinessSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await busBusinesstService.warehousePage(searchParams);
        const { records, statistics, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        console.log(newPg)
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)
    }
    const exportBusBusiness = async (searchValue: IBusBusinessSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await busBusinesstService.warehouseExport(searchParams);
        convertRes2Blob(result)
    }
    const exportDetailsBusBusiness = async (searchValue: IBusBusinessSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await busBusinesstService.warehouseDetailExport(searchParams);
        convertRes2Blob(result)
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        initPgCfg,
        descTotal,
        selectedRow,
        exportBusBusiness,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDescTotal,
        setSelectedRow,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        exportDetailsBusBusiness,
    }
})