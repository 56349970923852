import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { WhConfirmDrawer } from "./WhConfirmDrawer"
import { useWarehousingModel } from "./WarehousingModel";
export default () => {
    const { wsConfirmModal: visible,} = useWarehousingModel();

    return (
        <>
            <PageHeader />
            <DataBox />
            {visible && <WhConfirmDrawer />}
        </>
    )
}