import { ITreeSelectData } from "@/views/base/dict/dictModel";

export enum SearchTypeEnum {
    'Input' = 'input',
    'Select' = 'select',
    'Datepicker' = 'datepicker',
    'RangePicker' = 'rangePicker',
    'CheckBox' = 'checkBox',
    'FmsRangePicker' = 'fmsRangePicker',
    "TreeSelect" = 'treeSelect'
}

export interface ISearchValue {
    [propName: string]: string | number | [string, string] | object | string[]

}
export interface IFmsSearchValue {
    [propName: string]: string | undefined | [string, string] | { startTime: string, endTime: string }
}

export interface ISearchField {
    fieldName: string;
    type: SearchTypeEnum;
    placeholder?: string | string[];
    showTime?: Object | boolean;
    format?: string;
    xxl?: number;
    span?: number;
    disabled?: boolean;
    options?: { label: string, value: any }[],
    treeData?: ITreeSelectData[],
    rangePickerFieldName?: string[] | ["startTime", "endTime"]  //供应链专用字段
    [key: string]: any


}

type Isize = 'large' | 'middle' | 'small';

export interface ISearchProp {
    title: string | JSX.Element;
    triggerSearch?: boolean,
    searchFields?: ISearchField[],
    searchMoreFields?: ISearchField[],
    searchValues?: ISearchValue,
    showSearch?: boolean,
    onChange?: (arg: ISearchValue) => void
    onFmsChange?: (arg: IFmsSearchValue) => void
    handleReset?: () => void;
    handleSearch?: (params?: object) => void;
    size?: Isize;
    justify?: "start" | "end"
}