import { httpRequest } from '@/utils/HttpRequest'
import { ISearchPage, IPage } from '@/types/ScmTypes'

export interface ISCMInventoryStatisticsModelDetail {
    //商品编码
    materialCode: string
    //商品名称
    materialName: string
    //替换编号
    replaceCode: string
    //仓库编号
    warehouseCode: string
    //仓库名称
    warehouseName: string
    //货位编号
    shelfCode: string
    //库存数量
    inventoryQuantity: number
    //占用数量
    occupyNum: number
    //最新进价
    lastPurchasePrice: number
    //库存成本
    inventoryAmount: number
    //最初入库日期
    lastStockIoTime: number
    //库龄
    overstock: number
    //是否积压 0否 1是
    overstockFlag: number
    //最近一月是否销售 0否 1是
    saleRecent: number
    //最近一年是否销售 0否 1是
    saleRecentYear: number
    //年周转率
    turnoverRate: number
    //积压率
    overstockRate: number
    //销存率
    saleOverstockRate: number
}

//搜索条件
export interface ISCMInventoryStatisticsSearchFields {
    [key: string]: any
}

export interface ISCMInventoryStatisticsSearch extends ISCMInventoryStatisticsSearchFields, ISearchPage { }

//进销存报表
class AllocService {
    async page(searchVo: ISCMInventoryStatisticsSearch) {
        const apiUrl = '/bcm/inventory/overstock/statics'
        return await httpRequest.post<IPage<ISCMInventoryStatisticsModelDetail>>(apiUrl, searchVo)
    }
    async export(searchVo: ISCMInventoryStatisticsSearch) {
        const apiUrl = '/bcm/inventory/overstock/statics/export'
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 })
    }
}

export const allocService = new AllocService()
