import React, { useEffect } from "react";
import { Tree } from "antd";
import { YhBox } from "@/components/YhBox";
import { FormBox } from "./FormBox";
import { EventDataNode, DataNode } from "antd/lib/tree";
import { IoverstockEnum, ISCMSettingDetail } from "../settingsType";
import { useSettingsModel } from "../settingsModel";

export const DataBox = () => {
    const {

        setSelectedNodes, loadPoData, loadSoData, loadRankData } = useSettingsModel()
    const treeData = [
        {
            title: '积压设置',
            key: IoverstockEnum.overStock,
            children: [
                {
                    title: '销售单积压处理',
                    key: IoverstockEnum.overStock_SO,
                },
                {
                    title: '采购单积压提醒',
                    key: IoverstockEnum.overStock_PO,
                },
                {
                    title: '积压等级设置',
                    key: IoverstockEnum.overStock_GRADE,
                },
                {
                    title: '积压件定义',
                    key: IoverstockEnum.overStock_DEFIND,
                },
            ],
        },
    ];
    const onSelect = (selectedKeys: React.Key[], info: {
        event: "select";
        selected: boolean;
        node: any;
        selectedNodes: DataNode[];
        nativeEvent: MouseEvent;
    }) => {
        let selectNode = info.selectedNodes as ISCMSettingDetail[]
        console.log(selectNode)
        setSelectedNodes(selectNode)
    }
    useEffect(() => {
        loadPoData();
        loadSoData();
        loadRankData();
    }, [])
    return (
        <YhBox title="">
            <div className="dict-container flex">
                <div className="dict-list-box">
                    <Tree
                        defaultExpandedKeys={[IoverstockEnum.overStock]}
                        // selectedKeys={}
                        titleRender={(node: any) => `${node.title}`}
                        onSelect={onSelect}
                        treeData={treeData}
                    />
                </div>
                <div className="dict-from-box flex">
                    <FormBox />
                </div>
            </div>
        </YhBox>
    )
}