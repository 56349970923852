import { createModel } from "hox";
import  { useState } from "react";

export const useSummaryCustomerDetailsModel = createModel(function () {
    const [itemModel, setItemModel] = useState<boolean>(false); //维修项目弹框
    const [materialModel, setMaterialModel] = useState<boolean>(false); //维修项目弹框 
    return {
        itemModel,
        materialModel,
        setItemModel,
        setMaterialModel
    }
})