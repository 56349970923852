import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, Dropdown, Menu, message, Upload } from "antd";
import { DeploymentDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useDeploymentModel } from "./deploymentModel";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
import { formatNum } from "@/utils/utils";
import { DeploymentSearchBox } from "./SearchBox"
import { importPropsConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";
import { DownOutlined } from "@ant-design/icons";
export const DeploymentAll = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        exportSaleSo,
        listDownloadTpl,
        loadData,
        detailsExport
    } = useDeploymentModel();
    const { user } = useGlobalModel()
    const { addNav, selectedNavId, replaceNav } = useNavModel();

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.allocateCode as string, mode: "look", parentInfo: { source: "List", selectedNavId } },
            true
        );
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleSo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => addNav('16-4-1', { id: "", mode: "add", parentInfo: { source: "List", selectedNavId } })}
        >
            新增
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        {/* <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/deployment/list/import/${user.username}`, loadData)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload> */}
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportSaleSo()}
        >
            导出
        </Button> */}
        <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => listDownloadTpl().then()}
        >
            下载模板
        </Button> */}
    </>
    const desc: IDescription[] = [
        {
            label: "调配数量",
            value: selectedRowKeys.length === 0 ? descTotal?.num??0 : rowsDescTotal?.num??0,
            color: DescriptionColorEnum.red
        },
        {
            label: "调配金额",
            value: selectedRowKeys.length === 0 ? descTotal?.amount??0 : formatNum(rowsDescTotal?.amount)??0,
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <DeploymentSearchBox />
        <YhBox title="调配记录" action={action} descriptions={desc}>
            <DeploymentDataTable />
        </YhBox>
    </>
}