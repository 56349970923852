import React, { useEffect } from "react";
import { Button } from "antd";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { YhBox } from "@/components/YhBox";
import { ActionTypeEnum, useDetailModel } from "./detailModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { useStockModel } from "./stock/stockModel";




export const InfoBox = () => {
    const { setActionType, actionType, busBusiness } = useDetailModel();
    const { descTotal } = useStockModel();
    const onTabType = (type: ActionTypeEnum) => {
        setActionType(type)
    }
    const classname = (type: ActionTypeEnum) => {
        return 'mr-5 ' + (actionType === type ? 'is-active' : '')
    }

    const action = (
        <>
            <Button
                className={classname(ActionTypeEnum.STOCK)}
                type="link"
                shape="round"
                onClick={() => onTabType(ActionTypeEnum.STOCK)}
            >
                库存信息
            </Button>
            <Button
                className={classname(ActionTypeEnum.PRESALE)}
                type="link"
                shape="round"
                onClick={() => onTabType(ActionTypeEnum.PRESALE)}
            >
                预售记录
            </Button>
            <Button
                className={classname(ActionTypeEnum.SALE)}
                type="link"
                shape="round"
                onClick={() => onTabType(ActionTypeEnum.SALE)}
            >
                销售记录
            </Button>
            <Button
                className={classname(ActionTypeEnum.DEPLOYMENT)}
                type="link"
                shape="round"
                onClick={() => onTabType(ActionTypeEnum.DEPLOYMENT)}
            >
                调配记录
            </Button>
            <Button
                className={classname(ActionTypeEnum.PREWITHDRAWAL)}
                type="link"
                shape="round"
                onClick={() => onTabType(ActionTypeEnum.PREWITHDRAWAL)}
            >
                预退记录
            </Button>
            <Button
                className={classname(ActionTypeEnum.SALESRETURN)}
                type="link"
                shape="round"
                onClick={() => onTabType(ActionTypeEnum.SALESRETURN)}
            >
                销退记录
            </Button>
        </>
    );


    const desc: IDescription[] = [
        {
            label: "库存种数",
            value: descTotal?.total ?? 0,
            color: DescriptionColorEnum.red
        },
        {
            label: "库存种类",
            value: descTotal?.type ?? 0,
            color: DescriptionColorEnum.red
        },
        {
            label: "库存成本（最近）",
            value: descTotal?.latestAmountTotal ?? 0,
            color: DescriptionColorEnum.red
        },
        {
            label: "库存成本（平均）",
            value: descTotal?.averageAmount ?? 0,
            color: DescriptionColorEnum.red
        },
    ]
    return (
        <YhBox
            title={busBusiness.warehouseName}
            descriptions={actionType === ActionTypeEnum.STOCK ? desc : undefined}
            action={action}
        />
    )
}
