import React, { useEffect, useState, FC } from "react";
import { Button, Tooltip, message } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum } from "@/components/YhFormBox/Types";
import useNavModel from "@/model/navModel";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { ITopType } from "@/types/ScmTypes";
import { DetailType } from "@/types/ScmEnums";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const FormBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        detailEditForm,
        detailLookForm,
        setReturnDrawer,
        setDetailType,
        resetEditCatch,
        getDetailEditOne,
        setConsumeHistoryModel,
        setConsumeHistoryVisible,
        setRepairHistoryModel,
        setRepairHistoryVisible
    } = usefollowUpDetailsModel();
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { getSelectedDictOne } = useDictModel();
    const { beShopDs } = useGlobalModel();

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("8-2") && resetEditCatch(mode);
        }
    }, [selectedNavId])

    const rightChildren = <>
        {mode === "edit" &&
            <YhAuth resCode={ResourceAuthEnum.R_VISIT_FILL}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { setReturnDrawer(true); setDetailType("edit") }}
                >填写回访信息</Button>
            </YhAuth>
        }
        {mode === "look" &&
            <YhAuth resCode={ResourceAuthEnum.R_VISIT_FILL}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { setReturnDrawer(true); setDetailType("look") }}
                >查看回访信息</Button>
            </YhAuth>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => replaceNav(selectedNavId, "8")}
        >返回</Button>
    </>
    const typeForm = {
        add: { customerName: "", customerCode: "", licensePlate: "" },
        edit: detailEditForm,
        look: detailLookForm
    }
    const onConsumption = () => {
        let { customerCode, customerName } = typeForm[mode]
        if (!customerCode) {
            return message.warning("请先选择客户")
        }
        setConsumeHistoryVisible(true)
        setConsumeHistoryModel({ customerName: customerName as string })
    }
    const onRepairHistory = () => {
        let { licensePlate } = typeForm[mode]
        if (!licensePlate) {
            return message.warning("请先选择车辆")
        }
        setRepairHistoryVisible(true)
        setRepairHistoryModel({ licensePlate: licensePlate })
    }

    const title = <>
        <span>基础信息</span>
        <Tooltip placement="topLeft" title="消费记录">
            <FontIcon type="icon-xiaofeijilu" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onConsumption}
            />
        </Tooltip>
        <Tooltip placement="topLeft" title="维修历史">
            <FontIcon type="icon-ic_history" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onRepairHistory}
            />
        </Tooltip>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: title,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工单单号",
                fieldName: "maintenanceCode",
                disable: true,
                placeholder: "自动生成",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                rules: [{ required: true, message: "请选择客户" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "送修人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入送修人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "送车电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入送车电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车牌号",
                fieldName: "licensePlate",
                rules: [{ required: true, message: "请输入车牌号" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "VIN码",
                fieldName: "vin",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "工单状态",
                fieldName: "status",
                disable: true,
                options: getSelectedDictOne(DictCodeEnmu.VISIT_STATUS),
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "维修类型",
                fieldName: "serviceNatureCode",
                options: getSelectedDictOne(DictCodeEnmu.REPAIR_TYPE),
                rules: [{ required: true, message: "维修类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "vehicleTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
                // rules: [{ required: true, message: "请选择车辆类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "服务地点",
                fieldName: "serviceLocationCode",
                options: getSelectedDictOne(DictCodeEnmu.SERVICE_ADDRESS),
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算方",
                fieldName: "settlementPartyCode",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                span: 5,
                labelCol: { offset: 1 },
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆里程",
                fieldName: "mileage",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工作小时",
                fieldName: "workingHours",
                hidden: more
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "进厂时间",
                showTime: true,
                fieldName: "enterTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "出厂时间",
                showTime: true,
                fieldName: "deliveryTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "去零",
                fieldName: "favorableAmount",
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售实体",
                fieldName: "bentityCode",
                hidden: more,
                options: beShopDs
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "故障描述",
                fieldName: "faultDesc",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "处理意见",
                fieldName: "faultResult",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "customerAddress",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "待修内容",
                fieldName: "toBeRepaired",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "下次保养时间",
                fieldName: "nextMaintainTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                hidden: more
            },
        ]
    };



    const onChange = (changedValues: { [key: string]: any }) => {
        console.log(changedValues);

    }

    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: true,
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}