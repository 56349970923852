import React from "react";
import { Button, Table } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { useSummaryDetailsModel } from "./summaryDetailsModels";


export const ItemTableBox = () => {
    const { setItemModel } = useSummaryDetailsModel();

    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { setItemModel(true) }}
        >新增项目</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { }}
        >删除项目</Button>
    </>;

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '项目编码',
            width: 120,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '项目名称',
            width: 160,
            dataIndex: 'typeCode',
            fixed: "left",
        }, {
            title: '工时',
            width: 160,
            dataIndex: 'name',
        }, {
            title: '单价',
            width: 160,
            dataIndex: 'contact',
        }, {
            title: '工时费',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '折扣',
            width: 120,
            dataIndex: 'address',
        }, {
            title: '小计',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];

    return (
        <YhBox title="维修项目" rightChildren={action} style={{ marginBottom: "5px" }}>
            <Table
                // rowKey={record => record.id}
                // rowSelection={{
                //     selectedRowKeys: selectedRowKeys, //记录选中
                //     onChange: (selectedRowKeys: React.Key[], selectedRows: ISCMSupplierDetail[]) => {
                //         setSelectedRowKeys(selectedRowKeys as string[]);
                //     }
                // }}
                columns={columns}
                dataSource={[]}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}