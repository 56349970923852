import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { IInventoryDetailsDetail } from "../InventoryDetailsService";
import { useStockPoDetailModel } from "../InventoryDetailsModel";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        setHistoryVisible, setHistoryRow,
        loadData, setSelectedRowKeys, setOccupyStockModel
    } = useStockPoDetailModel();

    const onHistoryModel = (record: IInventoryDetailsDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }

    const columns: ColumnsType<IInventoryDetailsDetail> = [
        {
            title: '序号',
            width: 66,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编码',
            width: 200,
            dataIndex: 'materialCode',
            fixed: "left",
            //render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => onHistoryModel(record)}/>,
        }, {
            title: '商品名称',
            width: 200,
            dataIndex: 'materialName',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            title: '仓库名称',
            width: 200,
            dataIndex: 'storageWarehouseName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '库存数量',
            width: 120,
            dataIndex: 'inventoryNum',

        }, {
            title: '占用库存数量',
            width: 120,
            dataIndex: 'occupyNum',
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title="占用库存查看"><p className="ellipsis click-span" onClick={(e) => {
                e.stopPropagation();
                setOccupyStockModel(record);
                return;

            }}>{text}</p></Tooltip>,
        }, {
            title: '可用库存数量',
            width: 120,
            dataIndex: 'availableNum',
        }, {
            title: '库存金额',
            width: 120,
            dataIndex: 'inventoryAmount',
            render: (text) => text ? `￥${text}` : `￥${0}`,
        }, {
            title: '入库单价',
            width: 120,
            dataIndex: 'price',
            render: (text) => text ? `￥${text}` : `￥${0}`,
        }, {
            title: '入库数量',
            width: 120,
            dataIndex: 'num',
        }, {
            title: '入库金额',
            width: 120,
            dataIndex: 'amount',
            render: (text) => text ? `￥${text}` : `￥${0}`,
        }, {
            title: '采购单价',
            width: 120,
            dataIndex: 'latestPurPrice',
            render: (text) => text ? `￥${text}` : `￥${0}`,
        },
        {
            title: '最近销价',
            width: 120,
            dataIndex: 'salePrice',
            render: (text) => text ? `￥${text}` : `￥${0}`,
        },
        {
            title: '供应商名称',
            width: 210,
            dataIndex: 'customerName',
        }, {
            title: '预售单号',
            width: 210,
            dataIndex: 'orderCode',
        }, {
            title: '入库日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        }
    ];
    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: IInventoryDetailsDetail[]) => {
            //         setSelectedRowKeys(selectedRowKeys as string[]);
            //     }
            // }}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.id);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                },
                onSelect: (record) => onRow(record.id),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                }
            })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}
