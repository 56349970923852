import React, { FC } from "react";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";
import useGlobalModel from "@/model/globalModel";
import { RouterParamType } from "@/model/navModel";
import { useDeploymentDetailModel } from "./deploymentDetailModel";
import { formatNum } from "@/utils/utils";
export const FixedBottom: FC<RouterParamType> = ({ id, mode }) => {
    const { cm } = useGlobalModel();
    const { addDesc, editDesc, lookDesc } = useDeploymentDetailModel()
    const typeDesc = {
        "add": addDesc,
        "edit": editDesc,
        "look": lookDesc,
    }
    const orderStatistics: IDescription[] = [
        {
            label: "调配项数",
            value: formatNum(typeDesc[mode].itemNumAll),
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "调配数量",
            value: formatNum(typeDesc[mode].numAll),
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "调配金额",
            value: formatNum(typeDesc[mode].totalAll),
            color: DescriptionColorEnum.red,
            status: true
        },
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${cm.collapsed ? '80px' : '226px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}