
import { CLIENT_APP_CODE, getBaseWSRequestUrl } from "@/utils/constants";
import { notification } from "antd";
import { useEffect, useRef } from "react"
import WebSocketJSClass from "../utils/webSocketClass";
import useGlobalModel from "../model/globalModel";
import { IRechangeMsgFields } from "@/views/account/AccountTypes";
const instanceNotification = Notification || window.Notification;

/**
 * 消息类型
 */
enum MsgTypeEnum {
    "AUDITED" = "你有一条审核结果信息，请及时查看!", // 已审核
    "PENDING" = "您有一条订单消息，请及时处理!", // 待处理
}

/**
 * 消息类型
 */
interface IMsgType {
    type: "RECHARGE" | "ADJUST" | "RECHARGE_AUDIT_ONE" | "ADJUST_AUDIT_ONE" | "CHSH";
    data: IRechangeMsgFields;
}
/**
 * 心跳
 */
interface IMsgHeartBeatType {
    type: "HEART_BEAT";
    data: "pong";
}
/**
 * 连接创建成功
 */
interface IMsgCreateType {
    type: "START";
    data: "start";
}
/**
 * 异常
 */
interface IMsgErrorType {
    type: "ERROR";
    data: null;
}
/**
 * 刷新消息列表
 */
interface IMsgRefreshType {
    type: "RECHARGE_AUDIT" | "ADJUST_AUDIT";
    data: null;
}
/**
 * webSocket消息类型
 */
type IMsg = IMsgType | IMsgHeartBeatType | IMsgCreateType | IMsgErrorType | IMsgRefreshType


interface IUseToastNotification {
  loadMsg?: () => void;
  jumpMsgDetail?: (data:IRechangeMsgFields) =>void
}

export function useToastNotification(props: IUseToastNotification) { 
  const {
    loadMsg,
    jumpMsgDetail,
  } = props;
  const {shopDs,user} = useGlobalModel();
  const wsRef = useRef<WebSocketJSClass>({} as WebSocketJSClass)
  
  useEffect(() => {
    createWebSocket()
    asyncLoadMagInit()
    return () => {
        wsRef.current.Close()
    }
  }, [])
    

  //创建ws
  const createWebSocket = () => {
      wsRef.current = new WebSocketJSClass(`${getBaseWSRequestUrl()}/msg/${CLIENT_APP_CODE}-${user.userCode}`, (data) => {
          let info: IMsg = data ? JSON.parse(data) : { type: "ERROR" }
          switch (info.type) {
              case "START":
                  return console.log("websocket创建成功:", info.data);
              case "HEART_BEAT":
                  return console.log("心跳回执:", info.data);
              case "ERROR":
                  return console.error("消息推送内容有误:", data);
              case "RECHARGE_AUDIT_ONE":
              case "ADJUST_AUDIT_ONE":
                  // if ((info.data as IRechangeMsgFields).code !== user.userCode) return
                //   document.hidden ? creatNotification(info.data, MsgTypeEnum.AUDITED) : openNotification(info.data, MsgTypeEnum.AUDITED);
                  break;
              case "ADJUST":
              case "RECHARGE":
                //   if (shopDs.findIndex(item => item.code === (info.data as IRechangeMsgFields).shopCode) === -1) return
                //   document.hidden ? creatNotification(info.data) : openNotification(info.data);
                  break;
              case "CHSH":
                  if (info.data.branch !== CLIENT_APP_CODE || info.data.createUser !== user.userName) return
                  document.hidden ? creatNotification(info.data, info.data.note,"") : openNotification(info.data, info.data.note,"");
                  break;
              default:
                  break;
          }
          loadMsg?.()
      })
      wsRef.current.createConnect()
  }

  /**
   * 消息通知授权初始化
   * @param detail 
   */
  const asyncLoadMagInit = () => {
      // console.log(instanceNotification);
      if (instanceNotification) {
          var permissionNow = instanceNotification.permission;
          if (permissionNow === 'granted') { //允许通知
              // creatNotification();
          } else if (permissionNow === 'default') {
              setPermission();
          } else if (permissionNow === 'denied') {
              console.log('用户拒绝了你!!!');
          } else {
              setPermission();
          }
      }

  }
  
  /**
   * 请求获取通知权限
   */
  function setPermission() {
      instanceNotification.requestPermission(function (PERMISSION) {
          if (PERMISSION === 'granted') {
              console.log('用户允许通知了!!!');
              // creatNotification();
          } else {
              console.log('用户拒绝了你!!!');
          }
      });
  }
  
  /**
   * 创建一个系统内部消息提示
   * @param placement 
   */
  const openNotification = (detail: IRechangeMsgFields, title: string = MsgTypeEnum.PENDING,body:string="快点击处理吧!") => {
      notification.info({
          message: title,
          description: body,
          placement: "bottomRight",
          duration: 120,
          style: title === MsgTypeEnum.AUDITED ? {
              background: "#62f275",
              width: 420,
          } : {
              background: "#ff6464",
              color: "#fff",
              width: 420,
          },
          key: detail.id,
          onClick: () => {
              jumpMsgDetail?.(detail)
              notification.close(detail.id)
          }
      });
  };

  /**
   * 创建一个消息通知
   * @param detail 
   * @returns 
   */
  function creatNotification(detail: IRechangeMsgFields, title: string = MsgTypeEnum.PENDING,body:string="快点击处理吧!") {
      if (!window.Notification) {
          alert("浏览器不支持通知！");
          return openNotification(detail, title);
      }
      console.log(window.Notification.permission);
      if (window.Notification.permission != 'granted') {
          console.log('用户未开启通知权限!!!');
          return openNotification(detail, title);
      }
      var instanceNotification = new Notification(title, {
          "body": body,
          "requireInteraction": true
      });
      instanceNotification.onshow = function () {
          console.log("显示通知");
      };
      instanceNotification.onclick = function (event) {
          event.preventDefault();
          jumpMsgDetail?.(detail)
          instanceNotification.close();
      };
      instanceNotification.onclose = function () {
          console.log("通知关闭");
      };
      instanceNotification.onerror = function () {
          console.log('错误');
      };
  }
  
  return {
    wsRef
  }
}