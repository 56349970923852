import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { useDictModel } from "@/views/base/dict/dictModel";
import { reportFormService } from "../reportFormService";
import { ISCMJxcDetailPurchasePin } from "../reportFormType";
import useGlobalModel from "@/model/globalModel";
import { useReportFormModel } from "../reportFormModel";
import { transPgToPagination } from "@/utils/utils";

interface IOccupyStocType {
    visible: boolean,
    onCancel: () => void,
    materialCode: string
    materialName: string
    id: string
}
export const ReportFormModel = ({
    visible,
    materialCode,
    materialName,
    id,
    onCancel,
}: IOccupyStocType) => {
    const { shopAndOrg } = useGlobalModel();
    const { getTreeNodeName } = useDictModel();
    const [searchParam, setSearchParam] = useState<object>({})
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [dataSource, setDataSource] = useState<ISCMJxcDetailPurchasePin[]>([]);
    const { exportPin } = useReportFormModel();

    useEffect(() => {
        if (visible) {
            loadData(initPgCfg, { materialCode, materialName })
            setSearchParam({ materialCode, materialName })
        }
    }, [visible])
    const loadData = async (page?: ISearchPage, searchValue: object = searchParam) => {
        let searchParams = {
            ...searchValue,
            shopId: shopAndOrg.shopId,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await reportFormService.purchasePin(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '从属门店',
            fixed: "left",
            width: 160,
            dataIndex: 'shopName',
        }, {
            title: '仓库',
            fixed: "left",
            width: 140,
            dataIndex: 'warehouseName',
        }, {
            title: '出入库日期',
            width: 160,
            dataIndex: 'stockIoTime',
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'tradeTypeName',
        }, {
            title: '对方单位',
            width: 180,
            dataIndex: 'userName',
        }, {
            title: '单号',
            width: 180,
            dataIndex: 'code',
        }, {
            title: '货位',
            width: 140,
            dataIndex: 'shelfCode',
        }, {
            title: '数量',
            width: 100,
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
        }, {
            title: '金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '成本',
            width: 100,
            dataIndex: 'costAmount',
        }, {
            title: '毛利',
            width: 100,
            dataIndex: 'profit',
        }, {
            title: '数量余额',
            width: 100,
            dataIndex: 'surplusNum',
        }, {
            title: '金额余额',
            width: 100,
            dataIndex: 'surplusAmount',
        }, {
            title: '业务员',
            width: 120,
            dataIndex: 'createUser',
        }
    ]
    const action =
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportPin(materialCode, materialName, id)}
        >详情导出</Button>;
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title={`商品名称:${materialName}  商品编码：${materialCode}`}
        width={1000}
        bodyHeight={700}
        showFooter={false}
    >
        <YhBox title={action}>
            <Table
                style={{ cursor: "pointer" }}
                columns={columns}
                dataSource={dataSource}
                // pagination={false}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize }).then();
                    }
                }}
                scroll={{ x: 'max-content', y: 600 }}
            >
            </Table>
        </YhBox>

    </YHModal>
}