import React from "react";
import { useSaleOrderModel } from "../saleOrderModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";


export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSaleOrderModel();
    const { getSelectedDictOne } = useDictModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "销售订单号",
            fieldName: "saleOrderCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            options: getSelectedDictOne(DictCodeEnmu.SALE_ORDER),

        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "车牌号",
            fieldName: "licensePlate"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "VIN码",
            fieldName: "vin"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "车辆型号",
            fieldName: "vehicleModel"
        },

    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "车辆类型",
            fieldName: "vehicleTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),

        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "维修类型",
            fieldName: "maintenanceTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.REPAIR_TYPE),

        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算方",
            fieldName: "settlementPartyCode",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),

        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算方式",
            fieldName: "paymentMethodCode",
            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD),

        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS)
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser"
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "发票类型",
            fieldName: "invoiceTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),

        },

    ]
    return (
        <YhPageHeader
            size="small"
            title="销售订单"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}