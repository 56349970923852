import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, Menu, Dropdown } from "antd";
import { PreSaleDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePreSaleModel } from "./presaleModel";
import { formatNum } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
import { usePreSaleDetailModel } from "./detail/presaleDetailModel";
import { DownOutlined } from "@ant-design/icons";
import { SalePrint } from "./SalePrint"
export const PreSaleDataBox = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        invalidateData,
        exportSaleSo,
        detailsExport,
        print,
        setPrint
    } = usePreSaleModel();
    const { resetEditCatch } = usePreSaleDetailModel()
    const { selectedNavId, replaceNav, addNav, findNav, selectNav, switchNavState } = useNavModel();

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.stock-io") {
            globalPrompt("message", { text: "该单据已出库，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        let saleOrderEditKey = "16-2-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow.orderCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "预售记录跳转详情",
                type: "warning",
                text: "您还有预售记录详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow.orderCode as string, mode: "edit" });
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.orderCode as string, mode: "look", },
            true
        );
    }
    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        globalPrompt("modal", {
            title: "预售单作废",
            type: "error",
            text: "",
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    orderCode: selectedRow.orderCode as string,
                }
                invalidateData(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    // const onWarehousing = () => {
    //     if (selectedRowKeys.length !== 1) {
    //         globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
    //         return;
    //     }
    //     if (selectedRow.status === "presale-order.transfer") {
    //         globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
    //         return;
    //     }
    //     if (selectedRow.status === "presale-order.change") {
    //         globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
    //         return;
    //     }
    //     let supplierEditKey = "16-2-2";
    //     replaceNav(
    //         selectedNavId,
    //         supplierEditKey,
    //         {
    //             id: selectedRow.orderCode as string, mode: "edit", parentInfo: {
    //                 Warehousing: "Warehousing"
    //             }
    //         },
    //         true
    //     );
    // }
    const onTranSale = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "presale-order.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        let supplierEditKey = "16-2-2";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            {
                id: selectedRow.orderCode as string, mode: "edit", parentInfo: {
                    TranSale: "TranSale"
                }
            },
            true
        );
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleSo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onEdit}
        >
            编辑
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onWarehousing}
        >
            库房确认
        </Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onTranSale}
        >
            转销售单
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onInvalidate}
        >
            作废
        </Button> */}
        <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onPrint}
        >
            打印
        </Button>
    </>
    const desc: IDescription[] = [
        {
            label: "预售数量",
            value: selectedRowKeys.length === 0 ? descTotal?.presaleNum??0 : (rowsDescTotal?.presaleNum??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "预售金额",
            value: selectedRowKeys.length === 0 ? descTotal?.presaleAmount??0 : formatNum(rowsDescTotal?.presaleAmount??0),
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <YhBox title="预售记录" action={action} descriptions={desc}>
            <PreSaleDataTable />
        </YhBox>
        {print && <SalePrint/>}
    </>
}