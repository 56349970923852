import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, message, Select } from 'antd';
import { IMaintenanceField } from '@/views/maintenance/maintenanceType';
import moment from 'moment';
import "./settleScaniaPrint.css"
import skny from "../../assets/images/skny.png"
import ouman from "../../assets/images/ouman.png"
import zf from "../../assets/images/zf.png"
import iveco from "../../assets/images/iveco.png"
import yhjt from "../../assets/images/yhjt.png";
import { IIMgEmum, logoOptions, logoYhhOptions } from '@/types/ScmEnums';
import { globalPrompt } from '../message';
import { useDictModel } from '@/views/base/dict/dictModel';
import useGlobalModel, { getFindItem } from '@/model/globalModel';
import { customerService } from '@/views/base/customerFiles/customer/baseCustomerService';
import { momentFormat } from '@/utils/utils';
export interface IPrintClumns {
    title: string,
    dataIndex: string,
    render?: (text: string | number) => string
}
export interface IPrintHeader {
    [key: string]: string | number | any
}
export interface IPrintFooter {
    [key: string]: string | number | any
}
interface Item {
    [key: string]: string | number | any
}
export interface IPrintType {
    title?: string,
    type?: string,
    visible: boolean,
    onClose: () => void,
    onConfirm?: () => void,
    itemClumns?: IPrintClumns[], //维修项目字段及信息
    columns: IPrintClumns[],  //商品字段及信息
    dataSource: Item[],      //商品数据
    itemDataSource?: Item[],  //维修项目数据
    formVal: IMaintenanceField,
    shopCode: string,
    isTechnician?: boolean //是否显示维修技师
}
export interface IHeader {
    fieldLabel: string,
    fieldValue?: string | number,
    className?: string,
    border?: boolean,
}
let shopObject: any = {
    "SHOP-M-00000017": {
        "img": skny,
        "address": "新疆乌鲁木齐市米东区金汇西路2877号",
        "phone": "0991-5310666     18016861905",
        "Bank": "招商银行乌鲁木齐分行解放北路支行",
        "BankAccount": "8530 8058 0110 001",
        "Fax": "0991-5310666     18016861905"
    },
    "SHOP-M-00000018": {
        "img": ouman,
        "phone": "0991-5310666     13209963230",
        "Bank": "新疆天山农村银行股份有限公司米东区支行",
        "BankAccount": "8070 1001 2010 1140 42179",
        "Fax": "0991-5310666     13209963230"

    },
    "SHOP-M-00000020": {
        "img": skny,
        "address": "天津津南经济开发区 旺港路15号",
        "phone": "022-28577398",
        "Bank": "中国工商银行股份有限公司天津金地支行",
        "BankAccount": "0302061609100023871",
        "Fax": "022-28577392"
    }
}
export const SaleScaniaPrint = ({
    title = "",
    visible,
    onClose,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    formVal,
    shopCode,
    isTechnician = false
}: IPrintType) => {
    const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    const { CustomerData, shopAndOrg: { shopName, shopId } } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState<string>("")
    // 遍历商品td
    const mapGoods = (goods: Item) => {
        return columns.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods: Item) => {
        return itemClumns?.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    useEffect(() => {
        // console.log(settlementPartyCode,formVal)
        if (Object.keys(formVal).length !== 0) {
            let { settlementPartyCode, customerName } = formVal
            // console.log(formVal)
            if (settlementPartyCode === "settle-party.internal") {
                setSettlementPartyName(shopName)
            } else if (settlementPartyCode && (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1)) {
                let { value: customerCode } = getTreeNodeOne(settlementPartyCode as string)[0]
                // console.log(settlementPartyCode, getTreeNodeOne(settlementPartyCode as string),getFindItem(customerCode, CustomerData))
                setSettlementPartyName(getFindItem(customerCode, CustomerData) ? getFindItem(customerCode, CustomerData).name : '')
            } else {
                setSettlementPartyName(customerName as string)
            }
        }
    }, [formVal])
    const [imgVal, setImgVal] = useState(shopCode !== "SHOP-M-00000018" ? skny : ouman)

    const print = () => {
        const el: HTMLElement | null = document.getElementById('settle-scania-print-container');
        const iframe: any = document.createElement('IFRAME');
        let doc = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./settleScaniaPrint.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    }
    const setLogo = () => {
        let logoVal: IIMgEmum = IIMgEmum.SKNY;
        globalPrompt("modal", {
            width: 380,
            title: "设置logo",
            type: "info",
            // text: "",
            text: <>
                <span>请选择Logo类型</span>
                <Select
                    showSearch={true}
                    defaultValue={shopCode !== "SHOP-M-00000018" ? IIMgEmum.SKNY : IIMgEmum.OUMAN}
                    options={shopCode !== "SHOP-M-00000018" ? logoOptions : logoYhhOptions}
                    onChange={(value, option) => logoVal = value}
                />
            </>,
            okText: "确定",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    resolve(logoVal)
                }).then(res => {
                    switch (logoVal) {
                        case IIMgEmum.SKNY:
                            return setImgVal(skny)
                        case IIMgEmum.OUMAN:
                            return setImgVal(ouman)
                        case IIMgEmum.ZF:
                            return setImgVal(zf)
                        case IIMgEmum.IVECO:
                            return setImgVal(iveco)
                        default:
                            return setImgVal(skny)
                    }
                    // setImgVal(logoVal)
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    return <Modal
        title="打印详情"
        width={800}
        visible={visible}
        onCancel={onClose}
        footer={[
            <Button type="link" onClick={onClose} key="back"> 取消</Button>,
            <Button type="link" onClick={print} key="submit">确认打印</Button>,
            <Button type="link" onClick={setLogo} key="logo">设置Logo</Button>,

        ]}
    >
        <div id="settle-scania-print-container">
            <div className="settle-container">
                <div className="settle-header">
                    <div className="h2">
                        <h2>{`${title}销售结算单`}</h2>
                    </div>
                    <div className={`logo `}>
                        <img src={yhjt} alt="" />
                        {shopCode === "SHOP-M-00000020" && <div>欧豪富港</div>}
                    </div>
                    <div className={`skny`}>
                        <img src={imgVal} alt="斯堪尼亚" />
                    </div>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td>服务厂:</td>
                            <td colSpan={3}>{title}</td>
                            {/* <td>工单号:</td>
                            <td>{formVal.maintenanceCode}</td> */}
                            <td>结算时间:</td>
                            <td>{momentFormat(formVal.settleTime)}</td>
                        </tr>
                        {/* <tr>
                            <td colSpan={6}></td>
                        </tr> */}
                        <tr>
                            <td colSpan={6}></td>
                        </tr>
                        <tr>
                            <td colSpan={6}></td>
                        </tr>
                        <tr>
                            <td>销售单号:</td>
                            <td>{formVal.maintenanceCode}</td>
                            <td>制单时间:</td>
                            <td>{formVal.enterTime}</td>
                            <td>销售时间:</td>
                            <td>{momentFormat(formVal.saleTime)}</td>
                            {/* <td>托修单位:</td>
                            <td>{formVal.customerName}</td>
                            <td>车辆类型:</td>
                            <td>{formVal.vehicleTypeName}</td>
                            <td>出厂时间:</td>
                            <td>{formVal.deliveryTime}</td> */}
                        </tr>
                        <tr>
                            <td>客户名称:</td>
                            <td colSpan={3}>{formVal.customerName}</td>
                            {/* <td>{formVal.contact}</td>
                            <td>车牌号:</td>
                            <td>{formVal.licensePlate}</td> */}
                            <td>行驶里程:</td>
                            <td>{formVal.mileage}<span className="span-right">公里/每小时</span></td>
                        </tr>
                        <tr>
                            <td>联系人:</td>
                            <td>{formVal.contact}</td>
                            <td>联系电话:</td>
                            <td>{formVal.phone}</td>
                            <td>客户地址:</td>
                            <td>{formVal.customerAddress}</td>
                            {/* <td>联系电话:</td>
                            <td>{formVal.phone}</td>
                            <td>底盘号:</td>
                            <td>{formVal.vin}</td>
                            <td>结算日期:</td>
                            <td>{formVal.settleTime}</td> */}
                        </tr>
                        <tr>
                            <td>车辆类型:</td>
                            <td>{formVal.vehicleTypeName}</td>
                            <td>车牌号:</td>
                            <td>{formVal.licensePlate}</td>
                            <td>底盘号:</td>
                            <td>{formVal.vin}</td>
                        </tr>
                        <tr>
                            {/* <td>维修类型:</td>
                            <td>{formVal.serviceNatureName}</td> */}
                            <td>结算方式:</td>
                            <td>{getTreeNodeName(formVal.payType as string)}</td>
                            <td>结算方:</td>
                            <td>{settlementPartyName}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="ming-xi">
                    <tbody>
                        <tr>
                            {/* <td>序号</td>
                            <td>项目编码</td>
                            <td>修理项目</td>
                            <td>工时</td>
                            <td style={{ textAlign: "right" }}>单价</td>
                            <td style={{ textAlign: "right" }}>折扣</td>
                            
                            <td style={{ textAlign: "right" }}>工时金额</td> */}
                            {itemClumns && itemDataSource && itemDataSource.length > 0 && itemClumns.filter(item => item).map((item, index) =>
                                <td key={index}>{item.title}</td>
                            )}
                        </tr>
                        {itemDataSource && itemDataSource.length > 0 &&
                            itemDataSource.map((item, index) =>
                                <tr key={index}>
                                    {itemGoods(item)}
                                </tr>
                            )}
                        <tr className="part">
                            {/* <td>序号</td>
                            <td>零件编码</td>
                            <td>零件名称</td>
                            <td>数量</td>
                            <td style={{ textAlign: "right" }}>单价</td>
                            <td style={{ textAlign: "right" }}>折扣</td>
                            <td style={{ textAlign: "right" }}>零件金额</td> */}
                            {columns && dataSource && dataSource.length > 0 && columns.filter(item => item).map((item, index) =>
                                <td key={index}>{item.title}</td>
                            )}
                        </tr>
                        {dataSource && dataSource.length > 0 &&
                            dataSource.map((item, index) =>
                                <tr key={index}>
                                    {mapGoods(item)}
                                </tr>
                            )
                        }
                        <tr style={{ height: "20px" }}>
                            <td colSpan={7}></td>
                        </tr>
                    </tbody>
                </table>
                <div className="total-price">
                    <table>
                        <tbody>
                            <tr>
                                <td>零件小计</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.materialScienceAmount}</td>
                            </tr>
                            <tr>
                                <td>优惠金额</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.discountTotalAmount}</td>
                            </tr>
                            <tr>
                                <td>费用合计</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.totalAmount}</td>
                            </tr>
                            <tr>
                                <td>{formVal.payType === "settle-type.cash" ? '实收' : '应收'}人民币金额(RMB)&nbsp;&nbsp;{formVal.paymentMethodName}</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.receivableTotalAmount}</td>
                            </tr>
                            <tr>
                                <td>大写人民币</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.receivableTotalAmountCN}</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <table>
                        <tbody>
                            <tr style={{ width: "90%" }}>
                                <td style={{ margin: "20px 0 0 -250px" }}>{formVal.payType === "settle-type.cash" ? '实收' : '应收'}人民币金额(RMB)<span>{formVal.customerName}{formVal.paymentMethodName}</span></td>
                                <td style={{ textAlign: "right" }} colSpan={3}>{formVal.receivableTotalAmount}</td>
                            </tr>                            
                        </tbody>
                    </table> */}
                </div>
                {/* <div className="paybox">
                    <div className="paybox-item">结算类型:<span>&nbsp;&nbsp;{getTreeNodeName(formVal.payType as string)}</span></div>
                    <div className="paybox-item">{formVal.payType === "settle-type.cash" ? '实收:' : '应收:'}<span>&nbsp;&nbsp;{formVal.receivableTotalAmount}</span></div>
                </div> */}
                <p className="remarks" style={{ margin: "10px 0 20px" }}>备注：{formVal.note}</p>
                <div className="print-flex">
                    <div>制单<span>&nbsp;&nbsp;{formVal.createUser}</span></div>
                    <div>库房<span>&nbsp;&nbsp;</span></div>
                    <div>质检<span>&nbsp;&nbsp;{formVal.formVal}</span></div>
                    <div>财务<span>&nbsp;&nbsp;{formVal.formVal}</span></div>
                </div>
                <div className={`sign-open`}>
                    <div>托修人签字:</div>
                    <div>维修厂签字:</div>
                </div>
                <div className={`warm-reminder`}>
                    <p>预防性更换温馨提示：</p>
                    <p>前轮轴承80万公里，后轮轴承60万公里，离合器分离轴承70万公里</p>
                </div>
                <div className="footer">
                    <span>{title}</span>
                    <span>地址：<span>{shopObject[shopCode]["address"]}</span></span>
                    <span>电话：<span>{shopObject[shopCode]["phone"]}</span></span>
                    <span>传真：<span>{shopObject[shopCode]["Fax"]}</span></span>
                </div>
                <p style={{ "marginTop": "60px", textAlign: "right" }}>打印日期:&nbsp;&nbsp;&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm')}</p>
            </div>
        </div>
    </Modal>
}