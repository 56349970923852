import React from "react";
import { useShopModel } from "../shopModel";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useOrgModel } from "../../org/orgModel";
import { useGlobalModel } from "@/model/globalModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useShopModel();
    const { getOrgTreeSelect } = useOrgModel();
    const { beDs } = useGlobalModel();
    const searchFields = [
        {
            type: SearchTypeEnum.TreeSelect,
            placeholder: "从属机构",
            fieldName: "orgCode",
            treeData: getOrgTreeSelect(DictCodeEnmu.ORG)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "关联实体",
            fieldName: "bentityCode",
            options: beDs
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "门店名称",
            fieldName: "name",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            fieldName: "enable",
            options: [{ label: "启用", value: 1 }, { label: "禁用", value: 0 }]
        }
    ]

    return (
        <YhPageHeader
            title="门店管理"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}