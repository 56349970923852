import React, { useState, useEffect } from "react";
import { Table, Button, message, Upload } from "antd";
import { YHModal } from "@/components/YHModal";
import { useStockPoDetailModel } from "../purchaseDetailsModel";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { IImportParams, ISCMPoShiftImportDetail,ISCMPurchaseDetailsSearchFields } from "../purchaseDetailsService";
import { YhBut } from "@/components/YhButton";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { ISelectOptions } from "@/types/ScmTypes";
import { warehouseService } from "@/views/base/warehouse/warehouseService";
import { Item, EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { importPropsConfig,importPropsConfigs, messageConfig } from "@/utils/importFile";
import { DictCodeEnmu } from "@/types/ScmDictEnums";
import { getBaseRequestUrl, getToken } from "@/utils/constants";
import { IPurchaseStock } from '@/views/sale/order/saleOrderTypes';
interface FilrResponse {
    retCode: number,
    retData: string,
    retMsg: string,
    success: boolean
}
interface File {
    uid?: string,
    name?: string,
    status?: string,
    response: FilrResponse,
    linkProps?: any
}
interface Upload {
    file: File,
    fileList: any,
    event: any
}
export const ShiftShelfModals = () => {
    const { shopAndOrg:{shopId}, warehouseList, setLoadingInfo } = useGlobalModel();
    const {
        shiftShelfModals: visible, setShiftShelfModals,
        setSearchParamShelf,
        selectedShelfRows, setSelectedShelfRows, shelftDownloadTpl,
        shiftShelfApis
    } = useStockPoDetailModel();
    const [shelfDs, setShelfDs] = useState<ISelectOptions[]>();  // 实时查询货位

    useEffect(() => {
        if (visible) {
            // loadDataShelf({ pageNum: 1 }, {});
            // console.log("222"+shopId)
            console.log("222",shopId)

            setSearchParamShelf({});
            setSelectedShelfRows([]);
        }
    }, [visible])

    const onCancel = () => {
        setShiftShelfModals(false);
    };


    //货位根据仓库code查询
    const queryShelf = async (wsCode: string) => {
        const searchParams = {
            shopId,
            warehouseCode: wsCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        setShelfDs(records.map((item:any) => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    //仓库选择  (置空货位)
    const wsChange = (value: string, options: ISelectOptions, record: ISCMPoShiftImportDetail) => {
        if (options && options.id) {
            queryShelf(options.value as string)
        }
    }

    //货位触焦
    const shelfFocus = (record: Item) => {
        if (record["warehouseCode1"]) {
            queryShelf(record["warehouseCode1"] as string)
        } else {
            setShelfDs([]);
        }
    }


    const columnsSelect = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: string) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '原仓库',
            width: 140,
            dataIndex: 'warehouseName',
        }, {
            title: '原货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '原库存',
            width: 100,
            dataIndex: 'originalInventoryNum',
        }, {
            title: '仓库',
            width: 150,
            dataIndex: "warehouseCode1",
            editable: true,
            editType: "select",
            options: transOptions(warehouseList),
            onSelectChange: wsChange,
            render: (text: string) => warehouseList.find((item: any) => item.code === text)?.name
        }, {
            title: '货位',
            width: 150,
            dataIndex: 'shelfCode1',
            editable: true,
            editType: "select",
            options: shelfDs, //transOptions(shelfDs as any),
            onSelectFocus: shelfFocus
        }, {
            title: '转移库存',
            width: 120,
            editable: true,
            editType: "input",
            dataIndex: 'moveNum',
        }, {
            title: '操作',
            width: 80,
            align: "center",
            fixed: "right",
            className: "td-wrap",
            render: (text: any, record: ISCMPoShiftImportDetail) => <YhBut
                type="delete"
                txt="移除"
                click={() => {
                    const newData = selectedShelfRows.filter(item => item.id !== record.id);
                    setSelectedShelfRows(newData);
                }}
            />
        }
    ];

    const newSelectColumns = columnsSelect.map((col: any, index) => {
        return {
            ...col,
            onCell: (record: ISCMPoShiftImportDetail, rowIndex: number) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row: ISCMPoShiftImportDetail) => {
        let dataSource = [...selectedShelfRows];
        const index = dataSource.findIndex((item) => row.id === item.id);

        //选中货位的话， 赋值货位code
        if (row.shelfCode1) {
            // debugger;
            row.shelfId1 = shelfDs?.find(item => item.value === row.shelfCode1)?.id;
        }
        //设置仓库名称
        if (row.warehouseCode1) {
            row.warehouseName1 = warehouseList.find((item: any) => item.code === row.warehouseCode1)?.name
        }
        //判断仓库变化时，  重置货位
        if (row.warehouseCode1 !== dataSource[index].warehouseCode1) {
            row.shelfCode1 = undefined
        }

        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        setSelectedShelfRows([...dataSource]);
    }


    const upLoadCallback = (retData:any) => {
        if (!retData) {
            setLoadingInfo({ loadingVisible: false, txt: "" });
            return message.warning("数据异常，请尝试重新导入")
        }
        let newShelfRows: ISCMPoShiftImportDetail[] = [];
        let str: string = "";
        retData.forEach((item:any) => {
            if (!item.status) {
                str += `【${item.msg}】`
            }
            if (item.list && item.list.length) {
                for (let i = 0; i < item.list.length; i++) {
                    let goods = item.list[i];
                    let findObj = selectedShelfRows.find(originGoods => originGoods.id === goods.id);
                    //过滤掉列表存在的
                    if (!findObj ) {
                        newShelfRows.push({
                            ...goods,
                            moveNum: goods.originalInventoryNum ?? 0,
                            warehouseCode: goods.originalWarehouseCode,// string,    //"3",
                            warehouseName: goods.originalWarehouseName,// string,    //"3",
                            shelfId: goods.originalShelfId,// string,    //"3",
                            shelfCode: goods.originalShelfCode,// string,    //"3",
                            warehouseCode1: goods.warehouseCode,    //转移仓库,
                            warehouseName1: goods.warehouseName,    //"3",
                            shelfId1: goods.shelfId,    //转移货位,
                            shelfCode1: goods.shelfCode,    //"3",
                        })
                    }
                }
            }
        })
        // console.log(newShelfRows)
        setLoadingInfo({ loadingVisible: false, txt: "" });
        if (!newShelfRows.length) {
            messageConfig("没有匹配到可正常转移的配件" + str, 3, "info");
            return;
        } else {
            messageConfig(str ? "以下配件导入异常：" + str : "导入成功", 20, "info");
        }
        setSelectedShelfRows(origin => [...origin, ...newShelfRows])
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => shelftDownloadTpl()}
        >下载模板</Button>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfigs(`/bcm/purchase/shift/import/${shopId}`, upLoadCallback, () => setLoadingInfo({ loadingVisible: true, txt: "正在导入中" }), false)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
    </>;

    return <YHModal
        visible={visible}
        onOk={() => {
            let filterPayData = selectedShelfRows.filter(item => (item.shelfCode1));
            if (filterPayData.length !== selectedShelfRows.length) {
                message.warning("请选择要转移的货位");
                return;
            }
            shiftShelfApis()
        }}
        onCancel={onCancel}
        title="库存明细-转移货位"
        width={1200}
        bodyHeight={600}
        showFooter={true}
    >
        <YhBox title="已选配件列表" rightChildren={action}>
            <Table
                rowKey={record => record.id as string}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns as ColumnsType<IPurchaseStock>}
                dataSource={selectedShelfRows}
                pagination={false}
                scroll={{ x: 'max-content', y: 450 }}
            />
        </YhBox>
    </YHModal>
}