import { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { ISearchPage } from '@/types/ScmTypes';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants';
import { internalService } from './internalService';
import useGlobalModel from '@/model/globalModel';
import { IInternalDetail, IInternalSearch } from './internalTypes';
import { convertRes2Blob } from '@/utils/exportFile';
import { isArray } from 'util';
import { useLockFn } from 'ahooks';
import { transPgToPagination } from '@/utils/utils';
export const useInternalModel = createModel(function () {
	const {
		user: { shopId },
		accUnitDs,
	} = useGlobalModel();

	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	//查询条件
	const [searchParam, setSearchParam] = useState<IInternalSearch>({});
	//列表数据源
	const [dataSource, setDataSource] = useState<IInternalDetail[]>([]);
	//表格选中行Key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	const [selectedData, setSelectedData] = useState<IInternalDetail>();

	useEffect(() => {
		let filters = dataSource.filter((el) =>
			selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false
		);
		const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
		setSelectedData(selectedData);
	}, [selectedRowKeys]);

	//列表数据源分页参数
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	const updateSearchParam = (updateParam: IInternalSearch) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};
	//重置
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};
	const loadData = async (
		page: ISearchPage = initPgCfg,
		searchValue: IInternalSearch = searchParam
	) => {
		const searchParams = {
			shopId,
			...searchValue,
			unitCode: accUnitDs.find((item: any) => item.shop == shopId)?.code,
			page: {
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await internalService.page(searchParams);
		const { records, ...pg } = retData;
		// debugger;
		setPgCfg(transPgToPagination(pg));
		setDataSource(records);
	};
	return {
		/***field***/
		searchParam,
		dataSource,
		selectedRowKeys,
		pgCfg,
		/***method***/
		setSearchParam,
		setDataSource,
		setSelectedRowKeys,
		setPgCfg,
		updateSearchParam,
		resetSearchParam,
		loadData,
		selectedData,
		setSelectedData,
	};
});
