//作废的功能禁用 末尾添加hide按钮会隐藏  去掉hide和门户鉴权功能录入保持一致按钮会显示
export enum ResourceAuthEnum {
	//预约管理(新增)
	AP_AD = 'bcm.ap.ad',
	//预约管理(编辑)
	AP_ED = 'bcm.ap.ed',
	//预约管理(查看)
	AP_VW = 'bcm.ap.vw',
	//预约管理(作废)
	AP_CANCEL = 'bcm.ap.cancel hide',
	//预约管理(导出)
	AP_EX = 'bcm.ap.ex',
	//预约管理(打印)
	AP_PRINT = 'bcm.ap.print',
	//预约管理(详情-保存)
	AP_ED_SAVE = 'bcm.ap.ed.save',
	//预约管理(详情-返回)
	AP_ED_BACK = 'bcm.ap.ed.back',
	//预约管理(详情-刷新)
	AP_ED_REFRESH = 'bcm.ap.ed.refresh',
	//预约管理(详情-整单折扣)
	AP_ED_DISCOUNT_ALL = 'bcm.ap.ed.discount-all',
	//预约管理(详情-用料折扣)
	AP_ED_DISCOUNT_M = 'bcm.ap.ed.discount-m',
	//预约管理(详情-项目折扣)
	AP_ED_DISCOUNT_I = 'bcm.ap.ed.discount-i',
	//预约管理(详情-维修项目添加)
	AP_ED_ADD_I = 'bcm.ap.ed.add-i',
	//预约管理(详情-维修项目批量删除)
	AP_ED_BATCH_DEL_I = 'bcm.ap.ed.batch-del-i',
	//预约管理(详情-维修项目删除)
	AP_ED_DEL_I = 'bcm.ap.ed.del-i',
	//预约管理(详情-维修用料添加)
	AP_ED_ADD_M = 'bcm.ap.ed.add-m',
	//预约管理(详情-维修用料批量删除)
	AP_ED_BATCH_DEL_M = 'bcm.ap.ed.batch-del-m',
	//预约管理(详情-维修用料删除)
	AP_ED_DEL_M = 'bcm.ap.ed.del-m',
	//预约管理(详情-转工单核对)
	AP_ED_VERIFY = 'bcm.ap.verify',

	//工单管理(新增)
	M_AD = 'bcm.m.ad',
	//工单管理(编辑)
	M_ED = 'bcm.m.ed',
	//工单管理(查看)
	M_VW = 'bcm.m.vw',
	//工单管理(作废)
	M_CANCEL = 'bcm.m.cancel hide',
	//工单管理(导出)
	M_EX = 'bcm.m.ex',
	//工单管理(打印)
	M_PRINT = 'bcm.m.print',
	//工单管理(转车间)
	M_TRANS = 'bcm.m.trans',
	//工单管理（维修出库）
	M_M_OUT = 'bcm.m.m-out',

	//工单管理(详情-保存)
	M_ED_SAVE = 'bcm.m.ed.save',
	//工单管理(详情-返回)
	M_ED_BACK = 'bcm.m.ed.back',
	//工单管理(详情-刷新)
	M_ED_REFRESH = 'bcm.m.ed.refresh',
	//工单管理(详情-整单折扣)
	M_ED_DISCOUNT_ALL = 'bcm.m.ed.discount-all',
	//工单管理(详情-用料折扣)
	M_ED_DISCOUNT_M = 'bcm.m.ed.discount-m',
	//工单管理(详情-项目折扣)
	M_ED_DISCOUNT_I = 'bcm.m.ed.discount-i',
	//工单管理(详情-维修项目添加)
	M_ED_ADD_I = 'bcm.m.ed.add-i',
	//工单管理(详情-维修项目批量删除)
	M_ED_BATCH_DEL_I = 'bcm.m.ed.batch-del-i',
	//工单管理(详情-维修项目删除)
	M_ED_DEL_I = 'bcm.m.ed.del-i',
	//工单管理(详情-维修用料添加)
	M_ED_ADD_M = 'bcm.m.ed.add-m',
	//工单管理(详情-维修用料批量删除)
	M_ED_BATCH_DEL_M = 'bcm.m.ed.batch-del-m',
	// 工单管理(详情-维修用料删除)
	M_ED_DEL_M = 'bcm.m.ed.del-m',
	// 工单管理(详情-确认结算)
	M_ED_CONFIRM_BILL = 'bcm.m.ed.confirm-bill',

	// 车间派工
	//派工列表(查看)
	DISP_LIST_VW = 'bcm.disp.list.vw',
	//派工列表(派工)
	DISP_LIST_DISP = 'bcm.disp.list.disp',
	//派工列表(完工)
	DISP_LIST_FINISH = 'bcm.disp.list.finish',
	//派工列表(维修工查看)
	DISP_LIST_VW_M = 'bcm.disp.list.vw-m',
	//派工列表(详情返回)
	DISP_LIST_BACK = 'bcm.dispatch.list.back',

	//历史工单(查看)
	DISP_HISTORY_VW = 'bcm.disp.history.vw',
	//历史工单(维修工查看)
	DISP_HISTORY_VW_M = 'bcm.disp.history.vw-m',
	//历史工单(详情返回)
	DISP_HISTORY_BACK = 'bcm.disp.history.back',

	// 结算列表
	// 结算列表(查看)
	BILL_LIST_VW = 'bcm.bill.list.vw',
	// 结算列表(导出)
	BILL_LIST_EX = 'bcm.bill.list.ex',
	// 结算列表(打印)
	BILL_LIST_PRINT = 'bcm.bill.list.print',
	// 结算列表(详情返回)
	BILL_LIST_BACK = 'bcm.bill.list.back',

	// 客户结算
	// 客户结算(查看)
	BILL_C_VW = 'bcm.bill.c.vw',
	// 客户结算(导出)
	BILL_C_EX = 'bcm.bill.c.ex',
	// 客户结算(打印)
	BILL_C_PRINT = 'bcm.bill.c.print',
	// 客户结算(详情返回)
	BILL_C_BACK = 'bcm.bill.c.back',

	// 厂家结算
	// 厂家结算(结算)
	BILL_M_BILL = 'bcm.bill.m.bill',
	// 厂家结算(查看)
	BILL_M_VW = 'bcm.bill.m.vw',
	// 厂家结算(导出)
	BILL_M_EX = 'bcm.bill.m.ex',
	// 厂家结算(打印)
	BILL_M_PRINT = 'bcm.bill.m.print',
	// 厂家结算(详情保存)
	BILL_M_ED_SAVE = 'bcm.bill.m.ed.save',
	// 厂家结算(详情返回)
	BILL_M_ED_BACK = 'bcm.bill.m.ed.back',
	//厂家结算(详情刷新)
	BILL_M_ED_REFRESH = 'bcm.bill.m.ed.refresh',
	//厂家结算(详情确认结算)
	BILL_M_ED_CONFIRM_BILL = 'bcm.bill.m.confirm-bill',
	//厂家结算(详情整体折扣)
	BILL_M_ED_DISCOUNT_ALL = 'bcm.bill.ed.discount-all',
	//厂家结算(维修项目添加)
	BILL_M_ED_ADD_I = 'bcm.bill.m.ed.add-i',
	//厂家结算(维修项目删除)
	BILL_M_ED_DEL_I = 'bcm.bill.m.ed.del-i',
	//厂家结算(维修项目批量删除)
	BILL_M_ED_BATCH_DEL_I = 'bcm.bill.m.ed.batch-del-i',
	//厂家结算(维修用料添加)
	BILL_M_ED_ADD_M = 'bcm.bill.m.ed.add-m',
	//厂家结算(维修用料删除)
	BILL_M_ED_DEL_M = 'bcm.bill.m.ed.del-m',
	//厂家结算(维修用料批量删除)
	BILL_M_ED_BATCH_DEL_M = 'bcm.bill.m.ed.batch-del-m',

	// 客户回访
	// 客户回访(回访)
	R_VISIT_V = 'bcm.r-visit.v',
	// 客户回访(查看)
	R_VISIT_VW = 'bcm.r-visit.vw',
	// 客户回访(导出)
	R_VISIT_EX = 'bcm.r-visit.ex',
	// 客户回访(详情-填写回访信息)
	R_VISIT_FILL = 'bcm.r-visit.fill',
	// 客户回访(详情-返回)
	R_VISIT_BACK = 'bcm.r-visit.back',

	//销售订单
	//销售订单（新增）
	S_SO_AD = 'bcm.s.so.ad',
	//销售订单（编辑）
	S_SO_ED = 'bcm.s.so.ed',
	//销售订单（查看）
	S_SO_VW = 'bcm.s.so.vw',
	//销售订单（作废）
	S_SO_CANCEL = 'bcm.s.so.cancel hide',
	//销售订单（导出）
	S_SO_EX = 'bcm.s.so.ex',
	//销售订单（详情-保存）
	S_SO_ED_SAVE = 'bcm.s.so.ed.save',
	//销售订单（详情-返回）
	S_SO_ED_BACK = 'bcm.s.so.ed.back',
	//销售订单（详情-刷新）
	S_SO_ED_REFRESH = 'bcm.s.so.ed.refresh',
	//销售订单（详情-新增配件）
	S_SO_ED_ADD_M = 'bcm.s.so.ed.add-m',
	//销售订单（详情-批量删除）
	S_SO_ED_DEL_M = 'bcm.s.so.ed.del-m',
	//销售订单（详情-下载模板）
	S_SO_ED_DLOAD = 'bcm.s.so.ed.dload',
	//销售订单（详情-导入）
	S_SO_ED_IM = 'bcm.s.so.ed.im',
	//销售订单（详情-折扣）
	S_SO_ED_DISC = 'bcm.s.so.ed.disc',
	//销售订单（详情-转销售单）
	S_SO_ED_TRANS = 'bcm.s.so.ed.trans',

	// 销售单
	//销售单(新增)
	S_S_AD = 'bcm.s.s.ad',
	//销售单(编辑)
	S_S_ED = 'bcm.s.s.ed',
	//销售单(查看)
	S_S_VW = 'bcm.s.s.vw',
	//销售单(作废)
	S_S_CANCEL = 'bcm.s.s.cancel hide',
	//销售单(导出)
	S_S_EX = 'bcm.s.s.ex',
	//销售单(打印)
	S_S_PRINT = 'bcm.s.s.print',
	//销售单(补单)
	S_S_REPL = 'bcm.s.s.repl',
	//销售单(详情-保存)
	S_S_ED_SAVE = 'bcm.s.s.ed.save',
	//销售单(详情-返回)
	S_S_ED_BACK = 'bcm.s.s.ed.back',
	//销售单(详情-刷新)
	S_S_ED_REFRESH = 'bcm.s.s.ed.refresh',
	//销售单(详情-折扣)
	S_S_ED_DISC = 'bcm.s.s.ed.disc',
	//销售单(详情-确认结算)
	S_S_ED_CONFIRM_BILL = 'bcm.s.s.ed.confirm-bill',
	//销售单(详情-库房出库)
	S_S_ED_STOCK = 'bcm.s.s.ed.stock',
	//销售单(详情-新增配件)
	S_S_ED_ADD_M = 'bcm.s.s.ed.add-m',
	//销售单(详情-删除配件)
	S_S_ED_DEL_M = 'bcm.s.s.ed.del-m',
	//销售单(详情-下载模板)
	S_S_ED_DLOAD = 'bcm.s.s.ed.dload',
	//销售单(详情-导入)
	S_S_ED_IM = 'bcm.s.s.ed.im',

	//销售退货
	//销售退货(新增)
	S_R_AD = 'bcm.s.r.ad',
	//销售退货(编辑)
	S_R_ED = 'bcm.s.r.ed',
	//销售退货(查看)
	S_R_VW = 'bcm.s.r.vw',
	//销售退货(作废)
	S_R_CANCEL = 'bcm.s.r.cancel hide',
	//销售退货(导出)
	S_R_EX = 'bcm.s.r.ex',
	//销售退货(打印)
	S_R_PRINT = 'bcm.s.r.print',
	//销售退货(补单)
	S_R_REPL = 'bcm.s.r.repl',
	//销售退货(详情-保存)
	S_R_ED_SAVE = 'bcm.s.r.ed.save',
	//销售退货(详情-返回)
	S_R_ED_BACK = 'bcm.s.r.ed.back',
	//销售退货(详情-新增配件)
	S_R_ED_ADD_M = 'bcm.s.r.ed.add-m',
	//销售退货(详情-批量删除)
	S_R_ED_DEL_M = 'bcm.s.r.ed.del-m',
	//销售退货(详情-确认结算)
	S_R_ED_CONFIRM_BILL = 'bcm.s.r.ed.confirm-bill',
	//销售退货(详情-库房入库)
	S_R_ED_STOCK = 'bcm.s.r.ed.stock',

	//采购计划
	//采购计划(新增)
	P_PL_AD = 'bcm.p.pl.ad',
	//采购计划(编辑)
	P_PL_ED = 'bcm.p.pl.ed',
	//采购计划(查看)
	P_PL_VW = 'bcm.p.pl.vw',
	//采购计划(导出)
	P_PL_EX = 'bcm.p.pl.ex',
	//采购计划(详情-保存)
	P_PL_ED_SAVE = 'bcm.p.pl.ed.save',
	//采购计划(详情-返回)
	P_PL_ED_BACK = 'bcm.p.pl.ed.back',
	//采购计划(详情-刷新)
	P_PL_ED_REFRESH = 'bcm.p.pl.ed.refresh',
	//采购计划(详情-新增配件)
	P_PL_ED_ADD_M = 'bcm.p.pl.ed.add-m',
	//采购计划(详情-批量删除配件)
	P_PL_ED_DEL_M = 'bcm.p.pl.ed.del-m',
	//采购计划(详情-下载模板)
	P_PL_ED_DLOAD = 'bcm.p.pl.ed.dload',
	//采购计划(详情-导入)
	P_PL_ED_IM = 'bcm.p.pl.ed.im',
	//采购计划(详情-转采购单)
	P_PL_ED_TRANS = 'bcm.p.pl.ed.trans',

	//采购单
	//采购单(新增)
	P_P_AD = 'bcm.p.p.ad',
	//采购单(编辑)
	P_P_ED = 'bcm.p.p.ed',
	//采购单(查看)
	P_P_VW = 'bcm.p.p.vw',
	//采购单(作废)
	P_P_CANCEL = 'bcm.p.p.cancel hide',
	//采购单(导出)
	P_P_EX = 'bcm.p.p.ex',
	//采购单(补单)
	P_P_REPL = 'bcm.p.p.repl',
	//采购单(详情-保存)
	P_P_ED_SAVE = 'bcm.p.p.ed.save',
	//采购单(详情-返回)
	P_P_ED_BACK = 'bcm.p.p.ed.back',
	//采购单(详情-刷新)
	P_P_ED_REFRESH = 'bcm.p.p.ed.refresh',
	//采购单(详情-折扣)
	P_P_ED_DISC = 'bcm.p.p.ed.disc',
	//采购单(详情-补单)
	P_P_ED_REPL = 'bcm.p.p.ed.repl',
	//采购单(详情-确认结算)
	P_P_ED_CONFIRM_BILL = 'bcm.p.p.ed.confirm-bill',
	//采购单(详情-库房入库)
	P_P_ED_STOCK = 'bcm.p.p.ed.stock',
	//采购单(详情-新增商品)
	P_P_ED_ADD_M = 'bcm.p.p.ed.add-m',
	//采购单(详情-删除商品)
	P_P_ED_DEL_M = 'bcm.p.p.ed.del-m',
	//采购单(详情-下载模板)
	P_P_ED_DLOAD = 'bcm.p.p.ed.dload',
	//采购单(详情-导入)
	P_P_ED_IM = 'bcm.p.p.ed.im',
	//采购单(详情-转销售单)
	P_P_ED_TRANS = 'bcm.p.p.ed.trans',

	//采购退货单
	//采购退货单(新增)
	P_R_AD = 'bcm.p.r.ad',
	//采购退货单(编辑)
	P_R_ED = 'bcm.p.r.ed',
	//采购退货单(查看)
	P_R_VW = 'bcm.p.r.vw',
	//采购退货单(作废)
	P_R_CANCEL = 'bcm.p.r.cancel hide',
	//采购退货单(导出)
	P_R_EX = 'bcm.p.r.ex',
	//采购退货单(详情-保存)
	P_R_ED_SAVE = 'bcm.p.r.ed.save',
	//采购退货单(详情-返回)
	P_R_ED_BACK = 'bcm.p.r.ed.back',
	//采购退货单(详情-刷新)
	P_R_ED_REFRESH = 'bcm.p.r.ed.refresh',
	//采购退货单(详情-确认结算)
	P_R_ED_CONFIRM_BILL = 'bcm.p.r.ed.confirm-bill',
	//采购退货单(详情-库房出库)
	P_R_ED_STOCK = 'bcm.p.r.ed.stock',
	//采购退货单(详情-新增商品)
	P_R_ED_ADD_M = 'bcm.p.r.ed.add-m',
	//采购退货单(详情-删除商品)
	P_R_ED_DEL_M = 'bcm.p.r.ed.del-m',

	//机构管理
	//机构管理(刷新)
	ORG_ORG_REFRESH = 'bcm.org.org.refresh',
	//机构管理(同步)
	ORG_ORG_SYNC = 'bcm.org.org.sync',

	//经营实体
	//经营实体(刷新)
	ORG_ENTITY_REFRESH = 'bcm.org.entity.refresh',
	//经营实体(同步)
	ORG_ENTITY_SYNC = 'bcm.org.entity.sync',

	//门店管理
	//门店管理(新增)
	ORG_SHOP_AD = 'bcm.org.shop.ad',
	//门店管理(编辑)
	ORG_SHOP_ED = 'bcm.org.shop.ed',

	//供应商档案
	//供应商档案(新增)
	DATA_S_AD = 'bcm.data.s.ad',
	//供应商档案(编辑)
	DATA_S_ED = 'bcm.data.s.ed',
	//供应商档案(查看)
	DATA_S_VW = 'bcm.data.s.vw',
	//供应商档案(导出)
	DATA_S_EX = 'bcm.data.s.ex',
	//供应商档案(导入)
	DATA_S_IM = 'bcm.data.s.im',
	//供应商档案(下载模板)
	DATA_S_DLOAD = 'bcm.data.s.dload',
	//供应商档案(启用/禁用)
	DATA_S_ABLE = 'bcm.data.s.able',
	//供应商档案(详情-保存)
	DATA_S_ED_SAVE = 'bcm.data.s.ed.save',
	//供应商档案(详情-重置)
	DATA_S_ED_RESET = 'bcm.data.s.ed.reset',
	//供应商档案(详情-取消)
	DATA_S_ED_BACK = 'bcm.data.s.ed.back',

	//配件档案
	//配件档案(新增)
	DATA_M_AD = 'bcm.data.m.ad',
	//配件档案(编辑)
	DATA_M_ED = 'bcm.data.m.ed',
	//配件档案(查看)
	DATA_M_VW = 'bcm.data.m.vw',
	//配件档案(导出)
	DATA_M_EX = 'bcm.data.m.ex',
	//配件档案(导入)
	DATA_M_IM = 'bcm.data.m.im',
	//配件档案(下载模板)
	DATA_M_DLOAD = 'bcm.data.m.dload',
	//配件档案(启用/禁用)
	DATA_M_ABLE = 'bcm.data.m.able',
	//配件档案(详情-保存)
	DATA_M_ED_SAVE = 'bcm.data.m.ed.save',
	//配件档案(详情-重置)
	DATA_M_ED_RESET = 'bcm.data.m.ed.reset',
	//配件档案(详情-取消)
	DATA_M_ED_BACK = 'bcm.data.m.ed.back',

	//仓库管理
	//仓库管理(新增)
	DATA_W_AD = 'bcm.data.w.ad',
	//仓库管理(编辑)
	DATA_W_ED = 'bcm.data.w.ed',
	//仓库管理(详情-保存)
	DATA_W_ED_SAVE = 'bcm.data.w.ed.save',
	//仓库管理(详情-重置)
	DATA_W_ED_RESET = 'bcm.data.w.ed.reset',
	//仓库管理(详情-保存)
	DATA_W_ED_BACK = 'bcm.data.w.ed.back',

	//仓库管理(详情-区域信息)
	DATA_W_ED_AREA = 'bcm.data.w.ed.area',
	//仓库管理(详情-区域新增)
	DATA_W_ED_AREA_AD = 'bcm.data.w.ed.area.ad',
	//仓库管理(详情-区域编辑)
	DATA_W_ED_AREA_ED = 'bcm.data.w.ed.area.ed',
	//仓库管理(详情-区域导入)
	DATA_W_ED_AREA_IM = 'bcm.data.w.ed.area.im',
	//仓库管理(详情-区域下载模板)
	DATA_W_ED_AREA_DLOAD = 'bcm.data.w.ed.area.dload',

	//仓库管理(详情-货架信息)
	DATA_W_ED_SHELVES = 'bcm.data.w.ed.shelves',
	//仓库管理(详情-货架新增)
	DATA_W_ED_SHELVES_AD = 'bcm.data.w.ed.shelves.ad',
	//仓库管理(详情-货架编辑)
	DATA_W_ED_SHELVES_ED = 'bcm.data.w.ed.shelves.ed',
	//仓库管理(详情-货架导入)
	DATA_W_ED_SHELVES_IM = 'bcm.data.w.ed.shelves.im',
	//仓库管理(详情-货架下载模板)
	DATA_W_ED_SHELVES_DLOAD = 'bcm.data.w.ed.shelves.dload',

	//仓库管理(详情-货位信息)
	DATA_W_ED_SHELF = 'bcm.data.w.ed.shelf',
	//仓库管理(详情-货位新增)
	DATA_W_ED_SHELF_AD = 'bcm.data.w.ed.shelf.ad',
	//仓库管理(详情-货位编辑)
	DATA_W_ED_SHELF_ED = 'bcm.data.w.ed.shelf.ed',
	//仓库管理(详情-货位导入)
	DATA_W_ED_SHELF_IM = 'bcm.data.w.ed.shelf.im',
	//仓库管理(详情-货位下载模板)
	DATA_W_ED_SHELF_DLOAD = 'bcm.data.w.ed.shelf.dload',
	//仓库管理 (编辑 - 货位信息 tab - 导出)
	DATA_W_ED_SHELF_EX = "scm.data.w.ed.shelf.ex",

	//数据字典
	//增加同级
	DATA_DIC_SUB = 'bcm.data.dic.sub',
	//增加下级
	DATA_DIC_BRO = 'bcm.data.dic.bro',
	//同级保存
	DATA_DIC_SUB_SAVE = 'bcm.data.dic.sub.save',
	//下级保存
	DATA_DIC_BRO_SAVE = 'bcm.data.dic.bro.save',
	//编辑保存
	DATA_DIC_ED_SAVE = 'bcm.data.dic.ed.save',
	//同级取消
	DATA_DIC_SUB_BACK = 'bcm.data.dic.sub.back',
	//下级取消
	DATA_DIC_BRO_BACK = 'bcm.data.dic.bro.back',
	//编辑取消
	DATA_DIC_ED_BACK = 'bcm.data.dic.ed.back',

	//客户管理
	//客户管理(新增)
	DATA_C_C_AD = 'bcm.data.c.c.ad',
	//客户管理(编辑)
	DATA_C_C_ED = 'bcm.data.c.c.ed',
	//客户管理(查看)
	DATA_C_C_VW = 'bcm.data.c.c.vw',
	//客户管理(导出)
	DATA_C_C_EX = 'bcm.data.c.c.ex',
	//客户管理(导入)
	DATA_C_C_IM = 'bcm.data.c.c.im',
	//客户管理(下载模板)
	DATA_C_C_DLOAD = 'bcm.data.c.c.dload',
	//客户管理(下属车辆)
	DATA_C_C_VEHICLE = 'bcm.data.c.c.vehicle',
	//客户管理(详情-保存)
	DATA_C_C_ED_SAVE = 'bcm.data.c.c.ed.save',
	//客户管理(详情-重置)
	DATA_C_C_ED_RESET = 'bcm.data.c.c.ed.reset',
	//客户管理(详情-取消)
	DATA_C_C_ED_BACK = 'bcm.data.c.c.ed.back',

	// 车辆管理
	//车辆管理(新增)
	DATA_C_V_AD = 'bcm.data.c.v.ad',
	//车辆管理(编辑)
	DATA_C_V_ED = 'bcm.data.c.v.ed',
	//车辆管理(查看)
	DATA_C_V_VW = 'bcm.data.c.v.vw',
	//车辆管理(导出)
	DATA_C_V_EX = 'bcm.data.c.v.ex',
	//车辆管理(导入)
	DATA_C_V_IM = 'bcm.data.c.v.im',
	//车辆管理(下载模板)
	DATA_C_V_DLOAD = 'bcm.data.c.v.dload',
	//车辆管理(返回客户)
	DATA_C_V_BACK_C = 'bcm.data.c.v.back-c',
	//车辆管理(详情-保存)
	DATA_C_V_ED_SAVE = 'bcm.data.c.v.ed.save',
	//车辆管理(详情-重置)
	DATA_C_V_ED_RESET = 'bcm.data.c.v.ed.reset',
	//车辆管理(详情-取消)
	DATA_C_V_ED_BACK = 'bcm.data.c.v.ed.back',

	// 维修项目
	//维修项目(新增)
	DATA_I_AD = 'bcm.data.i.ad',
	//维修项目(编辑)
	DATA_I_ED = 'bcm.data.i.ed',
	//维修项目(查看)
	DATA_I_VW = 'bcm.data.i.vw',
	//维修项目(导出)
	DATA_I_EX = 'bcm.data.i.ex',
	//维修项目(导入)
	DATA_I_IM = 'bcm.data.i.im',
	//维修项目(下载模板)
	DATA_I_DLOAD = 'bcm.data.i.dload',
	//维修项目(详情-保存)
	DATA_I_ED_SAVE = 'bcm.data.i.ed.save',
	//维修项目(详情-重置)
	DATA_I_ED_RESET = 'bcm.data.i.ed.reset',
	//维修项目(详情-取消)
	DATA_I_ED_BACK = 'bcm.data.i.ed.back',

	// 维修技师
	//维修技师(新增)
	DATA_T_AD = 'bcm.data.t.ad',
	//维修技师(编辑)
	DATA_T_ED = 'bcm.data.t.ed',
	//维修技师(查看)
	DATA_T_VW = 'bcm.data.t.vw',
	//维修技师(导出)
	DATA_T_EX = 'bcm.data.t.ex',
	//维修技师(导入)
	DATA_T_IM = 'bcm.data.t.im',
	//维修技师(详情-保存)
	DATA_T_ED_SAVE = 'bcm.data.t.ed.save',
	//维修技师(详情-重置)
	DATA_T_ED_RESET = 'bcm.data.t.ed.reset',
	//维修技师(详情-取消)
	DATA_T_ED_BACK = 'bcm.data.t.ed.back',

	// 库存统计
	// 库存统计(导出)
	ST_INVENT_EX = 'bcm.st.invent.ex',
	//库存盘点
	//库存盘点(新增)
	ST_STTAKE_AD = 'bcm.st.sttake.ad',
	//库存盘点(编辑)
	ST_STTAKE_ED = 'bcm.st.sttake.ed',
	//库存盘点(查看)
	ST_STTAKE_VW = 'bcm.st.sttake.vw',
	//库存盘点(导出)
	ST_STTAKE_EX = 'bcm.st.sttake.ex',
	//库存盘点(作废)
	ST_STTAKE_CANCEL = 'bcm.st.sttake.cancel hide',
	//库存盘点(打印)
	ST_STTAKE_PRINT = 'bcm.st.sttake.print',
	//库存盘点(详情-保存)
	ST_STTAKE_ED_SAVE = 'bcm.st.sttake.ed.save',
	//库存盘点(详情-取消)
	ST_STTAKE_ED_BACK = 'bcm.st.sttake.ed.back',
	//库存盘点(详情-新增配件)
	ST_STTAKE_ED_ADD_M = 'bcm.st.sttake.ed.add-m',
	//库存盘点(详情-删除配件)
	ST_STTAKE_ED_EDL_M = 'bcm.st.sttake.ed.del-m',
	//库存盘点(详情-导入)
	ST_STTAKE_ED_IM = 'bcm.st.sttake.ed.im',
	//库存盘点(详情-导出)
	ST_STTAKE_ED_EX = 'bcm.st.sttake.ed.ex',
	//库存盘点(详情-盘点完成)
	ST_STTAKE_ED_FINISH = 'bcm.st.sttake.ed.finish',

	//维修出库

	//维修出库(编辑)
	ST_M_OUT_ED = 'bcm.st.m-out.ed',
	//维修出库(查看)
	ST_M_OUT_VW = 'bcm.st.m-out.vw',
	//维修出库(导出)
	ST_M_OUT_EX = 'bcm.st.m-out.ex',
	//维修出库(详情-保存)
	ST_M_OUT_ED_SAVE = 'bcm.st.m-out.ed.save',
	//维修出库(详情-重置)
	ST_M_OUT_ED_RESET = 'bcm.st.m-out.ed.reset',
	//维修出库(详情-取消)
	ST_M_OUT_ED_BACK = 'bcm.st.m-out.ed.back',
	//维修出库(详情-开始备料)
	ST_M_OUT_ED_PREPARE_M_S = 'bcm.st.m-out.ed.prepare-m.s',
	//维修出库(详情-备料完成)
	ST_M_OUT_ED_PREPARE_M_F = 'bcm.st.m-out.ed.prepare-m.f',
	//维修出库(详情-出库)
	ST_M_OUT_ED_PINK_M = 'bcm.st.m-out.ed.pick-m',
	//维修出库(详情-退料)
	ST_M_OUT_ED_RETURN_M = 'bcm.st.m-out.ed.return-m',

	//维修借件
	//维修借件(新增)
	ST_M_BORROW_AD = 'bcm.st.m-borrow.ad',
	//维修借件(编辑)
	ST_M_BORROW_ED = 'bcm.st.m-borrow.ed',
	//维修借件(详情-保存)
	ST_M_BORROW_ED_SAVE = 'bcm.st.m-borrow.ed.save',
	//维修借件(详情-重置)
	ST_M_BORROW_ED_RESET = 'bcm.st.m-borrow.ed.refresh',
	//维修借件(详情-取消)
	ST_M_BORROW_ED_BACK = 'bcm.st.m-borrow.ed.back',
	//维修借件(详情-新增配件)
	ST_M_BORROW_ED_ADD_M = 'bcm.st.m-borrow.ed.add-m',
	//维修借件(详情-删除配件)
	ST_M_BORROW_ED_EDL_M = 'bcm.st.m-borrow.ed.del-m',

	//出库单据
	//出库单据(查看)
	ST_OUT_VW = 'bcm.st.out.vw',
	//出库单据(导出)
	ST_OUT_EX = 'bcm.st.out.ex',
	//出库单据(详情-返回)
	ST_OUT_BACK = 'bcm.st.out.back',

	//入库单据
	//入库单据(查看)
	ST_IN_VW = 'bcm.st.in.vw',
	//入库单据(导出)
	ST_IN_EX = 'bcm.st.in.ex',
	//入库单据(详情-返回)
	ST_IN_BACK = 'bcm.st.in.back',

	/*fms*/
	//客户账户管理 （查看）
	ACC_C_VW = 'bcm.ac.c.vw',
	//客户账户管理 （账户初始化）
	ACC_C_INIT = 'bcm.ac.c.init',
	//客户账户管理 （同步）
	ACC_C_ASYNC = 'bcm.ac.c.async',

	//客户账户管理详情 （账户信息）
	ACC_C_VW_AC = 'bcm.ac.c.vw.ac',
	//客户账户管理详情 （账户信息-开户）
	ACC_C_VW_AC_INIT = 'bcm.ac.c.vw.ac.init',
	//客户账户管理详情 （账户信息-调额）
	ACC_C_VW_AC_ADJ = 'bcm.ac.c.vw.ac.adj',
	//客户账户管理详情 （账户信息-充值）
	ACC_C_VW_AC_CHARG = 'bcm.ac.c.vw.ac.charg',
	//客户账户管理详情 （账户信息-转账）
	ACC_C_VW_AC_TRANS = 'bcm.ac.c.vw.ac.trans',
	//客户账户管理详情 （账户信息-核销账单）
	ACC_C_VW_AC_W_OFF = 'bcm.ac.c.vw.ac.w-off',
	//客户账户管理详情 （账户信息-启用禁用）
	ACC_C_VW_AC_DISABLE = 'bcm.ac.c.vw.ac.disable',

	//客户账户管理详情 （调额记录）
	ACC_C_VW_ADJ = 'bcm.ac.c.vw.adj',
	//客户账户管理详情 （调额记录-审核）
	ACC_C_VW_ADJ_AUDIT = 'bcm.ac.c.vw.adj.audit',

	//客户账户管理详情 （充值记录）
	ACC_C_VW_CHARG = 'bcm.ac.c.vw.charg',
	//客户账户管理详情 （充值记录-审核）
	ACC_C_VW_CHARG_AUDIT = 'bcm.ac.c.vw.charg.audit',
	//客户账户管理详情 （转账记录）
	ACC_C_VW_TRANS = 'bcm.ac.c.vw.trans',

	//客户账户管理详情 （账单信息）
	ACC_C_VW_BILL = 'bcm.ac.c.vw.bill',
	//客户账户管理详情 （账单信息-核销）
	ACC_C_VW_BILL_W_OFF = 'bcm.ac.c.vw.bill.w-off',

	//客户账户管理详情 （核销记录）
	ACC_C_VW_W_OFF = 'bcm.ac.c.vw.w-off',
	//客户账户管理详情 （操作日志）
	ACC_C_VW_LOG = 'bcm.ac.c.vw.log',

	//供应商账户管理 （查看）
	ACC_S_VW = 'bcm.data.s.vw1',
	//供应商账户管理 （账户初始化）
	ACC_S_INIT = 'bcm.ac.s.init',
	//供应商账户管理 （同步）
	ACC_S_ASYNC = 'bcm.ac.s.async',

	//供应商账户管理详情 （账户信息）
	ACC_S_VW_AC = 'bcm.ac.s.vw.ac',
	//供应商账户管理详情 （账户信息-开户）
	ACC_S_VW_AC_INIT = 'bcm.ac.s.vw.ac.init',
	//供应商账户管理详情 （账户信息-调额）
	ACC_S_VW_AC_ADJ = 'bcm.ac.s.vw.ac.adj',
	//供应商账户管理详情 （账户信息-充值）
	ACC_S_VW_AC_CHARG = 'bcm.ac.s.vw.ac.charg',
	//供应商账户管理详情 （账户信息-转账）
	ACC_S_VW_AC_TRANS = 'bcm.ac.s.vw.ac.trans',
	//供应商账户管理详情 （账户信息-核销账单）
	ACC_S_VW_AC_W_OFF = 'bcm.ac.s.vw.ac.w-off',
	//供应商账户管理详情 （账户信息-启用禁用）
	ACC_S_VW_AC_DISABLE = 'bcm.ac.s.vw.ac.disable',

	//供应商账户管理详情 （调额记录）
	ACC_S_VW_ADJ = 'bcm.ac.s.vw.adj',
	//供应商账户管理详情 （调额记录-审核）
	ACC_S_VW_ADJ_AUDIT = 'bcm.ac.s.vw.adj.audit',

	//供应商账户管理详情 （充值记录）
	ACC_S_VW_CHARG = 'bcm.ac.s.vw.charg',
	//供应商账户管理详情 （充值记录-审核）
	ACC_S_VW_CHARG_AUDIT = 'bcm.ac.s.vw.charg.audit',
	//供应商账户管理详情 （转账记录）
	ACC_S_VW_TRANS = 'bcm.ac.s.vw.trans',

	//供应商账户管理详情 （账单信息）
	ACC_S_VW_BILL = 'bcm.ac.s.vw.bill',
	//供应商账户管理详情 （账单信息-核销）
	ACC_S_VW_BILL_W_OFF = 'bcm.ac.s.vw.bill.w-off',

	//供应商账户管理详情 （核销记录）
	ACC_S_VW_W_OFF = 'bcm.ac.s.vw.w-off',
	//供应商账户管理详情 （操作日志）
	ACC_S_VW_LOG = 'bcm.ac.s.vw.log',

	//客户账单管理-导出
	BILL_C_EXS = 'bcm.biil.c.ex',
	//客户账户管理-账单信息-导出
	ACC_BILL_C_EX = 'bcm.ac.c.vw.bill.ex',
	//供应商账单管理-导出
	BILL_S_EX = 'bcm.biil.s.ex',
	//供应商账户管理-账单信息-导出
	ACC_BILL_S_EX = 'bcm.ac.s.vw.bill.ex',

	//客户信息管理(查看)
	DATA_C_VW = 'bcm.data.c.vw',
	//客户信息管理(同步)
	DATA_C_SYNC = 'bcm.data.c.sync',

	//供应商管理(查看)
	DATA_S_VW1 = 'bcm.data.s.vw1',
	//供应商管理(同步)
	DATA_S_SYNC = 'bcm.data.s.sync',

	//调入单编辑、
	P_DR_ED = 'bcm.p.dr.ed',
	//调入单请调、
	P_DR_AD_APPLY = 'bcm.p.dr.ad.apply',
	//调入单-删除商品、
	P_DR_AD_DEL_M = 'bcm.p.dr.ad.del-m',
	//调入单-调入、
	P_DR_ED_DR = 'bcm.p.dr.ed.dr',
	//调入单-导入明细
	P_DR_AD_IM = 'bcm.p.dr.ad.im',
	//调入单-新增、
	P_DR_AD = 'bcm.p.dr.ad',
	//调入单-新增商品、
	P_DR_AD_ADD_M = 'bcm.p.dr.ad.add-m',
	//调入单-导出、
	P_DR_EX = 'bcm.p.dr.ex',
	//调入单-导出明细、
	P_DR_ED_EX = 'bcm.p.dr.ed.ex',
	//调入单-打印、
	P_DR_ED_PRINT = 'bcm.p.dr.ed.print',
	//调入单-下载模板、
	P_DR_AD_DLOAD = 'bcm.p.dr.ad.dload',
	//调出单-删除商品、
	S_DC_ED_DEL_M = 'bcm.s.dc.ed.del-m',
	//调出单-导入明细、
	S_DC_ED_IM = 'bcm.s.dc.ed.im',
	//调出单-导出、
	S_DC_EX = 'bcm.s.dc.ex',
	//调出单-打印、
	S_DC_ED_PRINT = 'bcm.s.dc.ed.print',
	//调出单-调出、
	S_DC_ED_DC = 'bcm.s.dc.ed.dc',
	//调出单编辑-保存、
	S_DC_ED_SAVE = 'bcm.s.dc.ed.save',
	//调出单新增-保存、
	S_DC_AD_SAVE = 'bcm.s.dc.ad.save',
	//调出单-新增商品、
	S_DC_ED_ADD_M = 'bcm.s.dc.ed.add-m',
	//调出单-驳回、
	S_DC_ED_REJECT = 'bcm.s.dc.ed.reject',
	//调出单-新增、
	S_DC_AD = 'bcm.s.dc.ad',
	//调出单编辑、
	S_DC_ED = 'bcm.s.dc.ed',
	//调出单-导出明细、
	S_DC_ED_EX = 'bcm.s.dc.ed.ex',
	//调出单-下载模板、
	S_DC_ED_DLOAD = 'bcm.s.dc.ed.dload',

	//调拨管理（导出）
	ST_DB_EX = 'bcm.st.db.ex',
	// 内部核算单位(新增)
	DATA_ACCUNIT_AD = 'bcm.data.accUnit.ad',
	// 内部核算单位(编辑)
	DATA_ACCUNIT_ED = 'bcm.data.accUnit.ed',
	// 内部核算单位(导出)
	DATA_ACCUNIT_EX = 'bcm.data.accUnit.ex',

	// 内部核算单位(新增-保存)
	DATA_ACCUNIT_AD_SAVE = 'bcm.data.accUnit.ad.save',
	// 内部核算单位(编辑-保存)
	DATA_ACCUNIT_ED_SAVE = 'bcm.data.accUnit.ed.save',
	//内部调拨单（申请）
	ACC_INTERNAL_APPLY = 'bcm.ac.internal.apply',
	//内部调拨单（查看）
	ACC_INTERNAL_VW = 'bcm.ac.internal.vw',
	//内部调拨单（申请-申请）
	ACC_INTERNAL_APPLY_APPLY = 'bcm.ac.internal.apply.apply',
	//内部调拨单（申请-保存）
	ACC_INTERNAL_APPLY_SAVE = 'bcm.ac.internal.apply.save',
	//内部调拨单（申请-确认）
	ACC_INTERNAL_APPLY_CONFIRM = 'bcm.ac.internal.apply.confirm',
	//内部调拨单（申请-驳回）
	ACC_INTERNAL_APPLY_REJECT = 'bcm.ac.internal.apply.reject',
	//统计分析
	//应收报表（导出）
	STATIST_RECEIVABLE_EXPORT = "bcm.statist.receivable.export",
	//销售报表（导出）
	STATIST_SALE_EXPORT = "bcm.statist.sale.export",
	//收入汇总报表(导出)
	STATIST_INCOME_EXPORT = "bcm.statist.income.export",
	//采购单报表
	STT_PL_EX = "bcm.stt.pl.ex",
	//采购单明细表
	STT_P_EX = "bcm.stt.p.ex",
	//销售单明细表
	STT_S_EX = "bcm.stt.s.ex",
	//销售单报表-导出
	STT_SL_EX = "bcm.stt.sl.ex",
	
}
