import React from "react";
import { Table, Button, Input } from "antd";
import { YHModal } from "@/components/YHModal";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";

export const MaterialModal = () => {
    const { materialModel: visible, setMaterialModel } = usefollowUpDetailsModel();
    const onOk = ()=>{
        onCancel()
    }
    const onCancel = () => {
        setMaterialModel(false);
    };

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i+1}</>
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'typeCode',
            fixed: "left",
        }, {
            title: '单位',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '规格',
            width: 120,
            dataIndex: 'address',
        }, {
            title: '库存',
            width: 120,
            dataIndex: 'address',
        }, {
            title: '使用数量',
            width: 120,
            dataIndex: 'address',
        }, {
            title: '价格',
            width: 120,
            dataIndex: 'address',     
        }, {
            title: '库房',
            width: 120,
            dataIndex: 'address',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'address',                                             
        }, {
            title: '操作',
            width: 120,
            render: () => <Button size="small" type="primary">添加</Button>
        }
    ];

    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16"
            placeholder="请入你要查询的商品"
        />
        <Button style={{ margin: "0px 12px 0px 15px" }} shape="round">检索</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        title="选择配件"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                // size="small"
                rowKey={record => record.id}
                columns={columns}
                dataSource={[]}
                pagination={{
                    // ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        // loadData({ pageNum, pageSize }).then();
                    }
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                // rowKey={record => record.id}
                columns={columns}
                dataSource={[]}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}