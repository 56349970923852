import React, { FC } from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { usePurchasePlanDetailsModel } from "./purchasePlanDetailsModel";
import { Description } from "@/components/YhBox/Description";
import { ITopType } from "@/types/ScmTypes";
import { useDictModel } from "@/views/base/dict/dictModel";
import { debounce } from "lodash";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { YhAuth } from "@/components/auth/YhAuth";

export const TopButs: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const { detailAddForm, detailEditForm, detailLookForm, Transfer, resetEditCatch, detailDataSource, topButsControl } = usePurchasePlanDetailsModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const { getTreeNodeName } = useDictModel();

    const onTrunPo = async () => {
        let materialNames: string[] = []
        await detailDataSource.forEach(item => {
            if (item.num === 0) {
                materialNames.push(item.materialName as string)
            }
        })
        if (materialNames.length !== detailDataSource.length) {
            let ret = await Transfer()
            if (ret) {
                resetEditCatch(mode);
                goBack();
                globalPrompt("message", { "text": "转接成功", "type": "success" });
            } else {
                globalPrompt("message", { "text": "转接失败", "type": "error" })
            }
        } else {
            await globalPrompt("message", { type: "error", text: `请输入${materialNames.toString()}的到货数量` })
        }

    }
    const goBack = () => {
        replaceNav(selectedNavId, "11-2")
    }
    const action = mode === "edit" ? <>
        <YhAuth resCode={ResourceAuthEnum.P_PL_ED_TRANS}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl}
                onClick={debounce(() => { onTrunPo() }, 900)}
            >转采购单</Button>
        </YhAuth>
    </> : false;
    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatus: IDescription[] = [
        {
            label: "采购订单",
            value: typeTitle[mode]?.planCode as string ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatus.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}