import React, { useEffect } from "react";
import { Table, Switch, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useShopModel } from "../shopModel";
import { IVRMBeDetails, IVRMShopDetail } from "../shopService";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys, disableShop
    } = useShopModel();

    useEffect(() => {
        loadData()
    }, [])

    const columns: ColumnsType<IVRMShopDetail> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '分支编码',
            width: 160,
            dataIndex: 'branch',
            fixed: "left",
        }, {
            title: '门店编码',
            width: 160,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '门店名称',
            width: 160,
            dataIndex: 'name',
        }, {
            title: '门店类型',
            width: 120,
            dataIndex: 'categoryName',
        }, {
            title: '从属机构',
            width: 160,
            dataIndex: 'orgName',
        }, {
            title: '关联实体',
            width: 160,
            dataIndex: 'details',
            render: (beDetails: IVRMBeDetails[]) => {
                if (beDetails && beDetails.length) {
                    let textVal = beDetails.map(item => item.bentityName).join();
                    // return beDetails.map(item => item.bentityName).join()
                    return <Tooltip placement="topLeft" title={textVal} arrowPointAtCenter><p style={{ width: "200px" }} className="ellipsis">{textVal}</p></Tooltip>
                }
                return ""
            }
        }, {
            title: '负责人',
            width: 120,
            dataIndex: 'contact',
        }, {
            title: '联系电话',
            width: 120,
            dataIndex: 'phone',
        }, {
            title: '公司地址',
            width: 160,
            dataIndex: 'address',
        }, {
            title: '状态',
            width: 120,
            dataIndex: 'enable',
            render: (text, record) => (
                <Switch
                    checkedChildren="启用"
                    unCheckedChildren="禁用"
                    checked={+record.enable ? true : false}
                    onChange={(checked: boolean, e: any) => {
                        e.stopPropagation();
                        disableShop({ id: record.id, enable: checked ? 1 : 0 })
                        // editShop({ ...record, status: checked ? 0 : 1 })
                    }}
                />
            ),
        }
    ];


    //点击行选高亮
    const onRow = (id: string) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([id]);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            rowSelection={{
                type: "radio",
                selectedRowKeys: selectedRowKeys, //记录选中
                onChange: (selectedRowKeys: React.Key[], selectedRows: IVRMShopDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                }
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id)
            })}
            scroll={{ x: 'max-content', y: "calc(100vh - 486px)" }}
        />
    )
}
