import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface IMonitoringDetail {
    id: string, // string
    beginNum?: string,   // 2
    bookOccupyNum?: string,   // null
    busSaleNum?: string,   // null
    busSreturnNum?: string,   // null
    compensateNum?: string,   // null
    currentNum?: string,   // 2
    differenceNum?: string,   // 0
    maintainNum?: string,   // null
    maintainOccupyNum?: string,   // null
    materialCode?: string,   // "612600060133"
    materialName?: string,   // "外置节温器"
    mreturnNum?: string,   // null
    occupyDifference?: string,   // 0
    occupyNum?: string,   // 0
    preturnNum?: string,   // 0
    preturnOccupyNum?: string,   // 0
    purchasePrice?: string,   // 85
    purchasePriceLatest?: string,   // 85
    purhcaseNum?: string,   // 2
    remainNum?: string,   // 2
    saleNum?: string,   // 0
    saleOccupyNum?: string,   // 0
    shopId?: string,   // "64eec0c1d6f37078b6b460e4cb0ac9d7"
    sreturnNum?: string,   // 0
    stockCost?: string,   // 170
    stockCostLatest?: string,   // 170
    unusualSreturnNum?: string,   // 0
    [key: string]: any
}
export interface IMonitoringDetailPurchasePin {
    [key: string]: any
}

//搜索条件
export interface IMonitoringSearchFields {
    [key: string]: any
}

export interface IMonitoringSearch
    extends IMonitoringSearchFields, ISearchPage {

}
export interface IStatistics {
    amountDiff: number;
    beforeAmountDiff: number;
    costAmountDiff: number;
    giftsAmountDiff: number;
    giftsNumDiff: number;
    numDiff: number;
    profitDiff: number;
    sumNumDiff: number;
    discountAmountDiff: number;
}


export interface IGoodsRecordModal {
    row?: IMonitoringDetail,
    remainVisible: boolean,   // false,  //进销存剩余数
    occupyVisible: boolean,   // false,  //占用库存数
    startVisible: boolean,   // false,  //期初数
    compensateVisible: boolean,   // false,  //补偿数
    type: string,   // false,  //补偿数
    title?: string,   // false,  //弹框标题
    field?: string,   // false,  //对应字段
}

export interface IremainDetail {
    createTime?: string   // [2022, 3, 3, 9, 39, 34]
    id: string   // "01097699e65147e8bc58b3fd54bc5ac4"
    materialCode?: string   // "12JS160T-1701170"
    materialName?: string   // "一二档同步器总成"
    num?: number   // 1
    pageNum?: string   // null
    pageSize?: string   // null
    price?: string   // 789.45
    shopId?: string   // "18059196cb7c4829ac35f23329ba2256"
    status?: string   // "purchase.allocation"
    type?: string   // "io_spd"
    warehouseCode?: string   // "WH-M-00000012"
    warehouseName?: string   // "天津陕汽库"
}


export interface IDetailsDrawer {
    visible: boolean,
    code: string,
    type?: string
}

//进销存报表
class MonitoringService {
    async page(searchVo: IMonitoringSearch) {
        const apiUrl = "/bcm/data/monitoring/page";
        return await httpRequest.post<IPage<IMonitoringDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: IMonitoringSearch) {
        const apiUrl = "/bcm/data/monitoring/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo: IMonitoringSearch) {
        const apiUrl = "/bcm/data/monitoring/statistics";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }


    //占用明细查询接口
    async occupyDeatil(searchVo: IMonitoringSearch) {
        const apiUrl = "/scm2/statistics/occupyDeatil";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }
    //进销存明细
    async purchaseSaleStorageDeatil(searchVo: IMonitoringSearch) {
        const apiUrl = "/scm2/statistics/purchaseSaleStorageDeatil";
        return await httpRequest.post<IremainDetail[]>(apiUrl, searchVo);
    }

    // 库存明细查采销历史
    async purchaseSaleHistoryDeatil(searchVo: IMonitoringSearch) {
        const apiUrl = "/scm2/statistics/purchaseSaleStorageDeatil";
        return await httpRequest.post<any[]>(apiUrl, searchVo);
    }

    async purchaseSaleStorageDeatilExport(searchVo: IMonitoringSearch) {
        const apiUrl = "/scm2/statistics/purchaseSaleStorageDeatilExport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
}

export const monitoringService = new MonitoringService();