import React, { useEffect } from "react";
import { Button, Input, Table, message, Select, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useWarehouseModel } from "../../warehouseModel";
import { IVRMShelvesDetail } from "../../warehouseTypes";
import { ColumnsType } from "antd/es/table";
import { ShelvesDrawer } from "./ShelvesDrawer";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

//货架列表
export const ShelvesTable = () => {
    const { initPgCfg, areaDataSource, shelvesPgCfg, shelvesDataSource, setSelectedShelvesRows,
        selectedShelvesRows, shelvesLoadData, downloadTplShelves,
        shelvesSearch, setShelvesSearch, resetShelves,
        setIShelvesDrawer
    } = useWarehouseModel();

    useEffect(() => {
        shelvesLoadData(initPgCfg);
    }, [])

    const action = <div className="flex justify-between">
        <div>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELVES_AD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setIShelvesDrawer("add")}
                >新增</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELVES_ED}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => {
                        if (selectedShelvesRows.length !== 1) {
                            message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！");
                            return;
                        }
                        setIShelvesDrawer("edit")
                    }}
                >编辑</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELVES_IM}>
                <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/warehouse/shelves/import`, shelvesLoadData)}>
                    <Button className="mr-5" type="link" shape="round">导入</Button>
                </Upload>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SHELVES_DLOAD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { downloadTplShelves() }}
                >下载模板</Button>
            </YhAuth>

        </div>
        <div className="flex min-width-select">
            <Select
                allowClear
                showSearch
                optionFilterProp="label"
                className="ant-select-round ml-10"
                value={shelvesSearch?.["areaId"]}
                placeholder="所属区域"
                onChange={(value) => setShelvesSearch({ ...shelvesSearch, "areaId": value })}
                options={(() => areaDataSource.map(item => ({ label: item.code, value: item.id })))()}
            />
            <Input
                allowClear
                datatype="round"
                className="border-r-16 ml-10"
                placeholder="货架编号"
                value={shelvesSearch?.["code"]}
                onChange={(e) => setShelvesSearch({ ...shelvesSearch, "code": e.target.value })}
            />
            <Button
                style={{ margin: "0px 12px 0px 15px" }}
                shape="round"
                onClick={() => shelvesLoadData()}
            >检索</Button>
            <Button
                style={{ margin: "0px 0px 0px 12px" }}
                shape="round"
                onClick={() => resetShelves()}
            >重置</Button>

        </div>
    </div>;

    const columns: ColumnsType<IVRMShelvesDetail> = [
        {
            title: '序号',
            width: 60,
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '货架编号',
            width: 300,
            align: "center",
            dataIndex: 'code',
        },
        {
            title: '货架名称',
            width: 300,
            align: "center",
            dataIndex: 'name',
        },
        {
            title: '所属区域',
            width: 300,
            align: "center",
            dataIndex: 'areaCode',
        },
        {
            title: '所属仓库',
            width: 350,
            align: "center",
            dataIndex: 'warehouseName',
        },
        {
            title: '创建人',
            width: 300,
            align: "center",
            dataIndex: 'createUser',
        },
        {
            title: '创建时间',
            width: 350,
            align: "center",
            dataIndex: 'createTime',
        },
        {
            title: '备注',
            width: 300,
            align: "center",
            dataIndex: 'note',
        }
    ];

    const onRow = (record: IVRMShelvesDetail) => {
        let findArr = selectedShelvesRows.find(item => item.id === record.id);
        if (findArr) {
            let filterArr = selectedShelvesRows.filter(item => item.id !== record.id);
            setSelectedShelvesRows(filterArr);
        } else {
            setSelectedShelvesRows(origin => [...origin, record]);
        }
    };

    return (
        <>
            <YhBox action={action}>
                <Table
                    rowKey={record => record.id}
                    rowSelection={{
                        selectedRowKeys: selectedShelvesRows.map(item => item.id), //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IVRMShelvesDetail[]) => {
                            setSelectedShelvesRows(selectedRows);
                        }
                    }}
                    columns={columns}
                    dataSource={shelvesDataSource}
                    pagination={{
                        ...shelvesPgCfg,
                        position: ["bottomCenter"],
                        showTotal: () => `共${shelvesPgCfg.total}条`,
                        onChange: (pageNum, pageSize) => {
                            shelvesLoadData({ pageNum, pageSize })
                        }
                    }}
                    onRow={record => ({
                        onClick: () => onRow(record)
                    })}
                    scroll={{ x: 'max-content', y: 300 }}
                />
            </YhBox>
            <ShelvesDrawer />
        </>
    )
}

