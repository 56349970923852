import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { WhConfirmDrawer } from "./WhConfirmDrawer"
import { useDeliveryModel } from "./deliveryModel";
export default () => {
    const { wsConfirmModal: visible, setWsConfirmModal, selectedRow, loadData } = useDeliveryModel();

    return (
        <>
            <PageHeader />
            <DataBox />
            {visible && <WhConfirmDrawer />}
        </>
    )
}