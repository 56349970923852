import React, { useState, useRef } from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { AddGoodsModal } from "./AddGoodsModal";
import { SettleDrawer } from "./SettleDrawer";
import { WhConfirmDrawer } from "./WhConfirmDrawer";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { useSaleRoDetailModel } from "./saleRoDetailModel";
import { HistoryModel } from "@/views/historyModel";
import { ConsumeHistoryModel } from "@/views/commonModule/consumeHistoryModel"
import { RepairHistoryModel } from "@/views/commonModule/repairHistoryModel"


export const SaleRoDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const {
        historyVisible, setHistoryVisible,
        historyRow,
        consumeHistoryModel,
        consumeHistoryVisible, setConsumeHistoryVisible,
        repairHistoryModel, setRepairHistoryModel
    } = useSaleRoDetailModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    return (
        <>
            <TopButs {...detailsInfo} />
            <FormBox {...detailsInfo} IFormRef={IFormRef} />
            <TableBox {...detailsInfo} IFormRef={IFormRef} />
            <AddGoodsModal {...detailsInfo} />
            <FixedBottom {...detailsInfo} />
            <SettleDrawer {...detailsInfo} />
            <WhConfirmDrawer  {...detailsInfo} />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <ConsumeHistoryModel
                visible={consumeHistoryVisible}
                name={consumeHistoryModel.customerName}
                onCancel={() => setConsumeHistoryVisible(false)}
            />
            <RepairHistoryModel
                visible={repairHistoryModel}
                onCancel={() => setRepairHistoryModel(false)}
            />
        </>
    )
}