import { useState, } from "react";
import { createModel } from "hox";
import { stockOutputService } from "../../stockOutputService"
import {
    IDetailsList,
    IStockOutputField,
} from "../../stockOutputType"
export const useOutputDetailsModel = createModel(function () {
    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IStockOutputField>({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState<IDetailsList[]>([])
    const getDetailLookOne = async (id: string, pattern: string) => {
        let { retData } = await stockOutputService.one(id);
        const { details, ...from } = retData
        setDetailLookForm(from);
        setDetailLookList(details as IDetailsList[])
    }
    return {
        detailLookForm,
        detailLookList,
        setDetailLookForm,
        setDetailLookList,
        getDetailLookOne
    }
})