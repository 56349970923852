import React, { useEffect, useState, FC } from "react";
import { Button, Form, message, Modal, Tooltip, UploadFile } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel from "@/model/navModel";
import { usePurchasePoModel } from "../../purchasePoModel";
import { globalPrompt } from "@/components/message";
import { ITopType, ISelectOptions } from "@/types/ScmTypes";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { usePurchasePoDetailsModel } from "./purchasePoDetailsModel"
import { DetailType } from "@/types/ScmEnums";
import { openSync } from "fs";
import { debounce } from "lodash";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData } from "@/utils/constants";
import { fileDownload } from "@/utils/exportFile";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { UploadOutlined } from "@ant-design/icons";
export const FormBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const {
        initForm,
        detailEditForm,
        detailAddForm,
        detailLookForm,
        detailAddList,
        detailDataSource,
        detailLookList,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        resetEditCatch,
        insertDetail,
        editDetail,
        setDetailAddForm,
        setDetailAddList,
        setTopButsControl,
        supplementInsert,
        setSupplierHistoryModel,
        setSupplierHistoryVisible,
        formComputeRule,
        setDetailDataSource,
        setDetailLookList
    } = usePurchasePoDetailsModel()
    const { beShopDs, supplieAllList } = useGlobalModel();
    const { getSelectedDictOne } = useDictModel();
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮

    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow
    } = usePurchasePoModel();
    const { shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel()

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("11-2-1") && resetAddCatch(mode);
            !findNav("11-2-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])
    const typeList = {
        "add": setDetailAddList,
        "edit": setDetailDataSource,
        "look": setDetailLookList,
    }
    const save = () => {
        //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            let { fileList = [], ...params } = res
            if (fileList.length > 0) {
                const file = fileList[0]
                if (!file?.uid) {
                    console.log(file)
                    return message.error('文件信息有误，请重新上传文件')
                }
                params.md5 = file?.uid
                params.fileName = file?.name
            } else {
                params.md5 = ''
                params.fileName = ''
            }
            if (mode === "add") {
                const resData = parentInfo ? await supplementInsert({ ...params }) : await insertDetail({ ...params })
                if (resData) {
                    globalPrompt("message", { text: `${parentInfo ? '补单' : '新增'}成功`, type: "success" });
                    resetAddCatch(mode);
                    setSelectedRowKeys([resData.id as string])
                    setDescRows([resData])
                    setSelectedRow(resData)
                    goBack();
                }
            } else if (mode === "edit") {
                let { id, purchaseCode } = detailEditForm
                const resData = await editDetail({ ...params, id, purchaseCode })
                if (resData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    getDetailEditOne(purchaseCode as string, mode)
                }
            }
        })
    }
    const goBack = () => {
        replaceNav(selectedNavId, "11-2")
    }
    const onRefresh = async () => {
        if (mode === 'add') {
            IFormRef.current?.resetFields()
            setDetailAddForm(initForm)
            setDetailAddList([])

        } else if (mode === 'edit') {
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const disabledFromBtn = () => {
        let status = detailEditForm.status;
        if (mode === "look") return false;
        if (mode === "add") return true;

        if (mode === "edit" &&
            status === DictCodeEnmu.PURCHASE_STATUS_BILL ||
            status === DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO
        ) {
            return false
        }
        return true;
    }
    const rightChildren = <>
        {disabledFromBtn() &&
            <YhAuth resCode={ResourceAuthEnum.P_P_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { save() }, 900)}

                >保存</Button>
            </YhAuth>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    const goSupplier = () => {
        replaceNav(
            selectedNavId,
            "13-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }

    //采购实体
    const benttityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    //供应商类型
    // const supplierTypeChange = (value: string, option: ISelectOption) => {
    //     option && onChange({ supplierTypeName: option.label, supplierTypeCode: option.value });
    // }
    // 商品类型
    const materialTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ materialTypeName: option.label, materialTypeCode: option.value });
    }
    //采购类型
    const purchaseTypeChange = (value: string, option: ISelectOption) => {
        if (option.value == 'purchase-type.zf') {
            option && onChange({ purchaseTypeName: option.label, purchaseTypeCode: option.value,coefficient: '13%'});
            typeList[mode]([])
        } else {
            option && onChange({ purchaseTypeName: option.label, purchaseTypeCode: option.value,coefficient: ''});
            typeList[mode]([])
        }
    }
    //运输方式
    const deliveryChange = (value: string, option: ISelectOption) => {
        option && onChange({ deliveryMethodName: option.label, deliveryMethod: option.value });
    }
    //发票类型
    const invoiceChange = (value: string, option: ISelectOption) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value });
    }
    //结算方式
    const payMethodChange = (value: string, option: ISelectOption) => {
        option && onChange({ deliveryMethodName: option.label, deliveryMethod: option.value });
    }
    const payTypeChange = (value: string, option: ISelectOption) => {
        // console.log(option, getSelectedDictOne(DictCodeEnmu.PAY_METHOD),)
    }

    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }
    const onConsumption = () => {
        let { supplierCode, supplierName } = typeForm[mode]
        if (!supplierCode) {
            return message.warning("请先选择供应商")
        }
        setSupplierHistoryVisible(true)
        setSupplierHistoryModel({ supplierName: supplierName as string })
    }
    const title = <>
        <span>基础信息</span>
        <Tooltip placement="topLeft" title="采购历史">
            <FontIcon type="icon-xiaofeijilu" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onConsumption}
            />
        </Tooltip>
    </>
    const onPreview = (file: UploadFile<any>) => {
        const { uid, name } = file
        const type = uid.split('.')[1]
        if (['jpg', 'png', 'jpeg', 'gif'].includes(type)) {
            Modal.info({
                title: '图片查看',
                content: <img style={{ width: '100%' }} src={getBaseImgLookUrl(uid)} />,
                okText: '确定',
                cancelText: '取消',
                centered: true,
                width: 800,
            })
        } else {
            // window.open(getBaseImgLookUrl(uid))
            fileDownload(getBaseImgLookUrl(uid), name)
        }
    }

    const normFile = (e: UploadChangeParam) => {
        // let filetypes: string[] = ['.jpg', '.png', '.jpeg', 'svg', '.gif', '.pdf', '.doc']
        // let isType: boolean = false
        // let fileEnd: string = e?.file.name.substring(e?.file.name.lastIndexOf('.'))
        // isType = filetypes?.includes(fileEnd) ?? false
        if (e?.file.status === 'done') {
            if (e?.file?.response?.retCode == 0) {
                const data = {
                    name: e.file.name,
                    size: e.file.size,
                    type: e.file.type,
                    uid: e?.file?.response?.retData,
                }
                e.fileList = [data]
            } else {
                message.error('文件上传失败，请重新上传!')
                e.fileList?.pop()
            }
        }
        if (e.file.status === 'error') {
            message.error('文件上传失败，请重新上传!')
            e.fileList?.pop()
        }
        if (Array.isArray(e)) {
            return e
        }
        // if (!isType) {
        //     e.fileList?.pop()
        // }
        return e && e.fileList
    }
    const baseFormItem: IYhFormItemsBox = {
        title: title,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购单号",
                fieldName: "purchaseCode",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: <span className="click-span" onClick={goSupplier}>供应商名称</span>,
                fieldName: "supplierCode",
                options: transOptions(supplieAllList),
                // onSelectChange: supplierChange,
                rules: [{ required: true, message: "请选择供应商" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请填入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "采购类型",
                fieldName: "purchaseTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE),
                onSelectChange: purchaseTypeChange,
                rules: [{ required: true, message: "请选择采购类型" }]
            },
            ...(() => {
                if (typeForm[mode].purchaseTypeCode == 'purchase-type.zf') {
                    return [
                    //     {
                    //     type: FormItemTypeEnum.Select,
                    //     fieldLabel: "采购系数",
                    //     fieldName: "coefficient",
                    //     options: getSelectedDictOne(DictCodeEnmu.EXCHANGE_RATE),
                    //     rules: [{ required: typeForm[mode].purchaseTypeCode == 'purchase-type.zf', message: "请选择采购系数" }]
                    // },
                    {
                        type: FormItemTypeEnum.Input,
                        fieldLabel: "采购系数",
                        fieldName: "coefficient",
                        disable: true,
                    },
                ]
                } else {
                    return []
                }
            })(),
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商类型",
                fieldName: "supplierTypeName",
                disable: true,
                // treeData: getTreeSelectOne(DictCodeEnmu.SUPPLIER_TYPE),
                // onSelectChange: supplierTypeChange,
                // rules: [{ required: true, message: "请选择供应商类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "采购实体",
                fieldName: "bentityCode",
                allowClear: true,
                showSearch: true,
                options: beShopDs,
                onSelectChange: benttityChange,
                rules: [{ required: true, message: "请选择采购实体" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "商品类型",
                fieldName: "materialTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
                onSelectChange: materialTypeChange,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                onSelectChange: payTypeChange,
                rules: [{ required: true, message: "请输入结算类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 11,
                labelCol: { offset: 2 },
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "厂家订单号",
                fieldName: "orderCode",
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "是否直发",
                fieldName: "isDirect",
                allowClear: true,
                showSearch: true,
                options: [{ label: "是", value: 1 }, { label: "否", value: 0 }],
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "运输方式",
                fieldName: "deliveryMethod",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.LOGISTIC_MODE),
                onSelectChange: deliveryChange,
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "发票类型",
                fieldName: "invoiceTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
                onSelectChange: invoiceChange,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "createUser",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库管员",
                fieldName: "storekeeper",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "入库时间",
                fieldName: "stockIoTime",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算员",
                fieldName: "reckoner",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算时间",
                fieldName: "settleTime",
                hidden: more,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "核销时间",
                fieldName: "settleTime",
                hidden: more,
                disable: true,
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "supplierName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商类型code",
                fieldName: "supplierTypeCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "商品类型名称",
                fieldName: "materialTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购类型名称",
                fieldName: "purchaseTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "运输方式名称",
                fieldName: "deliveryMethodName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发票类型名称",
                fieldName: "invoiceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Other,
                fieldLabel: '附件',
                fieldName: 'md5',
                colClassName: 'ant-col-h-auto',
                span: 18,
                formItem: (
                    <Form.Item name="fileList" labelCol={{ span: 2 }} label="附件" valuePropName="fileList" getValueFromEvent={normFile} >
                        <Upload
                            disabled={mode === 'look'}
                            className="upload-box"
                            onPreview={onPreview}
                            name="file"
                            action={getBaseImgUploadUrl()}
                            data={getBaseUploadData()}
                            listType="picture"
                            beforeUpload={(file:any) => {
                                const isLt20M = file.size / 1024 / 1024 < 20
                                if (!isLt20M) {
                                    message.error('上传到文件不能超过20M')
                                    file.status='done'
                                }
                                return isLt20M
                            }}
                            iconRender={(file) => {
                                return (
                                    <FontIcon
                                        onClick={() => {
                                            onPreview(file)
                                        }}
                                        style={{ fontSize: '24px' }}
                                        type="icon-tupian"
                                    />
                                )
                            }}
                        >
                            {mode !== 'look' && (
                                <>
                                    <Button icon={<UploadOutlined />}>选择文件上传</Button>
                                    <span style={{ lineHeight: '25px', color: 'green', fontSize: '13px', marginLeft: '10px' }}>
                                        提醒：上传单个文件不能超过20M,当前仅.jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc等任何类型文件
                                    </span>
                                </>
                            )}
                        </Upload>
                    </Form.Item>
                ),
            },

        ]
    };



    const onChange = (changedValues: { [key: string]: any }) => {
        let newChangedValues = changedValues;
        if ("supplierCode" in changedValues && changedValues.supplierCode) {
            let { name, typeCode, typeName, contact, phone } = getFindItem(changedValues.supplierCode as string, supplieAllList)
            newChangedValues = { ...changedValues, contact, phone, supplierName: name, supplierTypeCode: typeCode, supplierTypeName: typeName }
        }
        mode === "add" ? updateAddFormVal(newChangedValues) : updatEditFormVal(newChangedValues)
        setTopButsControl(true)

    }

    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        disabled: !disabledFromBtn(),
        labelAlign: "right",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}