import { httpRequest } from "../../../utils/HttpRequest";
import {
    ISaleSoSearch,
    ISaleSoDetail,
    ISaleSoFields,
    ISaleSoList,
    Detailed,
    IInvalidate,
    ISaleSoPoSettleAndWhConfirm,
    ISaleSoSettleParams
} from "./saleSoTypes"

class SaleSoService {
    async page(searchVo: ISaleSoSearch) {
        const apiUrl = "/bcm/sale/findPage";
        return await httpRequest.post<ISaleSoList>(apiUrl, searchVo);
    }
    async totalQuantity(searchVo: ISaleSoSearch) {
        const apiUrl = "/bcm/sale/totalQuantity";
        return await httpRequest.post<number>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/sale/findById/${id}`;
        return await httpRequest.get<ISaleSoDetail>(apiUrl);
    }

    async insert(insertVo: ISaleSoFields) {
        const apiUrl = "/bcm/sale/insert";
        return await httpRequest.post<ISaleSoDetail>(apiUrl, insertVo);
    }
    async update(updateVo: ISaleSoFields) {
        const apiUrl = "/bcm/sale/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/bcm/sale/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: ISaleSoFields) {
        const apiUrl = "/bcm/sale/export";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //转销售单
    async transfer(params: ISaleSoFields) {
        const apiUrl = `/bcm/sale/transfer`;
        return await httpRequest.post<boolean>(apiUrl,params);
    }
    // //详情导入
    // async invalidate(){
    //     const apiUrl = "/bcm/sale/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/bcm/sale/detail/downloadTpl";
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //作废
    async invalidate(params: IInvalidate) {
        const apiUrl = "/bcm/sale/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //库房确认
    async warehousing(params: ISaleSoPoSettleAndWhConfirm) {
        const apiUrl = "/bcm/sale/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //销售结算
    async settle(params: ISaleSoSettleParams) {
        const apiUrl = "/bcm/sale/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //销售补单
    async supplementInsert(insertVo: ISaleSoFields) {
        const apiUrl = "/bcm/sale/supplementInsert";
        return await httpRequest.post<ISaleSoDetail>(apiUrl, insertVo);
    }
    //详情导出
    async detailsExport(params: ISaleSoFields) {
        const apiUrl = "/bcm/sale/detailsExport";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
}

export const saleSoService = new SaleSoService();