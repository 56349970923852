import { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { IPgProps, ISearchPage } from '@/types/ScmTypes';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants';
import { allocService, ISCMAllocSearchFields, ISCMAllocDetail } from './allocService';
import useGlobalModel from '@/model/globalModel';
import { convertRes2Blob } from '@/utils/exportFile';
import { transPgToPagination } from '@/utils/utils';

export const useAllocModel = createModel(function () {
	const {
		user: { shopId, shopCode },
		setLoadingInfo,
	} = useGlobalModel();
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};

	//查询条件
	const [searchParam, setSearchParam] = useState<ISCMAllocSearchFields>({});
	//列表数据源
	const [dataSource, setDataSource] = useState<ISCMAllocDetail[]>([]);
	//表格选中行Key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	//列表数据源分页参数
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	const [loading, setLoading] = useState<boolean>(false);

	const updateSearchParam = (updateParam: ISCMAllocSearchFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};

	//重置
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};

	const loadData = async (
		page: ISearchPage = initPgCfg,
		searchValue: ISCMAllocSearchFields = searchParam
	) => {
		setLoading(true);
		const searchParams = {
			shopCode,
			...searchValue,
			pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
			pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
		};
		let { retData } = await allocService.page(searchParams);
		const { records, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource((origin) => records?.map((item, index) => ({ ...item, key: index + '1' } ?? [])));
		setLoading(false);
	};

	//导出
	const exportAlloc = async (searchValue: ISCMAllocSearchFields = searchParam) => {
		setLoadingInfo({ loadingVisible: true, txt: '快速导出中' });
		try {
			const searchParams = {
				shopCode,
				...searchValue,
				pageSize: -1,
				pageNum: 1,
			};
			let result = await allocService.export(searchParams);
			result && setLoadingInfo({ loadingVisible: false, txt: '' });
			convertRes2Blob(result);
		} catch {
			setLoadingInfo({ loadingVisible: false, txt: '' });
		}
	};

	return {
		/***field***/
		searchParam,
		dataSource,
		selectedRowKeys,
		pgCfg,
		loading,
		setLoading,
		/***method***/
		setDataSource,
		setSelectedRowKeys,
		resetSearchParam,
		updateSearchParam,
		loadData,
		exportAlloc,
	};
});
