import React, { useState, useEffect } from "react";
import ReactECharts from 'echarts-for-react';
import { YhBox } from "@/components/YhBox";
import echarts from 'echarts/lib/echarts';
import { Button } from "antd";
import { homeService, ITransitFilder } from "./homeService"
import useGlobalModel from "@/model/globalModel";
import { formatNum } from "@/utils/utils";
export function BarContent() {
    const { shopAndOrg } = useGlobalModel()
    let { shopId } = shopAndOrg
    const [dayOption, setDayOption] = useState<any>([])
    const [EchartsWeek, setEchartsWeek] = useState<any>([])
    const [EchartsArr, setEchartsArr] = useState<any>([])
    const [echartsInfo, setEchartsInfo] = useState<any>([]);


    useEffect(() => {
        homeService.zhou(encodeURI(encodeURI(shopId))).then(res => {
            let { retData } = res
            filter(retData)
        })
    }, [shopId])
    const nameObj: any = {
        "appDetails": "预约量",
        "BreakDetails": "失约量",
        "FactoryDetails": "维修量",
        "SaleDetails": "销售量",
        "PurDetails": "采购量",
        "ReturnDetails": "回访量",
    }
    const filter = (res: any) => {
        let resObj = res[0];
        let arrInfo:any = [];
        let resTime = resObj["SaleDetails"].map((item: any) => item.day)
        setEchartsWeek(resTime)
        let newLineObj = Object.keys(resObj).map((item: any) => {
            let obj:any = {};
            let newData = resObj[item].map((item2:any) => {
                obj[item2.day] = {
                    num: item2.num,
                    amount: item2.amount
                }
                return `${item2.num}`
            })
            arrInfo.push(obj);

            return item = {
                name: nameObj[item],
                type: "bar",
                data: newData
            }
        }).filter(filItem=> filItem.name ==="维修量" || filItem.name ==="销售量" || filItem.name ==="采购量" )
        setEchartsInfo(arrInfo);
        setEchartsArr([...newLineObj])
    }
    const barOption = {
        // color: ['//#3CB2EF', //'#32C5E9', //'#9FE6B8', //'#FFDB5C', '//#FF9F7F', '#FB7293'],
        color: [ '#FFDB5C', '#32C5E9', '#9FE6B8'],
        // color: ['预约', '#进场', '失约', '销售', '采购', '回访'],
        //提示
        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            formatter: function (params:any) {
                let str = "";
                if (params && params.length > 0) {
                    params.forEach((item:any) => {
                        const { seriesName, value, seriesIndex, name } = item;
                        str += `<span style="font-size:10px">${item.seriesName}</span><br/><span style="font-size:10px">数量:${item.data}</span><span style="font-size:10px">&nbsp;&nbsp;金额:￥${formatNum(echartsInfo?.[seriesIndex]?.[name]?.["amount"])}</span><br/>`
                    })
                }
                return str;
            },            
            extraCssText: 'width: 170px'
        },
        //图列
        legend: {
            right: 60,
            top: 0,
            show: true,
            orient: 'horizontal',
            itemWidth: 16,
            itemHeight: 10,
            icon: "roundRect",
            borderRadius: 10,
            data: ['维修量', '销售量', '采购量'],
        },
        //画布
        grid: {
            left: '5%',
            right: '5%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: EchartsWeek

        },
        yAxis: {
            type: 'value',
        },
        label: {
            show: false
        },
        itemStyle: {
            barBorderRadius: [2, 2, 0, 0] //（顺时针左上，右上，右下，左下）
        },
        series: EchartsArr
    };

    return (
        <div style={{ display: "flex" }}>
            <YhBox
                title={<></>}
                style={{ marginBottom: 10, verticalAlign: 'top', height: 420, width: "100%" }}
            >
                <ReactECharts
                    echarts={echarts}
                    theme="light"
                    option={barOption}
                    style={{ height: 370 }}
                />
            </YhBox>
        </div>
    )
}