import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { usePsiModel } from "../psiModel";
import { IPsiDetail } from "../psiService";
import { formatNum } from "@/utils/utils";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys, onOpen
    } = usePsiModel();

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IPsiDetail> = [
        {
            title: '序号',
            width: 50,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '物料编码',
            fixed: "left",
            width: 160,
            dataIndex: 'materialCode',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '物料名称',
            fixed: "left",
            width: 160,
            dataIndex: 'materialName',
            render: (text: any) => <YhTootip text={text} />,
           // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '采购价格',
            width: 120,
            dataIndex: 'purchasePrice',
            render: (text) => '￥' + formatNum(text)
        },
        {
            title: '库存成本',
            width: 120,
            dataIndex: 'stockCost',
            render: (text) => '￥' + formatNum(text)
        },
        {
            title: '最新进价',
            width: 120,
            dataIndex: 'purchasePriceLatest',
            render: (text) => '￥' + formatNum(text)
        }, {
            title: '最新库存成本',
            width: 120,
            dataIndex: 'stockCostLatest',
            render: (text) => '￥' + formatNum(text)
        }, {
            title: '当前库存数',
            width: 120,
            dataIndex: 'currentNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "当前库存数",
            //     field: "currentNum"
            // })}>{text}</span>,
        },  {
            title: '进销存剩余数',
            width: 120,
            dataIndex: 'remainNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "进销存剩余数",
            //     field: "remainNum"
            // })}>{text}</span>,
        }, {
            title: '差异数',
            width: 120,
            dataIndex: 'differenceNum',
        }, 
        {
            title: '期初数',
            width: 120,
            dataIndex: 'beginNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "startVisible",
            // })}> {text}</span >,
        },
        {
            title: '补偿数',
            width: 120,
            dataIndex: 'compensateNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "compensateVisible",
            // })}> {text}</span >,
        },
        {
           //title: '公交预售数',
            title: '预售数',
            width: 120,
            dataIndex: 'presaleorderNum',
        },
        {
             title: '预退数',
             width: 120,
             dataIndex: 'preturnorderNum',
         },
        {
            title: '采购数(含期初和补偿)',
            width: 160,
            dataIndex: 'purhcaseNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "采购数",
            //     field: "purhcaseNum",
            //     type: "io_spd"
            // })}> {text}</span >,
        },   {
            title: '采退退货数',
            width: 120,
            dataIndex: 'preturnNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "采退退货数",
            //     field: "preturnNum",
            //     type: "io_sprd"
            // })}> {text}</span >,
        }, {
            title: '销售数',
            width: 120,
            dataIndex: 'saleNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "销售数",
            //     field: "saleNum",
            //     type: "io_ssd"
            // })}> {text}</span >,
        }, {
            title: '销售退货数',
            width: 120,
            dataIndex: 'sreturnNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "销售退货数",
            //     field: "sreturnNum",
            //     type: "io_ssrd"
            // })}> {text}</span >,
        }, 
        {
            title: '占用库存数',
            width: 120,
            dataIndex: 'occupyNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "occupyVisible",
            //     title: "占用库存数",
            //     field: "occupyNum",
            // })}> {text}</span >,
        },
        {
            title: '采退占用数',
            width: 120,
            dataIndex: 'preturnOccupyNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "occupyVisible",
            //     title: "采退占用数",
            //     field: "preturnOccupyNum",
            //     type: "occupy_prd"
            // })}> {text}</span >,
        },
        {
            title: '销售占用数',
            width: 120,
            dataIndex: 'saleOccupyNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "occupyVisible",
            //     title: "销售占用数",
            //     field: "saleOccupyNum",
            //     type: "occupy_ssd"
            // })}> {text}</span >,
        },
        {
            title: '预售占用数',
            width: 120,
            dataIndex: 'ysOccupyNum',
        },
        {
            title: '销订占用数',
            width: 120,
            dataIndex: 'sorderOccupyNum',
        },
        {
            title: '占用差异',
            width: 120,
            dataIndex: 'occupyDifference',
        }
        
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id),
            //     onContextMenu: (e) => {
            //         e.preventDefault();
            //         setSelectedRowKeys([record.id]);
            //     }
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 360px)" }}
        />
    )
}