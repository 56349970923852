import React, { useRef } from "react";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { branchDs, defaultMdCmParam } from "@/utils/constants";
import { BeOptModeEnum, useEntityModel } from "../entityModel";


export function DetailDrawer() {
    const { branch } = defaultMdCmParam;
    const {
        drawerVisible: visible,
        optMode,
        setDrawerVisible,
        selectedRow,
    } = useEntityModel();
    const formRef = useRef<IYhFormRef>();


    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        disabled: optMode === BeOptModeEnum.DETAIL,
        formValues: optMode === BeOptModeEnum.ADD ? { branch: branch } : selectedRow,
        items: [
            {
                type: FormItemTypeEnum.Input,
                ds: branchDs,
                fieldLabel: "分支",
                fieldName: "branch",
                disable: optMode === BeOptModeEnum.DETAIL || optMode === BeOptModeEnum.EDIT,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "版本",
                fieldName: "ver",
                span: 24,
                disable: optMode === BeOptModeEnum.DETAIL || optMode === BeOptModeEnum.EDIT,
                hidden: optMode === BeOptModeEnum.ADD,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "启用",
                fieldName: "enable",
                span: 24,
                disable: optMode === BeOptModeEnum.DETAIL || optMode === BeOptModeEnum.EDIT,
                hidden: optMode === BeOptModeEnum.ADD,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "编码",
                fieldName: "code",
                disable: optMode === BeOptModeEnum.DETAIL || optMode === BeOptModeEnum.EDIT,
                rules: optMode === BeOptModeEnum.EDIT ? [{ required: true, message: "编码不能为空" }] : undefined,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "名称",
                fieldName: "name",
                span: 24,
                rules: [{
                    required: true,
                    message: "名称不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "法人",
                fieldName: "corporation",
                span: 24,
                rules: [{
                    required: true,
                    message: "法人不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "电话",
                fieldName: "phone",
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "注册地址",
                fieldName: "address",
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "开户行",
                fieldName: "bank",
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户",
                fieldName: "bankAccount",
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "状态",
                fieldName: "status",
                span: 24,
                hidden: optMode === BeOptModeEnum.ADD,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            }
        ]
    };
    const title = optMode === BeOptModeEnum.ADD ? "新增经营实体"
        : optMode === BeOptModeEnum.EDIT ? "修改经营实体"
            : optMode === BeOptModeEnum.DETAIL ? "经营实体信息"
                : "";
    const okText = optMode === BeOptModeEnum.ADD ? "新增"
        : optMode === BeOptModeEnum.EDIT ? "修改"
            : "";

    const onOk = async () => {
        try {
            let formData = await formRef.current?.validateFields();
            // if (formData) {
            //     if (optMode === BeOptModeEnum.ADD) {
            //         let insertRet = await beService.insert(formData);
            //         if (insertRet.retData) {
            //             message.success("新增成功!");
            //             await loadData();
            //         } else {
            //             message.success("新增失败!" + insertRet.retMsg);
            //         }
            //     }
            //     if (optMode === BeOptModeEnum.EDIT) {
            //         let updateRet = await beService.update(formData);
            //         if (updateRet.retData) {
            //             message.success("修改成功!");
            //             await loadData();
            //         } else {
            //             message.success("修改失败!" + updateRet.retMsg);
            //         }
            //     }

            // }
        } catch (e) {
            console.log("数据校验失败");
        }
    }
    const onCancel = () => {
        setDrawerVisible(false);
    }
    const drawerConfig = {
        title,
        visible,
        onOk,
        onCancel,
        okText,
        showFooter: optMode !== BeOptModeEnum.DETAIL
    }
    return (
        <YHDrawer  {...drawerConfig}>
            <YhForm formRef={formRef} {...formConfig} />
        </YHDrawer>
    )
}
