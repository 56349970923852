import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import { useState, useEffect } from "react";

import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";

import { purchasePoService } from "./purchasePoService"
import { IPurchasePoSearch, IPurchasePoDetail, IPurchasePoInvalidate } from "./purchasePoType"
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { convertRes2Blob } from "@/utils/exportFile";
import { message } from "antd";

export interface IDescTotal {
    totalNum: number,
    totalAmount: number,
    paidAmount: number
}
export const usePurchasePoModel = createModel(function () {
    const { shopAndOrg, } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IPurchasePoSearch = {};
    const initDataSource: IPurchasePoDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        totalNum: 0,
        totalAmount: 0,
        paidAmount: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<IPurchasePoSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IPurchasePoDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IPurchasePoDetail>({});
    //列表数据源分页参数

    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<IPurchasePoDetail[]>([]);
    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IPurchasePoDetail>();

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            let { totalNum, payableAmount, paidAmount } = item
            newRowsDescTotal.totalNum = bigNumber.add(formatNum(totalNum), newRowsDescTotal.totalNum)
            newRowsDescTotal.totalAmount = bigNumber.add(formatNum(payableAmount), newRowsDescTotal.totalAmount)
            newRowsDescTotal.paidAmount = bigNumber.add(formatNum(paidAmount), newRowsDescTotal.paidAmount)
            // newRowsDescTotal.totalNum += +formatNum(totalNum)
            // newRowsDescTotal.totalAmount += +formatNum(payableAmount)
            // newRowsDescTotal.paidAmount += +formatNum(paidAmount)

        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IPurchasePoDetail)
    }, [selectedRowKeys, dataSource])

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        // console.log(updateParam)
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IPurchasePoSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: IPurchasePoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await purchasePoService.page(searchParams);
        const { records, statistics, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        console.log(newPg)
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)
    }
    const exportPurchasePo = async (searchValue: IPurchasePoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await purchasePoService.export(searchParams);
        convertRes2Blob(result)
    }
    const invalidate = async (params: IPurchasePoInvalidate) => {
        let { retData } = await purchasePoService.invalidate(params);
        if (retData) {
            loadData()
            message.success("作废成功")
        }
    }
    const detailsExport = async (searchValue: IPurchasePoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
            purchaseCode:(descRows.map((item)=>{return item.purchaseCode})).join()

        }
        let result = await purchasePoService.detailsExport(searchParams);
        convertRes2Blob(result)
    }
    const [print, setPrint] = useState<boolean>(false);
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        initPgCfg,
        descTotal,
        selectedRow,
        exportPurchasePo,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDescTotal,
        setSelectedRow,
        invalidate,
        detailsExport,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        print, setPrint,
        setHistoryRow,setHistoryVisible
    }
});