import React from "react";
import { usePurchaseRoModel } from "../purchaseRoModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePurchaseRoModel();
    const { getSelectedDictOne } = useDictModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "退货单号",
            fieldName: "returnCode"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            // options: [{ label: "正常", value: "0" }, { label: "非正常", value: "9" }]
            options: getSelectedDictOne(DictCodeEnmu.PURCHASE_RETURN),

        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "供应商",
            fieldName: "supplierName",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            // options: [{ label: "正常", value: "0" }, { label: "非正常", value: "9" }]
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
        },

    ]
    const searchMoreFields = [
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "结算方式",
        //     fieldName: "payMethodName",
        //     // options: [{ label: "正常", value: "0" }, { label: "非正常", value: "9" }]
        //     options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD),

        // },
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS)
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser"
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["结算开始", "结算结束"],
            rangePickerFieldName: ["statSettleTime", "endSettleTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },    
    ]
    return (
        <YhPageHeader
            title="采购退货"
            justify="end"
            size="small"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}