import React, { useRef } from "react";
import { useAccountModel } from "./accountModel";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { useDetailModel } from "../detailModel";
import { UserTypeEnum } from "@/types/FmsEnums";
import { accountService, creditFlagDs } from "../../../../AccountService";
import { message, Form, Button } from "antd";
import useGlobalModel from "@/model/globalModel";
import { useOrgModel } from "@/views/organization/org/orgModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import FontIcon from "@/components/YhIcon";
import { UploadOutlined } from "@ant-design/icons";
import { useLockFn } from "ahooks";

export const AdjustDrawer = () => {
    const { customer } = useDetailModel(model => [model.customer]);
    const { beShopDs } = useGlobalModel(({ beShopDs }) => [beShopDs]);
    const { getOrgTreeSelect } = useOrgModel(() => []);


    const {
        selectedData,
        adjustDrawerVisible,
        setAdjustDrawerVisible,
        onReset,
    } = useAccountModel(model => [model.adjustDrawerVisible]);

    const formRef = useRef<IYhFormRef>();
    const normFile = (e: UploadChangeParam) => {
        let filetypes: string[] = [".jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc"];
        let isType: boolean = false;
        let fileEnd: string = e?.file.name.substring(e?.file.name.lastIndexOf("."));
        isType = filetypes?.includes(fileEnd) ?? false;
        console.log('Upload event:', e);
        if (e?.file.status === 'done') {
            if (e?.file?.response?.retCode == 200) {
                const data = {
                    name: e.file.name,
                    size: e.file.size,
                    type: e.file.type,
                    uid: e?.file?.response?.retData,
                }
                // e.fileList = [data]
                e.fileList[e.fileList.length - 1] = data
            } else {
                message.error('文件上传失败，请重新上传!')
                e.fileList?.pop()
            }
        }
        if (e.file.status === 'error') {
            message.error('文件上传失败，请重新上传!')
            e.fileList?.pop()
        }
        if (Array.isArray(e)) {
            return e;
        }
        if (!isType) {
            e.fileList?.pop()
        }
        return e && e.fileList;
    };
    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        disabled: false,
        formValues: {
            ...selectedData,
            type: selectedData?.accountTypeCode === "account-type.credit" ? 1 : 0
        },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "分支",
                fieldName: "branch",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户",
                fieldName: "code",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户",
                fieldName: "name",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "授信类型",
                fieldName: "creditFlag",
                disable: true,
                span: 24,
                options: creditFlagDs(),
            },
            {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "机构",
                fieldName: "orgCode",
                disable: true,
                span: 24,
                treeDs: getOrgTreeSelect(DictCodeEnmu.ORG)
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "实体",
                fieldName: "beCode",
                disable: true,
                span: 24,
                options: beShopDs
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "授信额度",
                fieldName: "creditAmount",
                disable: true,
                span: 24,

            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "调额类型",
                fieldName: "type",
                span: 24,
                hidden: selectedData?.accountTypeCode !== "account-type.credit",
                options: [
                    {
                        label: "目标调额",
                        value: 0
                    },
                    {
                        label: "增量调额",
                        value: 1
                    }
                ]
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调整额度",
                fieldName: "adjustAmount",
                span: 24,
                rules: [
                    { required: true, message: '额度为必填项！' },
                ]
            },
            {
                type: FormItemTypeEnum.Other,
                fieldLabel: "附件",
                fieldName: "file",
                colClassName: "ant-col-h-auto",
                span: 24,
                formItem: <Form.Item
                    name="fileList"
                    label="附件"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: true,
                            message: '请上传附件信息'
                        }
                    ]}
                >
                    <Upload
                        name="file"
                        action={"https://file-server.laoniuche.cn/file/uploadFile"}
                        // headers={{
                        //     authorization: getToken()
                        // }}
                        listType="picture"
                        // disabled={editModel === 'look'}
                        beforeUpload={(file:any) => {
                            let fileEnd: string = file.name.substring(file.name.lastIndexOf("."));
                            // let filetypes: string[] = [".jpg", ".png", ".doc", ".xls", ".pdf", ".docx", ".xlsx", ".mp4", ".mp3"];
                            let filetypes: string[] = [".jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc"];
                            // var filetypes = [".jpg", ".png", ".rar", ".txt", ".zip", ".doc", ".ppt", ".xls", ".pdf", ".docx", ".xlsx", ".mp4", ".mp3"];
                            let isType = filetypes?.includes(fileEnd);
                            if (!isType) {
                                message.error('不支持该格式的上传,当前仅支持 图片、pdf和.doc 类型文件');
                            }
                            const isLt20M = file.size / 1024 / 1024 < 20;
                            if (!isLt20M) {
                                message.error('上传到文件不能超过20M')
                                    file.status='done';
                            }
                            return isType && isLt20M;
                        }}

                        iconRender={(file) => {
                            if (file?.type?.indexOf('image/') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-tupian2" />
                            if (file?.type?.indexOf('pdf') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-pdf" />
                            if (file?.type?.indexOf('msword') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-doc" />
                        }}
                    >
                        <Button icon={<UploadOutlined />}>选择文件上传</Button>
                    </Upload>
                </Form.Item>
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "remark",
                span: 24,
            },
        ]
    };
    const onOk = useLockFn(async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const { fileList = [], ...params } = formData
                if (isNaN(Number(formData.adjustAmount))) {
                    return message.error('额度输入错误！')
                }
                if (Number(formData.adjustAmount) < 0) {
                    return message.error('额度需要大于零！')
                }
                const reqData:any = {
                    ...params,
                    partnerTypeCode: UserTypeEnum.CUSTOMER,
                    partnerCode: customer.code,
                    accountCode: formData.code,
                    note: formData.remark
                };
                if (fileList.length > 0) {
                    const file = fileList[0];
                    if (!file?.uid) {
                        console.log(file);
                        return message.error('文件信息有误，请重新上传文件')
                    }
                    reqData.md5 = file?.uid;
                    reqData.fileName = file.name;
                    reqData.fileSize = file.size;
                }
                let insertRet = await accountService.adjust(reqData);
                if (insertRet.success) {
                    message.success("调额申请成功!");
                    onReset();
                    setAdjustDrawerVisible(false);
                } else {
                    message.error("调额失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log("数据校验失败");
        }
    });
    const onCancel = () => {
        setAdjustDrawerVisible(false);
    };
    return (
        <YHDrawer
            {...{
                title: "账户调额",
                visible: adjustDrawerVisible,
                onOk,
                onCancel,
                showFooter: true
            }}
        >
            <YhForm formRef={formRef} {...formConfig} />
        </YHDrawer>
    )
}
