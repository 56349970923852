import React, { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useLogModel } from "./logModel";
import { IAccountLogDetail } from "../../../../AccountTypes";
import { logResultRender } from "@/types/FmsRender";

const columns: ColumnsType<IAccountLogDetail> = [
    {
        align: "center",
        title: '分支',
        dataIndex: 'branch',
    },
    {
        align: "center",
        title: '日志编码',
        dataIndex: 'code',
    },
    // {
    //     title: '日志类型',
    //     dataIndex: 'partnerTypeCode',
    // },
    {
        align: "center",
        title: '账户编码',
        dataIndex: 'accountCode',
    },
    {
        align: "center",
        title: '账户名称',
        dataIndex: 'accountName',
    },
    {
        align: "center",
        title: '关联编码',
        dataIndex: 'linkCode',
    },
    {
        align: "center",
        title: '客户编码',
        dataIndex: 'partnerCode',
    },
    // {
    //     title: '客户名称',
    //     dataIndex: 'partnerName',
    // },
    // {
    //     title: '客户类型',
    //     dataIndex: 'partnerTypeCode',
    // },
    {
        align: "center",
        title: '日志类型',
        dataIndex: 'logType',
    },
    {
        align: "center",
        title: '日志内容',
        dataIndex: 'logContent',
    },
    {
        align: "center",
        title: '操作人',
        dataIndex: 'operator',
    },

    {
        align: "center",
        title: '操作时间',
        dataIndex: 'logTime',
    },
    {
        align: "center",
        title: '操作结果',
        dataIndex: 'result',
        render: logResultRender
    },
    // {
    //     title: '状态',
    //     dataIndex: 'status',
    //     width: 80,
    //     render: (v) => v === 0 ? "正常" : "异常"
    // },
    {
        align: "center",
        title: '备注',
        dataIndex: 'note',
    },

];

export function LogDataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useLogModel();


    return (
        <Table
            rowSelection={{
                type: "radio",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IAccountLogDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => {
                return {
                    onClick: event => {
                        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
                            setSelectedRowKeys([]);
                        } else {
                            setSelectedRowKeys([record.key]);
                        }
                    },
                };
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}
