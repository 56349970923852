import React, { useEffect } from "react";
import { Button, Tree, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { FormBox } from "./FormBox";
import { EventDataNode, DataNode } from "antd/lib/tree";
import { useOrgModel } from "../orgModel";
import { IVRMOrgDetail } from "../orgService";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { treeSource, loadData, setSelectedNodes, selectedNodes, syncData } = useOrgModel();

    useEffect(() => {
        loadData();
    }, [])


    const onSelect = (selectedKeys: React.Key[], info: {
        event: "select";
        selected: boolean;
        node: any;
        selectedNodes: DataNode[];
        nativeEvent: MouseEvent;
    }) => {
        let selectNode = info.selectedNodes as IVRMOrgDetail[];
        setSelectedNodes(selectNode);
    };


    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ORG_ORG_REFRESH}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => loadData().then(res => message.success("已刷新"))}
            >刷新</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ORG_ORG_SYNC}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => syncData()}
            >同步</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="机构组织管理" action={action}>
            <div className="dict-container flex">
                <div className="dict-list-box">
                    <Tree
                        defaultExpandedKeys={[selectedNodes[0]?.key]}
                        selectedKeys={[selectedNodes[0]?.key]}
                        titleRender={(node: any) => `${node.name}`}
                        onSelect={onSelect}
                        treeData={treeSource}
                    />
                </div>
                <div className="dict-from-box flex">
                    {selectedNodes.length > 0 ?
                        <FormBox />
                        :
                        <div className="dict-init-box flex flex-1 items-center justify-center">
                            组织机构
                        </div>
                    }
                </div>
            </div>
        </YhBox>
    )
}