import React, { useRef } from "react";
import { useAdjustModel } from "./adjustModel";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { accountService } from "../../../../AccountService";
import { message } from "antd";
import useGlobalModel from "@/model/globalModel";

export const AdjustDrawer = () => {

    const {
        selectedData,
        adjustDrawerVisible,
        setAdjustDrawerVisible,
        onReset,
    } = useAdjustModel(model => [model.adjustDrawerVisible]);

    const {
        user
    } = useGlobalModel(model => [model.user])

    const formRef = useRef<IYhFormRef>();
    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        disabled: false,
        formValues: {
            code: selectedData?.code,
            approver: user.userName
        },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "编号",
                fieldName: "code",
                disable: true,
                span: 24,
            },
            // {
            //     type: FormItemTypeEnum.Input,
            //     fieldLabel: "分支",
            //     fieldName: "branch",
            //     disable: true,
            //     span: 24,
            // },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "状态",
                fieldName: "status",
                span: 24,
                options: [
                    {
                        label: '审核通过',
                        value: 2
                    },
                    {
                        label: '审核驳回',
                        value: 8
                    }
                ],
                rules: [{
                    required: true,
                    message: "状态为必选项！"
                }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "审核人",
                disable: true,
                fieldName: "approver",
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            },
        ]
    };
    const onOk = async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const reqData = {
                    ...formData,
                };
                let insertRet = await accountService.adjustApproval(reqData);
                if (insertRet.success) {
                    message.success("审核成功!");
                    onReset();
                    setAdjustDrawerVisible(false);
                } else {
                    message.success("审核失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log("数据校验失败");
        }
    }
    const onCancel = () => {
        setAdjustDrawerVisible(false);
    };
    return (
        <YHDrawer
            {...{
                title: "账户调额审核",
                visible: adjustDrawerVisible,
                onOk,
                onCancel,
                showFooter: true
            }}
        >
            <YhForm formRef={formRef} {...formConfig} />
        </YHDrawer>
    )
}
