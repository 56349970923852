
import {IOverStockField,IRemindField,IDefiniyionField,IRemindSearch,IDefiniyionSearch } from "./settingsType"
import { httpRequest } from "@/utils/HttpRequest";

class Settingservice {
    //查询所有积压等级
    async overstock(shopCode: string) {
        const apiUrl = `/bcm/overstock/select/${shopCode}`;
        return await httpRequest.get<IOverStockField[]>(apiUrl);
    }
    //积压件定义一级修改
    async definiyion(searchVo: IDefiniyionSearch) {
        const apiUrl = `/bcm/overstock/definiyion`;
        return await httpRequest.post<IDefiniyionField>(apiUrl,searchVo);
    }  
    //积压件定义修改
    async definiyionUpdate(params: IOverStockField[]) {
        const apiUrl = `/bcm/overstock/update`;
        return await httpRequest.post<boolean>(apiUrl,params);
    }     
    //查询销售/采购积压提醒
    async remind(searchVo: IRemindSearch) {
        const apiUrl = `/bcm/overstock/query/remind`;
        return await httpRequest.post<IRemindField>(apiUrl,searchVo);
    }  
    //销售/采购积压提醒修改
    async remindUpdate(params: IRemindField) {
        const apiUrl = `/bcm/overstock/update/remind`;
        return await httpRequest.post<boolean>(apiUrl,params);
    }      
    
}
export const settingservice = new Settingservice();