import React, {  } from "react";
import { Button, Upload } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useVehicleModel } from "../vehicleModel";
import { useNavModel } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const {
        selectedRowKeys,
        updateEditFormVal,
        loadData,
        exportData,
        downloadTpl,
        setSearchParam,
        resetSearchParam,
        setSelectedRowKeys
    } = useVehicleModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let vehicleEditKey = "13-5-2-2";
        let isFindNav = findNav(vehicleEditKey);
        if (!isFindNav) {
            addNav(vehicleEditKey, { id: selectedRowKeys[0] as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "车辆跳转详情",
                type: "warning",
                text: "您还有车辆详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(vehicleEditKey, { id: selectedRowKeys[0] as string, mode: "edit" });
                    updateEditFormVal(null);
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "13-5-2-2";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRowKeys[0] as string, mode: "look", },
            true
        );
    }
    const goCustomer = () => {
        addNav("13-5-1");
        setSearchParam({})
        resetSearchParam()
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_V_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { addNav("13-5-2-1", { id: "", mode: "add" }) }}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_V_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_V_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_V_IM}>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/vehicle/import`, loadData)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_V_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { exportData() }}
            >导出</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_V_DLOAD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { downloadTpl() }}
            >下载模板</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_V_BACK_C}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => goCustomer()}
            >返回客户</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="车辆列表" action={action}>
            <DataTable />
        </YhBox>
    )
}
