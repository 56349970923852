import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useStockStatisticModel } from "../stockStatisticModel";
import { IStockStatisticDetail } from "../stockStatisticService"
import { formatNum } from "@/utils/utils";
import YhTootip from "@/components/YhTootip";
import { spawn } from "child_process";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
        setHistoryVisible,
        setHistoryRow,
        setOccupyStockModel,
        relocationList, setRelocationList,
        setRelocationVisible,
        setRecordsSearch,
        setRecordsVisible,
        setPinRecord,
        setAppintRecord
    } = useStockStatisticModel();
    const onHistoryModel = (record: IStockStatisticDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const columns: ColumnsType<IStockStatisticDetail> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => onHistoryModel(record)}/>,
           // render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,

        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            // render: (text: any) => <YhTootip text={text} />,
            render: (text: any, record: any, index: number) => <span>{text}<span style={{color:'red',fontSize:'10px',marginBottom:'5px',bottom:'7px',position:'relative' }}>{!record.result?'':record.result=='盘盈'?'盈':record.result=='相符'?'':'亏'}</span><span style={{color:'red',fontSize:'10px',marginBottom:'5px',bottom:'7px',position:'relative' }}>{!record.differenceNum?'':record.differenceNum}</span></span>,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '移库记录',
            width: 100,
            dataIndex: 'num',
            render: (text: string, record: any) => <span onClick={() => {
                if (record.num === 0) {
                    return false
                } else {
                    setRecordsSearch({ stockId: record.id })
                    setRecordsVisible(true)
                }

            }} className="click-span">{text}</span>
        }, {
            title: '品牌',
            width: 160,
            dataIndex: 'brandName',
        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }, {
            title: '可用库存',
            width: 160,
            dataIndex: 'availableStock',
        }, {
            title: '仓库',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '初始库存',
            width: 160,
            dataIndex: 'inventoryInitial',
        }, {
            title: '库存数量',
            width: 160,
            dataIndex: 'inventoryNum',
        }, {
            title: '占用库存',
            width: 160,
            dataIndex: 'inventoryOccupied',
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title="占用库存查看"><p className="ellipsis click-span" onClick={(e) => {
                e.stopPropagation();
                setOccupyStockModel(record);
                return;

            }}>{text}</p></Tooltip>,
        }, 
        // {
        //     title: '销订数量',
        //     width: 160,
        //     dataIndex: 'xdsum',
        //     render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title="销订数量查看"><p className="ellipsis click-span" onClick={(e) => {
        //         e.stopPropagation();
        //         setPinRecord(record);
        //         return;

        //     }}>{text}</p></Tooltip>,            
        // }, {
        //     title: '预约数量',
        //     width: 160,
        //     dataIndex: 'yysum',
        //     render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title="预约数量查看"><p className="ellipsis click-span" onClick={(e) => {
        //         e.stopPropagation();
        //         setAppintRecord(record);
        //         return;

        //     }}>{text}</p></Tooltip>,            

        // }, 
        {
            title: '进货价（参考价）',
            width: 160,
            dataIndex: 'purchasePrice',
            render:(text)=>`￥${formatNum(text)}`,
        }, {
            title: '销售价（参考价）',
            width: 160,
            dataIndex: 'retailPrice',
            render:(text)=>`￥${formatNum(text)}`,
        }, {
            title: '进货价（最近进价）',
            width: 160,
            dataIndex: 'latestPurPrice',
            render:(text)=>`￥${formatNum(text)}`,
        }, {
            title: '进货价（平均价）',
            width: 160,
            dataIndex: 'averagePurchasePrice',
            render:(text)=>`￥${formatNum(text)??0}`,
        }, {
            title: '库存成本（最近进价）',
            width: 160,
            dataIndex: 'latestAmount',
            render:(text)=>`￥${formatNum(text)}`,
        }, {
            title: '库存成本（平均价）',
            width: 160,
            dataIndex: 'averageAmount',
            render:(text)=>`￥${formatNum(text)??0}`,
        }, {
            title: '零售价（最近）',
            width: 160,
            dataIndex: 'lateSalePrice',
            render:(text)=>`￥${formatNum(text)}`,
        }, {
            title: '库存上限',
            width: 160,
            dataIndex: 'limitUpper',
        }, {
            title: '库存下限',
            width: 160,
            dataIndex: 'limitLower',
        }, {
            title: '积压标记',
            width: 160,
            dataIndex: 'overstock',
        // }, {
        //     title: '库龄',
        //     width: 160,
        //     dataIndex: 'limitLower',
        }, {
            title: '最早进货时间',
            width: 160,
            dataIndex: 'stockTime',

        }, {
            title: '所属门店',
            width: 160,
            dataIndex: 'shopName',
            // }, {
            //     title: '新仓库',
            //     width: 120,
            //     fixed: "right",
            //     dataIndex: 'warehouseNameNew',
            //     render: () => '新仓库'
            // }, {
            //     title: '新货位',
            //     width: 120,
            //     fixed: "right",
            //     dataIndex: 'warehouseNameNew',
            //     render: () => '新货位'
            // }, {
            //     title: '移库数量',
            //     width: 120,
            //     fixed: "right",
            //     dataIndex: 'relocationNum',
            //     render: () => '移库数量'
        }, 
        // {
        //     title: '操作',
        //     width: 120,
        //     fixed: "right",
        //     render: (text: string, record: any) => <span className="click-span" onClick={() => {
        //         setSelectedRowKeys([record.id as string]);
        //         setRelocationList([record])
        //         setRelocationVisible(true)
        //     }}>移库</span>
        // }
    ];

    useEffect(() => {
        loadData().then();
    }, [])
    //点击行选高亮
    const onRow = (record: IStockStatisticDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = relocationList.findIndex((item) => (item.id === id))
        let newRelocationList = [...relocationList];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newRelocationList.push(record)
            setRelocationList(newRelocationList)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newRelocationList.splice(findIndexDesc, 1);
            setRelocationList(newRelocationList);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    return (
        <>
            <Table
                loading={loading}
                style={{ cursor: "pointer" }}
                rowKey={record => record.id as string}
                rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys: React.Key[], selectedRows: IStockStatisticDetail[]) => {
                        setSelectedRowKeys(selectedRowKeys);
                        setRelocationList(selectedRows)
                    },
                    selectedRowKeys
                }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize }).then();
                    }
                }}
                onRow={record => {
                    return {
                        onClick: () => onRow(record),
                        onContextMenu: (e) => {
                            e.preventDefault();
                            setSelectedRowKeys([record.id as string]);
                            setRelocationList([record])
                        }
                    };
                }}
                scroll={{ x: 'max-content', y: 500 }}
            />
        </>
    )
}
