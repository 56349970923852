import React from "react";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel from "@/model/globalModel";
import { useAcctUnitModel } from "../acctUnitModel";
import Item from "antd/lib/list/Item";


export function PageHeader () {
    const { shopDs, beDs } = useGlobalModel();
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useAcctUnitModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "内部核算单位",
            fieldName: "name",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "关联门店",
            fieldName: "shop",
            options: shopDs
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "关联实体",
            fieldName: "bentity",
            options: []
            // options: beDs.map(item => ({ ...item, value: item.id }))
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            fieldName: "status",
            options: [{ label: "启用", value: 1 }, { label: "禁用", value: 0 }]
        }
    ]


    return (
        <YhPageHeader
            title="内部核算单位"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}