import React, { useEffect } from "react";
import { useAccountModel } from "./accountModel";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { AccountDataTable } from "./DataTable";
import { OpenAccountDrawer } from "./OpenAccountDrawer";
import { AdjustDrawer } from "./AdjustDrawer";
import { RechargeDrawer } from "./RechargeDrawer";
import { TransferDrawer } from "./TransferDrawer";
import { WriteoffDrawer } from "./WriteoffDrawer";
import { UserTypeEnum } from "@/types/FmsEnums";
import { useGlobalModel } from "@/model/globalModel";
import { accountShopService } from "@/views/account/AccountShopService";
import { useDetailModel } from "../detailModel";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const AccountDataBox = () => {
    const {
        supplierCode,
        dataSource,
        selectedRowKeys,
        onReset,
        setOpenDrawerVisible,
        setAdjustDrawerVisible,
        setRechargeDrawerVisible,
        setTransferDrawerVisible,
        setWriteoffDrawerVisible,
        loadData,
        setSelectedData,
        setSupplierUserList,
        setSupplierUserDs,
    } = useAccountModel();
    const { user: { shopCode } } = useGlobalModel();
    const { supplier } = useDetailModel();

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
        !!selectedRowKeys.length && accountShopService.find({
            partnerCode: supplierCode,
            partnerTypeCode: UserTypeEnum.SUPPLIER,
            shopCode
        }).then(result => {
            setSupplierUserList(result.retData)
            setSupplierUserDs(
                result.retData.map(item => {
                    return {
                        label: item.name ?? '',
                        value: item.code ?? ''
                    }
                })
            )
        })
    }, [selectedRowKeys]);

    const onOpenAccount = () => {
        setOpenDrawerVisible(true);
    }
    const onAdjust = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            setAdjustDrawerVisible(true);
        } else {
            message.error("请选择调额账户!");
        }
    }
    const onRecharge = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            setRechargeDrawerVisible(true);
        } else {
            message.error("请选择充值账户!");
        }
    }
    const onTransfer = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            setTransferDrawerVisible(true);
        } else {
            message.error("请选择转出账户!");
        }
    }

    const onWriteoff = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            setWriteoffDrawerVisible(true);
        } else {
            message.error("请选择核销账户!");
        }
    }

    const action = (
        <>
            <div>
                <YhAuth resCode={ResourceAuthEnum.ACC_S_VW_AC_INIT}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={onOpenAccount}
                    >
                        开户
                </Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.ACC_S_VW_AC_ADJ}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={onAdjust}
                    >
                        调额
                </Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.ACC_S_VW_AC_CHARG}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={onRecharge}
                    >
                        充值
                </Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.ACC_S_VW_AC_TRANS}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={onTransfer}
                    >
                        转账
                </Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.ACC_S_VW_AC_W_OFF}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={onWriteoff}
                    >
                        核销账单
                </Button>
                </YhAuth>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onReset}
                >
                    刷新
                </Button>
            </div>
        </>
    );

    // const desc: IDescription[] = [
    //     {
    //         label: "可用额度合计",
    //         value: accountSummaryVo?.totalAvailableAmount?.toFixed(2) ?? '0.00',
    //         color: DescriptionColorEnum.green
    //     },
    //     {
    //         label: "欠款总计",
    //         value: accountSummaryVo?.totalDebtAmount?.toFixed(2) ?? '0.00',
    //         color: DescriptionColorEnum.red
    //     },
    // ]

    const desc: IDescription[] = [
        {
            label: "可用额度合计",
            value: supplier?.totalAvailableAmount?.toFixed(2) ?? 0.00,
            color: DescriptionColorEnum.green
        },
        {
            label: "欠款总计",
            value: supplier?.totalDebtAmount?.toFixed(2) ?? 0.00,
            color: DescriptionColorEnum.red
        },
    ]
    return (
        <>
            <YhBox title={"账户列表"} action={action} descriptions={desc}>
                <AccountDataTable />
            </YhBox>

            <OpenAccountDrawer />
            <AdjustDrawer />
            <RechargeDrawer />
            <TransferDrawer />
            <WriteoffDrawer />
        </>
    )
}
