import { httpRequest } from "../../../utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {
    IPurchaseRoSearch,
    IPurchaseRoDetail,
    IPurchaseRoFields,
    Detailed,
    IPurchaseRoList,
    IPurchaseRoSettleAndWhConfirm,
    IPurchaseDetailsSearch,
    IPurchaseDetails,
} from "./purchaseRoType"

class PurchaseRoService {
    async page(searchVo: IPurchaseRoSearch) {
        const apiUrl = "/bcm/preturn/findPage";
        return await httpRequest.post<IPurchaseRoList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/preturn/findById/${id}`;
        return await httpRequest.get<IPurchaseRoDetail>(apiUrl);
    }

    async insert(insertVo: IPurchaseRoFields) {
        const apiUrl = "/bcm/preturn/insert";
        return await httpRequest.post<IPurchaseRoDetail>(apiUrl, insertVo);
    }
    async update(updateVo: IPurchaseRoFields) {
        const apiUrl = "/bcm/preturn/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/bcm/preturn/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: IPurchaseRoFields) {
        const apiUrl = "/bcm/preturn/export";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //作废
    async invalidate(params: IPurchaseRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/preturn/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //退货结算
    async settle(params: IPurchaseRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/preturn/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //采购退货查询详情
    async details(params: IPurchaseDetailsSearch) {
        const apiUrl = "/bcm/purchase/details/findPage";
        return await httpRequest.post<IPage<IPurchaseDetails>>(apiUrl, params);
    }
    // //详情导入
    // async import(){
    //     const apiUrl = "/bcm/preturn/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    //库房确认
    async warehousing(params: IPurchaseRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/preturn/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: IPurchaseRoFields) {
        const apiUrl = "/bcm/preturn/detailsExport";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
}

export const purchaseRoService = new PurchaseRoService();