import React from "react";
import { usePurchasePoModel } from "../purchasePoModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePurchasePoModel();
    const { getSelectedDictOne } = useDictModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "采购单号",
            fieldName: "purchaseCode",
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "调拨单号",
            fieldName: "allocationCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "供应商",
            fieldName: "supplierName",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            // options: [{ label: "正常", value: "0" }, { label: "非正常", value: "9" }]
            options: getSelectedDictOne(DictCodeEnmu.PURCHASE_STATUS),

        },
      
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]
    const searchMoreFields = [
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "采购类型",
        //     fieldName: "purchaseTypeCode",
        //     options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE),
        // },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "商品类型",
        //     fieldName: "materialTypeCode",
        //     options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
        // },
      
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            // options: [{ label: "正常", value: "0" }, { label: "非正常", value: "9" }]
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser"
        },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "结算方式",
        //     fieldName: "payMethodCode",
        //     // options: [{ label: "正常", value: "0" }, { label: "非正常", value: "9" }]
        //     options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD),
        // },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "结算类型",
        //     fieldName: "payType",
        //     options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)
        // },
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS)
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["结算开始", "结算结束"],
            rangePickerFieldName: ["statSettleTime", "endSettleTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },    
    ]
    return (
        <>
            <YhPageHeader
                title="采购单"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                searchMoreFields={searchMoreFields}
                onChange={updateSearchParam}
                // handleSearch={()=>{console.log(searchParam)}}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
        </>
    );
}