import React, { useEffect } from "react";
import { Table, Space, Switch } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTechnicianModel } from "../technicianModel";
import { ITechnicianDetail } from "../technicianType";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, onDisabled,
        loadData, setSelectedRowKeys,
    } = useTechnicianModel();
    const columns: ColumnsType<ITechnicianDetail> = [
        {
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`
        }, {
            title: '姓名',
            dataIndex: 'name',
            width: 140,
        }, {
            title: '手机',
            dataIndex: 'phone',
            width: 150,
        }, {
            title: '职位',
            dataIndex: 'title',
            width: 140,
        }, {
            title: '分配点数',
            dataIndex: 'assignPoints',
            width: 140,
        }, {
            title: '创建人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '操作',
            dataIndex: 'enable',
            width: 140,
            render: (text, record) => (
                <Space size="middle">
                    <Switch checked={record.enable === 1} onChange={(checked) => {
                        onDisabled(record.id as string, checked);
                    }} />
                </Space>
            ),

        }
    ];
    useEffect(() => {
        loadData()
    }, []);

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            rowSelection={{
                selectedRowKeys: selectedRowKeys, //记录选中
                onChange: (selectedRowKeys: React.Key[], selectedRows: ITechnicianDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                }
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: 500 }}

        />
    )
}
