import { createModel } from "hox";
import { useState, useEffect, Key } from "react";
import useGlobalModel from "@/model/globalModel";
import { IPreSaleDetail, IPreSaleDetailsList } from "../../../../busBusinessType"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { globalPrompt } from "@/components/message";
import { transPgToPagination, formatNum ,bigNumber} from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { useLockFn } from "ahooks";
import { IStockStatisticSearch } from "@/views/stock/statistic/stockStatisticService";
import { DictCodeEnmu, sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";
import moment from "moment";
import { convertRes2Blob } from "@/utils/exportFile";
import { useDetailModel } from "../../detailModel";
import { message } from "antd";
import { IPurchaseStock } from '@/views/sale/order/saleOrderTypes';
import { saleOrderService } from '@/views/sale/order/saleOrderService';


//这里为了继承一个弹框列表的自定义属性值
export interface IPreSaleDetailModel extends IPurchaseStock {
    num: number,
    price?: number,
    amount?: number
}
export type IPreSaleDesc = {
    item: number,//预售项数
    number: number,//预售数量
    amount: number//预售金额

}
export const usePreSaleDetailModel = createModel(function () {
    const { busBusiness: { warehouseCode, warehouseName } } = useDetailModel()
    const { user, shopAndOrg, warehouseList } = useGlobalModel()
    const [materialModel, setMaterialModel] = useState<boolean>(false); //维修配件弹框
    const initDesc: IPreSaleDesc = {
        item: 0,
        number: 0,
        amount: 0
    }
    const initForm = {}
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initMaterialSearchParam = {};

    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IPreSaleDetail>({ ...initForm })
    //新增维修用料详情列表
    const [detailMaterialAddList, setDetailMaterialAddList] = useState<IPreSaleDetailsList[]>([])
    //新增维修用料详情列表选中行
    const [detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialAddDataRow, setDetailMaterialAddDataRow] = useState<IPreSaleDetailsList[]>([]);
    const [addDesc, setAddDesc] = useState<IPreSaleDesc>(initDesc)

    //编辑数据
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState<IPreSaleDetail>({})
    //编辑维修用料详情列表
    const [detailMaterialEditList, setDetailMaterialEditList] = useState<IPreSaleDetailsList[]>([])
    //编辑维修用料详情列表选中行
    const [detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialEditDataRow, setDetailMaterialEditDataRow] = useState<IPreSaleDetailsList[]>([]);
    const [editDesc, setEditDesc] = useState<IPreSaleDesc>(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IPreSaleDetail>({})
    //查看维修用料详情列表
    const [detailMaterialLookList, setDetailMaterialLookList] = useState<IPreSaleDetailsList[]>([])
    const [lookDesc, setLookDesc] = useState<IPreSaleDesc>(initDesc)

    //配件列表配置
    const [workMaterialDataSource, setWorkMaterialDataSource] = useState<IPreSaleDetailModel[]>([])
    const [searchWorkMaterialData, setSearchWorkMaterialData] = useState<IStockStatisticSearch>(initMaterialSearchParam)
    const [workMaterialPgCfg, setWorkMaterialPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataMaterialSource, setAddDataMaterialSource] = useState<IPreSaleDetailModel[]>([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let newDesc: IPreSaleDesc = initDesc;
        detailMaterialAddList && detailMaterialAddList.forEach((item, index) => {
            newDesc.item = bigNumber.add(newDesc.item ,1)
            newDesc.number = bigNumber.add(newDesc.number,Number(item.num))
            newDesc.amount = bigNumber.add(newDesc.amount,Number(item.totalAmount))
            // newDesc.item += 1;
            // newDesc.number += Number(item.num);
            // newDesc.amount += Number(item.totalAmount);

        })
        setAddDesc(newDesc);

    }, [detailAddForm, detailMaterialAddList])
    useEffect(() => {
        let newDesc: IPreSaleDesc = initDesc;
        detailMaterialEditList && detailMaterialEditList.forEach((item, index) => {
            newDesc.item  = bigNumber.add(newDesc.item,1)
            newDesc.number = bigNumber.add(newDesc.number,Number(item.num))
            newDesc.amount = bigNumber.add( newDesc.amount,Number(item.totalAmount))
            // newDesc.item += 1;
            // newDesc.number += Number(item.num);
            // newDesc.amount += Number(item.totalAmount);
        })
        setEditDesc(newDesc);

    }, [detailEditForm, detailMaterialEditList])
    useEffect(() => {
        let newDesc: IPreSaleDesc = initDesc;
        detailMaterialLookList && detailMaterialLookList.forEach((item, index) => {
            newDesc.item = bigNumber.add( newDesc.item,1)
            newDesc.number = bigNumber.add(newDesc.number,Number(item.num))
            newDesc.amount = bigNumber.add(newDesc.amount,Number(item.totalAmount))

            // newDesc.item += 1;
            // newDesc.number += Number(item.num);
            // newDesc.amount += Number(item.totalAmount);
        })
        setLookDesc(newDesc);
    }, [detailLookForm, detailMaterialLookList])


    //新增表单缓存
    const updateAddFormVal = (val: IPreSaleDetail) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updateEditFormVal = (val: IPreSaleDetail) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await busBusinesstService.preSaleOne(id);
        const { item, deliveryTime, enterTime, ...from } = retData
        const newMaterialDetails = item?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newFrom = {
            ...from,
            enterTime: enterTime ? moment(enterTime) : null,
            deliveryTime: deliveryTime ? moment(deliveryTime) : null
        }
        if (retData && pattern === "edit") {
            setDetailMaterialEditList(newMaterialDetails as IPreSaleDetailsList[] ?? [])
            setDetailEditForm(newFrom)
            setTopButsControl(false)
        } else if (retData && pattern === "look") {
            setDetailLookForm(newFrom);
            setDetailMaterialLookList(newMaterialDetails as IPreSaleDetailsList[] ?? [])
        }
    }
    //关闭维修用料弹框
    const resetWorkMaterialloadData = async () => {
        setSearchWorkMaterialData({});
        workMaterialloadData(initPgCfg);
    }
    const workMaterialloadData = async (page?: ISearchPage, searchValue: IStockStatisticSearch = searchWorkMaterialData) => {
        setLoading(true)
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            age: 1,
            status: `${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            pageSize: page?.pageSize ?? workMaterialPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workMaterialPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await saleOrderService.stock(searchParams);
        const { records, ...pg } = retData;
        const newRecords:any = records.map(item => {
            let { lateSalePrice, retailPrice } = item
            return {
                ...item,
                num: item.availableStock??1,
                lateSalePrice: lateSalePrice === 0 || lateSalePrice === null ? retailPrice : lateSalePrice,
                amount: bigNumber.toFixed(bigNumber.times(item.availableStock??1,lateSalePrice === 0 || lateSalePrice === null ? (retailPrice as number) : (lateSalePrice as number)),2) 
            }
        })
        setWorkMaterialPgCfg(transPgToPagination(pg));
        setWorkMaterialDataSource(newRecords);
        setLoading(false)
    }
    //筛选维修用料模块详情 详情和新增的列表已有数据的方法
    const modelMaterialScreenMethod = async (modelList: IPreSaleDetailModel[], detailList: IPreSaleDetailsList[], source: string, mode: string, type: 'import' | 'select' = 'select') => {
        const repetition: string[] = [];
        const newDetailData: IPreSaleDetailsList[] = [];
        //source有值时 根据customerCode匹配对应的仓库 反之直接带出预售销售详情仓库名称仓库编码
        const sourceWarehouse = source ? warehouseList.find((item: any) => item.customerCode === (mode === "add" ? detailAddForm.customerCode : detailEditForm.customerCode)) : { code: warehouseCode, name: warehouseName }
        modelList.forEach(Item => {
            // let isSelect = undefined; // detailList && detailList.find(item => item.materialCode === Item.materialCode);
            let isSelect = detailList && detailList.find(item => item.detailsId === Item.id);
            let { id, ids, materialCode, materialName, inventoryNum, availableStock, num, lateSalePrice, averagePurchasePrice, purchasePrice, amount, price, latestPurPrice, ...params } = Item
            let newPrice = averagePurchasePrice ?? purchasePrice
            let nowPrice = type === 'import' ? price : (lateSalePrice ?? price);
            if (isSelect === undefined) {
                newDetailData.push({
                    ...params,
                    discountRate: 100,
                    id: "",
                    avallable: availableStock,
                    materialCode,
                    materialName,
                    num,
                    price: nowPrice,
                    amount,
                    saleNum: 0,
                    totalAmount: amount,
                    stockId: id,
                    detailsId: id,
                    warehouseCode: Item.warehouseCode,
                    warehouseName: Item.warehouseName,
                    storageWarehouseCode: sourceWarehouse?.code,
                    storageWarehouseName: sourceWarehouse?.name,
                    costPrice: latestPurPrice,
                    latestPurPrice,
                    purchaseId: id,
                    //costAmount: +formatNum(Number(latestPurPrice) * num),
                    costAmount: +bigNumber.toFixed(bigNumber.times(latestPurPrice as number, num), 2)
                });
            } else {
                repetition.push(Item.materialName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到维修用料详情列表 source用来判断是否来源于列表
    //是根据客户匹配对应仓库名称和编码 否直接拿预售销售详情里面的数据
    const transformsToPoMaterialDetailList = async (mode: string, source?: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialAddList, source as string, mode)
            setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialEditList, source as string, mode)
            setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailMaterialDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailMaterialAddList.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (mode === "edit") {
            const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialEditList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialEditDataRowKeys([]);
            setDetailMaterialEditDataRow([]);
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        let { amount: totalAll } = mode === "edit" ? editDesc : addDesc;
        totalAll = +formatNum(totalAll);//应收
        let goodsTotal: number = 0;
        goods.forEach(item => {
            let { totalAmount } = item as { totalAmount: number }
            goodsTotal += totalAmount
        })
        if (+formatNum(goodsTotal) !== totalAll) {
            message.warning(`详情公交预售总金额金额${+formatNum(goodsTotal)}与表单公交预售总金额金额不符`);
            return false;
        }
        return true
    }
    //新增预售单
    const insertDetail = useLockFn(async (params: IPreSaleDetail) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            item: detailMaterialAddList,
            createUser: user.username,
            // storekeeper:user.username
        }
        let { retData } = await busBusinesstService.preSaleInsert(newParams)
        return retData
    })
    //编辑预售单
    const editDetail = useLockFn(async (params: IPreSaleDetail) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            item: detailMaterialEditList,
            updateUser: user.username
        }
        let { retData } = await busBusinesstService.preSaleUpdate(newParams)
        return retData
    })
    //
    //转销售单
    const transfer = useLockFn(async (params: IPreSaleDetail) => {
        let { id, ...form } = params
        let newParams = {
            ...form,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            // item: detailMaterialEditList,
            updateUser: user.username,
            createUser: user.username
        }
        let { retData } = await busBusinesstService.preSaleTransfer(newParams)
        return retData
    })
    const warehousing = useLockFn(async () => {
        let { id, orderCode } = detailEditForm as { id: string, orderCode: string }
        let { retData } = await busBusinesstService.preSaleWarehousing({ id, orderCode, storekeeper: user.username })
        return retData
    })
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddForm(initForm)
        setDetailMaterialAddList([]);
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailMaterialEditList([]);
    }
    const downloadTpl = async () => {
        let result = await busBusinesstService.preSaleDownloadTpl();
        convertRes2Blob(result)
    }
    const [wsConfirmModal, setWsConfirmModal] = useState<boolean>(false)
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IPreSaleDetailsList>({})
    return {
        initForm,
        materialModel,
        initPgCfg,
        initMaterialSearchParam,
        detailAddForm,
        detailMaterialAddList,
        detailMaterialAddDataRowKeys,
        detailMaterialAddDataRow,
        addDesc,
        detailEditForm,
        detailMaterialEditList,
        detailMaterialEditDataRowKeys,
        detailMaterialEditDataRow,
        editDesc,
        detailLookForm,
        detailMaterialLookList,
        lookDesc,
        workMaterialDataSource,
        searchWorkMaterialData,
        workMaterialPgCfg,
        addDataMaterialSource,
        setMaterialModel,
        setDetailAddForm,
        setDetailMaterialAddList,
        setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        setAddDesc,
        setDetailEditForm,
        setDetailMaterialEditList,
        setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        setEditDesc,
        setDetailLookForm,
        setDetailMaterialLookList,
        setLookDesc,
        setWorkMaterialDataSource,
        setSearchWorkMaterialData,
        setWorkMaterialPgCfg,
        setAddDataMaterialSource,
        updateAddFormVal,
        updateEditFormVal,
        getDetailEditOne,
        resetWorkMaterialloadData,
        workMaterialloadData,
        modelMaterialScreenMethod,
        transformsToPoMaterialDetailList,
        detailMaterialDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        downloadTpl,
        wsConfirmModal, setWsConfirmModal,
        warehousing,
        topButsControl, setTopButsControl,
        transfer,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        formComputeRule,
        loading, setLoading
    }
})