import React, { FC } from "react";
import { Button, Table, Upload, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { globalPrompt } from "@/components/message";
import { ColumnsType } from "antd/es/table";
import { useStockBorrowDetailModel } from "./stockBorrowDetailsModel";

import { RouterParamType } from "@/model/navModel";
import { importPropsConfig } from "@/utils/importFile";
import { DetailType } from "@/types/ScmEnums";
import { IDetailList } from "../../stockOutAndInListType";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { formatNum, formatIntPoint } from "@/utils/utils";
import YhTag from "@/components/YhTag";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { YhAuth } from "@/components/auth/YhAuth";


export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const {
        setAddModalVisible,
        detailAddList,
        detailDataSource,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        modelScreenMethod,
        detailDetailList,
        storage,
        getDetailEditOne,
    } = useStockBorrowDetailModel();

    const action = <>
        {mode !== "look" && <>
            <YhAuth resCode={ResourceAuthEnum.ST_M_BORROW_ED_ADD_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setAddModalVisible(true)}
                >新增配件</Button>
            </YhAuth>
            {mode != "edit" &&
                <YhAuth resCode={ResourceAuthEnum.ST_M_BORROW_ED_EDL_M}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={() => { detailDetailList(mode) }}
                    >批量删除</Button>
                </YhAuth>
            }
        </>}
    </>
    const ReturnIt = async (record: IDetailList) => {
        let { id, borrowCode } = detailEditForm
        let params = {
            id,
            borrowCode,
            details: [record]
        }
        let retData = await storage(params)
        if (retData) {
            message.success("还件成功")
            getDetailEditOne(borrowCode as string, "edit")
        }
    }
    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编码',
            dataIndex: 'materialCode',
            fixed: "left",
        }, {
            title: '配件名称',
            dataIndex: 'materialName',
            fixed: "left",
        }, {
            title: '库存数量',
            width: 160,
            dataIndex: 'inventoryNums',
        }, {
            title: '借件数量',
            width: 160,
            dataIndex: 'borrowNum',
            editable: mode != "look" ? true : false,
        }, {
            title: '操作',
            // width: 120,
            fixed: "right",
            align: "center",
            render: (text: string, record: IDetailList, index: number) => (
                <YhTag onClick={() => ReturnIt(record)}
                    disabled={mode !== "edit"}
                    color="cyan"
                    text={"还件"}></YhTag>
            )
        }
    ];
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = (row: IDetailList) => {
        let { inventoryNums, borrowNum } = row
        borrowNum = +formatIntPoint(borrowNum, inventoryNums, "借件数量>=库存数量")
        let newRow = {
            ...row,
            borrowNum
        }
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.materialCode === item.materialCode);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
    }
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": [],
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="配件信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.materialCode as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode == DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns() as ColumnsType<IDetailList>}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}