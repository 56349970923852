import React from "react";
import { useStockStatisticModel } from "../stockStatisticModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { DictCodeEnmu } from '@/types/VrmSaDictEnums';
import { useDictModel } from "@/views/base/dict/dictModel";


export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setSelectedRowKeys
    } = useStockStatisticModel();
    const { getSelectedDictOne } = useDictModel();
    const { warehouseList } = useGlobalModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "是否有占用",
            fieldName: "occupy",
            options: [{ label: "是", value: 0 }, { label: "否", value: null as any }],
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "是否有库存",
            fieldName: "inventoryNum",
            options: [{ label: "是", value: 1 }, { label: "否", value: null as any }],
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "配件编码",
            fieldName: "materialCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "配件名称",
            fieldName: "materialName"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "品牌",
            fieldName: "brandCode",
            allowClear: true,
            showSearch: true,
            options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND),
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "仓库",
            fieldName: "warehouseCode",
            options: transOptions(warehouseList.filter((item: any) => item.type === "warehouse-category.big")),
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "货位",
            fieldName: "shelfCode"
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: "最早进货日期",
            fieldName: "stockTime",
        },
    ]
    return (
        <YhPageHeader
            title="库存统计"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params });setSelectedRowKeys([]); }}
            handleReset={resetSearchParam}
        />
    );
}