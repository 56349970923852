import React, { useState, useRef } from "react";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";

export const StockEntryModelDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    return (
        <>
            <TopButs />
            <FormBox {...detailsInfo} IFormRef={IFormRef}/>
            <TableBox {...detailsInfo} />
        </>
    )
}