import React  from "react";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import {usePsiBusModel} from "../psibusModel"

export function PageHeader(){
    const {updateSearchParam,loadData,searchParam,resetSearchParam} =usePsiBusModel()
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品编号",
            fieldName: "materialCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品名称",
            fieldName: "materialName"
        }
    ]
    return (
        <YhPageHeader 
        title={"公交进销存报表汇总"}
        size="small"
        justify="end"
        searchFields={searchFields}
        onChange={updateSearchParam}
        triggerSearch
        handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
        handleReset={resetSearchParam}
        />
            
       
    );
}