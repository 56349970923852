import React, { useEffect, useState, FC } from "react";
import { Button, message, Tooltip } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { } from "@/model/navModel";
import { usePurchaseRoModel } from "../../purchaseRoModel";
import { globalPrompt } from "@/components/message";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailsModel"
import { ITopType } from "@/types/ScmTypes";
import { DetailType } from "@/types/ScmEnums";
import { debounce } from "lodash";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const FormBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow
    } = usePurchaseRoModel();
    const {
        detailEditForm,
        detailAddForm,
        detailLookForm,
        detailAddList,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        resetEditCatch,
        insertDetail,
        editDetail,
        setDetailAddForm,
        setDetailAddList,
        setTopButsControl,
        setSupplierHistoryModel,
        setSupplierHistoryVisible,
        formComputeRule
    } = usePurchaseRoDetailsModel();
    const { beShopDs, supplieAllList } = useGlobalModel();
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { getSelectedDictOne } = useDictModel();
    const { shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel()

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("11-3-1") && resetAddCatch(mode);
            !findNav("11-3-2") && resetEditCatch(mode);
        }
    }, [selectedNavId])

    const save = () => {
        //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const resData = await insertDetail(res)
                if (resData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    resetAddCatch(mode);
                    setSelectedRowKeys([resData.id as string])
                    setDescRows([resData])
                    setSelectedRow(resData)
                    goBack();
                }
            } else if (mode === "edit") {
                let { id, returnCode } = detailEditForm
                const resData = await editDetail({ ...res, id, returnCode })
                if (resData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    getDetailEditOne(returnCode as string, mode)
                }
            }
        })
    }
    const goBack = () => {
        replaceNav(selectedNavId, "11-3")
    }
    const onRefresh = async () => {
        if (mode === 'add') {
            IFormRef.current?.resetFields()
            setDetailAddForm({})
            setDetailAddList([])

        } else if (mode === 'edit') {
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }


    const rightChildren = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.P_R_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { save() }, 900)}

                >保存</Button>
            </YhAuth>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    //采购实体
    const benttityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    //发票类型
    const invoiceChange = (value: string, option: ISelectOption) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value });
    }
    //采购单位
    const documentChange = (value: string, option: ISelectOption) => {
        option && onChange({ documentTypeName: option.label, documentTypeCode: option.value });
    }
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }
    const onConsumption = () => {
        let { supplierCode, supplierName } = typeForm[mode]
        if (!supplierCode) {
            return message.warning("请先选择供应商")
        }
        setSupplierHistoryVisible(true)
        setSupplierHistoryModel({ supplierName: supplierName as string })
    }
    const title = <>
        <span>基础信息</span>
        <Tooltip placement="topLeft" title="采购历史">
            <FontIcon type="icon-xiaofeijilu" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onConsumption}
            />
        </Tooltip>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: title,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "退货单号",
                fieldName: "returnCode",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: <span>供应商名称</span>,
                fieldName: "supplierCode",
                options: transOptions(supplieAllList),
                rules: [{ required: true, message: "请选择供应商" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "采购实体",
                fieldName: "bentityCode",
                options: beShopDs,
                onSelectChange: benttityChange,
                rules: [{ required: true, message: "请选择采购实体" }]
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "供应商类型",
                fieldName: "supplierTypeName",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "退货地址",
                fieldName: "returnAddress",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "发票类型",
                fieldName: "invoiceTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
                onSelectChange: invoiceChange,
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "结算类型",
                fieldName: "payType",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                rules: [{ required: true, message: "请输入结算类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "退货原因",
                fieldName: "returnReason",
                span: 5,
                labelCol: { offset: 1 },
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "单据类型",
                fieldName: "documentTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.PRETURN_TYPE),
                rules: [{ required: true, message: "请选择单据类型" }],
                onSelectChange: documentChange,
                disable: mode === "edit" || !!detailAddList.length,
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "",
                span: 1,
                labelCol: { offset: 5 },
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                hidden: more,
                span: 12,
                labelCol: { span: 3 },
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单人",
                fieldName: "createUser",
                hidden: more,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                hidden: more,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库管员",
                fieldName: "storekeeper",
                hidden: more,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "出库时间",
                fieldName: "stockIoTime",
                hidden: more,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算员",
                fieldName: "reckoner",
                hidden: more,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算时间",
                fieldName: "settleTime",
                disable: true,
                hidden: more
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "核销时间",
                fieldName: "payTime",
                disable: true,
                hidden: more
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "supplierName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商类型code",
                fieldName: "supplierTypeCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发票类型名称",
                fieldName: "invoiceTypeName",
                hidden: true
            }
        ]
    };



    const onChange = (changedValues: { [key: string]: any }) => {
        let newChangedValues = changedValues;
        if ("supplierCode" in changedValues && changedValues.supplierCode) {
            let { name, typeCode, typeName, contact, phone } = getFindItem(changedValues.supplierCode as string, supplieAllList)
            newChangedValues = { ...changedValues, contact, phone, supplierName: name, supplierTypeCode: typeCode, supplierTypeName: typeName }
        }
        mode === "add" ? updateAddFormVal(newChangedValues) : updatEditFormVal(newChangedValues)
        setTopButsControl(true)
    }

    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === DetailType.look || (detailEditForm.status === "purchase-return.settle" && mode === DetailType.edit),
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}