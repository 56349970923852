import React from "react";
import { DataBox } from "./DataBox"
import { DetailDrawer } from "./DetailDrawer"
import { PageHeader } from "./PageHeader"
const TechnicianItem = () => {
    return (
        <>
            <PageHeader/>
            <DataBox />
            <DetailDrawer />
        </>
    )
}
export default TechnicianItem