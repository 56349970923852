import React from "react";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { useAllocModel } from "../allocModel";

export function PageHeader () {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useAllocModel();
    const { getSelectedDictOne, getTreeSelectOne } = useDictModel(() => []);

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品编号",
            fieldName: "materialCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品名称",
            fieldName: "materialName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "调拨单号",
            fieldName: "code"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "调出门店",
            fieldName: "outShopName"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "出库状态",
            fieldName: "stockOutStatus",
            options: getSelectedDictOne(DictCodeEnmu.SALE_STOCK_IO_STATUS)
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "调入门店",
            fieldName: "shopName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "调入门店",
            fieldName: "shopName"
        },

    ]

    const searchMoreFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "入库状态",
            fieldName: "stockInStatus",
            options: getSelectedDictOne(DictCodeEnmu.PUR_STOCK_IO_STATUS)
        },
    ]

    return (
        <YhPageHeader
            title="调拨管理"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}