import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import React, { useEffect, useState } from "react";
import { PaginationProps } from "antd/es/pagination";

import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";
import {
    IStockEntryField,
    IStockEntrySearch,
    ITotalVo
} from "./stockEntryType"
import { stockEntryService } from "./stockEntryService"
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { convertRes2Blob } from "@/utils/exportFile";
import { bigNumber } from "@/utils/utils"


export interface IMdBeSearchField {
    code?: string;
    name?: string;
    corporation?: string;
    phone?: string;
    address?: string;
    bank?: string;
    bankAccount?: string;
}
export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

export const useStockEntryModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IStockEntrySearch = {};
    const initDataSource: IStockEntryField[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc: ITotalVo = { InTotal: 0, totalNum: 0 }

    //查询条件
    const [searchParam, setSearchParam] = useState<IStockEntrySearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IStockEntryField[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IStockEntryField>({});
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //单据汇总
    const [descTotal, setDescTotal] = useState<ITotalVo>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<ITotalVo>(initDesc);
    const [descRows, setDescRows] = useState<IStockEntryField[]>([]);
    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IStockEntryField>();

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item, index) => {
            let { inTotal } = item
            newRowsDescTotal.totalNum = bigNumber.add(newRowsDescTotal.totalNum, 1)
            newRowsDescTotal.InTotal = bigNumber.add(newRowsDescTotal.InTotal, inTotal as number)
            // newRowsDescTotal.totalNum += +1
            // newRowsDescTotal.InTotal += inTotal as number
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IStockEntryField)
    }, [selectedRowKeys])


    const [drawerVisible, setDrawerVisible] = useState(false);

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IMdBeSearchField) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }
    const loadData = async (page?: ISearchPage, searchValue: IStockEntrySearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await stockEntryService.page(searchParams);
        const { stock, totalVo } = retData[0];
        const { records, ...pg } = stock
        let newPg = { ...pg, pageNum: pg.current }
        setDescTotal(totalVo)
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records ?? []);
    }
    const exportEntry = async (searchValue: IStockEntrySearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ioFlag: 1,
            id: selectedRowKeys.join(),
        }
        let result = await stockEntryService.export(searchParams);
        convertRes2Blob(result)
    }
    const exportDetailsEntry = async (searchValue: IStockEntrySearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ioFlag: 0,
            id: selectedRowKeys.join(),
        }
        let result = await stockEntryService.detailsExport(searchParams);
        convertRes2Blob(result)
    }
    return {
        /***field***/
        searchParam,
        initPgCfg,
        dataSource,
        selectedRowKeys,
        pgCfg,
        drawerVisible,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        descTotal, setDescTotal,
        selectedRow, setSelectedRow,
        exportEntry,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        exportDetailsEntry,
        setHistoryRow,setHistoryVisible
    }
});