import { httpRequest } from "../../utils/HttpRequest";
import { ISearchPage } from "@/types/ScmTypes";

export interface IUserField {
    endTime?: string, // "",
    ioFlag?: number, // 0,
    materialCode?: string, // "",
    pageNum?: number, // 0,
    pageSize?: number, // 0,
    shopId?: string, // "",
    startTime?: string, // "",
    tradeCode?: string, // "",
    tradeTypeCode?: string, // "",
    tradeTypeName?: string, // "",
    userName?: string, // ""
}
export type IUserSearch = IUserField & ISearchPage
export type IUserDetail = {
    id?: string,    // "9d737218d53d49bf8b3757825f3f9265",
    tradeCode?: string,    // "TJHYXS202106030001",
    userName?: string,    // "华宇",
    stockIoTime?: string,    // "2021-06-0",
    tradeTypeCode?: string,    // "trade-type.sale",
    tradeTypeName?: string,    // "销售单",
    inTotal?: number,    // 1,
    billingClerk?: string,    // "张帅-2.0",
    billingTime?: string,    // "2021-0",
    ioFlag?: number,    // 0,
    shopName?: string,    // null,
    createUser?: string,    // null,
    createTime?: string,    // "2021-06-03",
    note?: string,    // null,
    details?: string,    // null
}
export interface IUseStatistics {
    amount: number,
    totalNum: number,
    inTotal: number
}
export type IUserList = {
    records: IUserDetail[],
    total: number,
    size: number,
    current: number,
    orders: any,
    optimizeCountSql: boolean,
    hitCount: boolean,
    statistics: IUseStatistics,
    searchCount: boolean,
    pages: number
}
export interface ISupperField {
    endTime?: string, // "",
    ioFlag?: number, // 0,
    materialCode?: string, // "",
    pageNum?: number, // 0,
    pageSize?: number, // 0,
    shopId?: string, // "",
    startTime?: string, // "",
    tradeCode?: string, // "",
    tradeTypeCode?: string, // "",
    tradeTypeName?: string, // "",
    userName?: string, // ""
}
export type ISupperSearch = ISupperField & ISearchPage
export type ISupperDetail = {
    id?: string,    // "9d737218d53d49bf8b3757825f3f9265",
    tradeCode?: string,    // "TJHYXS202106030001",
    userName?: string,    // "华宇",
    stockIoTime?: string,    // "2021-06-0",
    tradeTypeCode?: string,    // "trade-type.sale",
    tradeTypeName?: string,    // "销售单",
    inTotal?: number,    // 1,
    billingClerk?: string,    // "张帅-2.0",
    billingTime?: string,    // "2021-0",
    ioFlag?: number,    // 0,
    shopName?: string,    // null,
    createUser?: string,    // null,
    createTime?: string,    // "2021-06-03",
    note?: string,    // null,
    details?: string,    // null
}
export type ISupperList = {
    records: ISupperDetail[],
    total: number,
    size: number,
    current: number,
    orders: any,
    optimizeCountSql: boolean,
    hitCount: boolean,
    statistics: IUseStatistics,
    searchCount: boolean,
    pages: number
}
export interface IItemAndMaterialSearch extends ISearchPage {
    licensePlate?: string,
    shopId?: string
}
export interface IItemField {
    id?: string,  // "40272ba669e64b7a9f71297907ae676e",
    maintenanceCode?: string,  // "TJHYWX202106040001",
    itemCode?: string,  // "JSS037",
    itemName?: string,  // "拆装座椅减震器",
    workHour?: number,  // 4,
    price?: number,  // 50,
    discountRate?: number,  // 100,
    itemAmount?: number,  // 200,
    receivableAmount?: number,  // 200,
    note?: string,  // null,
    createUser?: string,  // "郝磊",
    updateUser?: string,  // null,
    serviceTypeName?: string,  // null,
    customerName?: string,  // "华宇",
    contact?: string,  // "张三",
    phone?: string,  // "13406829491",
    licensePlate?: string,  // "京A889076"
}
export interface IMaterialField {
    id?: string, // "de7f99da2cb7416dbd2d978b3b13591d",
    stockId?: string, // "4f24ae89b4ae48c7a79408f2453e64da",
    status?: string, // null,
    maintenanceCode?: string, // "TJHYWX202106040001",
    materialCode?: string, // "880788",
    materialName?: string, // "齿轮油",
    num?: number, // 1,
    inventoryNum?: number, // 84,
    price?: number, // 25,
    discountRate?: number, // 100,
    materialAmount?: number, // 25,
    receivableAmount?: number, // 25,
    warehouseCode?: string, // "WH-M-00000020",
    warehouseName?: string, // "zhangshuai zhang",
    shelfId?: string, // "c303e412f75f08cdc6511b4090c1c63f",
    shelfCode?: string, // "001",
    note?: string, // null,
    createUser?: string, // "郝磊",
    updateUser?: string, // null,
    unclaimedNum?: number, // 1,
    quantityNum?: number, // 0,
    collectedNum?: number, // 0,
    returnProducts?: string, // null,
    returnNum?: number, // 0,
    cause?: string, // null,
    latestPurPrice?: number, // 22,
    cost?: number, // 22,
    serviceTypeName?: string, // null,
    customerName?: string, // "华宇",
    contact?: string, // "张三",
    phone?: string, // "13406829491",
    licensePlate?: string, // "京A889076"
}
class CommonModuleService {
    async userPage(searchVo: IUserSearch) {
        const apiUrl = `/bcm/stockIo/user/findPage`;
        return await httpRequest.post<IUserList>(apiUrl, searchVo);
    }
    async supperPage(searchVo: ISupperSearch) {
        const apiUrl = `/bcm/stockIo/supper/findPage`;
        return await httpRequest.post<ISupperList>(apiUrl, searchVo);
    }
    async stockIoExport(searchVo: ISupperSearch) {
        const apiUrl = `/bcm/stockIo/export`;
        return await httpRequest.postFile(apiUrl, searchVo);
    }
    async recordItem(searchVo: IItemAndMaterialSearch) {
        const apiUrl = `/bcm/maintenance/recordItem`
        return await httpRequest.post<IItemField[]>(apiUrl, searchVo);
    }
    async recordMater(searchVo: IItemAndMaterialSearch) {
        const apiUrl = `/bcm/maintenance/recordMater`
        return await httpRequest.post<IMaterialField[]>(apiUrl, searchVo);
    }
}
export const commonModuleService = new CommonModuleService();
