import React from "react";
import { Button, Steps } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useInternalModel } from "../internalModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { useInternalDetailsModel } from "./detail/internalDetailsModel";

export const DataBox = () => {
    const { selectedRowKeys, selectedData } = useInternalModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel(() => []);
    const { resetEditCatch } = useInternalDetailsModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let poEditKey = "15-3-2";
        let isFindNav = findNav(poEditKey);
        if (!isFindNav) {
            addNav(poEditKey, { id: selectedData?.code as string, mode: "edit" });
            resetEditCatch()
        } else {
            globalPrompt("modal", {
                title: "内部调拨单跳转详情",
                type: "warning",
                text: "您还有内部调拨单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(poEditKey, { id: selectedData?.code as string, mode: "edit" });
                    resetEditCatch()
                }
            })
        }
    }


    const title = (current: number) => <>
        <span>审核进度： </span>

        <Steps current={current} style={{ margin: "10px 0 " }}>
            <Steps.Step title="待申请" status={current === 0 ? "process" : current > 0 ? "finish" : "wait"} />
            <Steps.Step title="待对方确认" status={current === 1 ? "process" : current > 1 ? "finish" : "wait"} />

            {
                current === 2 ? <Steps.Step title="对方不认可" status="error" /> : <Steps.Step title="待财务确认" status={current === 3 ? "process" : current > 3 ? "finish" : "wait"} />
            }
            {
                current === 4 ? <Steps.Step title="对方已驳回" status="error" /> : <Steps.Step title="待财务审核" status={current === 5 ? "process" : current > 5 ? "finish" : "wait"} />
            }
            {
                current === 6 ? <Steps.Step title="财务已驳回" status="error" /> : <Steps.Step title="待最终审核" status={current === 7 ? "process" : current > 7 ? "finish" : "wait"} />
            }
            {
                current === 9 ? <Steps.Step title="最终已驳回" status="error" /> : <Steps.Step title="最终审核通过" status={current === 8 ? "process" : current > 8 ? "finish" : "wait"} />
            }

        </Steps>
        <YhAuth resCode={ResourceAuthEnum.ACC_INTERNAL_APPLY_APPLY}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("15-3-1", { id: "", mode: "add" })}
            >申请</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ACC_INTERNAL_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >查看</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title={title(selectedData?.status as number ?? 0)}>
            <DataTable />
        </YhBox>
    )
}