import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IHistoryQueryDetail, IHistoryQuerySearch } from "./historyQueryType";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table/interface";
import { transPgToPagination } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { historyQueryService } from "./historyQueryService";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";

export const useHistoryQueryModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IHistoryQuerySearch = {};
    const initDataSource: IHistoryQueryDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //查询条件
    const [searchParam, setSearchParam] = useState<IHistoryQuerySearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IHistoryQueryDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IHistoryQueryDetail>({});

    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //派工抽屉
    const [dispatchVisible, setDispatchVisible] = useState<boolean>(false);
    //弹框状态值
    const [detailType, setDetailType] = useState<string>("");

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IHistoryQueryDetail)
    }, [selectedRowKeys])

    const updateSearchParam = (updateParam: IHistoryQuerySearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }
    const loadData = async (page?: ISearchPage, searchValue: IHistoryQuerySearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await historyQueryService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    return {
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        initPgCfg,
        dispatchVisible,
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setSelectedRow,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        detailType, setDetailType,
        setDispatchVisible
    }
})