import { createModel } from "hox";
import React, { useState } from "react"
export interface ITemporaryField {
    createUser: string,
    discountRate: number,
    id: string,
    detailsId: string,
    itemAmount: number,
    itemCode: string,
    itemName: string,
    note: string,
    price: number,
    receivableAmount: number,
    updateUser: string,
    workHour: number,
    temporary: number,
}
function useModel() {
    //供临时项目使用
    const [temporaryid, setTemporaryid] = useState<string>(new Date().getTime().toString())
    const temporaryObj: ITemporaryField = {
        createUser: "",
        discountRate: 100,
        id: "",
        detailsId: temporaryid,
        itemAmount: 0,
        itemCode: "临时编码",
        itemName: "临时名称",
        note: "",
        price: 0,
        receivableAmount: 0,
        updateUser: "",
        workHour: 1,
        temporary: 1,
    }
    return {
        temporaryid,
        temporaryObj,
        setTemporaryid
    }
}
export const useVrmModel = createModel(useModel);
export default useVrmModel;