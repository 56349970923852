import React, { FC } from "react";
import { Button, message, Table, Tooltip, Upload } from "antd";
import { RouterParamType } from "@/model/navModel";
import { YhBox } from "@/components/YhBox";
import { useDeploymentDetailModel } from "./deploymentDetailModel"
import { ISaleDetailsList } from "../../../../busBusinessType";
import { bigNumber, formatIntPoint, formatNum } from "@/utils/utils";
import { DetailType } from "@/types/ScmEnums";
import { ColumnsType } from "antd/es/table";
import { components } from "@/components/YHEditTable/editTable";
import { importPropsConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";
import { globalPrompt } from "@/components/message";
import {useStockPoDetailModel} from "../../../../detailedInventory/InventoryDetailsModel"

export const MaterialTableBox: FC<RouterParamType> = ({ id, mode }) => {
    const {
        setMaterialModel,
        detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        detailMaterialLookList,
        detailMaterialDetailList,
        downloadTpl,
        modelMaterialScreenMethod,
        setTopButsControl,
        //setHistoryVisible,
        setHistoryRow,
        detailAddForm
    } = useDeploymentDetailModel();
    const {setHistoryVisible} = useStockPoDetailModel()
    const onHistoryModel = (record: ISaleDetailsList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const { shopAndOrg: { shopId } } = useGlobalModel()
    const importCallback = async (list: any) => {
        const newDetailList = mode === "add" ? detailMaterialAddList : detailMaterialEditList
        const { repetition, newDetailData } = await modelMaterialScreenMethod(list, newDetailList)
        if (mode === "add") {
            setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        } else if (mode === "edit") {
            setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
    }
    const action = <>
        {mode !== "look" && <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => {
                    if(!detailAddForm.sourceWarehouseCode){
                        return message.warning('请选择调出仓库')
                    }else{setMaterialModel(true)}
                }}
            >新增商品</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => detailMaterialDetailList(mode)}
            >删除商品</Button>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/transit/sale/details/import/${shopId}`, importCallback)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => downloadTpl().then()}
            >下载模板</Button>
            {/* <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => setMaterialModel(true)}
            >导入</Button> */}
        </>}
    </>;
    const MaterialColumns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '公交编码',
            width: 120,
            dataIndex: 'materialNo',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span">{text}</p></Tooltip>,
        }, {
            title: '商品编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,

        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '品牌',
            width: 120,
            dataIndex: 'brandName',
        }, {
            title: '调出数量',
            width: 120,
            dataIndex: 'num',
            editable: mode != "look" ? true : false,
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
            editable: mode != "look" ? true : false,
        }, {
            title: '总计',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '可用库存',
            width: 120,
           //dataIndex: 'availableNum',
           dataIndex:'inventoryNum'
        }, {
            title: '单位',
            width: 120,
            dataIndex: 'unitName',
        }, {
            title: '预售单号',
            width: 120,
            dataIndex: 'stockCode',
        }
    ];
    const newSelectMaterialColums = () =>
        MaterialColumns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: ISaleDetailsList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave = (row: ISaleDetailsList) => {
        let { num, price, discountRate, amount, availableNum } = row
        num = +formatIntPoint(num, availableNum, "选择数量>=可用库存数量");  //数量
        price = +formatIntPoint(price);  //价格
        let newRow = {
            ...row,
            num,
            price: +formatNum(price),
            discountRate,
            //amount: +formatNum(num * price)
            amount:+bigNumber.toFixed(bigNumber.times(num,price),2),
        }

        let newData = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailMaterialEditList([...newData]) : setDetailMaterialAddList([...newData])
        setTopButsControl(true)
    }
    const typeMaterialList = {
        "add": [...detailMaterialAddList],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    //设置维修用料选中的行和数据
    const setTypeMaterialDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeMaterialDetailDataRow = async (selectedRows: ISaleDetailsList[], mode: string) => {
        if (mode === DetailType.add) {
            // setDetailMaterialAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            // setDetailMaterialEditDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectMaterialColums() as ColumnsType<ISaleDetailsList>}
                dataSource={typeMaterialList[mode] as ISaleDetailsList[]}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailMaterialAddDataRowKeys : detailMaterialEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleDetailsList[]) => {
                            setTypeMaterialDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        }
                    }}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }}
            />
        </YhBox>
    )
}