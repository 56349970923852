import { Key, useState, useEffect } from "react";
import { createModel } from "hox";
import { useSessionStorageState } from "ahooks";
import useNavModel from "@/model/navModel";
import { useGlobalModel, getFindItem } from "@/model/globalModel";
import { ITreeData, ISelectOptions } from "@/types/ScmTypes";
import { IMdOrgDetail } from "@/views/organization/org/orgService";
import { IMdDictFields } from "@/views/account/dictService";
import { IBusBusinessFields } from "../../busBusinessType"
export enum ActionTypeEnum {
    STOCK,//库存信息
    PRESALE,//预售记录
    SALE,//销售记录
    DEPLOYMENT,//调额记录
    PREWITHDRAWAL,//预退记录
    SALESRETURN,//消退记录
}

type labelsType = {
    [proppName: string]: string;
}
interface ICustomerType {
    customerCode?: string,
    customerName?: string,
    contact?: string,
    phone?: string,
    customerAdd?: string,
    customerTypeName?: string,
    customerTypeCode?: string,
    address?:string
}
export const useDetailModel = createModel(function () {
    const { user: { shopCode }, CustomerData } = useGlobalModel();
    const [busBusiness, setBusBusiness] = useState<IBusBusinessFields>({})
    const [actionType, setActionType] = useState<ActionTypeEnum>(ActionTypeEnum.STOCK) // 选中展示模块
    const [customerParams, setCustomerParams] = useState<ICustomerType>({})
    useEffect(() => {
        if (busBusiness.customerCode && CustomerData.length !== 0) {
            let { name, code, contact, phone, address, categoryCode, categoryName } = getFindItem(busBusiness.customerCode, CustomerData)
            setCustomerParams({
                customerCode: code,
                customerName: name,
                contact: contact,
                phone: phone,
                address,
                customerAdd: address,
                customerTypeName: categoryCode,
                customerTypeCode: categoryName
            })
        }
    }, [busBusiness.customerCode])
    return {
        actionType,
        busBusiness,
        customerParams,
        setActionType,
        setBusBusiness,
        setCustomerParams
    }
});
