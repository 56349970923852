import { createModel } from "hox";
import { message } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import { useState, useEffect } from "react";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE
} from "@/utils/constants";
import { ISaleOrderSearch, ISaleOrderDetail, ISaleOrderFields, ISaleOrderInvalidate } from "./saleOrderTypes"
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { saleOrderService } from "./saleOrderService"
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    totalNum: number,
    totalAmount: number
}
export const useSaleOrderModel = createModel(function () {

    const { shopAndOrg } = useGlobalModel()
    const [print, setPrint] = useState<boolean>(false);

    const initSearchParam: ISaleOrderSearch = {};
    const initDataSource: ISaleOrderDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        totalNum: 0,
        totalAmount: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<ISaleOrderSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<ISaleOrderDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<ISaleOrderDetail>({});
    //列表数据源分页参数

    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<ISaleOrderDetail[]>([]);

    const [drawerVisible, setDrawerVisible] = useState(false);
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as ISaleOrderFields)
    }, [selectedRowKeys, dataSource])
    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            let { totalNum, totalAmount } = item
            newRowsDescTotal.totalNum = bigNumber.add(newRowsDescTotal.totalNum, formatNum(totalNum))
            newRowsDescTotal.totalAmount = bigNumber.add(newRowsDescTotal.totalAmount, formatNum(totalAmount))
            // newRowsDescTotal.totalNum += +formatNum(totalNum)
            // newRowsDescTotal.totalAmount += +formatNum(totalAmount)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: ISaleOrderSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: ISaleOrderSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await saleOrderService.page(searchParams);
        const { records, statistics, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)
    }
    const onInvalidate = async (params: ISaleOrderInvalidate) => {
        let { retData } = await saleOrderService.invalidate(params)
        if (retData) {
            message.success("作废成功")
            loadData()
        }
    }
    const exportSaleOrder = async (searchValue: ISaleOrderSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await saleOrderService.export(searchParams);
        convertRes2Blob(result)
    }
    const detailsExport = async (searchValue: ISaleOrderSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await saleOrderService.detailsExport(searchParams);
        convertRes2Blob(result)
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        drawerVisible,
        initPgCfg,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        descTotal, setDescTotal,
        selectedRow, setSelectedRow,
        onInvalidate,
        exportSaleOrder,
        detailsExport,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        print, setPrint,setSearchParam
    }
});