import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { usePoReportModel } from "../poReportModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { exportPoReport,descTotal } = usePoReportModel();
    const desc: IDescription[] = [
        {
            label: "采购数量",
            value: descTotal.purchaseNum,
            color: DescriptionColorEnum.red
        },
        {
            label: "采购金额",
            value: descTotal.purchaseAmount,
            color: DescriptionColorEnum.red
        },
        // {
        //     label: "赠品数量",
        //     value: descTotal.giftsNum,
        //     color: DescriptionColorEnum.red
        // },
        // {
        //     label: "赠品金额",
        //     value: descTotal.gifsAmount,
        //     color: DescriptionColorEnum.red
        // },
    ]
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.STT_P_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportPoReport()}
            >导出</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title=" " action={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}