import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { monitoringService, IMonitoringSearchFields, IMonitoringDetail, IStatistics, IMonitoringDetailPurchasePin, IGoodsRecordModal, IDetailsDrawer } from "./monitoringService";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { getRandomStr, transPgToPagination } from "@/utils/utils";


export const useMonitoringModel = createModel(function () {
    const initStatistics = {
        amountDiff: 0,
        beforeAmountDiff: 0,
        costAmountDiff: 0,
        giftsAmountDiff: 0,
        giftsNumDiff: 0,
        numDiff: 0,
        profitDiff: 0,
        sumNumDiff: 0,
        discountAmountDiff: 0,
    }
    const { whDs, shopAndOrg: { shopId,shopCode }, setLoadingInfo } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //查询条件
    const [searchParam, setSearchParam] = useState<IMonitoringSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<IMonitoringDetail[]>([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [descTotal, setDescTotal] = useState<IStatistics>(initStatistics);
    const [loading, setLoading] = useState<boolean>(false);
    //商品明细
    const [purchasePinData, setPurchasePinData] = useState<IMonitoringDetailPurchasePin[]>([]);
    const [pinPgCfg, setPinPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //单据明细查看
    const [detailsVisible, setDatailsVisible] = useState<IDetailsDrawer>({
        visible: false,
        code: "",
    });

    //弹框
    const initGoodsRecordModal = {
        remainVisible: false,  //进销存剩余数
        occupyVisible: false,  //占用库存数
        startVisible: false,  //期初数
        compensateVisible: false,  //补偿数
        type: "all"
    }
    const [goodsRecordModal, setGoodsRecordModal] = useState<IGoodsRecordModal>(initGoodsRecordModal);

    const onCancel = () => {
        setGoodsRecordModal(initGoodsRecordModal);
    };

    const onOpen = (row: IMonitoringDetail, { str, type = "all", title = "弹框", field }: any) => {
        setGoodsRecordModal({
            ...initGoodsRecordModal,
            row,
            [str]: true,  //打开对应弹框
            type, title, field
        });
    }

    const updateSearchParam = (updateParam: IMonitoringSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IMonitoringSearchFields = searchParam) => {
        setLoading(true)
        setDescTotal(initStatistics);
        try {
            const searchParams = {
                shopId,
                shopCode,
                ...searchValue,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
            let { retData } = await monitoringService.page(searchParams);
            const { records, ...pg } = retData;
            setDataSource(records.map(item => ({
                ...item,
                key: getRandomStr()
            })));
            setPgCfg(transPgToPagination(pg));
            let { retData: Data } = await monitoringService.statistics(searchParams);
            setDescTotal(Data);
            setLoading(false)
        } catch {
            setLoading(false);
            // setDataSource([])
        }
    }

    //导出
    const exportMonitoring = async (searchValue: IMonitoringSearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                shopId,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
            }
            let result = await monitoringService.export(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData, exportMonitoring,
        descTotal, setDescTotal,
        purchasePinData, setPurchasePinData,
        pinPgCfg, setPinPgCfg,
        goodsRecordModal,
        onCancel, onOpen,
        detailsVisible, setDatailsVisible,
        loading
    }
});


