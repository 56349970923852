import { httpRequest } from '@/utils/HttpRequest';
import { IPage } from '@/types/ScmTypes';
import {
	IBCMSaleDcSearch,
	IBCMSaleDcDetail,
	IBCMSaleDcStatistics,
	IBCMSaleDcInsert,
	IBCMSaleDcUpdate,
	IBCMSDcGoodsOne,
	IBCMSDcSearch,
} from './allotType';
import { IEntityFields } from '@/views/organization/entity/entityType';

export interface ISCMPage<T, S> extends IPage<T> {
	statistics: S;
}

export interface IFmsShopUnitDetail {
	bentityList: IEntityFields[];
	address: string;
	bentity: string;
	branch: string;
	branchList: string;
	code: string;
	contact: string;
	createTime: string;
	createUser: string;
	deleteFlag: string;
	enable: string;
	id: string;
	name: string;
	note: string;
	phone: string;
	shop: string;
	shopCode: string;
	shopList: string;
	shopName: string;
	status: string;
	tel: string;
	updateTime: string;
	updateUser: string;
	ver: string;
}

class SaleDcService {
	async page(searchVo: IBCMSaleDcSearch) {
		const apiUrl = '/sms/transfer/to/listPage';
		return await httpRequest.post<IPage<IBCMSaleDcDetail>>(apiUrl, searchVo);
	}
	async pageStatistics(searchVo: IBCMSaleDcSearch) {
		const apiUrl = '/sms/transfer/to/TransferInSummary';
		return await httpRequest.post<IBCMSaleDcStatistics>(apiUrl, searchVo);
	}
	async one(id: string) {
		const apiUrl = `/sms/transfer/to/findByIdOrCode/${id}`;
		return await httpRequest.get<IBCMSaleDcDetail>(apiUrl);
	}

	// inAndOut "1" 是调出 "0"是调入
	async insert(insertVo: IBCMSaleDcInsert) {
		const apiUrl = '/sms/transfer/to/insert';
		return await httpRequest.post<any>(apiUrl, insertVo);
	}

	async update(updateVo: IBCMSaleDcUpdate) {
		const apiUrl = '/sms/transfer/to/update';
		return await httpRequest.post<any>(apiUrl, updateVo);
	}
	//查询门店结算单位的 下属关联
	async queryShopUnitBe(shopId: string) {
		const apiUrl = `/fms/accountUnit/find/one/${shopId}`;
		return await httpRequest.post<IFmsShopUnitDetail>(apiUrl);
	}
	//驳回
	async reject(id: string) {
		const apiUrl = `/sms/transfer/to/reject/${id}`;
		return await httpRequest.get<boolean>(apiUrl);
	}

	//调入调出   调入：0，调出 1
	async whConfirm(obj: { inAndOut: string; id: string }) {
		const apiUrl = `/sms/transfer/to/whConfirm`;
		return await httpRequest.post<IFmsShopUnitDetail>(apiUrl, obj);
	}

	async export(searchVo: IBCMSaleDcSearch) {
		const apiUrl = '/sms/transfer/to/export';
		return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
	}
    //列表明细导出
    async statisticsExport(searchVo: IBCMSaleDcSearch) {
        const apiUrl = "/sms/transfer/to/statisticsExport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
	//调出单明细下载模板
	async downloadTpl() {
		const appPageUrl = '/sms/transfer/to/detail/downloadTpl';
		return await httpRequest.getFile(appPageUrl);
	}
	//导出调出单明细
	async exportDetail(code: string) {
		const appPageUrl = `/sms/transfer/to/exportDetails/${code}`;
		return await httpRequest.getFile(appPageUrl);
	}

	//业务系统库房确认接口
	async bcmWhConfirm(searchVo: IBCMSaleDcUpdate) {
		const apiUrl = '/bcm/stock/details/whConfirm';
		return await httpRequest.post<boolean>(apiUrl, searchVo);
	}
	async check (branchIn:string,params:object) {
        const apiUrl = `${branchIn}/base/material/checkMaterialExist`
        return await httpRequest.post<any>(apiUrl, params)
    }
    async checkScm (branchIn:string,params:object) {
        const apiUrl = `${branchIn}/purchase/po/checkMaterialExist`
        return await httpRequest.post<any>(apiUrl, params)
    }
    async stockQuery (data: {details: Array<{shopId: string; materialCode: string;}>}) {
        const apiUrl = `/bcm/stock/details/query`
        return await httpRequest.post<any[]>(apiUrl, data, { timeout: 300000 })
    }
}

export const saleDcService = new SaleDcService();
