import React, { useEffect, useState } from "react";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { Table, Button, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { momentFormat } from "@/utils/utils";
import useGlobalModel from "@/model/globalModel";
import { IHistoryDetails, inventoryDetailsService } from "../InventoryDetailsService";
import { IPage, IRet } from "@/types/ScmTypes";



interface IHistoryModelType {
  visible: boolean,
  materialCode: string,
  materialName: string,
  defalutType?: string,
  onCancel: () => void
}



export const HistoryModel = ({ //采购销售历史通用查询弹框
  visible,
  materialCode,
  materialName,
  defalutType = 'sale',
  onCancel,
}: IHistoryModelType) => {
  const { shopAndOrg: { shopId } } = useGlobalModel()
  const [dataSource, setDataSource] = useState<IHistoryDetails[]>([])
  const [type, setType] = useState('')

  useEffect(() => {
    if (visible) {
      setType(defalutType)
    }
  }, [visible])

  useEffect(() => {
    getStockStatistic(type)
  }, [type])

  const getStockStatistic = async (type: string) => {
    let params = {
      pageSize: 1000,
      pageNum: 1,
      materialCode,
      shopId
    }
    let result = {} as IRet<IPage<IHistoryDetails>>;
    if (type === 'sale') {
      result = await inventoryDetailsService.saleHistory(params)
    } else if (type === 'purchase') {
      result = await inventoryDetailsService.presaleorderHistory(params)
    } else {
      result = await inventoryDetailsService.deploymentHistory(params)
    }
    const { retData: { records } } = result;
    setDataSource(records)
  }

  const action = <>
    <Button
      className={`mr-5`}
      type="link"
      shape="round"
      onClick={() => { setType("sale") }}
    >销售历史</Button>
    <Button
      className={`mr-5`}
      type="link"
      shape="round"
      onClick={() => { setType("purchase") }}
    >预售历史</Button>
    <Button
      className={`mr-5`}
      type="link"
      shape="round"
      onClick={() => { setType("deploy") }}
    >调配历史</Button>
  </>
  const columns: ColumnsType<any> = [
    {
      title: '序号',
      width: 60,
      fixed: "left",
      render: (t, r, i) => i + 1
    },
    {
      title: '单据编号',
      width: 160,
      fixed: "left",
      dataIndex: 'code',
    },
    // ...((type) => {
    //   return type === "purchase" ? [{
    //     title: '折扣',
    //     width: 80,
    //     dataIndex: '',
    //     render: (text: string) => text + "%"
    //   }] : []
    // })(type),
    {
      title: '制单时间',
      width: 160,
      dataIndex: 'createTime',
      render: (text) => momentFormat(text)
    },
    {
      title: type === "purchase" ? "供应商名称" : "客户名称",
      width: 160,
      dataIndex: 'customerName',
      render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
    },
    {
      title: '单价',
      width: 120,
      dataIndex: 'price',
    },
    {
      title: '数量',
      width: 80,
      dataIndex: 'num',
    },
    {
      title: '总计',
      width: 80,
      dataIndex: 'amount',
    },
    {
      title: '备注',
      width: 160,
      fixed: "left",
      dataIndex: 'note',
    },
  ]
  return <YHModal
    visible={visible}
    title={`商品名称:${materialName} 商品编码：${materialCode}`}
    onCancel={onCancel}
    width={1000}
    bodyHeight={700}
    showFooter={false}
  >

    <YhBox title={action}>
      <Table
        style={{ cursor: "pointer" }}
        rowKey={record => record.id as string}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content', y: 550 }}
        pagination={false}
      />
    </YhBox>
  </YHModal>
}