import React, { useRef, useState, useEffect } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils";
import { useWarehouseModel } from "../../warehouseModel";
import { IVRMShelfFormFields } from "../../warehouseTypes";
import { debounce } from "lodash";

//货位
export function ShelfDrawer() {
    const { shelfDrawer: { visible, mode }, setIShelfDrawer, selectedShelfRows, editFormVal, shelvesDataSource,
        insertWarehouseShelf, batchInserttWarehouseShelf, updateWarehouseShelf, shelvesLoadData, areaDataSource } = useWarehouseModel();
    const formRef = useRef<IYhFormRef>();
    const [isBatch, setIsBatch] = useState<boolean>(false);

    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        if ('isBatch' in changedValues) {
            setIsBatch(changedValues['isBatch']);
        }
    }
    const onCancel = () => {
        setIShelfDrawer();
        setIsBatch(false);
    }
    useEffect(() => {
        if (visible && mode === "edit") {
            setIsBatch(false); setShelvesOptions(shelvesDataSource.map(item => ({ label: item.code, value: item.id })));
        };
        if (visible && mode === "add") { setIsBatch(false); shelvesLoadData({ pageNum: 1, pageSize: 10000 }) };
    }, [visible]);
    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            formRef.current?.inst().submit();
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = (val: IVRMShelfFormFields) => {
        if (mode === "edit") {
            updateWarehouseShelf(val)
        } else {
            isBatch ? batchInserttWarehouseShelf(val) : insertWarehouseShelf(val);
        }
    }
    const isBatchItem = (bool: boolean) => {
        return bool ? [{
            type: FormItemTypeEnum.Input,
            fieldLabel: "起始编码",
            fieldName: "startCode",
            span: 24,
            rules: [{ required: true, message: "请输入起始编码" }]
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "数量",
            fieldName: "num",
            span: 24,
            rules: [{ required: true, message: "请输入数量" }]
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "前缀",
            fieldName: "prefix",
            span: 24,
            rules: [{ required: true, message: "请输入前缀" }]
        }] : []
    }
    //区域编码
    const areaOptions = () => {
        return areaDataSource ? areaDataSource.map(item => ({ label: item.code, value: item.id })) : [];
    }
    const areaChange = (value: string, option: ISelectOption) => {
        if (option) {
            formRef.current?.setFieldsValue({ areaCode: option.label, shelvesId: undefined });
            setShelvesOptions([]);
        }
    }
    const [shelvesOptions, setShelvesOptions] = useState<ISelectOption[]>([]);
    //货架触焦   (区域有选中就拿区域下面的货架，否咋就展示全部的货架)
    const shelvesFocus = () => {
        let areaId: string = formRef.current?.getFieldsValue()?.areaId ?? "";
        console.log(areaId, formRef.current?.getFieldsValue())
        let newShelves: ISelectOption[] = [];
        if (areaId) {
            newShelves = shelvesDataSource.filter(item => item.areaId === areaId).map(item => ({ label: item.code, value: item.id }));
        } else {
            newShelves = shelvesDataSource.map(item => ({ label: item.code, value: item.id }));
        }
        setShelvesOptions(newShelves);
    }

    const shelvesChange = (value: string, option: ISelectOption) => {
        option && formRef.current?.setFieldsValue({ shelvesCode: option.label });
    }
    const formConfig: IYhForm = {
        formRef,
        onFinish,
        formValues: mode === "edit" ? selectedShelfRows[0] : { warehouseName: editFormVal.name },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属仓库",
                fieldName: "warehouseName",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "所属区域",
                fieldName: "areaId",
                allowClear: true,
                showSearch: true,
                span: 24,
                disable: mode === "edit" ? true : false,
                options: areaOptions(),
                onSelectChange: areaChange,
                // rules: [{ required: true, message: "请选择所属区域" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "所属货架",
                fieldName: "shelvesId",
                allowClear: true,
                showSearch: true,
                span: 24,
                disable: mode === "edit" ? true : false,
                options: shelvesOptions,
                onSelectFocus: shelvesFocus,
                onSelectChange: shelvesChange,
                // rules: [{ required: true, message: "请选择所属货架" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "货位编码",
                fieldName: "code",
                span: 24,
                disable: isBatch,
                rules: [{ required: !isBatch, message: "请输入货位编码" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            },
            ...isBatchItem(isBatch),
            {
                type: FormItemTypeEnum.Checkbox,
                fieldLabel: "批量录入",
                fieldName: "isBatch",
                span: 24,
                hidden: mode === "edit" ? true : false
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "货架编码",
                fieldName: "shelvesCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "区域编码",
                fieldName: "areaCode",
                hidden: true
            },
        ]
    };
    return (
        <YHDrawer
            {...{
                title: `货位${mode === "edit" ? "编辑" : "新增"}`,
                visible,
                onCancel,
                okText: "保存",
                onOk: debounce(() => { onSubmit() }, 900)
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
