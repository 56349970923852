import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useStockPoDetailModel } from "../purchaseDetailsModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/ScmResourceAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, setShiftShelfModal, setShiftShelfModals, exportStockOut, statistics, setRecordsVisible } = useStockPoDetailModel();
    const { addNav, findNav, switchNavState } = useNavModel(() => []);

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行操作", type: "warning" });
            return;
        }
        let stockOutputEditKey = "4-4-1";
        let isFindNav = findNav(stockOutputEditKey);
        if (!isFindNav) {
            addNav(stockOutputEditKey, { id: selectedRowKeys[0], mode: "look" });
        } else {
            switchNavState(stockOutputEditKey, { id: selectedRowKeys[0], mode: "look" });
        }
    }

    const desc: IDescription[] = [
        {
            label: "库存成本",
            value: statistics?.stockAmount ?? 0,
            color: DescriptionColorEnum.red
        },
        {
            label: "库存数量",
            value: statistics?.stockNum ?? 0,
            color: DescriptionColorEnum.red
        },
    ]
    const action = <>
        {/* <YhAuth resCode={ResourceAuthEnum.ST_PO_TRANS}> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setShiftShelfModal(true)}
        >转移货位</Button>
        {/* </YhAuth> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportStockOut()}
        >导出</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setRecordsVisible(true)}
        >转移记录</Button>
        <YhAuth resCode={ResourceAuthEnum.ST_PO_TRANS}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => setShiftShelfModals(true)}
            >批量转移货位</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="采购明细列表" descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}