import React from "react";
import { Button, Table } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { useSummaryCustomerDetailsModel } from "./summaryCustomerDetailsModels";


export const MaterialTableBox = () => {
    const { setMaterialModel } = useSummaryCustomerDetailsModel();

    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setMaterialModel(true)}
        >新增商品</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { }}
        >删除商品</Button>
    </>;

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'typeCode',
            fixed: "left",
        }, {
            title: '库房',
            width: 160,
            dataIndex: 'name',
        }, {
            title: '货位',
            width: 160,
            dataIndex: 'contact',
        }, {
            title: '数量',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'address',
        }, {
            title: '配件费',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '折扣',
            width: 160,
            dataIndex: 'shopName',
        }, {
            title: '小计',
            width: 160,
            dataIndex: 'note',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];

    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                // rowKey={record => record.id}
                // rowSelection={{
                //     selectedRowKeys: selectedRowKeys, //记录选中
                //     onChange: (selectedRowKeys: React.Key[], selectedRows: ISCMSupplierDetail[]) => {
                //         setSelectedRowKeys(selectedRowKeys as string[]);
                //     }
                // }}
                columns={columns}
                dataSource={[]}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}