import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useReportFormModel } from "../reportFormModel";
import { momentFormat } from "@/utils/utils";
import { IReportFormField } from "../reportFormType"
import useNavModel from "@/model/navModel";



export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows,setReportFormModel
    } = useReportFormModel();
    const { selectedNavId, replaceNav } = useNavModel();
    useEffect(() => {
        loadData()
    }, [])
    const onRow = (record: IReportFormField) => {
        // let findIndex = selectedRowKeys.indexOf(id);
        // let newSelectedRowKeys = [...selectedRowKeys];
        // if (findIndex === -1) {
        //     newSelectedRowKeys.push(id);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // } else {
        //     newSelectedRowKeys.splice(findIndex, 1);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // }
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const columns: ColumnsType<IReportFormField> = [
        {
            title: '序号',
            width: 60,
            render: (t, r, i) => i + 1,
            fixed: "left",
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'code',
            fixed: "left",
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => {
                e.stopPropagation();
                setReportFormModel(record);
                return;
            }}>{text}</p></Tooltip>,

        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'name',
            fixed: "left",
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => {
                e.stopPropagation();
                setReportFormModel(record);
                return;
            }}>{text}</p></Tooltip>,
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }, {
            title: '品牌',
            width: 160,
            dataIndex: 'brandName',
        }, {
            title: '原厂编号',
            width: 160,
            dataIndex: 'factoryCode',
        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
        }, {
            title: '期初数量',
            width: 120,
            dataIndex: 'startCount',
        }, {
            title: '期初金额',
            width: 120,
            dataIndex: 'startMoney',
            render:(text)=>`￥${text}`,
        }, {
            title: '本期入数量',
            width: 120,
            dataIndex: 'nowInCount',
        }, {
            title: '本期入金额',
            width: 120,
            dataIndex: 'nowInCost',
            render:(text)=>`￥${text}`,
        }, {
            title: '本期出数量',
            width: 120,
            dataIndex: 'nowOutCount',
        }, {
            title: '本期出金额',
            width: 120,
            dataIndex: 'nowOutCost',
            render:(text)=>`￥${text}`,
        }, {
            title: '期末数量',
            width: 120,
            dataIndex: 'endCount',
        }, {
            title: '期末金额',
            width: 120,
            dataIndex: 'endMoney',  
            render:(text)=>`￥${text}`,
        }, {
            title: '采购金额',
            width: 120,
            dataIndex: 'sumPurchase',   
            render:(text)=>`￥${text}`,          
        }, {
            title: '采退金额',
            width: 120,
            dataIndex: 'refundAmount',  
            render:(text)=>`￥${text}`,
        }, {
            title: '销售成本',
            width: 120,
            dataIndex: 'cost',
            render:(text)=>`￥${text}`,
        }, {
            title: '销售金额',
            width: 120,
            dataIndex: 'amount',
            render:(text)=>`￥${text}`,
        }, {
            title: '销售毛利',
            width: 120,
            dataIndex: 'grossProfit',
            render:(text)=>`￥${text}`,
        }, {
            title: '销退金额',
            width: 120,
            dataIndex: 'totalAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '销退成本',
            width: 120,
            dataIndex: 'saleSreturnCost',
            render:(text)=>`￥${text}`,
        }, {
            title: '分类代码',
            width: 160,
            dataIndex: 'genericCode',      
        }, {
            title: '分组代码',
            width: 160,
            dataIndex: 'groupingCode',   
        }, {
            title: '仓库',
            width: 160,
            dataIndex: 'warehouseName',   
        }, {
            title: '货位',
            width: 160,
            dataIndex: 'shelfCode',      
        }, {
            title: '产地',
            width: 160,
            dataIndex: 'madeFrom',       
        }, {
            title: '规格',
            width: 160,
            dataIndex: 'model',       
        }, {
            title: '车型',
            width: 160,
            dataIndex: 'vehicleModel',       
        }, {
            title: '从属门店',
            width: 160,
            dataIndex: 'shopName',                                                                                                                     
        }
    ];
    const onRightClick = (record: IReportFormField) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IReportFormField[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }
            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
