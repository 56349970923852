import React, { useState } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table";
import {
    IAccountDetail,
    IAccountFields,
    IAccountSearch,
} from "../../../../AccountTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, defaultMdCmParam } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { accountService } from "../../../../AccountService";
import { useDetailModel } from "../detailModel";
import { useGlobalModel } from "@/model/globalModel";

type ISelectOptions = {
    label: string,
    value: string,
    [key:string]:any
}
export const useAccountModel = createModel(function () {
    const initSearchParam: IAccountFields = {};
    const initDataSource: IAccountDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const { customerCode } = useDetailModel();
    const { user: { shopCode } } = useGlobalModel();

    //查询条件
    const [searchParam, setSearchParam] = useState<IAccountFields>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IAccountDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedData, setSelectedData] = useState<IAccountFields>();
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [customerUserDs, setCustomerUserDs] = useState<ISelectOptions[]>(); // 选中客户下的账户列表
    const [customerUserList, setCustomerUserList] = useState<IAccountDetail[]>(); // 选中客户下的账户列表
    const [openDrawerVisible, setOpenDrawerVisible] = useState(false);
    const [adjustDrawerVisible, setAdjustDrawerVisible] = useState(false);
    const [rechargeDrawerVisible, setRechargeDrawerVisible] = useState(false);
    const [transferDrawerVisible, setTransferDrawerVisible] = useState(false);
    const [writeoffDrawerVisible, setWriteoffDrawerVisible] = useState(false);
    //账户类型参数account
    const [accountTypeDs, setAccountType] = useState<ISelectOptions[]>([]);


    const onSearch = () => {
        loadData({ pageNum: 1 }).then()
    }
    const onReset = () => {
        resetSearchParam()
    }


    // 根据条件返回用户options
    const getAccoutCustomerUser = async (data: IAccountFields) => {
        let result = await accountService.find({
            partnerCode: customerCode,
            ...data,
            shopCode
        })
        return result.retData.map(item => {
            return {
                label: item.name ?? '',
                value: item.code ?? ''
            }
        })
    }


    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IAccountFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IAccountFields = searchParam) => {
        const searchParams: IAccountFields = {
            ...searchValue,
            partnerCode: customerCode,
            shopCode
            // page: {
            //     pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            //     pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            // }
        }
        let { retData } = await accountService.find(searchParams);
        // const {records, ...pg} = retData;
        // setPgCfg(transPgToPagination(pg));
        // setDataSource(records);
        setDataSource(retData);
    }


    return {
        /***field***/
        customerCode,
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedData,
        openDrawerVisible,
        adjustDrawerVisible,
        rechargeDrawerVisible,
        transferDrawerVisible,
        writeoffDrawerVisible,
        customerUserDs,
        customerUserList,
        accountTypeDs,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setOpenDrawerVisible,
        setAdjustDrawerVisible,
        setRechargeDrawerVisible,
        setTransferDrawerVisible,
        setWriteoffDrawerVisible,
        getAccoutCustomerUser,
        onSearch,
        onReset,
        setSelectedData,
        setCustomerUserList,
        setCustomerUserDs,
        setAccountType
    }
});
