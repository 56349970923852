import React from "react";
import { DataBox } from "./DataBox";
import { Button, InputNumber, message } from "antd";
import { useSettingsModel } from "../settingsModel";
import { IoverstockEnum } from "../settingsType";

export const FormBox = () => {
    const { selectedNodes, purchaseOverStock, saleOverStock, definiyionOverStock, rankOverStock,
        setrankOverStock, setsaleOverStock, setPurchaseOverStock, setdefiniyionOverStock, updateDefiniyion,
        updateDefiniyionList,
        updateSo, updatePo
    } = useSettingsModel()
    const save = async () => {
        let ret = null
        if (selectedNodes[0]?.key === IoverstockEnum.overStock_SO) {
            let retData = await updateSo();
            ret = retData;
        } else if (selectedNodes[0]?.key === IoverstockEnum.overStock_PO) {
            let retData = await updatePo();
            ret = retData;
        } else if (selectedNodes[0]?.key === IoverstockEnum.overStock_DEFIND) {
            let retData = await updateDefiniyion();
            ret = retData;
        } else if (selectedNodes[0]?.key === IoverstockEnum.overStock_GRADE) {
            let retData = await updateDefiniyionList();
            ret = retData;
        }
        ret && message.success("操作成功");
    }
    const bgColor: any = {
        "一级": "background-1a53ff",
        "二级": "background-ffb31a",
        "三级": "background-e67300",
        "四级": "background-ff3333",
        "五级": "background-990000",
    }
    return (
        <>
            <div className="dict-list-header">
                <Button type="link" shape="round" onClick={() => save()}>保存</Button>
                <Button type="link" shape="round" onClick={() => { }}>取消</Button>
            </div>
            {/* 销售单积压处理 */}
            {selectedNodes[0]?.key === IoverstockEnum.overStock_SO && <div className="dict-from-content">
                <p>销售单积压设置：当商品满足以下条件时，则商品在选择积压处理后出现。</p>
                <div>
                    <span className="wd-100px">季销存率&lt;</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                        value={saleOverStock?.saleInventoryRatio as number}
                        onChange={(value) => setsaleOverStock({ ...saleOverStock, saleInventoryRatio: value as number })}
                    />
                    <span>(默认值&lt;1)</span>
                </div>
                <div className="mt-15">
                    <span className="wd-100px">积压标记&gt;=</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                        value={saleOverStock?.overstock as number}
                        onChange={(value) => setsaleOverStock({ ...saleOverStock, overstock: value as number })}
                    />
                    <span>(默认值&gt;=12)</span>
                </div>
            </div>}
            {/* 采购单积压处理 */}
            {selectedNodes[0]?.key === IoverstockEnum.overStock_PO && <div className="dict-from-content">
                <p>采购单积压件提醒设置：当商品满足以下条件时，则商品进行提醒。</p>
                <div>
                    <span className="wd-100px">季销存率&lt;</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                        value={purchaseOverStock?.saleInventoryRatio as number}
                        onChange={(value) => setPurchaseOverStock({ ...purchaseOverStock, saleInventoryRatio: value as number })}
                    />
                    <span>(默认值&lt;1)</span>
                </div>
                <div className="mt-15">
                    <span className="wd-100px">积压标记&gt;=</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                        value={purchaseOverStock?.overstock as number}
                        onChange={(value) => setPurchaseOverStock({ ...purchaseOverStock, overstock: value as number })}
                    />
                    <span>(默认值&gt;=12)</span>
                </div>
            </div>}
            {/* 积压件定义 */}
            {selectedNodes[0]?.key === IoverstockEnum.overStock_DEFIND && <div className="dict-from-content">
                <p>积压件定义：当商品满足以下条件时，则商品默认为积压件。</p>
                <div>
                    <span className="wd-100px">季销存率&lt;</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                        value={definiyionOverStock?.saleInventoryRatio as number}
                        onChange={(value) => setdefiniyionOverStock({ ...definiyionOverStock, saleInventoryRatio: value as number })}
                    />
                    <span>(默认值&lt;1)</span>
                </div>
                <div className="mt-15">
                    <span className="wd-100px">积压标记&gt;=</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                        value={definiyionOverStock?.overstock as number}
                        onChange={(value) => setdefiniyionOverStock({ ...definiyionOverStock, overstock: value as number })}
                    />
                    <span>(默认值&gt;=12)</span>
                </div>
            </div>}
            {/* 积压件等级设置*/}
            {selectedNodes[0]?.key === IoverstockEnum.overStock_GRADE && <div className="dict-from-content">
                <p>积压等级设置：(建议系统空闲时修改)。</p>
                {
                    rankOverStock.length !== 0 && rankOverStock.map((item, index, arr) => {
                        return (
                            <div key={index} className="mt-20">
                                <span className={`wd-50px ${bgColor[item.name as string]}`}>{item.name}：</span>
                                <span className="wd-100px">季销存率&lt;</span>
                                <InputNumber
                                    size="small"
                                    datatype="round"
                                    className="w-100px mx-5"
                                    placeholder=""
                                    min={0}
                                    value={item.saleInventoryRatio as number}
                                    onChange={(value) => {
                                        rankOverStock.splice(index, 1, { ...item, saleInventoryRatio: value as number })
                                        setrankOverStock([...rankOverStock])
                                    }}

                                />
                                <span className="wd-100px">积压标记&gt;=</span>
                                <InputNumber
                                    size="small"
                                    datatype="round"
                                    className="w-100px mx-5"
                                    placeholder=""
                                    min={0}
                                    value={item.overstock as number}
                                    onChange={(value) => {
                                        rankOverStock.splice(index, 1, { ...item, overstock: value as number })
                                        setrankOverStock([...rankOverStock])
                                    }}
                                // value={gradeAll["overstock_grade_one"]?.overstock as number}
                                // onChange={(value) => handleSetGradeAll("overstock_grade_one", +(value ?? 0))}
                                />
                            </div>
                        )
                    })
                }
                {/* <div className="mt-20">
                    <span className="wd-50px background-1a53ff">一级：</span>
                    <span className="wd-100px">季销存率&lt;</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                    // value={gradeAll["overstock_grade_one"]?.saleInventoryRatio as number}
                    // onChange={(value) => setGradeAll(
                    //     {
                    //         ...gradeAll,
                    //         overstock_grade_one: {
                    //             ...gradeAll["overstock_grade_one"],
                    //             saleInventoryRatio: value
                    //         }
                    //     }
                    // )}
                    />
                    <span className="wd-100px">积压标记&gt;=</span>
                    <InputNumber
                        size="small"
                        datatype="round"
                        className="w-100px mx-5"
                        placeholder=""
                        min={0}
                    // value={gradeAll["overstock_grade_one"]?.overstock as number}
                    // onChange={(value) => handleSetGradeAll("overstock_grade_one", +(value ?? 0))}
                    />
                </div> */}
            </div>}
        </>
    )
}