import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useStockPoDetailModel } from "../InventoryDetailsModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";

export const DataBox = () => {
    const { selectedRowKeys , exportStockOut, statistics} = useStockPoDetailModel();
    const { addNav, findNav, switchNavState } = useNavModel(() => []);

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行操作", type: "warning" });
            return;
        }
        let stockOutputEditKey = "4-4-1";
        let isFindNav = findNav(stockOutputEditKey);
        if (!isFindNav) {
            addNav(stockOutputEditKey, { id: selectedRowKeys[0], mode: "look" });
        } else {
            switchNavState(stockOutputEditKey, { id: selectedRowKeys[0], mode: "look" });
        }
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportStockOut()}
        >导出</Button>
    </>

const desc: IDescription[] = [
    {
        label: "库存数量",
        value: statistics?.presaleNum ?? 0,
        color: DescriptionColorEnum.green
    },

    {
        label: "占用库存",
        value: statistics?.occupyNum ?? 0,
        color: DescriptionColorEnum.green
    },
    {
        label: "可用数量",
        value: statistics?.availableNum ?? 0,
        color: DescriptionColorEnum.green
    },
    {
        label: "预售成本",
        value: statistics?.presaleTotalCost ?? 0,
        color: DescriptionColorEnum.red
    },
    {
        label: "库存金额",
        value: statistics?.presaleAmount?? 0,
        color: DescriptionColorEnum.red
    },
]
    return (
        <YhBox action={action} title="公交明细列表" descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}