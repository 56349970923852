import React, { useState, useRef } from "react";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { FormBox } from "./FormBox"
import { MaterialTableBox } from "./materialTableBox"
import { MaterialModal } from "./materialModel"
import { FixedBottom } from "./FixedBottom"
//import { HistoryModel } from "@/views/historyModel";
import { useDeploymentDetailModel } from "./deploymentDetailModel";
import {HistoryModel} from "../../../../detailedInventory/view/HistoryModel"
import {useStockPoDetailModel} from "../../../../detailedInventory/InventoryDetailsModel"
export const DeploymentDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    const {historyVisible,setHistoryVisible} = useStockPoDetailModel()
    const {historyRow,} = useDeploymentDetailModel();
    return (
        <>
            <FormBox  {...detailsInfo} IFormRef={IFormRef} />
            <MaterialTableBox {...detailsInfo} />
            <MaterialModal {...detailsInfo} />
            <FixedBottom {...detailsInfo} />
            {/* <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                isTransit={false}
                onCancel={() => setHistoryVisible(false)}
            /> */}
             <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
        </>
    )
}