import React, { useEffect } from "react";
import { useWriteoffModel } from "./writeoffModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { YhBox } from "@/components/YhBox";
import { WriteoffDataTable } from "./DataTable";

export const WriteoffDataBox = () => {
    const {
        dataSource,
        writeoffAmount,
        loadData,
        selectedRowKeys,
        setSelectedData,
    } = useWriteoffModel();

    useEffect(() => {
        loadData({ pageNum: 1 })
    }, [])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
    }, [selectedRowKeys]);

    const desc: IDescription[] = [
        {
            label: "核销总额",
            value: writeoffAmount?.toFixed(2) ?? '0.00',
            color: DescriptionColorEnum.green
        },

    ]

    return (
        <>
            <YhBox title={"核销记录"} descriptions={desc} >
                <WriteoffDataTable />
            </YhBox>
        </>
    )
}