import { createModel } from "hox";
import { useState, useEffect, Key } from "react";
import { TablePaginationConfig } from "antd/es/table/interface";
import useGlobalModel from "@/model/globalModel";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { IStockStatisticSearch, IStockStatisticDetail } from "@/views/stock/statistic/stockStatisticService";
import {
    IItemDetailList,
    IMaterialDetailList,
    IAppointmentField,
    IAppointmentDetail,
} from "../../appointmentType"
import { appointmentService } from "../../appointmentService"
import { IItemDetail, IItemSearch } from "@/views/base/item/itemType";
import { ISearchPage } from "@/types/ScmTypes";
import { itemService } from "@/views/base/item/itemService";
import { transPgToPagination, formatNum } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import { IVehicleDetail } from "@/views/base/customerFiles/vehicle/vehicleType";
import moment from "moment";
import { DictCodeEnmu, sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { useLockFn } from 'ahooks';
import { message } from "antd";
import { saleOrderService } from '@/views/sale/order/saleOrderService';
import { IPurchaseStock } from '@/views/sale/order/saleOrderTypes';


export type IAppointmentDescType = {
    workHours: number, //工时
    workHoursNum: number, //工时项数
    workHoursPrice: number, //工时费 折前
    workHoursDiscount: number, //工时优惠
    workHoursTotal: number, //工时总计 折后
    partPrice: number, //零件费 折前
    partPriceNum: number, //零件费项数
    partsDiscount: number, //零件优惠
    partsTotal: number, //零件总计 折后
    discountAll: number,//优惠金额
    receivableAll: number,//实收金额
    totalAll: number, //总计   
    amountReceivable: number //公交对应车辆型号金额

}
//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IPurchaseStock {
    num: number,
    price?: number,
    amount?: number
}
export const useAppointmentDetailsModel = createModel(function () {
    const { user, shopAndOrg } = useGlobalModel();
    const { shopId, shopName, shopCode } = shopAndOrg
    const isShopQiChi = shopCode === "SHOP-M-00000023" //判断当前门店是否为綦齿门店
    const [itemModel, setItemModel] = useState<boolean>(false); //维修项目弹框
    const [materialModel, setMaterialModel] = useState<boolean>(false); //维修项目弹框 
    const initDesc: IAppointmentDescType = {
        workHours: 0, //工时
        workHoursNum: 0, //工时项数
        workHoursPrice: 0, //工时费 折前
        workHoursDiscount: 0, //工时优惠
        workHoursTotal: 0, //工时总计 折后

        partPrice: 0, //零件费 折前
        partPriceNum: 0, //零件费项数
        partsDiscount: 0, //零件优惠
        partsTotal: 0, //零件总计 折后
        discountAll: 0,//优惠金额
        receivableAll: 0,//实收金额
        totalAll: 0, //总计    
        amountReceivable: 0 //公交对应车辆型号金额
    }
    const initForm = { shopId, shopName, favorableAmount: 0, enterTime: moment(new Date()) }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initMaterialSearchParam: IStockStatisticSearch = {};
    const [vehicleEditAll, setVehicleEditAll] = useState<IVehicleDetail[]>([])//实时切换车辆 编辑
    const [vehicleAddAll, setVehicleAddAll] = useState<IVehicleDetail[]>([])//实时切换车辆 新增
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IAppointmentDetail>({ initForm })
    //新增项目详情列表
    const [detailItemAddList, setDetailItemAddList] = useState<IItemDetailList[]>([])
    //新增项目详情列表选中行
    const [detailItemAddDataRowKeys, setDetailItemAddDataRowKeys] = useState<Key[]>([]);
    const [detailItemAddDataRow, setDetailItemAddDataRow] = useState<IItemDetailList[]>([]);
    //新增维修用料详情列表
    const [detailMaterialAddList, setDetailMaterialAddList] = useState<IMaterialDetailList[]>([])
    //新增维修用料详情列表选中行
    const [detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialAddDataRow, setDetailMaterialAddDataRow] = useState<IMaterialDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<IAppointmentDescType>(initDesc)

    //编辑数据
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState<IAppointmentDetail>({})
    //编辑项目详情列表
    const [detailItemEditList, setDetailItemEditList] = useState<IItemDetailList[]>([])
    //编辑项目详情列表选中行
    const [detailItemEditDataRowKeys, setDetailItemEditDataRowKeys] = useState<Key[]>([]);
    const [detailItemEditDataRow, setDetailItemEditDataRow] = useState<IItemDetailList[]>([]);
    //编辑维修用料详情列表
    const [detailMaterialEditList, setDetailMaterialEditList] = useState<IMaterialDetailList[]>([])
    //编辑维修用料详情列表选中行
    const [detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialEditDataRow, setDetailMaterialEditDataRow] = useState<IMaterialDetailList[]>([]);
    const [editDesc, setEditDesc] = useState<IAppointmentDescType>(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IAppointmentDetail>({})
    //查看项目详情列表
    const [detailItemLookList, setDetailItemLookList] = useState<IItemDetailList[]>([])
    //查看维修用料详情列表
    const [detailMaterialLookList, setDetailMaterialLookList] = useState<IMaterialDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<IAppointmentDescType>(initDesc)

    //配件列表配置
    const [workMaterialDataSource, setWorkMaterialDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkMaterialData, setSearchWorkMaterialData] = useState<IStockStatisticSearch>(initMaterialSearchParam)
    const [workMaterialPgCfg, setWorkMaterialPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataMaterialSource, setAddDataMaterialSource] = useState<IMaterialDetailModel[]>([]);

    //维修项目列表配置
    const [workItemDataSource, setWorkItemDataSource] = useState<IItemDetail[]>([])
    const [searchWorkItemData, setSearchWorkItemData] = useState<IItemSearch>(initMaterialSearchParam)
    const [workItemPgCfg, setWorkItemPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选维修项目列表
    const [addDataItemSource, setAddDataItemSource] = useState<IItemDetail[]>([]);

    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)
    useEffect(() => {
        let newDesc: IAppointmentDescType = { ...initDesc };
        detailItemAddList && detailItemAddList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialAddList && detailMaterialAddList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemAddList.length
        newDesc.partPriceNum = detailMaterialAddList.length
        if ("favorableAmount" in detailAddForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailAddForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailAddForm.favorableAmount)
        }
        isShopQiChi && (newDesc.amountReceivable = detailAddForm.amountReceivable);
        setAddDesc(newDesc);
    }, [detailItemAddList, detailMaterialAddList, detailAddForm])
    useEffect(() => {
        let newDesc: IAppointmentDescType = { ...initDesc };
        detailItemEditList && detailItemEditList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialEditList && detailMaterialEditList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemEditList.length
        newDesc.partPriceNum = detailMaterialEditList.length
        if ("favorableAmount" in detailEditForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailEditForm.favorableAmount)
        }
        setEditDesc(newDesc);
        isShopQiChi && (newDesc.amountReceivable = detailEditForm.amountReceivable);
    }, [detailItemEditList, detailMaterialEditList, detailEditForm])
    useEffect(() => {
        let newDesc: IAppointmentDescType = { ...initDesc };
        detailItemLookList && detailItemLookList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialLookList && detailMaterialLookList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemLookList.length
        newDesc.partPriceNum = detailMaterialLookList.length
        if ("favorableAmount" in detailLookForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailLookForm.favorableAmount)
        }
        setLookDesc(newDesc);
        isShopQiChi && (newDesc.amountReceivable = detailLookForm.amountReceivable);
    }, [detailItemLookList, detailMaterialLookList, detailLookForm])

    //新增表单缓存
    const updateAddFormVal = (val: IAppointmentDetail) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updateEditFormVal = (val: IAppointmentDetail) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }

    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await appointmentService.one(id);
        const { itemDetails, materialDetails, deliveryTime, enterTime, ...from } = retData
        const newItemDetails = itemDetails?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newMaterialDetails = materialDetails?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newFrom = {
            ...from,
            enterTime: enterTime ? moment(enterTime) : null,
            deliveryTime: deliveryTime ? moment(deliveryTime) : null
        }
        if (retData && pattern === "edit") {
            setDetailItemEditList(newItemDetails as IItemDetailList[] ?? [])
            setDetailMaterialEditList(newMaterialDetails as IMaterialDetailList[] ?? [])
            setDetailEditForm(newFrom)
            setTopButsControl(false)
        } else if (retData && pattern === "look") {
            setDetailLookForm(newFrom);
            setDetailItemLookList(newItemDetails as IItemDetailList[] ?? [])
            setDetailMaterialLookList(newMaterialDetails as IMaterialDetailList[] ?? [])
        }
    }
    //关闭维修项目弹框
    const resetWorkItemloadData = async () => {
        setSearchWorkItemData({});
        workItemloadData(initPgCfg, {});
    }
    const workItemloadData = async (page?: ISearchPage, searchValue: IItemSearch = searchWorkItemData) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            pageSize: page?.pageSize ?? workItemPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workItemPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await itemService.page(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            return {
                ...item,
                num: 1,
            }
        })
        setWorkItemPgCfg(transPgToPagination(pg));
        setWorkItemDataSource(newRecords);
    }
    //关闭维修用料弹框
    const resetWorkMaterialloadData = async () => {
        setSearchWorkMaterialData({});
        workMaterialloadData(initPgCfg, {});
    }
    const workMaterialloadData = async (page?: ISearchPage, searchValue: IStockStatisticSearch = searchWorkMaterialData, mode?: string) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            status:`${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            pageSize: page?.pageSize ?? workMaterialPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workMaterialPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await saleOrderService.stock(searchParams);
        const newServiceNatureCode = mode === "add" ? detailAddForm.serviceNatureCode : detailEditForm.serviceNatureCode
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            let { lateSalePrice, latestPurPrice, purchasePrice, retailPrice } = item
            let newPrice = newServiceNatureCode === "repair-type.responsible" //有责返修
                || newServiceNatureCode === "repair-type.no-resp"//无责返修
                || newServiceNatureCode === "repair-type.pack-upper"//组装上盖 下面得是小修
                || newServiceNatureCode === "repair-type.minor" ? purchasePrice : (lateSalePrice === 0 || lateSalePrice === null ? retailPrice ?? 0 : lateSalePrice ?? 0)

            return {
                ...item,
                num: 1,
                lateSalePrice: newPrice,
                amount: newPrice
            }
        })
        setWorkMaterialPgCfg(transPgToPagination(pg));
        setWorkMaterialDataSource(newRecords);
    }
    //筛选维修用料模块详情 详情和新增的列表已有数据的方法
    const modelMaterialScreenMethod = async (modelList: IMaterialDetailModel[], detailList: IMaterialDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IMaterialDetailList[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.detailsId === Item.id);
            let { id, ids, materialCode, materialName, inventoryNum, availableStock, num, lateSalePrice, amount, ...params } = Item
            if (isSelect === undefined) {
                newDetailData.push({
                    ...params,
                    discountRate: 100,
                    id: "",
                    inventoryNum: availableStock,
                    materialCode,
                    materialName,
                    num,
                    materialAmount: amount,
                    price: lateSalePrice,
                    receivableAmount: amount,
                    stockId: ids,
                    detailsId: id,
                });
            } else {
                repetition.push(Item.materialName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到维修用料详情列表
    const transformsToPoMaterialDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialAddList)
            setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialEditList)
            setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailMaterialDetailList = async (mode: string, parentInfo: string) => {
        if (mode === "add") {
            const filterDetailList = detailMaterialAddList.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (parentInfo) {
            const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialEditList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialEditDataRowKeys([]);
            setDetailMaterialEditDataRow([]);
        } else if (mode === "edit") {
            //删除详情列表调用的方法
            const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialEditList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialEditDataRowKeys([]);
            setDetailMaterialEditDataRow([]);
        }
    }

    //筛选维修项目模块详情 详情和新增的列表已有数据的方法
    const modelItemScreenMethod = async (modelList: IItemDetail[], detailList: IItemDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IMaterialDetailList[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.itemName === Item.name);
            let { code, name, id, amount, price, workHours, ...params } = Item;
            if (isSelect === undefined) {
                newDetailData.push({
                    ...params,
                    id: "",
                    detailsId: id,
                    discountRate: 100,
                    itemAmount: amount,
                    itemCode: code,
                    itemName: name,
                    price: price,
                    receivableAmount: amount,
                    workHour: workHours,
                    purchaseId:id
                });
            } else {
                repetition.push(Item.name as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到维修项目详情列表
    const transformsToPoItemDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelItemScreenMethod(addDataItemSource, detailItemAddList)
            setDetailItemAddList([...detailItemAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelItemScreenMethod(addDataItemSource, detailItemEditList)
            setDetailItemEditList([...detailItemEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除维修项目详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailItemDetailList = async (mode: string, parentInfo: string) => {
        if (mode === "add") {
            const filterDetailList = detailItemAddList.filter(el => !!!detailItemAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailItemAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailItemAddDataRowKeys([]);
            setDetailItemAddDataRow([]);
        } else if (parentInfo) {
            //删除详情列表调用的方法
            const filterDetailList = detailItemEditList.filter(el => !!!detailItemEditDataRowKeys.find(ele => ele === el.detailsId))
            setDetailItemEditList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailItemEditDataRowKeys([]);
            setDetailItemEditDataRow([]);
            // }
        } else if (mode === "edit") {
            const backendDetailList: any = detailItemEditDataRow.reduce((all: object[], next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);

            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = detailItemEditList.filter(el => !!!detailItemEditDataRowKeys.find(ele => ele === el.detailsId))
                setDetailItemEditList(filterDetailList)
                globalPrompt("message", { text: `删除成功`, type: "success" });
                setDetailItemEditDataRowKeys([]);
                setDetailItemEditDataRow([]);
            }

            if (backendDetailList.length > 0) {
                await appointmentService.itemDelete({ ids: backendDetailList, appointmentCode: detailEditForm.appointmentCode as string }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        globalPrompt("message", { text: `删除失败`, type: "success" });
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        let item = mode === "edit" ? detailItemEditList : detailItemAddList;
        if (goods.length === 0 && item.length === 0) {
            message.warning(`请补全单据的维修项目与维修用料`);
            return false;
        }
        let { workHoursTotal, partsTotal, receivableAll } = mode === "edit" ? editDesc : addDesc;
        workHoursTotal = +formatNum(workHoursTotal);//工时总计 折后
        partsTotal = +formatNum(partsTotal);//配件总计 折后
        receivableAll = +formatNum(receivableAll);

        let goodsTotal: number = 0;
        let itemsTotal: number = 0;
        if (goods.length !== 0) {
            goods.forEach(item => {
                let { receivableAmount } = item as { receivableAmount: number }
                // 优惠后单价  总计 -整单优惠 * (单品总计/sum(单品总计) /开单数量
                goodsTotal += receivableAmount
            })
            item.forEach(item => {
                let { receivableAmount } = item as { receivableAmount: number }
                itemsTotal += receivableAmount
            })
        } else {
            item.forEach(item => {
                let { receivableAmount } = item as { receivableAmount: number }
                // 优惠后单价  总计 -整单优惠 * (单品总计/sum(单品总计) /开单数量
                goodsTotal += receivableAmount
            })
        }
        if (+formatNum(goodsTotal + itemsTotal) !== receivableAll) {
            message.warning(`详情维修总金额金额${+formatNum(goodsTotal)}与表单维修总金额金额不符`);
            return false;
        }
        return true
    }
    //新增预约单
    const insertDetail = useLockFn(async (params: IAppointmentField) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            itemDetails: detailItemAddList,
            materialDetails: detailMaterialAddList,
            createUser: user.username
        }
        let { retData } = await appointmentService.insert(newParams)
        return retData
    })
    //编辑预约单
    const editDetail = useLockFn(async (params: IAppointmentField) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            itemDetails: detailItemEditList,
            materialDetails: detailMaterialEditList,
            updateUser: user.username
        }
        let { retData } = await appointmentService.update(newParams)
        return retData
    })

    //转工单核对
    const transfer = async (params: IAppointmentField) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            favorableAmount: 0,
            itemDetails: detailItemEditList,
            materialDetails: detailMaterialEditList,
            updateUser: user.username,
            createUser: user.username

        }
        let { retData } = await appointmentService.transfer(newParams)
        return retData
    }
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddForm(initForm)
        setDetailItemAddList([]);
        setDetailMaterialAddList([]);
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailItemEditList([]);
        setDetailMaterialEditList([]);
    }
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [consumeHistoryVisible, setConsumeHistoryVisible] = useState<boolean>(false) //消费记录展示
    const [consumeHistoryModel, setConsumeHistoryModel] = useState<{ customerName: string }>({ customerName: "" }) //消费记录参数
    const [repairHistoryVisible, setRepairHistoryVisible] = useState<boolean>(false) //维修历史展示
    const [repairHistoryModel, setRepairHistoryModel] = useState<{ licensePlate: string }>({ licensePlate: "" }) //维修历史


    return {
        initForm,
        itemModel,
        materialModel,
        initPgCfg,
        initMaterialSearchParam,
        detailAddForm,
        detailItemAddList,
        detailItemAddDataRowKeys,
        detailItemAddDataRow,
        detailMaterialAddList,
        detailMaterialAddDataRowKeys,
        detailMaterialAddDataRow,
        addDesc,
        detailEditForm,
        detailItemEditList,
        detailItemEditDataRowKeys,
        detailItemEditDataRow,
        detailMaterialEditList,
        detailMaterialEditDataRowKeys,
        detailMaterialEditDataRow,
        editDesc,
        detailLookForm,
        detailItemLookList,
        detailMaterialLookList,
        lookDesc,
        workMaterialDataSource,
        searchWorkMaterialData,
        workMaterialPgCfg,
        addDataMaterialSource,
        workItemDataSource,
        searchWorkItemData,
        workItemPgCfg,
        addDataItemSource,
        topButsControl,
        transfer,
        setItemModel,
        setMaterialModel,
        setDetailAddForm,
        setDetailItemAddList,
        setDetailItemAddDataRowKeys,
        setDetailItemAddDataRow,
        setDetailMaterialAddList,
        setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        setAddDesc,
        setDetailEditForm,
        setDetailItemEditList,
        setDetailItemEditDataRowKeys,
        setDetailItemEditDataRow,
        setDetailMaterialEditList,
        setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        setEditDesc,
        setDetailLookForm,
        setDetailItemLookList,
        setDetailMaterialLookList,
        setLookDesc,
        setWorkMaterialDataSource,
        setSearchWorkMaterialData,
        setWorkMaterialPgCfg,
        setAddDataMaterialSource,
        setWorkItemDataSource,
        setSearchWorkItemData,
        setWorkItemPgCfg,
        setAddDataItemSource,
        setTopButsControl,
        updateAddFormVal,
        updateEditFormVal,
        getDetailEditOne,
        resetWorkItemloadData,
        workItemloadData,
        resetWorkMaterialloadData,
        workMaterialloadData,
        modelMaterialScreenMethod,
        transformsToPoMaterialDetailList,
        detailMaterialDetailList,
        modelItemScreenMethod,
        transformsToPoItemDetailList,
        detailItemDetailList,
        vehicleEditAll, setVehicleEditAll,
        vehicleAddAll, setVehicleAddAll,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        consumeHistoryModel, setConsumeHistoryModel,
        consumeHistoryVisible, setConsumeHistoryVisible,
        repairHistoryModel, setRepairHistoryModel,
        repairHistoryVisible, setRepairHistoryVisible,
        isShopQiChi,
        formComputeRule
    }
})