import React, { FC } from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailsModel";
import { Description } from "@/components/YhBox/Description";
import { ITopType } from "@/types/ScmTypes";
import { useDictModel } from "@/views/base/dict/dictModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const TopButs: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const { detailAddForm, detailEditForm, detailLookForm, detailLookList, detailDataSource, topButsControl, setSettleVisible, setWsConfirmModal } = usePurchaseRoDetailsModel();
    const { getTreeNodeName } = useDictModel();
    const onWhConfirm = async () => {
        let materialNames: string[] = []
        let typeList = {
            add: [],
            edit: detailDataSource,
            look: detailLookList
        }
        await typeList[mode].forEach(item => {
            if (!!!item.shelfCode && !!!item.warehouseCode) {
                materialNames.push(item.materialName as string)
            }
        })
        if (materialNames.length === 0 && typeList[mode].length != 0) {
            await setWsConfirmModal(true)
        } else {
            await globalPrompt("message", { type: "error", text: `请选择商品${materialNames.toString()}的仓库与货位` })
        }
    }
    const action = mode === "edit" ? <>
        <YhAuth resCode={ResourceAuthEnum.P_R_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl || detailEditForm.status === "purchase-return.settle"}
                onClick={() => setSettleVisible(true)}
            >确认结算</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_R_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl || detailEditForm.status === "purchase-return.stock-io"}
                onClick={onWhConfirm}
            >确认出库</Button>
        </YhAuth>
    </> : mode === "look" ? <>
        <YhAuth resCode={ResourceAuthEnum.P_R_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl}
                onClick={() => setSettleVisible(true)}
            >结算查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_R_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl}
                onClick={onWhConfirm}
            >入库查看</Button>
        </YhAuth>

    </> : false;
    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }

    const orderStatu: IDescription[] = [
        {
            label: "采购退货单",
            value: typeTitle[mode]?.returnCode as string ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}