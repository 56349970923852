import React, { useState, useRef } from "react";
import { TopButs } from "./TopButs"
import { FormBox } from "./FormBox"
import { MaterialTableBox } from "./materialTableBox"
import { ItemTableBox } from "./itemTableBox"
import { FixedBottom } from "./FixedBottom"
import { MaterialModal } from "./materialModal"
import { ItemModal } from "./itemModal"
import { SettleDrawer } from "./SettleDrawer"
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { useMaintenanceDetailsModel } from "./maintenanceDetailsModels";
import { HistoryModel } from "@/views/historyModel";
import { ConsumeHistoryModel } from "@/views/commonModule/consumeHistoryModel";
import { RepairHistoryModel } from "@/views/commonModule/repairHistoryModel";


export const MaintenanceDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const {
        historyVisible, setHistoryVisible,
        historyRow,
        consumeHistoryModel,
        consumeHistoryVisible, setConsumeHistoryVisible,
        repairHistoryModel,
        repairHistoryVisible, setRepairHistoryVisible,
    } = useMaintenanceDetailsModel()
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    return (
        <>
            <TopButs {...detailsInfo} />
            <FormBox  {...detailsInfo} IFormRef={IFormRef} />
            <ItemTableBox {...detailsInfo} />
            <MaterialTableBox {...detailsInfo}  IFormRef={IFormRef}/>
            <FixedBottom {...detailsInfo} />
            <MaterialModal {...detailsInfo} />
            <ItemModal {...detailsInfo} />
            <SettleDrawer {...detailsInfo} />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <ConsumeHistoryModel
                visible={consumeHistoryVisible}
                name={consumeHistoryModel.customerName}
                onCancel={() => setConsumeHistoryVisible(false)}
            />
            <RepairHistoryModel
                visible={repairHistoryVisible}
                licensePlate={repairHistoryModel.licensePlate}
                onCancel={() => setRepairHistoryVisible(false)}
            />
        </>
    )
}