import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useBusBusinessModel } from "../../busBusinessModel";
import {
    IBusBusinessDetail,
} from "../../busBusinessType"
import { useDictModel } from "@/views/base/dict/dictModel";
import useNavModel from "@/model/navModel";




export function DataTable() {
    const { dataSource, selectedRowKeys, pgCfg, descRows, loadData, setSelectedRowKeys, setDescRows } = useBusBusinessModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    useEffect(() => {
        loadData().then();
    }, [])
    const columns: ColumnsType<IBusBusinessDetail> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '仓库编码',
            width: 160,
            fixed: "left",
            dataIndex: 'code',
        }, {
            title: '仓库名称',
            width: 120,
            fixed: "left",
            dataIndex: 'name',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '初始库存',
            width: 160,
            dataIndex: 'inventoryInitial',
        }, {
            title: '库存数量',
            width: 120,
            dataIndex: 'inventoryNum',
        }, {
            title: '预退数量',
            width: 120,
            dataIndex: 'preturnNum',
        }, {
            title: '预售数量',
            width: 120,
            dataIndex: 'pretsaleNum',
        }, {
            title: '销售数量',
            width: 120,
            dataIndex: 'saleNum',
        }, {
            title: '销售金额',
            width: 120,
            dataIndex: 'saleAmount',
            render:(text)=>`￥${text}`
        }, {
            title: '调配数量',
            width: 120,
            dataIndex: 'presaleNum',
        }, {
            title: '可用库存',
            width: 120,
            dataIndex: 'availableStock',
        }, {
            title: '库存金额',
            width: 120,
            dataIndex: 'inventoryAmount',
            render:(text)=>`￥${text}`
        }
    ];
    //点击行选高亮
    const onRow = (record: IBusBusinessDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record: IBusBusinessDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IBusBusinessDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
