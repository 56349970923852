import React, { FC, useState } from "react";
import { Button, Table, Upload, Tooltip, Input, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { globalPrompt } from "@/components/message";
import { ColumnsType } from "antd/es/table";
import { DetailType } from "@/types/ScmEnums";
import { useStockCheckDetailModel } from "./stockCheckDetailsModel";
import { IDetailList } from "../../stockCheckType"
import { EditableCell, EditableRow } from "@/components/YHEditTable/editTable";
import { importPropsConfig } from "@/utils/importFile";
import { ITopType } from "@/types/ScmTypes";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { stockCheckService } from "../../stockCheckService";

type ISCMSDcGoodsSearch = {
    data: any[],
    isSearch: boolean
}
export const TableBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const { setAddModalVisible,
        detailDataSource,
        detaiAddDataRowKeys,
        onExport,
        modelScreenMethod,
        setProfitAndLoss,
        detailLookList,
        detailAddList,
        detailDataRowKeys,
        setDetailAddList,
        setDetailDataRow,
        setDetailDataSource,
        setDetailDataRowKeys,
        // detailDetailList,
        setDetaiAddDataRowKeys,
        detailDataRow,
        setDetaiAddDataRow } = useStockCheckDetailModel();

    const importCallback = async (list: any) => {
        if (mode === "add") {
            const { repetition, newDetailData } = await modelScreenMethod(list, detailAddList)
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length !== 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        } else if (mode === "edit") {
            const { repetition, newDetailData } = await modelScreenMethod(list, detailDataSource)
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length !== 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
    }
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMSDcGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据
    const addMaterial = () => {
        IFormRef.current?.validateFields().then(async res => {
            setAddModalVisible(true)
        })
    }
        //删除详情列表数据
        const detailDetailList = async (mode: string) => {
            if (mode === "add") {
                const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.detailsId))
                setDetailAddList(filterDetailList)
                let newSearchData = searchVal.data.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.detailsId))
                setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
                globalPrompt("message", { text: `删除成功`, type: "success" });
                setDetaiAddDataRowKeys([]);
                setDetaiAddDataRow([])
            } else if (mode === "edit") {
                const backendDetailList: any = detailDataRow.reduce((all: object[], next) => {  //前端页面维护的需要删除的id
                    if (next.id) {
                        all.push({ id: next.id });
                    }
                    return all;
                }, []);            //删除详情列表调用的方法
                const deleteData = () => {
                    const filterDetailList = detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.detailsId))
                    // return console.log(filterDetailList)
                    setDetailDataSource(filterDetailList)
                    let newSearchData = searchVal.data.filter(el => !!!detailDataRowKeys.find(ele => ele === el.detailsId))
                    setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
                    globalPrompt("message", { text: `删除成功`, type: "success" });
                    setDetailDataRowKeys([]);
                    setDetailDataRow([])
                }
                if (backendDetailList.length > 0) {
                    await stockCheckService.materialDelete({ ids: backendDetailList }).then(res => {
                        if (res.retData) {
                            deleteData()
                        } else {
                            globalPrompt("message", { text: `删除失败`, type: "success" });
                        }
                    })
                } else {
                    deleteData()
                }
            }
        }
    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource: any | null = null) => {
        const isEtit = mode !== "add";
        let goodsList = dataSource ? dataSource : [...(isEtit ? detailDataSource : detailAddList)];
        if (searchParms) {
            let reg = new RegExp(searchParms);
            let arr: any = [];
            goodsList.forEach((item: { materialCode: string; materialName: string; }) => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })

            setSearchVal({ data: arr, isSearch: true });

            if (!arr.length) {
                message.warning("列表没有该商品！！")
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }

    const action = mode === DetailType.look ? <></> : <>
      <Input
                size="small"
                allowClear
                datatype="round"
                className="border-r-8 ml-10"
                placeholder="配件编号/配件名称"
                onChange={(e) => {setSearchParms(e.target.value );goodsFilter()}}
                onBlur={(e) => setSearchParms(e.target.value?.trim() ) }
                onPressEnter={() => goodsFilter()}
                style={{width:'200px'}}
            />
            <Button type="link" style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 查询</Button>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_ED_ADD_M}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={addMaterial}
            >新增配件</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_ED_EDL_M}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { detailDetailList(mode) }}
            >删除</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_ED_IM}>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/stock/check/details/import`, importCallback)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_ED_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { onExport() }}
            >下载模板</Button>
        </YhAuth>
    </>;
    const columns = [
        {
            title: '序号',
            width: 60,
            render: (t: string, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '商品编码',
            width: 120,
            dataIndex: 'materialCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            dataIndex: 'materialName',
            width: 120,
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '仓库',
            dataIndex: 'warehouseName',
            width: 120,

        }, {
            title: '货位',
            dataIndex: 'shelfCode',
            width: 120,

        }, {
            title: '系统库存',
            dataIndex: 'inventoryNum',
            width: 120,

        }, {
            title: '实际库存',
            dataIndex: 'realNum',
            editable: mode != "look" ? true : false,
            width: 90,
        }, {
            title: '最近进货价',
            dataIndex: 'averagePurchasePrice',
            editable: mode != "look" ? true : false,
            width: 120,

        }, {
            title: '盘点结果',
            dataIndex: 'result',
            width: 120,

        }, {
            title: '差异量',
            dataIndex: 'differenceNum',
            width: 120,

        }, {
            title: "差异金额",
            dataIndex: 'differenceAmount',
            width: 120,

        }, {
            title: '报废数量',
            dataIndex: 'scrapNum',
            editable: mode != "look" ? true : false,
            width: 120,

        }, {
            title: '报废金额',
            dataIndex: 'scrapAmount',
            width: 120,

        }, {
            title: '差异原因',
            width: 160,
            dataIndex: 'note',
            editable: mode != "look" ? true : false,

        }, {
            title: '报废原因',
            width: 160,
            dataIndex: 'scrapReason',
            editable: mode != "look" ? true : false,

        }
    ];
    const newSelectMaterialColums = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave = (row: IDetailList) => {
        let { realNum, result, differenceAmount, differenceNum, inventoryNum, latestPurPrice, averagePurchasePrice, scrapNum, scrapAmount } = row
        scrapNum = +formatNum(scrapNum)
        averagePurchasePrice = +formatNum(averagePurchasePrice)
        let newRow = {
            ...row,
            differenceNum: Number(realNum) - Number(inventoryNum),
            result: setProfitAndLoss(Number(realNum) - Number(inventoryNum)),
            differenceAmount: +((Number(realNum) - Number(inventoryNum)) * Number(averagePurchasePrice)).toFixed(2),
            scrapAmount: +formatNum(scrapNum * averagePurchasePrice)
        }
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(newData)
        }
    }
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,

    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="盘点明细" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.detailsId as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectMaterialColums() as ColumnsType<IDetailList>}
                dataSource={searchVal.isSearch ? searchVal.data :typeList[mode]}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                scroll={{ x: 'max-content', y: 450 }}
            />
        </YhBox>
    )
}