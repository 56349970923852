import React, { useRef, useEffect } from "react";
import { message, Select, Table } from "antd";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { useSummaryModel } from "./summaryModel";
import { accountService, creditFlagDs } from "../../../AccountService";
import { Form, Input, Button, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDetailModel } from "../detail/detailModel";
import { fmsDictService } from "../../../dictService";
import { ColumnsType } from "antd/lib/table";
import { ICustomerAccountSummary, IFMSInitAccountForm, ISCMInitCustomer } from "../../../AccountTypes";
import useGlobalModel from "@/model/globalModel";
import { YHDrawer } from "@/components/YhDrawer";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { CreditFlagEnum } from "@/types/ScmEnums";
import { AccountTypeEnum } from "@/types/FmsEnums";
import { useLockFn } from "ahooks";


export const InitAccountDrawer = () => {
	const [form] = Form.useForm();

	const { initAccountVisible, setInitAccountVisible, loadData, selectedRowKeys, setSelectedRowKeys } = useSummaryModel();
	const { shopAndOrg: { shopCode, shopName }, appDs, beShopDs } = useGlobalModel();
	const { accountTypeDs, setAccountType } = useDetailModel(model => [model.accountTypeDs]);

	useEffect(() => {
		if (selectedRowKeys.length === 0) {
			onCancel();
		}
	}, [selectedRowKeys])

	useEffect(() => {
		if (initAccountVisible) {
			fmsDictService.dictItemDs(DictCodeEnmu.ACCOUNT_TYPE).then(ds => setAccountType(ds))
		} else {
			form.resetFields();
		}
	}, [initAccountVisible]);

	const onOk = useLockFn(async () => {
		form.validateFields().then(res => {
			let result = res as IFMSInitAccountForm;
			// let accountType = result?.fmsAccounts?.map(item => item.accountTypeCode); //判断选中的时候有重读的账户类型
			// if (accountType.length !== new Set(accountType).size) {
			// 	message.warning("请不要选择有重复的账户类型");
			// 	return;
			// }
			let mdCustomers: ISCMInitCustomer[] = selectedRowKeys.map(item => ({
				code: item.code ?? "",
				name: item.name ?? ""
			}))
			accountService.initAccount({
				type: "1",
				fmsAccounts: result?.fmsAccounts ?? [],
				mdCustomers,
			}).then(res => {
				if (res) {
					onCancel();
					setSelectedRowKeys([]);
					message.success(res.retData ?? "初始化成功")
				}
			})
		})
	})

	const onCancel = () => {
		setInitAccountVisible(false);
	}


	//设置对应字段的名称
	const setFormFmsAccounts = (index: number, fieldName: string, fieldVal: ISelectOption) => {
		let fromVal: IFMSInitAccountForm = form.getFieldsValue();
		let newVal = fromVal.fmsAccounts[index];
		//判断当 更改授信类型时， 重置账户类型重新选择
		if (fieldName === "creditFlag") {
			fromVal.fmsAccounts.splice(index, 1, { ...newVal, ["accountTypeCode"]: undefined });
		} else {
			fromVal.fmsAccounts.splice(index, 1, { ...newVal, [fieldName]: fieldVal.label });
		}
		form.setFieldsValue(fromVal);
	}

	const columns: ColumnsType<ICustomerAccountSummary> = [
		{
			title: '序号',
			width: 66,
			align: "center",
			fixed: "left",
			render: (t, r, i) => i + 1
		},
		{
			align: "center",
			title: '供应商名称',
			dataIndex: 'name',
		},
		{
			align: "center",
			title: '供应商编码',
			dataIndex: 'code',
		},
		{
			title: '操作',
			width: 80,
			align: "center",
			render: (text, record) => <MinusCircleOutlined
				className="dynamic-delete-button"
				onClick={() => {
					let filterData = selectedRowKeys.filter(item => item.key !== record.key);
					setSelectedRowKeys(filterData);
				}}
			/>
		}
	]
	return (
		<YHDrawer
			{...{
				title: "账户初始化",
				width: 900,
				visible: initAccountVisible,
				closable: true,
				onOk,
				onCancel,
				showFooter: true
			}}
		>
			<Form form={form} autoComplete="off" initialValues={{
				"fmsAccounts": [{
					branch: undefined,
					accountTypeCode: undefined,
					accountTypeName: undefined,
					shopCode,
					shopName,
					beCode: undefined,
					beName: undefined,
					creditFlag: CreditFlagEnum.CREDIT
				}]
			}}>
				<Form.List name="fmsAccounts">
					{(fields, { add, remove }) => {
						return (
							<>
								{fields.map((field, index) => (
									<Space key={field.key} style={{ display: 'flex' }} align="baseline">
										<Form.Item
											{...field}
											name={[field.name, 'branch']}
											fieldKey={[field.fieldKey as any, 'branch']}
											rules={[{ required: true, message: '请选择分支' }]}
										>
											<Select
												style={{ width: 160 }}
												placeholder="系统分支"
												options={appDs}
											/>
										</Form.Item>
										<Form.Item
											{...field}
											name={[field.name, 'creditFlag']}
											fieldKey={[field.fieldKey as any, 'creditFlag']}
											rules={[{ required: true, message: '请选择授信类型' }]}
										>
											<Select
												style={{ width: 160 }}
												placeholder="授信类型"
												options={creditFlagDs()}
												onChange={(value, option) => { option && setFormFmsAccounts(index, "creditFlag", option as ISelectOption) }}
											/>
										</Form.Item>
										<Form.Item
											{...field}
											name={[field.name, 'accountTypeCode']}
											fieldKey={[field.fieldKey as any, 'accountTypeCode']}
											rules={[{ required: true, message: '请选择账户类型' }]}
										>
											<Select
												style={{ width: 160 }}
												placeholder="账户类型"
												options={(() => {
													let fromVal: IFMSInitAccountForm = form.getFieldsValue();
													if (!fromVal.fmsAccounts) return [];
													let newVal = fromVal.fmsAccounts[index]?.creditFlag ?? 1;  //记账1   现金 0
													// console.log(fromVal, index, newVal, accountTypeDs)
													return accountTypeDs.filter(item => newVal === 1 ? (item?.type === DictCodeEnmu.SETTLE_TYPE_CREDIT) : newVal === 0 ? (item?.type === DictCodeEnmu.SETTLE_TYPE_CASH) : (item?.type === DictCodeEnmu.SETTLE_TYPE_BALANCE));

													//在选择授信账户为记账时， 只展示 授信及月结，  否则返回其它
													// return accountTypeDs.filter(item => newVal ?
													// 	(item.value === AccountTypeEnum.CREDIT || item.value === AccountTypeEnum.CREDIT_MONTH || item.value === AccountTypeEnum.REBATE || item.value === AccountTypeEnum.REBATE_OIL) :
													// 	(item.value !== AccountTypeEnum.CREDIT && item.value !== AccountTypeEnum.CREDIT_MONTH && item.value !== AccountTypeEnum.REBATE && item.value !== AccountTypeEnum.REBATE_OIL));
												})()}
												onChange={(value, option) => { option && setFormFmsAccounts(index, "accountTypeName", option as ISelectOption) }}
											/>
										</Form.Item>
										<Form.Item
											{...field}
											name={[field.name, 'beCode']}
											fieldKey={[field.fieldKey as any, 'beCode']}
											rules={[{ required: true, message: '请选择账户实体' }]}
										>
											<Select
												style={{ width: 160 }}
												placeholder="账户实体"
												options={beShopDs}
												onChange={(value, option) => { option && setFormFmsAccounts(index, "beName", option as ISelectOption) }}
											/>
										</Form.Item>
										<Form.Item
											{...field}
											name={[field.name, 'shopCode']}
											fieldKey={[field.fieldKey as any, 'shopCode']}
											rules={[{ required: true, message: '请选择从属门店' }]}
										>
											<Select
												style={{ width: 160 }}
												placeholder="从属门店"
												options={[{ label: shopName, value: shopCode }]}
												onChange={(value, option) => { option && setFormFmsAccounts(index, "shopName", option as ISelectOption) }}
											/>
											{/* <TreeSelect
												style={{ width: 160 }}
												placeholder="组织机构"
												treeData={orgDs}
												onChange={(value, option) => { option && setFormFmsAccounts(index, "orgName", { label: option[0] as string, value: "" }) }}
											/> */}
										</Form.Item>

										{fields.length > 1 ? (
											<MinusCircleOutlined
												className="dynamic-delete-button"
												onClick={() => remove(field.name)}
											/>
										) : null}
									</Space>
								))}
								<Form.Item>
									<Button type="dashed" onClick={() => add({ creditFlag: CreditFlagEnum.CREDIT })} block icon={<PlusOutlined />}>增加</Button>
								</Form.Item>
							</>
						)
					}}
				</Form.List>
			</Form>
			<Table
				columns={columns}
				dataSource={selectedRowKeys}
				pagination={false}
			/>
		</YHDrawer>
	)
}
