import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {
    IPreSaleSearch,
    IPreSaleDetail,
    IPreSaleDList,
    IPreSaleFields,
    IPreSaleDetailed,
    IStockSearch, IStockList,
    //销售信息
    ISaleFields,
    ISaleStatistics,
    ISaleList,
    ISaleDetailed,
    ISaleSearch,
    ISaleDetail,
    ISaleSettleParams,
    IDeploymentFields,
    IDeploymentStatistics,
    IDeploymentList,
    IDeploymentSearch,
    IDeploymentDetail,
    //调配模块
    //销退信息，
    ISaleReturnFields,
    ISaleReturnList,
    ISaleReturnDetailed,
    ISaleReturnSettleDetail,
    ISaleReturnettleParams,
    ISaleReturnSearch,
    ISaleReturnDetail,
    ISaleDetailsList,
    ISaleDetailSearch,
    ISaleDetailDetail,
    //预退信息
    IPreWithdrawalDetailList,
    IPreWithdrawalFields,
    IPreSaleDetailsFields,
    IPreSaleDetailSearch,
    IPreSaleDetailDetail,
    IPreWithdrawalSearch,
    IPreWithdrawalDetail,
    IPreWithdrawalStatistics,
    IPreWithdrawalList,
    //小库存列表
    IBusBusinessSearch,
    IBusBusinessList,
    IBatchWarehousing,
    IPreSaleBatchWarehousing,
    ISaleBatchSettleParams

} from "./busBusinessType"
interface ISaleBatchSettle {
    details: ISaleBatchSettleParams[],
    shopId: string
}
class BusBusinesstService {
    //预售管理
    async preSalePage(searchVo: IPreSaleSearch) {
        const apiUrl = "/bcm/presaleorder/findPage";
        return await httpRequest.post<IPreSaleDList>(apiUrl, searchVo);
    }
    async preSaleOne(id: string) {
        const apiUrl = `/bcm/presaleorder/findById/${id}`;
        return await httpRequest.get<IPreSaleDetail>(apiUrl);
    }
    async preSaleInsert(insertVo: IPreSaleFields) {
        const apiUrl = `/bcm/presaleorder/insert`;
        return await httpRequest.post<IPreSaleFields>(apiUrl, insertVo);
    }
    async preSaleUpdate(updateVo: IPreSaleFields) {
        const apiUrl = `/bcm/presaleorder/update`;
        return await httpRequest.post<IPreSaleFields>(apiUrl, updateVo);
    }
    //列表下载模板
    async preSaleListDownloadTpl() {
        const apiUrl = `/bcm/presaleorder/listPage/downloadTpl`;
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    // 下载模板
    async preSaleDownloadTpl() {
        const apiUrl = `/bcm/presaleorder/detail/downloadTpl`;
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //明细删除
    async preSaleMaterialDelete(params: IPreSaleDetailed) {
        const apiUrl = `/bcm/presaleorder/materialDelete`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //作废
    async preSaleInvalidate(params: IPreSaleDetailed) {
        const apiUrl = `/bcm/presaleorder/invalidate`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //库房确认
    async preSaleWarehousing(params: IPreSaleDetailed) {
        const apiUrl = `/bcm/presaleorder/warehousing`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //列表导出
    async preSaleExport(params: IPreSaleFields) {
        const apiUrl = `/bcm/presaleorder/export`;
        return await httpRequest.postFile<boolean>(apiUrl, params);
    }
    //详情导出
    async preSaleDetailsExport(params: IPreSaleFields) {
        const apiUrl = `/bcm/presaleorder/detailsExport`;
        return await httpRequest.postFile<boolean>(apiUrl, params);
    }
    //转销售单
    async preSaleTransfer(params: IPreSaleFields) {
        const apiUrl = `/bcm/presaleorder/transfer`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }

    //库存信息
    async stockPage(searchVo: IStockSearch) {
        const apiUrl = "/bcm/presaleorder/stock/details";
        return await httpRequest.post<IStockList>(apiUrl, searchVo);
    }

    //预退新增查询
    async stockPageDetails(searchVo: IStockSearch) {
        const apiUrl = "/bcm/temporary/details/findPage";
        return await httpRequest.post<IStockList>(apiUrl, searchVo);
    }
    async stockPages(searchVo: IStockSearch) {
        const apiUrl = "bcm/temporary/findPages";
        return await httpRequest.post<IStockList>(apiUrl, searchVo);
    }
    //库存信息（新） 2022/7/9
    async stockPagePresaleorder(searchVo: IStockSearch) {
        const apiUrl = "/bcm/presaleorder/stock/details";
        return await httpRequest.post<IStockList>(apiUrl, searchVo);
    }
    //库存信息导出
    async stockExport(searchVo: IStockSearch) {
        const apiUrl = "/bcm/temporary/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo);
    }
    //销售管理
    async salePage(searchVo: ISaleSearch) {
        const apiUrl = "/bcm/transit/sale/findPage";
        return await httpRequest.post<ISaleList>(apiUrl, searchVo);
    }
    async saleOne(id: string) {
        const apiUrl = `/bcm/transit/sale/findById/${id}`;
        return await httpRequest.get<ISaleDetail>(apiUrl);
    }
    async saleInsert(insertVo: ISaleFields) {
        const apiUrl = `/bcm/transit/sale/insert`;
        return await httpRequest.post<ISaleFields>(apiUrl, insertVo);
    }
    async saleUpdate(updateVo: ISaleFields) {
        const apiUrl = `/bcm/transit/sale/update`;
        return await httpRequest.post<ISaleFields>(apiUrl, updateVo);
    }
    //作废
    async saleInvalidate(params: ISaleDetailed) {
        const apiUrl = `/bcm/transit/sale/invalidate`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //列表下载模板
    async saleListDownloadTpl() {
        const apiUrl = `/bcm/transit/sale/listPage/downloadTpl`;
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    // 下载模板
    async saleDownloadTpl() {
        const apiUrl = `/bcm/transit/sale/detail/downloadTpl`;
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //明细删除
    async saleMaterialDelete(params: ISaleDetailed) {
        const apiUrl = `/bcm/transit/sale/materialDelete`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //列表导出
    async saleExport(params: ISaleFields) {
        const apiUrl = `/bcm/transit/sale/export`;
        return await httpRequest.postFile<boolean>(apiUrl, params);
    }
    //详情导出
    async saleDetailsExport(params: ISaleFields) {
        const apiUrl = `/bcm/transit/sale/detailsExport`;
        return await httpRequest.postFile<boolean>(apiUrl, params);
    }
    //库房确认
    async saleWarehousing(params: ISaleDetailed) {
        const apiUrl = `/bcm/transit/sale/warehousing`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //结算
    async saleSettle(params: ISaleSettleParams) {
        const apiUrl = `/bcm/transit/sale/settle`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //销售单批量结算
    async saleBatchSettle(params: ISaleBatchSettle) {
        const apiUrl = `/bcm/transit/sale/batchSettle`;
        return await httpRequest.post<boolean>(apiUrl, params, { timeout: 180000 });
    }
    //转销退
    async saleTransfer(saleParams: IPreWithdrawalFields) {
        const apiUrl = `/bcm/transit/sale/transfer`;
        return await httpRequest.post<boolean>(apiUrl, saleParams);
    }
    //调配模块
    async deploymentPage(searchVo: IDeploymentSearch) {
        const apiUrl = "/bcm/deployment/findPage";
        return await httpRequest.post<IDeploymentList>(apiUrl, searchVo);
    }
    async deploymentOne(id: string) {
        const apiUrl = `/bcm/deployment/findById/${id}`;
        return await httpRequest.get<IDeploymentDetail>(apiUrl);
    }
    async deploymentInsert(insertVo: IDeploymentFields) {
        const apiUrl = `/bcm/deployment/insert`;
        return await httpRequest.post<IDeploymentFields>(apiUrl, insertVo);
    }
    async deploymentExport(insertVo: IDeploymentFields) {
        const apiUrl = `/bcm/deployment/export`;
        return await httpRequest.postFile<boolean>(apiUrl, insertVo);
    }
    async deploymentDetailExport(insertVo: IDeploymentFields) {
        const apiUrl = `/bcm/deployment/detailsExport`;
        return await httpRequest.postFile<boolean>(apiUrl, insertVo);
    }
    //列表下载模板
    async deploymentListDownloadTpl() {
        const apiUrl = `/bcm/deployment/listPage/downloadTpl`;
        return await httpRequest.getFile<boolean>(apiUrl);
    }

    //销退管理
    async sreturnPage(searchVo: ISaleReturnSearch) {
        const apiUrl = "/bcm/transit/sreturn/findPage";
        return await httpRequest.post<ISaleReturnList>(apiUrl, searchVo);
    }
    async sreturnOne(id: string) {
        const apiUrl = `/bcm/transit/sreturn/findById/${id}`;
        return await httpRequest.get<ISaleReturnDetail>(apiUrl);
    }
    async sreturnInsert(insertVo: ISaleReturnFields) {
        const apiUrl = `/bcm/transit/sreturn/insert`;
        return await httpRequest.post<ISaleReturnFields>(apiUrl, insertVo);
    }
    async sreturnUpdate(updateVo: ISaleReturnFields) {
        const apiUrl = `/bcm/transit/sreturn/update`;
        return await httpRequest.post<ISaleReturnFields>(apiUrl, updateVo);
    }

    //作废
    async sreturnInvalidate(params: ISaleReturnDetailed) {
        const apiUrl = `/bcm/transit/sreturn/invalidate`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //销售退货详情查询
    async saleDetailsPage(searchVo: ISaleDetailSearch) {
        const apiUrl = `/bcm/transit/sale/details/findPage`;
        return await httpRequest.post<IPage<ISaleDetailDetail>>(apiUrl, searchVo);
    }
    //销售退货删除配件
    async sreturnMaterialDelete(searchVo: ISaleReturnDetailed) {
        const apiUrl = `/bcm/transit/sreturn/materialDelete`;
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    //销售退货结算
    async sreturnSettle(searchVo: ISaleReturnettleParams) {
        const apiUrl = `/bcm/transit/sreturn/settle`;
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    //销售退货库房确认
    async sreturnWarehousing(searchVo: ISaleReturnDetailed) {
        const apiUrl = `/bcm/transit/sreturn/warehousing`;
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async sreturnExport(searchVo: ISaleReturnSearch) {
        const apiUrl = "/bcm/transit/sreturn/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo);
    }
    async sreturnDetailExport(searchVo: ISaleReturnSearch) {
        const apiUrl = "/bcm/transit/sreturn/detailsExport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo);
    }


    //预退管理
    async preturnPage(searchVo: IPreWithdrawalSearch) {
        const apiUrl = "/bcm/transit/preturn/findPage";
        return await httpRequest.post<IPreWithdrawalList>(apiUrl, searchVo);
    }
    async preturnOne(id: string) {
        const apiUrl = `/bcm/transit/preturn/findById/${id}`;
        return await httpRequest.get<IPreWithdrawalDetail>(apiUrl);
    }
    async preturnInsert(insertVo: IPreWithdrawalFields) {
        const apiUrl = `/bcm/transit/preturn/insert`;
        return await httpRequest.post<IPreWithdrawalFields>(apiUrl, insertVo);
    }
    //列表导出
    async preturnExport(params: IPreSaleFields) {
        const apiUrl = `/bcm/transit/preturn/export`;
        return await httpRequest.postFile<boolean>(apiUrl, params);
    }
    //列表导出
    async preturnDetailExport(params: IPreSaleFields) {
        const apiUrl = `/bcm/transit/preturn/detailsExport`;
        return await httpRequest.postFile<boolean>(apiUrl, params);
    }
    //列表下载模板
    async preturnListDownloadTpl() {
        const apiUrl = `/bcm/transit/preturn/listPage/downloadTpl`;
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //预售退货详情查询
    async presaleDetailsPage(searchVo: IPreSaleDetailSearch) {
        const apiUrl = `/bcm/presaleorder/details/findPage`;
        return await httpRequest.post<IPage<IPreSaleDetailDetail>>(apiUrl, searchVo);
    }
    async warehousePage(searchVo: IBusBusinessSearch) {
        const apiUrl = "/bcm/base/warehouse/page";
        return await httpRequest.post<IBusBusinessList>(apiUrl, searchVo);
    }
    async warehouseExport(searchVo: IBusBusinessSearch) {
        const apiUrl = "/bcm/base/warehouse/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo);
    }
    // 详情导出
    async warehouseDetailExport(searchVo: IBusBusinessSearch) {
        const apiUrl = "/bcm/base/warehouse/detailsexport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo);
    }
    //公交销售批量库房确认
    async saleBatchWarehousing(params: IBatchWarehousing) {
        const apiUrl = "/bcm/transit/sale/batchWarehousing";
        return await httpRequest.post<any>(apiUrl, params);
    }
    //预售单批量库房确认
    async preSaleBatchWarehousing(params: IPreSaleBatchWarehousing) {
        const apiUrl = "/bcm/presaleorder/batchWarehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }

}
export const busBusinesstService = new BusBusinesstService()