import React, { useEffect } from "react";
import { Button, Dropdown, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useFactoryModel } from "../factoryModel";
import { DownOutlined } from "@ant-design/icons";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const DataBox = () => {
    const { selectedRowKeys, descTotal, selectedRow, setPrint, exportMaintenance,
        setScaniaPrint, setSelectedRowKeys,
        setSettleScaniaPrint } = useFactoryModel();
    const { shopAndOrg: { shopCode } } = useGlobalModel();

    const { selectedNavId, replaceNav, addNav, findNav, selectNav, switchNavState } = useNavModel();
    const desc: IDescription[] = [
        {
            label: "结算金额",
            value: descTotal.totalAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "工时费",
            value: descTotal.workingHours,
            color: DescriptionColorEnum.red
        },
        {
            label: "零件费",
            value: descTotal.partsCost,
            color: DescriptionColorEnum.red
        },
    ]
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.settle") {
            globalPrompt("message", { text: "该单据已结算，无法进行编辑", type: "warning" });
            return;
        }
        let saleOrderEditKey = "7-3-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "厂家结算跳转详情",
                type: "warning",
                text: "您还有厂家结算详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
                    // updateEditFormVal(null);
                }
            })
        }
    }

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "7-3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.maintenanceCode as string, mode: "look", },
            true
        );
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const onSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setScaniaPrint(true)
    }
    const onSettleSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setSettleScaniaPrint(true)
    }
    const PrintMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={onSknyPrint}>工单打印</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={onSettleSknyPrint}>结算打印</span>
                </Menu.Item>
            </Menu>
        )
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.BILL_M_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >结算</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.BILL_M_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.BILL_M_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportMaintenance().then()}
            >导出</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.BILL_M_PRINT}>
            {shopCode === "SHOP-M-00000018" || shopCode === "SHOP-M-00000017" ?
                <Dropdown overlay={PrintMenu}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                    >打印<DownOutlined /></Button>
                </Dropdown> :
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onPrint}
                >打印</Button>
            }
        </YhAuth>
    </>;

    return (
        <YhBox title="厂家结算" descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}