import { httpRequest } from '@/utils/HttpRequest';
import { IPage } from '@/types/ScmTypes';
import {
	IInternalDetail,
	IInternalSearch,
	IAuditParams,
	IWriteoffDetail,
	IWriteoffParams,
	IFmsShopUnitDetail,
	IFmsAllotSearch,
	IFmsAllotFileds,
	IFmsAllotDetailSearch,
	IFmsAllotDetailFileds,
} from './internalTypes';
class InternalService {
	//核销记录
	async page(searchVo: IInternalSearch) {
		const apiUrl = '/fms/allotAccount/writeoff/page';
		return await httpRequest.post<IPage<IInternalDetail>>(apiUrl, searchVo);
	}
	async one(id: string) {
		const apiUrl = `/fms/allotAccount/writeoff/one/${id}`;
		return await httpRequest.post<IInternalDetail>(apiUrl);
	}
	//保存
	async writeoff(searchVo: IWriteoffParams) {
		const apiUrl = '/fms/allotAccount/writeoff';
		return await httpRequest.post<IWriteoffDetail>(apiUrl, searchVo);
	}
	//审核
	async audit(searchVo: IAuditParams) {
		const apiUrl = '/fms/allotAccount/writeoff/audit';
		return await httpRequest.post<boolean>(apiUrl, searchVo);
	}
	//查询门店结算单位的 下属关联
	async queryShopUnitBe(shopId: string) {
		const apiUrl = `/fms/accountUnit/find/one/${shopId}`;
		return await httpRequest.post<IFmsShopUnitDetail>(apiUrl);
	}
	//调拨信息查询
	async allot(searchVo: IFmsAllotSearch) {
		const apiUrl = `/fms/allot/listPage`;
		return await httpRequest.post<IPage<IFmsAllotFileds>>(apiUrl, searchVo);
	}
	//调拨详情查询
	async detailPage(searchVo: IFmsAllotDetailSearch) {
		const apiUrl = `/fms/allot/detailPage`;
		return await httpRequest.post<IPage<IFmsAllotDetailFileds>>(apiUrl, searchVo);
	}

	async writeoffPage(params: object) {
		const apiUrl = '/fms/allotAccount/writeoff/page';
		return await httpRequest.post<boolean>(apiUrl, params);
	}
}
export const internalService = new InternalService();
