import React, { useEffect } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { IPreWithdrawalDetail } from "../../../busBusinessType"
import { usePreWithdrawalModel } from "./preWithdrawalModel"
import { Tooltip } from "antd";
import { momentFormat } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
export const PreWithdrawalDataTable = () => {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows
    } = usePreWithdrawalModel();
    const { getTreeNodeName } = useDictModel();
    useEffect(() => {
        loadData()
    }, [])
    const columns: ColumnsType<IPreWithdrawalDetail> = [
        {
            align: "center",
            title: '序号',
            width: 60,
            render: (t, r, i) => i + 1,
            fixed: 'left',
        },
        {
            align: "center",
            title: '预退单号',
            width: 120,
            dataIndex: 'returnCode',
            fixed: 'left',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '单据状态',
            width: 120,
            dataIndex: 'status',
            fixed: 'left',
            render: (text) => <YhTag color={"green"} text={getTreeNodeName(text)} />,
        },
        {
            align: "center",
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            fixed: 'left',
        },
        {
            align: "center",
            title: '联系人',
            width: 120,
            dataIndex: 'contact',
        },
        {
            align: "center",
            title: '预退数量',
            width: 120,
            dataIndex: 'presaleNum',
        },
        {
            align: "center",
            title: '退货金额',
            width: 120,
            dataIndex: 'presaleAmount',
            render:(text)=>`￥${text}`,
        },
        {
            align: "center",
            title: '退货成本',
            width: 120,
            dataIndex: 'totalCost',
            render:(text)=>`￥${text}`,
        },
        {
            align: "center",
            title: '制单员',
            width: 120,
            dataIndex: 'createUser',
        },
        {
            align: "center",
            title: '制单时间',
            width: 120,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        },
        {
            align: "center",
            title: '备注',
            width: 140,
            dataIndex: 'note',
        },
    ]
    const onRow = (record: IPreWithdrawalDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    }
    const onRightClick = (record: IPreWithdrawalDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IPreWithdrawalDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows);
                },
                selectedRowKeys
            }}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />

    )
}