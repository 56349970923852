import React, { useRef } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils";
import { useWorkshopQueryModel } from "../workshopQueryModel";
import useGlobalModel, { transOptions } from "@/model/globalModel";

//结算
export function DispatchDrawer() {
    const { technicianData } = useGlobalModel()

    const { dispatchVisible: visible, detailType, selectedRow, loadData, dispatch, setDispatchVisible, setSelectedRowKeys } = useWorkshopQueryModel();
    const formRef = useRef<IYhFormRef>();

    const onCancel = () => {
        setDispatchVisible(false);
        loadData();
        setSelectedRowKeys([])
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(async res => {
            let { technicianCodes } = res
            let newRes = {
                ...res,
                id: selectedRow?.id,
                technicianCodes: res.technicianCodes.toString(),
                technicianNames: technicianData.filter((el: any) => !!technicianCodes.find((ele: any) => ele === el.code))?.map((item: any) => item.name).toString()
            }
            let retData = await dispatch(newRes)
            if (retData) {
                message.success("派工成功")
                onCancel()
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err))
        })
    }

    const onFinish = (val: any) => {

    }
    const formConfig: IYhForm<any> = {
        formRef,
        disabled: detailType === "look",
        onFinish,
        formValues: { ...selectedRow, technicianCodes: selectedRow?.technicianCodes ? selectedRow.technicianCodes.split(",") : [] },
        labelCol: { span: 6 },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工单单号",
                fieldName: "maintenanceCode",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车牌号",
                fieldName: "licensePlate",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "底盘号",
                fieldName: "vin",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "维修技师",
                fieldName: "technicianCodes",
                rules: [{ required: true, message: "请输入维修技师" }],
                allowClear: true,
                mode: "multiple",
                span: 24,
                options: transOptions(technicianData)
            },
        ]
    };
    return (
        <YHDrawer
            {...{
                title: '车间主任派工',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit,
                width: 600,
                showFooter: detailType !== "look",
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
