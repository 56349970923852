import { IExportRecordDetail, IExportRecordSearch } from './exportRecordTypes'
import { httpRequest } from '@/utils/HttpRequest'
import { IPage } from '@/types/ScmTypes'
import  {CLIENT_APP_CODE} from  '@/utils/constants'
class ExportRecordService {
    async page(params: IExportRecordSearch) {
        params.searchItem = {
            ...params.searchItem,
            // 默认参数
            appName: CLIENT_APP_CODE,
        }
        const apiUrl = '/yh-export/export/record/list'
        return await httpRequest.post<IPage<IExportRecordDetail>>(apiUrl, params)
    }
}
export const exportRecordService = new ExportRecordService()
