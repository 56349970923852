import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import React, { useState, useEffect } from "react";
import { PaginationProps } from "antd/es/pagination";

import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";
import { message } from "antd";

import { IStockBorrowDetail, IStockBorrowSearch } from "./stockOutAndInListType"
import { stockOutAndInListService } from "./stockOutAndInListService"
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
export interface IMdBeSearchField {
    code?: string;
    name?: string;
    corporation?: string;
    phone?: string;
    address?: string;
    bank?: string;
    bankAccount?: string;
}
export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

export const useStockOutAndInListModel = createModel(function () {
    const { shopAndOrg, } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IStockBorrowSearch = {};
    const initDataSource: IStockBorrowDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<IStockBorrowSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IStockBorrowDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRow, setSelectedRow] = useState<IStockBorrowDetail>({});

    //列表数据源分页参数

    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IStockBorrowDetail)
    }, [selectedRowKeys])

    const [drawerVisible, setDrawerVisible] = useState(false);

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IMdBeSearchField) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: IStockBorrowSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await stockOutAndInListService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        initPgCfg,
        drawerVisible,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        selectedRow, setSelectedRow
    }
});