import React, { FC } from "react";
import { Button, Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { useMaintenanceDetailsModel } from "./maintenanceDetailsModels";
import { RouterParamType } from "@/model/navModel";
import { DetailType } from "@/types/ScmEnums";
import { IItemDetailList } from "../../maintenanceType";
import { formatIntPoint, formatNum ,bigNumber} from "@/utils/utils";
import { components } from "@/components/YHEditTable/editTable";
import useVrmModel from "@/model/vrmModel";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const ItemTableBox: FC<RouterParamType> = ({ id, mode }) => {
    const { temporaryObj, setTemporaryid } = useVrmModel()
    const {
        setItemModel,
        detailItemAddList, setDetailItemAddList,
        detailItemAddDataRowKeys, setDetailItemAddDataRowKeys,
        setDetailItemAddDataRow,
        detailItemEditList, setDetailItemEditList,
        detailItemEditDataRowKeys, setDetailItemEditDataRowKeys,
        setDetailItemEditDataRow,
        detailItemLookList,
        detailItemDetailList,
        setTopButsControl,
    } = useMaintenanceDetailsModel();
    const onTemporary = () => {
        setTemporaryid(new Date().getTime().toString())
        mode === "add" ? setDetailItemAddList([...detailItemAddList, temporaryObj]) : setDetailItemEditList([...detailItemEditList, temporaryObj])
    }
    const title = <>
        <span>维修项目</span>
        <Tooltip placement="topLeft" title="新增临时项目">
            <FontIcon type="icon-linshibiaoge" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={() => onTemporary()}
            />
        </Tooltip>
    </>
    const action = <>
        {mode !== "look" && <>
            <YhAuth resCode={ResourceAuthEnum.M_ED_ADD_I}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { setItemModel(true) }}
                >新增项目</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.M_ED_DEL_I}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => detailItemDetailList(mode)}
            >删除项目</Button>
            </YhAuth>
        </>}
    </>;

    const itemColumn = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '项目编码',
            width: 120,
            dataIndex: 'itemCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '项目名称',
            width: 140,
            dataIndex: 'itemName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '工时',
            width: 100,
            dataIndex: 'workHour',
            editable: mode !== "look" ? true : false,
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            editable: mode !== "look" ? true : false,
        }, {
            title: '工时费',
            width: 100,
            dataIndex: 'itemAmount',
        }, {
            title: '折扣',
            width: 100,
            dataIndex: 'discountRate',
            editable: mode !== "look" ? true : false,
            render: (text: string) => text + "%"
        }, {
            title: '折后单价',
            width: 100,
            dataIndex: 'discountedPrice',            
        }, {
            title: '小计',
            width: 100,
            dataIndex: 'receivableAmount',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
            editable: mode !== "look" ? true : false,
        }
    ];
    const newSelectItemColums = () =>
        itemColumn.map((col: any, index) => {
            let disaBled = col.dataIndex === "itemCode" || col.dataIndex === "itemName"
            return {
                ...col,
                onCell: (record: IItemDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: disaBled ? record.temporary && mode !== "look" : col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    // allowClear: col.allowClear,
                    mode: col.mode,
                    handleSave: handleItemSave,
                }),
            }
        })
    const handleItemSave = (row: IItemDetailList) => {
        let { workHour, price, discountRate } = row
        workHour = +formatIntPoint(workHour); //工时
        price = +formatNum(price); //工时费
        discountRate = +formatIntPoint(discountRate, 100, '折扣不能高于100%')
        let newRow = {
            ...row,
            price,
            workHour,
            discountRate,
            itemAmount:+bigNumber.toFixed(bigNumber.times(workHour,price),2),
            receivableAmount: +bigNumber.toFixed(bigNumber.times(bigNumber.times(workHour,price),bigNumber.dividedBy(discountRate,100)),2),
            discountedPrice:+bigNumber.toFixed(bigNumber.dividedBy(bigNumber.times(price,discountRate),100),2)
            // itemAmount: +formatNum(workHour * price),
            // receivableAmount: +formatNum(workHour * price * discountRate / 100),
            // discountedPrice:+formatNum(price * discountRate / 100),
        }
        let newData = mode === "edit" ? detailItemEditList : detailItemAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailItemEditList([...newData]) : setDetailItemAddList([...newData])
        setTopButsControl(true)

    }
    //设置维修项目选中的行和数据
    const setTypeItemDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailItemAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailItemEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeItemDetailDataRow = async (selectedRows: IItemDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailItemAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailItemEditDataRow(selectedRows)
        }
    }
    const typeItemList = {
        "add": [...detailItemAddList],
        "edit": [...detailItemEditList],
        "look": [...detailItemLookList],
    }
    return (
        <YhBox title={title} rightChildren={action} style={{ marginBottom: "5px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailItemAddDataRowKeys : detailItemEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IItemDetailList[]) => {
                            setTypeItemDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeItemDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeItemDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectItemColums() as ColumnsType<IItemDetailList>}
                dataSource={typeItemList[mode] as IItemDetailList[]}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 250 }}
            />
        </YhBox>
    )
}