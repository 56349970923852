import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "../../../types/ScmTypes";
import { useState, Key, useEffect } from "react";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "../../../utils/constants";
import { IEntityDetail, IEntityFields } from "./entityType";
import { beService } from "./entityService";
import { message } from "antd";
import { transPgToPagination, formatNum } from "@/utils/utils";
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
export enum BeOptModeEnum {
    ADD,
    EDIT,
    DETAIL
}
export const useEntityModel = createModel(function () {
    const { user, shopAndOrg } = useGlobalModel()
    const { shopId } = shopAndOrg
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //列表数据
    const [dataSource, setDataSource] = useState<IEntityDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IEntityFields>({});
    //分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IEntityFields>({});

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IEntityFields)
    }, [selectedRowKeys])

    //设置搜索value
    const updateSearchParam = (updateParam: IEntityFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    //操作模式
    const [optMode, setOptMode] = useState<BeOptModeEnum>(BeOptModeEnum.ADD);
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //列表查询
    const loadData = async (page?: ISearchPage, searchValue: IEntityFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            // ...sourceAppOrBranch,
            "sourceApp": "vrm",
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }

        }
        let { retData } = await beService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));

        setDataSource(records);
    }
    const syncData = async () => {
        let ret = await beService.sync({
            ...searchParam,
            ...shopAndOrg,
            "sourceApp": "vrm",
            "branch": "main"
        });
        ret ? message.success("同步成功!重新加载数据...") : message.error("同步失败");
        ret && await loadData()
        // ret && await beLoadDataAll()
    }
    return {
        dataSource,
        searchParam,
        pgCfg,
        initPgCfg,
        drawerVisible,
        optMode,
        selectedRow,
        selectedRowKeys,
        setDataSource,
        setSearchParam,
        setPgCfg,
        loadData,
        updateSearchParam,
        resetSearchParam,
        setSelectedRowKeys,
        setDrawerVisible,
        setOptMode,
        setSelectedRow,
        syncData
    }
})