import React, { useEffect, useRef, useState, FC } from "react";
import { Button, message, Input } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { } from "@/model/navModel";
import { formatNum } from "@/utils/utils"
import { globalPrompt } from "@/components/message";
import { useStockBorrowDetailModel } from "./stockBorrowDetailsModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { vehicleService } from "@/views/base/customerFiles/vehicle/vehicleService";
import { IVehicleFields } from "@/views/base/customerFiles/vehicle/vehicleType";
import { ITopType } from "@/types/ScmTypes";
import { DetailType } from "@/types/ScmEnums";
import { debounce } from "lodash";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { YhAuth } from "@/components/auth/YhAuth";
//返回列表韦options的格式
export const vehicleOption = (list: any[]) => {
    return list.length != 0 ? list.map(item => ({ label: item.label, value: item.value })) : []
}
// 返回对应车辆牌照的车辆所有信息
export const getVehicleItem = (licensePlate: string, list: any[]) => {
    return list.find(item => item.licensePlate === licensePlate)
}
export const FormBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const { beShopDs, CustomerData, vehicleData } = useGlobalModel();

    const {
        detailEditForm,
        detailAddForm,
        vehicleEditAll,
        vehicleAddAll,
        initForm,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetAddCatch,
        resetEditCatch,
        insertDetail,
        editDetail,
        setDetailAddForm,
        setDetailAddList,
        setVehicleAddAll,
        setVehicleEditAll,
    } = useStockBorrowDetailModel();

    const { selectedNavId, replaceNav, findNav } = useNavModel();

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
    }, [id, mode])
    useEffect(() => {
        return () => {
            !findNav("5-1-1") && resetAddCatch(mode);
            !findNav("5-1-2") && resetEditCatch(mode);
        }
    }, [selectedNavId])

    const save = () => {
        // goBack()
        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const resData = await insertDetail(res)
                if (resData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    resetAddCatch(mode);
                    goBack();
                }
            } else if (mode === "edit") {
                let { id, borrowCode } = detailEditForm
                const resData = await editDetail({ ...res, id, borrowCode })
                if (resData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    getDetailEditOne(borrowCode as string, mode)
                }
            }
        })
    }
    const goBack = () => {
        replaceNav(selectedNavId, "9-4")
    }
    const onRefresh = async () => {
        if (mode === 'add') {
            IFormRef.current?.resetFields()
            getVehicleAllList()
            setDetailAddForm(initForm)
            setDetailAddList([])

        } else if (mode === 'edit') {
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const rightChildren = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.ST_M_BORROW_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { save() }, 900)}
                >保存</Button>
            </YhAuth>
        }
        {mode !== "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onRefresh}
            >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>

    useEffect(() => {
        getVehicleAllList()
    }, [mode])

    const getVehicleAllList = async (param?: IVehicleFields) => {
        let params = {
            ...param,
            pageSize: 1000,
            pageNum: 1
        }
        const { retData } = await vehicleService.page(params)
        const { records } = retData
        if (records.length === 0) { globalPrompt("message", { text: "该客户下没有车辆，点击左侧车辆牌照去新增吧!", type: "warning" }) }
        let newRecords = records.map(item => {
            return {
                ...item,
                label: item.licensePlate,
                value: item.licensePlate
            }
        })
        mode === "add" ? setVehicleAddAll(newRecords) : setVehicleEditAll(newRecords)
    }
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {

            option && onChange({
                customerName: option.label,
                customerCode: option.value,
            });
            option && getVehicleAllList({ customerName: option.label })
        }

    }
    //车辆牌照
    const vehicleChange = (value: string, option: ISelectOption) => {
        let VehicleIList = mode === "add" ? vehicleAddAll : vehicleEditAll
        if (value && option) {
            let { customerName, customerCode, licensePlate } = getVehicleItem(value, VehicleIList)
            option && onChange({
                customerName,
                customerCode,
                licensePlate,
            });
        }
    }
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    const goVehicle = () => {
        replaceNav(
            selectedNavId,
            "13-5-2-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "借件单号",
                fieldName: "borrowCode",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "借件人",
                fieldName: "borrowPerson",
                rules: [{ required: true, message: "请输入借件人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "借件日期",
                fieldName: "createTime",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库管",
                fieldName: "createUser",
                disable: true,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                options: transOptions(CustomerData),
                onSelectChange: customerChange,
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: "请选择客户名称" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goVehicle}>车辆牌照</span>,
                fieldName: "licensePlate",
                allowClear: true,
                showSearch: true,
                options: mode === "add" ? vehicleOption(vehicleAddAll) : vehicleOption(vehicleEditAll),
                onSelectChange: vehicleChange,
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            },
        ]
    };


    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: {}
    }
    const onChange = (changedValues: { [key: string]: any }) => {
        mode === "add" ? updateAddFormVal(changedValues) : updatEditFormVal(changedValues)
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        disabled: mode === DetailType.look,
        labelAlign: "right",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}