import React, { useState, useRef } from "react";
import { YhPageHeader } from "@/components/YhPageHeader";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { SettleDrawer } from "./SettleDrawer";
import { WhConfirmDrawer } from "./WhConfirmDrawer";
import { AddGoodsModal } from "./AddGoodsModal";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { HistoryModel } from "@/views/historyModel";
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailsModel";
import { SupplierHistoryModel } from "@/views/commonModule/supplierHistoryModel";

export const PurchaseRoDetails = () => {
    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { mode, id } = detailsInfo
    const IFormRef = useRef<IYhFormRef>();
    const {
        historyVisible, setHistoryVisible,
        historyRow,
        supplierHistoryModel,
        supplierHistoryVisible, setSupplierHistoryVisible,
    } = usePurchaseRoDetailsModel();
    return (
        <>
            {/* <YhPageHeader title="采购退货单新增" /> */}
            <TopButs {...detailsInfo} IFormRef={IFormRef} />
            <FormBox {...detailsInfo} IFormRef={IFormRef} />
            <TableBox {...detailsInfo} IFormRef={IFormRef} />
            <FixedBottom {...detailsInfo} />
            <SettleDrawer {...detailsInfo} />
            <WhConfirmDrawer {...detailsInfo} />
            <AddGoodsModal {...detailsInfo} />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <SupplierHistoryModel
                visible={supplierHistoryVisible}
                name={supplierHistoryModel.supplierName}
                onCancel={() => setSupplierHistoryVisible(false)}
            />
        </>
    )
}