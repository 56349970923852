import React, { useEffect } from "react";
import { Table, Switch } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAcctUnitModel } from "../acctUnitModel";
import { ISCMAcctUnitDetail } from "../acctUnitService";
import useGlobalModel from "@/model/globalModel";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys, disableAcctUnit
    } = useAcctUnitModel();
    const { mdmBeDs, mdmShopDs, shopDs, beDs } = useGlobalModel();

    const columns: ColumnsType<ISCMAcctUnitDetail> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '核算单位编码',
            width: 200,
            dataIndex: 'code',
            fixed: "left",
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '内部核算单位',
            width: 200,
            dataIndex: 'name',
            fixed: "left",
           // render: text => <p className="ellipsis" title={text}>{text}</p>
           render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '联系人',
            width: 140,
            dataIndex: 'contact',
        }, {
            title: '联系电话',
            width: 140,
            dataIndex: 'phone',
        }, {
            title: '公司地址',
            width: 160,
            dataIndex: 'address',
            render: text => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '客户经理',
            width: 160,
            dataIndex: 'staffCode',
        }, {
            title: '办公电话',
            width: 160,
            dataIndex: 'tel',
        },
        {
            title: '关联门店',
            width: 160,
            dataIndex: 'shop',
            render: text => text ? mdmShopDs.find(item => item.id === text)?.label : ""
        }, {
            title: '关联实体',
            width: 160,
            dataIndex: 'bentity',
            render: text => {
                const beArr = text.split(',')?.map((item: string) => mdmBeDs.find(beItem => item === beItem.id)?.label ?? "");
                const str = beArr.join(',')
                return <div title={str}>{str}</div>;
            }
        },
        // {
        //     title: '关联门店',
        //     width: 160,
        //     dataIndex: 'shop',
        //     render: text => text ? shopDs.find((item: any) => item.value === text)?.label : ""
        // }, {
        //     title: '关联实体',
        //     width: 160,
        //     dataIndex: 'bentity',
        //     render: text => {
        //         let str: string = "";
        //         text && text.split(',')?.forEach((item: string) => {
        //             str += beDs.find((beItem: any) => item === beItem.id)?.label ?? ""
        //         });
        //         return <p className="ellipsis" title={str}>{str}</p>;
        //     }
        // },
        {
            title: '创建人',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '创建日期',
            width: 180,
            dataIndex: 'createTime',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            render: text => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text, record) => (
                <Switch
                    checkedChildren={"开启"}
                    unCheckedChildren={"关闭"}
                    checked={+record.status ? true : false}
                    onChange={(checked: boolean, e: any) => {
                        e.stopPropagation();
                        disableAcctUnit(record.code, checked ? 1 : 0)
                    }}
                />
            ),
        }
    ];

    useEffect(() => {
        loadData()
    }, []);

    //点击行选高亮
    const onRow = (record: ISCMAcctUnitDetail) => {
        let findObj = selectedRowKeys.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.id !== record.id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };

    return (
        <Table
            loading={loading}
            rowKey={record => record.id}
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys((origin) => {
                            let changeRowId = changeRows.map(item => item.id);
                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.id)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record]);
                }
            })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}
