import React, { useEffect, useState } from "react";
import { Table, Button, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useDictModel } from "../base/dict/dictModel";
import { IItemAndMaterialSearch, commonModuleService, IItemField, IMaterialField } from "./commonModuleService"
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import useGlobalModel from "@/model/globalModel";
import { IMaintenanceDetail } from "../maintenance/maintenanceType";
import { maintenanceService } from "../maintenance/maintenanceService";
import { transPgToPagination, momentFormat } from "@/utils/utils";
interface IRepairHistoryType {
    visible: boolean,
    licensePlate?: string,
    onCancel: () => void
}
enum IRepairHistoryTab {
    Bill = "danju",
    Item = "item",
    Material = "material"
}
export const RepairHistoryModel = ({
    visible,
    licensePlate = "",
    onCancel,
}: IRepairHistoryType) => {
    const { shopAndOrg } = useGlobalModel();
    const { getTreeNodeName } = useDictModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const totalPgCfg = { pageSize: -1, pageNum: 1 }
    const [searchParam, setSearchParam] = useState<IItemAndMaterialSearch>({})
    const [dataSource, setDataSource] = useState<IMaintenanceDetail[]>([]);
    const [tabFlag, setTabFlag] = useState<IRepairHistoryTab>(IRepairHistoryTab.Bill)
    const [itemSource, setItemSource] = useState<IItemField[]>([]);
    const [materialSource, setMaterialSource] = useState<IMaterialField[]>([]);
    useEffect(() => {
        if (visible) {
            loadBillData(initPgCfg, { licensePlate })
            setSearchParam({ licensePlate })
        }
    }, [visible])
    useEffect(() => {
        return () => {
            setTabFlag(IRepairHistoryTab.Bill)
        }
    }, [visible])
    const loadBillData = async (page?: ISearchPage, searchValue: IItemAndMaterialSearch = searchParam) => {
        let searchParams = {
            ...searchValue,
            ...shopAndOrg,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await maintenanceService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records);
    }
    const onBill = () => {
        setTabFlag(IRepairHistoryTab.Bill)
        loadBillData(initPgCfg, { licensePlate })
    }
    const onItem = async () => {
        setTabFlag(IRepairHistoryTab.Item)
        let searchParams = {
            licensePlate,
            ...shopAndOrg,
            ...totalPgCfg,
        }
        let { retData } = await commonModuleService.recordItem(searchParams);
        setItemSource(retData);
    }
    const onMaterial = async () => {
        setTabFlag(IRepairHistoryTab.Material)
        let searchParams = {
            licensePlate,
            ...shopAndOrg,
            ...totalPgCfg,
        }
        let { retData } = await commonModuleService.recordMater(searchParams);
        setMaterialSource(retData)
    }
    const columns: ColumnsType<IMaintenanceDetail> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'maintenanceCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '维修类型',
            width: 120,
            dataIndex: 'serviceNatureName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '单据日期',
            width: 140,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '单据状态',
            width: 120,
            dataIndex: 'status',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '结算类型',
            width: 120,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '结算方式',
            width: 120,
            dataIndex: 'paymentMethodName',
        }, {
            title: '结算日期',
            width: 140,
            dataIndex: 'settleTime',
            render: (text) => momentFormat(text)
        }, {
            title: '核销日期',
            width: 140,
            dataIndex: 'writeOffTime',
            render: (text) => momentFormat(text)
        }, {
            title: '费用合计',
            width: 120,
            dataIndex: 'totalAmount',
        }, {
            title: '应收金额',
            width: 120,
            dataIndex: 'receivableTotalAmount',
        }, {
            title: '已收金额',
            width: 120,
            dataIndex: 'receivableAmount',
        }, {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
        }, {
            title: '送修人',
            width: 120,
            dataIndex: 'contact',
        }, {
            title: '送车电话',
            width: 120,
            dataIndex: 'phone',
        }, {
            title: '车牌号',
            width: 120,
            dataIndex: 'licensePlate',
        }
    ]
    const itemColUmns: ColumnsType<IItemField> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'maintenanceCode',
        }, {
            title: '维修类型',
            width: 120,
            dataIndex: 'serviceTypeName',
        }, {
            title: '项目编码',
            width: 160,
            dataIndex: 'itemCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '项目名称',
            width: 160,
            dataIndex: 'itemName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '工时',
            width: 120,
            dataIndex: 'workHour',
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
        }, {
            title: '工时费',
            width: 120,
            dataIndex: 'itemAmount',
        }, {
            title: '折扣',
            width: 120,
            dataIndex: 'discountRate',
            render: (text) => text + "%"
        }, {
            title: '合计',
            width: 120,
            dataIndex: 'receivableAmount',
        }, {
            title: '客户名称',
            width: 160,
            dataIndex: 'customerName',
        }, {
            title: '送修人',
            width: 160,
            dataIndex: 'contact',
        }, {
            title: '送车电话',
            width: 120,
            dataIndex: 'phone',
        }, {
            title: '车牌号',
            width: 120,
            dataIndex: 'licensePlate',
        }
    ]
    const materialolUmns: ColumnsType<IMaterialField> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'maintenanceCode',
        }, {
            title: '维修类型',
            width: 120,
            dataIndex: 'serviceTypeName',
        }, {
            title: '配件编码',
            width: 160,
            dataIndex: 'materialCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
        }, {
            title: '数量',
            width: 120,
            dataIndex: 'num',
        }, {
            title: '小计',
            width: 120,
            dataIndex: 'materialAmount',
        }, {
            title: '折扣',
            width: 120,
            dataIndex: 'discountRate',
            render: (text) => text + "%"
        }, {
            title: '费用合计',
            width: 120,
            dataIndex: 'receivableAmount',
        }, {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
        }, {
            title: '送修人',
            width: 160,
            dataIndex: 'contact',
        }, {
            title: '送车电话',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '车牌号',
            width: 120,
            dataIndex: 'licensePlate',
        }
    ]

    const title = <>
        <Button
            className={`mr-5 ${tabFlag === IRepairHistoryTab.Bill ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={onBill}
        >单据明细</Button >
        <Button
            className={`mr-5 ${tabFlag === IRepairHistoryTab.Item ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={onItem}
        >项目明细</Button >
        <Button
            className={`mr-5 ${tabFlag === IRepairHistoryTab.Material ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={onMaterial}
        >配件明细</Button >
    </>
    const ItemTable = <Table
        style={{ cursor: "pointer" }}
        columns={itemColUmns}
        dataSource={itemSource}
        pagination={false}
        scroll={{ x: 'max-content', y: 650 }}
    />
    const MaterialTable = <Table
        style={{ cursor: "pointer" }}
        columns={materialolUmns}
        dataSource={materialSource}
        pagination={false}
        scroll={{ x: 'max-content', y: 650 }}
    />
    const billTable = <Table
        style={{ cursor: "pointer" }}
        columns={columns}
        dataSource={dataSource}
        pagination={{
            ...pgCfg,
            position: ["bottomCenter"],
            showTotal: () => `共${pgCfg.total}条`,
            onChange: (pageNum, pageSize) => {
                loadBillData({ pageNum, pageSize }).then();
            }
        }}
        scroll={{ x: 'max-content', y: 650 }}
    />
    const RepairTabsItem = (str: IRepairHistoryTab) => {
        switch (str) {
            case IRepairHistoryTab.Bill:
                return billTable;
            case IRepairHistoryTab.Item:
                return ItemTable;
            case IRepairHistoryTab.Material:
                return MaterialTable;
            default:
                return <></>;
        }
    }
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title="维修历史"
        width={1000}
        bodyHeight={700}
        showFooter={false}
    >
        <YhBox title={title}>
            {RepairTabsItem(tabFlag)}
            {/* <Table
                style={{ cursor: "pointer" }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                    onChange: (pageNum, pageSize) => {
                        loadBillData({ pageNum, pageSize }).then();
                    }
                }}
                scroll={{ x: 'max-content', y: 520 }}
            >
            </Table> */}
        </YhBox>

    </YHModal>
}