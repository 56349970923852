import { httpRequest } from "../../../utils/HttpRequest";
import {
    ISummaryCustomerSearch,
    ISummaryCustomerList,
} from "./customerType"

class SettlementCustomerService {
    async page(searchVo: ISummaryCustomerSearch) {
        const apiUrl = "/bcm/customer/maintenance/settlePage";
        return await httpRequest.post<ISummaryCustomerList>(apiUrl, searchVo);
    }
    //导出
    async export(params: ISummaryCustomerSearch) {
        const apiUrl = "/bcm/maintenance/settleExport";
        return await httpRequest.postFile(apiUrl, params);
    }
}
export const settlementCustomerService = new SettlementCustomerService();
