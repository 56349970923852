import React, { FC } from "react";
import { RouterParamType } from "@/model/navModel";
import { YhBox } from "@/components/YhBox";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useOutputDetailsModel } from "./stockOutputModelDetails";
export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const { detailLookList } = useOutputDetailsModel()
    const colums: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
        }, {
            title: '所属库房',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '所属货位',
            width: 160,
            dataIndex: 'shelfCode',
        }, {
            title: '数量',
            width: 160,
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
        }, {
            title: '折扣',
            width: 120,
            dataIndex: 'discountRate',
        }, {
            title: '总计',
            width: 120,
            dataIndex: 'amount',
        }
    ]
    return (
        <YhBox title="商品信息" >
            <Table
                columns={colums}
                dataSource={detailLookList}
                pagination={false}
                scroll={{ x: 'max-content', y: 400 }}
            >
            </Table>
        </YhBox>
    )
}