import { useState, Key, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IVehicleDetail } from "@/views/base/customerFiles/vehicle/vehicleType";
import useGlobalModel from "@/model/globalModel";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { IStockStatisticSearch, IStockStatisticDetail } from "@/views/stock/statistic/stockStatisticService";
import { ISaleOrderFields, IDetailList, IPurchaseStockSearch, IPurchaseStock, } from "../../saleOrderTypes"
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import { saleOrderService } from "../../saleOrderService"
import { formatNum, transPgToPagination,bigNumber } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { globalPrompt } from "@/components/message";
import { DictCodeEnmu, sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { convertRes2Blob } from "@/utils/exportFile";
import { useLockFn } from "ahooks";
import { purchaseDetailsService } from "@/views/stock/purchaseDetails/purchaseDetailsService";
import { message } from "antd";
import { IPriceTypeEnum, ISalesTypeEnum } from '@/types/ScmEnums';
import { useDictModel } from "@/views/base/dict/dictModel";

export type ISaleOrderDescType = {
    numAll: number,
    itemNumAll: number,
    totalAll: number,
    notTotalAll: number,
    discountAll: number,
    [key: string]: any
}

//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IPurchaseStock {
    num: number,
    price?: number,
    amount?: number
    [key:string]:any
}
export const useSaleOrderDetailModel = createModel(function () {
    const { user, shopAndOrg } = useGlobalModel()
    const { shopId, shopName } = shopAndOrg
    const { getSelectedDictOne } = useDictModel();

    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)  //新增商品弹框
    const [vehicleEditAll, setVehicleEditAll] = useState<IVehicleDetail[]>([])//实时切换车辆 编辑
    const [vehicleAddAll, setVehicleAddAll] = useState<IVehicleDetail[]>([])//实时切换车辆 新增
    const initDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0,//总计
        discountAll: 0,//折扣
        notTotalAll: 0,//折前总计
    }
    const initForm = {
        shopId,
        shopName,
        favorableAmount: 0,
        maintenanceTypeName: "正常销售",
        maintenanceTypeCode: ISalesTypeEnum.NORMAL_SALES,
        priceTypeName: "零售价",
        priceTypeCode: IPriceTypeEnum.RETAIL,
    }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initSearchParam: IStockStatisticSearch = {};
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<ISaleOrderFields>(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState<IDetailList[]>([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState<Key[]>([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState<IDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<ISaleOrderDescType>(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<ISaleOrderFields>({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState<IDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<ISaleOrderDescType>(initDesc)

    //详情数据
    const [detailEditForm, setDetailEditForm] = useState<ISaleOrderFields>({})
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState<IDetailList[]>([]);
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState<Key[]>([]);
    const [detailDataRow, setDetailDataRow] = useState<IDetailList[]>([]);

    const [editDesc, setEditDesc] = useState<ISaleOrderDescType>(initDesc)

    //配件列表配置
    const [workDataSource, setWorkDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkData, setSearchWorkData] = useState<IPurchaseStockSearch>(initSearchParam)
    const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataSource, setAddDataSource] = useState<IMaterialDetailModel[]>([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)

    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([]);
    //设置底部总数展示（查看|编辑|查看）
    useEffect(() => {
        let newDesc: ISaleOrderDescType = { ...initDesc };
        detailDataSource && detailDataSource.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.amount))
            newDesc.notTotalAll = bigNumber.add(newDesc.notTotalAll,Number(item.notAmount))
            newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus(Number(item.amount),Number(item.notAmount)))

            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.amount);
            // newDesc.notTotalAll += Number(item.notAmount);
            // newDesc.discountAll += Number(item.amount) - Number(item.notAmount)
        });
        if ("favorableAmount" in detailEditForm) {
            newDesc.totalAll = bigNumber.minus(newDesc.totalAll, Number(detailEditForm.favorableAmount))
            newDesc.discountAll = bigNumber.add( newDesc.discountAll,Number(detailEditForm.favorableAmount))

            // newDesc.totalAll = newDesc.totalAll - Number(detailEditForm.favorableAmount);
            // newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount)
        }
        newDesc.itemNumAll = detailDataSource && detailDataSource.length
        setEditDesc(newDesc);
    }, [detailDataSource, detailEditForm])

    useEffect(() => {
        let newDesc: ISaleOrderDescType = { ...initDesc };
        detailAddList && detailAddList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.amount))
            newDesc.notTotalAll = bigNumber.add(newDesc.notTotalAll,Number(item.notAmount))
            newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus(Number(item.notAmount),Number(item.amount)))

            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.amount);
            // newDesc.notTotalAll += Number(item.notAmount);
            // newDesc.discountAll += Number(item.notAmount) - Number(item.amount)
        });
        if ("favorableAmount" in detailAddForm) {
            newDesc.totalAll = bigNumber.minus(newDesc.totalAll, Number(detailAddForm.favorableAmount))
            newDesc.discountAll = bigNumber.add( newDesc.discountAll,Number(detailAddForm.favorableAmount))

            // newDesc.totalAll = newDesc.totalAll - Number(detailAddForm.favorableAmount);
            // newDesc.discountAll = newDesc.discountAll + Number(detailAddForm.favorableAmount)
        }
        newDesc.itemNumAll = detailAddList && detailAddList.length
        setAddDesc(newDesc);
    }, [detailAddList, detailAddForm])

    useEffect(() => {
        let newDesc: ISaleOrderDescType = { ...initDesc };
        detailLookList && detailLookList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.amount))
            newDesc.notTotalAll = bigNumber.add(newDesc.notTotalAll,Number(item.notAmount))
            newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus(Number(item.amount),Number(item.notAmount)))


            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.amount);
            // newDesc.notTotalAll += Number(item.notAmount);
            // newDesc.discountAll += Number(item.amount) - Number(item.notAmount)
        });
        if ("favorableAmount" in detailLookForm) {
            newDesc.totalAll = bigNumber.minus(newDesc.totalAll, Number(detailLookForm.favorableAmount))
            newDesc.discountAll = bigNumber.add( newDesc.discountAll,Number(detailLookForm.favorableAmount))

            newDesc.totalAll = newDesc.totalAll - Number(detailLookForm.favorableAmount);
            newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount)
        }
        newDesc.itemNumAll = detailLookList && detailLookList.length
        setLookDesc(newDesc);
    }, [detailLookList, detailLookForm])

    //新增表单缓存
    const updateAddFormVal = (val: ISaleOrderFields) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val: ISaleOrderFields) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await saleOrderService.one(id);
        const { details, ...from } = retData
        let markUpNum=1
        if(from.markup){
          let num:any= getSelectedDictOne(DictCodeEnmu.MARKUP_RATE).find(item=>item.value==from.markup);
          num= num.label.replace("%","")/100;
          markUpNum+=num
        }
        const newDetails = details?.map((item) => {
            let price=from.markup?item.latestPurPrice:item.price
            return {
                ...item,
                //notAmount: +formatNum(Number(item.num) * Number(item.price))
                // notAmount:+bigNumber.toFixed(bigNumber.times(Number(item.num), Number(item.price)),2)
                notAmount: +bigNumber.toFixed(bigNumber.times(Number(item.num),bigNumber.times(markUpNum, Number(price))),2),

            }
        })
        if (retData && pattern == "edit") {
            setDetailDataSource(newDetails as IDetailList[] ?? [])
            setDetailEditForm(from)
            setTopButsControl(false)
        } else if (retData && pattern == "look") {
            setDetailLookForm(from);
            setDetailLookList(newDetails as IDetailList[] ?? [])
        }
    }
    const [loading, setLoading] = useState<boolean>(false);

    //关闭弹框
    const resetWorkloadData = async () => {
        setSearchWorkData({});
        workloadData(initPgCfg, {});
    }
    //加载配件数据
    const workloadData = async (page?: ISearchPage, searchValue: IPurchaseStockSearch = searchWorkData, mode?: string) => {
        let { customerCode, maintenanceTypeCode, priceTypeCode } = mode === "add" ? detailAddForm : detailEditForm
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            age: 1,
            customerCode,
            pageSize:searchValue.purchaseCode?500: page?.pageSize ?? workPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        const newMaintenanceTypeCode = maintenanceTypeCode
        setLoading(true);
        let { retData } = await saleOrderService.stock(searchParams);
        setLoading(false)
        const { records, ...pg } = retData;
        const newRecords:any = records.map(item => {
            let { lateSalePrice, latestPurPrice, purchasePrice, retailPrice } = item
            let newPrice = newMaintenanceTypeCode === "Sales type.normal sales" || newMaintenanceTypeCode === undefined ? priceTypeCode === IPriceTypeEnum.RETAIL ? retailPrice : (lateSalePrice === 0 ? retailPrice : lateSalePrice) : (latestPurPrice === 0 ? purchasePrice : latestPurPrice)
            return {
                ...item,
                num: item.availableStock??1,                
                lateSalePrice: newPrice ?? 0,
                amount:bigNumber.toFixed(bigNumber.times(item.availableStock??1,(newPrice as number)),2)
            }
        })
        setWorkPgCfg(transPgToPagination(pg));
        setWorkDataSource(newRecords);
    }
    //筛选模块详情 详情和新增的列表已有数据的方法
    const modelScreenMethod = async (modelList: IMaterialDetailModel[], detailList: IDetailList[],mode:string) => {
        const repetition: string[] = [];
        const newDetailData: IDetailList[] = []
        const formVal = mode === "add" ? detailAddForm : detailEditForm;
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.stockId === Item.id);
            if (isSelect === undefined) {
                let { id, materialCode, materialName, inventoryNum, availableStock, num, lateSalePrice,latestPurPrice, price, amount,markup, ...params }:any = Item
                let markUpNum=1
                if(formVal.markup){
                  let num:any= getSelectedDictOne(DictCodeEnmu.MARKUP_RATE).find(item=>item.value==formVal.markup);
                  num= num.label.replace("%","")/100;
                  markUpNum+=num
                }
                newDetailData.push({
                    ...Item,
                    inventoryNum: availableStock,
                    // price: lateSalePrice ?? price,
                    price:+bigNumber.toFixed(bigNumber.times(markUpNum,lateSalePrice?lateSalePrice:price!==0?price:latestPurPrice ),2),
                    latestPurPrice:latestPurPrice ?? price,
                    // notAmount: amount,
                    discountRate: 100,
                    id: "",
                    stockId: id,
                    detailsId: id,
                    purchaseId: id,
                    notAmount: +bigNumber.toFixed(bigNumber.times(num,bigNumber.times(markUpNum,lateSalePrice?lateSalePrice:price!==0?price:latestPurPrice)),2),
                    amount:+bigNumber.toFixed(bigNumber.times(num,bigNumber.times(markUpNum,lateSalePrice?lateSalePrice:price!==0?price:latestPurPrice)),2),
                });
            } else {
                repetition.push(Item.materialName as string);
            }
            console.log("配件列表",modelList)
        })
        return { newDetailData, repetition }
    }
    //添加到详情列表
    const transformsToPoDetailList = async (mode: string) => {
        //添加列表
        if (mode == "add") {
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList,mode)
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode == "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailDataSource,mode)
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除详情列表数据
    const detailDetailList = async (mode: string) => {
        if (mode == "add") {
            const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.stockId))
            setDetailAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetaiAddDataRowKeys([]);
            setDetaiAddDataRow([]);
        } else if (mode == "edit") {
            const filterDetailList = detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.stockId))
            setDetailDataSource(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailDataRowKeys([]);
            setDetailDataRow([]);
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailDataSource : detailAddList;
        let { totalAll } = mode === "edit" ? editDesc : addDesc;
        totalAll = +formatNum(totalAll);//采购总金额
        let goodsTotal: number = 0;
        goods.forEach(item => {
            let { amount } = item as { amount: number }
            // goodsTotal += amount
            goodsTotal = bigNumber.add(goodsTotal,amount)
        })
        if (+formatNum(goodsTotal) !== totalAll) {
            message.warning(`详情销订总金额${+formatNum(goodsTotal)}与表单销订总金额不符`);
            return false;
        }
        return true
    }
    //新增采购单
    const insertDetail = useLockFn(async (params: ISaleOrderFields) => {
        let newParams = {
            ...params,
            details: detailAddList,
            createUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await saleOrderService.insert(newParams);
        return retData;
    })
    //编辑采购单
    const editDetail = useLockFn(async (params: ISaleOrderFields) => {
        let newParams = {
            ...params,
            details: detailDataSource,
            updateUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await saleOrderService.update(newParams);
        return retData;
    })
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddList([]);
        setDetailAddForm({})
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailDataSource([]);
    }
    const downloadTpl = async () => {
        let result = await saleOrderService.downloadTpl();
        convertRes2Blob(result)
    }
    const onTransfer = useLockFn(async (planCode: string) => {
        let { retData } = await saleOrderService.transfer(planCode);
        return retData
    })
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [consumeHistoryVisible, setConsumeHistoryVisible] = useState<boolean>(false) //消费记录展示
    const [consumeHistoryModel, setConsumeHistoryModel] = useState<{ customerName: string }>({ customerName: "" }) //消费记录参数
    const [repairHistoryModel, setRepairHistoryModel] = useState<boolean>(false) //维修历史
    return {

        vehicleEditAll, setVehicleEditAll,
        vehicleAddAll, setVehicleAddAll,
        addModalVisible,
        initPgCfg,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        addDesc,
        detailLookForm,
        detailLookList,
        lookDesc,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        editDesc,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        shelfAllList,
        detaiAddDataRowKeys,
        // model
        setAddModalVisible,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setAddDesc,
        setDetailLookForm,
        setDetailLookList,
        setLookDesc,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setEditDesc,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetWorkloadData,
        workloadData,
        transformsToPoDetailList,
        detailDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        setShelfAllList,
        setDetaiAddDataRowKeys,
        downloadTpl,
        modelScreenMethod,
        initForm,
        topButsControl, setTopButsControl,
        onTransfer,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        consumeHistoryModel, setConsumeHistoryModel,
        consumeHistoryVisible, setConsumeHistoryVisible,
        repairHistoryModel, setRepairHistoryModel,
        formComputeRule,loading, setLoading
    }
});