import React from "react";
import { useStockCheckModel } from "../stockCheckModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "盘库单号",
        fieldName: "stocktakingCode"
    },
    {
        type: SearchTypeEnum.Select,
        placeholder: "盘点状态",
        fieldName: "status",
        options: [{ label: "已开单", value: "0" }, { label: "已作废", value: "9" }]
    },
    {
        type: SearchTypeEnum.Select,
        placeholder: "盘点结果",
        fieldName: "result",
        options: [{ label: "盘盈", value: "盘盈" }, { label: "盘亏", value: "盘亏" }, { label: "相符", value: "相符" }],
    },
    {
        type: SearchTypeEnum.Select,
        placeholder: "盘盈单据",
        fieldName: "statue",
        options: [{ label: "已开单", value: "0" }, { label: "已作废", value: "9" }]
    },
    {
        type: SearchTypeEnum.RangePicker,
        fieldName: "",
        placeholder: ["制单开始", "制单结束"],
        rangePickerFieldName: ["startTime", "endTime"],
        format: "YYYY-MM-DD",
        span: 12,
        xxl: 6,
    },
]

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useStockCheckModel();
    const { getSelectedDictOne } = useDictModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "盘库单号",
            fieldName: "stocktakingCode"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "盘点状态",
            fieldName: "status",
            options: getSelectedDictOne(DictCodeEnmu.STOCKTAKING)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "盘点结果",
            fieldName: "result",
            options: [{ label: "盘盈", value: "盘盈" }, { label: "盘亏", value: "盘亏" }, { label: "相符", value: "相符" }],
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "盘盈单据",
            fieldName: "toc",
            options: [{ label: "盘盈单据", value: "01" }, { label: "盘亏单据", value: "02" }]
        },

    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 10,
            xxl: 6,
        },
    ]
    return (
        <YhPageHeader
            title="库存盘点"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            // searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}