import React from "react";
import { useGoodsModel } from "../materialModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "配件编码",
        fieldName: "code"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "配件名称",
        fieldName: "name"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "替换编号",
        fieldName: "replaceCode"
    },
    {
        type: SearchTypeEnum.Select,
        placeholder: "状态",
        fieldName: "enable",
        options: [{ label: "开启", value: 1 }, { label: "关闭", value: 0 }]
    }
]

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useGoodsModel();

    return (
        <YhPageHeader
            title="配件管理"
            justify="end"
            size="small"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => loadData(initPgCfg, { ...searchParam, ...params })}
            handleReset={resetSearchParam}
        />
    );
}