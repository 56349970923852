import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { ReportFormModel } from "./ReportFormModel";
import { useReportFormModel } from "../reportFormModel"

export default () => {
    let { reportForm, setReportFormModel } = useReportFormModel()
    return (
        <>
            <PageHeader />
            <DataBox />
            <ReportFormModel
                visible={!!reportForm}
                materialCode={reportForm?.code as string}
                materialName={reportForm?.name as string}
                id={reportForm?.id as string}
                onCancel={() => setReportFormModel(null)} />
        </>
    )
}