import React, { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useInventorySituationModel } from "../inventorySituationModel";
import { ISCMInventorySituationDetail } from "../inventorySituationService";
import { formatNum } from "@/utils/utils";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys
    } = useInventorySituationModel();

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<ISCMInventorySituationDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '盘库单号',
            width: 180,
            fixed: "left",
            dataIndex: 'stocktakingCode',
        }, {
            title: '商品名称',
            width: 300,
            dataIndex: 'materialName',
        }, {
            title: '商品编号',
            width: 160,
            dataIndex: 'materialCode',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '账面库存数',
            width: 120,
            dataIndex: 'inventoryNum',
        }, {
            title: '实物盘点量',
            width: 120,
            dataIndex: 'realNum',
        }, {
            title: '差异数量',
            width: 120,
            dataIndex: 'differenceNum',
        }, {
            title: '差异金额',
            width: 120,
            dataIndex: 'differenceAmount',
            render: (text) => `￥${text}`
        }, {
            title: '报废数量',
            width: 120,
            dataIndex: 'scrapNum',
        }, {
            title: '报废金额',
            width: 120,
            dataIndex: 'scrapAmount',
            render: (text) => `￥${text}`
        }, {
            title: '差异原因',
            width: 160,
            dataIndex: 'differenceReason',
        }, {
            title: '报废原因',
            width: 160,
            dataIndex: 'scrapReason',
        }, {
            title: '仓库',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '制单人',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 180,
            dataIndex: 'createTime',
        }, {
            title: '完成日期',
            width: 180,
            dataIndex: 'finishTime',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }, {
            title: '从属门店',
            width: 160,
            dataIndex: 'shopName',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            rowKey={record => record.id}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id)
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}