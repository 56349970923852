import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface IVRMPoReportDetail {
    "id": string,
    "supplierName": string,      /// "供应商名称",
    "tradeTypeCode": string,      /// "单据类型code",
    "tradeTypeName": string,      /// "单据类型name",
    "supplierType": string,      /// 供应商类型,
    "supplierTypeCode": string,      /// null,
    "supplierTypeName": string,      /// null,
    "shopId": string,      /// "门店id",
    "shopName": string,      /// "门店名称",
    "code": string,      /// "单据编号",
    "materialCode": string,      /// "商品编号",
    "materialName": string,      /// "商品名称",
    "orderTypeCode": string,      /// "采购类型code",
    "orderTypeName": string,      /// "采购类型name",
    "replaceCode": string,      /// "替换号",
    "typeCode": string,      /// "商品类型",
    "typeName": string,      /// "商品类型名称",
    "price": string,      /// 采购单价,
    "num": string,      /// 采购数量,
    "realPrice": string,      /// 采购价格,
    "amount": string,      /// 采购金额,
    "giftsNum": string,      /// 赠品数量,
    "giftsAmount": string,      /// 赠品金额,
    "warehouseName": string,      /// "仓库名称",
    "shelfCode": string,      /// "货位",
    "note": string,      /// "备注",
    "planCode": string,      /// "采购订单号",
    "settleTime": string,      /// 结算时间,
    "createTime": string,      /// "2021-06-01 21:string,      ///42:string,      ///30",
    "updateTime": string,      /// "2021-06-01 21:string,      ///42:string,      ///30",
    "createUser": string,      /// "杨月姓名",
    "updateUser": string,      /// null,
    "payType": string,      /// "结算类型code",
    "payTypeName": string,      /// "结算类型name",
    "deleteFlag": string,      /// 0
}

//搜索条件
export interface IVRMPoReportSearchFields {
    "planCode"?: string,   // "采购订单",
    "payType"?: string,   // "结算类型",
    "typeCode"?: string,   // "商品类型",
    "tradeTypeCode"?: string,   // "单据类型",
    "orderTypeCode"?: string,   // "采购类型code",
    "createUser"?: string,   // "业务员",
    "startTime"?: string,   // "开始时间",
    "endTime"?: string,   // "结束时间",
    "supplierTypeCode"?: string,   // "供应商类型",
    "supplierName"?: string,   // "供应商",
    "materialName"?: string,   // "商品名称",
    "materialCode"?: string,   // "商品编码",
    "code"?: string,   // "采购单编码",
    "shopId"?: string,   // "门店ID"
}

export interface IVRMPoReportSearch
    extends IVRMPoReportSearchFields, ISearchPage {
}
export interface IStatistics {
    purchaseNum:number, // 7,           // 采购数量
    purchaseAmount:number, // 1480.3,   // 采购金额 
    giftsNum:number, // 0,			    // 赠品数量
    gifsAmount:number, // 0				// 赠品金额
}

//进销存报表
class PoReportService {
    async page(searchVo: IVRMPoReportSearch) {
        const apiUrl = "/bcm/statistics/purchaseStatistics";
        return await httpRequest.post<IPage<IVRMPoReportDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: IVRMPoReportSearch) {
        const apiUrl = "/bcm/statistics/purchaseStatisticsExport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo: IVRMPoReportSearch) {
        const apiUrl = "/bcm/statistics/purchaseTotal";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }
}

export const poReportService = new PoReportService();