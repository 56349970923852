import React, { useEffect } from "react";
import { Button } from "antd";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { YhBox } from "@/components/YhBox";
import { ActionTypeEnum, useDetailModel } from "./detailModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
// import { ResourceAuthEnum } from "@/types/ScmResourceAuthEnum";


export const InfoBox = () => {

    const { setActionType, actionType, customer: { accountSummaryVo, name }, loadSummaryData } = useDetailModel();

    const onAccountList = () => {
        setActionType(ActionTypeEnum.ACCOUNT)
    }
    const onAdjustList = () => {
        setActionType(ActionTypeEnum.ADJUST_LIST)
    }
    const onRechargeList = () => {
        setActionType(ActionTypeEnum.RECHARGE_LIST)
    }
    const onTransferList = () => {
        setActionType(ActionTypeEnum.TRANSFER_LIst)
    }
    const onBillList = () => {
        setActionType(ActionTypeEnum.BILL_LIST)
    }
    const onWriteoffList = () => {
        setActionType(ActionTypeEnum.WRITEOFF_LIST)
    }
    const onLogList = () => {
        setActionType(ActionTypeEnum.LOG_LIST)
    }

    const classname = (type: ActionTypeEnum) => {
        return 'mr-5 ' + (actionType === type ? 'is-active' : '')
    }

    const action = (
        <>
            <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_AC}>
                <Button
                    className={classname(ActionTypeEnum.ACCOUNT)}
                    type="link"
                    shape="round"
                    onClick={onAccountList}
                >
                    账户信息
            </Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_ADJ}>
                <Button
                    className={classname(ActionTypeEnum.ADJUST_LIST)}
                    type="link"
                    shape="round"
                    onClick={onAdjustList}
                >
                    调额记录
            </Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_CHARG}>
                <Button
                    className={classname(ActionTypeEnum.RECHARGE_LIST)}
                    type="link"
                    shape="round"
                    onClick={onRechargeList}
                >
                    充值记录
            </Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_TRANS}>
                <Button
                    className={classname(ActionTypeEnum.TRANSFER_LIst)}
                    type="link"
                    shape="round"
                    onClick={onTransferList}
                >
                    转账记录
            </Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_BILL}>
                <Button
                    className={classname(ActionTypeEnum.BILL_LIST)}
                    type="link"
                    shape="round"
                    onClick={onBillList}
                >
                    账单信息
            </Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_W_OFF}>
                <Button
                    className={classname(ActionTypeEnum.WRITEOFF_LIST)}
                    type="link"
                    shape="round"
                    onClick={onWriteoffList}
                >
                    核销记录
            </Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_LOG}>
                <Button
                    className={classname(ActionTypeEnum.LOG_LIST)}
                    type="link"
                    shape="round"
                    onClick={onLogList}
                >
                    操作日志
            </Button>
            </YhAuth>
        </>
    );
    // const desc: IDescription[] = [
    //     {
    //         label: "可用额度合计",
    //         value: customer?.totalAvailableAmount?.toFixed(2) ?? '0.00',  
    //         color: DescriptionColorEnum.green
    //     },
    //     {
    //         label: "欠款总计",
    //         value: customer?.totalDebtAmount?.toFixed(2) ?? '0.00',
    //         color: DescriptionColorEnum.red
    //     },
    // ]

    const desc: IDescription[] = [
        {
            label: "可用额度合计",
            value: accountSummaryVo?.totalAvailableAmount?.toFixed(2) ?? '0.00',
            color: DescriptionColorEnum.green
        },
        {
            label: "欠款总计",
            value: accountSummaryVo?.totalDebtAmount?.toFixed(2) ?? '0.00',
            color: DescriptionColorEnum.red
        },
    ]
    return (
        <YhBox
            title={name ?? " "}
            descriptions={desc}
            action={action}
        />
    )
}
