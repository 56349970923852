import { httpRequest } from "../../utils/HttpRequest";
import { ItemDetailed, MaterialDetailed, IAppointmentField, IAppointmentSearch, IAppointmentList, IAppointmentDetail, IAppointmentRoSettleAndWhConfirm } from "./appointmentType"

class AppointmentService {
    async page(searchVo: IAppointmentSearch) {
        const apiUrl = "/bcm/appointment/findPage";
        return await httpRequest.post<IAppointmentList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/appointment/findById/${id}`;
        return await httpRequest.get<IAppointmentDetail>(apiUrl);
    }

    async insert(insertVo: IAppointmentField) {
        const apiUrl = "/bcm/appointment/insert";
        return await httpRequest.post<IAppointmentDetail>(apiUrl, insertVo);
    }
    async update(updateVo: IAppointmentField) {
        const apiUrl = "/bcm/appointment/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    // 库房确认
    async warehousing(params: IAppointmentRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/appointment/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修用料明细删除
    async materialDelete(params: MaterialDetailed) {
        const apiUrl = "/bcm/appointment/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修项目明细删除
    async itemDelete(params: ItemDetailed) {
        const apiUrl = "/bcm/appointment/itemDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //结算
    async settl(params: IAppointmentRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/appointment/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //作废
    async invalidate(params: object) {
        const apiUrl = "/bcm/appointment/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //重新加载维修项目得数据
    async projectQuery(params: string[]) {
        const apiUrl = "/bcm/base/item/projectQuery";
        return await httpRequest.post<ItemDetailed[]>(apiUrl, params);
    }
    //重新加载维修用料得数据
    async find(params: string[]) {
        const apiUrl = "/bcm/stock/find";
        return await httpRequest.post<MaterialDetailed[]>(apiUrl, params);
    }
    //预约转维修
    async transfer(params: IAppointmentField) {
        const apiUrl = "/bcm/appointment/transfer";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: IAppointmentField) {
        const apiUrl = "/bcm/appointment/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: IAppointmentField) {
        const apiUrl = "/bcm/appointment/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
}
export const appointmentService = new AppointmentService();
