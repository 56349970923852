import React from "react";
import { useBusBusinessModel } from "../../busBusinessModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useBusBusinessModel();
    const { getSelectedDictOne } = useDictModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "仓库名称",
            fieldName: "name",
        }
    ]
    return (
        <>
            <YhPageHeader
                title="预售销售"
                size="small"
                justify="end"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                // handleSearch={()=>{console.log(searchParam)}}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
        </>
    );
}