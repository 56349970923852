import React, { useEffect } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { InitAccountDrawer } from "./InitAccountDrawer";
import {SyncroModel} from "./syncroModel"


export const Summary = () => {
    return (
        <>
            <PageHeader />
            <DataBox />
            <InitAccountDrawer />
            <SyncroModel/>
        </>
    )
}