import { useState, Key, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import useGlobalModel from "@/model/globalModel";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { IPurchaseRoFields, IDetailList, IPurchaseDetails, } from "../../purchaseRoType"
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import { purchaseRoService } from "../../purchaseRoService"
import { ISearchPage } from "@/types/ScmTypes";
import { transPgToPagination, formatNum,bigNumber } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import { DictCodeEnmu, sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { IStockStatisticDetail } from "@/views/stock/statistic/stockStatisticService";
import { useLockFn } from "ahooks";
import { message } from "antd";
export type IVrmRoDescType = {
    numAll: number,
    itemNumAll: number,
    totalAll: number,
    paidAmount: number,
    [key: string]: any
}
//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IPurchaseDetails {
    purchaseNum?: number,
    detailsId?: string,
}
export const usePurchaseRoDetailsModel = createModel(function () {
    const { user, shopAndOrg } = useGlobalModel()
    const { shopId, shopName } = shopAndOrg

    const [logisticsDraer, setLogisticsDraer] = useState<boolean>(false);  //物流登记弹框
    const [settleVisible, setSettleVisible] = useState<boolean>(false);   //结算弹框
    const [wsConfirmModal, setWsConfirmModal] = useState<boolean>(false);    //库房确认弹框
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)  //新增商品弹框
    const [registerModal, setRegisterModal] = useState<boolean>(false);     //出库登记弹框

    const initDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0, //总计
        paidAmount: 0
    }
    const initForm = { 
        shopId,
        shopName,
        documentTypeCode: DictCodeEnmu.PRETURN_TYPE_GENERAL,
        documentTypeName: "普通"
    }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initSearchParam: IPurchaseDetails = {};
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IPurchaseRoFields>(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState<IDetailList[]>([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState<Key[]>([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState<IDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<IVrmRoDescType>(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IPurchaseRoFields>({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState<IDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<IVrmRoDescType>(initDesc)

    //详情数据
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState<IDetailList[]>([]);
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState<Key[]>([]);
    const [detailDataRow, setDetailDataRow] = useState<IDetailList[]>([]);
    const [detailEditForm, setDetailEditForm] = useState<IPurchaseRoFields>({})
    const [editDesc, setEditDesc] = useState<IVrmRoDescType>(initDesc)
    //配件列表配置
    const [workDataSource, setWorkDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkData, setSearchWorkData] = useState<IPurchaseDetails>({})
    const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataSource, setAddDataSource] = useState<IMaterialDetailModel[]>([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)

    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([]);

    //设置底部总数展示（查看|编辑|查看）
    useEffect(() => {
        let newDesc: IVrmRoDescType = { ...initDesc };
        detailDataSource && detailDataSource.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll, Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,bigNumber.times(Number(item.price),Number(item.num)))

            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.price) * Number(item.num);
        });
        newDesc.itemNumAll = detailDataSource && detailDataSource.length
        setEditDesc(newDesc);
    }, [detailDataSource])

    useEffect(() => {
        let newDesc: IVrmRoDescType = { ...initDesc };
        detailAddList && detailAddList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll, Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,bigNumber.times(Number(item.num),Number(item.price)))
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.num) * Number(item.price);
        });
        newDesc.itemNumAll = detailAddList && detailAddList.length
        setAddDesc(newDesc);
    }, [detailAddList])

    useEffect(() => {
        let newDesc: IVrmRoDescType = { ...initDesc };
        detailLookList && detailLookList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll, Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,bigNumber.times(Number(item.num),Number(item.price)))
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.num) * Number(item.price);
        });
        newDesc.itemNumAll = detailLookList && detailLookList.length
        setLookDesc(newDesc);
    }, [detailLookList])
    //新增表单缓存
    const updateAddFormVal = (val: IPurchaseRoFields) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val: IPurchaseRoFields) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await purchaseRoService.one(id);
        let { details, ...from } = retData
        const settleDetails = retData.settleDetails
        let cashFileList: any = []
        if (settleDetails && settleDetails.length > 0) {
            const { cashAttach, cashAttachFileName } = settleDetails[0]
            if(cashAttach && cashAttachFileName){
                cashFileList = [
                    {
                        uid: cashAttach,
                        status: 'done',
                        name: cashAttachFileName,
                        response: {
                            retData: cashAttach,
                        },
                    },
                ]
            }
        }
        from.cashFileList = cashFileList
        if (retData && pattern === "edit") {
            setDetailDataSource(details as IDetailList[] ?? [])
            setDetailEditForm(from)
            setTopButsControl(false)
        } else if (retData && pattern === "look") {
            setDetailLookForm(from);
            setDetailLookList(details as IDetailList[] ?? [])
        }
    }
    //关闭弹框
    const resetWorkloadData = async (params: IPurchaseDetails) => {
        setSearchWorkData({ ...params });
        workloadData(initPgCfg, { ...params });
    }
    //加载配件数据
    // const workloadData = async (page?: ISearchPage, searchValue: IPurchaseDetails = searchWorkData, mode?:string) => {
        const workloadData = async (page?: ISearchPage, searchValue: IPurchaseDetails = searchWorkData) => {
        let mode=Object.values(detailEditForm).length>0 ? 'edit':Object.values(detailAddForm).length>0 ? 'add':'look'
        let documentTypeCode = mode === "edit" ? detailEditForm.documentTypeCode : detailAddForm.documentTypeCode;
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            supplierCode: mode === "edit" ? detailEditForm.supplierCode : detailAddForm.supplierCode,
            pageSize: page?.pageSize ?? workPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { supplierCode, ...params } = searchParams
        let seachData = documentTypeCode === DictCodeEnmu.PRETURN_TYPE_GENERAL ? searchParams : params
        let { retData } = await purchaseRoService.details(seachData);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            return {
                ...item,
                returnNum:bigNumber.minus(Number(item.inventoryNum), Number(item.inventoryOccupied))??1,
                purchasePrice: item.price,
                // amount: Number(item.price) * 1,
                amount:+bigNumber.toFixed(bigNumber.times(Number(item.price),bigNumber.minus(Number(item.inventoryNum), Number(item.inventoryOccupied))??1),2),
                // availableStock: Number(item.inventoryNum) - Number(item.inventoryOccupied)
                availableStock:bigNumber.minus(Number(item.inventoryNum), Number(item.inventoryOccupied))
            }
        })
        setWorkPgCfg(transPgToPagination(pg));
        setWorkDataSource(newRecords);
    }
    //筛选模块详情 详情和新增的列表已有数据的方法
    const modelScreenMethod = async (modelList: IPurchaseDetails[], detailList: IDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IMaterialDetailModel[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.detailsId === Item.id);
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    purchaseNum: Item.num,
                    num: Item.returnNum,
                    detailsId: Item.id,
                    id: "",
                });
            } else {
                repetition.push(Item.warehouseName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到详情列表
    const transformsToPoDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList)
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailDataSource)
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除详情列表数据
    const detailDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetaiAddDataRowKeys([]);
            setDetaiAddDataRow([]);
        } else if (mode === "edit") {
            const filterDetailList = detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.detailsId))
            console.log(filterDetailList)
            setDetailDataSource(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailDataRowKeys([]);
            setDetailDataRow([]);
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailDataSource : detailAddList;
        let { totalAll } = mode === "edit" ? editDesc : addDesc;
        totalAll = +formatNum(totalAll);//采购总金额
        let goodsTotal: number = 0;
        goods.forEach(item => {
            let { amount } = item as { amount: number }
            // goodsTotal += amount
            goodsTotal = bigNumber.add(goodsTotal,amount)
        })
        if (+formatNum(goodsTotal) !== totalAll) {
            message.warning(`详情采退总金额${+formatNum(goodsTotal)}与表单采退总金额不符`);
            return false;
        }
        return true
    }
    //新增采购单
    const insertDetail = useLockFn(async (params: IPurchaseRoFields) => {
        let newParams = {
            ...params,
            details: detailAddList,
            createUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchaseRoService.insert(newParams);
        return retData;
    })
    //编辑采购单
    const editDetail = useLockFn(async (params: IPurchaseRoFields) => {
        let newParams = {
            ...params,
            details: detailDataSource,
            updateUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchaseRoService.update(newParams);
        return retData;
    })
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddList([]);
        setDetailAddForm({})
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailDataSource([]);
    }
    const warehousing = async () => {
        let { id, returnCode } = detailEditForm
        let paramas = {
            id,
            returnCode,
            storekeeper: user.username
        }
        let { retData } = await purchaseRoService.warehousing(paramas);
        return retData
    }
    const onSettle = async (params: object) => {
        let { id, returnCode } = detailEditForm
        let paramas = {
            id,
            returnCode,
            reckoner: user.username,
            ...params

        }
        let { retData } = await purchaseRoService.settle(paramas);
        return retData
    }
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [supplierHistoryVisible, setSupplierHistoryVisible] = useState<boolean>(false) //消费记录展示
    const [supplierHistoryModel, setSupplierHistoryModel] = useState<{ supplierName: string }>({ supplierName: "" }) //消费记录参数
    return {
        addModalVisible,
        settleVisible,
        wsConfirmModal,
        registerModal,
        logisticsDraer,
        setLogisticsDraer,
        setAddModalVisible,
        setSettleVisible,
        setWsConfirmModal,
        setRegisterModal,
        // data 
        initPgCfg,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        addDesc,
        detailLookForm,
        detailLookList,
        lookDesc,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        editDesc,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        shelfAllList,
        detaiAddDataRowKeys,
        // model
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setAddDesc,
        setDetailLookForm,
        setDetailLookList,
        setLookDesc,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setEditDesc,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetWorkloadData,
        workloadData,
        transformsToPoDetailList,
        detailDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        setShelfAllList,
        setDetaiAddDataRowKeys,
        modelScreenMethod,
        warehousing,
        onSettle,
        topButsControl, setTopButsControl,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        supplierHistoryVisible, setSupplierHistoryVisible,
        supplierHistoryModel, setSupplierHistoryModel,
        formComputeRule
    }
});