import React from "react";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useSummaryModel } from "../summaryModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSummaryModel();
    const { getSelectedDictOne } = useDictModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "送修人",
            fieldName: "contact"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "送车电话",
            fieldName: "phone"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "车牌号",
            fieldName: "licensePlate"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "VIN码",
            fieldName: "vin"
        },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "工单状态",
        //     fieldName: "status",
        //     options: getSelectedDictOne(DictCodeEnmu.MAINTENANCE)
        // },

    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "车辆型号",
            fieldName: "vehicleModel"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "维修类型",
            fieldName: "serviceTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.REPAIR_TYPE)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "服务地点",
            fieldName: "serviceLocationCode",
            options: getSelectedDictOne(DictCodeEnmu.SERVICE_ADDRESS)
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "工单单号",
            fieldName: "maintenanceCode"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算方",
            fieldName: "settlementPartyCode",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算方式",
            fieldName: "settlementMethod",
            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)

        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "车辆类型",
            fieldName: "vehicleTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE)
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser"
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["进厂开始", "进厂结束"],
            rangePickerFieldName: ["enterStartTime", "enterEndTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["出厂开始", "出厂结束"],
            rangePickerFieldName: ["outStartTime", "outEndTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["createStartTime", "createEndTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
    ]
    return (
        <YhPageHeader
            title="结算列表"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}