import React, { FC } from "react";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { RouterParamType } from "@/model/navModel";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";
import { Description } from "@/components/YhBox/Description";
import { useDictModel } from "@/views/base/dict/dictModel";

export const TopButs: FC<RouterParamType> = ({ id, mode }) => {
    const {
        detailEditForm,
        detailLookForm,
    } = usefollowUpDetailsModel();
    const { getTreeNodeName } = useDictModel();
    const typeTitle = {
        "add": detailLookForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatu: IDescription[] = [
        {
            label: "回访单",
            value: typeTitle[mode].maintenanceCode ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={false}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}