import React, { useEffect, useRef, useState } from "react";
import { useAccountModel } from "./accountModel";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { accountService } from "../../../../AccountService";
import { message } from "antd";
import useGlobalModel from "@/model/globalModel";
import { useOrgModel } from "@/views/organization/org/orgModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

type ISelectOptions = {
    label: string,
    value: string,
}
export const TransferDrawer = () => {
    const { beDs } = useGlobalModel();
    const { getOrgTreeSelect } = useOrgModel(() => []);
    const {
        selectedData,
        onReset,
        transferDrawerVisible,
        setTransferDrawerVisible,
        getAccoutSupplierUser,
        supplierUserList,
    } = useAccountModel(model => [model.transferDrawerVisible]);

    const [customerUserDs, setCustomerUserDs] = useState<ISelectOptions[]>()
    const [orgInCode, setOrgInCode] = useState(selectedData?.orgCode)
    const [accountInCode, setAccountInCode] = useState()

    const getCustomer = async (value?: any) => {
        let options: ISelectOptions[] = await getAccoutSupplierUser({
            beCode: selectedData?.beCode,
        })
        let customer = supplierUserList?.find(index => index.code === value)
        setAccountInCode(value)
        setOrgInCode(customer?.orgCode)
        setCustomerUserDs(options)
    }
    useEffect(() => {
        getCustomer()
    }, [transferDrawerVisible])

    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        disabled: false,
        formValues: { ...selectedData, orgInCode, accountInCode },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户编码",
                fieldName: "code",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "转出账户",
                fieldName: "name",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "剩余金额(转出账户)",
                fieldName: "remainingAmount",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "机构(转出账户)",
                fieldName: "orgCode",
                disable: true,
                span: 24,
                treeDs: getOrgTreeSelect(DictCodeEnmu.ORG)
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "实体(转出账户)",
                fieldName: "beCode",
                disable: true,
                span: 24,
                options: beDs
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "转入账户",
                fieldName: "accountInCode",
                disable: false,
                span: 24,
                rules: [{
                    required: true,
                    message: "转入账户不得为空!"
                }],
                options: customerUserDs?.filter(item => item.value !== selectedData?.code),
                onSelectChange: getCustomer
            },
            // {
            //     type: FormItemTypeEnum.Input,
            //     fieldLabel: "剩余金额(转入账户)",
            //     fieldName: "phone",
            //     disable: true,
            //     span: 24,
            // },
            {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "机构(转入)",
                fieldName: "orgInCode",
                span: 24,
                treeDs: getOrgTreeSelect(DictCodeEnmu.ORG),
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "转账金额",
                fieldName: "transferAmount",
                span: 24,
                rules: [{
                    required: true,
                    pattern: new RegExp(/^[+]?(\d+)$|^[+]?(\d+\.\d+)$/),
                    message: "输入不能为空且大于等于0的数字!"
                }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "remark",
                span: 24,
            },
        ]
    };
    const formRef = useRef<IYhFormRef>();

    const onOk = async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const reqData = {
                    accountOutCode: formData.code,
                    accountInCode: formData.accountInCode,
                    transferAmount: formData.transferAmount,
                    note: formData.remark
                };
                let insertRet = await accountService.transfer(reqData);
                if (insertRet.success) {
                    message.success("转账成功!");
                    onReset();
                    setTransferDrawerVisible(false);
                } else {
                    message.error("转账失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log("数据校验失败");
        }
    }
    const onCancel = () => {
        setTransferDrawerVisible(false);
    }
    return (
        <YHDrawer
            {...{
                title: "转账",
                width: 600,
                visible: transferDrawerVisible,
                onOk,
                onCancel,
                showFooter: true
            }}
        >
            <YhForm formRef={formRef} {...formConfig} />
        </YHDrawer>
    )
}
