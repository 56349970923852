import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useStockOutAndInListModel } from "../stockOutAndInListModel";
import { IStockBorrowDetail } from "../stockOutAndInListType";
import { useDictModel } from "@/views/base/dict/dictModel";
import useNavModel from "@/model/navModel";



export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useStockOutAndInListModel();
    const columns: ColumnsType<IStockBorrowDetail> = [
        {
            title: '序号',
            width: 120,
            render: (t, r, i) => i + 1
        }, {
            title: '借件单号',
            width: 160,
            dataIndex: 'borrowCode',
            // render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(text)}>{text}</p></Tooltip>,
        }, {
            title: '借件人',
            width: 160,
            dataIndex: 'borrowPerson',
            // }, {
            //     title: '单据类型',
            //     width: 160,
            //     dataIndex: 'documentType',
            // }, {
            //     title: '单据状态',
            //     width: 160,
            //     dataIndex: 'status',
            //     render: (text) => getTreeNodeName(text)
        }, {
            title: '制单人',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '借件日期',
            width: 160,
            dataIndex: 'createTime',
        }
    ];
    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    useEffect(() => {
        loadData().then();
    }, [])
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IStockBorrowDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => {
                return {
                    onClick: () => onRow(record.id as string),
                    onContextMenu: (e) => {
                        e.preventDefault();
                        setSelectedRowKeys([record.id as string]);
                    }
                };
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}
