import React, { FC } from "react";
import "./style.less";
export interface IYhTagProps {
    color: "magenta" | "red" | "volcano" | "orange" | "gold" | "lime" | "green" | "cyan" | "blue" | "geekblue" | "purple",
    text: string,
    onClick?: () => void,
    disabled?: boolean,

}
const YhTag: FC<IYhTagProps> = ({
    color,
    text,
    disabled = false,
    onClick = () => null
}) => {
    return <span
        className={`ant-tags ant-tags-${color} ${disabled ? 'yh-tag-disabled' : ''}`}
        onClick={() => { !disabled && onClick() }}
    >{text}</span>
}
export default YhTag