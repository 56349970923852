import { httpRequest } from "@/utils/HttpRequest";
import { IPage, ISearchPage } from "@/types/ScmTypes";

//客户信息
export interface IVRMCustomerInfo {
    contact: string,
    phone: string,
    note: string
}

//详情
export interface IVRMCustomerDetail {
    address?: string,
    attrCode?: string,
    attrName?: string,
    bank?: string,
    bankAcct?: string,
    bankPhone?: string,
    branch?: string,
    businessScope?: string,
    code?: string,
    contact?: string,
    corporation?: string,
    createTime?: string,
    createUser?: string,
    email?: string,
    enable?: 0,
    fax?: string,
    id: string,
    invoiceTitle?: string,
    isInternal?: string,
    level?: 0,
    licenseNo?: string,
    linkCode?: string,
    name?: string,
    note?: string,
    orgCode?: string,
    orgName?: string,
    phone?: string,
    postAddress?: string,
    postCode?: string,
    shopId?: string,
    shopName?: string,
    staffCode?: string,
    staffName?: string,
    tel?: string,
    typeCode?: string,
    typeName?: string,
    updateTime?: string,
    updateUser?: string,
    ver?: number,
    website?: string,
    status?: string,
    categoryCode?: string,
    categoryName?: string,
}

//搜索条件
export interface IVRMCustomerSearchFields {
    shopId?: string,
    code?: string,
    name?: string,
    typeCode?: string,
    contact?: string,
    phone?: string,
    status?: string,
}

export interface IVRMCustomerSearch extends IVRMCustomerSearchFields, ISearchPage {
    
}

class CustomerService {
    async page(searchVo: IVRMCustomerSearch) {
        const apiUrl = "/bcm/base/customer/findPage";
        return await httpRequest.post<IPage<IVRMCustomerDetail>>(apiUrl, searchVo);
    }
    async totalPage(searchVo: IVRMCustomerSearch) {
        const apiUrl = "/bcm/base/customer/totalPage";
        return await httpRequest.post<IVRMCustomerDetail[]>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/base/customer/findById/${id}`;
        return await httpRequest.get<IVRMCustomerDetail>(apiUrl);
    }
    async insert(insertVo: IVRMCustomerDetail) {
        const apiUrl = "/bcm/base/customer/insert";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }
    async update(updateVo: IVRMCustomerDetail) {
        const apiUrl = "/bcm/base/customer/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //启用禁用
    async disable(params: object) {
        const apiUrl = `/bcm/base/customer/change`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导入
    async import(file: { file: File }) {
        const appPageUrl = "/bcm/base/customer/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //导出
    async export(searchVo: IVRMCustomerSearch) {
        const appPageUrl = "/bcm/base/customer/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //供应商转客户
    async transfer(id: string) {
        const appPageUrl = `/bcm/base/customer/transfer/${id}`;
        return await httpRequest.post<boolean>(appPageUrl);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/bcm/base/customer/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
}

export const customerService = new CustomerService();
