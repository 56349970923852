import React from "react";
import { DataTable } from "./DataTable";
import { Button, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useSupplierModel } from "../supplierModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, updateEditFormVal, exportSupplier, downloadTpl, loadData } = useSupplierModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "13-1-2";
        let isFindNav = findNav(supplierEditKey);
        if (!isFindNav) {
            addNav(supplierEditKey, { id: selectedRowKeys[0], mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "供应商跳转详情",
                type: "warning",
                text: "您还有供应商详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(supplierEditKey, { id: selectedRowKeys[0], mode: "edit" });
                    updateEditFormVal(null);
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "13-1-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRowKeys[0] as string, mode: "look", },
            true
        );
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_S_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("13-1-1", { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_S_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_S_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_S_IM}>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/supplier/import`, loadData)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_S_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { exportSupplier().then() }}
            >导出</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_S_DLOAD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { downloadTpl().then() }}
            >下载模板</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="供应商列表" action={action}>
            <DataTable />
        </YhBox>
    )
}