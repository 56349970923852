import { PageHeader } from "./PageHeader"
import {DataBox} from "./DataBox"
export default ()=> {
    return (
        <>
        <PageHeader />
        <DataBox />
        </>
        
    )
}