import { httpRequest } from '../../../utils/HttpRequest';
import { IPage, ISearchPage } from '@/types/ScmTypes';

//详情
export interface IVRMShopDetail {
	id: string; // "bf9dc1a3b9647038f96e545229b25e72",
	code: string; // "ETY-M-gSUn-00000019",
	orderNum: string; // 0,
	deleteFlag: string; // 0,
	createTime: string; // ,
	updateTime: string; //,
	createUser: string; // "默认用户",
	updateUser: string; // "默认用户",
	branch: string; // "main",
	ver: string; // 6,
	enable: string; // null,
	orgCode: string; // "机构编码",
	orgName: string; // "机构名称",
	name: string; // "门店名称",
	contact: string; // "联系人",
	phone: string; // "332",
	address: string; // "地址",
	categoryCode: string; // "门店类型",
	categoryName: string; // "门店类型名称",
	status: number; // 1,
	note: string; // "备注",
	details: IVRMBeDetails[]; //
}

//门店 实体
export interface IVRMBeDetails {
	id: string; // "911332111d8134f89b45490410550079",
	code: string; // null,
	orderNum: string; // 0,
	deleteFlag: string; // 0,
	createTime: string; //,
	updateTime: string; //,
	createUser: string; // "默认用户",
	updateUser: string; // null,
	shopCode: string; // "ETY-M-gSUn-00000019",
	bentityCode: string; // "实体编码-",
	bentityName: string; // "实体名称-"
}

//新增
export interface IVRMShopInsert {
	orgCode: string; // "机构编码",
	orgName: string; // "机构名称",
	address: string; // "地址",
	contact: string; // "联系人",
	branch: string; // "main",
	phone: string; // "332",
	name: string; // "门店名称",
	categoryCode: string; // "门店类型",
	categoryName: string; // "门店类型名称",
	note?: string; // "备注",
	details: {
		shopCode: string; // "备注",
		bentityCode: string; // "实体编码",
		bentityName: string; // "实体名称"
	}[];
	status: number;
	code?: string;
}

//编辑
export interface IVRMShopUpdate extends IVRMShopInsert {
	id: string;
}

//搜索条件
export interface IVRMShopSearchFields {
	enable?: string; // "分支",
	stuats?: string; // "状态",
	orgCode?: string; // "机构编码",
	name?: string; // "门店名称",
	sourceApp?: string; // "mdm",
}
export interface ICmSearchPageFields {
	page: ISearchPage;
}
export interface IVRMShopSearch extends IVRMShopSearchFields, ICmSearchPageFields {}

//表单字段
export interface IVRMShopFormFields {
	branch: string;
	code?: string;
	name: string;
	orgCode: string;
	orgName: string;
	contact: string;
	phone: string;
	address: string;
	status: boolean;
	categoryCode: string;
	categoryName: string;
	details: string[];
	note?: string;
}

class ShopService {
	async page(searchVo: IVRMShopSearch) {
		const apiUrl = '/bcm/shop/page';
		return await httpRequest.post<IPage<IVRMShopDetail>>(apiUrl, searchVo);
	}
	async one(obj: object) {
		const apiUrl = `/bcm/shop/one`;
		return await httpRequest.post<IVRMShopDetail>(apiUrl, obj);
	}
	async insert(insertVo: IVRMShopInsert) {
		const apiUrl = '/bcm/shop/insert';
		return await httpRequest.post<boolean>(apiUrl, insertVo);
	}
	async update(updateVo: IVRMShopUpdate) {
		const apiUrl = '/bcm/shop/update';
		return await httpRequest.post<boolean>(apiUrl, updateVo);
	}
	//启用禁用
	async disable(params: object) {
		const apiUrl = `/bcm/shop/change`;
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	async pageFms(searchVo: IVRMShopSearch) {
		const apiUrl = '/fms/shop/page';
		return await httpRequest.post<IPage<IVRMShopDetail>>(apiUrl, searchVo);
	}

	async fmsPage(searchVo: IVRMShopSearch) {
		const apiUrl = '/fms/shop/page';
		return await httpRequest.post<IPage<IVRMShopDetail>>(apiUrl, searchVo);
	}
	async newPageFms(searchVo: any) {
        const apiUrl = "/fms/shop/find";
        return await httpRequest.post<IVRMShopDetail[]>(apiUrl, searchVo);
    }
}

export const shopService = new ShopService();
