import React from "react";
import { PageHeader } from "./PageHeader"
import {DataBox} from "./DataBox"
const BaseVehicle = () => {
    return (
        <>
            <PageHeader/>
            <DataBox/>
        </>
    )
}
export default BaseVehicle