export enum DictCodeEnmu {
    //客户类型
    CUSTOMER_TYPE = "customer-type",
    //客户属性
    CUSTOMER_ATTR = "customer-attr",
    //供应商类型
    SUPPLIER_TYPE = "supplier-type",

    //配件类型
    MATERIAL_TYPE = "material-type",
    //配件类型 (发动机)
    MATERIAL_TYPE_ENGINE = "material-type.engine",
    //计量单位
    MATERIAL_UNIT = "unit",
    //品牌
    MATERIAL_BRAND = "material-brand",
    //发动机型号
    ENGINE_TYPE = "engine-type",

    //开户银行类型
    OPENBANK_TYPE = "account-bank",
    //账户类型
    ACCOUNT_TYPE = "account-type",
    //发票类型
    INVOICE_TYPE = "invoice-type",
    //发票状态
    INVOICE_STATUS = "invoice-status",
    //支付状态
    PAY_STATUS = "pay-status",
    //支付方式
    PAY_METHOD = "pay-method",
    //支付方式（纯现金类）
    PAY_METHOD_CASH = "pay-method.cash",
    //支付方式（现金账户类）
    PAY_METHOD_ACCT = "pay-method.acct",

    //结算类型
    PAYEE_TYPE = "settle-type",
    //经济实体
    BENTITY = "bentity",
    //核销状态
    WRITE_OFF_STATUS = "write-off-status",

    //采购单状态
    PURCHASE_STATUS = "purchase-status",

    //采购单状态 （已作废）
    PURCHASE_STATUS_CANCEL = "h-purchase-status.cancel",
    //采购单状态 （已结款）
    PURCHASE_STATUS_SETTLE = "g-purchase-status.settle",
    //采购单状态 （驳回待结算）
    PURCHASE_STATUS_REJECT = "e-purchase-status.reject",
    //采购单状态 （已开单）
    PURCHASE_STATUS_CREATE = "a-purchase-status.create",
    //采购单状态 （已结算）
    PURCHASE_STATUS_BILL = "f-purchase-status.bill",
    //采购单状态 （全部入库）
    PURCHASE_STATUS_COMPLETELY_IO = "d-purchase-status.completely-io",
    //采购单状态 （部分入库）
    PURCHASE_STATUS_PARTLY_IO = "c-purchase-status.partly-io",
    //采购单状态 （待入库）
    PURCHASE_STATUS_SUBMIT = "b-purchase-status.submit",

    //采购类型
    PURCHASE_TYPE = "purchase-type",
    //采购类型常规
    PURCHASE_TYPE_GENERAL = "purchase-type.general",
    //采购类型直发
    PURCHASE_TYPE_DIRECT = "purchase-type.direct",
    //采购类型投放
    PURCHASE_TYPE_LAUNCH = "purchase-type.launch",

    //采购退货单状态
    PURCHASE_RETURN_STATUS = "purchase-return-status",
    //采购单明细入库状态
    PUR_STOCK_IO_STATUS = "pur-stock—io-status",

    //采购退货单 (已作废)
    PURCHASE_RETURN_STATUS_CANCEL = "g-purchase-return-status.cancel",
    //采购退货单 (驳回待结算)
    PURCHASE_RETURN_STATUS_REJECT = "d-purchase-return-status.reject-t",
    //采购退货单 (已结款)
    PURCHASE_RETURN_STATUS_SETTLE = "f-purchase-return-status.settle",
    //采购退货单 (已结算)
    PURCHASE_RETURN_STATUS_BILL = "e-purchase-return-status.bill",
    //采购退货单 (已出库)
    PURCHASE_RETURN_STATUS_STOCK_IO = "c-purchase-return-status.stock-io",
    //采购退货单 (待出库)
    PURCHASE_RETURN_STATUS_SUBMIT = "b-purchase-return-status.submit",
    //采购退货单 (已开单)
    PURCHASE_RETURN_STATUS_CREATE = "a-purchase-return-status.create",



    //销售订单状态
    SALE_ORDER_STATUS = "sale-order-status",
    //销售订单 （已作废）
    SALE_ORDER_STATUS_CANCEL = "c-sale-order-status.cancel",
    //销售订单 （已转接）
    SALE_ORDER_STATUS_TRANSFER = "b-sale-order-status.transfer",
    //销售订单 （已开单）
    SALE_ORDER_STATUS_CREATE = "a-sale-order-status.create",


    //销售单状态
    SALE_STATUS = "sale-status",
    //销售单 (已作废)
    SALE_STATUS_CANCEL = "l-sale-status.cancel",
    //销售单 (待审核) 优惠类型为另赠时加的
    SALE_STATUS_BE_REVIEWED = "e-sale-status.be-reviewed",
    //销售单 (已开单)
    SALE_STATUS_CREATE = "a-sale-status.create",
    //销售单 (已结款)
    SALE_STATUS_SETTLE = "e-sale-status.settle",
    //销售单 (已结算)
    SALE_STATUS_BILL = "d-sale-status.bill",
    //销售单 (待结算)
    SALE_STATUS_SUBMIT = "b-sale-status.submit",
    //销售单 (结算中)
    SALE_STATUS_PAYING = "d-sale-status.paying",
    //销售单 (已派工)
    SALE_STATUS_DISPATCH = "f-sale-status.dispatch",
    //销售单 (备货中)
    SALE_STATUS_PREPARING = "g-sale-status.preparing",
    //销售单 (检验中)
    SALE_STATUS_INSPECTING = "h-sale-status.inspecting",
    //销售单 (驳回待结算)
    SALE_STATUS_REJECT = "c-sale-status.reject",
    //销售单 (待出库,检验完成)
    SALE_STATUS_INSPECTED = "i-sale-status.inspected",
    //销售单 (全部出库)
    SALE_STATUS_COMPLETELY_IO = "k-sale-status.completely-io",
    //销售单 (部分出库)
    SALE_STATUS_PARTLY_IO = "j-sale-status.partly-io",

    //销售退货单状态
    SALE_RETURN_STATUS = "sale-return-status",
    //销售退货单 (驳回待结算）
    SALE_RETURN_STATUS_REJECT = "d-sale-return-status.reject",
    //销售退货单 (已作废）
    SALE_RETURN_STATUS_CANCEL = "g-sale-return-status.cancel",
    //销售退货单 (已结款）
    SALE_RETURN_STATUS_SETTLE = "f-sale-return-status.settle",
    //销售退货单 (支付中）
    SALE_RETURN_STATUS_PAYING = "h-sale-return-status.paying",
    //销售退货单 (已结算）
    SALE_RETURN_STATUS_BILL = "e-sale-return-status.bill",
    //销售退货单 (已入库）
    SALE_RETURN_STATUS_STOCK_IO = "c-sale-return-status.stock-io",
    //销售退货单 (待入库）
    SALE_RETURN_STATUS_SUBMIT = "b-sale-return-status.submit",
    //销售退货单 (已开单）
    SALE_RETURN_STATUS_CREATE = "a-sale-return-status.create",

    // 非关联销售的状态
    UNUSUAL_SRETURN = "unusual-sreturn",
    // 已结款
    UNUSUAL_SRETURN_SETTLE = "unusual-sreturn.settle",
    // 已结算
    UNUSUAL_SRETURN_BILL = "unusual-sreturn.bill",
    // 已入库
    UNUSUAL_SRETURN_STOCK_IN = "unusual-sreturn.stock-in",
    // 待入库
    UNUSUAL_SRETURN_TO_STOCK = "unusual-sreturn.to-stock",
    // 已开单
    UNUSUAL_SRETURN_CREATE = "unusual-sreturn.create",
    // 待审核
    UNUSUAL_SRETURN_TO_AUDIT = "unusual-sreturn.to-audit",
    // 草拟
    UNUSUAL_SRETURN_DRAFT = "unusual-sreturn.draft",

    //销售类型
    SALE_TYPE = "sale-type",
    //销售类型直发
    SALE_TYPE_DIRECT = "sale-type.direct",
    //销售类型常规
    SALE_TYPE_GENERAL = "sale-type.general",
    //销售类型投放
    SALE_TYPE_LAUNCH = "sale-type.launch",
    //销售单明细出库状态
    SALE_STOCK_IO_STATUS = "sale-stock—io-status",
    //销售单明细出库状态 (待出库)
    SALE_STOCK_IO_INIT = "sale-stock—io-status.init",

    //售价选择类型
    SALE_PRICE_TYPE = "sale-price-type",
    //售价选择类型 (最新进价)
    SALE_PRICE_TYPE_LATEST = "sale-price-type.latest-purchase",
    //售价选择类型 (最近售价)
    SALE_PRICE_TYPE_LATELY = "sale-price-type.latest",
    //售价选择类型 (最低售价)
    SALE_PRICE_TYPE_LWEST = "sale-price-type.lowest",
    //售价选择类型 (最高进价)
    SALE_PRICE_TYPE_HIGHEST = "sale-price-type.highest",
    //售价选择类型 (平均价)
    SALE_PRICE_TYPE_AVERAGE = "sale-price-type.average",
    //售价选择类型 (优惠价格)
    SALE_PRICE_TYPE_REPAIR_FACTORY = "sale-price-type.repair-factory",
    //售价选择类型 (批发价格)
    SALE_PRICE_TYPE_DISTRIBUTOR = "sale-price-type.distributor",
    //售价选择类型 (零售价格)
    SALE_PRICE_TYPE_SALE = "sale-price-type.sale",
    //售价选择类型 (进货价)
    SALE_PRICE_TYPE_PURCHASE = "sale-price-type.purchase",


    //组织机构
    ORG = "cm-organization",
    //门店
    SHOP = "cm-shop",
    //门店类型
    SHOP_TYPE = "shop-type",
    //经济实体
    BE = "cm-business-entity",

    //入库员
    STAFF = "stock-io-staff",
    //送货员
    DELIVERYMAN = "deliveryman",
    //检验员
    INSPECTOR = "inspector",
    //直发员
    ZHIFA = "stock-io-staff.zhifa",

    //仓库类型
    WAREHOUSE_TYPE = "warehouse-type",
    WAREHOUSE_TYPE_DIRECT = "999-zf",//直发
    WAREHOUSE_TYPE_LAUNCH = "tf", //投放

    //收款人
    PAYEE = "payee",

    //库存盘点状态
    STOCK_CHECK_STATUS = "stocktaking-status",
    //库存盘点 （已作废）
    STOCK_CHECK_STATUS_CANCEL = "stocktaking-status.cancel",
    //库存盘点 （已完成）
    STOCK_CHECK_STATUS_FINISH = "stocktaking-status.finish",
    //库存盘点 （进行中）
    STOCK_CHECK_STATUS_CREATE = "stocktaking-status.create",

    //物流方式
    LOGISTIC_MODE = "logistic-_mode",
    //物流费结账方式
    LOGISTIC_PAY = "logistics-pay",

    //单据类型
    TRADE_TYPE = "trade-type",
    //单据类型 (销售单)
    TRADE_TYPE_SO = "trade-type.sale",
    //单据类型 (销售订单)
    TRADE_TYPE_SORDER = "trade-type.sale-order",
    //单据类型 ( 销售退货）
    TRADE_TYPE_SRO = "trade-type.sale-return",
    //单据类型 (采购单)
    TRADE_TYPE_PO = "trade-type.purchase",
    //单据类型 (采购退货)
    TRADE_TYPE_PRO = "trade-type.purchase-return",
    //单据类型 (公交销售单)
    TRADE_TYPE_TO = "trade-type.transit-sale",
    // //单据类型 (公交销退单)
    TRADE_TYPE_TRO = "trade-type.transit-sreturn",

    //优惠类型
    PREFERENTIAL_TYPE = "preferential_type",
    //优惠类型 （返利）
    PREFERENTIAL_TYPE_REBATE = "preferential_type.rebate",
    //优惠类型 （投放）
    PREFERENTIAL_TYPE_MARKET = "preferential_type.market",
    //优惠类型 （折让）
    PREFERENTIAL_TYPE_DISCOUNT = "preferential_type.discount",
    //优惠类型 （赠品）
    PREFERENTIAL_TYPE_GIFT = "preferential_type.gift",
    //优惠类型（普通）
    PREFERENTIAL_TYPE_GENERAL = "preferential_type.general",
    //优惠类型（单赠）
    PREFERENTIAL_TYPE_LINK_GIFT = "preferential_type.link-gift",
    //优惠类型（买赠）
    PREFERENTIAL_TYPE_BUY_GIFT = "preferential_type.buy-gift",
    //优惠类型（另赠）
    PREFERENTIAL_TYPE_OTHER_GIFT = "preferential_type.other-gift",
    //优惠类型（服务油品）
    PREFERENTIAL_TYPE_SERVICE_OIL = "preferential_type.service-oil",
    //优惠类型（服务配件）
    PREFERENTIAL_TYPE_SERVICE_M = "preferential_type.service-m",
    //优惠类型（索赔）
    PREFERENTIAL_TYPE_CLAIM = "preferential_type.claim",
    //优惠类型（采退）
    // PREFERENTIAL_TYPE_PRETURN = "preferential_type.preturn",

    //账户类型 （配件返利）
    ACC_TYPE_REBATE = "account-type.rebate",
    //账户类型 （油品返利）
    ACC_TYPE_REBATE_OIL = "account-type.rebate-oil",
    //账户类型 （投放）
    ACC_TYPE_MARKET = "account-type.credit-market",
    //账户类型 （配件投放）
    ACC_TYPE_DELIVERY = "account-type.credit-delivery",
    //账户类型 (月结)
    ACC_TYPE_Month = "account-type.credit-month",
    //账户类型 (临时授信)
    ACC_TYPE_Credit = "account-type.credit",
    //账户类型 (现金账户)
    ACC_TYPE_CASH = "account-type.cash",
    //账户类型（结转账户）
    ACC_TYPE_JZ = "account-type.jz",
    //账户类型（备品四方）
    ACC_TYPE_SPARE_AGREEMENT = "account-type.spare-agreement",
    //账户类型（油品四方）
    ACC_TYPE_OILS_AGREEMENT = "account-type.oils-agreement",
    //账户类型（二比一奖励账户）
    ACC_TYPE_OILS_REWARD = "account-type.reward",
    //账户类型（加盟商返利账户）
    ACC_TYPE_OILS_FRANCHISEE = "account-type.franchisee",
    //结算类型 (挂账)
    SETTLE_TYPE_CREDIT = "settle-type.credit",
    //结算类型 （现结）
    SETTLE_TYPE_CASH = "settle-type.cash",
    //结算类型 （结转）
    SETTLE_TYPE_BALANCE = "settle-type.balance",


    //系统设置 （积压件提醒设置）
    OVERSTOCK_P = "overstock_purchase",

    //调拨状态
    TRANSFER_STATUS = "transfer-status",
    //调拨状态(待调出)
    TRANSFER_STATUS_INIT = "transfer-status.init",
    //调拨状态(待调入)
    TRANSFER_STATUS_OUT = "transfer-status.out",


    //调拨类型
    TRANSFER_TYPE = "transfer-type",
    //备品调拨
    TRANSFER_TYPE_BP = "transfer-type.bp",
    //其它调拨
    TRANSFER_TYPE_OTHERS = "transfer-type.others",

    //币种
    CURRENCY = "currency",
    //门店区域
    SHOP_AREA = "shop-area",
    //门店性质
    SHOP_NATURE = "shop-nature",
    //采退类型
    PRETURN_TYPE = "preturn-type",
    //采退类型 (不受限)
    PRETURN_TYPE_NOT_LIMIT = "preturn-type.not-limit",
    //采退类型 （普通）
    PRETURN_TYPE_GENERAL = "preturn-type.general",

    //品牌类型
    MATERIAL_BRAND_YIDUN = "material-brand.yidun", //伊顿
    MATERIAL_BRAND_YUCHAI = "material-brand.yuchai", //玉柴
    MATERIAL_BRAND_YAXING = "material-brand.yaxing", //亚星
    MATERIAL_BRAND_HONGYAN1 = "material-brand.hongyan1", //红岩
    MATERIAL_BRAND_HAIGE = "material-brand.haige", //海格
    MATERIAL_BRAND_OTHERS = "material-brand.others", //其他
    MATERIAL_BRAND_DAYUN = "material-brand.dayun", //大运
    MATERIAL_BRAND_WEICHAI = "material-brand.weichai", //潍柴
    MATERIAL_BRAND_SHACMAN = "material-brand.shacman", //陕汽
    MATERIAL_BRAND_GATES = "material-brand.gates", //盖茨
    MATERIAL_BRAND_FAST = "material-brand.fast", //法士特
    MATERIAL_BRAND_BEIBEN = "material-brand.beiben", //北奔
    MATERIAL_BRAND_QICHI = "material-brand.qichi", //綦齿
    MATERIAL_BRAND_WABCO = "material-brand.wabco", //威伯科
    MATERIAL_BRAND_HANDE = "material-brand.hande", //汉德
    MATERIAL_BRAND_SINOTRUNK_HEAVY = "material-brand.sinotruk-heavy", //重汽重卡
    MATERIAL_BRAND_SINOTRUNK_LIGHT = "material-brand.sinotruk-light", //重汽轻卡
    MATERIAL_BRAND_YUNHAO = "material-brand.yunhao", //云豪备品
    MATERIAL_BRAND_YONGHE = "material-brand.yonghe", //雍和
    MATERIAL_BRAND_WANAN = "material-brand.wanan", //万安
    MATERIAL_BRAND_DUNKMAN = "material-brand.dunkman", //敦克曼
    MATERIAL_BRAND_TAIHANG = "material-brand.taihang", //太行
    MATERIAL_BRAND_TOTAL = "material-brand.total", //道达尔
    MATERIAL_BRAND_SCHAEFFLER = "material-brand.schaeffler", //舍弗勒
    MATERIAL_BRAND_YUNHAO_BOUTIQUE = "material-brand.yunhao boutique", //云豪精品
    MATERIAL_BRAND_OUMAN = "material-brand.ouman"//欧曼

}