import { createModel } from 'hox';
import useStorageState from '../hooks/useStorageState';
import {
	customerService,
	IVRMCustomerDetail,
} from '@/views/base/customerFiles/customer/baseCustomerService';
import { IVRMSupplierDetail, supplierService } from '@/views/base/supplier/supplierService';
import { IVRMWarehouseDetail } from '@/views/base/warehouse/warehouseTypes';
import { IVehicleDetail } from '@/views/base/customerFiles/vehicle/vehicleType';
import { IPgProps, ISelectOptions } from '@/types/ScmTypes';
import { beService } from '@/views/organization/entity/entityService';
import { shopService } from '@/views/organization/shop/shopService';
import { sourceAppOrBranch } from '@/types/VrmSaDictEnums';
import { ITechnicianDetail } from '@/views/base/technician/technicianType';
import appService from '@/views/account/appService';
import { warehouseService } from '@/views/base/warehouse/warehouseService';
import { technicianService } from '@/views/base/technician/technicianService';
import { acctUnitService, ISCMAcctUnitDetail } from '@/views/base/acctUnit/acctUnitService';
import { useState } from 'react';

export type RoleType = {
	id: number;
	name: String;
};
export type UserType = {
	id: number;
	username: string;
	password: String;
	token: string;
	shopCode: string;
	loginType?: 'account' | 'token';
	[key: string]: any;
};
export type ShopAndOrgType = {
	shopId: string;
	shopName: string;
	shopCode: string;
	orgCode: String;
	orgName: string;
};
type GlobalModelType = {
	user: UserType;
	setUser: (e: UserType) => void;
	resetUser: () => void;
	toggleMenu: () => void;
	cm: CmType;
	resetCm: () => void;
	loadingInfo: LoadingType,
	setLoadingInfo: (origin: LoadingType) => void,

	beShopDs: ISelectOptions[];
	setBeShopDs: (
		value: ISelectOptions[] | ((previousState?: ISelectOptions[] | undefined) => ISelectOptions[])
	) => void;
	[key: string]: any;
	accUnitDs: ISCMAcctUnitDetail[];
	accUnitDataAll: () => void;
	setAccUnitDs: (origin: ISCMAcctUnitDetail[]) => void;
	fetchFmsShopList: (value: string) => Promise<ISelectOptions[]>;

	mdmBeDs: ISelectOptions[];
	mdmBeLoadDataAll: () => void;
	setMdmBeDs: (origin: ISelectOptions[]) => void;

	mdmShopDs: ISelectOptions[];
	mdmShopLoadDataAll: () => void;
	setMdmShopDs: (origin: ISelectOptions[]) => void;
};
export type LoadingType = { loadingVisible: boolean, txt: string };

const initUserState = {
	id: 0,
	username: '',
	password: '',
	token: '',
	shopCode: '',
};
const initShopAndOrg = {
	shopId: '',
	shopName: '',
	orgCode: '',
	orgName: '',
	shopCode: '',
};
type CmType = {
	collapsed: boolean;
};
const initCmState = {
	collapsed: false,
};
//返回列表韦options的格式
export const transOptions = (list: any[]) => {
	return list.length != 0
		? list.map((item) => ({
			label: item.name,
			value: item.code,
			disabled: item.enable === 0 ? true : false,
		}))
		: [];
};
export const getFindItem = (code: string, list: any[]) => {
	return list.find((item) => item.code === code);
};
//返回列表韦options的格式
export const vehicleOption = (list: any[]) => {
	return list.length != 0
		? list.map((item) => ({
			label: item.label,
			value: item.value,
			disabled: item.enable === 0 ? true : false,
		}))
		: [];
};
// 返回对应车辆牌照的车辆所有信息
export const getVehicleItem = (licensePlate: string, list: any[]) => {
	return list.find((item) => item.licensePlate === licensePlate);
};
function useModel(): GlobalModelType {
	const [user, setUser, resetUser] = useStorageState<UserType>('user', initUserState);
	const [shopAndOrg, setShopAndOrg, resetShopAndOrg] = useStorageState<ShopAndOrgType>(
		'shopAndOrgNews',
		initShopAndOrg
	);
	//客户数据
	const [CustomerData, setCustomerData, clearCustomerData] = useStorageState<IVRMCustomerDetail[]>(
		'customerList',
		[]
	);
	//供应商数据
	const [supplieAllList, setsupplieAllList, resetsupplieAllList] = useStorageState<
		IVRMSupplierDetail[]
	>('supplieAllList', []);
	//仓库数据
	const [warehouseList, setWarehouseList, resetWarehouseList] = useStorageState<
		IVRMWarehouseDetail[]
	>('warehouseList', []);
	//车辆数据
	const [vehicleData, setVehicleData, clearVehicleData] = useStorageState<IVehicleDetail[]>(
		'vehicleList',
		[]
	);

	const [cm, setCm, resetCm] = useStorageState<CmType>('cm', initCmState);
	//主数据下所有经营实体
	const [mdmBeDs, setMdmBeDs] = useStorageState<ISelectOptions[]>('mdm.beDs', []);
	// 主数据所有门店
	const [mdmShopDs, setMdmShopDs] = useStorageState<ISelectOptions[]>('mdm.shopDs', []);
	//所有门店
	const [shopDs, setShopDs] = useStorageState<ISelectOptions[]>('vrm.shopDs', []);
	//所有经营实体
	const [beDs, setBeDs] = useStorageState<ISelectOptions[]>('vrm.beDs', []);
	//所有内部核算单位
	const [accUnitDs, setAccUnitDs] = useStorageState<ISCMAcctUnitDetail[]>('bcm.acctUnit', []);
	//对应门店的经营实体
	// const [beShopDs, setBeShopDs] = useStorageState<ISelectOptions[]>("vrm.beShopDs", [{ label: "上海欧豪汽车贸易有限公司", value: "bentity.sh-ouhao" }, { label: "天津綦齿", value: "bentity.tj-qichi" }]);
	const [beShopDs, setBeShopDs] = useStorageState<ISelectOptions[]>('vrm.beShopDs', []);
	//所有App系统分支
	const [appDs, setAppDs] = useStorageState<ISelectOptions[]>('fms.appDs', []);
	//用户所有菜单权限
	const [menuAuth, setMenuAuth] = useStorageState<string[]>('user.menus', []);
	//用户所有资源权限
	const [resourceAuth, setResourceAuth] = useStorageState<string[]>('user.resource', []);
	//维修技师数据
	const [technicianData, setTechniciankData] = useStorageState<ITechnicianDetail[]>(
		'technicianList',
		[]
	);
	const [whDs, setwhDs] = useStorageState<ISelectOptions[]>("bcm.waerhouseDs", []);
	const [fmsShopDs, setFmsShopDs] = useStorageState<any[]>("bcm.fmsShopDs", []);
	const [loadingInfo, setLoadingInfo] = useState<LoadingType>({ loadingVisible: false, txt: "" });

	//初始页数
	const initPage = { pageSize: 999999, pageNum: 1 };

	//门店缓存
	const shopLoadDataAll = async () => {
		// let { retData } = await shopService.page({ page: initPage, sourceApp: sourceAppOrBranch.sourceApp });
		// const { records } = retData;
		// let shopOptions = records.map(item => ({
		//     key: item.id,
		//     label: item.name,
		//     value: item.id,
		//     code: item.code
		// }));
		// setShopDs(shopOptions);
	};
	//初始经营实体缓存
	const beLoadDataAll = async () => {
		const searchParams = {
			// ...defaultMdCmParam,
			// branch: "",
			sourceApp: 'vrm',
			page: {
				pageSize: 999999,
				pageNum: 1,
			},
		};
		let { retData } = await beService.page(searchParams);
		const { records } = retData;
		setBeDs(
			records.map((item) => ({
				id: item.key as string,
				label: item.name ?? '',
				value: item.code ?? '',
			}))
		);
	};
	//初始应用分支缓存
	const appLoadDataAll = async () => {
		let { retData } = await appService.find({ sourceApp: sourceAppOrBranch.sourceApp });
		let appOptios = retData.map((item) => ({
			label: item.name ?? '',
			value: item.code ?? '',
		}));
		setAppDs(appOptios);
	};

	const toggleMenu = () => {
		setCm({
			...cm,
			collapsed: !cm.collapsed,
		});
	};
	//初始所有仓库
	const loadWarehouseAllData = async (shopId: string) => {

		const params = {
			pageSize: 10000,
			pageNum: 1,
			shopId,
		};
		let { retData } = await warehouseService.page(params);
		const { records, ...pg } = retData;
		const newRecords = records.filter((list:any)=>list.enable==1).map((item) => ({
			...item,
			// code: item.id,
			// codes: item.code
		}));
		setWarehouseList(newRecords);
		setwhDs(records.map(item => ({
			id: item.id as string,
			label: item.name as string,
			value: item.code as string,
		})));
		
	};
	//初始所有客户
	const loadCustomerAllData = async (shopId: string) => {
		const params = {
			shopId,
			...sourceAppOrBranch,
			pageSize: 100000,
			pageNum: 1,
		};
		let { retData } = await customerService.totalPage(params);
		setCustomerData(retData);
	};
	//初始所有供应商
	const loadSupplierAllData = async (shopId: string) => {
		const params = {
			pageSize: 10000,
			pageNum: 1,
			shopId,
		};
		let { retData } = await supplierService.page(params);
		const { records, ...pg } = retData;
		setsupplieAllList(records);
	};
	//初始所有维修技师
	const loadTechnician = async (shopId: string) => {
		const params = {
			shopId,
			...sourceAppOrBranch,
			pageSize: 10000,
			pageNum: 1,
		};
		let { retData } = await technicianService.page(params);
		const { records, ...pg } = retData;
		let newRecords = records.map((item) => {
			return {
				...item,
				code: item.technicianCode,
			};
		});
		setTechniciankData(newRecords);
	};
	//实时查询财务的门店
	const fetchFmsShopList = async (value: string) => {
		// if (!value) return await fn();
		let data = { page: { pageSize: -1, pageNum: 1 }, sourceApp: 'fms', name: value ,status:0};
		return shopService.pageFms(data).then((res) => {
			const {
				retData: { records },
			} = res;
			return records
				? records.map((item) => ({
					key: item.id,
					id: item.id,
					label: item.name,
					value: item.code,
					branch: item.branch,
				}))
				: [];
		});
	};

	// 缓存财务门店
	const fmsShopList = async () => {
		// if (!value) return await fn();
		let data = { page: { pageSize: -1, pageNum: 1 }, sourceApp: 'fms',status:0 };
		return shopService.pageFms(data).then((res) => {
			const {
				retData: { records },
			} = res;
			setFmsShopDs(records)
		});
	};

	//所有内部核算单位接口
	const accUnitDataAll = async () => {
		const searchParams = {
			shopId: user.shopId,
			pageSize: -1,
			pageNum: 1,
		};
		let { retData } = await acctUnitService.page(searchParams);
		const { records, ...pg } = retData;
		setAccUnitDs(records);
	};

	//初始主数据所有经营实体缓存
	const mdmBeLoadDataAll = async () => {
		const searchParams = {
			// ...defaultMdCmParam,
			// branch: "",
			sourceApp: 'fms',
			page: {
				pageSize: 999999,
				pageNum: 1,
			},
		};
		let { retData } = await beService.pageFms(searchParams);
		const { records } = retData;
		setMdmBeDs(
			records.map((item) => ({
				id: item.key as string,
				label: item.name ?? '',
				value: item.code ?? '',
			}))
		);
	};

	// 主数据门店缓存
	const mdmShopLoadDataAll = async () => {
		let data = { sourceApp: 'fms', page: { pageSize: 9999, pageNum: 1 } ,status:0};
		const {
			retData: { records },
		} = await shopService.fmsPage(data);
		const shopOptions = records.map((item) => ({
			key: item.id,
			id: item.id,
			label: item.name,
			value: item.code,
			branch: item.branch,
		}));
		setMdmShopDs(shopOptions);
	};
	//所有缓存加载与重置
	const requestCatchAll = (shopId: string = shopAndOrg.shopId) => {
		loadWarehouseAllData(shopId);
		loadCustomerAllData(shopId);
		loadSupplierAllData(shopId);
		loadTechnician(shopId);
		appLoadDataAll();
		beLoadDataAll();
		accUnitDataAll();
		fmsShopList()
	};
	return {
		user,
		setUser,
		resetUser,
		toggleMenu,
		cm,
		whDs,
		resetCm,
		shopAndOrg,
		setShopAndOrg,
		resetShopAndOrg,
		CustomerData,
		setCustomerData,
		clearCustomerData,
		supplieAllList,
		setsupplieAllList,
		resetsupplieAllList,
		warehouseList,
		setWarehouseList,
		resetWarehouseList,
		vehicleData,
		setVehicleData,
		clearVehicleData,
		beLoadDataAll,
		beDs,
		setBeDs,
		beShopDs,
		setBeShopDs,
		shopLoadDataAll,
		technicianData,
		setTechniciankData,
		menuAuth,
		setMenuAuth,
		resourceAuth,
		setResourceAuth,
		shopDs,
		setShopDs,
		appDs,
		setAppDs,
		appLoadDataAll,
		loadWarehouseAllData,
		loadCustomerAllData,
		loadSupplierAllData,
		loadTechnician,
		requestCatchAll,
		accUnitDataAll,
		fetchFmsShopList,
		accUnitDs,
		setAccUnitDs,
		mdmBeLoadDataAll,
		mdmBeDs,
		setMdmBeDs,
		mdmShopDs,
		setMdmShopDs,
		mdmShopLoadDataAll,
		loadingInfo, setLoadingInfo,
		fmsShopDs
	};
}

export const useGlobalModel = createModel(useModel);
export default useGlobalModel;
