import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";

export function BaseSupplier() {
    return (
        <>
            <PageHeader />
            <DataBox />
        </>
    );
}

