import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import { Key } from "react";

/***************************fms************************* */
interface ITreeDs<T> {
    enable?: number;
    code?: string;
    children?: T[]
}

export interface ITreeData<T> {
    key: Key;
    title?: string;
    children?: ITreeData<T>[] | undefined;
}

export interface IFieldValue<T> {
    [key: string]: T[keyof T]
}


export function trasDsToTreeData<T extends ITreeDs<T>>(
    ds: T[],
    keyField: keyof T,
    titleField: keyof T,
): ITreeData<T>[] {
    return ds.map(el => {
        const key = el[keyField] as any;
        const title = el[titleField] as any;
        const children = el.children && el.children.length > 0 ? trasDsToTreeData(el.children, keyField, titleField) : undefined;
        const disabled = el.enable === 0;
        return {
            el,
            key,
            title,
            disabled,
            value: el["code"],
            selectable: !disabled,
            children,
        }
    });
}
/*********************************************************************************** */
export interface IVRM2DictSearch {
    page: {
        pageSize: number;
        pageNum: number;
    },
    sourceApp?: string;
    branch?: string

}
export interface IVRM2DictSearchs {

    pageSize: number;
    pageNum: number;
    sourceApp?: string;
    branch?: string
    shopCode?: string
}
export interface IVRM2DictDetail {
    // note: string,
    // ver: number,
    // code: string,
    // sourceAuthKey: string,
    // pcode: string | null,
    // children: IVRM2DictDetail[],
    // enable: number,
    // name: string,
    // branch: string,
    // value: string,
    // key: string,

    branch: string,
    branchList: any | null,
    ver: number,
    enable: number,
    dictType: null,
    code: string,
    name: string,
    nameEn: string | null,
    value: string,
    pcode: string | null,
    note: string,
    status: number,
    children: IVRM2DictDetail[],
    shopCode?: string | any,
    key: string
}

export interface IVRM2DictInsert {
    sourceApp: string,
    branch?: string,
    code: string,
    name: string,
    value?: string,
    pcode?: string | null,
    note?: string,
}
export interface IVRM2ShopDictInsert {
    sourceApp: string,
    branch?: string,
    code: string,
    name: string,
    value?: string,
    pcode?: string | null,
    note?: string,
    shopCode?: string
}
export interface IVRM2ShopDictUpdate {
    sourceApp: string,
    branch?: string,
    code: string,
    value?: string,
    shopCode?: string,
    key?:string,
    // id?:string,
}
export interface IVRM2DictUpdate {
    sourceApp: string,
    branch?: string,
    code: string,
    value?: string,
}

export interface IVRM2DictOne {
    sourceApp: string,
    branch?: string,
    code: string
}

class DictService {
    //树列表
    async page(searchVo: IVRM2DictSearch) {
        const apiUrl = "/bcm/base/dict/page";
        return await httpRequest.post<IPage<IVRM2DictDetail>>(apiUrl, searchVo)
    }
    //门店树列表
    async pages(searchVo: IVRM2DictSearchs) {
        const apiUrl = "/bcm/base/dict/pages";
        return await httpRequest.post<IPage<IVRM2DictDetail>>(apiUrl, searchVo)
    }
    //门店新增
    async shopInsert(insertVo: IVRM2ShopDictInsert) {
        const apiUrl = "/bcm/base/dict/inserts";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //门店编辑
    async shopUpdate(updateVo: IVRM2ShopDictUpdate) {
        const apiUrl = "/bcm/base/dict/updates";
        return await httpRequest.post<boolean>(apiUrl, updateVo)
    }
    //新增
    async insert(insertVo: IVRM2DictInsert) {
        const apiUrl = "/bcm/base/dict/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //编辑
    async update(updateVo: IVRM2DictUpdate) {
        const apiUrl = "/bcm/base/dict/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo)
    }
    //新增
    async insertLocal(insertVo: object) {
        const apiUrl = "/bcm/base/dict/insertLocal";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //编辑
    async updateLocal(updateVo: object) {
        const apiUrl = "/bcm/base/dict/updateLocal";
        return await httpRequest.post<boolean>(apiUrl, updateVo)
    }
    //获取一条
    async one(oneVo: IVRM2DictOne) {
        const apiUrl = "/bcm/base/dict/one";
        return await httpRequest.post<IVRM2DictDetail>(apiUrl, oneVo)
    }

    async disable(oneVo: IVRM2DictOne) {
        const apiUrl = "/bcm/base/dict/disable";
        return await httpRequest.post<boolean>(apiUrl, oneVo)
    }

    async remove(oneVo: IVRM2DictOne) {
        const apiUrl = "/bcm/base/dict/remove";
        return await httpRequest.post<boolean>(apiUrl, oneVo)
    }
}

export const dictService = new DictService();
