import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, message } from 'antd';
import { numberParseChina, formatNum } from "@/utils/utils";
import { useSummaryModel } from '../summaryModel';
// import { maintenanceService } from "../maintenanceService"
import { IMaterialDetailList, IItemDetailList, IMaintenanceDetail } from '../../../maintenance/maintenanceType';
import { SettlePrint } from "@/components/settlePrint"
import moment from 'moment';
import { maintenanceService } from '../../../maintenance/maintenanceService';
export const Print = () => {
    const {
        print: visible,
        selectedRow,
        setPrint,
    } = useSummaryModel();
    const [formVal, setFormVal] = useState<IMaintenanceDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IMaterialDetailList[]>([]);
    const [itemList, setItemList] = useState<IItemDetailList[]>([]);
    useEffect(() => {
        visible && getDetailOne()
    }, [visible])
    const getDetailOne = async () => {
        let { retData } = await maintenanceService.one(selectedRow.maintenanceCode as string);
        let { itemDetails, materialDetails, receivableTotalAmount, enterTime, ...from } = retData
        receivableTotalAmount = formatNum(receivableTotalAmount)
        const newItemDetails = itemDetails?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        const newMaterialDetails = materialDetails?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        if (retData) {
            setMaintenanceList(newMaterialDetails as IMaterialDetailList[] ?? []);
            setItemList(newItemDetails as IItemDetailList[] ?? []);
            setFormVal({ ...from, receivableTotalAmount, receivableTotalAmountCN: numberParseChina(receivableTotalAmount), enterTime: moment(enterTime).format("YYYY-MM-DD HH:mm") })
        }
    }
    const onClose = () => {
        setPrint(false)
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '工时',
            dataIndex: 'workHour',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '工时费',
            dataIndex: 'itemAmount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '材料编号',
            dataIndex: 'materialCode',
        },
        {
            title: '材料名称',
            dataIndex: 'materialName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '金额',
            dataIndex: 'materialAmount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    let IHeader = [
        {
            fieldLabel: "工号：",
            fieldValue: formVal.maintenanceCode
        }, {
            fieldLabel: "支付方式：",
            fieldValue: formVal.paymentMethodName
        }, {
            fieldLabel: "打印日期：",
            fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm")
        },
    ]
    return (
        <SettlePrint
            // title="天津欧豪富港汽车贸易有限公司修理结算单"
            title={`${formVal.bentityName}修理结算单`}
            visible={visible}
            onClose={onClose}
            columns={columns}
            itemClumns={itemClumns}
            itemDataSource={itemList}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printHeader={formVal}
            printFooter={formVal}
            IHeader={IHeader}
        />

    )
}