import { useState } from 'react'
import { createModel } from 'hox'
import { ISearchPage } from '@/types/ScmTypes'
import { IExportRecordSearchFields, IExportRecordDetail, IExportRecordSearch } from './exportRecordTypes'
import { TablePaginationConfig } from 'antd/es/table/interface'
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants'
import { exportRecordService } from './exportRecordService'
import {transPgToPagination} from "@/utils/utils";

export const useExportRecordModel = createModel(function() {
    //查询条件
    const [searchParam, setSearchParam] = useState<IExportRecordSearchFields>({})

    //列表数据源
    const [dataSource, setDataSource] = useState<IExportRecordDetail[]>([])

    //列表数据源分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg)
    const [loading, setLoading] = useState<boolean>(false)
    const updateSearchParam = (updateParam: IExportRecordSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置
    const resetSearchParam = () => {
        setSearchParam({})
        loadData(initPgCfg, {})
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IExportRecordSearchFields = searchParam) => {
        setLoading(true)
        const params: IExportRecordSearch = {
            searchItem: { ...searchValue },
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            // 固定排序
            orders: [{ column: 'createTime', rule: 'DESC' }]
        }
        let { retData } = await exportRecordService.page(params)
        const { records, ...pg } = retData
        setPgCfg(transPgToPagination(pg))
        setDataSource(records)
        setLoading(false)
    }

    return {
        /**
         * field
         */
        searchParam,
        dataSource,
        pgCfg,
        loading,

        /**
         * method
         */
        setDataSource,
        updateSearchParam,
        resetSearchParam,
        loadData
    }
})
