import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home } from "../views/home/Home";

import SaleOrder from "../views/sale/order/view";
import { SaleOrderDetails } from "../views/sale/order/view/details";
import SaleSo from "../views/sale/so/view";
import { SaleSoDetails } from "../views/sale/so/view/details";
import SaleRo from "../views/sale/ro/view";
import { SaleRoDetails } from "../views/sale/ro/view/details";
import { SaleAllot } from "../views/sale/allot/view"
import { SaleAllotDetails } from "../views/sale/allot/view/details"



import Error404 from "../views/404";

//字典
import BaseGoods from "../views/base/goods/view";
import { BaseGoodsDetails } from "../views/base/goods/view/DetailsBox";
import { BaseWarehouse } from "../views/base/warehouse/view";
import { WarehouseDatalis } from "../views/base/warehouse/view/editDetails"
import { BaseSupplier } from "../views/base/supplier/view";
import { BaseSupplierDetails } from "../views/base/supplier/view/DetailsBox";
// import { BaseCustomer } from "../views/base/customer/view";
import { BaseCustomer } from "../views/base/customerFiles/customer/view";
import { BaseCustomerDetails } from "../views/base/customerFiles/customer/view/DetailsBox";

import BaseVehicle from "../views/base/customerFiles/vehicle/view";
import { BaseVehicleDetails } from "../views/base/customerFiles/vehicle/view/DetailsBox";
//维修项目
import BaseItem from "../views/base/item/view";
import { BaseItemDetails } from "../views/base/item/view/DetailsBox";
// 维修技师
import TechnicianItem from "../views/base/technician/view";
// 内部核算单位
import { BaseAcctUnit } from "../views/base/acctUnit/view"
import { BaseAcctUnitDetails } from "../views/base/acctUnit/view/DetailsBox"

import { BaseDict } from "../views/base/dict/view";

import Material from "../views/base/material/view"
import { MaterialDetails } from "../views/base/material/view/DetailsBox"
//采购单
//采购计划
import PurchasePlan from "../views/purchase/plan/view"
import { PurchasePlanDetails } from "../views/purchase/plan/view/details"
import PurchasePo from "../views/purchase/po/view";
import { PoDetails } from "../views/purchase/po/view/details";
import PurchaseRo from "../views/purchase/ro/view";
import { PurchaseRoDetails } from "../views/purchase/ro/view/details";
import PurchaseDr from "../views/purchase/dr/view"
import { PurchaseDrDetails } from "../views/purchase/dr/view/details"
//预约单
import Appointment from "../views/appointment/view"
import { AppointmentDetails } from "../views/appointment/view/details"
//工单管理
import Maintenance from "../views/maintenance/view"
import { MaintenanceDetails } from "../views/maintenance/view/details"
// 机构管理
// 机构管理
import OrganizationOrg from "../views/organization/org/view"
//门店管理
import { OrganizaShop } from "../views/organization/shop/view"
//经营实体
import BaseEntity from "../views/organization/entity/view"
//车间派工
// 派工列表
import WorkshopQuery from "../views/workshop/workshopQuery/view"
//历史工单
import HistoryQuery from "../views/workshop/historyQuery/view"
//结算管理
// 结算列表
import Summary from "../views/statements/summary/view"
import { SummaryeDetails } from "../views/statements/summary/view/details"
// 客户结算
import SummaryCustomer from "../views/statements/customer/view"
import { SummaryeCustomerDetails } from "../views/statements/customer/view/details"
//厂家结算
import Factory from "../views/statements/factory/view"
import { FactoryDetails } from "../views/statements/factory/view/details"
//客户回访
import FllowUp from "../views/followUp/view"
import { FollowUpDetails } from "../views/followUp/view/details"
//库房管理
//库存统计
import StockStatistic from "../views/stock/statistic/view";
// 调拨管理
import StockAlloc from "../views/stock/alloc/view"
import InventoryStatistics from "../views/stock/inventoryStatistics/view";

//维修出库
import Delivery from "../views/stock/delivery/view"
import { DeliveryDetails } from "../views/stock/delivery/view/details/index"
//出库单据
import StockOutput from "../views/stock/output/view";
import { StockOutputModelDetails } from "../views/stock/output/view/details";
//入库单据
import StockEntry from "../views/stock/entry/view";
import { StockEntryModelDetails } from "../views/stock/entry/view/details";
//库存盘点
import StockCheck from "../views/stock/check/view";
import { StockCheckDetails } from "../views/stock/check/view/details";

//维修借件
import Borrow from "../views/stock/borrow/view"
import { StockBorrowDetails } from "../views/stock/borrow/view/details"
//账户管理
//客户账户
import { AccountCustomer } from "../views/account/customerManage/view/index"
import { CustomerAccountDetail as CustomerAccountDetailManage } from "../views/account/customerManage/view/detail";
//供应商账户
import { AccountSupplier } from "../views/account/supplierManage/view/index"
import { SupplierAccountDetail as SupplierAccountDetailManage } from "../views/account/supplierManage/view/detail";

//内部对账单
import AccountInternal from "../views/account/internal/view"
import { InternalDetails } from "../views/account/internal/view/detail/index"

//首页结算
import homeSettlement from "../views/home/details/settlement"
import homeDelivery from "../views/home/details/delivery"
import homeWarehousing from "../views/home/details/Warehousing"

//公交业务
//预售销售
import BusBusinessPreSale from "@/views/busBusiness/view/summary"
import { BusBusinessDetail as BusBusinessDetailManage } from "@/views/busBusiness/view/detail"
import { PreSaleDetails } from "@/views/busBusiness/view/detail/presale/detail"
// 销售记录
import { SaleDetails } from "@/views/busBusiness/view/detail/sale/detail"
//调配记录
import { DeploymentDetails } from "@/views/busBusiness/view/detail/deployment/detail"
//销退记录
import { SaleReturnDetails } from "@/views/busBusiness/view/detail/salesReturn/detail"
//预退记录
import { PreWithdrawalDetails } from "@/views/busBusiness/view/detail/preWithdrawal/detail"
//预售销售总列表
import { PreSaleAll } from "@/views/busBusiness/preSaleAll/index"
import { SaleAll } from "@/views/busBusiness/saleAll/index"
//调配总列表
import { DeploymentAll } from "@/views/busBusiness/deployAll/index";
//预退总列表
import { PreWithdrawalAll } from "@/views/busBusiness/preWithdrawalAll/index";
//销退总列表
import { SaleReturnAll } from "@/views/busBusiness/saleReturnAll/index";
//公交库存明细
import DetailedInventory from "@/views/busBusiness/detailedInventory/view";

//统计分析
import StatistReportForm from "@/views/statistics/reportForm/view";
import InventorySituation from "../views/statistics/inventorySituation/view";
//销售报表
import SalesReort from "../views/statistics/salesReort/view";
//应收报表
import Receivables from "../views/statistics/receivables/view";
// 出入库明细
import StockInAndOut from "../views/statistics/stockInAndOut/view";
//系统设置
import Setting from "@/views/settings/view";
//库存管理 采购明细
import StockPoDetails from "../views/stock/purchaseDetails/view"
//销售单明细报表
import SoReport from "../views/statistics/So-report/view";
//销售单报表
import SoList from "../views/statistics/So-list/view";
//公交销售明细报表
import BusReport from "../views/statistics/Bus-report/view";
//公交销售报表
import BusList from "../views/statistics/Bus-list/view";
//采购单报表
import PoList from "../views/statistics/Po-list/view";
//采购单明细报表
import PoReport from "../views/statistics/Po-report/view";
import PsiReport from "../views/statistics/PSI-report/view";
import PsibusReport from "../views/statistics/PSIbusiness/view"
import Monitoring from "../views/statistics/monitoring/view";
import BillMonitor from "../views/statistics/bill-monitor/view";
import TransferMonitor from "../views/statistics/transfer-monitor/view";
import TransferDataMonitor from "../views/statistics/transfer-data-monitor/view";

// 导出记录
import ExportRecord from "@/views/exportrecord";
function VrmRoute() {
    return (
        <Switch>
            <Route exact={true} path={"/"} key="1" component={Home} />
            <Route exact={true} path={"/home/settlement"} key="1-1" component={homeSettlement} />
            <Route exact={true} path={"/home/Warehousing"} key="1-2" component={homeWarehousing} />
            <Route exact={true} path={"/home/delivery"} key="1-3" component={homeDelivery} />


            {/* 基础数据 */}
            <Route exact={true} path={"/base/supplier"} key="13-1" component={BaseSupplier} />
            <Route exact={true} path={"/base/supplier/add"} key="13-1-1" component={BaseSupplierDetails} />
            <Route exact={true} path={"/base/supplier/edit"} key="13-1-2" component={BaseSupplierDetails} />
            <Route exact={true} path={"/base/supplier/look"} key="13-1-3" component={BaseSupplierDetails} />

            {/* 配件管理 */}
            <Route exact={true} path={"/base/material"} key="13-2" component={Material} />
            <Route exact={true} path={"/base/material/add"} key="13-2-1" component={MaterialDetails} />
            <Route exact={true} path={"/base/material/edit"} key="13-2-2" component={MaterialDetails} />
            <Route exact={true} path={"/base/material/look"} key="13-2-3" component={MaterialDetails} />
            {/* 仓库 */}
            <Route exact={true} path={"/base/warehouse"} key="13-3" component={BaseWarehouse} />
            <Route exact={true} path={"/base/warehouse/details"} key="13-3-1" component={WarehouseDatalis} />
            {/* 客户 */}
            <Route exact={true} path={"/base/customerFiles/customer"} key="13-5-1" component={BaseCustomer} />
            <Route exact={true} path={"/base/customerFiles/customer/add"} key="13-5-1-1" component={BaseCustomerDetails} />
            <Route exact={true} path={"/base/customerFiles/customer/edit"} key="13-5-1-2" component={BaseCustomerDetails} />
            <Route exact={true} path={"/base/customerFiles/customer/look"} key="13-5-1-3" component={BaseCustomerDetails} />

            {/* 车辆 */}
            <Route exact={true} path={"/base/customerFiles/vehicle"} key="13-5-2" component={BaseVehicle} />
            <Route exact={true} path={"/base/customerFiles/vehicle/add"} key="13-5-2-1" component={BaseVehicleDetails} />
            <Route exact={true} path={"/base/customerFiles/vehicle/edit"} key="13-5-2-2" component={BaseVehicleDetails} />
            <Route exact={true} path={"/base/customerFiles/vehicle/look"} key="13-5-2-3" component={BaseVehicleDetails} />

            {/* 维修形目 */}
            <Route exact={true} path={"/base/item"} key="13-6" component={BaseItem} />
            <Route exact={true} path={"/base/item/add"} key="13-6-1" component={BaseItemDetails} />
            <Route exact={true} path={"/base/item/edit"} key="13-6-2" component={BaseItemDetails} />
            <Route exact={true} path={"/base/item/look"} key="13-6-3" component={BaseItemDetails} />

            {/* 维修技师 */}
            <Route exact={true} path={"/base/technician"} key="13-6" component={TechnicianItem} />

            {/* 内部核算单位 */}
            <Route exact={true} path={"/base/acctUnit"} key="13-8" component={BaseAcctUnit} />
            <Route exact={true} path={"/base/acctUnit/add"} key="13-8-1" component={BaseAcctUnitDetails} />
            <Route exact={true} path={"/base/acctUnit/edit"} key="13-8-2" component={BaseAcctUnitDetails} />

            {/* 数据字典 */}
            <Route exact={true} path={"/base/dict"} key="13-4" component={BaseDict} />
            {/* 采购计划 */}
            <Route exact={true} path={"/purchase/plan"} key="11-1" component={PurchasePlan} />
            <Route exact={true} path={"/purchase/plan/add"} key="11-1-1" component={PurchasePlanDetails} />
            <Route exact={true} path={"/purchase/plan/edit"} key="11-1-2" component={PurchasePlanDetails} />
            <Route exact={true} path={"/purchase/plan/look"} key="11-1-3" component={PurchasePlanDetails} />
            {/* 采购单 */}
            <Route exact={true} path={"/purchase/po"} key="11-2" component={PurchasePo} />
            <Route exact={true} path={"/purchase/po/add"} key="11-2-1" component={PoDetails} />
            <Route exact={true} path={"/purchase/po/edit"} key="11-2-2" component={PoDetails} />
            <Route exact={true} path={"/purchase/po/look"} key="11-2-3" component={PoDetails} />
            {/* 采购退货 */}
            <Route exact={true} path={"/purchase/ro"} key="11-3" component={PurchaseRo} />
            <Route exact={true} path={"/purchase/ro/add"} key="11-3-1" component={PurchaseRoDetails} />
            <Route exact={true} path={"/purchase/ro/edit"} key="11-3-2" component={PurchaseRoDetails} />
            <Route exact={true} path={"/purchase/ro/look"} key="11-3-3" component={PurchaseRoDetails} />

            <Route exact={true} path={"/purchase/dr"} key="11-4" component={PurchaseDr} />
            <Route exact={true} path={"/purchase/dr/add"} key="11-4-1" component={PurchaseDrDetails} />
            <Route exact={true} path={"/purchase/dr/edit"} key="11-4-2" component={PurchaseDrDetails} />

            {/* 销售管理 */}
            {/* 销售计划 */}
            <Route exact={true} path={"/sale/order"} key="5-1" component={SaleOrder} />
            <Route exact={true} path={"/sale/order/add"} key="5-1-1" component={SaleOrderDetails} />
            <Route exact={true} path={"/sale/order/edit"} key="5-1-2" component={SaleOrderDetails} />
            <Route exact={true} path={"/sale/order/look"} key="5-1-3" component={SaleOrderDetails} />

            <Route exact={true} path={"/sale/so"} key="5-2" component={SaleSo} />
            <Route exact={true} path={"/sale/so/add"} key="5-2-1" component={SaleSoDetails} />
            <Route exact={true} path={"/sale/so/edit"} key="5-2-2" component={SaleSoDetails} />
            <Route exact={true} path={"/sale/so/look"} key="5-2-3" component={SaleSoDetails} />

            <Route exact={true} path={"/sale/ro"} key="5-3" component={SaleRo} />
            <Route exact={true} path={"/sale/ro/add"} key="5-3-1" component={SaleRoDetails} />
            <Route exact={true} path={"/sale/ro/edit"} key="5-3-2" component={SaleRoDetails} />
            <Route exact={true} path={"/sale/ro/look"} key="5-3-3" component={SaleRoDetails} />

            <Route exact={true} path={"/sale/allot"} key="5-4" component={SaleAllot} />
            <Route exact={true} path={"/sale/allot/add"} key="5-4-1" component={SaleAllotDetails} />
            <Route exact={true} path={"/sale/allot/edit"} key="5-4-2" component={SaleAllotDetails} />
            {/* 预约单 */}
            <Route exact={true} path={"/appointment"} key="3" component={Appointment} />
            <Route exact={true} path={"/appointment/add"} key="3-1" component={AppointmentDetails} />
            <Route exact={true} path={"/appointment/edit"} key="3-2" component={AppointmentDetails} />
            <Route exact={true} path={"/appointment/look"} key="3-3" component={AppointmentDetails} />

            {/* 机构管理 */}
            <Route exact={true} path={"/organization/org"} key="14-1" component={OrganizationOrg} />
            <Route exact={true} path={"/organization/shop"} key="14-2" component={OrganizaShop} />
            {/* 经营实体 */}
            <Route exact={true} path={"/organization/entity"} key="14-3" component={BaseEntity} />
            {/* 维修工单 */}
            <Route exact={true} path={"/maintenance"} key="4" component={Maintenance} />
            <Route exact={true} path={"/maintenance/add"} key="4-1" component={MaintenanceDetails} />
            <Route exact={true} path={"/maintenance/edit"} key="4-2" component={MaintenanceDetails} />
            <Route exact={true} path={"/maintenance/look"} key="4-3" component={MaintenanceDetails} />
            {/* 车间派工 */}
            {/* 派工列表 */}
            <Route exact={true} path={"/workshop/workshopQuery"} key="6-1" component={WorkshopQuery} />
            {/* 历史列表 HistoryQuery*/}
            <Route exact={true} path={"/workshop/historyQuery"} key="6-2" component={HistoryQuery} />


            {/* 结算管理 */}
            {/* 结算列表 */}
            <Route exact={true} path={"/statements/summary"} key="7-1" component={Summary} />
            <Route exact={true} path={"/statements/summary/edit"} key="7-1-2" component={SummaryeDetails} />
            <Route exact={true} path={"/statements/summary/look"} key="7-1-3" component={SummaryeDetails} />
            {/* 客户结算 */}
            <Route exact={true} path={"/statements/customer"} key="7-2" component={SummaryCustomer} />
            <Route exact={true} path={"/statements/customer/look"} key="7-2-3" component={SummaryeCustomerDetails} />
            {/* 厂家结算 */}
            <Route exact={true} path={"/statements/factory"} key="7-3" component={Factory} />
            <Route exact={true} path={"/statements/factory/edit"} key="7-3-2" component={FactoryDetails} />
            <Route exact={true} path={"/statements/factory/look"} key="7-3-3" component={FactoryDetails} />
            {/* 客户回访 */}
            <Route exact={true} path={"/followUp"} key="8" component={FllowUp} />
            <Route exact={true} path={"/followUp/edit"} key="8-2" component={FollowUpDetails} />
            <Route exact={true} path={"/followUp/look"} key="8-3" component={FollowUpDetails} />
            {/* 仓库管理 */}
            {/* 维修出库 */}
            <Route exact={true} path={"/stock/delivery"} key="9-3" component={Delivery} />
            <Route exact={true} path={"/stock/delivery/edit"} key="9-3-2" component={DeliveryDetails} />
            <Route exact={true} path={"/stock/delivery/look"} key="9-3-3" component={DeliveryDetails} />
            {/* 库存统计 */}
            <Route exact={true} path={"/stock/statistic"} key="9-1" component={StockStatistic} />

            {/* 调拨管理*/}
            <Route exact={true} path={"/stock/alloc"} key="9-7" component={StockAlloc} />
             {/* 销存率统计 */}
            <Route exact={true} path={"/stock/inventoryStatistics"} key="4-10" component={InventoryStatistics} />
            {/* 出库单据 */}
            <Route exact={true} path={"/stock/output"} key="9-5" component={StockOutput} />
            <Route exact={true} path={"/stock/output/look"} key="9-5-3" component={StockOutputModelDetails} />
            {/* 入库单据 */}
            <Route exact={true} path={"/stock/entry"} key="9-6" component={StockEntry} />
            <Route exact={true} path={"/stock/entry/look"} key="9-6-3" component={StockEntryModelDetails} />
            {/* 维修借件 StockBorrowDetails*/}
            <Route exact={true} path={"/stock/borrow"} key="9-4" component={Borrow} />
            <Route exact={true} path={"/stock/borrow/add"} key="9-4-1" component={StockBorrowDetails} />
            <Route exact={true} path={"/stock/borrow/edit"} key="9-4-2" component={StockBorrowDetails} />

            {/* 库存盘点 */}
            <Route exact={true} path={"/stock/check"} key="9-2" component={StockCheck} />
            <Route exact={true} path={"/stock/check/add"} key="9-2-1" component={StockCheckDetails} />
            <Route exact={true} path={"/stock/check/edit"} key="9-2-2" component={StockCheckDetails} />
            <Route exact={true} path={"/stock/check/look"} key="9-2-3" component={StockCheckDetails} />
            {/* 账户管理 */}
            <Route exact={true} path={"/account/customerManage"} key="15-1" component={AccountCustomer} />
            <Route exact={true} path={"/account/customerManage/detail"} key="15-1-1" component={CustomerAccountDetailManage} />

            <Route exact={true} path={"/account/supplierManage"} key="15-2" component={AccountSupplier} />
            <Route exact={true} path={"/account/supplierManage/detail"} key="15-2-1" component={SupplierAccountDetailManage} />

            {/* 内部对账单 */}
            <Route exact={true} path={"/account/internalAllocation"} key="15-3" component={AccountInternal} />
            <Route exact={true} path={"/account/internalAllocation/add"} key="15-3-1" component={InternalDetails} />
            <Route exact={true} path={"/account/internalAllocation/edit"} key="15-3-2" component={InternalDetails} />
            <Route exact={true} path={"/account/internalAllocation/look"} key="15-3-3" component={InternalDetails} />
            {/* 公交业务 预售销售 */}
            <Route exact={true} path={"/busBusiness/preSale"} key="16-1" component={BusBusinessPreSale} />
            <Route exact={true} path={"/busBusiness/preSale/detail"} key="16-1-2" component={BusBusinessDetailManage} />
            {/* 预售记录 */}
            <Route exact={true} path={"/busBusiness/preSaleRecord/add"} key="16-2-1" component={PreSaleDetails} />
            <Route exact={true} path={"/busBusiness/preSaleRecord/edit"} key="16-2-2" component={PreSaleDetails} />
            <Route exact={true} path={"/busBusiness/preSaleRecord/look"} key="16-2-3" component={PreSaleDetails} />
            {/* 销售记录 */}
            <Route exact={true} path={"/busBusiness/saleRecord/add"} key="16-3-1" component={SaleDetails} />
            <Route exact={true} path={"/busBusiness/saleRecord/edit"} key="16-3-2" component={SaleDetails} />
            <Route exact={true} path={"/busBusiness/saleRecord/look"} key="16-3-3" component={SaleDetails} />
            {/* 调配记录 */}
            <Route exact={true} path={"/busBusiness/deployment/add"} key="16-4-1" component={DeploymentDetails} />
            <Route exact={true} path={"/busBusiness/deployment/look"} key="16-4-3" component={DeploymentDetails} />
            {/* 销退记录 */}
            <Route exact={true} path={"/busBusiness/saleReturn/add"} key="16-6-1" component={SaleReturnDetails} />
            <Route exact={true} path={"/busBusiness/saleReturn/edit"} key="16-6-2" component={SaleReturnDetails} />
            <Route exact={true} path={"/busBusiness/saleReturn/look"} key="16-6-3" component={SaleReturnDetails} />
            {/* 预退记录 */}
            <Route exact={true} path={"/busBusiness/preWithdrawal/add"} key="16-5-1" component={PreWithdrawalDetails} />
            <Route exact={true} path={"/busBusiness/preWithdrawal/edit"} key="16-5-2" component={PreWithdrawalDetails} />
            <Route exact={true} path={"/busBusiness/preWithdrawal/look"} key="16-5-3" component={PreWithdrawalDetails} />
            {/* 预售总列表 */}
            <Route exact={true} path={"/busBusiness/preSaleRecord"} key="16-2" component={PreSaleAll} />
            {/* 销售总列表 */}
            <Route exact={true} path={"/busBusiness/saleRecord"} key="16-3" component={SaleAll} />
            {/* 调配总列表 */}
            <Route exact={true} path={"/busBusiness/deployment"} key="16-4" component={DeploymentAll} />
            {/* 预退总列表 */}
            <Route exact={true} path={"/busBusiness/preWithdrawal"} key="16-5" component={PreWithdrawalAll} />
            {/* 销退总列表 */}
            <Route exact={true} path={"/busBusiness/saleReturn"} key="16-6" component={SaleReturnAll} />
            {/* 公交库存明细 */}
            <Route exact={true} path={"/busBusiness/detailedInventory"} key="16-7" component={DetailedInventory} />

            {/* ------------------------------------404*/}
            {/* 统计分析 */}
            {/* 綦齿业务报表 */}
            <Route exact={true} path={"/statistics/reportForm"} key="12-1" component={StatistReportForm} />
            <Route exact={true} path={"/statistics/inventorySituation"} key="12-8" component={InventorySituation} />

            {/* 销售报表 */}
            <Route exact={true} path={"/statistics/salesReport"} key="12-9" component={SalesReort} />
            {/* 应收报表 */}
            <Route exact={true} path={"/statistics/receivables"} key="12-10" component={Receivables} />
            {/* 出入库明细 */}
            <Route exact={true} path={"/statistics/stockInAndOut"} key="12-2" component={StockInAndOut} />
            {/* 系统设置 */}
            <Route exact={true} path={"/settings"} key="17" component={Setting} />
            <Route exact={true} path={"/stock/purchaseDetails"} key="9-7" component={StockPoDetails} />
            {/* 销售明细报表 */}
            <Route exact={true} path={"/statistics/so"} key="12-13" component={SoReport} />
            {/* 销售单报表 */}
            <Route exact={true} path={"/statistics/soList"} key="12-14" component={SoList} />
            {/* 公交销售明细报表 */}
            <Route exact={true} path={"/statistics/bus"} key="12-13" component={BusReport} />
            {/* 公交销售报表 */}
            <Route exact={true} path={"/statistics/busList"} key="12-14" component={BusList} />
            {/* 采购报表 */}
            <Route exact={true} path={"/statistics/poList"} key="12-6" component={PoList} />
            {/* 采购单明细报表 */}
            <Route exact={true} path={"/statistics/po"} key="12-12" component={PoReport} />
            {/* 进销存报表 */}
            <Route exact={true} path={"/statistics/PSI"} key="12-15" component={PsiReport} />
            {/* 公交进销存报表 */}
            <Route exact={true} path={"/statistics/PSIbusiness"} key="12-22" component={PsibusReport} />
            {/* 业务数据监测 */}
            <Route exact={true} path={"/statistics/monitoring"} key="12-18" component={Monitoring} />
            {/* 账单监测 */}
            <Route exact={true} path={"/statistics/billMonitor"} key="12-19" component={BillMonitor} />
            <Route exact={true} path={"/statistics/transferMonitor"} key="12-20" component={TransferMonitor} />
            <Route exact={true} path={"/statistics/transferDataMonitor"} key="12-21" component={TransferDataMonitor} />

            <Route exact={true} path={"/exportRecord"} key="15" component={ExportRecord} />
            <Route exact={true} path={"/*"} key="404" component={Error404} />
        </Switch>
    )
}

export default VrmRoute;
