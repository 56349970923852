import React, { useRef, useEffect, useState, FC } from "react";
import { message, Modal, Upload, UploadFile } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import {
  FormItemTypeEnum,
  IYhForm,
  IYhFormRef,
  ISelectOption,
} from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { formatNum } from "@/utils/utils";
import { useSaleSoDetailModel } from "./saleSoDetailModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import Table, { ColumnsType } from "antd/lib/table";
import {
  PlusOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { IVRMSettleTypeEnum } from "@/types/FmsDs";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { ISaleSoSettleParams } from "../../saleSoTypes";
import useGlobalModel from "@/model/globalModel";
import { accountService } from "@/views/account/AccountService";
import { ISelectOptions } from "@/types/ScmTypes";
import { RouterParamType } from "@/model/navModel";
import { customerService } from "@/views/base/customerFiles/customer/baseCustomerService";
import FontIcon from "@/components/YhIcon";
import {
  getBaseImgLookUrl,
  getBaseImgUploadUrl,
  getBaseUploadData,
} from "@/utils/constants";
import { fileDownload } from "@/utils/exportFile";
import { UploadChangeParam } from "antd/lib/upload";
export interface ISalePayMethod {
  amount?: number; /// 0,
  code?: string; /// "",
  createTime?: string; /// "",
  createUser?: string; /// "",
  deleteFlag?: number; /// 0,
  id?: string; /// "",
  note?: string; /// "",
  orderNum?: number; /// 0,
  payMethodCode?: string; /// "",
  payMethodName?: string; /// "",
  payee?: string; /// "",
  shopId?: string; /// "",
  tradeCode?: string; /// "",
  tradeTypeCode?: string; /// "",
  tradeTypeName?: string; /// "",
  updateTime?: string; /// "",
  updateUser?: string; /// ""
  available?: number;
  accountName?: string;
  accountCode?: string;
  key: string;
  [key: string]: any;
}
interface IAccountOption extends ISelectOption {}
//结算
export const SettleDrawer: FC<RouterParamType> = ({ id, mode }) => {
  const {
    settleVisible: visible,
    detailAddForm,
    detailEditForm,
    detailLookForm,
    lookSettleDetails,
    editSettleDetails,
    setSettleVisible,
    onSettle,
    getDetailEditOne,
  } = useSaleSoDetailModel();
  const { getSelectedDictOne, getTreeNodeName, getTreeNodeOne } =
    useDictModel();
  const {
    user,
    shopAndOrg: { shopName, shopId, shopCode },
  } = useGlobalModel();
  const formRef = useRef<IYhFormRef>();
  const typeForm = {
    add: detailAddForm,
    edit: detailEditForm,
    look: detailLookForm,
  };
  const typeDetailList = {
    add: [],
    edit: editSettleDetails,
    look: lookSettleDetails,
  };
  const [settleType, setSettleType] = useState<IVRMSettleTypeEnum>(
    IVRMSettleTypeEnum.SETTLE_CASH
  ); //结算类型，  默认现金结账
  const initDataSource: ISalePayMethod = {
    key: new Date().getTime().toString(),
    payMethodCode: "",
    payMethodName: "",
    amount: 0,
    payee: "",
    note: "",
  };
  const [payDataSource, setPayDataSource] = useState<ISalePayMethod[]>([]); //表格默认值
  const [accountTypeDs, setAccountTypeDs] = useState<ISelectOption[]>([]); //实时存取收款账户类型值settle-type.cash
  const [editPayMethodOptions, setEditPayMethodOptions] = useState<
    ISelectOptions[]
  >(getSelectedDictOne(DictCodeEnmu.PAY_METHOD)); //编辑根据结算类型展示结算方式
  const [customerCode, setCustomerCode] = useState<string>("");
  const [cash, setCash] = useState(false); //是否展示现金类得支付方式
  const [cashDs, setCashDs] = useState<IAccountOption[]>([]);

  useEffect(() => {
    if (visible) {
      formRef.current?.setFieldsValue({ ...typeForm[mode], note: "" });
      setPayDataSource(
        mode === "edit"
          ? [
              {
                ...initDataSource,
                amount: detailEditForm.receivedAmount as number,
              },
            ]
          : typeDetailList[mode]
      );
      setSettleType(typeForm[mode].payType as IVRMSettleTypeEnum);
      // queryAccount(typeForm[mode].customerCode as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, typeForm[mode].payType as string);
      setPayMethodOptions(typeForm[mode].payType as string);
      let { settlementPartyCode, bentityName } = typeForm[mode];
      if (settlementPartyCode === "settle-party.internal") {
        //这里为部门内部结算时，查询销售实体为客户的接口换取id
        customerService
          .page({ name: shopName, pageSize: 15, pageNum: 1, shopId })
          .then((res) => {
            let { records } = res.retData;
            // console.log(records)
            if (records) {
              let { code } = records[0];
              setCustomerCode(code as string);
              queryAccount(
                code as string,
                typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH
                  ? 0
                  : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE
                  ? 2
                  : 1,
                typeForm[mode].payType as string,
                shopName
              );
            } else {
              message.warning(
                `该结算方为部门内部费用时，无维护对应为${shopName}客户`
              );
            }
          });
      } else if (mode !== "look") {
        queryAccount(
          typeForm[mode].customerCode as string,
          typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH
            ? 0
            : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE
            ? 2
            : 1,
          typeForm[mode].payType as string,
          typeForm[mode].customerName
        );
      }
    }
  }, [visible]);
  const setPayMethodOptions = (payType: string) => {
    let newPayMethodOptions = getSelectedDictOne(
      DictCodeEnmu.PAY_METHOD
    ).filter((item) => {
      if (item.relationvalue === payType) {
        return item;
      }
    });
    setEditPayMethodOptions(newPayMethodOptions as ISelectOptions[]);
  };
  // 查询客户账户类型及可用额度 后期补
  const queryAccount = async (
    partnerCode: string,
    creditFlag: number,
    payType: string,
    customerName?: string
  ) => {
    // console.log(partnerCode, creditFlag)
    let params = {
      partnerCode, //客户/供应商编码
      shopCode,
      beCode: typeForm[mode].bentityCode,
      status: 0,
      creditFlag, //为记账类型的  1挂账 2现结
    };
    const { retData } = await accountService.accountFind(params);
    //根据优惠类型， 过滤授信账户的 （返利|授信）
    let filterResult = retData.filter((item) => {
      return item;
    });
    // debugger;
    //现金账户 或者 挂账账户。
    let newAccTypeDs: any = []; // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
    if (payType === IVRMSettleTypeEnum.SETTLE_CASH) {
      newAccTypeDs = filterResult.map((item) => ({
        label: item.name ?? "",
        value: item.code ?? "",
        available: item.availableAmount ?? 0,
        accounttypecode: item.accountTypeCode as string,
        key: item.key ?? "",
      }));
    } else if (payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {
      //挂账  account-type.credit
      newAccTypeDs = filterResult
        .filter((item) => {
          if (item.accountTypeCode == "account-type.credit")
            return {
              label: item.name ?? "",
              value: item.code ?? "",
              available: item.availableAmount ?? 0,
            };
        })
        .map((item1) => ({
          label: item1.name ?? "",
          value: item1.code ?? "",
          available: item1.availableAmount ?? 0,
          accounttypecode: item1.accountTypeCode as string,
          key: item1.key ?? "",
        }));
    } else if (payType === IVRMSettleTypeEnum.SETTLE_MONTHLY) {
      //月结  account-type.credit-month
      newAccTypeDs = filterResult
        .filter((item) => {
          if (item.accountTypeCode == "account-type.credit-month")
            return {
              label: item.name ?? "",
              value: item.code ?? "",
              available: item.availableAmount ?? 0,
            };
        })
        .map((item1) => ({
          label: item1.name ?? "",
          value: item1.code ?? "",
          available: item1.availableAmount ?? 0,
          accounttypecode: item1.accountTypeCode as string,
          key: item1.key ?? "",
        }));
    }
    setAccountTypeDs(newAccTypeDs);
    //选择挂账， 自动带出挂账的账户及授信额度
    if (newAccTypeDs.length > 0) {
      setCash(newAccTypeDs[0].accounttypecode === "account-type.cash");
      setPayDataSource(() => {
        let available = +(newAccTypeDs[0]?.available ?? 0); //可用额度
        let payAmount = +(typeForm[mode].receivedAmount as string); //需要支付的额度
        return [
          {
            ...initDataSource,
            available: available,
            payMethodName: newAccTypeDs[0].label,
            payMethodCode: newAccTypeDs[0].value,
            payMethodValue: newAccTypeDs[0].accounttypecode,
            amount: payAmount, //+`${payAmount <= available ? payAmount : 0}`,
          },
        ];
      });
    } else {
      setCash(false);
      message.warning(`该${customerName}客户没有授信账户`);
    }
  };

  //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
  const settleTypeChange = (value: string, option: ISelectOption) => {
    let { settlementPartyCode, bentityName } = typeForm[mode];
    if (option) {
      setSettleType(option.value as IVRMSettleTypeEnum);
      setPayDataSource([initDataSource]);
      setPayDataSource([
        { ...initDataSource, amount: detailEditForm.receivedAmount as number },
      ]);
      if (settlementPartyCode === "settle-party.internal") {
        //这里为部门内部结算时，查询销售实体为客户的接口换取id
        queryAccount(
          customerCode as string,
          value === IVRMSettleTypeEnum.SETTLE_CASH
            ? 0
            : value === IVRMSettleTypeEnum.SETTLE_BALANCE
            ? 2
            : 1,
          value as string,
          shopName
        );
      } else if (
        settlementPartyCode?.indexOf("man") !== -1 ||
        settlementPartyCode?.indexOf("scn") !== -1
      ) {
        let { value: customerCode, name } = getTreeNodeOne(
          settlementPartyCode as string
        )[0];
        // console.log(customerCode, name, getTreeNodeOne(settlementPartyCode as string))
        queryAccount(
          customerCode as string,
          typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH
            ? 0
            : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE
            ? 2
            : 1,
          typeForm[mode].payType as string,
          name
        );
      } else {
        queryAccount(
          typeForm[mode].customerCode as string,
          value === IVRMSettleTypeEnum.SETTLE_CASH
            ? 0
            : value === IVRMSettleTypeEnum.SETTLE_BALANCE
            ? 2
            : 1,
          value as string,
          typeForm[mode].customerName
        );
      }
      // option.value === ISCMSettleTypeEnum.SETTLE_CREDIT && queryAccount(editFormVal.customerCode);
      // queryAccount(typeForm[mode].customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, value);
      setPayMethodOptions(value);
    }
  };
  //监听批量录入字段
  const onChange = (changedValues: { [key: string]: any }) => {};

  const onCancel = () => {
    setSettleVisible(false);
  };

  //表单提交
  const onSubmit = async () => {
    // return console.log(payDataSource)
    let filterPayData = payDataSource.filter((item) => item.payMethodCode);
    if (filterPayData.length !== payDataSource.length) {
      message.warning("请完善收款信息");
      return;
    }
    let totalAmount: number = filterPayData.reduce((con, next) => {
      con += +(next.amount ?? 0);
      return con;
    }, 0);
    if (totalAmount !== +(typeForm[mode].receivedAmount as number)) {
      message.warning("您结算的金额与应收金额有差异");
      return;
    }
    if (
      settleType === IVRMSettleTypeEnum.SETTLE_CASH &&
      accountTypeDs.length === 0
    ) {
      message.warning("您的现金账户没有开户");
      return;
    }
    if (
      filterPayData.filter(
        (item) =>
          item.payMethodValue === DictCodeEnmu.ACC_TYPE_CASH && !!!item.cashCode
      ).length !== 0
    ) {
      message.warning("请补全你的现金类支付方式");
      return false;
    }
    formRef.current
      ?.validateFields()
      .then(async (res) => {
        let newAccountCode = "";
        let accountType = "";
        let accountName = "";

        if (res.payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {
          newAccountCode =
            filterPayData[0]?.["payMethodCode"] ?? ("" as string);
          accountType = filterPayData[0]?.["payMethodValue"] ?? ("" as string);
          accountName = filterPayData[0]?.["payMethodName"] ?? ("" as string);
        } else {
          let { value, accounttypecode, label } = accountTypeDs.find(
            (item) => filterPayData[0]?.payMethodValue === item.accounttypecode
          ) as any;
          newAccountCode = value;
          accountType = accounttypecode;
          accountName = label;
        }
        if (!newAccountCode && res.payType === IVRMSettleTypeEnum.SETTLE_CASH) {
          message.warning(`您的现金${filterPayData[0]?.payMethodName}没有开户`);
          return false;
        }
        let data: ISaleSoSettleParams = {
          id: typeForm[mode].id as string,
          saleCode: res.saleCode,
          fmsNote: res.fmsNote,
          reckoner: user.username,
          payType: res.payType,
          payableAmount: res.receivedAmount,
          accountCode: newAccountCode,
          accountType,
          accountName,
          details:
            filterPayData[0].payMethodValue !== "account-type.cash" ||
            settleType == IVRMSettleTypeEnum.SETTLE_CREDIT ||
            settleType == IVRMSettleTypeEnum.SETTLE_BALANCE
              ? []
              : filterPayData.map((item) => {
                  let {
                    key,
                    available,
                    payMethodValue,
                    cashCode,
                    cashName,
                    ...params
                  } = item;
                  let findCash = getSelectedDictOne(
                    DictCodeEnmu.PAY_METHOD_ACCT
                  ).find(
                    (item) => item?.relationvalue === payMethodValue
                  ) as any;
                  return {
                    ...params,
                    payMethodCode: cashCode ?? findCash?.value,
                    payMethodName: cashName ?? findCash?.label,
                    accountName,
                    accountCode: newAccountCode,
                  };
                }),
        };
        // console.log("结算", data);
        // return
        try {
          let retData = await onSettle(data);
          message.success("结算成功");
          onCancel();
          getDetailEditOne(typeForm[mode].saleCode as string, "edit");
        } catch (error) {
          onCancel();
        }
      })
      .catch((err) => {});
  };

  const onFinish = (val: any) => {};

  const onCashAttachChange = (
    info: UploadChangeParam<UploadFile<any>>,
    record: ISalePayMethod
  ) => {
    console.log(info);
    if (info.file.status === "done" && info.file?.response?.retCode === 0) {
      let dataSource: ISalePayMethod[] = [...payDataSource];
      const index = dataSource.findIndex((item) => record.key === item.key);
      const item = dataSource[index];
      dataSource.splice(index, 1, {
        ...item,
        ...{
          ...record,
          cashAttach: info?.file?.response?.retData,
          cashAttachFileName: info.file.name,
        },
      });
      setPayDataSource(dataSource);
    } else if (
      info.file.status === "error" &&
      info.file.response &&
      info?.file?.response?.retData
    ) {
      message.error("导入异常，请尝试重新导入");
    } else if (info.file.status === "error") {
      message.error("导入错误");
    }
  };

  const onCashAttachDelete = (record: ISalePayMethod) => {
    let dataSource: ISalePayMethod[] = [...payDataSource];
    const index = dataSource.findIndex((item) => record.key === item.key);
    const item = dataSource[index];
    dataSource.splice(index, 1, {
      ...item,
      ...{ ...record, cashAttach: "", cashAttachFileName: "" },
    });
    setPayDataSource(dataSource);
  };

  const onPreview = (record: ISalePayMethod) => {
    const cashAttach = record.cashAttach as string;
    const cashAttachFileName = record.cashAttachFileName as string;
    const type = cashAttach.split(".")[1];
    if (["jpg", "png", "jpeg", "gif"].includes(type)) {
      Modal.info({
        title: "图片查看",
        // eslint-disable-next-line react/jsx-no-undef
        content: (
          <img style={{ width: "100%" }} src={getBaseImgLookUrl(cashAttach)} />
        ),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        width: 800,
      });
    } else {
      // window.open(getBaseImgLookUrl(uid))
      fileDownload(getBaseImgLookUrl(cashAttach), cashAttachFileName);
    }
  };

  const formConfig: IYhForm<any> = {
    formRef,
    onFinish,
    labelCol: { span: 6 },
    disabled: mode === "look",
    onChange,
    items: [
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "销售单号",
        fieldName: "saleCode",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "制单日期",
        fieldName: "createTime",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "客户编码",
        fieldName: "customerCode",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "客户名称",
        fieldName: "customerName",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "销售实体",
        fieldName: "bentityName",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "所属门店",
        fieldName: "shopName",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "应收金额",
        fieldName: "receivedAmount",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "已收金额",
        fieldName: "receivableAmount",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "优惠金额",
        fieldName: "discountTotalAmount",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "结算日期",
        fieldName: "settleTime",
        span: 12,
        disable: true,
      },
      {
        type: FormItemTypeEnum.Select,
        fieldLabel: "结算类型",
        fieldName: "payType",
        onSelectChange: settleTypeChange,
        rules: [{ required: true, message: "该项为必选项" }],
        options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
        span: 12,
        // disable: true
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "备注",
        fieldName: "fmsNote",
        span: 12,
        // disable: true
      },
    ],
  };
  const columns = [
    {
      title: "支付方式",
      dataIndex: "payMethodCode",
      editable: true,
      editType: "select",
      options: payDataSource.length === 1 ? accountTypeDs : cashDs,
      // options:accountTypeDs,
      width: 260,
      render: (text: string) =>
        accountTypeDs.find((item) => item.value === text)?.label,
    },
    // cash ? {
    //     title: '现金类支付方式',
    //     dataIndex: 'cashCode',
    //     editable: true,
    //     editType: "select",
    //     options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASH),
    //     width: 120,
    //     render: (text: string) => getTreeNodeName(text)
    // } : {
    //     title: '可用额度',
    //     dataIndex: 'available',
    //     width: 100,
    //     render: (text: string) => <span className="statu-red">{`￥${text}`}</span>
    // },
    ...((str) => {
      if (str) {
        return [
          {
            title: "现金类支付方式",
            dataIndex: "cashCode",
            editable: true,
            editType: "select",
            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASH),
            width: 120,
            render: (text: string) => getTreeNodeName(text),
          },
          {
            title: "附件",
            dataIndex: "cashAttach",
            width: 120,
            render: (text: string, record: ISalePayMethod) => {
              if (text) {
                return (
                  <div
                    className="flex justify-around"
                    style={{
                      position: "relative",
                      width: "32px",
                      height: " 32px",
                    }}
                  >
                    <FontIcon
                      onClick={() => {
                        onPreview(record);
                      }}
                      style={{ fontSize: "32px" }}
                      type="icon-tupian"
                    />
                    <CloseCircleOutlined
                      onClick={() => {
                        onCashAttachDelete(record);
                      }}
                      style={{
                        fontSize: "12px",
                        color: "red",
                        position: "absolute",
                        top: "-6px",
                        right: "-6px",
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <Upload
                    action={getBaseImgUploadUrl()}
                    data={getBaseUploadData()}
                    showUploadList={false}
                    beforeUpload={(file:any) => {
                      const isLt20M = file.size / 1024 / 1024 < 20;
                      if (!isLt20M) {
                        message.error("上传到文件不能超过20M");
                      }
                      return isLt20M;
                    }}
                    onChange={(info) => {
                      onCashAttachChange(info, record);
                    }}
                  >
                    <CloudUploadOutlined
                      style={{
                        fontSize: "24px",
                        cursor: "pointer",
                        color: "#6C72F3",
                      }}
                    />
                  </Upload>
                );
              }
            },
          },
        ];
      } else {
        return [
          {
            title: "可用额度",
            dataIndex: "available",
            width: 100,
            render: (text: string) => (
              <span className="statu-red">{`￥${text}`}</span>
            ),
          },
        ];
      }
    })(cash),

    {
      title: "结算金额",
      width: 120,
      dataIndex: "amount",
      editable: mode === "look" ? false : true,
      render: (text: any) => `￥${formatNum(text)}`,
    },
    {
      title: "收款人",
      editable: mode === "look" ? false : true,
      dataIndex: "payee",
      editType: "select",
      options: getSelectedDictOne(DictCodeEnmu.PAYEE),
      width: 150,
      render: (text: string) => getTreeNodeName(text),
    },
    {
      align: "center",
      title: "操作",
      width: 100,
      render: (text: any, record: any) =>
        settleType === IVRMSettleTypeEnum.SETTLE_CREDIT ? (
          ""
        ) : (
          <div className="flex justify-around">
            <PlusOutlined
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => {
                setCashDs(
                  accountTypeDs.filter(
                    (item) =>
                      item.accounttypecode === DictCodeEnmu.ACC_TYPE_CASH
                  )
                );
                setPayDataSource(() => [...payDataSource, initDataSource]);
              }}
            />
            <DeleteOutlined
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() =>
                setPayDataSource(() =>
                  payDataSource.filter((item) => item.key !== record.key)
                )
              }
            />
          </div>
        ),
    },
  ];
  const lookColumns = [
    {
      title: "支付方式",
      dataIndex: "accountName",
      width: 260,
    },
    // detailLookForm.payType === IVRMSettleTypeEnum.SETTLE_CASH ?
    //     {
    //         title: '现金类支付方式',
    //         dataIndex: 'payMethodName',
    //         width: 120,
    //     } : {
    //     },

    ...(detailLookForm.payType === IVRMSettleTypeEnum.SETTLE_CASH
      ? [
          {
            title: "现金类支付方式",
            dataIndex: "payMethodName",
            width: 120,
          },
          {
            title: "附件",
            dataIndex: "cashAttach",
            width: 120,
            render: (text: string, record: ISalePayMethod) =>
              text && (
                <FontIcon
                  onClick={() => {
                    onPreview(record);
                  }}
                  style={{ fontSize: "32px" }}
                  type="icon-tupian"
                />
              ),
          },
        ]
      : [{}]),
    {
      title: "结算金额",
      width: 120,
      dataIndex: "amount",
    },
    detailLookForm.payType === IVRMSettleTypeEnum.SETTLE_CASH
      ? {
          title: "收款人",
          dataIndex: "payee",
          width: 150,
          render: (text: string) => getTreeNodeName(text),
        }
      : {},
  ];

  const newPayColumns = columns.map((col: any, index) => {
    return {
      ...col,
      onCell: (record: ISalePayMethod, rowIndex: number) => ({
        colIndex: index + 1,
        rowIndex,
        record,
        rules: col?.rules,
        options: col?.options,
        editType: col?.editType,
        editable: col?.editable,
        onSelectChange: col?.onSelectChange,
        onSelectFocus: col?.onSelectFocus,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  //内联编辑
  const handleSave = (row: ISalePayMethod) => {
    row.amount = +formatNum(row.amount);

    //结算类型为挂账时， 判断支付方式有值时候 去对应的可用额度
    // if (settleType === IVRMSettleTypeEnum.SETTLE_CREDIT) {
    //     if (row.payMethodCode) {
    //         let find = accountTypeDs.find(item => item.value === row.accountCode);
    //         row.available = +(find?.available ?? 0);
    //     }
    //     if (+row.amount > +(row.available ?? 0)) {
    //         row.amount = 0;
    //         message.warning("结算金额≤可用额度");
    //     }
    // } else if (settleType === IVRMSettleTypeEnum.SETTLE_CASH) {
    //     if (row.payMethodCode) {
    //         row.payMethodName = getTreeNodeName(row.payMethodCode) ?? "";;
    //     }
    // }
    // row.payMethodName = getTreeNodeName(row.payMethodCode as string) ?? "";;
    if (
      settleType === IVRMSettleTypeEnum.SETTLE_CREDIT ||
      settleType === IVRMSettleTypeEnum.SETTLE_CASH
    ) {
      if (row.payMethodCode) {
        let find = accountTypeDs.find(
          (item) => item.value === row.payMethodCode
        );
        row.payMethodName = find?.label ?? "";
        row.payMethodValue = (find?.accounttypecode as string) ?? "";
        row.payMethodCode = (find?.value as string) ?? "";
        row.available = +(find?.available ?? 0);
      }
    }

    if (row.payMethodValue === DictCodeEnmu.ACC_TYPE_CASH) {
      setCash(true);
      row.cashName = getTreeNodeName(row.cashCode) ?? "";
    } else {
      setCash(false);
      row.cashName = null;
      row.cashCode = null;
      if (+row.amount > +(row.available ?? 0)) {
        row.amount = 0;
        message.warning("结算金额≤可用额度");
      }
    }
    let dataSource: ISalePayMethod[] = [...payDataSource];
    const index = dataSource.findIndex((item) => row.key === item.key);
    const item = dataSource[index];
    dataSource.splice(index, 1, { ...item, ...row });
    setPayDataSource(dataSource);
  };
  return (
    <YHDrawer
      {...{
        title: "销售单结算",
        visible,
        onCancel,
        okText: "保存",
        onOk: onSubmit,
        width: 800,
        showFooter:
          detailEditForm.status === "sale.settle" || mode === "look"
            ? false
            : true,
      }}
    >
      <YhForm {...formConfig} />
      <Table
        // size="small"
        rowClassName="editable-row yh-edit-table-tr"
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        // columns={newPayColumns as ColumnsType<ISalePayMethod>}
        columns={
          mode === "edit"
            ? (newPayColumns as ColumnsType<ISalePayMethod>)
            : lookColumns
        }
        dataSource={payDataSource}
        pagination={false}
        scroll={{ x: "max-content", y: 400 }}
      />
      <div className="table-bottom-total" onClick={() => {}}>
        合计:
        <span className="ml-15 statu-red">{`￥${formatNum(
          payDataSource.reduce((con, next) => {
            con += +(next.amount ?? 0);
            return con;
          }, 0)
        )}`}</span>
      </div>
    </YHDrawer>
  );
};
