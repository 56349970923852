import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useStockStatisticModel } from "../stockStatisticModel";
import useNavModel from "@/model/navModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { descTotal, selectedRowKeys, rowsDescTotal, statistics, exportStockStatistic, setRelocationVisible, setRecordsVisible } = useStockStatisticModel();
    const desc: IDescription[] = [
        {
            label: "库存数量",
            value: statistics?.stockNum ?? 0,
            color: DescriptionColorEnum.red
        },
    ]

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ST_INVENT_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportStockStatistic().then()}
            >导出</Button>
        </YhAuth>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setRelocationVisible(true)}
        >移库</Button> */}
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setRecordsVisible(true)}
        >转移记录</Button> */}
    </>;

    return (
        <YhBox title="库存统计列表" descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}