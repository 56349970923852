import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useBusListModel } from "../busListModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { exportBusList, descTotal } = useBusListModel();

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.STT_SL_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportBusList()}
            >导出</Button>
        </YhAuth>
    </>;
    const desc: IDescription[] = [
        {
            label: "销售数量",
            value: descTotal.saleNum,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售金额",
            value: descTotal.saleAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售成本",
            value: descTotal.saleCost,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售毛利",
            value: descTotal.grossProfit,
            color: DescriptionColorEnum.red
        }
    ]
    return (
        <YhBox title={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}