import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import React, { useState, useEffect } from "react";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";

import {
    IStockCheckSearch,
    IStockCheckDetail,
} from "./stockCheckType"
import { stockCheckService } from "./stockCheckService"
import { transPgToPagination } from "@/utils/utils";
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { message } from "antd";
import { convertRes2Blob } from "@/utils/exportFile";


export const useStockCheckModel = createModel(function () {
    const { shopAndOrg, } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IStockCheckSearch = {};
    const initDataSource: IStockCheckDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<IStockCheckSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IStockCheckDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IStockCheckDetail>({});
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IStockCheckDetail>();

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IStockCheckDetail)
    }, [selectedRowKeys])
    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IStockCheckSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: IStockCheckSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await stockCheckService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    const invalidateData = async (obj: object) => {
        let { retData } = await stockCheckService.invalidate(obj)
        if (retData) {
            message.success("作废成功");
            loadData();
        }
    }
    const exportStockCheck = async (searchValue: IStockCheckSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await stockCheckService.export(searchParams);
        convertRes2Blob(result)
    }
    const exportDetialtockCheck = async (searchValue: IStockCheckSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await stockCheckService.exportDetialtockCheck(searchParams);
        convertRes2Blob(result)
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        initPgCfg,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        selectedRow, setSelectedRow,
        invalidateData,
        exportStockCheck,
        exportDetialtockCheck,
        setHistoryRow,setHistoryVisible
    }
});