import React from "react";
import { AddDetailDrawer } from "./AddDetailDrawer";
import { DataBox } from "./DataBox";
import { YhPageHeader } from "@/components/YhPageHeader";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { useWarehouseModel } from "../warehouseModel";
const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "仓库名称",
        fieldName: "name"
    }
]
export function BaseWarehouse() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys,searchParam,updateSearchParam,resetSearchParam,initPgCfg
    } = useWarehouseModel();
    return (
        <>
            <YhPageHeader title="仓库管理"
                justify="end"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                // handleSearch={loadData}
                handleSearch={(params) => loadData(initPgCfg, params)}
                handleReset={resetSearchParam} />
            <DataBox />
            <AddDetailDrawer />
        </>
    );
}

