import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, message, Dropdown, Menu } from "antd";
import { SaleDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useSaleModel } from "./saleModel";
import { formatNum } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useSaleDetailModel } from "./detail/saleDetailModel";
import { DownOutlined } from "@ant-design/icons";
import { SalePrint } from "./SalePrint"

export const SaleDataBox = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        invalidateData,
        exportSaleSo,
        detailsExport,
        saleTransfer,
        print,
        setPrint
    } = useSaleModel();
    const { resetEditCatch } = useSaleDetailModel()
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.stock-io") {
            globalPrompt("message", { text: "该单据已出库，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        let saleSoEditKey = "16-3-2";
        let isFindNav = findNav(saleSoEditKey);
        if (!isFindNav) {
            addNav(saleSoEditKey, { id: selectedRow?.saleCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "销售记录跳转详情",
                type: "warning",
                text: "您还有销售记录详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleSoEditKey, { id: selectedRow?.saleCode as string, mode: "edit" });
                    // updateEditFormVal(null);
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.saleCode as string, mode: "look", },
            true
        );
    }
    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行作废", type: "warning" });
            return;
        }
        globalPrompt("modal", {
            title: "销售记录作废",
            type: "error",
            text: "",
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    saleCode: selectedRow.saleCode as string,
                }
                invalidateData(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onSaleTransfer = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        saleTransfer(selectedRow.saleCode as string)
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleSo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const onPrint = ()=>{
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onEdit}
        >
            编辑
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onInvalidate}
        >
            作废
        </Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onSaleTransfer}
        >
            转销退
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={()=>onPrint()}
        >
            打印
        </Button>
        <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown>          
    </>
    const desc: IDescription[] = [
        {
            label: "销售数量",
            value: selectedRowKeys.length === 0 ? descTotal?.saleNum??0 : rowsDescTotal?.saleNum??0,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售金额",
            value: selectedRowKeys.length === 0 ? descTotal?.saleAmount??0 : formatNum(rowsDescTotal?.saleAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "优惠金额",
            value: selectedRowKeys.length === 0 ? descTotal?.preferentialAmount??0 : formatNum(rowsDescTotal?.preferentialAmount??0),
            color: DescriptionColorEnum.red
        },

        {
            label: "应收金额",
            value: selectedRowKeys.length === 0 ? descTotal?.receivableAmount??0 : formatNum(rowsDescTotal?.receivableAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "已收金额",
            value: selectedRowKeys.length === 0 ? descTotal?.receivedAmount??0 : formatNum(rowsDescTotal?.receivedAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "销售成本",
            value: selectedRowKeys.length === 0 ? descTotal?.saleCost??0 : formatNum(rowsDescTotal?.saleCost??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "销售毛利",
            value: selectedRowKeys.length === 0 ? descTotal?.grossProfit??0 : (rowsDescTotal?.grossProfit??0),
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <YhBox title="销售记录" action={action} descriptions={desc}>
            <SaleDataTable />
        </YhBox>
        {print && <SalePrint/>}
    </>
}