import React, { useState, useEffect } from "react";
import { createModel } from "hox";
import useGlobalModel from "@/model/globalModel";
import { saleDcService } from "../../allotService";
import { convertRes2Blob } from "@/utils/exportFile";
import { message } from "antd";
import { useLockFn } from "ahooks";
import { IBCMSaleDcFormFields, IBCMSaleDcInsert, IBCMSaleDcUpdate, IBCMSDcGoodsOne, IBCMSDcGoodsDetail } from "../../allotType";
import { globalPrompt } from "@/components/message";
import { ISCMSDcSelectGoods } from "./AddGoodsModal";
import { purchaseDrService } from '@/views/purchase/dr/purchaseDrService';
import { formatNum,bigNumber } from "@/utils/utils";

export type ISCMSDcDescType = {
    numAll: number,
    amountAll: number,
    actualAmount: number,
    totalPreAmount: number;
    [key: string]: any
}
const initDesc = {
    actualAmount: 0,  //实际总价合计 （数量*单价）
    //对应展示字段
    numAll: 0,     //调拨数量
    amountAll: 0,    //调拨总计   （去除下浮）
    totalPreAmount: 0, //实际总价合计 （数量*单价）
}


export const useSaleDcDetailsModel = createModel(function () {
    const { shopAndOrg: { shopId, shopCode, shopName, }, user: { userName }, fmsShopDs, setLoadingInfo } = useGlobalModel();

    const initFormVal = {
        outShopCode: shopCode,
        outShopName: shopName,
        outShopId: shopId,
        createUser: userName,
        applier: userName,
        discountTotalAmount: "0.00", //优惠总额
        favorableAmount: "0",    //整单优惠
        aggregateAmount: "0.00",  //应付金额
        discountRate: "0",    //整单下浮
    } as IBCMSaleDcFormFields


    //编辑缓存
    const [editFormVal, setEditFormVal] = useState<IBCMSaleDcFormFields>(initFormVal);
    const [editGoodsList, setEditGoodsList] = useState<IBCMSDcGoodsDetail[]>([]);
    const [editDesc, setEditDesc] = useState<ISCMSDcDescType>(initDesc)
    const [isCancel, setIsCancel] = useState<boolean>(false);    //记录单子是否是已作废状态

    //新增缓存
    const [addFormVal, setAddFormVal] = useState<IBCMSaleDcFormFields>(initFormVal);
    const [addGoodsList, setAddGoodsList] = useState<IBCMSDcGoodsDetail[]>([]);
    const [addDesc, setAddDesc] = useState<ISCMSDcDescType>(initDesc);

    // 保存按钮loading
    const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);
    // 调拨按钮限制
    const [btnContral, setBtnContral] = useState<boolean>(false)

    useEffect(() => {
        let newDesc: ISCMSDcDescType = { ...initDesc };
        editGoodsList.forEach(item => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
        });
        setEditDesc(newDesc);
    }, [editGoodsList])
    useEffect(() => {
       // debugger
        let newDesc: ISCMSDcDescType = { ...initDesc };
        addGoodsList.forEach(item => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
        });
        setAddDesc(newDesc);
    }, [addGoodsList]);

    useEffect(() => {
        resetAddCatch();
        setAddFormVal({ ...initFormVal, createUser: userName });
    }, [shopId]);

    //表单新增修改
    const updateAddFormVal = (val: object | null) => {
        val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal(initFormVal);
    }
    //表单编辑修改
    const updateEditFormVal = (val: object | null) => {
        val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal(initFormVal);
    }

    //重置缓存
    const resetEditCatch = () => {
        updateEditFormVal(null);   //表单
        setEditGoodsList([]);     //详情商品列表
        setEditSelectGoods([]);   //弹框已选商品

    }
    const resetAddCatch = () => {
        updateAddFormVal(initFormVal);
        setAddGoodsList([]);
        setAddSelectGoods([]);
    }

    //获取一条数据
    const getSDcDetailOne = async (id: string) => {
        let { retData } = await saleDcService.one(id);
        let { details, ...formVal } = retData;
        if (retData) {
            setEditGoodsList(() => details.map(item => ({ ...item, key: item.id, isHave: true })))
            setEditFormVal(formVal);
        }
        return retData;
    };

    //表单配件校验 (开单数量不能为0)
    const verifyGoods = (mode: string): boolean => {
        let goods = mode === "edit" ? editGoodsList : addGoodsList;
        if (!goods.length) {
            message.warning("请添加商品明细");
            return false;
        }
        let zeroNum = goods.filter(item => !(+item.num));
        if (zeroNum.length) {
            message.warning(`【${zeroNum.map(item => item.materialName).join()}】配件的开单数量不能为0`)
            return false;
        }
        return true;
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? editGoodsList : addGoodsList;
        let { actualAmount } = (mode === "edit" ? editDesc : addDesc) as { actualAmount: number };
        actualAmount = +formatNum(actualAmount);//应付金额
        let goodsTotal: number = 0;
        goods.forEach(item => {
            let { amount } = item as { amount: number }
            // goodsTotal += amount
            goodsTotal = bigNumber.add(goodsTotal,amount)
        })
        if (+formatNum(goodsTotal) !== actualAmount) {
            message.warning(`详情调拨金额${+formatNum(goodsTotal)}与表单调拨金额不符`);
            return false;
        }
        return true
    }
    //新增 Api
    const insertSDc = useLockFn(async (val: IBCMSaleDcFormFields) => {
        if (val.shopCode === val.outShopCode) {
            message.warning("调入门店和调出门店不能为同一个");
            return false;
        }
        setSaveBtnLoading(true);
        let data: IBCMSaleDcInsert = {
            ...val,
            details: addGoodsList.map(item => { let { key, error, isHave, msg, ...params } = item; return params }),
            inAndOut: "1",
            totalPreAmount:formatNum(addDesc['totalPreAmount'])
        }
        try {
        let { success, retData } = await saleDcService.insert(data);
        let { id: res, materialList } = retData;
            if (success) {
                setBtnContral(false)
                setSaveBtnLoading(false);
                if (!materialList) {
                    globalPrompt('message', {
                        text: `调出单新增成功,转换到可编辑详情页`,
                        type: 'success',
                        duration: 3,
                    });
                } else {
                    globalPrompt('message', {
                        text: `${data.shopName}没有【${materialList
                            .map((item: any) => item.code)
                            .join()}】,【${materialList.map((item: any) => item.code).join()}】该配件,已自动添加到商品档案`,
                        type: 'success',
                        duration: 10,
                    });
                }
            } else {
                setBtnContral(false)
                setSaveBtnLoading(false);
                globalPrompt('message', { text: '调出单新增失败', type: 'error' });
            }
            setSaveBtnLoading(false);
            return res;
        } catch (error) {
            setBtnContral(false)
            setSaveBtnLoading(false);
        }

    })

    //编辑 Api
    const updateSDc = useLockFn(async (val: IBCMSaleDcFormFields & { id: string }) => {
        let inShop = fmsShopDs.filter((item: any) => item.code === val.shopCode)[0].id
        if (val.shopCode === val.outShopCode) {
            message.warning("调入门店和调出门店不能为同一个");
            return false;
        }
        setSaveBtnLoading(true);
        let data: IBCMSaleDcUpdate = {
            ...val,
            id: editFormVal.id,
            updateUser: userName,
            details: editGoodsList.map(item => { let { key, error, isHave, msg, ...params } = item; return params }),
            inAndOut: "1",
            shopId: val.shopId === null ? inShop : val.shopId,
            totalPreAmount:formatNum(editDesc['totalPreAmount'])
        }
        try {
        let { success, retData } = await saleDcService.update(data);
        let { id: res, materialList } = retData;

            if (success) {
                setSaveBtnLoading(false);
                setBtnContral(false)
                if (!materialList) {
                    globalPrompt('message', {
                        text: `调出单编辑成功`,
                        type: 'success',
                        duration: 3,
                    });
                } else {
                    globalPrompt('message', {
                        text: `${data.shopName}没有【${materialList
                            .map((item: any) => item.code)
                            .join()}】该配件,已自动添加到商品档案`,
                        type: 'success',
                        duration: 10,
                    });
                }
                getSDcDetailOne(editFormVal.id);
            } else {
                setBtnContral(false)
                setSaveBtnLoading(false);
                globalPrompt('message', { text: '调出单编辑失败', type: 'error' });
            }
            setSaveBtnLoading(false);
            return res;
        } catch (error) {
            setBtnContral(false)
            setSaveBtnLoading(false);
        }
    })

    /**
    * 商品新增
    */
    const [addGoodsModal, setAddGoodsModal] = useState<boolean>(false)  //商品弹框
    const [editSelectGoods, setEditSelectGoods] = useState<ISCMSDcSelectGoods[]>([]);  //已选的商品
    const [addSelectGoods, setAddSelectGoods] = useState<ISCMSDcSelectGoods[]>([]);  //已选的商品

    //添加商品  默认数量字段为1
    const add = (record: any, mode: string) => {
        const isEdit: boolean = mode === "edit";
        isEdit ?
            setEditSelectGoods(origin => [...origin, { ...record, sDcNum:record.inventoryQuantity??1 }]) :
            setAddSelectGoods(origin => [...origin, { ...record, sDcNum:record.inventoryQuantity??1 }]);
    }
    //添加商品  默认数量字段为1
    const allAdd = (record: any, mode: string) => {
        const isEdit: boolean = mode === "edit";
        let list:any=[]
        record.map((item:any)=>{list.push({...item, sDcNum:item.inventoryQuantity??1})})
        isEdit ?
            setEditSelectGoods(origin => [...origin,  ...list]) :
            setAddSelectGoods(origin => [...origin,  ...list]);
    }
    //删除商品 选中的
    const remove = (record: ISCMSDcSelectGoods, mode: string) => {
        mode === "edit" ?
            setEditSelectGoods(origin => origin.filter(item => item.id !== record.id)) :
            setAddSelectGoods(origin => origin.filter(item => item.id !== record.id))
    }

    //选中的配件转商品
    const transformsToSDcGoods = useLockFn(async (selectList: ISCMSDcSelectGoods[], mode: string) => {
        setLoadingInfo({ loadingVisible: true, txt: "加载中" })
        const isEdit: boolean = mode === "edit";
        const newDetailData: IBCMSDcGoodsDetail[] = []
        let formVal = mode === 'add' ? addFormVal : editFormVal;
        let branchIn =
            formVal.branchIn === 'scm' ? 'scm2' : formVal.branchIn === 'vrm' ? 'vrm2' : 'bcm';
        let inShopId = formVal.shopId ?? shopId;
        let codeList: string[] = [];
        let goodsList = mode === 'add' ? addSelectGoods : editSelectGoods;
        goodsList.forEach((item) => {
            codeList.push(item.materialCode);
        });
        let data = {
            shopId: inShopId,
            checkoutMaterialCodeList: codeList,
            existMaterialCodeList: [],
            notExistMaterialCodeList: [],
        };
        let { retData } =
            branchIn === 'scm2'
                ? await saleDcService.checkScm(branchIn, data)
                : await saleDcService.check(branchIn, data);

        const { retData: stockList } = await saleDcService.stockQuery({ details: selectList.map(item => ({ shopId: inShopId, materialCode: item.materialCode })) });
        let { existMaterialCodeList } = retData;
        selectList.forEach(item => {
            const stockItem = stockList.find(s => item.materialCode === s.materialCode)
            newDetailData.push({
                isHave: existMaterialCodeList.indexOf(item.materialCode) > -1,
                id: "",
                key: item.id,   //
                brandCode: item.brandCode,
                brandName: item.brandName,
                purchaseDetailsId: item.id,
                materialCode: item.materialCode,
                materialName: item.materialName,
                replaceCode: item.replaceCode,
                typeCode: item.typeCode,
                typeName: item.typeName,
                unitCode: item.unitCode,
                unitName: item.unitName,
                num: item.sDcNum,
                price: item.price,  //进货价
                prePrice:item.price ?? 0, //折前单价
                preAmount: +item.sDcNum * +(item.price ?? 0), //总计
                costPrice: item.price ?? 0,  //进货价
                favorableAmount: "0",  //单品优惠
                discountRate: "0",     //下浮
                // amount: +item.sDcNum * +(item.price), //总计
                amount:+bigNumber.toFixed(bigNumber.times(+item.sDcNum,+(item.price)),2),
                outShelfId: item.shelfId,
                outShelfCode: item.shelfCode,
                outWarehouseName: item.warehouseName,
                outWarehouseCode: item.warehouseCode,
                inventoryNum: item.inventoryQuantity,
                purchaseCode: item.purchaseCode,
                note: "",
                inShelfCode: stockItem?.inShelfCode,
                inShelfId: stockItem?.inShelfId,
                inWarehouseCode: stockItem?.inWarehouseCode,
                inWarehouseName: stockItem?.inWarehouseName
            });
        });
        isEdit ? setEditGoodsList(origin => [...origin, ...newDetailData]) : setAddGoodsList(origin => [...origin, ...newDetailData]);
        isEdit ? setEditSelectGoods([]) : setAddSelectGoods([]);
        setLoadingInfo({ loadingVisible: false, txt: "" })
        setAddGoodsModal(false)
    })

    //驳回
    const rejectDc = useLockFn(async () => {
        let { retData } = await saleDcService.reject(editFormVal.id);
        if (retData) {
            globalPrompt("message", { text: "调出单驳回成功", type: "success" });
            getSDcDetailOne(editFormVal.id);
        } else {
            globalPrompt("message", { text: "调出单驳回失败", type: "error" });
        }
    });

    //调出
    const outWhConfirm = useLockFn(async (val: IBCMSaleDcFormFields) => {
        // saleDcService.one(editFormVal.id).then(({retData})=>{
        //     let { status } = retData;
        //     if (status === 'transfer-status.out') {
        //         message.error('当前调拨单为待调入状态，请刷新页面');
        //         return false;
        //     }
        // })
        let { retData } = await saleDcService.one(editFormVal.id);
		let { status } = retData;
		if (status === 'transfer-status.out') {
			message.error('当前调拨单为待调入状态，请刷新页面');
			return false;
		}

        let inShop = fmsShopDs.filter((item: any) => item.code === val.shopCode)[0].id
        await updateSDc({ ...val, id: editFormVal.id, shopId: val.shop === null ? inShop : val.shopId })
        let obj = {
            inAndOut: "1",
            id: editFormVal.id,
            status: editFormVal.status,
            transferOut: userName,
            transferOutTime: editFormVal.transferOutTime,//调出时间
            transferIn: editFormVal.transferIn,//调入员
            transferInTime: editFormVal.transferInTime,//调入时间
            createUser: userName,
            transferOutCode: editFormVal.transferOutCode,
            transferInCode: editFormVal.transferInCode,
        }
        let data: IBCMSaleDcUpdate = {
            ...editFormVal,
            id: editFormVal.id,
            status: editFormVal.status,
            transferOut: userName,
            transferOutTime: editFormVal.transferOutTime,//调出时间
            transferIn: editFormVal.transferIn,//调入员
            transferInTime: editFormVal.transferInTime,//调入时间
            createUser: userName,
            details: editGoodsList.map(item => { let { key, ...params } = item; return params }),
            inAndOut: "1",
        }
        try{
            let { retData } = await saleDcService.bcmWhConfirm(data);
            if (retData) {
                let { retData: WhConfirmRetData } = await saleDcService.whConfirm(obj);
                if (WhConfirmRetData) {
                    message.success("调出成功");
                    getSDcDetailOne(editFormVal.id);
                }
            }
        }catch(error){
           
        }
       

    })

    //明细下载模板
    const sDcDownload = async () => {
        let result = await saleDcService.downloadTpl();
        convertRes2Blob(result);
    }

    //导出明细
    const exportSDcdetail = async () => {
        let result = await saleDcService.exportDetail(editFormVal?.code ?? "");
        convertRes2Blob(result);
    }

    /**
    * 打印
    */
    const [print, setPrint] = useState<boolean>(false);
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<any>({})

    return {
        editFormVal,
        addFormVal,
        addGoodsModal,
        isCancel,
        setAddGoodsModal,
        updateAddFormVal,
        updateEditFormVal,
        resetEditCatch,
        resetAddCatch,
        /**api */
        insertSDc,
        updateSDc,
        getSDcDetailOne,
        sDcDownload,
        exportSDcdetail,
        /**商品详情 */
        editSelectGoods, setEditSelectGoods,
        addSelectGoods, setAddSelectGoods,
        addGoodsList, setAddGoodsList,
        editGoodsList, setEditGoodsList,
        add, remove, verifyGoods,
        transformsToSDcGoods,
        editDesc, addDesc,
        print, setPrint,
        rejectDc,
        outWhConfirm,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        formComputeRule,
        saveBtnLoading, setSaveBtnLoading,
        btnContral, setBtnContral,allAdd
    }
});