import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { poListService, IVRMPoListSearchFields, IVRMPoListDetail, IStatistics } from "./poListService";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { transPgToPagination } from "@/utils/utils";


export const usePoListModel = createModel(function () {
    const initStatistics = {
        payAmount: 0,
        paidAmount: 0,
        receivedAmount: 0,
        purchaseQuantity:0
    }
    const { shopAndOrg: { shopId } } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<IVRMPoListSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<IVRMPoListDetail[]>([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [loading, setLoading] = useState<boolean>(false);
    const [descTotal, setDescTotal] = useState<IStatistics>(initStatistics)
    const updateSearchParam = (updateParam: IVRMPoListSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IVRMPoListSearchFields = searchParam) => {
        setLoading(true);
        const searchParams = {
            shopId,
            ...searchValue,
            startTime: searchValue?.startTime ? searchValue?.startTime.replace(/T/g, " ") : "",
            endTime: searchValue?.endTime ? searchValue?.endTime.replace(/T/g, " ") : "",
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await poListService.page(searchParams);
        let { retData:Data } = await poListService.statistics(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDescTotal(Data);
        setDataSource(origin =>
            records?.map((item, index) => ({ ...item, id: index + "1" }) ?? []
            ));
        setLoading(false);
    }

    //导出
    const exportPoList = async (searchValue: IVRMPoListSearchFields = searchParam) => {
        try {
            const searchParams = {
                shopId,
                startTime: searchValue?.startTime ? searchValue?.startTime.replace(/T/g, " ") : "",
                endTime: searchValue?.endTime ? searchValue?.endTime.replace(/T/g, " ") : "",
                ...searchValue,
                id: selectedRowKeys.join(),
                pageSize: -1,
                pageNum: 1,
            }
            let result = await poListService.export(searchParams);
            convertRes2Blob(result);
        } catch{ 
            
         }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg, loading,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData, exportPoList,
        descTotal, setDescTotal
    }
});


