import React, { useRef, useState, useEffect } from "react";
import { Button, message, Form, Space, Select, Input } from "antd";
import { YhBox } from "@/components/YhBox";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { useGoodsModel } from "../materialModel";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IFormGoodsFields } from "../materialService";
import { getFormValidatorErrText } from "@/utils/utils"
import { DetailType } from "@/types/ScmEnums";
import { useDictModel } from "../../dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { warehouseService } from "../../warehouse/warehouseService";
import { IVRMshelfDetail } from "../../warehouse/warehouseTypes";
import { debounce } from "lodash";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IMaterialDetail } from "../materialService"
import { globalPrompt } from '@/components/message';
export const MaterialDetails = () => {
    const {
        addFormVal,
        editFormVal,
        lookFormVal,
        initForm,
        shelfAllList,
        setShelfAllList,
        updateAddFormVal,
        updateEditFormVal,
        insertGoods,
        editGoods,
        getGoodsOne,
        setAddFormVal,
        setSelectedRowKeys,
        warehouseDetalList,
        initWarehouseDetalList,
        editWarehouseDetalList,
        lookWarehouseDetalList,
        setWarehouseDetalList
    } = useGoodsModel();
    const { warehouseList, shopAndOrg: { shopCode, shopId, shopName } } = useGlobalModel()
    const [form] = Form.useForm();

    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const formRef = useRef<IYhFormRef>();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { id, mode, } = detailsInfo
    const { getSelectedDictOne } = useDictModel();
    const [loading, setLoading] = useState<boolean>(false)

    //编辑时， 没有缓在调用详情接口
    useEffect(() => {
        console.log(editFormVal)
        if (!Object.keys(editFormVal).length && detailsInfo.mode === DetailType.edit) {
            getGoodsOne(id, mode)
        }
        mode === DetailType.look && getGoodsOne(id, mode)

    }, [detailsInfo]);

    useEffect(() => {
        return () => {
            let addNavExist = findNav("13-2-1");
            let editNavExist = findNav("13-2-2");
            !addNavExist && updateAddFormVal(null);
            !editNavExist && updateEditFormVal(null);
        }
    }, [id, mode, selectedNavId])

    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, "13-2");
    }

    //表单提交
    const onSubmit = async () => {
        setLoading(true);
        let data: any = {};
        let materialDetal: any[] = []
        await form.validateFields().then(warehouseList => {
            materialDetal = warehouseList.materialDetal
        })
        // if (!(materialDetal && materialDetal.length > 0)) {
        //     globalPrompt("message", { text: "请添加仓库货位", type: "error" })
        //     return
        // }
        await formRef.current?.validateFields().then((res) => {
            data = res
            let newMaterialDetal:any = materialDetal.map(item => ({
                ...item,
                materialCode: data.code,
                materialName: data.name,
                shopCode, shopId, shopName,
            }
          
            )
           
            )
            console.log('newMaterialDetal',newMaterialDetal)
            if (detailsInfo.mode === "edit") {
                editGoods({
                    ...data,
                    id: detailsInfo.id,
                    retailPrice:+newMaterialDetal[0].retailPrice,
                    purchasePrice:+newMaterialDetal[0].purchasePrice
                    //materialDetal: newMaterialDetal
                } as IFormGoodsFields).then(res => {
                    res && onClose();
                    setLoading(false);
                    getGoodsOne(detailsInfo.id, mode)
                })
            } else {
                insertGoods({
                    ...data,
                    retailPrice:+newMaterialDetal[0].retailPrice,
                    purchasePrice:+newMaterialDetal[0].purchasePrice
                // materialDetal: newMaterialDetal
                } as IFormGoodsFields).then(res => {
                    setSelectedRowKeys([res as string])
                    res && onClose()
                    setLoading(false);
                })
            }
            
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
            setLoading(false);
        })
    }

    //实时保留缓存
    const onChange = (changedValues: { [key: string]: any }) => {
        detailsInfo.mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues);
    }
    const onSelectChanges = async (value: string, option: ISelectOption) => {
        let params = {
            pageSize: 1000,
            pageNum: 1,
            warehouseCode: value,
        }
        const { retData } = await warehouseService.shelfAllPage(params)
        const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
        setShelfAllList(newRecords)
    }

    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "配件编号",
                fieldName: "code",
                disable: mode != "add",
                rules: [{ required: true, message: "请输入配件编号" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "配件名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入配件名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "材料编号",
                fieldName: "materialNo",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "分类代码",
                fieldName: "genericCode",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "品牌",
                fieldName: "brandCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND),
                rules: [{ required: true, message: "请选择品牌" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                disable: true,
                fieldName: "shopName",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "商品类型",
                fieldName: "typeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
                rules: [{ required: true, message: "请选择商品类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "规格型号",
                fieldName: "model"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "产地",
                fieldName: "madeFrom",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "单位",
                fieldName: "unitCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_UNIT),
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库存上限",
                fieldName: "limitUpper",
                rules: [{ required: true, message: "请选择库存上限" }]

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库存下限",
                fieldName: "limitLower",
                rules: [{ required: true, message: "请选择库存下限" }]

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "最小起订量",
                fieldName: "minOrderNum"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "替换编号",
                fieldName: "replaceCode",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "原厂编号",
                fieldName: "factoryCode",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车型",
                fieldName: "vehicleModel",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "分组代码",
                fieldName: "groupingCode",
            }, {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "商品状态",
                fieldName: "enable"
            }
        ]
    };

    const otherFormItem: IYhFormItemsBox = {
        title: "其它信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                fieldName: "createUser",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改人",
                fieldName: "updateUser",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改时间",
                fieldName: "updateTime",
                disable: true,
            }
        ]
    }
    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal
    }
    const typeTitle = {
        add: "新增",
        edit: "编辑",
        look: "查看",
    }
    const supplierFromConfig: IYhFormBox = {
        formRef,
        labelCol: { span: 6 },
        disabled: mode === DetailType.look,
        labelAlign: "right",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem, otherFormItem]
    }

    const reset = async () => {
        formRef.current?.resetFields()
        form.resetFields()
        if (detailsInfo.mode === "add") {
            setAddFormVal(initForm)
            setWarehouseDetalList([{ ...initWarehouseDetalList }])
        } else if (detailsInfo.mode === "edit") {
            getGoodsOne(detailsInfo.id, mode)
        }
        message.success("重置成功")
    }
    const action = <>
        {mode != "look" &&
            <YhAuth resCode={ResourceAuthEnum.DATA_M_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    loading={loading}
                    onClick={debounce(() => { onSubmit() }, 900)}
                >保存</Button>
            </YhAuth>
        }
        {mode != "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { reset() }}
        >重置</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onClose}
        >取消</Button>
    </>;
    const setFormShelfs = (index: number, fieldName: string, fieldVal: ISelectOption) => {
        console.log(fieldVal)
        let fromVal: IMaterialDetail = form.getFieldsValue();
        let newVal = fromVal?.materialDetal?.[index];
        //判断更改仓库时仓库重新选择
        if (fieldName === "warehouseCode") {
            fromVal?.materialDetal?.splice(index, 1, { ...newVal, "warehouseName": fieldVal.label, warehouseId: getFindItem(fieldVal.value as string, warehouseList)?.id, "shelfCode": undefined });
            onSelectChanges(fieldVal.value as string, fieldVal)
        } else if (fieldName === "shelfCode") {
            console.log(getFindItem(fieldVal.value as string, shelfAllList), getFindItem(fieldVal.value as string, shelfAllList)?.id)
            fromVal?.materialDetal?.splice(index, 1, { ...newVal, "shelfId": getFindItem(fieldVal.value as string, shelfAllList)?.id as string });
        } else {
            fromVal?.materialDetal?.splice(index, 1, { ...newVal, [fieldName]: fieldVal.label });
        }
    }
    const typeWarehouseForm = {
        add: warehouseDetalList,
        edit: editWarehouseDetalList,
        look: lookWarehouseDetalList
    }
    useEffect(() => {
        form.setFieldsValue({ materialDetal: typeWarehouseForm[mode] });
    }, [typeWarehouseForm[mode]])
    return (
        <>
            <YhBox title={`配件档案${typeTitle[mode]}`} action={action} style={{ paddingBottom: "0px", marginBottom: "15px" }}></YhBox>
            <YhFormBox {...supplierFromConfig} />
            <Form form={form}
                autoComplete="off"
            >
                <YhBox title="价格，货位信息"
                >

                    <Form.List name="materialDetal">
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.map((field, index) => (
                                        <Space key={field.key}
                                            align="baseline"
                                            style={{ paddingLeft: "35px" }}
                                        >
                                            {/* <Form.Item
                                                {...field}
                                                name={[field.name, 'warehouseCode']}
                                                label="所属仓库"
                                                style={{ width: "350px" }}
                                                fieldKey={[field.fieldKey as any, 'warehouseCode']}
                                                //rules={[{ required: true, message: '请选择所属仓库' }]}

                                            >
                                                <Select
                                                    placeholder="所属仓库"
                                                    options={transOptions(warehouseList?.filter((item: any) => item.type === "warehouse-category.big"))}
                                                    allowClear={true}
                                                    showSearch={true}
                                                    optionFilterProp="label"

                                                    onChange={(value, option) => { option && setFormShelfs(index, 'warehouseCode', option as ISelectOption) }}
                                                    disabled={mode === DetailType.look}
                                                />
                                            </Form.Item> */}
                                            {/* <Form.Item
                                                {...field}
                                                name={[field.name, 'shelfCode']}
                                                label="所属货位"
                                                style={{ width: "350px" }}
                                                fieldKey={[field.fieldKey as any, 'shelfCode']}
                                               // rules={[{ required: true, message: '请选择所属货位' }]}

                                            >
                                                <Select

                                                    placeholder="所属货位"
                                                    options={transOptions(shelfAllList)}
                                                    allowClear={true}
                                                    showSearch={true}
                                                    optionFilterProp="label"
                                                    disabled={mode === DetailType.look}
                                                    onChange={(value, option) => { option && setFormShelfs(index, 'shelfCode', option as ISelectOption) }}

                                                />
                                            </Form.Item> */}
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'purchasePrice']}
                                                label="进货价"
                                                style={{ width: "350px" }}
                                                fieldKey={[field.fieldKey as any, 'purchasePrice']}
                                                rules={[{ required: true, message: '请输入进货价' }]}

                                            >
                                                <Input
                                                    placeholder="进货价"
                                                    disabled={mode === DetailType.look}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'retailPrice']}
                                                label="零售价"
                                                style={{ width: "350px" }}
                                                fieldKey={[field.fieldKey as any, 'retailPrice']}
                                                rules={[{ required: true, message: '请输入零售价' }]}

                                            >
                                                <Input
                                                    placeholder="零售价"
                                                    disabled={mode === DetailType.look}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'warehouseId']}
                                                label="仓库id"
                                                hidden={true}
                                                style={{ width: "350px" }}
                                                fieldKey={[field.fieldKey as any, 'warehouseId']}
                                            >
                                                <Input

                                                    placeholder="仓库id"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'warehouseName']}
                                                label="仓库名称"
                                                hidden={true}
                                                style={{ width: "350px" }}
                                                fieldKey={[field.fieldKey as any, 'warehouseName']}
                                            >
                                                <Input

                                                    placeholder="仓库名称"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'shelfId']}
                                                label="货位id"
                                                hidden={true}
                                                style={{ width: "350px" }}
                                                fieldKey={[field.fieldKey as any, 'shelfId']}
                                            >
                                                <Input

                                                    placeholder="货位id"
                                                />
                                            </Form.Item>
                                            {fields.length > 1 && mode !== DetailType.look ? (
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(field.name)}
                                                />
                                            ) : null}
                                        </Space>

                                    ))}
                                    {/* {
                                        mode !== DetailType.look && <Form.Item>
                                            <Button style={{ width: "100px", marginLeft: "110px" }} type="dashed" block onClick={() => add({ purchasePrice: 0, repairPrice: 0 })} icon={<PlusOutlined />}>增加</Button>
                                        </Form.Item>
                                    } */}

                                </>

                            )
                        }}
                    </Form.List>
                </YhBox>
            </Form>

        </>
    )
}