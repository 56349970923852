import React, { FC, useState } from "react";
import { Button, Input, message, Table, Tooltip, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { usePreWithdrawalDetailModel } from "./preWithdrawalDetailModel"
import { ISaleDetailsList } from "../../../../busBusinessType";
import { bigNumber, formatIntPoint, formatIntPoint1, formatNum} from "@/utils/utils";
import { DetailType } from "@/types/ScmEnums";
import { ColumnsType } from "antd/es/table";
import { components } from "@/components/YHEditTable/editTable";
import { importPropsConfig } from "@/utils/importFile";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { globalPrompt } from "@/components/message";
import { ITopType, ISelectOptions } from "@/types/ScmTypes";
import { ISelectOption } from "@/components/YhFormBox/Types";
import { stockStatisticService } from "@/views/stock/statistic/stockStatisticService";
import { useStockPoDetailModel } from "@/views/busBusiness/detailedInventory/InventoryDetailsModel";
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";
type ISCMSDcGoodsSearch = {
    data: any[],
    isSearch: boolean
}

export const MaterialTableBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const [warehouseOptions, setWarehouseOptions] = useState<ISelectOptions[]>([]);
    const [shelfOptions, setShelfOptions] = useState<ISelectOptions[]>([]);
    const { shopAndOrg, warehouseList } = useGlobalModel();
    const { shopId } = shopAndOrg

    const {
        setMaterialModel,detailMaterialEditDataRow,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        detailMaterialLookList,
        downloadTpl,
        // detailMaterialDetailList,
        setTopButsControl,
        modelMaterialScreenMethod,
        //setHistoryVisible,
        setHistoryRow,detailEditForm
    } = usePreWithdrawalDetailModel();
    const {setHistoryVisible} = useStockPoDetailModel()
    const onHistoryModel = (record: ISaleDetailsList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMSDcGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据
    const uniqueArr = (arr: any[], str: string) => {
        const res = new Map()
        return arr.filter((arr) => !res.has(arr[str]) && res.set(arr[str], 1))
    }
    const importCallback = async (list: any) => {
        const stockStatisticParams = {
            ...shopAndOrg,
            pageSize: 1000,
            pageNum: 1,
        }
        // const newDetailList = mode === "add" ? detailMaterialAddList : detailMaterialEditList
        // const newList = uniqueArr(list, "materialCode")
        // const { repetition, newDetailData } = await modelMaterialScreenMethod(newList, newDetailList)
        // console.log(newList)
        // console.log(repetition)
        // console.log(newDetailData)
        // debugger
        if (mode === "add") {
            setDetailMaterialAddList([...detailMaterialAddList, ...list])
            let upDetailData: any[] = [];
            for (let i = 0; i < list.length; i++) {
                let { materialCode } = list[i];
                let { retData: { records } } = await stockStatisticService.pages({ ...stockStatisticParams, materialCode });
                upDetailData.push({
                    ...list[i],
                    oldWarehouseList: records.map((item: any) => {
                        let { warehouseCode, warehouseName, shelfCode, shelfId } = item
                        return {
                            warehouseCode, warehouseName, shelfCode, shelfId
                        }
                    }),
                    id:'',
                    detailsId:list[i].id??'',
                    largeWarehouseCode: records[0]?.warehouseCode ?? "",
                    largeWarehouseName: records[0]?.warehouseName ?? "",
                    shelfCode: records[0]?.shelfCode ?? "",
                    shelfId: records[0]?.shelfId ?? "",
                })
            }
            setDetailMaterialAddList([...detailMaterialAddList, ...upDetailData]);
            // repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        } else if (mode === "edit") {
            setDetailMaterialEditList([...detailMaterialEditList, ...list])
            // repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
    }
    const switchModelViaible = () => {
        IFormRef.current?.validateFields().then(async res => {
            setMaterialModel(true)
        })
    }
    const warehousFocus = (record: any) => {
        let newMaterialDetal: any[] = []
        let obj: any = {}
        record?.oldWarehouseList?.forEach((item: any) => {
            if (!obj[item.warehouseCode]) {
                let { warehouseCode, warehouseName } = item
                newMaterialDetal.push({
                    label: warehouseName,
                    value: warehouseCode,
                })
                obj[item.warehouseCode] = true;
            }
        })
        setWarehouseOptions(newMaterialDetal)
    }
    const shelfFocus = (record: any) => {
        let { warehouseCode } = record
        let newMaterialDetal = record?.oldWarehouseList?.filter((filItem: any) => (
            warehouseCode === filItem.warehouseCode
        ))?.map((item: any) => {
            let { shelfCode, shelfId } = item
            return {
                label: shelfCode,
                value: shelfCode,
                code: shelfId
            }
        })
        setShelfOptions(newMaterialDetal)
    }
    const wsChanges = async (value: string, option: ISelectOption, record: any) => {
        const index = detailMaterialAddList.findIndex((item) => record.id === item.id);
        const item = detailMaterialAddList[index];
        const { num, oldWarehouseList } = record;
        const { purchasePrice } = oldWarehouseList.find((item: any) => value === item.warehouseCode)
        detailMaterialAddList.splice(index, 1, { ...item, shelfCode: "", shelfId: "", num, amount: +bigNumber.toFixed(bigNumber.times(num,purchasePrice),2) });
        setDetailMaterialAddList([...detailMaterialAddList])                                       
    }
        //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
        const goodsFilter = (dataSource: any | null = null) => {
            // const isEtit = mode !== "add";
            let goodsList = dataSource ? dataSource : typeMaterialList[mode];
            if (searchParms) {
                let reg = new RegExp(searchParms);
                let arr: any = [];
                goodsList.forEach((item: { materialCode: string; materialName: string; }) => {
                    if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                        arr.push(item);
                    }
                })
                setSearchVal({ data: arr, isSearch: true });
                if (!arr.length) {
                    message.warning("列表没有该商品！！")
                }
            } else {
                setSearchVal({ data: [], isSearch: false });
            }
        }
            //删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailMaterialDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailMaterialAddList.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialAddList(filterDetailList)
            let newSearchData = searchVal.data.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (mode === "edit") {
            const backendDetailList: any = detailMaterialEditDataRow.reduce((all: object[], next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);

            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
                setDetailMaterialEditList(filterDetailList)
                let newSearchData = searchVal.data.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
                setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
                globalPrompt("message", { text: `删除成功`, type: "success" });
                setDetailMaterialEditDataRowKeys([]);
                setDetailMaterialEditDataRow([]);
            }

            if (backendDetailList.length > 0) {
                await busBusinesstService.sreturnMaterialDelete({ ids: backendDetailList, returnCode: detailEditForm.returnCode as string }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        globalPrompt("message", { text: `删除失败`, type: "success" });
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    const action = <>
      <Input
                size="small"
                allowClear
                datatype="round"
                className="border-r-8 ml-10"
                placeholder="配件编号/配件名称"
                onChange={(e) => {setSearchParms(e.target.value );goodsFilter()}}
                onBlur={(e) => setSearchParms(e.target.value?.trim() ) }
                onPressEnter={() => goodsFilter()}
                style={{width:'200px'}}
            />
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 查询</Button>
        {mode !== "look" && <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => switchModelViaible()}
            >新增商品</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => detailMaterialDetailList(mode)}
            >删除商品</Button>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/transit/sale/details/import/${shopId}`, importCallback)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => downloadTpl().then()}
            >下载模板</Button>
        </>}
    </>;
    const MaterialColumns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '公交编码',
            width: 120,
            dataIndex: 'materialNo',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span">{text}</p></Tooltip>,
        }, {
            title: '商品编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '替换编号',
            width: 120,
            dataIndex: 'replaceCode',
        }, {
            title: '商品名称',
            width: 140,
            dataIndex: 'materialName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '退入仓库',
            width: 120,
            dataIndex: 'largeWarehouseName',
            //editable: mode != "look" ? true : false,
            //editType: "select",
            onSelectChange: wsChanges,
            options: warehouseOptions,
            onSelectFocus: warehousFocus,
            //render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '退入货位',
            width: 120,
            dataIndex: 'shelfCode',
            //editable: true,
            editType: "select",
            onSelectFocus: shelfFocus,
            options: shelfOptions,
        }, {
            title: '可用库存',
            width: 120,
            dataIndex: 'availableNum',
        }, {
            title: '退货数量',
            width: 120,
            dataIndex: 'num',
            editable: mode != "look" ? true : false,
        }, {
            title: '退货单价',
            width: 120,
            dataIndex: 'price',
            editable: mode != "look" ? true : false,
        }, {
            title: '退货金额',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '品牌',
            width: 120,
            dataIndex: 'brandName',
        }, {
            title: '单位',
            width: 120,
            dataIndex: 'unitName',
        }, {
            title: '规格',
            width: 120,
            dataIndex: 'model',
        }, {
            title: '成本价',
            width: 100,
            dataIndex: 'costPrice',
        }, {
            title: '成本金额',
            width: 100,
            dataIndex: 'costAmount',
        }, {
            title: '预售单号',
            width: 120,
            dataIndex: 'stockCode',
        }
    ];
    const newSelectMaterialColums = () =>
        MaterialColumns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: ISaleDetailsList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave = (row: ISaleDetailsList) => {
        let { num, price, availableNum, largeWarehouseCode,costPrice } = row as { num: number, price: number, availableNum: number, largeWarehouseCode: string,costPrice:number }
        let newPurPrice = price !== 0 ? price : costPrice
        price = +formatIntPoint1(newPurPrice,costPrice , `修改价格不应小于成本价${costPrice}`);
        num = +formatIntPoint(num, availableNum, "修改数量>=可用库存");  //数量
        price = +formatIntPoint(price);  //价格
        let newRow = {
            ...row,
            num,
            price: +formatNum(price),
            //amount: +formatNum(num * price),
            amount:+bigNumber.toFixed(bigNumber.times(num,price),2),
            largeWarehouseName: warehouseOptions.find(item => largeWarehouseCode === item.value)?.label as string,
        }

        let newData = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailMaterialEditList([...newData]) : setDetailMaterialAddList([...newData])
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(newData)
        }
        setTopButsControl(true)
    }
    const typeMaterialList = {
        "add": [...detailMaterialAddList],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    //设置维修用料选中的行和数据
    const setTypeMaterialDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeMaterialDetailDataRow = async (selectedRows: ISaleDetailsList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectMaterialColums() as ColumnsType<ISaleDetailsList>}
                dataSource={searchVal.isSearch ? searchVal.data :typeMaterialList[mode]}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailMaterialAddDataRowKeys : detailMaterialEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleDetailsList[]) => {
                            setTypeMaterialDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        }
                    }}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }}
            />
        </YhBox>
    )
}