import React, { FC } from "react";
import { ISearchField, ISearchProp, SearchTypeEnum } from "../Types";
import { SearchInput } from "./SearchInput";
import { SearchDatepicker } from "./SearchDatepicker";
import { SearchRangePicker } from "./SearchRangePicker";
import { SearchSelect } from "./SearchSelect";
import { SearchCheckBox } from "./SearchCheckBox";
import { SearchTreeSelect } from "./SearchTreeSelect";
import { Col } from "antd";
import { SearchFmsRangePicker } from "./SearchFmsRangePicker";


type PropType = ISearchProp & ISearchField;
export const SearchItem: FC<PropType> = (props) => {

    return <Col span={props.span ?? 8} xxl={props.xxl ?? 4} key={props.fieldName}>
        {
            (() => {
                switch (props.type) {
                    case SearchTypeEnum.Input:
                        return <SearchInput {...props} />;
                    case SearchTypeEnum.Datepicker:
                        return <SearchDatepicker {...props} />;
                    case SearchTypeEnum.RangePicker:
                        return <SearchRangePicker {...props} />;
                    case SearchTypeEnum.Select:
                        return <SearchSelect {...props} />;
                    case SearchTypeEnum.CheckBox:
                        return <SearchCheckBox {...props} />;
                        case SearchTypeEnum.TreeSelect:
                            return <SearchTreeSelect {...props} />;
                        case SearchTypeEnum.FmsRangePicker:
                            return <SearchFmsRangePicker {...props} />;
                        default:
                            return <></>;
                }
            })()
        }
    </Col>
}