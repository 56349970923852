import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import { IVehicleSearch, IVehicleDetail } from "./vehicleType"

class VehicleService {
    async page(searchVo: IVehicleSearch) {
        const apiUrl = "/bcm/base/vehicle/findPage";
        return await httpRequest.post<IPage<IVehicleDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/base/vehicle/findById/${id}`;
        return await httpRequest.get<IVehicleDetail>(apiUrl);
    }
    async insert(insertVo: IVehicleDetail) {
        const apiUrl = "/bcm/base/vehicle/insert";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }
    async update(updateVo: IVehicleDetail) {
        const apiUrl = "/bcm/base/vehicle/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //启用禁用
    async disable(params: object) {
        const apiUrl = `/bcm/base/vehicle/change`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导入
    async import(file: { file: File }) {
        const appPageUrl = "/bcm/base/vehicle/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //导出
    async export(searchVo: IVehicleSearch) {
        const appPageUrl = "/bcm/base/vehicle/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/bcm/base/vehicle/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
}
export const vehicleService = new VehicleService();