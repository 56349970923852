import { FormInstance, Rule } from 'antd/es/form';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColProps } from 'antd/lib/grid/col';
import { FormLabelAlign } from 'antd/lib/form/interface';
import { IYhBox } from '../YhBox/Types';
import { MutableRefObject, Ref } from 'react';
import { NamePath, Store } from 'rc-field-form/es/interface';
import { Moment } from 'moment';
import { ISelectOptions } from '@/types/ScmTypes';
export enum FormItemTypeEnum {
	Input,
	Select,
	Datepicker,
	Switch,
	Checkbox,
	FileImage,
	Button,
	TreeSelect,
	Other,
	AsyncSelect,
}

export interface ITreeData {
	value: string;
	title: string;
	children: ISelectOption[];
}

export interface ISelectOption {
	label: string;
	value: string | number;
	relationvalue?: string;
	[key: string]: any;
}

export interface IFormItem {
	type: FormItemTypeEnum;
	fieldLabel: string | JSX.Element;
	fieldName: string;
	placeholder?: string;
	ds?: ITreeData[] | ISelectOption[];
	labelCol?: { span?: number; offset?: number };
	hidden?: boolean;
	allowClear?: boolean;
	showSearch?: boolean;
	mode?: 'multiple' | 'tags' | undefined;
	size?: SizeType;
	disable?: boolean;
	multiple?: boolean;
	labelInValue?: boolean;
	rules?: Rule[];
	span?: number;
	colClassName?: string;
	onSetFieldsValue?: (formValues: { [key: string]: any }) => void;
	onSelectChange?: (value: string, option: ISelectOption) => void;
	onSelectFocus?: () => void;
	disabledDate?: (current: Moment) => boolean;
	selectfetchList?: (search: string) => Promise<ISelectOption[]>;
	options?: ISelectOptions[];
	md5List?: string[];
	prefix?: string;
	suffix?: string;
	btnTitle?: string;

	[key: string]: any;
}

export interface IYhFormItemsBox extends IYhBox {
	disabled?: boolean;
	items: IFormItem[];
	justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
	IclassName?: string;
}

export interface IYhFormBox<IValues = any> {
	formRef?: MutableRefObject<IYhFormRef | undefined>;
	colon?: boolean;
	name?: string;
	labelCol?: ColProps;
	wrapperCol?: ColProps;
	labelAlign?: FormLabelAlign;
	onChange?: (changedValues: { [key: string]: any }, values?: IValues) => void;
	onFinish?: (values: IValues) => void;
	onReset?: () => {};
	formValues?: {};
	disabled?: boolean;
	boxs: IYhFormItemsBox[];
}

export interface IYhFormRef {
	validateFields: () => Promise<Store>;
	getFieldsValue: () => any;
	setFieldsValue: (changedValues: { [key: string]: any }) => void;
	resetFields: (fields?: NamePath[]) => void;
	inst: () => FormInstance;
}

export interface IYhForm<T = any> {
	formRef?: MutableRefObject<IYhFormRef | undefined>;
	colon?: boolean;
	name?: string;
	labelCol?: ColProps;
	labelAlign?: FormLabelAlign;
	onChange?: (changedValues: { [key: string]: any }, values?: T) => void;
	onFinish?: (values: T) => void;
	onReset?: () => {};
	formValues?: any;
	disabled?: boolean;
	items: IFormItem[];
	justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
}
