//作废的功能禁用 末尾添加hide按钮会隐藏  去掉hide和门户鉴权功能录入保持一致按钮会显示
export enum ResourceAuthEnum {
    //采购单（新增）
    P_AD = "scm.p.p.ad",
    //采购单（新增-补单）
    P_AD_REPL = "scm.p.p.ad.repl",
    //采购单（新增-保存）
    P_AD_SAVE = "scm.p.p.ad.save",
    //采购单（编辑）
    P_ED = "scm.p.p.ed",
    //采购单（编辑-打印）
    P_ED_PRINT = "scm.p.p.ed.print",
    //采购单（编辑-作废）
    P_ED_CANCEL = "scm.p.p.ed.cancel",
    //采购单（编辑-保存）
    P_ED_SAVE = "scm.p.p.ed.save",
    //采购单（编辑-转库房）
    P_ED_SUBMIT = "scm.p.p.ed.submit",
    //采购单（编辑-库房确认）
    P_ED_CONFIRM = "scm.p.p.ed.confirm",
    //采购单（编辑-直发确认）
    P_ED_CONFIRM_DIRECT = "scm.p.p.ed.direct-confirm",
    //采购单（编辑-结算）
    P_ED_BILL = "scm.p.p.ed.bill",
    //采购单（编辑-取消结算）
    P_ED_CNL_BILL = "scm.p.p.ed.cnl-bill",
    //采购单（编辑-转销售单）
    P_ED_TRANS = "scm.p.p.ed.trans",
    //采购单（编辑-新增商品）
    P_ED_ADD_M = "scm.p.p.ed.add-m",
    //采购单（编辑-删除商品）
    P_ED_DEL_M = "scm.p.p.ed.del-m",
    //采购单（编辑-下载模板）
    P_ED_DLOAD = "scm.p.p.ed.dload",
    //采购单（编辑-导入商品）
    P_ED_IM = "scm.p.p.ed.im",
    //采购单（编辑-导出商品）
    P_ED_EX = "scm.p.p.ed.ex",
    //采购单（编辑-入库登记）
    P_ED_RECORD_IO = "scm.p.p.ed.record-io",
    //采购单（取消结算明细）
    P_CNL_BILL = "scm.p.p.cnl-bill",
    //采购单（导出）
    P_EX = "scm.p.p.ex",
    //采购单（添加临时货位）
    P_ED_TEMP_SHELF = "scm.p.p.ed.temp-shelf",

    //采购退货（新增）
    PR_AD = "scm.p.pr.ad",
    //采购退货（新增-保存）
    PR_AD_SAVE = "scm.p.pr.ad.save",
    //采购退货（编辑）
    PR_ED = "scm.p.pr.ed",
    //采购退货（编辑-打印）
    PR_ED_PRINT = "scm.p.pr.ed.print",
    //采购退货（编辑-作废）
    PR_ED_CANCEL = "scm.p.pr.ed.cancel",
    //采购退货（编辑-保存）
    PR_ED_SAVE = "scm.p.pr.ed.save",
    //采购退货（编辑-转库房）
    PR_ED_SUBMIT = "scm.p.pr.ed.submit",
    //采购退货（编辑-库房确认）
    PR_ED_CONFIRM = "scm.p.pr.ed.confirm",
    //采购退货（编辑-结算）
    PR_ED_BILL = "scm.p.pr.ed.bill",
    //采购退货（编辑-物流登记）
    PR_ED_LOGISTIC = "scm.p.pr.ed.logistic",
    //采购退货（编辑-新增商品）
    PR_ED_ADD_M = "scm.p.pr.ed.add-m",
    //采购退货（编辑-删除商品）
    PR_ED_DEL_M = "scm.p.pr.ed.del-m",
    //采购退货（编辑-下载模板）
    PR_ED_DLOAD = "scm.p.pr.ed.dload",
    //采购退货（编辑-导入商品）
    PR_ED_IM = "scm.p.pr.ed.im",
    //采购退货（编辑-导出商品）
    PR_ED_EX = "scm.p.pr.ed.ex",
    //采购退货（编辑-出库登记）
    PR_ED_RECORD_IO = "scm.p.pr.ed.record-io",
    //采购退货（导出）
    PR_EX = "scm.p.pr.ex",

    //销售订单（新增）
    SO_AD = "scm.s.so.ad",
    //销售订单（新增-保存）
    SO_AD_SAVE = "scm.s.so.ad.save",
    //销售订单（编辑）
    SO_ED = "scm.s.so.ed",
    //销售订单（编辑-打印）
    SO_ED_PRINT = "scm.s.so.ed.print",
    //销售订单（编辑-作废）
    SO_ED_CANCEL = "scm.s.so.ed.cancel",
    //销售订单（编辑-保存）
    SO_ED_SAVE = "scm.s.so.ed.save",
    //销售订单（编辑-转销售单）
    SO_ED_TRANS = "scm.s.so.ed.trans",
    //销售订单（编辑-新增商品）
    SO_ED_ADD_M = "scm.s.so.ed.add-m",
    //销售订单（编辑-删除商品）
    SO_ED_DEL_M = "scm.s.so.ed.del-m",
    //销售订单（编辑-下载模板）
    SO_ED_DLOAD = "scm.s.so.ed.dload",
    //销售订单（编辑-导入商品）
    SO_ED_IM = "scm.s.so.ed.im",
    //销售订单（编辑-导出商品）
    SO_ED_EX = "scm.s.so.ed.ex",
    //销售订单（导出）
    SO_EXPORT = "scm.s.so.export",

    //销售单（新增）
    S_AD = "scm.s.s.ad",
    //销售单（新增 -补单）
    S_AD_REPL = "scm.s.s.ad.repl",
    //销售单（新增-保存）
    S_AD_SAVE = "scm.s.s.save",
    //销售单（编辑）
    S_ED = "scm.s.s.ed",
    //销售单（编辑-打印）
    S_ED_PRINT = "scm.s.s.ed.print",
    //销售单（编辑-作废）
    S_ED_CANCEL = "scm.s.s.ed.cancel",
    //销售单（编辑-保存）
    S_ED_SAVE = "scm.s.s.ed.save",
    // 销售单(编辑-审核)
    S_ED_APPROVE = "scm.s.s.ed.approve",
    //销售单（编辑-提交）
    S_ED_SUBMIT = "scm.s.s.ed.submit",
    //销售单（编辑-结算）
    S_ED_BILL = "scm.s.s.ed.bill",
    //销售单（编辑-取消结算）
    S_ED_CNL_BILL = "scm.s.s.ed.cnl-bill",
    //销售单（编辑-转退单）
    S_ED_TRANS = "scm.s.s.ed.trans",
    //销售单（编辑-物流登记）
    S_ED_LOGISTIC = "scm.s.s.ed.logistic",
    //销售单（编辑-新增商品）
    S_ED_ADD_M = "scm.s.s.ed.add-m",
    //销售单（编辑-删除商品）
    S_ED_DEL_M = "scm.s.s.ed.del-m",
    //销售单（编辑-下载模板）
    S_ED_DLOAD = "scm.s.s.ed.dload",
    //销售单（编辑-导入商品）
    S_ED_IM = "scm.s.s.ed.im",
    //销售单（编辑-导出商品）
    S_ED_EX = "scm.s.s.ed.ex",
    //销售单（绑定）
    S_BIND = "scm.s.s.bind",
    //销售单（绑定明细）
    S_BIND_DETAIL = "scm.s.s.bind-detail",
    //销售单（取消结算明细）
    S_CNL_BILL = "scm.s.s.cnl-bill",
    //销售单（导出）
    S_EXPORT = "scm.s.s.export",
    //销售单（派工）
    S_DISPATCH = "scm.s.s.dispatch",
    //销售单（编辑-库房确认）
    S_ED_CONFIRM = "scm.s.s.ed.confirm",
    //销售单（编辑-直发库房确认）
    S_ED_CONFIRM_DRIECT = "scm.s.s.ed.direct-confirm",
    //销售单（整单退货）
    S_ED_RETURN_ALL = "scm.s.s.ed.return-all",

    //销售退货（新增）
    SR_AD = "scm.s.sr.ad",
    //销售退货（新增-保存）
    SR_AD_SAVE = "scm.s.sr.ad.save",
    //销售退货（编辑）
    SR_ED = "scm.s.sr.ed",
    //销售退货（编辑-打印）
    SR_ED_PRINT = "scm.s.sr.ed.print",
    //销售退货（编辑-作废）
    SR_ED_CANCEL = "scm.s.sr.ed.cancel",
    //销售退货（编辑-保存）
    SR_ED_SAVE = "scm.s.sr.ed.save",
    //销售退货（编辑-转库房）
    SR_ED_SUBMIT = "scm.s.sr.ed.submit",
    //销售退货（编辑-库房确认）
    SR_ED_CONFIRM = "scm.s.sr.ed.confirm",
    //销售退货（编辑-结算）
    SR_ED_BILL = "scm.s.sr.ed.bill",
    //销售退货（编辑-新增商品）
    SR_ED_ADD_M = "scm.s.sr.ed.add-m",
    //销售退货（编辑-删除商品）
    SR_ED_DEL_M = "scm.s.sr.ed.del-m",
    //销售退货（编辑-下载模板）
    SR_ED_DLOAD = "scm.s.sr.ed.dload",
    //销售退货（编辑-导入商品）
    SR_ED_IM = "scm.s.sr.ed.im",
    //销售退货（编辑-导出商品）
    SR_ED_EX = "scm.s.sr.ed.ex",
    //销售退货（编辑-入库登记）
    SR_ED_RECORD_IO = "scm.s.sr.ed.record-io",
    //销售退货（导出）
    SR_EXPORT = "scm.s.sr.export",

    // 非关联退货(新增)
    UNUSUAL_AD = "scm.s.unusual.ad",
    // 编辑
    UNUSUAL_ED = "scm.s.unusual.ed",
    // 导出
    UNUSUAL_EXPORT = "scm.s.unusual.export",
    // 编辑-下载模板
    UNUSUAL_ED_DLOAD= "scm.s.unusual.ed.dload",
    // 编辑-导入商品
    UNUSUAL_ED_IM= "scm.s.unusual.ed.im",
    // 编辑-打印
    UNUSUAL_ED_PRINT= "scm.s.unusual.ed.print",
    // 编辑-删除商品
    UNUSUAL_ED_DEL_M= "scm.s.unusual.ed.del-m",
    // 编辑-结算
    UNUSUAL_ED_BILL= "scm.s.unusual.ed.bill",
    // 编辑-转库房
    UNUSUAL_ED_SUBMIT= "scm.s.unusual.ed.submit",
    // 新增-保存
    UNUSUAL_AD_SAVE= "scm.s.unusual.ad.save",
    // 编辑-新增商品
    UNUSUAL_ED_ADD_M= "scm.s.unusual.ed.add-m",
    // 编辑-库房确认
    UNUSUAL_ED_CONFIRM= "scm.s.unusual.ed.confirm",
    // 编辑-入库登记
    UNUSUAL_ED_RECORD_IO = "scm.s.unusual.ed.record-io",
    // 编辑-保存
    UNUSUAL_ED_SAVE = "scm.s.unusual.ed.save",
    // 编辑-导出商品
    UNUSUAL_ED_EX = "scm.s.unusual.ed.ex",
    // 编辑-审核
    UNUSUAL_ED_AUDIT = "scm.s.unusual.ed.audit",
    // 编辑-提交
    UNUSUAL_ED_SUBMISSION= "scm.s.unusual.ed.submission",
    //库存统计 (转移货位)
    ST_INVENT_TRANS = "scm.st.invent.trans",
    //库存统计 (转移记录)
    ST_INVENT_TRANS_LOG = "scm.st.invent.trans-log",
    //库存统计 (导出)
    ST_INVENT_EX = "scm.st.invent.ex",
    //库存统计 (积压标记初始化)
    ST_INVENT_IM_OVERSTOCK = "scm.st.invent.im-overstock",
    // 库存统计（库存核验）
    ST_INVENT_IM_CHECK = "scm.st.invent.check",


    //库存管理 采购明细(转移货位)
    ST_PO_TRANS = "scm.st.po.trans",
    //库存管理 采购明细(转移直发货位)
    ST_PO_TRANS_STRAIGHT = "scm.st.po.trans-straight",

    //库存盘点 (新增)
    ST_STTAKE_AD = "scm.st.sttake.ad",
    //库存盘点 (保存)
    ST_STTAKE_AD_SAVE = "scm.st.sttake.ad.save",
    //库存盘点 (编辑)
    ST_STTAKE_ED = "scm.st.sttake.ed",
    //库存盘点 (导出)
    ST_STTAKE_EXPORT = "scm.st.sttake.ex",
    //库存盘点 (编辑-作废)
    ST_STTAKE_ED_CANCEL = "scm.st.sttake.ed.cancel",
    //库存盘点 (编辑-盘点完成)
    ST_STTAKE_ED_FINISH = "scm.st.sttake.ed.finish",
    //库存盘点 (编辑-保存)
    ST_STTAKE_ED_SAVE = "scm.st.sttake.ed.save",
    //库存盘点 (编辑-新增商品)
    ST_STTAKE_ED_ADD_M = "scm.st.sttake.ed.add-m",
    //库存盘点 (编辑-删除商品)
    ST_STTAKE_ED_EDL_M = "scm.st.sttake.ed.edl-m",
    //库存盘点 (编辑-导出盘库商品)
    ST_STTAKE_ED_DLOAD = "scm.st.sttake.ed.dload",
    //库存盘点 (编辑-导入商品)
    ST_STTAKE_ED_IM = "scm.st.sttake.ed.im",
    //库存盘点 (编辑-导出明细)
    ST_STTAKE_ED_EX = "scm.st.sttake.ed.ex",

    //入库单 (查看)
    ST_IN_VW = "scm.st.in.vw",
    //入库单 (导出)
    ST_IN_EX = "scm.st.in.ex",

    //出库单 (查看)
    ST_OUT_VW = "scm.st.out.vw",
    //出库单 (导出)
    ST_OUT_EX = "scm.st.out.ex",


    //出库配送单 (查看)
    ST_DELIVER_VW = "scm.st.deliver.vw",
    //出库配送单 (导出)
    ST_DELIVER_EX = "scm.st.deliver.ex",

    //出入库操作表 (导出)
    ST_STAFF_EX = "scm.st.staff.ex",

    //商品管理 (新增)
    DATA_M_AD = "scm.data.m.ad",
    //商品管理 (新增-保存)
    DATA_M_AD_SAVE = "scm.data.m.ad.save",
    //商品管理 (编辑)
    DATA_M_ED = "scm.data.m.ed",
    //商品管理 (编辑 - 保存)
    DATA_M_ED_SAVE = "scm.data.m.ed.save",
    //商品管理 (导入)
    DATA_M_IM = "scm.data.m.im",
    //商品管理 (导出)
    DATA_M_EX = "scm.data.m.ex",
    //商品管理 (下载模板)
    DATA_M_DLOAD = "scm.data.m.dload",
    //商品管理 (导入图片)
    DATA_M_IM_PHOTO = "scm.data.m.im-photo",
    //商品管理 (更新价格)
    DATA_M_IM_PRICE = "scm.data.m.im-price",

    //客户管理 (新增)
    DATA_C_AD = "scm.data.c.ad",
    //客户管理 (新增-保存)
    DATA_C_AD_SAVE = "scm.data.c.ad.save",
    //客户管理 (编辑)
    DATA_C_ED = "scm.data.c.ed",
    //客户管理 (编辑 - 保存)
    DATA_C_ED_SAVE = "scm.data.c.ed.save",
    //客户管理 (导入)
    DATA_C_IM = "scm.data.c.im",
    //客户管理 (导出)
    DATA_C_EX = "scm.data.c.ex",
    //客户管理 (下载模板)
    DATA_C_DLOAD = "scm.data.c.dload",
    //客户管理 (转成供应商)
    DATA_C_TRANS_S = "scm.data.c.trans-s",
    //客户 门店共享
    DATA_C_SHARE = "scm.data.c.share",

    //供应商 门店共享
    DATA_S_SHARE = "scm.data.s.share",
    //供应商管理 (新增)
    DATA_S_AD = "scm.data.s.ad",
    //供应商管理 (新增-保存)
    DATA_S_AD_SAVE = "scm.data.s.ad.save",
    //供应商管理 (编辑)
    DATA_S_ED = "scm.data.s.ed",
    //供应商管理 (编辑 - 保存)
    DATA_S_ED_SAVE = "scm.data.s.ed.save",
    //供应商管理 (导入)
    DATA_S_IM = "scm.data.s.im",
    //供应商管理 (导出)
    DATA_S_EX = "scm.data.s.ex",
    //供应商管理 (下载模板)
    DATA_S_DLOAD = "scm.data.s.dload",
    //供应商管理 (转成供应商)
    DATA_S_TRANS_C = "scm.data.s.trans-c",

    //仓库管理 (新增)
    DATA_W_AD = "scm.data.w.ad",
    //仓库管理 (新增-保存)
    DATA_W_AD_SAVE = "scm.data.w.ad.save",
    //仓库管理 (编辑)
    DATA_W_ED = "scm.data.w.ed",
    //仓库管理 (编辑 - 保存)
    DATA_W_ED_SAVE = "scm.data.w.ed.save",

    //仓库管理 (编辑 - 区域信息 tab)
    DATA_W_ED_AREA = "scm.data.w.ed.area",
    //仓库管理 (编辑 - 区域信息 tab -新增)
    DATA_W_ED_AREA_AD = "scm.data.w.ed.area.ad",
    //仓库管理 (编辑 - 区域信息 tab -编辑)
    DATA_W_ED_AREA_ED = "scm.data.w.ed.area.ed",
    //仓库管理 (编辑 - 区域信息 tab - 导入)
    DATA_W_ED_AREA_IM = "scm.data.w.ed.area.im",
    //仓库管理 (编辑 - 区域信息 tab - 下载模板)
    DATA_W_ED_AREA_DLOAD = "scm.data.w.ed.area.dload",

    //仓库管理 (编辑 - 货架信息 tab)
    DATA_W_ED_SHELVES = "scm.data.w.ed.shelves",
    //仓库管理 (编辑 - 货架信息 tab -新增)
    DATA_W_ED_SHELVES_AD = "scm.data.w.ed.shelves.ad",
    //仓库管理 (编辑 - 货架信息 tab -编辑)
    DATA_W_ED_SHELVES_ED = "scm.data.w.ed.shelves.ed",
    //仓库管理 (编辑 - 货架信息 tab - 导入)
    DATA_W_ED_SHELVES_IM = "scm.data.w.ed.shelves.im",
    //仓库管理 (编辑 - 货架信息 tab - 下载模板)
    DATA_W_ED_SHELVES_DLOAD = "scm.data.w.ed.shelves.dload",

    //仓库管理 (编辑 - 货位信息 tab)
    DATA_W_ED_SHELF = "scm.data.w.ed.shelf",
    //仓库管理 (编辑 - 货位信息 tab -新增)
    DATA_W_ED_SHELF_AD = "scm.data.w.ed.shelf.ad",
    //仓库管理 (编辑 - 货位信息 tab -编辑)
    DATA_W_ED_SHELF_ED = "scm.data.w.ed.shelf.ed",
    //仓库管理 (编辑 - 货位信息 tab - 导入)
    DATA_W_ED_SHELF_IM = "scm.data.w.ed.shelf.im",
    //仓库管理 (编辑 - 货位信息 tab - 下载模板)
    DATA_W_ED_SHELF_DLOAD = "scm.data.w.ed.shelf.dload",
    //仓库管理 (编辑 - 货位信息 tab - 导出)
    DATA_W_ED_SHELF_EX = "scm.data.w.ed.shelf.ex",

    //数据字典 (增加下级)
    DATA_DIC_SUB = "scm.data.dic.sub",
    //数据字典 (增加同级)
    DATA_DIC_BRO = "scm.data.dic.bro",
    //数据字典 (同步)
    DATA_DIC_SYNC = "scm.data.dic.sync",


    //机构管理 (同步)
    ORG_ORG_SYNC = "scm.org.org.sync",

    //门店管理 (新增)
    ORG_SHOP_AD = "scm.org.shop.ad",
    //门店管理 (编辑)
    ORG_SHOP_ED = "scm.org.shop.ed",

    //经营实体管理 (查看)
    ORG_ENTITY_VW = "scm.org.entity.vw",
    //经营实体管理 (同步)
    ORG_ENTITY_SYNC = "scm.org.entity.sync",

    /*fms*/
    //客户账户管理 （查看）
    ACC_C_VW = "scm.ac.c.vw",
    //客户账户管理 （账户初始化）
    ACC_C_INIT = "scm.ac.c.init",

    //客户账户管理详情 （账户信息）
    ACC_C_VW_AC = "scm.ac.c.vw.ac",
    //客户账户管理详情 （账户信息-开户）
    ACC_C_VW_AC_INIT = "scm.ac.c.vw.ac.init",
    //客户账户管理详情 （账户信息-调额）
    ACC_C_VW_AC_ADJ = "scm.ac.c.vw.ac.adj",
    //客户账户管理详情 （账户信息-充值）
    ACC_C_VW_AC_CHARG = "scm.ac.c.vw.ac.charg",
    //客户账户管理详情 （账户信息-转账）
    ACC_C_VW_AC_TRANS = "scm.ac.c.vw.ac.trans",
    //客户账户管理详情 （账户信息-核销账单）
    ACC_C_VW_AC_W_OFF = "scm.ac.c.vw.ac.w-off",
    //客户账户管理详情 （账户信息-启用禁用）
    ACC_C_VW_AC_DISABLE = "scm.ac.c.vw.ac.disable",

    //客户账户管理详情 （调额记录）
    ACC_C_VW_ADJ = "scm.ac.c.vw.adj",
    //客户账户管理详情 （调额记录-审核）
    ACC_C_VW_ADJ_AUDIT = "scm.ac.c.vw.adj.audit",

    //客户账户管理详情 （充值记录）
    ACC_C_VW_CHARG = "scm.ac.c.vw.charg",
    //客户账户管理详情 （充值记录-审核）
    ACC_C_VW_CHARG_AUDIT = "scm.ac.c.vw.charg.audit",
    //客户账户管理详情 （转账记录）
    ACC_C_VW_TRANS = "scm.ac.c.vw.trans",

    //客户账户管理详情 （账单信息）
    ACC_C_VW_BILL = "scm.ac.c.vw.bill",
    //客户账户管理详情 （账单信息-核销）
    ACC_C_VW_BILL_W_OFF = "scm.ac.c.vw.bill.w-off",
    //客户账户管理详情 （账单信息-批量核销）
    ACC_C_VW_BILL_W_OFF_BATCH = "scm.ac.c.vw.bill.w-off-batch",

    //客户账户管理详情 （核销记录）
    ACC_C_VW_W_OFF = "scm.ac.c.vw.w-off",
    //客户账户管理详情 （操作日志）
    ACC_C_VW_LOG = "scm.ac.c.vw.log",

    //供应商账户管理 （查看）
    ACC_S_VW = "scm.ac.s.vw",
    //供应商账户管理 （账户初始化）
    ACC_S_INIT = "scm.ac.s.init",

    //供应商账户管理详情 （账户信息）
    ACC_S_VW_AC = "scm.ac.s.vw.ac",
    //供应商账户管理详情 （账户信息-开户）
    ACC_S_VW_AC_INIT = "scm.ac.s.vw.ac.init",
    //供应商账户管理详情 （账户信息-调额）
    ACC_S_VW_AC_ADJ = "scm.ac.s.vw.ac.adj",
    //供应商账户管理详情 （账户信息-充值）
    ACC_S_VW_AC_CHARG = "scm.ac.s.vw.ac.charg",
    //供应商账户管理详情 （账户信息-转账）
    ACC_S_VW_AC_TRANS = "scm.ac.s.vw.ac.trans",
    //供应商账户管理详情 （账户信息-核销账单）
    ACC_S_VW_AC_W_OFF = "scm.ac.s.vw.ac.w-off",
    //供应商账户管理详情 （账户信息-批量核销）
    ACC_S_VW_AC_W_OFF_BATCH = "scm.ac.s.vw.ac.w-off-batch",
    //供应商账户管理详情 （账户信息-启用禁用）
    ACC_S_VW_AC_DISABLE = "scm.ac.s.vw.ac.disable",

    //供应商账户管理详情 （调额记录）
    ACC_S_VW_ADJ = "scm.ac.s.vw.adj",
    //供应商账户管理详情 （调额记录-审核）
    ACC_S_VW_ADJ_AUDIT = "scm.ac.s.vw.adj.audit",

    //供应商账户管理详情 （充值记录）
    ACC_S_VW_CHARG = "scm.ac.s.vw.charg",
    //供应商账户管理详情 （充值记录-审核）
    ACC_S_VW_CHARG_AUDIT = "scm.ac.s.vw.charg.audit",
    //供应商账户管理详情 （转账记录）
    ACC_S_VW_TRANS = "scm.ac.s.vw.trans",

    //供应商账户管理详情 （账单信息）
    ACC_S_VW_BILL = "scm.ac.s.vw.bill",
    //供应商账户管理详情 （账单信息-核销）
    ACC_S_VW_BILL_W_OFF = "scm.ac.s.vw.bill.w-off",

    //供应商账户管理详情 （核销记录）
    ACC_S_VW_W_OFF = "scm.ac.s.vw.w-off",
    //供应商账户管理详情 （操作日志）
    ACC_S_VW_LOG = "scm.ac.s.vw.log",

    //客户账单管理-导出
    BILL_C_EX = "scm.biil.c.ex",
    //客户账户管理-账单信息-导出
    ACC_BILL_C_EX = "scm.ac.c.vw.bill.ex",
    //供应商账单管理-导出
    BILL_S_EX = "scm.biil.s.ex",
    //供应商账户管理-账单信息-导出
    ACC_BILL_S_EX = "scm.ac.s.vw.bill.ex",

    //客户信息管理(查看)
    DATA_C_VW = "scm.data.c.vw",
    //客户信息管理(同步)
    DATA_C_SYNC = "scm.data.c.sync",

    //供应商管理(查看)
    DATA_S_VW = "scm.data.s.vw",
    //供应商管理(同步)
    DATA_S_SYNC = "scm.data.s.sync",

    //进销存明细表
    STT_SPS_EX = "scms.stt.sps.ex",
    //采购单报表
    STT_PL_EX = "scms.stt.pl.ex",
    //采购单明细表
    STT_P_EX = "scms.stt.p.ex",
    //销售单报表
    STT_SL_EX = "scms.stt.sl.ex",
    //销售单明细表
    STT_S_EX = "scms.stt.s.ex",
    //库存盘点情况
    STT_ST_EX = "scms.stt.st.ex",
    //配件交易频次
    STT_TRADE_EX = "scms.stt.trade.ex",
    //配件销售参考
    STT_M_EX = "scms.stt.m-s.ex",

    //调入单编辑、
    P_DR_ED = "scm.p.dr.ed",
    //调入单请调、
    P_DR_AD_APPLY = "scm.p.dr.ad.apply",
    //调入单-删除商品、
    P_DR_AD_DEL_M = "scm.p.dr.ad.del-m",
    //调入单-调入、
    P_DR_ED_DR = "scm.p.dr.ed.dr",
    //调入单-导入明细、
    P_DR_AD_IM = "scm.p.dr.ad.im",
    //调入单-新增、
    P_DR_AD = "scm.p.dr.ad",
    //调入单-新增商品、
    P_DR_AD_ADD_M = "scm.p.dr.ad.add-m",
    //调入单-导出、
    P_DR_EX = "scm.p.dr.ex",
    //调入单-导出明细、
    P_DR_ED_EX = "scm.p.dr.ed.ex",
    //调入单-打印、
    P_DR_ED_PRINT = "scm.p.dr.ed.print",
    //调入单-下载模板、
    P_DR_AD_DLOAD = "scm.p.dr.ad.dload",
    //调入单（添加临时货位）
    P_DR_TEMP_SHELF = "scm.p.dr.ed.temp-shelf",

    //调出单-删除商品、
    S_DC_ED_DEL_M = "scm.s.dc.ed.del-m",
    //调出单-导入明细、
    S_DC_ED_IM = "scm.s.dc.ed.im",
    //调出单-导出、
    S_DC_EX = "scm.s.dc.ex",
    //调出单-打印、
    S_DC_ED_PRINT = "scm.s.dc.ed.print",
    //调出单-调出、
    S_DC_ED_DC = "scm.s.dc.ed.dc",
    //调出单编辑-保存、
    S_DC_ED_SAVE = "scm.s.dc.ed.save",
    //调出单新增-保存、
    S_DC_AD_SAVE = "scm.s.dc.ad.save",
    //调出单-新增商品、
    S_DC_ED_ADD_M = "scm.s.dc.ed.add-m",
    //调出单-驳回、
    S_DC_ED_REJECT = "scm.s.dc.ed.reject",
    //调出单-新增、
    S_DC_AD = "scm.s.dc.ad",
    //调出单编辑、
    S_DC_ED = "scm.s.dc.ed",
    //调出单-导出明细、
    S_DC_ED_EX = "scm.s.dc.ed.ex",
    //调出单-下载模板、
    S_DC_ED_DLOAD = "scm.s.dc.ed.dload",

    //调拨管理
    ST_DB_EX = "scm.st.db.ex",
}