import React, { FC, useEffect, useState } from "react";
import { Button, Table, Upload, Tooltip, message, Input } from "antd";
import { YhBox } from "@/components/YhBox";
import { globalPrompt } from "@/components/message";
import { ColumnsType } from "antd/es/table";
import { usePurchasePoDetailsModel } from "./purchasePoDetailsModel";
import { RouterParamType } from "@/model/navModel";
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import { importPropsConfig } from "@/utils/importFile";
import { warehouseService } from "@/views/base/warehouse/warehouseService";
import { ISelectOption } from "@/components/YhFormBox/Types";
import { IDetailList } from "@/views/purchase/plan/purchasePlanType";
import { formatNum, formatIntPoint, bigNumber } from "@/utils/utils";
import { DetailType } from "@/types/ScmEnums";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { Console } from "console";
import { purchaseDetailsService } from "@/views/stock/purchaseDetails/purchaseDetailsService";
type ISCMSDcGoodsSearch = {
    data: any[],
    isSearch: boolean
}

export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const {
        detailAddForm,
        detailAddList,
        detailLookForm,
        detailDataSource,
        detailLookList,
        shelfAllList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        setShelfAllList,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setAddModalVisible,
        setDetailAddList,
        setDetailDataSource,
        // detailDetailList,
        downloadTpl,
        modelScreenMethod,
        setTopButsControl,
        setHistoryVisible,
        setHistoryRow,
        specilDataSource,
        setSpecilDataSource
    } = usePurchasePoDetailsModel();
    const { warehouseList, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel()
    const onHistoryModel = (record: IDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMSDcGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据
    let identification = []
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }
    // 导入重复数据过滤
    const uniqueArr = (arr: any[], strs: string[]) => {
        const res = new Map()
        return arr.filter((arr) => !res.has(strs.map(str => arr[str]).join('-')) && res.set(strs.map(str => arr[str]).join('-'), 1))
    }
    const importCallback = async (list: any) => {
        // let newList = uniqueArr(list, ['materialCode', 'price'])
        const { repetition, newDetailData } = await modelScreenMethod(list, detailAddList)
        if (mode === "add") {
            let newData:any=[]
            if(typeForm[mode].purchaseTypeCode == 'purchase-type.zf'){
                newData=newDetailData.map((item)=>{
                    return{...item,
                        preTaxAmount:item.price,
                        taxAmount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),0.13),item.num),2),
                        price:+bigNumber.toFixed(bigNumber.times((item.price as number),1.13),2),
                        amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),1.13),item.num),2),
                    }
                })
            }else{newData=newDetailData}
            setDetailAddList([...detailAddList, ...newData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
            let identification = newDetailData
        } else if (mode === "edit") {
            let newData:any=[]
            if(typeForm[mode].purchaseTypeCode == 'purchase-type.zf'){
                newData=newDetailData.map((item)=>{
                    return{...item,
                        preTaxAmount:item.price,
                        taxAmount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),0.13),item.num),2),
                        price:+bigNumber.toFixed(bigNumber.times((item.price as number),1.13),2),
                        amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),1.13),item.num),2),
                    }
                })
            }else{newData=newDetailData}
            setDetailDataSource([...detailDataSource, ...newData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
        let newDate = newDetailData;
        const index = newDate.filter((item) => !item.warehouseCode);
        setSpecilDataSource(index)
    }
    const controlEditable = () => {
        if (mode === DetailType.look) return false
        if (mode === DetailType.edit &&
            detailEditForm.status === DictCodeEnmu.PURCHASE_STATUS_BILL ||
            detailEditForm.status === DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO) {
            return false
        }
        return true
    }
       //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
   const goodsFilter = (dataSource: any | null = null) => {
    // const isEtit = mode !== "add";
    let goodsList = dataSource ? dataSource : typeList[mode];
    if (searchParms) {
        let reg = new RegExp(searchParms);
        let arr: any = [];
        goodsList.forEach((item: { materialCode: string; materialName: string; }) => {
            if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                arr.push(item);
            }
        })
        setSearchVal({ data: arr, isSearch: true });
        if (!arr.length) {
            message.warning("列表没有该商品！！")
        }
    } else {
        setSearchVal({ data: [], isSearch: false });
    }
}
	//删除详情列表数据
	const detailDetailList = async (mode: string, Material?: IDetailList) => {
		// debugger
		if (mode === 'add') {
			const filterDetailList = detailAddList.filter(
				(el) => !!!detaiAddDataRowKeys.find((ele) => ele === el.materialCode)
			);
			setDetailAddList(filterDetailList);
            let newSearchData = searchVal.data.filter(
				(el) => !!!detaiAddDataRowKeys.find((ele) => ele === el.materialCode)
			);
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetaiAddDataRowKeys([]);
			setDetaiAddDataRow([]);
		} else if (mode === 'edit') {
			const filterDetailList = detailDataSource.filter(
				(el) => !!!detailDataRowKeys.find((ele) => ele === el.materialCode)
			);
			setDetailDataSource(filterDetailList);
            let newSearchData = searchVal.data.filter(
				(el) => !!!detailDataRowKeys.find((ele) => ele === el.materialCode)
			);
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetailDataRowKeys([]);
			setDetailDataRow([]);
		}
	};
    const action = <>
            <Input
                size="small"
                allowClear
                datatype="round"
                className="border-r-8 ml-10"
                placeholder="配件编号/配件名称"
                onChange={(e) => {setSearchParms(e.target.value );goodsFilter()}}
                onBlur={(e) => setSearchParms(e.target.value?.trim() ) }
                onPressEnter={() => goodsFilter()}
                style={{width:'200px'}}
            />
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 查询</Button>

        {/* {(mode !== "look" ? (mode === "add" || detailEditForm.status !== "purchase-status.bill") : false) && <> */}
        {controlEditable() && <>
            <YhAuth resCode={ResourceAuthEnum.P_P_ED_ADD_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => {
                        if (typeForm[mode].purchaseTypeCode) {
                            setAddModalVisible(true)
                        } else {
                            globalPrompt("message", { text: `请选择采购类型！`, type: "warning" })
                        }
                    }}
                >新增配件</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_P_ED_DEL_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { detailDetailList(mode) }}
                >批量删除</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_P_ED_IM}>
                <Upload disabled={!typeForm[mode].purchaseTypeCode} showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/purchase/plan/details/import/${shopId}`, importCallback)}>
                    <Button className="mr-5" type="link" shape="round"  onClick={() => {
                        if (!typeForm[mode].purchaseTypeCode) {
                            globalPrompt("message", { text: `请选择采购类型！`, type: "warning" })
                        }
                    }}>导入</Button>
                </Upload>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_P_ED_DLOAD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={downloadTpl}
                >下载模板</Button>
            </YhAuth>
        </>}
    </>;

    // TODO 如果此商品没有入库记录，选择仓库后，货位默认为临时货位
    const wsChanges = async (value: string, option: ISelectOption, record: IDetailList) => {
        let params = {
            pageSize: 1000,
            pageNum: 1,
            warehouseCode: value,
        }
        const { retData } = await warehouseService.shelfAllPage(params)
        console.log(retData)
        const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
        setShelfAllList(newRecords)
        if (!record?.shelfCode) {
            mode === DetailType.edit ?
                setDetailDataSource(origin => origin.map(item => item.materialCode === record.materialCode ? { ...item, shelfCode: '临时货位' } : item)) :
                setDetailAddList(origin => origin.map(item => item.materialCode === record.materialCode ? { ...item, shelfCode: '临时货位' } : item));
        }
    }
    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text} {record.icon === 1 && <span style={{ background: "orange", color: "white" }}>多</span>} </p></Tooltip>,
        }, 
        {
            title: '商品类型',
            width: 110,
            dataIndex: 'typeName',
        },
        {
            title: '品牌名称',
            width: 110,
            dataIndex: 'brandName',
        },{
            title: '单位',
            width: 80,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 120,
            dataIndex: 'num',
            editable: controlEditable(),
        },
        ...(() => {
            if (typeForm[mode].purchaseTypeCode == 'purchase-type.zf') {
                return [{
                    title: '税前单价',
                    width: 120,
                    dataIndex: 'preTaxAmount',
                    editable: controlEditable(),
                }]
            } else {
                return []
            }
        })(),
        {
            title: '采购单价',
            width: 120,
            dataIndex: 'price',
            editable: controlEditable()&&typeForm[mode].purchaseTypeCode !== 'purchase-type.zf',
        },
        ...(() => {
            if (typeForm[mode].purchaseTypeCode == 'purchase-type.zf') {
                return [{
                    title: '税额',
                    width: 120,
                    dataIndex: 'taxAmount',
                }]
            } else {
                return []
            }
        })(),
        {
            title: '采购金额',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '到货数量',
            width: 120,
            dataIndex: 'confirmNum',
            editable: controlEditable(),
        }, {
            title: '仓库',
            width: 160,
            dataIndex: 'warehouseCode',
            editable: controlEditable(),
            editType: "select",
            onSelectChange: wsChanges,
            options: transOptions(warehouseList?.filter((item: any) => item.type === "warehouse-category.big")),
            rules: [{ required: true, message: "请选择仓库" }],
            render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '货位',
            width: 160,
            dataIndex: 'shelfCode',
            editable: controlEditable(),
            editType: "select",
            rules: [{ required: true, message: "请选择货位" }],
            options: transOptions(shelfAllList),
            render: (text: string) => text ?? "请选择货位",
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: controlEditable(),

        }
    ];
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = async (row: IDetailList) => {
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.materialCode === item.materialCode);
        const item = newData[index];
        let { num, price, amount, warehouseCode, shelfCode, confirmNum, damageNum } = row
        num = +formatNum(num)
        price = +formatNum(price)
        confirmNum = +formatIntPoint(confirmNum, num, "到货数量>=采购数量")
        damageNum = +formatIntPoint(damageNum, num, "到货数量>=采购数量")
        let newRow: any = {}
        if (typeForm[mode].purchaseTypeCode !== 'purchase-type.zf') {
            newRow = {
                ...row,
                price,
                warehouseName: getFindItem(warehouseCode as string, warehouseList)?.name,
                shelfId: getFindItem(shelfCode as string, shelfAllList)?.id ?? row.shelfId,
                // amount: +formatNum(num * price),
                amount: +bigNumber.toFixed(bigNumber.times(num, price), 2),
                confirmNum,
                damageNum,
            }
        } else {
            newRow = {
                ...row,
                // price,
                warehouseName: getFindItem(warehouseCode as string, warehouseList)?.name,
                shelfId: getFindItem(shelfCode as string, shelfAllList)?.id ?? row.shelfId,
                // amount: +formatNum(num * price),
                // amount:+bigNumber.toFixed(bigNumber.times(num,price),2),
                confirmNum,
                damageNum,
                preTaxAmount: +formatNum(row.preTaxAmount),
                taxAmount: +bigNumber.toFixed(bigNumber.times(bigNumber.times((row.preTaxAmount as number), 0.13), num), 2),
                price: +bigNumber.toFixed(bigNumber.times((row.preTaxAmount as number), 1.13), 2),
                amount: +bigNumber.toFixed(bigNumber.times(bigNumber.times((row.preTaxAmount as number), 1.13), num), 2),
            }
        }
        try {
            const params = {
                pageSize: 99999,
                pageNum: 1,
                jzMaterialCode: newRow.materialCode,
                shopId,
                status: `${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            }
            const { retData: infoAll } = await purchaseDetailsService.stockPage(params);
            let { records } = infoAll;
            if (newRow.warehouseCode !== records[0].warehouseCode) {
                newRow.warehouseCode = newRow.warehouseCode;
                if (newData[index].shelfCode == '临时货位') {
                    console.log(' row.shelfCode', newRow.shelfCode)
                    newRow.shelfCode = newRow.shelfCode
                } else {
                    newRow.shelfCode = '临时货位'
                    console.log(' row.shelfCode2', newRow.shelfCode)
                }
            }
            if (newRow.warehouseCode === records[0].warehouseCode) {
                newRow.warehouseCode = records[0].warehouseCode
                newRow.shelfCode = records[0].shelfCode
            }
        } catch (e) { }
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(newData)
        }
        setTopButsControl(true)
    };
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }
    const goWarehouse = () => {

    }
    // const title = <>
    //     <span>配件信息</span>
    //     <Tooltip placement="topLeft" title="跳转新增临时货位">
    //         <FontIcon type="icon-chelianggongchengqichepeijian" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={goWarehouse}
    //         />
    //     </Tooltip>
    // </>
    return (
        <YhBox title={"配件信息"} rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.materialCode as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns() as ColumnsType<IDetailList>}
                dataSource={searchVal.isSearch ? searchVal.data :typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content', y: 600 }}
            />
        </YhBox>
    )
}