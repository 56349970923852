import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAppointmentModel } from "../appointmentModel";

import { IAppointmentDetail } from "../appointmentType";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";

type statusType = {
    "appointment.transfer": string,
    "appointment.change": string,
    "appointment.create": string,
    [key: string]: any
}

// export const DataTable: FC<{ onLook: () => void }> = ({
//     onLook
// }) => {
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows
    } = useAppointmentModel();

    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const statusColor: statusType = {
        "appointment.transfer": "green",
        "appointment.change": "red",
        "appointment.create": "cyan",
    }
    const columns: ColumnsType<IAppointmentDetail> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '预约单号',
            width: 120,
            dataIndex: 'appointmentCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(text)}>{text}</p></Tooltip>,
        }, {
            title: '预约状态',
            width: 120,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,

        }, {
            title: '客户名称',
            width: 160,
            dataIndex: 'customerName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '送修人',
            width: 140,
            dataIndex: 'contact',
        }, {
            title: '送车电话',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '车牌号',
            width: 120,
            dataIndex: 'licensePlate',
        }, {
            title: 'VIN码',
            width: 160,
            dataIndex: 'vin',
        }, {
            title: '车辆类型',
            width: 160,
            dataIndex: 'vehicleTypeName',
        }, {
            title: '车辆型号',
            width: 160,
            dataIndex: 'vehicleModel',
        }, {
            title: '维修类型',
            width: 160,
            dataIndex: 'serviceNatureName',
        }, {
            title: '工时费',
            width: 160,
            dataIndex: 'workingHoursAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '配件费',
            width: 160,
            dataIndex: 'materialScienceAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '费用合计',
            width: 160,
            dataIndex: 'totalAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '优惠金额',
            width: 160,
            dataIndex: 'discountTotalAmount',
            render:(text)=>`￥${text}`,
        // }, {
        //     title: '公交金额',
        //     width: 80,
        //     dataIndex: 'amountReceivable',
        //     render: (text) => `￥${text ?? 0}`,
        }, {
            title: '应收金额',
            width: 160,
            dataIndex: 'receivableTotalAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '销售毛利',
            width: 160,
            dataIndex: 'grossProfit',
            render:(text)=>`￥${text}`,
        }, {
            title: '销售成本',
            width: 160,
            dataIndex: 'totalCost',
            render:(text)=>`￥${text}`,
        }, {
            title: '结算类型',
            width: 160,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
            // }, {
            //     title: '结算方式',
            //     width: 160,
            //     dataIndex: 'fax',
        }, {
            title: '结算方',
            width: 160,
            dataIndex: 'settlementPartyName',
        }, {
            title: '服务地点',
            width: 160,
            dataIndex: 'serviceLocationName',
        }, {
            title: '预约进厂时间',
            width: 160,
            dataIndex: 'enterTime',
            render: (text) => momentFormat(text)
        }, {
            title: '预估出厂时间',
            width: 160,
            dataIndex: 'deliveryTime',
            render: (text) => momentFormat(text)       
        }, {
            title: '所属门店',
            width: 120,
            dataIndex: 'shopName',
        }, {
            title: '制单员',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '站点',
            width: 120,
            dataIndex: 'siteInformation',
            render: (text) => getTreeNodeName(text),
            fixed:'right'
        }, {
            title: '经营实体',
            width: 120,
            dataIndex: 'bentityName',
            fixed:'right',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }
    ];
    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (record: IAppointmentDetail) => {
        // let findIndex = selectedRowKeys.indexOf(id);
        // let newSelectedRowKeys = [...selectedRowKeys];
        // if (findIndex === -1) {
        //     newSelectedRowKeys.push(id);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // } else {
        //     newSelectedRowKeys.splice(findIndex, 1);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // }
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record: IAppointmentDetail)=>{
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    const countX = (arr: any) => {
        return arr.reduce((cur: any, next: any) => {
            return cur + (next.width ? next.width : 0)
        })
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            // loading={loading}
            // size="small"
            rowKey={record => record.id as string}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IAppointmentDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record as IAppointmentDetail),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}

        />
    )
}
