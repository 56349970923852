import React, { useState } from 'react'
import { Modal, Button, Input, message, Select } from 'antd';
import { numberParseChina, formatNum } from "@/utils/utils";
import "./settlePrint.css";
import { globalPrompt } from '../message';
import useGlobalModel from '@/model/globalModel';
import { useSetState } from 'ahooks';

//打印
export interface IPrintClumns {
    title: string,
    dataIndex: string,
    render?: (text: string | number) => string
}
export interface IPrintHeader {
    [key: string]: string | number | any
}
export interface IPrintFooter {
    [key: string]: string | number | any
}
interface Item {
    [key: string]: string | number | any
}
export interface IPrintClumns {
    title: string,
    dataIndex: string,
    render?: (text: string | number) => string
}
export interface IPrintType {
    title?: string,
    type?: string,
    visible: boolean,
    onClose: () => void,
    onConfirm?: () => void,
    itemClumns?: IPrintClumns[], //维修项目字段及信息
    columns: IPrintClumns[],  //商品字段及信息
    dataSource: Item[],      //商品数据
    itemDataSource?: Item[],  //维修项目数据
    printHeader: IPrintHeader,    //header 及 footer
    printFooter: IPrintFooter,    //header 及 footer
    IHeader: IHeader[],    //header 及 footer

}
export interface IHeader {
    fieldLabel: string,
    fieldValue?: string | number,
    className?: string,
    border?: boolean,
}

export const SettlePrint = ({
    title = "",
    visible,
    onClose,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    printHeader,
    printFooter,
    IHeader
}: IPrintType) => {

    //打印
    const print = () => {
        const el: HTMLElement | null = document.getElementById('public-print-container');
        const iframe: any = document.createElement('IFRAME');
        let doc = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./settlePrint.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    };
    // 遍历商品td
    const mapGoods = (goods: Item) => {
        return columns.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods: Item) => {
        return itemClumns?.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    const { beShopDs } = useGlobalModel()
    const [headTitle, setHeadTitle] = useState<string|null>(null)
    const setTitle = () => {
        let titVal: string = ""
        globalPrompt("modal", {
            width: 380,
            title: "设置logo",
            type: "info",
            // text: "",
            text: <>
                <span>请选择表头公司类型</span>
                <Select
                    showSearch={true}
                    defaultValue={beShopDs.find(item => item.label === title)?.value}
                    options={beShopDs}
                    onChange={(value, option: any) => titVal = option.label}
                />
            </>,
            okText: "确定",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    resolve(titVal)
                }).then(res => {
                    // console.log(titVal)
                    setHeadTitle(`${titVal}修理结算单`)
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    return (
        <Modal
            title="打印详情"
            width={1300}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button type="link" onClick={setTitle} key="back">设置表头公司</Button>,
                <Button type="link" onClick={onClose} key="back"> 取消</Button>,
                <Button type="link" onClick={print} key="submit">确认打印</Button>,
            ]}
        >
            <div id="public-print-container">
                <div className="print-container">
                    <h2>{headTitle ?? title}</h2>
                    <ul className="header-odd">
                        {IHeader && IHeader.map((item, index) => {
                            return <li className={item.className ?? ""} key={index}>{item.fieldLabel}{item.fieldValue}</li>
                        })}
                    </ul>
                    <table className="news-table">
                        <tbody>
                            <tr>
                                <td>车牌</td>
                                <td>{printHeader.licensePlate}</td>
                                <td>客户名称</td>
                                <td colSpan={7}>{printHeader.customerName}</td>
                            </tr>
                            <tr>
                                <td>分店</td>
                                <td >{printHeader.shopName}</td>
                                <td>联系人</td>
                                <td >{printHeader.contact}</td>
                                <td>电话</td>
                                <td >{printHeader.phone}</td>
                                <td>销售员</td>
                                <td >{printHeader.createUser}</td>
                                <td>部门经理</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>车型</td>
                                <td colSpan={3}>{printHeader.vehicleModel}</td>
                                <td>颜色</td>
                                <td></td>
                                <td>送修日期</td>
                                <td>{printHeader.enterTime}</td>
                                <td>结算日期</td>
                                <td>{printHeader.settleTime}</td>
                            </tr>
                            <tr>
                                <td>报修项目</td>
                                <td colSpan={9}>{printHeader.faultDesc}</td>
                            </tr>
                        </tbody>

                    </table>
                    <table className="item-table">
                        <tbody>
                            <tr className="print-center" >
                                {itemClumns && itemDataSource && itemDataSource.length > 0 && itemClumns.filter(item => item).map((item, index) =>
                                    <td key={index}>{item.title}</td>
                                )}
                            </tr>
                            {itemDataSource && itemDataSource.length > 0 &&
                                itemDataSource.map((item, index) =>
                                    <tr key={index} className="print-center">
                                        {itemGoods(item)}
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    <table className="material-table">
                        <tbody>
                            <tr className="print-center" >
                                {columns && dataSource && dataSource.length > 0 && columns.filter(item => item).map((item, index) =>
                                    <td key={index}>{item.title}</td>
                                )}
                            </tr>
                            {dataSource && dataSource.length > 0 &&
                                dataSource.map((item, index) =>
                                    <tr key={index} className="print-center">
                                        {mapGoods(item)}
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                    <table className="news-table">
                        <tbody>
                            <tr>
                                <td>工时费</td>
                                <td>{printHeader.workingHoursAmount}</td>
                                <td>材料费</td>
                                <td>{printHeader.materialScienceAmount}</td>
                                <td>合计金额</td>
                                <td>{printHeader.totalAmount}</td>
                                <td>含税合计</td>
                                <td>{printHeader.totalAmount}</td>
                            </tr>
                            <tr>
                                <td>优惠金额</td>
                                <td>{printHeader.discountTotalAmount}</td>
                                <td>实收金额</td>
                                <td>{printHeader.receivableTotalAmount}</td>
                                <td>(大写)</td>
                                <td colSpan={3}>{printHeader.receivableTotalAmountCN}</td>
                            </tr>
                            <tr>
                                <td>待结金额</td>
                                <td >{}</td>
                                <td>客户签字</td>
                                <td>{}</td>
                                <td>结算人</td>
                                <td>{printHeader.reckoner}</td>
                                <td>会计</td>
                                <td></td>
                            </tr>
                        </tbody>

                    </table>
                    <ul className="header-odd">
                        <li>制表人：{printHeader.createUser}</li>
                        <li>客车系统</li>
                        <li>第1页/共1页</li>
                    </ul>
                </div>
            </div>
        </Modal>

    )
}