import React, { useEffect } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { IDeploymentDetail } from "../busBusinessType"
import { useDeploymentModel } from "./deploymentModel"
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import { momentFormat } from "@/utils/utils";
import YhTootip from "@/components/YhTootip";
import useNavModel from "@/model/navModel";

type statusType = {
    "allocate.stock-io": string,
    [key: string]: any
}
const statusColor: statusType = {
    "allocate.stock-io": "green",
}
export const DeploymentDataTable = () => {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys,setDescRows
    } = useDeploymentModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const { getTreeNodeName } = useDictModel();

    const columns: ColumnsType<IDeploymentDetail> = [
        {
            align: "center",
            title: '序号',
            width:60,
            fixed: 'left',
            render:(t,r,i)=>i +1
        },
        {
            align: "center",
            title: '调配单号',
            dataIndex: 'allocateCode',
            width:200,
            fixed: 'left',
            render: (text: any,record) => <YhTootip text={text} onClick={()=>{
                let supplierEditKey = "16-4-3";
                replaceNav(
                    selectedNavId,
                    supplierEditKey,
                    { id: record.allocateCode as string, mode: "look", parentInfo: { source: "List", selectedNavId } },
                    true
                ); 
            }}/>,
        },
        {
            align: "center",
            title: '单据状态',
            dataIndex: 'status',
            width:120,
            fixed: 'left',
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        },
        {
            align: "center",
            title: '调出仓库',
            dataIndex: 'sourceWarehouseName',
            width:200,
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            title: '调出前数量',
            dataIndex: 'originalNum',
            width:120,
        },
        // {
        //     align: "center",
        //     title: '调出前金额 wu',
        //     dataIndex: 'amount',
        //     width:120,
        // },
        {
            align: "center",
            title: '调配数量',
            dataIndex: 'outNum',
            width:120,
        },
        {
            align: "center",
            title: '调配金额',
            dataIndex: 'amount',
            render:(text)=>`￥${text}`,
            width:120,
        },
        {
            align: "center",
            title: '调入仓库',
            dataIndex: 'targetWarehouseName',
            width:120,
        },
        {
            align: "center",
            title: '制单人',
            dataIndex: 'createUser',
            width:120,
        },
        {
            align: "center",
            title: '制单时间',
            dataIndex: 'createTime',
            width:120,
            render: (text) => momentFormat(text)
        },
        {
            align: "center",
            title: '备注',
            dataIndex: 'note',
            width:120,
        },
    ]
    const onRow = (record: IDeploymentDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    }
    const onRightClick = (record: IDeploymentDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    //
    useEffect(()=>{
        loadData().then()
    },[])
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IDeploymentDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />

    )
}