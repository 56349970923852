import React, { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, defaultMdCmParam } from '@/utils/constants';
import { IPgProps, ISearchPage } from '@/types/ScmTypes';
import { globalPrompt } from '@/components/message';
import useGlobalModel from '@/model/globalModel';
import { convertRes2Blob } from '@/utils/exportFile';
import {
	ISCMAcctUnitDetail,
	ISCMAcctUnitSearchFields,
	acctUnitService,
	ISCMAcctUnitFormFields,
	ISCMAcctUnitInsert,
	ISCMAcctUnitUpdate,
} from './acctUnitService';
import { message } from 'antd';
import { transPgToPagination } from '@/utils/utils';

export const useAcctUnitModel = createModel(function () {
	const {
		user: { shopId, shopName, userName },
		setLoadingInfo,
		accUnitDataAll,
	} = useGlobalModel();
	//列表数据源
	const [dataSource, setDataSource] = useState<ISCMAcctUnitDetail[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	//查询条件
	const [searchParam, setSearchParam] = useState<ISCMAcctUnitSearchFields>({});
	//分页参数
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//表格多选id值
	const [selectedRowKeys, setSelectedRowKeys] = useState<ISCMAcctUnitDetail[]>([]);
	//列表查询
	const loadData = async (
		page?: ISearchPage,
		searchValue: ISCMAcctUnitSearchFields = searchParam
	) => {
		setLoading(true);
		const searchParams = {
			shopId,
			...searchValue,
			pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
			pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
		};
		let { retData } = await acctUnitService.page(searchParams);
		const { records, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(records);
		setLoading(false);
		accUnitDataAll();
	};
	//设置搜索value
	const updateSearchParam = (updateParam: ISCMAcctUnitSearchFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};

	//切换门店重置缓存
	useEffect(() => {
		setSearchParam({});
		setSelectedRowKeys([]);
		setAddFormVal({ ...initAddFormVal, shopName, createUser: userName });
	}, [shopId]);

	//重置搜索参数
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};

	/**
	 * 表单详情
	 */

	//编辑表单缓存
	const [editFormVal, setEditFormVal] = useState<object>({});

	const initAddFormVal = {
		shopName,
		createUser: userName,
		status: true,
	};
	//新增表单缓存
	const [addFormVal, setAddFormVal] = useState<object>(initAddFormVal);
	//表单新增修改
	const updateAddFormVal = (val: object | null) => {
		val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal(initAddFormVal);
	};
	//表单新增修改
	const updateEditFormVal = (val: object | null) => {
		val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({});
	};
	//获取一条详情数据 Api
	const getAcctUnitOne = async (code: string) => {
		let { retData } = await acctUnitService.one({ code });
		retData &&
			setEditFormVal({
				...retData,
				status: !!retData.status,
				bentity: retData.bentity?.split(','),
			});
	};

	//表单新增 Api
	const insertAcctUnit = async (val: ISCMAcctUnitFormFields) => {
		let data: ISCMAcctUnitInsert = {
			...val,
		};
		let { retData } = await acctUnitService.insert(data);
		retData
			? globalPrompt('message', { text: '新增成功', type: 'success' })
			: globalPrompt('message', { text: '新增失败', type: 'error' });
		return retData;
	};

	//表单编辑 Api
	const editAcctUnit = async (val: ISCMAcctUnitFormFields) => {
		let data: ISCMAcctUnitUpdate = {
			...val,
		};
		let { retData } = await acctUnitService.update(data);
		if (retData) {
			loadData();
			globalPrompt('message', { text: '编辑成功', type: 'success' });
		} else {
			globalPrompt('message', { text: '编辑失败', type: 'error' });
		}
		return retData;
	};
	//禁用启用
	const disableAcctUnit = async (id: string, checked: number) => {
		let params = {
			code: id,
			status: checked,
		};
		let { retData } = await acctUnitService.disable(params);
		if (retData) {
			loadData();
			globalPrompt('message', { text: '编辑成功', type: 'success' });
		} else {
			globalPrompt('message', { text: '编辑失败', type: 'error' });
		}
		return retData;
	};
	//导出
	const exportAcctUnit = async (searchValue: ISCMAcctUnitSearchFields = searchParam) => {
		setLoadingInfo({ loadingVisible: true, txt: '快速导出中' });
		try {
			const searchParams = {
				shopId,
				...searchValue,
				pageSize: pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			};

			let result = await acctUnitService.export(searchParams);
			result && setLoadingInfo({ loadingVisible: false, txt: '' });
			convertRes2Blob(result);
		} catch {
			setLoadingInfo({ loadingVisible: false, txt: '' });
		}
	};
	//同步
	const sync = async () => {
		let params = {
			shopId,
			pageSize: -1,
			pageNum: 1,
		};
		let { retData } = await acctUnitService.sync(params);
		if (retData) {
			message.success('同步成功');
			loadData();
		}
	};

	return {
		/***field***/
		searchParam,
		dataSource,
		selectedRowKeys,
		pgCfg,
		editFormVal,
		addFormVal,
		loading,
		/***method***/
		updateAddFormVal,
		updateEditFormVal,
		insertAcctUnit,
		editAcctUnit,
		getAcctUnitOne,
		setDataSource,
		setSelectedRowKeys,
		resetSearchParam,
		updateSearchParam,
		loadData,
		exportAcctUnit,
		disableAcctUnit,
		sync,
	};
});
