import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useFollowUpModel } from "../followUpModel";
import { IFollowUpDetail } from "../followUpType";
import { momentFormat } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import useNavModel, { RouterParamType } from "@/model/navModel";

type statusType = {
    "visit-status.will": string, //未回访
    "visit-status.done": string,//已回访
    [key: string]: any
}
const statusColor: statusType = {
    "visit-status.will": "geekblue", //未回访
    "visit-status.done": "green",//已回访
}

export function DataTable() {
    const {
        dataSource,
        selectedRowKeys,
        pgCfg,
        initPgCfg,
        loadData,
        setSelectedRowKeys,
        setSearchParam
    } = useFollowUpModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav, getSelectedNavState } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    // const { parentInfo } = detailsInfo
    useEffect(() => {
        console.log(detailsInfo)
        if (detailsInfo && detailsInfo.parentInfo) {
            let { status } = detailsInfo.parentInfo
            setSearchParam({ status })
            loadData(initPgCfg, { status })
        } else {
            loadData()
        }
    }, [])
    const onLook = (code: string) => {
        let supplierEditKey = "8-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const columns: ColumnsType<IFollowUpDetail> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '工单单号',
            width: 120,
            dataIndex: 'maintenanceCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(text)}>{text}</p></Tooltip>,
        }, {
            title: '回访状态',
            width: 120,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '送修人',
            width: 120,
            dataIndex: 'contact',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '送车电话',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '车牌号',
            width: 120,
            dataIndex: 'licensePlate',
        }, {
            title: 'VIN码',
            width: 160,
            dataIndex: 'vin',
        }, {
            title: '车辆类型',
            width: 160,
            dataIndex: 'vehicleTypeName',
        }, {
            title: '车辆型号',
            width: 160,
            dataIndex: 'vehicleModel',
        }, {
            title: '维修类型',
            width: 160,
            dataIndex: 'serviceNatureName',
        }, {
            title: '工时费',
            width: 160,
            dataIndex: 'workingHoursAmount',
        }, {
            title: '配件费',
            width: 160,
            dataIndex: 'materialScienceAmount',
        }, {
            title: '费用合计',
            width: 160,
            dataIndex: 'totalAmount',
        }, {
            title: '客户反馈',
            width: 160,
            dataIndex: 'feedback',
        }, {
            title: '结算方式',
            width: 160,
            dataIndex: 'paymentMethodName',
        }, {
            title: '结算方',
            width: 160,
            dataIndex: 'settlementPartyName',
        }, {
            title: '发票类型',
            width: 160,
            dataIndex: 'invoiceTypeName',
        }, {
            title: '开票状态',
            width: 160,
            dataIndex: 'invoiceStatus',
            render: (text) => text === 1 ? "已开票" : "未开票"
        }, {
            title: '服务地点',
            width: 160,
            dataIndex: 'serviceLocationName',
        }, {
            title: '进厂时间',
            width: 160,
            dataIndex: 'enterTime',
            render: (text) => momentFormat(text)
        }, {
            title: '出厂时间',
            width: 160,
            dataIndex: 'deliveryTime',
            render: (text) => momentFormat(text)
        }, {
            title: '制单员',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '所属门店',
            width: 160,
            dataIndex: 'shopName',
        }
    ];


    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IFollowUpDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
