import React, { useState, useEffect } from "react";
import { createModel } from "hox";
import { ISaleReturnDetail, ISaleReturnFields, ISaleReturnStatistics, ISaleReturnDetailed } from "../busBusinessType"
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, defaultMdCmParam } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table";
import { ISearchPage } from "@/types/ScmTypes";
import useGlobalModel from "@/model/globalModel";
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { message } from "antd";
import { convertRes2Blob } from "@/utils/exportFile";

export const useSaleReturnModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const { shopCode, shopId } = shopAndOrg

    const initSearchParam: ISaleReturnFields = {};
    const initDataSource: ISaleReturnDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        totalNum: 0, // 0,
        totalAmount: 0, // 0,
        receivedAmount: 0, // null,
        latestPurPrice: 0, // null,
        receivableAmount: 0, // 0,
        grossProfit: 0, // null
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<ISaleReturnFields>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<ISaleReturnDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<ISaleReturnDetail>({});
    const [descTotal, setDescTotal] = useState<ISaleReturnStatistics>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<ISaleReturnStatistics>(initDesc);
    const [descRows, setDescRows] = useState<ISaleReturnDetail[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item, index) => {
            let { totalNum, totalAmount, receivableAmount } = item
            newRowsDescTotal.totalNum = bigNumber.add(newRowsDescTotal.totalNum, totalNum as number)
            newRowsDescTotal.totalAmount = bigNumber.add(newRowsDescTotal.totalAmount, formatNum(totalAmount))
            newRowsDescTotal.receivedAmount = bigNumber.add(newRowsDescTotal.receivedAmount, formatNum(receivableAmount))
            // newRowsDescTotal.totalNum += (totalNum as number)
            // newRowsDescTotal.totalAmount += +formatNum(totalAmount)
            // newRowsDescTotal.receivedAmount += +formatNum(receivableAmount)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as ISaleReturnFields)
    }, [selectedRowKeys, dataSource])

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: ISaleReturnFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    const loadData = async (page?: ISearchPage, searchValue: ISaleReturnFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopCode,
            shopId,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await busBusinesstService.sreturnPage(searchParams);
        const { records, statistics, ...pg } = retData;
        setDescTotal(statistics)
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records);
    }
    const invalidateData = async (params: ISaleReturnDetailed) => {
        let { retData } = await busBusinesstService.sreturnInvalidate(params)
        if (retData) {
            message.success("作废成功");
            loadData();
        }
    }
    const [print, setPrint] = useState<boolean>(false)
    const exportSaleSo = async (searchValue: ISaleReturnFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await busBusinesstService.sreturnExport(searchParams);
        convertRes2Blob(result)
    }
    const detailsExport = async (searchValue: ISaleReturnFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
            returnCode:(descRows.map((item)=>{return item.returnCode})).join()

        }
        let result = await busBusinesstService.sreturnDetailExport(searchParams);
        convertRes2Blob(result)
    }
    return {
        initPgCfg,
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedRow,
        rowsDescTotal,
        descRows,
        descTotal,
        // method
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setPgCfg,
        updatePgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setSelectedRow,
        setRowsDescTotal,
        setDescRows,
        setDescTotal,
        invalidateData,
        print, setPrint,
        exportSaleSo,
        detailsExport
    }
})