import React, { useEffect } from "react";
import { YhPageHeader } from "@/components/YhPageHeader";
import { InfoBox } from "./InfoBox";
import { ActionTypeEnum, useDetailModel } from "./detailModel";

import { AccountSearchBox } from "./account/SearchBox";                 // 账户列表检索区
import { AdjustSearchBox } from "./adjust/SearchBox";                   // 调额记录检索区
import { BIllSearchBox } from "./bill/SearchBox";                       // 账单列表检索区
import { LogSearchBox } from "./log/SearchBox";                         // 操作日志检索区
import { RechargeSearchBox } from "./recharge/SearchBox";               // 充值记录检索区
import { TransferSearchBox } from "./transfer/SearchBox";               // 转账记录检索区
import { WriteoffSearchBox } from "./writeoff/SearchBox";               // 核销记录检索区

import { AccountDataBox } from "./account";                             // 账户列表展示区
import { AdjustDataBox } from "./adjust";                               // 调额记录展示区
import { BillDataBox } from "./bill";                                   // 账单列表展示区
import { LogDataBox } from "./log";                                     // 操作日志展示区
import { RechargeDataBox } from "./recharge";                           // 充值记录展示区
import { TransferDataBox } from "./transfer";                           // 转账记录展示区
import { WriteoffDataBox } from "./writeoff";                           // 核销记录展示区
import { useGlobalModel } from "@/model/globalModel";
import { fmsDictService } from "@/views/account/dictService";
import { useDictModel } from "@/views/base/dict/dictModel";
import { labelsType, ISelectOptions } from "@/types/ScmTypes";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

/**
 * 检索区判断
 */
const DetailSearchBox = () => {
    const { actionType, } = useDetailModel();
    switch (actionType) {
        case ActionTypeEnum.ACCOUNT:
            return <AccountSearchBox />;
        case ActionTypeEnum.ADJUST_LIST:
            return <AdjustSearchBox />;
        case ActionTypeEnum.BILL_LIST:
            return <BIllSearchBox />;
        case ActionTypeEnum.LOG_LIST:
            return <LogSearchBox />;
        case ActionTypeEnum.RECHARGE_LIST:
            return <RechargeSearchBox />;
        case ActionTypeEnum.TRANSFER_LIst:
            return <TransferSearchBox />;
        case ActionTypeEnum.WRITEOFF_LIST:
            return <WriteoffSearchBox />;
        default:
            return <></>
    }
}

/**
 * 内容区判断
 */
const DetailDataBox = () => {
    const { actionType, } = useDetailModel();
    switch (actionType) {
        case ActionTypeEnum.ACCOUNT:
            return <AccountDataBox />;
        case ActionTypeEnum.ADJUST_LIST:
            return <AdjustDataBox />;
        case ActionTypeEnum.BILL_LIST:
            return <BillDataBox />;
        case ActionTypeEnum.LOG_LIST:
            return <LogDataBox />;
        case ActionTypeEnum.RECHARGE_LIST:
            return <RechargeDataBox />;
        case ActionTypeEnum.TRANSFER_LIst:
            return <TransferDataBox />;
        case ActionTypeEnum.WRITEOFF_LIST:
            return <WriteoffDataBox />;
        default:
            return <></>
    }
}

export const CustomerAccountDetail = () => {
    const {
        setPayMethodLabels,
        setPayMethodDs,
        setAccountType,
        customerCode, setActionType,
        closeNav,
        loadSummaryData,
    } = useDetailModel();

    const { resourceAuth } = useGlobalModel(model => [model.resourceAuth, model.appDs]);
    const { getSelectedDictOne } = useDictModel(() => []);

    useEffect(() => {
        let obj: labelsType = {};
        fmsDictService.accountTreeOne(DictCodeEnmu.PAY_METHOD).then(ds => setPayMethodDs(ds))

        // setAccountType(() => getSelectedDictOne(DictCodeEnmu.ACCOUNT_TYPE) as ISelectOptions[])
        fmsDictService.dictItemDs(DictCodeEnmu.ACCOUNT_TYPE).then(ds => setAccountType(ds));
        getSelectedDictOne(DictCodeEnmu.PAYEE_TYPE).forEach(item => {
            obj[item.value] = item.label
        })
        setPayMethodLabels(obj);

        setActionType(() => {
            //所有tab权限
            let authArr = [
                ResourceAuthEnum.ACC_C_VW_AC,
                ResourceAuthEnum.ACC_C_VW_ADJ,
                ResourceAuthEnum.ACC_C_VW_CHARG,
                ResourceAuthEnum.ACC_C_VW_TRANS,
                ResourceAuthEnum.ACC_C_VW_BILL,
                ResourceAuthEnum.ACC_C_VW_W_OFF,
                ResourceAuthEnum.ACC_C_VW_LOG,
            ];
            //过滤出来所有分配的权限；
            let findAuth = resourceAuth.filter((item:string) => authArr.indexOf(item as ResourceAuthEnum) >= 0);
            if (!findAuth.length) return ActionTypeEnum.NULL;
            switch (findAuth[0]) {
                case ResourceAuthEnum.ACC_C_VW_AC:
                    return ActionTypeEnum.ACCOUNT;
                case ResourceAuthEnum.ACC_C_VW_ADJ:
                    return ActionTypeEnum.ADJUST_LIST;
                case ResourceAuthEnum.ACC_C_VW_CHARG:
                    return ActionTypeEnum.RECHARGE_LIST;
                case ResourceAuthEnum.ACC_C_VW_TRANS:
                    return ActionTypeEnum.TRANSFER_LIst;
                case ResourceAuthEnum.ACC_C_VW_BILL:
                    return ActionTypeEnum.BILL_LIST;
                case ResourceAuthEnum.ACC_C_VW_W_OFF:
                    return ActionTypeEnum.WRITEOFF_LIST;
                case ResourceAuthEnum.ACC_C_VW_LOG:
                    return ActionTypeEnum.LOG_LIST;
                default:
                    return ActionTypeEnum.NULL;
            }
        });
        if (!customerCode) {
            return closeNav("6-1-1");
        }
    }, []);

    useEffect(() => {
        loadSummaryData()
    }, [customerCode]);

    return (
        <>
            <YhPageHeader title="客户账户详情" />
            <InfoBox />
            <DetailSearchBox />
            <DetailDataBox />
        </>
    )
}
