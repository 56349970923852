import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { SalePrint } from "./SalePrint"
import { useSaleSoModel } from "../saleSoModel";
import { SettleScaniaPrints } from "./settleScaniaPrints"

export default () => {
    const {
        print,
        settleScaniaPrint
    } = useSaleSoModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <SalePrint />}
            {settleScaniaPrint && <SettleScaniaPrints />}
        </>
    )
}