import React, { } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useReportFormModel } from "../reportFormModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { formatNum } from "@/utils/utils";

export const DataBox = () => {
    const { selectedRowKeys, descTotal, rowsDescTotal, exportEntry } = useReportFormModel();
    const desc: IDescription[] = [
        {
            label: "期初总数",
            value: selectedRowKeys.length === 0 ? descTotal.startSumCount : formatNum(rowsDescTotal.startSumCount),
            color: DescriptionColorEnum.red
        },
        {
            label: "期初总额",
            value: selectedRowKeys.length === 0 ? descTotal.startSumMoney : formatNum(rowsDescTotal.startSumMoney),
            color: DescriptionColorEnum.red
        },
        {
            label: "期末总数",
            value: selectedRowKeys.length === 0 ? descTotal.endSumCount : formatNum(rowsDescTotal.endSumCount),
            color: DescriptionColorEnum.red
        },
        {
            label: "期末总额",
            value: selectedRowKeys.length === 0 ? descTotal.endSumMoney : formatNum(rowsDescTotal.endSumMoney),
            color: DescriptionColorEnum.red
        },
        {
            label: "入库总数",
            value: selectedRowKeys.length === 0 ? descTotal.inSumCount : formatNum(rowsDescTotal.inSumCount),
            color: DescriptionColorEnum.red
        },
        {
            label: "入库总额",
            value: selectedRowKeys.length === 0 ? descTotal.inSumMoney : formatNum(rowsDescTotal.inSumMoney),
            color: DescriptionColorEnum.red
        },
        {
            label: "出库总数",
            value: selectedRowKeys.length === 0 ? descTotal.outSumCount : formatNum(rowsDescTotal.outSumCount),
            color: DescriptionColorEnum.red
        },
        {
            label: "出库总额",
            value: selectedRowKeys.length === 0 ? descTotal.outSumMoney : formatNum(rowsDescTotal.outSumMoney),
            color: DescriptionColorEnum.red
        }
    ]
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ST_IN_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportEntry()}
            >导出</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="库存报表" descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}