import { httpRequest } from "../../../utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import { ItemDetailed, MaterialDetailed, ISummaryField, ISummarySearch, ISummaryDetail, ISummaryRoSettleAndWhConfirm } from "./summaryType"

class SettlementService {
    async page(searchVo: ISummarySearch) {
        const apiUrl = "/bcm/maintenance/settlePage";
        return await httpRequest.post<IPage<ISummaryDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/settlement/findById/${id}`;
        return await httpRequest.get<ISummaryDetail>(apiUrl);
    }

    async insert(insertVo: ISummaryField) {
        const apiUrl = "/bcm/settlement/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    async update(updateVo: ISummaryField) {
        const apiUrl = "/bcm/settlement/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    // 库房确认
    async warehousing(params: ISummaryRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/settlement/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修用料明细删除
    async materialDelete(params: MaterialDetailed) {
        const apiUrl = "/bcm/settlement/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修项目明细删除
    async itemDelete(params: ItemDetailed) {
        const apiUrl = "/bcm/settlement/itemDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //结算
    async settl(params: ISummaryRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/settlement/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //作废
    async invalidate(params: object) {
        const apiUrl = "/bcm/settlement/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //重新加载维修项目得数据
    async projectQuery(params: string[]) {
        const apiUrl = "/bcm/base/item/projectQuery";
        return await httpRequest.post<ItemDetailed[]>(apiUrl, params);
    }
    //重新加载维修用料得数据
    async find(params: string[]) {
        const apiUrl = "/bcm/stock/find";
        return await httpRequest.post<MaterialDetailed[]>(apiUrl, params);
    }
    //预约转维修
    async transfer(params: object) {
        const apiUrl = "/bcm/settlement/transfer";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: ISummarySearch) {
        const apiUrl = "/bcm/maintenance/settleExport";
        return await httpRequest.postFile(apiUrl, params);
    }
    // async detailsExport(params: ISaleRoFields) {
    //     const apiUrl = "/bcm/sreturn/detailsExport";
    //     return await httpRequest.postFile(apiUrl, params);
    // }
}
export const settlementService = new SettlementService();
