import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { busListService, IVRMBusListSearchFields, IVRMBusListDetail, IStatistics } from "./busListService";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { transPgToPagination } from "@/utils/utils";


export const useBusListModel = createModel(function () {
    const initStatistics = {
        saleNum: 0, //销售数量
        saleAmount: 0, //销售金额
        saleCost: 0, //销售成本
        grossProfit: 0, //销售毛利
    }
    const { shopAndOrg: { shopId } } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<IVRMBusListSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<IVRMBusListDetail[]>([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [loading, setLoading] = useState<boolean>(false);
    const [descTotal, setDescTotal] = useState<IStatistics>(initStatistics);

    const updateSearchParam = (updateParam: IVRMBusListSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IVRMBusListSearchFields = searchParam) => {
        setLoading(true);
        const searchParams = {
            shopId,
            ...searchValue,
            startTime: searchValue?.startTime ? searchValue?.startTime.replace(/T/g, " ") : "",
            endTime: searchValue?.endTime ? searchValue?.endTime.replace(/T/g, " ") : "",
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await busListService.page(searchParams);
        let { retData: Data } = await busListService.statistics(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDescTotal(Data);
        setDataSource(origin =>
            records?.map((item, index) => ({ ...item, id: index + "1" }) ?? []
            ));
        setLoading(false);
    }

    //导出
    const exportBusList = async (searchValue: IVRMBusListSearchFields = searchParam) => {
        try {
            const searchParams = {
                shopId,
                startTime: searchValue?.startTime ? searchValue?.startTime.replace(/T/g, " ") : "",
                endTime: searchValue?.endTime ? searchValue?.endTime.replace(/T/g, " ") : "",
                ...searchValue,
                id: selectedRowKeys.join(),
                pageSize: -1,
                pageNum: 1,
            }
            let result = await busListService.export(searchParams);
            convertRes2Blob(result);
        } catch{

        }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg, loading,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData, exportBusList,
        descTotal, setDescTotal
    }
});


