import React from "react";
import { useWarehousingModel } from "./WarehousingModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";


export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useWarehousingModel();
    const { getSelectedDictOne } = useDictModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "单据编号",
            fieldName: "documentNumber",
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            xxl: 3,
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS),
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "userName",
            xxl: 3,
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单员",
            fieldName: "createUser",
            xxl: 3,
        },

    ]

    return (
        <>
            <YhPageHeader
                size="small"
                title="待入库列表"
                justify="end"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
        </>
    );
}