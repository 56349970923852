import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useStockInAndOutModel } from "../stockInAndOutModel";
import { momentFormat } from "@/utils/utils";
import { IStockInAndOutField } from "../stockInAndOutType"
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";



export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows, ioFlag, initPgCfg,
        loadData, setSelectedRowKeys, setDescRows, reset
    } = useStockInAndOutModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "9-6-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    useEffect(() => {
        loadData(initPgCfg, { ioFlag })
    }, [])
    useEffect(() => {
        return () => {
            !findNav("9-6") && reset();
        }
    }, [selectedNavId])
    const onRow = (record: IStockInAndOutField) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const columns: ColumnsType<IStockInAndOutField> = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => i + 1,
            fixed: "left",
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'tradeCode',
            fixed: "left",
            render: (text: any) => <YhTootip text={text} />,
            //render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any) => <YhTootip text={text} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '所属仓库',
            width: 160,
            dataIndex: 'warehouseName',
            render: (text: any) => <YhTootip text={text} />,
           // render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '所属货位',
            width: 140,
            dataIndex: 'shelfCode',
        }, {
            title: ioFlag?'入库数量':'出库数量',
            width: 100,
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
        }, {
            title: ioFlag?'入库金额':'出库金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'tradeTypeName',
        }, {
            title: '制单人',
            width: 100,
            dataIndex: 'createUser',
        }, {
            title: '制单时间',
            width: 160,
            dataIndex: 'createTime',
            render:(text)=>momentFormat(text)
        }, {
            title: '库管员',
            width: 100,
            dataIndex: 'billingClerk',
        }, {
            title: ioFlag?'入库时间':'出库时间',
            width: 160,
            dataIndex: 'createTime',
            render:(text)=>momentFormat(text)
        }, {
            title: '单位名称',
            width: 180,
            dataIndex: 'userName',
        }
    ];
    const onRightClick = (record: IStockInAndOutField) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IStockInAndOutField[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }
            })}
            scroll={{ x: 'max-content', y: 450 }}
        />
    )
}
