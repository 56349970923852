import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useBillMonitorModel } from "../billMonitorModel";
import { IBillMonitorDetail } from "../billmonitorService";
import { formatNum } from "@/utils/utils";
import "./style.css"
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = useBillMonitorModel();
    const { getTreeNodeName } = useDictModel(() => []);
    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IBillMonitorDetail> = [
        {
            align: "center",
            width: 60,
            ellipsis: true,
            title: '序号',
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '门店名称',
            dataIndex: 'shopName',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据编号',
            dataIndex: 'tradeCode',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单位名称',
            dataIndex: 'partnerName',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据类型',
            dataIndex: 'tradeTypeName',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据状态',
            dataIndex: 'status',
            render: text => getTreeNodeName(text)
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '业务支付状态',
            dataIndex: 'payStatus',
            render: text => getTreeNodeName(text)
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '业务单据金额',
            dataIndex: 'totalAmount',
            render: text => `￥${formatNum(text)}`
        },

        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '财务收款状态',
            dataIndex: 'billStatus',
            render: text => text === '0' ? '初始' : text === '1' ? '已记账' : text === '2' ? '已核销' : text
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '账单金额',
            dataIndex: 'billAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '差异金额',
            dataIndex: 'diffAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            title: '状态差异',
            dataIndex: 'payStatus',
            render: (text, row) => ((row.billStatus === '0' && getTreeNodeName(text) === '初始') || (row.billStatus === '1' && getTreeNodeName(text) === '已记账') || (row.billStatus === '2' && getTreeNodeName(text) === '已核销')) ? '否' : '是'
        },
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id),
            //     onContextMenu: (e) => {
            //         e.preventDefault();
            //         setSelectedRowKeys([record.id]);
            //     }
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 360px)" }}
        />
    )
}
