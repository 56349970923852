import {useEffect} from 'react'
import {useSessionStorageState} from 'ahooks'

// model返回的类型定义
export type SessionStateType<T> = [
    T, // model值的引用
    (value: T | ((previousState?: T) => T)) => void, // 修改model值函数的引用
    () => void
];


/**
 * 基于Hooks语法生成数据持久化 state 和与之对应的修改方法
 * @param key 用于在LocalStorage中存储使用的Key
 * @param defaultValue state的初值
 * @returns {state,setState,resetState}
 */
export function useStorageState<T>(key: string, defaultValue: T): SessionStateType<T> {

    const [state, setState] = useSessionStorageState<T>(key, defaultValue)

    // 用于初始加载缓存数据
    useEffect(() => {
        setState(getSessionStorageState())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 获取sessionStorage中数据并返回
    function getSessionStorageState(): T {
        const raw = sessionStorage.getItem(key);
        if (raw) {
            try {
                return JSON.parse(raw);
            } catch (e) {
                console.log("解析数据异常!", e)
            }
        }
        return defaultValue;
    }


    function resetState() {
        setState(defaultValue)
    }

    return [state, setState, resetState];
}

export default useStorageState;
