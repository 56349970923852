import React, { useState } from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { AddGoodsModal } from "./AddGoodsModal";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { Print } from "./Print";

export const SaleAllotDetails = () => {
    const { getSelectedNavState } = useNavModel(() => []);
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);

    return (
        <>
            {detailsInfo && <>
                <TopButs {...detailsInfo} />
                <FormBox {...detailsInfo} />
                <TableBox {...detailsInfo} />
                <FixedBottom {...detailsInfo} />
                <AddGoodsModal {...detailsInfo} />
                <Print />
            </>
            }
        </>
    )
}