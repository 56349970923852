import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useHistoryQueryModel } from "../historyQueryModel";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, selectedRow, setDispatchVisible } = useHistoryQueryModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            {
                id: selectedRow.maintenanceCode as string, mode: "look", parentInfo: { selectedNavId }
            },
            true
        );
    }
    const onDispatchLook = () => {
        setDispatchVisible(true)
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DISP_HISTORY_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DISP_HISTORY_VW_M}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onDispatchLook}
            >维修工查看</Button>
        </YhAuth>

    </>;

    return (
        <YhBox title="历史工单" action={action}>
            <DataTable />
        </YhBox>
    )
}