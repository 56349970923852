import React, { useState, useEffect, FC } from "react";
import { Table, Button, Input, DatePicker, message, Checkbox } from "antd";
import { YHModal } from "@/components/YHModal";
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { IPage, ISearchPage } from "@/types/ScmTypes";
import useGlobalModel from "@/model/globalModel";
import { YhBut } from "@/components/YhButton";
import { formatNum, transPgToPagination } from "@/utils/utils";
import { RouterParamType } from "@/model/navModel";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { ISCMORVRMTransferDetails, ISCMPDrSearchFields } from "../../purchaseDrTypes"
import { IMaterialDetail } from '@/views/base/material/materialService';
import { purchaseDrService } from '../../purchaseDrService';
import { debounce } from "lodash";

enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}

//为已添加的商品增加 数量字段
export interface ISCMPDrSelectGoods
    extends ISCMORVRMTransferDetails {
    pDrNum: number,
    brandCode: string
    brandName: string
    bussCode: string
    bussType: string
};

export interface IMaterialDetailModel extends IMaterialDetail {
    num: number,
    amount: number,
    confirmNum?: number,    // 0,
    damageNum?: number,    // 0,
    inWarehouseCode?: string, // 0,
    inWarehouseName?: string, // 0,
    inShelfCode?: string
}

export const AddGoodsModal: FC<RouterParamType> = ({ id, mode }) => {
    const { user: { shopId }, } = useGlobalModel();
    const {
        editFormVal,ciLoginInfo, setCiLoginInfo,
        addFormVal,
        editGoodsList,
        addGoodsList,
        addGoodsModal: visible,
        setAddGoodsModal,
        add,
        remove,
        transformsToPDrGoods,
        editSelectGoods,
        setEditSelectGoods,
        addSelectGoods,
        setAddSelectGoods,
    } = usePurchaseDrDetailsModel();
    const [checked, setChecked] = useState(true);
    const goodList = mode === 'add' ? addGoodsList : editGoodsList
    //查询条件
    const [searchParam, setSearchParam] = useState<ISCMPDrSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<ISCMORVRMTransferDetails[]>([]);
    //列表数据源分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: 10, current: 1 }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [bool, setBool] = useState<boolean>(false);  //用变量来触发全局事件 ， 否咋拿不到全局状态里面的缓存数据
    const [loading, setLoadding] = useState<boolean>(false);
    const { branchOut, outShopId, outShopName } = mode === "edit" ? editFormVal : addFormVal
    const loadData = async (page?: ISearchPage, ciLogin = ciLoginInfo) => {
        setLoadding(true);
        try {
            let date: Date = new Date();
            let { outShopId } = mode === "edit" ? editFormVal : addFormVal;
            const searchParams = {
                shopId: outShopId,
                // ...searchParam,
                materialCode: branchOut !== 'scm' ? searchParam.materialCode?.trim() : undefined,
                materialName: branchOut !== 'scm' ? searchParam.materialName?.trim() : undefined,
                codeOrName: branchOut === 'scm' ? searchParam.materialCode?.trim() === '' ? searchParam.materialName?.trim() : searchParam.materialCode?.trim() ?? searchParam.materialName?.trim() : undefined,
                availableQuantityGT: branchOut === 'scm' ? 0 : undefined,
                age: branchOut !== 'scm' ? 1 : undefined,
                saleTypeCode: branchOut === 'scm' ? "sale-type.allot" : "sale-type.general",  //选常规商品
                pageSize: page?.pageSize ?? pgCfg.pageSize,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current,

            }
            let retData = {} as IPage<ISCMORVRMTransferDetails>
            switch (branchOut) {
                case "scm":
                    const scmResult = await purchaseDrService.scm2TransferDetails(searchParams);
                    retData = scmResult.retData
                    break;
                case "bcm":
                    const bcmResult = await purchaseDrService.bcmTransferDetails(searchParams);
                    retData = bcmResult.retData
                    break;
                case "vrm":
                    const vrmResult = await purchaseDrService.vrm2TransferDetails(searchParams);
                    retData = vrmResult.retData
                    break;
                case "ci":
                    const ciResult = await purchaseDrService.ciTransferDetails(searchParams, ciLogin.token);
                    retData = ciResult.retData
                    break;
                default:
                    setAddGoodsModal(false)
                    return message.warning("请先选择调出门店")
            }
            const { records, ...pg } = retData;
            setPgCfg(transPgToPagination(pg));
            setDataSource(records.map((item, index) => ({
                ...item,
                inventoryQuantity: branchOut !== 'scm' ? item.stockNum ?? item.inventoryQuantity : Number(item.inventoryQuantity) - item.occupyNum,
                purchaseCode: item.purchaseCode ?? item.bussCode,
                price: item.price ?? item.costPrice,
                bussCode: item.bussCode ?? '',
                bussType: item.bussType ?? ''
            })));
            setLoadding(false);
        } catch (error) {
            setLoadding(false)
        }

    }

    const onGlobalKeyUp = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.keyCode === 13) {
            setBool(origin => !origin);
        }
    };
    //登录云豪科技获取token， 拿到门店名称获取云豪科技机构列表匹配对应机构id
    const ciLogin = async () => {
        let { retData } = await purchaseDrService.ciLogin();
        let { retData: { list } } = await purchaseDrService.ciOrgPage(retData.token);
        //查询调出门店名称 和对应的机构名称
        let findOrg = (list ?? []).find((item: any) => item.mdShopId === outShopId);
        if (!findOrg) {
            message.warning("云豪科技没有对应调出门店的机构");
            onCancel();
            return
        }
        //有实体 && 有门店 && 有调拨权限 才可以进行调拨
        if (!(findOrg.shopCode && findOrg.businessEntityCode && findOrg.allowTransfer)) {
            message.warning(`云豪科技【${outShopName}】该机构没有调拨权限`);
            onCancel();
            return
        }
        //保存对应的机构id
        retData.organInfo.id = findOrg.id
        setCiLoginInfo(retData ?? {})
        loadData(initPgCfg, retData ?? {})
    }
    useEffect(() => {
        bool && transformsToPDrGoods(mode === "edit" ? editSelectGoods : addSelectGoods, mode)
    }, [bool]);

    //注册window的crtl+回车事件来触发当前 弹框确认事件
    useEffect(() => {
        if (visible) {
            document.onkeyup = onGlobalKeyUp;
            if (branchOut === "ci") {
                ciLogin()
            } else {
                loadData();
            }
        }
        else {
            document.onkeyup = null;
            setBool(false);
        }
    }, [visible])

    useEffect(() => {
        return () => {
            document.onkeyup = null;
            setBool(false);
        }
    }, []);

    const onCancel = () => {
        setAddGoodsModal(false);
        setChecked(true)
    };

    const column = (optype: number) => [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 160,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: string) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '商品名称',
            width: 180,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: string) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '商品类型',
            width: 180,
            dataIndex: 'typeName',
        }, {
            title: '货位',
            width: 180,
            dataIndex: 'shelfCode',
        }, {
            title: '可用库存',
            width: 120,
            dataIndex: 'inventoryQuantity',
        }, {
            title: '采购单价',
            width: 120,
            dataIndex: 'latestPurPrice',
        },
        optype === OPT_TYPE.ADD ? {} : {
            title: '数量',
            width: 100,
            dataIndex: 'pDrNum',
            editable: true,
        },
        {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '操作',
            width: 80,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text: any, record: ISCMPDrSelectGoods) => optype === OPT_TYPE.ADD ?
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record, mode)}
                    disabled={
                        !!(mode === "edit" ? editSelectGoods : addSelectGoods).find(item => item.id === record.id) ||
                        !!goodList.find(item => item.key === record.id) ||
                        record.inventoryQuantity <= 0
                    }
                />
                :
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record, mode)}
                />
        }
    ];

    const newSelectColumns = column(OPT_TYPE.REMOVE).map((col: any, index) => {
        return {
            ...col,
            onCell: (record: ISCMPDrSelectGoods, rowIndex: number) => ({
                colIndex: index,
                rowIndex,
                record,
                rules: col.rules,
                options: col.options,
                editType: col.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row: ISCMPDrSelectGoods) => {
        row.pDrNum = +formatNum(row.pDrNum);
        let dataSource = mode === "edit" ? editSelectGoods : addSelectGoods;
        const index = dataSource.findIndex((item) => row.id === item.id);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        mode === "edit" ? setEditSelectGoods([...dataSource]) : setAddSelectGoods([...dataSource]);
    }

    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-5"
            placeholder="商品编号"
            value={searchParam?.materialCode}
            onChange={(e) => setSearchParam({ ...searchParam, materialCode: e.target.value })}
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-5"
            placeholder="商品名称"
            value={searchParam?.materialName}
            onChange={(e) => setSearchParam({ ...searchParam, materialName: e.target.value })}
            onPressEnter={() => loadData(initPgCfg)}
        />
        {/* <Checkbox checked={checked} onChange={(e) => { setChecked(e.target.checked); loadData(initPgCfg); setSearchParam({ ...searchParam}) }} >
            是否有库存
        </Checkbox> */}
        <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => loadData(initPgCfg)}>检索</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={debounce(() => transformsToPDrGoods(mode === "edit" ? editSelectGoods : addSelectGoods, mode))}
        onCancel={onCancel}
        title="调入单-选择配件"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "5px", paddingBottom: 0 }}>
            <Table
                loading={loading}
                rowKey={record => record.id}
                columns={column(OPT_TYPE.ADD) as ColumnsType<ISCMORVRMTransferDetails>}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns}
                dataSource={mode === "edit" ? editSelectGoods : addSelectGoods}
                pagination={false}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}