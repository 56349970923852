import React from "react";
import { useSupplierModel } from "../supplierModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        span: 4,
        placeholder: "供应商编码",
        fieldName: "code",
        size: "small",
    },
    {
        type: SearchTypeEnum.Input,
        span: 4,
        placeholder: "供应商名称",
        fieldName: "name",
        size: "small",
    },
    {
        type: SearchTypeEnum.Input,
        span: 4,
        placeholder: "联系人",
        fieldName: "contact",
        size: "small",
    },
    {
        type: SearchTypeEnum.Input,
        span: 4,
        placeholder: "联系电话",
        fieldName: "phone",
        size: "small",
    },
    {
        type: SearchTypeEnum.Select,
        span: 4,
        placeholder: "状态",
        fieldName: "enable",
        size: "small",
        options: [{ label: "开启", value: 1 }, { label: "关闭", value: 0 }]
    }
]

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSupplierModel();
    return (
        <YhPageHeader
            title="供应商管理"
            justify="end"
            size="small"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => loadData(initPgCfg, {...searchParam,...params})}
            handleReset={resetSearchParam}
        />
    );
}