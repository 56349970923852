import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { usePoListModel } from "../poListModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { descTotal, exportPoList, } = usePoListModel();

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.STT_PL_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportPoList()}
            >导出</Button>
        </YhAuth>
    </>;
    const desc: IDescription[] = [
        {
            label: "采购数量",
            value: descTotal.purchaseQuantity,
            color: DescriptionColorEnum.red
        },
        {
            label: "结算金额",
            value: descTotal.payAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "已付金额",
            value: descTotal.paidAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "已收金额",
            value: descTotal.receivedAmount,
            color: DescriptionColorEnum.red
        },
    ]
    return (
        <YhBox title=" " action={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}