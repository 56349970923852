import React, { useEffect } from "react";
import { Button, Dropdown, Input, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePurchaseRoModel } from "../purchaseRoModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { usePurchaseRoDetailsModel } from "./details/purchaseRoDetailsModel";
import { DownOutlined } from "@ant-design/icons";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, selectedRow, descTotal, rowsDescTotal,setPrint, detailsExport, invalidate, exportPurchaseRo, setSelectedRowKeys } = usePurchaseRoModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const { resetEditCatch } = usePurchaseRoDetailsModel();

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status == "purchase-return.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status == "purchase-return.stock-io") {
            globalPrompt("message", { text: "该单据已入库，无法进行编辑", type: "warning" });
            return;
        }
        let supplierEditKey = "11-3-2";
        let isFindNav = findNav(supplierEditKey);
        if (!isFindNav) {
            addNav(supplierEditKey, { id: selectedRow.returnCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "采购退货跳转详情",
                type: "warning",
                text: "您还有采购退货单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(supplierEditKey, { id: selectedRow.returnCode as string, mode: "edit" });
                    resetEditCatch();
                }
            })
        }
    }

    const desc: IDescription[] = [
        {
            label: "退货总量",
            value: selectedRowKeys.length === 0 ? descTotal.totalNum : (+formatNum(rowsDescTotal.totalNum)).toFixed(0),
            color: DescriptionColorEnum.red
        },
        {
            label: "退货总金额",
            value: selectedRowKeys.length === 0 ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            color: DescriptionColorEnum.red
        },
        {
            label: "已收金额",
            value: selectedRowKeys.length === 0 ? descTotal.paidAmount : formatNum(rowsDescTotal.paidAmount),
            color: DescriptionColorEnum.red
        },
    ]

    const onExport = () => {

    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "11-3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.returnCode as string, mode: "look", },
            true
        );
    }
    const cancellation = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "预售单作废",
            type: "error",
            text: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id as string,
                    returnCode: selectedRow.returnCode as string,
                    cancelReason
                }
                invalidate(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportPurchaseRo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.P_R_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("11-3-1", { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_R_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_R_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_R_EX}>
            <Dropdown overlay={exportMenu}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                >导出<DownOutlined /></Button>
            </Dropdown>
            {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportPurchaseRo().then()}
        >导出</Button> */}
        </YhAuth>
        {/* <YhAuth resCode={ResourceAuthEnum.P_R_CANCEL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={cancellation}
            >作废</Button>
        </YhAuth> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onPrint}
        >打印</Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onSettle}
        >结算</Button>                                
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onWhConfirm}
        >库房出库</Button> */}
    </>;

    return (
        <YhBox title={"采购退货列表"} descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}