import { httpRequest } from "../../../utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {
    IStockDeliveryField,
    IStockDeliverySearch,
    IStockDeliveryDetail,
    IDetailsUpdateParams,
    IDetailsFindPageSearch,
    IDetailsFindPageField,
    IStoreInventoryDetail

} from "./stockOutDispatchType"

class StockOutDispatchService {
    async page(searchVo: IStockDeliverySearch) {
        const apiUrl = "/bcm/maintenance/deliveryPage";
        return await httpRequest.post<IPage<IStockDeliveryDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/maintenance/findById/${id}`;
        return await httpRequest.get<IStockDeliveryDetail>(apiUrl);
    }
    async deliveryUpdate(params: IStockDeliveryField) {
        const apiUrl = `/bcm/maintenance/deliveryUpdate`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    async detailsUpdate(params: IDetailsUpdateParams) {
        const apiUrl = `/bcm/maintenance/detailsUpdate`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //出库
    async stocke(params: IDetailsUpdateParams) {
        const apiUrl = `/bcm/maintenance/stocke`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //退料入库
    async storage(params: IDetailsUpdateParams) {
        const apiUrl = `/bcm/maintenance/storage`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //出入库记录查询
    async detailsFindPage(params: IDetailsFindPageSearch) {
        const apiUrl = `/bcm/stock/details/details/findPage`;
        return await httpRequest.post<IPage<IDetailsFindPageField>>(apiUrl, params);
    }
    //云修分店库存
    async vrm2Inventory(materialCode: string) {
        const apiUrl = `/vrm2/stock/store/inventory`;
        return await httpRequest.post<IStoreInventoryDetail[]>(apiUrl,{materialCode});
    }
    //供应链分店库存
    async scm2Inventory(materialCode: string) {
        const apiUrl = `/scm2/stock/statistic/store/inventory`;
        return await httpRequest.post<IStoreInventoryDetail[]>(apiUrl, {materialCode});
    }
    //客车分店库存
    async bcmInventory(materialCode: string) {
        const apiUrl = `/bcm/stock/store/inventory`;
        return await httpRequest.post<IStoreInventoryDetail[]>(apiUrl, {materialCode});
    }

}

export const stockOutDispatchService = new StockOutDispatchService();