import React, { useEffect } from "react";
import { useRechargeModel } from "./rechargeModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { YhBox } from "@/components/YhBox";
import { RechargeDataTable } from "./DataTable";
import { ApprovalDrawer } from "./ApprovalDrawer";
import { Button, message } from "antd";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const RechargeDataBox = () => {
    const {
        rechargeAmount,
        loadData,
        selectedRowKeys,
        selectedData,
        setApprovalDrawerVisible,
    } = useRechargeModel();

    useEffect(() => {
        loadData().then()
    }, [])

    const desc: IDescription[] = [
        {
            label: "充值总额",
            value: rechargeAmount?.toFixed(2) ?? '0.00',
            color: DescriptionColorEnum.green
        },
    ]
    const onApproval = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            if (selectedData?.status === 0) {
                setApprovalDrawerVisible(true);
            } else {
                message.error("该条记录不可审核!");
            }
        } else {
            message.error("请选择待审核记录!");
        }
    }
    const action = (
        <YhAuth resCode={ResourceAuthEnum.ACC_S_VW_CHARG_AUDIT}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onApproval}
            >
                审核
            </Button>
        </YhAuth>
    );
    return (
        <>
            <YhBox title={"充值记录"} action={action} descriptions={desc}>
                <RechargeDataTable />
            </YhBox>
            <ApprovalDrawer />
        </>
    )
}