import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import useGlobalModel from "./model/globalModel";
import { Login } from "./views/login/Login";
import TokenLogin from './views/login/TokenLogin'
import Index from "./layout";
import { urlParse } from './utils/utils';
import { setToken } from './utils/constants';
import useNavModel from './model/navModel';

function App() {
    const { user } = useGlobalModel();
    const { initNav } = useNavModel();
    useEffect(() => {
        initNav()
    }, [])
    function loginCheck() {
        // const url = 'https://localhost:3000?token=90d587565ba24df99d4f804fb931d2c8'
        const url = window.location.href
        let urlObj = urlParse(url);

        if (urlObj.token) {
            setToken(urlObj.token)
            return <TokenLogin token={urlObj.token} />
        }
        return <Login />
    }
    return (
        <Switch>
            <Route path="/login" component={() => loginCheck()} />
            <Route path="/" render={
                () => user.token ? <Index /> : <Redirect to="/login" />}
            />
        </Switch>
    );
}

export default App;
