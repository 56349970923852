import { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { IVRMShopDetail, IVRMShopSearchFields, shopService, IVRMShopInsert } from './shopService';
import {
	DEFAULT_PAGE_SIZE,
	DEFAULT_FIRST_PAGE_NUM,
	defaultMdCmParam,
} from '../../../utils/constants';
import { ISearchPage } from '@/types/ScmTypes';
import useGlobalModel from '@/model/globalModel';
import { globalPrompt } from '@/components/message';
import { transPgToPagination } from '@/utils/utils';

interface IVRMShopDrawerType {
	visible: boolean;
	mode: 'add' | 'edit';
}

export const useShopModel = createModel(function () {
	const { shopLoadDataAll, shopAndOrg } = useGlobalModel();
	//切换门店清除缓存
	useEffect(() => {
		setSelectedRowKeys([]);
	}, [shopAndOrg.shopCode]);
	//列表数据源
	const [dataSource, setDataSource] = useState<IVRMShopDetail[]>([]);
	//查询条件
	const [searchParam, setSearchParam] = useState<IVRMShopSearchFields>({});
	//分页参数
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//表格多选id值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

	//列表查询
	const loadData = async (page?: ISearchPage, searchValue: IVRMShopSearchFields = searchParam) => {
		const searchParams = {
			...searchValue,
			sourceApp: defaultMdCmParam.sourceApp,
			page: {
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: page?.pageNum ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await shopService.page(searchParams);
		const { records, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(records);
	};
	//设置搜索value
	const updateSearchParam = (updateParam: IVRMShopSearchFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};
	//重置搜索参数
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};

	/**
	 * 详情
	 */
	const initShopDrawer: IVRMShopDrawerType = { visible: false, mode: 'add' };
	const [shopDrawer, setShopDrawer] = useState<IVRMShopDrawerType>(initShopDrawer);
	const setIShopDrawer = (str?: 'add' | 'edit') =>
		setShopDrawer(str ? { visible: true, mode: str } : initShopDrawer);

	//根据id查询
	const getSelectedRowData = () => {
		let filters = dataSource.filter((el) =>
			selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false
		);
		return filters && filters.length > 0
			? {
					...filters[0],
					status: !!!filters[0]?.status,
					details: filters[0]?.details?.map((item) => item.bentityCode) ?? [],
			  }
			: null;
	};

	const insertShop = async (val: IVRMShopInsert) => {
		let { retData } = await shopService.insert(val);
		if (retData) {
			loadData();
			setIShopDrawer();
			shopLoadDataAll();
			globalPrompt('message', { text: '门店新增成功', type: 'success' });
		} else {
			globalPrompt('message', { text: '门店新增失败', type: 'error' });
		}
	};

	//表单编辑 Api
	const editShop = async (val: IVRMShopInsert) => {
		let { retData } = await shopService.update({ ...val, id: selectedRowKeys[0] });
		if (retData) {
			loadData();
			setIShopDrawer();
			shopLoadDataAll();
			globalPrompt('message', { text: '门店修改成功', type: 'success' });
		} else {
			globalPrompt('message', { text: '门店修改失败', type: 'error' });
		}
	};

	//启用 禁用 api
	const disableShop = async (params: object) => {
		let { retData } = await shopService.disable(params);
		if (retData) {
			loadData();
			globalPrompt('message', { text: '操作成功', type: 'success' });
		} else {
			globalPrompt('message', { text: '操作失败', type: 'error' });
		}
	};

	return {
		/***field***/
		searchParam,
		dataSource,
		selectedRowKeys,
		pgCfg,
		/***method***/
		shopDrawer,
		setIShopDrawer,
		getSelectedRowData,
		insertShop,
		editShop,
		setDataSource,
		setSelectedRowKeys,
		resetSearchParam,
		updateSearchParam,
		loadData,
		disableShop,
	};
});
