import { createModel } from "hox";
import { useState, useEffect } from "react";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table/interface";
import { transPgToPagination, bigNumber } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { factoryService } from "./factoryServcie";
import { IFactoryDetail, IFactorySearch } from "./factoryType";

import { message } from "antd";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    partsCost: number,
    totalAmount: number,
    workingHours: number,
}
export const useFactoryModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const [print, setPrint] = useState<boolean>(false);
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IFactorySearch = {};
    const initDataSource: IFactoryDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        partsCost: 0,
        totalAmount: 0,
        workingHours: 0,
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<IFactorySearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IFactoryDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IFactoryDetail>({});

    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //统计信息
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IFactoryDetail)
    }, [selectedRowKeys])

    const updateSearchParam = (updateParam: IFactorySearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }
    const loadData = async (page?: ISearchPage, searchValue: IFactorySearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await factoryService.page(searchParams);
        const { records, statistics, ...pg } = retData;
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)
    }
    const invalidateData = async (obj: object) => {
        return message.success("作废成功");
        let { retData } = await factoryService.invalidate(obj)
        if (retData) {
            message.success("作废成功");
            loadData();
        }
    }
    const exportMaintenance = async (searchValue: IFactorySearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await factoryService.export(searchParams);
        convertRes2Blob(result)
    }
    const [scaniaPrint, setScaniaPrint] = useState<boolean>(false);
    const [settleScaniaPrint, setSettleScaniaPrint] = useState<boolean>(false);
    return {
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        initPgCfg,
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setSelectedRow,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        invalidateData,
        descTotal, setDescTotal,
        print, setPrint,
        exportMaintenance,
        scaniaPrint, setScaniaPrint,
        settleScaniaPrint, setSettleScaniaPrint
    }
})