import { httpRequest } from "../../../utils/HttpRequest";
import {
    ItemDetailed,
    MaterialDetailed,
    IFactoryField,
    IFactorySearch,
    IFactoryDetail,
    IFactoryRoSettleAndWhConfirm,
    IFactoryList
} from "./factoryType"

class FactoryService {
    async page(searchVo: IFactorySearch) {
        const apiUrl = "/bcm/factory/main/findPage";
        return await httpRequest.post<IFactoryList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/factory/main/findById//${id}`;
        return await httpRequest.get<IFactoryDetail>(apiUrl);
    }
    async update(updateVo: IFactoryField) {
        const apiUrl = "/bcm/factory/main/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    // 库房确认
    async warehousing(params: IFactoryRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/settlement/factory/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修用料明细删除
    async materialDelete(params: MaterialDetailed) {
        const apiUrl = "/bcm/factory/main/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修项目明细删除
    async itemDelete(params: ItemDetailed) {
        const apiUrl = "/bcm/factory/main/itemDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //结算
    async settl(params: IFactoryRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/factory/main/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //作废
    async invalidate(params: object) {
        const apiUrl = "/bcm/settlement/factory/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //重新加载维修项目得数据
    async projectQuery(params: string[]) {
        const apiUrl = "/bcm/base/item/projectQuery";
        return await httpRequest.post<ItemDetailed[]>(apiUrl, params);
    }
    //重新加载维修用料得数据
    async find(params: string[]) {
        const apiUrl = "/bcm/stock/find";
        return await httpRequest.post<MaterialDetailed[]>(apiUrl, params);
    }
    //预约转维修
    async transfer(params: object) {
        const apiUrl = "/bcm/settlement/factory/transfer";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: IFactorySearch) {
        const apiUrl = "/bcm/factory/main/export";
        return await httpRequest.postFile(apiUrl, params);
    }
}
export const factoryService = new FactoryService();
