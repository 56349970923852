import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import React, { useState, useEffect } from "react";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";
import { message } from "antd";
import { ISaleRoSearch, ISaleRoDetail, ISaleRoInvalidate } from "./saleRoTypes"
import { saleRoService } from "./saleRoService"
import useGlobalModel from "@/model/globalModel";
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    totalNum: number,
    totalAmount: number,
    discountTotalAmount: number,
    receivedAmount: number,
    latestPurPrice: number,
    receivableAmount: number,
    grossProfit: number
}
export const useSaleRoModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const [print, setPrint] = useState<boolean>(false);
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: ISaleRoSearch = {};
    const initDataSource: ISaleRoDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        totalNum: 0,
        totalAmount: 0,
        discountTotalAmount: 0,
        receivedAmount: 0,
        latestPurPrice: 0,
        receivableAmount: 0,
        grossProfit: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<ISaleRoSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<ISaleRoDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<ISaleRoDetail>({});
    //列表数据源分页参数
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as ISaleRoDetail)
    }, [selectedRowKeys, dataSource])

    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<ISaleRoDetail[]>([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<ISaleRoDetail>();

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            let { totalNum, receivedAmount, receivableAmount, totalCost } = item
            newRowsDescTotal.totalNum = bigNumber.add(newRowsDescTotal.totalNum, formatNum(totalNum))
            newRowsDescTotal.totalAmount = bigNumber.add(newRowsDescTotal.totalAmount, formatNum(receivedAmount))
            newRowsDescTotal.receivableAmount = bigNumber.add(newRowsDescTotal.receivableAmount, formatNum(receivableAmount))
            newRowsDescTotal.latestPurPrice = bigNumber.add(newRowsDescTotal.latestPurPrice, formatNum(totalCost))
            // newRowsDescTotal.totalNum += +formatNum(totalNum)
            // newRowsDescTotal.totalAmount += +formatNum(receivedAmount)
            // newRowsDescTotal.receivableAmount += +formatNum(receivableAmount)
            // newRowsDescTotal.latestPurPrice += +formatNum(totalCost)

        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])
    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: ISaleRoSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
    }
    const loadData = async (page?: ISearchPage, searchValue: ISaleRoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await saleRoService.page(searchParams);
        const { records, statistics, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)
    }
    const invalidate = async (params: ISaleRoInvalidate) => {
        let { retData } = await saleRoService.invalidate(params);
        if (retData) {
            loadData()
            message.success("作废成功")
        }
    }
    const exportSaleRo = async (searchValue: ISaleRoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await saleRoService.export(searchParams);
        convertRes2Blob(result)
    }
    const detailsExport = async (searchValue: ISaleRoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
            returnCode:(descRows.map((item)=>{return item.returnCode})).join()
        }
        let result = await saleRoService.detailsExport(searchParams);
        convertRes2Blob(result)
    }
    const [settleScaniaPrint, setSettleScaniaPrint] = useState<boolean>(false);

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        drawerVisible,
        initPgCfg,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        invalidate,
        descTotal, setDescTotal,
        selectedRow, setSelectedRow,
        exportSaleRo,
        print, setPrint,
        detailsExport,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        settleScaniaPrint, setSettleScaniaPrint,
        setHistoryVisible, setHistoryRow

    }
});