import React, { FC, useState } from "react";
import { Button, Input, message, Table, Tooltip, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { usePreSaleDetailModel } from "./presaleDetailModel"
import { IPreSaleDetailsList } from "../../../../busBusinessType";
import { bigNumber, formatIntPoint, formatIntPoint1, formatNum, repairFormatNum } from "@/utils/utils";
import { DetailType } from "@/types/ScmEnums";
import { ColumnsType } from "antd/es/table";
import { components } from "@/components/YHEditTable/editTable";
import { importPropsConfig, importPropsConfigs, messageConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";
import { globalPrompt } from "@/components/message";
import { ITopType } from "@/types/ScmTypes";
import { useDetailModel } from "../../detailModel";
import {useStockPoDetailModel} from "../../../../detailedInventory/InventoryDetailsModel"
type ISCMSDcGoodsSearch = {
    data: any[],
    isSearch: boolean
}
export const MaterialTableBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const {
        setMaterialModel,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        detailMaterialLookList,
        // detailMaterialDetailList,
        downloadTpl,
        modelMaterialScreenMethod,
        setTopButsControl,
        //setHistoryVisible,
        setHistoryRow,
        detailAddForm,
        detailEditForm,
    } = usePreSaleDetailModel();
    //库存明细
    const {setHistoryVisible} = useStockPoDetailModel()

    const { busBusiness: { warehouseCode, warehouseName } } = useDetailModel()
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMSDcGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据
    const onHistoryModel = (record: IPreSaleDetailsList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const { shopAndOrg: { shopId }, setLoadingInfo, warehouseList } = useGlobalModel()

    // const importCallback = async (list: any) => {
    //     debugger
    //     const newDetailList = mode === "add" ? detailMaterialAddList : detailMaterialEditList
    //     const { repetition, newDetailData } = await modelMaterialScreenMethod(list, newDetailList, parentInfo?.source, mode, 'import')
    //     if (mode === "add") {
    //         setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData])
    //         repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
    //     } else if (mode === "edit") {
    //         setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData])
    //         repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
    //     }
    // }

    //导入明细成功
    const upLoadCallback = (list: any[]) => {
        //debugger
        if (!list?.length) return message.warning('请导入正确格式的配件');
        const isEdit: boolean = mode === "edit";
        const goodsList: IPreSaleDetailsList[] = [...(isEdit ? detailMaterialEditList : detailMaterialAddList)]
        let newShelfRows: any[] = [];
        let str: string = "";
        const repetition: string[] = []; //重复的名字
        const sourceWarehouse = parentInfo?.source ? warehouseList.find((item: any) => item.customerCode === (mode === "add" ? detailAddForm.customerCode : detailEditForm.customerCode)) : { code: warehouseCode, name: warehouseName }
        list = list.reduce((prev, curr) => prev.some((item: { materialCode: any; }) => item.materialCode === curr.materialCode)? prev : [...prev,curr], [])
        //debugger
        list.forEach(item => {
            let num: number = item.num ?? 0;
            let price: number = item.price
            if (!item.success) {
                str += `【${item.msg}】`
            }
            if (item.inventoryDetailsList && item.inventoryDetailsList.length) {
                const tempArr = item.inventoryDetailsList;
                for (let i = 0; i < tempArr.length && num > 0; i++) {
                    let good = tempArr[i];
                    let findObj = goodsList.find(originGoods => originGoods.purchaseDetailsId === good.purchaseDetailsId);
                    if (findObj) {
                        repetition.push(`${item.materialCode}并且采购单号${item.purchaseCode}相同`);
                    }
                    // 公共字段
                    let publicData = {

                        allocateCode: good.allocateCode,
                        allocateId: good.allocateId,
                        // availableNum: ,
                        // customerCode: ,
                        // customerName: ,
                        // inventoryAmount: ,
                        // inventoryCost: ,
                        inventoryNum: good.inventoryNum,
                        latestPurPrice: good.latestPurPrice,

                        // occupyNum: ,
                        // orderCode: ,
                        // preSaleType: ,
                        purchaseCode: good.purchaseCode,
                        replaceCode: good.replaceCode,
                        // returnNum: ,
                        // saleNum: ,
                        // salePrice: ,

                        discountRate: 100,
                        id: "",
                        price,
                        saleNum: 0,
                        materialCode: good.materialCode,
                        materialName: good.materialName,
                        materialNo: good.materialNo,
                        avallable: good.availableStock,
                        note: good.note,
                        unitName: good.unitName,
                        unitCode: good.unitCode,
                        stockId: good.id,
                        detailsId: good.id,
                        warehouseCode: good.warehouseCode,
                        warehouseName: good.warehouseName,
                        storageWarehouseCode: sourceWarehouse?.code,
                        storageWarehouseName: sourceWarehouse?.name,
                        purchaseId: good.id,
                        costPrice: good.latestPurPrice,
                        //costAmount: +formatNum(Number(good.latestPurPrice) * good.availableStock),
                        costAmount:+bigNumber.toFixed(bigNumber.times(good.latestPurPrice as number,good.availableStock),2)
                    }
                    let kynum = good.availableStock;
                    // 可用库存 = 库存数量 - 占用库存
                    // let kynum = goods.inventoryQuantity - goods.occupyNum;
                    //当匹配到最后一个配件, 可用库存不满足用户填写数量, 那就设置该配件为异常配件, 数量设置为减完前面配件可用库存剩余的数量
                    if (i === tempArr.length - 1 && kynum < num) {
                        let amount = +repairFormatNum(Number(price ?? 0) * (Number(num ?? 1)))
                        newShelfRows.push({
                            ...publicData,
                            error: item.msg,
                            num, //开单数量
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            amount,
                            totalAmount: amount,
                        })
                        break
                    }
                    if (kynum) {
                        num = num - kynum;
                        // 当 可用数量 小于
                        let tempNum = num < 0 ? kynum + num : kynum;
                        //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                        let amount = +repairFormatNum(Number(price ?? 0) * Number(tempNum ?? 1))
                        newShelfRows.push({
                            ...publicData,
                            num: tempNum, //开单数量
                            amount,
                            totalAmount: amount
                        })
                    }
                }
            }
        })

        isEdit ? setDetailMaterialEditList(origin => [...origin, ...newShelfRows]) : setDetailMaterialAddList(origin => [...origin, ...newShelfRows]);
        repetition.length != 0 && globalPrompt("message", { text: `商品编号：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        setLoadingInfo({ loadingVisible: false, txt: "" });
        messageConfig(str ? str : "导入成功", 20, "info");
    };

    const materialModel = () => {
        IFormRef.current?.validateFields().then(async res => {
            setMaterialModel(true)
        })
    }
    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource: any | null = null) => {
        // const isEtit = mode !== "add";
        let goodsList = dataSource ? dataSource : typeMaterialList[mode];
        if (searchParms) {
            let reg = new RegExp(searchParms);
            let arr: any = [];
            goodsList.forEach((item: { materialCode: string; materialName: string; }) => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })
            setSearchVal({ data: arr, isSearch: true });
            if (!arr.length) {
                message.warning("列表没有该商品！！")
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }
    //删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailMaterialDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailMaterialAddList.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialAddList(filterDetailList)
            let newSearchData = searchVal.data.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (mode === "edit") {
            const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialEditList(filterDetailList)
            let newSearchData = searchVal.data.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialEditDataRowKeys([]);
            setDetailMaterialEditDataRow([]);
        }
    }
    const action = <>
      <Input
                size="small"
                allowClear
                datatype="round"
                className="border-r-8 ml-10"
                placeholder="配件编号/配件名称"
                onChange={(e) => {setSearchParms(e.target.value );goodsFilter()}}
                onBlur={(e) => setSearchParms(e.target.value?.trim() ) }
                onPressEnter={() => goodsFilter()}
                style={{width:'200px'}}
            />
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 查询</Button>
        {mode !== "look" && <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => materialModel()}
            >新增商品</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => detailMaterialDetailList(mode)}
            >删除商品</Button>
            <Upload showUploadList={false} className="mr-10 ml-10" {
                ...importPropsConfigs(
                    `/bcm/presaleorder/details/import/${shopId}`,
                    upLoadCallback,
                    () => setLoadingInfo({ loadingVisible: true, txt: "快速导入中" }), false)
            }>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => downloadTpl().then()}
            >下载模板</Button>
            {/* <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => setMaterialModel(true)}
            >导入</Button> */}
        </>}
    </>;
    const MaterialColumns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '公交编码',
            width: 120,
            dataIndex: 'materialNo',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span">{text}</p></Tooltip>,
        }, {
            title: '商品编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '原始仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '代储仓库',
            width: 120,
            dataIndex: 'storageWarehouseName',
        }, {
            title: '预售数量',
            width: 120,
            dataIndex: 'num',
            editable: mode != "look" ? true : false,
        },
        ...((str) => {
            if (str) {
                return [
                    {
                        title: '销售数量',
                        width: 120,
                        dataIndex: 'num',
                    },
                ]
            } else {
                return []
            }
        })(parentInfo),
        {
            title: '单价',
            width: 120,
            dataIndex: 'price',
            editable: mode != "look" ? true : false,
        }, {
            title: '零件费',
            width: 120,
            dataIndex: 'amount',
        },
        {
            title: '总计',
            width: 120,
            dataIndex: 'totalAmount',
        }, {
            title: '可用库存',
            width: 100,
            dataIndex: 'avallable',
        }, {
            title: '采购单号',
            width: 100,
            dataIndex: 'purchaseCode',
        }, {
            title: '成本价',
            width: 100,
            dataIndex: 'costPrice',
        }, {
            title: '成本金额',
            width: 100,
            dataIndex: 'costAmount',
        },
        {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable:mode !="look"? true:false
        }
    ];
    const newSelectMaterialColums = () =>
        MaterialColumns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IPreSaleDetailsList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave = async (row: IPreSaleDetailsList) => {
        let { num, price, discountRate, avallable, costPrice } = row
        num = +formatIntPoint(num, avallable, "选择数量>=可用库存数量");  //数量
        // price = +formatIntPoint1(price,costPrice, `单价不应小于成本价${costPrice}`);  //价格
        price = +formatIntPoint1(price);  //价格
        discountRate = +formatIntPoint1(discountRate, 100, '折扣不能高于100%');  //折扣
        let newRow = {
            ...row,
            num,
            price: +formatNum(price),
            discountRate,
            //amount: +formatNum(Number(num) * price),
            amount:+bigNumber.toFixed(bigNumber.times(num as number,price),2),
            //totalAmount: +formatNum(Number(num) * price * discountRate / 100),
            totalAmount:+bigNumber.toFixed(bigNumber.times( bigNumber.times( num as number,price),bigNumber.dividedBy(discountRate,100)),2) ,
            costPrice: costPrice,
            //costAmount: +formatNum(Number(costPrice) * Number(num)),
            costAmount:+bigNumber.toFixed(bigNumber.times(costPrice as number,num as number),2)
        }

        let newData = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailMaterialEditList([...newData]) : setDetailMaterialAddList([...newData])
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(newData)
        }
        setTopButsControl(true)
    }
    const typeMaterialList = {
        "add": [...detailMaterialAddList],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    //设置维修用料选中的行和数据
    const setTypeMaterialDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeMaterialDetailDataRow = async (selectedRows: IPreSaleDetailsList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectMaterialColums() as ColumnsType<IPreSaleDetailsList>}
                dataSource={searchVal.isSearch ? searchVal.data :typeMaterialList[mode]}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailMaterialAddDataRowKeys : detailMaterialEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IPreSaleDetailsList[]) => {
                            setTypeMaterialDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        }
                    }}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }}
            />
        </YhBox>
    )
}