import React from "react";
import { YhBox } from "@/components/YhBox";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { useAccountModel } from "../account/accountModel";
import { useRechargeModel } from "./rechargeModel";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel from "@/model/globalModel";
import { useOrgModel } from "@/views/organization/org/orgModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";


export const RechargeSearchBox = () => {
    const {
        supplierUserDs,
    } = useAccountModel();
    const { beDs } = useGlobalModel();
    const { getOrgTreeSelect } = useOrgModel();
    const {
        onSearch,
        searchParam,
        onReset,
        updateSearchParam,loadData
    } = useRechargeModel()


    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "账户",
            fieldName: "accountCode",
            options: supplierUserDs,
        }, {
            type: SearchTypeEnum.TreeSelect,
            placeholder: "机构",
            fieldName: "orgCode",
            treeData: getOrgTreeSelect(DictCodeEnmu.ORG)
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "实体",
            fieldName: "beCode",
            options: beDs
        }, {
            type: SearchTypeEnum.FmsRangePicker,
            fieldName: "rechargeTimeRange",
            placeholder: ["起始日期", "结束日期"],
        },

    ]

    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title=""
                justify="end"
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                onFmsChange={updateSearchParam}
                                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={onReset}
            />
        </YhBox>
    )
}
