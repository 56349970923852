import React, { FC } from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { RouterParamType } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useSaleRoDetailModel } from "./saleRoDetailModel";
import { Description } from "@/components/YhBox/Description";
import { useDictModel } from "@/views/base/dict/dictModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const TopButs: FC<RouterParamType> = ({ id, mode }) => {
    const { setSettleVisible, setWsConfirmModal,
        detailDataSource,
        detailLookList,
        detailAddForm,
        detailEditForm,
        detailLookForm,
        topButsControl,
    } = useSaleRoDetailModel();
    const { getTreeNodeName } = useDictModel();

    const onWhConfirm = () => {
        if (detailDataSource.length !== -1 && mode === "edit") {
            setWsConfirmModal(true)
        }
        else if (detailLookList.length !== -1 && mode === "look") {
            setWsConfirmModal(true)
        } else {
            globalPrompt("message", { type: "error", text: `请添加商品详情` })
        }
    }
    const onSettle = () => {
        setSettleVisible(true)
    }
    const action = mode === "edit" ? <>
    
        <YhAuth resCode={ResourceAuthEnum.S_R_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onSettle}
                disabled={topButsControl || detailEditForm.status === "sale-return-status.settle"}
            >确认结算</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_R_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onWhConfirm}
                disabled={topButsControl || detailEditForm.status === "sale-return-status.stock-io"}
            >库房入库</Button>
        </YhAuth>
    </> : mode === "look" ? <>
        <YhAuth resCode={ResourceAuthEnum.S_R_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                // disabled={topButsControl}
                onClick={onSettle}
            >结算查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_R_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                // disabled={topButsControl}
                onClick={onWhConfirm}
            >入库查看</Button>
        </YhAuth>

    </> : false;

    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatu: IDescription[] = [
        {
            label: "销售退货单",
            value: typeTitle[mode]?.returnCode as string ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}