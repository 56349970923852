import React, { FC, useState, useEffect } from "react";
import { IFormItem } from "../Types";
import { Badge, Upload, Button, Image, Form, Input } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import { UploadChangeParam } from "antd/lib/upload";

export const __FormFileImage: FC<IFormItem> = ({ md5List = [], onSetFieldsValue, btnTitle = "上传", ...props }) => {
    const fileUrl = "https://file-server.laoniuche.cn"; //生产
    const [previewImage, setpreviewImage] = useState<string[]>(md5List); //商品缩略图

    // 图片上传
    const handleImgChange = (info: UploadChangeParam) => {
        if (info.file.status === "done") {
            let originImg = [...previewImage];
            let md5 = info?.file?.response?.retData as string;
            originImg.push(md5)
            setpreviewImage(originImg)
        }
    };

    useEffect(() => {
        setpreviewImage(md5List)
    }, [md5List])

    useEffect(() => {
        onSetFieldsValue && onSetFieldsValue({ [props.fieldName]: previewImage })
    }, [previewImage]);

    return (
        <>
            <Form.Item name={props.fieldName} hidden><Input /></Form.Item>
            <Form.Item
                label={props.fieldLabel}
                rules={props.rules}
                labelCol={props.labelCol}
            >
                {previewImage && previewImage.map((item, index) => (
                    <div key={index} className="img-file-box">
                        <Badge
                            count={props.disable ? null : <CloseCircleOutlined style={{ color: '#f5222d' }} onClick={(e) => {
                                e.stopPropagation()
                                let originImg = previewImage.filter(imgMd5 => imgMd5 !== item);
                                //console.log(originImg, item)
                                setpreviewImage(originImg);
                            }} />}
                        >
                            <Image src={`${fileUrl}/file/fetch?id=${item}`} />
                        </Badge>
                    </div>
                ))}
                {!props.disable && <Upload
                    disabled={props.disable}
                    name='file'
                    showUploadList={false}
                    action={`${fileUrl}/file/uploadFile`}
                    onChange={handleImgChange}
                >
                    <Button type="link" disabled={props.disable}>{btnTitle}</Button>
                </Upload>}
            </Form.Item>
        </>
    )
}
