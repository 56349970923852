import React, { useState } from "react";
import { Button, message } from "antd";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { YhBox } from "@/components/YhBox";
import { DataTable } from "./DataTable";
import useNavModel from "@/model/navModel";
import { useSummaryModel } from "./summaryModel";
import { useDetailModel } from "../detail/detailModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";


export const DataBox = () => {
    const { addNav } = useNavModel(() => []);
    const { selectedRowKeys, summaryData, setInitAccountVisible, loadDataAll, setSynchroModel } = useSummaryModel();
    const { setSupplierCode } = useDetailModel(() => []);
    const [syncLoading, setSyncLoading] = useState<boolean>(false);

    const desc: IDescription[] = [
        {
            label: "已用额度合计",
            value: summaryData?.totalUsedAmount?.toFixed(2) ?? 0.00,
            color: DescriptionColorEnum.green
        },
        {
            label: "可用额度合计",
            value: summaryData?.totalAvailableAmount?.toFixed(2) ?? 0.00,
            color: DescriptionColorEnum.green
        },
        {
            label: "剩余总额合计",
            value: summaryData?.totalRemainingAmount?.toFixed(2) ?? 0.00,
            color: DescriptionColorEnum.green
        },
        {
            label: "欠款总计",
            value: summaryData?.totalDebtAmount?.toFixed(2) ?? 0.00,
            color: (summaryData?.totalDebtAmount ?? 0.00) > 0 ? DescriptionColorEnum.red : DescriptionColorEnum.green
        },
    ]

    const onDetail = () => {
        if (selectedRowKeys && selectedRowKeys.length === 1) {
            if (selectedRowKeys[0]?.code) {
                setSupplierCode(selectedRowKeys[0]?.code);
                addNav("15-2-1");
            }
        } else {
            message.error("请选择单条数据进行操作");
        }
    }

    const onInitAccount = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            setInitAccountVisible(true);
        } else {
            loadDataAll()
        }
    }

    const action = (
        <>
            <YhAuth resCode={ResourceAuthEnum.ACC_S_VW}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onDetail}
                >
                    查看
            </Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ACC_S_INIT}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onInitAccount}
                >
                    账户初始化
            </Button>
            </YhAuth>
            {/* <Button
                className="mr-5"
                type="link"
                shape="round"
                loading={syncLoading}
                onClick={() => {
                    setSyncLoading(true);
                    syncData().then(res => setSyncLoading(false)).catch(err => setSyncLoading(false));
                }}
            >{syncLoading ? "同步中" : "同步"}
            </Button> */}
            <YhAuth resCode={ResourceAuthEnum.DATA_S_SYNC}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => {
                        setSynchroModel(true)
                    }}
                >同步</Button>
            </YhAuth>
        </>
    );

    return (
        <YhBox title=" " descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}
