import { BillIoFlagEnum } from "./ScmEnums"

// 收支类型
export const ioFlagRender = (v: number) => {
  return (
    v === BillIoFlagEnum.IN ? '收'
      : v === BillIoFlagEnum.OUT ? '付'
        : ''
  )
}

// 订单收款状态
export const billStatusRender = (v: number) => {
  return (
    v === 0 ? "初始"
      : v === 1 ? '已记账'
        : v === 2 ? '已核销'
          : ""
  )
}

// 日志操作结果
export const logResultRender = (v: number) => {
  return (
    v === 0 ? '初始'
      : v === 1 ? '成功'
        : '失败'
  )
}