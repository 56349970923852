import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, message } from "antd";
import { DeploymentDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useDeploymentModel } from "./deploymentModel";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
import { formatNum } from "@/utils/utils";
export const DeploymentDataBox = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        exportSaleSo
    } = useDeploymentModel();
    const { selectedNavId, replaceNav } = useNavModel();

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.allocateCode as string, mode: "look", },
            true
        );
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportSaleSo()}
        >
            导出
        </Button>
    </>
    const desc: IDescription[] = [
        {
            label: "调配数量",
            value: selectedRowKeys.length === 0 ? descTotal?.num??0 : rowsDescTotal?.num??0,
            color: DescriptionColorEnum.red
        },
        {
            label: "调配金额",
            value: selectedRowKeys.length === 0 ? descTotal?.amount??0 : formatNum(rowsDescTotal?.amount??0),
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <YhBox title="调配记录" action={action} descriptions={desc}>
            <DeploymentDataTable />
        </YhBox>
    </>
}