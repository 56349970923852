import { httpRequest } from '@/utils/HttpRequest';
import { ISearchPage, IPage } from '@/types/ScmTypes';

export interface ISCMAllocDetail {
	inShelfCode: string; // null
	inStockIoNum: string; // 0
	materialCode: string; // "A-5010-105"
	materialName: string; // "预选阀"
	note: string; // ""
	num: string; // 1
	outShopName: string; // "法士特中心库"
	shopName: string; // "潍柴事业部"
	stockInStatus: string; // "sale-stock—io-status.init"
	stockIoNum: string; // 0
	stockOutStatus: string; // "pur-stock—io-status.init"
	transferCode: string; // "WCS-DB21081700021"
	transferInTime: string; // null
	transferOutTime: string; // null
	warehouseCode: string; // null
	warehouseName: string; // null
}

//搜索条件
export interface ISCMAllocSearchFields {
	[key: string]: any;
}

export interface ISCMAllocSearch extends ISCMAllocSearchFields, ISearchPage {}

//进销存报表
class AllocService {
	async page(searchVo: ISCMAllocSearch) {
		const apiUrl = '/sms/transfer/to/managePage';
		return await httpRequest.post<IPage<ISCMAllocDetail>>(apiUrl, searchVo);
	}
	async export(searchVo: ISCMAllocSearch) {
		const apiUrl = '/sms/transfer/to/statisticsExport';
		return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
	}
}

export const allocService = new AllocService();
