import React, { useEffect } from "react";
import { Button, Dropdown, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useSummaryCustomerModel } from "../customerModel";
import useGlobalModel from "@/model/globalModel";
import { DownOutlined } from "@ant-design/icons";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const DataBox = () => {
    const { selectedRowKeys, descTotal, selectedRow, setPrint, exportMaintenance,
        setScaniaPrint, setSelectedRowKeys,
        setSettleScaniaPrint } = useSummaryCustomerModel();
    const { shopAndOrg: { shopCode } } = useGlobalModel()
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const desc: IDescription[] = [
        {
            label: "结算金额",
            value: descTotal.totalAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "工时费",
            value: descTotal.workingHours,
            color: DescriptionColorEnum.red
        },
        {
            label: "零件费",
            value: descTotal.partsCost,
            color: DescriptionColorEnum.red
        },
    ]
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            {
                id: selectedRow.maintenanceCode as string, mode: "look", parentInfo: { selectedNavId }
            },
            true
        );
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const onSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setScaniaPrint(true)
    }
    const onSettleSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setSettleScaniaPrint(true)
    }
    const PrintMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={onSknyPrint}>工单打印</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={onSettleSknyPrint}>结算打印</span>
                </Menu.Item>
            </Menu>
        )
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.BILL_C_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.BILL_C_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportMaintenance().then()}
            >导出</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.BILL_C_PRINT}>
            {shopCode === "SHOP-M-00000018" || shopCode === "SHOP-M-00000017" ?
                <Dropdown overlay={PrintMenu}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                    >打印<DownOutlined /></Button>
                </Dropdown> :
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onPrint}
                >打印</Button>
            }
        </YhAuth>

    </>;

    return (
        <YhBox title="客户结算列表" descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}