import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, message } from "antd";
import { SaleReturnDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useSaleReturnModel } from "./saleReturnModel";
import { formatNum } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
import { useSaleReturnDetailModel } from "./detail/saleReturnDetailModel";
import { SalePrint } from "./SalePrint";

export const SaleReturnDataBox = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        invalidateData,
        print, setPrint,
        exportSaleSo
    } = useSaleReturnModel();
    const { resetEditCatch } = useSaleReturnDetailModel()
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();

    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        globalPrompt("modal", {
            title: "销退记录作废",
            type: "error",
            text: "",
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    returnCode: selectedRow.returnCode as string,
                }
                invalidateData(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let saleSoEditKey = "16-6-2";
        let isFindNav = findNav(saleSoEditKey);
        if (!isFindNav) {
            addNav(saleSoEditKey, { id: selectedRow?.returnCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "销退记录跳转详情",
                type: "warning",
                text: "您还有销退记录详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleSoEditKey, { id: selectedRow?.returnCode as string, mode: "edit" });
                    // updateEditFormVal(null);
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-6-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow?.returnCode as string, mode: "look", },
            true
        );
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => addNav('16-6-1', { id: "", mode: "add" })}
        >
            新增
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onEdit}
        >
            编辑
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => onPrint()}
        >
            打印
        </Button> */}
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onInvalidate}
        >
            作废
        </Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportSaleSo().then()}
        >
            导出
        </Button>
    </>
    const desc: IDescription[] = [
        {
            label: "销退数量",
            value: selectedRowKeys.length === 0 ? descTotal?.totalNum??0 : (rowsDescTotal?.totalNum??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "销退金额",
            value: selectedRowKeys.length === 0 ? descTotal?.totalAmount??0 : formatNum(rowsDescTotal?.totalAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "已付金额",
            value: selectedRowKeys.length === 0 ? descTotal?.receivableAmount??0 : formatNum(rowsDescTotal?.receivedAmount??0),
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <YhBox title="销退记录" action={action} descriptions={desc}>
            <SaleReturnDataTable />
        </YhBox>
        {print && <SalePrint />}
    </>
}