import { useEffect, useRef, useState } from 'react'
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";
import Modal from 'antd/lib/modal/Modal'
import { FormItemTypeEnum, IYhFormBox, IYhFormItemsBox, IYhFormRef } from '@/components/YhFormBox/Types'
import { message } from 'antd'
// import { ISelectOptions } from '@/types/AppTypes'
// import { warehouseService } from '@/views/base/warehouseManage/warehouseService'
import { globalPrompt } from '@/components/message'
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import { YhFormBox } from '@/components/YhFormBox'

export const ChangeWarehouseModel = ({ mode, visible, setVisible }: { mode: string, visible: boolean, setVisible: (value: boolean) => void }) => {
    const { warehouseList, setLoadingInfo, shopAndOrg, userList, userData, fetchUserList } = useGlobalModel()
    const {editGoodsList,addGoodsList,setEditGoodsList,setAddGoodsList} = usePurchaseDrDetailsModel()
    const formRef = useRef<IYhFormRef>()
    const [data, setData] = useState<any>({inWarehouseName:'',inWarehouseCode:'', inShelfCode:'临时货位' })
    const [shelfAllList, setShelfAllList] = useState<any>([])
    // const changeshelfCode = async (value:any, options:any) => {
    //     console.log(warehouseList.find((item:any) => item.value === value)?.label);
    //     debugger
    //     setData({ 
    //         inWarehouseCode:value,
    //         inWarehouseName:value?warehouseList.find((item:any) => item.value === value)?.label:''
    //         })
    // }
    useEffect(() => {
        setData({inWarehouseName:'',inWarehouseCode:'', inShelfCode:'临时货位' })
	}, [visible]);
    const onOk = () => {
        globalPrompt('modal', {
			title: '',
			type: 'warning',
			text: '您是否批量调整仓库货位？',
			okText: '确认',
			onOk:  () => {
                let dataSource = editGoodsList
                let newList:any=[]
                dataSource.map((item:any)=>{
                    if(!item.inWarehouseCode||!item.inWarehouseName||!item.inShelfCode){
                        newList.push({...item,...data})
                    }else{
                        newList.push(item)
                    }
                })
                setEditGoodsList([...newList])
                setVisible(false)
			},
			cancelText: '取消',
			onCancel: () => {},
		})
    }

    const formItem: IYhFormItemsBox = {
        title: '仓库货位批量调整',
        style: { padding: '20px', marginTop: '20px' },
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: '仓库',
                fieldName: 'inWarehouseCode',
                options: transOptions(warehouseList.filter((item: any) => item.type !== 'warehouse-category.small')),
                span: 24,
                // onSelectChange: changeshelfCode,
                render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
            },
            // {
            //     type: FormItemTypeEnum.Select,
            //     fieldLabel: '货位',
            //     fieldName: 'inShelfCode',
            //     options: shelfAllList,
            //     span: 24,
            // },
            // {
            //     type: FormItemTypeEnum.Input,
            //     fieldLabel: '货位',
            //     fieldName: 'inShelfCode',
            //     span: 24,
            //     disable:true,
            // },
        ],
    }

    const onChange = (changedValues: { [key: string]: any }) => {
        setData({ 
            ...data,
            ...changedValues,
            inWarehouseName:warehouseList.find((item:any) => item.code === changedValues.inWarehouseCode)?.name??''
            })
    }

    const FromConfig: IYhFormBox<any> = {
        formRef,
        labelAlign: 'right',
        formValues: data,
        onChange,
        boxs: [formItem],
    }

    return (
        <Modal
            visible={visible}
            cancelText="取消"
            okText="确认"
            onCancel={() => {
                setVisible(false)
            }}
            onOk={onOk}
        >
            <YhFormBox {...FromConfig} />
        </Modal>
    )
}
