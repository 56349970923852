import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {
    IVRMWarehouseSearch,
    IVRMShelvesBatchInsert,
    IVRMWarehouseDetail,
    IVRMWarehouseInsert,
    IVRMWarehouseUpdate,
    IVRMAreaSearchFields,
    IVRMAreaDetail,
    IVRMAreaInsert,
    IVRMAreaBatchInsert,
    IVRMAreaUpdate,
    IVRMShelvesSearch,
    IVRMShelvesDetail,
    IVRMShelvesInsert,
    IVRMShelvesUpdate,
    IVRMshelfSearch,
    IVRMshelfDetail,
    IVRMshelfInsert,
    IVRMshelfBatchInsert,
    IVRMShelfUpdate
} from "./warehouseTypes";


class WarehouseService {
    //仓库
    async page(searchVo: IVRMWarehouseSearch) {
        const apiUrl = "/bcm/base/warehouse/findPage";
        return await httpRequest.post<IPage<IVRMWarehouseDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/base/warehouse/findById/${id}`;
        return await httpRequest.get<IVRMWarehouseDetail>(apiUrl);
    }
    async insert(insertVo: IVRMWarehouseInsert) {
        const apiUrl = "/bcm/base/warehouse/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    async update(updateVo: IVRMWarehouseUpdate) {
        const apiUrl = "/bcm/base/warehouse/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }


    async areaPage(searchVo: IVRMAreaSearchFields) {
        const apiUrl = "/bcm/base/warehouse/area/listPage";
        return await httpRequest.post<IPage<IVRMAreaDetail>>(apiUrl, searchVo);
    }
    async areaInsert(insertVo: IVRMAreaInsert) {
        const apiUrl = "/bcm/base/warehouse/area/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //批量录入区域
    async areaBatchInsert(insertVo: IVRMAreaBatchInsert) {
        const apiUrl = "/bcm/base/warehouse/area/batchinsert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    async areaUpdate(updateVo: IVRMAreaUpdate) {
        const apiUrl = "/bcm/base/warehouse/area/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //区域导入
    async areaImport(file: { file: File }) {
        const appPageUrl = "vrm2/base/warehouse/area/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //区域模板下载
    async areaDownloadTpl() {
        const appPageUrl = "/bcm/base/warehouse/area/downloadTpl";
        return await httpRequest.getFile<boolean>(appPageUrl);
    }


    async shelvesPage(searchVo: IVRMShelvesSearch) {
        const apiUrl = "/bcm/base/warehouse/shelves/listPage";
        return await httpRequest.post<IPage<IVRMShelvesDetail>>(apiUrl, searchVo);
    }
    async shelvesInsert(insertVo: IVRMShelvesInsert) {
        const apiUrl = "/bcm/base/warehouse/shelves/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //批量录入货架
    async shelvesBatchInsert(insertVo: IVRMShelvesBatchInsert) {
        const apiUrl = "/bcm/base/warehouse/shelves/batchinsert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    async shelvesUpdate(updateVo: IVRMShelvesUpdate) {
        const apiUrl = "/bcm/base/warehouse/shelves/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //货架导入
    async shelvesImport(file: { file: File }) {
        const appPageUrl = "vrm2/base/warehouse/shelves/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //货架模板下载
    async shelvesDownloadTpl() {
        const appPageUrl = "/bcm/base/warehouse/shelves/downloadTpl";
        return await httpRequest.getFile<boolean>(appPageUrl);
    }


    async shelfPage(searchVo: IVRMshelfSearch) {
        const apiUrl = "/bcm/base/warehouse/shelf/findPage";
        return await httpRequest.post<IPage<IVRMshelfDetail>>(apiUrl, searchVo);
    }
    async shelfAllPage(searchVo: IVRMshelfSearch) {
        const apiUrl = "/bcm/base/warehouse/shelf/allPage";
        return await httpRequest.post<IVRMshelfDetail[]>(apiUrl, searchVo);
    }
    async shelfInsert(insertVo: IVRMshelfInsert) {
        const apiUrl = "/bcm/base/warehouse/shelf/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //批量新增货位
    async shelfBatchInsert(insertVo: IVRMshelfBatchInsert) {
        const apiUrl = "/bcm/base/warehouse/shelf/batchinsert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    async shelfUpdate(updateVo: IVRMShelfUpdate) {
        const apiUrl = "/bcm/base/warehouse/shelf/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //货位导入
    async shelfImport(file: { file: File }) {
        const appPageUrl = "vrm2/base/warehouse/shelf/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //货位模板下载
    async shelfDownloadTpl() {
        const appPageUrl = "/bcm/base/warehouse/shelf/downloadTpl";
        return await httpRequest.getFile<boolean>(appPageUrl);
    }
    //货位导出
    async shelfExport(params:object) {
        const appPageUrl = "/bcm/base/warehouse/shelf/export";
        return await httpRequest.postFile<boolean>(appPageUrl,params);
    }
    //仓库下载模板
    async downloadTpl() {
        const appPageUrl = "/bcm/base/warehouse/downloadTpl";
        return await httpRequest.getFile<boolean>(appPageUrl);
    }

}

export const warehouseService = new WarehouseService();
