import React, { FC } from "react";
import { Button, Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { useSaleReturnDetailModel } from "./saleReturnDetailModel"
import { ISaleDetailsList } from "../../../../busBusinessType";
import { bigNumber, formatIntPoint, formatNum } from "@/utils/utils";
import { DetailType } from "@/types/ScmEnums";
import { ColumnsType } from "antd/es/table";
import { components } from "@/components/YHEditTable/editTable";
import { ITopType } from "@/types/ScmTypes";
import { useStockPoDetailModel } from "@/views/busBusiness/detailedInventory/InventoryDetailsModel";

export const MaterialTableBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        setMaterialModel,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        detailMaterialLookList,
        detailMaterialDetailList,
        setTopButsControl,
        //setHistoryVisible,
        setHistoryRow
    } = useSaleReturnDetailModel();
    const {setHistoryVisible} = useStockPoDetailModel()
    const onHistoryModel = (record: ISaleDetailsList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const switchModelViaible = () => {
        IFormRef.current?.validateFields().then(async res => {
            setMaterialModel(true)
        })
    }
    const action = <>
        {mode !== "look" && <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => switchModelViaible()}
            >新增商品</Button>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => detailMaterialDetailList(mode)}
            >删除商品</Button>
        </>}
    </>;
    const MaterialColumns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '销售单号',
            width: 120,
            dataIndex: 'saleCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span">{text}</p></Tooltip>,
        }, {
            title: '公交编码',
            width: 120,
            dataIndex: 'materialNo',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span">{text}</p></Tooltip>,
        }, {
            title: '商品编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,

        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '销售数量',
            width: 120,
            dataIndex: 'saleNum',
        }, {
            title: '销售单价',
            width: 120,
            dataIndex: 'salePrice',
        }, {
            title: '退货数量',
            width: 120,
            dataIndex: 'num',
            editable: mode != "look" ? true : false,
        }, {
            title: '退货单价',
            width: 120,
            dataIndex: 'price',
            editable: mode != "look" ? true : false,
        }, {
            title: '退货金额',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '已退数量',
            width: 120,
            dataIndex: 'sreturnNum',
        }, {
            title: '品牌',
            width: 120,
            dataIndex: 'brandName',

        }, {
            title: '规格',
            width: 120,
            dataIndex: 'model',
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }, {
            title: '单位',
            width: 120,
            dataIndex: 'unitName',
        }
    ];
    const newSelectMaterialColums = () =>
        MaterialColumns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: ISaleDetailsList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave = (row: ISaleDetailsList) => {
        let { num, price, saleNum, sreturnNum } = row as { num: number, price: number, saleNum: number, sreturnNum: number }
        num = +formatIntPoint(num, saleNum - sreturnNum, "修改数量>=销售数量-已退数量");  //数量
        // price = +formatIntPoint(price);  //价格
        price = +formatNum(price);  //价格
        let newRow = {
            ...row,
            num,
            price,
            //amount: +formatNum(num * price),
            amount:+bigNumber.toFixed(bigNumber.times(num,price),2)
        }

        let newData = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailMaterialEditList([...newData]) : setDetailMaterialAddList([...newData])
        setTopButsControl(true)
    }
    const typeMaterialList = {
        "add": [...detailMaterialAddList],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    //设置维修用料选中的行和数据
    const setTypeMaterialDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeMaterialDetailDataRow = async (selectedRows: ISaleDetailsList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectMaterialColums() as ColumnsType<ISaleDetailsList>}
                dataSource={typeMaterialList[mode] as ISaleDetailsList[]}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailMaterialAddDataRowKeys : detailMaterialEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleDetailsList[]) => {
                            setTypeMaterialDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        }
                    }}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }}
            />
        </YhBox>
    )
}