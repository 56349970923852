import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useTransferDataMonitorModel } from "../transferDataMonitorModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/ScmResourceAuthEnum";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";

export const DataBox = () => {
    const { exportTransferDataMonitor, descTotal } = useTransferDataMonitorModel();

    const action = <>
        {/* <YhAuth resCode={ResourceAuthEnum.STT_SPS_EX}> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportTransferDataMonitor()}
        >导出</Button>
        {/* </YhAuth> */}
    </>;
    const desc: IDescription[] = [

        {
            label: "数量差异",
            value: descTotal.num,
            color: descTotal.num == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.num != 0
        },
        {
            label: "金额差异",
            value: descTotal.amount,
            color: descTotal.amount == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.amount != 0
        },
        {
            label: "成本差异",
            value: descTotal.costAmount,
            color: descTotal.costAmount == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.costAmount != 0
        },
        {
            label: "毛利差异",
            value: descTotal.profit,
            color: descTotal.profit == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.profit != 0
        },

    ]
    return (
        <YhBox title={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}