import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface ISCMInventorySituationDetail {
    "id": string,
    "shopId": string,                // "门店id",
    "shopName": string,                // "门店名称",
    "stocktakingCode": string,                // "盘库单好",
    "materialCode": string,                // "商品编码",
    "materialName": string,                // "商品名称",
    "replaceCode": string,                // "替换号",
    "warehouseCode": string,                // "仓库编码",
    "warehouseName": string,                // "潍柴库",
    "shelfCode": string,                // "货位",
    "status": string,                // "状态",
    "inventoryNum": string,                // “库存”,
    "realNum": string,                // 实物盘点,
    "result": string,                // "盘亏",
    "differenceNum": string,                // -差异量,
    "differenceAmount": string,                // 差异金额,
    "scrapNum": string,                // 报废量0,
    "scrapAmount": string,                // 报废金额.00,
    "differenceReason": string,                // 差异原因
    "scrapReason": string,                // 报废原因
    "avgPrice": string,                // 平均价,
    "costAmount": string,                // 成本,
    "profit": string,                // 毛利,
    "note": string,                // "备注",
    "finishTime": string,                // 完成时间
}

//搜索条件
export interface ISCMInventorySituationSearchFields {
    "createEndTime"?: string;
    "scrapNum"?: string,      // "报废量  传0",
    "differenceNum"?: string,      // "差异量 传0",
    "scrapReason"?: string,      // "报废原因",
    "differenceReason"?: string,      // "差异原因",
    "createStartTime"?: string,      // "创建开始时间",
    "purchaseCode"?: string,      // "创建结束时间",
    "supplierName"?: string,      // "供应商",
    "startTime"?: string,      // "开始时间",
    "endTime"?: string,      // "结束时间",
    "materialName"?: string,      // "商品名称",
    "materialCode"?: string,      // "商品编码",
    "warehouseCode"?: string,      // "仓库编码",
    "code"?: string,      // "盘库单编码",
    "shopId"?: string,      // "门店ID"
}

export interface ISCMInventorySituationSearch
    extends ISCMInventorySituationSearchFields, ISearchPage {
}


//进销存报表
class InventorySituationService {
    async page(searchVo: ISCMInventorySituationSearch) {
        const apiUrl = "/bcm/statistics/stocktakingStatistics";
        return await httpRequest.post<IPage<ISCMInventorySituationDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: ISCMInventorySituationSearch) {
        const apiUrl = "/bcm/statistics/stocktakingStatisticsExport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
}

export const inventorySituationService = new InventorySituationService();