import React, { useEffect, FC, useState } from "react";
import { ITopType } from "@/types/ScmTypes";
import { usePreWithdrawalDetailModel } from "./preWithdrawalDetailModel"
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import useNavModel, { } from "@/model/navModel";
import { DetailType } from "@/types/ScmEnums";
import { useDictModel, ITreeSelectData } from "@/views/base/dict/dictModel";
import { globalPrompt } from "@/components/message";
import { formatNum } from "@/utils/utils"
import { Button, message, Input } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import { debounce } from "lodash";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDetailModel, ActionTypeEnum } from "../../detailModel";
import { usePreWithdrawalModel } from "../preWithdrawalModel"
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";

export const FormBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        updateAddFormVal,
        updateEditFormVal,
        insertDetail,
        resetAddCatch,
        resetEditCatch,
        getDetailEditOne,
        setTopButsControl,
        formComputeRule,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialEditList, setDetailMaterialEditList,
    } = usePreWithdrawalDetailModel();
    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow,
    } = usePreWithdrawalModel();
    const { getTreeNodeName } = useDictModel();
    const { customerParams } = useDetailModel()

    const { setActionType } = useDetailModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { CustomerData, beShopDs, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel();
    const materialList=mode === "edit" ?  detailMaterialEditList : detailMaterialAddList
    const setMaterialList=mode === "edit" ?  setDetailMaterialEditList : setDetailMaterialAddList
    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])

    useEffect(() => {
        return () => {
            !findNav("16-5-1") && resetAddCatch(mode);
            !findNav("16-5-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])
    const { getSelectedDictOne, getTreeSelectOne } = useDictModel();
    //当列表跳转来的时候 对应客户信息为空 反之带预售销售详情的数据
    let newCustomerParams = parentInfo?.source ? {} : customerParams
    const typeForm = {
        add: { ...newCustomerParams, ...detailAddForm, shopId, shopCode, shopName, },
        edit: detailEditForm,
        look: detailLookForm
    }
    const save = () => {
        //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const retData = await insertDetail(res)
                if (retData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    setSelectedRowKeys([retData.id as string])
                    setDescRows([retData])
                    setSelectedRow(retData)
                    resetAddCatch(mode)
                    goBack()
                }
            }
        })

    }
    const goBack = () => {
        if (parentInfo && parentInfo.selectedNavId) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "16-1-2");
            setActionType(ActionTypeEnum.PREWITHDRAWAL)
        }
    }
    const onRefresh = () => {
        if (mode === "add") {
            IFormRef.current?.resetFields()
            resetAddCatch()
        } else if (mode === "edit") {
            IFormRef.current?.resetFields()
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const rightChildren = <>
        {mode !== "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { save() }, 900)}
            >保存</Button>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    function clearGoodsList() {
        if (materialList.length > 0) globalPrompt('message', { text: '切换成功!需要重新选择商品信息', type: 'warning' })
        setMaterialList([])
}
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {
            let { name, code, contact, phone, address, categoryCode, categoryName } = getFindItem(value, CustomerData)
            option && onChange({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address: address,
                customerTypeCode: categoryCode,
                customerTypeName: categoryName,
            });
            clearGoodsList()
        }
    }
    //客户类型
    const customerTypeChange = (value: string, option: ITreeSelectData) => {
        option && onChange({
            customerTypeName: option.title,
            customerTypeCode: option.value,
        });
    }
    //商品类型
    const productTypChange = (value: string, option: ISelectOption) => {
        option && onChange({
            productTypeName: option.label,
            productTypeCode: option.value,
        });
    }

    const orderStatus: IDescription[] = [
        {
            label: "预退单号",
            value: typeForm[mode].returnCode ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeForm[mode]?.status && getTreeNodeName(typeForm[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]
    const baseFormItem: IYhFormItemsBox = {
        title: <>基本信息&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mode !== "add" && orderStatus.map((item, index) => <Description key={index} {...item} />)}</>,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                options: transOptions(CustomerData.filter((item: any) => item.categoryCode === "customer-type.transit")),
                onSelectChange: customerChange,
                allowClear: true,
                showSearch: true,
                disable: !!!parentInfo?.source,
                span: 12,
                labelCol: { span: 3 },
                rules: [{ required: true, message: "请选择客户" }]
            }, {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "客户类型",
                fieldName: "customerTypeCode",
                treeData: getTreeSelectOne(DictCodeEnmu.CUSTOMER_TYPE),
                onTreeSelectChange: customerTypeChange,
                rules: [{ required: true, message: "请输入客户类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "address",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
                // }, {
                //     type: FormItemTypeEnum.Select,
                //     fieldLabel: "预售类型",
                //     fieldName: "preSaleType",
                //     options: getSelectedDictOne(DictCodeEnmu.PRESALE_TYPE),
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "商品类型",
                fieldName: "productTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
                onSelectChange: productTypChange,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "createUser",
                disable: true,

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                disable: true,

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "商品类型Name",
                fieldName: "productTypeName",
                hidden: true
            }
        ]
    }
    const onChange = (changedValues: { [key: string]: any }) => {
        mode === "add" ? updateAddFormVal(changedValues) : updateEditFormVal(changedValues)
        setTopButsControl(true)
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === "look",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }
    return (
        <YhFormBox {...poFromConfig} />

    )
}