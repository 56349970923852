import React, { useRef, useState, useEffect } from "react";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { useAccountModel } from "./accountModel";
import { message } from "antd";
import { accountService, creditFlagDs } from "../../../../AccountService";
import { useDetailModel } from "../detailModel";
import { UserTypeEnum, AccountTypeEnum } from "@/types/FmsEnums";
import { useGlobalModel } from "@/model/globalModel";
import { ISelectOptions } from "@/types/ScmTypes";
import { useOrgModel } from "@/views/organization/org/orgModel";
import { forFind } from "@/utils/utils";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export const OpenAccountDrawer = () => {
    const {
        openDrawerVisible,
        setOpenDrawerVisible,
        onReset,
    } = useAccountModel(model => [model.openDrawerVisible]);
    const { treeSource, getOrgTreeSelect } = useOrgModel();
    const {
        customer,
        accountTypeDs,
    } = useDetailModel(model => [
        model.customer,
        model.accountTypeDs
    ]);
    const { shopAndOrg: { shopCode, shopName }, beShopDs, appDs } = useGlobalModel();
    const [accountTypeData, setAccountTypeData] = useState<ISelectOptions[]>([]);
    const formRef = useRef<IYhFormRef>();

    //账户更改
    const creditChange = (value: any) => {
        formRef.current?.setFieldsValue({ accountTypeCode: undefined })
    }
    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        disabled: false,
        formValues: { partnerName: customer?.name },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "开户客户",
                fieldName: "partnerName",
                disable: true,
                span: 24,
                rules: [{
                    required: true,
                    message: "系统(分支)不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "系统(分支)",
                fieldName: "branch",
                disable: false,
                span: 24,
                options: appDs,
                rules: [{
                    required: true,
                    message: "系统(分支)不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "关联机构",
                fieldName: "orgCode",
                span: 24,
                treeData: getOrgTreeSelect(DictCodeEnmu.ORG),
                rules: [{
                    required: true,
                    message: "关联机构不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "从属门店",
                fieldName: "shopCode",
                span: 24,
                options: [{ label: shopName, value: shopCode }],
                rules: [{
                    required: true,
                    message: "从属门店不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "关联实体",
                fieldName: "beCode",
                span: 24,
                options: beShopDs,
                rules: [{
                    required: true,
                    message: "关联机构不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户名称",
                fieldName: "name",
                span: 24,
                rules: [{
                    required: true,
                    message: "账户名称不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "授信类型",
                fieldName: "creditFlag",
                span: 24,
                rules: [{
                    required: true,
                    message: "授信类型不能为空"
                }],
                onSelectChange: creditChange,
                options: creditFlagDs(),
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "账户类型",
                fieldName: "accountTypeCode",
                span: 24,
                options: accountTypeData,
                onSelectFocus: () => {
                    let fromVal = formRef.current?.getFieldsValue() ?? {};
                    if (fromVal.creditFlag === undefined) return setAccountTypeData([]);
                    let newVal = fromVal.creditFlag;  //记账1   现金 0
                    setAccountTypeData(() => accountTypeDs.filter(item => newVal === 1 ? (item?.type === DictCodeEnmu.SETTLE_TYPE_CREDIT) : newVal === 0 ?(item?.type === DictCodeEnmu.SETTLE_TYPE_CASH):(item?.type === DictCodeEnmu.SETTLE_TYPE_BALANCE)));
                    //在选择授信账户为记账时， 只展示 授信及月结，  否则返回其它
                    // setAccountTypeData(accountTypeDs.filter(item => newVal ?
                    //     (item.value === AccountTypeEnum.CREDIT || item.value === AccountTypeEnum.CREDIT_MONTH || item.value === AccountTypeEnum.REBATE || item.value === AccountTypeEnum.REBATE_OIL) :
                    //     (item.value !== AccountTypeEnum.CREDIT && item.value !== AccountTypeEnum.CREDIT_MONTH && item.value !== AccountTypeEnum.REBATE && item.value !== AccountTypeEnum.REBATE_OIL)))
                },
                rules: [{
                    required: true,
                    message: "账户类型不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            },
        ]
    };
    const onOk = async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const reqData = {
                    ...formData,
                    partnerTypeCode: UserTypeEnum.CUSTOMER,
                    partnerCode: customer.code,
                    accountTypeName: formData?.accountTypeCode ? accountTypeDs.find(item => item.value === formData?.accountTypeCode)?.label : "",
                    beName: formData?.beCode ? beShopDs?.find(item => item.value === formData?.beCode)?.label : "",
                    orgName: formData?.orgCode ? forFind(treeSource, formData?.orgCode, "code")?.name ?? "" : "",
                };
                let insertRet = await accountService.open(reqData);
                if (insertRet.success) {
                    message.success("开户成功!");
                    onReset();
                    setOpenDrawerVisible(false);
                } else {
                    message.error("开户失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log("数据校验失败");
        }
    }
    const onCancel = () => {
        setOpenDrawerVisible(false);
    }

    return (
        <YHDrawer
            {...{
                title: "开户",
                visible: openDrawerVisible,
                closable: true,
                onOk,
                onCancel,
                showFooter: true
            }}
        >
            <YhForm formRef={formRef} {...formConfig} />
        </YHDrawer>
    )
}
