import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useHistoryQueryModel } from "../historyQueryModel";
import { IHistoryQueryDetail } from "../historyQueryType";
import YhTag from "@/components/YhTag";
import { useDictModel } from "@/views/base/dict/dictModel";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";

type statusType = {
    "maintenance.return-visit": string, //已回访
    "maintenance.write-off": string, //已结款
    "maintenance.underway": string,//在修
    "maintenance.await": string,//待修
    "maintenance.trans": string,//已转接
    "maintenance.stock-io": string,//已出库
    "maintenance.settle": string,//已结算
    "maintenance.create": string,//已开单
    "maintenance.change": string,//已作废
    "maintenance.completed": string,

    [key: string]: any
}

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useHistoryQueryModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            {
                id: code, mode: "look", parentInfo: { selectedNavId }
            },
            true
        );
    }
    const statusColor: statusType = {
        "maintenance.return-visit": 'lime', //已回访
        "maintenance.write-off": "green", //已结款
        "maintenance.underway": "geekblue",//在修
        "maintenance.await": "purple",//待修
        "maintenance.trans": "cyan",//已转接
        "maintenance.stock-io": "magenta",//已出库
        "maintenance.settle": "orange",//已结算
        "maintenance.create": "blue",//已开单
        "maintenance.change": "red",//已作废
        "maintenance.completed": "purple"//已完工,


    }
    const columns: ColumnsType<IHistoryQueryDetail> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '工单单号',
            width: 120,
            dataIndex: 'maintenanceCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(text)}>{text}</p></Tooltip>,
        }, {
            title: '工单状态',
            width: 120,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '维修工',
            width: 120,
            dataIndex: 'technicianNames',
        }, {
            title: '工时统计',
            width: 160,
            dataIndex: 'duration',
        }, {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '送修人',
            width: 160,
            dataIndex: 'contact',
        }, {
            title: '送车电话',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '车牌号',
            width: 120,
            dataIndex: 'licensePlate',
        }, {
            title: 'VIN码',
            width: 160,
            dataIndex: 'vin',
        }, {
            title: '车辆类型',
            width: 160,
            dataIndex: 'vehicleTypeName',
        }, {
            title: '车辆型号',
            width: 160,
            dataIndex: 'vehicleModel',
        }, {
            title: '维修类型',
            width: 160,
            dataIndex: 'serviceNatureName',
        }, {
            title: '工时费',
            width: 160,
            dataIndex: 'workingHoursAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '配件费',
            width: 160,
            dataIndex: 'materialScienceAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '费用合计',
            width: 160,
            dataIndex: 'totalAmount',
            render:(text)=>`￥${text}`,
        }, {
            title: '结算类型',
            width: 160,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '结算方式',
            width: 160,
            dataIndex: 'paymentMethodName',
        }, {
            title: '结算方',
            width: 160,
            dataIndex: 'settlementPartyName',
        }, {
            title: '服务地点',
            width: 160,
            dataIndex: 'serviceLocationName',

        }, {
            title: '进厂时间',
            width: 160,
            dataIndex: 'enterTime',
            render: (text) => momentFormat(text)
        }, {
            title: '出厂时间',
            width: 160,
            dataIndex: 'deliveryTime',
            render: (text) => momentFormat(text)
        }, {
            title: '制单员',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '所属门店',
            width: 160,
            dataIndex: 'shopName',
        }
    ];


    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IHistoryQueryDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content',y:450 }}
        />
    )
}
