import React, { useEffect, useRef, useState } from "react";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from "@/components/YhFormBox/Types";
import { useSupplierModel } from "../supplierModel";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IVRMSupplierDetail } from "../supplierService";
import { getFormValidatorErrText } from "@/utils/utils"
import { DetailType } from "@/types/ScmEnums";
import { useDictModel } from "../../dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { debounce } from "lodash";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const BaseSupplierDetails = () => {
    const {
        addFormVal,
        editFormVal,
        lookFormVal,
        updateAddFormVal,
        updateEditFormVal,
        insertSupplier,
        editSupplier,
        getSupplierOne,
        setAddFormVal,
        setSelectedRowKeys,
    } = useSupplierModel();
    const { getTreeSelectOne } = useDictModel();

    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const formRef = useRef<IYhFormRef>();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { id, mode } = detailsInfo

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (!Object.keys(editFormVal).length && mode === DetailType.edit) {
            getSupplierOne(id, mode)
        }
        mode === DetailType.look && getSupplierOne(id, mode)
    }, [id, mode])

    //清楚缓存
    useEffect(() => {
        return () => {
            !findNav("13-1-1") && updateAddFormVal(null);
            !findNav("13-1-2") && updateEditFormVal(null);
        }
    }, [selectedNavId])

    //关闭当前页
    const onClose = () => {
        let { parentInfo } = detailsInfo
        if (parentInfo) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "13-1");
        }
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            let data = {
                ...res,
            }
            if (detailsInfo.mode === "edit") {
                editSupplier({ ...data, id: detailsInfo.id } as IVRMSupplierDetail).then(res => {
                    res && onClose()
                })
            } else {
                insertSupplier(data as IVRMSupplierDetail).then(res => {
                    setSelectedRowKeys([res as string])
                    res && onClose()
                })
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商编码",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入供应商名称" }]
            }, {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "供应商类型",
                fieldName: "typeCode",
                treeData: getTreeSelectOne(DictCodeEnmu.SUPPLIER_TYPE),
                rules: [{ required: true, message: "请输入供应商名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                // rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                // rules: [{ required: true, message: "请输入联系人电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人2",
                fieldName: "name2"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone2"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "办公电话",
                fieldName: "tel"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "电子邮箱",
                fieldName: "email"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "传真号码",
                fieldName: "fax"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店(暂定)",
                fieldName: "orgCode",
                disable: true
            },
            {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "状态",
                fieldName: "enable",
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系地址",
                fieldName: "address",
                span: 12,
                labelCol: { span: 3 }
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 12,
                labelCol: { span: 3 }
            }
        ]
    };
    const makeFormItem: IYhFormItemsBox = {
        title: "开票信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发票抬头",
                fieldName: "invoiceTitle",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "营业执照",
                fieldName: "licenseNo",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "开户银行",
                fieldName: "bank",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "银行账号",
                fieldName: "bankAcct",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "邮寄地址",
                fieldName: "postAddress",
                span: 12,
                labelCol: { span: 3 }
            }
        ]
    }
    const otherFormItem: IYhFormItemsBox = {
        title: "其它信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                fieldName: "createUser",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改人",
                fieldName: "updateUser",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改时间",
                fieldName: "updateTime",
                disable: true
            }
        ]
    }


    const onChange = (changedValues: { [key: string]: any }) => {
        // console.log(changedValues);
        detailsInfo.mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues);
    }
    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal
    }
    const typeTitle = {
        add: "新增",
        edit: "编辑",
        look: "查看",
    }
    const supplierFromConfig: IYhFormBox = {
        formRef,
        labelCol: { span: 6 },
        disabled: mode === DetailType.look,
        labelAlign: "right",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem, makeFormItem, otherFormItem]
    }
    const reset = () => {
        formRef.current?.resetFields()
        if (detailsInfo.mode === "add") {
            setAddFormVal({})
        } else if (detailsInfo.mode === "edit") {
            getSupplierOne(detailsInfo.id, mode)
        }
        message.success("重置成功")
    }
    const action = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.DATA_S_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { onSubmit() }, 900)}

                >保存</Button>
            </YhAuth>}
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => reset()}
        >重置</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onClose}
        >取消</Button>
    </>;

    return (
        <>
            {/* <YhPageHeader  /> */}
            <YhBox title={`供应商${typeTitle[mode]}`} action={action} style={{ paddingBottom: "0px", marginBottom: "15px" }}></YhBox>
            <YhFormBox {...supplierFromConfig} />
        </>
    )
}