import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useMonitoringModel } from "../monitoringModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/ScmResourceAuthEnum";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";

export const DataBox = () => {
    const { exportMonitoring, descTotal } = useMonitoringModel();

    const action = <>
        {/* <YhAuth resCode={ResourceAuthEnum.STT_SPS_EX}> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportMonitoring()}
        >导出</Button>
        {/* </YhAuth> */}
    </>;
    const desc: IDescription[] = [

        {
            label: "折前金额差异",
            value: descTotal.beforeAmountDiff,
            color: descTotal.beforeAmountDiff == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.beforeAmountDiff != 0
        },
        {
            label: "优惠金额差异",
            value: descTotal.discountAmountDiff,
            color: descTotal.discountAmountDiff == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.discountAmountDiff != 0
        },
        {
            label: "应收金额差异",
            value: descTotal.amountDiff ?? 0,
            color: descTotal.amountDiff == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.amountDiff != 0
        },
        {
            label: "成本金额差异",
            value: descTotal.costAmountDiff ?? 0,
            color: descTotal.costAmountDiff == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.costAmountDiff != 0
        },
        {
            label: "毛利金额差异",
            value: descTotal.profitDiff ?? 0,
            color: descTotal.profitDiff == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.profitDiff != 0
        },
        {
            label: "总数量差异",
            value: descTotal.sumNumDiff ?? 0,
            color: descTotal.sumNumDiff == 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
            animation: descTotal.sumNumDiff != 0
        },
    ]
    return (
        <YhBox title={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}