import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import { ITechnicianSearch, ITechnicianDetail, ITechnicianFields } from "./technicianType"

class TechnicianService {
    async page(searchVo: ITechnicianSearch) {
        const apiUrl = "/bcm/base/technician/findPage";
        return await httpRequest.post<IPage<ITechnicianDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/base/technician/findById/${id}`;
        return await httpRequest.get<ITechnicianDetail>(apiUrl);
    }
    async insert(insertVo: ITechnicianFields) {
        const apiUrl = "/bcm/base/technician/insert";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }
    async update(updateVo: ITechnicianFields) {
        const apiUrl = "/bcm/base/technician/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //启用禁用
    async disable(params:ITechnicianFields) {
        const apiUrl = `/bcm/base/technician/change`;
        return await httpRequest.post<boolean>(apiUrl,params);
    }
}
export const technicianService = new TechnicianService();