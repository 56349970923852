import { httpRequest } from "../../../utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {
    IStockCheckSearch,
    IStockCheckDetail,
    IStockCheckFields,
    Detailed,
    IDetailList,
} from "./stockCheckType"
// 盘库单
class StockCheckService {
    async page(searchVo: IStockCheckSearch) {
        const apiUrl = "/bcm/stock/check/findPage";
        return await httpRequest.post<IPage<IStockCheckDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/stock/check/findById/${id}`;
        return await httpRequest.get<IStockCheckDetail>(apiUrl);
    }

    async insert(insertVo: IStockCheckFields) {
        const apiUrl = "/bcm/stock/check/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    async update(updateVo: IStockCheckFields) {
        const apiUrl = "/bcm/stock/check/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //作废
    async invalidate(params: object) {
        const apiUrl = "/bcm/stock/check/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //盘点完成
    async complete(params: object) {
        const apiUrl = "/bcm/stock/check/complete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //盘点审核
    async finalAudit(params: object) {
        const apiUrl = "/bcm/stock/check/finalAudit";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导入盘库
    async warehousing() {
        const apiUrl = "/bcm/stock/check/import";
        return await httpRequest.post<boolean>(apiUrl);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/bcm/stock/check/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/bcm/stock/check/detail/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    //导出
    async export(params: IStockCheckSearch) {
        const apiUrl = "/bcm/stock/check/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //详情导出
    async exportDetialtockCheck(params: IStockCheckSearch) {
        const apiUrl = "bcm/stock/check/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
}

export const stockCheckService = new StockCheckService();