import { IPage, ISearchPage } from "@/types/ScmTypes";
import { httpRequest } from "@/utils/HttpRequest";


export interface IStatistics {
    differenceNumTotal: number,// -127
    occupyDifferenceTotal: number,  // -1194793
    currentNumTotal: number,  // -1194793
    [key: string]: number
}
export interface IPsibusSearchFields {
    [key: string]: any
}

export interface IPsbusiDetail {
    id: string, // string
    beginNum?: string,   // 2
    bookOccupyNum?: string,   // null
    busSaleNum?: string,   // null
    busSreturnNum?: string,   // null
    compensateNum?: string,   // null
    currentNum?: string,   // 2
    differenceNum?: string,   // 0
    maintainNum?: string,   // null
    maintainOccupyNum?: string,   // null
    materialCode?: string,   // "612600060133"
    materialName?: string,   // "外置节温器"
    mreturnNum?: string,   // null
    occupyDifference?: string,   // 0
    occupyNum?: string,   // 0
    preturnNum?: string,   // 0
    preturnOccupyNum?: string,   // 0
    purchasePrice?: string,   // 85
    purchasePriceLatest?: string,   // 85
    purhcaseNum?: string,   // 2
    remainNum?: string,   // 2
    saleNum?: string,   // 0
    saleOccupyNum?: string,   // 0
    shopId?: string,   // "64eec0c1d6f37078b6b460e4cb0ac9d7"
    sreturnNum?: string,   // 0
    stockCost?: string,   // 170
    stockCostLatest?: string,   // 170
    unusualSreturnNum?: string,   // 0
    [key: string]: any
}

export interface IPsibusSearch
    extends IPsibusSearchFields, ISearchPage {

}

 class PsiBusService {
    async page(searchVo: IPsibusSearch) {
        const apiUrl = "/bcm/statistics/bmsPurchaseSaleStorage";
        return await httpRequest.post<IPage<IPsbusiDetail>>(apiUrl, searchVo);
    }
    // 头部统计
    async statistics(searchVo: IPsibusSearch) {
        const apiUrl = "/bcm/statistics/bmsPurchaseSaleStorageTotal";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }
    //导出
    async export(searchVo: IPsibusSearch) {
        const apiUrl = "/bcm/statistics/bmsPurchaseSaleStorageExport";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
}

export const psiBusService = new PsiBusService();