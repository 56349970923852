import React from "react";
import { useEntityModel } from "../entityModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "实体编码",
        fieldName: "code"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "实体名称",
        fieldName: "name"
    },
    {
        type: SearchTypeEnum.Select,
        placeholder: "实体状态",
        fieldName: "enable",
        options: [{ label: "可用", value: 1 }, { label: "不可用", value: 0 }]
    },
]

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useEntityModel();

    return (
        <YhPageHeader
            title="经营实体管理"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => loadData(initPgCfg, { ...searchParam, ...params })}
            handleReset={resetSearchParam}
        />
    );
}