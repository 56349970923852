import React, { FC } from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { DeliveryList } from "./DeliveryList"
import { MaterialList } from "./MaterialList"
import { WarehousingList } from "./WarehousingList"
import { useDeliveryDetailsModel, DeliveryDetailsTabsEnum } from "./deliveryDetailsModel"
import { RouterParamType } from "@/model/navModel";

export const DeliveryDetailsTabs: FC<RouterParamType> = ({ id, mode }) => {
    const { tabsFlag, setTabFlag } = useDeliveryDetailsModel()
    const action = <>
        <Button
            className={`mr-5 ${tabsFlag === DeliveryDetailsTabsEnum.Material ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => { setTabFlag(DeliveryDetailsTabsEnum.Material) }}
        >配件信息</Button>
        <Button
            className={`mr-5 ${tabsFlag === DeliveryDetailsTabsEnum.DeliveryRecord ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => { setTabFlag(DeliveryDetailsTabsEnum.DeliveryRecord) }}
        >出库记录</Button>
        <Button
            className={`mr-5 ${tabsFlag === DeliveryDetailsTabsEnum.WarehousingRecord ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => { setTabFlag(DeliveryDetailsTabsEnum.WarehousingRecord) }}
        >入库记录</Button>
    </>
    const DeliveryDetailsTabsItem = (str: string) => {
        switch (str) {
            case DeliveryDetailsTabsEnum.Material:
                return <MaterialList id={id} mode={mode} />
            case DeliveryDetailsTabsEnum.DeliveryRecord:
                return <DeliveryList id={id} mode={mode}/>
            case DeliveryDetailsTabsEnum.WarehousingRecord:
                return <WarehousingList id={id} mode={mode}/>
        }
    }
    return (
        <>
            <YhBox action={action} style={{ padding: "20px 0 0", marginBottom: "15px" }} />
            {DeliveryDetailsTabsItem(tabsFlag)}
        </>
    )
}