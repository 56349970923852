import { IPage } from '@/types/ScmTypes';
import { httpRequest } from "@/utils/HttpRequest";
import { IStockStatisticList } from '@/views/stock/statistic/stockStatisticService';
import { ISaleOrderSearch, ISaleOrderDetail, ISaleOrderFields, ISaleOrderList, Detailed, IInvalidate, IPurchaseStockSearch, IPurchaseStock } from "./saleOrderTypes"
class SaleOrderService {
    async page(searchVo: ISaleOrderSearch) {
        const apiUrl = "/bcm/sale/order/findPage";
        return await httpRequest.post<ISaleOrderList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/sale/order/findById/${id}`;
        return await httpRequest.get<ISaleOrderDetail>(apiUrl);
    }

    async insert(insertVo: ISaleOrderFields) {
        const apiUrl = "/bcm/sale/order/insert";
        return await httpRequest.post<ISaleOrderDetail>(apiUrl, insertVo);
    }
    async update(updateVo: ISaleOrderFields) {
        const apiUrl = "/bcm/sale/order/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/bcm/sale/order/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: ISaleOrderFields) {
        const apiUrl = "/bcm/sale/order/export";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //转销售单
    async transfer(planCode: string) {
        const apiUrl = `/bcm/sale/order/transfer/${planCode}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
    // //详情导入
    // async invalidate(){
    //     const apiUrl = "/bcm/sale/order/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/bcm/sale/order/detail/downloadTpl";
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //作废
    async invalidate(params: IInvalidate) {
        const apiUrl = "/bcm/sale/order/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: ISaleOrderFields) {
        const apiUrl = "/bcm/sale/order/detailsExport";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }

       // 库存采购明细
       async stock(params:IPurchaseStockSearch){
        const apiUrl = '/bcm/purchase/stock'
        return await httpRequest.post<IPage<IPurchaseStock>>(apiUrl, params, { timeout: 300000 })
    }
        // 库存采购明细
    async newStock(params:IPurchaseStockSearch){
        const apiUrl = 'bcm/stock/findPage'
        return await httpRequest.post<IStockStatisticList[]>(apiUrl, params)
    }
}

export const saleOrderService = new SaleOrderService();