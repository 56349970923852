import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { useSaleDcDetailsModel } from "./details/saleAllotDetailsModel";
import { useSaleDcModel } from "../allotModel";

export const DataBox = () => {
    const { selectedRowKeys, statistics, exportSaleDc, exportStatisticsExport } = useSaleDcModel();
    const { resetEditCatch } = useSaleDcDetailsModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel(() => []);

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let SaleDcEditKey = "5-4-2";
        let isFindNav = findNav(SaleDcEditKey);
        if (!isFindNav) {
            addNav(SaleDcEditKey, { id: selectedRowKeys[0] as string, mode: "edit" });
            resetEditCatch()
        } else {
            globalPrompt("modal", {
                title: "调出单跳转详情",
                type: "warning",
                text: "您还有调出单单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(SaleDcEditKey, { id: selectedRowKeys[0] as string, mode: "edit" });
                    resetEditCatch()
                }
            })
        }
    }

    const desc: IDescription[] = [
        {
            label: "调出数量",
            value: statistics?.totalNum,
            color: DescriptionColorEnum.green
        },
        {
            label: "应收金额",
            value: `￥${statistics.totalAmount}`,
            color: DescriptionColorEnum.red
        }
    ]
    const printMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => exportSaleDc()}>
                列表导出
            </Menu.Item>
            <Menu.Item key="0" onClick={() => exportStatisticsExport()}>
                明细导出
            </Menu.Item>
        </Menu>
    );
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.S_DC_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("5-4-1", { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_DC_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_DC_EX}>
            {/* <Dropdown overlay={printMenu} trigger={['hover']}> */}
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => exportSaleDc()}
                >导出</Button>
            {/* </Dropdown> */}
        </YhAuth>
    </>;

    return (
        <YhBox title="调出单列表" descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}