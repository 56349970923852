import React, { useEffect } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useFollowUpModel } from "../followUpModel";
import { usefollowUpDetailsModel } from "./details/followUpDetailsModels";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, selectedRow, settleExport, setSelectedRowKeys } = useFollowUpModel();
    const { selectedNavId, replaceNav, addNav, findNav, selectNav, switchNavState } = useNavModel();
    const { resetEditCatch } = usefollowUpDetailsModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let saleOrderEditKey = "8-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "客户回访跳转详情",
                type: "warning",
                text: "您还有客户回访详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "8-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.maintenanceCode as string, mode: "look", },
            true
        );
    }

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.R_VISIT_V}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >回访</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.R_VISIT_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.R_VISIT_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => settleExport().then()}
            >导出</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="客户回访" action={action}>
            <DataTable />
        </YhBox>
    )
}