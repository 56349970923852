import React, { useState, useEffect, FC } from "react";
import { Table, Button, Input, DatePicker } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { useInternalDetailsModel } from "./internalDetailsModel";
import { ColumnsType } from "antd/lib/table";
import { IFmsAllotDetailFileds } from "../../internalTypes";
import { RouterParamType } from "@/model/navModel";
import useGlobalModel from "@/model/globalModel";

export const AllotDetailModel: FC<RouterParamType> = ({ id, mode }) => {
    const { user: { shopCode } } = useGlobalModel();
    const { allDetailVisible: visible,
        addGoodsList, editGoodsList,
        editAllotRow, addAllotRow,
        editAllotRowKeys, addAllotRowKeys,
        allotDetailList,
        setAllDetailVisible, allotDetailLoadData } = useInternalDetailsModel()
    const onCancel = () => {
        setAllDetailVisible(false)
    }
    useEffect(() => {
        if (visible && mode == "add") {
            allotDetailLoadData({ transferCodes: addAllotRowKeys.length != 0 ? addAllotRow.map(item => item.code) : addGoodsList.map(item => item.code) })
        } else if (visible && mode == "edit") {
            allotDetailLoadData({ transferCodes: editAllotRowKeys.length != 0 ? editAllotRow.map(item => item.code) : editGoodsList.map(item => item.code) })
        }
    }, [visible])

    const columns: ColumnsType<IFmsAllotDetailFileds> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '调拨单号',
            width: 150,
            dataIndex: 'transferCode',
            fixed: "left",
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'shopCode',
            fixed: "left",
            render: text => text === shopCode ? "调入" : "调出"
        }, {
            title: '商品编码',
            width: 100,
            dataIndex: 'materialCode',
        }, {
            title: '商品名称',
            width: 130,
            dataIndex: 'materialName',
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
        }, {
            title: '数量',
            width: 136,
            dataIndex: 'num',
        }, {
            title: '金额',
            width: 100,
            dataIndex: 'amount',
        }
    ];
    const lookAllDetail = () => {
        if (mode == "add") {
            allotDetailLoadData({ transferCodes: addGoodsList.map(item => item.code) })
        } else if (mode == "edit") {
            allotDetailLoadData({ transferCodes: editGoodsList.map(item => item.code) })
        }
    }
    const title = <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={() => {
            lookAllDetail()
        }}
    >查看所有明细</Button>
    return <YHModal
        visible={visible}
        onOk={onCancel}
        onCancel={onCancel}
        title="对账明细"
        width={1000}
        bodyHeight={500}
        showFooter={true}
    >
        <YhBox title={title}>
            <Table
                columns={columns}
                dataSource={allotDetailList}
                pagination={false}
            />
        </YhBox>
    </YHModal>
}