import React from "react";
import { useStockInAndOutModel } from "../stockInAndOutModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";


export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        ioFlag,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useStockInAndOutModel();
    const { getSelectedDictOne } = useDictModel(() => []);

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "单据编号",
            fieldName: "tradeCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "配件编码",
            fieldName: "materialCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "配件名称",
            fieldName: "materialName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "所属仓库",
            fieldName: "warehouseName"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: ioFlag ? "入库类型" : "出库类型",
            fieldName: "tradeTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).filter(item => Number(item.relationvalue) == ioFlag)
        },
    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ioFlag ? ["入库开始", "入库结束"] : ["出库开始", "出库结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
    ]
    return (
        <YhPageHeader
            title="出入库记录明细"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params, ioFlag }) }}
            handleReset={resetSearchParam}
        />
    );
}