import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { PaginationProps } from "antd/es/pagination";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { IMaterialDetail, IMaterialSearchFields, materialService, IFormGoodsFields, IWarehouseMaterialDetal } from "./materialService";
import { globalPrompt } from "@/components/message";
import { convertRes2Blob } from "@/utils/exportFile";
import useGlobalModel, { getFindItem } from "@/model/globalModel";
import { useDictModel } from "../dict/dictModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { IVRMshelfDetail } from "../warehouse/warehouseTypes";
import { useLockFn } from "ahooks";


export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

type ISCMFormVal = {
    photo?: string[]
    [key: string]: any
}

export const useGoodsModel = createModel(function () {
    const { shopAndOrg, user, warehouseList } = useGlobalModel()
    const { shopCode, shopId, } = shopAndOrg
    const initWarehouseDetalList = {
        purchasePrice: 0,// 0,
        repairPrice: 0,// 0,
        shelfCode: undefined,// "",
        shelfId: undefined,// "",
        warehouseCode: undefined,// "",
        warehouseId: undefined,// "",
        warehouseName: undefined,// ""
    }
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const { getTreeNodeName } = useDictModel();

    /**
     * 列表
     */
    //列表数据源
    const [dataSource, setDataSource] = useState<IMaterialDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IMaterialSearchFields>({});
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initForm: ISCMFormVal = { shopName: shopAndOrg.shopName, enable: 1 }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //实时货位数据
    const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([])
    //多仓库货位列表
    const [warehouseDetalList, setWarehouseDetalList] = useState<IWarehouseMaterialDetal[]>([initWarehouseDetalList])
    //编辑
    const [editWarehouseDetalList, setEditWarehouseDetalList] = useState<IWarehouseMaterialDetal[]>([])
    //查看
    const [lookWarehouseDetalList, setLookWarehouseDetalList] = useState<IWarehouseMaterialDetal[]>([])

    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IMaterialDetail>();

    //列表查询
    const loadData = async (page?: ISearchPage, searchValue: IMaterialSearchFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await materialService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    //设置搜索value
    const updateSearchParam = (updateParam: IMaterialSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    /**
     * 表单详情
     */

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<ISCMFormVal>({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState<ISCMFormVal>(initForm);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState<ISCMFormVal>({});
    //表单新增修改
    const updateAddFormVal = (val: object | null) => {
        val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal({});
    }
    //表单新增修改
    const updateEditFormVal = (val: object | null) => {
        val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({});
    }
    //获取一条详情数据 Api
    const getGoodsOne = async (id: string, pattern: string) => {
        
        let { retData } = await materialService.one(id);
        console.log(retData)
        let { materialDetal, ...form } = retData
        console.log('form',form)
        let newmaterialDetal: any[] = [form]
        if (retData && pattern === "edit") {
            setEditFormVal(form);
            setEditWarehouseDetalList(newmaterialDetal)
        } else if (retData && pattern === "look") {
            setLookFormVal(form);
            setLookWarehouseDetalList(newmaterialDetal)
        }
    }

    //表单新增 Api
    const insertGoods = useLockFn(async (data: IFormGoodsFields) => {
        let params = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            createUser: user.username,
            typeName: getTreeNodeName(data.typeCode as string),
            brandName: getTreeNodeName(data.brandCode as string),
            unitName: getTreeNodeName(data.unitCode as string),
            warehouseId: getFindItem(data.warehouseCode as string, warehouseList)?.id,
            warehouseName: getFindItem(data.warehouseCode as string, warehouseList)?.name,
            shelfId: getFindItem(data.shelfCode as string, shelfAllList)?.id,
            enable: data.enable ? 1 : 0,
        }
        let { retData } = await materialService.insert({ ...params });
        retData ?
            globalPrompt("message", { text: "商品新增成功", type: "success" })
            : globalPrompt("message", { text: "商品新增失败", type: "error" });
        return retData;
    })

    //表单编辑 Api
    const editGoods = useLockFn(async (data: IFormGoodsFields) => {
        let params = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            updateUser: user.username,
            typeName: getTreeNodeName(data.typeCode as string),
            brandName: getTreeNodeName(data.brandCode as string),
            unitName: getTreeNodeName(data.unitCode as string),
            warehouseId: getFindItem(data.warehouseCode as string, warehouseList)?.id,
            warehouseName: getFindItem(data.warehouseCode as string, warehouseList)?.name,
            shelfId: getFindItem(data.shelfCode as string, shelfAllList)?.id,
            enable: data.enable ? 1 : 0,
        }
        let { retData } = await materialService.update({ ...params });
        retData ?
            globalPrompt("message", { text: "商品编辑成功", type: "success" })
            : globalPrompt("message", { text: "商品编辑失败", type: "error" });
        return retData;
    })
    const onDisabled = async (id: string, checked: boolean) => {
        let { retData } = await materialService.disable({ id, enable: checked ? 1 : 0 });
        if (retData) {
            loadData()
            globalPrompt("message", { text: "商品编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "商品编辑失败", type: "error" });
        }
        return retData;
    }
    //导入
    const importGoods = async (file: { file: File }) => {
        let { retData } = await materialService.import(file);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "导入成功", type: "success" })
        } else {
            globalPrompt("message", { text: "导入失败", type: "error" });
        }
        return retData;
    }

    //导入物料图片
    const importImgGoods = async (file: { file: File }) => {
        let { retData } = await materialService.import(file);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "导入图片成功", type: "success" })
        } else {
            globalPrompt("message", { text: "导入图片失败", type: "error" });
        }
        return retData;
    }

    //导出
    const exportGoods = async (searchValue: IMaterialSearchFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await materialService.export(searchParams);
        // retData ?
        //     globalPrompt("message", { text: "导出成功", type: "success" })
        //     : globalPrompt("message", { text: "导出失败", type: "error" });
        convertRes2Blob(result)
        return result;
    }
    //下载模板
    const downloadTpl = async () => {
        let result = await materialService.downloadTpl();
        convertRes2Blob(result)
        return result;
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        editFormVal,
        addFormVal,
        lookFormVal,
        initPgCfg,
        initForm,
        /***method***/
        updateAddFormVal,
        updateEditFormVal,
        insertGoods,
        editGoods,
        getGoodsOne,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        importGoods,
        exportGoods,
        downloadTpl,
        importImgGoods,
        setAddFormVal,
        setEditFormVal,
        onDisabled,
        setLookFormVal,

        shelfAllList, setShelfAllList,
        warehouseDetalList, setWarehouseDetalList,
        initWarehouseDetalList,
        editWarehouseDetalList, setEditWarehouseDetalList,
        lookWarehouseDetalList, setLookWarehouseDetalList,
        setHistoryRow,setHistoryVisible
    }
});