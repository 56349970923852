export enum MdEnableStatusEnum {
    DISABLE,
    ENABLE
}

export enum UserTypeEnum {
    EMPLOYEE = "user-type.employee", // "内部员工"
    MICRO_BOSS = "user-type.micro-boss", // "微老板"
    DRIVER = "user-type.driver", //"司机"
    HEADMAN = "user-type.headman", //"队长"
    BOSS = "user-type.boss", // "老板"
    SUPPLIER = "user-type.supplier", //"供应商"
    CUSTOMER = "user-type.customer", //"客户"
}

export enum TradeTypeEnum {
    SALE = "trade-type.sale", //销售,
    PURCHASE = "trade-type.purchase", //采购
    SALE_RETURN = "trade-type.sale-return", //销售退货
    PURCHASE_RETURN = "trade-type.purchase-return", //采采购退货
    PURCHASE_PLAN = "trade-type.purchase-plan", //采购计划
    SALE_ORDER = "trade-type.sale-order", //销售订单
    APPOINTMENT = "trade-type.appointment", //预约
    MAINTENANCE = "trade-type.maintenance", //维修
    DISPATCH = "trade-type.dispatch", //派工
    SETTLE = "trade-type.settle", //结算
    RETURN_VISIT = "trade-type.return-visit", //回访
    STOCKTAKING = "trade-type.stocktaking", //盘库
    LOSS = "trade-type.loss", //损失
    BILL_DISCOUNT = "trade-type.bill-discount", //账单抹零
}

export enum ReviewStatusEnum {
    INIT,
    REVIEWING,
    REVIEWED,
    REJECTED = 9,
}

export enum AccountStatusEnum {
    INIT,
    DISABLED = 9
}

export enum CreditFlagEnum {
    NON_CREDIT,
    CREDIT
}

export enum AdjustStatusEnum {
    INIT,
    REVIEWING,
    ADJUSTED,
    REJECTED = 8,
    FAIL,
}

export enum RechargeStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9,
}

export enum TransferStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9
}

export enum AccountLogTypeEnum {
    OPEN = "OPEN",
    DISABLE = "DISABLE",
    ENABLE = "ENABLE",
    ADJUST = "ADJUST",
    ADJUST_APPROVAL = "ADJUST_APPROVAL",
    RECHARGE = "RECHARGE",
    TRANSFER_IN = "TRANSFER_IN",
    TRANSFER_OUT = "TRANSFER_OUT",
    BALANCE = "BALANCE",
    UPDATE = "UPDATE",
}

export enum AccountTypeEnum {
    CASH = "account-type.cash",
    DOWN_PAYMENT = "account-type.down-payment",
    ADVANCE = "account-type.advance",
    COUPON = "account-type.coupon",
    PRESTORE = "account-type.prestore",
    REBATE = "account-type.rebate",
    REBATE_OIL = "account-type.rebate-oil",
    SERVICE_CHARGE = "account-type.service-charge",
    CREDIT_MONTH = "account-type.credit-month",
    CREDIT = "account-type.credit",
}

export enum BillIoFlagEnum {
    IN = 1,
    OUT
}

export enum BillPayTypeEnum {
    CREDIT = "settle-type.credit", // "挂账"
    CASH = "settle-type.cash", //"现结"
}

export enum BillStatusEnum {
    INIT,
    BOOKED,
    BALANCED
}

export enum BillCashStatusEnum {
    INIT,
    CASHED,
    RECHARGED,
    BALANCED,
    FAIL = 9,
}

export enum BillCancelStatusEnum {
    INIT,
    REVIEWING,
    CANCELLED,
    FAIL,
}

export enum BillInvoiceStatusEnum {
    INIT,
    APPLYING,
    PARTIAL,
    INVOICED,
}

export enum BillBalanceStatusEnum {
    INIT,
    BALANCED,
    FAIL = 9,
}

export enum BillLogTypeEnum {
    INSERT = "INSERT",
    APPROVAL = "DISABLE",
    CREDIT_BOOK = "CREDIT_BOOK",
    CASH_BOOK = "CASH_BOOK",
    BALANCE = "BALANCE",
    INVOICE = "INVOICE",
    INVOICE_CANCEL = "INVOICE",
    INVOICE_APPROVAL = "INVOICE_APPROVAL",
    CANCEL = "CANCEL",
    CANCEL_APPROVAL = "CANCEL_APPROVAL",
}
