import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePurchasePlanModel } from "../purchasePlanModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { usePurchasePlanDetailsModel } from "./details/purchasePlanDetailsModel"
import useGlobalModel from "@/model/globalModel";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";


export const DataBox = () => {
    const { selectedRowKeys, selectedRow, descTotal, rowsDescTotal, exportPurchasePlan, setSelectedRowKeys } = usePurchasePlanModel();
    const { setDetailEditForm, setDetailDataSource } = usePurchasePlanDetailsModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const [visible, setVisible] = useState(false);
   
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "11-1-2";
        let isFindNav = findNav(supplierEditKey);
        if (!isFindNav) {
            addNav(supplierEditKey, { id: selectedRow.planCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "采购订单跳转详情",
                type: "warning",
                text: "您还有采购订单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(supplierEditKey, { id: selectedRow.planCode as string, mode: "edit" });
                    // updateEditFormVal(null);
                    setDetailEditForm({})
                    setDetailDataSource([])
                }
            })
        }
    }
    const desc: IDescription[] = [
        {
            label: "计划总量",
            value: selectedRowKeys.length === 0 ? descTotal.totalNum : (+formatNum(rowsDescTotal.totalNum)).toFixed(0),
            color: DescriptionColorEnum.red
        },
        {
            label: "计划总金额",
            value: selectedRowKeys.length === 0 ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            color: DescriptionColorEnum.red
        }
    ]
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "11-1-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.planCode as string, mode: "look", },
            true
        );

    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.P_PL_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("11-1-1", { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_PL_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_PL_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_PL_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { exportPurchasePlan().then() }}
            >导出</Button>
        </YhAuth>
    </>;

    return (
        <>
            <YhBox title={"采购订单列表"} descriptions={desc} action={action}>
                <DataTable />
            </YhBox>
        </>
    )
}