import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useNavModel from "../model/navModel";
import useGlobalModel from "../model/globalModel";
import { forFind } from "../utils/utils";
import menuData from "../data/menuData.json";
import { Layout, Spin } from "antd";
import VrmMenu from "./VrmMenu";
import VrmHeader from "./VrmHeader";
import VrmNav from "./VrmNav";
import VrmRoute from "./VrmRoute";
import { useDictModel } from "../views/base/dict/dictModel"
import { useCustomerModel } from "@/views/base/customerFiles/customer/baseCustomerModel";
import { useSupplierModel } from "@/views/base/supplier/supplierModel";
import { useWarehouseModel } from "@/views/base/warehouse/warehouseModel";
import { useOrgModel } from "@/views/organization/org/orgModel";
import { useTechnicianModel } from "@/views/base/technician/technicianModel";

const { Sider, Content } = Layout;

const Index = () => {

    const history = useHistory();
    const { user, shopAndOrg, cm, beLoadDataAll, appLoadDataAll, requestCatchAll, loadingInfo } = useGlobalModel();
    const { addNav } = useNavModel();
    const { loadData: loadDictData } = useDictModel()
    const { loadData: orgLoadData } = useOrgModel();
    useEffect(() => {
        // 这里加载初始全局用到的数据
        if (shopAndOrg.shopId) {
            requestCatchAll(shopAndOrg.shopId)
            loadDictData(shopAndOrg.shopCode);
            orgLoadData();
            // beLoadDataAll();
            // appLoadDataAll();
            // loadCustomerAllData(shopAndOrg);
            // loadSupplierAllData(shopAndOrg);
            // loadWarehouseAllData(shopAndOrg);
            // loadTechnician(shopAndOrg);
        }
    }, [shopAndOrg])
    //监听在地址栏输入url。
    useEffect(() => {
        history.listen((location, action) => {
            if (action === "POP" && location.pathname !== "/login" && user.token) {
                // console.log(location)
                let findNav = forFind(menuData, location.pathname, "path", true);
                // console.log(location, action, "监听路有变化", findNav)
                addNav(findNav ? findNav.id : "404");
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Spin spinning={loadingInfo.loadingVisible} tip={loadingInfo.txt ?? '加载中...'}>
            <Layout style={{ height: "100vh" }}>
                <VrmHeader />
                <Layout>
                    <Sider className="layout-sider" trigger={null} collapsible collapsed={cm.collapsed} width={226} collapsedWidth={80}>
                        <div className="layout-sider-shop">{shopAndOrg.shopName}</div>
                        <VrmMenu />
                    </Sider>
                    <Layout className="layout-section">
                        <VrmNav />
                        <Content>
                            <div className="layout-section-content">
                                <VrmRoute />
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </Spin>
    )
}

export default Index;