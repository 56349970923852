import React from 'react'
import { PageHeader } from './PageHeader'
import { DataBox } from './DataBox'

/**
 * 导出记录
 */
const ExportRecord = () => {
    return (
        <>
            <PageHeader />
            <DataBox />
        </>
    )
}

export default ExportRecord
