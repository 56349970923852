import React, { useEffect } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useStockOutputModel } from "../stockOutputModel";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, selectedRow, descTotal, rowsDescTotal, exportOutput,exportDetailsOutput } = useStockOutputModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const desc: IDescription[] = [
        {
            label: "出库项数",
            value: selectedRowKeys.length === 0 ? descTotal.totalNum : rowsDescTotal.totalNum,
            color: DescriptionColorEnum.red
        },
        {
            label: "出库总计",
            value: selectedRowKeys.length === 0 ? descTotal.InTotal : rowsDescTotal.InTotal,
            color: DescriptionColorEnum.green
        }
    ]
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "9-5-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.id as string, mode: "look", },
            true
        );
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ST_OUT_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_OUT_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { exportOutput() }}
            >导出</Button>
        </YhAuth>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { exportDetailsOutput() }}
        >详情导出</Button>
    </>;

    return (
        <YhBox title="出库单列表" descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}