import React, { FC, useEffect } from "react";
import { message, Modal } from "antd";
import useGlobalModel from "../../model/globalModel";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { CLIENT_APP_CODE } from "@/utils/constants";
import { shopService, IVRMShopDetail } from "../organization/shop/shopService";
import { loginServiceAuth2, Auth2MenuDetail } from "./LoginServiceAuth2";
import { ToolOutlined, SmileTwoTone } from "@ant-design/icons";
import { globalPrompt } from "@/components/message";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";

import "./config";

interface ITokenLogin {
	token: string;
}
declare const window: Window & { loginTimer: NodeJS.Timeout | null };

export const TokenLogin: FC<ITokenLogin> = ({ token }) => {
	const history = useHistory()
	const { user, shopAndOrg, setUser, setBeShopDs, setResourceAuth, setShopDs, setMenuAuth, setShopAndOrg } = useGlobalModel();

	useEffect(() => {
		if (!window.loginTimer) {
			window.loginTimer = setTimeout(function () { beforeLogin() }, 1500);
		}
	}, [])

	const beforeLogin = async () => {
		//初始页数
		const searchParams = { pageSize: -1, pageNum: 1 }
		let { retData } = await loginServiceAuth2.VerifyInfo({ sourceApp: sourceAppOrBranch.sourceApp, token });
		let { menus, resources, roles, shops, ...userInfo } = retData ?? {};

		//用户下没有门店或者门店信息不存在直接返回警告。
		if (!shops || !shops.length) {
			// loginServiceAuth2.Logout();
			message.warning("该用户下没有门店信息,请联系后台人员配置门店信息", 5);
			return;
		}
		setResourceAuth(resources ?? []);  //设置资源列表

		//设置用户菜单所有权限列表,(递归查询到所有要展示得菜单地址。)
		let menuArr: string[] = [];
		function findMenuAll(array: Auth2MenuDetail[]) {
			array.forEach(item => {
				item.linkUrl && menuArr.push(item.linkUrl);
				if (item.children && item.children.length > 0)
					findMenuAll(item.children);
			})
		}
		findMenuAll(menus?.[CLIENT_APP_CODE] ?? []);
		setMenuAuth(menuArr);

		//设置用户门店信息
		let { retData: shopRetData } = await shopService.page({ page: searchParams, sourceApp: sourceAppOrBranch.sourceApp })
		const { records } = shopRetData;
		if (records && records.length > 0) {
			//拿到供应链所有的门店， 过滤出用户有权限的门店。
			let filterShop = records.filter(item => shops.indexOf(item.code) > -1);

			if (!filterShop.length) {
				// loginServiceAuth2.Logout();
				message.warning("该用户下没有配置bcm业务系统的门店信息");
				return;
			}

			setShopDs(filterShop.map(item => ({
				key: item.id,
				label: item.name,
				value: item.id,
				code: item.code
			})));

			// let shop = filterShop[0]
			// setBeShopDs((shop?.details ?? []).map(item => ({
			// 	id: item.id,
			// 	label: item.bentityName ?? "",
			// 	value: item.bentityCode ?? ""
			// })))
			// setUser({ ...user, username: userInfo.userName, token, ...userInfo, loginType: 'account', shopCode: shop.code })
			// setShopAndOrg({ ...shopAndOrg, shopId: shop.id, shopName: shop.name, shopCode: shop.code })
			// globalPrompt("notification", {
			// 	type: "success", title: "登录成功",
			// 	duration: 3,
			// 	text: <p className="bold"><span className="block"><SmileTwoTone /> {`您好!${userInfo.userName ?? ""}`}</span>欢迎使用云豪云修管理系统</p>
			// });
			// loginServiceAuth2.recordAppLoginInfo(CLIENT_APP_CODE, userInfo.userCode);
			// history.push("/")

			let setShopModal = Modal.confirm({
				title: <span style={{ fontSize: "20px", fontWeight: "bold" }}>请选择你的门店</span>,
				centered: true,
				closable: true,
				icon: <ToolOutlined />,
				className: "login-shop-modal",
				content: <div className="login-shop-container">
					{
						filterShop.map(item => (
							<span key={item.id} onClick={() => setShopInfoOrUser(item)}>{item.name}</span>
						))
					}
				</div>,
				// onCancel: () => { loginServiceAuth2.Logout(); }
				onCancel: () => { message.warning("不选择门店信息将无法正常进入系统") }
			});

			const setShopInfoOrUser = (shop: IVRMShopDetail) => {
				let { orgCode, orgName } = shop
				//默认设置用户第一个门店的信息 及门店下面对应的经营实体
				setBeShopDs((shop?.details ?? []).map(item => ({
					id: item.id,
					label: item.bentityName ?? "",
					value: item.bentityCode ?? ""
				})));
				setUser({ ...user, username: userInfo.userName, token, ...userInfo, loginType: 'token', shopCode: shop.code })
				setShopAndOrg({ ...shopAndOrg, shopId: shop.id, shopName: shop.name, shopCode: shop.code, orgCode, orgName })
				loginServiceAuth2.recordAppLoginInfo(CLIENT_APP_CODE, userInfo.userCode);
				globalPrompt("notification", {
					type: "success", title: "登录成功",
					duration: 3,
					text: <p className="bold"><span className="block"><SmileTwoTone /> {`您好!${userInfo.userName ?? ""}`}</span>欢迎使用云豪客车管理系统</p>
				});
				setShopModal.destroy();
				history.push("/")
			}
		}
	}

	return (
		<div className="login">
			<div className="caseBlanche">
				<div className="rond">
					<div className="test"></div>
				</div>
				<div className="load">
					<p>loading</p>
				</div>
			</div>
		</div>
	)

}

export default TokenLogin;