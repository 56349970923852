import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import { useState, useEffect } from "react";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { homeService, ISettlementFildes, ISettlementDetails, ISettlementSearch } from "../../homeService"
import { transPgToPagination, formatNum } from "@/utils/utils";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    totalNum: number,
    totalAmount: number
}
export const useSettlementModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const { shopCode, shopId } = shopAndOrg
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopCode])
    const initSearchParam: ISettlementSearch = {};
    const initDataSource: ISettlementDetails[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM };
    const initDesc = {
        totalNum: 0,
        totalAmount: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<ISettlementSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<ISettlementDetails[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<ISettlementFildes>({});
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<ISettlementDetails[]>([]);



    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as ISettlementDetails)
    }, [selectedRowKeys, dataSource])

    const updateSearchParam = (updateParam: ISettlementFildes) => {
        // console.log(updateParam)
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: ISettlementSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            // ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        const { retData } = await homeService.settled(searchParams);
        const { records, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
    }
    const [settleVisible, setSettleVisible] = useState<boolean>(false)
    const [purchaseSettleVisible, setPurchaseSettleVisible] = useState<boolean>(false)

    return {
        initPgCfg,
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        descTotal,
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setSelectedRow,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setDescTotal,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        settleVisible, setSettleVisible,
        purchaseSettleVisible, setPurchaseSettleVisible
    }
})