import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { transferMonitorService, ITransferMonitorSearchFields, ITransferMonitorDetail, IStatistics, } from "./transfermonitorService";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { getRandomStr, transPgToPagination } from "@/utils/utils";


export const useTransferMonitorModel = createModel(function () {
    const initStatistics = {
        num: 0,// 差异数量
        amount: 0,// 差异金额
        costAmount: 0,// 差异金额
        profit: 0,// 差异毛利
    }
    const { whDs, user: { shopId,shopCode }, setLoadingInfo } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //查询条件
    const [searchParam, setSearchParam] = useState<ITransferMonitorSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<ITransferMonitorDetail[]>([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [descTotal, setDescTotal] = useState<IStatistics>(initStatistics);
    const [loading, setLoading] = useState<boolean>(false);


    const updateSearchParam = (updateParam: ITransferMonitorSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: ITransferMonitorSearchFields = searchParam) => {
        setLoading(true)
        setDescTotal(initStatistics);
        try {
            const searchParams = {
                shopId,
                shopCode,
                ...searchValue,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
            let { retData } = await transferMonitorService.page(searchParams);
            const { records, ...pg } = retData;
            setDataSource(records.map(item => ({
                ...item,
                key: getRandomStr()
            })));
            setPgCfg(transPgToPagination(pg));
            let { retData: Data } = await transferMonitorService.statistics(searchParams);
            setDescTotal(Data);
            setLoading(false)
        } catch {
            setLoading(false);
            // setDataSource([])
        }
    }

    //导出
    const exportTransferMonitor = async (searchValue: ITransferMonitorSearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                shopId,
                shopCode,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
            }
            let result = await transferMonitorService.export(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData, exportTransferMonitor,
        descTotal, setDescTotal,
        loading
    }
});


