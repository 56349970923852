import React, { useEffect } from "react";
import { useWriteoffModel } from "./writeoffModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { WriteoffDataTable } from "./DataTable";
import { WriteoffDrawer } from "./writeoffDrawer";

export const WriteoffDataBox = () => {
    const {
        state,
        dataSource,
        writeoffAmount,
        selectedRowKeys,
        setOpenDrawerVisible,
        loadData,
        setSelectedData,
    } = useWriteoffModel();

    useEffect(() => {
        loadData({ pageNum: 1 }).then()
    }, [state])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
    }, [selectedRowKeys]);

    const desc: IDescription[] = [
        {
            label: "核销总额",
            value: writeoffAmount?.toFixed(2) ?? '0.00',
            color: DescriptionColorEnum.green
        },

    ]
    const onApproval = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            setOpenDrawerVisible(true);
        } else {
            message.error("请选择待查看记录!");
        }
    }
    const action = (
        <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onApproval}
            >
                详情
            </Button>

        </>
    );
    return (
        <>
            <YhBox title={"核销记录"} descriptions={desc} >
                <WriteoffDataTable />
            </YhBox>
            <WriteoffDrawer />
        </>
    )
}