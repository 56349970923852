import React, { FC, useState } from "react";
import { Button, Table, Upload, Tooltip, message, Input } from "antd";
import { YhBox } from "@/components/YhBox";
import { globalPrompt } from "@/components/message";
import { ColumnsType } from "antd/es/table";
import { useSaleSoDetailModel } from "./saleSoDetailModel";
import { RouterParamType } from "@/model/navModel";
import { DetailType, IPriceTypeEnum, ISalesTypeEnum } from "@/types/ScmEnums";
import { IDetailList } from "../../saleSoTypes";
import { formatNum, formatIntPoint, formatIntPoint1, bigNumber } from "@/utils/utils";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import useGlobalModel from "@/model/globalModel";
import { ITopType } from "@/types/ScmTypes";
import { stockStatisticService } from "@/views/stock/statistic/stockStatisticService";
import { saleSoService } from "../../saleSoService";
import { DictCodeEnmu } from '@/types/VrmSaDictEnums';
import { purchaseDetailsService } from '@/views/stock/purchaseDetails/purchaseDetailsService';
type ISCMSDcGoodsSearch = {
    data: any[],
    isSearch: boolean
}

export const TableBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const { shopAndOrg: { shopId } } = useGlobalModel()

    const {
        setAddModalVisible,
        detailAddList,
        detailDataSource,
        detailLookList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        detailAddForm,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        // detailDetailList,
        downloadTpl,
        modelScreenMethod,
        setTopButsControl,
        setHistoryVisible,
        setHistoryRow
    } = useSaleSoDetailModel();
    const onHistoryModel = (record: IDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const uniqueArr = (arr: any[], str: string) => {
        const res = new Map()
        return arr.filter((arr) => !res.has(arr[str]) && res.set(arr[str], 1))
    }
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMSDcGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据
    let { priceTypeCode, maintenanceTypeCode, customerCode } = mode === "add" ? detailAddForm : detailEditForm;
    const importCallback = async (list: any) => {
        let detailsList = mode == "add" ? detailAddList : detailDataSource;
        let newList = uniqueArr(list, "materialCode")
        let newImportList: any[] = []
        await Promise.all(newList.map(async (item: any) => {
            let { stockId, latestPurPrice, materialCode, lateSalePrice, discountRate, purchasePrice, retailPrice, num, petroleumPrice, ...params } = item as { latestPurPrice: number, lateSalePrice: number, purchasePrice: number, retailPrice: number, num: number, materialCode: string, discountRate: number, petroleumPrice: number, stockId: string }
            let newPrice: number = 0;
            if (maintenanceTypeCode !== ISalesTypeEnum.NORMAL_SALES) {
                newPrice = latestPurPrice ?? purchasePrice
            } else if (maintenanceTypeCode === ISalesTypeEnum.NORMAL_SALES) {
                if (priceTypeCode === IPriceTypeEnum.CUSTOMER_LATEST) {//需要通过接口查询，客户最近一次的销售价
                    await purchaseDetailsService.recentSalesPrice(shopId, materialCode, customerCode as string).then(res => {
                        let { retData: { price } } = res;
                        newPrice = price as any
                    });
                } else if (priceTypeCode === IPriceTypeEnum.RECENT) {//售价选择 最近销价
                    newPrice = lateSalePrice ?? retailPrice;
                } else if (priceTypeCode === IPriceTypeEnum.RETAIL) {//售价选择 零售价
                    newPrice = retailPrice;
                } else if (priceTypeCode === IPriceTypeEnum.BUYING_PRICE) {//售价选择 进货价
                    newPrice = purchasePrice;
                }
            }
            return {
                ...params,
                latestPurPrice,
                purchasePrice,
                retailPrice,
                lateSalePrice,
                stockId,
                id: stockId,
                materialCode,
                num,
                discountRate,
                price: newPrice,
                //notAmount: +formatNum(num * newPrice),
                notAmount:+bigNumber.toFixed(bigNumber.times(num,newPrice),2),
                //amount: +formatNum(num * newPrice * (discountRate / 100))
                amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(num,newPrice),bigNumber.dividedBy(discountRate,100)),2),
            }
        })).then(reslist => {
            newImportList = reslist
        })

        const { repetition, newDetailData } = await modelScreenMethod(list, detailAddList)
        if (mode === "add") {
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        } else if (mode === "edit") {
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
    }
    const switchModelViaible = () => {
        IFormRef.current?.validateFields().then(async res => {
            setAddModalVisible(true)
        })
    }

    const controlEditable = () => {
        if (mode === DetailType.look) return false
        if (mode === DetailType.edit &&
            detailEditForm.status === DictCodeEnmu.SALE_SETTLE ||
            detailEditForm.status === DictCodeEnmu.SALE_STOCK_IO) {
            return false
        }
        return true
    }
    const addModal = () => {
        if (!maintenanceTypeCode) {
            message.warning("请选择销售类型");
            return;
        }
        if (!customerCode) {
            message.warning("请选择客户");
            return;
        }
        IFormRef.current?.validateFields().then(async res => {
            setAddModalVisible(true)
        })
    }
   //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
   const goodsFilter = (dataSource: any | null = null) => {
    // const isEtit = mode !== "add";
    let goodsList = dataSource ? dataSource : typeList[mode];
    if (searchParms) {
        let reg = new RegExp(searchParms);
        let arr: any = [];
        goodsList.forEach((item: { materialCode: string; materialName: string; }) => {
            if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                arr.push(item);
            }
        })
        setSearchVal({ data: arr, isSearch: true });
        if (!arr.length) {
            message.warning("列表没有该商品！！")
        }
    } else {
        setSearchVal({ data: [], isSearch: false });
    }
}
	//删除详情列表数据
	const detailDetailList = async (mode: string, Material?: IDetailList) => {
		// debugger
		if (mode === 'add') {
			const filterDetailList = detailAddList.filter(
				(el) => !!!detaiAddDataRowKeys.find((ele) => ele === el.purchaseId)
			);
			setDetailAddList(filterDetailList);
            let newSearchData = searchVal.data.filter(
				(el) => !!!detaiAddDataRowKeys.find((ele) => ele === el.purchaseId)
			);
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetaiAddDataRowKeys([]);
			setDetaiAddDataRow([]);
		} else if (mode === 'edit') {
			const filterDetailList = detailDataSource.filter(
				(el) => !!!detailDataRowKeys.find((ele) => ele === el.purchaseId)
			);
			setDetailDataSource(filterDetailList);
            let newSearchData = searchVal.data.filter(
				(el) => !!!detailDataRowKeys.find((ele) => ele === el.purchaseId)
			);
            setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetailDataRowKeys([]);
			setDetailDataRow([]);
		}
	};
    const action =
        <>
        <Input
                size="small"
                allowClear
                datatype="round"
                className="border-r-8 ml-10"
                placeholder="配件编号/配件名称"
                onChange={(e) => {setSearchParms(e.target.value );goodsFilter()}}
                onBlur={(e) => setSearchParms(e.target.value?.trim() ) }
                onPressEnter={() => goodsFilter()}
                style={{width:'200px'}}
            />
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 查询</Button>
            {(mode !== "look" ? (mode === "add" || detailEditForm.status !== "sale.settle") : false) && <>
                <YhAuth resCode={ResourceAuthEnum.S_S_ED_ADD_M}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={() => addModal()}
                    >新增商品</Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.S_S_ED_DEL_M}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={() => { detailDetailList(mode) }}
                    >删除商品</Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.S_S_ED_IM}>
                    <Upload
                        beforeUpload={(file, fileList) => {
                            if (!maintenanceTypeCode) {
                                message.warning("请选择销售类型");
                                return false;
                            }
                            if (!customerCode) {
                                message.warning("请选择客户");
                                return false;
                            }
                            return true
                        }}
                        showUploadList={false}
                        className="mr-10 ml-10"
                        {...importPropsConfig(`/bcm/sale/details/import/${shopId}`, importCallback)}
                    >
                        <Button
                            disabled={!(maintenanceTypeCode && customerCode)}
                            className="mr-5"
                            type="link"
                            shape="round"
                        >导入</Button>
                    </Upload>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.S_S_ED_DLOAD}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={downloadTpl}
                    >下载模板</Button>
                </YhAuth>
            </>}
        </>

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, 
        {
            title: '可用库存',
            width: 80,
            dataIndex: 'inventoryNum',
        },
        {
            title: '销售数量',
            width: 80,
            dataIndex: 'num',
            // editable: mode === DetailType.look || (detailEditForm.status === "sale.settle" && mode === DetailType.edit) ? false : true,
            editable: controlEditable()
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            // editable: mode === DetailType.look || (detailEditForm.status === "sale.settle" && mode === DetailType.edit) ? false : true,
            editable: controlEditable()
        }, {
            title: '金额',
            width: 100,
            dataIndex: 'notAmount',
        }, {
            title: '折扣',
            width: 100,
            dataIndex: 'discountRate',
            // editable: mode === DetailType.look || (detailEditForm.status === "sale.settle" && mode === DetailType.edit) ? false : true,
            editable: controlEditable(),
            suffix: "%",
            render: (text: string) => text + '%'
        }, {
            title: '折后单价',
            width: 100,
            dataIndex: 'discountedPrice',
        }, {
            title: '单项优惠',
            width: 100,
            editable: controlEditable(),
            dataIndex: 'decreaseAmount',
        }, {
            title: '零件金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '成本价',
            width: 100,
            dataIndex: 'costPrice',
        },  {
            title: '仓库',
            width: 140,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 140,
            dataIndex: 'shelfCode',
        },  {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        },
        {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        },
        {
            title: '成本金额',
            width: 100,
            dataIndex: 'costAmount',
        },
        {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        },
        {
            title: '备注',
            width: 250,
            fixed: "right",
            dataIndex: 'note',
            editable: mode != "look" ? true : false,
        }
    ];
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = async (row: IDetailList) => {
        let { num, price, latestPurPrice, discountRate, decreaseAmount, costPrice, inventoryNum, termNum } = row
        discountRate = +formatIntPoint(discountRate, 100, '折扣不能高于100%')
        num = mode === "add" ? parentInfo ? +formatIntPoint(num) : +formatIntPoint(num, inventoryNum, "选择数量>=可用库存数量") : parentInfo ? +formatIntPoint(num) : +formatIntPoint(num, termNum ? termNum : inventoryNum, "选择数量>=可用库存数量");  //数量
        price = +formatIntPoint1(price, latestPurPrice, `修改价格不应小于进货价${latestPurPrice}`);
        price = +formatNum(price);
        //let discountedPrice = +formatNum(price * discountRate / 100)
        let discountedPrice=+bigNumber.toFixed(bigNumber.times(price,bigNumber.dividedBy(discountRate,100)),2)
        decreaseAmount = +formatIntPoint(decreaseAmount ?? 0, Number(num) * price * discountRate / 100, '单项优惠金额不能大于折后金额!')
        let newRow = {
            ...row,
            price,
            discountRate,
            num,
            decreaseAmount,
            // notAmount: +formatNum(Number(num) * price),
            notAmount:+bigNumber.toFixed(bigNumber.times(+num,price),2),
            // amount: +formatNum((Number(num) * price * discountRate / 100) - decreaseAmount),
            amount:+bigNumber.toFixed(bigNumber.minus(bigNumber.times(bigNumber.times(+num,price),bigNumber.dividedBy(discountRate,100)),decreaseAmount),2),
           
            discountedPrice,
            // costAmount: +formatNum(num * Number(costPrice)),
            costAmount:+bigNumber.toFixed(bigNumber.times(num,Number(costPrice)),2)
        }
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.stockId === item.stockId);
        const item = newData[index];

        // //判断仓库变化时，  重置货位
        // if (newRow.warehouseCode !== newData[index].warehouseCode) {
        //     newRow.shelfCode = undefined
        // }
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(newData)
        }
        setTopButsControl(true)
    }
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }

    return (
        <YhBox title="配件信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.purchaseId as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode == DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns() as ColumnsType<IDetailList>}
                dataSource={searchVal.isSearch ? searchVal.data :typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}