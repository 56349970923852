import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface ISalesReortDetail {
    id:string;
    documentNumber: string, //"XLWWX2021110501",               单号
    tradeTypeCode: string, //"trade-type.maintenance",        单据类型
    customerName: string, //"全速顺达有限公司",                 客户名称   
    customerTypeCode: string, //null,                         客户类型
    customerTypeName: string, //null,
    shopName: string, //"新疆新陆王",                          门店名称    
    shopId: string, //"6f5d134fe0ec32a28c54514086c1abe8",
    tradeTypeName: string, //"维修",                          单据类型                         
    type: string, //null,                       
    totalAmount: string, //920,                               销售金额              
    discountTotalAmount: string, //0,                         优惠总金额              
    materialScienceAmount: string, //0,                       配件金额  
    materialDiscountAmount: string, //null,                   配件优惠金额  
    workingHoursAmount: string, //920,                        工时费  
    workingDiscountAmount: string, //null,                    工时优惠金额  
    createUser: string, //"毕思文",                            创建人 
    settleTime: string, //null,                               结算日期 
    createTime: string, //"2021-11-0",              创建时间      
    note: string, //null                                        备注
}

//搜索条件
export interface ISalesReortSearchFields {
    shopId?: string, //"门店id",
    tradeTypeCode?: string, //"单据类型",
    startTime?: string, //"制单开始时间",
    EndTime?: string, //"制单结束时间",
    settlementStartTime?: string, //"结算开始时间",
    settlementEndTime?: string, //"结算结束时间"
}

export interface ISalesReortSearch
    extends ISalesReortSearchFields, ISearchPage {
}


//进销存报表
class SalesReortService {
    async page(searchVo: ISalesReortSearch) {
        const apiUrl = "/bcm/sale/sale/form";
        return await httpRequest.post<IPage<ISalesReortDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: ISalesReortSearch) {
        const apiUrl = "/bcm/sale/export/from";
        return await httpRequest.postFile(apiUrl, searchVo);
    }
}

export const salesReortService = new SalesReortService();