import React, { FC, useEffect, useState } from "react";
import { useWarehousingModel } from "./WarehousingModel";
import { YHDrawer } from "@/components/YhDrawer";
import { ColumnsType } from "antd/lib/table";
import { Table, message } from "antd";
import { RouterParamType } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import useGlobalModel from "@/model/globalModel";
import { purchasePoService } from "@/views/purchase/po/purchasePoService";
import { saleRoService } from "@/views/sale/ro/saleRoService";
export interface IDataSource {
    materialCode?: string,
    materialName?: string,
    num?: string,
}
export const WhConfirmDrawer: FC = ({ }) => {
    const { user, shopAndOrg } = useGlobalModel()
    const { wsConfirmModal: visible, setWsConfirmModal, selectedRow, loadData, setSelectedRowKeys } = useWarehousingModel();
    let { documentNumber, type, id } = selectedRow
    const [dataSource, setDataSource] = useState<IDataSource[]>([])
    const [formWsConfirm, setFormWsConfirm] = useState<object>({})
    const onCancel = () => {
        setWsConfirmModal(false);
    };
    useEffect(() => {
        if (visible) {
            getWhConfirmList()
        }

    }, [visible])
    const getWhConfirmList = async () => {
        let newDetails: any = []
        if (type === "销退单") {
            let { retData } = await saleRoService.one(documentNumber as string)
            let { details, ...from } = retData
            setFormWsConfirm(from)
            newDetails = details?.map(item => {
                let { materialCode, materialName, num } = item
                return { materialCode, materialName, num }
            })
        } else if (type === "采购单") {
            let { retData } = await purchasePoService.one(documentNumber as string)
            let { details, ...from } = retData
            setFormWsConfirm(from)
            newDetails = details?.map(item => {
                let { materialCode, materialName, num } = item
                return { materialCode, materialName, num }
            })
        }
        setDataSource(newDetails)
    }
    const onSubmit = async () => {
        let { id, returnCode, purchaseCode } = formWsConfirm as { id: string, returnCode: string, purchaseCode: string }

        if (type === "销退单") {
            let { retData } = await saleRoService.warehousing({ id, returnCode, storekeeper: user.username })
            if (retData) {
                onCancel()
                loadData()
                message.success("入库成功")
                setSelectedRowKeys([])
            }
        } else if (type === "采购单") {
            let { retData } = await purchasePoService.warehousing({ id, purchaseCode, storekeeper: user.username })
            if (retData) {
                onCancel()
                loadData()
                message.success("入库成功")
                setSelectedRowKeys([])
            }
        }
    }

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            dataIndex: "index",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        },
        {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '出库数量',
            width: 160,
            dataIndex: 'num',
        }
    ];
    return (
        <YHDrawer
            {...{
                title: '库房确认',
                visible,
                onCancel,
                width: 800,
                okText: "保存",
                onOk: onSubmit,
                // showFooter: mode === "look" ? false : true

            }}
        >
            <Table
                // size="small"
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 400 }}
            />
        </YHDrawer>
    )
}