import React, { useEffect, FC, useState } from "react";
import { ITopType } from "@/types/ScmTypes";
import { useDeploymentDetailModel } from "./deploymentDetailModel";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import useNavModel, { } from "@/model/navModel";
import { DetailType } from "@/types/ScmEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import { globalPrompt } from "@/components/message";
import { Button, message } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import { debounce } from "lodash";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDetailModel, ActionTypeEnum } from "../../detailModel";
import { useDeploymentModel } from "../deploymentModel"
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";

export const FormBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const { busBusiness: { warehouseCode, warehouseName }, setActionType } = useDetailModel()
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        updateAddFormVal,
        updateEditFormVal,
        insertDetail,
        resetAddCatch,
        resetEditCatch,
        getDetailEditOne,
        setTopButsControl,
        formComputeRule,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialEditList, setDetailMaterialEditList,
    } = useDeploymentDetailModel();

    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow,
    } = useDeploymentModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { warehouseList, user, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel();
    const materialList=mode === "edit" ?  detailMaterialEditList : detailMaterialAddList
    const setMaterialList=mode === "edit" ?  setDetailMaterialEditList : setDetailMaterialAddList
    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])

    useEffect(() => {
        return () => {
            !findNav("16-4-1") && resetAddCatch(mode);
            !findNav("16-4-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])
    const { getSelectedDictOne } = useDictModel();
    //当列表跳转来的时候 对应客户信息为空 反之带预售销售详情的数据
    let newWarehouse = parentInfo?.source ? {} : { sourceWarehouseCode: warehouseCode, sourceWarehouseName: warehouseName, }
    const typeForm = {
        add: {
            // sourceWarehouseCode: warehouseCode,
            // sourceWarehouseName: warehouseName,
            shopId, shopCode, shopName,
            createUser: user.username,
            ...detailAddForm,
            ...newWarehouse,
        },
        edit: detailEditForm,
        look: detailLookForm
    }
    const save = () => {
        //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const retData = await insertDetail(res)
                if (retData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    setSelectedRowKeys([retData.id as string])
                    setDescRows([retData])
                    setSelectedRow(retData)
                    resetAddCatch(mode)
                    goBack()
                }
            }
        })

    }
    const goBack = () => {
        if (parentInfo && parentInfo.selectedNavId) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "16-1-2");
            setActionType(ActionTypeEnum.DEPLOYMENT)
        }
    }
    const onRefresh = () => {
        // console.log(process)
        if (mode === "add") {
            IFormRef.current?.resetFields()
            resetAddCatch()
        }
        message.success("刷新成功")
    }
    const rightChildren = <>
        {mode !== "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { save() }, 900)}
            >保存</Button>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    function clearGoodsList() {
        if (materialList.length > 0) globalPrompt('message', { text: '切换成功!需要重新选择商品信息', type: 'warning' })
        setMaterialList([])
}
    //商品类型
    const productTypChange = (value: string, option: ISelectOption) => {
        option && onChange({
            productTypeName: option.label,
            productTypeCode: option.value,
        });
        clearGoodsList()
    }
    //调入仓库
    const warehouseChange = (value: string, option: ISelectOption) => {
        option && onChange({ targetWarehouseName: option.label, targetWarehouseCode: option.value });
        clearGoodsList()
    }
    //调出仓库
    const sourceWarehouseChange = (value: string, option: ISelectOption) => {
        option && onChange({ sourceWarehouseName: option.label, sourceWarehouseCode: value });
        clearGoodsList()
    }
    const orderStatus: IDescription[] = [
        {
            label: "调配单号",
            value: typeForm[mode].allocateCode ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeForm[mode]?.status && getTreeNodeName(typeForm[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]
    const baseFormItem: IYhFormItemsBox = {
        title: <>基本信息&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mode !== "add" && orderStatus.map((item, index) => <Description key={index} {...item} />)}</>,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span>调出仓库</span>,
                fieldName: "sourceWarehouseCode",
                options: transOptions(warehouseList.filter((item: any) => item.type === "warehouse-category.small")),
                onSelectChange: sourceWarehouseChange,
                allowClear: true,
                showSearch: true,
                disable: !!!parentInfo?.source,
                rules: [{ required: true, message: "调出仓库" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "商品类型",
                fieldName: "productTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
                onSelectChange: productTypChange,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span>调入仓库</span>,
                fieldName: "targetWarehouseCode",
                // options: transOptions(warehouseList).filter(item => item.value !== warehouseCode),
                options: transOptions(warehouseList.filter((item: any) => item.type === "warehouse-category.small")).filter(item => item.value !== warehouseCode),
                onSelectChange: warehouseChange,
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: "调入仓库" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调配人",
                fieldName: "createUser",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调配时间",
                fieldName: "createTime",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出仓库name",
                fieldName: "sourceWarehouseName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入仓库name",
                fieldName: "targetWarehouseName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "商品类型name",
                fieldName: "productTypeName",
                hidden: true
            }
        ]
    }
    const onChange = (changedValues: { [key: string]: any }) => {
        mode === "add" ? updateAddFormVal(changedValues) : updateEditFormVal(changedValues)
        setTopButsControl(true)
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === "look",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }
    return (
        <YhFormBox {...poFromConfig} />

    )
}