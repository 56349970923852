import { httpRequest } from "@/utils/HttpRequest";
import { IPage, IKey, IMdCmFields } from "@/types/ScmTypes";
import { trasDsToTreeData } from "@/views/base/dict/dictService";

export interface IMdOrgFields extends IMdCmFields {

    code?: string;
    name?: string;
    pcode?: string;
    contact?: string;
    phone?: string;
    address?: string;
    note?: string;
    children?: IMdOrgDetail[];
}
export type IMdOrgDetail = IKey & IMdCmFields & IMdOrgFields;


export interface IVRMOrgSearch {
    page: {
        pageSize: number;
        pageNum: number;
    }
    sourceApp?: string;
    branch?: string;
}



export interface IVRMOrgDetail {
    note: string,
    ver: string,
    code: string,
    address: string,
    sourceAuthKey: string,
    pcode: string,
    branch?: string,
    phone: string,
    children: IVRMOrgDetail[],
    enable: number,
    contact: string,
    name: string,
    key: string,
}


export interface IVRMOrgInsert {
    sourceApp: string,
    branch?: string,
    code: string,
    name: string,
    value?: string,
    pcode?: string | null,
    note?: string,
}

export interface IVRMOrgUpdate {
    sourceApp?: string,
    branch?: string,
    code: string,
    value?: string,
}

export interface IVRMOrgOne {
    sourceApp?: string,
    branch?: string,
    code: string
}

class OrgService {
    //树列表
    async page(searchVo: IVRMOrgSearch) {
        const apiUrl = "/bcm/base/org/page";
        return await httpRequest.post<IPage<IVRMOrgDetail>>(apiUrl, searchVo)
    }
    //同步
    async sync(syncVo: object) {
        const apiUrl = "/bcm/base/org/sync";
        return await httpRequest.post<boolean>(apiUrl, syncVo)
    }
    async allTree(vo: IMdOrgFields) {
        const apiUrl = "/fms/org/allTree";
        vo.branchList = ["main", "fms"];
        return await httpRequest.post<IMdOrgDetail[]>(apiUrl, vo)
    }
    async orgTreeDs(vo: IMdOrgFields) {
        let { retData } = await this.allTree(vo);
        // retData.map(el=>({
        //     label:el.name,
        //     value:el.code,
        //     children:[]
        // }))
        let treeData = trasDsToTreeData(retData, "code", "name");
        return treeData;
    }
}

export const orgService = new OrgService();
