import { httpRequest } from "../../../utils/HttpRequest";
import { IPage, ISearchPage } from "../../../types/ScmTypes";
import { IMaterialDetail } from "@/views/base/material/materialService";
export interface IStockStatisticFields {
    availableStock?: number,    // 0,
    averageAmount?: number,    // 0,
    averagePurchasePrice?: number,    // 0,
    brandCode?: string,    // "",
    brandName?: string,    // "",
    id?: string,    // "",
    inventoryInitial?: number,    // 0,
    inventoryNum?: number,    // 0,
    inventoryOccupied?: number,    // 0,
    lateSalePrice?: number,    // 0,
    latestAmount?: number,    // 0,
    latestPurPrice?: number,    // 0,
    limitLower?: number,    // 0,
    limitUpper?: number,    // 0,
    madeFrom?: string,    // "",
    materialCode?: string,    // "",
    materialName?: string,    // "",
    model?: string,    // "",
    nameEn?: string,    // "",
    purchasePrice?: number,    // 0,
    replaceCode?: string,    // "",
    retailPrice?: number,    // 0,
    shelfCode?: string,    // "",
    shelfId?: string,    // "",
    typeCode?: string,    // "",
    typeName?: string,    // "",
    unitCode?: string,    // "",
    unitName?: string,    // "",
    warehouseCode?: string,    // "",
    warehouseName?: string,    // ""
    ids?: string,
    occupy?:number|null,
    age?:number|null
    [key: string]: any

}

export type IStockStatisticSearch = IStockStatisticFields & ISearchPage;
export type IStockStatisticDetail = IStockStatisticFields
export interface IInventoryTotal {
    averageAmount: number,  // 0,
    type: number,  // 8,
    latestAmountTotal: number,  // 4250,
    total: number,  // 65
}
export interface IList {
    hitCount: boolean,
    optimizeCountSql: boolean,
    total: number,
    current: number,
    size: number,
    orders: any,
    isSearchCount: boolean,
    records: IStockStatisticDetail[]
}
export interface IStockStatisticList {
    inventoryTotal: IInventoryTotal,
    list: IList
}

export interface IStockStatisticTotal {
    stockNum:number
}
export interface IOccupyFidles {
    materialCode?: string // "",
    shopId?: string // ""
}
export type IOccupySearch = IOccupyFidles & ISearchPage;
export interface IOccupyDetails {
    code?: string, // "TJHYWX202106030002"
    createTime?: string, // "2021-06-0"
    customerCode?: string, // "CUS-M-00002407"
    customerName?: string, // "华宇"
    detailsId?: string, // "c5feae1d69054fc99c87d28c4dbd6fbb"
    id?: string, // "56f2435d9685482c862bddfb3896b931"
    materialCode?: string, // "880788"
    num?: string, // 1
    shopId?: string, // "2d0351d2cf90973a4467e76ee5b57582"
    shopName?: string, // "天津红岩品牌部"
    status?: string, // "maintenance.create"
    type?: string, // "维修工单"
}
export interface IRelocation {
    materialCode?: string,// "",
    materialName?: string,// "",
    relocationNum?: string,// 0,
    shelfCode?: string,// "",
    shelfCodeNew?: string,// "",
    shelfId?: string,// "",
    shelfIdNew?: string,// "",
    shopId?: string,// "",
    shopName?: string,// "",
    stockId?: string,// "",
    warehouseCode?: string,// "",
    warehouseCodeNew?: string,// "",
    warehouseName?: string,// "",
    warehouseNameNew?: string,// ""
}
export interface IRelocationDetail {
    details: IRelocation[]
}

//转移记录
export interface IRecordsFields {
    startTime?: string,
    endTime?: string,
    materialCode?: string,
    stockId?: string
}
export type IRecordsSearch = IRecordsFields & ISearchPage
export interface IRecordsDetail {
    id?: string, // "",
    materialCode?: string, // "",
    materialName?: string, // "",
    relocationNum?: string, // 0,
    shelfCode?: string, // "",
    shelfCodeNew?: string, // "",
    shelfId?: string, // "",
    shelfIdNew?: string, // "",
    shopId?: string, // "",
    shopName?: string, // "",
    stockId?: string, // "",
    warehouseCode?: string, // "",
    warehouseCodeNew?: string, // "",
    warehouseName?: string, // "",
    warehouseNameNew?: string, // ""
}
export interface IFindBySorderSumDetail {
    appointmentCode?: string, // "QCYY2021102301",
    appointmentType?: string, // "销售单",
    materialCode?: string, // "6060 268 002H",
    materialName?: string, // "分离拨叉",
    warehouseName?: string, // "綦齿库",
    shelfCode?: string, // "A12-1-1",
    num?: string, // 1.00,
    inventoryNum?: string, // 20.00
}
//采购配件新增查询
export interface IStockFindStockFileds {
    id?: string, // "aa9c35de8ab65a0f148140e452daf3f9",
    nameEn?: string, // null,
    materialNo?: string, // null,
    materialCode?: string, // "2659252",
    replaceCode?: string, // null,
    minOrderNum?: number, // 0.00,
    materialName?: string, // "配件-新增",
    inventoryNum?: number, // null,
    inventoryInitial?: string, // null,
    inventoryOccupied?: string, // null,
    availableStock?: string, // null,
    warehouseCode?: string, // "WH-M-00000076",
    warehouseName?: string, // "綦齿库",
    shelfId?: string, // "23891e9869fce1cebbcef9ab951ed24c",
    shelfCode?: string, // "D55-3-3",
    brandCode?: string, // null,
    brandName?: string, // null,
    model?: string, // null,
    madeFrom?: string, // null,
    unitName?: string, // null,
    unitCode?: string, // null,
    averagePurchasePrice?: string, // null,
    purchasePrice?: number, // null,
    averageAmount?: number, // null,
    latestPurPrice?: number, // 130.00,
    latestAmount?: string, // null,
    lateSalePrice?: string, // null,
    limitUpper?: number, // null,
    retailPrice?: number, // null,
    typeName?: string, // "备品",
    typeCode?: string, // null,
    limitLower?: number, // null,
    shopId?: string, // null,
    shopName?: string, // null,
    shopCode?: string, // null,
    num?: number, // null,
    overstock?: string, // null,
    xdsum?: string, // null,
    yysum?: string, // null,
    earliestTime?: string, // null,
    createTime?: string, // null
    code?: string,
    name?: string,
    price?: number,
}

export interface IStockFindDetails {
    // 商品编码：
    materialCode: string
    // 商品名称：
    materialName: string
    // 仓库名称: 
    warehouseName: string
    // 仓库编号: 
    warehouseCode: string
    // 货位编号：
    shelfCode: string
    // 入库数量：
    inventoryNum: number
    purchasePrice: number,
    retailPrice: number,
    // 初始库存数量：
    inventoryInitial: number
    // 占用库存数量：
    inventoryOccupied: number
    // 可用库存数量：
    availableStock: number
    // 入库单价：
    latestPurPrice: number
    // 最新销价：
    lateSalePrice: number
    // 入库金额：
    totalAmount: number
    // 供应商编号：
    supplierCode: string
    // 供应商名称：
    supplierName: string
    // 采购单号：
    purchaseCode: string
    // 入库日期：
    stockIoTime: string
    // 替换编号：
    replaceCode: string
    inventoryQuantity?: number,
    id: string
    [key:string]:any
}
class StockStatisticService {
    //库存查询
    async page(searchVo: IStockStatisticSearch) {
        const apiUrl = "/bcm/stock/findPage";
        return await httpRequest.post<IStockStatisticList[]>(apiUrl, searchVo);
    }
    //分页查询
    async pages(searchVo: IStockStatisticSearch) {
        const apiUrl = "/bcm/stock/findPages";
        return await httpRequest.post<IPage<IStockStatisticDetail>>(apiUrl, searchVo);
    }
    //分页查询
    async detailsPage(searchVo: IStockStatisticSearch) {
        const apiUrl = "/bcm/stock/details/findPage";
        return await httpRequest.post<IPage<IStockStatisticDetail>>(apiUrl, searchVo);
    }
    //根据客户code 编码code查询第一次销售价格
    async pages1(searchVo: IStockStatisticSearch) {
        const apiUrl = "/bcm/stock/findPages1";
        return await httpRequest.post<IPage<IStockStatisticDetail>>(apiUrl, searchVo);
    }
    //根据ID查询
    async one(id: string) {
        const apiUrl = `/bcm/purchase/findById/${id}`;
        return await httpRequest.get<IStockStatisticFields>(apiUrl);
    }
    //导出
    async export(params: IStockStatisticSearch) {
        const apiUrl = "/bcm/stock/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //占用明细查看
    async occupy(searchVo: IOccupySearch) {
        const apiUrl = `/bcm/occupy/findPage`;
        return await httpRequest.post<IPage<IOccupyDetails>>(apiUrl, searchVo);
    }

    // 公交占用库存查看
    async busOccupy(searchVo: IOccupySearch) {
        const apiUrl = `/bcm/occupy/gj/findPage`;
        return await httpRequest.post<IPage<IOccupyDetails>>(apiUrl, searchVo);
    }
    //移库
    async relocation(searchVo: IRelocationDetail) {
        const apiUrl = `/bcm/stock/relocation`;
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    //移库记录查看
    async records(searchVo: IRecordsSearch) {
        const apiUrl = `/bcm/records/page`;
        return await httpRequest.post<IPage<IRecordsDetail>>(apiUrl, searchVo);
    }
    //移库还原接口
    async reduction(id: string) {
        const apiUrl = `/bcm/records/reduction/${id}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
    //销钉数量详情查询
    async findBySorderSum(searchVo: object) {
        const apiUrl = `/bcm/stock/findBySorderSum`;
        return await httpRequest.post<IFindBySorderSumDetail[]>(apiUrl, searchVo);
    }
    //预约数量详情查询
    async findByAppointmentSum(searchVo: object) {
        const apiUrl = `/bcm/stock/findByAppointmentSum`;
        return await httpRequest.post<IFindBySorderSumDetail[]>(apiUrl, searchVo);
    }
    //采购配件详情查询
    async findStock(searchVo: object) {
        const apiUrl = `/bcm/stock/findstock`;
        return await httpRequest.post<IPage<IMaterialDetail>>(apiUrl, searchVo);
    }
    //采购明细单条单据对应价格查询
    async findId(stockId: string) {
        const apiUrl = `/bcm/stock/details/findId/${stockId}`;
        return await httpRequest.get<IStockFindDetails>(apiUrl);
    }
}

export const stockStatisticService = new StockStatisticService();