import { createModel } from "hox";
import { useState, useEffect, Key } from "react";
import useGlobalModel from "@/model/globalModel";
import { ISaleReturnDetail, IISaleReturnDetailList, ISaleDetailDetail, ISaleDetailSearch, ISaleReturnettleParams, ISaleReturnSettleDetail } from "../../../../busBusinessType"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { globalPrompt } from "@/components/message";
import { transPgToPagination, formatNum,bigNumber } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { useLockFn } from "ahooks";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";
import moment from "moment";
import { convertRes2Blob } from "@/utils/exportFile";
import { useDetailModel } from "../../detailModel";
import { message } from "antd";


//这里为了继承一个弹框列表的自定义属性值
export interface ISaleDetailModel extends ISaleDetailDetail {
    num: number,
    price?: number,
    amount?: number,
    saleNum?: number
}
export type ISaleDesc = {
    numAll: number,
    itemNumAll: number,
    totalAll: number,
    discountAll: number,
    receivableAll: number,
    [key: string]: any

}
export const useSaleReturnDetailModel = createModel(function () {
    const { customerParams } = useDetailModel()
    const { user, shopAndOrg } = useGlobalModel()
    const { shopId } = shopAndOrg
    const [materialModel, setMaterialModel] = useState<boolean>(false); //维修配件弹框 
    const initDesc: ISaleDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0,//总计
        discountAll: 0,//折扣,
        receivableAll: 0 //应收
    }
    const initForm = { ...customerParams }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initMaterialSearchParam = {};

    //新增数据
    //新增详情form 
    const [detailAddForm, setDetailAddForm] = useState<ISaleReturnDetail>({ initForm })
    //新增维修用料详情列表
    const [detailMaterialAddList, setDetailMaterialAddList] = useState<IISaleReturnDetailList[]>([])
    //新增维修用料详情列表选中行
    const [detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialAddDataRow, setDetailMaterialAddDataRow] = useState<IISaleReturnDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<ISaleDesc>(initDesc)

    //编辑数据
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState<ISaleReturnDetail>({})
    //编辑维修用料详情列表
    const [detailMaterialEditList, setDetailMaterialEditList] = useState<IISaleReturnDetailList[]>([])
    //编辑维修用料详情列表选中行
    const [detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialEditDataRow, setDetailMaterialEditDataRow] = useState<IISaleReturnDetailList[]>([]);
    const [editDesc, setEditDesc] = useState<ISaleDesc>(initDesc)
    //编辑结算详情
    const [editSettleDetails, setEditSettleDetails] = useState<ISaleReturnSettleDetail[]>([])
    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<ISaleReturnDetail>({})
    //查看维修用料详情列表
    const [detailMaterialLookList, setDetailMaterialLookList] = useState<IISaleReturnDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<ISaleDesc>(initDesc)
    //查看结算详情
    const [lookSettleDetails, setLookSettleDetails] = useState<ISaleReturnSettleDetail[]>([])

    //配件列表配置
    const [workMaterialDataSource, setWorkMaterialDataSource] = useState<ISaleDetailModel[]>([])
    const [searchWorkMaterialData, setSearchWorkMaterialData] = useState<ISaleDetailSearch>(initMaterialSearchParam)
    const [workMaterialPgCfg, setWorkMaterialPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataMaterialSource, setAddDataMaterialSource] = useState<ISaleDetailModel[]>([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)
    useEffect(() => {
        let newDesc: ISaleDesc = initDesc;
        detailMaterialAddList && detailMaterialAddList.forEach((item, index) => {
            let { num, amount } = item

            newDesc.numAll = bigNumber.add(newDesc.numAll,Number(num))
            newDesc.totalAll  = bigNumber.add(newDesc.totalAll,Number(amount))
            // newDesc.numAll += Number(num);
            // newDesc.totalAll += Number(amount);
        })
        newDesc.itemNumAll = detailMaterialAddList && detailMaterialAddList.length
        setAddDesc(newDesc);

    }, [detailAddForm, detailMaterialAddList])
    useEffect(() => {
        let newDesc: ISaleDesc = initDesc;
        detailMaterialEditList && detailMaterialEditList.forEach((item, index) => {
            let { num, amount } = item
            newDesc.numAll = bigNumber.add(newDesc.numAll,Number(num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(amount))
            // newDesc.numAll += Number(num);
            // newDesc.totalAll += Number(amount);
        })
        newDesc.itemNumAll = detailMaterialAddList && detailMaterialAddList.length
        newDesc.itemNumAll = detailMaterialEditList && detailMaterialEditList.length
        setEditDesc(newDesc);

    }, [detailEditForm, detailMaterialEditList])
    useEffect(() => {
        let newDesc: ISaleDesc = initDesc;
        detailMaterialLookList && detailMaterialLookList.forEach((item, index) => {
            let { num, amount } = item
            newDesc.numAll = bigNumber.add(newDesc.numAll,Number(num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(amount))
            // newDesc.numAll += Number(num);
            // newDesc.totalAll += Number(amount);
        })
        newDesc.itemNumAll = detailMaterialAddList && detailMaterialAddList.length
        newDesc.itemNumAll = detailMaterialLookList && detailMaterialLookList.length
        setLookDesc(newDesc);
    }, [detailLookForm, detailMaterialLookList])


    //新增表单缓存
    const updateAddFormVal = (val: ISaleReturnDetail) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updateEditFormVal = (val: ISaleReturnDetail) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await busBusinesstService.sreturnOne(id);
        const { details, deliveryTime, enterTime, ...from } = retData
        const settleDetails = retData.settleDetails
        const newMaterialDetails = details?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        let cashFileList: any = []
        if (settleDetails && settleDetails.length > 0) {
            const { cashAttach, cashAttachFileName } = settleDetails[0]
            if(cashAttach && cashAttachFileName){
                cashFileList = [
                    {
                        uid: cashAttach,
                        status: 'done',
                        name: cashAttachFileName,
                        response: {
                            retData: cashAttach,
                        },
                    },
                ]
            }
        }
        const newFrom = {
            ...from,
            cashFileList,
            enterTime: enterTime ? moment(enterTime) : null,
            deliveryTime: deliveryTime ? moment(deliveryTime) : null
        }
        if (retData && pattern === "edit") {
            setDetailMaterialEditList(newMaterialDetails as IISaleReturnDetailList[] ?? [])
            setDetailEditForm(newFrom)
            setTopButsControl(false)
        } else if (retData && pattern === "look") {
            setDetailLookForm(newFrom);
            setDetailMaterialLookList(newMaterialDetails as IISaleReturnDetailList[] ?? [])
        }
    }
    //关闭维修用料弹框
    const resetWorkMaterialloadData = async () => {
        let { warehouseCode, customerCode } = searchWorkMaterialData
        setSearchWorkMaterialData({ warehouseCode, customerCode });
        workMaterialloadData(initPgCfg, { warehouseCode, customerCode });
    }
    const workMaterialloadData = async (page?: ISearchPage, searchValue: ISaleDetailSearch = searchWorkMaterialData) => {
        const searchParams = {
            ...searchValue,
            shopId,
            pageSize: page?.pageSize ?? workMaterialPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workMaterialPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await busBusinesstService.saleDetailsPage(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            return {
                ...item,
                num: 1,
                saleNum: item.num,
                amount: item.price
            }
        })
        setWorkMaterialPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setWorkMaterialDataSource(newRecords);
    }
    //筛选维修用料模块详情 详情和新增的列表已有数据的方法
    const modelMaterialScreenMethod = async (modelList: ISaleDetailModel[], detailList: IISaleReturnDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IISaleReturnDetailList[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.detailsId === Item.id);
            let { id, stockId, materialCode, materialName, saleNum, num, price, amount, ...params } = Item
            if (isSelect === undefined) {
                newDetailData.push({
                    ...params,
                    id: "",
                    saleNum,
                    salePrice: price,
                    materialCode,
                    materialName,
                    num,
                    price: price,
                    amount,
                    stockId: stockId,
                    detailsId: id,
                    detailId: id,
                    presaleId: params.presaleId,
                });
            } else {
                repetition.push(Item.materialName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到维修用料详情列表
    const transformsToPoMaterialDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialAddList)
            setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialEditList)
            setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailMaterialDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailMaterialAddList.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (mode === "edit") {
            const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialEditList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialEditDataRowKeys([]);
            setDetailMaterialEditDataRow([]);
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        let { totalAll } = mode === "edit" ? editDesc : addDesc;

        let goodsTotal: number = 0;
        totalAll = +formatNum(totalAll)
        goods.forEach(item => {
            let { amount } = item as { amount: number, num: number };
            goodsTotal += amount
        })
        if (+formatNum(goodsTotal) !== totalAll) {
            message.warning(`详情公交销退应收金额${+formatNum(goodsTotal)}与表单公交销退应收金额不符`);
            return false;
        }
        return true
    }
    //新增预售单
    const insertDetail = useLockFn(async (params: ISaleReturnDetail) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            details: detailMaterialAddList,
            createUser: user.username
        }
        let { retData } = await busBusinesstService.sreturnInsert(newParams)
        return retData
    })
    //编辑预售单
    const editDetail = useLockFn(async (params: ISaleReturnDetail) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            details: detailMaterialEditList,
            updateUser: user.username
        }
        let { retData } = await busBusinesstService.sreturnUpdate(newParams)
        return retData
    })

    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddForm(initForm)
        setDetailMaterialAddList([]);
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailMaterialEditList([]);
    }
    const downloadTpl = async () => {
        let result = await busBusinesstService.saleDownloadTpl();
        convertRes2Blob(result)
    }
    const [wsConfirmModal, setWsConfirmModal] = useState<boolean>(false)
    const [settleVisible, setSettleVisible] = useState<boolean>(false);   //结算弹框
    const warehousing = useLockFn(async () => {
        let { id, returnCode } = detailEditForm as { id: string, returnCode: string }
        let { retData } = await busBusinesstService.sreturnWarehousing({ id, returnCode, storekeeper: user.username })
        return retData
    })
    const onSettle = async (paramas: ISaleReturnettleParams) => {
        let { retData } = await busBusinesstService.sreturnSettle(paramas);
        return retData
    }
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IISaleReturnDetailList>({})
    return {
        initForm,
        materialModel,
        initPgCfg,
        initMaterialSearchParam,
        detailAddForm,
        detailMaterialAddList,
        detailMaterialAddDataRowKeys,
        detailMaterialAddDataRow,
        addDesc,
        detailEditForm,
        detailMaterialEditList,
        detailMaterialEditDataRowKeys,
        detailMaterialEditDataRow,
        editDesc,
        detailLookForm,
        detailMaterialLookList,
        lookDesc,
        workMaterialDataSource,
        searchWorkMaterialData,
        workMaterialPgCfg,
        addDataMaterialSource,
        setMaterialModel,
        setDetailAddForm,
        setDetailMaterialAddList,
        setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        setAddDesc,
        setDetailEditForm,
        setDetailMaterialEditList,
        setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        setEditDesc,
        setDetailLookForm,
        setDetailMaterialLookList,
        setLookDesc,
        setWorkMaterialDataSource,
        setSearchWorkMaterialData,
        setWorkMaterialPgCfg,
        setAddDataMaterialSource,
        updateAddFormVal,
        updateEditFormVal,
        getDetailEditOne,
        resetWorkMaterialloadData,
        workMaterialloadData,
        modelMaterialScreenMethod,
        transformsToPoMaterialDetailList,
        detailMaterialDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        downloadTpl,
        wsConfirmModal, setWsConfirmModal,
        settleVisible, setSettleVisible,
        warehousing,
        onSettle,
        editSettleDetails, setEditSettleDetails,
        lookSettleDetails, setLookSettleDetails,
        topButsControl, setTopButsControl,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        formComputeRule
    }
})