import React, { FC, useEffect, useState } from "react";
import { useDeliveryModel } from "./deliveryModel";
import { YHDrawer } from "@/components/YhDrawer";
import { ColumnsType } from "antd/lib/table";
import { Table, message } from "antd";
import { RouterParamType } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { purchaseRoService } from "@/views/purchase/ro/purchaseRoService";
import { saleSoService } from "@/views/sale/so/saleSoService";
import useGlobalModel from "@/model/globalModel";
export interface IDataSource {
    materialCode?: string,
    materialName?: string,
    num?: string,
}
export const WhConfirmDrawer: FC = ({ }) => {
    const { user, shopAndOrg } = useGlobalModel()
    const { wsConfirmModal: visible, setWsConfirmModal, selectedRow, loadData,setSelectedRowKeys } = useDeliveryModel();
    let { documentNumber, type, id } = selectedRow
    const [dataSource, setDataSource] = useState<IDataSource[]>([])
    const [formWsConfirm, setFormWsConfirm] = useState<object>({})
    const onCancel = () => {
        setWsConfirmModal(false);
    };
    useEffect(() => {
        if (visible) {
            getWhConfirmList()
        }

    }, [visible])
    const getWhConfirmList = async () => {
        let newDetails: any = []
        if (type === "销售单") {
            let { retData } = await saleSoService.one(documentNumber as string)
            let { details, ...from } = retData
            setFormWsConfirm(from)
            newDetails = details?.map(item => {
                let { materialCode, materialName, num } = item
                return { materialCode, materialName, num }
            })
        } else if (type === "采退单") {
            let { retData } = await purchaseRoService.one(documentNumber as string)
            let { details, ...from } = retData
            setFormWsConfirm(from)
            newDetails = details?.map(item => {
                let { materialCode, materialName, num } = item
                return { materialCode, materialName, num }
            })
        }
        setDataSource(newDetails)
    }
    const onSubmit = async () => {
        let { id, saleCode, returnCode } = formWsConfirm as { id: string, saleCode: string, returnCode: string }

        if (type === "销售单") {
            let { retData } = await saleSoService.warehousing({ id, saleCode, storekeeper: user.username })
            if (retData) {
                onCancel()
                loadData()
                message.success("出库成功")
                setSelectedRowKeys([])
            }
        } else if (type === "采退单") {
            let { id, saleCode } = formWsConfirm as { id: string, saleCode: string }
            let { retData } = await purchaseRoService.warehousing({ id, returnCode, storekeeper: user.username })
            if (retData) {
                onCancel()
                loadData()
                message.success("出库成功")
                setSelectedRowKeys([])
            }
        }
        // let retData = await warehousing()
        // if (retData) {
        //     await onCancel();
        //     // await getDetailEditOne(id, mode);
        //     await globalPrompt("message", { text: "出库成功", type: "success" });

        // } else {
        //     await onCancel()
        //     await globalPrompt("message", { text: "出库失败", type: "error" });
        // }
    }

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            dataIndex: "index",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        },
        {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '出库数量',
            width: 160,
            dataIndex: 'num',
        }
    ];
    return (
        <YHDrawer
            {...{
                title: '库房确认',
                visible,
                onCancel,
                width: 800,
                okText: "保存",
                onOk: onSubmit,
                // showFooter: mode === "look" ? false : true

            }}
        >
            <Table
                // size="small"
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 400 }}
            />
        </YHDrawer>
    )
}