import React from "react";
import { usePoListModel } from "../poListModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = usePoListModel();
    const { getSelectedDictOne } = useDictModel(() => []);

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "供应商名称",
            fieldName: "supplierName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "采购单号",
            fieldName: "code"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单员",
            fieldName: "createUser"
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["settlementStartTime", "settlementEndTime"],
            placeholder: ["结算起始日期", "结算结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },

    ]
    const searchMoreFields = [
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "采购类型",
        //     fieldName: "purchaseTypeCode",
        //     options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TYPE)
        // },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.PAYEE_TYPE)
        },        
        {
            type: SearchTypeEnum.Select,
            placeholder: "供应商类型",
            fieldName: "supplierTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.SUPPLIER_TYPE)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据类型",
            fieldName: "tradeTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE)?.filter(
                item => `${item.value}`.indexOf("purchase-return") > -1 || `${item.value}`==='trade-type.purchase'
                ) ?? []
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["startTime", "endTime"],
            placeholder: ["开单起始日期", "开单结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },        

    ]
    return (
        <YhPageHeader
            title="采购单报表"
            size="small"
            // justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}