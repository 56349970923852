import React, { useEffect } from "react";
import { Button, Upload } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useCustomerModel } from "../baseCustomerModel";
import { useNavModel } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedData, selectedRowKeys, updateEditFormVal, loadData, exportCustomer, downloadTpl, setSelectedRowKeys } = useCustomerModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let customerEditKey = "13-5-1-2";
        let isFindNav = findNav(customerEditKey);
        if (!isFindNav) {
            addNav(customerEditKey, { id: selectedRowKeys[0], mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "客户跳转详情",
                type: "warning",
                text: "您还有客户详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(customerEditKey, { id: selectedRowKeys[0], mode: "edit" });
                    updateEditFormVal(null);
                }
            })
        }
    }
    const goVehicle = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行车辆", type: "warning" });
            return;
        }
        replaceNav(
            selectedNavId,
            "13-5-2",
            {
                id: "",
                mode: "add",
                parentInfo: {
                    customerName: selectedData?.name,
                    selectedNavId,
                }
            },
            true
        )
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "13-5-1-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRowKeys[0] as string, mode: "look", },
            true
        );
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_C_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { addNav("13-5-1-1", { id: "", mode: "add" }) }}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_C_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_C_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_C_IM}>
            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/customer/import`, loadData)}>
                <Button className="mr-5" type="link" shape="round">导入</Button>
            </Upload>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_C_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { exportCustomer() }}
            >导出</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_C_C_DLOAD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { downloadTpl() }}
            >下载模板</Button>
        </YhAuth>
        {/* <YhAuth resCode={ResourceAuthEnum.DATA_C_C_VEHICLE}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => goVehicle()}
            >下属车辆</Button>
        </YhAuth> */}
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { transferCustomer("id").then() }}
        >转成供应商</Button> */}
    </>;

    return (
        <YhBox title="客户列表" action={action}>
            <DataTable />
        </YhBox>
    )
}
