import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useStockOutDispatchModel } from "../stockOutDispatchModel";
import { IStockDeliveryDetail } from "../stockOutDispatchType"
import { momentFormat } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import useGlobalModel, { getFindItem } from "@/model/globalModel";
import useNavModel from "@/model/navModel";

type statusType = {
    "maintenance.return-visit": string, //已回访
    "maintenance.write-off": string, //已结款
    "maintenance.underway": string,//在修
    "maintenance.await": string,//待修
    "maintenance.trans": string,//已转接
    "maintenance.stock-io": string,//已出库
    "maintenance.settle": string,//已结算
    "maintenance.create": string,//已开单
    "maintenance.change": string,//已作废
    // "maintenance.create": string,    
    "maintenance.completed": string,
    [key: string]: any
}
const statusColor: statusType = {
    "maintenance.return-visit": 'lime', //已回访
    "maintenance.write-off": "green", //已结款
    "maintenance.underway": "geekblue",//在修
    "maintenance.await": "purple",//待修
    "maintenance.trans": "cyan",//已转接
    "maintenance.stock-io": "magenta",//已出库
    "maintenance.settle": "green",//已结算
    "maintenance.create": "blue",//已开单
    "maintenance.change": "red",//已作废
    "maintenance.completed": "green"//已完工,
}

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useStockOutDispatchModel();
    const { getTreeNodeName } = useDictModel();
    const { technicianData } = useGlobalModel()
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "9-3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const columns: ColumnsType<IStockDeliveryDetail> = [
        {
            title: '序号',
            width: 80,
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 160,
            dataIndex: 'maintenanceCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(text)}>{text}</p></Tooltip>,
        }, {
            title: '单据状态',
            width: 120,
            dataIndex: 'status',
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '出库数量',
            width: 120,
            dataIndex: 'deliveryNum',
        }, {
            title: '客户名称',
            width: 160,
            dataIndex: 'customerName',
        }, {
            title: '车牌号',
            width: 160,
            dataIndex: 'licensePlate',
        }, {
            title: 'VIN码',
            width: 160,
            dataIndex: 'vin',
        }, {
            title: '领料人',
            width: 160,
            dataIndex: 'technicianNames',
            // render: (text) => getFindItem(text, technicianData)?.name ?? text
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '出库日期',
            width: 160,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '所属门店',
            width: 160,
            dataIndex: 'shopName',
        }
    ];

    useEffect(() => {
        loadData().then();
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IStockDeliveryDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
