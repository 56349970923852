import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import { useState, Key, useEffect } from "react";
import {
    IStockCheckFields,
    IDetailList,
    IInvalidateAndComplete,
} from "../../stockCheckType"
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { stockCheckService } from "../../stockCheckService"
import { IStockStatisticSearch, IStockStatisticDetail, stockStatisticService } from "@/views/stock/statistic/stockStatisticService";
import moment from "moment";
import { momentFormat, transPgToPagination, formatNum } from "@/utils/utils";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { globalPrompt } from "@/components/message";
import { DictCodeEnmu, sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { saleOrderService } from '@/views/sale/order/saleOrderService';
import { IPurchaseStock } from '@/views/sale/order/saleOrderTypes';

export type IStockCheckDescType = {
    stockAll: number,
    checkAll: number,
    SurplusNumAll: number,
    deficitNumAll: number,
    differenceNumAll: number,
    differenceMoneyAll: number,
    differenceRateAll: number,
    differenceRateNumAll: number,
    totalAll: number,
    [key: string]: any
}
export interface IMaterialDetailModel extends IPurchaseStock {
    num: number,
}
export const useStockCheckDetailModel = createModel(function () {
    const { user, shopAndOrg } = useGlobalModel()
    const { shopName } = shopAndOrg

    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)  //新增商品弹框
    const initDesc = {
        stockAll: 0,   //库存数量
        checkAll: 0,   //盘点数量
        SurplusNumAll: 0,   //盘盈项数
        deficitNumAll: 0,   //盘亏项数
        differenceNumAll: 0,   //差异数量
        differenceMoneyAll: 0,   //差异金额
        differenceRateAll: 0,   //差异率
        differenceRateNumAll: 0, //数量差异率
        totalAll: 0, //系统库存总金额
    }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initSearchParam: object = {
        createUser: user.username,
        shopName
    };
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IStockCheckFields>(initSearchParam)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState<IDetailList[]>([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState<Key[]>([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState<IDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<IStockCheckDescType>(initDesc)


    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IStockCheckFields>({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState<IDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<IStockCheckDescType>(initDesc)

    //详情数据
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState<IDetailList[]>([]);
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState<Key[]>([]);
    const [detailDataRow, setDetailDataRow] = useState<IDetailList[]>([]);
    const [detailEditForm, setDetailEditForm] = useState<IStockCheckFields>({})
    const [editDesc, setEditDesc] = useState<IStockCheckDescType>(initDesc)

    //退货配件弹框配置
    const [workDataSource, setWorkDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkData, setSearchWorkData] = useState<IStockStatisticSearch>(initSearchParam)
    const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [addDataSource, setAddDataSource] = useState<IMaterialDetailModel[]>([]);

    //判断盘盈盘亏
    const setProfitAndLoss = (differ: number) => {
        if (differ === 0) return "相符"
        if (differ > 0) return "盘盈"
        if (differ < 0) return "盘亏"
    }
    const setProfitAndLossForm = (differ: number) => {
        console.log(differ)
        if (differ === 0) return "相符"
        if (differ > 0) return "盘盈"
        if (differ < 0) return "盘亏"
    }
    useEffect(() => {
        let newDesc: IStockCheckDescType = { ...initDesc };
        detailAddList?.forEach(item => {
            newDesc.stockAll += Number(item.inventoryNum);
            newDesc.checkAll += Number(item.realNum);
            newDesc.SurplusNumAll += item.result === "盘盈" ? 1 : 0;
            newDesc.deficitNumAll += item.result === "盘亏" ? -1 : 0;
            newDesc.differenceNumAll += Number(item.differenceNum);
            newDesc.differenceMoneyAll += Number(item.differenceAmount);
            newDesc.totalAll += Number(item.inventoryNum) * Number(item.averagePurchasePrice);
        });
        newDesc.differenceRateAll = (newDesc.differenceMoneyAll) / newDesc.totalAll * 100;
        newDesc.differenceRateNumAll = (newDesc.differenceNumAll / newDesc.checkAll) * 100
        setAddDesc(newDesc);
    }, [detailAddList])

    useEffect(() => {
        let newDesc: IStockCheckDescType = { ...initDesc };
        detailDataSource?.forEach(item => {
            newDesc.stockAll += Number(item.inventoryNum);
            newDesc.checkAll += Number(item.realNum);
            newDesc.SurplusNumAll += item.result === "盘盈" ? 1 : 0;
            newDesc.deficitNumAll += item.result === "盘亏" ? -1 : 0;
            newDesc.differenceNumAll += Number(item.differenceNum);
            newDesc.differenceMoneyAll += Number(item.differenceAmount);
            newDesc.totalAll += Number(item.inventoryNum) * Number(item.averagePurchasePrice);
        });
        newDesc.differenceRateAll = (newDesc.differenceMoneyAll) / newDesc.totalAll * 100;
        newDesc.differenceRateNumAll = (newDesc.differenceNumAll / newDesc.checkAll) * 100
        setEditDesc(newDesc);
    }, [detailDataSource])

    useEffect(() => {
        let newDesc: IStockCheckDescType = { ...initDesc };
        detailLookList?.forEach(item => {
            newDesc.stockAll += Number(item.inventoryNum);
            newDesc.checkAll += Number(item.realNum);
            newDesc.SurplusNumAll += item.result === "盘盈" ? 1 : 0;
            newDesc.deficitNumAll += item.result === "盘亏" ? -1 : 0;
            newDesc.differenceNumAll += Number(item.differenceNum);
            newDesc.differenceMoneyAll += Number(item.differenceAmount);
            newDesc.totalAll += Number(item.inventoryNum) * Number(item.averagePurchasePrice);
        });
        newDesc.differenceRateAll = (newDesc.differenceMoneyAll) / newDesc.totalAll * 100;
        newDesc.differenceRateNumAll = (newDesc.differenceNumAll / newDesc.checkAll) * 100
        setLookDesc(newDesc);
    }, [detailLookList])

    //新增表单缓存
    const updateAddFormVal = (val: IStockCheckFields) => {
        let newVal = val
        if ("startTime" in val) {
            const startTime = val.startTime
            const endTime = detailAddForm.endTime
            newVal = !!!endTime ? { ...val } : { ...val, duration: moment.duration(endTime.diff(startTime)).days() + "天" }
        }
        if ("endTime" in val) {
            const endTime = val.endTime
            const startTime = detailAddForm.startTime
            newVal = !!!startTime ? { ...val, } : { ...val, duration: moment.duration(endTime.diff(startTime)).days() + "天" }
        }
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val: IStockCheckFields) => {
        let newVal = val
        if ("startTime" in val) {
            const startTime = val.startTime
            const endTime = detailEditForm.endTime
            newVal = !!!endTime ? { ...val } : { ...val, duration: moment.duration(endTime.diff(startTime)).days() + "天" }
        }
        if ("endTime" in val) {
            const endTime = val.endTime
            const startTime = detailEditForm.startTime
            newVal = !!!startTime ? { ...val, } : { ...val, duration: moment.duration(endTime.diff(startTime)).days() + "天" }
        }
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //根据id查询一条编辑或查看得数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await stockCheckService.one(id);
        const { details, startTime, endTime, ...from } = retData
        let newfrom = {
            ...from,
            startTime: startTime ? moment(startTime) : null,
            endTime: endTime ? moment(endTime) : null
        }
        let newDetails = details?.map(item => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        if (retData && pattern === "edit") {
            console.log(newfrom, pattern)
            setDetailEditForm(newfrom)
            setDetailDataSource(newDetails as IDetailList[] ?? [])
        } else if (retData && pattern === "look") {
            setDetailLookForm(newfrom);
            setDetailLookList(newDetails as IDetailList[] ?? [])
        }
    }
    //关闭弹框
    const resetWorkloadData = async (params: IStockCheckFields) => {
        setSearchWorkData({});
        workloadData(initPgCfg, { ...params });
    }
    //加载库存统计数据    
    const workloadData = async (page?: ISearchPage, searchValue: IStockStatisticDetail = searchWorkData) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            status:`${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            pageSize: page?.pageSize ?? workPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        // let { retData } = await saleOrderService.stock(searchParams);
        // const { records, ...pg } = retData;
        let { retData } = await saleOrderService.newStock(searchParams);
        let { list } = retData[0]
        let { records, ...pg } = list
        let newPg = { ...pg, pageNum: pg.current }
        const newRecords = records.map(item => {
            return {
                ...item,
                num: 0,
                averagePurchasePrice: item.latestPurPrice,// item.averagePurchasePrice !== 0 ? item.averagePurchasePrice : item.purchasePrice,
                amount: item.latestPurPrice as number * 0,
            }
        })
        setWorkPgCfg(transPgToPagination(newPg));
        setWorkDataSource(newRecords);
    }
    //筛选模块详情 详情和新增的列表已有数据的方法
    const modelScreenMethod = async (modelList: IMaterialDetailModel[], detailList: IDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: any[] = []
        modelList.forEach(Item => {
            let { inventoryNum, num, averagePurchasePrice, realNum, differenceNum, differenceAmount, result } = Item
            let isSelect = detailList?.find(item => item.materialCode === Item.materialCode);
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    realNum: realNum ?? num,
                    differenceNum: differenceNum ?? num - Number(inventoryNum),
                    result: result ?? setProfitAndLoss(num - Number(inventoryNum)),
                    detailsId: Item.id,
                    stockId: Item.id,
                    id: "",
                    differenceAmount: differenceAmount ?? ((num - Number(inventoryNum)) * Number(averagePurchasePrice)).toFixed(2),
                    scrapNum: 0,
                    scrapAmount: 0,
                });
            } else {
                repetition.push(Item.materialName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到详情列表
    const transformsToRoDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList)
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length !== 0 && globalPrompt("message", { text: `商品名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            console.log()
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailDataSource)
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length !== 0 && globalPrompt("message", { text: `商品名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
    }
    //删除详情列表数据
    const detailDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetaiAddDataRowKeys([]);
            setDetaiAddDataRow([])
        } else if (mode === "edit") {
            const backendDetailList: any = detailDataRow.reduce((all: object[], next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.detailsId))
                // return console.log(filterDetailList)
                setDetailDataSource(filterDetailList)
                globalPrompt("message", { text: `删除成功`, type: "success" });
                setDetailDataRowKeys([]);
                setDetailDataRow([])
            }
            if (backendDetailList.length > 0) {
                await stockCheckService.materialDelete({ ids: backendDetailList }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        globalPrompt("message", { text: `删除失败`, type: "success" });
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    //新增采购单
    const insertDetail = async (params: IStockCheckFields) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            createUser: user.username,
            differenceRateAll: formatNum(addDesc.differenceRateAll),
            startTime: params.startTime ? momentFormat(params.startTime) : null,
            endTime: params.endTime ? momentFormat(params.endTime) : null,
            details: detailAddList
        }
        let { retData } = await stockCheckService.insert(newParams);
        return retData;
    }
    //编辑采购单
    const editDetail = async (params: IStockCheckFields) => {
        // momentFormat 
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            differenceRateAll: formatNum(editDesc.differenceRateAll),
            startTime: params.startTime ? momentFormat(params.startTime) : null,
            endTime: params.endTime ? momentFormat(params.endTime) : null,
            details: detailDataSource
        }
        let { retData } = await stockCheckService.update(newParams);
        return retData;
    }
    //下载模板
    const onExport = async () => {
        let result = await stockCheckService.downloadTpl();
        convertRes2Blob(result);
    }
    const onComplete = async (params: IInvalidateAndComplete) => {
        let { retData } = await stockCheckService.complete(params);
        return retData
    }
    const onFinalAudit = async (params: IInvalidateAndComplete) => {
        let { retData } = await stockCheckService.finalAudit(params);
        return retData
    }
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type: string) => {
        setDetailAddForm({})
        setDetailAddList([]);
    }
    const resetEditCatch = async (type: string) => {
        setDetailEditForm({});
        setDetailDataSource([]);
    }
    return {
        addModalVisible,
        detailAddForm,
        detailAddList,
        detaiAddDataRowKeys,
        detaiAddDataRow,
        detailLookForm,
        detailLookList,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        addDesc,
        editDesc,
        lookDesc,
        initPgCfg,
        setAddModalVisible,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRowKeys,
        setDetaiAddDataRow,
        setDetailLookForm,
        setDetailLookList,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        setAddDesc,
        setLookDesc,
        setEditDesc,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        transformsToRoDetailList,
        detailDetailList,
        insertDetail,
        editDetail,
        resetWorkloadData,
        workloadData,
        setProfitAndLoss,
        modelScreenMethod,
        onExport,
        resetAddCatch,
        resetEditCatch,
        onComplete,
        setProfitAndLossForm,
        onFinalAudit
    }
})