import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { DispatchDrawer } from "./DispatchDrawer"
export default () => {
    return (
        <>
            <PageHeader />
            <DataBox />
            <DispatchDrawer />
        </>
    )
}