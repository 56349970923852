import React, { useRef, useState, useEffect } from "react";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from "@/components/YhFormBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IItemDetail } from "../itemType";
import { getFormValidatorErrText } from "@/utils/utils"
import { useItemModel } from "../itemModel";
import { DetailType } from "@/types/ScmEnums";
import { useDictModel } from "../../dict/dictModel";
import { DictCodeEnmu, sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { debounce } from "lodash";

export const BaseItemDetails = () => {
    const {
        addFormVal,
        editFormVal,
        lookFormVal,
        initFormVal,
        updateAddFormVal,
        updateEditFormVal,
        insertDetail,
        editDetail,
        getDetailOne,
        setAddFormVal,
        setSelectedRowKeys
    } = useItemModel();
    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const { shopAndOrg, user } = useGlobalModel()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel();
    const formRef = useRef<IYhFormRef>();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { id, mode, parentInfo } = detailsInfo

    //编辑时， 没有缓在调用详情接口
    useEffect(() => {
        if (!Object.keys(editFormVal).length && mode === "edit") {
            getDetailOne(id, mode)
        }
        mode === "look" && getDetailOne(id, mode);
    }, [id, mode]);

    useEffect(() => {
        return () => {
            let addNavExist = findNav("13-6-1");
            let editNavExist = findNav("13-6-2");
            !addNavExist && updateAddFormVal({ ...initFormVal });
            !editNavExist && updateEditFormVal({});
        }
    }, [selectedNavId])

    //关闭当前页
    const onClose = () => {
        if (parentInfo) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "13-6");
            detailsInfo.mode === "edit" ? updateEditFormVal({}) : updateAddFormVal({ ...initFormVal });
        }

    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            let newRes = {
                ...res,
                ...shopAndOrg,
                ...sourceAppOrBranch,
                enable: res.enable ? 1 : 0,
                createUser: user.username,
                typeName: getTreeNodeName(res.typeCode)
            }
            if (detailsInfo.mode === "edit") {
                editDetail({ ...newRes, id: detailsInfo.id } as IItemDetail).then(res => {
                    res && onClose()
                })
            } else {
                insertDetail(newRes as IItemDetail).then(res => {
                    setSelectedRowKeys([res as string])
                    res && onClose()
                })
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    //实时保留缓存
    const onChange = (changedValues: { [key: string]: any }) => {
        detailsInfo.mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues);
    }

    const baseFormItem: IYhFormItemsBox = {
        title: "基本信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "项目编号",
                fieldName: "code",
                rules: [{ required: true, message: "请输入项目编号" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "项目名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入项目名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工时",
                fieldName: "workHours",
                rules: [{ required: true, message: "请输入工时" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "单价",
                fieldName: "price",
                rules: [{ required: true, message: "请输入单价" }]

            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工时费",
                fieldName: "amount",
                disable: true,
                rules: [{ required: true, message: "请输入工时费" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "维修类型",
                fieldName: "typeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.REPAIR_TYPE)
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note"
            }, {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "状态",
                fieldName: "enable",
            }
        ]
    };

    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal
    }
    const typeTitle = {
        add: "新增",
        edit: "编辑",
        look: "查看",
    }
    const supplierFromConfig: IYhFormBox = {
        formRef,
        labelCol: { span: 6 },
        disabled: mode === DetailType.look,
        labelAlign: "right",
        // formValues: detailsInfo.mode === "edit" ? editFormVal : addFormVal,
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }

    const reset = async () => {
        formRef.current?.resetFields()
        if (detailsInfo.mode === "add") {
            setAddFormVal({})
        } else if (detailsInfo.mode === "edit") {
            getDetailOne(detailsInfo.id, mode)
        }
        message.success("重置成功")
    }
    const action = <>
        {mode != "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={debounce(() => { onSubmit() }, 900)}
        >保存</Button>}
        {mode != "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => reset()}
        >重置</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onClose}
        >取消</Button>
    </>;

    return (
        <>
            {/* <YhPageHeader title="维修项目档案详情" /> */}
            <YhBox title={`维修项目档案${typeTitle[mode]}`} action={action} style={{ paddingBottom: "0px", marginBottom: "15px" }}></YhBox>
            <YhFormBox {...supplierFromConfig} />
        </>
    )
}