import React, { useState, FC, useEffect } from "react";
import { Button, message } from "antd";
import { YHModal } from "@/components/YHModal";
import { usePsiModel } from "../../psiModel";
import { useGlobalModel } from "@/model/globalModel";
import { DetailsTable } from "./detailsTable";
import { IremainDetail, psiService } from "../../psiService";
import { convertRes2Blob } from "@/utils/exportFile";


// 进销存type io_spd采购明细 io_ssd销售明细(出库）occupy_ssd销售明细（占用）io_sprd采购退货明细 io_ssrd销售退货明细io_vmm维修物料明细(出入库)occupy_vmm维修物料明细(占用)io_vrm维修物料退货明细occupy_vam维修预约
//进销存剩余数
export const RemainNumModal = () => {
	const { shopAndOrg, setLoadingInfo } = useGlobalModel();
	const {
		goodsRecordModal: { remainVisible: visible, row, type, title, field }, onCancel
	} = usePsiModel();
	const [recordType, setRecordType] = useState<string>("io_spd");
	const [dataSource, setDataSource] = useState<IremainDetail[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (visible && row) {
			loadData()
		}
	}, [visible])

	const loadData = async (str: string = type === "all" ? "io_spd" : type) => {
		setLoading(true);
		setRecordType(str)
		try {
			const searchParams = {
				shopId: shopAndOrg.shopId,
				materialCode: row?.materialCode,
				types: str.split(","),
			}
			let { retData } = await psiService.purchaseSaleStorageDeatil(searchParams);
			setDataSource(retData ?? []);
			setLoading(false)
		} catch {
			setLoading(false);
			setDataSource([])
		}
	}

	//导出
	const exportDetail = async () => {
		if (!dataSource.length) {
			message.warning("无数据");
			return;
		}
		setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
		try {
			const searchParams = {
				shopId: shopAndOrg.shopId,
				materialCode: row?.materialCode,
				types: recordType.split(","),
			}
			let result = await psiService.purchaseSaleStorageDeatilExport(searchParams);
			result && setLoadingInfo({ loadingVisible: false, txt: "" });
			convertRes2Blob(result);
		} catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
	}


	const classname = (type: string) => {
		return 'tab-item ' + (recordType === type ? 'tab-active' : '')
	}

	return <YHModal
		visible={visible}
		onCancel={onCancel}
		title={<p><span>{title}:{row?.[field as string]}</span><span className="ml-20">商品编号:{row?.materialCode}</span><span className="ml-20">商品名称:{row?.materialName}</span></p>}
		width={1300}
		bodyHeight={550}
	>
		{type === "all" ? <div style={{ margin: "0 0 10px 10px" }}>
			<Button
				className={classname("io_spd")}
				type="link"
				shape="round"
				onClick={() => loadData("io_spd")}
			>采购明细</Button>
			<Button
				className={classname("io_sprd")}
				type="link"
				shape="round"
				onClick={() => loadData("io_sprd")}
			>采退明细</Button>
			<Button
				className={classname("io_ssd")}
				type="link"
				shape="round"
				onClick={() => loadData("io_ssd")}
			>销售明细</Button>
			<Button
				className={classname("io_ssrd")}
				type="link"
				shape="round"
				onClick={() => loadData("io_ssrd")}
			>销退明细</Button>
			<Button
				className={classname("io_vmm")}
				type="link"
				shape="round"
				onClick={() => loadData("io_vmm")}
			>维修明细</Button>
			<Button
				className={classname("io_vrm")}
				type="link"
				shape="round"
				onClick={() => loadData("io_vrm")}
			>维退明细</Button>
		</div > : <></>}

		<DetailsTable recordType={recordType} dataSource={dataSource} loading={loading} exportDetail={exportDetail} {...row} />
	</YHModal>
}