import React, { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useBillModel } from "./billModel";
import { IBillDetail } from "../../../../../bill/BillTypes";
import { useDetailModel } from "../detailModel";
import { billStatusRender, ioFlagRender } from "@/types/FmsRender";
import { TradeTypeEnum } from "@/types/FmsEnums";



export function BillDataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useBillModel();

    const {
        payMethodLabels,
    } = useDetailModel()
    const columns: ColumnsType<IBillDetail> = [
        {
            align: "center",
            title: '分支',
            dataIndex: 'branch',
        },
        {
            align: "center",
            title: '单据编码',
            dataIndex: 'code',
        },
        {
            align: "center",
            title: '账户名称',
            dataIndex: 'accountName',
        },
        {
            align: "center",
            title: '单据类型',
            dataIndex: 'tradeTypeName',
        },
        {
            align: "center",
            title: '实体',
            dataIndex: 'beName',
        },
        {
            align: "center",
            title: '支付类型',
            dataIndex: 'payType',
            render: v => payMethodLabels[v]
        },
        {
            align: "center",
            title: '优惠类型',
            dataIndex: 'preferentialTypeName',
        },
        {
            align: "center",
            title: '收支类型',
            dataIndex: 'ioFlag',
            render: ioFlagRender
        },
        {
            align: "center",
            title: '收款状态',
            dataIndex: 'status',
            render: billStatusRender
        },
        {
            align: "center",
            title: '从属机构',
            dataIndex: 'orgName',
        },
        {
            align: "center",
            title: '供应商名称',
            dataIndex: 'partnerName',
        },
        {
            align: "center",
            title: '记账日期',
            dataIndex: 'createTime',
        },
        {
            align: "center",
            title: '业务员',
            dataIndex: 'tradeOperator',
        },
        {
            align: "center",
            title: '单据金额',
            dataIndex: 'billAmount',
            render: (text, record) => (
                record.tradeTypeCode as any === TradeTypeEnum.SALE_RETURN ||
                record.tradeTypeCode as any === TradeTypeEnum.PURCHASE_RETURN) ? Math.abs(text) : text
        },
        {
            align: "center",
            title: '核销日期',
            dataIndex: 'writeoffTime',
        },
        {
            align: "center",
            title: '核销人',
            dataIndex: 'writeoffUser',
        },
        {
            align: "center",
            title: '备注',
            dataIndex: 'note',
        },
    ];
    return (
        <Table
            rowSelection={{
                type: "radio",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IBillDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => {
                return {
                    onClick: event => {
                        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
                            setSelectedRowKeys([]);
                        } else {
                            setSelectedRowKeys([record.key]);
                        }
                    },
                };
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}
