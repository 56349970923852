import React, { useRef } from "react";
import { useBillModel } from "./billModel";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { accountService } from "../../../../AccountService";
import { message } from "antd";

export const WriteoffDrawer = () => {

    const {
        selectedData,
        onReset,
        writeoffDrawerVisible,
        setWriteoffDrawerVisible
    } = useBillModel(model => [model.writeoffDrawerVisible]);

    const formRef = useRef<IYhFormRef>();
    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        disabled: false,
        formValues: { ...selectedData },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户编码",
                fieldName: "accountCode",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "核销金额",
                fieldName: "billAmount",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户实体",
                fieldName: "beName",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账单编码",
                fieldName: "code",
                disable: true,
                span: 24,
            },
        ]
    };
    const onOk = async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const reqData = {
                    billCode: formData.code,
                    writeoffType: 0,
                };
                let insertRet = await accountService.writeoff(reqData);
                if (insertRet.success) {
                    message.success("核销成功!");
                    onReset();
                    setWriteoffDrawerVisible(false);
                } else {
                    message.error("核销失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log("数据校验失败");
        }
    }
    const onCancel = () => {
        setWriteoffDrawerVisible(false);
    };

    return (
        <YHDrawer
            {...{
                title: "核销账单",
                visible: writeoffDrawerVisible,
                onOk,
                onCancel,
                showFooter: true
            }}
        >
            <YhForm formRef={formRef} {...formConfig} />
        </YHDrawer>
    )
}
