import { ISearchPage } from '@/types/ScmTypes'

export enum StatusEnum {
    processing = '进行中',
    success = '完成',
    fail = '失败',
}



//搜索条件
export interface IExportRecordSearchFields {
    fileName?: string
    menu?: string
    status?: string

    [key: string]: any
}

export interface IExportRecordSearch extends ISearchPage {
    searchItem: IExportRecordSearchFields
    orders: any[]
}

export interface IExportRecordDetail {
    id: string

    // 系统名称
    appName?: string
    // 用户code
    userCode?: string
    //用户名称
    userName?: string
    // 菜单
    menu?: string
    // 文件名称
    fileName: string
    // 查询参数
    queryParams: string
    // 上传到oss的返回值
    ossResult?: string
    // 状态
    status: string
    // 失败原因
    failMsg: string
    // 开始时间
    startTime: string
    // 结束时间
    endTime: string
    // 备注
    remark: string
    // 下载地址
    downloadUrl: string
}
