import { useState, } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { stockEntryService } from "../../stockEntryService"
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import {
    IDetailsList,
    IStockEntryField,
} from "../../stockEntryType"
export const useEntryDetailsModel = createModel(function () {
    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IStockEntryField>({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState<IDetailsList[]>([])
    const getDetailLookOne = async (id: string, pattern: string) => {
        let { retData } = await stockEntryService.one(id);
        const { details, ...from } = retData
        setDetailLookForm(from);
        setDetailLookList(details as IDetailsList[])
    }
    return {
        detailLookForm,
        detailLookList,
        setDetailLookForm,
        setDetailLookList,
        getDetailLookOne
    }
})