import React from "react";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";
import useGlobalModel from "@/model/globalModel";

export const FixedBottom = () => {
    const { cm } = useGlobalModel();
    const orderStatistics: IDescription[] = [
        {
            label: "工时小计",
            value: "2500（2项）",
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "配件小计",
            value: "2500（2项）",
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "优惠总金额",
            value: "2500",
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "费用合计",
            value: "2500（2项）",
            color: DescriptionColorEnum.red,
            status: true
        }
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${cm.collapsed ? '80px' : '226px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}