import React from "react";
import { YhForm } from "@/components/YhFormBox";
import { FormItemTypeEnum, IYhForm } from "@/components/YhFormBox/Types";
import { useOrgModel } from "../orgModel";

const branchDs = [
    { label: "财务分支", value: "fms" },
    { label: "主分支", value: "main" },
    { label: "供应链分支", value: "scm" },
    { label: "云修分支", value: "vrm" },
]

export const FormBox = () => {
    const { selectedNodes } = useOrgModel();

    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        formValues: selectedNodes[0],
        disabled: true,
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "分支",
                fieldName: "branch",
                ds: branchDs,
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "从属机构",
                fieldName: "pcode",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "版本",
                fieldName: "ver",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "编码",
                fieldName: "code",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "名称",
                fieldName: "name",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系地址",
                fieldName: "address",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 18,
            }, {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "启用",
                fieldName: "enable",
                span: 18,
            },
        ]
    };


    return (
        <>
            <div className="dict-list-header">
                {/* <Button type="link" shape="round" onClick={onSubmit}>保存</Button>
                <Button type="link" shape="round" onClick={() => setSelectedNodes([])}>取消</Button> */}
            </div>
            <div className="dict-from-content">
                <YhForm {...formConfig} />
            </div>
        </>
    )
}