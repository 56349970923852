import React, { useEffect, useState } from "react";
import { Table, Switch } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAdjustModel } from "./adjustModel";
import { IAccountAdjustDetail } from "../../../../AccountTypes";
import { httpRequest } from "@/utils/HttpRequest";
import { convertRes2Blob } from "@/utils/exportFile";
import PreviewModal from "@/components/YhPreview/index";
import { SnippetsOutlined } from "@ant-design/icons";
import { creditFlagRender } from "@/utils/utils";

type IPreviewInfoType = {
    visible: boolean,
    md5: string,
    fileName: string,
    filePath: string
    fileType: string
}


export function AdjustDataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useAdjustModel();
    // 预览附件内容
    const [previewInfo, setPreviewInfo] = useState<IPreviewInfoType>({
        visible: false,
        md5: "",
        fileName: "",
        filePath: "",
        fileType: ""
    })
    // 打开附件弹框
    const onPreview = async (item: IAccountAdjustDetail) => {
        console.log("附件预览", item);
        let fileMd5 = item?.md5
        let fileName = item?.fileName as string
        let fileType = fileName?.substring(fileName.lastIndexOf(".") + 1) ?? 'png'
        let filePath = `https://file-server.laoniuche.cn/file/fetch?id=${fileMd5}`
        if (fileType === 'docx') {
            const file = await httpRequest
                .postFile(filePath);
            return convertRes2Blob(file);
        }
        setPreviewInfo({
            visible: true,
            md5: fileMd5 as string,
            fileName,
            filePath,
            fileType
        })
    }

    // 关闭附件弹框
    const onCancel = () => {
        setPreviewInfo({ visible: false, md5: "", fileName: "", filePath: "", fileType: "" })
    }

    const columns: ColumnsType<IAccountAdjustDetail> = [
        {
            align: "center",
            title: '分支',
            dataIndex: 'branch',
        },
        // {
        //     title: '编码',
        //     dataIndex: 'code',
        // },
        // {
        //     title: '账户编码',
        //     dataIndex: 'accountCode',
        // },
        {
            align: "center",
            title: '账户名称',
            dataIndex: 'accountName',
            render: (t, r) => r?.md5 === null
                ? <span>{t}</span>
                : <span style={{ position: "relative" }}>
                    {t}
                    <i style={{ position: "absolute", right: "-15px", top: "-3px", color: "#5200ff" }} onClick={() => onPreview(r)}>
                        <SnippetsOutlined />
                    </i>
                </span>
        },
        {
            align: "center",
            title: '授信类型',
            dataIndex: 'creditFlag',
            render: (v) => creditFlagRender(v)
        },
        {
            align: "center",
            title: '从属机构',
            dataIndex: 'orgName',
        },
        {
            align: "center",
            title: '账户实体',
            dataIndex: 'beName',
        },
        {
            align: "center",
            title: '授信额度',
            dataIndex: 'creditAmount',
        },
        {
            align: "center",
            title: '调整额度',
            dataIndex: 'adjustAmount',
        },
        {
            align: "center",
            title: '业务员',
            dataIndex: 'createUser',
        },
        {
            align: "center",
            title: '调额日期',
            dataIndex: 'createTime',
        },
        // {
        //     title: '客户类型',
        //     dataIndex: 'partnerTypeCode',
        // },
        // {
        //     title: '客户编码',
        //     dataIndex: 'partnerCode',
        // },
        // {
        //     title: '客户名称',
        //     dataIndex: 'partnerName',
        // },

        {
            align: "center",
            title: '审核状态',
            dataIndex: 'status',
            render: (v) =>
                v === 0 ? '初始'
                    : v === 1 ? '审核中'
                        : v === 2 ? '审核通过(已调整)'
                            : v === 8 ? '审核驳回'
                                : v === 9 ? '失败'
                                    : ''
        },
        {
            align: "center",
            title: '备注',
            dataIndex: 'note',
        },
        // {
        //     title: '操作',
        //     dataIndex: 'state',
        //     render: (text, record) =>
        //     (
        //         <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked checked={text === 0} />
        //     )
        // },

    ];
    return (
        <>
            <Table
                rowSelection={{
                    type: "radio",
                    onChange: (selectedRowKeys: React.Key[], selectedRows: IAccountAdjustDetail[]) => {
                        setSelectedRowKeys(selectedRowKeys);
                    },
                    selectedRowKeys
                }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize }).then();
                    }
                }}
                onRow={record => {
                    return {
                        onClick: event => {
                            if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
                                setSelectedRowKeys([]);
                            } else {
                                setSelectedRowKeys([record.key]);
                            }
                        },
                    };
                }}
                scroll={{ x: 'max-content' }}
            />
            {
                previewInfo.visible && <PreviewModal
                    visible={previewInfo.visible}
                    onCancel={onCancel}
                    md5Id={previewInfo.md5}
                    fileName={previewInfo.fileName}
                    filePath={previewInfo.filePath}
                    fileType={previewInfo.fileType}
                />
            }
        </>
    )
}
