import React from "react";
import { useItemModel } from "../itemModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "项目编号",
        fieldName: "code"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "项目名称",
        fieldName: "name"
    },
]

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useItemModel();

    return (
        <YhPageHeader
            title="维修项目管理"
            justify="end"
            size="small"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => loadData(initPgCfg, params)}
            handleReset={resetSearchParam}
        />
    );
}