import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import { useState, useEffect } from "react";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { purchasePlanService } from "./purchasePlanService"
import { IPurchasePlanSearch, IPurchasePlanDetail, IPurchasePlanFields } from "./purchasePlanType"
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    totalNum: number,
    totalAmount: number
}
export const usePurchasePlanModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const { shopCode, shopId } = shopAndOrg
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopCode])
    const initSearchParam: IPurchasePlanSearch = {};
    const initDataSource: IPurchasePlanDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM };
    const initDesc = {
        totalNum: 0,
        totalAmount: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<IPurchasePlanSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IPurchasePlanDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IPurchasePlanFields>({});
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<IPurchasePlanDetail[]>([]);


    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            let { totalNum, totalAmount } = item
            newRowsDescTotal.totalNum = bigNumber.add(formatNum(totalNum), newRowsDescTotal.totalNum)
            newRowsDescTotal.totalAmount = bigNumber.add(formatNum(totalAmount), newRowsDescTotal.totalAmount)
            // newRowsDescTotal.totalNum += +formatNum(totalNum)
            // newRowsDescTotal.totalAmount += +formatNum(totalAmount)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IPurchasePlanDetail)
    }, [selectedRowKeys, dataSource])

    const updateSearchParam = (updateParam: IPurchasePlanFields) => {
        // console.log(updateParam)
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: IPurchasePlanSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        const { retData } = await purchasePlanService.page(searchParams);
        const { records, statistics, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)
    }
    //导出
    const exportPurchasePlan = async (searchValue: IPurchasePlanSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopId,
            id: selectedRowKeys.join(),
            "pageNum": 0,
            "pageSize": 0,
        }
        let result = await purchasePlanService.export(searchParams);
        convertRes2Blob(result)


    }
    return {
        initPgCfg,
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        descTotal,
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setSelectedRow,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        exportPurchasePlan,
        setDescTotal,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows
    }
})