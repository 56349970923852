import React, { } from "react";
import { Table, Button, Input } from "antd";
import { YHModal } from "@/components/YHModal";
// import { useMaintenanceDetailsModel } from "./summaryDetailsModels";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { useSummaryDetailsModel } from "./summaryDetailsModels";

export const ItemModal = () => {
    const { itemModel: visible, setItemModel, } = useSummaryDetailsModel();
    const onOk = () => {
        onCancel()
    }
    const onCancel = () => {
        setItemModel(false);
    };

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i}</>
        }, {
            title: '项目编码',
            width: 120,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '项目名称',
            width: 160,
            dataIndex: 'typeCode',
            fixed: "left",
        }, {
            title: '工时',
            width: 160,
            dataIndex: 'name',
        }, {
            title: '单价',
            width: 160,
            dataIndex: 'contact',
        }, {
            title: '工时费',
            width: 160,
            dataIndex: 'phone',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'address',
        }, {
            title: '操作',
            width: 120,
            render: () => <Button size="small" type="primary">添加</Button>
        }
    ];

    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16"
            placeholder="请入你编码"
        />
        <Button style={{ margin: "0px 12px 0px 15px" }} shape="round">检索</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        title="预约管理-选择项目"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选项目列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                // size="small"
                rowKey={record => record.id}
                columns={columns}
                dataSource={[]}
                pagination={{
                    // ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        // loadData({ pageNum, pageSize }).then();
                    }
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选项目列表">
            <Table
                // rowKey={record => record.id}
                columns={columns}
                dataSource={[]}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}