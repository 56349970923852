import React, { FC } from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { globalPrompt } from "@/components/message";
import { usePurchasePoDetailsModel } from "./purchasePoDetailsModel";
import { Description } from "@/components/YhBox/Description";
import { ITopType } from "@/types/ScmTypes";
import { useDictModel } from "@/views/base/dict/dictModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const TopButs: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const { detailAddForm,
        detailEditForm,
        detailLookForm,
        detailDataSource,
        detailLookList,
        topButsControl,
        setSettleVisible,
        setWsConfirmModal,
        serChangeSaleDrawer } = usePurchasePoDetailsModel();
    const { getTreeNodeName } = useDictModel();

    const onSettle = () => {
        IFormRef.current?.validateFields().then(async res => {
            setSettleVisible(true)
        })

    }
    const onWhConfirm = () => {
        let materialNames: string[] = []
        let typeList = {
            add: [],
            edit: detailDataSource,
            look: detailLookList
        }
        typeList[mode].forEach(item => {
            if (!!!item.shelfCode && !!!item.warehouseCode) {
                materialNames.push(item.materialName as string)
            }
        })
        if (materialNames.length === 0 && typeList[mode].length != 0) {
            IFormRef.current?.validateFields().then(async res => {
                setWsConfirmModal(true)
            })
        } else {
            globalPrompt("message", { type: "error", text: `请选择${materialNames.toString()}的仓库与货位` })
        }
    }
    const onTrunSale = () => {
        serChangeSaleDrawer(true)
    }
    const action = mode === "edit" ? <>
        <YhAuth resCode={ResourceAuthEnum.P_P_ED_TRANS}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl}
                onClick={onTrunSale}
            >转销售单</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_P_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl || detailEditForm.status === "purchase-status.bill"}
                onClick={onSettle}
            >确认结算</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_P_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl || detailEditForm.status === "purchase.completely-io" || detailEditForm.status === "purchase.completely-io"}
                onClick={onWhConfirm}
            >确认入库</Button>
        </YhAuth>
    </> : mode === "look" ? <>
        <YhAuth resCode={ResourceAuthEnum.P_P_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                // disabled={topButsControl}
                onClick={onSettle}
            >结算查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_P_ED_STOCK}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                // disabled={topButsControl}
                onClick={onWhConfirm}
            >入库查看</Button>
        </YhAuth>
    </> : false;
    //     <Button
    //     className="mr-5"
    //     type="link"
    //     shape="round"
    //     disabled={topButsControl}
    //     onClick={onSettle}
    // >结算查看</Button>
    // <Button
    //     className="mr-5"
    //     type="link"
    //     shape="round"
    //     disabled={topButsControl}
    //     onClick={onWhConfirm}
    // >入库查看</Button>
    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatu: IDescription[] = [
        {
            label: "采购单",
            // value: "XS202012301259",
            value: typeTitle[mode]?.purchaseCode as string ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            // value: "已开单",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",

            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}