import { createModel } from "hox";
import { useState, useEffect } from "react";
import { TablePaginationConfig } from "antd/es/table/interface";
import { returnService } from "../../followUpService";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import {
    IItemDetailList,
    IMaterialDetailList,
    IFollowUpDetail,
    IReturnDetails,
    IFollowUpUpDateDetails,
} from "../../followUpType"
import moment from "moment";
import { IStockStatisticDetail } from "@/views/stock/statistic/stockStatisticService";

export type IMaintenanceDescType = {
    workHours: number, //工时
    workHoursNum: number, //工时项数
    workHoursPrice: number, //工时费 折前
    workHoursDiscount: number, //工时优惠
    workHoursTotal: number, //工时总计 折后
    partPrice: number, //零件费 折前
    partPriceNum: number, //零件费项数
    partsDiscount: number, //零件优惠
    partsTotal: number, //零件总计 折后
    discountAll: number,//优惠金额
    receivableAll: number,//实收金额
    totalAll: number, //总计        

}
export const usefollowUpDetailsModel = createModel(function () {
    // const { shopId, shopName } = shopAndOrg
    const [itemModel, setItemModel] = useState<boolean>(false); //维修项目弹框
    const [materialModel, setMaterialModel] = useState<boolean>(false); //维修项目弹框 
    const [returnDrawer, setReturnDrawer] = useState<boolean>(false); //回访抽屉 
    const initDesc: IMaintenanceDescType = {
        workHours: 0, //工时
        workHoursNum: 0, //工时项数
        workHoursPrice: 0, //工时费 折前
        workHoursDiscount: 0, //工时优惠
        workHoursTotal: 0, //工时总计 折后

        partPrice: 0, //零件费 折前
        partPriceNum: 0, //零件费项数
        partsDiscount: 0, //零件优惠
        partsTotal: 0, //零件总计 折后
        discountAll: 0,//优惠金额
        receivableAll: 0,//实收金额
        totalAll: 0, //总计    
    }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //编辑数据
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState<IFollowUpDetail>({})
    //编辑项目详情列表
    const [detailItemEditList, setDetailItemEditList] = useState<IItemDetailList[]>([])
    //编辑维修用料详情列表
    const [detailMaterialEditList, setDetailMaterialEditList] = useState<IMaterialDetailList[]>([])
    //编辑回访内容
    const [detailReturnDetailsEditList, setDetailReturnDetailsEditList] = useState<IReturnDetails[]>([])
    const [editDesc, setEditDesc] = useState<IMaintenanceDescType>(initDesc)
    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IFollowUpDetail>({})
    //查看项目详情列表
    const [detailItemLookList, setDetailItemLookList] = useState<IItemDetailList[]>([])
    //查看维修用料详情列表
    const [detailMaterialLookList, setDetailMaterialLookList] = useState<IMaterialDetailList[]>([])
    //查看回访内容
    const [detailReturnDetailsLookList, setDetailReturnDetailsLookList] = useState<IReturnDetails[]>([])
    const [lookDesc, setLookDesc] = useState<IMaintenanceDescType>(initDesc)
    //弹框状态值
    const [detailType, setDetailType] = useState<string>("edit");

    useEffect(() => {
        let newDesc: IMaintenanceDescType = initDesc;
        detailItemEditList && detailItemEditList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialEditList && detailMaterialEditList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemEditList.length
        newDesc.partPriceNum = detailMaterialEditList.length
        if ("favorableAmount" in detailEditForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailEditForm.favorableAmount)
        }
        setEditDesc(newDesc);
    }, [detailItemEditList, detailMaterialEditList, detailEditForm])
    
    useEffect(() => {
        let newDesc: IMaintenanceDescType = initDesc;
        detailItemLookList && detailItemLookList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialLookList && detailMaterialLookList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemLookList.length
        newDesc.partPriceNum = detailMaterialLookList.length
        if ("favorableAmount" in detailLookForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailLookForm.favorableAmount)
        }
        setLookDesc(newDesc);
    }, [detailItemLookList, detailMaterialLookList, detailLookForm])
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await returnService.one(id);
        const { itemDetails, materialDetails, returnDetails, deliveryTime, enterTime, ...from } = retData
        const newItemDetails = itemDetails?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newMaterialDetails = materialDetails?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newFrom = {
            ...from,
            enterTime: enterTime ? moment(enterTime) : null,
            deliveryTime: deliveryTime ? moment(deliveryTime) : null
        }
        if (retData && pattern === "edit") {
            setDetailItemEditList(newItemDetails as IItemDetailList[] ?? [])
            setDetailReturnDetailsEditList(returnDetails as IReturnDetails[] ?? [])
            setDetailMaterialEditList(newMaterialDetails as IMaterialDetailList[] ?? [])
            setDetailEditForm(newFrom)
        } else if (retData && pattern === "look") {
            setDetailLookForm(newFrom);
            setDetailItemLookList(newItemDetails as IItemDetailList[] ?? [])
            setDetailReturnDetailsLookList(returnDetails as IReturnDetails[] ?? [])
            setDetailMaterialLookList(newMaterialDetails as IMaterialDetailList[] ?? [])
        }
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailItemEditList([]);
        setDetailMaterialEditList([]);
        setDetailReturnDetailsEditList([])
    }
    const editFollowUp = async (params: IFollowUpUpDateDetails) => {
        let newparams = {
            details: [params],
            id: detailEditForm.id as string
        }
        await returnService.update(newparams);

    }
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [consumeHistoryVisible, setConsumeHistoryVisible] = useState<boolean>(false) //消费记录展示
    const [consumeHistoryModel, setConsumeHistoryModel] = useState<{ customerName: string }>({ customerName: "" }) //消费记录参数
    const [repairHistoryVisible, setRepairHistoryVisible] = useState<boolean>(false) //维修历史展示
    const [repairHistoryModel, setRepairHistoryModel] = useState<{ licensePlate: string }>({ licensePlate: "" }) //维修历史
    return {
        initPgCfg,
        itemModel,
        materialModel,
        returnDrawer,
        setItemModel,
        setMaterialModel,
        setReturnDrawer,
        detailEditForm, setDetailEditForm,
        detailItemEditList, setDetailItemEditList,
        detailMaterialEditList, setDetailMaterialEditList,
        detailReturnDetailsEditList, setDetailReturnDetailsEditList,
        editDesc, setEditDesc,
        detailLookForm, setDetailLookForm,
        detailItemLookList, setDetailItemLookList,
        detailMaterialLookList, setDetailMaterialLookList,
        detailReturnDetailsLookList, setDetailReturnDetailsLookList,
        lookDesc, setLookDesc,
        getDetailEditOne,
        resetEditCatch,
        detailType, setDetailType,
        editFollowUp,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        consumeHistoryModel, setConsumeHistoryModel,
        consumeHistoryVisible, setConsumeHistoryVisible,
        repairHistoryModel, setRepairHistoryModel,
        repairHistoryVisible, setRepairHistoryVisible
    }
})