import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IAccountTransferDetail } from "../../../../AccountTypes";
import { useTransferModel } from "./transferModel";

const columns: ColumnsType<IAccountTransferDetail> = [
    {
        align: "center",
        title: '分支',
        dataIndex: 'branch',
    },
    {
        align: "center",
        title: '转账金额',
        dataIndex: 'transferAmount',
    },
    {
        align: "center",
        title: '转入账户',
        dataIndex: 'accountInName',
    },
    {
        align: "center",
        title: '转入机构',
        dataIndex: 'inOrgName',
    },
    {
        align: "center",
        title: '转入实体',
        dataIndex: 'inBeName',
    },
    {
        align: "center",
        title: '转入前金额',
        dataIndex: 'accountInAmount',
    },
    {
        align: "center",
        title: '转入后金额',
        dataIndex: '',
        render: (v, record) => {
            let inAmount = record?.accountInAmount ?? 0
            let trAmount = record?.transferAmount ?? 0
            return inAmount + trAmount
        }
    },
    {
        align: "center",
        title: '转出账户',
        dataIndex: 'accountOutName',
    },
    {
        align: "center",
        title: '转出机构',
        dataIndex: 'outOrgName',
    },
    {
        align: "center",
        title: '转出实体',
        dataIndex: 'outBeName',
    },
    {
        align: "center",
        title: '转出前金额',
        dataIndex: 'accountOutAmount',
    },
    {
        align: "center",
        title: '转出后金额',
        dataIndex: '',
        render: (v, record) => {
            let otAmount = record?.accountOutAmount ?? 0
            let trAmount = record?.transferAmount ?? 0
            return otAmount - trAmount
        }
    },
    {
        align: "center",
        title: '业务员',
        dataIndex: 'createUser',
    },
    {
        align: "center",
        title: '转账时间',
        dataIndex: 'createTime',
    },
    // {
    //     title: '状态',
    //     dataIndex: 'status',
    //     width: 80,
    //     render: (v) => v === 0 ? "正常" : "异常"
    // },
    {
        align: "center",
        title: '备注',
        dataIndex: 'note',
    },

];

export function TransferDataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useTransferModel();



    return (
        <Table
            rowSelection={{
                type: "radio",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IAccountTransferDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => {
                return {
                    onClick: event => {
                        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
                            setSelectedRowKeys([]);
                        } else {
                            setSelectedRowKeys([record.key]);
                        }
                    },
                };
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}
