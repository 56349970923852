import React, { useRef, FC, useEffect, useState } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText, formatNum } from "@/utils/utils";
import { useSettlementModel } from "./settlementModel"

import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { RouterParamType } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { accountService } from "@/views/account/AccountService";
import { IVRMSettleTypeEnum } from "@/types/FmsDs";
import { ISelectOptions } from "@/types/ScmTypes";
import { purchasePoService } from "@/views/purchase/po/purchasePoService";
import { purchaseRoService } from "@/views/purchase/ro/purchaseRoService";
import useGlobalModel from "@/model/globalModel";


export const PurchaseSettleDrawer = () => {
    const { user, shopAndOrg: { shopName, shopId,shopCode } } = useGlobalModel()
    const { purchaseSettleVisible: visible, setPurchaseSettleVisible, selectedRow, resetSearchParam } = useSettlementModel();
    const { type, documentNumber,userName } = selectedRow as { documentNumber: string, type: string,userName:string }
    const { getSelectedDictOne } = useDictModel();
    const [settleType, setSettleType] = useState<IVRMSettleTypeEnum>(IVRMSettleTypeEnum.SETTLE_CASH);   //结算类型，  默认现金结账
    const [accountTypeDs, setAccountTypeDs] = useState<ISelectOption[]>([]) //实时存取收款账户类型值settle-type.cash
    const [editPayMethodOptions, setEditPayMethodOptions] = useState<ISelectOptions[]>(getSelectedDictOne(DictCodeEnmu.PAY_METHOD))//编辑根据结算类型展示结算方式
    const [purchaseSettlementForm, setPurchaseSettlemntForm] = useState<any>({})
    const [cash, setCash] = useState(false);

    const formRef = useRef<IYhFormRef>();

    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        if ('isBatch' in changedValues) {
        }
    }
    useEffect(() => {
        if (visible) {
            // queryAccount(typeForm[mode].supplierCode as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, typeForm[mode].payType as string);
            getPurchaseOne()
        }


    }, [visible])
    const getPurchaseOne = async () => {
        let from: any = {}
        if (type === "采购单") {
            let { retData } = await purchasePoService.one(documentNumber)
            let { purchaseCode, ...params } = retData
            from = { ...params, purchaseCode }
        } else if (type === "采退单") {
            let { retData } = await purchaseRoService.one(documentNumber)
            let { returnCode, ...params } = retData
            from = { ...params, purchaseCode: returnCode }
        }
        setPurchaseSettlemntForm(from)
        queryAccount(from.supplierCode as string, from.payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : from.payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, from.payType as string,from);
    }

    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode: string, creditFlag: number, payType: string,newSettlementForm: any = purchaseSettlementForm) => {

        let params = {
            partnerCode,  //客户/供应商编码 
            beCode: purchaseSettlementForm.bentityCode??newSettlementForm.bentityCode,
            status: 0,
            creditFlag,  //为记账类型的  1挂账 2现结
            sourceApp: "vrm",
            shopCode
        }
        const { retData } = await accountService.accountFind(params);
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter(item => {
            // if (creditFlag) {
            //     return typeForm[mode].payType !== IVRMSettleTypeEnum.SETTLE_CASH ?
            //         (item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE || item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE_OIL) :
            //         (item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE && item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE_OIL)
            // }
            return item;
        })
        // debugger;
        //现金账户 或者 挂账账户。
        let newAccTypeDs: any = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        if (payType === IVRMSettleTypeEnum.SETTLE_CASH) {
            newAccTypeDs = filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0, accounttypecode: item.accountTypeCode as string, key: item.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {//挂账  account-type.credit
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_MONTHLY) {//月结  account-type.credit-month
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit-month") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        }
        setAccountTypeDs(newAccTypeDs);
        let accTypeDs: any = newAccTypeDs[0]
        if (newAccTypeDs.length > 0) {
            setCash(accTypeDs.accounttypecode === "account-type.cash");
            formRef.current?.setFieldsValue({ payMethodName: accTypeDs.label, payMethodCode: accTypeDs.value, payMethodValue: accTypeDs.accounttypecode, available: formatNum(accTypeDs?.available) });
        } else {
            setCash(false);
            message.warning(`该${userName}客户/供应商没有对应账户`);
            formRef.current?.setFieldsValue({ payMethodName: undefined, payMethodCode: undefined, payMethodValue: undefined, available: undefined });
        }
    }
    const setPayMethodOptions = (payType: string) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions as ISelectOptions[])
    }
    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value: string, option: ISelectOption) => {
        if (option) {
            setSettleType(option.value as IVRMSettleTypeEnum);
            // option.value === ISCMSettleTypeEnum.SETTLE_CREDIT && queryAccount(editFormVal.customerCode);
            queryAccount(purchaseSettlementForm.supplierCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value);
            setPayMethodOptions(value)

        }
    }
    const onCancel = () => {
        setPurchaseSettleVisible(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            formRef.current?.inst().submit();
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = async (val: any) => {
        // return console.log(accountTypeDs)
        // let { payType, payMethodName } = val
        // let { id, purchaseCode } = purchaseSettlementForm
        // let data = {
        //     id,
        //     reckoner: user.username,
        // }
        let { payType,supplierName, payMethodName, payableAmount, payMethodCode, payMethodValue, cashCode, cashName } = val
        let { id, purchaseCode } = purchaseSettlementForm
        let data = {
            id,
            reckoner: user.username,
            accountCode: payMethodCode,
            accountType: payMethodValue,
            payType,
            payableAmount,
            payMethodName,
            payMethodCode,
            details: payMethodValue !== "account-type.cash" ? [] : [
                {
                    payMethodCode: cashCode,
                    payMethodName: cashName,
                    accountName: payMethodName,
                    accountCode: payMethodCode,
                    amount: payableAmount,
                    payee: "",
                }
            ]
        }
        let newData
        if (type === "采购单") {
            let retData = await purchasePoService.settle({ ...data, purchaseCode })
            newData = retData
        } else if (type === "采退单") {
            let retData = await purchaseRoService.settle({ ...data, returnCode: purchaseCode })
            newData = retData
        }
        // let retData = purchasePoService.settle({ accountCode: accountTypeDs[0]?.value, payType, payMethodName })
        // let retData = await onSettle({ accountCode: accountTypeDs[0]?.value, payType, payMethodName })
        if (newData) {
            await onCancel();
            // await getDetailEditOne(id, mode);
            await globalPrompt("message", { text: "结算成功", type: "success" });
            resetSearchParam()

        } else {
            await onCancel()
            await globalPrompt("message", { text: "结算失败", type: "error" });
            resetSearchParam()
        }
    }
    const payMethodChange = (value: string, option: ISelectOption) => {
        if (option.accounttypecode === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true);
        } else {
            setCash(false);
        }
        option && formRef.current?.setFieldsValue({
            payMethodName: option.label,
            payMethodValue: option.accounttypecode,
            available: formatNum(option?.available),
            cashName: undefined,
            cashCode: undefined
        });
    }
    const cashSelectChange = (value: string, option: ISelectOption) => {
        option && formRef.current?.setFieldsValue({ cashName: option.label, cashCode: option.value });
    }
    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        formValues: purchaseSettlementForm,
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "单据编号",
                fieldName: "purchaseCode",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单日期",
                fieldName: "createTime",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "supplierName",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商编码",
                fieldName: "supplierCode",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购实体",
                fieldName: "bentityName",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "应付金额",
                fieldName: "payableAmount",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "已付金额",
                fieldName: "paidAmount",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                onSelectChange: settleTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                span: 24,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "支付方式",
                fieldName: "payMethodCode",
                onSelectChange: payMethodChange,
                options: accountTypeDs,
                span: 24,
            },
            ...((str) => {
                if (str) {
                    return [
                        {
                            type: FormItemTypeEnum.Select,
                            fieldLabel: "现金类支付",
                            fieldName: "cashCode",
                            span: 24,
                            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASH),
                            onSelectChange: cashSelectChange,
                            rules: [{ required: true, message: "请完善现金类支付" }],
                        }
                    ]
                } else {
                    return [
                        {
                            type: FormItemTypeEnum.Input,
                            fieldLabel: "可用额度",
                            fieldName: "available",
                            span: 24,
                            disable: true,
                        },
                    ]
                }
            })(cash),
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "现金类支付",
                fieldName: "cashName",
                hidden: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "支付方式对应得账户类型",
                fieldName: "payMethodValue",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "支付方式",
                fieldName: "payMethodName",
                span: 24,
                hidden: true
            },

        ]
    };
    return (
        <YHDrawer
            {...{
                title: '采购付款',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit,
                showFooter: true
                // width:600
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
