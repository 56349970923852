import React, { FC, useState } from "react";
import { Button, Table, Input, message, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { RouterParamType } from "@/model/navModel";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import useGlobalModel from "@/model/globalModel";
import { formatInt, formatNum, formatDiscount } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { IInternaGoodslDetail } from "../../internalTypes";
import { useInternalDetailsModel } from "./internalDetailsModel";
import { importPropsConfig } from "@/utils/importFile";
import { useDictModel } from "@/views/base/dict/dictModel";


export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const { user: { shopCode } } = useGlobalModel();
    const { addGoodsList, editFormVal: { status }, editGoodsList, pgCfg, allotLoadData,
        addAllotRowKeys, setAddAllotRowKeys,
        setAddAllotRow,
        editAllotRowKeys, setEditAllotRowKeys,
        setEditAllotRow,
        addFormVal,
        setAllDetailVisible
    } = useInternalDetailsModel();
    const { getTreeNodeName } = useDictModel(() => []);

    const action = <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={() => {
            if (mode === "add" && !!!addFormVal.adverseUnitCode) {
                return message.error("请选择对账单位")
            }
            setAllDetailVisible(true)
        }}
    >查看对账明细</Button>

    const columns: ColumnsType<IInternaGoodslDetail> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'shopCode',
            fixed: "left",
            render: text => text === shopCode ? "调入" : "调出"

        }, {
            title: '调拨单号',
            width: 160,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '单据金额',
            width: 100,
            dataIndex: 'aggregateAmount',
        }, {
            title: '交易门店',
            width: 130,
            dataIndex: 'outShopName',
        }, {
            title: '调拨类型',
            width: 100,
            dataIndex: 'transferTypeName',
        }, {
            title: '支付状态',
            width: 136,
            dataIndex: 'payStatus',
            render: text => getTreeNodeName(text)
        }, {
            title: '支付类型',
            width: 100,
            dataIndex: 'payRecords',
        }, {
            title: '业务员',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '记账日期',
            width: 120,
            dataIndex: 'applyTime',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
        }
    ];

    const setTypeDetailDataRow = async (selectedRows: IInternaGoodslDetail[]) => {
        if (mode === "add") {
            setAddAllotRow(selectedRows)
        } else if (mode === "edit") {
            setEditAllotRow(selectedRows)
        }
    }
    const setTypeDetailDataRowKeys = async (selectedRowKeys: string[]) => {
        if (mode === "add") {
            setAddAllotRowKeys(selectedRowKeys)
        } else if (mode === "edit") {
            setEditAllotRowKeys(selectedRowKeys)
        }
    }
    return (
        <YhBox title="" action={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.id}
                rowSelection={{
                    selectedRowKeys: mode === "add" ? addAllotRowKeys : editAllotRowKeys, //记录选中
                    onChange: (selectedRowKeys: React.Key[], selectedRows: IInternaGoodslDetail[]) => {
                        setTypeDetailDataRowKeys(selectedRowKeys as string[])
                        setTypeDetailDataRow(selectedRows)
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        setTypeDetailDataRow(selectedRows)
                    }
                }}
                columns={columns}
                dataSource={mode === "edit" ? editGoodsList : addGoodsList}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        allotLoadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}