import { httpRequest } from "../../utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";
// import { IRet } from "";

export interface ITodayNumInName {
    num: number,
    name: string
}
export interface IToday {
    App: ITodayNumInName,
    Sale: ITodayNumInName,
    Return: ITodayNumInName,
    Break: ITodayNumInName,
    Factory: ITodayNumInName,
    Pur: ITodayNumInName,
}
export interface IZhouNumInDay {
    num: number,
    day: string
}
export interface IZhou {
    "appDetails": IZhouNumInDay[],
    "BreakDetails": IZhouNumInDay[],
    "FactoryDetails": IZhouNumInDay[],
    "SaleDetails": IZhouNumInDay[],
    "PurDetails": IZhouNumInDay[],
    "ReturnDetails": IZhouNumInDay[],
}
export interface ITopFildes {
    "unsettledNum": number,
    "warehousingNum": number,
    "deliveryNum": number,
    "excessInventory": number,
    "stockShortage": number,
    "returnNum": number
}
export interface ISetFildes {
    createUser?: string,  // "",
    icon: string,  // "",
    id?: string,  // "",
    name: string,  // "",
    path: string,  // ""
    url: string
}
export interface ISetDetailUpdate {
    createUser: string,
    details: ISetFildes[]
}
export interface ISettlementFildes {
    id?: string, // "2026ec67f7531caa2f870f839a8998fd",
    documentNumber?: string, // "TJHYWX202106300001",
    type?: string, // "维修工单",
    userName?: string, // "66018部队",
    contact?: string, // "刘奇",
    phone?: string, // "18920614102",
    settlementPartyCode?: string, // "settle-party.self-pay",
    settlementPartyName?: string, // "客户自费",
    alreadyAmount?: string, // "3200.00",
    shouldAmount?: string, // null,
    createUser?: string, // "杜照伟",
    note?: string, // null,
    createTime?: string, // "2021-06-30 16:00:47"
    [key: string]: any
}
export type ISettlementSearch = ISettlementFildes & ISearchPage;
export type ISettlementDetails = ISettlementFildes

export interface IDeliveryFildes {
    id?: string, // "5fd6648d41d6bd64e8cd2399dd8a054d",
    payStatus?: string, // "pay-status.write-off",
    totalNum?: string, // "3.00",
    documentNumber?: string, // "TJHYXS202105300004",
    userType?: string, // "客户",
    type?: string, // "销售单",
    userName?: string, // "天津仲胜客运有限公司",
    contact?: string, // "骆仲民",
    phone?: string, // "13323382561",
    createUser?: string, // "杜照伟",
    note?: string, // "",
    createTime?: string, // "2021-05-30 14:49:31"
    [key: string]: any
}
export type IDeliverySearch = IDeliveryFildes & ISearchPage;
export type IDeliveryDetails = IDeliveryFildes

export interface IWarehousingFildes {
    id?: string, // "2f925d39e3856532dfb41dbf4cd1d288",
    payStatus?: string, // "pay-status.init",
    status?: string, // "purchase-status.create",
    totalNum?: string, // "2.00",
    documentNumber?: string, // "TJHYCG202106100022",
    userType?: string, // "供应商",
    type?: string, // "采购单",
    userName?: string, // "福伊特驱动技术系统（上海）有限公司  ",
    contact?: string, // "暂无",
    phone?: string, // "暂无",
    createUser?: string, // "杜照伟",
    note?: string, // null,
    createTime?: string, // "2021-06-10 17:13:38"
    [key: string]: any

}
export interface ITransitFilder{
    name:string, // "第三仓库(南宫代储库)",
    preSaleAmount:number, // 1717,
    preSaleNum:number, // 4,
    saleOrderAmount:number, // 450,
    saleOrderNum:number, // 1
}
export type IWarehousingSearch = IWarehousingFildes & ISearchPage;
export type IWarehousingDetails = IWarehousingFildes
class HomeService {
    async today(shopId: string) {
        const apiUrl = `/bcm/statistics/today/${shopId}`;
        return await httpRequest.get<IToday[]>(apiUrl);
    }
    async zhou(shopId: string) {
        const apiUrl = `/bcm/statistics/zhou/${shopId}`;
        return await httpRequest.get<IZhou[]>(apiUrl);
    }
    async yer(shopId: string) {
        const apiUrl = `/bcm/statistics/yer/${shopId}`;
        return await httpRequest.get<IZhou[]>(apiUrl);
    }
    async top(shopId: string) {
        const apiUrl = `/bcm/statistics/top/${shopId}`;
        return await httpRequest.get<ITopFildes>(apiUrl);
    }
    // 设置管理
    async set(params: { createUser: string }) {
        const apiUrl = `/bcm/set/findPage`;
        return await httpRequest.post<ISetFildes[]>(apiUrl, params);
    }
    async setUpdate(details: ISetDetailUpdate) {
        const apiUrl = `/bcm/set/update`;
        return await httpRequest.post<boolean>(apiUrl, details);
    }
    async settled(searchVo: ISettlementSearch) {
        const apiUrl = `/bcm/statistics/settled`;
        return await httpRequest.post<IPage<ISettlementDetails>>(apiUrl, searchVo);
    }
    async Delivery(searchVo: IDeliverySearch) {
        const apiUrl = `/bcm/statistics/Delivery`;
        return await httpRequest.post<IPage<IDeliveryDetails>>(apiUrl, searchVo);
    }
    async warehousing(searchVo: IWarehousingSearch) {
        const apiUrl = `/bcm/statistics/warehousing`;
        return await httpRequest.post<IPage<IWarehousingDetails>>(apiUrl, searchVo);
    }
    async transit(shopId:string){
        const apiUrl = `/bcm/statistics/transit/${shopId}`;
        return await httpRequest.get<ITransitFilder[]>(apiUrl);
    }
}
export const homeService = new HomeService();