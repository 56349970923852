import { ISearchPage, ISelectOptions, ISearchPageField } from '@/types/ScmTypes';
import { IEntityFields } from '@/views/organization/entity/entityType';

export interface IInternaGoodslDetail {
	[key: string]: any;
}
export interface IInternalDetail {
	id?: string; // "2250d71f54813a2ebc94dfc06021733d",
	code?: string; // "WOF-00000008",
	orderNum?: string; // null,
	deleteFlag?: string; // 0,
	createTime?: string; // null
	updateTime?: string; // null,
	createUser?: string; // "管理员",
	updateUser?: string; // null,
	branch?: string; // "vrm",
	accountCode?: string; // "ALLOT-00000001",
	accountName?: string; // "中国中国北京欧豪汽车贸易有限公司门店核算账户",
	writeoffType?: string; // 0,
	timeAsc?: string; // null,
	mostAvailable?: string; // null,
	writeoffAmount?: string; // 2.85,
	writeoffTime?: string; // null,
	status?: string | number; // 0,
	note?: string; // null,
	beCode?: string; // "bentity.bj-ouhao",
	beName?: string; // "北京欧豪汽车贸易有限公司",
	shopCode?: string; // "SHOP-M-00000002",
	shopName?: string; // "法士特中心库",
	unitCode?: string; // "AUT-M-00000017",
	unitName?: string; // "中国中国",
	adverseAccountCode?: string; // "ALLOT-00000002",
	adverseAccountName?: string; // "潍柴潍柴北京欧豪汽车贸易有限公司门店核算账户",
	adverseUnitCode?: string; // "AUT-M-00000018",
	adverseUnitName?: string; // "潍柴潍柴",
	adverseBeCode?: string; // "bentity.bj-ouhao",
	adverseBeName?: string; // "北京欧豪汽车贸易有限公司",
	applyUser?: string; // null,
	applyTime?: string; // null,
	adverseAffirmUser?: string; // null,
	adverseAffirmTime?: string; // null,
	financeAffirmUser?: string; // null,
	financeAffirmTime?: string; // null,
	financeAuditUser?: string; // null,
	financeAuditTime?: string; // null,
	endAuditUser?: string; // null,
	endAuditTime?: string; // null
	[key: string]: any;
}

export interface IInternalSearch {
	status?: number;
	adverseAccountName?: string;
}
export interface IWriteoffParams {
	billCodeList: string[];
	billCode?: string; //"FST-DB21091000001",
	writeoffType: number; //0
	[key: string]: any;
}
export interface IWriteoffDetail {
	branch?: string; // "vrm",
	code?: string; // "WOF-00000009",
	accountCode?: string; // "ALLOT-00000001",
	accountName?: string; // "中国中国北京欧豪汽车贸易有限公司门店核算账户",
	writeoffType?: string; // 0,
	timeAsc?: string; // null,
	mostAvailable?: string; // null,
	writeoffAmount?: string; // 2.85,
	writeoffTime?: string; // "2021-11-3",
	status?: string; // 0,
	note?: string; // null,
	orgCode?: string; // null,
	orgName?: string; // null,
	beCode?: string; // "bentity.bj-ouhao",
	beName?: string; // "北京欧豪汽车贸易有限公司",
	shopCode?: string; // "SHOP-M-00000002",
	shopName?: string; // "法士特中心库",
	creditFlag?: string; // null,
	createTime?: string; // "2021-11-30",
	createUser?: string; // "郝磊",
	account?: string; // null,
	billList?: string; // null,
	partnerCode?: string; // null,
	partnerName?: string; // null,
	partnerTypeCode?: string; // null,
	key?: string; // "51ca90957f1d65bd928cf7c28f9ef973"
}
export interface IAuditParams {
	code: string; //"WOF-00000001",
	adverseAffirmUser: string; //"李四",
	status: number; //2
}
export enum IInternalStatus {
	DAISHENQING, //待申请
	DAIDUIFANGQUEREN, //待对方确认
	DUIFANGBURENKE, //对方不认可
	DAICAIWUQUEREN, //待财务确认
	DUIFANGYIBOHUI, //对方已驳回
	DAICAIWUSHENHE, //待财务审核
	CAIWUYIBOHUI, //财务已驳回
	DAIZUIZHONGSHENHE, //待最终审核
	ZUIZHONGSHENHETONGGUO, //最终审核通过
	ZUIZHONGYIBOHUI, //最终已驳回
}
export const internalStatusOption: ISelectOptions[] = [
	{ label: '待申请', value: IInternalStatus.DAISHENQING },
	{ label: '待对方确认', value: IInternalStatus.DAIDUIFANGQUEREN },
	{ label: '对方不认可', value: IInternalStatus.DUIFANGBURENKE },
	{ label: '待财务确认', value: IInternalStatus.DAICAIWUQUEREN },
	{ label: '对方已驳回', value: IInternalStatus.DUIFANGYIBOHUI },
	{ label: '待财务审核', value: IInternalStatus.DAICAIWUSHENHE },
	{ label: '财务已驳回', value: IInternalStatus.CAIWUYIBOHUI },
	{ label: '待最终审核', value: IInternalStatus.DAIZUIZHONGSHENHE },
	{ label: '最终审核通过', value: IInternalStatus.ZUIZHONGSHENHETONGGUO },
	{ label: '最终已驳回', value: IInternalStatus.ZUIZHONGYIBOHUI },
];
export interface IFmsShopUnitDetail {
	bentityList: IEntityFields[];
	address: string;
	bentity: string;
	branch: string;
	branchList: string;
	code: string;
	contact: string;
	createTime: string;
	createUser: string;
	deleteFlag: string;
	enable: string;
	id: string;
	name: string;
	note: string;
	phone: string;
	shop: string;
	shopCode: string;
	shopList: string;
	shopName: string;
	status: string;
	tel: string;
	updateTime: string;
	updateUser: string;
	ver: string;
}
export interface IFmsAllotFileds {
	transferTypeCode?: string; // "transfer-type.bp",
	transferTypeName?: string; // "备品调拨",
	payType?: string; // null,
	shopCode?: string; // "SHOP-M-00000001",
	shopName?: string; // "潍柴事业部",
	bentityCode?: string; // "bentity.bj-ouhao",
	bentityName?: string; // "北京欧豪汽车贸易有限公司",
	outShopCode?: string; // "SHOP-M-00000002",
	outShopName?: string; // "法士特中心库",
	contact?: string; // "杨月",
	phone?: string; // "1530003",
	address?: string; // null,
	outBentityCode?: string; // "bentity.bj-ouhao",
	outBentityName?: string; // "北京欧豪汽车贸易有限公司",
	applier?: string; // "吴杏云",
	applyTime?: string; // "2021-09-10 11",
	transferOut?: string; // null,
	transferOutTime?: string; // null,
	transferIn?: string; // null,
	transferInTime?: string; // null,
	status?: string; // null,
	payStatus?: string; // null,
	note?: string; // null,
	totalNum?: number; // 0,
	discountRate?: string; // "0",
	favorableAmount?: number; // 0.00,
	discountTotalAmount?: number; // 0.00,
	totalAmount?: number; // 1.00,
	paidAmount?: number; // 0.00,
	aggregateAmount?: number; // 0.00,
	code?: string; // "WCS-DB21091000001",
	id?: string; // "8636efb9aba64d9eba5ce3415813d01e",
	createTime?: string; // "2021-09-106",
	updateTime?: string; // "2021-11-30 10",
	createUser?: string; // "吴杏云",
	updateUser?: string; // null,
	details?: string; // null,
	deleteFlag?: number; // 0
}
export interface IFmsAllotSearch extends ISearchPage {}
export interface IFmsAllotDetailFileds {
	id?: string; // "f4dff12cecf5dbd56bb33924412824c6",
	code?: string; // null,
	orderNum?: string; // 0,
	deleteFlag?: string; // 0,
	createTime?: string; //"",
	updateTime?: string; //"",
	createUser?: string; // "张帅",
	updateUser?: string; // null,
	branch?: string; // null,
	shopCode?: string; // "SHOP-M-00000002",
	transferCode?: string; // "FST-DB21120300001",
	materialCode?: string; // "13050733",
	materialName?: string; // "燃油粗滤芯",
	replaceCode?: string; // "",
	typeCode?: string; // "material-type.wc-parts.226b-b",
	typeName?: string; // "226B B类配件",
	unitCode?: string; // "unit.article",
	unitName?: string; // "",
	num?: string; // 1,
	price?: string; // 56.00,
	favorableAmount?: string; // 0.00,
	discountRate?: string; // "0",
	amount?: string; // 56.00,
	inWarehouseCode?: string; // "fast",
	inWarehouseName?: string; // "法士特中心库",
	inShelfId?: string; // "1abeeab254d4350d01e3737aeb873efc",
	inShelfCode?: string; // "临时货位",
	outWarehouseCode?: string; // "wc",
	outWarehouseName?: string; // "潍柴库",
	outShelfId?: string; // null,
	outShelfCode?: string; // "204-K-17",
	status?: string; // "transfer-status.init",
	stockInStatus?: string; // "pur-stock—io-status.init",
	stockOutStatus?: string; // "sale-stock—io-status.init",
	payStatus?: string; // null,
	note?: string; // ""
}
export interface IFmsAllotDetailSearch extends ISearchPage {}
