import React, { useState, useEffect } from 'react';
import { createModel } from 'hox';
import useGlobalModel from '@/model/globalModel';
import { purchaseDrService } from '../../purchaseDrService';
import { convertRes2Blob } from '@/utils/exportFile';
import { message } from 'antd';
import { useLockFn } from 'ahooks';
import {
	ISCMPurchaseDrFormFields,
	ISCMPDrGoodsDetail,
	ISCMPurchaseDrInsert,
	ISCMPurchaseDrUpdate,
} from '../../purchaseDrTypes';
import { ISCMPDrSelectGoods } from './AddGoodsModal';
import { globalPrompt } from '@/components/message';
import { CLIENT_APP_CODE } from '@/utils/constants';
import { formatNum,bigNumber } from '@/utils/utils';

export type ISCMPDrDescType = {
	numAll: number;
	amountAll: number;
	actualAmount: number;
	totalPreAmount: number;
	[key: string]: any;
};
const initDesc = {
	actualAmount: 0, //实际总价合计 （数量*单价）
	totalPreAmount: 0, //实际总价合计 （数量*单价）
	//对应展示字段
	numAll: 0, //调拨数量
	amountAll: 0, //调拨总计   （去除下浮）
};

export const usePurchaseDrDetailsModel = createModel(function () {
	const {
		shopAndOrg: { shopId, shopCode, shopName },
		user: { userName },
	} = useGlobalModel();

	const initFormVal = {
		shopCode,
		shopName,
		branchIn: CLIENT_APP_CODE,
		createUser: userName,
		applier: userName,
		discountTotalAmount: '0.00', //优惠总额
		favorableAmount: '0', //整单优惠
		aggregateAmount: '0.00', //应付金额
		discountRate: '0', //整单下浮
	} as ISCMPurchaseDrFormFields;

	//编辑缓存
	const [editFormVal, setEditFormVal] = useState<ISCMPurchaseDrFormFields>(initFormVal);
	const [editGoodsList, setEditGoodsList] = useState<ISCMPDrGoodsDetail[]>([]);
	const [editDesc, setEditDesc] = useState<ISCMPDrDescType>(initDesc);
	const [isCancel, setIsCancel] = useState<boolean>(false); //记录单子是否是已作废状态

	//新增缓存
	const [addFormVal, setAddFormVal] = useState<ISCMPurchaseDrFormFields>(initFormVal);
	const [addGoodsList, setAddGoodsList] = useState<ISCMPDrGoodsDetail[]>([]);
	const [addDesc, setAddDesc] = useState<ISCMPDrDescType>(initDesc);
	const [addOutShopCode, setAddOutShopCode] = useState<boolean>(false); //是否选择调出门店

	

	// 添加临时货位
	const [addTempShelfModal, setAddTempShelfModal] = useState<boolean>(false);


	// 保存按钮的loading
	const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);

	// 调拨按钮控制
	const [btnContral, setBtnContral] = useState<boolean>(false)

	useEffect(() => {
		let newDesc: ISCMPDrDescType = { ...initDesc };
		editGoodsList.forEach((item) => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
		});
		setEditDesc(newDesc);
	}, [editGoodsList]);

	useEffect(() => {
		let newDesc: ISCMPDrDescType = { ...initDesc };
		addGoodsList.forEach((item) => {
			// newDesc.numAll += Number(item.num);
			newDesc.numAll = +bigNumber.add(item.num,newDesc.numAll);
			// newDesc.amountAll += Number(item.amount);
			newDesc.amountAll = +bigNumber.add(item.amount,newDesc.amountAll);
			// newDesc.actualAmount += Number(item.num) * Number(item.price);
			newDesc.actualAmount = +bigNumber.add(bigNumber.times(item.num,item.price),newDesc.actualAmount);
			// newDesc.totalPreAmount += Number(item.num) * Number(item.prePrice);
			newDesc.totalPreAmount = +bigNumber.add(bigNumber.times(item.num,item.prePrice),newDesc.totalPreAmount);
		});
		setAddDesc(newDesc);
	}, [addGoodsList]);

	useEffect(() => {
		resetAddCatch();
		setAddFormVal({ ...initFormVal, shopId, shopName, createUser: userName });
	}, [shopId]);

	//表单新增修改
	const updateAddFormVal = (val: object | null) => {
		val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal(initFormVal);
	};
	//表单编辑修改
	const updateEditFormVal = (val: object | null) => {
		val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal(initFormVal);
	};

	//重置缓存
	const resetEditCatch = () => {
		updateEditFormVal(null); //表单
		setEditGoodsList([]); //详情商品列表
		setEditSelectGoods([]); //弹框已选商品
	};
	const resetAddCatch = () => {
		updateAddFormVal(null);
		setAddGoodsList([]);
		setAddSelectGoods([]);
	};

	//云创得登录信息
	const [ciLoginInfo, setCiLoginInfo] = useState<any>({});
	
	//获取一条数据
	const getPDrDetailOne = async (id: string) => {
		let { retData } = await purchaseDrService.one(id);
		let { details, ...formVal } = retData;
		if (retData) {
			setEditGoodsList(() => details.map((item) => ({ ...item, key: item.id, isHave:true })));
			setEditFormVal(formVal);
		}
		return retData;
	};

	//表单配件校验 (开单数量不能为0)
	const verifyGoods = (mode: string): boolean => {
		let goods = mode === 'edit' ? editGoodsList : addGoodsList;
		if (!goods.length) {
			message.warning('请添加商品明细');
			return false;
		}
		let zeroNum = goods.filter((item) => !+item.num);
		if (zeroNum.length) {
			message.warning(
				`【${zeroNum.map((item) => item.materialName).join()}】配件的开单数量不能为0`
			);
			return false;
		}
		const seen:any = {};  
		const inconsistent:any = [];  
	  
		goods.forEach(item => {  
		  const key = `${item.materialCode}-${item.inWarehouseCode}`;  
		  if (seen[key]) {  
			if (seen[key] !== item.inShelfCode) {  
			  inconsistent.push(item);  
			}  
		  } else {  
			seen[key] = item.inShelfCode;  
		  }  
		});  
	  
		if (inconsistent.length > 0) { 
			debugger
		  message.error(`检测到相同物品货位不一致，请检查并更正！`);  
		  return false;
		} 
		// message.success('数据检查通过，可以保存！');  
		return true;
	};
	//表单计算校验 (计算主单信息与配件每一项)
	const formComputeRule = (mode: string): boolean => {
		let goods = mode === 'edit' ? editGoodsList : addGoodsList;
		let { actualAmount } = (mode === 'edit' ? editDesc : addDesc) as { actualAmount: number };
		actualAmount = +formatNum(actualAmount); //应付金额
		let goodsTotal: number = 0;
		goods.forEach((item) => {
			let { amount } = item as { amount: number };
			// goodsTotal += amount;
			goodsTotal = bigNumber.add(goodsTotal,amount)
		});
		if (+formatNum(goodsTotal) !== actualAmount) {
			message.warning(`详情调拨金额${+formatNum(goodsTotal)}与表单调拨金额不符`);
			return false;
		}
		return true;
	};
	//新增 Api
	const insertPDr = useLockFn(async (val: ISCMPurchaseDrFormFields) => {
		if (val.shopCode === val.outShopCode) {
			message.warning('调入门店和调出门店不能为同一个');
			return false;
		}
		setSaveBtnLoading(true);
		let data: ISCMPurchaseDrInsert = {
			...val,
			shopId,
			// outShopId: val.branchOut === 'ci' ? ciLoginInfo?.organInfo?.id : val.outShopId,‘
			outShopId: val.outShopId,
			details: addGoodsList.map((item) => {
				let { key, error, isHave, msg, ...params } = item;
				return params;
			}),
			inAndOut: '0',
			totalPreAmount:formatNum(addDesc['totalPreAmount'])
		};
		try {
		let { retData, success } = await purchaseDrService.insert(data);
		let { id: res, materialList } = retData;
			if (success) {
				setBtnContral(false)
				setSaveBtnLoading(false);
				if (!materialList) {
					globalPrompt('message', {
						text: `调入单新增成功,转换到可编辑详情页`,
						type: 'success',
						duration: 3,
					});
				} else {
					globalPrompt('message', {
						text: `${data.shopName}没有【${materialList
							.map((item: any) => item.code)
							.join()}】该配件,将自动添加至配件档案，请及时修正对应档案信息`,
						type: 'success',
						duration: 10,
					});
				}
			} else {
				setBtnContral(false)
				setSaveBtnLoading(false);
				globalPrompt('message', { text: '调入单新增失败', type: 'error' });
			}
			setSaveBtnLoading(false);
			return res;
		} catch (error) {
			setBtnContral(false)
			setSaveBtnLoading(false);
		}

	});

	//编辑 Api
	const updatePDr = useLockFn(async (val: ISCMPurchaseDrFormFields & { id: string }) => {
		setSaveBtnLoading(true);
		let data: ISCMPurchaseDrUpdate = {
			...val,
			id: editFormVal.id,
			shopId,
			updateUser: userName,
			details: editGoodsList.map((item) => {
				let { key, error, isHave, msg, ...params } = item;
				return params;
			}),
			totalPreAmount:formatNum(editDesc['totalPreAmount'])
		};
		try {
		let { success, retData } = await purchaseDrService.update(data);
		let { materialList } = retData;

			if (success) {
				setSaveBtnLoading(false);
				setBtnContral(false)
				if (!materialList) {
					globalPrompt('message', {
						text: `调入单编辑成功`,
						type: 'success',
						duration: 3,
					});
				} else {
					setSaveBtnLoading(false);
					globalPrompt('message', {
						text: `${data.shopName}没有【${materialList
							.map((item: any) => item.code)
							.join()}】该配件,将自动添加至配件档案，请及时修正对应档案信息`,
						type: 'success',
						duration: 10,
					});
				}
				getPDrDetailOne(editFormVal.id);
			} else {
				setBtnContral(false)
				globalPrompt('message', { text: '调入单编辑失败', type: 'error' });
			}
			setSaveBtnLoading(false);
			return success;
		} catch (error) {
			setBtnContral(false)
			setSaveBtnLoading(false);
		}
	});

	/**
	 * 商品新增
	 */
	const [addGoodsModal, setAddGoodsModal] = useState<boolean>(false); //商品弹框
	const [editSelectGoods, setEditSelectGoods] = useState<ISCMPDrSelectGoods[]>([]); //已选的商品
	const [addSelectGoods, setAddSelectGoods] = useState<ISCMPDrSelectGoods[]>([]); //已选的商品
	const [specilDataSource, setSpecilDataSource] = useState<any[]>([]);

	//添加商品  默认数量字段为1
	const add = async (record: ISCMPDrSelectGoods, mode: string) => {
		const isEdit: boolean = mode === 'edit';
		let params = {
			shopId,
			materialCode: record.materialCode,
		};
		let { retData } = await purchaseDrService.selfWh(params);
		let { warehouseCode, warehouseName, shelfCode, shelfId } = retData ?? {};
		isEdit
			? setEditSelectGoods((origin) => [
					...origin,
					{
						...record,
						pDrNum: 1,
						inWarehouseCode: warehouseCode ?? '',
						inWarehouseName: warehouseName ?? '',
						inShelfCode: shelfCode ?? '',
						inShelfId: shelfId ?? '',
					},
			  ])
			: setAddSelectGoods((origin) => [
					...origin,
					{
						...record,
						pDrNum: 1,
						inWarehouseCode: warehouseCode ?? '',
						inWarehouseName: warehouseName ?? '',
						inShelfCode: shelfCode ?? '',
						inShelfId: shelfId ?? '',
					},
			  ]);
	};

	//删除商品 选中的
	const remove = (record: ISCMPDrSelectGoods, mode: string) => {
		mode === 'edit'
			? setEditSelectGoods((origin) => origin.filter((item) => item.id !== record.id))
			: setAddSelectGoods((origin) => origin.filter((item) => item.id !== record.id));
	};

	//选中的配件转商品
	const transformsToPDrGoods = useLockFn(async (selectList: ISCMPDrSelectGoods[], mode: string) => {
		const isEdit: boolean = mode === 'edit';
		const newDetailData: ISCMPDrGoodsDetail[] = [];
		let formVal = mode === 'add' ? addFormVal : editFormVal;
		let branchIn =
			formVal.branchIn === 'scm' ? 'scm2' : formVal.branchIn === 'vrm' ? 'vrm2' : 'bcm';
		let inShopId = formVal.shopId ?? shopId;
		let codeList: string[] = [];
		let goodsList = mode === 'add' ? addSelectGoods : editSelectGoods;
		goodsList.forEach((item) => {
			codeList.push(item.materialCode);
		});
		let data = {
			shopId: inShopId,
			checkoutMaterialCodeList: codeList,
			existMaterialCodeList: [],
			notExistMaterialCodeList: [],
		};
		let { retData } =
			branchIn === 'scm2'
				? await purchaseDrService.checkScm(branchIn, data)
				: await purchaseDrService.check(branchIn, data);
		let { existMaterialCodeList } = retData
		selectList.forEach((item) => {
			newDetailData.push({
				isHave:existMaterialCodeList.indexOf(item.materialCode)> -1,
				id: '',
				key: item.id, //
				brandName: item.brandName,
				brandCode: item.brandCode,
				purchaseDetailsId: item.id,
				materialCode: item.materialCode,
				materialName: item.materialName,
				replaceCode: item.replaceCode,
				typeCode: item.typeCode,
				typeName: item.typeName,
				unitCode: item.unitCode,
				unitName: item.unitName,
				num: item.pDrNum,
				price: item.price, //进货价
				prePrice:item.price ?? 0, //折前单价
				preAmount: +item.pDrNum * +(item.price ?? 0), //总计
				costPrice: item.price ?? 0, //成本单价
				favorableAmount: '0', //单品优惠         ·+++
				discountRate: '0', //下浮
				// amount: +item.pDrNum * +(item.price ?? 0), //总计
				amount:+bigNumber.toFixed(bigNumber.times(+item.pDrNum ,+(item.price ?? 0)),2),
				outShelfId: item.shelfId,
				outShelfCode: item.shelfCode,
				outWarehouseName: item.warehouseName,
				outWarehouseCode: item.warehouseCode,
				inventoryNum: item.inventoryQuantity,
				purchaseCode: item.purchaseCode,
				inWarehouseCode: item.inWarehouseCode ?? '',
				inWarehouseName: item.inWarehouseName ?? '',
				inShelfCode: item.inShelfCode ?? '',
				inShelfId: item.shelfId ?? '',
				bussType: item.bussType ?? '',
				bussCode: item.bussCode ?? '',
				note: '',
			});
		});
		isEdit
			? setEditGoodsList((origin) => [...origin, ...newDetailData])
			: setAddGoodsList((origin) => [...origin, ...newDetailData]);
		isEdit ? setEditSelectGoods([]) : setAddSelectGoods([]);
		setAddGoodsModal(false);

		let newDate = addSelectGoods;
		const index = newDate.filter((item) => !item.inWarehouseCode);
		setSpecilDataSource(index)
	});
	//调入
	const inWhConfirm = useLockFn(async (val: ISCMPurchaseDrFormFields & { id: string }) => {
		await updatePDr({...val, shopId});
		let obj = {
			inAndOut: '0',
			id: editFormVal.id,
			status: editFormVal.status,
			transferIn: userName,
			transferOut: editFormVal.transferOut,
			transferOutTime: editFormVal.transferOutTime, //调出时间
			transferInTime: editFormVal.transferInTime, //调入时间
			createUser: userName,
			transferOutCode: editFormVal.transferOutCode,
			transferInCode: editFormVal.transferInCode,
		};
		let data: ISCMPurchaseDrUpdate = {
			...val,
			id: editFormVal.id,
			inAndOut: '0',
			transferIn: userName,
			transferOut: editFormVal.transferOut,
			transferOutTime: editFormVal.transferOutTime, //调出时间
			transferInTime: editFormVal.transferInTime, //调入时间
			createUser: userName,
			status: editFormVal.status,
			details: editGoodsList.map((item) => {
				let { key, ...params } = item;
				return params;
			}),
		};

		let { retData } = await purchaseDrService.bcmConfirm(data);
		if (retData) {
			let { retData: WhConfirmRetData } = await purchaseDrService.whConfirm(obj);
			if (WhConfirmRetData) {
				message.success('调入成功');
				getPDrDetailOne(editFormVal.id);
			}
		}
	});

	//明细下载模板
	const pDrDownload = async () => {
		let result = await purchaseDrService.downloadTpl();
		convertRes2Blob(result);
	};

	//导出明细
	const exportPDrdetail = async () => {
		let result = await purchaseDrService.exportDetail(editFormVal?.code ?? '');
		convertRes2Blob(result);
	};
	const [historyVisible, setHistoryVisible] = useState<boolean>(false);
	const [historyRow, setHistoryRow] = useState<any>({});
	/**
	 * 打印
	 */
	const [print, setPrint] = useState<boolean>(false);

	return {
		editFormVal,
		addFormVal,
		addGoodsModal,
		isCancel,
		setAddGoodsModal,
		updateAddFormVal,
		updateEditFormVal,
		resetEditCatch,
		resetAddCatch,
		/**api */
		insertPDr,
		updatePDr,
		getPDrDetailOne,
		pDrDownload,
		exportPDrdetail,
		/**商品详情 */
		editSelectGoods,
		setEditSelectGoods,
		addSelectGoods,
		setAddSelectGoods,
		addGoodsList,
		setAddGoodsList,
		editGoodsList,
		setEditGoodsList,
		add,
		remove,
		verifyGoods,
		transformsToPDrGoods,
		editDesc,
		addDesc,
		print,
		setPrint,
		inWhConfirm,
		addOutShopCode,
		setAddOutShopCode,
		historyVisible,
		setHistoryVisible,
		historyRow,
		setHistoryRow,
		formComputeRule,
		addTempShelfModal,
		setAddTempShelfModal,
		saveBtnLoading,
		setSaveBtnLoading,
		btnContral, setBtnContral,
		setSpecilDataSource,specilDataSource,
		ciLoginInfo, setCiLoginInfo,
	};
});
