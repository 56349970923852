import React, { FC } from "react";

import { Col } from "antd";
import { FormItemTypeEnum, IFormItem } from "../Types";
import { __FormInput as FormInput } from "./FormInput";
import { __FormDatePicker as FormDatePicker } from "./FormDatePicker";
import { __FormSwitch as FormSwitch } from "./FormSwitch";
import { __FormSelect as FormSelect } from "./FormSelect";
import { __FormCheckbox as FormCheckbox } from "./FormCheckbox";
import { __FormFileImage as FormFileImage } from "./FormFileImage";
import { __FormButton as FormButton } from "./FormButton";
import { __FormTreeSelect as FormTreeSelect } from "./FormTreeSelect";
import { __FormAsyncSelect as FormAsyncSelect } from "./FormAsyncSelect";

export const __FormItem: FC<IFormItem> = (props) => {

    return <Col span={props.span ?? 6} key={props.fieldName} className={props.colClassName} hidden={props.hidden}>
        {
            (() => {
                switch (props.type) {
                    case FormItemTypeEnum.Input:
                        return <FormInput {...props} />;
                    case FormItemTypeEnum.Datepicker:
                        return <FormDatePicker {...props} />;
                    case FormItemTypeEnum.Switch:
                        return <FormSwitch {...props} />;
                    case FormItemTypeEnum.Select:
                        return <FormSelect {...props} />;
                    case FormItemTypeEnum.Checkbox:
                        return <FormCheckbox {...props} />;
                    case FormItemTypeEnum.FileImage:
                        return <FormFileImage {...props} />;
                    case FormItemTypeEnum.Button:
                        return <FormButton {...props} />;
                    case FormItemTypeEnum.TreeSelect:
                        return <FormTreeSelect {...props} />;
                    case FormItemTypeEnum.AsyncSelect:
                        return <FormAsyncSelect {...props} />;
                    case FormItemTypeEnum.Other:
                        return props.formItem
                    default:
                        return <></>;
                }
            })()
        }
    </Col>
}