import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTransferMonitorModel } from "../transferMonitorModel";
import { ITransferMonitorDetail } from "../transfermonitorService";
import { formatNum } from "@/utils/utils";
import "./style.css"
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = useTransferMonitorModel();
    const { getTreeNodeName } = useDictModel(() => []);
    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<ITransferMonitorDetail> = [
        {
            align: "center",
            width: 60,
            ellipsis: true,
            title: '序号',
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调入单号',
            dataIndex: 'transferInCode',
             render: (text: any) => <YhTootip text={text} />,
        },
        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '调入单状态?',
        //     dataIndex: '',
        //     render: text => getTreeNodeName(text)
        // },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调入门店',
            dataIndex: 'shopName',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调入数量',
            dataIndex: 'inTotalNum',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调入金额',
            dataIndex: 'inTotalAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调入成本金额',
            dataIndex: 'inCostAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调入毛利金额',
            dataIndex: 'inProfit',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调出单号',
            dataIndex: 'transferOutCode',
            render: (text: any) => <YhTootip text={text} />,
        },

        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '调出单状态?',
        //     dataIndex: '',
        //     render: text => getTreeNodeName(text)
        // },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调出门店',
            dataIndex: 'outShopName',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调出数量',
            dataIndex: 'outTotalNum',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调出金额',
            dataIndex: 'outTotalAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调出成本金额',
            dataIndex: 'costAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调出毛利金额',
            dataIndex: 'grossProfit',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '数量差异',
            dataIndex: 'numDiff',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '金额差异',
            dataIndex: 'amountDiff',
            render: text => `￥${formatNum(text)}`
        },
        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '成本差异?',
        //     dataIndex: 'saleProfit',
        //     render: text => `￥${formatNum(text)}`
        // },
        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '毛利差异?',
        //     dataIndex: '',
        //     render: text => `￥${formatNum(text)}`
        // },
        // {
        //     align: "center",
        //     width: 100,
        //     title: '调入制单日期',
        //     dataIndex: 'createTime',
        // },

    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id),
            //     onContextMenu: (e) => {
            //         e.preventDefault();
            //         setSelectedRowKeys([record.id]);
            //     }
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 360px)" }}
        />
    )
}
