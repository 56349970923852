import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useSettlementModel } from "./settlementModel";


export const DataBox = () => {
    const { selectedRowKeys, setSettleVisible, selectedRow,setPurchaseSettleVisible } = useSettlementModel();
    const onSettle = () => {
        let { type } = selectedRow

        if (selectedRowKeys.length !== 1) {
            return message.error("请选择一条数据")
        }
        return type === "维修工单" || type === "销售单" || type === "销退单" ? setSettleVisible(true) : setPurchaseSettleVisible(true)
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => onSettle()}
        >结算</Button>
    </>;

    return (
        <>
            <YhBox title={action} >
                <DataTable />
            </YhBox>
        </>
    )
}