import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { Print } from "./Print"
import { useSummaryCustomerModel } from '../customerModel';
import { ScaniaPrints } from "./scaniaPrint";
import { SettleScaniaPrints } from "./settleScaniaPrints";

export default () => {
    const { 
        print,
        scaniaPrint,
        settleScaniaPrint, } = useSummaryCustomerModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <Print />}
            {scaniaPrint && <ScaniaPrints />}
            {settleScaniaPrint && <SettleScaniaPrints />}
        </>
    )
}