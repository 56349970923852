import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSaleSoModel } from "../saleSoModel";
import { ISaleSoDetail } from "../saleSoTypes";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import { formatNum, momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";

type statusType = {
    "sale-status.write-off": string,
    "sale.change": string,
    "sale.stock-io": string,
    "sale.settle": string,
    "sale.create": string,
    "sale.supplement": string,
    "sale.end": string,
    [key: string]: any
}


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows,setHistoryRow,setHistoryVisible
    } = useSaleSoModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "5-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
   
    const statusColor: statusType = {
        "sale-status.write-off": "green",
        "sale.change": "red",
        "sale.stock-io": "cyan",
        "sale.settle": "green",
        "sale.create": "blue",
        "sale.supplement": "orange",
        "sale.end": "gold",
    }
    const onHistoryModel = (record: ISaleSoDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    useEffect(() => {
        loadData()
    }, [])
    const columns: ColumnsType<ISaleSoDetail> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '销售单号',
            width: 200,
            fixed: "left",
            dataIndex: 'saleCode',
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() =>{
                let supplierEditKey = "5-2-3";
                replaceNav(
                    selectedNavId,
                    supplierEditKey,
                    { id: record?.saleCode as string, mode: "look", },
                    true
                );
                onHistoryModel(record)
            }}/>,
            //render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" style={{ color: record.status == 'sale-replenish-status.replenish' ? 'red' : record.status == 'sale.supplement' || record.status == 'sale.end' ? 'magenta' : 'blue' }} onClick={(e) => { e.stopPropagation(); onLook(text) }}>{text}</p></Tooltip>,
        }, {
            title: '调拨单号',
            width: 200,
            fixed: "left",
            dataIndex: 'allocationCode',
             render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '单据状态',
            width: 80,
            fixed: "left",
            dataIndex: 'status',
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,

        }, {
            title: '支付状态',
            width: 70,
            dataIndex: 'payStatus',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '客户名称',
            width: 200,
            dataIndex: 'customerName',
            render: (text: any) => <YhTootip text={text} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '联系人',
            width: 150,
            dataIndex: 'contact',
        }, {
            title: '联系电话',
            width: 95,
            dataIndex: 'phone',
        }, {
            title: '销售数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '销售金额',
            width: 80,
            dataIndex: 'totalAmount',
            render: (text) => `￥${text}`,
        }, {
            title: '优惠金额',
            width: 80,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${text}`,
        }, {
            // title: '应收金额',
            title: '营业额',
            width: 80,
            dataIndex: 'receivedAmount',
            render: (text) => `￥${text}`,
        }, {
            title: '已收金额',
            width: 80,
            dataIndex: 'receivableAmount',
            render: (text) => `￥${text ?? 0}`,
        }, {
            title: '销售成本',
            width: 80,
            dataIndex: 'totalCost',
            render: (text) => `￥${text}`,
        }, {
            title: '销售毛利',
            width: 80,
            dataIndex: 'grossProfit',
            render: (text) => `￥${formatNum(text)}`,
        }, {
            title: '退货数量',
            width: 80,
            dataIndex: 'returnTotalNum',
        }, {
            title: '销售类型',
            width: 80,
            dataIndex: 'maintenanceTypeName',
        },{
            title: '结算方',
            width: 90,
            dataIndex: 'settlementPartyName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '结算方式',
            width: 120,
            dataIndex: 'paymentMethodName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '销售员',
            width: 60,
            dataIndex: 'saleUser',
        }, {
            title: '制单员',
            width: 60,
            dataIndex: 'createUser',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '制单时间',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '库管员',
            width: 60,
            dataIndex: 'storekeeper',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '所属门店',
            width: 120,
            dataIndex: 'shopName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '结算时间',
            width: 130,
            dataIndex: 'settleTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结算员',
            width: 60,
            dataIndex: 'reckoner',
        }, {
            title: '出库时间',
            width: 130,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '核销时间',
            width: 130,
            dataIndex: 'writeOffTime',
            render: (text) => momentFormat(text)

        }, {
            title: '作废原因',
            width: 120,
            dataIndex: 'cancelReason',
        }, {
            title: '转接单号',
            width: 120,
            dataIndex: 'saleOrderCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
            // }, {
            //     title: '调拨单号',
            //     width: 180,
            //     fixed: "left",
            //     dataIndex: 'transferOrder',
        },
        //  {
        //     title: '车辆类型',
        //     width: 80,
        //     dataIndex: 'vehicleTypeName',
        // },
        //  {
        //     title: '车牌号',
        //     width: 80,
        //     dataIndex: 'licensePlate',
        //     render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        // },
        //  {
        //     title: 'VIN码',
        //     width: 80,
        //     dataIndex: 'vin',
        //     render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        // }, 
        {
            title: '开票状态',
            width: 80,
            dataIndex: 'invoiceStatus',
            render: (text) => text == 1 ? "已开票" : "未开票"
        }, {
            title: '发票类型',
            width: 80,
            dataIndex: 'invoiceTypeName',
        }, 
        // {
        //     title: '站点',
        //     width: 120,
        //     dataIndex: 'siteInformation',
        //     fixed: 'right',
        //     render: (text) => getTreeNodeName(text)
        // },
        {
            title: '经营实体',
            width: 140,
            dataIndex: 'bentityName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
            fixed: 'right'
        }, {
            title: '备注',
            width: 200,
            fixed: "right",
            dataIndex: 'note',
        }
    ];
    //点击行选高亮
    const onRow = (record: ISaleSoDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record: ISaleSoDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleSoDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }
            })}
            scroll={{ x: 'max-content', y: 520 }}

        />
    )
}
