import { Key, useState } from "react";
import { createModel } from "hox";
import { useSessionStorageState } from "ahooks";
import { ICustomerAccountSummary } from "../../../AccountTypes";
import { accountService } from "../../../AccountService";
import useNavModel from "@/model/navModel";
import { useGlobalModel } from "@/model/globalModel";
import { ITreeData, ISelectOptions } from "@/types/ScmTypes";
import { IMdOrgDetail } from "@/views/organization/org/orgService";
import { IMdDictFields } from "@/views/account/dictService";

export enum ActionTypeEnum {
    ACCOUNT,
    ADJUST_LIST,
    RECHARGE_LIST,
    TRANSFER_LIst,
    BILL_LIST,
    WRITEOFF_LIST,
    LOG_LIST,
    NULL
}

type labelsType = {
    [proppName: string]: string;
}

export const useDetailModel = createModel(function () {
    const { user: { shopCode } } = useGlobalModel();
    const [customerCode, setCustomerCode] = useSessionStorageState<string>("accountShop.detail.customerCode", "");
    const [actionType, setActionType] = useState<ActionTypeEnum>(ActionTypeEnum.ACCOUNT) // 选中展示模块

    //进入详情， 会去查有没有分配权限， 并取到第一个权限为展示
    // const [actionType, setActionType] = useState(() => {
    //     //所有tab权限
    //     let authArr = [
    //         ResourceAuthEnum.ACC_C_VW_AC,
    //         ResourceAuthEnum.ACC_C_VW_ADJ,
    //         ResourceAuthEnum.ACC_C_VW_CHARG,
    //         ResourceAuthEnum.ACC_C_VW_TRANS,
    //         ResourceAuthEnum.ACC_C_VW_BILL,
    //         ResourceAuthEnum.ACC_C_VW_W_OFF,
    //         ResourceAuthEnum.ACC_C_VW_LOG,
    //     ];
    //     //过滤出来所有分配的权限；
    //     let findAuth = resourceAuth.filter(item => authArr.indexOf(item as ResourceAuthEnum) >= 0);
    //     if (!findAuth.length) return ActionTypeEnum.NULL;
    //     switch (findAuth[0]) {
    //         case ResourceAuthEnum.ACC_C_VW_AC:
    //             return ActionTypeEnum.ACCOUNT;
    //         case ResourceAuthEnum.ACC_C_VW_ADJ:
    //             return ActionTypeEnum.ADJUST_LIST;
    //         case ResourceAuthEnum.ACC_C_VW_CHARG:
    //             return ActionTypeEnum.RECHARGE_LIST;
    //         case ResourceAuthEnum.ACC_C_VW_TRANS:
    //             return ActionTypeEnum.TRANSFER_LIst;
    //         case ResourceAuthEnum.ACC_C_VW_BILL:
    //             return ActionTypeEnum.BILL_LIST;
    //         case ResourceAuthEnum.ACC_C_VW_W_OFF:
    //             return ActionTypeEnum.WRITEOFF_LIST;
    //         case ResourceAuthEnum.ACC_C_VW_LOG:
    //             return ActionTypeEnum.LOG_LIST;
    //         default:
    //             return ActionTypeEnum.NULL;
    //     }
    // });

    const [customer, setCustomer] = useState<ICustomerAccountSummary>({ key: "" });
    const { closeNav } = useNavModel(() => []);

    const [payMethodLabels, setPayMethodLabels] = useState<labelsType>({});
    const [payMethodDs, setPayMethodDs] = useState<ITreeData<IMdDictFields>[]>();
    const [accountTypeDs, setAccountType] = useState<ISelectOptions[]>([]);


    const loadSummaryData = (code?: Key) => {
        if (customerCode) {
            accountService
                .customerSummaryOne(customerCode, shopCode)
                .then(({ retData }) => {
                    setCustomer(retData ?? {});
                });
        }
    }

    return {
        payMethodDs,
        payMethodLabels,
        actionType,
        customerCode,
        customer,
        accountTypeDs, setAccountType,
        setActionType,
        setCustomerCode,
        loadSummaryData,
        setPayMethodDs,
        setPayMethodLabels,
        closeNav,
    }
});
