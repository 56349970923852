import { httpRequest } from "../../../utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {  IWorkshopQuerySearch, IWorkshopQueryDetail } from "./workshopQueryType"

class WorkshopQueryService {
    async page(searchVo: IWorkshopQuerySearch) {
        const apiUrl = "/bcm/maintenance/dispatchPage";
        return await httpRequest.post<IPage<IWorkshopQueryDetail>>(apiUrl, searchVo);
    }
    //维修派工
    async dispatch(params:IWorkshopQueryDetail) {
        const apiUrl = `/bcm/maintenance/dispatch`;
        return await httpRequest.post<boolean>(apiUrl,params);
    }
    //维修完工
    async finished(params:IWorkshopQueryDetail) {
        const apiUrl = `/bcm/maintenance/complete`;
        return await httpRequest.post<boolean>(apiUrl,params);
    }
}
export const workshopQueryService = new WorkshopQueryService();
