import React, { } from "react";
import { Button, Dropdown, Input, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePurchasePoModel } from "../purchasePoModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { usePurchasePoDetailsModel } from "../view/details/purchasePoDetailsModel"
import { DownOutlined } from "@ant-design/icons";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";


export const DataBox = () => {
    const { selectedRowKeys, selectedRow, descTotal, rowsDescTotal, setPrint, detailsExport, exportPurchasePo, invalidate, setSelectedRowKeys } = usePurchasePoModel();
    const { serChangeSaleDrawer, setDetailEditForm, setDetailDataSource } = usePurchasePoDetailsModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status == "purchase.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        let supplierEditKey = "11-2-2";
        let isFindNav = findNav(supplierEditKey);
        if (!isFindNav) {
            addNav(supplierEditKey, { id: selectedRow.purchaseCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "采购单跳转详情",
                type: "warning",
                text: "您还有采购单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(supplierEditKey, { id: selectedRow.purchaseCode as string, mode: "edit" });
                    // updateEditFormVal(null);
                    setDetailEditForm({})
                    setDetailDataSource([])
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "11-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.purchaseCode as string, mode: "look", },
            true
        );
    }
    const cancellation = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "采购单作废",
            type: "error",
            text: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id as string,
                    purchaseCode: selectedRow.purchaseCode as string,
                    cancelReason
                }
                invalidate(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onTrunSale = () => {
        serChangeSaleDrawer(true)
    }
    const desc: IDescription[] = [
        {
            label: "采购数量",
            value: selectedRowKeys.length === 0 ? descTotal.totalNum : (+formatNum(rowsDescTotal.totalNum)).toFixed(0),
            color: DescriptionColorEnum.red
        },
        {
            label: "采购金额",
            value: selectedRowKeys.length === 0 ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            color: DescriptionColorEnum.red
        },
        {
            label: "已付金额",
            value: selectedRowKeys.length === 0 ? descTotal.paidAmount : formatNum(rowsDescTotal.paidAmount),
            color: DescriptionColorEnum.red
        }
    ]
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportPurchasePo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.P_P_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("11-2-1", { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_P_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_P_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.P_P_EX}>
            {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportPurchasePo().then()}
        >导出</Button> */}
            <Dropdown overlay={exportMenu}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                >导出<DownOutlined /></Button>
            </Dropdown>
        </YhAuth>
        {/* <YhAuth resCode={ResourceAuthEnum.P_P_CANCEL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={cancellation}
            >作废</Button>
        </YhAuth> */}
        {/* <YhAuth resCode={ResourceAuthEnum.P_P_REPL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("11-2-1", {
                    id: "", mode: "add", parentInfo: {
                        Supplement: "Supplement"
                    }
                })}
            >补单</Button>
        </YhAuth> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onPrint}
        >打印</Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { setVisible(true); }}
        >导出</Button> */}
    </>;

    return (
        <>
            <YhBox title={"采购单列表"} descriptions={desc} action={action}>
                <DataTable />
            </YhBox>
        </>
    )
}