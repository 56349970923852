import { useState } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
    defaultMdCmParam,
    defaultMdCmParamAccout
} from "@/utils/constants";
import { IAccountSummary, ICustomerAccountSummary } from "../../../AccountTypes";
import { message } from "antd";
import { accountShopService } from "@/views/account/AccountShopService";
import { useGlobalModel } from "@/model/globalModel";
import { IMdCustomerFields } from "@/types/FmsCustomerTypes";
import { ITreeData, ISelectOptions, ISearchPage } from "@/types/ScmTypes";
import { IMdOrgDetail } from "@/views/organization/org/orgService";
import { transPgToPagination } from "@/utils/utils";

export const useSummaryModel = createModel(function () {
    const { user: { shopCode } } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<IMdCustomerFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<ICustomerAccountSummary[]>([]);

    const [summaryData, setSummaryData] = useState<IAccountSummary>({});
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<ICustomerAccountSummary[]>([]);
    const [selectedData, setSelectedData] = useState<ICustomerAccountSummary>();
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    const [orgDs, setOrgDs] = useState<ITreeData<IMdOrgDetail>[]>();
    const [supplierTypeDs, setSupplierType] = useState<ISelectOptions[]>();
    const [appDs, setAppDs] = useState<ISelectOptions[]>();

    //账户初始化
    const [initAccountVisible, setInitAccountVisible] = useState<boolean>(false);
    const [synchroModel, setSynchroModel] = useState<boolean>(false);
    const [syncLoading, setSyncLoading] = useState<boolean>(false);
    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IMdCustomerFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSelectedRowKeys([]);
        setSearchParam({});
        loadData(initPgCfg, {})
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IMdCustomerFields = searchParam) => {
        const searchParams = {
            sourceApp: defaultMdCmParamAccout.sourceApp,
            ...searchValue,
            shopCode,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await accountShopService.supplierSummaryPage(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);

        let { retData: summaryData } = await accountShopService.supplierSummary(searchParams);
        setSummaryData(summaryData);
    }
    //全选使用
    const loadDataAll = async () => {
        const searchParams = {
            sourceApp: defaultMdCmParamAccout.sourceApp,
            ...searchParam,
            shopCode,
            page: {
                pageSize: 1000000,
                pageNum: 1
            }
        }
        let { retData } = await accountShopService.supplierSummaryPage(searchParams);
        const { records, ...pg } = retData;
        if (records && records.length > 0) {
            setSelectedRowKeys(records);
            setInitAccountVisible(true);
        } else {
            message.warning("没有符合条件的数据")
        }
    }

    const getSelectedData = () => {
        return selectedData;
    }

    const syncData = async (params: object) => {
        let ret = await accountShopService.supplierSync({ ...searchParam, ...params });
        ret ? message.success("同步成功!重新加载数据...") : message.error("同步失败");
        // ret && await loadData(initPgCfg, {})
        return ret.retData;
    }

    return {
        /***field***/
        setOrgDs, appDs, setAppDs,
        setSupplierType,
        setSelectedData,
        initPgCfg,
        orgDs,
        supplierTypeDs,
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedData,
        summaryData,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData, loadDataAll,
        updatePgCfg,
        getSelectedData, syncData,
        initAccountVisible, setInitAccountVisible,
        synchroModel, setSynchroModel,
        syncLoading, setSyncLoading
    }
});
