import { BillIoFlagEnum } from "./ScmEnums"
import { ISelectOptions } from "./ScmTypes"

export const PartnerTypeconst = {
	'CUSTOMER': "PARTNER-TYPE.CUS",
	'SUPPLIER': "PARTNER-TYPE.SUP"
}

export const UserTypeEnum = {
	'EMPLOYEE': "user-type.employee", // "内部员工"
	'MICRO_BOSS': "user-type.micro-boss", // "微老板"
	'DRIVER': "user-type.driver", //"司机"
	'HEADMAN': "user-type.headman", //"队长"
	'BOSS': "user-type.boss", // "老板"
	'SUPPLIER': "user-type.supplier", //"供应商"
	'CUSTOMER': "user-type.customer", //"客户"
}

export const TradeTypeEnum = {
	'SALE': "trade-type.sale", //销售,
	'PURCHASE': "trade-type.purchase", //采购
	'SALE_RETURN': "trade-type.sale-return", //销售退货
	'PURCHASE_RETURN': "trade-type.purchase-return", //采采购退货
	'PURCHASE_PLAN': "trade-type.purchase-plan", //采购计划
	'SALE_ORDER': "trade-type.sale-order", //销售订单
	'APPOINTMENT': "trade-type.appointment", //预约
	'MAINTENANCE': "trade-type.maintenance", //维修
	'DISPATCH': "trade-type.dispatch", //派工
	'SETTLE': "trade-type.settle", //结算
	'RETURN_VISIT': "trade-type.return-visit", //回访
	'STOCKTAKING': "trade-type.stocktaking", //盘库
	'LOSS': "trade-type.loss", //损失
	'BILL_DISCOUNT': "trade-type.bill-discount", //账单抹零
}

/**
 * 单据类型
 */
export const tradeTypeDs: ISelectOptions[] = [
	{
		label: '销售单',
		value: TradeTypeEnum.SALE,
	},
	{
		label: '采购单',
		value: TradeTypeEnum.PURCHASE,
	},
	{
		label: '销售退货单',
		value: TradeTypeEnum.SALE_RETURN,
	},
	{
		label: '采购退货单',
		value: TradeTypeEnum.PURCHASE_RETURN,
	},
	{
		label: '采购计划',
		value: TradeTypeEnum.PURCHASE_PLAN,
	},
	{
		label: '销售订单',
		value: TradeTypeEnum.SALE_ORDER,
	},
	{
		label: '预约单',
		value: TradeTypeEnum.APPOINTMENT,
	},
	{
		label: '云修-维修单',
		value: TradeTypeEnum.MAINTENANCE,
	},
	{
		label: '派工单',
		value: TradeTypeEnum.DISPATCH,
	},
	{
		label: '结算单',
		value: TradeTypeEnum.SETTLE,
	},
	{
		label: '回访单',
		value: TradeTypeEnum.RETURN_VISIT,
	},
	{
		label: '盘库单',
		value: TradeTypeEnum.STOCKTAKING,
	},
	{
		label: '损失',
		value: TradeTypeEnum.LOSS,
	},
	{
		label: '账单抹零',
		value: TradeTypeEnum.BILL_DISCOUNT,
	},
]

/**
 * 交易对象类型
 */
export const partnerTypeDs: ISelectOptions[] = [
	{
		label: '客户',
		value: UserTypeEnum.CUSTOMER
	},
	{
		label: '供应商',
		value: UserTypeEnum.SUPPLIER
	},
]

/**
 * 收支类型
 */
export const ioFlagDs: ISelectOptions[] = [
	{
		label: '收',
		value: BillIoFlagEnum.IN
	},
	{
		label: '付',
		value: BillIoFlagEnum.OUT
	},
]

/**
 * 订单收款状态
 */
export const billStatusDs: ISelectOptions[] = [
	{
		label: '初始',
		value: 0
	},
	{
		label: '已记账',
		value: 1
	},
	{
		label: '已核销',
		value: 2
	},
]

/**
 * 结算类型
 */
export enum IVRMSettleTypeEnum {
    SETTLE_MONTHLY = "settle-type.monthly", //月结
	SETTLE_CREDIT = "settle-type.credit", //挂账
	SETTLE_CASH = "settle-type.cash",  //现结
	SETTLE_BALANCE = "settle-type.balance"//结转
}
export const settleTypeDs: ISelectOptions[] = [
	{
		label: '挂账',
		value: IVRMSettleTypeEnum.SETTLE_CREDIT
	},
	{
		label: '现结',
		value: IVRMSettleTypeEnum.SETTLE_CASH
	}
]