
import React, { useRef, useState } from "react";
import { FormBox } from "./FormBox"
import { TableBox } from "./TableBox"
import { FixedBottom } from "./FixedBottom"
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { AddGoodsModal } from "./AddGoodsModal";
import { TopButs } from "./TopButs"
import { HistoryModel } from "@/views/historyModel";
import { usePurchasePlanDetailsModel } from "./purchasePlanDetailsModel";
import { SupplierHistoryModel } from "@/views/commonModule/supplierHistoryModel"
export const PurchasePlanDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    const {
        historyVisible, setHistoryVisible,
        historyRow,
        supplierHistoryModel,
        supplierHistoryVisible, setSupplierHistoryVisible,
    } = usePurchasePlanDetailsModel();
    return (
        detailsInfo && <>
            <TopButs {...detailsInfo} IFormRef={IFormRef} />
            <FormBox {...detailsInfo} IFormRef={IFormRef} />
            <TableBox {...detailsInfo} />
            <FixedBottom  {...detailsInfo} />
            <AddGoodsModal {...detailsInfo} />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <SupplierHistoryModel
                visible={supplierHistoryVisible}
                name={supplierHistoryModel.supplierName}
                onCancel={() => setSupplierHistoryVisible(false)}
            />
        </>
    )
}