import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface IVRMPoListDetail {
    [key: string]: any
}

//搜索条件
export interface IVRMPoListSearchFields {
    [key: string]: any
}

export interface IVRMPoListSearch
    extends IVRMPoListSearchFields, ISearchPage {
}
export interface IStatistics {
    purchaseQuantity:number, //采购数量
    payAmount: number, // 1480.3,   // 结算金额
    paidAmount: number, // 1234.75, // 已付金额
    receivedAmount: number, // 0    // 已收金额
}

//采购报表
class PoListService {
    async page(searchVo: IVRMPoListSearch) {
        const apiUrl = "/bcm/purchase/form";
        return await httpRequest.post<IPage<IVRMPoListDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: IVRMPoListSearch) {
        const apiUrl = "/bcm/purchase/export/form";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo: IVRMPoListSearch) {
        const apiUrl = "/bcm/purchase/statistics";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }
}

export const poListService = new PoListService();