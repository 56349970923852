import React, { FC } from "react";
import {  Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";
import { RouterParamType } from "@/model/navModel";
import { IMaterialDetailList } from "../../followUpType";


export const MaterialTableBox: FC<RouterParamType> = ({ id, mode }) => {
    const { 
        detailMaterialEditList,
        detailMaterialLookList,
        setHistoryVisible,
        setHistoryRow
    } = usefollowUpDetailsModel();
    const onHistoryModel = (record: IMaterialDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }

    const columns: ColumnsType<IMaterialDetailList> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '库房',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 160,
            dataIndex: 'shelfCode',
        }, {
            title: '数量',
            width: 160,
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
        }, {
            title: '配件费',
            width: 160,
            dataIndex: 'materialAmount',
        }, {
            title: '折扣',
            width: 160,
            dataIndex: 'discountRate',
            render: (text: string) => text + "%"
        }, {
            title: '小计',
            width: 160,
            dataIndex: 'receivableAmount',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];
    const typeMaterialList = {
        "add": [],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    return (
        <YhBox title="维修用料" style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.id as string }
                columns={columns}
                dataSource={typeMaterialList[mode] as IMaterialDetailList[]}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}