import React from "react";
import { YhBox } from "@/components/YhBox";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { useAccountModel } from "../account/accountModel";
import { useLogModel } from "./logModel";
import { YhPageHeader } from "@/components/YhPageHeader";

const logDs = [
    {
        label: '开户',
        value: 'OPEN'
    },
    {
        label: '禁用',
        value: 'DISABLE'
    },
    {
        label: '启用',
        value: 'ENABLE'
    },
    {
        label: '调额',
        value: 'ADJUST'
    },
    {
        label: '调额审核',
        value: 'ADJUST_APPROVAL'
    },
    {
        label: '充值',
        value: 'RECHARGE'
    },
    {
        label: '转入',
        value: 'TRANSFER_IN'
    },
    {
        label: '转出',
        value: 'TRANSFER_OUT'
    },
    {
        label: '平帐',
        value: 'BALANCE'
    },
    {
        label: '更新',
        value: 'UPDATE'
    },
]

export const LogSearchBox = () => {
    const {
        customerUserDs,
    } = useAccountModel();

    const {
        onReset,
        searchParam,
        onSearch,
        updateSearchParam,loadData
    } = useLogModel()

    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "账户名称",
            fieldName: "accountCode",
            options: customerUserDs
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "日志类型",
            fieldName: "logType",
            options: logDs
        }, {
            type: SearchTypeEnum.FmsRangePicker,
            fieldName: "logTimeRange",
            placeholder: ["起始日期", "结束日期"],
        },
    ]

    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title=""
                justify="end"
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                onFmsChange={updateSearchParam}
                                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={onReset}
            />
        </YhBox>
    )
}
