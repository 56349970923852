import React, { useRef, useState } from "react";
import { message } from "antd";
import { useWarehouseModel } from "../warehouseModel";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils"
import { IVRMWarehouseInsert } from "../warehouseTypes";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { debounce } from "lodash";
import { useDictModel } from "../../dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export function AddDetailDrawer() {
    const { beShopDs, CustomerData, shopAndOrg } = useGlobalModel()
    const { getSelectedDictOne } = useDictModel();
    const [type, setType] = useState<boolean>(false)

    const { addWareVisible: visible, setAddWaerVisible, insertWarehouse,loadData } = useWarehouseModel();
    const formRef = useRef<IYhFormRef>();

    const onCancel = () => {
        setAddWaerVisible(false);
        loadData()
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            let { customerCode, ...params } = res
            let from = {
                ...params,
                customerCode,
                customerName: type?getFindItem(customerCode as string, CustomerData)?.name:"",
            }
            insertWarehouse(from as IVRMWarehouseInsert).then(res => {
                res && onCancel()
            })
        }).catch(err => {
            console.log(err)
            message.warning(getFormValidatorErrText(err));
        })
    }
    const typeChange = (value: string, option: ISelectOption) => {
        console.log(option)
        if (value === "warehouse-category.big") {
            setType(false)
        } else if (value === "warehouse-category.small") {
            setType(true)
        }
    }
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        formValues: { shopName: shopAndOrg.shopName },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "仓库编码",
                fieldName: "code",
                span: 24,
                // rules: [{ required: true, message: "请输入仓库编码" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "仓库名称",
                fieldName: "name",
                span: 24,
                rules: [{ required: true, message: "请输入仓库名称" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "仓库类型",
                fieldName: "type",
                options: getSelectedDictOne(DictCodeEnmu.WAREHOUSE_CATEGORRY),
                onSelectChange: typeChange,
                rules: [{ required: true, message: "请选择仓库类型" }],
                span: 24,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户名称",
                fieldName: "customerCode",
                options: transOptions(CustomerData),
                rules: type ? [{ required: true, message: "请选择客户名称" }] : [],
                allowClear: true,
                showSearch: true,
                hidden: !type,
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "仓库负责人",
                fieldName: "contact",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系地址",
                fieldName: "address",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            }
        ]
    };
    return (
        <YHDrawer
            {...{
                title: "仓库新增",
                visible,
                onCancel,
                okText: "保存",
                onOk: debounce(() => { onSubmit() }, 900)

            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
