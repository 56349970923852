import React from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { AreaTable } from "./AreaTable";
import { ShelvesTable } from "./ShelvesTable";
import { ShelfTable } from "./ShelfTable";
import { useWarehouseModel, IVRMWarehouseTabEnmu } from "../../warehouseModel";

export const WarehouseDetailsTabs = () => {

    const { tabsFlag, setTabFlag } = useWarehouseModel();

    const action = <>
        <Button
            className={`mr-5 ${tabsFlag === IVRMWarehouseTabEnmu.Area ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => setTabFlag(IVRMWarehouseTabEnmu.Area)}
        >区域信息</Button>
        <Button
            className={`mr-5 ${tabsFlag === IVRMWarehouseTabEnmu.Shelves ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => setTabFlag(IVRMWarehouseTabEnmu.Shelves)}
        >货架信息</Button>
        <Button
            className={`mr-5 ${tabsFlag === IVRMWarehouseTabEnmu.Shelf ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => setTabFlag(IVRMWarehouseTabEnmu.Shelf)}
        >货位信息</Button>
    </>;

    //渲染对应列表
    const WarehouseDetailsTabsItem = (str: string) => {
        switch (str) {
            case IVRMWarehouseTabEnmu.Area:
                return <AreaTable />;
            case IVRMWarehouseTabEnmu.Shelves:
                return <ShelvesTable />;
            case IVRMWarehouseTabEnmu.Shelf:
                return <ShelfTable />;
            default:
                return <></>;
        }
    }
    return (
        <>
            <YhBox action={action} style={{ padding: "20px 0 0", marginBottom: "15px" }} />
            {WarehouseDetailsTabsItem(tabsFlag)}
        </>
    )
}