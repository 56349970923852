import React from "react";
import { YhBox } from "@/components/YhBox";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { useAccountModel } from "./accountModel";
import { creditFlagDs } from "../../../../AccountService";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useOrgModel } from "@/views/organization/org/orgModel";
import useGlobalModel from "@/model/globalModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";


export const AccountSearchBox = () => {
    const {
        searchParam,
        updateSearchParam,
        loadData,
        onReset,
    } = useAccountModel(model => [model.searchParam]);
    const { getOrgTreeSelect } = useOrgModel();
    const { beDs } = useGlobalModel();

    const searchFields = [
        {
            type: SearchTypeEnum.TreeSelect,
            placeholder: "组织机构",
            fieldName: "orgCode",
            treeData: getOrgTreeSelect(DictCodeEnmu.ORG)
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "实体",
            fieldName: "beCode",
            options: beDs
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "授信类型",
            fieldName: "creditFlag",
            options: creditFlagDs()
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "编码",
            fieldName: "code",
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "账户名称",
            fieldName: "name",
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            fieldName: "status",
            options: [{ label: "已启用", value: "0" }, { label: "已禁用", value: "9" }],

        },
        // {
        //     type: SearchTypeEnum.FmsRangePicker,
        //     fieldName: "writeoffTimeRange",
        //     placeholder: ["起始日期", "结束日期"],
        // },

    ]

    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title=""
                justify="end"
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                onFmsChange={updateSearchParam}
                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={onReset}
            />
        </YhBox>
    )
}
