import React from "react";
import { Button, Upload } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useWarehouseModel } from "../warehouseModel";
import { useNavModel } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { importPropsConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";

export const DataBox = () => {
    const { selectedRowKeys, updateEditFormVal, setAddWaerVisible, downloadTpl, loadData,shelfExport } = useWarehouseModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel();
    const { shopAndOrg: { shopId } } = useGlobalModel()

    const onEdit = () => {
        if (!selectedRowKeys) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "13-3-1";
        let isFindNav = findNav(supplierEditKey);
        if (!isFindNav) {
            addNav(supplierEditKey, { id: selectedRowKeys, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "仓库跳转详情",
                type: "warning",
                text: "您还有仓库详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(supplierEditKey, { id: selectedRowKeys, mode: "edit" });
                    updateEditFormVal(null);
                }
            })
        }
    }

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_W_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => setAddWaerVisible(true)}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_W_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/warehouse/import`, loadData)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={downloadTpl}
        >下载模板</Button> */}
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { shelfExport() }}
        >导出</Button>         */}
    </>;

    return (
        <YhBox title="仓库列表" action={action}>
            <DataTable />
        </YhBox>
    )
}