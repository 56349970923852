import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface IVRMBusListDetail {
    documentNumber: string,//"TJHXS2022011607",
    tradeTypeCode: string,//"trade-type.sale",
    customerName: string,//"白金库",
    customerTypeCode: string,//"customer-type.group",
    customerTypeName: string,//"集团客户",
    shopName: string,//"天津红岩品牌部",
    shopId: string,//"18059196cb7c4829ac35f23329ba2256",
    status: string,//"sale.stock-io",
    tradeTypeName: string,//"销售",
    type: string,//null,
    totalAmount: string,//787.5,
    paidAmount: string,//787.5,
    discountTotalAmount: string,//0,
    materialScienceAmount: string,//null,
    materialDiscountAmount: string,//null,
    workingHoursAmount: string,//null,
    workingDiscountAmount: string,//null,
    totalCost: string,//787.5,
    grossProfit: string,//0,
    createUser: string,//"张晓莹",
    settleTime: string,//"2022-01-166",
    createTime: string,//"2022-01-167",
    note: string,//""
    [key: string]: any
}

//搜索条件
export interface IVRMBusListSearchFields {
    [key: string]: any
}

export interface IVRMBusListSearch
    extends IVRMBusListSearchFields, ISearchPage {
}
export interface IStatistics {
    saleNum: number; //销售数量
    saleAmount: number; //销售金额
    saleCost: number; //销售成本
    grossProfit: number; //销售毛利
    preferentialAmount?: number;
    receivableAmount?: number;
    receivedAmount?: number;
}

//采购报表
class BusListService {
    async page(searchVo: IVRMBusListSearch) {
        const apiUrl = "/bcm/transit/sale/formAll";
        return await httpRequest.post<IPage<IVRMBusListDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: IVRMBusListSearch) {
        const apiUrl = "/bcm/transit/sale/formAll/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo: IVRMBusListSearch) {
        const apiUrl = "/bcm/transit/sale/statisticalData";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }
}

export const busListService = new BusListService();