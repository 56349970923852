import React, { useEffect } from "react";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { BillDataTable } from "./DataTable";
import { useBillModel } from "./billModel";
import { WriteoffDrawer } from "./WriteoffDrawer";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const BillDataBox = () => {
    const {
        dataSource,
        billSummaryData,
        selectedRowKeys,
        selectedData,
        setWriteoffDrawerVisible,
        loadData,
        setSelectedData,
        billCustomerExport
    } = useBillModel();

    useEffect(() => {
        loadData({ pageNum: 1 })
    }, [])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.key === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
    }, [selectedRowKeys]);

    const desc: IDescription[] = [
        {
            label: "已核销总额",
            value: billSummaryData?.totalWriteoffAmount?.toFixed(2) ?? '0.00',
            color: DescriptionColorEnum.green
        },
        {
            label: "未核销总额",
            value: billSummaryData?.totalUnWriteoffAmount?.toFixed(2) ?? '0.00',
            color: DescriptionColorEnum.red
        },

    ]


    const onWriteoff = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            if (selectedData?.status === 1) {
                setWriteoffDrawerVisible(true);
            } else {
                message.warning("账单状态不可核销！")
            }
        } else {
            message.error("请选择核销账户!");
        }
    }

    const action = (
        <>
            <div>
                <YhAuth resCode={ResourceAuthEnum.ACC_C_VW_BILL_W_OFF}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={onWriteoff}
                    >
                        核销
                </Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.ACC_BILL_C_EX}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={billCustomerExport}
                    >
                        导出
                </Button>
                </YhAuth>
            </div>

        </>
    );

    return (
        <>
            <YhBox title={"账单列表"} descriptions={desc} action={action}>
                <BillDataTable />
            </YhBox>
            <WriteoffDrawer />
        </>
    )
}
