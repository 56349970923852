import React, { useState, useEffect } from 'react'
import { numberParseChina, formatNum } from "@/utils/utils";
import { Modal, Button, Input, message, Select } from 'antd';
import moment from 'moment';
import "./scaniaPrint.css"
import skny from "../../assets/images/skny.png"
import ouman from "../../assets/images/ouman.png"
import zf from "../../assets/images/zf.png"
import iveco from "../../assets/images/iveco.png"


import { IMaintenanceField } from '@/views/maintenance/maintenanceType';
import { globalPrompt } from '../message';
import { IIMgEmum, logoOptions, logoYhhOptions } from '@/types/ScmEnums';
import { maintenanceService } from '@/views/maintenance/maintenanceService';
import { convertRes2Blob } from '@/utils/exportFile';

export interface IPrintClumns {
    title: string,
    dataIndex: string,
    render?: (text: string | number) => string
}
export interface IPrintHeader {
    [key: string]: string | number | any
}
export interface IPrintFooter {
    [key: string]: string | number | any
}
interface Item {
    [key: string]: string | number | any
}
export interface IPrintType {
    title?: string,
    type?: string,
    visible: boolean,
    onClose: () => void,
    onConfirm?: () => void,
    itemClumns?: IPrintClumns[], //维修项目字段及信息
    columns: IPrintClumns[],  //商品字段及信息
    dataSource: Item[],      //商品数据
    itemDataSource?: Item[],  //维修项目数据
    formVal: IMaintenanceField,
    shopCode: string,
    isBooking?: boolean
}

export interface IHeader {
    fieldLabel: string,
    fieldValue?: string | number,
    className?: string,
    border?: boolean,
}
let shopObject: any = {
    "SHOP-M-00000017": {
        "img": skny,
        "address": "新疆乌鲁木齐市米东区金汇西路2877号",
        "phone": "0991-5310666     18016861905",
        "Bank": "招商银行乌鲁木齐分行解放北路支行",
        "BankAccount": "8530 8058 0110 001",
        "Fax": "0991-5310666     18016861905"
    },
    "SHOP-M-00000018": {
        "img": ouman,
        "address": "新疆乌鲁木齐市米东区金汇西路2877号",
        "phone": "0991-5310666     13209963230",
        "Bank": "新疆天山农村银行股份有限公司米东区支行",
        "BankAccount": "8070 1001 2010 1140 42179",
        "Fax": "0991-5310666     13209963230"
    },
    "SHOP-M-00000020": {
        "img": skny,
        "address": "天津津南经济开发区 旺港路15号",
        "phone": "022-28577398",
        "Bank": "中国工商银行股份有限公司天津金地支行",
        "BankAccount": "0302061609100023871",
        "Fax": "022-28577392"
    }
}
export const ScaniaPrint = ({
    title = "",
    visible,
    onClose,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    formVal,
    shopCode,
    isBooking = false
}: IPrintType) => {
    const [imgVal, setImgVal] = useState(shopCode !== "SHOP-M-00000018" ? skny : ouman)
    const [imgType, setImgType] = useState<IIMgEmum>(IIMgEmum.SKNY)

    const print = () => {
        const el: HTMLElement | null = document.getElementById('scania-print-container');
        const iframe: any = document.createElement('IFRAME');
        let doc = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./scaniaPrint.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    }
    // 遍历商品td
    const mapGoods = (goods: Item) => {
        return columns.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods: Item) => {
        return itemClumns?.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }

    const setLogo = () => {
        let logoVal: IIMgEmum = IIMgEmum.SKNY;
        globalPrompt("modal", {
            width: 380,
            title: "设置logo",
            type: "info",
            // text: "",
            text: <>
                <span>请选择Logo类型</span>
                <Select
                    showSearch={true}
                    defaultValue={shopCode !== "SHOP-M-00000018" ? IIMgEmum.SKNY : IIMgEmum.OUMAN}
                    options={shopCode !== "SHOP-M-00000018" ? logoOptions : logoYhhOptions}
                    onChange={(value, option) => logoVal = value}
                />
            </>,
            okText: "确定",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    resolve(logoVal)
                }).then(res => {
                    setImgType(logoVal)
                    switch (logoVal) {
                        case IIMgEmum.SKNY:
                            return setImgVal(skny)
                        case IIMgEmum.OUMAN:
                            return setImgVal(ouman)
                        case IIMgEmum.ZF:
                            return setImgVal(zf)
                        case IIMgEmum.IVECO:
                            return setImgVal(iveco)
                        default:
                            return setImgVal(skny)
                    }
                    // setImgVal(logoVal)
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onExport = async () => {
        let { BankAccount, address, Bank, phone } = shopObject[shopCode]
        let params = {
            "account": BankAccount,
            "adds": address,
            "cheque": Bank,
            // "maintenanceCode": formVal.maintenanceCode as string,
            "name": title,
            "phone": phone,
            "type": imgType
        }
        let result = isBooking ? await maintenanceService.appointmentExcelPrint({...params,appointmentCode:formVal.maintenanceCode as string,}) : await maintenanceService.excelPrint({ ...params, "maintenanceCode": formVal.maintenanceCode as string, })
        convertRes2Blob(result)

    }
    return <Modal
        title="打印详情"
        width={800}
        visible={visible}
        onCancel={onClose}
        footer={[
            <Button type="link" onClick={onClose} key="back"> 取消</Button>,
            <Button type="link" onClick={print} key="submit">确认打印</Button>,
            <Button type="link" onClick={setLogo} key="submit">设置Logo</Button>,
            <Button type="link" onClick={onExport} key="submit">导出excel</Button>,
        ]}
    >
        <div id="scania-print-container">
            <div className="scaina-contaier">
                <div className="scaina-header">
                    <div className="header-left">
                        <div className="logo">
                            <img src={imgVal} alt="斯堪尼亚" />
                        </div>
                        <h1>维修工单</h1>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>委托客户：<span>{formVal.customerName}</span></td>
                                </tr>
                                <tr className="tableft">
                                    <td>制造厂商：<span>斯堪尼亚</span></td>
                                    <td>车型：{formVal.vehicleModel}</td>
                                </tr>
                                <tr className="tableft">
                                    <td>车牌号：<span>{formVal.licensePlate}</span></td>
                                    <td>底盘号：<span>{formVal.vin}</span></td>
                                </tr>
                                <tr className="tableft">
                                    <td>送修人：<span>{formVal.contact}</span></td>
                                    <td>联系电话：<span>{formVal.phone}</span></td>
                                </tr>
                                <tr>
                                    <td>修理类型：<span>{formVal.serviceNatureName}</span></td>
                                    <td>车辆类型：<span>{formVal.vehicleTypeName}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>客户地址：<span>{formVal.customerAddress}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="header-right">
                        <h2>{title}</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>服务厂地址：{shopObject[shopCode]["address"]}</td>
                                </tr>
                                <tr>
                                    <td>电话：{shopObject[shopCode]["phone"]}</td>
                                </tr>
                                <tr>
                                    <td>传真：{shopObject[shopCode]["Fax"]}</td>
                                </tr>
                                <tr>
                                    <td>开户银行：{shopObject[shopCode]["Bank"]}</td>
                                </tr>
                                <tr>
                                    <td>开户行账号：{shopObject[shopCode]["BankAccount"]}</td>
                                </tr>
                                <tr>
                                    <td style={{ height: "18px" }}></td>
                                </tr>
                                <tr>
                                    <td>工单号：<span>{formVal.maintenanceCode}</span></td>
                                </tr>
                                <tr>
                                    {/* <td>预约进厂时间：<span>{baseInfo.deliveryTime}</span></td> */}
                                    <td>预约进厂时间：<span>{formVal.enterTime}</span></td>
                                </tr>
                                <tr>
                                    <td>预约出厂时间：<span>{formVal.deliveryTime}</span></td>
                                </tr>
                                <tr>
                                    <td>接单员：{formVal.createUser}</td>
                                </tr>
                                <tr>
                                    <td>行驶里程：<span>{formVal.mileage}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <table className="mingxi">
                    <tbody>
                        <tr>
                            <td colSpan={7}>故障描述：<span>{formVal.faultDesc}</span></td>
                        </tr>
                        <tr>
                            <td colSpan={7}>原因分析和处理意见：<span>{formVal.faultResult}</span></td>
                        </tr>
                        <tr className="print-center" >
                            {itemClumns && itemDataSource && itemDataSource.length > 0 && itemClumns.filter(item => item).map((item, index) =>
                                <td key={index}>{item.title}</td>
                            )}
                        </tr>
                        {itemDataSource && itemDataSource.length > 0 &&
                            itemDataSource.map((item, index) =>
                                <tr key={index} className="print-center">
                                    {itemGoods(item)}
                                </tr>
                            )}
                        <tr>
                            <td style={{ textAlign: "right", "paddingRight": "15px" }} colSpan={7}>工时小计：<span>{formVal.workingHoursAmount}</span></td>
                        </tr>
                        <tr className="print-center" >
                            {columns && dataSource && dataSource.length > 0 && columns.filter(item => item).map((item, index) =>
                                <td key={index}>{item.title}</td>
                            )}
                        </tr>
                        {dataSource && dataSource.length > 0 &&
                            dataSource.map((item, index) =>
                                <tr key={index} className="print-center">
                                    {mapGoods(item)}
                                </tr>
                            )
                        }
                        <tr>
                            <td style={{ textAlign: "right", "paddingRight": "15px" }} colSpan={7}>零件小计：<span>{formVal.materialScienceAmount}</span></td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "right", "paddingRight": "15px" }} colSpan={7}>总合计：<span>{formVal.receivableTotalAmount}</span></td>
                        </tr>
                        <tr className="re">
                            <td colSpan={7}>
                                <p>备注：{formVal.note}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="footer">
                    <p>制单员：<span>{formVal.createUser}</span></p>
                    <p>声明：</p>
                    <p>1.本人同意按贵厂工单所列的修理项目修理，愿意支付有关项目需要更换的零件款及修理费</p>
                    <p>2.车辆翻新，拆装前后挡风玻璃，如有损坏，我厂概不赔偿</p>
                    <p>3.车主提车时，请对我厂修理项目，车况检查清楚，出厂后概不负责</p>
                    <p style={{ "marginTop": "30px" }} className={`weituo`}>委托人：<span>&nbsp;</span></p>
                    <p style={{ "marginTop": "30px", textAlign: "right" }}>打印日期:&nbsp;&nbsp;&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm')}</p>
                </div>
            </div>
        </div>

    </Modal>
}
