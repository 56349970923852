import React, { useState, useEffect } from 'react'
import { SaleScaniaPrint } from "@/components/SaleScaniaPrint"
import { formatNum, numberParseChina } from '@/utils/utils';
import { saleRoService } from '../saleRoService';
import { useSaleRoModel } from '../saleRoModel';
import { ISaleRoDetail, IDetailList } from '../saleRoTypes';
import moment from 'moment';
import useGlobalModel from '@/model/globalModel';
export const SettleScaniaPrints = ({
}) => {
    // settleScaniaPrint, setSettleScaniaPrint
    const { shopAndOrg: { shopCode } } = useGlobalModel()

    const { settleScaniaPrint: visible, selectedRow, setSettleScaniaPrint } = useSaleRoModel()
    const onClose = () => {
        setSettleScaniaPrint(false)
    }
    const print = () => {

    }
    const [formVal, setFormVal] = useState<ISaleRoDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IDetailList[]>([]);
    // const [itemList, setItemList] = useState<IItemDetailList[]>([]);
    useEffect(() => {
        visible && getDetailOne()
    }, [visible])
    const getDetailOne = async () => {
        let { retData } = await saleRoService.one(selectedRow.returnCode as string);
        let { details, receivedAmount, settleTime, ...from } = retData
        receivedAmount = +formatNum(receivedAmount)
        const newMaterialDetails = details?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        if (retData) {
            setMaintenanceList(newMaterialDetails as IDetailList[] ?? []);
            // setItemList(newItemDetails as IItemDetailList[] ?? []);
            setFormVal({
                ...from,
                maintenanceCode:from.returnCode,
                receivableTotalAmount: receivedAmount,
                receivableTotalAmountCN: numberParseChina(receivedAmount),
                enterTime: moment(settleTime).format("YYYY-MM-DD HH:mm")
            })
        }
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },

        {
            title: '工时',
            dataIndex: 'workHour',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text: any) => text + "%"
        },
        {
            title: '工时费',
            dataIndex: 'receivableAmount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '材料编号',
            dataIndex: 'materialCode',
        },
        {
            title: '材料名称',
            dataIndex: 'materialName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text: any) => text + "%"
        },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    let IHeader = [
        {
            fieldLabel: "工号：",
            fieldValue: formVal.maintenanceCode
        }, {
            fieldLabel: "支付方式：",
            fieldValue: formVal.paymentMethodName
        }, {
            fieldLabel: "打印日期：",
            fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm")
        },
    ]
    return <SaleScaniaPrint
        title={`${formVal.bentityName}`}
        visible={visible}
        onClose={onClose}
        columns={columns}
        itemClumns={itemClumns}
        itemDataSource={[]}
        dataSource={maintenanceList.filter(item => item.num !== 0)}
        formVal={formVal}
        shopCode={shopCode}

    />
}