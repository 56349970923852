import React from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { DataTable } from "./DataTable";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { usePsiBusModel } from "../psibusModel";

export const DataBox = () =>{
    const {descTotal,exportPsi } = usePsiBusModel();

    const action = <>
    <Button
        className="mr-5"
        type="link"
        shape="round"
        onClick={() => exportPsi()}
    >导出</Button>
 </>;

const desc: IDescription[] = [

    {
        label: "库存差异数量",
        value: descTotal?.differenceNumTotal,
        color: descTotal?.differenceNumTotal != 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
        animation: descTotal?.differenceNumTotal != 0
    },
    {
        label: "占用差异数量",
        value: descTotal?.occupyDifferenceTotal,
        color: descTotal?.occupyDifferenceTotal != 0 ? DescriptionColorEnum.green : DescriptionColorEnum.red,
        animation: descTotal?.occupyDifferenceTotal != 0
    },
    {
        label: "库存数量",
        value: descTotal?.currentNumTotal ?? 0,
        color: DescriptionColorEnum.red
    },
]
    return (
    <YhBox title={action} descriptions={desc}>
          <DataTable />
    </YhBox>
    )

}