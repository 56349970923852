import React, { useEffect, useRef, useState } from "react";
import { Button, message } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { useWarehouseModel } from "../../warehouseModel";
import { IVRMWarehouseDetail } from "../../warehouseTypes";
import { getFormValidatorErrText } from "@/utils/utils";
import { WarehouseDetailsTabs } from "./WarehouseDetailsTabs";
import { debounce } from "lodash";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";


export function WarehouseDatalis() {
    const {
        editFormVal,
        updateEditFormVal,
        editWarehouse,
        getWarehouseOne,
        type, setType
    } = useWarehouseModel();
    const { CustomerData } = useGlobalModel()
    const { getSelectedDictOne } = useDictModel();

    const formRef = useRef<IYhFormRef>();
    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (!editFormVal.id) {
            getWarehouseOne(detailsInfo.id)
        }
    }, [detailsInfo])

    //清楚缓存
    useEffect(() => {
        return () => {
            !findNav("13-3-1") && updateEditFormVal(null);
        }
    }, [selectedNavId])

    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, "13-3");
    }

    //仓库修改提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            let { customerCode, ...params } = res
            let from:any = {
                ...params,
                customerCode,
                customerName:customerCode? getFindItem(customerCode as string, CustomerData).name:"",
                enable: res.enable ? 1 : 0,
            }
            editWarehouse({ ...from, id: detailsInfo.id as string } as IVRMWarehouseDetail).then(res => {

                if (res) {
                    onClose();
                    updateEditFormVal(null);
                }
            })
        }).catch(err => {
            console.log(err)
            // message.warning(getFormValidatorErrText(err));
        })
    }
    const customerChange = (value: string, option: ISelectOption) => {
        option && onChange({ customerName: option.label, customerCode: option.value });
    }
    //实时缓存
    const onChange = (changedValues: any) => {
        updateEditFormVal(changedValues);
    }

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_SAVE}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { onSubmit() }, 900)}
            >保存</Button>
        </YhAuth>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { formRef.current?.resetFields(); getWarehouseOne(detailsInfo.id); message.success("重置成功") }}
        >重置</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => replaceNav(selectedNavId, "13-3")}
        >取消</Button>
    </>;

    const typeChange = (value: string, option: ISelectOption) => {
        console.log(option)
        if (value === "warehouse-category.big") {
            setType(false)
        } else if (value === "warehouse-category.small") {
            setType(true)
        }
    }
    const baseFormItem: IYhFormItemsBox = {
        title: "仓库详情",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        action: action,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "仓库编码",
                fieldName: "code",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "仓库名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入仓库名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "从属门店",
                fieldName: "shopName",
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "仓库类型",
                fieldName: "type",
                options: getSelectedDictOne(DictCodeEnmu.WAREHOUSE_CATEGORRY),
                allowClear: true,
                showSearch: true,
                onSelectChange: typeChange,
                rules: [{ required: true, message: "请选择仓库类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户名称",
                fieldName: "customerCode",
                options: transOptions(CustomerData),
                onSelectChange: customerChange,
                rules: type ? [{ required: true, message: "请选择客户名称" }] : undefined,
                allowClear: true,
                showSearch: true,
                hidden: !type
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "负责人",
                fieldName: "contact"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "电话",
                fieldName: "phone"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "地址",
                fieldName: "address"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note"
            }, 
            {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "状态",
                fieldName: "enable"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden:true
            }
        ]
    };

    const warehouseFromConfig: IYhFormBox = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: editFormVal,
        onChange,
        boxs: [baseFormItem]
    }

    return (
        <>
            {/* <YhPageHeader title="仓库管理" /> */}
            <YhFormBox {...warehouseFromConfig} />
            {editFormVal.id ? <WarehouseDetailsTabs /> : null}
        </>
    )
}