import React, { } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useBusBusinessModel } from "../../busBusinessModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { DownOutlined } from "@ant-design/icons";
import { formatNum } from "@/utils/utils";
import { useDetailModel, ActionTypeEnum } from "../detail/detailModel";


export const DataBox = () => {
    const { selectedRowKeys, selectedRow, descTotal, rowsDescTotal, exportBusBusiness,exportDetailsBusBusiness } = useBusBusinessModel();
    const { navTabs, selectedNavId, setNavTabs, busAddNav, addNav, replaceNav } = useNavModel();
    // const { setCustomerParams, setBusBusiness } = useDetailModel()
    const { setActionType } = useDetailModel();

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let { customerCode, customerName, code, name } = selectedRow;
        busAddNav(
            '16-1-2',
            {
                id: "" as string, mode: "add", parentInfo: {
                    customerCode,
                    customerName,
                    warehouseCode: code,
                    warehouseName: name,
                }
            }
        );
        setActionType(ActionTypeEnum.STOCK)
    }
    const desc: IDescription[] = [
        {
            label: "库存数量",
            value: selectedRowKeys.length === 0 ? descTotal?.inventoryNum??0 : (+formatNum(rowsDescTotal?.inventoryNum??0)),
            color: DescriptionColorEnum.red
        },
        {
            label: "销售数量",
            value: selectedRowKeys.length === 0 ? descTotal?.saleNum??0 : (+formatNum(rowsDescTotal?.saleNum??0)),
            color: DescriptionColorEnum.red
        },
        {
            label: "销售金额",
            value: selectedRowKeys.length === 0 ? descTotal?.saleAmount??0 : formatNum(rowsDescTotal?.saleAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "调配数量",
            value: selectedRowKeys.length === 0 ? descTotal?.preNum??0 : formatNum(rowsDescTotal?.preNum??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "可用库存数",
            value: selectedRowKeys.length === 0 ? descTotal?.availableStock??0 : formatNum(rowsDescTotal?.availableStock??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "库存金额",
            value: selectedRowKeys.length === 0 ? descTotal?.inventoryAmount??0 : formatNum(rowsDescTotal?.inventoryAmount??0),
            color: DescriptionColorEnum.red
        }
    ]

    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >查看</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportBusBusiness().then()}
        >导出</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportDetailsBusBusiness().then()}
        >详情导出</Button>
        {/* <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown> */}
    </>;

    return (
        <>
            <YhBox title={action} descriptions={desc}>
                <DataTable />
            </YhBox>
        </>
    )
}