import React from "react";
import { useStockPoDetailModel } from "../purchaseDetailsModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel from "@/model/globalModel";
import { DictCodeEnmu } from '@/types/VrmSaDictEnums';



export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setSelectedRowKeys
    } = useStockPoDetailModel();

    const { getSelectedDictOne } = useDictModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "是否有占用",
            fieldName: "occupy",
            options: [{ label: "是", value: 2 }, { label: "否", value: 1 }],
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "是否有库存",
            fieldName: "inventoryNum",
            options: [{ label: "是", value: 1 }, { label: "否", value:2 }],
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "采购单号",
            fieldName: "purchaseCode",
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品编号",
            fieldName: "materialCode",
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品名称",
            fieldName: "materialName",
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "品牌",
            fieldName: "brandCode",
            allowClear: true,
            showSearch: true,
            options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND),
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "供应商名称",
            fieldName: "supplierName",
            xxl: 3,
        },
    ]


    return (
        <YhPageHeader
            title="采购明细"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => {loadData({ pageNum: 1 }, { ...searchParam, ...val });setSelectedRowKeys([]);}}
            handleReset={resetSearchParam}
        />
    );
}