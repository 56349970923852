import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IFollowUpDetail, IFollowUpSearch } from "./followUpType";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table/interface";
import { transPgToPagination } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { returnService } from "./followUpService";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";

export const useFollowUpModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IFollowUpSearch = {};
    const initDataSource: IFollowUpDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //查询条件
    const [searchParam, setSearchParam] = useState<IFollowUpSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IFollowUpDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IFollowUpDetail>({});

    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IFollowUpDetail)
    }, [selectedRowKeys])

    const updateSearchParam = (updateParam: IFollowUpSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }
    const loadData = async (page?: ISearchPage, searchValue: IFollowUpSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await returnService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    const settleExport = async (searchValue: IFollowUpSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await returnService.settleExport(searchParams);
        convertRes2Blob(result)
    }
    return {
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        initPgCfg,
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setSelectedRow,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        settleExport
    }
})