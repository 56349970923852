import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTransferDataMonitorModel } from "../transferDataMonitorModel";
import { ITransferDataMonitorDetail } from "../transferDataMonitorService";
import { formatNum } from "@/utils/utils";
import "./style.css"
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = useTransferDataMonitorModel();
    const { getTreeNodeName } = useDictModel(() => []);
    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<ITransferDataMonitorDetail> = [
        {
            align: "center",
            width: 60,
            ellipsis: true,
            title: '序号',
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调拨单号',
            dataIndex: 'code',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '单据类型',
            dataIndex: 'typeName',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调拨单状态',
            dataIndex: 'allotStatus',
            render: text => getTreeNodeName(text)
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调入门店',
            dataIndex: 'shopName',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调出门店',
            dataIndex: 'outShopName',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调拨数量',
            dataIndex: 'totalNum',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调拨金额',
            dataIndex: 'totalAmount',
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调拨成本金额',
            dataIndex: 'costAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调拨毛利金额',
            dataIndex: 'grossProfit',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '业务单据编号',
            dataIndex: 'saleCode',
            render: (text: any) => <YhTootip text={text} />,
        },

        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据状态',
            dataIndex: 'status',
            render: text => getTreeNodeName(text)
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据数量',
            dataIndex: 'saleNum',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据金额',
            dataIndex: 'saleAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据成本',
            dataIndex: 'saleCost',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据毛利',
            dataIndex: 'saleProfit',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '数量差异',
            dataIndex: 'saleVarianceQuantity',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '金额差异',
            dataIndex: 'saleVarianceAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '成本差异',
            dataIndex: 'costVarianceAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '毛利差异',
            dataIndex: 'profitVarianceAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            title: '调拨单制单日期',
            dataIndex: 'createTime',
        },
        {
            align: "center",
            width: 100,
            title: '调入/出日期',
            dataIndex: 'saleCreateTime',
        },
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id),
            //     onContextMenu: (e) => {
            //         e.preventDefault();
            //         setSelectedRowKeys([record.id]);
            //     }
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 360px)" }}
        />
    )
}
