import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { globalPrompt } from "@/components/message";
import { transPgToPagination } from "@/utils/utils";
import { technicianService } from "./technicianService";
import { ITechnicianDetail, ITechnicianFields } from "./technicianType"
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { useLockFn } from "ahooks";
export const useTechnicianModel = createModel(function () {
    const { shopAndOrg, loadTechnician } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //列表数据
    const [dataSource, setDataSource] = useState<ITechnicianDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<ITechnicianFields>({});
    //分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格选中id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中行的值
    const [selectedRow, setSelectedRow] = useState<ITechnicianDetail>({});

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<ITechnicianFields>({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState<ITechnicianFields>({});
    //弹框显示控制
    const [detailDrawer, setDetailDrawer] = useState<boolean>(false)
    //弹框状态值
    const [detailType, setDetailType] = useState<string>("");

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as ITechnicianDetail)
    }, [selectedRowKeys])

    //设置搜索value
    const updateSearchParam = (updateParam: ITechnicianFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //列表查询
    const loadData = async (page?: ISearchPage, searchValue: ITechnicianFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await technicianService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        loadTechnician(shopAndOrg.shopId)
    }
    //表单新增修改
    const updateAddFormVal = (val: ITechnicianFields) => {
        let newVal = val
        Object.keys(val).length !== 0 ? setAddFormVal({ ...addFormVal, ...newVal }) : setAddFormVal({});
    }
    //表单新增修改
    const updateEditFormVal = (val: ITechnicianFields) => {
        let newVal = val
        Object.keys(val).length !== 0 ? setEditFormVal({ ...editFormVal, ...newVal }) : setEditFormVal({});
    }
    //表单新增 Api
    const insertDetail = useLockFn(async (data: ITechnicianFields) => {
        let newData = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await technicianService.insert(newData);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "维修技师新增成功", type: "success" })
        } else {
            globalPrompt("message", { text: "维修技师新增失败", type: "error" });
        }
        return retData;
    })

    //表单编辑 Api
    const editDetail = useLockFn(async (data: ITechnicianFields) => {
        let newData = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await technicianService.update(newData);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "维修技师编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "维修技师编辑失败", type: "error" });
        }
        return retData;
    })
    const onDisabled = async (id: string, checked: boolean) => {
        let { retData } = await technicianService.disable({ id, enable: checked ? 1 : 0 })
        if (retData) {
            loadData()
            globalPrompt("message", { text: "维修技师编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "维修技师编辑失败", type: "error" });
        }
        return retData;
    }
    return {
        dataSource,
        searchParam,
        pgCfg,
        selectedRowKeys,
        addFormVal,
        editFormVal,
        detailDrawer,
        detailType,
        selectedRow,
        initPgCfg,
        setAddFormVal,
        setSelectedRowKeys,
        setPgCfg,
        setSearchParam,
        setDataSource,
        updateSearchParam,
        resetSearchParam,
        loadData,
        updateAddFormVal,
        updateEditFormVal,
        insertDetail,
        editDetail,
        setDetailDrawer,
        setDetailType,
        setSelectedRow,
        onDisabled,
    }
})