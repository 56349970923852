import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSettlementModel } from "./settlementModel";
import { ISettlementFildes } from "../../homeService"
import { momentFormat } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import useNavModel from "@/model/navModel";


type statusType = {
    "purchase-plan.transfer": string,
    "purchase-plan.create": string,
    [key: string]: any
}

export function DataTable() {

    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows
    } = useSettlementModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string, type: string) => {
        if (type === "维修工单") {
            let supplierEditKey = "4-3";
            onReplaceNav(code, supplierEditKey)
        } else if (type === "采购单") {
            let supplierEditKey = "11-2-3";
            onReplaceNav(code, supplierEditKey)
        } else if (type === "采退单") {
            let supplierEditKey = "11-3-3";
            onReplaceNav(code, supplierEditKey)
        } else if (type === "销售单") {
            let supplierEditKey = "5-2-3";
            onReplaceNav(code, supplierEditKey)
        } else if (type === "销退单") {
            let supplierEditKey = "5-3-3";
            onReplaceNav(code, supplierEditKey)
        }
    }
    const onReplaceNav = (code: string, supplierEditKey: string) => {
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const statusColor: statusType = {
        "purchase-plan.transfer": "blue",
        "purchase-plan.create": "cyan",
    }
    const columns: ColumnsType<ISettlementFildes> = [
        {
            title: '序号',
            width: 60,
            render: (t, r, i) => `${i + 1}`,
            fixed: "left",
        }, {
            title: '单据编号',
            width: 150,
            dataIndex: 'documentNumber',
            fixed: "left",
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => {onLook(text, record.type)}}>{text}</p></Tooltip>,

        }, {
            title: '单据类型',
            width: 160,
            dataIndex: 'type',
            fixed: "left",
        }, {
            title: '客户/供应商',
            width: 160,
            dataIndex: 'userName',
        }, {
            title: '客户属性',
            width: 160,
            dataIndex: 'userType',
        }, {
            title: '联系人',
            width: 160,
            dataIndex: 'contact',
        }, {
            title: '联系电话',
            width: 120,
            dataIndex: 'phone',
        }, {
            title: '结算方',
            width: 160,
            dataIndex: 'settlementPartyName',
        }, {
            title: '结算类型',
            width: 160,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '应收/应付',
            width: 200,
            dataIndex: 'alreadyAmount',
        }, {
            title: '制单员',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '制单时间',
            width: 160,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];

    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (record: ISettlementFildes) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let findIndexRowKeys = selectedRowKeys.findIndex((item) => (item === id))

        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];
        console.log(findIndexDesc)
        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
        // let findIndex = selectedRowKeys.indexOf(id);
        // let newSelectedRowKeys = [...selectedRowKeys];
        // if (findIndex === -1) {
        //     newSelectedRowKeys.push(id);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // } else {
        //     newSelectedRowKeys.splice(findIndex, 1);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // }
    };
    const onRightClick = (record: ISettlementFildes) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: ISettlementFildes[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
