import { useState, Key, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { vehicleService } from "./vehicleService";
import { IVehicleSearch, IVehicleDetail, IVehicleFields } from "./vehicleType"
import { globalPrompt } from "@/components/message";
import { convertRes2Blob } from "@/utils/exportFile";
import { transPgToPagination } from "@/utils/utils";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel, { getFindItem } from "@/model/globalModel";
import { useDictModel } from "../../dict/dictModel";
import moment from "moment";
import { useLockFn } from "ahooks";
export const useVehicleModel = createModel(function () {
    const { shopAndOrg, user, CustomerData, setVehicleData } = useGlobalModel()
    const { getTreeNodeName } = useDictModel();
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //列表数据
    const [dataSource, setDataSource] = useState<IVehicleDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IVehicleFields>({});
    //分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<IVehicleFields>({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState<IVehicleFields>({});
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState<IVehicleFields>({});
    //设置搜索value
    const updateSearchParam = (updateParam: IVehicleFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //列表查询
    const loadData = async (page?: ISearchPage, searchValue: IVehicleFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await vehicleService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    //所有列表查询
    const loadVehicleAllData = async () => {
        const params = {
            pageSize: 1000,
            pageNum: 1,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await vehicleService.page(params);
        const { records, ...pg } = retData;

        setVehicleData(records)
    }
    //表单新增修改
    const updateAddFormVal = (val: object | null) => {
        val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal({});
    }
    //表单新增修改
    const updateEditFormVal = (val: object | null) => {
        val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({});
    }
    //获取一条详情数据 Api
    const getDetailOne = async (id: string, pattern: string) => {
        let { retData } = await vehicleService.one(id);
        // retData && setEditFormVal(retData);
        let newRetData = {
            ...retData,
            registerDate: retData.registerDate ? moment(retData.registerDate) : null
        }
        if (retData && pattern === "edit") {
            setEditFormVal(newRetData);
        } else if (retData && pattern === "look") {
            setLookFormVal(newRetData);
        }
    }
    const setParams = (data: IVehicleDetail) => {
        return {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            enable: data.enable ? 1 : 0,
            categoryName: getTreeNodeName(data.categoryCode as string),
            brandName: getTreeNodeName(data.brandCode as string),
            customerName: getFindItem(data.customerCode as string, CustomerData).name,
            contact: getFindItem(data.customerCode as string, CustomerData).contact,
            phone: getFindItem(data.customerCode as string, CustomerData).phone,
            address: getFindItem(data.customerCode as string, CustomerData).address,
            typeCode: getFindItem(data.customerCode as string, CustomerData).categoryCode,
            typeName: getFindItem(data.customerCode as string, CustomerData).categoryName,
            registerDate: data.registerDate ? moment(data.registerDate).format("YYYY-MM-DD HH:mm:ss") : null
        }
    }
    //表单新增 Api
    const insertDetail = useLockFn(async (data: IVehicleDetail) => {
        let params = setParams(data)
        let { retData } = await vehicleService.insert({ ...params, createUser: user.username, enable: 1 });
        if (retData) {
            loadData()
            globalPrompt("message", { text: "车辆新增成功", type: "success" })

        } else {
            globalPrompt("message", { text: "车辆新增失败", type: "error" });

        }
        return retData;
    })

    //表单编辑 Api
    const editDetail = useLockFn(async (data: IVehicleDetail) => {
        let params = setParams(data)
        let { retData } = await vehicleService.update({ ...params, updateUser: user.username, });
        if (retData) {
            loadData()
            globalPrompt("message", { text: "车辆编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "车辆编辑失败", type: "error" });
        }
        return retData;
    })

    //导入
    const importData = async (file: { file: File }) => {
        let { retData } = await vehicleService.import(file);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "导入成功", type: "success" })
        } else {
            globalPrompt("message", { text: "导入失败", type: "error" });

        }
        return retData;
    }

    //导出
    const exportData = async (searchValue: IVehicleSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await vehicleService.export(searchParams);
        convertRes2Blob(result);
    }
    //下载模板
    const downloadTpl = async () => {
        let result = await vehicleService.downloadTpl();
        convertRes2Blob(result);
        // let { retData } = await vehicleService.downloadTpl();
        // retData ?
        //     globalPrompt("message", { text: "下载模板成功", type: "success" })
        //     : globalPrompt("message", { text: "下载模板失败", type: "error" });
        // return retData;
    }
    //车辆禁用开启
    const onDisabled = async (id: string, checked: boolean) => {
        let { retData } = await vehicleService.disable({ id, enable: checked ? 1 : 0 })
        if (retData) {
            loadData()
            globalPrompt("message", { text: "车辆编辑成功", type: "success" })
        } else {

            globalPrompt("message", { text: "车辆编辑失败", type: "error" })
        }
        return retData;
    }
    return {
        dataSource,
        searchParam,
        pgCfg,
        selectedRowKeys,
        addFormVal,
        editFormVal,
        lookFormVal,
        initPgCfg,
        setAddFormVal,
        setSelectedRowKeys,
        setPgCfg,
        setSearchParam,
        setDataSource,
        updateSearchParam,
        resetSearchParam,
        loadData,
        updateAddFormVal,
        updateEditFormVal,
        getDetailOne,
        insertDetail,
        editDetail,
        importData,
        exportData,
        downloadTpl,
        setLookFormVal,
        onDisabled,
        loadVehicleAllData
    }
})