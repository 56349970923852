import React, { useEffect, useState } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, defaultMdCmParam } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { IBillDetail, IBIllFields, IBillSearch, IBillSearchParam, IBillSummary } from "../../../../../bill/BillTypes";
import { billService } from "../../../../../bill/BillService";
import { useDetailModel } from "../detailModel";
import { UserTypeEnum } from "@/types/FmsEnums";
import { useGlobalModel } from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { transPgToPagination } from "@/utils/utils";

export const useBillModel = createModel(function () {
    const initSearchParam: IBillSearchParam = {};
    const initDataSource: IBillDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const { supplierCode } = useDetailModel();
    const { user: { shopCode } } = useGlobalModel();

    //查询条件
    const [searchParam, setSearchParam] = useState<IBillSearchParam>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IBillDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedData, setSelectedData] = useState<IBIllFields>();
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    const [billSummaryData, setBillSummaryData] = useState<IBillSummary>({});
    const [writeoffDrawerVisible, setWriteoffDrawerVisible] = useState(false);


    const onSearch = () => {
        loadData({ pageNum: 1 }).then()
    }
    const onReset = () => {
        resetSearchParam()
    }


    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IBIllFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IBillSearchParam = searchParam) => {
        const searchParams: IBillSearch = {
            ...searchValue,
            partnerCode: supplierCode,
            partnerTypeCode: UserTypeEnum.SUPPLIER,
            shopCode,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await billService.page(searchParams);
        let { retData: billSummaryRetData } = await billService.billSummary(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setBillSummaryData(billSummaryRetData)
        setDataSource(records);
    }

    //供应商账单导出
    const billSupplierExport = async () => {
        const searchParams: IBillSearch = {
            ...searchParam,
            partnerCode: supplierCode,
            partnerTypeCode: UserTypeEnum.SUPPLIER,
            shopCode,
            page: {
                pageSize: -1,
                pageNum: 1
            }
        }
        let result = await billService.export(searchParams);
        convertRes2Blob(result);
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedData,
        writeoffDrawerVisible,
        billSummaryData,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setWriteoffDrawerVisible,
        onSearch,
        onReset,
        setSelectedData,
        billSupplierExport
    }
});
