import React from "react";
import { YhBox } from "@/components/YhBox";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { useTransferModel } from "./transferModel";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useAccountModel } from "../account/accountModel";


export const TransferSearchBox = () => {
    const {
        onReset,
        searchParam,
        onSearch,
        updateSearchParam,loadData
    } = useTransferModel()
    const {
        customerUserDs,
    } = useAccountModel();
    
    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "转入账户",
            fieldName: "accountInCode",
            options: customerUserDs
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "转出账户",
            fieldName: "accountOutCode",
            options: customerUserDs
        }, {
            type: SearchTypeEnum.FmsRangePicker,
            fieldName: "transferTimeRange",
            placeholder: ["起始日期", "结束日期"],
        },
    ]

    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title=""
                justify="end"
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                onFmsChange={updateSearchParam}
                                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={onReset}
            />
        </YhBox>
    )
}
