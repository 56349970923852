import React from "react";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { YhBox } from "@/components/YhBox";
import { YhPageHeader } from "@/components/YhPageHeader";
import { usePreWithdrawalModel } from "./preWithdrawalModel";

export const PreWithdrawalSearchBox = () => {
    const {
        searchParam, updateSearchParam, initPgCfg, resetSearchParam, loadData
    } = usePreWithdrawalModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName",
            xxl: 3,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "预退单号",
            fieldName: "returnCode",
            xxl: 3,
        }, {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        // {
        //     type: SearchTypeEnum.RangePicker,
        //     fieldName: "",
        //     placeholder: ["结算开始", "结算结束"],
        //     rangePickerFieldName: ["statSettleTime", "endSettleTime"],
        //     format: "YYYY-MM-DD",
        //     span: 12,
        //     xxl: 6,
        // },
    ]
    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title="预退列表"
                justify="end"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
        </YhBox>
    )
}