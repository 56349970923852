import React, { useEffect } from "react";
import { message, Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAccountModel } from "./accountModel";
import { IAccountDetail } from "../../../../AccountTypes";
import { useDetailModel } from "../detailModel";
import { accountService } from "../../../../AccountService";
import { useGlobalModel } from "@/model/globalModel";

export function AccountDataTable() {
    const { resourceAuth, appDs } = useGlobalModel(model => [model.resourceAuth, model.appDs])
    const {
        dataSource, selectedRowKeys,
        setSelectedRowKeys,
        loadData,
    } = useAccountModel();

    const handleChangeStatus = async (status: boolean, code: string) => {
        let result
        if (status) {
            result = await accountService.enable({ code })
        } else {
            result = await accountService.disable({ code })
        }
        if (result.success) {
            message.success('操作成功！')
            loadData().then()
        } else {
            message.error('操作失败！')
        }
    }
    const columns: ColumnsType<IAccountDetail> = [
        {
            align: "center",
            title: '分支',
            dataIndex: 'branch',
            render: (v) => {
                let item = appDs?.find((item: { value: any; }) => item.value === v)
                return item?.label
            }
        },
        {
            align: "center",
            title: '账户编码',
            dataIndex: 'code',
        },
        {
            align: "center",
            title: '账户名称',
            dataIndex: 'name',
        },
        {
            align: "center",
            title: '授信类型',
            dataIndex: 'creditFlag',
            render: (v) => v === 1 ? '记账' : v === 0 ? '现结' : ''
        },
        {
            align: "center",
            title: '账户类型',
            dataIndex: 'accountTypeName',
        },
        {
            align: "center",
            title: '账户实体',
            dataIndex: 'beName',
        },
        {
            align: "center",
            title: '从属机构',
            dataIndex: 'orgName',
        },
        {
            align: "center",
            title: '授信额度',
            dataIndex: 'creditAmount',
        },
        {
            align: "center",
            title: '已用额度',
            dataIndex: 'usedAmount',
        },
        {
            align: "center",
            title: '可用额度',
            dataIndex: 'availableAmount',
        },
        {
            align: "center",
            title: '剩余金额',
            dataIndex: 'remainingAmount',
        },
        {
            align: "center",
            title: '创建日期',
            dataIndex: 'createTime',
        },
        {
            align: "center",
            title: '备注',
            dataIndex: 'note',
        },
        // {
        //     title: '状态',
        //     dataIndex: 'status',
        //     width: 80,
        //     render: (v) => v === 0 ? "正常" : "异常"
        // },
        {
            align: "center",
            title: '操作',
            dataIndex: 'status',
            render: (text, record) =>
                (
                    <Switch
                        checkedChildren="已启用"
                        unCheckedChildren="已禁用"
                        defaultChecked
                        // disabled={!resourceAuth.find((item: any) => item === ResourceAuthEnum.ACC_S_VW_AC_DISABLE)}
                        checked={text === 0}
                        onChange={(v) => handleChangeStatus(v, record.code as string)}
                    />
                )
        },

    ];

    return (
        <Table
            rowSelection={{
                type: "radio",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IAccountDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            // pagination={{
            //     ...pgCfg,
            //     position: ["bottomRight"],
            //     onChange: (pageNum, pageSize) => {
            //         loadData({pageNum, pageSize}).then();
            //     }
            // }}
            onRow={record => {
                return {
                    onClick: event => {
                        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
                            setSelectedRowKeys([]);
                        } else {
                            setSelectedRowKeys([record.key]);
                        }
                    },
                };
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}
