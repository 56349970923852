import React, { FC } from "react";
import { Button, Table, Upload, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { globalPrompt } from "@/components/message";
import { ColumnsType } from "antd/es/table";
import { usePurchasePlanDetailsModel } from "./purchasePlanDetailsModel";
import { RouterParamType } from "@/model/navModel";
import { EditableCell, EditableRow } from "@/components/YHEditTable/editTable";
import { IDetailList } from "../../purchasePlanType"
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { ISelectOption } from "@/components/YhFormBox/Types";
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import { warehouseService } from "@/views/base/warehouse/warehouseService";
import { formatNum, formatIntPoint, bigNumber } from "@/utils/utils";
import { DetailType } from "@/types/ScmEnums";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { DictCodeEnmu } from '@/types/VrmSaDictEnums';

export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const {
        detailAddList,
        detailDataSource,
        detailLookList,
        shelfAllList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        setShelfAllList,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setAddModalVisible,
        setDetailAddList,
        setDetailDataSource,
        detailDetailList,
        downloadTpl,
        modelScreenMethod,
        setHistoryVisible,
        setHistoryRow
    } = usePurchasePlanDetailsModel();
    const onHistoryModel = (record: IDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    // 导入重复数据过滤
    const uniqueArr = (arr: any[], strs: string[]) => {
        const res = new Map()
        return arr.filter((arr) => !res.has(strs.map(str => arr[str]).join('-')) && res.set(strs.map(str => arr[str]).join('-'), 1))
    }
    const { warehouseList, shopAndOrg: { shopId } } = useGlobalModel()
    const importCallback = async (list: any) => {
        // let newList = uniqueArr(list, ['materialCode', 'price'])
        const { repetition, newDetailData } = await modelScreenMethod(list, detailAddList)
        if (mode === "add") {
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length !== 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        } else if (mode === "edit") {
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length !== 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
    }
    const controlBtn = () => {
        if (mode === 'add') {
            return true
        } else if (mode === 'edit' && detailEditForm.status !== DictCodeEnmu.PURCHASE_PLAN_TRANSFER) {
            return true
        } else if (mode === 'look') {
            return false
        }
    }
    const action = <>
        {controlBtn() && <>
            <YhAuth resCode={ResourceAuthEnum.P_PL_ED_ADD_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setAddModalVisible(true)}
                >新增配件</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_PL_ED_DEL_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { detailDetailList(mode) }}
                >批量删除</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_PL_ED_IM}>
                <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/purchase/plan/details/import/${shopId}`, importCallback)}>
                    <Button className="mr-5" type="link" shape="round">导入</Button>
                </Upload>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_PL_ED_DLOAD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={downloadTpl}
                >下载模板</Button>
            </YhAuth>

        </>}
    </>;
    //货位根据仓库code查询
    const queryShelf = async (wsCode: string) => {
        let params = {
            pageSize: 1000,
            pageNum: 1,
            warehouseCode: wsCode,
        }
        const { retData } = await warehouseService.shelfAllPage(params)
        const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
        setShelfAllList(newRecords)
    }
    const wsChanges = async (value: string, option: ISelectOption, record: IDetailList) => {
        if (option && option.value) {
            queryShelf(option.value as string)
        }
    }
    //货位触焦
    const shelfFocus = (record: any) => {
        if (record["warehouseCode"]) {
            queryShelf(record["warehouseCode"] as string)
        } else {
            setShelfAllList([]);
        }
    }
    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text} {record.icon === 1 && <span style={{ background: "orange", color: "white" }}>多</span>} </p></Tooltip>,
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 120,
            dataIndex: 'planNum',
            editable: mode === "add" ? true : false,
        }, {
            title: '采购单价',
            width: 120,
            dataIndex: 'price',
            editable: mode !== "look" ? true : false,
        }, {
            title: '采购金额',
            width: 120,
            dataIndex: 'amount',
            // editable: mode != "look" ? true : false,
        }, {
            title: '所属仓库',
            width: 150,
            dataIndex: 'warehouseCode',
            editable: mode !== "look" ? true : false,
            editType: "select",
            onSelectChange: wsChanges,
            options: transOptions(warehouseList?.filter((item: any) => item.type === "warehouse-category.big")),
            rules: [{ required: true, message: "请选择仓库" }],
            render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '所属货位',
            width: 150,
            dataIndex: 'shelfCode',
            editable: mode !== "look" ? true : false,
            editType: "select",
            onSelectFocus: shelfFocus,
            rules: [{ required: true, message: "请选择货位" }],
            options: transOptions(shelfAllList),
            render: (text: string) => text ?? "请选择货位",
        }, {
            title: '最小起订量',
            width: 120,
            dataIndex: 'minNum',
        }, {
            title: '到货数量',
            width: 120,
            dataIndex: 'num',
            editable: mode === "edit" ? true : false,
            // editable: mode != "look" ? true : false,

        }, {
            title: '已到货数量',
            width: 120,
            dataIndex: 'arrivedTotal',
            // editable: mode == "edit" ? true : false,
        }, {
            title: '未到货数量',
            width: 120,
            dataIndex: 'unarrivedNum',
            // editable: mode == "edit" ? true : false,
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
            editable: mode != "look" ? true : false,
        }
    ];
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            let disaBled = col.dataIndex === "num"
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: disaBled ? record.planNum !== record.arrivedTotal : col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = (row: IDetailList) => {
        let { num, planNum, price, warehouseCode, shelfCode, arrivedNum, arrivedTotal, unarrivedNum } = row
        num = +formatNum(num)
        planNum = +formatNum(planNum)
        price = +formatNum(price)
        arrivedTotal = +formatNum(arrivedTotal)
        unarrivedNum = +formatNum(unarrivedNum)
        num = +formatIntPoint(num, planNum, "到货数量>=采购数量")
        let newRow = {
            ...row,
            num,
            price,
            warehouseName: getFindItem(warehouseCode as string, warehouseList)?.name,
            shelfId: getFindItem(shelfCode as string, shelfAllList)?.id ?? row.shelfId,
            //amount: +formatNum(planNum * price),
            amount:+bigNumber.toFixed(bigNumber.times(planNum,price),2),
            arrivedNum,
           // arrivedTotal: arrivedTotal + num,
           arrivedTotal:bigNumber.add(arrivedTotal,num),
           // unarrivedNum: unarrivedNum - num
           unarrivedNum:bigNumber.minus(unarrivedNum,num)
        }
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.materialCode === item.materialCode);
        const item = newData[index];

        // //判断仓库变化时，  重置货位
        if (newRow.warehouseCode !== newData[index].warehouseCode) {
            newRow.shelfCode = undefined
        }
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
    }
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="配件信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.materialCode as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns() as ColumnsType<IDetailList>}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content', y: 290 }}
            />
        </YhBox>
    )
}