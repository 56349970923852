import { useState, Key, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import useGlobalModel from '@/model/globalModel';
import { IVehicleDetail } from '@/views/base/customerFiles/vehicle/vehicleType';
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from '@/utils/constants';
import { ISaleSoFields, IDetailList, ISaleSoSettleParams, ISettleDetails } from '../../saleSoTypes';
import { saleSoService } from '../../saleSoService';
import {
	IStockStatisticSearch,
	IStockStatisticDetail,
} from '@/views/stock/statistic/stockStatisticService';
import { IVRMshelfDetail } from '@/views/base/warehouse/warehouseTypes';
import { ISearchPage } from '@/types/ScmTypes';
import { formatNum, transPgToPagination,bigNumber } from '@/utils/utils';
import { globalPrompt } from '@/components/message';
import { DictCodeEnmu, sourceAppOrBranch } from '@/types/VrmSaDictEnums';
import { convertRes2Blob } from '@/utils/exportFile';
import { useLockFn } from 'ahooks';
import moment from 'moment';
import { message } from 'antd';
import { saleOrderService } from '@/views/sale/order/saleOrderService';
import { IPriceTypeEnum, ISalesTypeEnum } from '@/types/ScmEnums';

export type ISaleSoDescType = {
	numAll: number;
	itemNumAll: number;
	totalAll: number;
	discountAll: number;
	receivableAll: number;
	[key: string]: any;
};
//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IStockStatisticDetail {
	num: number;
	price?: number;
	amount?: number;
}
export const useSaleSoDetailModel = createModel(function () {
	const { user, shopAndOrg } = useGlobalModel();
	const { shopId, shopName } = shopAndOrg;
	const [logisticsDraer, setLogisticsDraer] = useState<boolean>(false); //物流登记弹框
	const [settleVisible, setSettleVisible] = useState<boolean>(false); //结算弹框

	const [changeSaleDrawer, serChangeSaleDrawer] = useState<boolean>(false); //转销售单弹框
	const [wsConfirmModal, setWsConfirmModal] = useState<boolean>(false); //库房确认弹框
	const [registerModal, setRegisterModal] = useState<boolean>(false); //入库登记弹框
	const [discountModal, setDiscountModal] = useState<boolean>(false); //折扣弹框
    const [loading, setLoading] = useState<boolean>(false);

	const [addModalVisible, setAddModalVisible] = useState<boolean>(false); //新增商品弹框
	const [vehicleEditAll, setVehicleEditAll] = useState<IVehicleDetail[]>([]); //实时切换车辆 编辑
	const [vehicleAddAll, setVehicleAddAll] = useState<IVehicleDetail[]>([]); //实时切换车辆 新增
	const initDesc = {
		numAll: 0, //数量
		itemNumAll: 0, //项数
		totalAll: 0, //总计
		discountAll: 0, //折扣,
		receivableAll: 0, //应收
	};
	const initForm = {
		shopId,
		shopName,
		favorableAmount: 0,
		maintenanceTypeName: '正常销售',
		maintenanceTypeCode: ISalesTypeEnum.NORMAL_SALES,
		priceTypeName: '零售价',
		priceTypeCode: IPriceTypeEnum.RETAIL,
	};
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const initSearchParam: IStockStatisticSearch = {};
	//新增数据
	//新增详情form
	const [detailAddForm, setDetailAddForm] = useState<ISaleSoFields>(initForm);
	//新增详情列表
	const [detailAddList, setDetailAddList] = useState<IDetailList[]>([]);
	//新增详情列表选中行
	const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState<Key[]>([]);
	const [detaiAddDataRow, setDetaiAddDataRow] = useState<IDetailList[]>([]);
	const [addDesc, setAddDesc] = useState<ISaleSoDescType>(initDesc);

	//查看数据
	//查看详情form
	const [detailLookForm, setDetailLookForm] = useState<ISaleSoFields>({});
	//查看详情列表
	const [detailLookList, setDetailLookList] = useState<IDetailList[]>([]);
	const [lookDesc, setLookDesc] = useState<ISaleSoDescType>(initDesc);
	//查看结算详情
	const [lookSettleDetails, setLookSettleDetails] = useState<ISettleDetails[]>([]);
	//详情数据
	//详情列表参数
	const [detailDataSource, setDetailDataSource] = useState<IDetailList[]>([]);
	//详情表格选中行
	const [detailDataRowKeys, setDetailDataRowKeys] = useState<Key[]>([]);
	const [detailDataRow, setDetailDataRow] = useState<IDetailList[]>([]);
	const [detailEditForm, setDetailEditForm] = useState<ISaleSoFields>({});
	const [editDesc, setEditDesc] = useState<ISaleSoDescType>(initDesc);
	//编辑结算详情
	const [editSettleDetails, setEditSettleDetails] = useState<ISettleDetails[]>([]);

	//配件列表配置
	const [workDataSource, setWorkDataSource] = useState<IMaterialDetailModel[]>([]);
	const [searchWorkData, setSearchWorkData] = useState<IStockStatisticSearch>(initSearchParam);
	const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//已选配件列表
	const [addDataSource, setAddDataSource] = useState<IMaterialDetailModel[]>([]);
	//表单改变或表格数据发生改变时，控制功能按钮的启用禁用
	const [topButsControl, setTopButsControl] = useState<boolean>(false);

	//实时详情列表货位数据
	const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([]);
	//设置底部总数展示（查看|编辑|查看）
	useEffect(() => {
		let newDesc: ISaleSoDescType = { ...initDesc };
		detailDataSource &&
			detailDataSource.forEach((item) => {
				newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
				newDesc.totalAll = bigNumber.add(	newDesc.totalAll ,Number(item.notAmount))
				newDesc.discountAll = bigNumber.add(newDesc.discountAll,Number(item.notAmount) - Number(item.amount))
				newDesc.receivableAll = bigNumber.add(newDesc.receivableAll,Number(item.amount))

				// newDesc.numAll += Number(item.num);
				// newDesc.totalAll += Number(item.notAmount);
				// newDesc.discountAll += Number(item.notAmount) - Number(item.amount);
				// newDesc.receivableAll += Number(item.amount);
			});
		if ('favorableAmount' in detailEditForm) {
			newDesc.receivableAll = bigNumber.minus(newDesc.receivableAll ,Number(detailEditForm.favorableAmount))
			newDesc.discountAll = bigNumber.add( newDesc.discountAll,Number(detailEditForm.favorableAmount))

			// newDesc.receivableAll = newDesc.receivableAll - Number(detailEditForm.favorableAmount);
			// newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount);
		}
		newDesc.itemNumAll = detailDataSource && detailDataSource.length;
		setEditDesc(newDesc);
	}, [detailDataSource, detailEditForm]);

	useEffect(() => {
		let newDesc: ISaleSoDescType = { ...initDesc };
		detailAddList &&
			detailAddList.forEach((item) => {
				newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
				newDesc.totalAll = bigNumber.add(	newDesc.totalAll ,Number(item.notAmount))
				newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus(Number(item.notAmount), Number(item.amount)))
				newDesc.receivableAll = bigNumber.add(newDesc.receivableAll,Number(item.amount))

				// newDesc.numAll += Number(item.num);
				// newDesc.totalAll += Number(item.notAmount);
				// newDesc.discountAll += Number(item.notAmount) - Number(item.amount);
				// newDesc.receivableAll += Number(item.amount);
			});
		if ('favorableAmount' in detailAddForm) {
			newDesc.receivableAll = bigNumber.minus(newDesc.receivableAll ,Number(detailAddForm.favorableAmount))
			newDesc.discountAll = bigNumber.add( newDesc.discountAll,Number(detailAddForm.favorableAmount))

			// newDesc.receivableAll = newDesc.receivableAll - Number(detailAddForm.favorableAmount);
			// newDesc.discountAll = newDesc.discountAll + Number(detailAddForm.favorableAmount);
		}
		newDesc.itemNumAll = detailAddList && detailAddList.length;
		setAddDesc(newDesc);
	}, [detailAddList, detailAddForm]);

	useEffect(() => {
		let newDesc: ISaleSoDescType = { ...initDesc };
		//console.log(111)
		
		detailLookList &&
			detailLookList.forEach((item,i) => {
				console.log(Number(item.notAmount) - Number(item.amount))
				if(Number(item.notAmount) - Number(item.amount)){
					console.log(i)
					console.log(Number(item.notAmount),Number(item.amount))
				}
				newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
				newDesc.totalAll = bigNumber.add(	newDesc.totalAll ,Number(item.notAmount))
				newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus(Number(item.notAmount),Number(item.amount)))
				newDesc.receivableAll = bigNumber.add(newDesc.receivableAll,Number(item.amount))

				// newDesc.numAll += Number(item.num);
				// newDesc.totalAll += Number(item.notAmount);
				// newDesc.discountAll += Number(item.notAmount) - Number(item.amount);
				// newDesc.receivableAll += Number(item.amount);
			});
		if ('favorableAmount' in detailLookForm) {
			newDesc.receivableAll = bigNumber.minus(newDesc.receivableAll ,Number(detailLookForm.favorableAmount))
			newDesc.discountAll = bigNumber.add( newDesc.discountAll,Number(detailLookForm.favorableAmount))

			// newDesc.receivableAll = newDesc.receivableAll - Number(detailLookForm.favorableAmount);
			// newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount);
		}
		newDesc.itemNumAll = detailLookList && detailLookList.length;
		setLookDesc(newDesc);
	}, [detailLookList, detailLookForm]);

	//新增表单缓存
	const updateAddFormVal = (val: ISaleSoFields) => {
		let newVal = val;
		setDetailAddForm({ ...detailAddForm, ...newVal });
	};
	//编辑表单缓存
	const updatEditFormVal = (val: ISaleSoFields) => {
		let newVal = val;
		setDetailEditForm({ ...detailEditForm, ...newVal });
	};
	//获取一条编辑或查看数据
	const getDetailEditOne = async (id: string, pattern: string) => {
		let { retData } = await saleSoService.one(id);
		const { details, settleDetails, saleTime,md5,fileName, ...from } = retData;
		const newDetails = details?.map((item) => {
			return {
				...item,
				// notAmount: +formatNum(((Number(item.num) * 100) * (Number(item.price)*100)) / 10000),
				notAmount:+bigNumber.toFixed(bigNumber.dividedBy(bigNumber.times(bigNumber.times(Number(item.num),100),bigNumber.times(Number(item.price),100)),10000),2),
				// termNum: Number(item.num) + Number(item.inventoryNum), //判断编辑数量的条
				termNum:bigNumber.add(Number(item.num),Number(item.inventoryNum)),  //判断编辑数量的条
			};
		});
		let fileList: any = []
		if (md5) {
			fileList = [
				{
					uid: md5,
					status: 'done',
					name: fileName,
					response: {
						retData: md5,
					},
				},
			]
		}
		let newFrom = {
			...from,
			fileList:fileList,
			saleTime: saleTime ? moment(saleTime) : null,
		};
		if (retData && pattern === 'edit') {
			setDetailDataSource((newDetails as IDetailList[]) ?? []);
			setDetailEditForm(newFrom);
			setTopButsControl(false);
			setEditSettleDetails((settleDetails as ISettleDetails[]) ?? []);
		} else if (retData && pattern === 'look') {
			setDetailLookForm(newFrom);
			setDetailLookList((newDetails as IDetailList[]) ?? []);
			setLookSettleDetails((settleDetails as ISettleDetails[]) ?? []);
		}
	};
	//关闭弹框
	const resetWorkloadData = async () => {
		setSearchWorkData({});
		workloadData(initPgCfg, {});
	};
	//加载配件数据
	const workloadData = async (
		page?: ISearchPage,
		searchValue: IStockStatisticSearch = searchWorkData,
		mode?: string
	) => {
		const searchParams = {
			...searchValue,
			...shopAndOrg,
			...sourceAppOrBranch,
			age: 1,
			status: `${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
			pageSize: searchValue.purchaseCode?500:page?.pageSize ?? workPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
			pageNum: (page?.pageNum || page?.current) ?? workPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
		};
		const {maintenanceTypeCode: newMaintenanceTypeCode, priceTypeCode}= mode === 'add' ? detailAddForm : detailEditForm;
		setLoading(true);
		let { retData } = await saleOrderService.stock(searchParams);
		setLoading(false);
		const { records, ...pg } = retData;
		const newRecords:any = records.map((item) => {
			let { lateSalePrice, latestPurPrice, purchasePrice, retailPrice } = item;
			let newPrice = newMaintenanceTypeCode === 'Sales type.normal sales' || newMaintenanceTypeCode === undefined
					? priceTypeCode === IPriceTypeEnum.RETAIL ? retailPrice : lateSalePrice === 0 || lateSalePrice === null ? retailPrice ?? 0 : lateSalePrice ?? 0 : latestPurPrice === 0
					? purchasePrice
					: latestPurPrice;
			return {
				...item,
				num: item.availableStock??1,				
				lateSalePrice: newPrice==0?latestPurPrice:newPrice,
				amount: bigNumber.toFixed(bigNumber.times(item.availableStock??1,newPrice==0?(latestPurPrice as number):(newPrice as number)),2) 
			};
		});
		setWorkPgCfg(transPgToPagination(pg));
		setWorkDataSource(newRecords);
	};

	//筛选模块详情 详情和新增的列表已有数据的方法
	const modelScreenMethod = async (
		modelList: IMaterialDetailModel[],
		detailList: IDetailList[]
	) => {
		const repetition: string[] = [];
		const newDetailData: IDetailList[] = [];
		modelList.forEach((Item) => {
			let isSelect = detailList && detailList.find((item) => item.purchaseId === Item.id);
			let {
				id,
				ids,
				materialCode,
				materialName,
				latestPurPrice,
				inventoryNum,
				availableStock,
				num,
				price,
				lateSalePrice,
				averagePurchasePrice,
				purchasePrice,
				amount,
				...params
			} = Item;
			let newPrice = averagePurchasePrice ?? purchasePrice;
			let costPrice = latestPurPrice ?? purchasePrice;
			if (isSelect === undefined) {
				newDetailData.push({
					...Item,
					price: lateSalePrice ?? price,
					notAmount: amount,
					inventoryNum: availableStock,
					discountRate: 100,
					discountedPrice: lateSalePrice ?? price,
					stockId: id,
					id: '',
					costPrice,
					//costAmount: +formatNum(Number(costPrice) * num),
					costAmount:+bigNumber.toFixed(bigNumber.times(costPrice as number,num),2),
					decreaseAmount: 0,
					purchaseId: id,
				});
			} else {
				repetition.push(Item.materialName as string);
			}
		});
		return { newDetailData, repetition };
	};
	//添加到详情列表
	const transformsToPoDetailList = async (mode: string) => {
		//添加列表
		if (mode === 'add') {
			const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList);
			setDetailAddList([...detailAddList, ...newDetailData]);
			repetition.length != 0 &&
				globalPrompt('message', {
					text: `物料名称：${repetition.join(',')}，重复的将不在添加。`,
					type: 'warning',
				});
		} else if (mode === 'edit') {
			//详情列表
			const { repetition, newDetailData } = await modelScreenMethod(
				addDataSource,
				detailDataSource
			);
			setDetailDataSource([...detailDataSource, ...newDetailData]);
			repetition.length != 0 &&
				globalPrompt('message', {
					text: `物料名称：${repetition.join(',')}，重复的将不在添加。`,
					type: 'warning',
				});
		}
	};
	//删除详情列表数据
	const detailDetailList = async (mode: string, Material?: IDetailList) => {
		// debugger
		if (mode === 'add') {
			const filterDetailList = detailAddList.filter(
				(el) => !!!detaiAddDataRowKeys.find((ele) => ele === el.purchaseId)
			);
			setDetailAddList(filterDetailList);
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetaiAddDataRowKeys([]);
			setDetaiAddDataRow([]);
		} else if (mode === 'edit') {
			const filterDetailList = detailDataSource.filter(
				(el) => !!!detailDataRowKeys.find((ele) => ele === el.purchaseId)
			);
			setDetailDataSource(filterDetailList);
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetailDataRowKeys([]);
			setDetailDataRow([]);
		}
	};

	//表单配件校验 (开单数量不能为0)
	const verifyGoods = (mode: string): boolean => {
		let goods: IDetailList[] = mode === 'edit' ? detailDataSource : detailAddList;

		let zeroNum = goods.filter(
			(item: IDetailList) => Number(item.price) < Number(item.latestPurPrice)
		);
		if (zeroNum.length) {
			// message.warning(`【${zeroNum.map(item => item.materialName).join()}】配件的开单价格`)
			message.warning(
				`${zeroNum
					.map(
						(item) => item.materialName + '配件的开单金额不能低于采购入库价' + item.latestPurPrice
					)
					.join()}请重新填写`
			);
			return false;
		}
		return true;
	};
	//表单计算校验 (计算主单信息与配件每一项)
	const formComputeRule = (mode: string): boolean => {
		let goods = mode === 'edit' ? detailDataSource : detailAddList;
		let { receivableAll, numAll } = mode === 'edit' ? editDesc : addDesc;
		let { favorableAmount } = (mode === 'edit' ? detailEditForm : detailAddForm) as {
			favorableAmount: number;
		};

		receivableAll = +formatNum(receivableAll); //应收
		favorableAmount = +formatNum(favorableAmount); //抹零
		let goodsTotal: number = 0;
		goods.forEach((item) => {
			let { amount, num } = item as { amount: number; num: number };
			// goodsTotal += +formatNum(amount - (+formatNum(amount / (receivableAll + favorableAmount)) * +favorableAmount));
			// 优惠后单价  总计 -整单优惠 * (单品总计/sum(单品总计) /开单数量
			goodsTotal +=(amount  - (+favorableAmount * (amount / (receivableAll + favorableAmount)))/ num)  * num;
			//goodsTotal = bigNumber.add(goodsTotal,bigNumber.toFixed(bigNumber.times(bigNumber.minus(amount,bigNumber.dividedBy(bigNumber.times(favorableAmount,bigNumber.dividedBy(bigNumber.dividedBy(bigNumber.dividedBy(amount,bigNumber.add(receivableAll,favorableAmount))))),num)),num),2))
		});
		if (+formatNum(goodsTotal) !== receivableAll) {
			message.warning(`详情销售应收金额${+formatNum(goodsTotal)}与表单销售应收金额不符`);
			return false;
		}
		return true;
	};
	//新增采购单
	const insertDetail = useLockFn(async (params: ISaleSoFields) => {
		let newParams = {
			...params,
			details: detailAddList,
			createUser: user.username,
			...shopAndOrg,
			...sourceAppOrBranch,
		};
		let { retData } = await saleSoService.insert(newParams);
		return retData;
	});
	//编辑采购单
	const editDetail = useLockFn(async (params: ISaleSoFields) => {
		let newParams = {
			...params,
			details: detailDataSource,
			updateUser: user.username,
			...shopAndOrg,
			...sourceAppOrBranch,
		};
		let { retData } = await saleSoService.update(newParams);
		return retData;
	});
	//关闭页签清不同状态缓存
	const resetAddCatch = async (type?: string) => {
		setDetailAddList([]);
		setDetailAddForm(initForm);
	};
	const resetEditCatch = async (type?: string) => {
		setDetailEditForm({});
		setDetailDataSource([]);
	};
	const downloadTpl = async () => {
		let result = await saleSoService.downloadTpl();
		convertRes2Blob(result);
	};
	const warehousing = async () => {
		let { id, saleCode } = detailEditForm;
		let paramas = {
			id,
			saleCode,
			storekeeper: user.username,
		};
		let { retData } = await saleSoService.warehousing(paramas);
		return retData;
	};
	const onSettle = async (paramas: ISaleSoSettleParams) => {
		let { retData } = await saleSoService.settle(paramas);
		return retData;
	};
	const supplementInsert = async (params: ISaleSoFields) => {
		let newParams = {
			...params,
			details: detailAddList,
			createUser: user.username,
			...shopAndOrg,
			...sourceAppOrBranch,
		};
		let { retData } = await saleSoService.supplementInsert(newParams);
		return retData;
	};
	const onTransfer = useLockFn(async () => {
		let newParams = {
			...detailEditForm,
			details: detailDataSource,
			updateUser: user.username,
			...shopAndOrg,
			...sourceAppOrBranch,
		};
		let { retData } = await saleSoService.transfer(newParams);
		return retData;
	});
	const [historyVisible, setHistoryVisible] = useState<boolean>(false);
	const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({});
	const [consumeHistoryVisible, setConsumeHistoryVisible] = useState<boolean>(false); //消费记录展示
	const [consumeHistoryModel, setConsumeHistoryModel] = useState<{ customerName: string }>({
		customerName: '',
	}); //消费记录参数
	const [repairHistoryModel, setRepairHistoryModel] = useState<boolean>(false); //维修历史
	return {
		settleVisible,
		changeSaleDrawer,
		wsConfirmModal,
		registerModal,
		logisticsDraer,
		discountModal,
		setSettleVisible,
		serChangeSaleDrawer,
		setWsConfirmModal,
		setRegisterModal,
		setLogisticsDraer,
		setDiscountModal,
		vehicleEditAll,
		setVehicleEditAll,
		vehicleAddAll,
		setVehicleAddAll,
		// data
		addModalVisible,
		initPgCfg,
		detailAddForm,
		detailAddList,
		detaiAddDataRow,
		addDesc,
		detailLookForm,
		detailLookList,
		lookDesc,
		detailDataSource,
		detailDataRowKeys,
		detailDataRow,
		detailEditForm,
		editDesc,
		workDataSource,
		searchWorkData,
		workPgCfg,
		addDataSource,
		shelfAllList,
		detaiAddDataRowKeys,
		// model
		setAddModalVisible,
		setDetailAddForm,
		setDetailAddList,
		setDetaiAddDataRow,
		setAddDesc,
		setDetailLookForm,
		setDetailLookList,
		setLookDesc,
		setDetailDataSource,
		setDetailDataRowKeys,
		setDetailDataRow,
		setDetailEditForm,
		setEditDesc,
		setWorkDataSource,
		setSearchWorkData,
		setWorkPgCfg,
		setAddDataSource,
		updateAddFormVal,
		updatEditFormVal,
		getDetailEditOne,
		resetWorkloadData,
		workloadData,
		transformsToPoDetailList,
		detailDetailList,
		insertDetail,
		editDetail,
		resetAddCatch,
		resetEditCatch,
		setShelfAllList,
		setDetaiAddDataRowKeys,
		downloadTpl,
		modelScreenMethod,
		warehousing,
		onSettle,
		initForm,
		topButsControl,
		setTopButsControl,
		supplementInsert,
		historyVisible,
		setHistoryVisible,
		historyRow,
		setHistoryRow,
		lookSettleDetails,
		setLookSettleDetails,
		editSettleDetails,
		setEditSettleDetails,
		consumeHistoryModel,
		setConsumeHistoryModel,
		consumeHistoryVisible,
		setConsumeHistoryVisible,
		repairHistoryModel,
		setRepairHistoryModel,
		onTransfer,
		formComputeRule,
		verifyGoods,
		loading, 
		setLoading
	};
});
