import { formatNum } from "@/utils/utils";
import YhTootip from "@/components/YhTootip";
import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import {IPsbusiDetail} from "../psibusService"
import {usePsiBusModel} from "../psibusModel"


export function DataTable() {
    const {dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,} = usePsiBusModel()

        useEffect(() => {
            loadData();
        }, [])

    const columns: ColumnsType<IPsbusiDetail> = [
        {
            title: '序号',
            width: 50,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '物料编码',
            fixed: "left",
            width: 160,
            dataIndex: 'materialCode',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '物料名称',
            fixed: "left",
            width: 160,
            dataIndex: 'materialName',
            render: (text: any) => <YhTootip text={text} />,
           // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '采购价格',
            width: 120,
            dataIndex: 'purchasePrice',
            render: (text) => '￥' + formatNum(text)
        }, 
        {
            title: '库存成本',
            width: 120,
            dataIndex: 'stockCost',
            render: (text) => '￥' + formatNum(text)
        }, 
        {
            title: '最新进价',
            width: 120,
            dataIndex: 'purchasePriceLatest',
            render: (text) => '￥' + formatNum(text)
        },
         {
            title: '最新库存成本',
            width: 120,
            dataIndex: 'stockCostLatest',
            render: (text) => '￥' + formatNum(text)
        }, {
            title: '当前库存数',
            width: 120,
            dataIndex: 'currentNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "当前库存数",
            //     field: "currentNum"
            // })}>{text}</span>,
        },
        
        {
            title: '进销存剩余数',
            width: 120,
            dataIndex: 'remainNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "remainVisible",
            //     title: "进销存剩余数",
            //     field: "remainNum"
            // })}>{text}</span>,
        }, {
            title: '差异数',
            width: 120,
            dataIndex: 'differenceNum',
        }, 
        {
            title: '公交预售数',
            width: 120,
            dataIndex: 'presaleorderNum',
           
        }, 
        {
            title: '公交预退数',
            width: 120,
            dataIndex: 'preturnorderNum',
        }, 
        {
            title: '公交销售数',
            width: 120,
            dataIndex: 'busSaleNum',
        }, {
            title: '公交销退数',
            width: 120,
            dataIndex: 'busSreturnNum',
        },
        {
            title: '公交调配数',
            width: 120,
            dataIndex: 'allocateNum',
        },
        
        
        
        {
            title: '占用库存数',
            width: 120,
            dataIndex: 'occupyNum',
            // render: (text: any, record: any, index: number) => <span className="click-span" onClick={(e) => onOpen(record, {
            //     str: "occupyVisible",
            //     title: "占用库存数",
            //     field: "occupyNum",
            // })}> {text}</span >,
        },
        {
            title: '公交销售占用数',
            width: 120,
            dataIndex:"busSaleOccupyNum"
           
        },
        {
            title: '占用差异',
            width: 120,
            dataIndex: 'occupyDifference',
        }
    ];

    return (
        <Table
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}        
            scroll={{ x: 'max-content', y: "calc(100vh - 360px)" }}
        />
    )



}