import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { Print } from "./Print"
import { useAppointmentModel } from "../appointmentModel";
import { ScaniaPrints } from "./scaniaPrint";
import { SettleScaniaPrints } from "./settleScaniaPrints";
export default () => {
    const {
        print,
        scaniaPrint,
        settleScaniaPrint,
    } = useAppointmentModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <Print />}
            {scaniaPrint && <ScaniaPrints />}
            {settleScaniaPrint && <SettleScaniaPrints />}
        </>
    )
}