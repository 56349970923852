import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useNavModel } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useDictModel } from "../../dict/dictModel";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { useAcctUnitModel } from "../acctUnitModel";

export const DataBox = () => {
    const { user: { shopId }, setLoadingInfo } = useGlobalModel();
    const { selectedRowKeys, loadData, updateEditFormVal, exportAcctUnit, sync } = useAcctUnitModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel(() => []);
    const { getTreeNodeOne } = useDictModel(() => []);

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let customerEditKey = "13-8-2";
        let isFindNav = findNav(customerEditKey);
        if (!isFindNav) {
            addNav(customerEditKey, { id: selectedRowKeys[0]?.code as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "内部核算单位跳转详情",
                type: "warning",
                text: "您还有内部核算单位详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(customerEditKey, { id: selectedRowKeys[0]?.code, mode: "edit" });
                    updateEditFormVal(null);
                }
            })
        }
    }


    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DATA_ACCUNIT_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { addNav("13-8-1", { id: "", mode: "add" }) }}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_ACCUNIT_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DATA_ACCUNIT_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => { exportAcctUnit() }}
            >导出</Button>
        </YhAuth>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { sync() }}
        >同步</Button>
    </>;

    return (
        <YhBox title="" action={action}>
            <DataTable />
        </YhBox>
    )
}