import { useState } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { PaginationProps } from "antd/es/pagination";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { globalPrompt } from "@/components/message";
import { warehouseService } from "./warehouseService";
import {
    IVRMWarehouseDetail,
    IVRMWarehouseSearchFields,
    IVRMWarehouseInsert,
    IVRMAreaDetail,
    IVRMAreaSearchFields,
    IVRMAreaInsert,
    IVRMAreaBatchInsert,
    IVRMAreaUpdate,
    IVRMAreaFormFields,
    IVRMShelvesUpdate,
    IVRMShelvesBatchInsert,
    IVRMShelvesInsert,
    IVRMShelvesDetail,
    IVRMShelvesSearch,
    IVRMShelvesFormFields,
    IVRMShelfUpdate,
    IVRMshelfDetail,
    IVRMshelfSearch,
    IVRMshelfInsert,
    IVRMShelfFormFields,
    IVRMshelfBatchInsert,
    IVRMshelfSearchFields
} from "./warehouseTypes";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";

export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

export interface IVRMTabsDrawerInfo {
    visible: boolean,
    mode: "add" | "edit"
}

export enum IVRMWarehouseTabEnmu {
    Area = "区域",
    Shelves = "货架",
    Shelf = "货位"
}

export const useWarehouseModel = createModel(function () {
    const { shopAndOrg, user, loadWarehouseAllData } = useGlobalModel()

    /**
     * 仓库列表
     */
    //列表数据源
    const [dataSource, setDataSource] = useState<IVRMWarehouseDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IVRMWarehouseSearchFields>({});
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //行选id
    const [selectedRowKeys, setSelectedRowKeys] = useState<string>("");
    //设置搜索value
    const updateSearchParam = (updateParam: IVRMWarehouseSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys("");
        loadData(initPgCfg, {});
    }
    //仓库列表查询
    const loadData = async (page?: ISearchPage, searchValue: IVRMWarehouseSearchFields = searchParam) => {

        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await warehouseService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        loadWarehouseAllData(shopAndOrg.shopId);
    }

    /**
     * 仓库新增
     */
    const [addWareVisible, setAddWaerVisible] = useState<boolean>(false);

    //仓库新增 Api
    const insertWarehouse = async (data: IVRMWarehouseInsert) => {
        let params = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            createUser: user.username,
        }
        let { retData } = await warehouseService.insert(params);
        retData ?
            globalPrompt("message", { text: "仓库新增成功", type: "success" })
            : globalPrompt("message", { text: "仓库新增失败", type: "error" });
        return retData;
    }

    /** 
     * 仓库详情
     */
    const initWaerhouseFormVal = {
        id: "",
        shopId: "",
        shopName: "",
        orgCode: "",
        orgName: "",
        code: "",
        name: "",
        contact: "",
        phone: "",
        address: "",
        note: "",
        createTime: "",
        updateTime: "",
        createUser: "",
        updateUser: "",
    }
    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<any>(initWaerhouseFormVal);

    //表单修改
    const updateEditFormVal = (val: IVRMWarehouseDetail | null) => {
        setEditFormVal(val ? { ...editFormVal, ...val } : initWaerhouseFormVal);
    }
    const [type, setType] = useState<boolean>(false)

    //获取一条详情数据 Api
    const getWarehouseOne = async (id: string) => {
        let { retData } = await warehouseService.one(id);
        retData && setEditFormVal({...retData,
            enable: retData.enable ? 1 : 0,
        });
        setType(retData.type === "warehouse-category.small" ? true : false)
    }
    //供应商状态
    const onDisabled = async (value:any, checked: boolean) => {
        let params = {
            ...value,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            createUser: user.username,
            enable: checked ? 1 : 0
        }
        let { retData } = await warehouseService.update(params)
        if (retData) {
            loadData()
            globalPrompt("message", { text: "仓库状态编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "仓库状态编辑失败", type: "error" });
        }
        return retData;
    }
    //表单编辑 Api
    const editWarehouse = async (data: IVRMWarehouseDetail) => {
        let params = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            createUser: user.username,
        }
        let { retData } = await warehouseService.update(params);
        retData ?
            globalPrompt("message", { text: "仓库编辑成功", type: "success" })
            : globalPrompt("message", { text: "仓库编辑失败", type: "error" });
        return retData;
    }


    /**
     * 仓库详情 tab 
     */
    const [tabsFlag, setTabFlag] = useState<IVRMWarehouseTabEnmu>(IVRMWarehouseTabEnmu.Area);    //tab切换标记


    /**
     * 仓库 区域
     */
    const initAreaDrawer: IVRMTabsDrawerInfo = { visible: false, mode: "add" }; //弹框
    const [areaDrawer, setAreaDrawer] = useState<IVRMTabsDrawerInfo>(initAreaDrawer);
    const setIAreaDrawer = (str?: "add" | "edit") => setAreaDrawer(str ? { visible: true, mode: str } : initAreaDrawer);

    const [areaSearch, setAreaSearch] = useState<string>("");   //搜索
    const [areaDataSource, setAreaDataSource] = useState<IVRMAreaDetail[]>([]); //区域数据源
    const [areaPgCfg, setAreaPgCfg] = useState<TablePaginationConfig>(initPgCfg);  //分页参数
    const [selectedAreaRows, setSelectedAreaRows] = useState<IVRMAreaDetail[]>([]); //多选area行信息
    // const [areaInsertVal,setAreaInsetVal] = useState<IVRMAreaInsert>({});
    //搜索
    const aeraFilter = (reset: boolean = true) => {
        let areaData = [...areaDataSource]
        if (areaSearch && reset) {
            let reg = new RegExp(areaSearch);
            let arr: IVRMAreaDetail[] = [];
            areaData.forEach(item => {
                if (item.code.match(reg)) {
                    arr.push(item);
                }
            })
            setAreaDataSource(arr);

            if (!arr.length) {
                globalPrompt("message", { text: "没有匹配的区域", type: "success" });
            }
        } else {
            areaLoadData();
        }
    }
    //区域列表 Api
    const areaLoadData = async (page?: ISearchPage, searchValue: IVRMAreaSearchFields = {}) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            warehouseId: editFormVal.id,
            pageSize: 1000,
            pageNum: 1,
            // pageSize: page?.pageSize ?? areaPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            // pageNum: page?.pageNum ?? areaPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await warehouseService.areaPage(searchParams);
        const { records, ...pg } = retData;
        setAreaPgCfg(transPgToPagination(pg));
        setAreaDataSource(records);
    }

    //区域新增 Api
    const insertWarehouseArea = async (fields: IVRMAreaFormFields) => {
        let { isBatch, num, prefix, startCode, ...val } = fields;
        let data: IVRMAreaInsert = {
            ...val,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
        }
        let { retData } = await warehouseService.areaInsert(data);
        if (retData) {
            areaLoadData();
            globalPrompt("message", { text: "区域新增成功", type: "success" });
            setIAreaDrawer();
        } else {
            globalPrompt("message", { text: "区域新增失败", type: "error" });
        }
    }
    //区域批量新增 Api
    const batchInserttWarehouseArea = async (fields: IVRMAreaFormFields) => {
        let { isBatch, ...val } = fields;
        let data: IVRMAreaBatchInsert = {
            ...val,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
            code: ""

        }

        let { retData } = await warehouseService.areaBatchInsert(data);
        if (retData) {
            areaLoadData();
            globalPrompt("message", { text: "区域新增成功", type: "success" });
            setIAreaDrawer();
        } else {
            globalPrompt("message", { text: "区域新增失败", type: "error" });
        }
    }

    //区域编辑 Api
    const updateWarehouseArea = async (fields: IVRMAreaFormFields) => {
        let data: IVRMAreaUpdate = {
            ...fields,
            id: selectedAreaRows[0].id,
            shopId: "门店id",
            warehouseId: editFormVal.id as string,
        }
        let { retData } = await warehouseService.areaUpdate(data);
        if (retData) {
            areaLoadData();
            globalPrompt("message", { text: "区域编辑成功", type: "success" });
            setIAreaDrawer();
        } else {
            globalPrompt("message", { text: "区域编辑失败", type: "error" });
        }
    }

    //区域导入 Api
    const importWarehouseArea = async (file: File) => {
        let { retData } = await warehouseService.areaImport({ file: file });
        if (retData) {
            areaLoadData();
            globalPrompt("message", { text: "区域导入成功", type: "success" });
        } else {
            globalPrompt("message", { text: "区域导入失败", type: "error" });
        }
    }
    const downloadTplArea = async () => {
        let result = await warehouseService.areaDownloadTpl();
        convertRes2Blob(result)
    }

    /**
     * 仓库 货架
     */
    const initShelvesDrawer: IVRMTabsDrawerInfo = { visible: false, mode: "add" };
    const [shelvesDrawer, setShelvesDrawer] = useState<IVRMTabsDrawerInfo>(initShelvesDrawer);
    const setIShelvesDrawer = (str?: "add" | "edit") => setShelvesDrawer(str ? { visible: true, mode: str } : initShelvesDrawer);

    const [shelvesDataSource, setShelvesDataSource] = useState<IVRMShelvesDetail[]>([]); //区域数据源
    const [shelvesPgCfg, setShelvesPgCfg] = useState<TablePaginationConfig>(initPgCfg);  //分页参数
    const [selectedShelvesRows, setSelectedShelvesRows] = useState<IVRMShelvesDetail[]>([]); //多选Shelves行信息
    const [shelvesSearch, setShelvesSearch] = useState<IVRMShelvesSearch>({});   //搜索

    //货位重置
    const resetShelves = () => {
        shelvesLoadData({
            pageSize: DEFAULT_PAGE_SIZE,
            pageNum: DEFAULT_FIRST_PAGE_NUM
        }, {});
        setShelvesSearch({});
    }
    //货架列表 Api
    const shelvesLoadData = async (page?: ISearchPage, searchValue: IVRMShelvesSearch = shelvesSearch) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
            pageSize: page?.pageSize ?? shelvesPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? shelvesPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await warehouseService.shelvesPage(searchParams);
        const { records, ...pg } = retData;
        setShelvesPgCfg(transPgToPagination(pg));
        setShelvesDataSource(records);
    }

    //货架新增 Api
    const insertWarehouseShelves = async (fields: IVRMShelvesFormFields) => {
        // return console.log(fields)
        // debugger;
        let { isBatch, num, prefix, startCode, ...val } = fields;
        let data: IVRMShelvesInsert = {
            ...val,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
        }
        let { retData } = await warehouseService.shelvesInsert(data);
        if (retData) {
            shelvesLoadData();
            globalPrompt("message", { text: "货架新增成功", type: "success" });
            setIShelvesDrawer();
        } else {
            globalPrompt("message", { text: "货架新增失败", type: "error" });
        }
    }
    //货架批量新增 Api
    const batchInserttWarehouseShelves = async (fields: IVRMShelvesFormFields) => {
        let { isBatch, ...val } = fields;
        let data: IVRMShelvesBatchInsert = {
            ...val,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
            code: ""

        }

        let { retData } = await warehouseService.shelvesBatchInsert(data);
        if (retData) {
            shelvesLoadData();
            globalPrompt("message", { text: "货架新增成功", type: "success" });
            setIShelvesDrawer();
        } else {
            globalPrompt("message", { text: "货架新增失败", type: "error" });
        }
    }

    //货架编辑 Api
    const updateWarehouseShelves = async (fields: IVRMShelvesFormFields) => {
        let { isBatch, ...val } = fields;
        let data: IVRMShelvesUpdate = {
            ...val,
            id: selectedShelvesRows[0].id,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
        }
        let { retData } = await warehouseService.shelvesUpdate(data);
        if (retData) {
            shelvesLoadData();
            globalPrompt("message", { text: "货架编辑成功", type: "success" });
            setIShelvesDrawer();
        } else {
            globalPrompt("message", { text: "货架编辑失败", type: "error" });
        }
    }

    //货架导入 Api
    const importWarehouseShelves = async (file: File) => {
        let { retData } = await warehouseService.shelvesImport({ file: file });
        if (retData) {
            shelvesLoadData();
            globalPrompt("message", { text: "货架导入成功", type: "success" });
        } else {
            globalPrompt("message", { text: "货架导入失败", type: "error" });
        }
    }
    const downloadTplShelves = async () => {
        let result = await warehouseService.shelvesDownloadTpl();
        convertRes2Blob(result)
    }

    /**
     * 仓库 货位
     */
    const initShelfDrawer: IVRMTabsDrawerInfo = { visible: false, mode: "add" };
    const [shelfDrawer, setShelfDrawer] = useState<IVRMTabsDrawerInfo>(initShelfDrawer);
    const setIShelfDrawer = (str?: "add" | "edit") => setShelfDrawer(str ? { visible: true, mode: str } : initShelfDrawer);

    const [shelfDataSource, setShelfDataSource] = useState<IVRMshelfDetail[]>([]); //区域数据源
    const [shelfPgCfg, setShelfPgCfg] = useState<TablePaginationConfig>(initPgCfg);  //分页参数
    const [selectedShelfRows, setSelectedShelfRows] = useState<IVRMshelfDetail[]>([]); //多选Shelf行信息
    const [shelfSearch, setShelfSearch] = useState<IVRMshelfSearchFields>({});


    //货位列表 Api
    const shelfLoadData = async (page?: ISearchPage, searchValue: IVRMshelfSearch = shelfSearch) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
            warehouseCode: editFormVal.code,
            pageSize: page?.pageSize ?? shelfPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? shelfPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        setShelfPgCfg(transPgToPagination(pg));
        setShelfDataSource(records);
    }
    //货位重置
    const resetShelf = () => {
        shelfLoadData({
            pageSize: DEFAULT_PAGE_SIZE,
            pageNum: DEFAULT_FIRST_PAGE_NUM
        }, {});
        setShelfSearch({});
    }
    //货位新增 Api
    const insertWarehouseShelf = async (fields: IVRMShelfFormFields) => {
        let { isBatch, num, prefix, startCode, ...val } = fields;
        let data: IVRMshelfInsert = {
            ...val,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
            warehouseCode: editFormVal.code,
        }
        let { retData } = await warehouseService.shelfInsert(data);
        if (retData) {
            shelfLoadData();
            globalPrompt("message", { text: "货位新增成功", type: "success" });
            setIShelfDrawer();
        } else {
            globalPrompt("message", { text: "货位新增失败", type: "error" });
        }
    }
    //货位批量新增 Api
    const batchInserttWarehouseShelf = async (fields: IVRMShelfFormFields) => {
        let { isBatch, ...val } = fields;
        let data: IVRMshelfBatchInsert = {
            ...val,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
            warehouseCode: editFormVal.code,
            code: ""

        }

        let { retData } = await warehouseService.shelfBatchInsert(data);
        if (retData) {
            shelfLoadData();
            globalPrompt("message", { text: "货位新增成功", type: "success" });
            setIShelfDrawer();
        } else {
            globalPrompt("message", { text: "货位新增失败", type: "error" });
        }
    }

    //货位编辑 Api
    const updateWarehouseShelf = async (fields: IVRMShelfFormFields) => {
        let { isBatch, ...val } = fields;
        let data: IVRMShelfUpdate = {
            ...val,
            id: selectedShelfRows[0].id,
            ...shopAndOrg,
            warehouseId: editFormVal.id as string,
            warehouseCode: editFormVal.code,
        }
        let { retData } = await warehouseService.shelfUpdate(data);
        if (retData) {
            shelfLoadData();
            globalPrompt("message", { text: "货位编辑成功", type: "success" });
            setIShelfDrawer();
        } else {
            globalPrompt("message", { text: "货位编辑失败", type: "error" });
        }
    }

    //货位导入 Api
    const importWarehouseShelf = async (file: File) => {
        let { retData } = await warehouseService.shelfImport({ file: file });
        if (retData) {
            shelfLoadData();
            globalPrompt("message", { text: "货位导入成功", type: "success" });
        } else {
            globalPrompt("message", { text: "货位导入失败", type: "error" });
        }
    }
    const downloadTplShelf = async () => {
        let result = await warehouseService.shelfDownloadTpl();
        convertRes2Blob(result)
    }
    const downloadTpl = async () => {
        let result = await warehouseService.downloadTpl();
        convertRes2Blob(result)
    }
    const shelfExport = async () => {
        const searchParams = {
            ...shelfSearch,
            ...shopAndOrg,
            id: selectedRowKeys,
            // warehouseCode: dataSource.find(item => item.id === selectedRowKeys)?.code,
            pageSize: 100000,
            pageNum: 1,
        }
        let result = await warehouseService.shelfExport(searchParams);
        convertRes2Blob(result)
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        editFormVal,
        tabsFlag,
        addWareVisible,
        areaDrawer,
        shelvesDrawer,
        shelfDrawer,
        areaDataSource,
        areaPgCfg,
        selectedAreaRows,
        shelvesDataSource,
        shelvesPgCfg,
        selectedShelvesRows,
        shelfDataSource,
        shelfPgCfg,
        selectedShelfRows,
        initPgCfg,
        /***method***/
        setAddWaerVisible,
        setTabFlag,
        updateEditFormVal,
        insertWarehouse,
        editWarehouse,
        getWarehouseOne,
        setDataSource,
        setSelectedRowKeys,
        loadData,
        setIAreaDrawer,
        setIShelvesDrawer,
        setIShelfDrawer,
        areaLoadData,
        setSelectedAreaRows,
        insertWarehouseArea,
        batchInserttWarehouseArea,
        updateWarehouseArea,
        importWarehouseArea,
        setSelectedShelvesRows,
        shelvesLoadData,
        insertWarehouseShelves,
        batchInserttWarehouseShelves,
        updateWarehouseShelves,
        importWarehouseShelves,
        shelfLoadData,
        insertWarehouseShelf,
        batchInserttWarehouseShelf,
        updateWarehouseShelf,
        importWarehouseShelf,
        setSelectedShelfRows,
        areaSearch, setAreaSearch, aeraFilter, downloadTplArea,
        shelvesSearch, setShelvesSearch, resetShelves, downloadTplShelves,
        shelfSearch, setShelfSearch, resetShelf, downloadTplShelf,
        type, setType,
        resetSearchParam,
        updateSearchParam,
        downloadTpl,
        shelfExport,
        onDisabled
    }
});