import React, { useState, useEffect, FC } from "react";
import { Table, Button, Input, Checkbox, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { useSaleDcDetailsModel } from "./saleAllotDetailsModel";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { ISearchPage } from "@/types/ScmTypes";
import useGlobalModel from "@/model/globalModel";
import { YhBut } from "@/components/YhButton";
import { formatNum, transPgToPagination } from "@/utils/utils";
import { RouterParamType } from "@/model/navModel";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { IBCMSDcSearchFields } from "../../allotType";
import { ISCMPODetailsStockDetail } from "@/views/purchase/po/purchasePoType";
import { IMaterialDetail } from "../../../../base/material/materialService"
import { purchaseDrService } from '@/views/purchase/dr/purchaseDrService';
import { IBCMPODetailsStockDetail } from '@/views/purchase/dr/purchaseDrTypes';
import { debounce } from "lodash";
import YhTootip from "@/components/YhTootip";


enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}

//为已添加的商品增加 数量字段
export interface ISCMSDcSelectGoods
    extends ISCMPODetailsStockDetail {
    sDcNum: number
    brandCode: string
    brandName: string
    isHave: boolean
};

export interface IMaterialDetailModel extends IMaterialDetail {
    num: number,
    amount: number,
    confirmNum?: number,    // 0,
    damageNum?: number,    // 0,
}

export const AddGoodsModal: FC<RouterParamType> = ({ id, mode }) => {
    const { shopAndOrg: { shopId } } = useGlobalModel();
    const {
        addGoodsModal: visible, setAddGoodsModal, add, remove, transformsToSDcGoods,
        editSelectGoods, setEditSelectGoods,
        addSelectGoods, setAddSelectGoods,
        addGoodsList,
        editGoodsList,allAdd
    } = useSaleDcDetailsModel();
    const [checked, setChecked] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    //查询条件
    const [searchParam, setSearchParam] = useState<IBCMSDcSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<IBCMPODetailsStockDetail[]>([]);
    //列表数据源分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: 10, current: 1 }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [bool, setBool] = useState<boolean>(false);  //用变量来触发全局事件 ， 否咋拿不到全局状态里面的缓存数据

    const loadData = async (page?: ISearchPage) => {
        const searchParams = {
            shopId,
            ...searchParam,
            age: 1,
            saleTypeCode: "sale-type.general",  //选常规商品
            pageSize:searchParam.purchaseCode?500:page?.pageSize ?? pgCfg.pageSize,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current,

        }
        setLoading(true);
        let { retData } = await purchaseDrService.bcmTransferDetails(searchParams);
        setLoading(false)
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        let newRecords:any = records.map(item => ({
             ...item, 
             inventoryQuantity: item.stockNum,
            //  num: item.inventoryQuantity??1,	
            }))
        setDataSource(newRecords);
    }

    const onGlobalKeyUp = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.keyCode === 13) {
            setBool(origin => !origin);
        }
    };

    useEffect(() => {
        bool && transformsToSDcGoods(mode === "edit" ? editSelectGoods : addSelectGoods, mode)
    }, [bool]);

    //注册window的crtl+回车事件来触发当前 弹框确认事件
    useEffect(() => {
        if (visible) {
            document.onkeyup = onGlobalKeyUp;
            loadData();
        }
        else {
            document.onkeyup = null;
            setBool(false);
        }
    }, [visible])

    useEffect(() => {
        return () => {
            document.onkeyup = null;
            setBool(false);
        }
    }, []);

    const onCancel = () => {
        setAddGoodsModal(false);
    };

    const column = (optype: number) => [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 160,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any,record:any) =><div style={{display:"flex",flexWrap: 'nowrap'}}> <YhTootip text={text} /><span style={{color:'red'}}>{record.ytNote}</span></div>,
        }, {
            title: '商品名称',
            width: 180,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: string) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '商品类型',
            width: 180,
            dataIndex: 'typeName',
        }, {
            title: '货位',
            width: 180,
            dataIndex: 'shelfCode',
        }, {
            title: '可用库存',
            width: 120,
            dataIndex: 'inventoryQuantity', //这里取采购数量的值判断
        }, {
            title: '采购单价',
            width: 120,
            dataIndex: 'latestPurPrice',
        },
        optype === OPT_TYPE.ADD ? {} : {
            title: '数量',
            width: 100,
            dataIndex: 'sDcNum',
            editable: true,
        },
        {
            title: '品牌',
            width: 180,
            dataIndex: 'brandName',
        },
        {
            title: '采购单号',
            width: 100,
            dataIndex: 'purchaseCode',
        },{
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '操作',
            width: 80,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text: any, record: ISCMSDcSelectGoods) => optype === OPT_TYPE.ADD ?
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record, mode)}
                    disabled={
                        !!(mode === "edit" ? editSelectGoods : addSelectGoods).find(item => item.id === record.id)
                        || !!(mode === "edit" ? editGoodsList : addGoodsList).find(item => item.key === record.id)
                        || +record.inventoryQuantity == 0}
                />
                :
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record, mode)}
                />
        }
    ];

    const newSelectColumns = column(OPT_TYPE.REMOVE).map((col: any, index) => {
        return {
            ...col,
            onCell: (record: ISCMSDcSelectGoods, rowIndex: number) => ({
                colIndex: index,
                rowIndex,
                record,
                rules: col.rules,
                options: col.options,
                editType: col.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row: any) => {
        row.sDcNum = +formatNum(row.sDcNum);
        let dataSource = mode === "edit" ? editSelectGoods : addSelectGoods;
        const index = dataSource.findIndex((item) => row.id === item.id);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        mode === "edit" ? setEditSelectGoods([...dataSource]) : setAddSelectGoods([...dataSource]);
    }

    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-5"
            placeholder="采购单号"
            value={searchParam?.purchaseCode}
            onChange={(e) => setSearchParam({ ...searchParam, purchaseCode: e.target.value })}
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-5"
            placeholder="商品编号"
            value={searchParam?.materialCode}
            onChange={(e) => setSearchParam({ ...searchParam, materialCode: e.target.value })}
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-5"
            placeholder="商品名称"
            value={searchParam?.materialName}
            onChange={(e) => setSearchParam({ ...searchParam, materialName: e.target.value })}
            onPressEnter={() => loadData(initPgCfg)}
        />
        {/* <Checkbox checked={checked} onChange={(e) => { setChecked(e.target.checked); loadData(initPgCfg); setSearchParam({ ...searchParam }) }} >
            是否有库存
        </Checkbox> */}
        <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => loadData(initPgCfg)}>检索</Button>
        <Button type="link" style={{ margin: "0px 12px 0px 15px" }}  onClick={() => allAdd(dataSource, mode)}>全部添加</Button>
        </div>

    return <YHModal
        visible={visible}
        onOk={debounce(() => transformsToSDcGoods(mode === "edit" ? editSelectGoods : addSelectGoods, mode), 500)}
        onCancel={onCancel}
        title="调出单-选择配件"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "5px", paddingBottom: 0 }}>
            <Table
                loading={loading}
                rowKey={record => record.id}
                columns={column(OPT_TYPE.ADD) as ColumnsType<IBCMPODetailsStockDetail>}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns}
                dataSource={mode === "edit" ? editSelectGoods : addSelectGoods}
                pagination={false}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}