import React, { useRef, useState, useEffect } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils";
import { useWarehouseModel } from "../../warehouseModel";
import { IVRMShelvesFormFields } from "../../warehouseTypes";
import { debounce } from "lodash";

//货架弹框
export function ShelvesDrawer() {
    const { areaDataSource, shelvesDrawer: { visible, mode }, setIShelvesDrawer, selectedShelvesRows, editFormVal,
        insertWarehouseShelves, batchInserttWarehouseShelves, updateWarehouseShelves } = useWarehouseModel();
    const formRef = useRef<IYhFormRef>();
    const [isBatch, setIsBatch] = useState<boolean>(false);

    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        if ('isBatch' in changedValues) {
            setIsBatch(changedValues['isBatch']);
        }
    }

    const onCancel = () => {
        setIShelvesDrawer();
        setIsBatch(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            formRef.current?.inst().submit();
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }
    const areaOptions = () => {
        return areaDataSource ? areaDataSource.map(item => ({ label: item.code, value: item.id })) : [];
    }
    useEffect(() => {
        !isBatch && formRef.current?.setFieldsValue({ code: null, name: null });
    }, [isBatch]);
    
    const onFinish = (val: IVRMShelvesFormFields) => {
        if (mode === "edit") {
            updateWarehouseShelves(val)
        } else {
            isBatch ? batchInserttWarehouseShelves(val) : insertWarehouseShelves(val);
        }
    }

    const isBatchItem = (bool: boolean) => {
        return bool ? [{
            type: FormItemTypeEnum.Input,
            fieldLabel: "起始编码",
            fieldName: "startCode",
            span: 24,
            rules: [{ required: true, message: "请输入起始编码" }]
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "数量",
            fieldName: "num",
            span: 24,
            rules: [{ required: true, message: "请输入数量" }]
        }, {
            type: FormItemTypeEnum.Input,
            fieldLabel: "前缀",
            fieldName: "prefix",
            span: 24,
            rules: [{ required: true, message: "请输入前缀" }]
        }] : []
    }
    //区域编码
    const areaChange = (value: string, option: ISelectOption) => {
        // console.log(option)
        option && formRef.current?.setFieldsValue({ areaCode: option.label });

    }
    const formConfig: IYhForm = {
        formRef,
        onFinish,
        formValues: mode === "edit" ? selectedShelvesRows[0] : { warehouseName: editFormVal.name },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属仓库",
                fieldName: "warehouseName",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "所属区域",
                fieldName: "areaId",
                span: 24,
                allowClear: true,
                showSearch: true,
                options: areaOptions(),
                onSelectChange: areaChange,
                rules: [{ required: true, message: "请选择所属区域" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "货架编号",
                fieldName: "code",
                span: 24,
                disable: isBatch || mode === "edit",
                rules: [{ required: !isBatch, message: "请输入货架编号" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "货架名称",
                fieldName: "name",
                span: 24,
                disable: isBatch,
                // rules: [{ required: true, message: "请输入货架名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            },
            ...isBatchItem(isBatch),
            {
                type: FormItemTypeEnum.Checkbox,
                fieldLabel: "批量录入",
                fieldName: "isBatch",
                span: 24,
                hidden: mode === "edit" ? true : false
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "区域编码",
                fieldName: "areaCode",
                hidden: true
            },
        ]
    };
    return (
        <YHDrawer
            {...{
                title: `货架${mode === "edit" ? "编辑" : "新增"}`,
                visible,
                onCancel,
                okText: "保存",
                onOk: debounce(() => { onSubmit() }, 900)
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
