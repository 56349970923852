import { createModel } from 'hox';
import { useState, useEffect } from 'react';
import {
	IInternaGoodslDetail,
	IInternalDetail,
	IWriteoffParams,
	IAuditParams,
	IFmsAllotFileds,
	IFmsAllotDetailFileds,
} from '../../internalTypes';
import useGlobalModel from '@/model/globalModel';
import { TablePaginationConfig } from 'antd/lib/table';
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from '@/utils/constants';
import { ISearchPage } from '@/types/ScmTypes';
import { internalService } from '../../internalService';
import { useInternalModel } from '../../internalModel';
import { transPgToPagination } from '@/utils/utils';
export const useInternalDetailsModel = createModel(function () {
	const { selectedData } = useInternalModel();
	const {
		user: { userName },
		shopAndOrg: { shopId, shopCode, shopName },
	} = useGlobalModel();
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};

	const initFormVal = {
		shopCode,
		shopName,
	};
	//编辑缓存
	const [editFormVal, setEditFormVal] = useState<IInternalDetail>(initFormVal);
	const [editGoodsList, setEditGoodsList] = useState<IInternaGoodslDetail[]>([]);

	//新增缓存
	const [addFormVal, setAddFormVal] = useState<IInternalDetail>(initFormVal);
	const [addGoodsList, setAddGoodsList] = useState<IInternaGoodslDetail[]>([]);
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

	//新增选中的对账明细key
	const [addAllotRowKeys, setAddAllotRowKeys] = useState<string[]>([]);
	const [addAllotRow, setAddAllotRow] = useState<IFmsAllotFileds[]>([]);
	//编辑选中的对账明细key
	const [editAllotRowKeys, setEditAllotRowKeys] = useState<string[]>([]);
	const [editAllotRow, setEditAllotRow] = useState<IFmsAllotFileds[]>([]);
	//对账明细打开页面
	const [allDetailVisible, setAllDetailVisible] = useState<boolean>(false);
	const [allotDetailList, setAllotDetailList] = useState<IFmsAllotDetailFileds[]>([]);
	useEffect(() => {
		resetAddCatch();
		setAddFormVal({ ...initFormVal, shopId, shopName, createUser: userName });
	}, [shopId]);
	//表单新增修改
	const updateAddFormVal = (val: object | null) => {
		val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal(initFormVal);
	};
	//表单编辑修改
	const updateEditFormVal = (val: object | null) => {
		val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal(initFormVal);
	};
	//重置缓存
	const resetEditCatch = () => {
		updateEditFormVal(null); //表单
		setEditGoodsList([]); //详情商品列表
	};
	const resetAddCatch = () => {
		updateAddFormVal(null);
		setAddGoodsList([]);
	};
	const getInternalDetailOne = async (id: string) => {
		let { retData } = await internalService.one(id);
		setEditFormVal(retData);
		allotLoadData({}, { writeoffCode: retData.code }, 'edit');
		return retData;
		// console.log(retData)
	};

	const allotLoadData = async (
		page: ISearchPage = initPgCfg,
		search: IInternalDetail = {},
		mode: string = 'add'
	) => {
		let params = {
			...search,
			pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
			pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
		};
		let { retData } = await internalService.allot(params);
		let { records, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		mode === 'add' ? setAddGoodsList(records) : setEditGoodsList(records);
	};
	const allotDetailLoadData = async (search: object) => {
		let params = {
			...search,
			pageSize: -1,
			pageNum: 1,
		};
		let { retData } = await internalService.detailPage(params);
		let { records, ...pg } = retData;
		setAllotDetailList(records);
	};
	const saveInternal = async (params: IWriteoffParams) => {
		let { retData } = await internalService.writeoff(params);
		return retData;
	};
	const audit = async (params: IAuditParams) => {
		let { retData } = await internalService.audit(params);
		return retData;
	};
	return {
		editFormVal,
		editGoodsList,
		addFormVal,
		addGoodsList,
		setEditGoodsList,
		setEditFormVal,
		setAddFormVal,
		setAddGoodsList,
		resetEditCatch,
		resetAddCatch,
		updateEditFormVal,
		updateAddFormVal,
		allotLoadData,
		pgCfg,
		setPgCfg,
		saveInternal,
		getInternalDetailOne,
		audit,
		allDetailVisible,
		setAllDetailVisible,
		addAllotRowKeys,
		setAddAllotRowKeys,
		addAllotRow,
		setAddAllotRow,
		editAllotRowKeys,
		setEditAllotRowKeys,
		editAllotRow,
		setEditAllotRow,
		allotDetailLoadData,
		allotDetailList,
		setAllotDetailList,
	};
});
