
import React, { useState, useRef } from "react";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { FormBox } from "./FormBox"
import { DeliveryDetailsTabs } from "./DeliveryDetailsTabs"
import { IYhFormRef } from "@/components/YhFormBox/Types";

export const DeliveryDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    return (
        <>
            <FormBox  {...detailsInfo} IFormRef={IFormRef} />
            <DeliveryDetailsTabs  {...detailsInfo} />
        </>
    )
}