import { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useBusListModel } from "../busListModel";
import { IVRMBusListDetail } from "../busListService";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, pgCfg,
        loadData } = useBusListModel();
    const { getTreeNodeName } = useDictModel(() => []);

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IVRMBusListDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '销售单号',
            fixed: "left",
            width: 200,
            dataIndex: 'documentNumber',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '客户名称',
            width: 200,
            dataIndex: 'customerName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '单据类型',
            width: 150,
            dataIndex: 'tradeTypeName',
        }, {
            //     title: '优惠类型',
            //     width: 150,
            //     dataIndex: 'preferentialTypeName',
            // }, {
            title: '结算类型',
            width: 150,
            dataIndex: 'payType',
            render: text => getTreeNodeName(text)
        }, {
            title: '单据状态',
            width: 150,
            dataIndex: 'status',
            render: text => getTreeNodeName(text)
        }, {
            title: '支付状态',
            width: 150,
            dataIndex: 'payStatus',
            render: text => getTreeNodeName(text)
        }, {
            title: '销售、退数量',
            width: 150,
            dataIndex: 'totalNum',
        }, {
            title: '销售、退金额',
            width: 150,
            dataIndex: 'totalAmount',
            render: (text) => `￥${text}`
        }, {
            //     title: '优惠金额',
            //     width: 150,
            //     dataIndex: 'discountTotalAmount',
            //     render: (text) => `￥${text}`
            // }, {
            title: '已收、付金额',
            width: 150,
            dataIndex: 'amount',
            render: (text) => `￥${text}`
        }, {
            title: '销售、退成本',
            width: 150,
            dataIndex: 'totalCost',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销售、退毛利',
            width: 150,
            dataIndex: 'grossProfit',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '制单员',
            width: 150,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '结算日期',
            width: 160,
            dataIndex: 'settleTime',

        }
    ];



    return (
        <Table
            rowKey={record => record.id}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}