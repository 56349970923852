import React, { FC, useState } from "react";
import { Button, Table, Input, message, Upload, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { RouterParamType } from "@/model/navModel";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import { formatInt, formatNum, formatDiscount, bigNumber } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { ISCMPDrGoodsDetail } from "../../purchaseDrTypes";
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";
import { importPropsConfigScm, messageConfig, importPropsConfig } from "@/utils/importFile";
import { IVRMshelfDetail } from '@/views/base/warehouse/warehouseTypes';
import { warehouseService } from '@/views/base/warehouse/warehouseService';
import { ISelectOption } from '@/components/YhFormBox/Types';
import { IDetailList } from '@/views/sale/order/saleOrderTypes';
import { HistoryModel } from "@/views/historyModel";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {usePurchaseDrModel} from '../../purchaseDrModel'
import { globalPrompt } from "@/components/message";
import { purchaseDrService } from "../../purchaseDrService";
import { purchaseDetailsService } from "@/views/stock/purchaseDetails/purchaseDetailsService";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { ChangeWarehouseModel } from './ChangeWarehouseModel'

type ISCMPDrGoodsSearch = {
    data: ISCMPDrGoodsDetail[],
    isSearch: boolean
}

export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const { shopAndOrg: { shopId }, setLoadingInfo, warehouseList } = useGlobalModel();
    const {dataSource} =usePurchaseDrModel()
    const { addGoodsList, addFormVal, editFormVal, addOutShopCode, setAddGoodsList, editGoodsList, setEditGoodsList, setAddGoodsModal, pDrDownload, exportPDrdetail
        , setHistoryVisible, historyRow, historyVisible, setHistoryRow, setAddTempShelfModal, setBtnContral,setSpecilDataSource,specilDataSource } = usePurchaseDrDetailsModel();
    const onHistoryModel = (record: ISCMPDrGoodsDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [searchParms, setSearchParms] = useState<string>("");
    const [searchVal, setSearchVal] = useState<ISCMPDrGoodsSearch>({ data: [], isSearch: false });   //用于搜索展示的数据
    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const statusValue=editFormVal ;
    let goodsList = mode === "edit" ? editGoodsList : addGoodsList
    const [changeWarehouseVisible, setChangeWarehouseVisible] = useState(false)
    //删除商品
    const onRemove = () => {
        if (!selectedRowKeys.length) {
            message.warning("请选择你要删除的商品");
            return;
        }
        mode === "edit" ?
            setEditGoodsList(origin => origin.filter(item => selectedRowKeys.indexOf(item.key) < 0)) :
            setAddGoodsList(origin => origin.filter(item => selectedRowKeys.indexOf(item.key) < 0));

        let newSearchData = searchVal.data.filter(item => selectedRowKeys.indexOf(item.key) < 0)
        setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });

        setSelectedRowKeys([]);
        message.success("删除成功");
    }

    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource: ISCMPDrGoodsDetail[] | null = null) => {
        const isEtit = mode === "edit";
        let goodsList = dataSource ? dataSource : [...(isEtit ? editGoodsList : addGoodsList)];
        if (searchParms) {
            let reg = new RegExp(searchParms);
            let arr: ISCMPDrGoodsDetail[] = [];
            goodsList.forEach(item => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })
            setSearchVal({ data: arr, isSearch: true });

            if (!arr.length) {
                message.warning("列表没有该商品！！")
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }

    //导入明细成功
    const upLoadCallback = (list: any[]) => {
        if (!list?.length) return message.warning('请导入正确格式的配件');
        const isEdit: boolean = mode === "edit";
        const goodsList: ISCMPDrGoodsDetail[] = [...(isEdit ? editGoodsList : addGoodsList)]
        let newShelfRows: any[] = [];
        let str: string = "";
        list.forEach(item => {
            let num: number = item.num ?? 0;
            let price: number = item.price
            if (!item.success) {
                str += `【${item.msg}】`
            }
            if (item.inventoryDetailsList && item.inventoryDetailsList.length) {
                let data = item.inventoryDetailsList.map((item: any) => {
                    return {
                        ...item,
                        createTime: Number(item.createTime.join(''))
                    }
                })
                const tempArr = data.sort((a: any, b: any) => a.createTime - b.createTime)
                for (let i = 0; i < tempArr.length; i++) {
                    let goods = tempArr[i];
                    // 公共字段
                    let publicData = {
                        id: "",
                        key: goods.id,
                        purchaseDetailsId: goods.id,
                        materialCode: goods.materialCode,
                        materialName: goods.materialName,  //商品名称
                        price: bigNumber.times(price,(1 - Number(item.discountRate ?? 0))).toFixed(2) ,
                        prePrice: price,
                        costPrice: goods.costPrice,
                        purchaseCode: goods.bussCode,
                        inventoryNum: goods.inventoryQuantity - goods.occupyNum,
                        typeName: goods.typeName,
                        typeCode: goods.typeCode,
                        outWarehouseCode: goods.warehouseCode,  //仓库编码
                        outWarehouseName: goods.warehouseName,  //仓库编码
                        outShelfCode: goods.shelfCode,
                        replaceCode: goods.replaceCode,
                        unitName: goods.unitName,
                        unitCode: goods.unitCode,
                        latestPurPrice: goods.latestPurPrice,
                        discountRate:item.discountRate
                    }
                    // 可用库存 = 库存数量 - 占用库存
                    let kynum = goods.inventoryQuantity - goods.occupyNum;
                    //当匹配到最后一个配件, 可用库存不满足用户填写数量, 那就设置该配件为异常配件, 数量设置为减完前面配件可用库存剩余的数量
                    if (i === tempArr.length - 1 && kynum < num) {
                        newShelfRows.push({
                            ...publicData,
                            isHave: true,
                            error: item.msg,
                            num, //开单数量
                            confirmNum: num ?? 1,
                            preAmount:+bigNumber.toFixed(bigNumber.times(Number(price ?? 0),Number(num ?? 1)),2),
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            //amount: +formatNum(Number(price ?? 0) * (1 - Number(goods.discountRate ?? 0)) * (Number(num ?? 1))),
                            amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(Number(price ?? 0),bigNumber.minus(1,Number(item.discountRate ?? 0))),Number(num ?? 1)),2)
                        })
                        break
                    }
                    if (num <= 0) break;
                    if (kynum) {
                        num = num - kynum;
                        // 当 可用数量 小于
                        let tempNum = num < 0 ? kynum + num : kynum;
                        newShelfRows.push({
                            ...publicData,
                            isHave: item.success,
                            msg: item.msg,
                            num: tempNum, //开单数量
                            confirmNum: tempNum ?? 1,
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            //amount: +formatNum(Number(price ?? 0) * (1 - Number(goods.discountRate ?? 0)) * (Number(tempNum ?? 1)))
                            amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(Number(price ?? 0),bigNumber.minus(1,Number(item.discountRate ?? 0))),Number(tempNum ?? 1)),2),
                            preAmount:+bigNumber.toFixed(bigNumber.times(Number(price ?? 0),Number(tempNum ?? 1)),2),
                        })
                    }
                }
            }
        })
        isEdit ? setEditGoodsList(origin => [...origin, ...newShelfRows]) : setAddGoodsList(origin => [...origin, ...newShelfRows]);
        setLoadingInfo({ loadingVisible: false, txt: "" });
        messageConfig(str ? str : "导入成功", 20, "info");
        
		let newDate = newShelfRows;
		const index = newDate.filter((item) => !item.inWarehouseCode);
		setSpecilDataSource(index)
    };
    const { branchOut, outShopId, branchIn } = mode === "edit" ? editFormVal : addFormVal;
    const importUrl = branchOut === "vrm" ? `/vrm2/stock/details/allocationImport/${outShopId}/${shopId}/${branchIn}` : branchOut === "scm" ? `/scm2/sale/so/allocationImport/${outShopId}/${shopId}//${branchIn}` : `/bcm/stock/details/allocationImport/${outShopId}/${shopId}//${branchIn}`
    const action = <div className="flex justify-between white-space">
        <div>
            {mode !== "edit" && <>
                <YhAuth resCode={ResourceAuthEnum.P_DR_AD_ADD_M}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        disabled={mode === "add" ? !addFormVal.branchOut : !editFormVal.branchOut}
                        onClick={() => {
                            if (mode === "add" && !addOutShopCode) {
                                message.warning("请选择调出门店");
                                return false;
                            }
                            setAddGoodsModal(true)
                        }}
                    >新增商品</Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.P_DR_AD_DEL_M}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={() => onRemove()}
                    >删除商品</Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.P_DR_AD_DLOAD}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={() => {
                            pDrDownload()
                        }}
                    >下载模板</Button>
                </YhAuth>
            </>}
            <YhAuth resCode={ResourceAuthEnum.P_DR_AD_IM}>
                <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfigScm(importUrl, upLoadCallback, () => setLoadingInfo({ loadingVisible: true, txt: "快速导入中" }))}>
                    <Button
                        disabled={mode == 'edit' ? !editFormVal.branchOut : !addFormVal.branchOut}
                        className="mr-5"
                        type="link"
                        shape="round">导入</Button>
                </Upload>
            </YhAuth>
            {editFormVal.status== "transfer-status.out"&&<Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => {setChangeWarehouseVisible(true)}}
            >批量调入仓库调整</Button>}
        </div>
        <div className="flex">
            <Input
                allowClear
                datatype="round"
                className="border-r-16"
                placeholder="商品编号/商品名称"
                onChange={(e) => setSearchParms(e.target.value)}
                onPressEnter={() => goodsFilter()}
            />
            <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 检索</Button>
        </div>
    </div>;
    const wsChanges = async (value: string, option: ISelectOption, record: ISCMPDrGoodsDetail) => {
        let params = {
            pageSize: 10000,
            pageNum: 1,
            warehouseCode: value,
        }
        const { retData } = await warehouseService.shelfAllPage(params)
        const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
        setShelfAllList(newRecords)
        if (!record?.inShelfCode) {
            mode === "edit" ?
                setEditGoodsList(origin => origin.map(item => item.materialCode === record.materialCode ? { ...item, inShelfCode: '临时货位' } : item)) :
                setAddGoodsList(origin => origin.map(item => item.materialCode === record.materialCode ? { ...item, inShelfCode: '临时货位' } : item));
        }
    }

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '提示',
            width: 60,
            fixed: "left",
            align: "center",
            dataIndex: '',
            render: (t: any, r: any) => !r.isHave || r?.error ? <Tooltip placement="topLeft" title={!r.isHave ? r?.msg ?? '调入门店没有该商品, 保存时将自动添加该商品, 请及时维护对应的商品信息' : r.error} arrowPointAtCenter><ExclamationCircleOutlined style={{ color: '#FF4500', fontSize: '20px' }} /></Tooltip> : ''
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => { e.stopPropagation(); onHistoryModel(record) }}>{text}</p></Tooltip>,

        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
        }, {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '调拨数量',
            width: 100,
            dataIndex: 'num',
            editable: mode === "add",
        },{
            title: '折前单价',
            width: 120,
            dataIndex: 'prePrice',
            editable: mode === "add",
            render: (text: any) => `￥${formatNum(text)}`
        },  {
            title: '折前调拨金额',
            width: 120,
            dataIndex: 'preAmount',
            render: (text: any) => `￥${formatNum(text)}`
        },{
            title: '下浮',
            width: 100,
            dataIndex: 'discountRate',
            // editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        },{
            title: '单价',
            width: 130,
            dataIndex: 'price',
            // editable: mode === "add",
            render: (text: any) => `￥${formatNum(text)}`
        },
        // {
        //     title: '下浮',
        //     width: 100,
        //     dataIndex: 'discountRate',
        //     editable: mode === "add",
        // },
        {
            title: '调拨金额',
            width: 136,
            dataIndex: 'amount',
            render: (text: any) => `￥${formatNum(text)}`
        }, {
            title: '成本单价',
            width: 136,
            dataIndex: 'costPrice',
            render: (text: any) => `￥${formatNum(text ?? 0)}`
        }, {
            title: '调入仓库',
            width: 120,
            dataIndex: 'inWarehouseCode',
            editable: editFormVal.status !== "transfer-status.in",// || (detailEditForm.status === "purchase-status.bill" && mode === DetailType.edit) ? false : true,
            editType: "select",
            // onSelectChange: wsChanges,
            options: transOptions(warehouseList.filter((item: any) => item.type !== 'warehouse-category.small')),
            rules: [{ required: true, message: "请选择仓库" }],
            render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '调入货位',
            width: 120,
            dataIndex: 'inShelfCode',
            editable: editFormVal.status !== "transfer-status.in",
            editType: "select",
            rules: [{ required: true, message: "请选择货位" }],
            options: transOptions(shelfAllList),
            render: (text: string) => text ? text : "请选择货位",
        }, {
            title: '调出仓库',
            width: 120,
            dataIndex: 'outWarehouseName',
        }, {
            title: '调出货位',
            width: 120,
            dataIndex: 'outShelfCode',
        }, {
            title: '商品类型',
            width: 120,
            dataIndex: 'typeName',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        },
        // {
        //     title: '备注',
        //     width: 160,
        //     dataIndex: 'note',
        //     editable: mode === "add",
        // }
    ];

    const newSelectColumns = columns.map((col: any, index) => {
        return {
            ...col,
            onCell: (record: any, rowIndex: number) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = async(row: ISCMPDrGoodsDetail) => {
        let dataSource = mode === "edit" ? editGoodsList : addGoodsList
        const index = dataSource.findIndex((item) => row.key === item.key);
        const item = dataSource[index];
        row.num = +formatInt(row.num, +(row?.inventoryNum ?? 0), "调拨数量>=库存");
        row.price = +bigNumber.times(row.prePrice,(1 - (+row.discountRate))).toFixed(2);
        row.prePrice = +formatNum(row.prePrice);
        row.preAmount = +bigNumber.times(row.prePrice,row.num).toFixed(2);
        if (mode === "add" && row.prePrice < +formatNum(row.costPrice)) {
            row.prePrice = +formatNum(row.costPrice);
            message.warning("您填写的单价不能小于进货价,已为您重置为进货价");
        }
        row.discountRate = +formatDiscount(row.discountRate);
        row.inWarehouseName = getFindItem(row.inWarehouseCode as string, warehouseList)?.name;
        row.inShelfId = getFindItem(row.inShelfCode as string, shelfAllList)?.id ?? row.shelfId;
        //总计=单价*（1-下浮）*开单数量
        //row.amount = +formatNum(row.price * (1 - row.discountRate) * row.num);
        row.amount = +bigNumber.toFixed(bigNumber.times(bigNumber.times(row.prePrice,bigNumber.minus(1,row.discountRate)),row.num),2)

        try{
            const params = {
                pageSize: 99999,
                pageNum: 1,
                materialCode:row.materialCode,
                shopId,
                status: `${DictCodeEnmu.PURCHASE_STATUS_BILL},${DictCodeEnmu.PURCHASE_STATUS_COMPLETELY_IO},${DictCodeEnmu.PURCHASE_STATUS_ALLOCATION}`,
            }
            if(item.inWarehouseCode!==row.inWarehouseCode){
            const {retData:infoAll} = await purchaseDetailsService.stockPage(params);
            let {records} = infoAll;
           if(records.length<1||!records.find((item)=>item.warehouseCode==row.inWarehouseCode)){
            let params = {
                pageSize: 10000,
                pageNum: 1,
                warehouseCode: row.inWarehouseCode,
            }
            const { retData } = await warehouseService.shelfAllPage(params)
            const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
            setShelfAllList(newRecords)
            if (!row?.inShelfCode) {
                mode === "edit" ?
                    setEditGoodsList(origin => origin.map(item => item.materialCode === row.materialCode ? { ...item, inShelfCode: '临时货位' } : item)) :
                    setAddGoodsList(origin => origin.map(item => item.materialCode === row.materialCode ? { ...item, inShelfCode: '临时货位' } : item));
            }
           }
            if(!records.find((item)=>item.warehouseCode==row.inWarehouseCode)) {
                row.inWarehouseCode =row.inWarehouseCode
                if(dataSource[index].inShelfCode == '临时货位' ) {
                    row.inShelfCode=row.inShelfCode
                }else{
                    row.inShelfCode= '临时货位'
                }
            }
            else if(records.find((item)=>item.warehouseCode==row.inWarehouseCode)) {
                row.inWarehouseCode =records.find((item)=>item.warehouseCode==row.inWarehouseCode)?.warehouseCode  as string
                row.inShelfCode = records.find((item)=>item.warehouseCode==row.inWarehouseCode)?.shelfCode   as string 
            }

           }
        dataSource.splice(index, 1, { ...item, ...row });
        mode === "edit" ? setEditGoodsList([...dataSource]) : setAddGoodsList([...dataSource]);     
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(dataSource)
        }
        setBtnContral(true)
    }catch(e){}
    }


    return (
        <YhBox title="" action={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.key}
                rowClassName={(record) => {
                    if (!record?.isHave || record?.error) return "editable-row yh-edit-table-tr background-FFB4B4";
                    return "editable-row yh-edit-table-tr"
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys: React.Key[], selectedRows: ISCMPDrGoodsDetail[]) => {
                        setSelectedRowKeys(selectedRowKeys as string[]);
                    }
                }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns as ColumnsType<ISCMPDrGoodsDetail>}
                dataSource={searchVal.isSearch ? searchVal.data : mode === "edit" ? editGoodsList : addGoodsList}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <ChangeWarehouseModel mode={mode} visible={changeWarehouseVisible} setVisible={setChangeWarehouseVisible}/>
        </YhBox>
    )
}