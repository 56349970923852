import React, { useState, useEffect } from 'react';
import { YhPrint } from "@/components/BCMYhPrint";
import { formatNum } from '@/utils/utils';
import { useGlobalModel } from '@/model/globalModel';
import { Radio } from 'antd';
import { useSaleDcDetailsModel } from './saleAllotDetailsModel';
import { IBCMSaleDcDetail, IBCMSDcGoodsOne } from '../../allotType';
import { saleDcService } from '../../allotService';

export function Print () {
    const { print, setPrint, editFormVal } = useSaleDcDetailsModel();
    const [formVal, setFormVal] = useState<IBCMSaleDcDetail>();
    const [goodsList, setGoodsList] = useState<IBCMSDcGoodsOne[]>([]);
    const { user } = useGlobalModel();
    useEffect(() => {
        print && getPDrDetailOne()
    }, [print])

    //获取一条数据
    const getPDrDetailOne = async () => {
        let { retData } = await saleDcService.one(editFormVal.id);
        let { details, ...formVal } = retData;
        if (retData) {
            setGoodsList(details)
            setFormVal(retData)
        }
    };

    const columns = [
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '商品类型',
            dataIndex: 'typeName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '金额',
            dataIndex: 'amount',
            render: (text: string | number) => `￥${formatNum(text)}`
        },
		{
            title: '仓库',
            dataIndex: 'outWarehouseName',
        },
        {
            title: '货位',
            dataIndex: 'outShelfCode',
        },

    ]

    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "调出单号",
                fieldValue: formVal?.code
            },
            {
                fieldLabel: "调出门店",
                fieldValue: formVal?.outShopName
            },
            {
                fieldLabel: "调入门店",
                fieldValue: formVal?.shopName
            },
            {
                fieldLabel: "调出实体",
                fieldValue: formVal?.outBentityName,
                className: "w30"
            },
            {
                fieldLabel: "调出日期",
                fieldValue: formVal?.transferOutTime,//当前时间
                className: "w30"
            },
            {
                fieldLabel: "调入日期",
                fieldValue: formVal?.createTime,
                className: "h34_w40"
            },
        ],
        footer: [
            {
                fieldLabel: "制表人",
                fieldValue: user.userName,
                className: "w20"
            },
            {
                fieldLabel: "业务",
                fieldValue: formVal?.createUser,
                className: "w20"
            },
            {
                fieldLabel: "财务",
                fieldValue: formVal?.createUser,
                className: "w20"
            },
            {
                fieldLabel: "库房",
                fieldValue: formVal?.createUser,
                className: "w20"
            },
            {
                fieldLabel: "结算员",
                fieldValue: formVal?.createUser,
                className: "w20"
            },
            {
                fieldLabel: "备注",
                fieldValue: formVal?.note,
                className: "note"
            },
        ],
        totalAmount: [
            {
                fieldLabel: "应付金额",
                fieldValue: formVal?.totalAmount,
            },
        ]
    }

    const [paperSize, setPaperSize] = useState<number>(41);  //打印的商品条数
    const modalTitle = <>
        <span style={{ marginRight: "15px" }}>打印详情</span>
        <Radio.Group onChange={(e) => setPaperSize(e.target.value)} value={paperSize}>
            <Radio value={46}>A4</Radio>
            <Radio value={41}>10x11</Radio>
        </Radio.Group>
    </>

    return (
        <YhPrint
            modalTitle={modalTitle}
            title={formVal?.bentityName ?? "北京德兴志达商贸有限公司"}
            type="调出单"
            visible={print}
            onClose={() => setPrint(false)}
            columns={columns}
            dataSource={goodsList}
            printShow={printShow}
            paperSize={paperSize}
        />
    );
}

export default Print;
