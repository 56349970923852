import React, { FC } from "react";
import { Button, Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailsModel";
import { ColumnsType } from "antd/es/table";
import { ITopType } from "@/types/ScmTypes";
import { DetailType } from "@/types/ScmEnums";
import { IDetailList } from "../../purchaseRoType";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { ISelectOption } from "@/components/YhFormBox/Types";
import { warehouseService } from "@/views/base/warehouse/warehouseService";
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import useGlobalModel, { transOptions, getFindItem } from "@/model/globalModel";
import { formatNum, formatIntPoint ,bigNumber} from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const TableBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        setAddModalVisible,
        detailAddList,
        detailDataSource,
        detailLookList,
        shelfAllList,
        detailEditForm,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        setShelfAllList,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        detailDetailList,
        setTopButsControl,
        setHistoryVisible,
        setHistoryRow
    } = usePurchaseRoDetailsModel();
    const onHistoryModel = (record: IDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const { warehouseList } = useGlobalModel()

    const switchModelViaible = () => {
        IFormRef.current?.validateFields().then(async res => {
            setAddModalVisible(true)
        })
    }
    const action = <>
        {(mode !== "look" ? (mode === "add" || detailEditForm.status !== "purchase-return.settle") : false) && <>
            <YhAuth resCode={ResourceAuthEnum.P_R_ED_ADD_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => switchModelViaible()}
                >新增配件</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_R_ED_DEL_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { detailDetailList(mode) }}
                >批量删除</Button>
            </YhAuth>
        </>}
    </>
    const wsChanges = async (value: string, option: ISelectOption, record: IDetailList) => {
        let params = {
            pageSize: 1000,
            pageNum: 1,
            warehouseCode: value,
        }
        const { retData } = await warehouseService.shelfAllPage(params)
        // const { records, ...pg } = retData;
        const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
        setShelfAllList(newRecords)
    }
    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,

        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 120,
            dataIndex: 'purchaseNum',
        }, {
            title: '采购单价',
            width: 120,
            dataIndex: 'purchasePrice',
        }, {
            title: '退货数量',
            width: 120,
            dataIndex: 'num',
            editable: mode === DetailType.look || (detailEditForm.status === "purchase-return.settle" && mode === DetailType.edit) ? false : true,
        }, {
            title: '退货单价',
            width: 120,
            dataIndex: 'price',
            editable: mode === DetailType.look || (detailEditForm.status === "purchase-return.settle" && mode === DetailType.edit) ? false : true,
        }, {
            title: '退货金额',
            width: 120,
            dataIndex: 'amount',
        }, {
            title: '采购单号',
            width: 120,
            dataIndex: 'purchaseCode',
        }, {
            title: '已退货数量',
            width: 120,
            dataIndex: 'preturnNum',
        }, {
            title: '仓库',
            width: 160,
            dataIndex: 'warehouseCode',
            // editable: mode != "look" ? true : false,
            editType: "select",
            onSelectChange: wsChanges,
            options: transOptions(warehouseList),
            rules: [{ required: true, message: "请选择仓库" }],
            render: (text: string) => transOptions(warehouseList).find(item => item.value === text)?.label ?? "请选择仓库",
        }, {
            title: '货位',
            width: 160,
            dataIndex: 'shelfCode',
            // editable: mode != "look" ? true : false,
            editType: "select",
            rules: [{ required: true, message: "请选择货位" }],
            options: transOptions(shelfAllList),
            render: (text: string) => text ?? "请选择货位",
        }, {
            title: '备注',
            width: 300,
            dataIndex: 'note',
            editable: mode != "look" ? true : false,
        }
    ];
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    const handleSave = (row: IDetailList) => {
        let { num, price, amount, warehouseCode, shelfCode, preturnNum, purchaseNum } = row
        preturnNum = +formatNum(preturnNum)
        purchaseNum = +formatNum(purchaseNum)
        price = +formatNum(price)
        num = +formatIntPoint(num, purchaseNum - preturnNum, "退货数量>=采购数量-已退数量")

        let newRow = {
            ...row,
            price,
            warehouseName: getFindItem(warehouseCode as string, warehouseList)?.name,
            shelfId: getFindItem(shelfCode as string, shelfAllList)?.id ?? row.shelfId,
            num,
            // amount: +formatNum(num * price),
            amount:+bigNumber.toFixed(bigNumber.times(num,price),2)
        }
        let newData = mode === "edit" ? detailDataSource : detailAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        //判断仓库变化时，  重置货位
        if (newRow.warehouseCode !== newData[index].warehouseCode) {
            newRow.shelfCode = undefined
        }
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
        setTopButsControl(true)
    }
    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="配件信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.detailsId as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns() as ColumnsType<IDetailList>}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}