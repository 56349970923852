import React from "react";
import { message, notification, Modal } from "antd";
import { ModalProps } from "antd/lib/modal";

import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

type IElType = "message" | "notification" | "modal";

type IMessageType = "success" | "error" | "warning" | "info";

type IPlactment = 'topLeft' | "topRight" | "bottomLeft" | "bottomRight";

interface PromptParams extends ModalProps {
    text: string | React.ReactNode,
    type?: IMessageType,
    icon?: React.ReactNode;             //图标 
    title?: string | React.ReactNode,
    duration?: number,                  //延时时间   
    placement?: IPlactment,              //弹出位置  （notification通知框）
    onClick?: () => void,                //点击回调   （用于notification通知框点击）
    onClose?: () => void,                //关闭回调   （用于notification通知框点击）
    btn?: React.ReactNode
}

export function globalPrompt(
    elType: IElType,
    {
        text,
        type = "info",
        icon = "",
        title = "标题",
        duration = 3,
        placement = "topRight",
        onClick = () => null,
        onClose = () => null,
        btn = null,
        ...params
    }: PromptParams,
) {
    if (elType === "message") {
        message.open({
            content: text,
            icon,
            type,
            duration,
            onClose
        })
    } else if (elType === "notification") {
        notification[type]({
            description: text,
            message: title,
            icon,
            duration,
            placement,
            btn,
            onClick,
            onClose
        });
    } else if (elType === "modal") {
        Modal.confirm({
            content: text,
            icon: modalIconType(type, icon),
            title,
            width:600,
            ...params
        });
    }

    function modalIconType(type: string, icon?: React.ReactNode) {
        if (icon) return icon;
        switch (type) {
            case "success":
                return <CheckCircleOutlined style={{ color: "#52c41a" }} />;
            case "error":
                return <CloseCircleOutlined style={{ color: "#ff4d4f" }} />;
            case "info":
                return <InfoCircleOutlined style={{ color: "#1890ff" }} />;
            default:
                return <ExclamationCircleOutlined />
        }
    }
};