import React from "react";
import { useMonitoringModel } from "../monitoringModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { DictCodeEnmu } from "@/types/ScmDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu as VRMDictCodeEnmu } from "@/types/VrmSaDictEnums";


export function PageHeader() {
    const { getSelectedDictOne } = useDictModel(() => []);
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useMonitoringModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "单据编号",
            fieldName: "code"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据类型",
            fieldName: "tradeTypeCode",
            // mode: "multiple",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE)
                .filter(item =>
                    item.value === DictCodeEnmu.TRADE_TYPE_SO ||
                    item.value === DictCodeEnmu.TRADE_TYPE_PO ||
                    item.value === DictCodeEnmu.TRADE_TYPE_SRO ||
                    item.value === DictCodeEnmu.TRADE_TYPE_PRO ||
                    item.value === DictCodeEnmu.TRADE_TYPE_TO ||
                    item.value === DictCodeEnmu.TRADE_TYPE_TRO)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "状态(请先选择单据类型)",
            fieldName: "status",
            // mode: "multiple",
            options: searchParam?.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_SO
                ? getSelectedDictOne(DictCodeEnmu.SALE_STATUS)
                : searchParam.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_PO
                    ? getSelectedDictOne(DictCodeEnmu.PURCHASE_STATUS)
                    : searchParam.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_SRO
                        ? getSelectedDictOne(DictCodeEnmu.SALE_RETURN_STATUS)
                        : searchParam.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_PRO
                            ? getSelectedDictOne(DictCodeEnmu.PURCHASE_RETURN_STATUS)
                            : searchParam.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_TO
                                ? getSelectedDictOne(VRMDictCodeEnmu.SALE)
                                : searchParam.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_TRO
                                    ? getSelectedDictOne(VRMDictCodeEnmu.SALE_RETURN)
                                    : []
        },
    ]
    return (
        <YhPageHeader
            title="业务数据监控"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}