import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { ShiftShelfModal } from "./ShiftShelfModal"
import { ShiftShelfModals } from "./ShiftShelfModals"
import { useStockPoDetailModel } from "../purchaseDetailsModel";
import { TranRecord } from "./TranRecord";
import { OccupyStockModel } from "./OccupyStockModel"

export default () => {
    const {
        recordsVisible, setRecordsVisible,
        setRecordsSearch,
        occupyStock, setOccupyStockModel,
    } = useStockPoDetailModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            <ShiftShelfModal />
            <ShiftShelfModals />
            <TranRecord
                visible={recordsVisible}
                onCancel={() => { setRecordsVisible(false); setRecordsSearch({}) }}
            />
            <OccupyStockModel
                visible={!!occupyStock}
                materialCode={occupyStock?.materialCode as string}
                onCancel={() => setOccupyStockModel(null)}
            />
        </>
    )
}