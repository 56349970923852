import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { useSaleRoModel } from "../saleRoModel";
import { SalePrint } from "./SalePrint";
import { SettleScaniaPrints } from "./settleScaniaPrints"

export default () => {
    const { print, settleScaniaPrint } = useSaleRoModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <SalePrint />}
            {settleScaniaPrint && <SettleScaniaPrints />}
        </>
    )
}