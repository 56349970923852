import React, { useRef } from "react";
import { useAccountModel } from "./accountModel";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { UserTypeEnum } from "@/types/FmsEnums";
import { accountService, creditFlagDs } from "../../../../AccountService";
import { message, Button, Upload, Form } from "antd";
import { useDetailModel } from "../detailModel";
import useGlobalModel from "@/model/globalModel";
import { useOrgModel } from "@/views/organization/org/orgModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import { UploadOutlined } from "@ant-design/icons";
import FontIcon from "@/components/YhIcon";
import { UploadChangeParam } from "antd/lib/upload";

export const RechargeDrawer = () => {
    const { customer } = useDetailModel(model => [model.customer]);
    const { beShopDs } = useGlobalModel();
    const { getOrgTreeSelect } = useOrgModel(() => []);
    const { getTreeSelectOne } = useDictModel(() => [])
    const {
        selectedData,
        rechargeDrawerVisible,
        setRechargeDrawerVisible,
        onReset,
    } = useAccountModel(model => [model.rechargeDrawerVisible]);
    const formRef = useRef<IYhFormRef>();
    const normFile = (e: UploadChangeParam) => {
        let filetypes: string[] = [".jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc"];
        let isType: boolean = false;
        let fileEnd: string = e?.file.name.substring(e?.file.name.lastIndexOf("."));
        isType = filetypes?.includes(fileEnd) ?? false;
        console.log('Upload event:', e);
        if (e?.file.status === 'done') {
            if (e?.file?.response?.retCode == 200) {
                const data = {
                    name: e.file.name,
                    size: e.file.size,
                    type: e.file.type,
                    uid: e?.file?.response?.retData,
                }
                // e.fileList = [data]
                e.fileList[e.fileList.length - 1] = data
            } else {
                message.error('文件上传失败，请重新上传!')
                e.fileList?.pop()
            }
        }
        if (e.file.status === 'error') {
            message.error('文件上传失败，请重新上传!')
            e.fileList?.pop()
        }
        if (Array.isArray(e)) {
            return e;
        }
        if (!isType) {
            e.fileList?.pop()
        }
        return e && e.fileList;
    };
    const formConfig: IYhForm = {
        formRef,
        labelCol: { span: 6 },
        disabled: false,
        formValues: { ...selectedData },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户",
                fieldName: "code",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户",
                fieldName: "name",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "授信类型",
                fieldName: "creditFlag",
                disable: true,
                span: 24,
                options: creditFlagDs(),
            },
            {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "机构",
                fieldName: "orgCode",
                disable: true,
                span: 24,
                treeDs: getOrgTreeSelect(DictCodeEnmu.ORG)
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "实体",
                fieldName: "beCode",
                disable: true,
                span: 24,
                options: beShopDs
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "剩余金额",
                fieldName: "remainingAmount",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "充值金额",
                fieldName: "rechargeAmount",
                span: 24,
                rules: [{
                    required: true,
                    pattern: new RegExp(/^[0-9|\.\-]*$/),
                    message: "输入不能为空且输入数为数字!"  
                }],
            },
            {
                type: FormItemTypeEnum.TreeSelect,
                fieldLabel: "支付方式",
                fieldName: "payMethodCode",
                span: 24,
                treeData: getTreeSelectOne(DictCodeEnmu.PAY_METHOD),
                rules: [{
                    required: true,
                    message: "支付方式不能为空"
                }],
            },
            {
                type: FormItemTypeEnum.Other,
                fieldLabel: "附件",
                fieldName: "file",
                colClassName: "ant-col-h-auto",
                span: 24,
                formItem: <Form.Item
                    name="fileList"
                    label="附件"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={selectedData?.creditFlag === 0 ? [
                        {
                            required: true,
                            message: '现结账户充值需要上传附件信息'
                        }
                    ] : undefined}
                >
                    <Upload
                        name="file"
                        action={"https://file-server.laoniuche.cn/file/uploadFile"}
                        // maxCount={1}
                        listType="picture"
                        beforeUpload={(file:any) => {
                            let fileEnd: string = file.name.substring(file.name.lastIndexOf("."));
                            // let filetypes: string[] = [".jpg", ".png", ".doc", ".xls", ".pdf", ".docx", ".xlsx", ".mp4", ".mp3"];
                            let filetypes: string[] = [".jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc"];
                            // var filetypes = [".jpg", ".png", ".rar", ".txt", ".zip", ".doc", ".ppt", ".xls", ".pdf", ".docx", ".xlsx", ".mp4", ".mp3"];
                            let isType = filetypes?.includes(fileEnd);
                            if (!isType) {
                                message.error('不支持该格式的上传,当前仅支持 图片、pdf和.doc 类型文件');
                            }
                            const isLt20M = file.size / 1024 / 1024 < 20;
                            if (!isLt20M) {
                                message.error('上传到文件不能超过20M')
                                    file.status='done';
                            }
                            return isType && isLt20M;
                        }}

                        iconRender={(file) => {
                            if (file?.type?.indexOf('image/') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-tupian" />
                            if (file?.type?.indexOf('pdf') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-pdf" />
                            if (file?.type?.indexOf('msword') !== -1) return <FontIcon style={{ fontSize: '24px' }} type="icon-doc" />
                        }}
                    >
                        <Button icon={<UploadOutlined />}>选择文件上传</Button>
                    </Upload>
                </Form.Item>
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "收款人",
                fieldName: "operator",
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "remark",
                span: 24,
            },
        ]
    };

    const onOk = async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const { fileList = [], ...params } = formData
                const reqData: any = {
                    ...params,
                    accountCode: formData.code,
                    partnerTypeCode: UserTypeEnum.CUSTOMER,
                    partnerCode: customer.code,
                    note: formData.remark
                };
                if (fileList.length > 0) {
                    const file = fileList[0];
                    if (!file?.uid) {
                        console.log(file);
                        return message.error('文件信息有误，请重新上传文件')
                    }
                    reqData.md5 = file?.uid;
                    reqData.fileName = file.name;
                    reqData.fileSize = file.size;
                }
                let insertRet = await accountService.recharge(reqData);
                if (insertRet.success) {
                    message.success(selectedData?.creditFlag === 0 ? "充值申请成功" : "充值成功!");
                    onReset();
                    setRechargeDrawerVisible(false);
                } else {
                    message.error("充值失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log("数据校验失败");
        }
    }
    const onCancel = () => {
        setRechargeDrawerVisible(false);
    }
    return (
        <YHDrawer
            {...{
                title: "账户充值",
                visible: rechargeDrawerVisible,
                onOk,
                onCancel,
                showFooter: true
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
