import { httpRequest } from "../../utils/HttpRequest";
import {
    IAccountFields,
    IAccountDetail,
    IAccountAdjustApproval,
    IAccountAdjustSubmit,
    IAccountOne,
    IAccountSearch,
    IAccountAdjustFields,
    IAccountAdjustSearch,
    IAccountAdjustDetail,
    IAccountTransferFields,
    IAccountTransferDetail,
    IAccountTransferSearch,
    IAccountWriteoffFields,
    IAccountWriteoffDetail,
    IAccountWriteoffSearch,
    IAccountRechargeFields,
    IAccountRechargeDetail,
    IAccountRechargeSearch,
    IAccountLogSearch,
    IAccountLogDetail,
    ICustomerAccountSummary,
    IAccountSummary,
    ISupplierAccountSummary,
    IAccountType,
    IFMSInitAccountForm,
    IAccountRechargeApproval

} from "./AccountTypes";
import { Key } from "react";
import { IMdCustomerSearch, IMdSupplierSearch } from "@/types/FmsCustomerTypes";
import { CreditFlagEnum } from "@/types/ScmEnums";
import { IPage } from "@/types/ScmTypes";


export const creditFlagDs = () => {
    return [{
        label: '授信',
        value: CreditFlagEnum.CREDIT.valueOf()
    }, {
        label: '非授信',
        value: CreditFlagEnum.NON_CREDIT.valueOf()
    },
    {
        label: '结转',
        value: CreditFlagEnum.CARRY_FORWARD.valueOf()
    }]
}

class AccountService {
    //
    async customerSummaryPage(searchVo: IMdCustomerSearch) {
        const apiUrl = "/fms/account/summary/customer/page";
        return await httpRequest
            .post<IPage<ICustomerAccountSummary>>(apiUrl, searchVo);
    }

    //
    async customerSummaryOne(code: string | Key, shopCode: string) {
        const apiUrl = `/fms/accountShop/summary/customer/one/${code}/${shopCode}`;
        return await httpRequest
            .post<ICustomerAccountSummary>(apiUrl);
    }

    //
    async customerSummary(searchVo: IMdCustomerSearch) {
        const apiUrl = "/fms/account/summary/customer";
        return await httpRequest
            .post<IAccountSummary>(apiUrl, searchVo);
    }

    //
    async supplierSummaryPage(searchVo: IMdSupplierSearch) {
        const apiUrl = "/fms/account/summary/supplier/page";
        return await httpRequest
            .post<IPage<ISupplierAccountSummary>>(apiUrl, searchVo);
    }


    //
    async supplierSummaryOne(code: string, shopCode: string) {
        const apiUrl = `/fms/accountShop/summary/supplier/one/${code}/${shopCode}`;
        return await httpRequest
            .post<ISupplierAccountSummary>(apiUrl);

    }

    //
    async supplierSummary(searchVo: IMdSupplierSearch) {
        const apiUrl = "/fms/account/summary/supplier";
        return await httpRequest
            .post<IAccountSummary>(apiUrl, searchVo);
    }

    //开户
    async open(param: IAccountFields) {
        const apiUrl = "/fms/account/open";
        return await httpRequest
            .post<IAccountDetail>(apiUrl, param);
    }

    //禁用账户
    async disable(param: IAccountOne) {
        const apiUrl = "/fms/account/disable";
        return httpRequest
            .post<boolean>(apiUrl, param)
    }

    //启用账户
    async enable(param: IAccountOne) {
        const apiUrl = "/fms/account/enable";
        return httpRequest
            .post<boolean>(apiUrl, param)
    }

    //账户查询
    async find(param: IAccountFields) {
        const apiUrl = "/fms/account/find";
        return await httpRequest
            .post<IAccountDetail[]>(apiUrl, param)
    }

    async accountDs(param: IAccountFields) {
        let { retData } = await this.find(param);
        return retData.map(el => ({
            label: el.name ?? "",
            value: el.code ?? "",
            ...el,
        }))
    }

    //账户查询(分页)
    async page(param: IAccountSearch) {
        const apiUrl = "/fms/account/page";
        return await httpRequest
            .post<IPage<IAccountDetail>>(apiUrl, param)
    }

    //账户查询(单条)
    async one(code: string) {
        const apiUrl = "/fms/account/one/" + code;
        return await httpRequest
            .post<IAccountDetail>(apiUrl);
    }

    //调额
    async adjust(param: IAccountAdjustFields) {
        const apiUrl = "/fms/account/adjust";
        return await httpRequest
            .post<boolean>(apiUrl, param);
    }

    //调额提交
    async adjustSubmit(param: IAccountAdjustSubmit) {
        const apiUrl = "/fms/account/adjust/submit";
        return await httpRequest
            .post<boolean>(apiUrl, param);
    }

    //调额审核
    async adjustApproval(param: IAccountAdjustApproval) {
        const apiUrl = "/fms/account/adjust/approval";
        return await httpRequest
            .post<boolean>(apiUrl, param);
    }

    //调额记录查询(分页)
    async adjustPage(param: IAccountAdjustSearch) {
        const apiUrl = "/fms/account/adjust/page";
        return await httpRequest
            .post<IPage<IAccountAdjustDetail>>(apiUrl, param);
    }

    //转账
    async transfer(param: IAccountTransferFields) {
        const apiUrl = "/fms/account/transfer";
        return await httpRequest
            .post<IAccountTransferDetail>(apiUrl, param);
    }

    //转账记录查询(分页)
    async transferPage(param: IAccountTransferSearch) {
        const apiUrl = "/fms/account/transfer/page";
        return await httpRequest
            .post<IPage<IAccountTransferDetail>>(apiUrl, param);
    }

    //核销
    async writeoff(param: IAccountWriteoffFields) {
        const apiUrl = "/fms/account/writeoff";
        return await httpRequest
            .post<IAccountWriteoffDetail>(apiUrl, param);
    }

    //核销总额
    async writeoffTotalAmount(param: IAccountWriteoffSearch) {
        const apiUrl = "/fms/account/writeoff/writeoffTotalAmount";
        return await httpRequest
            .post<number>(apiUrl, param);
    }
    // 核销记录查询(分页)
    async writeoffPage(param: IAccountWriteoffSearch) {
        const apiUrl = "/fms/account/writeoff/page";
        return await httpRequest
            .post<IPage<IAccountTransferDetail>>(apiUrl, param);
    }

    //充值
    async recharge(param: IAccountRechargeFields) {
        const apiUrl = "/fms/account/recharge";
        return await httpRequest
            .post<IAccountRechargeDetail>(apiUrl, param);
    }

    //充值总额
    async rechargeTotalAmount(param: IAccountRechargeSearch) {
        const apiUrl = "/fms/account/recharge/rechargeTotalAmount";
        return await httpRequest
            .post<number>(apiUrl, param);
    }

    //充值记录查询(分页)
    async rechargePage(param: IAccountRechargeSearch) {
        const apiUrl = "/fms/account/recharge/page";
        return await httpRequest
            .post<IPage<IAccountRechargeDetail>>(apiUrl, param);
    }
    //充值审批
    async rechargeApproval(param: IAccountRechargeApproval) {
        const apiUrl = "/fms/account/recharge/approval";
        return await httpRequest
            .post<boolean>(apiUrl, param);
    }
    //账户日志查询(分页)
    async logPage(param: IAccountLogSearch) {
        const apiUrl = "/fms/account/log/page";
        return await httpRequest
            .post<IPage<IAccountLogDetail>>(apiUrl, param);
    }

    //账户初始化
    async initAccount(param: IFMSInitAccountForm) {
        const apiUrl = "/fms/account/summary/customer/customerAccount";
        return await httpRequest
            .post<boolean>(apiUrl, param);
    }

    //查询特定得收款账户
    accountFind(params: object) {
        const apiUrl = "/fms/account/find";
        return httpRequest
            // .post<IPage<IAccountType>>(apiUrl, params);
            .post<IAccountType[]>(apiUrl, params);
    }
}

export const accountService = new AccountService();
