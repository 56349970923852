import React from "react";
import { useCustomerModel } from "../baseCustomerModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setSelectedRowKeys
    } = useCustomerModel();
    const {  getSelectedDictOne } = useDictModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "客户类型",
            fieldName: "typeCode",
            options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "联系人",
            fieldName: "contact"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "联系电话",
            fieldName: "phone"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "客户状态",
            fieldName: "enable",
            options: [{ label: "启用", value: 1 }, { label: "禁用", value: 0 }]
        },
        {
            type: SearchTypeEnum.Datepicker,
            placeholder: "登记日期",
            fieldName: "createTime",
        },
    ]
    return (
        <YhPageHeader
            title="客户管理"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => {loadData(initPgCfg, { ...searchParam, ...params });setSelectedRowKeys([]);}}
            handleReset={resetSearchParam}
        />
    );
}