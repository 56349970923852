import React, { useEffect, useState } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, defaultMdCmParam } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { IAccountTransferDetail, IAccountTransferFields, IAccountTransferSearch, IAccountTransferSearchParam } from "../../../../AccountTypes";
import { accountService } from "../../../../AccountService";
import { useDetailModel } from "../detailModel";
import { useGlobalModel } from "@/model/globalModel";
import { transPgToPagination } from "@/utils/utils";

export const useTransferModel = createModel(function () {
    const initSearchParam: IAccountTransferSearchParam = {};
    const initDataSource: IAccountTransferDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const { user: { shopCode } } = useGlobalModel();
    //查询条件
    const [searchParam, setSearchParam] = useState<IAccountTransferSearchParam>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IAccountTransferDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedData, setSelectedData] = useState<IAccountTransferFields>();
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    const [openDrawerVisible, setOpenDrawerVisible] = useState(false);
    const [adjustDrawerVisible, setAdjustDrawerVisible] = useState(false);
    const [rechargeDrawerVisible, setRechargeDrawerVisible] = useState(false);
    const [transferDrawerVisble, setTransferDrawerVisble] = useState(false);
    const { customerCode } = useDetailModel();

    const onSearch = () => {
        loadData({ pageNum: 1 }).then()
    }
    const onReset = () => {
        resetSearchParam()
    }

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IAccountTransferFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IAccountTransferSearchParam = searchParam) => {
        const searchParams: IAccountTransferSearch = {
            ...searchValue,
            partnerInCode: customerCode,
            partnerOutCode: customerCode,
            shopCode,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await accountService.transferPage(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }


    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedData,
        openDrawerVisible,
        adjustDrawerVisible,
        rechargeDrawerVisible,
        transferDrawerVisble,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setOpenDrawerVisible,
        setAdjustDrawerVisible,
        setRechargeDrawerVisible,
        setTransferDrawerVisble,
        onSearch,
        onReset,
        setSelectedData,
    }
});
