import React, { FC } from "react";
import { Button, Table, Upload, Tooltip, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { globalPrompt } from "@/components/message";
import { ColumnsType } from "antd/es/table";
import { useSaleOrderDetailModel } from "./saleOrderDetailModel";
import { RouterParamType } from "@/model/navModel";
import { importPropsConfig } from "@/utils/importFile";
import { DetailType, IPriceTypeEnum, ISalesTypeEnum } from "@/types/ScmEnums";
import { IDetailList } from "../../saleOrderTypes";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import useGlobalModel from "@/model/globalModel";
import { purchaseDetailsService } from '@/views/stock/purchaseDetails/purchaseDetailsService';
import BigNumber from "bignumber.js";
import { formatNum, formatIntPoint ,bigNumber, closeWebPage} from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
export const TableBox: FC<RouterParamType> = ({ id, mode }) => {
    const { shopAndOrg: { shopId } } = useGlobalModel()

    const {
        setAddModalVisible,
        detailAddForm,
        detailEditForm,
        detailAddList,
        detailDataSource,
        detailLookList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        detailDetailList,
        downloadTpl,
        modelScreenMethod,
        setHistoryVisible,
        setHistoryRow
    } = useSaleOrderDetailModel();
    const onHistoryModel = (record: IDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const { getSelectedDictOne } = useDictModel();
    const uniqueArr = (arr: any[], str: string) => {
        const res = new Map()
        return arr.filter((arr) => !res.has(arr[str]) && res.set(arr[str], 1))
    }
    
      let { priceTypeCode, maintenanceTypeCode, customerCode,markup } = mode === "add" ? detailAddForm : detailEditForm;    
      const importCallback = async (list: any) => {
        let detailsList = mode == "add" ? detailAddList : detailDataSource;
        let newList = uniqueArr(list, 'materialCode')
        let newImportList: any[] = []
        await Promise.all(newList.map(async (item: any) => {
            let { stockId, latestPurPrice, materialCode, lateSalePrice, discountRate, purchasePrice, retailPrice, num, ...params } = item as { latestPurPrice: number, lateSalePrice: number, purchasePrice: number, retailPrice: number, num: number, materialCode: string, discountRate: number, stockId: string }
            let newPrice: number = 0;
            if (maintenanceTypeCode !== ISalesTypeEnum.NORMAL_SALES) {
                newPrice = latestPurPrice ?? purchasePrice
            } else if (maintenanceTypeCode === ISalesTypeEnum.NORMAL_SALES) {
                if (priceTypeCode === IPriceTypeEnum.CUSTOMER_LATEST) {//需要通过接口查询，客户最近一次的销售价
                    await purchaseDetailsService.recentSalesPrice(shopId, materialCode, customerCode as string).then(res => {
                        let { retData: { price } } = res;
                        newPrice = price as any
                    });
                } else if (priceTypeCode === IPriceTypeEnum.RECENT) {//售价选择 最近销价
                    newPrice = lateSalePrice ?? retailPrice
                } else if (priceTypeCode === IPriceTypeEnum.RETAIL) {//售价选择 零售价
                    newPrice = retailPrice
                }
            }
            let markUpNum=1
            if(markup){
              let num:any= getSelectedDictOne(DictCodeEnmu.MARKUP_RATE).find(item=>item.value==markup);
              num= num.label.replace("%","")/100;
              markUpNum+=num
            }
            return {
                ...params,
                latestPurPrice,
                purchasePrice,
                retailPrice,
                lateSalePrice,
                stockId,
                id: stockId,
                materialCode,
                num,
                discountRate,
                // price: newPrice,
                // notAmount: +formatNum(num * newPrice),
                // notAmount:+bigNumber.toFixed(bigNumber.times(num,newPrice),2),
                // //notAmount:+bigNumber.toFixed(bigNumber.times( bigNumber.times(num,newPrice),bigNumber.dividedBy(discountRate,100)),2),
                // amount:+bigNumber.toFixed(bigNumber.times( bigNumber.times(num,newPrice),bigNumber.dividedBy(discountRate,100)),2)
                price:+bigNumber.toFixed(bigNumber.times(markUpNum,newPrice),2),
                notAmount: +bigNumber.toFixed(bigNumber.times(num,bigNumber.times(markUpNum,newPrice)),2),
                amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(num,bigNumber.times(markUpNum,newPrice)),bigNumber.dividedBy(discountRate,100)),2),

            }
        })).then(reslist => {
            newImportList = reslist
        })
        const { repetition, newDetailData } = await modelScreenMethod(list, detailAddList,mode)
        if (mode === "add") {
            setDetailAddList([...detailAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        } else if (mode === "edit") {
            setDetailDataSource([...detailDataSource, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });
        }
    }

    const addModal = () => {
        if (!maintenanceTypeCode) {
            message.warning("请选择销售类型");
            return;
        }
        if (!customerCode) {
            message.warning("请选择客户");
            return;
        }
        setAddModalVisible(true)
    }
    const action = <>
        {mode !== "look" && <>
            <YhAuth resCode={ResourceAuthEnum.S_SO_ED_ADD_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => addModal()}
                >新增配件</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.S_SO_ED_DEL_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { detailDetailList(mode) }}
                >批量删除</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.S_SO_ED_IM}>
                <Upload
                    beforeUpload={(file, fileList) => {
                        if (!maintenanceTypeCode) {
                            message.warning("请选择销售类型");
                            return false;
                        }
                        if (!customerCode) {
                            message.warning("请选择客户");
                            return false;
                        }
                        return true
                    }}
                    showUploadList={false}
                    className="mr-10 ml-10"
                    {...importPropsConfig(`/bcm/sale/details/import/${shopId}`, importCallback)}
                >
                    <Button
                        disabled={!(maintenanceTypeCode && customerCode)}
                        className="mr-5"
                        type="link"
                        shape="round"
                    >导入</Button>
                </Upload>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.S_SO_ED_DLOAD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={downloadTpl}
                >下载模板</Button>
            </YhAuth>

        </>
        }
    </>

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编码',
            width: 100,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 100,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '订单数量',
            width: 80,
            dataIndex: 'num',
            editable: mode != "look" ? true : false,
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            editable: mode != "look"||markup ? true : false,
        }, {
            title: '金额',
            width: 100,
            dataIndex: 'notAmount',
        }, {
            title: '折扣',
            width: 100,
            dataIndex: 'discountRate',
            editable: mode != "look" ? true : false,
            suffix: "%",
            render: (text: string) => text + '%'
        }, {
            title: '零件金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, 
        {
            title: '成本价',
            width: 100,
            dataIndex: 'latestPurPrice',
        },{
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryNum',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        }, {
            title: '备注',
            width: 200,
            dataIndex: 'note',
            fixed: "right",
            editable: mode != "look" ? true : false,
        }
    ];
    const newSelectColumns = () =>
        columns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
          const handleSave = (row: IDetailList) => {
                let { num, price, discountRate } = row
                discountRate = +formatIntPoint(discountRate, 100, '折扣不能高于100%');
                num = +formatIntPoint(num)
                // price = +formatNum(price);  
                price = +bigNumber.toFixed(formatNum(price),2);
                // let markUpNum=1
                // if(markup){
                //   let num:any= getSelectedDictOne(DictCodeEnmu.MARKUP_RATE).find(item=>item.value==markup);
                //   num= num.label.replace("%","")/100;
                //   markUpNum+=num
                // }
                let newRow = {
                  ...row,
                  price,
                  discountRate,
                  num,
                  notAmount: +bigNumber.toFixed(bigNumber.times(num,price),2),
                  amount:+bigNumber.toFixed(bigNumber.times( bigNumber.times(num,price),bigNumber.dividedBy(discountRate,100)),2)
                //   notAmount: +bigNumber.toFixed(bigNumber.times(markUpNum,bigNumber.times(num,price)),2),
                //   amount:+bigNumber.toFixed(bigNumber.times( bigNumber.times(markUpNum,bigNumber.times(num,price)),bigNumber.dividedBy(discountRate,100)),2)
                
                }
            
                let newData = mode === "edit" ? detailDataSource : detailAddList;
                const index = newData.findIndex((item) => row.stockId === item.stockId);
                const item = newData[index];
            
                // //判断仓库变化时， 重置货位
                // if (newRow.warehouseCode !== newData[index].warehouseCode) {
                //   newRow.shelfCode = undefined
                // }
                newData.splice(index, 1, { ...item, ...newRow });
                mode === "edit" ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
                // setTopButsControl(true)
              }

    const typeList = {
        "add": detailAddList,
        "edit": detailDataSource,
        "look": detailLookList,
    }
    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows: IDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="配件信息" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.stockId as string}
                rowSelection={
                    mode === DetailType.look ? undefined : {
                        selectedRowKeys: mode == DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IDetailList[]) => {
                            setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeDetailDataRow(selectedRows, mode)
                        }
                    }}
                columns={newSelectColumns() as ColumnsType<IDetailList>}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}