import React, { useRef, useState, useEffect } from "react";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from "@/components/YhFormBox/Types";
import { useVehicleModel } from "../vehicleModel";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IVehicleDetail } from "../vehicleType";
import { getFormValidatorErrText } from "@/utils/utils"
import { DetailType } from "@/types/ScmEnums";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { debounce } from "lodash";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const BaseVehicleDetails = () => {
    const { CustomerData } = useGlobalModel()
    const { getSelectedDictOne } = useDictModel();

    const {
        addFormVal,
        editFormVal,
        lookFormVal,
        updateAddFormVal,
        updateEditFormVal,
        insertDetail,
        editDetail,
        getDetailOne,
        setAddFormVal,
        setSelectedRowKeys
    } = useVehicleModel();
    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const formRef = useRef<IYhFormRef>();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { id, mode, parentInfo } = detailsInfo

    //编辑时， 没有缓在调用详情接口
    useEffect(() => {
        if (!Object.keys(editFormVal).length && mode === "edit") {
            getDetailOne(id, mode)
        }
        mode === "look" && getDetailOne(id, mode)

    }, [detailsInfo]);
    useEffect(() => {
        if (parentInfo) {
            let { customerCode } = parentInfo
            updateAddFormVal({ customerCode })
        }

    }, [parentInfo])
    useEffect(() => {
        return () => {
            let addNavExist = findNav("13-5-2-1");
            let editNavExist = findNav("13-5-2-2");
            !addNavExist && updateAddFormVal(null);
            !editNavExist && updateEditFormVal(null);
        }
    }, [])

    //关闭当前页
    const onClose = () => {
        if (parentInfo) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "13-5-2");
            detailsInfo.mode === "edit" ? updateEditFormVal(null) : updateAddFormVal(null);
        }
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            if (detailsInfo.mode === "edit") {
                editDetail({ ...res, id: detailsInfo.id, code: editFormVal.code } as IVehicleDetail).then(res => {
                    res && onClose()
                })
            } else {
                insertDetail(res as IVehicleDetail).then(res => {
                    setSelectedRowKeys([res as string])
                    res && onClose()
                })
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    //实时保留缓存
    const onChange = (changedValues: { [key: string]: any }) => {
        detailsInfo.mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues);
    }

    const baseFormItem: IYhFormItemsBox = {
        title: "基本信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户名称",
                fieldName: "customerCode",
                allowClear: true,
                showSearch: true,
                options: transOptions(CustomerData),
                rules: [{ required: true, message: "请输入客户名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆牌照",
                fieldName: "licensePlate",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发动机号",
                fieldName: "engineCode"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "model"
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "categoryCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
                rules: [{ required: true, message: "请选择车辆类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "变速箱号",
                fieldName: "gearboxCode"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "使用性质",
                // options: [{ label: "卡车", value: "卡车" }],
                fieldName: "natureCode"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车桥",
                fieldName: "axle"
            }, {
                type: FormItemTypeEnum.Select,//VEHICLE_BRAND
                fieldLabel: "车辆品牌",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_BRAND),
                fieldName: "brandCode"
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "注册日期",
                fieldName: "registerDate"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车系",
                fieldName: "series"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "登记日期",
                fieldName: "createTime",
                disable: true
                // }, {
                //     type: FormItemTypeEnum.Input,
                //     fieldLabel: "车型",
                //     fieldName: "model"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "Vin码",
                fieldName: "vin"
                // }, {
                //     type: FormItemTypeEnum.FileImage,
                //     fieldLabel: "行驶证",
                //     btnTitle:"上传行驶证",
                //     fieldName: "vin"
            }
        ]
    };
    const priceFormItem: IYhFormItemsBox = {
        title: "保养信息（字段暂无）",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "上次保养日期",
                fieldName: "createTime"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "上次保养里程",
                fieldName: "createTime"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "下次保养日期",
                fieldName: "createTime"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "下次保养里程",
                fieldName: "createTime"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "最新里程",
                fieldName: "createTime"
            }
        ]
    }

    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal
    }
    const typeTitle = {
        add: "新增",
        edit: "编辑",
        look: "查看",
    }
    const supplierFromConfig: IYhFormBox = {
        formRef,
        labelCol: { span: 6 },
        disabled: mode == DetailType.look,
        labelAlign: "right",
        // formValues: detailsInfo.mode === "edit" ? editFormVal : addFormVal,
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem, priceFormItem]
    }

    const reset = async () => {
        formRef.current?.resetFields()
        if (detailsInfo.mode === "add") {
            setAddFormVal({})
        } else if (detailsInfo.mode === "edit") {
            getDetailOne(detailsInfo.id, mode)
        }
        message.success("重置成功")
    }
    const action = <>
        {mode != "look" &&
            <YhAuth resCode={ResourceAuthEnum.DATA_C_V_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { onSubmit() }, 900)}

                >保存</Button>
            </YhAuth>}
        {mode != "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => reset()}
        >重置</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onClose}
        >取消</Button>
    </>;

    return (
        <>
            {/* <YhPageHeader title="车辆详情" /> */}
            <YhBox title={`车辆${typeTitle[mode]}`} action={action} style={{ paddingBottom: "0px", marginBottom: "15px" }}></YhBox>
            <YhFormBox {...supplierFromConfig} />
        </>
    )
}