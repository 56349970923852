import React, { useState, useEffect } from "react";
import { Table, Button, Input, message } from "antd";
import { YHModal } from "@/components/YHModal";
import { useStockPoDetailModel } from "../InventoryDetailsModel";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { IPoShiftImportDetail } from "../InventoryDetailsService";
import { YhBut } from "@/components/YhButton";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { warehouseService } from "@/views/base/warehouse/warehouseService";
import { Item, EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { ISelectOptions } from "@/types/ScmTypes";
interface FilrResponse {
    retCode: number,
    retData: string,
    retMsg: string,
    success: boolean
}
interface File {
    uid?: string,
    name?: string,
    status?: string,
    response: FilrResponse,
    linkProps?: any
}
interface Upload {
    file: File,
    fileList: any,
    event: any
}
export const ShiftShelfModal = () => {
    const { user: { shopId }, warehouseList } = useGlobalModel();
    const {
        shiftShelfModal: visible, setShiftShelfModal,
        setSearchParamShelf,
        searchParamShelf,
        dataSourceShelf,
        selectedShelfRows, setSelectedShelfRows,
        pgCfgShelf,
        loadDataShelf, shiftShelfApi
    } = useStockPoDetailModel();
    const [shelfDs, setShelfDs] = useState<ISelectOptions[]>();  // 实时查询货位

    useEffect(() => {
        if (visible) {
            loadDataShelf({ pageNum: 1 }, {});
            setSearchParamShelf({});
            setSelectedShelfRows([]);
        }
    }, [visible])

    const onCancel = () => {
        setShiftShelfModal(false);
    };


    //货位根据仓库code查询
    const queryShelf = async (wsCode: string) => {
        const searchParams = {
            shopId,
            warehouseCode: wsCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData: records } = await warehouseService.shelfAllPage(searchParams);

        setShelfDs(records.map((item: any) => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    //仓库选择  (置空货位)
    const wsChange = (value: string, options: ISelectOptions, record: IPoShiftImportDetail) => {
        if (options && options.id) {
            queryShelf(options.value as string)
        }
    }

    //货位触焦
    const shelfFocus = (record: Item) => {
        if (record["warehouseCode1"]) {
            queryShelf(record["warehouseCode1"] as string)
        } else {
            setShelfDs([]);
        }
    }
    const columns: ColumnsType<IPoShiftImportDetail> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: string) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '原仓库',
            width: 160,
            dataIndex: 'warehouseName',
            render: (text: string) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '原货位',
            width: 160,
            dataIndex: 'shelfCode',
        }, {
            title: '原库存',
            width: 160,
            dataIndex: 'availableStock',
        }, {
            title: '操作',
            width: 80,
            align: "center",
            fixed: "right",
            className: "td-wrap",
            render: (text, record) => <YhBut
                type="add"
                txt="添加"
                click={() => setSelectedShelfRows(origin => [...origin, { ...record, moveNum: record.availableStock ?? 0 }])}
                disabled={!!selectedShelfRows.find(item => item.id === record.id) || record.availableStock === 0}
            />
        }
    ];

    const columnsSelect = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: string) => <p className="ellipsis" title={text}>{text}</p>
        }, {
            title: '原仓库',
            width: 140,
            dataIndex: 'warehouseName',
        }, {
            title: '原货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '原库存',
            width: 100,
            dataIndex: 'availableStock',
        }, {
            title: '仓库',
            width: 150,
            dataIndex: "warehouseCode1",
            editable: true,
            editType: "select",
            options: transOptions(warehouseList),
            onSelectChange: wsChange,
            render: (text: string) => warehouseList.find((item: any) => item.code === text)?.name
        }, {
            title: '货位',
            width: 150,
            dataIndex: 'shelfCode1',
            editable: true,
            editType: "select",
            options: shelfDs, //transOptions(shelfDs as any),
            onSelectFocus: shelfFocus
        }, {
            title: '转移库存',
            width: 120,
            editable: true,
            editType: "input",
            dataIndex: 'moveNum',
        }, {
            title: '操作',
            width: 80,
            align: "center",
            fixed: "right",
            className: "td-wrap",
            render: (text: any, record: IPoShiftImportDetail) => <YhBut
                type="delete"
                txt="移除"
                click={() => {
                    const newData = selectedShelfRows.filter(item => item.id !== record.id);
                    setSelectedShelfRows(newData);
                }}
            />
        }
    ];

    const newSelectColumns = columnsSelect.map((col: any, index) => {
        return {
            ...col,
            onCell: (record: IPoShiftImportDetail, rowIndex: number) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row: IPoShiftImportDetail) => {
        let dataSource = [...selectedShelfRows];
        const index = dataSource.findIndex((item) => row.id === item.id);
        console.log(row.warehouseCode1)
        //选中货位的话， 赋值货位code
        if (row.shelfCode1) {
            // debugger;
            row.shelfId1 = shelfDs?.find(item => item.value === row.shelfCode1)?.id;
        }
        //设置仓库名称
        if (row.warehouseCode1) {
            row.warehouseName1 = warehouseList.find((item: any) => item.code === row.warehouseCode1)?.name
        }
        //判断仓库变化时，  重置货位
        if (row.warehouseCode1 !== dataSource[index].warehouseCode1) {
            row.shelfCode1 = undefined
        }

        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        setSelectedShelfRows([...dataSource]);
    }

    const action = <>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => shelftDownloadTpl()}
        >下载模板</Button>
        <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/2/purchase/po/transfer/library/import/${shopId}`, upLoadCallback)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload> */}
    </>;
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16  mr-5"
            placeholder="商品名称"
            onChange={(e) => setSearchParamShelf({ ...searchParamShelf, materialName: e.target.value })}
            onPressEnter={() => loadDataShelf({ pageNum: 1, pageSize: 15 })}
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16"
            placeholder="商品编号"
            onChange={(e) => setSearchParamShelf({ ...searchParamShelf, materialCode: e.target.value })}
            onPressEnter={() => loadDataShelf({ pageNum: 1, pageSize: 15 })}
        />
        <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => loadDataShelf({ pageNum: 1, pageSize: 15 })}>检索</Button>
    </div>
    return <YHModal
        visible={visible}
        onOk={() => {
            let filterPayData = selectedShelfRows.filter(item => (item.shelfCode1));
            if (filterPayData.length !== selectedShelfRows.length) {
                message.warning("请选择要转移的货位");
                return;
            }
            shiftShelfApi()
        }}
        onCancel={onCancel}
        title="采购明细-转移货位"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "5px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id}
                columns={columns}
                dataSource={dataSourceShelf}
                pagination={{
                    ...pgCfgShelf,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadDataShelf({ pageNum, pageSize }).then();
                    }
                }}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表" rightChildren={action}>
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns as ColumnsType<IPoShiftImportDetail>}
                dataSource={selectedShelfRows}
                pagination={false}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}