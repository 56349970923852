import React from "react";
import { DataBox } from "./DataBox";

export default () => {
    return (
        <>
            {/* <YhPageHeader title="机构管理" /> */}
            <DataBox />
        </>
    )
}