import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useStockEntryModel } from "../stockEntryModel";
import { momentFormat } from "@/utils/utils";
import { IStockEntryField } from "../stockEntryType"
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";



export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows,setHistoryVisible,setHistoryRow
    } = useStockEntryModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "9-6-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    useEffect(() => {
        loadData()
    }, [])
    const onRow = (record: IStockEntryField) => {
        // let findIndex = selectedRowKeys.indexOf(id);
        // let newSelectedRowKeys = [...selectedRowKeys];
        // if (findIndex === -1) {
        //     newSelectedRowKeys.push(id);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // } else {
        //     newSelectedRowKeys.splice(findIndex, 1);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // }
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const momentArr = (text: any) => {
        return `${text[0]}-${text[1] >= 10 ? text[1] : '0' + text[1]}-${text[2] >= 10 ? text[2] : '0' + text[2]} ${text[3] >= 10 ? text[3] : '0' + text[3]}:${text[4] >= 10 ? text[4] : '0' + text[4]}`
    }

    const onHistoryModel = (record: IStockEntryField) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }


    const columns: ColumnsType<IStockEntryField> = [
        {
            title: '序号',
            width: 120,
            render: (t, r, i) => i + 1,
            fixed: "left",
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'tradeCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => onHistoryModel(record)}/>,
            //render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(record.id)}>{text}</p></Tooltip>,

        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'tradeTypeName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '入库数量',
            width: 160,
            dataIndex: 'inTotal',
        }, {
            title: '入库金额',
            width: 160,
            dataIndex: 'amount',
            render:(text)=>`￥${text}`,
        }, {
            title: '单位名称',
            width: 160,
            dataIndex: 'userName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '制单人',
            width: 160,
            dataIndex: 'billingClerk',
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
            render: (text) => momentArr(text)
        }, {
            title: '库管员',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '入库时间',
            width: 160,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '所属门店',
            width: 160,
            dataIndex: 'shopName',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];
    const onRightClick = (record: IStockEntryField) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IStockEntryField[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }
            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
