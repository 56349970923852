import { createModel } from "hox";
import { useState, Key, useEffect } from "react";
import {
    IMaterialDetailList,
    IStockDeliveryDetail,
    IStockDeliveryField,
    IDetailsUpdateParams,
} from "../../stockOutDispatchType"
import { stockOutDispatchService } from "../../stockOutDispatchService"
import { IDetailsList } from "@/views/stock/output/stockOutputType";
import useGlobalModel from "@/model/globalModel";

export enum DeliveryDetailsTabsEnum {
    Material = "配件信息",
    DeliveryRecord = "出库记录",
    WarehousingRecord = "入库记录",
}
export const useDeliveryDetailsModel = createModel(function () {
    const { user } = useGlobalModel()

    //编辑数据
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState<IStockDeliveryDetail>({})
    //编辑维修用料详情列表
    const [detailMaterialEditList, setDetailMaterialEditList] = useState<IMaterialDetailList[]>([])
    const [detailMaterialEditListRowKeys, setDetailMaterialEditListRowKeys] = useState<Key[]>([])
    const [detailMaterialEditListRow, setDetailMaterialEditListRow] = useState<IMaterialDetailList[]>([])
    useEffect(() => {
        let filters = detailMaterialEditList.filter(el => {
            return detailMaterialEditListRowKeys.map(item => {
                return el.id === item
            })
        })
        detailMaterialEditListRowKeys.length > 0 && setDetailMaterialEditListRow(filters)
        // console.log(filters)
    }, [detailMaterialEditList])

    //出库单据列表
    const [deliveryEditList, setDeliveryEditList] = useState<IDetailsList[]>([])
    //入库单据列表
    const [warehousingEditList, setWarehousingEditList] = useState<IDetailsList[]>([])

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IStockDeliveryDetail>({})
    //查看维修用料详情列表
    const [detailMaterialLookList, setDetailMaterialLookList] = useState<IMaterialDetailList[]>([])
    //出库单据列表
    const [deliveryLookList, setDeliveryLookList] = useState<IDetailsList[]>([])
    //入库单据列表
    const [warehousingLookList, setWarehousingLookList] = useState<IDetailsList[]>([])
    //设置tab状态值
    const [tabsFlag, setTabFlag] = useState<DeliveryDetailsTabsEnum>(DeliveryDetailsTabsEnum.Material)
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await stockOutDispatchService.one(id);
        const { itemDetails, materialDetails, technicianNames, ...from } = retData
        if (retData && pattern === "edit") {
            setDetailMaterialEditList(materialDetails as IMaterialDetailList[] ?? [])
            setDetailEditForm({ ...from, materialHandler: technicianNames, storekeeper: user.username })
        } else if (retData && pattern === "look") {
            setDetailLookForm({ ...from, materialHandler: technicianNames });
            setDetailMaterialLookList(materialDetails as IMaterialDetailList[] ?? [])
        }
    }
    const resetEditCatch = () => {
        setDetailEditForm({})
        setDetailMaterialEditList([])
        setDeliveryEditList([])
        setWarehousingEditList([])
    }
    const resetLookCatch = () => {
        setDetailLookForm({})
        setDetailMaterialLookList([])
        setDeliveryLookList([])
        setWarehousingLookList([])
    }
    const deliveryUpdate = async (params: IStockDeliveryField) => {
        let { retData } = await stockOutDispatchService.deliveryUpdate(params);
        return retData
    }
    const detailsUpdate = async (params: IDetailsUpdateParams) => {
        let { retData } = await stockOutDispatchService.detailsUpdate(params);
        return retData
    }
    const stocke = async (params: IDetailsUpdateParams) => {
        let { retData } = await stockOutDispatchService.stocke(params);
        return retData
    }
    const storage = async (params: IDetailsUpdateParams) => {
        let { retData } = await stockOutDispatchService.storage(params);
        return retData
    }

    const getOutEntry = async (id: string, pattern: string) => {
        let { retData } = await stockOutDispatchService.detailsFindPage({
            tradeCode: id,
            pageNum: 1,
            pageSize: 1000,
        })

        if (retData) {
            let { records } = retData
            // let newSplitList = splitList(records, "ioFlag")
            // let chukuList = newSplitList[0]["data"]
            // let rukuList = newSplitList[1]["data"]

            let chukuList = records.filter(item => item.ioFlag === 0)
            let rukuList = records.filter(item => item.ioFlag === 1)
            if (pattern === "edit") {
                setDeliveryEditList(chukuList)
                setWarehousingEditList(rukuList)
            } else if (pattern === "look") {
                setDeliveryLookList(chukuList)
                setWarehousingLookList(rukuList)
            }
        }
    }
    return {
        detailEditForm,
        detailMaterialEditList,
        detailLookForm,
        detailMaterialLookList,
        setDetailEditForm,
        setDetailMaterialEditList,
        setDetailLookForm,
        setDetailMaterialLookList,
        getDetailEditOne,
        tabsFlag, setTabFlag,
        resetEditCatch,
        resetLookCatch,
        deliveryUpdate,
        detailsUpdate,
        detailMaterialEditListRowKeys, setDetailMaterialEditListRowKeys,
        detailMaterialEditListRow, setDetailMaterialEditListRow,
        stocke,
        storage,
        deliveryEditList, setDeliveryEditList,
        warehousingEditList, setWarehousingEditList,
        deliveryLookList, setDeliveryLookList,
        warehousingLookList, setWarehousingLookList,
        getOutEntry
    }

})