import React, { useEffect } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { ISaleDetail } from "../busBusinessType"
import { useSaleModel } from "./saleModel"
import Tooltip from "antd/es/tooltip";
import YhTag from "@/components/YhTag";
import { useDictModel } from "@/views/base/dict/dictModel";
import { momentFormat } from "@/utils/utils";
import { render } from "react-dom";
import YhTootip from "@/components/YhTootip";
import useNavModel from "@/model/navModel";


type statusType = {
    "sale-status.write-off": string,
    "sale.change": string,
    "sale.stock-io": string,
    "sale.settle": string,
    "sale.create": string,
    [key: string]: any
}
const statusColor: statusType = {
    "sale-status.write-off": "green",
    "sale.change": "red",
    "sale.stock-io": "cyan",
    "sale.settle": "green",
    "sale.create": "blue",
}
export const SaleDataTable = () => {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows
    } = useSaleModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();

    useEffect(() => {
        loadData()
    }, [])

    const columns: ColumnsType<ISaleDetail> = [
        {
            align: "center",
            title: '序号',
            width: 40,
            fixed: 'left',
            render: (t, r, i) => i + 1
        },
        {
            align: "center",
            title: '销售单号',
            dataIndex: 'saleCode',
            fixed: 'left',
            width: 200,
            render: (text: any,record) => <YhTootip text={text} onClick={()=>{
                let supplierEditKey = "16-3-3";
                replaceNav(
                selectedNavId,
                supplierEditKey,
                { id: record.saleCode as string, mode: "look", parentInfo: { selectedNavId } },
                    true
        );
            }} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '单据状态',
            dataIndex: 'status',
            fixed: 'left',
            width: 120,
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        },
        {
            align: "center",
            title: '支付状态',
            dataIndex: 'payStatus',
            width: 120,
            render: (text) => getTreeNodeName(text)
        },
        {
            align: "center",
            title: '客户名称',
            dataIndex: 'customerName',
            width: 200,
            render: (text: any) => <YhTootip text={text} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '联系人',
            dataIndex: 'contact',
            width: 160,
        },
        {
            align: "center",
            title: '销售数量',
            dataIndex: 'totalNum',
            width: 120
        },
        {
            align: "center",
            title: '销售金额',
            dataIndex: 'totalAmount',
            render:(text)=> text ? `￥${text}` : `￥${0}`,
            width: 120
        },
        {
            align: "center",
            title: '优惠金额',
            dataIndex: 'discountTotalAmount',
            render:(text)=> text ? `￥${text}` : `￥${0}`,
            width: 120
        },
        {
            align: "center",
            title: '应收金额',
            dataIndex: 'receivableAmount',
            render:(text)=> text ? `￥${text}` : `￥${0}`,
            width: 120
        },
        {
            align: "center",
            title: '已收金额',
            dataIndex: 'receivedAmount',
            render:(text)=> text ? `￥${text}` : `￥${0}`,
            width: 120
        },
        {
            align: "center",
            title: '销售成本',
            dataIndex: 'totalCost',
            render:(text)=> text ? `￥${text}` : `￥${0}`,
            width: 120
        },
        {
            align: "center",
            title: '销售毛利',
            dataIndex: 'grossProfit',
            render:(text)=> text ? `￥${text}` : `￥${0}`,
            width: 120
        },
        {
            align: "center",
            title: '退货数量',
            dataIndex: 'returnTotalNum',
            width: 120
        },
        {
            align: "center",
            title: '发票类型',
            dataIndex: 'invoiceTypeName',
            width: 120
        },
        {
            align: "center",
            title: '开票状态',
            dataIndex: 'invoiceStatus',
            width: 120,
            render: (text) => text == 1 ? "已开票" : "未开票"
        },
        {
            align: "center",
            title: '结算方',
            dataIndex: 'settlementPartyName',
            width: 140,
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '结算类型',
            dataIndex: 'payType',
            width: 120,
            render: (text) => getTreeNodeName(text)
        },
        {
            align: "center",
            title: '结算方式',
            dataIndex: 'paymentMethodName',
            width: 200,
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            align: "center",
            title: '制单员',
            dataIndex: 'createUser',
            width: 120
        },
        {
            align: "center",
            title: '制单时间',
            dataIndex: 'createTime',
            width: 140,
            render: (text) => momentFormat(text)
        },
        {
            align: "center",
            title: '库管员',
            dataIndex: 'storekeeper',
            width: 120
        },
        {
            align: "center",
            title: '出库时间',
            dataIndex: 'stockIoTime',
            width: 140,
            render: (text) => momentFormat(text)
        },
        {
            align: "center",
            title: '核销时间',
            dataIndex: 'writeOffTime',
            width: 140,
            render: (text) => momentFormat(text)
        },
        {
            align: "center",
            title: '备注',
            dataIndex: 'note',
            width: 120
        },
        {
            align: "center",
            title: '转接单号',
            dataIndex: 'orderCode',
            width: 120,
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '结算人',
            dataIndex: 'reckoner',
            width: 100
        },
        {
            align: "center",
            title: '结算时间',
            dataIndex: 'settleTime',
            width: 150
        },
    ]
    const onRow = (record: ISaleDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    }
    const onRightClick = (record: ISaleDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
             
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: ISaleDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            pagination={{
                showSizeChanger:true,
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />

    )
}