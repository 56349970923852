import React, { useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import { ICustomerAccountSummary } from "../../../AccountTypes";
import { useSummaryModel } from "./summaryModel";

const columns: ColumnsType<ICustomerAccountSummary> = [
    {
        align: "center",
        title: "分支",
        dataIndex: "branch"
    },
    {
        align: "center",
        title: '客户名称',
        dataIndex: 'name',
    },
    {
        align: "center",
        title: '客户编码',
        dataIndex: 'code',
    },
    {
        align: "center",
        title: '客户类型',
        dataIndex: 'categoryName',
    },
    {
        align: "center",
        title: '授信额度',
        dataIndex: 'totalCreditAmount',
    },
    {
        align: "center",
        title: '已用额度',
        dataIndex: 'totalUsedAmount',
    },
    {
        align: "center",
        title: '可用额度',
        dataIndex: 'totalAvailableAmount',
    },
    {
        align: "center",
        title: '剩余总额',
        dataIndex: 'totalRemainingAmount',
    },
    {
        align: "center",
        title: '欠款',
        dataIndex: 'totalDebtAmount',
    },
    // {
    //     title: '从属机构',
    //     dataIndex: 'orgName',
    // },

];

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useSummaryModel(model => [model.dataSource, model.selectedRowKeys]);

    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (record: ICustomerAccountSummary) => {
        let findObj = selectedRowKeys.find(item => item.key === record.key)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.key !== record.key);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };

    return (
        <Table
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys([])
                        // : setSelectedRowKeys((origin) => {
                        //     let changeRowId = changeRows.map(item => item.key);
                        //     return origin.filter(item => changeRowId.indexOf(item.key) < 0)
                        // });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.key)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record]);
                }
            })}
            scroll={{ x: 'max-content',y:500 }}
        />
    )
}

