import React from "react";
import "./home.less"
import { Header } from "./Header"
import { Content } from "./Content"
import { BarContent } from "./barContent"
import { Navigation } from "./Navigation"
import { NavigationModel } from "./NavigationDrawer"
import useGlobalModel from "@/model/globalModel";

export function Home() {
    const { shopAndOrg: { shopCode } } = useGlobalModel()


    return (
        <>
            <Header />
            {shopCode==="SHOP-M-00000022"&&<Content />}
            {shopCode!=="SHOP-M-00000022"&&<BarContent />}
            <Navigation />
            <NavigationModel />
        </>
    )
}