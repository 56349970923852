import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { DetailDrawer } from "./DetailDrawer"
const BaseEntity = () => {
    return (
        <>
            <PageHeader />
            <DataBox />
            <DetailDrawer />
        </>
    )
}
export default BaseEntity
