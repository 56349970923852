import React, { FC } from "react";
import { Button, Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { useAppointmentDetailsModel } from "./appointmentDetailsModels";
import { RouterParamType } from "@/model/navModel";
import { IMaterialDetailList } from "../../appointmentType";
import { bigNumber, formatIntPoint, formatNum } from "@/utils/utils";
import { components } from "@/components/YHEditTable/editTable";
import { DetailType } from "@/types/ScmEnums";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";


export const MaterialTableBox: FC<RouterParamType> = ({ id, mode, parentInfo }) => {
    const {
        setMaterialModel,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        detailMaterialLookList,
        detailMaterialDetailList,
        setHistoryVisible,
        setHistoryRow
    } = useAppointmentDetailsModel();
    const onHistoryModel = (record: IMaterialDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const action = <>
        {mode !== "look" && <>
            <YhAuth resCode={ResourceAuthEnum.AP_ED_ADD_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setMaterialModel(true)}
                >新增商品</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.AP_ED_DEL_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => detailMaterialDetailList(mode, parentInfo)}
                >删除商品</Button>
            </YhAuth>
        </>}
    </>;

    const MaterialColumns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '可用库存',
            width: 160,
            dataIndex: 'inventoryNum',
        }, {
            title: '数量',
            width: 160,
            dataIndex: 'num',
            editable: mode != "look" ? true : false,
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
            editable: mode != "look" ? true : false,
        }, {
            title: '配件费',
            width: 160,
            dataIndex: 'materialAmount',
        }, {
            title: '折扣',
            width: 160,
            dataIndex: 'discountRate',
            editable: mode != "look" ? true : false,
            render: (text: string) => text + "%"
        }, {
            title: '小计',
            width: 160,
            dataIndex: 'receivableAmount',
        }, {
            title: '库房',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 160,
            dataIndex: 'shelfCode',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: mode != "look" ? true : false,
        }
    ];
    const newSelectMaterialColums = () =>
        MaterialColumns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IMaterialDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave = (row: IMaterialDetailList) => {
        let { num, price, discountRate, materialAmount, inventoryNum, availableStock, receivableAmount,  } = row
        num = +formatIntPoint(num, inventoryNum, '选择数量>=可用库存数量');  //数量
        price = +formatIntPoint(price);  //价格
        discountRate = +formatIntPoint(discountRate, 100, '折扣不能高于100%');  //折扣
        let newRow = {
            ...row,
            num,
            price: +formatNum(price),
            discountRate,
            //materialAmount: +formatNum(num * price),
            materialAmount:+bigNumber.toFixed(bigNumber.times(num,price),2),
            //receivableAmount: +formatNum(num * price * discountRate / 100),
            receivableAmount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(num,price),bigNumber.dividedBy(discountRate,100)),2)
        }

        let newData = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailMaterialEditList([...newData]) : setDetailMaterialAddList([...newData])
    }
    const typeMaterialList = {
        "add": [...detailMaterialAddList],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    //设置维修用料选中的行和数据
    const setTypeMaterialDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeMaterialDetailDataRow = async (selectedRows: IMaterialDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRow(selectedRows)
        }
    }
    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectMaterialColums() as ColumnsType<IMaterialDetailList>}
                dataSource={typeMaterialList[mode] as IMaterialDetailList[]}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailMaterialAddDataRowKeys : detailMaterialEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IMaterialDetailList[]) => {
                            setTypeMaterialDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        }
                    }}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }}
            />
        </YhBox>
    )
}