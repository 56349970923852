import React, { useEffect, useRef, useState, FC } from "react";
import { Button, message } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { getFormValidatorErrText, switchNum1, formatNum, bigNumber } from "@/utils/utils"
import { useDictModel } from "@/views/base/dict/dictModel";
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";
import { ISCMPurchaseDrFormFields, ISCMPDrGoodsDetail } from "../../purchaseDrTypes";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { debounce } from "lodash";
import { purchaseDrService } from "../../purchaseDrService";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const FormBox: FC<RouterParamType> = ({ id, mode }) => {
    const formRef = useRef<IYhFormRef>();
    const { fetchFmsShopList, shopAndOrg: { shopId }, } = useGlobalModel(() => []);
    const {
        editFormVal, addFormVal,
        addGoodsList, editGoodsList,
        editDesc, addDesc, inWhConfirm,
        btnContral,
        setAddGoodsList,
        setEditGoodsList,
        setPrint,
        insertPDr,
        saveBtnLoading,
        updatePDr,
        getPDrDetailOne,
        updateEditFormVal,
        updateAddFormVal,
        resetEditCatch,
        resetAddCatch,
        verifyGoods,
        setAddOutShopCode,
        exportPDrdetail,
        setSaveBtnLoading,
        setBtnContral
    } = usePurchaseDrDetailsModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { getSelectedDictOne, getTreeNodeOne, getTreeSelectOne } = useDictModel(() => []);
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮

    //编辑
    useEffect(() => {
        if (mode === "edit") {
            //没有缓在调用详情接口
            if (!editFormVal.id) {
                getPDrDetailOne(id).then(res => {
                    getShopinUnitDetail(res.shopCode)
                    getShopOutUnitDetail({ label: res.outShopName, value: res.outShopCode }, true)
                });
                return;
            }
            editFormVal.outShopCode && getShopOutUnitDetail({ label: editFormVal.outShopName ?? "", value: editFormVal.outShopCode }, true);
            editFormVal.shopCode && getShopinUnitDetail(editFormVal.shopCode);
        }
        if (mode === "add") {
            addFormVal.outShopCode && getShopOutUnitDetail({ label: addFormVal.outShopName ?? "", value: addFormVal.outShopCode }, true);
            addFormVal.shopCode && getShopinUnitDetail(addFormVal.shopCode);
        }
        setBtnContral(false)
    }, [mode]);

    //关闭页签的时候清除对应缓存
    useEffect(() => {
        return () => {
            !findNav("11-4-1") && resetAddCatch()
            !findNav("11-4-2") && resetEditCatch();
            setBtnContral(false)
        }
    }, [selectedNavId])

    //监听商品变化的时候 设置表单金额
    useEffect(() => {
        let values: ISCMPurchaseDrFormFields = formRef.current?.getFieldsValue();
        setFormAmount(values);
    }, [addDesc, editDesc])

    //保留缓存及回显表单
    const saveCache = (val: { [key: string]: any }, isFormItem: boolean = true) => {
        mode === "edit" ? updateEditFormVal(val) : updateAddFormVal(val);
    }

    /**
  * 表单或者商品操作。 （设置金额，回显表单，设置缓存,禁用库房操作）
  */
    const setFormAmount = (values: ISCMPurchaseDrFormFields, changedValues?: { [key: string]: any }) => {
        let { amountAll, actualAmount } = mode === "edit" ? editDesc : addDesc;
        let {
            favorableAmount, //整单优惠
            discountRate, //整单下浮
            aggregateAmount,  //应付金额    
            paidAmount,   //已付金额
            discountTotalAmount, //优惠总额
        } = values;
        discountRate = switchNum1(discountRate, "0");
        favorableAmount = switchNum1(favorableAmount, "0");
        actualAmount = +formatNum(actualAmount);
        amountAll = +formatNum(amountAll);
        //应付金额=sum(总计)*（1-整单下浮）-整单优惠）
        //let isZero = +formatNum(amountAll * (1 - +discountRate) - +favorableAmount);
        let isZero=+bigNumber.toFixed(bigNumber.times(amountAll,bigNumber.minus(bigNumber.minus(1,discountRate),favorableAmount)),2)
        aggregateAmount = isZero < 0 ? `-${isZero}` : isZero;

        //优惠总额 = 所有商品的合计 - 总计 - 整单优惠 - 整单下浮优惠
        discountTotalAmount = formatNum(actualAmount - isZero);

        paidAmount = formatNum(paidAmount);
        // setWsConfirmDisable(true);
        saveCache(changedValues ? changedValues : { discountTotalAmount, aggregateAmount, favorableAmount, discountRate, paidAmount });
    }

    //表单change
    const onChange = (changedValues: { [key: string]: any }, values: any) => {
        if ("favorableAmount" in changedValues || "discountRate" in changedValues) {
            setFormAmount(values);
        } else {
            setFormAmount(values, { ...changedValues });
        }
        setBtnContral(true)
    }
    const queryMaterial = async (goodsList: ISCMPDrGoodsDetail[]) => {
        let retData = await purchaseDrService.queryMaterial({ list: goodsList.map(item => ({ code: item.materialCode as string, shopId })) })
        return retData
    }
    //表单提交
    const onSubmit = async () => {
        if (!verifyGoods(mode)) return;
        // if(!formComputeRule(mode)) return;

        let filterGoodsShelf = mode === "add" ? addGoodsList.filter(item => !!!item.inShelfCode || !!!item.inWarehouseCode) : editGoodsList.filter(item => !!!item.inShelfCode || !!!item.inWarehouseCode);
        // if (!!filterGoodsShelf.length) {
        //     message.warning("没有对应调入仓库和调入货位");
        //     return false;
        // }
        let isMaterial = await queryMaterial(mode === "add" ? addGoodsList : editGoodsList)
        if (!isMaterial) return false
        formRef.current?.validateFields().then((val: any) => {
            mode === "edit" ?
                updatePDr(val) :
                insertPDr(val).then(res => {
                    if (res) {
                        resetEditCatch();
                        replaceNav("11-4-1", "11-4-2", { id: res ?? "", mode: "edit" });
                    }
                });
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    };

    const [inUnitBeList, setinUnitBeList] = useState<ISelectOption[]>([]);  //调入门店对应的关联实体
    //回显选中调入门店带出来的数据  
    const getShopinUnitDetail = async (shopId: string) => {
        let { retData } = await purchaseDrService.queryShopUnitBe(shopId as string);
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setinUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
            } else {
                setinUnitBeList([]);
                message.warning("调入门店对应的核算单位没有关联实体")
            }
        } else {
            setinUnitBeList([]);
            message.warning("调入门店没有关联的核算单位");
        }
    }

    const [outUnitBeList, setOutUnitBeList] = useState<ISelectOption[]>([]);  //调出门店对应的关联实体
    //回显选中调出门店带出来的数据 
    const getShopOutUnitDetail = async (option: ISelectOption, echo: boolean = false) => {
        let { retData } = await purchaseDrService.queryShopUnitBe(option.id as string ?? option.value as string);
        let data = {
            outShopCode: option.value,
            outShopName: option.label,
            outShopId: option.id,
            transferTypeName: option.branch === "scm" ? "备品调拨" : "其它调拨",
            transferTypeCode: option.branch === "scm" ? DictCodeEnmu.TRANSFER_TYPE_BP : DictCodeEnmu.TRANSFER_TYPE_OTHERS,
            outBentityName: undefined,   //每次选中的时候对应的实体置空
            outBentityCode: undefined,
            contact: undefined,
            phone: undefined,
            branchOut: option.branch,
        }
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setOutUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
            } else {
                setOutUnitBeList([]);
                message.warning("调出门店对应的核算单位没有关联实体")
            };
            //回显时不用执行一下操作
            if (echo) return;
            saveCache({
                ...data,
                contact: retData.contact,
                phone: retData.phone,
            });
        } else {
            saveCache(data);
            setOutUnitBeList([]);
            message.warning("调出门店没有关联的核算单位");
        }
    }
    //调出门店
    const outShopChange = (value: string, option: ISelectOption) => {
        mode === "add" && setAddOutShopCode(!!value);
        mode === "add" ? setAddGoodsList([]) : setEditGoodsList([]);

        if (option && option.id) {
            getShopOutUnitDetail(option)
        }
    }
    //调出实体
    const outBeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ outBentityName: option.label, outBentityCode: option.value });
    }
    //调入实体
    const inBeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ bentityName: option.label, bentityCode: option.value });
    }
    //调拨类行
    const transferTypeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ transferTypeName: option.label, transferTypeCode: option.value });
    }
    const onRefresh = debounce(() => {
        getPDrDetailOne(id).then(res => message.success("已刷新"));
    }, 500);
    const whConFirm = () => {
        if (!verifyGoods(mode)) return;

        let filterGoodsShelf = mode === "add" ? addGoodsList.filter(item => !!!item.inShelfCode || !!!item.inWarehouseCode) : editGoodsList.filter(item => !!!item.inShelfCode || !!!item.inWarehouseCode);
        if (!!filterGoodsShelf.length) {
            message.warning("没有对应调入仓库和调入货位");
            return false;
        }

        formRef.current?.validateFields().then((val: any) => {
            inWhConfirm(val)
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }
    const rightChildren = <>
        {mode === "edit" ? <>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportPDrdetail()}
            >导出</Button>
            <YhAuth resCode={ResourceAuthEnum.P_DR_ED_PRINT}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setPrint(true)}
                >打印</Button>
            </YhAuth>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onRefresh}
            >刷新</Button>
            <YhAuth resCode={ResourceAuthEnum.P_DR_AD_APPLY}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    loading={saveBtnLoading}
                    disabled={editFormVal.status === DictCodeEnmu.TRANSFER_STATUS_IN}
                    onClick={debounce(() => {
                        onSubmit();
                    }, 500)}
                >{saveBtnLoading ? '保存中' : '保存'}</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.P_DR_ED_DR}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    disabled={editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_OUT || btnContral}
                    onClick={debounce(() => { whConFirm() }, 500)}
                >调入</Button>
            </YhAuth>

        </> : <YhAuth resCode={ResourceAuthEnum.P_DR_AD_APPLY}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => {
                    onSubmit();
                }, 500)}
            >请调</Button>
        </YhAuth>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { replaceNav(selectedNavId, "11-4"); setSaveBtnLoading(false) }}
        >返回</Button>
    </>
    const [shopList, setShopList] = useState<ISelectOption[]>([])
    useEffect(() => {
        fetchFmsShopList('').then(arr => {
            setShopList(arr)
        })
    }, [])
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "10px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入单号",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: '调入门店',
                fieldName: "shopName",
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: '调出门店',
                fieldName: "outShopCode",
                onSelectChange: outShopChange,
                rules: [{ required: true, message: "请选择调出门店" }],
                // selectfetchList: fetchFmsShopList,
                options:shopList
                // defaultQuery: mode === "edit" ? editFormVal.outShopName : addFormVal.outShopName
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "整单下浮",
                fieldName: "discountRate",
                hidden: true,
                rules: [{ required: true, message: "该项为必填" }, { pattern: /^0\.[0-9]{1,2}$|^0{1}$|^1{1}$|^1\.[0]{1,2}$/, message: '请输入0-1' }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "应付金额",
                fieldName: "aggregateAmount",
                disable: true,
                hidden: true,
                // }, {
                //     type: FormItemTypeEnum.Input,
                //     fieldLabel: "结算类型",
                //     fieldName: "payType",
                //     disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "调拨类型",
                fieldName: "transferTypeCode",
                onSelectChange: transferTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.TRANSFER_TYPE),
                disable: true,
                rules: [{ required: true, message: "请选择调拨类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "调入实体",
                fieldName: "bentityCode",
                options: inUnitBeList,
                onSelectChange: inBeChange,
                rules: [{ required: true, message: "请选择调入实体" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "调出实体",
                fieldName: "outBentityCode",
                onSelectChange: outBeChange,
                options: outUnitBeList,
                rules: [{ required: true, message: "请选择调出实体" }]
            }, {
                type: FormItemTypeEnum.Other,
                fieldLabel: "",
                fieldName: ""
                // span: 10,
                // labelCol: { span: 4 },
                // colClassName: "detail-note-col"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "整单优惠",
                fieldName: "favorableAmount",
                hidden: true,
                rules: [{ pattern: /^[0-9]+(.[0-9]{0,2})?$/, message: '请输入数字且可保留两位小数' }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "已付金额",
                fieldName: "paidAmount",
                disable: true,
                hidden: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "优惠总额",
                fieldName: "discountTotalAmount",
                disable: true,
                hidden: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "applier",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入员",
                fieldName: "transferIn",
                disable: true,
                // hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出员",
                fieldName: "transferOut",
                disable: true,
                // hidden: more
            }, {
                type: FormItemTypeEnum.Other,
                fieldLabel: "",
                fieldName: ""
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单日期",
                fieldName: "applyTime",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出日期",
                fieldName: "transferOutTime",
                disable: true,
                // hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入日期",
                fieldName: "transferInTime",
                disable: true,
                // hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单人",
                fieldName: "createUser",
                disable: true,
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改人",
                fieldName: "updateUser",
                disable: true,
                hidden: true
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入门店Code",
                fieldName: "shopCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出门店名称",
                fieldName: "outShopName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出门店id",
                fieldName: "outShopId",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出实体名称",
                fieldName: "outBentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调拨类型名称",
                fieldName: "transferTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入分支",
                fieldName: "branchIn",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出分支",
                fieldName: "branchOut",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "状态",
                fieldName: "status",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调出单号",
                fieldName: "transferOutCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "调入单号",
                fieldName: "transferInCode",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "成本金额",
                fieldName: "costAmount",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "毛利",
                fieldName: "grossProfit",
                hidden: true
            },
        ]
    };

    const poFromConfig: IYhFormBox<ISCMPurchaseDrFormFields> = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: mode === "edit" ? editFormVal : addFormVal,
        onChange,
        disabled: mode === "edit",
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}