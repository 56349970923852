import React, { useEffect, FC } from "react";
import { YhFormBox } from "@/components/YhFormBox";
import { ITopType } from "@/types/ScmTypes";
import { Button } from "antd";
import { IYhFormItemsBox, FormItemTypeEnum, IYhFormBox } from "@/components/YhFormBox/Types";
import useNavModel from "@/model/navModel";
import { useEntryDetailsModel } from "./stockEntryModelDetails"
import { DetailType } from "@/types/ScmEnums";
export const FormBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const { selectedNavId, replaceNav } = useNavModel();
    const { detailLookForm, getDetailLookOne } = useEntryDetailsModel()
    useEffect(() => {
        mode === DetailType.look && getDetailLookOne(id, mode)

    }, [mode, id])
    const goBack = () => {
        replaceNav(selectedNavId, "9-5")
    }
    const rightChildren = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "单据编号",
                fieldName: "tradeCode",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "单据类型",
                fieldName: "tradeTypeName",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "billingClerk",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "billingTime",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库管员",
                fieldName: "createUser",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "入库时间",
                fieldName: "createTime",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "单位名称",
                fieldName: "userName",
            }
        ]
    }
    const onChange = () => {

    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        disabled: true,
        labelAlign: "right",
        formValues: detailLookForm,
        onChange,
        boxs: [baseFormItem]
    }
    return (
        <YhFormBox {...poFromConfig} />
    )
}
