import React, { FC } from "react";
import { useSaleReturnDetailModel } from "./saleReturnDetailModel";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { YHDrawer } from "@/components/YhDrawer";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { globalPrompt } from "@/components/message";

export const WhConfirmDrawer: FC<RouterParamType> = ({ id, mode }) => {
    const { wsConfirmModal: visible, detailMaterialEditList, detailMaterialLookList, setWsConfirmModal, warehousing, getDetailEditOne } = useSaleReturnDetailModel();
    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();

    const onCancel = () => {
        setWsConfirmModal(false);
    };

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 80,
            dataIndex: "index",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        },
        {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
        }, {
            title: '配件名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '入库数量',
            width: 160,
            dataIndex: 'num',
        }
    ];

    const onSubmit = async () => {
        let retData = await warehousing()
        if (retData) {
            await onCancel();
            await getDetailEditOne(id, mode);
            await globalPrompt("message", { text: "入库成功", type: "success" });

        } else {
            await onCancel()
            await globalPrompt("message", { text: "入库失败", type: "error" });
        }
    }
    return (
        <YHDrawer
            {...{
                title: '库房确认',
                visible,
                onCancel,
                width: 800,
                okText: "保存",
                onOk: onSubmit,
                showFooter: mode === "look" ? false : true
            }}
        >
            <Table
                // size="small"
                rowKey={record => record.id}
                columns={columns}
                dataSource={mode == "edit" ? detailMaterialEditList : detailMaterialLookList}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 400 }}
            />
        </YHDrawer>
    )
}