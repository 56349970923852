import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useStockCheckModel } from "../stockCheckModel";
import { IStockCheckDetail } from "../stockCheckType";
import { useDictModel } from "@/views/base/dict/dictModel";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys,setHistoryVisible,setHistoryRow
    } = useStockCheckModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "9-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    useEffect(() => {
        loadData()
    }, [])
    const onHistoryModel = (record: IStockCheckDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }

    const columns: ColumnsType<IStockCheckDetail> = [
        {
            title: '序号',
            width: 60,
            render: (t, r, i) => i + 1,
            fixed: "left",
        }, {
            title: '盘库单号',
            width: 200,
            dataIndex: 'stocktakingCode',
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => onHistoryModel(record)}/>,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(text)}>{text}</p></Tooltip>,
            fixed: "left",
        }, {
            title: '盘点状态',
            width: 120,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => getTreeNodeName(text)
        }, {
            title: '盘点类型',
            width: 120,
            dataIndex: 'type',
            fixed: "left",
            render: (text) => getTreeNodeName(text)
        }, {
            title: '盘点结果',
            width: 120,
            dataIndex: 'result',
        }, {
            title: '仓库',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '系统库存',
            width: 120,
            dataIndex: 'bookTotalNum',
        }, {
            title: '实际库存',
            width: 160,
            dataIndex: 'realTotalNum',
        }, {
            title: '盘盈总数',
            width: 160,
            dataIndex: 'surplusItem',
        }, {
            title: '盘亏总数',
            width: 160,
            dataIndex: 'lossItem',
        }, {
            title: '差异数量',
            width: 160,
            dataIndex: 'differenceTotalNum',
        }, {
            title: '差异金额',
            width: 160,
            dataIndex: 'differenceTotalAmounts',
            render:(text)=>`￥${text}`,
        }, {
            title: '差异率',
            width: 160,
            dataIndex: 'differenceRateAll',
            render: (text) => text ? text + "%" : ""
        }, {
            title: '开始时间',
            width: 160,
            dataIndex: 'startTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结束时间',
            width: 160,
            dataIndex: 'endTime',
            render: (text) => momentFormat(text)
        }, {
            title: '制单人',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '制单时间',
            width: 160,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '所属门店',
            width: 160,
            dataIndex: 'shopName',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IStockCheckDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
