import React from "react";
import { useSaleRoModel } from "../saleRoModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSaleRoModel();
    const {  getSelectedDictOne } = useDictModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "退货单号",
            fieldName: "returnCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            fieldName: "status",
            options:getSelectedDictOne(DictCodeEnmu.SALE_RETURN_STATUS)
        },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "结算方式",
        //     fieldName: "paymentMethodCode",
        //     options:getSelectedDictOne(DictCodeEnmu.PAY_METHOD)
        // },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS)
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser"
        },
    ]
    const searchMoreFields = [
       
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["结算开始", "结算结束"],
            rangePickerFieldName: ["statSettleTime", "endSettleTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },    
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]
    return (
        <YhPageHeader
            title="销售退货"
            justify="end"
            size="small"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}