import React from "react";
import { useInternalModel } from "../internalModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import { internalStatusOption } from "../internalTypes";


export function PageHeader () {
    const { getSelectedDictOne, getTreeSelectOne } = useDictModel(() => []);
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useInternalModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "对方单位",
            fieldName: "adverseAccountName",
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "状态",
            fieldName: "status",
            options: internalStatusOption
        },
    ]
    return (
        <>
            <YhPageHeader
                title="内部对账单"
                size="small"
                justify="end"
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={resetSearchParam}
            />
        </>
    );
}