import { createModel } from "hox";
import { message } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import { useState, useEffect } from "react";

import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";
import { ISaleSoSearch, ISaleSoDetail, ISaleInvalidate } from "./saleSoTypes"
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { saleSoService } from "./saleSoService"
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { convertRes2Blob } from "@/utils/exportFile";
import { useLockFn } from "ahooks";
export interface IDescTotal {
    totalNum: number,
    totalAmount: number,
    discountTotalAmount: number,
    receivedAmount: number,
    latestPurPrice: number,
    receivableAmount: number,
    grossProfit: number
}

export const useSaleSoModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel();
    const [print, setPrint] = useState<boolean>(false);
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: ISaleSoSearch = {};
    const initDataSource: ISaleSoDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        totalNum: 0,
        totalAmount: 0,
        discountTotalAmount: 0,
        receivedAmount: 0,
        latestPurPrice: 0,
        receivableAmount: 0,
        grossProfit: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<ISaleSoSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<ISaleSoDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<ISaleSoDetail>({});
    //列表数据源分页参数

    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<ISaleSoDetail[]>([]);
    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<ISaleSoDetail>();
    

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            let { totalNum, totalAmount, discountTotalAmount, receivedAmount, receivableAmount, totalCost, grossProfit } = item
            newRowsDescTotal.totalNum = bigNumber.add(newRowsDescTotal.totalNum, totalNum as number)
            newRowsDescTotal.totalAmount = bigNumber.add(newRowsDescTotal.totalAmount, formatNum(totalAmount))
            newRowsDescTotal.discountTotalAmount = bigNumber.add(newRowsDescTotal.discountTotalAmount, formatNum(discountTotalAmount))
            newRowsDescTotal.receivedAmount = bigNumber.add(newRowsDescTotal.receivedAmount, formatNum(receivedAmount))
            newRowsDescTotal.latestPurPrice = bigNumber.add(newRowsDescTotal.latestPurPrice, formatNum(totalCost))
            newRowsDescTotal.receivableAmount = bigNumber.add(newRowsDescTotal.receivableAmount, formatNum(receivableAmount))
            newRowsDescTotal.grossProfit = bigNumber.add(newRowsDescTotal.grossProfit, formatNum(grossProfit))
            // newRowsDescTotal.totalNum += (totalNum as number)
            // newRowsDescTotal.totalAmount += +formatNum(totalAmount)
            // newRowsDescTotal.discountTotalAmount += +formatNum(discountTotalAmount)
            // newRowsDescTotal.receivedAmount += +formatNum(receivedAmount)
            // newRowsDescTotal.latestPurPrice += +formatNum(totalCost)
            // newRowsDescTotal.receivableAmount += +formatNum(receivableAmount)
            // newRowsDescTotal.grossProfit += +formatNum(grossProfit)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as ISaleSoDetail)
    }, [selectedRowKeys, dataSource])

    const [bingingModal, setBindingModal] = useState<boolean>(false);   //绑定弹框
    const [bingingDetailModal, setBindingDetailModal] = useState<boolean>(false);   //绑定明细弹框
    const [cancelSettleModal, setCancelSettleModal] = useState<boolean>(false);   //取消结算明细弹框
    const [dispatchingModal, setDispatchingModal] = useState<boolean>(false);    //派工弹框

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: ISaleSoSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: ISaleSoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await saleSoService.page(searchParams);
        const { records, statistics, ...pg } = retData
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setDescTotal(statistics)
    }
    const invalidate = async (params: ISaleInvalidate) => {
        let { retData } = await saleSoService.invalidate(params);
        if (retData) {
            loadData()
            message.success("作废成功")
        }
    }
    const exportSaleSo = async (searchValue: ISaleSoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await saleSoService.export(searchParams);
        convertRes2Blob(result)
    }
    const detailsExport = async (searchValue: ISaleSoSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
            saleCode:(descRows.map((Element)=>{return Element.saleCode})).join()
        }
        let result = await saleSoService.detailsExport(searchParams);
        convertRes2Blob(result)
    }
    const [settleScaniaPrint, setSettleScaniaPrint] = useState<boolean>(false);
    const onTransfer = useLockFn(async () => {
        let { retData: oneRetData } = await saleSoService.one(selectedRow.saleCode as string);
        let { retData } = await saleSoService.transfer(oneRetData);
        return retData
    })
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        initPgCfg,
        bingingModal,
        bingingDetailModal,
        cancelSettleModal,
        dispatchingModal,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setBindingModal,
        setBindingDetailModal,
        setCancelSettleModal,
        setDispatchingModal,
        descTotal, setDescTotal,
        selectedRow, setSelectedRow,
        invalidate,
        exportSaleSo,
        print, setPrint,
        detailsExport,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        settleScaniaPrint, setSettleScaniaPrint,
        onTransfer,
        setHistoryVisible,
        setHistoryRow
    }
});