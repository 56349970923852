import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { ISearchPage } from "@/types/ScmTypes";
import React, { useState, useEffect } from "react";

import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";

import { IStockStatisticSearch, IStockStatisticDetail, stockStatisticService, IInventoryTotal, IRecordsDetail, IRecordsFields, IFindBySorderSumDetail, IStockStatisticList, IStockStatisticTotal } from "./stockStatisticService"
import { transPgToPagination, formatNum } from "@/utils/utils";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { message } from "antd";
import { purchaseDetailsService } from '../purchaseDetails/purchaseDetailsService';
export interface IMdBeSearchField {
    code?: string;
    name?: string;
    corporation?: string;
    phone?: string;
    address?: string;
    bank?: string;
    bankAccount?: string;
}


export const useStockStatisticModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IStockStatisticSearch = {};
    const initDataSource: IStockStatisticDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        averageAmount: 0,
        type: 0,
        latestAmountTotal: 0,
        total: 0
    }
    const initStatistics = { 
        stockNum:0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<IStockStatisticSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IStockStatisticDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [descTotal, setDescTotal] = useState<IInventoryTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IInventoryTotal>(initDesc);
    const [descRows, setDescRows] = useState<IStockStatisticDetail[]>([]);

    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [occupyStock, setOccupyStockModel] = useState<IStockStatisticDetail | null>(null)
    //移库配件
    const [relocationList, setRelocationList] = useState<IStockStatisticDetail[]>([])
    const [relocationVisible, setRelocationVisible] = useState<boolean>(false)
    //移库记录列表
    const [recordsList, setRecordsList] = useState<IRecordsDetail[]>([])
    //移库记录检索
    const [recordsSearch, setRecordsSearch] = useState<IRecordsFields>({})
    //移库记录显示隐藏
    const [recordsVisible, setRecordsVisible] = useState<boolean>(false)
    //销钉记录
    const [pinRecord, setPinRecord] = useState<IStockStatisticDetail|null>(null)
    //预约记录
    const [appintRecord,setAppintRecord] = useState<IStockStatisticDetail|null>(null)

    const [statistics, setStatistics] = useState<IStockStatisticTotal>(initStatistics);

    useEffect(() => {
        let newRowsDescTotal = initDesc
        relocationList.forEach((item, index) => {
            let { inventoryNum, latestAmount, averageAmount } = item
            newRowsDescTotal.averageAmount += +formatNum(averageAmount)
            newRowsDescTotal.type += +1
            newRowsDescTotal.latestAmountTotal += +formatNum(latestAmount)
            newRowsDescTotal.total += inventoryNum as number
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [relocationList])

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IStockStatisticSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
        setRelocationList([])
        // setSelectedRow({})
    }
    const loadData = async (page?: ISearchPage, searchValue: IStockStatisticSearch = searchParam) => {
        setLoading(true)
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        try {
            let { retData } = await stockStatisticService.page(searchParams);
            let { list } = retData[0]
            let { records, ...pg } = list
            let newPg = { ...pg, pageNum: pg.current }
            setPgCfg(transPgToPagination(newPg));
            setDataSource(records);
            setLoading(false)
        } catch (error) {
            message.error(error)
        }
        try {
            let { retData: statistics } = await purchaseDetailsService.amount(searchParams);
             setStatistics(statistics)
        } catch (error) {
            message.error(error)
        }
        setLoading(false)
    }
    const exportStockStatistic = async (searchValue: IStockStatisticSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await stockStatisticService.export(searchParams);
        convertRes2Blob(result)
    }
    //移库
    const relocation = async () => {
        let newRelocationList = relocationList.map(item => {
            let { materialCode, materialName, relocationNum, shelfCode, shelfCodeNew, shelfId, shelfIdNew, shopId, shopName, id, warehouseCode, warehouseCodeNew, warehouseName, warehouseNameNew } = item
            return { materialCode, materialName, relocationNum, shelfCode, shelfCodeNew, shelfId, shelfIdNew, shopId, shopName, stockId: id, warehouseCode, warehouseCodeNew, warehouseName, warehouseNameNew }
        })
        let { retData } = await stockStatisticService.relocation({ details: newRelocationList });
        return retData;
    }


    //移库记录查看
    const records = async () => {
        let params = {
            ...recordsSearch,
            "pageNum": 1,
            "pageSize": 10000,
            shopId: shopAndOrg.shopId
        }
        let { retData } = await stockStatisticService.records(params);
        let { records } = retData
        setRecordsList(records)
    }
    //移库还原
    const reduction = async (id: string) => {
        let { retData } = await stockStatisticService.reduction(id);
        if (retData) {
            message.success("还件成功")
            records()
        }
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        drawerVisible,
        initPgCfg,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        descTotal, setDescTotal,
        exportStockStatistic,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        loading, setLoading,
        occupyStock, setOccupyStockModel,
        relocationList, setRelocationList,
        relocationVisible, setRelocationVisible,
        relocation,
        recordsList, setRecordsList,
        records,
        recordsVisible, setRecordsVisible,
        recordsSearch, setRecordsSearch,
        reduction,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        pinRecord, setPinRecord,
        appintRecord,setAppintRecord,
        statistics, setStatistics
    }
});