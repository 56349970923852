import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSalesReortModel } from "../salesReortModel";
import { ISalesReortDetail } from "../salesReortService";
import { formatNum, momentFormat } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys
    } = useSalesReortModel();
    const { getTreeNodeName } = useDictModel();

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<ISalesReortDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 180,
            fixed: "left",
            dataIndex: 'documentNumber',
        }, {
            title: '客户名称',
            width: 180,
            dataIndex: 'customerName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '结算金额',
            width: 120,
            dataIndex: 'totalAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '工时费',
            width: 120,
            dataIndex: 'workingHoursAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '工时折扣金额',
            width: 120,
            dataIndex: 'workingDiscountAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '材料费',
            width: 120,
            dataIndex: 'materialScienceAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '材料折扣金额',
            width: 120,
            dataIndex: 'materialDiscountAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '优惠金额',
            width: 120,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '已结款金额',
            width: 120,
            dataIndex: 'paidAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '单据状态',
            width: 120,
            dataIndex: 'status',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '单据性质',
            width: 120,
            dataIndex: 'tradeTypeName',
        }, {
            title: '制单日期',
            width: 150,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '单据备注',
            width: 150,
            dataIndex: 'note',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            rowKey={record => record.id}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id)
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}