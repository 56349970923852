import { httpRequest } from '@/utils/HttpRequest';
import { IPage } from '@/types/ScmTypes';
import { IEntitySearch, IEntityDetail, IMdBeSearch } from './entityType';
export interface paramsDisable {
	id: string;
	enable: number;
}

class BeService {
	async page(searchVo: IEntitySearch) {
		const apiUrl = '/bcm/base/be/page';
		return await httpRequest.post<IPage<IEntityDetail>>(apiUrl, searchVo);
	}

	async pageFms(searchVo: IMdBeSearch) {
		const apiUrl = '/fms/be/page';
		return await httpRequest.post<IPage<IEntityDetail>>(apiUrl, searchVo);
	}
	async one(id: string) {
		const apiUrl = `/bcm/base/be/findById/${id}`;
		return await httpRequest.get<IEntityDetail>(apiUrl);
	}
	async insert(insertVo: IEntityDetail) {
		const apiUrl = '/bcm/base/be/insert';
		return await httpRequest.post<boolean>(apiUrl, insertVo);
	}
	async update(updateVo: IEntityDetail) {
		const apiUrl = '/bcm/base/be/update';
		return await httpRequest.post<boolean>(apiUrl, updateVo);
	}
	//启用禁用
	async disable(parsms: paramsDisable) {
		const apiUrl = `/bcm/base/be/change`;
		return await httpRequest.post<boolean>(apiUrl, parsms);
	}
	//导入
	async import(file: { file: File }) {
		const appPageUrl = '/bcm/base/be/import';
		return await httpRequest.post<boolean>(appPageUrl, file);
	}
	//导出
	async export(searchVo: IEntitySearch) {
		const appPageUrl = '/bcm/base/be/export';
		return await httpRequest.postFile(appPageUrl, searchVo);
	}
	//下载模板
	async downloadTpl() {
		const appPageUrl = '/bcm/base/be/downloadTpl';
		return await httpRequest.getFile(appPageUrl);
	}
	//同步
	async sync(params: object) {
		const appPageUrl = '/bcm/base/be/sync';
		return await httpRequest.post<boolean>(appPageUrl, params);
	}
}
export const beService = new BeService();
