import React, { useRef } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils";
import { useSaleSoDetailModel } from "./saleSoDetailModel";

//物流登记
export function LogisticsDraer() {
    const { logisticsDraer: visible, setLogisticsDraer } = useSaleSoDetailModel();
    const formRef = useRef<IYhFormRef>();

    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        if ('isBatch' in changedValues) {
        }
    }

    const onCancel = () => {
        setLogisticsDraer(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            formRef.current?.inst().submit();
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = (val: any) => {

    }

    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        formValues: {},
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售单号",
                fieldName: "warehouseName",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户名称",
                fieldName: "code",
                options: [{ label: "1", value: "1" }],
                span: 24,
                rules: [{ required: true, message: "请选择供应商名称" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售金额",
                fieldName: "code",
                span: 24,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "物流方式",
                fieldName: "name",
                span: 24,
                options: [{ label: "1", value: "1" }],
                rules: [{ required: true, message: "请选择物流方式" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "物流公司",
                fieldName: "note",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "物流单号",
                fieldName: "note",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "件数",
                fieldName: "note",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "厂家单号",
                fieldName: "note",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "物流费用",
                fieldName: "note",
                span: 24,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "物流费结账方式",
                fieldName: "code",
                options: [{ label: "1", value: "1" }],
                span: 24,
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "开始日期",
                fieldName: "code",
                span: 24,
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "完毕日期",
                fieldName: "code",
                span: 24,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "送货员",
                fieldName: "code",
                options: [{ label: "1", value: "1" }],
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            },

        ]
    };
    return (
        <YHDrawer
            {...{
                title: '销售配送登记',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
