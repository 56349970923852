import { httpRequest } from "../../utils/HttpRequest";
import {
    ItemDetailed,
    MaterialDetailed,
    IMaintenanceField,
    IMaintenanceRoSettleParams,
    IMaintenanceSearch,
    IMaintenanceDetail,
    IMaintenanceRoSettleAndWhConfirm,
    IMaintenanceList,
    IPrintExcel
} from "./maintenanceType"

class MaintenanceService {
    async page(searchVo: IMaintenanceSearch) {
        const apiUrl = "/bcm/maintenance/findPage";
        return await httpRequest.post<IMaintenanceList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/maintenance/findById/${id}`;
        return await httpRequest.get<IMaintenanceDetail>(apiUrl);
    }
    async insert(insertVo: IMaintenanceField) {
        const apiUrl = "/bcm/maintenance/insert";
        return await httpRequest.post<IMaintenanceDetail>(apiUrl, insertVo);
    }
    async update(updateVo: IMaintenanceField) {
        const apiUrl = "/bcm/maintenance/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    // 库房确认
    async warehousing(params: IMaintenanceRoSettleAndWhConfirm) {
        const apiUrl = "/bcm/maintenance/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修用料明细删除
    async materialDelete(params: MaterialDetailed) {
        const apiUrl = "/bcm/maintenance/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //维修项目明细删除
    async itemDelete(params: ItemDetailed) {
        const apiUrl = "/bcm/maintenance/itemDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //结算
    async settl(params: IMaintenanceRoSettleParams) {
        const apiUrl = "/bcm/maintenance/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //作废
    async invalidate(params: object) {
        const apiUrl = "/bcm/maintenance/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //重新加载维修项目得数据
    async projectQuery(params: string[]) {
        const apiUrl = "/bcm/base/item/projectQuery";
        return await httpRequest.post<ItemDetailed[]>(apiUrl, params);
    }
    //重新加载维修用料得数据
    async find(params: string[]) {
        const apiUrl = "/bcm/stock/find";
        return await httpRequest.post<MaterialDetailed[]>(apiUrl, params);
    }
    //预约转维修
    async transfer(params: object) {
        const apiUrl = "/bcm/maintenance/transfer";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //转车间
    async tran(params: object) {
        const apiUrl = "/bcm/maintenance/tran";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: IMaintenanceSearch) {
        const apiUrl = "/bcm/maintenance/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: IMaintenanceSearch) {
        const apiUrl = "/bcm/maintenance/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
    //打印Excel格式
    async excelPrint(params: IPrintExcel) {
        const apiUrl = "/bcm/maintenance/exportFind";
        return await httpRequest.postFile(apiUrl, params);
    }
    //打印预约单Excel格式
    async appointmentExcelPrint(params: IPrintExcel) {
        const apiUrl = "/bcm/appointment/exportFind ";
        return await httpRequest.postFile(apiUrl, params);
    }
}
export const maintenanceService = new MaintenanceService();
