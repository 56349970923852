import React, { useEffect, useState } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
    defaultMdCmParam
} from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { useDetailModel } from "../detailModel";
import { accountService } from "../../../../AccountService";
import { IAccountWriteoffDetail, IAccountWriteoffFields, IAccountWriteoffSearch, IAccountWriteoffSearchParam } from "../../../../AccountTypes";
import { UserTypeEnum } from "@/types/FmsEnums";
import { useGlobalModel } from "@/model/globalModel";
import { transPgToPagination } from "@/utils/utils";

export const useWriteoffModel = createModel(function () {
    const initSearchParam: IAccountWriteoffSearchParam = {};
    const initDataSource: IAccountWriteoffDetail[] = [];
    const initPgCfg: TablePaginationConfig = {
        pageSize: DEFAULT_PAGE_SIZE,
        current: DEFAULT_FIRST_PAGE_NUM
    }
    const { supplierCode } = useDetailModel();
    const { user: { shopCode } } = useGlobalModel();

    //查询条件
    const [searchParam, setSearchParam] = useState<IAccountWriteoffSearchParam>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IAccountWriteoffDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedData, setSelectedData] = useState<IAccountWriteoffFields>();
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    // 核销总额
    const [writeoffAmount, setWriteoffAmount] = useState<number>(0)

    const onSearch = () => {
        loadData({ pageNum: 1 }).then()
    }
    const onReset = () => {
        resetSearchParam()
    }


    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IAccountWriteoffFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IAccountWriteoffSearchParam = searchParam) => {
        const searchParams: IAccountWriteoffSearch = {
            ...searchValue,
            partnerCode: supplierCode,
            partnerTypeCode: UserTypeEnum.SUPPLIER,
            shopCode,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await accountService.writeoffPage(searchParams);
        let { retData: totalRetData } = await accountService.writeoffTotalAmount(searchParams)
        const { records, ...pg } = retData;
        setWriteoffAmount(totalRetData)
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }


    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedData,
        writeoffAmount,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        onSearch,
        onReset,
        setSelectedData,
    }
});
