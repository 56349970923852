import React, { } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useStockInAndOutModel } from "../stockInAndOutModel";
import useNavModel from "@/model/navModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, descTotal, ioFlag, rowsDescTotal, initPgCfg, searchParam, setIoFlag, loadData, exportEntry } = useStockInAndOutModel();
    const desc: IDescription[] = [
        {
            label: ioFlag ? "入库项数" : "出库项数",
            value: selectedRowKeys.length === 0 ? descTotal.num : rowsDescTotal.num,
            color: DescriptionColorEnum.red
        },
        {
            label: ioFlag ? "入库总计" : "出库总计",
            value: selectedRowKeys.length === 0 ? descTotal.amount : rowsDescTotal.amount,
            color: DescriptionColorEnum.green
        }
    ]
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportEntry()}
        >导出</Button>
    </>;
    const ioFlagRecord = (io: number) => {
        setIoFlag(io);
        loadData(initPgCfg, { ...searchParam, ioFlag: io });
    }
    const title = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => ioFlagRecord(0)}
        >出库记录</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => ioFlagRecord(1)}
        >入库记录</Button>
    </>
    return (
        <>
            <YhBox style={{ marginBottom: "5px", paddingLeft: "10px" }}>{title}</YhBox>
            <YhBox title="出入库记录明细" descriptions={desc} action={action}>
                <DataTable />
            </YhBox>
        </>
    )
}