import React, { useState, useEffect } from "react";
import { createModel } from "hox";
import { IPreSaleDetail, IPreSaleFields, IPreSaleDetailed, IPreSaleBatchWarehousing } from "../busBusinessType"
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table";
import { ISearchPage } from "@/types/ScmTypes";
import { formatNum, transPgToPagination, bigNumber } from "@/utils/utils";
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";
import useGlobalModel from "@/model/globalModel";
import { message } from "antd";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    presaleNum: number
    presaleAmount: number
    presaleTotalCost: number
}
export const usePreSaleModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const { shopCode } = shopAndOrg
    const initSearchParam: IPreSaleFields = {};
    const initDataSource: IPreSaleDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        presaleNum: 0,
        presaleAmount: 0,
        presaleTotalCost: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<IPreSaleFields>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IPreSaleDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IPreSaleDetail>({});
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<IPreSaleDetail[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item, index) => {
            let { presaleNum, presaleAmount, totalCost } = item
            newRowsDescTotal.presaleNum = bigNumber.add(Number(presaleNum), newRowsDescTotal.presaleNum)
            newRowsDescTotal.presaleAmount = bigNumber.add(formatNum(presaleAmount), newRowsDescTotal.presaleAmount)
            newRowsDescTotal.presaleTotalCost = bigNumber.add(formatNum(totalCost), newRowsDescTotal.presaleTotalCost)
            // newRowsDescTotal.presaleNum += Number(presaleNum);
            // newRowsDescTotal.presaleAmount += +formatNum(presaleAmount);
            // newRowsDescTotal.presaleTotalCost += +formatNum(totalCost);
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IPreSaleDetail)
    }, [selectedRowKeys, dataSource])

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IPreSaleFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    const loadData = async (page?: ISearchPage, searchValue: IPreSaleFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await busBusinesstService.preSalePage(searchParams);
        const { records, statistics, ...pg } = retData;
        setDescTotal(statistics)
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records);
    }
    const invalidateData = async (params: IPreSaleDetailed) => {
        let { retData } = await busBusinesstService.preSaleInvalidate(params)
        if (retData) {
            message.success("作废成功");
            loadData();
        }
    }
    const exportSaleSo = async (searchValue: IPreSaleFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await busBusinesstService.preSaleExport(searchParams);
        convertRes2Blob(result)
    }
    const detailsExport = async (searchValue: IPreSaleFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
            orderCode:(descRows.map((item)=>{return item.orderCode})).join()
        }
        let result = await busBusinesstService.preSaleDetailsExport(searchParams);
        convertRes2Blob(result)
    }
    const listDownloadTpl = async () => {
        let result = await busBusinesstService.preSaleListDownloadTpl();
        convertRes2Blob(result)
    }
    const preSaleBatchWarehousing = async (params: IPreSaleBatchWarehousing) => {
        let res = await busBusinesstService.preSaleBatchWarehousing(params);
        console.log(res)
        return res as any
    }
    const [print, setPrint] = useState(false);
    return {
        initPgCfg,
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedRow,
        rowsDescTotal,
        descRows,
        descTotal,

        // method
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setPgCfg,
        updatePgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setSelectedRow,
        setRowsDescTotal,
        setDescRows,
        setDescTotal,
        invalidateData,
        exportSaleSo,
        detailsExport,
        listDownloadTpl,
        print, setPrint,
        preSaleBatchWarehousing
    }
})