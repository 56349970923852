import React, { useEffect } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { InitAccountDrawer } from "./InitAccountDrawer";
// import { useSummaryModel } from "./summaryModel";
// import { dictService } from "../../../../base/dict/dictService";
// import { orgService } from "../../../../base/org/orgService";
// import appService from "../../../../base/app/appService";
// import { DictCodeEnmu } from "@/types/FmsDictEnums";
import {SyncroModel} from "./syncroModel"

export const Summary = () => {
    // const {
    //     setOrgDs,
    //     setAppDs,
    //     setCustomerType,
    // } = useSummaryModel()

    // useEffect(() => {
    //     orgService.orgTreeDs({}).then(ds => setOrgDs(ds))
    //     dictService.dictItemDs(DictCodeEnmu.CUSTOMER_TYPE).then(ds => setCustomerType(ds));
    //     appService.appDs({}).then(ds => setAppDs(ds));
    // }, []);

    return (
        <>
            <PageHeader />
            <DataBox />
            <InitAccountDrawer />
            <SyncroModel/>
        </>
    )
}