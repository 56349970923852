import React, { useState, useRef } from "react";
import { TopButs } from "./TopButs"
import { FormBox } from "./FormBox"
import { MaterialTableBox } from "./materialTableBox"
import { ItemTableBox } from "./itemTableBox"
import { FixedBottom } from "./FixedBottom"
import { MaterialModal } from "./materialModal"
import { ItemModal } from "./itemModal"
import { ReturnDrawer } from "./ReturnDrawer"
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { HistoryModel } from "@/views/historyModel";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";
import { ConsumeHistoryModel } from "@/views/commonModule/consumeHistoryModel"
import { RepairHistoryModel } from "@/views/commonModule/repairHistoryModel"

export const FollowUpDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const {
        historyVisible, setHistoryVisible,
        historyRow,
        consumeHistoryModel,
        consumeHistoryVisible, setConsumeHistoryVisible,
        repairHistoryVisible, setRepairHistoryVisible,
        repairHistoryModel, setRepairHistoryModel
    } = usefollowUpDetailsModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    return (
        <>
            <TopButs {...detailsInfo} />
            <FormBox  {...detailsInfo} IFormRef={IFormRef} />
            <ItemTableBox {...detailsInfo} />
            <MaterialTableBox {...detailsInfo} />
            <FixedBottom {...detailsInfo} />
            <MaterialModal />
            <ItemModal />
            <ReturnDrawer />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <ConsumeHistoryModel
                visible={consumeHistoryVisible}
                name={consumeHistoryModel.customerName}
                onCancel={() => setConsumeHistoryVisible(false)}
            />
            <RepairHistoryModel
                visible={repairHistoryVisible}
                licensePlate={repairHistoryModel.licensePlate}
                onCancel={() => setRepairHistoryVisible(false)}
            />
        </>
    )
}