import { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { ISearchPage } from '@/types/ScmTypes';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants';
import { saleDcService } from './allotService';
import useGlobalModel from '@/model/globalModel';
import { IBCMSaleDcSearchFields, IBCMSaleDcDetail, IBCMSaleDcStatistics } from './allotType';
import { convertRes2Blob } from '@/utils/exportFile';
import { transPgToPagination } from '@/utils/utils';
import { isArray } from 'util';

export const useSaleDcModel = createModel(function () {
	const {
		user: { shopId, shopCode },
		setLoadingInfo,
	} = useGlobalModel();
	//查询条件
	const [searchParam, setSearchParam] = useState<IBCMSaleDcSearchFields>({});
	//列表数据源
	const [dataSource, setDataSource] = useState<IBCMSaleDcDetail[]>([]);
	//表格选中行Key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	//列表数据源分页参数
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//列表统计展示数据
	const initStatistics = {
		totalAmount: '0.00',
		totalNum: 0,
	};
	const [statistics, setStatistics] = useState<IBCMSaleDcStatistics>(initStatistics);
	const [loading, setLoading] = useState<boolean>(false);
	const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IBCMSaleDcDetail>();
    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
	const updateSearchParam = (updateParam: IBCMSaleDcSearchFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};

	//切换门店重置缓存
	useEffect(() => {
		setSearchParam({});
		setSelectedRowKeys([]);
	}, [shopId]);

	//重置
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};

	const loadData = async (
		page?: ISearchPage,
		searchValue: IBCMSaleDcSearchFields = searchParam
	) => {
		// setLoading(true);
		const searchParams = {
			outShopCode: shopCode,
			...searchValue,
            status: isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status,
			pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
			pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
		};
		let { retData } = await saleDcService.page(searchParams);
		const { records, ...pg } = retData;
		let newPg = { ...pg, pageNum: pg.current }
		setPgCfg(transPgToPagination(newPg));
		setDataSource(records);
		loadTotal(searchParams);
	};
    const loadTotal = async (searchParams:any) => {
        let { retData: statistics } = await saleDcService.pageStatistics(searchParams);
        setStatistics(statistics ?? initStatistics);
    }
	//导出
	const exportSaleDc = async (searchValue: IBCMSaleDcSearchFields = searchParam) => {
		setLoadingInfo({ loadingVisible: true, txt: '快速导出中' });
		try {
			const searchParams = {
				inAndOut: '1',
				outShopCode: shopCode,
				...searchValue,
				status: isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status,
				pageSize: -1,
				pageNum: 1,
			};
			let result = await saleDcService.export(searchParams);
			result && setLoadingInfo({ loadingVisible: false, txt: '' });
			convertRes2Blob(result);
		} catch {
			setLoadingInfo({ loadingVisible: false, txt: '' });
		}
	};
    const exportStatisticsExport = async (searchValue: IBCMSaleDcSearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                inAndOut: "1",
                outShopCode: shopCode,
                ...searchValue,
				status: isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status,
                pageSize: -1,
                pageNum: 1,
            }
            let result = await saleDcService.statisticsExport(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
    }
	return {
		/***field***/
		searchParam,
		dataSource,
		selectedRowKeys,
		pgCfg,
		statistics,
		loading,
		/***method***/
		setDataSource,
		setSelectedRowKeys,
		resetSearchParam,
		updateSearchParam,
		loadData,
		exportSaleDc,
		exportStatisticsExport,
		setHistoryRow,setHistoryVisible,
		updatePgCfg,

	};
});
