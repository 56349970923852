import React, { FC, useEffect } from "react";
import { IFormItem } from "../Types";
import { Form, TreeSelect } from "antd";
import { ITreeSelectData } from "@/views/base/dict/dictModel";

export const __FormTreeSelect: FC<IFormItem> = (props) => {
    useEffect(() => { console.log(props.treeDate) }, [])
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <TreeSelect
                // placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
                treeDefaultExpandAll
                allowClear={props.allowClear}
                treeData={props.treeData}
                disabled={props.disable}
                onSelect={(value: string, option: ITreeSelectData) => props.onTreeSelectChange?.(value, option as ITreeSelectData)}
                showSearch
                treeNodeFilterProp='title'
            />
        </Form.Item>
    )
}