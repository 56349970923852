import React, { useEffect } from "react";
import { Button, Dropdown, Input, Menu, message } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useSaleSoModel } from "../saleSoModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useSaleSoDetailModel } from "./details/saleSoDetailModel";
import { DownOutlined } from "@ant-design/icons";
import useGlobalModel from "@/model/globalModel";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { debounce } from "lodash";

export const DataBox = () => {
    const {
        selectedRowKeys,
        selectedRow,
        descTotal,
        rowsDescTotal,
        invalidate,
        exportSaleSo,
        setPrint,
        detailsExport,
        setSelectedRowKeys,
        setSettleScaniaPrint,
        onTransfer
    } = useSaleSoModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const { resetEditCatch } = useSaleSoDetailModel()
    const { shopAndOrg: { shopCode } } = useGlobalModel()
    const desc: IDescription[] = [
        {
            label: "销售数量",
            value: selectedRowKeys.length === 0 ? descTotal.totalNum : (+formatNum(rowsDescTotal.totalNum)).toFixed(0),// "1232",
            color: DescriptionColorEnum.red
        },
        {
            label: "销售金额",
            value: selectedRowKeys.length === 0 ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),// "￥33.23",
            color: DescriptionColorEnum.red
        },
        {
            label: "优惠总金额",
            value: selectedRowKeys.length === 0 ? descTotal.discountTotalAmount : formatNum(rowsDescTotal.discountTotalAmount),// "￥1232",
            color: DescriptionColorEnum.red
        },
        {
            label: "应收金额",
            value: selectedRowKeys.length === 0 ? descTotal.receivedAmount : formatNum(rowsDescTotal.receivedAmount),// "￥1232",
            color: DescriptionColorEnum.red
        },
        {
            label: "已收金额",
            value: selectedRowKeys.length === 0 ? descTotal.receivableAmount : formatNum(rowsDescTotal.receivableAmount),// "￥1232",
            color: DescriptionColorEnum.red
        },
        {
            label: "销售成本",
            value: selectedRowKeys.length === 0 ? descTotal.latestPurPrice : formatNum(rowsDescTotal.latestPurPrice),// "￥1232",
            color: DescriptionColorEnum.red
        },
        {
            label: "销售毛利",
            value: selectedRowKeys.length === 0 ? descTotal.grossProfit : formatNum(rowsDescTotal.grossProfit),// "￥1232",
            color: DescriptionColorEnum.red
        },
    ]

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.stock-io") {
            globalPrompt("message", { text: "该单据已出库，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        let saleSoEditKey = "5-2-2";
        let isFindNav = findNav(saleSoEditKey);
        if (!isFindNav) {
            addNav(saleSoEditKey, { id: selectedRow?.saleCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "销售单跳转详情",
                type: "warning",
                text: "您还有销售单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleSoEditKey, { id: selectedRow?.saleCode as string, mode: "edit" });
                    // updateEditFormVal(null);
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "5-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow?.saleCode as string, mode: "look", },
            true
        );
    }

    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }

    const onSupplement = () => {
        addNav("5-2-1", {
            id: "", mode: "add", parentInfo: {
                Supplement: "Supplement"
            }
        })
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleSo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const onSettleSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setSettleScaniaPrint(true)
    }
    const onTrunSaleRo = async () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let retData = await onTransfer()
        if (retData) {
            replaceNav(selectedNavId, "5-3");
            message.success("转接销售退货单成功")
        }

    }

    // 作废
    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "销售单作废",
            type: "error",
            text: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id as string,
                    saleCode: selectedRow.saleCode as string,
                    cancelReason
                }
                invalidate(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.S_S_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav("5-2-1", { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_S_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_S_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_S_EX}>
            <Dropdown overlay={exportMenu}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                >导出<DownOutlined /></Button>
            </Dropdown>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_S_PRINT}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { onTrunSaleRo() }, 900)}
            >转销退单</Button>
            {shopCode === "SHOP-M-00000020" ?
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onSettleSknyPrint}
                >结算打印</Button> :
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onPrint}
                >打印</Button>
            }
        </YhAuth>
        {/* <YhAuth resCode={ResourceAuthEnum.S_S_CANCEL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onInvalidate}
            >作废</Button>
        </YhAuth> */}
        {/* <YhAuth resCode={ResourceAuthEnum.S_S_REPL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onSupplement}
            >补单</Button>
        </YhAuth> */}
    </>;

    return (
        <YhBox title={"销售单列表"} descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}