import { httpRequest } from "../../utils/HttpRequest";
import { IMdCmFields, IKey } from "../../types/ScmTypes";


export interface IMdAppFields extends IMdCmFields {
    code?: string;
    name?: string;
    note?: string;
    status?: number;
}

export type IMdApp = IKey & IMdCmFields & IMdAppFields;

class AppService {
    async find(searchVo: IMdAppFields) {
        const appPageUrl = "/fms/app/find";
        return await httpRequest.post<IMdApp[]>(appPageUrl, { ...searchVo});
    }
}

export const appService = new AppService();
export default appService
