import React, {  } from "react";
import { Button, Dropdown, Input, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useMaintenanceModel } from "../maintenanceModel";
import { useMaintenanceDetailsModel } from "../view/details/maintenanceDetailsModels";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { useDeliveryDetailsModel } from "@/views/stock/delivery/view/details/deliveryDetailsModel";
import { DownOutlined } from "@ant-design/icons";
import useGlobalModel from "@/model/globalModel";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const {
        selectedRowKeys,
        selectedRow,
        invalidateData,
        setPrint,
        tran,
        exportMaintenance,
        descTotal,
        rowsDescTotal,
        setScaniaPrint,
        setSettleScaniaPrint,
        detailsExport,
        setSelectedRowKeys
    } = useMaintenanceModel();
    const { shopAndOrg: { shopCode } } = useGlobalModel()
    const { resetEditCatch: resetDeliveryEditCatch } = useDeliveryDetailsModel()
    const { resetEditCatch } = useMaintenanceDetailsModel()
    const { selectedNavId, replaceNav, addNav, findNav, selectNav, switchNavState } = useNavModel();

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.chacompletednge") {
            globalPrompt("message", { text: "该单据已完工，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.settle") {
            globalPrompt("message", { text: "该单据已结算，无法进行编辑", type: "warning" });
            return;
        }
        let saleOrderEditKey = "4-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "维修管理跳转详情",
                type: "warning",
                text: "您还有维修管理详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.maintenanceCode as string, mode: "look", },
            true
        );
    }
    const onTrunShop = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行转车间", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.completed") {
            globalPrompt("message", { text: "该单据已完工，无法进行转车间", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.settle") {
            globalPrompt("message", { text: "该单据已结算，无法进行转车间", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.underway") {
            globalPrompt("message", { text: "该单据已在修，无法进行转车间", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.await") {
            globalPrompt("message", { text: "该单据已待修，无法进行转车间", type: "warning" });
            return;
        }
        globalPrompt("modal", {
            title: "维修单同时转车间和库房",
            type: "success",
            text: "",
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    maintenanceCode: selectedRow.maintenanceCode,
                }
                tran(obj).then(res => {
                    if (res) {
                        globalPrompt("message", { text: `工单单号${selectedRow.maintenanceCode}转接成功`, type: "success" });
                        // addNav("6-1")
                    }
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "维修单作废",
            type: "error",
            text: <><span>请输入作废原因</span>
            <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    maintenanceCode: selectedRow.maintenanceCode,
                    cancelReason
                }
                invalidateData(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })

    }
    const onDelivery = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "maintenance.underway" || selectedRow.status === "maintenance.await") {
            let saleOrderEditKey = "9-3-2";
            let isFindNav = findNav(saleOrderEditKey);
            if (!isFindNav) {
                addNav(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
            } else {
                globalPrompt("modal", {
                    title: "维修出库跳转详情",
                    type: "warning",
                    text: "您还有维修出库详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                    cancelText: "打开新页签",
                    onCancel: () => {
                        switchNavState(saleOrderEditKey, { id: selectedRow.maintenanceCode as string, mode: "edit" });
                        resetDeliveryEditCatch()
                    }
                })
            }
        } else if (selectedRow.status === "maintenance.settle" || selectedRow.status === "maintenance.completed") {
            let supplierEditKey = "9-3-3";
            replaceNav(
                selectedNavId,
                supplierEditKey,
                { id: selectedRow.maintenanceCode as string, mode: "look", },
                true
            );
        } else {
            globalPrompt("message", { text: "您的工单不是在修,待修,已结算，已完工状态，不能进行维修出库操作", type: "warning" });
            return;
        }
    }
    const onSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setScaniaPrint(true)
    }
    const onSettleSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setSettleScaniaPrint(true)
    }
    const PrintMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={onSknyPrint}>工单打印</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={onSettleSknyPrint}>结算打印</span>
                </Menu.Item>
            </Menu>
        )
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportMaintenance()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.M_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav('4-1', { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.M_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.M_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.M_TRANS}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onTrunShop}
            >转接</Button>
        </YhAuth>
        {/* <YhAuth resCode={ResourceAuthEnum.M_M_OUT}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onDelivery}
            >维修出库</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.M_CANCEL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onInvalidate}
            >作废</Button>
        </YhAuth> */}
        <YhAuth resCode={ResourceAuthEnum.M_EX}>
            <Dropdown overlay={exportMenu}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                >导出<DownOutlined /></Button>
            </Dropdown>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.M_PRINT}>
            {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportMaintenance().then()}
        >导出</Button> */}
            {shopCode === "SHOP-M-00000018" || shopCode === "SHOP-M-00000017" || shopCode === "SHOP-M-00000020" ?
                <Dropdown overlay={PrintMenu}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                    >打印<DownOutlined /></Button>
                </Dropdown> :
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onPrint}
                >打印</Button>
            }
        </YhAuth>
    </>;
    const desc: IDescription[] = [
        {
            label: "工时费",
            value: selectedRowKeys.length === 0 ? descTotal.manHourFee : formatNum(rowsDescTotal.manHourFee),
            color: DescriptionColorEnum.red
        },
        {
            label: "费用合计",
            value: selectedRowKeys.length === 0 ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            color: DescriptionColorEnum.red
        },
        {
            label: "优惠金额",
            value: selectedRowKeys.length === 0 ? descTotal.discountTotalAmount : formatNum(rowsDescTotal.discountTotalAmount),
            color: DescriptionColorEnum.red
        },
        {
            label: "应收金额",
            value: selectedRowKeys.length === 0 ? descTotal.receivableTotalAmount : formatNum(rowsDescTotal.receivableTotalAmount),
            color: DescriptionColorEnum.red
        },
        // {
        //     label: "公交金额",
        //     value: selectedRowKeys.length === 0 ? descTotal.sumAmountReceivable : formatNum(rowsDescTotal.sumAmountReceivable),
        //     color: DescriptionColorEnum.red
        // },
        {
            label: "已收金额",
            value: selectedRowKeys.length === 0 ? descTotal.receivableAmount : formatNum(rowsDescTotal.receivableAmount),
            color: DescriptionColorEnum.red
        },
    ]
    return (
        <YhBox title={"工单列表"} descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}