import React, { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAllocModel } from "../allocModel";
import { ISCMAllocDetail } from "../allocService";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useAllocModel();
    const { getTreeNodeName } = useDictModel(() => []);
    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<ISCMAllocDetail> = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            fixed: "left",
            width: 160,
            dataIndex: 'materialCode',
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            title: '商品名称',
            fixed: "left",
            width: 160,
            dataIndex: 'materialName',
            render: (text: any) => <YhTootip text={text} />,
        },
        // {
        //     title: '仓库',
        //     width: 160,
        //     dataIndex: 'warehouseName',
        // }, {
        //     title: '货位',
        //     width: 160,
        //     dataIndex: 'inShelfCode',
        // },
        {
            title: '调拨数量',
            width: 100,
            dataIndex: 'num',
        }, {
            title: '调拨单号',
            width: 160,
            dataIndex: 'transferCode',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '调出门店',
            width: 160,
            dataIndex: 'outShopName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '已出库数量',
            width: 100,
            dataIndex: 'stockIoNum',
        }, {
            title: '出库状态',
            width: 100,
            dataIndex: 'stockOutStatus',
            render: text => text ? getTreeNodeName(text) : ""
        }, {
            title: '出库时间',
            width: 180,
            dataIndex: 'transferOutTime',
        }, {
            title: '调入门店',
            width: 160,
            dataIndex: 'shopName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '已入库数量',
            width: 100,
            dataIndex: 'inStockIoNum',
        }, {
            title: '入库状态',
            width: 120,
            dataIndex: 'stockInStatus',
            render: text => text ? getTreeNodeName(text) : ""
        }, {
            title: '入库时间',
            width: 180,
            dataIndex: 'transferInTime',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id)
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}