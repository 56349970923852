import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { SettleDrawer } from "./maintenanceSettleDrawer"
import { PurchaseSettleDrawer } from "./PurchaseSettleDrawer"
import { useSettlementModel } from "./settlementModel";
export default () => {
    const { settleVisible: visible,
        purchaseSettleVisible,
        setSettleVisible,
    } = useSettlementModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {visible && <SettleDrawer />}
            {purchaseSettleVisible && <PurchaseSettleDrawer />}
        </>
    )
}