export enum MdEnableStatusEnum {
    DISABLE,
    ENABLE
}

export enum PartnerTypeEnum {
    CUSTOMER = "PARTNER-TYPE.CUS",
    SUPPLIER = "PARTNER-TYPE.SUP"
}
export enum UserTypeEnum {
    EMPLOYEE = "user-type.employee", // "内部员工"
    MICRO_BOSS = "user-type.micro-boss", // "微老板"
    DRIVER = "user-type.driver", //"司机"
    HEADMAN = "user-type.headman", //"队长"
    BOSS = "user-type.boss", // "老板"
    SUPPLIER = "user-type.supplier", //"供应商"
    CUSTOMER = "user-type.customer", //"客户"
}
export enum TradeTypeEnum {
    PURCHASE_ORDER = "TRADE-TYPE.PURCHASE-ORDER",
    PURCHASE = "TRADE-TYPE.PURCHASE",
    PURCHASE_RETURN = "TRADE-TYPE.PURCHASE-RETURN",
    SALE_ORDER = "TRADE-TYPE.SALE-ORDER",
    SALE = "TRADE-TYPE.SALE",
    SALE_RETURN = "TRADE-TYPE.SALE-RETURN",
    REPAIR_YX = "TRADE-TYPE.YX-REPAIR",
    REPAIR_YC = "TRADE-TYPE.YC-REPAIR",
    LOSS = "TRADE-TYPE.LOSS",
    REDUCTION = "TRADE-TYPE.REDUCTION",
}

export enum ReviewStatusEnum {
    INIT,
    REVIEWING,
    REVIEWED,
    REJECTED = 9,
}

export enum AccountStatusEnum {
    INIT,
    DISABLED = 9
}

export enum CreditFlagEnum {
    NON_CREDIT,
    CREDIT,
    CARRY_FORWARD
}

export enum AdjustStatusEnum {
    INIT,
    REVIEWING,
    ADJUSTED,
    REJECTED = 8,
    FAIL,
}

export enum RechargeStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9,
}

export enum TransferStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9
}

export enum AccountLogTypeEnum {
    OPEN = "OPEN",
    DISABLE = "DISABLE",
    ENABLE = "ENABLE",
    ADJUST = "ADJUST",
    ADJUST_APPROVAL = "ADJUST_APPROVAL",
    RECHARGE = "RECHARGE",
    TRANSFER_IN = "TRANSFER_IN",
    TRANSFER_OUT = "TRANSFER_OUT",
    BALANCE = "BALANCE",
    UPDATE = "UPDATE",
}

export enum BillIoFlagEnum {
    IN = 1,
    OUT
}

export enum BillPayTypeEnum {
    CASH = 1,
    CREDIT_ACCOUNT
}

export enum BillStatusEnum {
    INIT,
    BOOKED,
    BALANCED
}

export enum BillCashStatusEnum {
    INIT,
    CASHED,
    RECHARGED,
    BALANCED,
    FAIL = 9,
}

export enum BillCancelStatusEnum {
    INIT,
    REVIEWING,
    CANCELLED,
    FAIL,
}

export enum BillInvoiceStatusEnum {
    INIT,
    APPLYING,
    PARTIAL,
    INVOICED,
}

export enum BillBalanceStatusEnum {
    INIT,
    BALANCED,
    FAIL = 9,
}

export enum BillLogTypeEnum {
    INSERT = "INSERT",
    APPROVAL = "DISABLE",
    CREDIT_BOOK = "CREDIT_BOOK",
    CASH_BOOK = "CASH_BOOK",
    BALANCE = "BALANCE",
    INVOICE = "INVOICE",
    INVOICE_CANCEL = "INVOICE",
    INVOICE_APPROVAL = "INVOICE_APPROVAL",
    CANCEL = "CANCEL",
    CANCEL_APPROVAL = "CANCEL_APPROVAL",
}
export enum DetailType {
    "add" = "add",
    "edit" = "edit",
    "look" = "look"
}
// const titleType = {
//     add: "新增",
//     edit: "编辑",
//     look: "查看",
// }
export enum titleType {
    add = "新增",
    edit = "编辑",
    look = "查看",
}
//打印logo设置
export enum IIMgEmum {
    SKNY = "skny",
    OUMAN = "ouman",
    ZF = "zf",
    IVECO = "iveco"
}
export const logoOptions = [ //新路王
    { label: "斯堪尼亚logo", value: IIMgEmum.SKNY },
    { label: "依维柯logo", value: IIMgEmum.IVECO },
    // { label: "曼恩logo", value: IIMgEmum.OUMAN },
    // { label: "采埃孚logo", value: IIMgEmum.ZF }
]
export const logoYhhOptions = [ //云和汇
    { label: "曼恩logo", value: IIMgEmum.OUMAN },
    { label: "采埃孚logo", value: IIMgEmum.ZF }
]
export enum IPayTypeEnum {
    SELFPAY = "settle-party.self-pay", //客户结算
    MANMAINTAIN = "settle-party.man-maintain", //MAN-保养合约
    MANCLAIM = "settle-party.man-claim", //MAN-索赔
    MANGOODWILL = "settle-party.man-goodwill", //MAN-Goodwill
    JFCLAIM = "settle-party.jf-claim",//JF-索赔
    SYCLAIM = "settle-party.sy-claim",//SY-索赔
    XGCLAIM = "settle-party.xg-claim",//XG-索赔
    OMCLAIM = "settle-party.om-claim",//OM-索赔
    ZQCLAIM = "settle-party.zq-claim",//ZQ-索赔
    SQCLAIM = "settle-party.sq-claim",//SQ-索赔
    HYCLAIM = "settle-party.hy-claim",//HY-索赔
    SCNSCANIA = "settle-party.scn-scania",//SCN-斯堪尼亚牵引车部门
    CHASSIS = "settle-party.chassis",//专用车底盘部门
    INTERNAL = "settle-party.internal", //部门内部费用
    INSURANCE = "settle-party.insurance",//保险公司
    SCNCLAIM = "settle-party.scn-claim",//SCN-索赔
    SCNEPS = "settle-party.scn-eps", //SCN-EPS
    SCNGOODWILL = "settle-party.scn-goodwill",//SCN-GoodWill
    SCNMAINTAIN = "settle-party.scn-maintain", //SCN-保养合约
}

//销售类型
export enum ISalesTypeEnum {
    SUOPEI = "sales-type.suopei",// 三包索赔
    CALLLOUT = "sales-type.callout", // 调出订单
    SELFUSE = "sales-type.self use", // 部门自用
    GIFTS = "sales-type.sales-gifts",// 销售馈赠
    NORMAL_SALES = "Sales type.normal sales",//正常销售
}
//售价选择
export enum IPriceTypeEnum {
    BUYING_PRICE = "price-selection.buying-price",//进货价
    CUSTOMER_LATEST = "price-selection.customer-latest",//客户最近销价
    RECENT = "price-selection.recent",//最近销价
    RETAIL = "price-selection.retail",//零售价
    // PETROLEUM = "price-selection.petroleum-price" //中石油销价
}