import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form } from "antd";

export const __FormButton: FC<IFormItem> = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            rules={props.rules}
            labelCol={props.labelCol}
            hidden={props.hidden}
            colon={false}
        >
        </Form.Item>
    )
}