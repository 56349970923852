import React, { FC } from "react";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { RouterParamType } from "@/model/navModel";
import { useStockCheckDetailModel } from "./stockCheckDetailsModel";
import { Description } from "@/components/YhBox/Description";
import { useDictModel } from "@/views/base/dict/dictModel";

export const TopButs: FC<RouterParamType> = ({ id, mode }) => {
    const { getTreeNodeName } = useDictModel();
    const { detailAddForm, detailLookForm, detailEditForm, } = useStockCheckDetailModel()

    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatu: IDescription[] = [
        {
            label: "盘点单号",
            value: typeTitle[mode]?.stocktakingCode as string ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}