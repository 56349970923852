import React from "react";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { Button } from "antd";
import { useSalesReortModel } from "../salesReortModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { onExport } = useSalesReortModel()
    const action =
        <YhAuth resCode={ResourceAuthEnum.STATIST_SALE_EXPORT}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onExport}
            >导出</Button>
        </YhAuth>
    return (
        <YhBox title={action}>
            <DataTable />
        </YhBox>
    )
}