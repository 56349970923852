import React, { FC } from "react";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";
import useGlobalModel from "@/model/globalModel";
import { RouterParamType } from "@/model/navModel";
import { formatNum } from "@/utils/utils";
import { useSaleDcDetailsModel, ISCMSDcDescType } from "./saleAllotDetailsModel";

export const FixedBottom: FC<RouterParamType> = ({ id, mode }) => {
    const { editDesc, addDesc, } = useSaleDcDetailsModel();
    const { cm } = useGlobalModel();

    const retuenText = (field: string, money: boolean = false) => {
        let desc: ISCMSDcDescType = mode === "edit" ? editDesc : addDesc;
        return money ? `￥${formatNum(desc[field])}` : desc[field];
    }

    const orderStatistics: IDescription[] = [
        {
            label: "调拨数量",
            value: retuenText("numAll"),
            color: DescriptionColorEnum.green,
            status: true
        },
        {
            label: "折前总计",
            value: retuenText("totalPreAmount", true),
            color: DescriptionColorEnum.green,
            status: true
        },
        {
            label: "调拨总计",
            value: retuenText("actualAmount", true),
            color: DescriptionColorEnum.red,
            status: true
        }
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${cm.collapsed ? '80px' : '226px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}