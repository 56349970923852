import React, { useEffect, useState } from "react";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { useSummaryModel } from "./summaryModel";
import { Button, Input } from "antd";
import useGlobalModel from "@/model/globalModel";
enum ITypes {
    RANGE = "Range",
    TERM = "term",

}
export const SyncroModel = () => {
    const { user: { shopName, shopCode } } = useGlobalModel();
    const { synchroModel: visible, setSynchroModel, syncData, syncLoading, setSyncLoading, loadData, initPgCfg } = useSummaryModel();
    // const [syncLoading, setSyncLoading] = useState<boolean>(false);
    const [type, setType] = useState<ITypes>(ITypes.TERM)
    const [name, setName] = useState<string>('')
    const onCancel = () => {
        setSynchroModel(false)
    }
    const onOk = () => {
        setSyncLoading(true)
        let params = type === ITypes.RANGE ? { branch: 'bcm' } : { name }
        syncData(params).then(res => { setSyncLoading(false); loadData(initPgCfg, {}); onCancel() }).catch(err => setSyncLoading(false));
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setType(ITypes.TERM)}
        >条件同步</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setType(ITypes.RANGE)}
        >分支同步</Button>

    </>
    return <YHModal
        visible={visible}
        // onOk={onOk}
        // onCancel={onCancel}
        title="客户同步"
    // showFooter={true}
    >
        <YhBox title={action}>
            {type === ITypes.RANGE && <div className="flex justify-center items-center " style={{ height: "150px" }} >
                分支范围：客车管理分支
            </div>}
            {type === ITypes.TERM && <div className="flex justify-center items-center " style={{ height: "150px" }} >
                供应商名称：<Input onChange={(e) => setName(e.target.value)} value={name} style={{ width: "350px", marginLeft: "20px" }} />
            </div>}
        </YhBox>
        <div className="flex justify-center items-center mt-30" >
            <Button
                className="mr-5"
                type="link"
                shape="round"
                loading={syncLoading}
                onClick={onOk}
            >{syncLoading ? "同步中" : "同步"}</Button>
            <Button
                className="mr-5"
                type="default"
                shape="round"
                onClick={onCancel}
            >取消</Button>
        </div>
    </YHModal>
}