import { httpRequest } from "../../../utils/HttpRequest";
import {
    IPurchasePoSearch,
    IPurchasePoDetail,
    IPurchasePoFields,
    IPurchasePoTurnSale,
    Detailed,
    IPurchasePoList,
    IPurchasePoSettleAndWhConfirm
} from "./purchasePoType"

class PurchasePoService {
    async page(searchVo: IPurchasePoSearch) {
        const apiUrl = "/bcm/purchase/findPage";
        return await httpRequest.post<IPurchasePoList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/purchase/findById/${id}`;
        return await httpRequest.get<IPurchasePoDetail>(apiUrl);
    }

    async insert(insertVo: IPurchasePoFields) {
        const apiUrl = "/bcm/purchase/insert";
        return await httpRequest.post<IPurchasePoDetail>(apiUrl, insertVo);
    }
    async update(updateVo: IPurchasePoFields) {
        const apiUrl = "/bcm/purchase/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/bcm/purchase/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: IPurchasePoFields) {
        const apiUrl = "/bcm/purchase/export";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //作废
    async invalidate(params: IPurchasePoSettleAndWhConfirm) {
        const apiUrl = "/bcm/purchase/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/bcm/purchase/detail/downloadTpl";
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //库房确认
    async warehousing(params: IPurchasePoSettleAndWhConfirm) {
        const apiUrl = "/bcm/purchase/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //采购结算
    async settle(params: IPurchasePoSettleAndWhConfirm) {
        const apiUrl = "/bcm/purchase/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //采购补单
    async supplementInsert(insertVo: IPurchasePoFields) {
        const apiUrl = "/bcm/purchase/supplementInsert";
        return await httpRequest.post<IPurchasePoDetail>(apiUrl, insertVo);
    }
    //转接销售单
    async transfer(insertVo: IPurchasePoFields) {
        const apiUrl = "/bcm/purchase/transfer";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //详情导出
    async detailsExport(params: IPurchasePoFields) {
        const apiUrl = "/bcm/purchase/detailsExport";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
}

export const purchasePoService = new PurchasePoService();