import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { SalePrint } from "./SalePrint";
import { usePurchaseRoModel } from "../purchaseRoModel";

export default () => {
    const { print } = usePurchaseRoModel();

    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <SalePrint />}

        </>
    )
}