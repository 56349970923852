import React, { useState, useEffect } from "react";
import { createModel } from "hox";
import { ISaleDetail, ISaleFields, ISaleStatistics, ISaleDetailed } from "../../../busBusinessType"
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, defaultMdCmParam } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table";
import { ISearchPage } from "@/types/ScmTypes";
import { useDetailModel, ActionTypeEnum } from "../detailModel";
import useGlobalModel from "@/model/globalModel";
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { message } from "antd";
import { convertRes2Blob } from "@/utils/exportFile";

export const useSaleModel = createModel(function () {
    const { busBusiness: { customerCode }, setActionType } = useDetailModel();
    const { shopAndOrg } = useGlobalModel()
    const { shopCode, shopId } = shopAndOrg
    const initSearchParam: ISaleFields = {};
    const initDataSource: ISaleDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc = {
        saleNum: 0,
        saleAmount: 0,
        preferentialAmount: 0,
        receivableAmount: 0,
        receivedAmount: 0,
        saleCost: 0,
        grossProfit: 0
    }
    //查询条件
    const [searchParam, setSearchParam] = useState<ISaleFields>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<ISaleDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<ISaleDetail>({});
    const [descTotal, setDescTotal] = useState<ISaleStatistics>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<ISaleStatistics>(initDesc);
    const [descRows, setDescRows] = useState<ISaleDetail[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item, index) => {
            let { totalNum, totalAmount, discountTotalAmount, receivedAmount, receivableAmount, totalCost, grossProfit } = item
            // newRowsDescTotal.saleNum += (totalNum as number)
            newRowsDescTotal.saleNum = bigNumber.add(newRowsDescTotal.saleNum, totalNum as number)
            // newRowsDescTotal.saleAmount += +formatNum(totalAmount)
            newRowsDescTotal.saleAmount = bigNumber.add(newRowsDescTotal.saleAmount, totalAmount as number)
            // newRowsDescTotal.preferentialAmount += +formatNum(discountTotalAmount)
            newRowsDescTotal.preferentialAmount = bigNumber.add(newRowsDescTotal.preferentialAmount, discountTotalAmount as number)
            // newRowsDescTotal.receivedAmount += +formatNum(receivedAmount)
            newRowsDescTotal.receivedAmount = bigNumber.add(newRowsDescTotal.receivedAmount, receivedAmount as number)
            // newRowsDescTotal.saleCost += +formatNum(totalCost)
            newRowsDescTotal.saleCost = bigNumber.add(newRowsDescTotal.saleCost, totalCost as number)
            // newRowsDescTotal.receivableAmount += +formatNum(receivableAmount)
            newRowsDescTotal.receivableAmount = bigNumber.add(newRowsDescTotal.receivableAmount, receivableAmount as number)
            // newRowsDescTotal.grossProfit += grossProfit as number
            newRowsDescTotal.grossProfit = bigNumber.add(newRowsDescTotal.grossProfit, grossProfit as number)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as ISaleFields)
    }, [selectedRowKeys, dataSource])

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: ISaleFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    const loadData = async (page?: ISearchPage, searchValue: ISaleFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopCode,
            shopId,
            customerCode,//后期改成动态
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await busBusinesstService.salePage(searchParams);
        const { records, statistics, ...pg } = retData;
        setDescTotal(statistics)
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records);
    }
    const invalidateData = async (params: ISaleDetailed) => {
        let { retData } = await busBusinesstService.saleInvalidate(params)
        if (retData) {
            message.success("作废成功");
            loadData();
        }
    }
    const exportSaleSo = async (searchValue: ISaleFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            customerCode,
            id: selectedRowKeys.join(),
        }
        let result = await busBusinesstService.saleExport(searchParams);
        convertRes2Blob(result)
    }
    const detailsExport = async (searchValue: ISaleFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            customerCode,
            id: selectedRowKeys.join(),
        }
        let result = await busBusinesstService.saleDetailsExport(searchParams);
        convertRes2Blob(result)
    }
    const saleTransfer = async (saleCode: string) => {
        let { retData } = await busBusinesstService.saleOne(saleCode);
        const { item, deliveryTime, enterTime, ...from } = retData
        const newMaterialDetails = item?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
                detailId: item.id,
                saleNum: item.num,
                salePrice: item.price,
                saleCode: saleCode,
                id: ""
            }
        })
        let { retData: tranRetData } = await busBusinesstService.saleTransfer({ ...from, details: newMaterialDetails });
        if (tranRetData) {
            message.success("转接销退单成功");
            loadData();
            setActionType(ActionTypeEnum.SALESRETURN)
        }
    }
    const [print, setPrint] = useState<boolean>(false)
    return {
        initPgCfg,
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        selectedRow,
        rowsDescTotal,
        descRows,
        descTotal,
        // method
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setPgCfg,
        updatePgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        setSelectedRow,
        setRowsDescTotal,
        setDescRows,
        setDescTotal,
        invalidateData,
        exportSaleSo,
        detailsExport,
        saleTransfer,
        print,
        setPrint
    }
})