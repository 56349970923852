import React, { useRef, useEffect, useState, FC } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { formatNum } from "@/utils/utils";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import Table, { ColumnsType } from "antd/lib/table";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { IVRMSettleTypeEnum } from "@/types/FmsDs";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { IFactoryRoSettleParams } from "../../factoryType"
import useGlobalModel from "@/model/globalModel";
import { useFactoryDetailsModel } from "./factoryDetailsModels";
import { ISelectOptions } from "@/types/ScmTypes";
import { accountService } from "@/views/account/AccountService";
import { RouterParamType } from "@/model/navModel";
export interface ISalePayMethod {
    amount?: number,     /// 0,
    code?: string,     /// "",
    createTime?: string,     /// "",
    createUser?: string,     /// "",
    deleteFlag?: number,     /// 0,
    id?: string,     /// "",
    note?: string,     /// "",
    orderNum?: number,     /// 0,
    payMethodCode?: string,     /// "",
    payMethodName?: string,     /// "",
    payee?: string,     /// "",
    shopId?: string,     /// "",
    tradeCode?: string,     /// "",
    tradeTypeCode?: string,     /// "",
    tradeTypeName?: string,     /// "",
    updateTime?: string,     /// "",
    updateUser?: string,     /// ""
    available?: number,
    accountName?: string,
    accountCode?: string,
    key: string,
}
//结算
export const SettleDrawer: FC<RouterParamType> = ({ id, mode }) => {
    const { settleVisible: visible, detailAddForm, detailEditForm, detailLookForm, lookSettleDetails, editSettleDetails, setSettleVisible, onSettle, getDetailEditOne } = useFactoryDetailsModel();
    const { user, shopAndOrg } = useGlobalModel()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel();
    const formRef = useRef<IYhFormRef>();
    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm
    }
    const typeDetailList = {
        add: [],
        edit: editSettleDetails,
        look: lookSettleDetails
    }
    const [settleType, setSettleType] = useState<IVRMSettleTypeEnum>(IVRMSettleTypeEnum.SETTLE_CASH);   //结算类型，  默认现金结账
    const initDataSource: ISalePayMethod = {
        key: new Date().getTime().toString(),
        payMethodCode: "",
        payMethodName: "",
        amount: 0,
        payee: "",
        note: "",
    };
    const [payDataSource, setPayDataSource] = useState<ISalePayMethod[]>([]);  //表格默认值
    const [accountTypeDs, setAccountTypeDs] = useState<ISelectOption[]>([]) //实时存取收款账户类型值settle-type.cash
    const [editPayMethodOptions, setEditPayMethodOptions] = useState<ISelectOptions[]>(getSelectedDictOne(DictCodeEnmu.PAY_METHOD))//编辑根据结算类型展示结算方式

    useEffect(() => {
        if (visible) {
            formRef.current?.setFieldsValue({ ...typeForm[mode], note: "" });
            setPayDataSource(mode === "edit" ? [initDataSource] : typeDetailList[mode]);
            setSettleType(typeForm[mode].payType as IVRMSettleTypeEnum);
            queryAccount(typeForm[mode].customerCode as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, typeForm[mode].payType as string);
            setPayMethodOptions(typeForm[mode].payType as string)

        }
    }, [visible])
    const setPayMethodOptions = (payType: string) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions as ISelectOptions[])
    }
    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode: string, creditFlag: number, payType: string) => {
        // console.log(partnerCode, creditFlag)
        let params = {
            partnerCode,  //客户/供应商编码 
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag,  //为记账类型的  1挂账 2现结
            sourceApp: "vrm",
        }
        const { retData } = await accountService.accountFind(params);
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter(item => {
            // if (creditFlag) {
            //     return typeForm[mode].payType !== IVRMSettleTypeEnum.SETTLE_CASH ?
            //         (item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE || item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE_OIL) :
            //         (item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE && item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE_OIL)
            // }
            return item;
        })
        console.log(filterResult)
        // debugger;
        //现金账户 或者 挂账账户。
        let newAccTypeDs: any = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        if (payType === IVRMSettleTypeEnum.SETTLE_CASH) {
            newAccTypeDs = filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {//挂账  account-type.credit
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0 }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_MONTHLY) {//月结  account-type.credit-month
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit-month") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0 }));
        }
        setAccountTypeDs(newAccTypeDs);
        //选择挂账， 自动带出挂账的账户及授信额度
        if (creditFlag) {
            newAccTypeDs.length > 0 ?
                setPayDataSource(() => {
                    let available = +(newAccTypeDs[0]?.available ?? 0);  //可用额度
                    let payAmount = +(typeForm[mode].receivedAmount as string);   //需要支付的额度
                    // if (payAmount > available) { message.warning("该账户可用额度不足") };
                    return [{
                        ...initDataSource,
                        available: available,
                        accountName: newAccTypeDs[0].label,
                        accountCode: newAccTypeDs[0].value,
                        // payMethodName: newAccTypeDs[0].label,
                        // payMethodCode: newAccTypeDs[0].value,
                        amount: +`${payAmount <= available ? payAmount : 0}`,
                    }]
                })
                :
                message.warning(`该客户没有授信账户`);
        }
    }
    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value: string, option: ISelectOption) => {
        if (option) {
            setSettleType(option.value as IVRMSettleTypeEnum);
            setPayDataSource([initDataSource]);
            // option.value === ISCMSettleTypeEnum.SETTLE_CREDIT && queryAccount(editFormVal.customerCode);
            queryAccount(typeForm[mode].customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, value);
            setPayMethodOptions(value)

        }
    }
    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {

    }

    const onCancel = () => {
        setSettleVisible(false);
    }

    //表单提交
    const onSubmit = async () => {
        let filterPayData = payDataSource.filter(item => (item.payMethodCode));
        if (filterPayData.length !== payDataSource.length) {
            message.warning("请完善收款信息");
            return;
        }
        let totalAmount: number = filterPayData.reduce((con, next) => { con += +(next.amount ?? 0); return con }, 0);
        if (totalAmount !== +(typeForm[mode].receivableTotalAmount as number)) {
            message.warning("您结算的金额与应收金额有差异");
            return;
        }
        if (settleType === IVRMSettleTypeEnum.SETTLE_CASH && accountTypeDs.length === 0) {
            message.warning("您的现金账户没有开户");
            return;
        }
        formRef.current?.validateFields().then(async res => {
            let data: IFactoryRoSettleParams = {
                id: typeForm[mode].id as string,
                maintenanceCode: res.maintenanceCode,
                note: res.note,
                reckoner: user.username,
                payType: res.payType,
                accountCode: accountTypeDs[0]?.["value"] as string  ?? "",
                details: payDataSource.map((item => {
                    let { key, ...params } = item
                    return params
                }))
            }
            try {
                let retData = await onSettle(data)
                message.success("结算成功");
                onCancel()
                getDetailEditOne(typeForm[mode].maintenanceCode as string, "edit")
            } catch (error) {
                onCancel()
            }


        }).catch(err => {

            // message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = (val: any) => {

    }

    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工单单号",
                fieldName: "maintenanceCode",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单日期",
                fieldName: "createTime",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户编码",
                fieldName: "customerCode",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售实体",
                fieldName: "bentityName",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "应收金额",
                fieldName: "receivableTotalAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "已收金额",
                fieldName: "receivableAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "优惠金额",
                fieldName: "discountTotalAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算日期",
                fieldName: "settleTime",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                onSelectChange: settleTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                span: 12,
                // disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 12,
                // disable: true
            },


        ]
    };
    const columns = [

        {
            title: '结算方式',
            dataIndex: 'payMethodCode',
            editType: "select",
            options: editPayMethodOptions,//getSelectedDictOne(DictCodeEnmu.PAY_METHOD),
            width: 150,
            editable: mode === "look" ? false : true,
            render: (text: string) => getTreeNodeName(text)

        },
        ...((str) => {
            if (str === IVRMSettleTypeEnum.SETTLE_CASH) {
                return []
            } else {
                return [
                    {
                        title: '可用额度',
                        width: 120,
                        dataIndex: 'available',
                        render: (text: string) => <span className="statu-red">{`￥${text ?? 0}`}</span>
                    },
                ]
            }
        })(settleType),
        {
            title: '结算金额',
            width: 120,
            dataIndex: 'amount',
            editable: mode === "look" ? false : true,
            render: (text: any) => `￥${formatNum(text)}`
        }, {
            title: '收款人',
            editable: mode === "look" ? false : true,
            dataIndex: 'payee',
            editType: "select",
            options: getSelectedDictOne(DictCodeEnmu.PAYEE),
            width: 150,
            render: (text: string) => getTreeNodeName(text)
        }, {
            align: "center",
            title: '操作',
            width: 100,
            render: (text: any, record: any) =>
                <div className="flex justify-around">
                    <PlusOutlined
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                        onClick={() => setPayDataSource(() => [...payDataSource, initDataSource])}
                    />
                    <DeleteOutlined
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                        onClick={() => setPayDataSource(() => payDataSource.filter(item => item.key !== record.key))}
                    />
                </div>,
        }
    ];


    const newPayColumns = columns.map((col: any, index) => {
        return {
            ...col,
            onCell: (record: ISalePayMethod, rowIndex: number) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row: ISalePayMethod) => {
        row.amount = +formatNum(row.amount);

        //结算类型为挂账时， 判断支付方式有值时候 去对应的可用额度
        if (settleType === IVRMSettleTypeEnum.SETTLE_CREDIT) {
            if (row.payMethodCode) {
                // debugger;
                let find = accountTypeDs.find(item => item.value === row.accountCode);
                // row.payMethodName = find?.label ?? "";
                // row.payMethodName = getTreeNodeName(row.payMethodCode) ?? "";;
                row.available = +(find?.available ?? 0);
            }
            if (+row.amount > +(row.available ?? 0)) {
                row.amount = 0;
                message.warning("结算金额≤可用额度");
            }
        } else if (settleType === IVRMSettleTypeEnum.SETTLE_CASH) {
            if (row.payMethodCode) {
                row.payMethodName = getTreeNodeName(row.payMethodCode) ?? "";;
            }
        }
        row.payMethodName = getTreeNodeName(row.payMethodCode as string) ?? "";;

        let dataSource: ISalePayMethod[] = [...payDataSource];
        const index = dataSource.findIndex((item) => row.key === item.key);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        setPayDataSource(dataSource);
    }
    return (
        <YHDrawer
            {...{
                title: '工单结算',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit,
                width: 800,
                showFooter: detailEditForm.status === "maintenance.settle" || mode === "look" ? false : true

            }}
        >
            <YhForm {...formConfig} />
            <Table
                // size="small"
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newPayColumns as ColumnsType<ISalePayMethod>}
                dataSource={payDataSource}
                pagination={false}
                scroll={{ x: 'max-content', y: 400 }}
            />
            <div className="table-bottom-total" onClick={() => { }}>合计:<span className="ml-15 statu-red">{`￥${formatNum(payDataSource.reduce((con, next) => { con += +(next.amount ?? 0); return con }, 0))}`}</span></div>

        </YHDrawer>
    )
}
