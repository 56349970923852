import React from "react";
import { useReportFormModel } from "../reportFormModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "商品编号",
        fieldName: "code"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "商品名称",
        fieldName: "name"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "商品类型",
        fieldName: "typeName"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "车型",
        fieldName: "vehicleModel"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "仓库",
        fieldName: "warehouseName"
    },
]
const searchMoreFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "品牌",
        fieldName: "brandName"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "产地",
        fieldName: "madeFrom",
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "分组代码",
        fieldName: "groupingCode",
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "分类代码",
        fieldName: "genericCode"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "规格",
        fieldName: "model",
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "货位",
        fieldName: "shelfCode",
    },
    {
        type: SearchTypeEnum.RangePicker,
        fieldName: "",
        placeholder: ["出入库开始", "出入库结束"],
        rangePickerFieldName: ["startTime", "endTime"],
        format: "YYYY-MM-DD",
        span: 12,
        xxl: 6,
    },

]

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useReportFormModel();

    return (
        <YhPageHeader
            title="库存报表"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}