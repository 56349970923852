import React, { FC } from "react";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";
import useGlobalModel from "@/model/globalModel";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";
import { RouterParamType } from "@/model/navModel";
import { formatNum } from "@/utils/utils";

export const FixedBottom: FC<RouterParamType> = ({ id, mode }) => {
    const { cm } = useGlobalModel();
    const {
        editDesc, lookDesc
    } = usefollowUpDetailsModel();
    const typeDesc = {
        "add": lookDesc,
        "edit": editDesc,
        "look": lookDesc,
    }
    const orderStatistics: IDescription[] = [
        {
            label: "工时小计",
            value: `${formatNum(typeDesc[mode].workHoursPrice)} (${typeDesc[mode].workHoursNum})项`,
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "配件小计",
            value: `${formatNum(typeDesc[mode].partPrice)} (${typeDesc[mode].partPriceNum})项`,
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "费用合计",
            value: formatNum(typeDesc[mode].partPrice + typeDesc[mode].workHoursPrice),
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "优惠金额",
            value: formatNum(typeDesc[mode].discountAll),
            color: DescriptionColorEnum.red,
            status: true
        },
        {
            label: "应收金额",
            value: formatNum(typeDesc[mode].receivableAll),
            color: DescriptionColorEnum.red,
            status: true
        }
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${cm.collapsed ? '80px' : '226px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}