import React, { FC } from "react";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { RouterParamType } from "@/model/navModel";
import { Description } from "@/components/YhBox/Description";
import { useFactoryDetailsModel } from "./factoryDetailsModels";
import { useDictModel } from "@/views/base/dict/dictModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";


export const TopButs: FC<RouterParamType> = ({ id, mode }) => {
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        topButsControl,
        setSettleVisible
    } = useFactoryDetailsModel();
    const { getTreeNodeName } = useDictModel();
    const onSettle = () => {
        setSettleVisible(true)
    }
    const action = mode === "edit" ? <>
        <YhAuth resCode={ResourceAuthEnum.BILL_M_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onSettle}
                disabled={topButsControl || detailEditForm.status === "maintenance.settle"}
            >确认结算</Button>
        </YhAuth>
    </> : mode === "look" ? <>
        <YhAuth resCode={ResourceAuthEnum.BILL_M_ED_CONFIRM_BILL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                // disabled={topButsControl}
                onClick={onSettle}
            >结算查看</Button>
        </YhAuth>
    </> : false;
    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const orderStatu: IDescription[] = [
        {
            label: "结算单",
            value: typeTitle[mode].maintenanceCode ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}