import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";
import { NumberLiteralType } from "typescript";

/**
 * 入库单
 */
export interface IInventoryDetailsDetail {
    id: string,//"ab6074c0f54044cc990047ba93648601",
    shopId: string,//"dc6cdb958e17d8440b98c3ae7e6b817e",
    status: string,//"presale-order.stock-io",
    inventoryNum: string,//5,
    salePrice: string,//null,
    purchaseCode: string,//null,
    stockId: string,//"52699621a508459684336ac8d9ab2ac1",
    orderCode: string,//"BJKCGJYS22041700014",
    materialNo: string,//"",
    materialCode: string,//"0732 612 005",
    materialName: string,//"通气塞总成",
    replaceCode: string,//null,
    warehouseCode: string,//"WH-M-00000076",
    warehouseName: string,//"綦齿库",
    storageWarehouseCode: string,//"WH-M-00000211",
    storageWarehouseName: string,//"三车间马坡料库",
    num: string,//5,
    returnNum: string,//0,
    price: string,//14.49,
    saleNum: string,//null,
    amount: string,//72.45,
    discountRate: string,//100,
    totalAmount: string,//72.45,
    brandCode: string,//null,
    brandName: string,//"綦江",
    unitCode: string,//"unit.article",
    unitName: string,//"件",
    latestPurPrice: string,//4.2,
    avallable: string,//null,
    note: string,//null,
    costPrice: string,//null,
    costAmount: string,//null
    [key: string]: any
}

//搜索条件
export interface IInventoryDetailsSearchFields {
    startTime?: string,
    endTime?: string,
    code?: string,
    tradeTypeCode?: string,
    stockIoTypeCode?: string,
    tradeCode?: string,
    materialCode?: string,
    storekeeper?: string,
    materialName?: string,
    bentityCode?: string,
    ioFlag?: string,            //出或入  出是 1 入是 0
    shopId?: string,
    occupy?:number|null,
    age?:number|null,
}

export interface IInventoryDetailsSearch
    extends IInventoryDetailsSearchFields, ISearchPage {
}

export interface IInventoryDetailsStatistics {
    totalAmount: string | number
}


//商品详情
export interface IInventoryDetailsGoods {
    id: string,                           // "111",
    code: string,                           // "1",
    orderNum: string,                           // 1,
    deleteFlag: string,                           // 0,
    createTime: string,                           //,
    updateTime: string,                           // ,
    createUser: string,                           // null,
    updateUser: string,                           // null,
    detailsId: string,                           // "1",
    ioId: string,                           // "1",
    materialCode: string,                           // "1",
    materialName: string,                           // "1",
    replaceCode: string,                           // "1",
    typeCode: string,                           // "1",
    typeName: string,                           // "1",
    unitCode: string,                           // "1",
    unitName: string,                           // "1",
    engineType: string,                           // "1",
    ioFlag: string,                           // "1",
    num: string,                           // 1,
    price: string,                           // 1,
    amount: string,                           // 1,
    warehouseCode: string,                           // "1",
    warehouseName: string,                           // "1",
    shelfId: string,                           // "1",
    shelfCode: string,                           // "1",
    openingNum: string,                           // 1,
    openingPurAmount: string,                           // 1,
    openingAvgAmount: string,                           // 1,
    endingNum: string,                           // 1,
    endingPurAmount: string,                           // 1,
    endingAvgAmount: string,                           // 1
}
//采购明细转移货位
export interface IPoShift {
    id: string,// "3562a0997394fe648fbaff0debbc50bf", 采购单明细id
    warehouseCode: string,// "wc", 仓库编号
    warehouseName: string,// "潍柴库", 仓库名称
    shelfId: string,// "bcd65b9cad280b5f7a9089d7433ce303", 货位ID
    shelfCode: string,// "ABC123", 货位编号
    moveNum: number,// 10                                                                移库数量
}
export interface IPoShiftImportDetail {
    shopId: string, // null,
    code: string, // null,
    shopName: string, // null,
    materialCode: string, // "511-434-01-01",
    materialName: string, // "排气制动电磁阀",
    originalWarehouseCode: string, // "wc",
    originalWarehouseName: string, // "潍柴库",
    originalShelfId: string, // null,
    originalShelfCode: string, // "A1-20",
    originalInventoryNum: number, // 1,
    moveNum: number, // null,
    warehouseCode: string, // "wc",
    warehouseName: string, // "潍柴库",
    shelfId: string, // null,
    shelfCode: string, // "A1-11",
    restoreNum: number, // null,
    restoreTime: string, // null,
    restorePerson: string, // null,
    isRestore: string, // null,
    id: string, // "0c6d5ff7e2aab68486e59ce664c99017",
    createTime: string, // null,
    updateTime: string, // null,
    createUser: string, // null,
    updateUser: string, // null,
    deleteFlag: string, // null
    warehouseCode1?: string
    warehouseName1?: string
    shelfId1?: string
    shelfCode1?: string
    availableStock?: number
}
export interface IInventoryDetailsTotal {
    stockAmount: number       // 库存成本
    stockNum: number                //  库存数量
    presaleAmount: number;
    presaleNum: number; // 库存数量
    presaleTotalCost: number; //库存成本
    [key: string]: any
}

export interface IHistorySearch extends ISearchPage{
    materialCode: string;
    shopId: string;
}

export interface IHistoryDetails {

}
class InventoryDetailsService {
    //采购明细
    async page(searchVo: IInventoryDetailsSearch) {
        const apiUrl = "/bcm/presaleorder/stock/details";
        return await httpRequest.post<IPage<IInventoryDetailsDetail>>(apiUrl, searchVo);
    }
    //采购明细
    async statistics(searchVo: IInventoryDetailsSearch) {
        const apiUrl = "/bcm/presaleorder/header/statistics";
        return await httpRequest.post<IInventoryDetailsTotal>(apiUrl, searchVo);
    }
    //采购明细统计接口
    async amount(searchVo: IInventoryDetailsSearch) {
        const apiUrl = "/bcm/stock/details/total/amount";
        return await httpRequest.post<IInventoryDetailsTotal>(apiUrl, searchVo, { timeout: 300000 });
    }
    //移库
    async shift(arr: IPoShift[]) {
        const apiUrl = `/bcm/stock/details/shift`;
        return await httpRequest.post<boolean>(apiUrl, arr);
    }
    //导出
    async export(searchVo: IInventoryDetailsSearch) {
        const apiUrl = `/bcm/presaleorder/stock/export`;
        return await httpRequest.postFile<IInventoryDetailsDetail>(apiUrl, searchVo, { timeout: 300000 });
    }

    //配件预售预退历史
    async presaleorderHistory(searchVo: IHistorySearch) {
        const apiUrl = "/bcm/presaleorder/history";
        return await httpRequest.post<IPage<IHistoryDetails>>(apiUrl, searchVo);
    }
    //配件公交销售销退历史
    async saleHistory(searchVo: IHistorySearch) {
        const apiUrl = "/bcm/transit/sale/history";
        return await httpRequest.post<IPage<IHistoryDetails>>(apiUrl, searchVo);
    }
    //配件公交调配历史
    async deploymentHistory(searchVo: IHistorySearch) {
        const apiUrl = "/bcm/deployment/history";
        return await httpRequest.post<IPage<IHistoryDetails>>(apiUrl, searchVo);
    }
}

export const inventoryDetailsService = new InventoryDetailsService();