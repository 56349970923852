import React, { FC } from "react";
import { Button, Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { useMaintenanceDetailsModel } from "./maintenanceDetailsModels";
import { IMaterialDetailList } from "../../maintenanceType";
import { formatIntPoint, formatNum, formatIntPoint1,bigNumber } from "@/utils/utils";
import { components } from "@/components/YHEditTable/editTable";
import { DetailType } from "@/types/ScmEnums";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { ITopType } from "@/types/ScmTypes";

export const MaterialTableBox: FC<ITopType> = ({ id, mode, IFormRef }) => {
    const {
        setMaterialModel,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        detailMaterialLookList,
        detailMaterialDetailList,
        setTopButsControl,
        setHistoryVisible,
        setHistoryRow
    } = useMaintenanceDetailsModel();
    const onHistoryModel = (record: IMaterialDetailList) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }
    const switchModelViaible = () => {
        IFormRef.current?.validateFields().then(async res => {
            setMaterialModel(true)
        })
    }
    const action = <>
        {mode !== "look" && <>
            <YhAuth resCode={ResourceAuthEnum.M_ED_ADD_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => switchModelViaible()}
                >新增商品</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.M_ED_DEL_M}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => detailMaterialDetailList(mode)}
                >删除商品</Button>
            </YhAuth>
        </>}
    </>;

    const MaterialColumns = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '配件编码',
            width: 120,
            dataIndex: 'materialCode',
            render: (text: any, record: any, index: number) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onHistoryModel(record)}>{text}</p></Tooltip>,
            fixed: "left",
        }, {
            title: '配件名称',
            width: 120,
            dataIndex: 'materialName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
            fixed: "left",
        }, {
            title: '单位',
            width: 60,
            dataIndex: 'unitName',
        }, {
            title: '数量',
            width: 100,
            dataIndex: 'num',
            editable: mode !== "look" ? true : false,
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            editable: mode !== "look" ? true : false,
        }, {
            title: '配件费',
            width: 100,
            dataIndex: 'materialAmount',
        }, {
            title: '折扣',
            width: 100,
            dataIndex: 'discountRate',
            editable: mode !== "look" ? true : false,
            render: (text: string) => text + "%"
        }, {
            title: '折后单价',
            width: 100,
            dataIndex: 'discountedPrice',
        }, {
            title: '小计',
            width: 100,
            dataIndex: 'receivableAmount',
        }, {
            title: '成本价',
            width: 100,
            dataIndex: 'costPrice',
        }, {
            title: '成本金额',
            width: 100,
            dataIndex: 'costAmount',
        }, {
            title: '库房',
            width: 160,
            dataIndex: 'warehouseName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '货位',
            width: 160,
            dataIndex: 'shelfCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '可用库存',
            width: 160,
            dataIndex: 'inventoryNum',
        }, {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: mode !== "look" ? true : false,

        }
    ];
    const newSelectMaterialColums = () =>
        MaterialColumns.map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IMaterialDetailList, rowIndex: number) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    suffix: col.suffix,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleMaterialSave,
                }),
            }
        })
    const handleMaterialSave =async (row: IMaterialDetailList) => {
        let { num, price, discountRate, materialCode, purchasePrice,costPrice,stockId,inventoryNum } = row
        num = +formatIntPoint(num, inventoryNum, "选择数量>=可用库存数量");  //数量
        price = +formatIntPoint1(price, purchasePrice, `修改价格不应小于进货价${purchasePrice}`);
        discountRate = +formatIntPoint(discountRate, 100, '折扣不能高于100%')
        let newRow = {
            ...row,
            num,
            price: +formatNum(price),
            discountRate,
            materialAmount:+bigNumber.toFixed(bigNumber.times(Number(num),price),2),
            receivableAmount:+bigNumber.toFixed(bigNumber.times(bigNumber.times(Number(num),price),bigNumber.dividedBy( discountRate,100)),2),
            discountedPrice:+bigNumber.toFixed(bigNumber.times(price,bigNumber.dividedBy(discountRate ,100)),2),
            costAmount:+bigNumber.toFixed(bigNumber.times(Number(num),Number(costPrice)),2)
            // materialAmount: +formatNum(Number(num) * price),
            // receivableAmount: +formatNum(Number(num) * price * discountRate / 100),
            // discountedPrice: +formatNum(price * discountRate / 100),
            // costAmount: +formatNum(Number(num) * Number(costPrice)),
        }

        let newData = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        const index = newData.findIndex((item) => row.detailsId === item.detailsId);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...newRow });
        mode === "edit" ? setDetailMaterialEditList([...newData]) : setDetailMaterialAddList([...newData])
        setTopButsControl(true)
    }
    const typeMaterialList = {
        "add": [...detailMaterialAddList],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    //设置维修用料选中的行和数据
    const setTypeMaterialDetailDataRowKeysAndRow = async (selectedRowKeys: React.Key[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeMaterialDetailDataRow = async (selectedRows: IMaterialDetailList[], mode: string) => {
        if (mode === DetailType.add) {
            setDetailMaterialAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailMaterialEditDataRow(selectedRows)
        }
    }

    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowKey={record => record.detailsId as string}
                rowClassName='editable-row yh-edit-table-tr'
                columns={newSelectMaterialColums() as ColumnsType<IMaterialDetailList>}
                dataSource={typeMaterialList[mode] as IMaterialDetailList[]}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: mode === DetailType.add ? detailMaterialAddDataRowKeys : detailMaterialEditDataRowKeys, //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IMaterialDetailList[]) => {
                            setTypeMaterialDetailDataRowKeysAndRow(selectedRowKeys, mode)
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setTypeMaterialDetailDataRow(selectedRows, mode)
                        }
                    }}
                components={components}
                pagination={false}
                scroll={{ x: 'max-content', y: 300 }}
            />
        </YhBox>
    )
}