import React from "react";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { YhBox } from "@/components/YhBox";
import { YhPageHeader } from "@/components/YhPageHeader";
import { usePreSaleModel } from "./presaleModel";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { useDictModel } from '@/views/base/dict/dictModel';
import { DictCodeEnmu } from '@/types/VrmSaDictEnums';

export const PreSaleSearchBox = () => {
    const {
        searchParam, updateSearchParam, initPgCfg, resetSearchParam, loadData,setSelectedRowKeys
    } = usePreSaleModel();
    const { getSelectedDictOne } = useDictModel();
    const { CustomerData } = useGlobalModel();
    const searchFields = [
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "客户名称",
        //     fieldName: "customerCode",
        //     allowClear: true,
        //     showSearch: true,
        //     options: transOptions(CustomerData.filter((item: any) => item.categoryCode === "customer-type.transit")),
        //     xxl: 6,
        // },
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName",
            xxl: 6,
        },
         {
            type: SearchTypeEnum.Input,
            placeholder: "预售单号",
            fieldName: "orderCode",
            xxl: 3,
        }, 
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            options: getSelectedDictOne(DictCodeEnmu.PRESALE_ORDER),
            xxl: 3,
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单人",
            fieldName: "createUser",
            xxl: 3,
        },
        //  {
        //     type: SearchTypeEnum.Input,
        //     placeholder: "1商品编码",
        //     fieldName: "materialCode",
        //     xxl: 3,
        // }, 
        //{
        //     type: SearchTypeEnum.Input,
        //     placeholder: "公交编码",
        //     fieldName: "materialNo",
        //     xxl: 3,

        // }, {
        //     type: SearchTypeEnum.Input,
        //     placeholder: "商品名称",
        //     fieldName: "materialName",
        //     xxl: 3,
        // },
        {
            type: SearchTypeEnum.Input,
            placeholder: "备注",
            fieldName: "note",
            xxl: 3,
        },                {
            type: SearchTypeEnum.Input,
            placeholder: "发货单号",
            fieldName: "shipmentOrderNumber",
            xxl: 3,
        },
                {
            type: SearchTypeEnum.Input,
            placeholder: "订单单号",
            fieldName: "orderNumber",
            xxl: 3,
        },{
            type: SearchTypeEnum.Input,
            placeholder: "入库员",
            fieldName: "storekeeper",
            xxl: 3,
        }, {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["入库开始", "入库结束"],
            rangePickerFieldName: ["statWourchaseTime", "endWourchaseTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
    ]
    return (
            <YhPageHeader
                title="预售列表"
                justify="end"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params });setSelectedRowKeys([]); }}
                handleReset={resetSearchParam}
            />
    )
}