import { httpRequest } from "../../../utils/HttpRequest";
import { IReportFormField, IReportFormSearch,IPurchasePinSearch, IReportFormDetail,IModelList,IRepertVo,ISCMJxcDetailPurchasePin } from "./reportFormType"
import { IPage } from "@/types/ScmTypes";

class ReportFormService {
    async page(searchVo: IReportFormSearch) {
        const apiUrl = "/bcm/report/findPage";
        return await httpRequest.post<IReportFormDetail>(apiUrl, searchVo);
    }
    async total(searchVo: IReportFormSearch) {
        const apiUrl = "/bcm/report/findPage/total";
        return await httpRequest.post<IRepertVo>(apiUrl, searchVo);
    }
    async findByCode(searchVo: object) {
        const apiUrl = `/bcm/report/findByCode`;
        return await httpRequest.post<IModelList[]>(apiUrl,searchVo);
    }
    //导出
    async export(params: IReportFormSearch) {
        const apiUrl = "/bcm/report/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //详情明细列表
    async purchasePin(searchVo: IPurchasePinSearch){
        const apiUrl = "/bcm/stock/details/purchasePin";
        return await httpRequest.post<IPage<ISCMJxcDetailPurchasePin>>(apiUrl, searchVo);
    }
    //详情明细导出
    async purchasePinExport(params: IPurchasePinSearch) {
        const apiUrl = "bcm/stock/details/purchasePinExport";
        return await httpRequest.postFile(apiUrl, params);
    }
}

export const reportFormService = new ReportFormService();