
import React, { FC } from "react";
import { Button, Input, Table, message, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/lib/table";
import { useDeliveryDetailsModel } from "./deliveryDetailsModel"
import { RouterParamType } from "@/model/navModel";
import YhTag from "@/components/YhTag";
import { IMaterialDetailList } from "../../stockOutDispatchType"
import { useDictModel } from "@/views/base/dict/dictModel";
import { globalPrompt } from "@/components/message";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const MaterialList: FC<RouterParamType> = ({ id, mode }) => {
    const {
        detailEditForm,
        detailMaterialEditList,
        detailMaterialLookList,
        detailMaterialEditListRowKeys, setDetailMaterialEditListRowKeys,
        detailMaterialEditListRow, setDetailMaterialEditListRow,
        detailsUpdate,
        getDetailEditOne,
        stocke,
        storage,
        getOutEntry
    } = useDeliveryDetailsModel()
    const { getTreeNodeName } = useDictModel();

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 60,
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '状态',
            width: 100,
            align: "center",
            dataIndex: "status",
            render: (text) => text ? getTreeNodeName(text) : "备料前"
        },
        {
            title: '配件编号',
            width: 120,
            align: "center",
            dataIndex: 'materialCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        },
        {
            title: '配件名称',
            width: 140,
            align: "center",
            dataIndex: 'materialName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        },
        {
            title: '库存数量',
            width: 120,
            align: "center",
            dataIndex: 'inventoryNum',
        },
        {
            title: '使用数量',
            width: 120,
            align: "center",
            dataIndex: 'quantityNum',
        },
        {
            title: '已领数量',
            width: 120,
            align: "center",
            dataIndex: 'collectedNum',
        },
        {
            title: '待领数量',
            width: 120,
            align: "center",
            dataIndex: 'unclaimedNum',
        },
        {
            title: '退料数量',
            width: 120,
            align: "center",
            dataIndex: 'returnNum',
        },
        {
            title: '所属仓库',
            width: 120,
            align: "center",
            dataIndex: 'warehouseName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            title: '所属货位',
            width: 120,
            align: "center",
            dataIndex: 'shelfCode',
        },
        {
            title: '操作',
            width: 200,
            fixed: "right",
            align: "center",
            dataIndex: 'code',
            render: (text, record, index) => (
                mode !== "look" && <>
                    <YhTag onClick={() => { onItemComplte({ ...record, status: "details.preparation.accomplish" }) }}
                        disabled={record.status !== "details.preparation.of"}
                        color="cyan"
                        text={"备料完成"}></YhTag>
                    <YhTag onClick={() => onStocke([record])}
                        disabled={record.unclaimedNum === 0 || record.status !== "details.preparation.accomplish"}
                        color="blue"
                        text={"出库"}></YhTag>
                    <YhTag onClick={() => onReturnMaterial(record)}
                        // disabled={record.status !== "details.preparation.accomplish" || record.collectedNum === 0}
                        disabled={!(record.status === "details.preparation.ware.out") || record.quantityNum === 0}
                        color="gold"
                        text={"退料"}></YhTag>
                </>
            )
        },
        // ...((str) => {
        //     if(str==="look"){
        //         return []
        //     }else{
        //         return [
        //             {
        //                 title: '所属货位',
        //                 width: 120,
        //                 dataIndex: 'shelfCode',
        //             },
        //         ]
        //     }
        // })(mode),
    ];
    const onReturnMaterial = async (record: IMaterialDetailList) => {
        let { maintenanceCode, id } = detailEditForm
        let { num } = record
        let cause: string = ""
        let returnProducts: string = ""
        globalPrompt("modal", {
            width: 380,
            title: "退货",
            type: "info",
            // text: "",
            text: <>
                <span>退货数量：</span>
                <Input
                    onChange={(e) => { returnProducts = e.target.value }}
                />
                <span>退货原因：</span>
                <Input
                    onChange={(e) => { cause = e.target.value }}
                />
            </>,
            okText: "确定",
            onOk: () => {
                console.log(+returnProducts, num, +returnProducts > Number(num))

                var re = /^[0-9]+.?[0-9]*$/;
                return new Promise((resolve, reject) => {
                    if (!re.test(returnProducts)) {
                        reject("输入的值不是数字，请重新输入")
                        message.error("输入的值不是数字，请重新输入")

                    } else if (+returnProducts > Number(num)) {
                        reject("输入的值大于使用数量")
                        message.error("输入的值大于使用数量")
                    } else {
                        resolve(returnProducts)
                    }
                }).then(res => {
                    let newRecords = {
                        ...record,
                        returnProducts,
                        cause,
                    }
                    storage({ id, maintenanceCode: maintenanceCode as string, ids: [newRecords] }).then(res => {
                        if (res) {
                            message.success("退料成功")
                            getDetailEditOne(maintenanceCode as string, mode)
                        }
                    })

                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onStocke = async (record: IMaterialDetailList[]) => {
        let { maintenanceCode, id } = detailEditForm
        let retData = await stocke({ id, maintenanceCode: maintenanceCode as string, ids: record })
        if (retData) {
            message.success("出库成功")
            getDetailEditOne(maintenanceCode as string, mode)
        }
    }
    const onItemComplte = async (record: IMaterialDetailList) => {
        let { maintenanceCode } = detailEditForm
        let retData = await detailsUpdate({ maintenanceCode: maintenanceCode as string, ids: [record] })
        if (retData) {
            message.success("编辑成功")
            getDetailEditOne(id, mode)
        }
    }
    const startMaterial = async (status: string) => {
        if (detailMaterialEditListRow.length === 0) {
            message.error("请至少选择一条数据")
            return
        }
        let { maintenanceCode } = detailEditForm
        let newDetailMaterialEditListRow = detailMaterialEditListRow.map(item => {
            return {
                ...item,
                status
            }
        })
        let retData = await detailsUpdate({ maintenanceCode: maintenanceCode as string, ids: newDetailMaterialEditListRow })
        if (retData) {
            message.success("编辑成功")
            getDetailEditOne(id, mode)
            getOutEntry(id, mode)
        }
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => getDetailEditOne(id, mode)}
        >刷新</Button>
        {mode !== "look" && <>
            <YhAuth resCode={ResourceAuthEnum.ST_M_OUT_ED_PREPARE_M_S}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    disabled={!!detailMaterialEditListRow.find(item =>item.status === "details.preparation.of" || item.status === "details.preparation.accomplish")}//||
                    onClick={() => startMaterial("details.preparation.of")}
                >开始备料</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ST_M_OUT_ED_PREPARE_M_F}>
                <Tooltip placement="topRight" title="此功能按钮只针对于列表状态为备料中的数据进行操作">
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        disabled={!!!detailMaterialEditListRow.find(item => item.status === "details.preparation.of")}
                        onClick={() => startMaterial("details.preparation.accomplish")}
                    >备料完成</Button>
                </Tooltip>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.ST_M_OUT_ED_PINK_M}>
                <Tooltip placement="topRight" title="此功能按钮只针对于列表状态为备料完成的数据进行操作">
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        disabled={!!detailMaterialEditListRow.find(item => item.unclaimedNum === 0 || item.status !== "details.preparation.accomplish")}
                        onClick={() => onStocke(detailMaterialEditListRow)}
                    >全部出库</Button>
                </Tooltip>
            </YhAuth>
        </>}
    </>
    const typeMaterialList = {
        "add": [],
        "edit": [...detailMaterialEditList],
        "look": [...detailMaterialLookList],
    }
    return (
        <YhBox title={<></>} rightChildren={action}>
            <Table
                rowKey={record => record.id as string}
                pagination={false}
                rowSelection={
                    mode === "look" ? undefined : {
                        selectedRowKeys: detailMaterialEditListRowKeys,
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IMaterialDetailList[]) => {
                            console.log(selectedRowKeys, selectedRows)
                            setDetailMaterialEditListRowKeys(selectedRowKeys)
                            setDetailMaterialEditListRow(selectedRows)

                        },
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            setDetailMaterialEditListRow(selectedRows)
                        }
                    }
                }
                columns={columns}
                dataSource={typeMaterialList[mode]}
                scroll={{ x: 'max-content', y: 500 }}
            />
        </YhBox>
    )
}