import React, { useEffect } from "react";
import { Button, Input, Table, message, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useWarehouseModel } from "../../warehouseModel";
import { IVRMAreaDetail } from "../../warehouseTypes";
import { ColumnsType } from "antd/es/table";
import { AreaDrawer } from "./AreaDrawer";
import { importPropsConfig } from "@/utils/importFile";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";


//区域列表
export const AreaTable = () => {
    const {
        areaDataSource, setSelectedAreaRows,
        selectedAreaRows, areaLoadData, editFormVal,
        areaSearch, setAreaSearch, aeraFilter, downloadTplArea,
        setIAreaDrawer
    } = useWarehouseModel();

    useEffect(() => {
        areaLoadData();
    }, [])

    const action = <div className="flex justify-between">
        <div>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_AREA_AD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => setIAreaDrawer("add")}
                >新增</Button>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_AREA_ED}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => {
                        if (selectedAreaRows.length !== 1) {
                            message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！");
                            return;
                        }
                        setIAreaDrawer("edit");
                    }}
                >编辑</Button>
            </YhAuth>
            {/* <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => {
                    let file = new File(["First Line Text", "Second Line Text"], "excel");
                    importWarehouseArea(file);
                }}
            >导入</Button> */}
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_AREA_IM}>
                <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/base/warehouse/area/import/${editFormVal.id}`, areaLoadData)}>
                    <Button className="mr-5" type="link" shape="round">导入</Button>
                </Upload>
            </YhAuth>
            <YhAuth resCode={ResourceAuthEnum.DATA_W_ED_AREA_DLOAD}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={() => { downloadTplArea() }}
                >下载模板</Button>
            </YhAuth>
        </div>
        <div className="flex">
            <Input
                allowClear
                datatype="round"
                className="border-r-16"
                placeholder="区域编号"
                value={areaSearch}
                onChange={(e) => setAreaSearch(e.target.value)}
                onPressEnter={() => aeraFilter()}
            />
            <Button
                style={{ margin: "0px 12px 0px 15px" }}
                shape="round"
                onClick={() => aeraFilter()}
            >检索</Button>
            <Button
                style={{ margin: "0px 0px 0px 12px" }}
                shape="round"
                onClick={() => { setAreaSearch(""); aeraFilter(false) }}
            >重置</Button>

        </div>
    </div>;

    const columns: ColumnsType<IVRMAreaDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '区域编号',
            width: 180,
            align: "center",
            dataIndex: 'code',
        },
        {
            title: '区域名称',
            width: 180,
            align: "center",
            dataIndex: 'name',
        },
        {
            title: '所属仓库',
            width: 180,
            align: "center",
            dataIndex: 'warehouseName',
        },
        {
            title: '创建人',
            width: 180,
            align: "center",
            dataIndex: 'createUser',
        },
        {
            title: '创建时间',
            width: 180,
            align: "center",
            dataIndex: 'createTime',
        },
        {
            title: '备注',
            // width: 180,
            align: "center",
            dataIndex: 'note',
        }
    ];

    const onRow = (record: IVRMAreaDetail) => {
        let findArr = selectedAreaRows.find(item => item.id === record.id);
        if (findArr) {
            let filterArr = selectedAreaRows.filter(item => item.id !== record.id);
            setSelectedAreaRows(filterArr);
        } else {
            setSelectedAreaRows(origin => [...origin, record]);
        }
    };
    return (
        <>
            <YhBox action={action}>
                <Table
                    rowKey={record => record.id}
                    rowSelection={{
                        selectedRowKeys: selectedAreaRows.map(item => item.id), //记录选中
                        onChange: (selectedRowKeys: React.Key[], selectedRows: IVRMAreaDetail[]) => {
                            setSelectedAreaRows(selectedRows);
                        }
                    }}
                    columns={columns}
                    dataSource={areaDataSource}
                    // pagination={{
                    //     // ...areaPgCfg, 
                    //     position: ["bottomCenter"],
                    //     showTotal: () => `共${areaPgCfg.total}条`,
                    //     onChange: (pageNum, pageSize) => {
                    //         areaLoadData({ pageNum, pageSize })
                    //     }
                    // }}
                    pagination={false}
                    onRow={record => ({
                        onClick: () => onRow(record)
                    })}
                    scroll={{ x: 'max-content', y: 320 }}
                />
            </YhBox>
            <AreaDrawer />
        </>
    )
}

