import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { HistoryModel } from "../../../historyModel"
import { useStockStatisticModel } from "../stockStatisticModel";
import { OccupyStockModel } from "./OccupyStockModel"
import { RelocationModel } from "./RelocationModel"
import { TranRecord } from "./TranRecord"
import { AppintModel } from "./AppintModel"
import { PinModel } from "./PinModel"

export default () => {
    const {
        historyVisible, setHistoryVisible,
        historyRow,
        occupyStock, setOccupyStockModel,
        relocationVisible, setRelocationVisible,
        recordsVisible, setRecordsVisible,
        setRecordsSearch,
        pinRecord, setPinRecord,
        appintRecord,setAppintRecord
    } = useStockStatisticModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <OccupyStockModel
                visible={!!occupyStock}
                materialCode={occupyStock?.materialCode as string}
                onCancel={() => setOccupyStockModel(null)}
            />
            <RelocationModel
                visible={relocationVisible}
                onCancel={() => setRelocationVisible(false)}
            />
            <TranRecord
                visible={recordsVisible}
                onCancel={() => { setRecordsVisible(false); setRecordsSearch({}) }}
            />
            <AppintModel
                visible={!!appintRecord}
                materialCode={appintRecord?.materialCode as string}
                onCancel={() => setAppintRecord(null)}
            />
            <PinModel
                visible={!!pinRecord}
                materialCode={pinRecord?.materialCode as string}
                onCancel={() => setPinRecord(null)}
            />                        
        </>
    )
}