import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useSoListModel } from "../soListModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { exportSoList,descTotal } = useSoListModel();

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.STT_SL_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportSoList()}
            >导出</Button>
        </YhAuth>
    </>;
    const desc: IDescription[] = [
        {
            label: "销售数量",
            value: descTotal.totalNum,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售金额",
            value: descTotal.payAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "应收金额",
            value: descTotal.receivableAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "已收金额",
            value: descTotal.receivedAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "已付金额",
            value: descTotal.paidAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售成本",
            value: descTotal.costAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售毛利",
            value: descTotal.grossProfitAmount,
            color: DescriptionColorEnum.red
        },
    ]
    return (
        <YhBox title=" " action={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}