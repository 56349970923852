import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IMaintenanceDetail, IMaintenanceSearch } from "./maintenanceType";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { TablePaginationConfig } from "antd/es/table/interface";
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { maintenanceService } from "./maintenanceService";
import { message } from "antd";
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { convertRes2Blob } from "@/utils/exportFile";
export interface IDescTotal {
    manHourFee: number,
    discountTotalAmount: number, // 321.94
    receivableAmount: number, // 2390.3
    receivableTotalAmount: number, // 7970.01
    totalAmount: number, // 8291.94
    sumAmountReceivable: number
}
export const useMaintenanceModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const [print, setPrint] = useState<boolean>(false);
    const initDesc = {
        manHourFee: 0,
        discountTotalAmount: 0, // 321.94
        receivableAmount: 0, // 2390.3
        receivableTotalAmount: 0, // 7970.01
        totalAmount: 0, // 8291.94
        sumAmountReceivable: 0
    }
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IMaintenanceSearch = {};
    const initDataSource: IMaintenanceDetail[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    //查询条件
    const [searchParam, setSearchParam] = useState<IMaintenanceSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IMaintenanceDetail[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IMaintenanceDetail>({});
    const [descTotal, setDescTotal] = useState<IDescTotal>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IDescTotal>(initDesc);
    const [descRows, setDescRows] = useState<IMaintenanceDetail[]>([]);

    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IMaintenanceDetail)
    }, [selectedRowKeys, dataSource])
    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            let { workingHoursAmount, discountTotalAmount, receivableTotalAmount, receivableAmount, totalAmount, amountReceivable } = item
            newRowsDescTotal.manHourFee = bigNumber.add(newRowsDescTotal.manHourFee, formatNum(workingHoursAmount))
            newRowsDescTotal.discountTotalAmount = bigNumber.add(newRowsDescTotal.discountTotalAmount, formatNum(discountTotalAmount))
            newRowsDescTotal.receivableAmount = bigNumber.add(newRowsDescTotal.receivableAmount, formatNum(receivableAmount))
            newRowsDescTotal.receivableTotalAmount = bigNumber.add(newRowsDescTotal.receivableTotalAmount, formatNum(receivableTotalAmount))
            newRowsDescTotal.totalAmount = bigNumber.add(newRowsDescTotal.totalAmount, formatNum(totalAmount))
            newRowsDescTotal.sumAmountReceivable = bigNumber.add(newRowsDescTotal.sumAmountReceivable, formatNum(amountReceivable))
            // newRowsDescTotal.manHourFee += +formatNum(workingHoursAmount)
            // newRowsDescTotal.discountTotalAmount += +formatNum(discountTotalAmount)
            // newRowsDescTotal.receivableAmount += +formatNum(receivableAmount)
            // newRowsDescTotal.receivableTotalAmount += +formatNum(receivableTotalAmount)
            // newRowsDescTotal.totalAmount += +formatNum(totalAmount)
            // newRowsDescTotal.sumAmountReceivable += +formatNum(amountReceivable)

        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    const updateSearchParam = (updateParam: IMaintenanceSearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }

    const loadData = async (page?: ISearchPage, searchValue: IMaintenanceSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await maintenanceService.page(searchParams);
        const { records, statistics, ...pg } = retData;
        setDescTotal(statistics)
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records);
    }
    const invalidateData = async (obj: object) => {
        let { retData } = await maintenanceService.invalidate(obj)
        if (retData) {
            message.success("作废成功");
            loadData();
        }
    }
    const tran = async (obj: object) => {
        let { retData } = await maintenanceService.tran(obj)
        loadData();
        return retData
    }

    const exportMaintenance = async (searchValue: IMaintenanceSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await maintenanceService.export(searchParams);
        convertRes2Blob(result)
    }
    const [scaniaPrint, setScaniaPrint] = useState<boolean>(false);
    const [settleScaniaPrint, setSettleScaniaPrint] = useState<boolean>(false);
    const detailsExport = async (searchValue: IMaintenanceSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await maintenanceService.detailsExport(searchParams);
        convertRes2Blob(result)
    }

    return {
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        initPgCfg,
        setSearchParam,
        setDataSource,
        setSelectedRowKeys,
        setSelectedRow,
        setPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData,
        invalidateData,
        tran,
        print, setPrint,
        exportMaintenance,
        descTotal, setDescTotal,
        scaniaPrint, setScaniaPrint,
        settleScaniaPrint, setSettleScaniaPrint,
        detailsExport,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows
    }
})