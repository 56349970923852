import React, { useEffect } from "react";
import { Button, Dropdown, Input, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useSaleOrderModel } from "../saleOrderModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useSaleOrderDetailModel } from "./details/saleOrderDetailModel";
import { DownOutlined } from "@ant-design/icons";
import useGlobalModel from "@/model/globalModel";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { selectedRowKeys, selectedRow, descTotal, rowsDescTotal, print,setPrint,detailsExport, onInvalidate, exportSaleOrder, setSelectedRowKeys } = useSaleOrderModel();
    const {
        resetEditCatch,
    } = useSaleOrderDetailModel();
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const desc: IDescription[] = [
        {
            label: "订单数量",
            value: selectedRowKeys.length === 0 ? descTotal.totalNum : (+formatNum(rowsDescTotal.totalNum)).toFixed(0),
            color: DescriptionColorEnum.red
        },
        {
            label: "订单总金额",
            value: selectedRowKeys.length === 0 ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            color: DescriptionColorEnum.red
        }
    ]
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale-order.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale-order.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        let saleOrderEditKey = "5-1-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow?.saleOrderCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "销售订单跳转详情",
                type: "warning",
                text: "您还有销售订单详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow?.saleOrderCode as string, mode: "edit" });
                    resetEditCatch();
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "5-1-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow?.saleOrderCode as string, mode: "look", },
            true
        );
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleOrder()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }

    // 作废
    const invalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "销售订单作废",
            type: "error",
            text: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id as string,
                    saleOrderCode: selectedRow.saleOrderCode as string,
                    cancelReason
                }
                onInvalidate(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.S_SO_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav('5-1-1', { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_SO_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_SO_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onPrint}
        >打印</Button>
        <YhAuth resCode={ResourceAuthEnum.S_SO_CANCEL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={invalidate}
            >作废</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.S_SO_EX}>
            <Dropdown overlay={exportMenu}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                >导出<DownOutlined /></Button>
            </Dropdown>
        </YhAuth>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportSaleOrder().then()}
        >导出</Button> */}
    </>;

    return (
        <YhBox title={"销售订单列表"} descriptions={desc} action={action}>
            <DataTable />
        </YhBox>
    )
}