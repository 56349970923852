import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { usePoReportModel } from "../poReportModel";
import { IVRMPoReportDetail } from "../poReportService";
import { formatNum } from "@/utils/utils";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = usePoReportModel();

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IVRMPoReportDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '供应商名称',
            fixed: "left",
            width: 160,
            dataIndex: 'supplierName',
            render: (text: any) => <YhTootip text={text} />,
            //render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '供应商类型',
            fixed: "left",
            width: 150,
            dataIndex: 'supplierTypeName',
        }, {
            title: '单据编号',
            fixed: "left",
            width: 180,
            dataIndex: 'code',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '单据类型',
            fixed: "left",
            width: 100,
            dataIndex: 'tradeTypeName',
        }, {
            title: '采购类型',
            width: 120,
            dataIndex: 'orderTypeName',
        }, {
            title: '结算类型',
            width: 120,
            dataIndex: 'payTypeName',
        }, {
            title: '商品编号',
            width: 200,
            dataIndex: 'materialCode',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '商品名称',
            width: 200,
            dataIndex: 'materialName',
            render: (text: any) => <YhTootip text={text} />,
           // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '商品类型',
            width: 140,
            dataIndex: 'typeName',
        },
        {
            title: '采购价格',
            width: 140,
            dataIndex: 'price',
            render: text =>'￥'+ Math.abs(text ?? 0)
            // render: (text) => `￥${text}`

        }, {
            title: '采购数量',
            width: 140,
            dataIndex: 'num',
            // render: (text: number, record) => record.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_PRO ? `-${text}` : `${text}`
        }, {
            title: '采购金额',
            width: 140,
            dataIndex: 'amount',
            // render: (text: number, record) => record.tradeTypeCode === DictCodeEnmu.TRADE_TYPE_PRO ? `-￥${text}` : `￥${text}`
        }, 
        {
            title: '配件采购频次',
            width: 100,
            dataIndex: 'purchaseCount',
        }, {
            title: '配件采退频次',
            width: 100,
            dataIndex: 'preturnCount',
        }, {
            title: '制单人',
            width: 160,
            dataIndex: 'createUser',
        }, {
            title: '制单时间',
            width: 160,
            dataIndex: 'createTime',        
        }, {
            title: '结算时间',
            width: 160,
            dataIndex: 'settleTime',                      
        }, {
            title: '从属门店',
            width: 160,
            dataIndex: 'shopName',
        // }, {
        //     title: '结算日期',
        //     width: 160,
        //     dataIndex: 'settleTime',
        }, {
            title: '备注',
            width: 200,
            dataIndex: 'note',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id)
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}