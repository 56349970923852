import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { salesReortService, ISalesReortSearchFields, ISalesReortDetail } from "./salesReortService";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { transPgToPagination } from "@/utils/utils";


export const useSalesReortModel = createModel(function () {
    const { shopAndOrg: { shopId } } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<ISalesReortSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<ISalesReortDetail[]>([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [loading, setLoading] = useState<boolean>(false);
    const updateSearchParam = (updateParam: ISalesReortSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: ISalesReortSearchFields = searchParam) => {
        const searchParams = {
  
            ...searchValue,
            shopId,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await salesReortService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading(false);
    }
    const onExport = async () => {
        const searchParams = {
            pageSize: -1,
            pageNum: 1,
            ...searchParam,
            shopId,
            id: selectedRowKeys.join(),
        }
        let result = await salesReortService.export(searchParams);
        convertRes2Blob(result)
    }


    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg, loading,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        onExport
    }
});


