import { httpRequest } from "../../../utils/HttpRequest";
import { IStockInAndOutField,ITotalVo, IStockInAndOutSearch,IStockInAndOutDetail } from "./stockInAndOutType"
import { IPage } from "@/types/ScmTypes";
class StockInAndOutService {
    async page(searchVo: IStockInAndOutSearch) {
        const apiUrl = "/bcm/stock/details/details/findPage";
        return await httpRequest.post<IPage<IStockInAndOutDetail>>(apiUrl, searchVo);
    }
    async statistics(searchVo: IStockInAndOutSearch) {
        const apiUrl = "/bcm/stock/details/statistics";
        return await httpRequest.post<ITotalVo>(apiUrl, searchVo);
    }
    async export(searchVo: IStockInAndOutSearch) {
        const apiUrl = "/bcm/stock/details/export";
        return await httpRequest.postFile(apiUrl, searchVo);
    }
}
export const stockInAndOutService = new StockInAndOutService();