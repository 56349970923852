import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { usePurchasePoModel } from "../purchasePoModel";
import {
    IPurchasePoDetail,
} from "../purchasePoType"
import YhTag from "@/components/YhTag";
import { useDictModel } from "@/views/base/dict/dictModel";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";

type statusType = {
    "purchase-status.create": string,
    "purchase.completely-io": string,
    "purchase-status.bill": string,
    "purchase.change": string,
    "purchase-status.write-off": string,

    [key: string]: any
}


export function DataTable() {
    const { dataSource, selectedRowKeys, pgCfg,descRows, loadData, setSelectedRowKeys,setDescRows,setHistoryRow,setHistoryVisible } = usePurchasePoModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "11-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    useEffect(() => {
        loadData().then();
    }, [])
    const statusColor: statusType = {
        "purchase-status.create": "blue",
        "purchase.completely-io": "cyan",
        "purchase-status.bill": "green",
        "purchase.change": "red",
        "purchase-status.write-off": "geekblue",

    }

    const onHistoryModel = (record: IPurchasePoDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }

    const columns: ColumnsType<IPurchasePoDetail> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '采购单号',
            width: 200,
            fixed: "left",
            dataIndex: 'purchaseCode',
            // render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => {e.stopPropagation();onLook(text)}}>{text}</p></Tooltip>,
            //render: (text: any,record:any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" style={{color:record.status=='pur-replenish-status.replenish'?'red':'blue'}} onClick={(e) => {e.stopPropagation();onLook(text)}}>{text}</p></Tooltip>,
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() =>{
                let supplierEditKey = "11-2-3";
                replaceNav(
                    selectedNavId,
                    supplierEditKey,
                    { id: record.purchaseCode as string, mode: "look", },
                    true
                );
                onHistoryModel(record)}}/>,
        }, {
            title: '调拨单号',
            width: 200,
            fixed: "left",
            dataIndex: 'allocationCode',
            render: (text: any) => <YhTootip text={text} />,
            //render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '支付状态',
            width: 70,
            dataIndex: 'payStatus',
            render: (text) => getTreeNodeName(text)            
        }, {
            title: '采购类型',
            width: 100,
            dataIndex: 'purchaseTypeName',
        }, {
            title: '商品类型',
            width: 80,
            dataIndex: 'materialTypeName',

        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payType',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '结算方式',
            width: 80,
            dataIndex: 'payMethodName',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '采购数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '到货数量',
            width: 80,
            dataIndex: 'arrivedNum',
        }, {
            title: '损坏数量',
            width: 80,
            dataIndex: 'damageTotalNum',
        }, {
            title: '入库数量',
            width: 80,
            dataIndex: 'stockIoNum',

        }, {
            title: '已付金额',
            width: 80,
            dataIndex: 'paidAmount',
            render:(text)=>`￥${text??0}`,
        }, {
            title: '退货数量',
            width: 80,
            dataIndex: 'returnTotalNum',
        }, {
            title: '发票类型',
            width: 80,
            dataIndex: 'invoiceTypeName',

        }, {
            title: '联系人',
            width: 150,
            dataIndex: 'contact',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '电话',
            width: 95,
            dataIndex: 'phone',
        }, {
            title: '制单员',
            width: 60,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '库管',
            width: 60,
            dataIndex: 'storekeeper',
        }, {
            title: '入库日期',
            width: 130,
            dataIndex: 'stockIoTime',
            render: (text) => momentFormat(text)
        }, {
            title: '结算员',
            width: 60,
            dataIndex: 'reckoner',
        }, {
            title: '结算日期',
            width: 130,
            dataIndex: 'settleTime',
            render: (text) => momentFormat(text)
        }, {
            title: '核销日期',
            width: 130,
            dataIndex: 'writeOffTime',
            render: (text) => momentFormat(text)
        }, {
            title: '作废原因',
            width: 120,
            dataIndex: 'cancelReason',

        }, {
            title: '采购计划号',
            width: 120,
            dataIndex: 'planCode',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '供应商',
            width: 150,
            dataIndex: 'supplierName',
            render: (text: any) => <YhTootip text={text} />,
           // render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
            fixed:"right"
        }, {
            title: '采购金额',
            width: 100,
            dataIndex: 'payableAmount',
            render:(text)=>`￥${text}`,
            fixed:"right"
        },{
            title: '备注',
            width: 160,
            dataIndex: 'note',
            fixed:"right",
            render: (text: any) => <YhTootip text={text} />,

        }
    ];
    //点击行选高亮
    const onRow = (record: IPurchasePoDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const onRightClick = (record: IPurchasePoDetail)=>{
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IPurchasePoDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    // setSelectedRowKeys([record.id as string]);
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
