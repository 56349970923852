import React, { FC, useState } from "react";
import { YHModal } from "@/components/YHModal";
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";
import { YhBox } from "@/components/YhBox";
import { Table, Select, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { ISelectOptions } from "@/types/ScmTypes";
import { warehouseService } from "@/views/base/warehouse/warehouseService";
import { IVRMshelfDetail, IVRMShelvesType } from "@/views/base/warehouse/warehouseTypes";
import { RouterParamType } from '@/model/navModel';
import { ISelectOption } from '@/components/YhFormBox/Types';

export const AddTempShelfModal: FC<RouterParamType> = ({ id, mode }) => {

	const {
		addTempShelfModal: visible,
		setAddTempShelfModal,
		editGoodsList,
		addGoodsList,
		setEditGoodsList,
		setAddGoodsList
	} = usePurchaseDrDetailsModel();
	let goodsList = mode === "add" ? addGoodsList : editGoodsList;
	const { warehouseList, user: { shopId } } = useGlobalModel(({ warehouseList, user }) => [warehouseList, user]);

	const [shelfInfo, setShelfInfo] = useState<IVRMShelvesType | null>(null);  //记录选中的货位信息
	const [whInfo, setWhInfo] = useState<IVRMShelvesType | null>(null);  //记录选中的仓库信息
	const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([]);
	const onCancel = () => {
		setAddTempShelfModal(false);
		setShelfInfo(null);
	};

	//仓库列表 Api
	const shelfLoadData = async (whObj: ISelectOption) => {
		let params = {
			pageSize: 10000,
			pageNum: 1,
			warehouseCode: whObj.value,
		}
		const { retData } = await warehouseService.shelfAllPage(params)
		const newRecords = retData.map((item: IVRMshelfDetail) => { return { ...item, name: item.code } })
		setShelfAllList(newRecords)
		setWhInfo({
			warehouseCode: whObj.value as string,
			warehouseName: whObj.label as string
		})
	}

	// 货位变化
	const shelfChange = (shelfObj: ISelectOption) => {
		setShelfInfo({
			shelfCode: shelfObj.value as string,
		})
	}

	const onSubmit = () => {
		if (!whInfo) {
			message.warning("请选择仓库");
			return;
		}
		if (!shelfInfo) {
			message.warning("请选择货位");
			return;
		}
		if (mode === 'add') {
			setAddGoodsList(addGoodsList => addGoodsList.map(item => {
				if (!item.inShelfCode) {
					return {
						...item,
						inShelfCode: shelfInfo.shelfCode,   //货位编码
						inWarehouseName: whInfo.warehouseName,   //仓库名称
						inWarehouseCode: whInfo.warehouseCode,  //仓库编码
					}
				};
				return item;
			}));
		} else {
			setEditGoodsList(editGoodsList => editGoodsList.map(item => {
				if (!item.inShelfCode) {
					return {
						...item, 
						inShelfCode: shelfInfo.shelfCode,   //货位编码
						inWarehouseName: whInfo.warehouseName,   //仓库名称
						inWarehouseCode: whInfo.warehouseCode,  //仓库编码
					}
				};
				return item;
			}));
		}
		onCancel();
	}

	const columns: ColumnsType<any> = [
		{
			title: '序号',
			width: 40,
			align: "center",
			render: (t: any, r: any, i: number) => i + 1
		}, {
			title: '商品编号',
			align: "center",
			dataIndex: 'materialCode',
		}, {
			title: '商品名称',
			align: "center",
			dataIndex: 'materialName',
		}
	];

	const rightChildren = <div className="flex justify-center translateX-70">
		<span className="label-name">仓库：</span>
		<Select
			style={{ width: 260 }}
			options={transOptions(warehouseList.filter((item: any) => item.type !== 'warehouse-category.small'))} //过滤掉直发库
			placeholder="请选择仓库"
			onChange={(value: string, option) => {
				value && shelfLoadData(option as ISelectOption)
			}}
		/>
		<Select
			style={{ width: 260 }}
			options={transOptions(shelfAllList)} //过滤掉直发库
			placeholder="请选择货位"
			onChange={(value: string, option) => {
				value && shelfChange(option as ISelectOption)
			}}
		/>
	</div>



	return <YHModal
		visible={visible}
		onOk={onSubmit}
		onCancel={onCancel}
		title="添加临时货位"
		width={800}
		bodyHeight={500}
		showFooter={true}
	>
		<YhBox title={rightChildren} style={{ marginBottom: "15px" }}>
			<Table
				columns={columns}
				dataSource={goodsList.filter(item => !!!item.shelfCode)}
				pagination={false}
				scroll={{ x: 'max-content', y: 400 }}
			/>
		</YhBox>
	</YHModal>
}