import React from "react";
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { Print } from "./Print"
import { useMaintenanceModel } from "../maintenanceModel";
import { ScaniaPrints } from "./scaniaPrint"
import { SettleScaniaPrints } from "./settleScaniaPrints"

export default () => {
    const {
        print,
        scaniaPrint,
        settleScaniaPrint, 
    } = useMaintenanceModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            {print && <Print />}
            {scaniaPrint && <ScaniaPrints />}
            {settleScaniaPrint && <SettleScaniaPrints />}

        </>
    )
}