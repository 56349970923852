import { httpRequest } from "../../../utils/HttpRequest";
import { IPurchasePlanSearch, IPurchasePlanDetail, IPurchasePlanFields,  Detailed,  IPurchasePlanList } from "./purchasePlanType"
class PurchasePlanService {
    async page(searchVo: IPurchasePlanSearch) {
        const apiUrl = "/bcm/purchase/plan/findPage";
        return await httpRequest.post<IPurchasePlanList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/purchase/plan/findById/${id}`;
        return await httpRequest.get<IPurchasePlanDetail>(apiUrl);
    }

    async insert(insertVo: IPurchasePlanFields) {
        const apiUrl = "/bcm/purchase/plan/insert";
        return await httpRequest.post<IPurchasePlanDetail>(apiUrl, insertVo);
    }
    async update(updateVo: IPurchasePlanFields) {
        const apiUrl = "/bcm/purchase/plan/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/bcm/purchase/plan/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: IPurchasePlanSearch) {
        const apiUrl = "/bcm/purchase/plan/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //转采购单
    async transfer(planCode: string) {
        const apiUrl = `/bcm/purchase/plan/transfer/${planCode}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
    // //详情导入
    // async import(){
    //     const apiUrl = "/bcm/purchase/plan/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/bcm/purchase/plan/detail/downloadTpl";
        return await httpRequest.getFile<boolean>(apiUrl);
    }

}

export const purchasePlanService = new PurchasePlanService();