import React, { useEffect, useState } from "react";
import { useTransferMonitorModel } from "../transferMonitorModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { DictCodeEnmu } from "@/types/ScmDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel from "@/model/globalModel";
import { ISelectOption } from "@/components/YhFormBox/Types";

export function PageHeader() {
    const { getSelectedDictOne } = useDictModel(() => []);
    const { fetchFmsShopList } = useGlobalModel();
    const [shopList, setShopList] = useState<ISelectOption[]>([])
    useEffect(() => {
        fetchFmsShopList('').then(arr => {
            setShopList(arr)
        })
    }, [])

    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useTransferMonitorModel();

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "调拨单号",
            fieldName: "code"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "业务单据编号",
            fieldName: "saleCode"
        },
        // {
        //     type: SearchTypeEnum.Select,
        //     placeholder: "调入门店",
        //     fieldName: "shopCode",
        //     options: shopList
        // },
        {
            type: SearchTypeEnum.Select,
            placeholder: "调出门店",
            fieldName: "outShopCode",
            options: shopList
        },
    ]
    return (
        <YhPageHeader
            title="调入调出监测"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}