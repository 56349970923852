import React, { useState, useRef } from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { AddGoodsModal } from "./AddGoodsModal";
import { SettleDrawer } from "./SettleDrawer";
import { ChangeSaleDrawer } from "./changeSaleDrawer";
import { WhConfirmDrawer } from "./WhConfirmDrawer";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { IYhFormRef } from "@/components/YhFormBox/Types";
import { HistoryModel } from "@/views/historyModel";
import { usePurchasePoDetailsModel } from "./purchasePoDetailsModel";
import { SupplierHistoryModel } from "@/views/commonModule/supplierHistoryModel";
export const PoDetails = () => {
    const { getSelectedNavState } = useNavModel();
    const {
        historyVisible, setHistoryVisible,
        historyRow,
        supplierHistoryModel,
        supplierHistoryVisible, setSupplierHistoryVisible,
    } = usePurchasePoDetailsModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const IFormRef = useRef<IYhFormRef>();
    return (
        <>
            <TopButs {...detailsInfo} IFormRef={IFormRef} />
            <FormBox {...detailsInfo} IFormRef={IFormRef} />
            <TableBox {...detailsInfo} />
            <FixedBottom {...detailsInfo} />
            <AddGoodsModal {...detailsInfo} />
            <SettleDrawer {...detailsInfo} />
            <ChangeSaleDrawer />
            <ChangeSaleDrawer />
            <WhConfirmDrawer {...detailsInfo} />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow.materialCode as string}
                materialName={historyRow.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
            <SupplierHistoryModel
                visible={supplierHistoryVisible}
                name={supplierHistoryModel.supplierName}
                onCancel={() => setSupplierHistoryVisible(false)}
            />
        </>
    )
}