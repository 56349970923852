import React, { useEffect, useState } from 'react';
import { Spin, Modal } from 'antd';

export default ({ visible = false, onCancel = () => {}, md5Id, fileName, filePath, fileType }) => {
	const [loading, setLoading] = useState(true);
	const [pdfPage, setPdfPage] = useState(1);
	const [fileInfo, setFileInfo] = useState({
		file: null, //文件
		fileType: '', //文件类型
		pdfPageNum: 1, //pdf页数
		fileName: '', //文件名,
		filePath: '', //服务器文件名
	});

	const fileTypeArr = ['pdf', 'docx', 'png', 'jpg', 'gif'];

	const iframeUrl = `https://view.officeapps.live.com/op/view.aspx?src=https://file-server.laoniuche.cn/file/fetch?id=${md5Id}`;
	// const iframeUrl = `https://file-server.laoniuche.cn/file/fetch?id=${md5Id}`;
	useEffect(() => {
		let oIframe = document.getElementById('file_frame');
		console.log('预览路径', filePath, fileType);
		try {
			oIframe.src = iframeUrl;
			// oIframe.src = filePath;
			if (!(/*@cc_on!@*/ 0)) {
				//if not IE
				oIframe.onload = function () {
					setLoading(false);
				};
			} else {
				oIframe.onreadystatechange = function () {
					if (oIframe.readyState == 'complete') {
						setLoading(false);
					}
				};
			}
		} catch (err) {
			setLoading(false);
		}
	}, [visible]);

	return (
		<Modal
			visible={visible}
			title="文件预览"
			footer={null}
			width={'100%'}
			style={{ top: '15px' }}
			bodyStyle={{ minHeight: '300px', maxHeight: 'calc(100vh - 84px)', padding: '10px',display: 'flex' }}
			onCancel={() => onCancel()}
		>
			{(fileType === 'jpeg') | (fileType === 'png') | (fileType === 'jpg') | (fileType === 'gif') | (fileType === 'svg') | (fileType === 'jpeg') ? (
				<div style={{ width: '100%' }}>
					<img style={{ display: 'inherit',margin: '0 auto' }} height="100%" src={filePath} alt="" />
			</div>
			) : fileType === 'pdf' ? (
				<div>
					<object data={filePath} type="application/pdf" width="100%" height="100%">
						<iframe src={filePath} width="100%" height="100%"></iframe>
					</object>
				</div>
			) : (
				<Spin spinning={loading} tip="文件加载中..." wrapperClassName="width-100">
							<div
								style={{flex: 1}}
						onContextMenu={(e) => {
							e.preventDefault();
						}}
					>
						{filePath && (
							<iframe
								id="file_frame"
								// src={`./iframe.html?openUrl=${iframeUrl}`}
								// src={iframeUrl}
								scrolling="yes"
								frameBorder="0"
								style={{
									width: '100%',
									height: 'calc(100vh - 120px)',
									overflow: 'visible',
									border: '1px solid #aaa',
								}}
							></iframe>
						)}
					</div>
				</Spin>
			)}
		</Modal>
	);
};
