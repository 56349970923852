import { useState,useEffect } from "react";
import { createModel } from "hox";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { getRandomStr, transPgToPagination } from "@/utils/utils";
import { TablePaginationConfig } from "antd/lib/table";
import {IPsbusiDetail, IStatistics,IPsibusSearchFields,psiBusService} from "./psibusService"

export const usePsiBusModel = createModel(function(){
    const { shopAndOrg, setLoadingInfo } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initStatistics = {
        differenceNumTotal: 0,
        occupyDifferenceTotal: 0,
        currentNumTotal: 0,
    }
     //列表数据源
     const [dataSource, setDataSource] = useState<IPsbusiDetail[]>([]);
     //列表数据源分页参数
     const [descTotal, setDescTotal] = useState<IStatistics>(initStatistics);
     const [loading, setLoading] = useState<boolean>(false);
     const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
     //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IPsibusSearchFields>({});


    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IPsibusSearchFields = searchParam) => {
        setLoading(true)
        try {
            const searchParams = {
                shopId: shopAndOrg.shopId,
                ...searchValue,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
         
            let { retData } = await psiBusService.page(searchParams);
            let { retData: Data } = await psiBusService.statistics(searchParams);
            const { records, ...pg } = retData;
            setDataSource(records.map((item: any)=> ({
                ...item,
                key: getRandomStr()
            })));
            setPgCfg(transPgToPagination(pg));
            setLoading(false)
            setDescTotal(Data);
        } catch {
            setLoading(false);
            setDataSource([])
        }
    }

    const updateSearchParam = (updateParam: IPsibusSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

     //重置
     const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

     //导出
     const exportPsi = async (searchValue: IPsibusSearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                shopId: shopAndOrg.shopId,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
            }
            let result = await psiBusService.export(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
    }



     return {
        /***field***/
        dataSource,
        descTotal,
        loading,
        selectedRowKeys,
        searchParam,
        pgCfg,
        /***method***/
       
        setDescTotal,
        setDataSource,
        setSelectedRowKeys,
        setSearchParam,
        loadData,
        updateSearchParam,
        resetSearchParam,
        exportPsi
        
       
    }
})