import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, message } from "antd";
import { PreWithdrawalDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePreWithdrawalModel } from "./preWithdrawalModel";
import { formatNum } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
export const PreWithdrawalDataBox = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        exportSaleSo
    } = usePreWithdrawalModel()
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-5-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.returnCode as string, mode: "look", },
            true
        );
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportSaleSo()}
        >
            导出
        </Button>
    </>
    const desc: IDescription[] = [
        {
            label: "预退数量",
            value: selectedRowKeys.length === 0 ? descTotal?.returnNum??0 : (rowsDescTotal?.returnNum??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "预退金额",
            value: selectedRowKeys.length === 0 ? descTotal?.returnAmount??0 : formatNum(rowsDescTotal?.returnAmount??0),
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <YhBox title="预退记录" action={action} descriptions={desc}>
            <PreWithdrawalDataTable />
        </YhBox>
    </>
}