//所有积压等级参数
export interface IOverStockField {
    id?: string, // "548c64c3c8d8741764ca783e2e55ca2b",
    code?: string, // "overstock_grade",
    orderNum?: number, // 0,
    createTime?: string, // "2021-06-22 2?:string , //0?:string , //12",
    updateTime?: string, // "2021-10-24 1?:string , //5?:string , //38",
    createUser?: string, // "管理员",
    updateUser?: string, // "周晓凤",
    shopCode?: string, // "SHOP-M-00000001",
    name?: string, // "二级",(等级)
    saleInventoryRatio?: number, // 1,(销存率)
    overstock?: number, // 9,(积压标记)
    pcode?: string, // "overstock_grade",
    status?: number, // 1,
    note?: string, // "2",
    deleteFlag?: number, // 0
}
// 查询销售/采购积压提醒详情
export interface IRemindField {
    id?: string, // "2832fa6a75ef5cde4beb4e64969a7e48",
    code?: string, // "overstock_sale",
    orderNum?: string, // 0,
    createTime?: string, // "2021-06-22",
    updateTime?: string, // "2021-10-",
    createUser?: string, // "管理员",
    updateUser?: string, // "周晓凤",
    shopCode?: string, // "SHOP-M-00000001",
    name?: string, // "销售单积压处理",
    saleInventoryRatio?: number, // "销存率",
    overstock?: number, // "积压标记",
    pcode?: string, // "overstock",
    status?: string, // 1,
    note?: string, // null,
    deleteFlag?: string, // 0
}
// 积压件定义详情
export interface IDefiniyionField {
    id?: string, // "2832fa6a75ef5cde4beb4e64969a7e48",
    code?: string, // "overstock_sale",
    orderNum?: number, // 0,
    createTime?: string, // "2021-06-22",
    updateTime?: string, // "2021-10-",
    createUser?: string, // "管理员",
    updateUser?: string, // "周晓凤",
    shopCode?: string, // "SHOP-M-00000001",
    name?: string, // "销售单积压处理",
    saleInventoryRatio?: number, // "销存率",
    overstock?: number, // "积压标记",
    pcode?: string, // "overstock",
    status?: number, // 1,
    note?: string, // null,
    deleteFlag?: number, // 0
}
export type IRemindSearch = IRemindField ;
export type IDefiniyionSearch = IDefiniyionField 

export enum IoverstockEnum {
    overStock = "overstock",
    overStock_SO = "overstock_sale",  //销售单积压处理
    overStock_PO = "overstock_purchase",  //采购积压处理
    overStock_DEFIND = "overstock_definition", //积压件定义
    overStock_GRADE = "overstock_grade", //积压等级设置
}
export interface ISCMSettingDetail {
    title:string,
    key:string,
    children:ISCMSettingDetail[],
}