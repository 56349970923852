import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import React, { useEffect, useState } from "react";
import { PaginationProps } from "antd/es/pagination";
import {
    DEFAULT_FIRST_PAGE_NUM,
    DEFAULT_PAGE_SIZE,
} from "@/utils/constants";
import {
    IReportFormField,
    IReportFormSearch,
    IRepertVo,
    IPurchasePinSearch
} from "./reportFormType"
import { reportFormService } from "./reportFormService"
import useGlobalModel from "@/model/globalModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { convertRes2Blob } from "@/utils/exportFile";
import { bigNumber } from "@/utils/utils";


export interface IMdBeSearchField {
    code?: string;
    name?: string;
    corporation?: string;
    phone?: string;
    address?: string;
    bank?: string;
    bankAccount?: string;
}
export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

export const useReportFormModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initSearchParam: IReportFormSearch = {};
    const initDataSource: IReportFormField[] = [];
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initDesc: IRepertVo = {
        endSumCount: 0,
        endSumMoney: 0,
        startSumCount: 0,
        startSumMoney: 0,
        inSumCount: 0, //入库总数
        outSumCount: 0, //出库总数
        inSumMoney: 0, //入库总金额
        outSumMoney: 0, //出库总金额
    }

    //查询条件
    const [searchParam, setSearchParam] = useState<IReportFormSearch>(initSearchParam);
    //列表数据源
    const [dataSource, setDataSource] = useState<IReportFormField[]>(initDataSource);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState<IReportFormField>({});
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //单据汇总
    const [descTotal, setDescTotal] = useState<IRepertVo>(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState<IRepertVo>(initDesc);
    const [descRows, setDescRows] = useState<IReportFormField[]>([]);
    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item, index) => {
            let { startCount, startMoney, endCount, endMoney, nowInCount, nowInCost, nowOutCount, nowOutCost } = item
            newRowsDescTotal.startSumCount = bigNumber.add(newRowsDescTotal.startSumCount, startCount as number)
            newRowsDescTotal.startSumMoney = bigNumber.add(newRowsDescTotal.startSumMoney, startMoney as number)
            newRowsDescTotal.endSumCount = bigNumber.add(newRowsDescTotal.endSumCount, endCount as number)
            newRowsDescTotal.endSumMoney = bigNumber.add(newRowsDescTotal.endSumMoney, endMoney as number)

            newRowsDescTotal.inSumCount = bigNumber.add(newRowsDescTotal.inSumCount, nowInCount as number)
            newRowsDescTotal.outSumCount = bigNumber.add(newRowsDescTotal.outSumCount, nowInCost as number)
            newRowsDescTotal.inSumMoney = bigNumber.add(newRowsDescTotal.inSumMoney, nowOutCount as number)
            newRowsDescTotal.outSumMoney = bigNumber.add(newRowsDescTotal.outSumMoney, nowOutCost as number)
            // newRowsDescTotal.startSumCount += startCount as number
            // newRowsDescTotal.startSumMoney += startMoney as number
            // newRowsDescTotal.endSumCount += endCount as number
            // newRowsDescTotal.endSumMoney += endMoney as number

            // newRowsDescTotal.inSumCount += nowInCount as number
            // newRowsDescTotal.outSumCount += nowInCost as number
            // newRowsDescTotal.inSumMoney += nowOutCount as number
            // newRowsDescTotal.outSumMoney += nowOutCost as number

        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData as IReportFormField)
    }, [selectedRowKeys])


    const [drawerVisible, setDrawerVisible] = useState(false);

    const updatePgCfg = (updateParam: TablePaginationConfig) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam: IMdBeSearchField) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam);
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }
    const loadData = async (page?: ISearchPage, searchValue: IReportFormSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopId: shopAndOrg.shopId,
            // ...sourceAppOrBranch,
            page: {
                pageSize: 10,// page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await reportFormService.page(searchParams);
        let { retData: total } = await reportFormService.total(searchParams);
        const { page: data } = retData
        let { records, ...pg } = data
        let newPg = { ...pg, pageNum: pg.current }
        setPgCfg(transPgToPagination(newPg));
        setDescTotal(total)
        setDataSource(records)
    }
    const exportEntry = async (searchValue: IReportFormSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            pageSize: 10000,
            pagenum: 1,
            id: selectedRowKeys.join(),
        }
        let result = await reportFormService.export(searchParams);
        convertRes2Blob(result)
    }
    const exportPin = async (code: string, name: string, id: string) => {
        const searchParams = {
            shopId: shopAndOrg.shopId,
            materialCode: code,
            materialName: name,
            // ...searchValue,
            id,
            pageSize: -1,
            pageNum: 1,
        }
        let result = await reportFormService.purchasePinExport(searchParams);
        convertRes2Blob(result)
    }
    const [reportForm, setReportFormModel] = useState<IReportFormField | null>(null)

    return {
        /***field***/
        searchParam,
        initPgCfg,
        dataSource,
        selectedRowKeys,
        pgCfg,
        drawerVisible,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        descTotal, setDescTotal,
        selectedRow, setSelectedRow,
        exportEntry,
        rowsDescTotal, setRowsDescTotal,
        descRows, setDescRows,
        reportForm, setReportFormModel,
        exportPin
    }
});