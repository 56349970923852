import { httpRequest } from "../../../utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import { IHistoryQuerySearch, IHistoryQueryDetail } from "./historyQueryType"

class HistoryQueryService {
    async page(searchVo: IHistoryQuerySearch) {
        const apiUrl = "/bcm/maintenance/historyQuerying";
        return await httpRequest.post<IPage<IHistoryQueryDetail>>(apiUrl, searchVo);
    }
}
export const historyQueryService = new HistoryQueryService();
