import React, { useEffect } from "react";
import { Table, Space, Switch, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCustomerModel } from "../baseCustomerModel";
import { IVRMCustomerDetail } from "../baseCustomerService";
import { momentFormat } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys, onDisabled,setHistoryRow,setHistoryVisible
    } = useCustomerModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "13-5-1-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const onHistoryModel = (record: IVRMCustomerDetail) => {
        setHistoryVisible(true)
        setHistoryRow(record)
    }


    const columns: ColumnsType<IVRMCustomerDetail> = [
        {
            title: '序号',
            width: 60,
            fixed:"left",
            render: (text, record, index) => `${index + 1}`
        }, {
            title: '客户名称',
            width: 200,
            dataIndex: 'name',
            fixed:"left",
            render: (text: any, record: any, index: number) => <YhTootip text={text} onClick={() => onHistoryModel(record)}/>,
            //render: (text: any,record:any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(record.id)}>{text}</p></Tooltip>,

        }, {
            title: '客户类型',
            width: 160,
            dataIndex: 'categoryName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '联系人',
            width: 120,
            dataIndex: 'contact',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '联系电话',
            width: 120,
            dataIndex: 'phone',
        }, {
            title: '登记日期',
            width: 120,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '联系地址',
            width: 180,
            dataIndex: 'address',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '创建人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '操作',
            width: 120,
            dataIndex: 'enable',
            render: (text, record) => (
                <Space size="middle">
                    {/* checked={record.materialStatus == 0} */}
                    <Switch checked={text === 1} onChange={(checked) => {
                        // onDisabled(record.id as string, checked);
                        onDisabled(record.id as string, checked);
                        // cutDisabled(record,checked)
                    }} />
                </Space>
            ),
        }
    ];
    useEffect(() => {
        loadData()
    }, []);

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            rowSelection={{
                selectedRowKeys: selectedRowKeys, //记录选中
                onChange: (selectedRowKeys: React.Key[], selectedRows: IVRMCustomerDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                }
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
