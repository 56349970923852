import React, { useRef, useEffect, useState, FC } from "react";
import { Button, Form, message, Modal, Upload, UploadFile } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { formatNum } from "@/utils/utils";
import { useSaleRoDetailModel } from "./saleRoDetailModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import { PlusOutlined, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { IVRMSettleTypeEnum } from "@/types/FmsDs";
import { ISaleRoSettleParams } from "../../saleRoTypes"
import useGlobalModel from "@/model/globalModel";
import { ISelectOptions } from "@/types/ScmTypes";
import { accountService } from "@/views/account/AccountService";
import { RouterParamType } from "@/model/navModel";
import { customerService } from "@/views/base/customerFiles/customer/baseCustomerService";
import FontIcon from "@/components/YhIcon";
import { getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData } from "@/utils/constants";
import { fileDownload } from "@/utils/exportFile";
import { UploadChangeParam } from "antd/lib/upload";
export interface ISalePayMethod {
    amount?: number,     /// 0,
    code?: string,     /// "",
    createTime?: string,     /// "",
    createUser?: string,     /// "",
    deleteFlag?: number,     /// 0,
    id?: string,     /// "",
    note?: string,     /// "",
    orderNum?: number,     /// 0,
    payMethodCode?: string,     /// "",
    payMethodName?: string,     /// "",
    payee?: string,     /// "",
    shopId?: string,     /// "",
    tradeCode?: string,     /// "",
    tradeTypeCode?: string,     /// "",
    tradeTypeName?: string,     /// "",
    updateTime?: string,     /// "",
    updateUser?: string,     /// ""
    key: string,
}
//结算
export const SettleDrawer: FC<RouterParamType> = ({ id, mode }) => {
    const { settleVisible: visible, detailAddForm, detailEditForm, detailLookForm, lookSettleDetails, editSettleDetails, setSettleVisible, onSettle, getDetailEditOne } = useSaleRoDetailModel();
    const { user, shopAndOrg: { shopName, shopId, shopCode } } = useGlobalModel()

    const { getSelectedDictOne, getTreeNodeName, getTreeNodeOne } = useDictModel();
    const [customerCode, setCustomerCode] = useState<string>("")
    const formRef = useRef<IYhFormRef>();
    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm
    }
    const typeDetailList = {
        add: [],
        edit: editSettleDetails,
        look: lookSettleDetails
    }
    const [settleType, setSettleType] = useState<IVRMSettleTypeEnum>(IVRMSettleTypeEnum.SETTLE_CASH);   //结算类型，  默认现金结账
    const initDataSource: ISalePayMethod = {
        key: new Date().getTime().toString(),
        payMethodCode: "",
        payMethodName: "",
        amount: 0,
        payee: "",
        note: "",
    };
    const [payDataSource, setPayDataSource] = useState<ISalePayMethod[]>([]);  //表格默认值
    const [accountTypeDs, setAccountTypeDs] = useState<ISelectOption[]>([]) //实时存取收款账户类型值
    const [cash, setCash] = useState(false);
    const [editPayMethodOptions, setEditPayMethodOptions] = useState<ISelectOptions[]>(getSelectedDictOne(DictCodeEnmu.PAY_METHOD))//编辑根据结算类型展示结算方式
    useEffect(() => {
        if (visible) {
            formRef.current?.setFieldsValue({ ...typeForm[mode], note: "" });
            setPayDataSource(mode === "edit" ? [{ ...initDataSource, amount: detailEditForm.receivedAmount as number }] : typeDetailList[mode]);
            setSettleType(typeForm[mode].payType as IVRMSettleTypeEnum);
            // queryAccount(typeForm[mode].customerCode as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, typeForm[mode].payType as string);
            setPayMethodOptions(typeForm[mode].payType as string)
            let { settlementPartyCode, bentityName } = typeForm[mode]
            if (settlementPartyCode === "settle-party.internal") {//这里为部门内部结算时，查询销售实体为客户的接口换取id
                customerService.page({ name: shopName, pageSize: 15, pageNum: 1, shopId }).then((res) => {
                    let { records } = res.retData
                    // console.log(records)
                    if (records) {
                        let { code } = records[0]
                        setCustomerCode(code as string)
                        queryAccount(code as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, typeForm[mode].payType as string, shopName);
                    } else if (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1) {
                        let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode as string)[0]
                        queryAccount(customerCode as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, typeForm[mode].payType as string, name);
                    } else {
                        message.warning(`该结算方为部门内部费用时，无维护对应为${shopName}客户`)
                    }
                })
            } else if (mode !== "look") {
                queryAccount(typeForm[mode].customerCode as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, typeForm[mode].payType as string, typeForm[mode].customerName);
            }
        }
    }, [visible])
    const setPayMethodOptions = (payType: string) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions as ISelectOptions[])
    }
    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode: string, creditFlag: number, payType: string, customerName?: string) => {
        // console.log(partnerCode, creditFlag)
        let params = {
            partnerCode,  //客户/供应商编码 
            shopCode,
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag,  //为记账类型的  1挂账 2现结
        }
        const { retData } = await accountService.accountFind(params);
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter(item => {
            return item;
        })
        // debugger;
        //现金账户 或者 挂账账户。
        let newAccTypeDs: any = [];
        if (payType === IVRMSettleTypeEnum.SETTLE_CASH) {
            newAccTypeDs = filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0, accounttypecode: item.accountTypeCode as string, key: item.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {//挂账  account-type.credit
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_MONTHLY) {//月结  account-type.credit-month
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit-month") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        }
        setAccountTypeDs(newAccTypeDs);
        let accTypeDs: any = newAccTypeDs[0]

        //选择挂账， 自动带出挂账的账户及授信额度
        if (newAccTypeDs.length > 0) {
            setCash(accTypeDs.accounttypecode === "account-type.cash");
            formRef.current?.setFieldsValue({ payMethodName: accTypeDs.label, payMethodCode: accTypeDs.value, payMethodValue: accTypeDs.accounttypecode, available: formatNum(accTypeDs?.available) })
        } else {
            // message.warning(`该客户没有授信账户`);
            message.warning(`该${customerName}客户没有授信账户`);
            formRef.current?.setFieldsValue({ payMethodName: undefined, payMethodCode: undefined, payMethodValue: undefined, available: undefined })

        }
    }


    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {

    }

    const onCancel = () => {
        setSettleVisible(false);
    }

    //表单提交
    const onSubmit = async () => {
        let filterPayData = payDataSource.filter(item => (item.payMethodCode));
        // if (filterPayData.length !== payDataSource.length) {
        //     message.warning("请完善收款信息");
        //     return;
        // }
        // let totalAmount: number = filterPayData.reduce((con, next) => { con += +(next.amount ?? 0); return con }, 0);
        // if (totalAmount !== +(typeForm[mode].receivedAmount as number)) {
        //     message.warning("您结算的金额与应收金额有差异");
        //     return;
        // }
        if (settleType === IVRMSettleTypeEnum.SETTLE_CASH && accountTypeDs.length === 0) {
            message.warning("您的现金账户没有开户");
            return;
        }
        formRef.current?.validateFields().then(async val => {
            const {cashFileList, ...res} = val
            let cashAttach = '',
            cashAttachFileName = ''
            if (cashFileList && cashFileList.length > 0) {
                const file = cashFileList[0]
                if (!file?.uid) {
                    console.log(file)
                    return message.error('文件信息有误，请重新上传文件')
                }
                cashAttach = file?.uid
                cashAttachFileName = file?.name
            }
            let data: ISaleRoSettleParams = {
                id: typeForm[mode].id as string,
                returnCode: res.returnCode,
                note: res.note,
                reckoner: user.username,
                payType: res.payType,
                accountCode: res.payMethodCode,
                accountType: res.payMethodValue,
                payableAmount: res.receivedAmount,
                payMethodName: res.payMethodName,
                payMethodCode: res.payMethodCode,
                details: res.payMethodValue !== "account-type.cash" ? [] : [
                    {
                        payMethodCode: res?.cashCode,
                        payMethodName: res.cashName,
                        accountName: res.payMethodName,
                        accountCode: res.payMethodCode,
                        amount: res.receivedAmount,
                        payee: "",
                        cashAttach,
                        cashAttachFileName
                    }
                ]
            }
            try {
                let retData = await onSettle(data)
                message.success("结算成功");
                onCancel()
                getDetailEditOne(typeForm[mode].returnCode as string, "edit")
            } catch (error) {
                onCancel()
            }


        }).catch(err => {
        })
    }

    const onFinish = (val: any) => {

    }
    const settleTypeChange = (value: string, option: ISelectOption) => {
        let { settlementPartyCode, bentityName } = typeForm[mode]

        if (option) {
            setSettleType(option.value as IVRMSettleTypeEnum);
            setPayDataSource([initDataSource]);
            if (settlementPartyCode === "settle-party.internal") {//这里为部门内部结算时，查询销售实体为客户的接口换取id
                queryAccount(customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value as string, shopName);
            } else if (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1) {
                let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode as string)[0]
                queryAccount(customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, typeForm[mode].payType as string, name);
            } else {
                queryAccount(typeForm[mode].customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value as string, typeForm[mode].customerName);
            }
            setPayMethodOptions(value)

        }
    }
    const payMethodChange = (value: string, option: ISelectOption) => {
        if (option.accounttypecode === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true);
        } else {
            setCash(false);
        }
        option && formRef.current?.setFieldsValue({
            payMethodName: option.label,
            payMethodValue: option.accounttypecode,
            available: formatNum(option?.available),
            cashName: undefined,
            cashCode: undefined
        });
    }
    const cashSelectChange = (value: string, option: ISelectOption) => {
        option && formRef.current?.setFieldsValue({ cashName: option.label, cashCode: option.value });
    }

    const onPreview = (file: UploadFile<any>) => {
        const { uid, name } = file
        const type = uid.split('.')[1]
        if (['jpg', 'png', 'jpeg', 'gif'].includes(type)) {
            Modal.info({
                title: '图片查看',
                content: <img style={{ width: '100%' }} src={getBaseImgLookUrl(uid)} />,
                okText: '确定',
                cancelText: '取消',
                centered: true,
                width: 800,
            })
        } else {
            // window.open(getBaseImgLookUrl(uid))
            fileDownload(getBaseImgLookUrl(uid), name)
        }
    }

    const normFile = (e: UploadChangeParam) => {
        // let filetypes: string[] = ['.jpg', '.png', '.jpeg', 'svg', '.gif', '.pdf', '.doc']
        // let isType: boolean = false
        // let fileEnd: string = e?.file.name.substring(e?.file.name.lastIndexOf('.'))
        // isType = filetypes?.includes(fileEnd) ?? false
        if (e?.file.status === 'done') {
            if (e?.file?.response?.retCode == 0) {
                const data = {
                    name: e.file.name,
                    size: e.file.size,
                    type: e.file.type,
                    uid: e?.file?.response?.retData,
                }
                e.fileList = [data]
            } else {
                message.error('文件上传失败，请重新上传!')
                e.fileList?.pop()
            }
        }
        if (e.file.status === 'error') {
            message.error('文件上传失败，请重新上传!')
            e.fileList?.pop()
        }
        if (Array.isArray(e)) {
            return e
        }
        // if (!isType) {
        //     e.fileList?.pop()
        // }
        return e && e.fileList
    }
    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        labelCol: { span: 6 },
        onChange,
        disabled: mode === "look",
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "退货单号",
                fieldName: "returnCode",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单日期",
                fieldName: "createTime",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户编码",
                fieldName: "customerCode",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算日期",
                fieldName: "settleTime",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "已付金额",
                fieldName: "receivableAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "应付金额",
                fieldName: "receivedAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                onSelectChange: settleTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                span: 12,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "支付方式",
                fieldName: "payMethodCode",
                onSelectChange: payMethodChange,
                options: accountTypeDs,
                span: 12,
                hidden: mode === "look"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "支付方式",
                fieldName: "payMethodName",
                span: 12,
                hidden: !(mode === "look")
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "现金类支付",
                fieldName: "cashName",
                hidden: !(mode === "look"),
                span: 12,
            },
            {
                type: FormItemTypeEnum.Other,
                fieldLabel: '附件',
                fieldName: 'cashAttach',
                colClassName: 'ant-col-h-auto',
                hidden: !(mode === 'look'),
                span: 24,
                formItem: (
                    <Form.Item name="cashFileList" label="附件" valuePropName="fileList" getValueFromEvent={normFile} labelCol={{ span: 3 }}>
                        <Upload
                            disabled={mode === 'look'}
                            className="upload-box"
                            onPreview={onPreview}
                            name="file"
                            action={getBaseImgUploadUrl()}
                            data={getBaseUploadData()}
                            listType="picture"
                            beforeUpload={(file:any) => {
                                const isLt20M = file.size / 1024 / 1024 < 20
                                if (!isLt20M) {
                                    message.error('上传到文件不能超过20M')
                                    file.status='done'
                                }
                                return isLt20M
                            }}
                            iconRender={(file) => {
                                return (
                                    <FontIcon
                                        onClick={() => {
                                            onPreview(file)
                                        }}
                                        style={{ fontSize: '24px' }}
                                        type="icon-tupian"
                                    />
                                )
                            }}
                        >
                            {mode !== 'look' && (
                                <>
                                    <Button icon={<UploadOutlined />}>选择文件上传</Button>
                                    <span style={{ lineHeight: '25px', color: 'green', fontSize: '13px', marginLeft: '10px' }}>
                                        提醒：上传单个文件不能超过20M,当前仅.jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc等任何类型文件
                                    </span>
                                </>
                            )}
                        </Upload>
                    </Form.Item>
                ),
            },
            ...((str) => {
                if (str) {
                    return [
                        {
                            type: FormItemTypeEnum.Select,
                            fieldLabel: "现金类支付",
                            fieldName: "cashCode",
                            span: 12,
                            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASH),
                            onSelectChange: cashSelectChange,
                            hidden: mode === "look",
                            rules: [{ required: true, message: "请完善现金类支付" }],
                        },
                        {
                            type: FormItemTypeEnum.Other,
                            fieldLabel: '附件',
                            fieldName: 'cashAttach',
                            colClassName: 'ant-col-h-auto',
                            span: 24,
                            formItem: (
                                <Form.Item name="cashFileList" label="附件" valuePropName="fileList" getValueFromEvent={normFile} labelCol={{ span: 3 }}>
                                    <Upload
                                        disabled={mode === 'look'}
                                        className="upload-box"
                                        onPreview={onPreview}
                                        name="file"
                                        action={getBaseImgUploadUrl()}
                                        data={getBaseUploadData()}
                                        listType="picture"
                                        beforeUpload={(file:any) => {
                                            const isLt20M = file.size / 1024 / 1024 < 20
                                            if (!isLt20M) {
                                                message.error('上传到文件不能超过20M')
                                    file.status='done'
                                            }
                                            return isLt20M
                                        }}
                                        iconRender={(file) => {
                                            return (
                                                <FontIcon
                                                    onClick={() => {
                                                        onPreview(file)
                                                    }}
                                                    style={{ fontSize: '24px' }}
                                                    type="icon-tupian"
                                                />
                                            )
                                        }}
                                    >
                                        {mode !== 'look' && (
                                            <>
                                                <Button icon={<UploadOutlined />}>选择文件上传</Button>
                                                <span style={{ lineHeight: '25px', color: 'green', fontSize: '13px', marginLeft: '10px' }}>
                                                    提醒：上传单个文件不能超过20M,当前仅.jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc等任何类型文件
                                                </span>
                                            </>
                                        )}
                                    </Upload>
                                </Form.Item>
                            ),
                        },
                    ]
                } else {
                    return [
                        {
                            type: FormItemTypeEnum.Input,
                            fieldLabel: "可用额度",
                            fieldName: "available",
                            span: 12,
                            hidden: mode === "look",
                            disable: true,
                        },
                    ]
                }
            })(cash),
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "financeNote",
                span: 12,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "支付方式对应得账户类型",
                fieldName: "payMethodValue",
                hidden: true
            },

        ]
    };



    return (
        <YHDrawer
            {...{
                title: '销售退货单结算',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit,
                width: 800,
                showFooter: detailEditForm.status === "sale-return-status.settle" || mode === "look" ? false : true
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
