import React from "react";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useSummaryModel } from "./summaryModel";
import { useGlobalModel } from "@/model/globalModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";


export function PageHeader() {
    const {
        // orgDs, 
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSummaryModel();

    const { appDs } = useGlobalModel();
    const { getSelectedDictOne } = useDictModel(() => []);

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            span: 4,
            xxl: 4,
            placeholder: "供应商名称",
            fieldName: "name",
            size: "small",
            disabled: false
        },
        // {
        //     type: SearchTypeEnum.TreeSelect,
        //     span: 4,
        //     placeholder: "组织机构",
        //     fieldName: "orgCode",
        //     size: "small",
        //     treeDs: orgDs,
        //     disabled: false
        // },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            xxl: 4,
            placeholder: "供应商类型",
            fieldName: "categoryCode",
            size: "small",
            disabled: false,
            options: getSelectedDictOne(DictCodeEnmu.SUPPLIER_TYPE)
        },
        {
            type: SearchTypeEnum.Select,
            xxl: 4,
            span: 4,
            placeholder: "系统分支",
            fieldName: "branch",
            size: "small",
            options: appDs,
        },
    ]

    return (
        <YhPageHeader
            title="供应商账户"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}