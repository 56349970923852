
import { httpRequest } from "../../../utils/HttpRequest";
import { IStockOutputField, IStockOutputSearch, IStockOutputDetail } from "./stockOutputType"
class StockOutputService {
    async page(searchVo: IStockOutputSearch) {
        const apiUrl = "/bcm/stockIo/out/findPage";
        return await httpRequest.post<IStockOutputDetail[]>(apiUrl, searchVo);
    }
    async one(id: string,ioFlag:number = 0) {
        const apiUrl = `/bcm/stockIo/findById`;
        return await httpRequest.post<IStockOutputField>(apiUrl,{id,ioFlag});
    }
    //导出
    async export(params: IStockOutputSearch) {
        const apiUrl = "/bcm/stockIo/export";
        return await httpRequest.postFile(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: IStockOutputSearch) {
        const apiUrl = "/bcm/stockIo/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
}

export const stockOutputService = new StockOutputService();