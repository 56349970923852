import React, { FC } from "react";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { Description } from "@/components/YhBox/Description";
import { useSaleOrderDetailModel } from "./saleOrderDetailModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { debounce } from "lodash";

export const TopButs: FC<RouterParamType> = ({ id, mode }) => {
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        onTransfer,
    } = useSaleOrderDetailModel();
    const { selectedNavId,
        replaceNav } = useNavModel();
    const { getTreeNodeName } = useDictModel();

    const onTrunSaleSo = async () => {
        let retData = await onTransfer(detailEditForm.saleOrderCode as string)
        if (retData) {
            replaceNav(selectedNavId, "5-2")
            message.success("转接销售单成功")
        }
    }
    const typeTitle = {
        "add": detailAddForm,
        "edit": detailEditForm,
        "look": detailLookForm,
    }
    const action = mode == "edit" ? <>
        <YhAuth resCode={ResourceAuthEnum.S_SO_ED_TRANS}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { onTrunSaleSo() }, 900)}
            >转销售单</Button>
        </YhAuth>
    </> : false;


    const orderStatu: IDescription[] = [
        {
            label: "销售订单",
            value: typeTitle[mode]?.saleOrderCode as string ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]

    return (
        <YhBox
            action={action}
            hidden={mode !== "add" ? false : true}
            title={<>
                {orderStatu.map((item, index) => <Description key={index} {...item} />)}
            </>}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}