import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useInternalModel } from "../internalModel";
import { IInternalDetail, internalStatusOption } from "../internalTypes";
import { useDictModel } from "@/views/base/dict/dictModel";
import { formatNum } from "@/utils/utils";
import { settleTypeDs } from "@/types/FmsDs";
import { isArray } from "util";

export function DataTable() {
    const { getTreeNodeName } = useDictModel(() => []);
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useInternalModel();

    useEffect(() => {
        loadData()
    }, [])

    const columns: ColumnsType<IInternalDetail> = [
        {
            title: '序号',
            width: 40,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '内部对账单号',
            width: 180,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '对账金额',
            width: 120,
            dataIndex: 'writeoffAmount',
            fixed: "left",
        }, {
            title: '状态',
            width: 120,
            dataIndex: 'status',
            render: (text) => internalStatusOption.find(item => item.value === text)?.label ?? text
        }, {
            title: '交易门店',
            width: 160,
            dataIndex: 'adverseUnitName',
        }, {
            title: '申请人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '申请时间',
            width: 120,
            dataIndex: 'createTime',
        }, {
            title: '申请说明',
            width: 120,
            dataIndex: 'note',
        }, {
            title: '对方确认人',
            width: 120,
            dataIndex: 'adverseAffirmUser',
        }, {
            title: '对方确认时间',
            width: 120,
            dataIndex: 'adverseAffirmTime',
        }, {
            title: '财务确认人',
            width: 120,
            dataIndex: 'financeAffirmUser',
        }, {
            title: '财务确认时间',
            width: 120,
            dataIndex: 'financeAffirmTime',
        }, {
            title: '财务审核人',
            width: 120,
            dataIndex: 'financeAuditUser',
        }, {
            title: '最终审核人',
            width: 120,
            dataIndex: 'endAuditUser',
        }, {
            title: '最终审核时间',
            width: 120,
            dataIndex: 'endAuditTime',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([id]);
        }
        // let findIndex = selectedRowKeys.indexOf(id);
        // let newSelectedRowKeys = [...selectedRowKeys];
        // if (findIndex === -1) {
        //     newSelectedRowKeys.push(id);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // } else {
        //     newSelectedRowKeys.splice(findIndex, 1);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            rowSelection={{
                //全部选中时  (存string[])
                type: "radio",
                // onSelectAll: (selected, selectedRows, changeRows) => {
                //     let changeRowId = changeRows.map(item => item.id);
                //     selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                //         : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                // },
                onSelect: (record) => onRow(record.id as string),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}
