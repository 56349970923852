import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { usePurchasePlanModel } from "../purchasePlanModel";
import { IPurchasePlanFields } from "../purchasePlanType"
import { momentFormat } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import useNavModel from "@/model/navModel";


type statusType = {
    "purchase-plan.transfer": string,
    "purchase-plan.create": string,
    [key: string]: any
}

export function DataTable() {

    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows
    } = usePurchasePlanModel();
    const { getTreeNodeName } = useDictModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "11-1-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const statusColor: statusType = {
        "purchase-plan.transfer": "blue",
        "purchase-plan.create": "cyan",
    }
    const columns: ColumnsType<IPurchasePlanFields> = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => `${i + 1}`,
            fixed: "left",
        }, {
            title: '采购计划号',
            width: 120,
            dataIndex: 'planCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) =>{e.stopPropagation(); onLook(text)}}>{text}</p></Tooltip>,

        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        }, {
            title: '厂家订单号',
            width: 120,
            dataIndex: 'orderCode',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '采购类型',
            width: 80,
            dataIndex: 'purchaseTypeName',
            render: (text) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '商品类型',
            width: 80,
            dataIndex: 'materialTypeName',
        }, {
            title: '采购数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '已到数量',
            width: 80,
            dataIndex: 'arrivedNum',
        }, {
            title: '未到数量',
            width: 80,
            dataIndex: 'unarrivedNum',
        }, {
            title: '采购总金额',
            width: 80,
            dataIndex: 'totalAmount',
        }, {
            title: '制单日期',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '制单人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '发运方式',
            width: 120,
            dataIndex: 'deliveryMethodName',
        }, {
            title: '是否直发',
            width: 80,
            dataIndex: 'isDirect',
            render: (text) => {
                if (text === null) {
                    return ""
                } else {
                    return text ? "是" : "否"
                }
            }
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];

    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (record: IPurchasePlanFields) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let findIndexRowKeys = selectedRowKeys.findIndex((item) => (item === id))

        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];
        console.log(findIndexDesc)
        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
        // let findIndex = selectedRowKeys.indexOf(id);
        // let newSelectedRowKeys = [...selectedRowKeys];
        // if (findIndex === -1) {
        //     newSelectedRowKeys.push(id);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // } else {
        //     newSelectedRowKeys.splice(findIndex, 1);
        //     setSelectedRowKeys(newSelectedRowKeys);
        // }
    };
    const onRightClick = (record: IPurchasePlanFields)=>{
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IPurchasePlanFields[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                // onClick: () => onRow(record.id as string),
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />
    )
}
