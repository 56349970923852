import React from "react";
import { useVehicleModel } from "../vehicleModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "客户名称",
        fieldName: "customerName"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "车辆牌照",
        fieldName: "licensePlate"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "vin码",
        fieldName: "vin"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "发动机号",
        fieldName: "engineCode",
    },
    {
        type: SearchTypeEnum.Datepicker,
        placeholder: "登记时间",
        fieldName: "createTime",
    },
]

export function PageHeader() {
    const {
        searchParam,
        initPgCfg,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useVehicleModel();

    return (
        <YhPageHeader
            title="车辆管理"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            // handleSearch={loadData}
            handleSearch={(params) => loadData(initPgCfg, { ...searchParam, ...params })}
            handleReset={resetSearchParam}
        />
    );
}