import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useSoReportModel } from "../soReportModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const DataBox = () => {
    const { descTotal,exportSoReport } = useSoReportModel();

    const action = <>
        <YhAuth resCode={ResourceAuthEnum.STT_S_EX}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportSoReport()}
            >导出</Button>
        </YhAuth>
    </>;
    const desc: IDescription[] = [
        {
            label: "销售数量",
            value: descTotal.saleNum,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售金额",
            value: descTotal.saleAmount,
            color: DescriptionColorEnum.red
        },
        // {
        //     label: "赠品数量",
        //     value: descTotal.giftsNum,
        //     color: DescriptionColorEnum.red
        // },
        // {
        //     label: "赠品金额",
        //     value: descTotal.giftsAmount,
        //     color: DescriptionColorEnum.red
        // },
        {
            label: "销售成本",
            value: descTotal.costAmount,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售毛利",
            value: descTotal.profit,
            color: DescriptionColorEnum.red
        },        
    ]
    return (
        <YhBox title=" " action={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}