import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/ScmResourceAuthEnum";
import { useAllocModel } from "../inventoryStatisticsModel";

export const DataBox = () => {
    const { exportAlloc } = useAllocModel();

    const action = <>
        {/* <YhAuth resCode={ResourceAuthEnum.ST_XC_EX}> */}
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportAlloc()}
            >导出</Button>
        {/* </YhAuth> */}
    </>;

    return (
        <YhBox title=" " action={action}>
            <DataTable />
        </YhBox>
    )
}