import React, { useEffect } from 'react'
import { useExportRecordModel } from './exportRecordModel'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IExportRecordDetail, StatusEnum } from './exportRecordTypes'
import { enumGetVal } from '@/utils/utils'

export const DataBox = () => {
    const { dataSource, pgCfg, loading, loadData } = useExportRecordModel()

    useEffect(() => {
        loadData().then()
    }, [])

    const columns: ColumnsType<IExportRecordDetail> = [
        {
            title: '菜单',
            width: 80,
            dataIndex: 'menu',
            fixed: 'left'
        },
        {
            title: '状态',
            width: 80,
            dataIndex: 'status',
            fixed: 'left',
            render(text) {
                return enumGetVal(text, StatusEnum)
            }
        },
        {
            title: '文件名称',
            width: 300,
            dataIndex: 'fileName'
        },
        {
            title: '创建时间',
            width: 160,
            dataIndex: 'createTime'
        },
        {
            title: '开始时间',
            width: 160,
            dataIndex: 'startTime'
        },
        {
            title: '结束时间',
            width: 160,
            dataIndex: 'endTime'
        },
        {
            title: '失败原因',
            width: 160,
            dataIndex: 'failMsg'
        },
        {
            title: '下载',
            width: 160,
            dataIndex: 'downloadUrl',
            fixed: 'right',
            render: (text) => (text ? <a href={text}>下载</a> : '')
        }
    ]

    return (
        <>
            <Table
                rowKey={(record) => record.id}
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ['bottomCenter'],
                    showTotal: (total: number) => `共${total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 'calc(100vh - 446px)' }}
            />
        </>
    )
}
