import React, { useEffect } from "react";
import { Space, Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useWarehouseModel } from "../warehouseModel";
import { IVRMWarehouseDetail } from "../warehouseTypes";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "../../dict/dictModel";
import YhTootip from "@/components/YhTootip";



export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys,onDisabled
    } = useWarehouseModel();
    const { getTreeNodeName } = useDictModel();
    const columns: ColumnsType<IVRMWarehouseDetail> = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => <>{i + 1}</>
        },
        {
            title: '所属门店',
            width: 180,
            align: "center",
            dataIndex: 'shopName',
        },
        {
            title: '仓库编号',
            width: 200,
            align: "center",
            dataIndex: 'code',
            fixed: "left",
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            title: '仓库名称',
            width: 200,
            align: "center",
            dataIndex: 'name',
            fixed: "left",
            render: (text: any) => <YhTootip text={text} />,
        },
        {
            title: '仓库类型',
            align: "center",
            dataIndex: 'type',
            render: (text) => getTreeNodeName(text)
        },
        {
            title: '仓库负责人',
            align: "center",
            dataIndex: 'contact',
        },
        {
            title: '联系电话',
            align: "center",
            dataIndex: 'phone',
        },
        {
            title: '联系地址',
            align: "center",
            dataIndex: 'address',
        }, {
            title: '创建人',
            width: 120,
            dataIndex: 'createUser',
        },
        {
            title: '备注',
            align: "center",
            dataIndex: 'note',
        }, {
            title: '状态',
            width: 100,
            dataIndex: 'enable',
            render: (text, record:any) => (
                <Space size="middle">
                    {/* checked={record.materialenable == 0} */}
                    <Switch checked={record.enable === 1} onChange={(checked) => {
                        onDisabled(record, checked);
                        // cutDisabled(record,checked)
                    }} />
                </Space>
            ),
        }
    ];
    const { shopAndOrg: { shopCode } } = useGlobalModel()
    useEffect(() => {
        setSelectedRowKeys('');
    }, [shopCode])
    useEffect(() => {
        loadData();
    }, [])

    return (
        <Table
            rowKey={record => record.id as string}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg, 
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: event => setSelectedRowKeys(selectedRowKeys === record.id as string ? "" : record.id as string)
            })}
            rowClassName={(record, index) => record.id === selectedRowKeys ? "table-select-row" : ""}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
