import React, { useState } from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { AllotDetailModel } from "./AllotDetailModel";
import useNavModel, { RouterParamType } from "@/model/navModel";

export const InternalDetails = () => {
    const { getSelectedNavState } = useNavModel(() => []);
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);

    return (
        <>
            {detailsInfo && <>
                <TopButs {...detailsInfo} />
                <FormBox {...detailsInfo} />
                <TableBox {...detailsInfo} />
                <AllotDetailModel {...detailsInfo} />
            </>
            }
        </>
    )
}