import React from "react";
import { useStockOutAndInListModel } from "../stockOutAndInListModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";

const searchFields = [
    {
        type: SearchTypeEnum.Input,
        placeholder: "借件单号",
        fieldName: "borrowCode"
    },
    {
        type: SearchTypeEnum.Input,
        placeholder: "借件人",
        fieldName: "borrowPerson"
    },
    {
        type: SearchTypeEnum.RangePicker,
        fieldName: "",
        placeholder: ["借件日期开始", "借件日期结束"],
        rangePickerFieldName: ["startTime", "endTime"],
        format: "YYYY-MM-DD",
        span: 16,
        xxl: 8,
    },
]

export function PageHeader() {
    const {
        initPgCfg,
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useStockOutAndInListModel();

    return (
        <YhPageHeader
            title="维修借件"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}