import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSoListModel } from "../soListModel";
import { IVRMSoListDetail } from "../soListService";
import { formatNum } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useSoListModel();
    const { getTreeNodeName } = useDictModel(() => []);

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IVRMSoListDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '销售单号',
            fixed: "left",
            width: 200,
            dataIndex: 'documentNumber',
            render: (text: any) => <YhTootip text={text} />,
            // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '客户名称',
            width: 200,
            dataIndex: 'customerName',
            render: (text: any) => <YhTootip text={text} />,
            //render: text => <Tooltip placement="topLeft" title={text}arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '客户类型',
            width: 100,
            dataIndex: 'customerTypeName',
        }, {
            title: '客户销售频次',
            width: 100,
            dataIndex: 'salesFrequency',
        }, {
            title: '单据类型',
            width: 150,
            dataIndex: 'tradeTypeName',
        }, {
            title: '销售类型',
            width: 150,
            dataIndex: 'maintenanceTypeCode',
            render: text => getTreeNodeName(text)
        }, {
            title: '结算类型',
            width: 150,
            dataIndex: 'payType',
            render: text => getTreeNodeName(text)
        }, {
            title: '单据状态',
            width: 150,
            dataIndex: 'status',
            render: text => getTreeNodeName(text)
        }, {
            title: '支付状态',
            width: 150,
            dataIndex: 'payStatus',
            render: text => getTreeNodeName(text)
        }, {
            title: '销售金额',
            width: 150,
            dataIndex: 'totalAmount',
            render: (text) => `￥${text}`
        }, {
            title: '优惠金额',
            width: 150,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${text}`
        }, {
            title: '应收金额',
            width: 150,
            dataIndex: 'amountCollected',
            render: (text) => `￥${text}`
        }, {
            title: '已收金额',
            width: 150,
            dataIndex: 'receivableAmount',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '已付金额',
            width: 120,
            dataIndex: 'paidAmount',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销售成本',
            width: 150,
            dataIndex: 'totalCost',
            render: (text) => `￥${text}`
        }, {
            title: '销售毛利',
            width: 150,
            dataIndex: 'grossProfit',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '制单员',
            width: 150,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '结算日期',
            width: 160,
            dataIndex: 'settleTime',

        }, {
            title: '客户类型',
            width: 150,
            dataIndex: 'customerTypeName',
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id)
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}