import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { usePoListModel } from "../poListModel";
import { IVRMPoListDetail } from "../poListService";
import { formatNum } from "@/utils/utils";
import { settleTypeDs } from "@/types/FmsDs";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = usePoListModel();
    const { getTreeNodeName } = useDictModel(() => []);

    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IVRMPoListDetail> = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '采购单号',
            fixed: "left",
            width: 180,
            dataIndex: 'documentNumber',
            render: (text: any) => <YhTootip text={text} />,
        }, {
            title: '供应商名称',
            width: 210,
            dataIndex: 'supplierName',
            render: (text: any) => <YhTootip text={text} />,
           // render: text => <Tooltip placement="topLeft" title={text}arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '供应商类型',
            width: 110,
            dataIndex: 'supplierTypeName',
        }, {
            title: '供应商采购频次',
            width: 110,
            dataIndex: 'purchaseFrequency',
        }, {
            title: '单据类型',
            width: 130,
            dataIndex: 'type',
        }, {
            title: '采购类型',
            width: 140,
            dataIndex: 'orderTypeName',
        }, {
            title: '单据状态',
            width: 130,
            dataIndex: 'status',
            render: text => getTreeNodeName(text)
        }, {
            title: '支付状态',
            width: 130,
            dataIndex: 'payStatus',
            render: text => getTreeNodeName(text)
        }, {
            title: '结算类型',
            width: 130,
            dataIndex: 'payType',
            render: text => settleTypeDs.find(item => item.value === text)?.label ?? ""
        }, {
            title: '制单员',
            width: 150,
            dataIndex: 'createUser',
        }, {
            title: '制单日期',
            width: 150,
            dataIndex: 'createTime',
        }, {
            title: '结算日期',
            width: 150,
            dataIndex: 'settleTime',
        }, {
            title: '采购金额',
            width: 120,
            dataIndex: 'totalAmount',
            render: (text) => '￥' + text
        }, {
            title: '已付金额',
            width: 120,
            dataIndex: 'paidAmount',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '已收金额',
            width: 120,
            dataIndex: 'received',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '分店',
            width: 140,
            dataIndex: 'shopName',
            render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }
    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id)
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
        />
    )
}