import { httpRequest } from '@/utils/HttpRequest';
import { IPage, ISearchPage } from '@/types/ScmTypes';
import { AxiosResponse } from 'axios';

export interface ISCMAcctUnitInfo {
	contact: string;
	phone: string;
	note: string;
}

//详情
export interface ISCMAcctUnitDetail {
	id: string;
	address: string;
	bentity: string;
	bentityList: string;
	branch: string;
	branchList: string;
	code: string;
	contact: string;
	createTime: string;
	createUser: string;
	deleteFlag: string;
	enable: string;
	name: string;
	note: string;
	phone: string;
	shop: string;
	shopCode: string;
	shopList: string;
	shopName: string;
	status: string;
	tel: string;
	updateTime: string;
	updateUser: string;
	ver: string;
}
//新增
export interface ISCMAcctUnitInsert {
	sourceApp?: string; // "fms",
	shopCode?: string; // "64eec0c1d6f37078b6b460e4cb0ac9d7",
	shopName?: string; // 1,
	name?: string; // "王氏集团",
	contact?: string; // "杨月",
	phone?: string; // "153000000",
	tel?: string; // "41515",
	address?: string; // "北京大学",
	shop?: string; // "a17fe0959754f55f0fcb1604f81ad814,b34262a08ce4d963a9b42ae32e1e08bd",
	betity?: string; // "768459d2960ef3484ea8f1334c56459a,81c06bb055d3e22aa0d7b39374842d67",
	note?: string; // "备注"
	createUser?: string; // "备注"
}

//编辑
export interface ISCMAcctUnitUpdate extends ISCMAcctUnitInsert {
	id?: string;
}
//搜索条件
export interface ISCMAcctUnitSearchFields {
	shopId?: string;
	code?: string;
	name?: string;
	status?: string;
}

export interface ISCMAcctUnitSearch extends ISCMAcctUnitSearchFields, ISearchPage {}

//表单字段
export interface ISCMAcctUnitFormFields {}

class AcctUnitService {
	async page(searchVo: ISCMAcctUnitSearch, config: object = {}) {
		const apiUrl = '/fms/accountUnit/listPage';
		return await httpRequest.post<IPage<ISCMAcctUnitDetail>>(apiUrl, searchVo, config);
	}
	async one(obj: { code: string }) {
		const apiUrl = `/fms/accountUnit/one`;
		return await httpRequest.post<ISCMAcctUnitDetail>(apiUrl, obj);
	}
	async insert(insertVo: ISCMAcctUnitInsert) {
		const apiUrl = '/fms/accountUnit/insert';
		return await httpRequest.post<boolean>(apiUrl, insertVo);
	}
	async update(updateVo: ISCMAcctUnitUpdate) {
		const apiUrl = '/fms/accountUnit/update';
		return await httpRequest.post<boolean>(apiUrl, updateVo);
	}
	//启用禁用
	// async disable(id: string, status: number) {
	//     const apiUrl = `/scm2/base/accountUnit/enableOrDisable/${id}/${status}`;
	//     return await httpRequest.get<boolean>(apiUrl);
	// }
	async disable(params: object) {
		const apiUrl = `/fms/accountUnit/disable`;
		return await httpRequest.post<boolean>(apiUrl, params);
	}
	//导出
	async export(searchVo: ISCMAcctUnitSearch) {
		const appPageUrl = '/fms/accountUnit/export';
		return await httpRequest.postFile(appPageUrl, searchVo, { timeout: 300000 });
	}
	async find(searchVo: ISCMAcctUnitSearch) {
		const apiUrl = '/fms/accountUnit/find';
		return await httpRequest.post<ISCMAcctUnitDetail[]>(apiUrl, searchVo);
	}
	//同步
	async sync(params: object) {
		const apiUrl = `/fms/accountUnit/sync`;
		return await httpRequest.post<boolean>(apiUrl, params);
	}
}

export const acctUnitService = new AcctUnitService();
