import React, { useRef } from "react";
import { useAccountModel } from "./accountModel";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YHDrawer } from "@/components/YhDrawer";
import { YhForm } from "@/components/YhFormBox";
import { accountService } from "../../../../AccountService";
import { message } from "antd";

export const WriteoffDrawer = () => {

    const {
        selectedData,
        onReset,
        writeoffDrawerVisible,
        setWriteoffDrawerVisible
    } = useAccountModel(model => [model.writeoffDrawerVisible]);

    const formRef = useRef<IYhFormRef>();


    const onOk = async () => {
        try {
            let formData = await formRef.current?.validateFields();
            if (formData) {
                const reqData = {
                    writeoffType: 1,
                    ...formData,
                };
                let insertRet = await accountService.writeoff(reqData);
                if (insertRet.success) {
                    message.success("账户核销成功!");
                    onReset();
                    setWriteoffDrawerVisible(false);
                } else {
                    message.error("账户核销失败!" + insertRet.retMsg);
                }
            }
        } catch (e) {
            console.log("数据校验失败");
        }
    }
    const onCancel = () => {
        setWriteoffDrawerVisible(false);
    };
    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        disabled: false,
        formValues: {
            accountCode: selectedData?.code,
            name: selectedData?.name
        },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "账户编码",
                fieldName: "accountCode",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "名称",
                fieldName: "name",
                disable: true,
                span: 24,
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "时间升序",
                fieldName: "timeAsc",
                span: 24,
                rules: [
                    {
                        required: true,
                        message: '请选择时间排序方式'
                    }
                ],
                options: [
                    {
                        label: '升序',
                        value: "1"
                    },
                    {
                        label: '降序',
                        value: "0"
                    },
                ]
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "最多可用",
                fieldName: "mostAvailable",
                span: 24,
                rules: [
                    {
                        required: true,
                        message: '请选择是否最多可用'
                    }
                ],
                options: [
                    {
                        label: '是',
                        value: "1"
                    },
                    {
                        label: '否',
                        value: "0"
                    },
                ]
            },
        ]
    };
    return (
        <YHDrawer
            {...{
                title: "账户自动核销",
                visible: writeoffDrawerVisible,
                onOk,
                onCancel,
                showFooter: true
            }}
        >
            <YhForm formRef={formRef} {...formConfig} />
        </YHDrawer>
    )
}
