export enum DictCodeEnmu {
	//调拨状态
	TRANSFER_STATUS = 'transfer-status',
	//调拨状态(待调出)
	TRANSFER_STATUS_INIT = 'transfer-status.init',
	//调拨状态(待调入)
	TRANSFER_STATUS_OUT = 'transfer-status.out',
	//调拨状态(已调入)
	TRANSFER_STATUS_IN = 'transfer-status.in',

	//采退类型
    PRETURN_TYPE = "preturn-type",
	//采退类型 (不受限)
    PRETURN_TYPE_NOT_LIMIT = "preturn-type.not-limit",
    //采退类型 （普通）
    PRETURN_TYPE_GENERAL = "preturn-type.general",
	//加价率
	MARKUP_RATE = 'Markup-rate', 
	//调拨类型
	TRANSFER_TYPE = 'transfer-type',
	//备品调拨
	TRANSFER_TYPE_BP = 'transfer-type.bp',
	//其它调拨
	TRANSFER_TYPE_OTHERS = 'transfer-type.others',

	// 销售出库状态
	SALE_STOCK_IO_STATUS = 'sale-stock—io-status',
	//销售单明细出库状态 (待出库)
	SALE_STOCK_IO_INIT = 'sale-stock—io-status.init',
	// 采购入库状态
	PUR_STOCK_IO_STATUS = 'pur-stock—io-status',

	//客户类型
	CUSTOMER_TYPE = 'customer-type',
	//客户属性
	CUSTOMER_ATTR = 'customer-attr',
	//供应商类型
	SUPPLIER_TYPE = 'supplier-type',

	//配件类型（商品类型）
	MATERIAL_TYPE = 'material-type',
	//计量单位
	MATERIAL_UNIT = 'unit',
	//配件品牌
	MATERIAL_BRAND = 'material-brand',
	//发动机型号
	ENGINE_TYPE = 'engine-type',

	//开户银行类型
	OPENBANK_TYPE = 'account-bank',
	//账户类型
	ACCOUNT_TYPE = 'account-type',
	//发票类型
	INVOICE_TYPE = 'invoice-type',
	//发票状态
	INVOICE_STATUS = 'invoice-status',
	//支付状态
	PAY_STATUS = 'pay-status',
	//支付方式
	PAY_METHOD = 'pay-method',
	//支付方式（纯现金类）
	PAY_METHOD_CASH = "pay-method.cash",
	//支付方式（现金账户类）
	PAY_METHOD_ACCT = "pay-method.acct",
	//经济实体
	BENTITY = 'bentity',
	//核销状态
	WRITE_OFF_STATUS = 'write-off-status-test',

    //采购单状态
    PURCHASE_STATUS = "purchase-status",
    //采购单状态(调拨采)
    PURCHASE_STATUS_ALLOCATION = "purchase.allocation",
    //采购单状态(已补单)
    PURCHASE_STATUS_REPLENISH = "pur-replenish-status.replenish",
    //采购单状态(已结算)
    PURCHASE_STATUS_BILL = "purchase-status.bill",
    //采购单状态(已作废)
    PURCHASE_STATUS_CHANGE = "purchase.change",
    //采购单状态(已入库)
    PURCHASE_STATUS_COMPLETELY_IO = "purchase.completely-io",
    //采购单状态(已开单)
    PURCHASE_STATUS_CREATE = "purchase-status.create",
	//采购类型
	PURCHASE_TEPE = 'purchase-type',
	//采购退货单状态
	PURCHASE_RETURN_STATUS = 'purchase-return-status',

	//销售订单状态
	SALE_ORDER_STATUS = 'sale-order-status',
	//销售价格类型
	SALE_PRICE_TYPE = 'sale-price-type-test',
	//销售单状态
	SALE_STATUS = 'sale-status',
	//销售单状态(调拨销)
	SALE_ALLOCATION = 'sale-allocation',
	//销售单状态(已出库(补))
	SALE_END = 'sale.end',
	//销售单状态(已开单(补))
	SALE_SUPPLEMENT = 'sale.supplement',
	//销售单状态(已转接)
	SALE_TRANSFER = 'sale.transfer',
	//销售单状态(已补单)
	SALE_REPLENISH_STATUS_REPLENISH = 'sale-replenish-status.replenish',
	//销售单状态(已结算)
	SALE_SETTLE = 'sale.settle',
	//销售单状态(已作废)
	SALE_CHANGE = 'sale.change',
	//销售单状态(已出库)
	SALE_STOCK_IO = 'sale.stock-io',
	//销售单状态(已开单)
	SALE_CREATE = 'sale.create',
	//销售退货单状态
	SALE_RETURN_STATUS = 'sale-return-status',
	//销售类型
	SALE_TYPE = 'sale-type',
	//售价选择
    PRICE_SELECTION = "price-selection",
	//维修类型
	REPAIR_TYPE = 'repair-type', //之前 "repair-type",
	//车辆类型
	VEHICLE_TYPE = 'vehicle-type',
	//仓库类型
	CK_TYPE = 'warehouse-type', //之前"ck",
	//采购单状态
	PURCHASE = 'purchase',
	//采购单退货状态
	PURCHASE_RETURN = 'purchase-return',
	//销售单状态
	SALE = 'sale',
	//销售退货状态
	SALE_RETURN = 'sale-return-status',
	// 结算类型
	SETTLE_TYPE = 'settle-type',
	//单据类型
	TRADE_TYPE = 'trade-type',
	//维修单状态
	MAINTENANCE = 'maintenance',
	//车辆品牌
	VEHICLE_BRAND = 'vehicle-brand',
	//服务性质
	SERVICE_TYPE = 'service-nature', //之前"service-type",
	//服务地点
	SERVICE_ADDRESS = 'service-address',
	//三包单位
	CLAIMUNIT = 'claim-unit',
	//预约状态
	APPOINTMENT = 'appointment',
	//盘点单状态
	STOCKTAKING = 'stocktaking',
	//采购计划状态
	PURCHASE_PLAN = 'purchase-plan',
	// 已转接
	PURCHASE_PLAN_TRANSFER = 'purchase-plan.transfer',
	//是否直发(直发类型)
	DIRECT_TYPE = 'direct-flag',
	//物流方式
	LOGISTIC_MODE = 'logistics-mode',
	//组织机构
	ORG = 'cm-organization',
	//门店
	SHOP = 'cm-shop',
	//门店类型
	SHOP_TYPE = 'shop-type',
	//经济实体
	BE = 'cm-business-entity',
	//结算方
	SETTLE_PARTY = 'settle-party',
	//销售订单状态
	SALE_ORDER = 'sale-order',
	//收款人
	PAYEE = 'payee',
	//回访单状态
	VISIT_STATUS = 'visit-status',
	//回访方式
	RETURN = 'return',
	//
	//账户类型 （配件返利）
	ACC_TYPE_REBATE = "account-type.rebate",
	//账户类型 （油品返利）
	ACC_TYPE_REBATE_OIL = "account-type.rebate-oil",
	//账户类型 （投放）
	ACC_TYPE_MARKET = "account-type.credit-market",
	//账户类型 （配件投放）
	ACC_TYPE_DELIVERY = "account-type.credit-delivery",
	//账户类型 (月结)
	ACC_TYPE_Month = "account-type.credit-month",
	//账户类型 (临时授信)
	ACC_TYPE_Credit = "account-type.credit",
	//账户类型 (现金账户)
	ACC_TYPE_CASH = "account-type.cash",
	//账户类型（结转账户）
	ACC_TYPE_JZ = "account-type.jz",
	//账户类型（备品四方）
	ACC_TYPE_SPARE_AGREEMENT = "account-type.spare-agreement",
	//账户类型（油品四方）
	ACC_TYPE_OILS_AGREEMENT = "account-type.oils-agreement",
	//结算类型
	PAYEE_TYPE = 'settle-type',
	//结算类型 (挂账)
	SETTLE_TYPE_CREDIT = 'settle-type.credit',
	//结算类型 （现结）
	SETTLE_TYPE_CASH = 'settle-type.cash',
	//结算类型 （结转）
	SETTLE_TYPE_BALANCE = 'settle-type.balance',

	//盘点类型
	// TRADE_TYPE = "trade-type"
	//预售类型
	PRESALE_TYPE = 'presale-type',
	//预售类型(调配)
	PRESALE_TYPE_DEPLOYMENT = 'presale-type.deployment',
	//预售状态
	PRESALE_ORDER = 'presale-order',
	//仓库类别
	WAREHOUSE_CATEGORRY = 'warehouse-category',
	//调配单状态
	ALLOCATE = 'allocate',
	//维修方式
	MAINTENANCE_MODE = 'maintenance-mode',
	//销售方式
	SALES_TYPE = 'sales-type',
	//站点名称
	SITE = 'site',
	//公交车辆型号
	MODEL = 'model',
	//采购类型
	PURCHASE_TYPE = "purchase-type",
	//优惠类型
	PREFERENTIAL_TYPE = "preferential-type",
	//汇率
	EXCHANGE_RATE = "exchange-rate",
}

export const sourceAppOrBranch = {
	sourceApp: 'bcm',
	branch: 'bcm',
};
export const orgCodeOrName = {
	orgCode: 'bcm',
	orgName: 'bcm',
};
