import React, { useRef, useState } from "react";
import { Button, message } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from "@/components/YhFormBox/Types";
import useNavModel, { } from "@/model/navModel";
import { useSummaryCustomerDetailsModel } from "./summaryCustomerDetailsModels";
import { globalPrompt } from "@/components/message";

export const FormBox = () => {
    const { } = useSummaryCustomerDetailsModel();
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { selectedNavId, replaceNav} = useNavModel();
    const formRef = useRef<IYhFormRef>();

    const rightChildren = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { message.success("打印成功") }}
        >打印</Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => {
                globalPrompt("modal", {
                    title: "作废",
                    text: "采购单YHCY-ETR-01 ，是否确定作废？",
                    onOk: () => { },
                })
            }}
        >作废</Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { message.success("补单成功") }}
        >补单</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => replaceNav(selectedNavId, "2-1")}
        >保存</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => { message.success("刷新成功") }}
        >刷新</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => replaceNav(selectedNavId, "2-1")}
        >返回</Button>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        // rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工单单号",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="statu-blue" onClick={() => { }}>客户名称</span>,
                fieldName: "name",
                options: [{ label: "1", value: "1" }],
                rules: [{ required: true, message: "请选择客户" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "送修人",
                fieldName: "tel",
                rules: [{ required: true, message: "请输入送修人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "送车电话",
                fieldName: "tel",
                rules: [{ required: true, message: "请输入送车电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车牌号",
                fieldName: "tel",
                rules: [{ required: true, message: "请输入车牌号" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "VIN码",
                fieldName: "tel",
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工单状态",
                fieldName: "tel",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "服务类型",
                fieldName: "contact",
                options: [{ label: "1", value: "1" }],
                rules: [{ required: true, message: "服务类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                fieldName: "contact",
                options: [{ label: "1", value: "1" }],
                rules: [{ required: true, message: "请选择车辆类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "服务地点",
                fieldName: "contact",
                options: [{ label: "1", value: "1" }],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算方",
                fieldName: "contact",
                options: [{ label: "1", value: "1" }],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算方式",
                fieldName: "contact",
                options: [{ label: "1", value: "1" }],
                rules: [{ required: true, message: "请选择结算方式" }],
                span: 5,
                labelCol: { offset: 1 },
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "tel",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆里程",
                fieldName: "tel",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工作小时",
                fieldName: "contact",
                hidden: more
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "进厂时间",
                showTime: true,
                fieldName: "enterTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "出厂时间",
                showTime: true,
                fieldName: "enterTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "去零",
                fieldName: "contact",
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售实体",
                fieldName: "contact",
                hidden: more,
                options: [{ label: "1", value: "1" }],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "contact",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "故障描述",
                fieldName: "contact",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "处理意见",
                fieldName: "contact",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "contact",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "待修内容",
                fieldName: "contact",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "下次保养时间",
                fieldName: "contact",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "contact",
                hidden: more
            },
        ]
    };



    const onChange = (changedValues: { [key: string]: any }) => {
        console.log(changedValues);

    }
    const poFromConfig: IYhFormBox = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: {},
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}