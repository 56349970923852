import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMonitoringModel } from "../monitoringModel";
import { IMonitoringDetail } from "../monitoringService";
import { formatNum } from "@/utils/utils";
import "./style.css"
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTootip from "@/components/YhTootip";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = useMonitoringModel();
    const { getTreeNodeName } = useDictModel(() => []);
    useEffect(() => {
        loadData();
    }, [])

    const columns: ColumnsType<IMonitoringDetail> = [
        { title: '序号', width: 50, fixed: "left", align: "center", render: (t, r, i) => i + 1 },
        { title: "单据编号", width: 200, fixed: "left", dataIndex: "code",render: (text: any) => <YhTootip text={text} />,  },
        { title: "单据状态", width: 160, dataIndex: "status", render: text => getTreeNodeName(text) },
        { title: "单据类型", width: 160, dataIndex: "tradeTypeName" },
        {
            title: "主单",
            className: "main",
            children: [
                { title: "总数量", width: 160, dataIndex: "sumNum" },
                { title: "折前金额", width: 120, dataIndex: "amount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "优惠金额", width: 120, dataIndex: "discountTotalAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "应收/付", width: 120, dataIndex: "receivedAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "成本金额", width: 120, dataIndex: "costAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "毛利金额", width: 120, dataIndex: "profit", render: (text) => text ? '￥' + text : '￥0' },
                { title: "主单应收差异", width: 120, dataIndex: "masterReceivedAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "主单毛利差异", width: 120, dataIndex: "masterProfitDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "已收/付", width: 120, dataIndex: "receivableAmount", render: (text) => text ? '￥' + text : '￥0' },
            ]
        }, {
            title: "明细",
            children: [
                { title: "总数量", width: 160, dataIndex: "dnum" },
                { title: "折前金额", width: 120, dataIndex: "dbeforeAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "优惠金额", width: 160, dataIndex: "discountAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "应收/付", width: 120, dataIndex: "damount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "成本金额", width: 120, dataIndex: "dcostAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "毛利金额", width: 120, dataIndex: "dprofit", render: (text) => text ? '￥' + text : '￥0' },
                { title: "明细应收差异", width: 120, dataIndex: "detailVarianceAmount", render: (text) => text ? '￥' + text : '￥0' },
                { title: "明细毛利差异", width: 120, dataIndex: "detailVarianceProfit", render: (text) => text ? '￥' + text : '￥0' },
            ]
        }, {
            title: "主单对应明细",
            children: [
                { title: "总数量差异", width: 160, dataIndex: "sumNumDiff" },
                { title: "折前金额差异", width: 120, dataIndex: "amountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "优惠金额差异", width: 120, dataIndex: "discountAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "应收金额差异", width: 120, dataIndex: "receivedAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "成本金额差异", width: 120, dataIndex: "costAmountDiff", render: (text) => text ? '￥' + text : '￥0' },
                { title: "毛利金额差异", width: 120, dataIndex: "profitDiff", render: (text) => text ? '￥' + text : '￥0' },
            ]
        }

    ];

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            // rowSelection={{
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id),
            //     onContextMenu: (e) => {
            //         e.preventDefault();
            //         setSelectedRowKeys([record.id]);
            //     }
            // })}
            scroll={{ x: 'max-content', y: "calc(100vh - 360px)" }}
        />
    )
}
