import React from "react";
import { TopButs } from "./TopButs"
import { FormBox } from "./FormBox"
import { MaterialTableBox } from "./materialTableBox"
import { ItemTableBox } from "./itemTableBox"
import { FixedBottom } from "./FixedBottom"
import { MaterialModal } from "./materialModal"
import { ItemModal } from "./itemModal"



export const SummaryeDetails = () => {
    return (
        <>
            <TopButs />
            <FormBox />
            <ItemTableBox />
            <MaterialTableBox />
            <FixedBottom />
            <MaterialModal />
            <ItemModal />
        </>
    )
}