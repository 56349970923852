import React, {  } from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useWorkshopQueryModel } from "../workshopQueryModel";
import { DetailType } from "@/types/ScmEnums";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
export const DataBox = () => {
    const { selectedRow, selectedRowKeys, setDispatchVisible, setDetailType, finished,  } = useWorkshopQueryModel();
    const { selectedNavId, replaceNav,  } = useNavModel();
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "4-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            {
                id: selectedRow.maintenanceCode as string, mode: "look", parentInfo: { selectedNavId }
            },
            true
        );
    }
    const onFinished = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        globalPrompt("modal", {
            title: "工单完工",
            type: "success",
            text: "",
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    maintenanceCode: selectedRow.maintenanceCode,
                }
                finished(obj).then(res => {
                    globalPrompt("message", { text: "订单完工", type: "success" });
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })

    }
    const onDispatch = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setDispatchVisible(true)
        setDetailType(DetailType.edit)
    }
    const onDispatchLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setDispatchVisible(true)
        setDetailType(DetailType.look)

    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.DISP_LIST_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DISP_LIST_DISP}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onDispatch}
            >派工</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DISP_LIST_FINISH}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onFinished}
            >完工</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.DISP_LIST_VW_M}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onDispatchLook}
            >维修工查看</Button>
        </YhAuth>
    </>;

    return (
        <YhBox title="派工列表" action={action}>
            <DataTable />
        </YhBox>
    )
}