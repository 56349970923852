import React, { FC } from "react";
import { YhBox } from "@/components/YhBox";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { RouterParamType } from "@/model/navModel";
import { Description } from "@/components/YhBox/Description";
import { useDictModel } from "@/views/base/dict/dictModel";
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";

export const TopButs: FC<RouterParamType> = ({ id, mode }) => {
    const { getTreeNodeName } = useDictModel(() => []);
    const { editFormVal: { code, status } } = usePurchaseDrDetailsModel();


    const orderStatu: IDescription[] = [
        {
            label: "调入单号",
            value: code ?? "",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: getTreeNodeName(status ?? ""),
            color: DescriptionColorEnum.green,
            status: true
        },
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            hidden={mode === "edit" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}