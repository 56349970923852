import React from "react";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { YhBox } from "@/components/YhBox";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useSaleModel } from "./saleModel";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export const SaleSearchBox = () => {
    const {
        searchParam, updateSearchParam, initPgCfg, resetSearchParam, loadData
    } = useSaleModel();
    const {beShopDs} =useGlobalModel();
    const { getSelectedDictOne } = useDictModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "销售实体",
            fieldName: "bentityCode",
            options: beShopDs,
            allowClear: true,
            showSearch: true,
            xxl: 3,
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "单据状态",
            fieldName: "status",
            options: getSelectedDictOne(DictCodeEnmu.SALE),
            allowClear: true,
            showSearch: true,
            xxl: 3,
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS),
            allowClear: true,
            showSearch: true,
            xxl: 3,
        },{
            type: SearchTypeEnum.Input,
            placeholder: "销售单号",
            fieldName: "saleCode",
            xxl: 3,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "公交编码",
            fieldName: "materialNo",
            xxl: 3,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "商品编码",
            fieldName: "materialCode",
            xxl: 3,
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "商品名称",
            fieldName: "materialName",
            xxl: 3,
        }, {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
    ]
    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title=""
                justify="end"
                size="small"
                triggerSearch={true}
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                handleSearch={(params) => { loadData(initPgCfg, { ...searchParam, ...params }) }}
                handleReset={resetSearchParam}
            />
        </YhBox>
    )
}