import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { stockStatisticService, IOccupyFidles, IOccupyDetails } from "@/views/stock/statistic/stockStatisticService"
import useGlobalModel from "@/model/globalModel";
import { transPgToPagination } from "@/utils/utils";
import { useDictModel } from "@/views/base/dict/dictModel";
interface IOccupyStocType {
    visible: boolean,
    onCancel: () => void,
    materialCode: string
}
export const OccupyStockModel = ({
    visible,
    materialCode,
    onCancel,
}: IOccupyStocType) => {
    const { shopAndOrg } = useGlobalModel();
    const { getTreeNodeName } = useDictModel();
    const [searchParam, setSearchParam] = useState<IOccupyFidles>({})
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    const [dataSource, setDataSource] = useState<IOccupyDetails[]>([]);

    useEffect(() => {
        if (visible) {
            loadData(initPgCfg, { materialCode })
            setSearchParam({ materialCode })
        }
    }, [visible])
    const loadData = async (page?: ISearchPage, searchValue: IOccupyFidles = searchParam) => {
        let searchParams = {
            ...searchValue,
            shopId: shopAndOrg.shopId,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await stockStatisticService.busOccupy(searchParams)
        let { records, ...pg } = retData
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '单据编号',
            width: 120,
            dataIndex: 'code',
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'type',
        }, {
            title: '单据状态',
            width: 120,
            dataIndex: 'status',
            render: (text) => getTreeNodeName(text)
        }, {
            title: '占用数量',
            width: 120,
            dataIndex: 'num',
        }, {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
        }
    ]
    return <YHModal
        visible={visible}
        onCancel={onCancel}
        title="占用明细查看"
        width={1000}
        bodyHeight={700}
        showFooter={false}
    >
        <YhBox title={""}>
            <Table
                style={{ cursor: "pointer" }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize }).then();
                    }
                }}
                scroll={{ x: 'max-content', y: 520 }}
            >
            </Table>
        </YhBox>

    </YHModal>
}