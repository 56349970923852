import React, { useEffect, useState } from "react";
import { YhBox } from "@/components/YhBox";
import FontIcon from "@/components/YhIcon";
import { homeService, ITopFildes } from "./homeService"
import useGlobalModel from "@/model/globalModel";
import useNavModel from "@/model/navModel";
export const Header = () => {
    const { shopAndOrg } = useGlobalModel()
    const { selectedNavId, addNav, replaceNav } = useNavModel();
    let { shopId } = shopAndOrg
    const [topTotal, setTopTotal] = useState<ITopFildes>({
        "unsettledNum": 0,
        "warehousingNum": 0,
        "deliveryNum": 0,
        "excessInventory": 0,
        "stockShortage": 0,
        "returnNum": 0
    })
    useEffect(() => {
        homeService.top(encodeURI(encodeURI(shopId))).then(res => {
            let { retData } = res
            setTopTotal(retData)
        })
    }, [shopId])
    const gofollowUp = () => {
        replaceNav(
            selectedNavId,
            "8",
            {
                id: "", mode: "add", parentInfo: {
                    status: "visit-status.will"
                }
            },
            true)
    }
    return (
        <YhBox
            title=""
            // width="45%"
            style={{ marginBottom: 10, marginTop: 10, verticalAlign: 'top' }}
        // contentClass="pt-40 overflow-y-auto h-100"
        >
            <div className="header">
                <div className="header-item" onClick={() => addNav('1-1')} >
                    <FontIcon type="icon-jiesuan" style={{ color: "#fff" }} className="header-icon" />
                    <div className="header-amount">
                        <span className="header-num">{topTotal.unsettledNum}单</span>
                        <span className="header-status">待结算</span>
                    </div>
                </div>
                <div className="header-item" onClick={() => addNav('1-2')}>
                    <FontIcon type="icon-ruku" style={{ color: "#fff" }} className="header-icon" />
                    <div className="header-amount">
                        <span className="header-num">{topTotal.warehousingNum}单</span>
                        <span className="header-status">待入库</span>
                    </div>
                </div>
                <div className="header-item" onClick={() => addNav('1-3')} >
                    <FontIcon type="icon-chuku" style={{ color: "#fff" }} className="header-icon" />
                    <div className="header-amount">
                        <span className="header-num">{topTotal.deliveryNum}单</span>
                        <span className="header-status">待出库</span>
                    </div>
                </div>
                {/* <div className="header-item" onClick={() => gofollowUp()}>
                    <FontIcon type="icon-huifang" style={{ color: "#fff" }} className="header-icon" />
                    <div className="header-amount">
                        <span className="header-num">{topTotal.returnNum}单</span>
                        <span className="header-status">待回访</span>
                    </div>
                </div> */}
                <div className="header-item">
                    <FontIcon type="icon-dikucunstock" style={{ color: "#fff" }} className="header-icon" />
                    <div className="header-amount">
                        <span className="header-num">{topTotal.excessInventory}</span>
                        <span className="header-status">库存不足</span>
                    </div>
                </div>
                <div className="header-item">
                    <FontIcon type="icon-ico_kufangguanli_wupinkucunyujing-" style={{ color: "#fff" }} className="header-icon" />
                    <div className="header-amount">
                        <span className="header-num">{topTotal.stockShortage}</span>
                        <span className="header-status">库存超储</span>
                    </div>
                </div>
            </div>
        </YhBox>
    )
}