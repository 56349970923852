import React from "react";
import { Button, message } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useEntityModel, BeOptModeEnum } from "../entityModel";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { YhAuth } from "@/components/auth/YhAuth";


export const DataBox = () => {
    const {
        selectedRowKeys,
        drawerVisible,
        setOptMode,
        setDrawerVisible,
        syncData
    } = useEntityModel();
    const onLook = () => {
        if (selectedRowKeys.length != 1) {
            message.error("请选择一条数据!")
        } else {
            setOptMode(BeOptModeEnum.DETAIL);
            setDrawerVisible(!drawerVisible)
        }
    }
    const action = <>

        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >查看</Button>
        <YhAuth resCode={ResourceAuthEnum.ORG_ENTITY_SYNC}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={syncData}
            >同步</Button>
        </YhAuth>
    </>

    return (
    <YhBox title="经营实体管理列表" action={action}>
        <DataTable />
    </YhBox>
)
}
