import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { IRet } from "../types/ScmTypes";
import {
    CLIENT_APP_CODE,
    INTERNAL_SERVER_ERROR_CODE,
    getToken,
    getBaseRequestUrl, DEFAULT_REQUEST_TIMEOUT
} from "./constants";
import { message } from "antd";
import moment from "moment";
import storage from 'good-storage';

type errData = {
    url?: string
    code?: string | number
    time?: string
    data?: string
    error?: string
}

class HttpRequest {

    private inst: AxiosInstance;
    private url: string = "";

    constructor(config: AxiosRequestConfig = {}) {
        let defaultConfig = {
            baseURL: getBaseRequestUrl(),
            timeout: DEFAULT_REQUEST_TIMEOUT,
        }
        this.inst = axios.create({ ...defaultConfig, ...config })
        this.interceptRequest();
        this.interceptResponse();
    }

    private interceptRequest() {
        this.inst.interceptors.request.use(
            config => {
                this.url = config.url as string
                // config.headers.Authorization = getToken();
                config.headers.Authorization = config.headers.token ?? getToken();
                return config;
            },
            error => Promise.reject(error)
        )
    }

    private interceptResponse() {
        this.inst.interceptors.response.use(
            (response) => response,
            (error) => {
                let res = error.response as AxiosResponse;
                let addErr: errData = { url: this.url, time: moment().format('YYYY-MM-DD HH:mm:ss'), }
                if (res) {
                	addErr.data = res.config.data
					addErr.code = res.status
                    let retData = res.data as IRet<any>
                    message.error(`code:${res.status} msg:${retData.retMsg} retData:${retData.retData}`)
                } else {
                    addErr.code = 'not response'
                    addErr.error = error.message
                    if(error && error.message && error.message === 'Network Error'){
                        message.error(`网络连接失败`)
                    }else{
                        message.error('服务器响应超时，请尝试重新请求')
                    }
                }
                let requestErrData: errData[] = storage.get('requestErrData') || []
                requestErrData.unshift(addErr)
                storage.set('requestErrData', requestErrData.slice(0, 50))
                return Promise.reject(error);
            }
        )
    }

    private responseTypeCheck(res: AxiosResponse) {
        const resType = res.headers["content-type"];
        if (resType !== "application/json") {
            console.log("只支持json返回数据!");
            //.....
        }
    }

    async request<T>(config: AxiosRequestConfig) {
        let ret = await this.inst.request<IRet<T>>(config);
        this.responseTypeCheck(ret);
        return ret.data;
    }

    async post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
        let ret = await this.inst.post<IRet<T>>(url, data, config);
        this.responseTypeCheck(ret);
        return ret.data;
    }
    async postFile<T>(url: string, data?: any, config?: AxiosRequestConfig) {
        let ret = await this.inst.post<IRet<T>>(url, data, { ...config, responseType: "blob" });
        return ret;
    }
    async get<T>(url: string, config?: AxiosRequestConfig) {
        let ret = await this.inst.get<IRet<T>>(url, config);
        this.responseTypeCheck(ret);
        return ret.data;
    }
    async getFile<T>(url: string, config?: AxiosRequestConfig) {
        let ret = await this.inst.get<T>(url, { ...config, responseType: "blob" });
        return ret;
    }
    async put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
        let ret = await this.inst.put<IRet<T>>(url, data, config);
        return ret.data;
    }

    async delete<T>(url: string, config?: AxiosRequestConfig) {
        let ret = await this.inst.delete<IRet<T>>(url, config);
        return ret.data;
    }

    async head<T>(url: string, config?: AxiosRequestConfig) {
        let ret = await this.inst.head<IRet<T>>(url, config);
        return ret.data;
    }

    async options<T>(url: string, config?: AxiosRequestConfig) {
        let ret = await this.inst.options<IRet<T>>(url, config);
        return ret.data;
    }

    async patch<T>(url: string, data?: any, config?: AxiosRequestConfig) {
        let ret = await this.inst.patch<IRet<T>>(url, data, config);
        return ret.data;
    }
}

export const httpRequest = new HttpRequest();