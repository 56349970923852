import { useState, Key, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import useGlobalModel from "@/model/globalModel";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { IMaterialDetail, IMaterialSearchFields, materialService } from "../../../../base/material/materialService"
import { IPurchasePoFields, IDetailList } from "../../purchasePoType"
import { IVRMshelfDetail } from "@/views/base/warehouse/warehouseTypes";
import { purchasePoService } from "../../purchasePoService";
import { ISearchPage } from "@/types/ScmTypes";
import { transPgToPagination, formatNum ,bigNumber} from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { convertRes2Blob } from "@/utils/exportFile";
import { IStockStatisticDetail } from "@/views/stock/statistic/stockStatisticService";
import { useLockFn } from "ahooks";
import { message } from "antd";

export type IVrmPlanDescType = {
    numAll: number,
    itemNumAll: number,
    totalAll: number,
    [key: string]: any
}
//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IMaterialDetail {
    num: number,
    amount: number,
    confirmNum?: number,    // 0,
    damageNum?: number,    // 0,
    isDefault?:string
    // unarrivedNum?: number,    // 0,
}
export const usePurchasePoDetailsModel = createModel(function () {
    const { user, shopAndOrg, beShopDs } = useGlobalModel()
    const { shopId, shopName,shopCode } = shopAndOrg
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)  //新增商品弹框
    const [settleVisible, setSettleVisible] = useState<boolean>(false);   //结算弹框
    const [changeSaleDrawer, serChangeSaleDrawer] = useState<boolean>(false);  //转销售单弹框
    const [wsConfirmModal, setWsConfirmModal] = useState<boolean>(false);    //库房确认弹框
    const [registerModal, setRegisterModal] = useState<boolean>(false);     //入库登记弹框

    const initDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0 //总计
    }
    const initForm = { shopId, shopName,shopCode }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initSearchParam: IMaterialSearchFields = {};
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IPurchasePoFields>(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState<IDetailList[]>([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState<Key[]>([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState<IDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<IVrmPlanDescType>(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IPurchasePoFields>({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState<IDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<IVrmPlanDescType>(initDesc)

    //详情数据
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState<IDetailList[]>([]);
    const [specilDataSource, setSpecilDataSource] = useState<IDetailList[]>([]);
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState<Key[]>([]);
    const [detailDataRow, setDetailDataRow] = useState<IDetailList[]>([]);
    const [detailEditForm, setDetailEditForm] = useState<IPurchasePoFields>({})
    const [editDesc, setEditDesc] = useState<IVrmPlanDescType>(initDesc)

    //配件列表配置
    const [workDataSource, setWorkDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkData, setSearchWorkData] = useState<IMaterialSearchFields>(initSearchParam)
    const [workPgCfg, setWorkPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataSource, setAddDataSource] = useState<IMaterialDetailModel[]>([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode, shopName },
        edit: detailEditForm,
        look: detailLookForm
    }

    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState<IVRMshelfDetail[]>([]);
    //设置底部总数展示（查看|编辑|查看）
    useEffect(() => {
        let newDesc: IVrmPlanDescType = { ...initDesc };
        detailDataSource && detailDataSource.forEach(item => {
            newDesc.numAll  = bigNumber.add(newDesc.numAll, Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll, bigNumber.times(Number(item.price),Number(item.num)))
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.price) * Number(item.num);
        });
        newDesc.itemNumAll = detailDataSource && detailDataSource.length
        setEditDesc(newDesc);
    }, [detailDataSource])

    useEffect(() => {
        let newDesc: IVrmPlanDescType = { ...initDesc };
        detailAddList && detailAddList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll, Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,bigNumber.times(Number(item.num),Number(item.price)))
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.num) * Number(item.price);
        });
        newDesc.itemNumAll = detailAddList && detailAddList.length
        setAddDesc(newDesc);
    }, [detailAddList])

    useEffect(() => {
        let newDesc: IVrmPlanDescType = { ...initDesc };
        detailLookList && detailLookList.forEach(item => {
            newDesc.numAll = bigNumber.add(newDesc.numAll ,Number(item.num))
            newDesc.totalAll = bigNumber.add( newDesc.totalAll,bigNumber.times(Number(item.num),Number(item.price)))

            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.num) * Number(item.price);
        });
        newDesc.itemNumAll = detailLookList && detailLookList.length
        setLookDesc(newDesc);
    }, [detailLookList])

    //新增表单缓存
    const updateAddFormVal = (val: IPurchasePoFields) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val: IPurchasePoFields) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await purchasePoService.one(id);
        // return console.log(retData)
        let { details,md5, fileName, ...from } = retData
        const settleDetails = retData.settleDetails
        let cashFileList: any = []
        let fileList: any = []
        if (settleDetails && settleDetails.length > 0) {
            const { cashAttach, cashAttachFileName } = settleDetails[0]
            if(cashAttach && cashAttachFileName){
                cashFileList = [
                    {
                        uid: cashAttach,
                        status: 'done',
                        name: cashAttachFileName,
                        response: {
                            retData: cashAttach,
                        },
                    },
                ]
            }
        }
        if(md5 && fileName){
            fileList = [
                {
                    uid: md5,
                    status: 'done',
                    name: fileName,
                    response: {
                        retData: md5,
                    },
                },
            ]
        }
        from.cashFileList = cashFileList
        from.fileList = fileList
        if (retData && pattern === "edit") {
            setDetailDataSource(details as IDetailList[] ?? [])
            setDetailEditForm(from)
            setTopButsControl(false)
        } else if (retData && pattern === "look") {
            setDetailLookForm(from);
            setDetailLookList(details as IDetailList[] ?? [])
        }
    }
    //关闭弹框
    const resetWorkloadData = async () => {
        setSearchWorkData({});
        workloadData(initPgCfg, {});
    }
    //加载配件数据
    const workloadData = async (page?: ISearchPage, searchValue: IMaterialSearchFields = searchWorkData) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            enable: 1,
            pageSize: page?.pageSize ?? workPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await materialService.page(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            let { latestPurPrice, purchasePrice, materialDetal } = item
            let newPurchasePrice = materialDetal && materialDetal?.[0]?.purchasePrice ? materialDetal?.[0]?.purchasePrice as number :purchasePrice?purchasePrice: 0
            return {
                ...item,
                num: 1,
                purchasePrice: newPurchasePrice,
                // amount: newPurchasePrice * 1,
                amount:+bigNumber.toFixed(bigNumber.times(newPurchasePrice,1),2),
                icon: materialDetal && materialDetal?.length !== 0 && materialDetal?.length > 1 ? 1 : 0,
                warehouseCode: materialDetal?.[0]?.warehouseCode as string,
                warehouseName: materialDetal?.[0]?.warehouseName as string,
                shelfCode: materialDetal?.[0]?.shelfCode as string,
                shelfId: materialDetal?.[0]?.shelfId as string,
            }
        })
        setWorkPgCfg(transPgToPagination(pg));
        setWorkDataSource(newRecords);
    }
    //筛选模块详情 详情和新增的列表已有数据的方法
    const modelScreenMethod = async (modelList: IMaterialDetailModel[], detailList: IDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IMaterialDetailModel[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.materialCode === Item.code);
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    confirmNum: 0,
                    damageNum: 0,
                    materialCode: Item.code ?? Item.materialCode,
                    materialName: Item.name ?? Item.materialName,
                    // materialNameEn:Item.nameEn ?? Item.materialNameEn,
                    price: Item.purchasePrice ?? Item.price,
                    id: "",
                    // warehouseName:
                });
            } else {
                repetition.push(Item.name);
            }
        })
        console.log(newDetailData)
        return { newDetailData, repetition }
    }
    //添加到详情列表
    const transformsToPoDetailList = async (mode: string) => {
        //debugger
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailAddList)
            let newData:any=[]
            if(typeForm[mode].purchaseTypeCode == 'purchase-type.zf'){
                newData=newDetailData.map((item)=>{
                    return{...item,
                        preTaxAmount:item.price,
                        taxAmount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),0.13),item.num),2),
                        price:+bigNumber.toFixed(bigNumber.times((item.price as number),1.13),2),
                        amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),1.13),item.num),2),
                    }
                })
            }else{newData=newDetailData}
            setDetailAddList([...detailAddList, ...newData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelScreenMethod(addDataSource, detailDataSource)
            let newData:any=[]
            if(typeForm[mode].purchaseTypeCode == 'purchase-type.zf'){
                newData=newDetailData.map((item)=>{
                    return{...item,
                        preTaxAmount:item.price,
                        taxAmount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),0.13),item.num),2),
                        price:+bigNumber.toFixed(bigNumber.times((item.price as number),1.13),2),
                        amount:+bigNumber.toFixed(bigNumber.times(bigNumber.times((item.price as number),1.13),item.num),2),
                    }
                })
            }else{newData=newDetailData}
            setDetailDataSource([...detailDataSource, ...newData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
        let newData = addDataSource;
        const index = newData.filter((item) =>{      
            return !item.warehouseCode
             item={...item,isDefault:"0"}
        })
        setSpecilDataSource(index)
    }
    //删除详情列表数据
    const detailDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.materialCode))
            setDetailAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetaiAddDataRowKeys([]);
            setDetaiAddDataRow([]);
        } else if (mode === "edit") {
            const filterDetailList = detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.materialCode))
            setDetailDataSource(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailDataRowKeys([]);
            setDetailDataRow([]);
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailDataSource : detailAddList;
        let { totalAll } = mode === "edit" ? editDesc : addDesc;
        totalAll = +formatNum(totalAll);//采购总金额
        let goodsTotal: number = 0;
        goods.forEach(item => {
            let { amount } = item as { amount: number }
            // goodsTotal += amount
            goodsTotal= bigNumber.add(goodsTotal,amount)
        })
        if (+formatNum(goodsTotal) !== totalAll) {
            message.warning(`详情采购总金额${+formatNum(goodsTotal)}与表单采购总金额不符`);
            return false;
        }
        return true
    }
    //新增采购单
    const insertDetail = useLockFn(async (params: IPurchasePoFields) => {
        let newParams = {
            ...params,
            details: detailAddList,
            createUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchasePoService.insert(newParams);
        return retData;
    })
    //编辑采购单
    const editDetail = useLockFn(async (params: IPurchasePoFields) => {
        let newParams = {
            ...params,
            details: detailDataSource,
            updateUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchasePoService.update(newParams);
        return retData;
    })
    //转销售单
    const insertSaleDetail = async (params: IPurchasePoFields) => {
        let newParams = {
            ...params,
            createUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchasePoService.transfer(newParams);
        return retData;
    }
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type: string) => {
        setDetailAddList([]);
        setDetailAddForm({})
    }
    const resetEditCatch = async (type: string) => {
        setDetailEditForm({});
        setDetailDataSource([]);
    }
    const downloadTpl = async () => {
        let result = await purchasePoService.downloadTpl();
        convertRes2Blob(result)
    }
    const warehousing = async () => {
        let { id, purchaseCode } = detailEditForm
        let paramas = {
            id,
            purchaseCode,
            storekeeper: user.username
        }
        let { retData } = await purchasePoService.warehousing(paramas);
        return retData
    }
    const onSettle = async (params: object) => {
        let { id, purchaseCode } = detailEditForm
        let paramas = {
            id,
            purchaseCode,
            reckoner: user.username,
            ...params
        }
        let { retData } = await purchasePoService.settle(paramas);
        return retData
    }
    const supplementInsert = async (params: IPurchasePoFields) => {

        let newParams = {
            ...params,
            details: detailAddList,
            createUser: user.username,
            ...shopAndOrg,
            ...sourceAppOrBranch,
        }
        let { retData } = await purchasePoService.supplementInsert(newParams);
        return retData;
    }
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [supplierHistoryVisible, setSupplierHistoryVisible] = useState<boolean>(false) //消费记录展示
    const [supplierHistoryModel, setSupplierHistoryModel] = useState<{ supplierName: string }>({ supplierName: "" }) //消费记录参数
    return {
        // data
        addModalVisible,
        settleVisible,
        changeSaleDrawer,
        wsConfirmModal,
        registerModal,
        initPgCfg,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        addDesc,
        detailLookForm,
        detailLookList,
        lookDesc,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        editDesc,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        shelfAllList,
        detaiAddDataRowKeys,
        // model
        setAddModalVisible,
        setSettleVisible,
        serChangeSaleDrawer,
        setWsConfirmModal,
        setRegisterModal,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setAddDesc,
        setDetailLookForm,
        setDetailLookList,
        setLookDesc,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setEditDesc,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetWorkloadData,
        workloadData,
        transformsToPoDetailList,
        detailDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        setShelfAllList,
        setDetaiAddDataRowKeys,
        downloadTpl,
        modelScreenMethod,
        warehousing,
        onSettle,
        initForm,
        topButsControl, setTopButsControl,
        supplementInsert,
        insertSaleDetail,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        supplierHistoryVisible, setSupplierHistoryVisible,
        supplierHistoryModel, setSupplierHistoryModel,
        formComputeRule,
        specilDataSource,
        setSpecilDataSource
    }
});