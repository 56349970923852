import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
export default function Material() {
    return (
        <>
            <PageHeader />
            <DataBox />
        </>
    )
}