import React, { FC } from "react";
import {  Table, Tooltip } from "antd";
import { YhBox } from "@/components/YhBox";
import { ColumnsType } from "antd/es/table";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";
import { RouterParamType } from "@/model/navModel";
import { IItemDetailList } from "../../followUpType"

export const ItemTableBox: FC<RouterParamType> = ({ id, mode }) => {
    const {
        detailItemEditList,
        detailItemLookList
    } = usefollowUpDetailsModel();


    const columns: ColumnsType<IItemDetailList> = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => <>{i + 1}</>
        }, {
            title: '项目编码',
            width: 120,
            dataIndex: 'itemCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '项目名称',
            width: 140,
            dataIndex: 'itemName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '工时',
            width: 160,
            dataIndex: 'workHour',
        }, {
            title: '单价',
            width: 160,
            dataIndex: 'price',
        }, {
            title: '工时费',
            width: 160,
            dataIndex: 'itemAmount',
        }, {
            title: '折扣',
            width: 120,
            dataIndex: 'discountRate',
            render: (text: string) => text + "%"
        }, {
            title: '小计',
            width: 160,
            dataIndex: 'receivableAmount',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];
    const typeItemList = {
        "add": [],
        "edit": [...detailItemEditList],
        "look": [...detailItemLookList],
    }
    return (
        <YhBox title="维修项目" style={{ marginBottom: "5px" }}>
            <Table
                rowKey={record => record.id as string}
                columns={columns}
                dataSource={typeItemList[mode] as IItemDetailList[]}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}