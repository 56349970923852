import { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { ISearchPage } from '@/types/ScmTypes';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants';
import { purchaseDrService } from './purchaseDrService';
import useGlobalModel from '@/model/globalModel';
import {
	ISCMPurchaseDrSearchFields,
	ISCMPurchaseDrDetail,
	ISCMPurchaseDrStatistics,
} from './purchaseDrTypes';
import { convertRes2Blob } from '@/utils/exportFile';
import { transPgToPagination, bigNumber } from '@/utils/utils';
import { isArray } from 'util';

export const usePurchaseDrModel = createModel(function () {
	const {
		user: { shopId, shopCode },
		setLoadingInfo,
	} = useGlobalModel();
	//查询条件
	const [searchParam, setSearchParam] = useState<ISCMPurchaseDrSearchFields>({});
	//列表数据源
	const [dataSource, setDataSource] = useState<ISCMPurchaseDrDetail[]>([]);
	//表格选中行Key值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	//列表数据源分页参数
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//列表统计展示数据
	const initStatistics = {
		totalAmount: '0.00',
		totalNum: 0,
	};
	const [statistics, setStatistics] = useState<ISCMPurchaseDrStatistics>(initStatistics);
	const [loading, setLoading] = useState<boolean>(false);
	const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<ISCMPurchaseDrDetail>();

	//const [statusValue,setstatusValue] = useState<any>([])

	const updateSearchParam = (updateParam: ISCMPurchaseDrSearchFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};

	//切换门店重置缓存
	useEffect(() => {
		setSearchParam({});
		setSelectedRowKeys([]);
	}, [shopId]);

	//重置
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};

	const loadData = async (
		page?: ISearchPage,
		searchValue: ISCMPurchaseDrSearchFields = searchParam
	) => {
		
		
		setLoading(true);
		const searchParams = {
			shopCode,
			...searchValue,
			status: isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status,
			pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
			pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
		};
		let { retData } = await purchaseDrService.page(searchParams);
		const { records, ...pg } = retData;
		let newPg = { ...pg, pageNum: pg.current }
		setPgCfg(transPgToPagination(newPg));
		setDataSource(records);
		setLoading(false);
		loadTotal(searchParams);



		console.log('records',records)
		
	};
	const loadTotal = async (searchParams: any) => {
		let { retData: statistics } = await purchaseDrService.pageStatistics(searchParams);
		setStatistics(statistics ?? initStatistics);
	}
	//导出
	const exportPurchaseDr = async (searchValue: ISCMPurchaseDrSearchFields = searchParam) => {
		setLoadingInfo({ loadingVisible: true, txt: '快速导出中' });
		try {
			const searchParams = {
				inAndOut: '0',
				shopCode,
				...searchValue,
				status: isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status,
				pageSize: -1,
				pageNum: 1,
			};
			let result = await purchaseDrService.export(searchParams);
			result && setLoadingInfo({ loadingVisible: false, txt: '' });
			convertRes2Blob(result);
		} catch {
			setLoadingInfo({ loadingVisible: false, txt: '' });
		}
	};
	const exportStatisticsExport = async (searchValue: ISCMPurchaseDrSearchFields = searchParam) => {
		setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
		try {
			const searchParams = {
				inAndOut: "0",
				shopCode,
				...searchValue,
				status: isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status,
				pageSize: -1,
				pageNum: 1,
			}
			let result = await purchaseDrService.statisticsExport(searchParams);
			result && setLoadingInfo({ loadingVisible: false, txt: "" });
			convertRes2Blob(result);
		} catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
	}
	return {
		/***field***/
		searchParam,
		dataSource,
		selectedRowKeys,
		pgCfg,
		statistics,
		loading,
		/***method***/
		setDataSource,
		setSelectedRowKeys,
		resetSearchParam,
		updateSearchParam,
		loadData,
		exportPurchaseDr,
		exportStatisticsExport,
		//setstatusValue,statusValue
		setHistoryRow,setHistoryVisible
	};
});
