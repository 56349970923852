import React, { useRef } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { usefollowUpDetailsModel } from "./followUpDetailsModels";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel from "@/model/globalModel";


export function ReturnDrawer() {
    const {
        returnDrawer: visible,
        detailType,
        setDetailType,
        setReturnDrawer,
        detailEditForm,
        getDetailEditOne,
        detailReturnDetailsLookList,
        detailReturnDetailsEditList,
        editFollowUp
    } = usefollowUpDetailsModel();
    const { user } = useGlobalModel()

    const formRef = useRef<IYhFormRef>();
    const { getSelectedDictOne } = useDictModel();
    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {

    }

    const onCancel = () => {
        setReturnDrawer(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            if (detailEditForm.status !== "visit-status.done") {
                try {
                    editFollowUp({ ...res, maintenanceCode: detailEditForm.maintenanceCode })
                    message.success("回访成功");
                    setDetailType("look");
                    onCancel();
                    getDetailEditOne(detailEditForm.maintenanceCode as string, "edit");
                } catch (error) {
                    onCancel()
                }
            } else {
                message.success("该单据已回访");
                onCancel()
            }

        }).catch(err => {
            // message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = (val: any) => {

    }

    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        disabled: detailType === "look" || detailEditForm.status === "visit-status.done",
        formValues: detailType === "edit" ? { ...detailReturnDetailsEditList[0], createUser: user.username } : { ...detailReturnDetailsLookList[0] },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "回访人员",
                fieldName: "createUser",
                rules: [{ required: true, message: "该项为必填项" }],
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "回访日期",
                fieldName: "createTime",
                span: 24,
                disable: true,
                // rules: [{ required: true, message: "该项为必填项" }],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "回访方式",
                fieldName: "returnTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.RETURN),
                span: 24,
                rules: [{ required: true, message: "该项为必填项" }],
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户反馈",
                fieldName: "feedback",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户建议",
                fieldName: "proposal",
                span: 24,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "跟踪内容",
                fieldName: "trackContent",
                span: 24,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "是否解决",
                fieldName: "isSolved",
                options: [{ label: "是", value: "1" }, { label: "否", value: "0" }],
                span: 24,
            }
        ]
    };
    return (
        <YHDrawer
            {...{
                title: '回访内容',
                visible,
                onCancel,
                showFooter: detailType !== "look",
                okText: "保存",
                onOk: onSubmit
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
