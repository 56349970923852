import React, { useEffect, useState } from "react";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { Table, Button, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { momentFormat } from "@/utils/utils";
import { IDetailsFindPageField } from "../stock/delivery/stockOutDispatchType";
import { stockOutDispatchService } from "../stock/delivery/stockOutDispatchService";
import useGlobalModel from "@/model/globalModel";
import { ShopStock } from "./shopStock"

interface IHistoryModelType {
    visible: boolean,
    materialCode: string,
    materialName: string,
    isTransit?: boolean,
    onCancel: () => void
}
export const HistoryModel = ({ //采购销售历史通用查询弹框
    visible,
    materialCode,
    materialName,
    isTransit = true,
    onCancel,
}: IHistoryModelType) => {
    const { shopAndOrg: { shopId } } = useGlobalModel()
    const [dataSource, setDataSource] = useState<IDetailsFindPageField[]>([])
    const [type, setType] = useState("purchase")
    useEffect(() => {
        visible && getStockStatistic("trade-type.purchase,trade-type.purchase-return,transfer.in.doc")
    }, [visible])
    const getStockStatistic = async (type: string) => {
        let params = {
            pageSize: 1000,
            pageNum: 1,
            tradeTypeCode: type,
            materialCode,
            shopId
        }
        let { retData } = await stockOutDispatchService.detailsFindPage(params)
        const { records } = retData;
        setDataSource(records)
    }
    const action = <>
        {isTransit && <Button
            className={`mr-5 ${type === "allShop" ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => { getStockStatistic("trade-type.purchase,trade-type.purchase-return,transfer.in.doc"); setType("purchase") }}
        >采购历史</Button>}
        <Button
            className={`mr-5 ${type === "allShop" ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => { getStockStatistic("trade-type.sale,trade-type.sale-return,trade-type.maintenance,transfer.out.doc,trade-type.maintenance.return.material,trade-type.maintenance.return,trade-type.repair.return.parts,trade-type.repair.borrowed.parts"); setType("sale") }}
        >销售历史</Button>
        <Button
            className={`mr-5 ${type === "allShop" ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => { getStockStatistic("trade-type.transit-presale,trade-type.transit-prunsale"); setType("sale") }}
        >预售历史</Button>
        <Button
            className={`mr-5 ${type === "allShop" ? "tab-buts-active" : ""}`}
            type="link"
            shape="round"
            onClick={() => { setType("allShop") }}
        >各门店库存</Button>
    </>
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            width: 60,
            fixed: "left",
            render: (t, r, i) => i + 1
        },
        {
            title: '单据编号',
            width: 160,
            fixed: "left",
            dataIndex: 'tradeCode',
        },
        {
            title: '单据类型',
            width: 160,
            fixed: "left",
            dataIndex: 'tradeTypeName',
        },
        {
            title: '制单时间',
            width: 160,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        },
        {
            title: type === "purchase" ? "供应商名称" : "客户名称",
            width: 160,
            dataIndex: 'userName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            title: '单价',
            width: 120,
            dataIndex: 'price',
        },
        {
            title: '数量',
            width: 80,
            dataIndex: 'num',
        },
        {
            title: '折扣',
            width: 80,
            dataIndex: 'discountRate',
            render: (text) => text + "%"
        },
        {
            title: '总计',
            width: 80,
            dataIndex: 'amount',
        },
    ]
    return <YHModal
        visible={visible}
        title={`商品名称:${materialName} 商品编码：${materialCode}`}
        onCancel={onCancel}
        width={1000}
        bodyHeight={700}
        showFooter={false}
    >

        <YhBox title={action}>
            {type !== "allShop" ? <Table
                style={{ cursor: "pointer" }}
                rowKey={record => record.id as string}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 'max-content', y: 550 }}
                pagination={false}
            /> : <ShopStock
                    materialCode={materialCode}
                    materialName={materialName}
                />}

        </YhBox>
    </YHModal>
}