import { message, Tooltip } from "antd";
import React, { FC, ReactNode } from "react";
import copy from "copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";

export interface IYhTootip {
  placement?: object,
  isCopy?: boolean,
  text: string,
  style?: object,
  onClick?: (e: any) => void,
  onMouseOver?:(e: any) => void,
  onMouseLeave?:(e: any) => void,
}

const YhTootip: FC<IYhTootip> = ({
  placement,
  isCopy = true,
  text,
  style,
  onClick,
  onMouseOver,
  onMouseLeave
}) => {
  const oSpan = <span style={{ cursor: "copy" }} onClick={(e) => {
    e.stopPropagation();
    if (isCopy) {
      copy(text);
      message.success("复制成功")
    }
  }}>
    <span >{text}</span>&nbsp;&nbsp;{isCopy && <CopyOutlined />}
  </span>

  return <Tooltip placement="topLeft" title={oSpan}><p className={`ellipsis ${onClick ? "click-span" :style?style: ''}`} onClick={(e) => { e.stopPropagation(); onClick?.(e) }}  onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>{text} </p></Tooltip>
}
export default YhTootip