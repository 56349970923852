import React, { useEffect } from "react";
import { Button, Dropdown, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useStockCheckModel } from "../stockCheckModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useStockCheckDetailModel } from "./details/stockCheckDetailsModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { DownOutlined } from "@ant-design/icons";

export const DataBox = () => {
    const { selectedRow, selectedRowKeys, invalidateData, exportStockCheck, setSelectedRowKeys,exportDetialtockCheck } = useStockCheckModel();
    const { resetEditCatch } = useStockCheckDetailModel();
    const { selectedNavId, replaceNav, addNav, findNav, selectNav, switchNavState } = useNavModel();

    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let saleOrderEditKey = "9-2-2";
        let isFindNav = findNav(saleOrderEditKey);
        if (!isFindNav) {
            addNav(saleOrderEditKey, { id: selectedRow.stocktakingCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "盘库管理跳转详情",
                type: "warning",
                text: "您还有盘库管理详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleOrderEditKey, { id: selectedRow.stocktakingCode as string, mode: "edit" });
                    resetEditCatch("edit")
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "9-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.stocktakingCode as string, mode: "look", },
            true
        );
    }
    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let val: string = ""
        globalPrompt("modal", {
            title: "盘点单作废",
            type: "error",
            text: "",
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    stocktakingCode: selectedRow.stocktakingCode,
                }
                invalidateData(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })

    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportStockCheck().then()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => exportDetialtockCheck().then()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav('9-2-1', { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        {/* <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_CANCEL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onInvalidate}
            >作废</Button>
        </YhAuth> */}
        <YhAuth resCode={ResourceAuthEnum.ST_STTAKE_EX}>
            {/* <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => exportStockCheck().then()}
            >导出</Button> */}
        <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown>
        </YhAuth>
    </>;

    return (
        <YhBox title="库存盘点列表" action={action}>
            <DataTable />
        </YhBox>
    )
}