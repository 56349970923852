import React, { useEffect, useRef, useState, FC } from "react";
import { Button, message } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { getFormValidatorErrText, switchNum1, formatNum } from "@/utils/utils"
import { useDictModel } from "@/views/base/dict/dictModel";
import { useInternalDetailsModel } from "./internalDetailsModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { IInternalDetail } from "../../internalTypes";
import useGlobalModel from "@/model/globalModel";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { debounce } from "lodash";
import { internalService } from "../../internalService";

export const FormBox: FC<RouterParamType> = ({ id, mode }) => {
    const formRef = useRef<IYhFormRef>();
    const { fetchFmsShopList, accUnitDs, user: { shopId, userName, shopCode, shopName }, shopDs } = useGlobalModel(() => []);
    const {
        editFormVal, addFormVal,
        addGoodsList,
        editGoodsList,
        resetEditCatch,
        resetAddCatch,
        updateEditFormVal,
        updateAddFormVal,
        allotLoadData,
        saveInternal,
        getInternalDetailOne,
        audit
    } = useInternalDetailsModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { getSelectedDictOne, getTreeNodeOne, getTreeSelectOne } = useDictModel(() => []);
    const [inUnitBeList, setinUnitBeList] = useState<ISelectOption[]>([]);  //从属门店对应的关联实体
    const [outUnitBeList, setOutUnitBeList] = useState<ISelectOption[]>([]);  //对账单位对应的关联实体
    //回显选中调出门店带出来的数据 
    const getShopOutUnitDetail = async (option: ISelectOption, echo: boolean = false, shopid: string) => {
        let { retData } = await internalService.queryShopUnitBe(shopid as string);
        let data = {
            adverseUnitCode: option.value,
            adverseUnitName: option.label,
            adverseShopCode: shopid,
            adverseBeCode: undefined,
            adverseBeName: undefined,
        }
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setOutUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
            } else {
                setOutUnitBeList([]);
                message.warning("调出门店对应的核算单位没有关联实体")
            };
            //回显时不用执行一下操作
            if (echo) return;
            saveCache({
                ...data,
            });
        } else {
            saveCache(data);
            setOutUnitBeList([]);
            message.warning("调出门店没有关联的核算单位");
        }
    }
    const adverseUnitOption = accUnitDs.filter(item => item.shop !== shopId).map(item => ({ label: item.name ?? "", value: item.code ?? "", shopcode: item.shopCode }))
    useEffect(() => {
        let writeoffAmount = 0;
        addGoodsList.forEach((item) => {
            writeoffAmount += item.totalAmount
        })
        updateAddFormVal({ writeoffAmount })
    }, [addGoodsList])
    //编辑
    useEffect(() => {
        if (mode === "edit") {
            //没有缓在调用详情接口
            if (!editFormVal.id) {
                getInternalDetailOne(id).then(res => {
                    // getShopinUnitDetail(res.shopCode as string)
                    // getShopOutUnitDetail({ label: res.outShopName, value: res.outShopCode }, true)
                });
                return;
            }
            editFormVal.outShopCode && getShopOutUnitDetail({ label: editFormVal.adverseUnitName ?? "", value: editFormVal.adverseUnitCode ?? "" }, true, addFormVal.adverseShopCode);
            editFormVal.shopCode && getShopinUnitDetail(editFormVal.shopCode);
        }
        if (mode === "add") {
            addFormVal.adverseUnitCode && getShopOutUnitDetail({ label: addFormVal.adverseUnitName ?? "", value: addFormVal.adverseUnitCode as string }, true, addFormVal.adverseShopCode);
            addFormVal.shopCode && getShopinUnitDetail(addFormVal.shopCode);
        }
    }, [mode]);

    //关闭页签的时候清除对应缓存
    useEffect(() => {
        return () => {
            !findNav("15-3-1") && resetAddCatch()
            !findNav("15-3-2") && resetEditCatch();
        }
    }, [selectedNavId])


    //保留缓存及回显表单
    const saveCache = (val: { [key: string]: any }, isFormItem: boolean = true) => {
        mode === "edit" ? updateEditFormVal(val) : updateAddFormVal(val);
    }

    //关联实体
    const BeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ beName: option.label, beCode: option.value });
    }
    //对账单位
    const adverseUnitChange = (value: string, option: ISelectOption) => {
        if (option && option.shopcode) {
            getShopOutUnitDetail(option, false, option?.shopcode as string);
            allotLoadData({}, { outShopCode: option?.shopcode as any, shopCode, status: 1, })
            // saveCache({ adverseUnitName: option.label, adverseUnitCode: option.value });
        }
        // option && saveCache({ adverseUnitName: option.label, adverseUnitCode: option.value });
    }
    //对账实体
    const adverseBeChange = (value: string, option: ISelectOption) => {
        option && saveCache({ adverseBeName: option.label, adverseBeCode: option.value });
    }

    const onChange = (changedValues: { [key: string]: any }, values: any) => {
        console.log(changedValues, values)
        saveCache(changedValues);
    }


    //回显选中调入门店带出来的数据  
    const getShopinUnitDetail = async (shopId: string) => {
        let { retData } = await internalService.queryShopUnitBe(shopId as string);
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setinUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
            } else {
                setinUnitBeList([]);
                message.warning("调入门店对应的核算单位没有关联实体")
            }
        } else {
            setinUnitBeList([]);
            message.warning("调入门店没有关联的核算单位");
        }
    }
    const save = (status: number) => {
        let billCodeList = (mode == "add" ? addGoodsList : editGoodsList).map(item => item.code);
        let adverseUnitCode = (mode == "add" ? addFormVal : editFormVal)?.adverseUnitCode
        let params = {
            billCodeList,
            outShopCode: accUnitDs.find(item => item.code === adverseUnitCode)?.shopCode,
            shopCode,
            status,
            writeoffType: 2
        }
        formRef.current?.validateFields().then(async val => {
            saveInternal(params).then(res => {
                if (res) {
                    mode == "add" ? message.success(`保存${status && '申请'}成功`) : message.success(`编辑${status && '申请'}成功`)
                    replaceNav(selectedNavId, "15-3")
                }
            })
        })
    }
    const confirm = (status: number) => {
        let params = {
            code: editFormVal.code as string,
            adverseAffirmUser: userName,
            status
        }
        formRef.current?.validateFields().then(async val => {
            audit(params).then(res => {
                if (res) {
                    message.success("审核成功")
                    replaceNav(selectedNavId, "15-3")
                }
            })
        })
    }
    const rightChildren = <>
        {(Number(editFormVal.status) <= 1 || mode == "add") &&
            <>
                <YhAuth resCode={ResourceAuthEnum.ACC_INTERNAL_APPLY_SAVE}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={debounce(() => {
                            save(0);
                        }, 500)}
                    >保存</Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.ACC_INTERNAL_APPLY_APPLY}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={debounce(() => {
                            mode == "add" ? save(1) : confirm(1)
                        }, 500)}
                    >申请</Button>
                </YhAuth>
            </>
        }
        {Number(editFormVal.status) == 1 &&
            <>
                <YhAuth resCode={ResourceAuthEnum.ACC_INTERNAL_APPLY_CONFIRM}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={debounce(() => {
                            confirm(3);
                        }, 500)}
                    >确认</Button>
                </YhAuth>
                <YhAuth resCode={ResourceAuthEnum.ACC_INTERNAL_APPLY_REJECT}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        onClick={debounce(() => {
                            confirm(2);
                        }, 500)}
                    >驳回</Button>
                </YhAuth>
            </>
        }
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => replaceNav(selectedNavId, "15-3")}
        >返回</Button>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "10px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "内部对账单号",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "从属门店",
                fieldName: "shopName",
                disable: true,
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "关联实体",
                fieldName: "beCode",
                onSelectChange: BeChange,
                options: inUnitBeList,
                rules: [{ required: true, message: "请选择关联实体" }],
                hidden: mode !== "add"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "关联实体",
                fieldName: "beName",
                rules: [{ required: true, message: "请选择关联实体" }],
                hidden: mode === "add"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "对账金额",
                fieldName: "writeoffAmount",
                disable: true,
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "对账单位",
                fieldName: "adverseUnitCode",
                onSelectChange: adverseUnitChange,
                options: adverseUnitOption,
                rules: [{ required: true, message: "请选择对账单位" }],
                hidden: mode !== "add"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "对账单位",
                fieldName: "adverseUnitName",
                rules: [{ required: true, message: "请选择对账单位" }],
                hidden: mode === "add"
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "对账实体",
                fieldName: "adverseBeCode",
                onSelectChange: adverseBeChange,
                options: outUnitBeList,
                rules: [{ required: true, message: "请选择对账实体" }],
                hidden: mode !== "add"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "对账实体",
                fieldName: "adverseBeName",
                rules: [{ required: true, message: "请选择对账实体" }],
                hidden: mode === "add"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "申请说明",
                fieldName: "note",
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "对账实体名称",
                fieldName: "adverseBeName",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "对账单位名称",
                fieldName: "adverseUnitName",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "对账单位门店code",
                fieldName: "adverseShopCode",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "关联实体名称",
                fieldName: "beName",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "从属门店Code",
                fieldName: "shopCode",
                hidden: true
            },


        ]
    };

    const poFromConfig: IYhFormBox<IInternalDetail> = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: mode === "edit" ? editFormVal : addFormVal,
        onChange,
        disabled: mode === "edit",
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}