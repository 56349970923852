import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { PaginationProps } from "antd/es/pagination";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { IVRMCustomerDetail, IVRMCustomerSearchFields, customerService } from "./baseCustomerService";
import { globalPrompt } from "@/components/message";
import { convertRes2Blob } from "@/utils/exportFile";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "../../dict/dictModel";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { useLockFn } from "ahooks";
import { accountShopService } from "@/views/account/AccountShopService";
import { message } from "antd";

export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size,
        current: pg.pageNum
    }
}

export const useCustomerModel = createModel(function () {
    const { shopAndOrg, user, loadCustomerAllData } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const { getTreeNodeName } = useDictModel();
    /**
     * 列表
     */
    //列表数据源
    const [dataSource, setDataSource] = useState<IVRMCustomerDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IVRMCustomerSearchFields>({});
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [selectedData, setSelectedData] = useState<IVRMCustomerDetail>();

    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IVRMCustomerDetail>();

    //列表查询
    const loadData = async (page?: ISearchPage, searchValue: IVRMCustomerSearchFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            // ...searchParam,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await customerService.page(searchParams);
        const { records, ...pg } = retData;
        await setPgCfg(transPgToPagination(pg));
        await setDataSource(records);
        await loadCustomerAllData(shopAndOrg.shopId)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: IVRMCustomerSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
    }, [selectedRowKeys]);
    /**
     * 表单详情
     */

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<object>({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState<object>({enable:1});
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState<object>({});
    //表单新增修改
    const updateAddFormVal = (val: object | null) => {
        val ? setAddFormVal({ ...addFormVal, ...val }) : setAddFormVal({enable:1});
    }
    //表单新增修改
    const updateEditFormVal = (val: object | null) => {
        val ? setEditFormVal({ ...editFormVal, ...val }) : setEditFormVal({});
    }
    //获取一条详情数据 Api
    const getCustomerOne = async (id: string, pattern: string) => {
        let { retData } = await customerService.one(id);
        // retData && setEditFormVal(retData);
        if (retData && pattern == "edit") {
            setEditFormVal(retData);
        } else if (retData && pattern == "look") {
            setLookFormVal(retData);
        }
    }

    //表单新增 Api
    const insertCustomer = useLockFn(async (data: IVRMCustomerDetail) => {
        let params = {
            ...data,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            enable: data.enable ? 1 : 0,
            createUser: user.username,
            categoryName: getTreeNodeName(data.categoryCode as string)
        }
        let { retData } = await customerService.insert(params);
        retData ?
            globalPrompt("message", { text: "客户新增成功", type: "success" })
            : globalPrompt("message", { text: "客户新增失败", type: "error" });
        if (retData) {
            await accountShopService.customerSync({ name: data.name }).then(res => {
                res ? message.success("客户账户信息同步成功...") : message.error("同步失败");
            })
        }
        return retData;
    })

    //表单编辑 Api
    const editCustomer = useLockFn(async (data: IVRMCustomerDetail) => {
        let params = {
            ...data,
            ...shopAndOrg,
            enable: data.enable ? 1 : 0,
            updateUser: user.username,
            ...sourceAppOrBranch,
            categoryName: getTreeNodeName(data.categoryCode as string)
        }
        let { retData } = await customerService.update(params);
        retData ?
            globalPrompt("message", { text: "客户编辑成功", type: "success" })
            : globalPrompt("message", { text: "客户编辑失败", type: "error" });
        if (retData) {
            await accountShopService.customerSync({ name: data.name }).then(res => {
                res ? message.success("客户账户信息同步成功...") : message.error("同步失败");
            })
        }
        return retData;
    })

    //导入
    const importCustomer = async (file: { file: File }) => {
        let { retData } = await customerService.import(file);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "导入成功", type: "success" })

        } else {

            globalPrompt("message", { text: "导入失败", type: "error" });
        }

        return retData;
    }

    //导出
    const exportCustomer = async (searchValue: IVRMCustomerSearchFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await customerService.export(searchParams);
        convertRes2Blob(result);
    }


    //下载模板
    const downloadTpl = async () => {
        let result = await customerService.downloadTpl();
        convertRes2Blob(result);
        // let { retData } = await customerService.downloadTpl();
        // retData ?
        //     globalPrompt("message", { text: "下载模板成功", type: "success" })
        //     : globalPrompt("message", { text: "下载模板失败", type: "error" });
        // return retData;
    }
    //客户禁用开启
    const onDisabled = async (id: string, checked: boolean) => {
        let { retData } = await customerService.disable({ id, enable: checked ? 1 : 0 })
        if (retData) {
            loadData()
            globalPrompt("message", { text: "客户编辑成功", type: "success" })

        } else {

            globalPrompt("message", { text: "客户编辑失败", type: "error" })
        }
        return retData;
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        editFormVal,
        addFormVal,
        initPgCfg,
        lookFormVal,
        selectedData,
        /***method***/
        updateAddFormVal,
        updateEditFormVal,
        insertCustomer,
        editCustomer,
        getCustomerOne,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        importCustomer,
        exportCustomer,
        downloadTpl,
        setAddFormVal,
        setLookFormVal,
        onDisabled,
        setSelectedData,setHistoryRow,setHistoryVisible
    }
});