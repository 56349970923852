import React, { useEffect, FC, useState } from "react";
import { ITopType } from "@/types/ScmTypes";
import { usePreSaleDetailModel } from "./presaleDetailModel"
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import useNavModel, { } from "@/model/navModel";
import { DetailType } from "@/types/ScmEnums";
import { useDictModel, ITreeSelectData } from "@/views/base/dict/dictModel";
import { globalPrompt } from "@/components/message";
import { bigNumber, formatNum } from "@/utils/utils"
import { Button, message, Input } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import { debounce } from "lodash";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDetailModel, ActionTypeEnum } from "../../detailModel";
import { usePreSaleModel } from "../presaleModel"
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";
import { IPreSaleDetailsList } from "@/views/busBusiness/busBusinessType";

export const FormBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        updateAddFormVal,
        updateEditFormVal,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        getDetailEditOne,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialLookList,
        setWsConfirmModal,
        setTopButsControl,
        formComputeRule,
        transfer
    } = usePreSaleDetailModel();
    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow,
    } = usePreSaleModel();
    const { customerParams } = useDetailModel()

    const { getTreeNodeName } = useDictModel();
    const { setActionType } = useDetailModel();
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { CustomerData, beShopDs, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel();
    const [preSale, setPreSale] = useState<any[]>([])
    const materialList=mode === "edit" ?  detailMaterialEditList : detailMaterialAddList
    const setMaterialList=mode === "edit" ?  setDetailMaterialEditList : setDetailMaterialAddList
    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
        getPreSale()
    }, [id, mode])

    const getPreSale = () => {
        const preSaleList = getSelectedDictOne(DictCodeEnmu.PRESALE_TYPE).map(item => {
            return {
                ...item,
                disabled: item.value === DictCodeEnmu.PRESALE_TYPE_DEPLOYMENT
            }
        })
        setPreSale(preSaleList)
    }

    useEffect(() => {
        //debugger
        return () => {
            !findNav("16-2-1") && resetAddCatch(mode);
            !findNav("16-2-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])
    const { getSelectedDictOne } = useDictModel();
    const onDiscount = () => {
        let discountVal: string = "";

        globalPrompt("modal", {
            width: 380,
            title: "折扣",
            type: "info",
            // text: "",
            text: <>
                <span>请输入折扣系数</span>
                <Input
                    onChange={(e) => { discountVal = e.target.value }}
                    suffix="%"
                />
            </>,
            okText: "确定",
            onOk: () => {
                var re = /^[0-9]+.?[0-9]*$/;
                return new Promise((resolve, reject) => {
                    if (!re.test(discountVal)) {
                        reject("输入的值不是数字，请重新输入")
                        message.error("输入的值不是数字，请重新输入")

                    } else {
                        resolve(discountVal)
                    }
                }).then(res => {
                    onDiscountMaterial(+discountVal);

                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onDiscountMaterial = (discount: number) => {
        let detailsMaterialList = mode === "add" ? detailMaterialAddList : detailMaterialEditList;
        let newDetailsMaterialList = detailsMaterialList.map((item) => {
            return {
                ...item,
                discountRate: discount,
                //totalAmount: +formatNum(Number(item.amount) * discount / 100),
                totalAmount:+bigNumber.toFixed(bigNumber.times(item.amount as number,bigNumber.dividedBy(discount,100)),2)
            }
        })
        mode === "add" ? setDetailMaterialAddList(newDetailsMaterialList) : setDetailMaterialEditList(newDetailsMaterialList)
    }
    //当列表跳转来的时候 对应客户信息为空 反之带预售销售详情的数据
    let newCustomerParams = parentInfo?.source ? {} : customerParams
    //debugger
    const typeForm = {
        add: { shopId, shopCode, shopName, bentityCode: beShopDs[2]?.value, bentityName: beShopDs[2]?.label, ...newCustomerParams, ...detailAddForm, },
        edit: detailEditForm,
        look: detailLookForm
    }
    const verifyDetail = async () => {
        let list = detailMaterialAddList;
        if (mode === "edit") list = detailMaterialEditList

        const result = list.some(item => (item?.price ?? 0) < item.costPrice)

        if (result) {
            globalPrompt("modal", {
                title: "预售单保存提醒",
                type: 'warning',
                text: "当前预售单价低于采购价，是否确认",
                okText: "确认",
                cancelText: "取消",
                onOk: save,
                onCancel: () => { }
            })
        } else {
            save()
        }
    }
    const save = () => {
        //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const retData = await insertDetail(res)
                if (retData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    setSelectedRowKeys([retData.id as string])
                    setDescRows([retData])
                    setSelectedRow(retData)
                    resetAddCatch(mode)
                    goBack()
                }
            } else if (mode === "edit") {
                const { id, orderCode } = detailEditForm
                const retData = parentInfo?.TranSale ? await transfer({ ...res, id, orderCode }) : await editDetail({ ...res, id, orderCode })
                if (!!!parentInfo?.TranSale) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    IFormRef.current?.resetFields()
                    getDetailEditOne(orderCode as string, mode)
                } else {
                    globalPrompt("message", { text: "转接成功", type: "success" });
                    if (parentInfo.selectedNavId) {
                        replaceNav(selectedNavId, "16-3");
                        // setActionType(ActionTypeEnum.PRESALE)
                    } else {
                        replaceNav(selectedNavId, "16-1-2");
                        setActionType(ActionTypeEnum.PRESALE)
                    }

                }
            }
        })

    }
    const goBack = () => {
        if (parentInfo && parentInfo.selectedNavId) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "16-1-2");
            setActionType(ActionTypeEnum.PRESALE)
        }
    }
    const onRefresh = () => {
        if (mode === "add") {
            IFormRef.current?.resetFields()
            resetAddCatch()
        } else if (mode === "edit") {
            IFormRef.current?.resetFields()
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const onWhConfirm = async () => {
        let materialNames: string[] = []
        let typeList = {
            add: [],
            edit: detailMaterialEditList,
            look: detailMaterialLookList
        }
        await typeList[mode].forEach(item => {
            if (!!!item.storageWarehouseName) {
                materialNames.push(item.materialName as string)
            }
        })
        if (materialNames.length === 0 && typeList[mode].length != 0) {
            await setWsConfirmModal(true)
        } else {
            await globalPrompt("message", { type: "error", text: `请选择${materialNames.toString()}的仓库` })
        }
    }
    const rightChildren = <>
        {mode === "edit" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={detailEditForm.status === "presale-order.stock-io"}
                onClick={onWhConfirm}
            >库房入库</Button>
        }
        {mode === "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onWhConfirm}
            >入库查看</Button>
        }
        {/* {mode !== "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onDiscount}
            >折扣</Button>
        } */}
        {mode !== "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { verifyDetail() }, 900)}
            >保存</Button>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    function clearGoodsList() {
            if (materialList.length > 0) globalPrompt('message', { text: '切换成功!需要重新选择商品信息', type: 'warning' })
            setMaterialList([])
    }
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {
            let { name, code, contact, phone, address, categoryCode, categoryName } = getFindItem(value, CustomerData)
            option && onChange({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address,
                customerAdd: address,
                customerTypeCode: categoryCode,
                customerTypeName: categoryName,
            });
            clearGoodsList()
        }
    }
    //客户类型
    const customerTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({
            customerTypeName: option.value,
        });
    }
    //商品类型
    const productTypChange = (value: string, option: ISelectOption) => {
        option && onChange({
            productTypeName: option.label,
            productTypeCode: option.value,
        });
    }
    //销售实体
    const bentityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    const orderStatus: IDescription[] = [
        {
            label: "预售单号",
            value: typeForm[mode].orderCode ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeForm[mode]?.status && getTreeNodeName(typeForm[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]
    const baseFormItem: IYhFormItemsBox = {
        title: <>基本信息&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mode !== "add" && orderStatus.map((item, index) => <Description key={index} {...item} />)}</>,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                options: transOptions(CustomerData.filter((item: any) => item.categoryCode === "customer-type.transit")),
                onSelectChange: customerChange,
                allowClear: true,
                showSearch: true,
                disable: !!!parentInfo?.source,
                span: 12,
                labelCol: { span: 3 },
                rules: [{ required: true, message: "请选择客户" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户类型",
                fieldName: "customerTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                onSelectChange: customerTypeChange,
                rules: [{ required: true, message: "请输入客户类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "address",
                rules: [{ required: true, message: "请输入客户地址" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售实体",
                fieldName: "bentityCode",
                options: beShopDs,
                allowClear: true,
                showSearch: true,
                onSelectChange: bentityChange,
                rules: [{ required: true, message: "请选择销售实体" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
            },
            //  {
            //     type: FormItemTypeEnum.Select,
            //     fieldLabel: "商品类型",
            //     fieldName: "productTypeCode",
            //     options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
            //     onSelectChange: productTypChange,
            // }, 
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "预售类型",
                fieldName: "preSaleType",
                options: preSale,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
            },{
                type: FormItemTypeEnum.Input,
                fieldLabel: "发货单号",
                fieldName: "shipmentOrderNumber",
            },{
                type: FormItemTypeEnum.Input,
                fieldLabel: "订单单号",
                fieldName: "orderNumber",
            },{
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "createUser",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "入库员",
                fieldName: "storekeeper",
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "入库时间",
                fieldName: "stockIoTime",
                disable: true,
            }, 
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "商品类型Name",
                fieldName: "productTypeName",
                hidden: true
            }
        ]
    }
    const onChange = (changedValues: { [key: string]: any }) => {
        // debugger;
        mode === "add" ? updateAddFormVal(changedValues) : updateEditFormVal(changedValues)
        setTopButsControl(true)
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === "look",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }
    return (
        <YhFormBox {...poFromConfig} />

    )
}