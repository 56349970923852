import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { ShiftShelfModal } from "./ShiftShelfModal"
import { useStockPoDetailModel } from "../InventoryDetailsModel";
import { TranRecord } from "./TranRecord";
import { OccupyStockModel } from "./OccupyStockModel"
import { HistoryModel } from "./HistoryModel";

export default () => {
    const {
        recordsVisible, setRecordsVisible,
        setRecordsSearch,
        occupyStock, setOccupyStockModel,
        historyVisible,
        setHistoryVisible,
        historyRow,
        setHistoryRow
    } = useStockPoDetailModel();
    return (
        <>
            <PageHeader />
            <DataBox />
            <ShiftShelfModal />
            <TranRecord
                visible={recordsVisible}
                onCancel={() => { setRecordsVisible(false); setRecordsSearch({}) }}
            />
            <OccupyStockModel
                visible={!!occupyStock}
                materialCode={occupyStock?.materialCode as string}
                onCancel={() => setOccupyStockModel(null)}
            />
            <HistoryModel
                visible={historyVisible}
                materialCode={historyRow?.materialCode as string}
                materialName={historyRow?.materialName as string}
                onCancel={() => setHistoryVisible(false)}
            />
        </>
    )
}