import React, { useEffect, useRef, useState } from "react";
import { Table } from "antd";
import { IBillCashDetail } from "../../../../../bill/BillTypes";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { YHDrawer } from "@/components/YhDrawer";
import { tradeTypeDs } from "@/types/FmsDs";
import { useWriteoffModel } from './writeoffModel'
import { useDetailModel } from "../detailModel";
import { ColumnsType } from "antd/lib/table";
import useGlobalModel from "@/model/globalModel";
import { useOrgModel } from "@/views/organization/org/orgModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

interface PostDataList {
  id: string;
  [key: string]: string | null | number | boolean;
}

type dataSourceType = IBillCashDetail & {
  id: string,
  isEdit: boolean
}

export const WriteoffDrawer = () => {
  const initDataSource: dataSourceType[] = [];
  const {
    selectedData,
    openDrawerVisible,
    setOpenDrawerVisible,
  } = useWriteoffModel(model => [model.openDrawerVisible]);

  const [dataSource, setDataSource] = useState<dataSourceType[]>(initDataSource)

  useEffect(() => {
    if (openDrawerVisible) {
      loadCashList()
    }
  }, [openDrawerVisible])
  const { beShopDs } = useGlobalModel();
  const { getOrgTreeSelect } = useOrgModel();

  const formRef = useRef<IYhFormRef>();

  const columns: ColumnsType<PostDataList> = [
    {
      align: "center",
      title: '收款方式',
      dataIndex: 'payMethodName',
    },
    {
      align: "center",
      title: '收款金额',
      dataIndex: 'payAmount',
    },
    {
      align: "center",
      title: '收款人',
      dataIndex: 'operator',
    },
  ];


  // 加载收付款明细
  const loadCashList = async () => {
    const code = selectedData?.code as string
    // let { retData } = await billService.one(code);
    // let data = retData.billCashDetailList?.map((item, index) => {
    //   return {
    //     ...item,
    //     id: item?.key ?? index,
    //     isEdit: false,
    //   }
    // })
    // setDataSource(data as dataSourceType[]);
  }

  const formConfig: IYhForm = {
    labelCol: { span: 6 },
    disabled: false,
    formValues: { ...selectedData },
    items: [
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "收款单位",
        fieldName: "partnerName",
        disable: true,
        span: 12,
      },
      {
        type: FormItemTypeEnum.TreeSelect,
        fieldLabel: "从属机构",
        fieldName: "orgCode",
        treeDs: getOrgTreeSelect(DictCodeEnmu.ORG),
        disable: true,
        span: 12,
      },
      {
        type: FormItemTypeEnum.TreeSelect,
        fieldLabel: "从属实体",
        fieldName: "beName",
        treeDs: beShopDs,
        disable: true,
        span: 12,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "业务编号",
        fieldName: "code",
        disable: true,
        span: 12,
      },

      {
        type: FormItemTypeEnum.Select,
        fieldLabel: "业务类型",
        fieldName: "tradeTypeName",
        disable: true,
        span: 12,
        ds: tradeTypeDs
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "收款金额",
        fieldName: "billAmount",
        disable: true,
        span: 12,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "操作员",
        fieldName: "tradeOperator",
        disable: true,
        span: 12,
      },
      {
        type: FormItemTypeEnum.Input,
        fieldLabel: "备注",
        fieldName: "note",
        disable: true,
        span: 12,
      },
    ]
  };

  const onCancel = () => {
    setOpenDrawerVisible(false);
  };
  return (
    <YHDrawer
      {...{
        title: "现金收款",
        visible: openDrawerVisible,
        showFooter: false,
        onCancel,
        width: 800,
      }}
    >
      <YhForm formRef={formRef} {...formConfig} />
      <Table
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource as unknown as PostDataList[]}
        columns={columns}
        pagination={false}
      />
    </YHDrawer>
  )
}
