import React, { useRef, useEffect, useState, FC } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { formatNum } from "@/utils/utils";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import Table, { ColumnsType } from "antd/lib/table";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { IVRMSettleTypeEnum } from "@/types/FmsDs";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import useGlobalModel from "@/model/globalModel";
import { ISelectOptions } from "@/types/ScmTypes";
import { accountService } from "@/views/account/AccountService";
import { RouterParamType } from "@/model/navModel";
import { customerService } from "@/views/base/customerFiles/customer/baseCustomerService";
import { maintenanceService } from "@/views/maintenance/maintenanceService";

import { useSettlementModel } from "./settlementModel"
import { IMaintenanceRoSettleParams, IMaintenanceRoSettleDetails } from "@/views/maintenance/maintenanceType";
import moment from "moment";
import { saleSoService } from "@/views/sale/so/saleSoService";
import { saleRoService } from "@/views/sale/ro/saleRoService";
interface IRoSettleParams {
    details: IMaintenanceRoSettleDetails[]
    id: string,  //"",
    note: string,  //"",
    reckoner: string,  //"",
    maintenanceCode?: string,  //""
    payType: string,
    // userCode:string
    accountCode: string,

    [key: string]: any
}
export interface ISalePayMethod {
    amount?: number,     /// 0,
    code?: string,     /// "",
    createTime?: string,     /// "",
    createUser?: string,     /// "",
    deleteFlag?: number,     /// 0,
    id?: string,     /// "",
    note?: string,     /// "",
    orderNum?: number,     /// 0,
    payMethodCode?: string,     /// "",
    payMethodName?: string,     /// "",
    payee?: string,     /// "",
    shopId?: string,     /// "",
    tradeCode?: string,     /// "",
    tradeTypeCode?: string,     /// "",
    tradeTypeName?: string,     /// "",
    updateTime?: string,     /// "",
    updateUser?: string,     /// ""
    available?: number,
    accountName?: string,
    accountCode?: string,
    key: string,
    [key: string]: any

}
interface IAccountOption extends ISelectOption {

}
//结算
export const SettleDrawer: FC = () => {
    const { settleVisible: visible, selectedRow, setSettleVisible, resetSearchParam } = useSettlementModel();
    const { type, documentNumber } = selectedRow as { documentNumber: string, type: string }
    const { user, shopAndOrg: { shopName, shopId,shopCode } } = useGlobalModel()
    const { getSelectedDictOne, getTreeNodeName, getTreeNodeOne } = useDictModel();
    const formRef = useRef<IYhFormRef>();
    const [settleType, setSettleType] = useState<IVRMSettleTypeEnum>(IVRMSettleTypeEnum.SETTLE_CASH);   //结算类型，  默认现金结账
    const initDataSource: ISalePayMethod = {
        key: new Date().getTime().toString(),
        payMethodCode: "",
        payMethodName: "",
        amount: 0,
        payee: "",
        note: "",
    };
    const [payDataSource, setPayDataSource] = useState<ISalePayMethod[]>([]);  //表格默认值
    const [accountTypeDs, setAccountTypeDs] = useState<ISelectOption[]>([]) //实时存取收款账户类型值
    const [editPayMethodOptions, setEditPayMethodOptions] = useState<ISelectOptions[]>(getSelectedDictOne(DictCodeEnmu.PAY_METHOD))//编辑根据结算类型展示结算方式
    const [customerCode, setCustomerCode] = useState<string>("")
    const [settlementForm, setSettlemntForm] = useState<any>({})
    const [cash, setCash] = useState(false)//是否展示现金类得支付方式
    const [cashDs, setCashDs] = useState<IAccountOption[]>([])
    // const [settleCustomer, setSettleCustomer] = useState<string>("")
    useEffect(() => {
        if (visible) {
            getMaintenanceOne()
        }
    }, [visible])
    const getMaintenanceOne = async () => {
        let from: any = {}
        if (type === "维修工单") {
            let { retData } = await maintenanceService.one(documentNumber)
            let { itemDetails, settleDetails, materialDetails, deliveryTime, enterTime, ...params } = retData
            from = params
        } else if (type === "销售单") {
            let { retData } = await saleSoService.one(documentNumber);
            let { details, saleTime, saleCode, receivedAmount, receivableAmount, ...params } = retData
            from = { ...params, maintenanceCode: saleCode, receivableTotalAmount: receivedAmount, receivableAmount: receivableAmount }
        } else if (type === "销退单") {
            let { retData } = await saleRoService.one(documentNumber);
            let { details, saleTime, returnCode, receivableAmount, receivedAmount, ...params } = retData
            from = { ...params, maintenanceCode: returnCode, receivableTotalAmount: receivedAmount, receivableAmount: receivableAmount }
        }
        setSettlemntForm(from)
        formRef.current?.setFieldsValue({ ...from, note: "" });
        // detailEditForm.receivableTotalAmount
        setPayDataSource([{ ...initDataSource, amount: from.receivableTotalAmount }]);
        setSettleType(from.payType as IVRMSettleTypeEnum);
        // queryAccount(from.customerCode as string, from.payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, from.payType as string);
        setPayMethodOptions(from.payType as string)
        let { settlementPartyCode } = from
        // debugger;
        if (settlementPartyCode === "settle-party.internal") {//这里为部门内部结算时，查询销售实体为客户的接口换取id
            customerService.page({ name: shopName, pageSize: 15, pageNum: 1, shopId }).then((res) => {
                let { records } = res.retData
                if (records) {
                    let { code } = records[0]
                    setCustomerCode(code as string)
                    queryAccount(code as string, from.payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : from.payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, from.payType as string, shopName,from);
                } else {
                    message.warning(`该结算方为部门内部费用时，无维护对应为${shopName}客户`)
                }
            })
        } else if (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1) {
            let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode as string)[0]
            queryAccount(customerCode as string, from.payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : from.payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, from.payType as string, name,from);
        } else {
            queryAccount(from.customerCode as string, from.payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : from.payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, from.payType as string, from.customerName,from);
        }
    }
    const setPayMethodOptions = (payType: string) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions as ISelectOptions[])
    }
    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode: string, creditFlag: number, payType: string, customerName?: string, newSettlementForm: any = settlementForm) => {
        // console.log(partnerCode, creditFlag)
        let params = {
            partnerCode,  //客户/供应商编码 
            beCode: settlementForm.bentityCode??newSettlementForm.bentityCode,
            status: 0,
            creditFlag,  //为记账类型的  1挂账 2现结
            sourceApp: "vrm",
            shopCode
        }
        const { retData } = await accountService.accountFind(params);
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter(item => {
            // if (creditFlag) {
            //     return settlementForm.payType !== IVRMSettleTypeEnum.SETTLE_CASH ?
            //         (item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE || item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE_OIL) :
            //         (item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE && item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE_OIL)
            // }
            return item;
        })
        // debugger;
        //现金账户 或者 挂账账户。
        let newAccTypeDs: any = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        if (payType === IVRMSettleTypeEnum.SETTLE_CASH) {
            newAccTypeDs = filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0, accounttypecode: item.accountTypeCode as string, key: item.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {//挂账  account-type.credit
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode === "account-type.credit") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_MONTHLY) {//月结  account-type.credit-month
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode === "account-type.credit-month") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        }
        setAccountTypeDs(newAccTypeDs);
        //选择挂账， 自动带出挂账的账户及授信额度s
        // if (creditFlag) {

        if (newAccTypeDs.length > 0) {
            setPayDataSource(() => {
                let available = +(newAccTypeDs[0]?.available ?? 0);  //可用额度
                let payAmount = +(settlementForm.receivableTotalAmount as string);   //需要支付的额度
                // if (payAmount > available) { message.warning("该账户可用额度不足") };
                return [{
                    ...initDataSource,
                    available: available,
                    payMethodName: newAccTypeDs[0].label,
                    payMethodCode: newAccTypeDs[0].value,
                    payMethodValue: newAccTypeDs[0].accounttypecode,
                    amount: payAmount,//+`${payAmount <= available ? payAmount : 0}`,
                }]
            })
            setCash(newAccTypeDs[0].accounttypecode === "account-type.cash");
            setPayDataSource(() => {
                let available = +(newAccTypeDs[0]?.available ?? 0);  //可用额度
                let payAmount = +(newSettlementForm.receivableTotalAmount as string);   //需要支付的额度
                return [{
                    ...initDataSource,
                    available: available,
                    payMethodName: newAccTypeDs[0].label,
                    payMethodCode: newAccTypeDs[0].value,
                    payMethodValue: newAccTypeDs[0].accounttypecode,
                    amount: payAmount, //+`${payAmount <= available ? payAmount : 0}`,
                }]
            })
        } else {
            message.warning(`该${customerName}客户没有授信账户`);
            setCash(false);

        }

    }
    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value: string, option: ISelectOption) => {
        let { settlementPartyCode } = settlementForm
        if (option) {
            setSettleType(option.value as IVRMSettleTypeEnum);
            setPayDataSource([{ ...initDataSource, amount: settlementForm.receivableTotalAmount }]);
            if (settlementPartyCode === "settle-party.internal") {//这里为部门内部结算时，查询销售实体为客户的接口换取id
                queryAccount(customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value as string, shopName);
            } else if (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1) {//这里为斯堪尼亚或man厂家结算时，查询数据字典获取对应的value(维护好的客户编码值)
                let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode as string)[0]
                queryAccount(customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, settlementForm.payType as string, name);
            } else {
                queryAccount(settlementForm.customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value as string, settlementForm.customerName);
            }
            // queryAccount(settlementForm.customerCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : 1, value);
            setPayMethodOptions(value)

        }
    }
    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {

    }

    const onCancel = () => {
        setSettleVisible(false);
    }

    //表单提交
    const onSubmit = async () => {
        let filterPayData = payDataSource.filter(item => (item.payMethodCode));
        if (filterPayData.length !== payDataSource.length) {
            message.warning("请完善收款信息");
            return;
        }
        let totalAmount: number = filterPayData.reduce((con, next) => { con += +(next.amount ?? 0); return con }, 0);
        if (totalAmount !== +(settlementForm.receivableTotalAmount as number)) {
            message.warning("您结算的金额与应收金额有差异");
            return;
        }
        if (settleType === IVRMSettleTypeEnum.SETTLE_CASH && accountTypeDs.length === 0) {
            message.warning("您的现金账户没有开户");
            return;
        }
        if (filterPayData.filter(item => item.payMethodValue === DictCodeEnmu.ACC_TYPE_CASH && !!!item.cashCode).length !== 0) {
            message.warning("请补全你的现金类支付方式");
            return false;
        }
        // return console.log(accountTypeDs)
        formRef.current?.validateFields().then(async res => {
            let { maintenanceCode } = settlementForm
            let newAccountCode = ''
            let accountType = ''
            let accountName = ''

            if (res.payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {
                newAccountCode = filterPayData[0]?.["payMethodCode"] ?? "" as string
                accountType = filterPayData[0]?.["payMethodValue"] ?? "" as string
                accountName = filterPayData[0]?.["payMethodName"] ?? "" as string
            } else {
                let { value, accounttypecode, label } = accountTypeDs.find(item => filterPayData[0]?.payMethodValue === item.accounttypecode) as any
                newAccountCode = value
                accountType = accounttypecode
                accountName = label
            }
            if (!newAccountCode && res.payType === IVRMSettleTypeEnum.SETTLE_CASH) {
                message.warning(`您的现金${filterPayData[0]?.payMethodName}没有开户`);
                return false;
            }
            let data: IRoSettleParams = {
                id: settlementForm.id as string,
                note: res.note,
                reckoner: user.username,
                payType: res.payType,
                payableAmount: res.receivableTotalAmount,
                accountCode: newAccountCode,
                accountType,
                accountName,
                payMethodName:accountName,
                details: filterPayData[0].payMethodValue !== "account-type.cash" ? [] : filterPayData.map(item => {
                    let { key, available, payMethodValue, cashCode, cashName, ...params } = item;
                    let findCash = getSelectedDictOne(DictCodeEnmu.PAY_METHOD_ACCT).find(item => item?.type === payMethodValue) as any
                    return {
                        ...params,
                        payMethodCode: cashCode ?? findCash?.value,
                        payMethodName: cashName ?? findCash?.label,
                        accountName,
                        accountCode: newAccountCode
                    };
                })
            }
            // let data: IRoSettleParams = {
            //     id: settlementForm.id as string,
            //     // maintenanceCode: res.maintenanceCode as string,
            //     note: res.note,
            //     reckoner: user.username,
            //     payType: res.payType,
            //     accountCode: accountTypeDs[0]?.["value"] as string ?? "",
            //     details: payDataSource.map((item => {
            //         let { key, ...params } = item
            //         return params
            //     }))
            // }
            try {
                let newData
                if (type === "维修工单") {
                    let { retData } = await maintenanceService.settl({ ...data, maintenanceCode: maintenanceCode as string })
                    newData = retData
                } else if (type === "销售单") {
                    let { retData } = await saleSoService.settle({ ...data, saleCode: maintenanceCode as string })
                    newData = retData
                } else if (type === "销退单") {
                    let { retData } = await saleRoService.settle({ ...data, returnCode: maintenanceCode as string })
                    newData = retData
                }
                if (newData) {
                    message.success("结算成功");
                    onCancel();
                    resetSearchParam()
                }

                // getDetailEditOne(settlementForm.maintenanceCode as string, "edit")
            } catch (error) {
                onCancel()
            }


        }).catch(err => {

            // message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = (val: any) => {

    }

    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "单据编号",
                fieldName: "maintenanceCode",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单日期",
                fieldName: "createTime",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户编码",
                fieldName: "customerCode",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售实体",
                fieldName: "bentityName",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "应收金额",
                fieldName: "receivableTotalAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "已收金额",
                fieldName: "receivableAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "优惠金额",
                fieldName: "discountTotalAmount",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算日期",
                fieldName: "settleTime",
                span: 12,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                onSelectChange: settleTypeChange,
                rules: [{ required: true, message: "该项为必选项" }],
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                span: 12,
                // disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 12,
                // disable: true
            },


        ]
    };
    const columns = [

        // {
        //     title: '结算方式',
        //     dataIndex: 'payMethodCode',
        //     editType: "select",
        //     options: editPayMethodOptions,//getSelectedDictOne(DictCodeEnmu.PAY_METHOD),
        //     width: 150,
        //     editable: true,

        //     render: (text: string) => getTreeNodeName(text)

        // },
        // ...((str) => {
        //     if (str === IVRMSettleTypeEnum.SETTLE_CASH) {
        //         return []
        //     } else {
        //         return [
        //             {
        //                 title: '可用额度',
        //                 width: 120,
        //                 dataIndex: 'available',
        //                 render: (text: string) => <span className="statu-red">{`￥${text ?? 0}`}</span>
        //             },
        //         ]
        //     }
        // })(settleType),
        {
            title: '支付方式',
            dataIndex: 'payMethodCode',
            editable: true,
            editType: "select",
            options: payDataSource.length === 1 ? accountTypeDs : cashDs,
            // options:accountTypeDs,
            width: 260,
            render: (text: string) => accountTypeDs.find(item => item.value === text)?.label
        },
        cash ? {
            title: '现金类支付方式',
            dataIndex: 'cashCode',
            editable: true,
            editType: "select",
            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASH),
            width: 120,
            render: (text: string) => getTreeNodeName(text)
        } : {
                title: '可用额度',
                dataIndex: 'available',
                width: 100,
                render: (text: string) => <span className="statu-red">{`￥${text}`}</span>
            },
        {
            title: '结算金额',
            width: 120,
            dataIndex: 'amount',
            editable: true,

            render: (text: any) => `￥${formatNum(text)}`
        }, {
            title: '收款人',
            editable: true,

            dataIndex: 'payee',
            editType: "select",
            options: getSelectedDictOne(DictCodeEnmu.PAYEE),
            width: 150,
            render: (text: string) => getTreeNodeName(text)
        }, {
            align: "center",
            title: '操作',
            width: 100,
            render: (text: any, record: any) =>
                <div className="flex justify-around">
                    <PlusOutlined
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                        onClick={() => {
                            setCashDs(accountTypeDs.filter(item => item.accounttypecode === DictCodeEnmu.ACC_TYPE_CASH));
                            setPayDataSource(() => [...payDataSource, initDataSource]);
                        }}
                    />
                    <DeleteOutlined
                        style={{ fontSize: '18px', cursor: 'pointer' }}
                        onClick={() => setPayDataSource(() => payDataSource.filter(item => item.key !== record.key))}
                    />
                </div>,
        }
    ];


    const newPayColumns = columns.map((col: any, index) => {
        return {
            ...col,
            onCell: (record: ISalePayMethod, rowIndex: number) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row: ISalePayMethod) => {
        row.amount = +formatNum(row.amount);

        //结算类型为挂账时， 判断支付方式有值时候 去对应的可用额度
        // if (settleType === IVRMSettleTypeEnum.SETTLE_CREDIT) {
        //     if (row.payMethodCode) {
        //         // debugger;
        //         let find = accountTypeDs.find(item => item.value === row.accountCode);
        //         // row.payMethodName = find?.label ?? "";
        //         // row.payMethodName = getTreeNodeName(row.payMethodCode) ?? "";;
        //         row.available = +(find?.available ?? 0);
        //     }
        //     if (+row.amount > +(row.available ?? 0)) {
        //         row.amount = 0;
        //         message.warning("结算金额≤可用额度");
        //     }
        // } else if (settleType === IVRMSettleTypeEnum.SETTLE_CASH) {
        //     if (row.payMethodCode) {
        //         row.payMethodName = getTreeNodeName(row.payMethodCode) ?? "";;
        //     }
        // }
        // row.payMethodName = getTreeNodeName(row.payMethodCode as string) ?? "";;
        if (settleType === IVRMSettleTypeEnum.SETTLE_CREDIT || settleType === IVRMSettleTypeEnum.SETTLE_CASH) {
            if (row.payMethodCode) {
                let find = accountTypeDs.find(item => item.value === row.payMethodCode);
                row.payMethodName = find?.label ?? "";
                row.payMethodValue = find?.accounttypecode as string ?? "";;
                row.payMethodCode = find?.value as string ?? "";;
                row.available = +(find?.available ?? 0);
            }
        }

        if (row.payMethodValue === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true)
            row.cashName = getTreeNodeName(row.cashCode) ?? "";;
        } else {
            setCash(false)
            row.cashName = null;
            row.cashCode = null;
            if (+row.amount > +(row.available ?? 0)) {
                row.amount = 0;
                message.warning("结算金额≤可用额度");
            }
        }
        let dataSource: ISalePayMethod[] = [...payDataSource];
        const index = dataSource.findIndex((item) => row.key === item.key);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        setPayDataSource(dataSource);
    }
    return (
        <YHDrawer
            {...{
                title: '结算',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit,
                width: 800,
                showFooter: true
            }}
        >
            <YhForm {...formConfig} />
            <Table
                // size="small"
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newPayColumns as ColumnsType<ISalePayMethod>}
                dataSource={payDataSource}
                pagination={false}
                scroll={{ x: 'max-content', y: 400 }}
            />
            <div className="table-bottom-total" onClick={() => { }}>合计:<span className="ml-15 statu-red">{`￥${formatNum(payDataSource.reduce((con, next) => { con += +(next.amount ?? 0); return con }, 0))}`}</span></div>

        </YHDrawer>
    )
}
