import { createModel } from "hox";
import { useState, useEffect, Key } from "react";
import { TablePaginationConfig } from "antd/es/table/interface";
import useGlobalModel from "@/model/globalModel";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { IStockStatisticSearch, IStockStatisticDetail, stockStatisticService } from "@/views/stock/statistic/stockStatisticService";
import {
    IItemDetailList,
    IMaterialDetailList,
    IFactoryDetail,
    IFactoryRoSettleAndWhConfirm,
    ISettleDetails
} from "../../factoryType"
import { factoryService } from "../../factoryServcie"
import { IItemDetail, IItemSearch } from "@/views/base/item/itemType";
import { ISearchPage } from "@/types/ScmTypes";
import { itemService } from "@/views/base/item/itemService";
import { transPgToPagination } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import { IVehicleDetail } from "@/views/base/customerFiles/vehicle/vehicleType";
import moment from "moment";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { useLockFn } from "ahooks";

export type IMaintenanceDescType = {
    workHours: number, //工时
    workHoursNum: number, //工时项数
    workHoursPrice: number, //工时费 折前
    workHoursDiscount: number, //工时优惠
    workHoursTotal: number, //工时总计 折后
    partPrice: number, //零件费 折前
    partPriceNum: number, //零件费项数
    partsDiscount: number, //零件优惠
    partsTotal: number, //零件总计 折后
    discountAll: number,//优惠金额
    receivableAll: number,//实收金额
    totalAll: number, //总计        

}
//这里为了继承一个弹框列表的自定义属性值
export interface IMaterialDetailModel extends IStockStatisticDetail {
    num: number,
    price?: number,
    amount?: number
}
export const useFactoryDetailsModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const { shopId, shopName } = shopAndOrg

    const [itemModel, setItemModel] = useState<boolean>(false); //维修项目弹框
    const [materialModel, setMaterialModel] = useState<boolean>(false); //维修项目弹框 
    const [settleVisible, setSettleVisible] = useState<boolean>(false);   //结算弹框

    const initDesc: IMaintenanceDescType = {
        workHours: 0, //工时
        workHoursNum: 0, //工时项数
        workHoursPrice: 0, //工时费 折前
        workHoursDiscount: 0, //工时优惠
        workHoursTotal: 0, //工时总计 折后

        partPrice: 0, //零件费 折前
        partPriceNum: 0, //零件费项数
        partsDiscount: 0, //零件优惠
        partsTotal: 0, //零件总计 折后
        discountAll: 0,//优惠金额
        receivableAll: 0,//实收金额
        totalAll: 0, //总计    
    }
    const initForm = { shopId, shopName, favorableAmount: 0, enterTime: moment(new Date()) }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initMaterialSearchParam: IStockStatisticSearch = {};
    const [vehicleEditAll, setVehicleEditAll] = useState<IVehicleDetail[]>([])//实时切换车辆 编辑
    const [vehicleAddAll, setVehicleAddAll] = useState<IVehicleDetail[]>([])//实时切换车辆 新增
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IFactoryDetail>(initForm)
    //新增项目详情列表
    const [detailItemAddList, setDetailItemAddList] = useState<IItemDetailList[]>([])
    //新增项目详情列表选中行
    const [detailItemAddDataRowKeys, setDetailItemAddDataRowKeys] = useState<Key[]>([]);
    const [detailItemAddDataRow, setDetailItemAddDataRow] = useState<IItemDetailList[]>([]);
    //新增维修用料详情列表
    const [detailMaterialAddList, setDetailMaterialAddList] = useState<IMaterialDetailList[]>([])
    //新增维修用料详情列表选中行
    const [detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialAddDataRow, setDetailMaterialAddDataRow] = useState<IMaterialDetailList[]>([]);
    const [addDesc, setAddDesc] = useState<IMaintenanceDescType>(initDesc)

    //编辑数据
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState<IFactoryDetail>({})
    //编辑项目详情列表
    const [detailItemEditList, setDetailItemEditList] = useState<IItemDetailList[]>([])
    //编辑项目详情列表选中行
    const [detailItemEditDataRowKeys, setDetailItemEditDataRowKeys] = useState<Key[]>([]);
    const [detailItemEditDataRow, setDetailItemEditDataRow] = useState<IItemDetailList[]>([]);
    //编辑维修用料详情列表
    const [detailMaterialEditList, setDetailMaterialEditList] = useState<IMaterialDetailList[]>([])
    //编辑维修用料详情列表选中行
    const [detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialEditDataRow, setDetailMaterialEditDataRow] = useState<IMaterialDetailList[]>([]);
    const [editDesc, setEditDesc] = useState<IMaintenanceDescType>(initDesc)
    //编辑结算详情
    const [editSettleDetails, setEditSettleDetails] = useState<ISettleDetails[]>([])
    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IFactoryDetail>({})
    //查看项目详情列表
    const [detailItemLookList, setDetailItemLookList] = useState<IItemDetailList[]>([])
    //查看维修用料详情列表
    const [detailMaterialLookList, setDetailMaterialLookList] = useState<IMaterialDetailList[]>([])
    const [lookDesc, setLookDesc] = useState<IMaintenanceDescType>(initDesc)
    //查看结算详情
    const [lookSettleDetails, setLookSettleDetails] = useState<ISettleDetails[]>([])
    //配件列表配置
    const [workMaterialDataSource, setWorkMaterialDataSource] = useState<IMaterialDetailModel[]>([])
    const [searchWorkMaterialData, setSearchWorkMaterialData] = useState<IStockStatisticSearch>(initMaterialSearchParam)
    const [workMaterialPgCfg, setWorkMaterialPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataMaterialSource, setAddDataMaterialSource] = useState<IMaterialDetailModel[]>([]);

    //维修项目列表配置
    const [workItemDataSource, setWorkItemDataSource] = useState<IItemDetail[]>([])
    const [searchWorkItemData, setSearchWorkItemData] = useState<IItemSearch>(initMaterialSearchParam)
    const [workItemPgCfg, setWorkItemPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选维修项目列表
    const [addDataItemSource, setAddDataItemSource] = useState<IItemDetail[]>([]);

    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)
    useEffect(() => {
        let newDesc: IMaintenanceDescType = { ...initDesc };
        detailItemAddList && detailItemAddList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialAddList && detailMaterialAddList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemAddList.length
        newDesc.partPriceNum = detailMaterialAddList.length
        if ("favorableAmount" in detailAddForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailAddForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailAddForm.favorableAmount)
        }
        setAddDesc(newDesc);
    }, [detailItemAddList, detailMaterialAddList, detailAddForm])
    useEffect(() => {
        let newDesc: IMaintenanceDescType = { ...initDesc };
        detailItemEditList && detailItemEditList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialEditList && detailMaterialEditList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemEditList.length
        newDesc.partPriceNum = detailMaterialEditList.length
        if ("favorableAmount" in detailEditForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailEditForm.favorableAmount)
        }
        setEditDesc(newDesc);
        // setTopButsControl(true)
    }, [detailItemEditList, detailMaterialEditList, detailEditForm])
    useEffect(() => {
        let newDesc: IMaintenanceDescType = { ...initDesc };
        detailItemLookList && detailItemLookList.forEach((item) => {
            newDesc.workHours += Number(item.workHour)
            newDesc.workHoursPrice += Number(item.itemAmount)
            newDesc.workHoursDiscount += Number(item.itemAmount) - Number(item.receivableAmount)
            newDesc.workHoursTotal += Number(item.receivableAmount);
        })
        detailMaterialLookList && detailMaterialLookList.forEach((item) => {
            newDesc.partPrice += Number(item.materialAmount)
            newDesc.partsDiscount += Number(item.materialAmount) - Number(item.receivableAmount)
            newDesc.partsTotal += Number(item.receivableAmount);
        })
        newDesc.discountAll = newDesc.workHoursDiscount + newDesc.partsDiscount
        newDesc.receivableAll = (newDesc.workHoursTotal + newDesc.partsTotal)
        newDesc.workHoursNum = detailItemEditList.length
        newDesc.partPriceNum = detailMaterialEditList.length
        if ("favorableAmount" in detailLookForm) {
            newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount)
            newDesc.receivableAll = newDesc.receivableAll - Number(detailLookForm.favorableAmount)
        }
        setLookDesc(newDesc);
    }, [detailItemLookList, detailMaterialLookList, detailLookForm])
    //新增表单缓存
    const updateAddFormVal = (val: IFactoryDetail) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updateEditFormVal = (val: IFactoryDetail) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }

    //获取一条编辑或查看数据
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await factoryService.one(id);
        const { itemDetails, settleDetails, materialDetails, deliveryTime, enterTime, ...from } = retData
        const newItemDetails = itemDetails?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newMaterialDetails = materialDetails?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newFrom = {
            ...from,
            enterTime: enterTime ? moment(enterTime) : null,
            deliveryTime: deliveryTime ? moment(deliveryTime) : null
        }
        if (retData && pattern === "edit") {
            setDetailItemEditList(newItemDetails as IItemDetailList[] ?? [])
            setDetailMaterialEditList(newMaterialDetails as IMaterialDetailList[] ?? [])
            setDetailEditForm(newFrom)
            setTopButsControl(false)
            setEditSettleDetails(settleDetails as ISettleDetails[] ?? [])

        } else if (retData && pattern === "look") {
            setDetailLookForm(newFrom);
            setDetailItemLookList(newItemDetails as IItemDetailList[] ?? [])
            setDetailMaterialLookList(newMaterialDetails as IMaterialDetailList[] ?? [])
            setLookSettleDetails(settleDetails as ISettleDetails[] ?? [])

        }
    }
    //关闭维修项目弹框
    const resetWorkItemloadData = async () => {
        setSearchWorkItemData({});
        workItemloadData(initPgCfg, {});
    }
    const workItemloadData = async (page?: ISearchPage, searchValue: IItemSearch = searchWorkItemData) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            pageSize: page?.pageSize ?? workItemPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workItemPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await itemService.page(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            return {
                ...item,
                num: 1,
            }
        })
        setWorkItemPgCfg(transPgToPagination(pg));
        setWorkItemDataSource(newRecords);
    }
    //关闭维修用料弹框
    const resetWorkMaterialloadData = async () => {
        setSearchWorkMaterialData({});
        workMaterialloadData(initPgCfg, {});
    }
    const workMaterialloadData = async (page?: ISearchPage, searchValue: IStockStatisticSearch = searchWorkMaterialData) => {
        const searchParams = {
            ...searchValue,
            pageSize: page?.pageSize ?? workMaterialPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workMaterialPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await stockStatisticService.pages(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            return {
                ...item,
                num: 1,
                amount: item.lateSalePrice
            }
        })
        setWorkMaterialPgCfg(transPgToPagination(pg));
        setWorkMaterialDataSource(newRecords);
    }
    //筛选维修用料模块详情 详情和新增的列表已有数据的方法
    const modelMaterialScreenMethod = async (modelList: IMaterialDetailModel[], detailList: IMaterialDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IMaterialDetailList[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.materialCode === Item.materialCode);
            let { id, materialCode, materialName, inventoryNum, availableStock, num, lateSalePrice, amount, ...params } = Item
            if (isSelect === undefined) {
                newDetailData.push({
                    ...params,
                    discountRate: 100,
                    id: "",
                    inventoryNum: availableStock,
                    materialCode,
                    materialName,
                    num,
                    materialAmount: amount,
                    price: lateSalePrice,
                    receivableAmount: amount,
                    stockId: id,
                    detailsId: id,
                });
            } else {
                repetition.push(Item.materialName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到维修用料详情列表
    const transformsToPoMaterialDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialAddList)
            setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialEditList)
            setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除维修用料详情列表数据
    const detailMaterialDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailMaterialAddList.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (mode === "edit") {
            const backendDetailList: any = detailMaterialEditDataRow.reduce((all: object[], next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);

            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
                setDetailMaterialEditList(filterDetailList)
                globalPrompt("message", { text: `删除成功`, type: "success" });
                setDetailMaterialEditDataRowKeys([]);
                setDetailMaterialEditDataRow([]);
            }

            if (backendDetailList.length > 0) {
                await factoryService.materialDelete({ ids: backendDetailList, maintenanceCode: detailEditForm.maintenanceCode as string }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        globalPrompt("message", { text: `删除失败`, type: "success" });
                    }
                })
            } else {
                deleteData()
            }
        }
    }

    //筛选维修项目模块详情 详情和新增的列表已有数据的方法
    const modelItemScreenMethod = async (modelList: IItemDetail[], detailList: IItemDetailList[]) => {
        const repetition: string[] = [];
        const newDetailData: IMaterialDetailList[] = []
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.itemName === Item.name);
            let { code, name, id, amount, price, workHours, ...params } = Item;
            if (isSelect === undefined) {
                newDetailData.push({
                    ...params,
                    id: "",
                    detailsId: id,
                    discountRate: 100,
                    itemAmount: amount,
                    itemCode: code,
                    itemName: name,
                    price: price,
                    receivableAmount: amount,
                    workHour: workHours

                });
            } else {
                repetition.push(Item.name as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到维修项目详情列表
    const transformsToPoItemDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelItemScreenMethod(addDataItemSource, detailItemAddList)
            setDetailItemAddList([...detailItemAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelItemScreenMethod(addDataItemSource, detailItemEditList)
            setDetailItemEditList([...detailItemEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除维修项目详情列表数据
    const detailItemDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailItemAddList.filter(el => !!!detailItemAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailItemAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailItemAddDataRowKeys([]);
            setDetailItemAddDataRow([]);
        } else if (mode === "edit") {
            const backendDetailList: any = detailItemEditDataRow.reduce((all: object[], next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);

            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = detailItemEditList.filter(el => !!!detailItemEditDataRowKeys.find(ele => ele === el.detailsId))
                setDetailItemEditList(filterDetailList)
                globalPrompt("message", { text: `删除成功`, type: "success" });
                setDetailItemEditDataRowKeys([]);
                setDetailItemEditDataRow([]);
            }

            if (backendDetailList.length > 0) {
                await factoryService.itemDelete({ ids: backendDetailList, maintenanceCode: detailEditForm.maintenanceCode as string }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        globalPrompt("message", { text: `删除失败`, type: "success" });
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    //新增预约单
    const insertDetail = useLockFn(async (params: IFactoryDetail) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            itemDetails: detailItemAddList,
            materialDetails: detailMaterialAddList
        }
        // let { retData } = await factoryService.insert(newParams)
        return true
    })
    //编辑预约单
    const editDetail = useLockFn(async (params: IFactoryDetail) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            itemDetails: detailItemEditList,
            materialDetails: detailMaterialEditList
        }
        let { retData } = await factoryService.update(newParams)
        return retData
    })
    //转工单核对
    const transfer = async () => {
        let { appointmentCode, cancelReason, id } = detailEditForm
        let { retData } = await factoryService.transfer({ appointmentCode, id })
        return retData
    }
    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddForm(initForm)
        setDetailItemAddList([]);
        setDetailMaterialAddList([]);
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailItemEditList([]);
        setDetailMaterialEditList([]);
    }
    const onSettle = async (paramas: IFactoryRoSettleAndWhConfirm) => {
        let { retData } = await factoryService.settl(paramas);
        return retData
        // if (retData) {
        //     await setSettleVisible(true)
        //     await getDetailEditOne(detailEditForm.purchaseCode as string, "edit")
        //     await globalPrompt("message", { text: `结算成功`, type: "success" });
        // }
    }
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IStockStatisticDetail>({})
    const [consumeHistoryVisible, setConsumeHistoryVisible] = useState<boolean>(false) //消费记录展示
    const [consumeHistoryModel, setConsumeHistoryModel] = useState<{ customerName: string }>({ customerName: "" }) //消费记录参数
    const [repairHistoryVisible, setRepairHistoryVisible] = useState<boolean>(false) //维修历史展示
    const [repairHistoryModel, setRepairHistoryModel] = useState<{ licensePlate: string }>({ licensePlate: "" }) //维修历史
    return {
        initForm,
        itemModel,
        materialModel,
        initPgCfg,
        initMaterialSearchParam,
        detailAddForm,
        detailItemAddList,
        detailItemAddDataRowKeys,
        detailItemAddDataRow,
        detailMaterialAddList,
        detailMaterialAddDataRowKeys,
        detailMaterialAddDataRow,
        addDesc,
        detailEditForm,
        detailItemEditList,
        detailItemEditDataRowKeys,
        detailItemEditDataRow,
        detailMaterialEditList,
        detailMaterialEditDataRowKeys,
        detailMaterialEditDataRow,
        editDesc,
        detailLookForm,
        detailItemLookList,
        detailMaterialLookList,
        lookDesc,
        workMaterialDataSource,
        searchWorkMaterialData,
        workMaterialPgCfg,
        addDataMaterialSource,
        workItemDataSource,
        searchWorkItemData,
        workItemPgCfg,
        addDataItemSource,
        topButsControl,
        transfer,
        setItemModel,
        setMaterialModel,
        setDetailAddForm,
        setDetailItemAddList,
        setDetailItemAddDataRowKeys,
        setDetailItemAddDataRow,
        setDetailMaterialAddList,
        setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        setAddDesc,
        setDetailEditForm,
        setDetailItemEditList,
        setDetailItemEditDataRowKeys,
        setDetailItemEditDataRow,
        setDetailMaterialEditList,
        setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        setEditDesc,
        setDetailLookForm,
        setDetailItemLookList,
        setDetailMaterialLookList,
        setLookDesc,
        setWorkMaterialDataSource,
        setSearchWorkMaterialData,
        setWorkMaterialPgCfg,
        setAddDataMaterialSource,
        setWorkItemDataSource,
        setSearchWorkItemData,
        setWorkItemPgCfg,
        setAddDataItemSource,
        setTopButsControl,
        updateAddFormVal,
        updateEditFormVal,
        getDetailEditOne,
        resetWorkItemloadData,
        workItemloadData,
        resetWorkMaterialloadData,
        workMaterialloadData,
        modelMaterialScreenMethod,
        transformsToPoMaterialDetailList,
        detailMaterialDetailList,
        modelItemScreenMethod,
        transformsToPoItemDetailList,
        detailItemDetailList,
        vehicleEditAll, setVehicleEditAll,
        vehicleAddAll, setVehicleAddAll,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        settleVisible, setSettleVisible,
        onSettle,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        lookSettleDetails, setLookSettleDetails,
        editSettleDetails, setEditSettleDetails,
        consumeHistoryModel, setConsumeHistoryModel,
        consumeHistoryVisible, setConsumeHistoryVisible,
        repairHistoryModel, setRepairHistoryModel,
        repairHistoryVisible, setRepairHistoryVisible
    }
})