import React, { useRef, useState, useEffect } from "react";
import { Button, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, IYhFormRef } from "@/components/YhFormBox/Types";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { getFormValidatorErrText } from "@/utils/utils"
import { useDictModel, ITreeSelectData } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";
import { useAcctUnitModel } from "../acctUnitModel";
import { ISCMAcctUnitFormFields } from "../acctUnitService";
import useGlobalModel from "@/model/globalModel";
import { defaultMdCmParam } from "@/utils/constants";
import { ISelectOptions } from "@/types/ScmTypes";

export const BaseAcctUnitDetails = () => {
    const {
        addFormVal,
        editFormVal,
        updateAddFormVal,
        updateEditFormVal,
        insertAcctUnit,
        editAcctUnit,
        getAcctUnitOne
    } = useAcctUnitModel();
    const { selectedNavId, replaceNav, getSelectedNavState, findNav } = useNavModel();
    const { mdmBeDs, mdmShopDs, shopDs, beDs } = useGlobalModel();
    const formRef = useRef<IYhFormRef>();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);

    //编辑时， 没有缓在调用详情接口
    useEffect(() => {
        if (!Object.keys(editFormVal).length && detailsInfo.mode === "edit") {
            getAcctUnitOne(detailsInfo.id)
        }
    }, [detailsInfo]);

    useEffect(() => {
        return () => {
            let addNavExist = findNav("13-8-1");
            let editNavExist = findNav("13-8-2");
            !addNavExist && updateAddFormVal(null);
            !editNavExist && updateEditFormVal(null);
        }
    }, [selectedNavId])


    //关闭当前页
    const onClose = () => {
        replaceNav(selectedNavId, "13-8");
    }

    //重置
    const onReset = () => {
        formRef.current?.resetFields();
        if (detailsInfo.mode === "edit") {
            getAcctUnitOne(detailsInfo.id)
        } else {
            updateAddFormVal(null);
        }
    };

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(val => {
            let data = {
                ...val,
                bentity: val.bentity.join() ?? val.bentity,
                status: val.status ? 1 : 0,
                sourceApp: defaultMdCmParam.sourceApp
            }
            if (detailsInfo.mode === "edit") {
                editAcctUnit({ ...data, id: detailsInfo.id }).then(res => {
                    res && onClose()
                    updateEditFormVal(null);
                })
            } else {
                insertAcctUnit(data).then(res => {
                    res && onClose()
                    updateAddFormVal(null);
                })
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    //实时保留缓存
    const onChange = (changedValues: { [key: string]: any }) => {
        detailsInfo.mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues);
    }

    const categoryCodeChange = (value: string, node: ITreeSelectData) => {
        node && onChange({ categoryName: node.title, categoryCode: node.value })
    }
    const shopChange = (value: string, options: ISelectOptions) => {
        console.log(options)
        options && onChange({ shop: value, shopName: options.label, shopCode: options.code })

    }
    const baseFormItem: IYhFormItemsBox = {
        title: "基础信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "内部核算单位编码",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "内部核算单位名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入内部核算单位名称" }]
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "关联门店",
                fieldName: "shop",
                options: mdmShopDs,
                onSelectChange: shopChange,
                allowClear: true,
                rules: [{ required: true, message: "请选择关联门店" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "关联实体",
                fieldName: "bentity",
                options: mdmBeDs,
                // ds: beDs.map((item: any) => ({ ...item, value: item.id })),
                mode: "multiple",
                rules: [{ required: true, message: "请选择关联实体" }]
            },
            // {
            //     type: FormItemTypeEnum.Select,
            //     fieldLabel: "关联门店",
            //     fieldName: "shop",
            //     options: shopDs,
            //     onSelectChange: shopChange,
            //     allowClear: true,
            //     rules: [{ required: true, message: "请选择关联门店" }]
            // }, {
            //     type: FormItemTypeEnum.Select,
            //     fieldLabel: "关联实体",
            //     fieldName: "bentity",
            //     options: beDs,
            //     // ds: beDs.map((item: any) => ({ ...item, value: item.id })),
            //     mode: "multiple",
            //     rules: [{ required: true, message: "请选择关联实体" }]
            // },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系地址",
                fieldName: "address",
                rules: [{ required: true, message: "请输入客户联系地址" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note"
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "关联门店名称",
                fieldName: "shopName",
                hidden: true
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "关联门店code",
                fieldName: "shopCode",
                hidden: true
            },
        ]
    };

    const otherFormItem: IYhFormItemsBox = {
        title: "其它信息",
        style: { padding: "18px 77px 0 0", marginBottom: "15px" },
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建人",
                fieldName: "createUser",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "创建日期",
                fieldName: "createTime",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改人",
                fieldName: "updateUser",
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "修改日期",
                fieldName: "updateTime",
                disable: true
            }, {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "状态",
                fieldName: "status"
            }
        ]
    }

    const acctunitFromConfig: IYhFormBox<ISCMAcctUnitFormFields> = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: detailsInfo.mode === "edit" ? editFormVal : addFormVal,
        onChange,
        boxs: [baseFormItem, otherFormItem]
    }


    const action = <>
        <YhAuth resCode={detailsInfo.mode === "edit" ? ResourceAuthEnum.DATA_ACCUNIT_ED_SAVE : ResourceAuthEnum.DATA_ACCUNIT_AD_SAVE}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onSubmit}
            >保存</Button>
        </YhAuth>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onReset}
        >重置</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onClose}
        >取消</Button>
    </>;

    return (
        <>
            <YhBox title={`内部核算单位${detailsInfo.mode === "edit" ? "编辑" : "新增"}`} action={action} style={{ paddingBottom: "0px", marginBottom: "15px" }}></YhBox>
            <YhFormBox {...acctunitFromConfig} />
        </>
    )
}