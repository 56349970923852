import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface IVRMBusReportDetail {
    "id": string,
    "customerName": string,    // "客户名称",
    "tradeTypeCode": string,    // "单据类型",
    "tradeTypeName": string,    // "单据类型名称",
    "customerTypeCode": string,    // "客户类型code",
    "customerTypeName": string,    // "客户类型name",
    "shopId": string,    // "门店id",
    "shopName": string,    // "门店名称",
    "code": string,    // "单号",
    "materialCode": string,    // "商品编码",
    "materialName": string,    // "商品名称",
    "orderTypeCode": string,    // "销售类型",
    "orderTypeName": string,    // "销售类型名称",
    "replaceCode": string,    // "替换号",
    "typeCode": string,    // "商品类型编码",
    "typeName": string,    // "商品类型名称",
    "price": string,    // 销售单价,
    "num": string,    // 销售数量,
    "amount": string,    // 销售金额,
    "giftsNum": string,    // 赠品数量,
    "giftsAmount": string,    // 赠品总金额,
    "warehouseName": string,    // "库房",
    "shelfCode": string,    // "货架",
    "costPrice": string,    // 成本价格,
    "costAmount": string,    // 销售成本,
    "profit": string,    // 销售毛利,
    "note": string,    // "备注",
    "settleTime": string,    // 结算日期,
    "createTime": string,    // "2021-06-01 17:string,    //11:string,    //31",
    "updateTime": string,    // "2021-06-01 17:string,    //11:string,    //45",
    "createUser": string,    // "吴杏云",
    "updateUser": string,    // null,
    "payType": string,    // "结算类型code",
    "payTypeName": string,    // "结算类型name",
    "deleteFlag": string,    // 0
}

//搜索条件
export interface IVRMBusReportSearchFields {
    "payType"?: string,    // "结算类型",
    "typeCode"?: string,    // "商品类型",
    "tradeTypeCode"?: string,    // "单据类型",
    "orderTypeCode"?: string,    // "销售类型code",
    "createUser"?: string,    // "业务员",
    "startTime"?: string,    // "开始时间",
    "endTime"?: string,    // "结束时间",
    "customerTypeCode"?: string,    // "客户类型",
    "customerName"?: string,    // "客户名称",
    "materialName"?: string,    // "商品名称",
    "materialCode"?: string,    // "商品编码",
    "code"?: string,    // "销售单编码",
    "shopId"?: string,    // "门店ID"
}

export interface IVRMBusReportSearch
    extends IVRMBusReportSearchFields, ISearchPage {
}
export interface IStatistics {
    saleNum: number; //销售数量
    saleAmount: number; //销售金额
    saleCost: number; //销售成本
    grossProfit: number; //销售毛利
    preferentialAmount?: number;
    receivableAmount?: number;
    receivedAmount?: number;

}

//进销存报表
class BusReportService {
    async page(searchVo: IVRMBusReportSearch) {
        const apiUrl = "/bcm/statistics/bcmSalePage";
        return await httpRequest.post<IPage<IVRMBusReportDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: IVRMBusReportSearch) {
        const apiUrl = "/bcm/statistics/bcmSalePage/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo: IVRMBusReportSearch) {
        const apiUrl = "/bcm/statistics/bcmSaleStatistic";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }
}

export const busReportService = new BusReportService();