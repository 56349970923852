import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

/**
 * 入库单
 */
export interface ISCMPurchaseDetailsDetail {
    materialCode: string,// 商品编码：
    // 商品名称：
    materialName: string
    // 仓库名称：
    warehouseName: string
    // 仓库编号：
    warehouseCode: string
    // 货位编号：
    shelfCode: string
    // 入库数量：
    inventoryQuantity: string
    // 入库单价：
    price: number,
    // 入库金额：
    amount: number,
    // 供应商编号：
    supplierCode: string
    // 供应商名称：
    supplierName: string
    // 采购单号：
    purchaseCode: string
    // 入库日期：
    settleTime: string
    // 替换编号：
    replaceCode: string
    // 物料编号：
    insideCode: string
    // 物料名称：
    insideName: string
    id: string
    warehouseCode1?: string
    warehouseName1?: string
    shelfId1?: string
    shelfCode1?: string
    moveNum?: number
    lateSalePrice: number,
    latestPurPrice: number,
    purchasePrice: number,
    retailPrice: number,
    [key: string]: any
}

//搜索条件
export interface ISCMPurchaseDetailsSearchFields {
    startTime?: string,
    endTime?: string,
    code?: string,
    tradeTypeCode?: string,
    stockIoTypeCode?: string,
    tradeCode?: string,
    materialCode?: string,
    storekeeper?: string,
    materialName?: string,
    bentityCode?: string,
    ioFlag?: string,            //出或入  出是 1 入是 0
    shopId?: string,
    occupy?:number|null,
    age?:number|null,
    [key:string]:any
}

export interface ISCMPurchaseDetailsSearch
    extends ISCMPurchaseDetailsSearchFields, ISearchPage {
}

export interface ISCMPurchaseDetailsStatistics {
    totalAmount: string | number
}


//商品详情
export interface ISCMPurchaseDetailsGoods {
    id: string,                           // "111",
    code: string,                           // "1",
    orderNum: string,                           // 1,
    deleteFlag: string,                           // 0,
    createTime: string,                           //,
    updateTime: string,                           // ,
    createUser: string,                           // null,
    updateUser: string,                           // null,
    detailsId: string,                           // "1",
    ioId: string,                           // "1",
    materialCode: string,                           // "1",
    materialName: string,                           // "1",
    replaceCode: string,                           // "1",
    typeCode: string,                           // "1",
    typeName: string,                           // "1",
    unitCode: string,                           // "1",
    unitName: string,                           // "1",
    engineType: string,                           // "1",
    ioFlag: string,                           // "1",
    num: string,                           // 1,
    price: string,                           // 1,
    amount: string,                           // 1,
    warehouseCode: string,                           // "1",
    warehouseName: string,                           // "1",
    shelfId: string,                           // "1",
    shelfCode: string,                           // "1",
    openingNum: string,                           // 1,
    openingPurAmount: string,                           // 1,
    openingAvgAmount: string,                           // 1,
    endingNum: string,                           // 1,
    endingPurAmount: string,                           // 1,
    endingAvgAmount: string,                           // 1
}
//采购明细转移货位
export interface ISCMPoShift {
    id: string,// "3562a0997394fe648fbaff0debbc50bf", 采购单明细id
    warehouseCode: string,// "wc", 仓库编号
    warehouseName: string,// "潍柴库", 仓库名称
    shelfId: string,// "bcd65b9cad280b5f7a9089d7433ce303", 货位ID
    shelfCode: string,// "ABC123", 货位编号
    moveNum: number,// 10                                                                移库数量
}
export interface ISCMPoSingleShift {
    id: string,// id
    shelfCode: string,// 货位编号
}
export interface ISCMPoShiftImportDetail {
    shopId: string, // null,
    code: string, // null,
    shopName: string, // null,
    materialCode: string, // "511-434-01-01",
    materialName: string, // "排气制动电磁阀",
    originalWarehouseCode: string, // "wc",
    originalWarehouseName: string, // "潍柴库",
    originalShelfId: string, // null,
    originalShelfCode: string, // "A1-20",
    originalInventoryNum: number, // 1,
    moveNum: number, // null,
    warehouseCode: string, // "wc",
    warehouseName: string, // "潍柴库",
    shelfId: string, // null,
    shelfCode: string, // "A1-11",
    restoreNum: number, // null,
    restoreTime: string, // null,
    restorePerson: string, // null,
    isRestore: string, // null,
    id: string, // "0c6d5ff7e2aab68486e59ce664c99017",
    createTime: string, // null,
    updateTime: string, // null,
    createUser: string, // null,
    updateUser: string, // null,
    deleteFlag: string, // null
    warehouseCode1?: string
    warehouseName1?: string
    shelfId1?: string
    shelfCode1?: string
    availableStock?: number
}

export interface IImportParams {
    "status": boolean,  // true,
    "msg": string,  // "导入成功!",
    "materialCode": string,  // "81.50221.0535",
    "materialName": string,  // NULL,
    "originalWarehouseCode": string,  // NULL,
    "originalShelfCode": string,  // NULL,
    "warehouseCode": string,  // NULL,
    "shelfCode": string,  // "202-A-01年4.5",
    list: ISCMPoShiftImportDetail[]
}
export interface ISCMPurchaseDetailsTotal {
    stockAmount: number       // 库存成本
    stockNum: number                //  库存数量
}
class PurchaseDetailsService {
    //采购明细
    async page(searchVo: ISCMPurchaseDetailsSearch) {
        const apiUrl = "/bcm/stock/details/findPage";
        return await httpRequest.post<IPage<ISCMPurchaseDetailsDetail>>(apiUrl, searchVo);
    }

    // 采购明细(库存模型更改之后的)
    async stockPage(searchVo: ISCMPurchaseDetailsSearch) {
        const apiUrl = "/bcm/purchase/stock";
        return await httpRequest.post<IPage<ISCMPurchaseDetailsDetail>>(apiUrl, searchVo, { timeout: 300000 });
    }
    //采购明细统计接口
    async amount(searchVo: ISCMPurchaseDetailsSearch) {
        const apiUrl = "/bcm/stock/details/total/amount";
        return await httpRequest.post<ISCMPurchaseDetailsTotal>(apiUrl, searchVo, { timeout: 300000 });
    }
    //移库下载模板
    async downloadTpl() {
        const appPageUrl = "/bcm/purchase/shift/download";
        return await httpRequest.getFile(appPageUrl);
    }
    //移库
    async shift(arr: ISCMPoShift[]) {
        const apiUrl = `/bcm/purchase/shift`;
        return await httpRequest.post<boolean>(apiUrl, arr);
    }
    //单条移库
    async singleShift(arr: ISCMPoSingleShift[]) {
        const apiUrl = `/bcm/purchase/single/shift`;
        return await httpRequest.post<boolean>(apiUrl, arr);
    }
    //导出
    async export(searchVo: ISCMPurchaseDetailsSearch) {
        const apiUrl = `/bcm/stock/details/exports`;
        return await httpRequest.postFile<ISCMPurchaseDetailsDetail>(apiUrl, searchVo, { timeout: 300000 });
    }
    //查询客户最近一次销售价
    async recentSalesPrice(shopId: string, materialCode: string, customerCode: string) {
        const apiUrl = `bcm/sale/recentSalesPrice/${shopId}/${customerCode}/${materialCode}`;
        return await httpRequest.get<ISCMPurchaseDetailsDetail>(apiUrl);
    }
    //查询维修单客户最近一次销售价
    async maintenanceRecentSalesPrice(shopId: string, materialCode: string, customerCode: string) {
        const apiUrl = `bcm/maintenance/recentSalesPrice/${shopId}/${customerCode}/${materialCode}`;
        return await httpRequest.get<ISCMPurchaseDetailsDetail>(apiUrl);
    }
}

export const purchaseDetailsService = new PurchaseDetailsService();