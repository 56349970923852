import React from "react";
import { useReceivableModel } from "../receivablesModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import useGlobalModel from "@/model/globalModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useReceivableModel();
    const { getSelectedDictOne } = useDictModel(() => []);
    const { whDs } = useGlobalModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "销售单号",
            fieldName: "documentNumber"
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据类型",
            fieldName: "tradeTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).filter(item => (item.value !== "trade-type.sale-order" && item.value.indexOf("sale") > -1) || item.value.indexOf("maintenance") > -1)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["startTime", "EndTime"],
            placeholder: ["制单起始日期", "制单结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },

    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["settlementStartTime", "settlementEndTime"],
            placeholder: ["结算起始日期", "结算结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]
    return (
        <YhPageHeader
            title="应收报表"
            size="small"
            // justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}