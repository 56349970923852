import React from "react";
import { DataBox } from "./DataBox";
import { DetailDrawer } from "./DetailDrawer";
import { PageHeader } from "./PageHeader";

export function OrganizaShop() {
    return (
        <>
            <PageHeader />
            <DataBox /> 
            <DetailDrawer />
        </>
    );
}

