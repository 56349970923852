import React, { useState, useEffect } from 'react'
import { numberParseChina, formatNum } from "@/utils/utils";
import { useSummaryCustomerModel } from '../customerModel';
import { IMaterialDetailList, IItemDetailList, IMaintenanceDetail } from '../../../maintenance/maintenanceType';
import { maintenanceService } from '../../../maintenance/maintenanceService';
import moment from 'moment';
import { ScaniaPrint } from "@/components/ScaniaPrint"
import useGlobalModel from '@/model/globalModel';
export const ScaniaPrints = () => {
    const { scaniaPrint: visible, selectedRow, setScaniaPrint } = useSummaryCustomerModel()
    const { shopAndOrg: { shopCode } } = useGlobalModel()
    const onClose = () => {
        setScaniaPrint(false)
    }
    const print = () => {

    }
    const [formVal, setFormVal] = useState<IMaintenanceDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IMaterialDetailList[]>([]);
    const [itemList, setItemList] = useState<IItemDetailList[]>([]);
    useEffect(() => {
        visible && getDetailOne()
    }, [visible])
    const getDetailOne = async () => {
        let { retData } = await maintenanceService.one(selectedRow.maintenanceCode as string);
        let { itemDetails, materialDetails, receivableTotalAmount, enterTime, ...from } = retData
        receivableTotalAmount = formatNum(receivableTotalAmount)
        const newItemDetails = itemDetails?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        const newMaterialDetails = materialDetails?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        if (retData) {
            setMaintenanceList(newMaterialDetails as IMaterialDetailList[] ?? []);
            setItemList(newItemDetails as IItemDetailList[] ?? []);
            setFormVal({ ...from, receivableTotalAmount, receivableTotalAmountCN: numberParseChina(receivableTotalAmount), enterTime: moment(enterTime).format("YYYY-MM-DD HH:mm") })
        }
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '工时',
            dataIndex: 'workHour',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '工时费',
            dataIndex: 'itemAmount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '材料编号',
            dataIndex: 'materialCode',
        },
        {
            title: '材料名称',
            dataIndex: 'materialName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '金额',
            dataIndex: 'materialAmount',
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    let IHeader = [
        {
            fieldLabel: "工号：",
            fieldValue: formVal.maintenanceCode
        }, {
            fieldLabel: "支付方式：",
            fieldValue: formVal.paymentMethodName
        }, {
            fieldLabel: "打印日期：",
            fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm")
        },
    ]
    return <ScaniaPrint
        title={`${formVal.bentityName}`}
        visible={visible}
        onClose={onClose}
        columns={columns}
        itemClumns={itemClumns}
        itemDataSource={itemList}
        dataSource={maintenanceList.filter(item => item.num !== 0)}
        formVal={formVal}
        shopCode={shopCode}
    />
}
