import React, { useEffect, useState } from "react";
import { Table, Space, Switch, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useVehicleModel } from "../vehicleModel";
import { IVehicleDetail } from "../vehicleType";
import useNavModel, { RouterParamType } from "@/model/navModel";
import { momentFormat } from "@/utils/utils";

export function DataTable() {
    const { getSelectedNavState, switchNavState } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const {
        dataSource, selectedRowKeys, pgCfg, initPgCfg,
        loadData, setSelectedRowKeys, onDisabled, updateSearchParam
    } = useVehicleModel();
    const { selectedNavId, replaceNav } = useNavModel();
    const onLook = (code: string) => {
        let supplierEditKey = "13-5-2-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: code, mode: "look", },
            true
        );
    }
    const columns: ColumnsType<IVehicleDetail> = [
        {
            title: '序号',
            width: 60,
            render: (text, record, index) => `${index + 1}`
        }, {
            title: '客户名称',
            width: 260,
            dataIndex: 'customerName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '车辆牌照',
            width: 160,
            dataIndex: 'licensePlate',
            render: (text: any, record: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={() => onLook(record.id)}>{text}</p></Tooltip>,
        }, {
            title: '车辆品牌',
            width: 160,
            dataIndex: 'brandName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '车系',
            width: 160,
            dataIndex: 'series',
        }, {
            title: '车辆型号',
            width: 160,
            dataIndex: 'model',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: 'VIN码',
            width: 160,
            dataIndex: 'vin',
        }, {
            title: '登记日期',
            width: 120,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '创建人',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '操作',
            width: 160,
            dataIndex: 'enable',
            render: (text, record) => (
                <Space size="middle">
                    {/* checked={record.materialStatus == 0} */}
                    <Switch checked={text == 1} onChange={(checked) => {
                        // onDisabled(record.id as string, checked);
                        onDisabled(record.id as string, checked);
                        // cutDisabled(record,checked)
                    }} />
                </Space>
            ),
        }
    ];
    useEffect(() => {
        if (detailsInfo) {
            let { parentInfo } = detailsInfo
            if (parentInfo) {
                loadData(initPgCfg, { customerName: parentInfo.customerName });
                updateSearchParam({ customerName: parentInfo.customerName })
                switchNavState("13-5-2", { id: "", mode: "add" })
            } else {
                loadData();
            }
        } else {
            loadData();
        }
        // console.log(detailsInfo)
    }, [detailsInfo]);

    //点击行选高亮
    const onRow = (id: string) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            rowSelection={{
                selectedRowKeys: selectedRowKeys, //记录选中
                onChange: (selectedRowKeys: React.Key[], selectedRows: IVehicleDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                }
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id as string]);
                }
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
