export default class WebSocketJSClass {
  // WebSocket实例
  protected SocketApp: WebSocket = {} as WebSocket;
  // 链接状态
  LinkConnected: boolean = false;
  // 重连超时计时器
  ReconnectTimer: NodeJS.Timeout | null = null;
  HeartBeat: NodeJS.Timeout | null = null;
  // 心跳计时器
  HeartBeatTimer: NodeJS.Timeout | null = null;
  // 服务超时计时器
  ServerTimer: NodeJS.Timeout | null = null;
  // websocket连接地址
  WsUrl: string = "";
  // 收到消息的处理回调函数
  onMessage: ((msg: string) => void) | undefined = undefined;

  constructor(url: string,onMessage: (msg: string) => void) {
    this.WsUrl = url
    this.onMessage = onMessage
  }
  /**
   * WebSocket重连
   */
  reconnect() {
    if (this.LinkConnected) {
      return;
    }
    this.LinkConnected = true;
    this.ReconnectTimer = setTimeout(() => {
      console.info('websocket 尝试重连...');
      this.LinkConnected = false;
      this.createConnect();
    }, 5000);
  }
  /**
   * 创建websocket对象
   */
  createConnect() {
    this.SocketApp = new WebSocket(this.WsUrl);
    this.SocketApp.onclose = () => {
      console.log('websocket 链接关闭');
    };
    this.SocketApp.onopen = () => {
      console.info('websocket is open');
      // 心跳检查
      this.HeartBeatCheck();
    };
    this.SocketApp.onerror =  () =>  {
      console.log("发生异常了")
      this.Close()
      this.createConnect()
    }
    this.SocketApp.onmessage = ({ data }: any) => {
      // 心跳检查
      this.HeartBeatCheck();
      this.onMessage?.(data)
    };
  }
  /**
   * 心跳检查
   */
  HeartBeatCheck() {
    if (this.HeartBeatTimer) {
      clearTimeout(this.HeartBeatTimer);
    }
    if (this.ServerTimer) {
      clearTimeout(this.ServerTimer);
    }
    this.HeartBeatTimer = setTimeout(() => {
      var msg = { data: "ping" }
      const newMsg = JSON.stringify(msg)
      this.SocketApp.send(newMsg);
      console.log("发送心跳")
      // 发送消息后10秒，没有响应，即代表websocket失去响应，10s后进入关闭程序
      this.ServerTimer = setTimeout(() => {
        console.log("等待已超时")
        this.SocketApp.close();
        this.createConnect()
      }, 10000);
    }, 10000);
  }
  /**
   * 关闭WebSocket
   */
  Close() {
    if (this.SocketApp) {
      this.SocketApp.close();
      this.LinkConnected = true;
    }
  }
}
