import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import {IItemSearch,IItemDetail} from "./itemType"

class ItemService {
    async page(searchVo: IItemSearch) {
        const apiUrl = "/bcm/base/item/findPage";
        return await httpRequest.post<IPage<IItemDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/base/item/findById/${id}`;
        return await httpRequest.get<IItemDetail>(apiUrl);
    }
    async insert(insertVo: IItemDetail) {
        const apiUrl = "/bcm/base/item/insert";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }
    async update(updateVo: IItemDetail) {
        const apiUrl = "/bcm/base/item/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //启用禁用
    async disable(params:object) {
        const apiUrl = `/bcm/base/item/change`;
        return await httpRequest.post<boolean>(apiUrl,params);
    }
    //导入
    async import(file: { file: File }) {
        const appPageUrl = "/bcm/base/item/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //导出
    async export(searchVo: IItemSearch) {
        const appPageUrl = "/bcm/base/item/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/bcm/base/item/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
}
export const itemService = new ItemService();