import React, { useEffect, useState, FC } from "react";
import { Button, message, Select, Input, Tooltip } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import useNavModel, { } from "@/model/navModel";
import { bigNumber, formatNum } from "@/utils/utils"
import { globalPrompt } from "@/components/message";
import { useFactoryDetailsModel } from "./factoryDetailsModels";
import { ITopType } from "@/types/ScmTypes";
import { DetailType } from "@/types/ScmEnums";
import useGlobalModel, { getFindItem, transOptions, vehicleOption, getVehicleItem } from "@/model/globalModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { IVehicleFields } from "@/views/base/customerFiles/vehicle/vehicleType";
import { vehicleService } from "@/views/base/customerFiles/vehicle/vehicleService";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { debounce } from "lodash";
import FontIcon from "@/components/YhIcon";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum";

export const FormBox: FC<ITopType> = ({ id, mode, parentInfo, IFormRef }) => {
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        updateAddFormVal,
        updateEditFormVal,
        vehicleEditAll, setVehicleEditAll,
        vehicleAddAll, setVehicleAddAll,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        getDetailEditOne,
        setTopButsControl,
        detailMaterialAddList, setDetailMaterialAddList,
        detailItemAddList, setDetailItemAddList,
        detailItemEditList, setDetailItemEditList,
        detailMaterialEditList, setDetailMaterialEditList,
        setConsumeHistoryModel,
        setConsumeHistoryVisible,
        setRepairHistoryModel,
        setRepairHistoryVisible
    } = useFactoryDetailsModel();
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { beShopDs, CustomerData, shopAndOrg: { shopId, shopCode } } = useGlobalModel();

    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
    }, [id, mode])

    useEffect(() => {
        return () => {
            !findNav("7-3-1") && resetAddCatch(mode);
            !findNav("7-3-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])
    const { getSelectedDictOne } = useDictModel();
    const Options = [
        { label: "整单折扣", value: "1" },
        { label: "维修项目折扣", value: "2" },
        { label: "维修用料折扣", value: "3" }
    ]
    const onDiscount = () => {
        let discountNum: string = "1";
        let discountVal: string = "";

        globalPrompt("modal", {
            width: 380,
            title: "折扣",
            type: "info",
            // text: "",
            text: <>
                <span>请选择折扣类型</span>
                <Select
                    showSearch={true}
                    defaultValue="1"
                    options={Options}
                    onChange={(value, option) => discountNum = value}
                />
                <span>请输入折扣系数</span>
                <Input
                    onChange={(e) => { discountVal = e.target.value }}
                    suffix="%"
                />
            </>,
            okText: "确定",
            onOk: () => {
                var re = /^[0-9]+.?[0-9]*$/;
                return new Promise((resolve, reject) => {
                    if (!re.test(discountVal)) {
                        reject("输入的值不是数字，请重新输入")
                        message.error("输入的值不是数字，请重新输入")

                    } else {
                        resolve(discountVal)
                    }
                }).then(res => {
                    if (discountNum === "1") {
                        onDiscountItem(+discountVal);
                        onDiscountMaterial(+discountVal);
                    } else if (discountNum === "2") {
                        onDiscountItem(+discountVal);
                    } else if (discountNum === "3") {
                        onDiscountMaterial(+discountVal);
                    }
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onDiscountItem = (discount: number) => {
        let detailsItemList = mode === "add" ? detailItemAddList : detailItemEditList;
        let newDetailsItemList = detailsItemList.map((item) => {
            return {
                ...item,
                discountRate: discount,
                //receivableAmount: +formatNum(Number(item.itemAmount) * discount / 100),
                receivableAmount:+bigNumber.toFixed(bigNumber.times(item.itemAmount as number,bigNumber.dividedBy(discount,100)),2),
            }
        })
        mode === "add" ? setDetailItemAddList(newDetailsItemList) : setDetailItemEditList(newDetailsItemList)
    }

    const onDiscountMaterial = (discount: number) => {
        let detailsMaterialList = mode === "add" ? detailMaterialAddList : detailMaterialEditList;
        let newDetailsMaterialList = detailsMaterialList.map((item) => {
            return {
                ...item,
                discountRate: discount,
                //receivableAmount: +formatNum(Number(item.materialAmount) * discount / 100),
                receivableAmount:+bigNumber.toFixed(bigNumber.times(item.materialAmount as number,bigNumber.dividedBy(discount,100)),2),
            }
        })
        mode === "add" ? setDetailMaterialAddList(newDetailsMaterialList) : setDetailMaterialEditList(newDetailsMaterialList)
    }
    const save = () => {
        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const retData = await insertDetail(res)
                if (retData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    resetAddCatch(mode)
                    goBack();
                }
            } else if (mode === "edit") {
                let { id, maintenanceCode } = detailEditForm
                const retData = await editDetail({ ...res, id, maintenanceCode })
                if (retData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" });
                    getDetailEditOne(maintenanceCode as string, mode)
                }
            }
        })
    }
    const goBack = () => {
        if (parentInfo) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "7-3")
        }
    }
    const onRefresh = () => {
        if (mode === "add") {
            IFormRef.current?.resetFields()
            getVehicleAllList()
            resetAddCatch()
        } else if (mode === "edit") {
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const rightChildren = <>
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.BILL_M_ED_DISCOUNT_ALL}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onDiscount}
                >折扣</Button>
            </YhAuth>
        }
        {mode !== "look" &&
            <YhAuth resCode={ResourceAuthEnum.BILL_M_ED_SAVE}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={debounce(() => { save() }, 900)}
                >保存</Button>
            </YhAuth>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    //车辆类型
    const vehicleTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ vehicleTypeName: option.label, vehicleTypeCode: option.value });
    }
    //维修类型
    const maintenanceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ maintenanceTypeName: option.label, maintenanceTypeCode: option.value });
    }
    //结算方
    const settlementPartyChange = (value: string, option: ISelectOption) => {
        option && onChange({ settlementPartyName: option.label, settlementPartyCode: option.value });
    }
    //结算方式
    const paymentMethodChange = (value: string, option: ISelectOption) => {
        option && onChange({ paymentMethodName: option.label, paymentMethodCode: option.value });
    }
    //客户类型
    const customerTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ customerTypeName: option.label, customerTypeCode: option.value });
    }
    //发票类型
    const invoiceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value });
    }
    //销售实体
    const bentityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    //服务性质
    const serviceNatureChange = (value: string, option: ISelectOption) => {
        option && onChange({ serviceNatureName: option.label, serviceNatureCode: option.value });
    }
    //服务地点
    const serviceLocationChange = (value: string, option: ISelectOption) => {
        option && onChange({ serviceLocationName: option.label, serviceLocationCode: option.value });
    }
    useEffect(() => {
        getVehicleAllList()
    }, [mode])

    const getVehicleAllList = async (param?: IVehicleFields) => {
        let params = {
            ...param,
            shopId,
            pageSize: 10000,
            pageNum: 1
        }
        const { retData } = await vehicleService.page(params)
        const { records } = retData
        if (records.length === 0) { globalPrompt("message", { text: "该客户下没有车辆，点击左侧车辆牌照去新增吧!", type: "warning" }) }
        let newRecords = records.map(item => {
            return {
                ...item,
                label: item.licensePlate,
                value: item.licensePlate
            }
        })
        mode === "add" ? setVehicleAddAll(newRecords) : setVehicleEditAll(newRecords)
    }
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {
            let { name, code, contact, phone, address, categoryCode, categoryName } = getFindItem(value, CustomerData)
            option && onChange({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address,
                customerTypeCode: categoryCode,
                customerTypeName: categoryName,
                licensePlate: null,
                vehicleTypeCode: null,
                vehicleTypeName: null,
                vehicleModel: null,
                vin: null
            });
        }
        getVehicleAllList({ customerName: option?.label })

    }
    //车辆牌照
    const vehicleChange = (value: string, option: ISelectOption) => {
        let VehicleIList: any = mode === "add" ? vehicleAddAll : vehicleEditAll
        if (value && option) {
            let { customerName, customerCode, licensePlate, brandCode, brandName, categoryCode, categoryName, phone, contact, address, vin, typeCode, typeName, model } = getVehicleItem(value, VehicleIList)
            option && onChange({
                customerName,
                customerCode,
                contact,
                phone,
                address,
                vin,
                licensePlate,
                vehicleTypeCode: categoryCode,
                vehicleTypeName: categoryName,
                vehicleModel: model,
                customerTypeCode: typeCode,
                customerTypeName: typeName,
            });
        }
    }
    const typeForm = {
        add: { ...detailAddForm, shopId, shopCode },
        edit: detailEditForm,
        look: detailLookForm
    }
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    const goVehicle = () => {
        replaceNav(
            selectedNavId,
            "13-5-2-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id,
                    customerCode: typeForm[mode].customerCode
                }
            },
            true)
    }

    const onConsumption = () => {
        let { customerCode, customerName } = typeForm[mode]
        if (!customerCode) {
            return message.warning("请先选择客户")
        }
        setConsumeHistoryVisible(true)
        setConsumeHistoryModel({ customerName: customerName as string })
    }
    const onRepairHistory = () => {
        let { licensePlate } = typeForm[mode]
        if (!licensePlate) {
            return message.warning("请先选择车辆")
        }
        setRepairHistoryVisible(true)
        setRepairHistoryModel({ licensePlate: licensePlate })
    }

    const title = <>
        <span>基础信息</span>
        <Tooltip placement="topLeft" title="消费记录">
            <FontIcon type="icon-xiaofeijilu" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onConsumption}
            />
        </Tooltip>
        <Tooltip placement="topLeft" title="维修历史">
            <FontIcon type="icon-ic_history" style={{ marginLeft: "10px", cursor: "pointer", color: "#6C72F3" }} onClick={onRepairHistory}
            />
        </Tooltip>
    </>
    const baseFormItem: IYhFormItemsBox = {
        title: title,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工单单号",
                fieldName: "maintenanceCode",
                disable: true,
                placeholder: "自动生成",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                options: transOptions(CustomerData),
                onSelectChange: customerChange,
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: "请选择客户" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "送修人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入送修人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "送车电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入送车电话" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goVehicle}>车辆牌照</span>,
                fieldName: "licensePlate",
                allowClear: true,
                showSearch: true,
                options: mode === "add" ? vehicleOption(vehicleAddAll) : vehicleOption(vehicleEditAll),
                onSelectChange: vehicleChange,
                rules: [{ required: true, message: "请输入车牌号" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "VIN码",
                fieldName: "vin",
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "工单状态",
                fieldName: "status",
                disable: true,
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MAINTENANCE),
                placeholder: "自动生成"
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "维修类型",
                allowClear: true,
                showSearch: true,
                fieldName: "serviceNatureCode",
                options: getSelectedDictOne(DictCodeEnmu.REPAIR_TYPE),
                onSelectChange: serviceNatureChange,
                rules: [{ required: true, message: "维修类型" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "车辆类型",
                allowClear: true,
                showSearch: true,
                fieldName: "vehicleTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
                onSelectChange: vehicleTypeChange,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售实体",
                fieldName: "bentityCode",
                options: beShopDs,
                onSelectChange: bentityChange,
                rules: [{ required: true, message: "请选择销售实体" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算方",
                fieldName: "settlementPartyCode",
                disable: true,
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
                onSelectChange: settlementPartyChange,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                // onSelectChange: paymentMethodChange,
                span: 5,
                labelCol: { offset: 1 },
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆型号",
                fieldName: "vehicleModel",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆里程",
                fieldName: "mileage",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工作小时",
                fieldName: "workingHours",
                hidden: more
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "进场时间",
                showTime: true,
                fieldName: "enterTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Datepicker,
                fieldLabel: "出厂时间",
                showTime: true,
                fieldName: "deliveryTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "去零",
                fieldName: "favorableAmount",
                hidden: more
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "服务地点",
                allowClear: true,
                showSearch: true,
                fieldName: "serviceLocationCode",
                onSelectChange: serviceLocationChange,
                options: getSelectedDictOne(DictCodeEnmu.SERVICE_ADDRESS),
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "故障描述",
                fieldName: "faultDesc",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "处理意见",
                fieldName: "faultResult",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "customerAddress",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "待修内容",
                fieldName: "toBeRepaired",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "下次保养时间",
                fieldName: "nextMaintainTime",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                hidden: more
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车辆类型Name",
                fieldName: "vehicleTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "维修类型Name",
                fieldName: "maintenanceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算方Name",
                fieldName: "settlementPartyName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算方式Name",
                fieldName: "paymentMethodName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发票类型Name",
                fieldName: "invoiceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "服务类型Name",
                fieldName: "serviceNatureName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "服务地点Name",
                fieldName: "serviceLocationName",
                hidden: true
            }
        ]
    };
    const onChange = (changedValues: { [key: string]: any }) => {
        mode === "add" ? updateAddFormVal(changedValues) : updateEditFormVal(changedValues)
        setTopButsControl(true)
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        disabled: mode == "look",
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}