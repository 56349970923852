import { createModel } from "hox";
import { useState, useEffect, Key } from "react";
import useGlobalModel from "@/model/globalModel";
import { IDeploymentDetail, IDeploymentDetailsList, IStockFields, IStockDetail, IStockSearch, ISettleDetail } from "../../../../busBusinessType"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from "@/utils/constants";
import { globalPrompt } from "@/components/message";
import { transPgToPagination, formatNum, bigNumber } from "@/utils/utils";
import { ISearchPage } from "@/types/ScmTypes";
import { useLockFn } from "ahooks";
import { sourceAppOrBranch } from "@/types/VrmSaDictEnums";
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";
import moment from "moment";
import { convertRes2Blob } from "@/utils/exportFile";
import { useDetailModel } from "../../detailModel";
import { message } from "antd";


//这里为了继承一个弹框列表的自定义属性值
export interface IDeploymentDetailModel extends IStockDetail {
    num: number,
    price?: number,
    amount?: number
}
export type IDeploymentDesc = {
    numAll: number,
    itemNumAll: number,
    totalAll: number,
    [key: string]: any

}
export const useDeploymentDetailModel = createModel(function () {
    const { busBusiness: { warehouseCode, warehouseName }, customerParams } = useDetailModel()
    const { user, shopAndOrg, warehouseList } = useGlobalModel()
    const { shopId } = shopAndOrg
    const [materialModel, setMaterialModel] = useState<boolean>(false); //维修配件弹框
    const initDesc: IDeploymentDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0,//总计
    }
    const initForm = {
        // sourceWarehouseCode: warehouseCode,
        // sourceWarehouseName: warehouseName,
        // createUser: user.username
    }
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initMaterialSearchParam = {};

    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState<IDeploymentDetail>({ initForm })
    //新增维修用料详情列表
    const [detailMaterialAddList, setDetailMaterialAddList] = useState<IDeploymentDetailsList[]>([])
    //新增维修用料详情列表选中行
    const [detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialAddDataRow, setDetailMaterialAddDataRow] = useState<IDeploymentDetailsList[]>([]);
    const [addDesc, setAddDesc] = useState<IDeploymentDesc>(initDesc)

    //编辑数据
    //编辑详情form
    const [detailEditForm, setDetailEditForm] = useState<IDeploymentDetail>({})
    //编辑维修用料详情列表
    const [detailMaterialEditList, setDetailMaterialEditList] = useState<IDeploymentDetailsList[]>([])
    //编辑维修用料详情列表选中行
    const [detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys] = useState<Key[]>([]);
    const [detailMaterialEditDataRow, setDetailMaterialEditDataRow] = useState<IDeploymentDetailsList[]>([]);
    const [editDesc, setEditDesc] = useState<IDeploymentDesc>(initDesc)
    //编辑结算详情
    const [editSettleDetails, setEditSettleDetails] = useState<ISettleDetail[]>([])
    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState<IDeploymentDetail>({})
    //查看维修用料详情列表
    const [detailMaterialLookList, setDetailMaterialLookList] = useState<IDeploymentDetailsList[]>([])
    const [lookDesc, setLookDesc] = useState<IDeploymentDesc>(initDesc)
    //查看结算详情
    const [lookSettleDetails, setLookSettleDetails] = useState<ISettleDetail[]>([])

    //配件列表配置
    const [workMaterialDataSource, setWorkMaterialDataSource] = useState<IDeploymentDetailModel[]>([])
    const [searchWorkMaterialData, setSearchWorkMaterialData] = useState<IStockSearch>(initMaterialSearchParam)
    const [workMaterialPgCfg, setWorkMaterialPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //已选配件列表
    const [addDataMaterialSource, setAddDataMaterialSource] = useState<IDeploymentDetailModel[]>([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState<boolean>(false)
    const [checked, setChecked] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        let newDesc: IDeploymentDesc = initDesc;
        detailMaterialAddList && detailMaterialAddList.forEach((item, index) => {
            newDesc.numAll = bigNumber.add(newDesc.numAll ,Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.amount))

            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.amount);
        })
        newDesc.itemNumAll = detailMaterialAddList && detailMaterialAddList.length
        setAddDesc(newDesc);

    }, [detailAddForm, detailMaterialAddList])
    useEffect(() => {
        let newDesc: IDeploymentDesc = initDesc;
        detailMaterialEditList && detailMaterialEditList.forEach((item, index) => {
            newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.amount))
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.amount);
        })
        newDesc.itemNumAll = detailMaterialEditList && detailMaterialEditList.length
        setEditDesc(newDesc);

    }, [detailEditForm, detailMaterialEditList])
    useEffect(() => {
        let newDesc: IDeploymentDesc = initDesc;
        detailMaterialLookList && detailMaterialLookList.forEach((item, index) => {
            newDesc.numAll = bigNumber.add(newDesc.numAll ,Number(item.num))
            newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.amount))

            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.amount);
        })
        newDesc.itemNumAll = detailMaterialLookList && detailMaterialLookList.length
        setLookDesc(newDesc);
    }, [detailLookForm, detailMaterialLookList])


    //新增表单缓存
    const updateAddFormVal = (val: IDeploymentDetail) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updateEditFormVal = (val: IDeploymentDetail) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    const getDetailEditOne = async (id: string, pattern: string) => {
        let { retData } = await busBusinesstService.deploymentOne(id);
        const { details, deliveryTime, enterTime, ...from } = retData
        const newMaterialDetails = details?.map((item) => {
            return {
                ...item,
                detailsId: item.id,
            }
        })
        const newFrom = {
            ...from,
            enterTime: enterTime ? moment(enterTime) : null,
            deliveryTime: deliveryTime ? moment(deliveryTime) : null
        }
        if (retData && pattern === "edit") {
            setDetailMaterialEditList(newMaterialDetails as IDeploymentDetailsList[] ?? [])
            setDetailEditForm(newFrom)
            setTopButsControl(false)
        } else if (retData && pattern === "look") {
            setDetailLookForm(newFrom);
            setDetailMaterialLookList(newMaterialDetails as IDeploymentDetailsList[] ?? [])
        }
    }
    //关闭维修用料弹框
    const resetWorkMaterialloadData = async (mode: string) => {
        setSearchWorkMaterialData({});
        workMaterialloadData(initPgCfg, {}, mode);
    }
    const workMaterialloadData = async (page?: ISearchPage, searchValue: IStockSearch = searchWorkMaterialData, mode?: string) => {
        setLoading(true);
        let newCustomerCode =
            mode === 'add'
                ? detailAddForm.sourceWarehouseCode
                : detailEditForm.sourceWarehouseCode;
        const searchParams = {
            ...searchValue,
            shopId,
            warehouseCode: newCustomerCode,
            age: 1,
            pageSize: page?.pageSize ?? workMaterialPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? workMaterialPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await busBusinesstService.stockPage(searchParams);
        const { records, ...pg } = retData;
        const newRecords:any = records.map(item => {
            let { salePrice, latestPurPrice } = item;
            return {
                ...item,
                num: item.availableNum??1,
                salePrice: salePrice || latestPurPrice,
                amount: bigNumber.toFixed(bigNumber.times(item.availableNum??1,(salePrice as number) || (latestPurPrice as number)),2) 
            }
        })
        setWorkMaterialPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setWorkMaterialDataSource(newRecords);
        setLoading(false);
    }
    //筛选维修用料模块详情 详情和新增的列表已有数据的方法
    const modelMaterialScreenMethod = async (modelList: IDeploymentDetailModel[], detailList: IDeploymentDetailsList[], mode?: string) => {
        const repetition: string[] = [];
        const newDetailData: IDeploymentDetailsList[] = []
        const newForm = mode === "add" ? detailAddForm : detailEditForm
        modelList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.detailsId === Item.id);
            let { id, materialCode, materialName, inventoryNum, availableStock, num, salePrice, lateSalePrice, amount, price, ...params } = Item
            if (isSelect === undefined) {
                newDetailData.push({
                    ...params,
                    id: "",
                    inventoryNum: availableStock ?? inventoryNum,
                    materialCode,
                    materialName,
                    num,
                    price: salePrice ?? price,
                    amount,
                    stockId: id,
                    detailsId: id,
                    presaleId: id,
                    warehouseName: Item.storageWarehouseName,
                    warehouseCode: Item.storageWarehouseCode,
                    stockCode: Item.orderCode,
                });
            } else {
                repetition.push(Item.materialName as string);
            }
        })
        return { newDetailData, repetition }
    }
    //添加到维修用料详情列表
    const transformsToPoMaterialDetailList = async (mode: string) => {
        //添加列表
        if (mode === "add") {
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialAddList, mode)
            setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        } else if (mode === "edit") {
            //详情列表
            const { repetition, newDetailData } = await modelMaterialScreenMethod(addDataMaterialSource, detailMaterialEditList, mode)
            setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData])
            repetition.length != 0 && globalPrompt("message", { text: `物料名称：${repetition.join(",")}，重复的将不在添加。`, type: "warning" });

        }
    }
    //删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
    const detailMaterialDetailList = async (mode: string) => {
        if (mode === "add") {
            const filterDetailList = detailMaterialAddList.filter(el => !!!detailMaterialAddDataRowKeys.find(ele => ele === el.detailsId))
            setDetailMaterialAddList(filterDetailList)
            globalPrompt("message", { text: `删除成功`, type: "success" });
            setDetailMaterialAddDataRowKeys([]);
            setDetailMaterialAddDataRow([]);
        } else if (mode === "edit") {
            const backendDetailList: any = detailMaterialEditDataRow.reduce((all: object[], next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);

            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = detailMaterialEditList.filter(el => !!!detailMaterialEditDataRowKeys.find(ele => ele === el.detailsId))
                setDetailMaterialEditList(filterDetailList)
                globalPrompt("message", { text: `删除成功`, type: "success" });
                setDetailMaterialEditDataRowKeys([]);
                setDetailMaterialEditDataRow([]);
            }

            if (backendDetailList.length > 0) {
                await busBusinesstService.saleMaterialDelete({ ids: backendDetailList, saleCode: detailEditForm.saleCode as string }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        globalPrompt("message", { text: `删除失败`, type: "success" });
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    //表单计算校验 (计算主单信息与配件每一项)
    const formComputeRule = (mode: string): boolean => {
        let goods = mode === "edit" ? detailMaterialEditList : detailMaterialAddList;
        let { totalAll } = mode === "edit" ? editDesc : addDesc;

        let goodsTotal: number = 0;
        totalAll = +formatNum(totalAll)
        goods.forEach(item => {
            let { amount } = item as { amount: number, num: number };
            goodsTotal += amount
        })
        if (+formatNum(goodsTotal) !== totalAll) {
            message.warning(`详情公交调配应收金额${+formatNum(goodsTotal)}与表单公交调配应收金额不符`);
            return false;
        }
        return true
    }
    //新增预售单
    const insertDetail = useLockFn(async (params: IDeploymentDetail) => {
        let newParams = {
            ...params,
            ...shopAndOrg,
            ...sourceAppOrBranch,
            details: detailMaterialAddList,
            createUser: user.username
        }
        let { retData } = await busBusinesstService.deploymentInsert(newParams)
        return retData
    })

    //关闭页签清不同状态缓存
    const resetAddCatch = async (type?: string) => {
        setDetailAddForm(initForm)
        setDetailMaterialAddList([]);
    }
    const resetEditCatch = async (type?: string) => {
        setDetailEditForm({});
        setDetailMaterialEditList([]);
    }
    const downloadTpl = async () => {
        let result = await busBusinesstService.saleDownloadTpl();
        convertRes2Blob(result)
    }
    const [historyVisible, setHistoryVisible] = useState<boolean>(false)
    const [historyRow, setHistoryRow] = useState<IDeploymentDetailsList>({})
    return {
        initForm,
        materialModel,
        initPgCfg,
        initMaterialSearchParam,
        detailAddForm,
        detailMaterialAddList,
        detailMaterialAddDataRowKeys,
        detailMaterialAddDataRow,
        addDesc,
        detailEditForm,
        detailMaterialEditList,
        detailMaterialEditDataRowKeys,
        detailMaterialEditDataRow,
        editDesc,
        detailLookForm,
        detailMaterialLookList,
        lookDesc,
        workMaterialDataSource,
        searchWorkMaterialData,
        workMaterialPgCfg,
        addDataMaterialSource,
        setMaterialModel,
        setDetailAddForm,
        setDetailMaterialAddList,
        setDetailMaterialAddDataRowKeys,
        setDetailMaterialAddDataRow,
        setAddDesc,
        setDetailEditForm,
        setDetailMaterialEditList,
        setDetailMaterialEditDataRowKeys,
        setDetailMaterialEditDataRow,
        setEditDesc,
        setDetailLookForm,
        setDetailMaterialLookList,
        setLookDesc,
        setWorkMaterialDataSource,
        setSearchWorkMaterialData,
        setWorkMaterialPgCfg,
        setAddDataMaterialSource,
        updateAddFormVal,
        updateEditFormVal,
        getDetailEditOne,
        resetWorkMaterialloadData,
        workMaterialloadData,
        modelMaterialScreenMethod,
        transformsToPoMaterialDetailList,
        detailMaterialDetailList,
        insertDetail,
        resetAddCatch,
        resetEditCatch,
        downloadTpl,
        editSettleDetails, setEditSettleDetails,
        lookSettleDetails, setLookSettleDetails,
        topButsControl, setTopButsControl,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow,
        formComputeRule,
        checked, setChecked,
        loading,
        setLoading
    }
})