import React, { useEffect } from "react";
import { Button, Tree, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { FormBox } from "./FormBox";
import { EventDataNode, DataNode } from "antd/lib/tree";
import { useDictModel } from "../dictModel";
import { IVRM2DictDetail } from "../dictService";
import useGlobalModel from "@/model/globalModel";

export const DataBox = () => {
    const { treeSource, loadData, setMode, setSelectedNodes, selectedNodes, setFormVal } = useDictModel();
    const { shopAndOrg: { shopCode } } = useGlobalModel()

    useEffect(() => {
        loadData(shopCode);
    }, [])


    //字典选中事件
    const onSelect = (selectedKeys: React.Key[], info: {
        event: "select";
        selected: boolean;
        node: any;
        selectedNodes: DataNode[];
        nativeEvent: MouseEvent;
    }) => {
        let selectNode = info.selectedNodes as IVRM2DictDetail[];
        console.log(selectNode)
        setMode(info.selected ? "edit" : "");
        setSelectedNodes(selectNode);
        if(selectNode[0]){
            let { shopCode, ...form } = selectNode[0]
            setFormVal({ ...form, shopCode: shopCode ? shopCode.split(",") : [] });
        }

    };


    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => {
                if (selectedNodes.length <= 0) {
                    message.warning("请选择要增加的父级");
                    return;
                }
                message.success("增加下级模式开启");
                setMode("nextInsert");
            }}
        >增加下级</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => {
                if (selectedNodes.length <= 0) {
                    message.warning("请选择要增加的同级");
                    return;
                }
                message.success("增加同级模式开启");
                setMode("add");
            }}
        >增加同级</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => {
                if (selectedNodes.length <= 0) {
                    message.warning("请选择要删除的节点");
                    return;
                }
            }}
        >删除</Button>
    </>;

    return (
        <YhBox title="数据字典" action={action}>
            <div className="dict-container flex">
                <div className="dict-list-box">
                    <Tree
                        selectedKeys={[selectedNodes[0]?.key]}
                        titleRender={(node: any) => `${node.name}`}
                        onSelect={onSelect}
                        treeData={treeSource}
                    />
                </div>
                <div className="dict-from-box flex">
                    {selectedNodes.length > 0 ?
                        <FormBox />
                        :
                        <div className="dict-init-box flex flex-1 items-center justify-center">数据字典</div>
                    }
                </div>
            </div>
        </YhBox>
    )
}