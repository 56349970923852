import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, Dropdown, Menu, Upload } from "antd";
import { PreWithdrawalDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { usePreWithdrawalModel } from "./preWithdrawalModel";
import { formatNum } from "@/utils/utils";
import { globalPrompt } from "@/components/message";
import useNavModel from "@/model/navModel";
import { PreWithdrawalSearchBox } from "./SearchBox"
import { importPropsConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";
import { SalePrint } from "./SalePrint"
import { DownOutlined } from "@ant-design/icons";

export const PreWithdrawalAll = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        loadData,
        exportSaleSo,
        listDownloadTpl,
        setPrint,
        print,
        detailsExport
    } = usePreWithdrawalModel()
    const { user } = useGlobalModel();
    const { selectedNavId, addNav, replaceNav } = useNavModel();

    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-5-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.returnCode as string, mode: "look", parentInfo: { source: "List", selectedNavId } },
            true
        );
    }
    const onAdd = () => {
        //这里加parentInfo参数是为了与详情信息新增跳转做区分 从此处跳转标识为从列表List跳转
        addNav('16-5-1', { id: "", mode: "add", parentInfo: { source: "List", selectedNavId } })
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleSo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onAdd}
        >
            新增
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        {/* <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/transit/preturn/list/import/${user.username}`, loadData)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onPrint}
        >
            打印
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => exportSaleSo()}
        >
            导出
        </Button> */}
        <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => listDownloadTpl().then()}
        >
            下载模板
        </Button> */}
    </>
    const desc: IDescription[] = [
        {
            label: "预退数量",
            value: selectedRowKeys.length === 0 ? descTotal?.returnNum??0 : (rowsDescTotal?.returnNum??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "预退金额",
            value: selectedRowKeys.length === 0 ? descTotal?.returnAmount??0 : formatNum(rowsDescTotal?.returnAmount??0),
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <PreWithdrawalSearchBox />
        <YhBox title="预退列表" action={action} descriptions={desc}>
            <PreWithdrawalDataTable />
        </YhBox>
        {print && <SalePrint />}
    </>
}