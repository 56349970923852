import React, { useEffect } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { IPreSaleDetail } from "../../../busBusinessType"
import { usePreSaleModel } from "./presaleModel"
import Tooltip from "antd/es/tooltip";
import { useDictModel } from "@/views/base/dict/dictModel";
import YhTag from "@/components/YhTag";
import { momentFormat } from "@/utils/utils";
type statusType = {
    "presale-order.create": string, // "blue",//已开单
    "presale-order.stock-io": string, // "magenta",//已出库
    "presale-order.change": string, // "red",//已作废
    "presale-order.transfer": string, // "cyan",//已转接
    [key: string]: any
}
const statusColor: statusType = {
    "presale-order.create": "blue",//已开单
    "presale-order.stock-io": "lime",//已出库
    "presale-order.change": "red",//已作废
    "presale-order.transfer": "cyan",//已转接
}
export const PreSaleDataTable = () => {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows,
        loadData, setSelectedRowKeys, setDescRows
    } = usePreSaleModel();
    const { getTreeNodeName } = useDictModel();

    const columns: ColumnsType<IPreSaleDetail> = [
        {
            align: "center",
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        },
        {
            align: "center",
            title: '预售单号',
            dataIndex: 'orderCode',
            fixed: "left",
            width: 120,
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '单据状态',
            dataIndex: 'status',
            fixed: "left",
            width: 140,
            render: (text) => <YhTag color={statusColor[text]} text={getTreeNodeName(text)} />,
        },
        {
            align: "center",
            title: '客户名称',
            dataIndex: 'customerName',
            width: 140,
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '联系人',
            dataIndex: 'contact',
            width: 140,
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '预售数量',
            dataIndex: 'presaleNum',
            width: 120,
        },
        {
            align: "center",
            title: '预售金额',
            dataIndex: 'presaleAmount',
            width: 120,
            render:(text)=>`￥${text}`,
        },
        {
            align: "center",
            title: '制单员',
            dataIndex: 'createUser',
            width: 120,
        },
        {
            align: "center",
            title: '制单时间',
            dataIndex: 'createTime',
            width: 140,
            render: (text) => momentFormat(text)
        },
        {
            align: "center",
            title: '备注',
            dataIndex: 'note',
        },
    ]

    useEffect(() => {
        loadData()
    }, [])

    const onRow = (record: IPreSaleDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    }
    const onRightClick = (record: IPreSaleDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IPreSaleDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                    setDescRows(selectedRows)
                },
                selectedRowKeys
            }}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }
            })}
            scroll={{ x: 'max-content', y: 520 }}
        />

    )
}