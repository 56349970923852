import React, { useEffect } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import useGlobalModel from '@/model/globalModel';

export function BaseAcctUnit () {
    const { mdmBeLoadDataAll, mdmShopLoadDataAll } = useGlobalModel();
    useEffect(() => {
        mdmBeLoadDataAll()
        mdmShopLoadDataAll()
    }, [])
    return (
        <>
            {/* <div>内部核算单位</div> */}
            <PageHeader />
            <DataBox />
        </>
    )
}