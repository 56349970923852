import React, { useEffect, FC } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/components/YHModal";
import { useStockBorrowDetailModel, IMaterialDetailModel } from "./stockBorrowDetailsModel";
import { ColumnsType } from "antd/lib/table";
import { YhBox } from "@/components/YhBox";
import { EditableRow, EditableCell } from "@/components/YHEditTable/editTable";
import { RouterParamType } from "@/model/navModel";
import { YhBut } from "@/components/YhButton";
import { formatNum, formatIntPoint } from "@/utils/utils";
enum OPT_TYPE {
    ADD = 1,
    REMOVE = 2
}
export const AddGoodsModal: FC<RouterParamType> = ({ id, mode }) => {
    const { addModalVisible: visible, workDataSource, workPgCfg, addDataSource, searchWorkData, setWorkDataSource, resetWorkloadData, setSearchWorkData, setAddDataSource, transformsToPoDetailList, workloadData, setAddModalVisible, } = useStockBorrowDetailModel();
    useEffect(() => {
        workloadData()
    }, [])


    const column = (optype: number) => [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t: any, r: any, i: number) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,

        }, {
            title: '库存',
            width: 120,
            dataIndex: 'inventoryNum',
        }, {
            title: '借件数量',
            width: 120,
            dataIndex: 'num',
            editable: true,
        }, {
            title: '库房',
            width: 140,
            dataIndex: 'warehouseName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        }, {
            title: '操作',
            width: 120,
            fixed: "right",
            align: "center",
            render: (text: string, record: IMaterialDetailModel) => optype == OPT_TYPE.ADD ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    disabled={!!addDataSource.find(item => item.id === record.id)}
                />
            ) : (
                    <YhBut
                        type="delete"
                        txt="删除"
                        click={() => remove(record)}
                    />
                )
        }
    ];
    const newSelectColumns = (optType: number) =>
        column(optType).map((col: any, index) => {
            return {
                ...col,
                onCell: (record: IMaterialDetailModel, rowIndex: number) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType == OPT_TYPE.ADD ? notHandleSave : handleSave,
                }),
            }
        })
    const notHandleSave = (row: IMaterialDetailModel) => {
        let { num, lateSalePrice, inventoryNum } = row
        num = +formatIntPoint(num, inventoryNum, "借件数量>库存数量")
        lateSalePrice = +formatNum(lateSalePrice)
        let newRow = {
            ...row,
            num,
            lateSalePrice,
        }
        const index = workDataSource.findIndex((item) => row.id === item.id);
        const item = workDataSource[index];
        workDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkDataSource([...workDataSource])
    }
    const handleSave = (row: IMaterialDetailModel) => {
        let { num, lateSalePrice, inventoryNum } = row
        num = +formatIntPoint(num, inventoryNum, "借件数量>库存数量")
        lateSalePrice = +formatNum(lateSalePrice)
        let newRow = {
            ...row,
            num,
            lateSalePrice,
        }
        const index = addDataSource.findIndex((item) => row.id === item.id);
        const item = addDataSource[index];
        addDataSource.splice(index, 1, { ...item, ...newRow });
        setAddDataSource([...addDataSource])
    }
    const add = async (record: IMaterialDetailModel) => {
        setAddDataSource(origin => [...origin, record])
    }
    const remove = async (record: IMaterialDetailModel) => {
        const filterAddDataSource = addDataSource.filter(item => item.id != record.id)
        setAddDataSource(filterAddDataSource)
    }
    const onOK = async () => {
        transformsToPoDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setAddModalVisible(false);
        setAddDataSource([]);
        resetWorkloadData()

    };
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialCode: e.target.value }) }}
            placeholder="商品编码"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialName: e.target.value }) }}
            placeholder="商品名称"
        />
        <Button onClick={() => workloadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => resetWorkloadData()} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOK}
        onCancel={onCancel}
        title="维修借件-选择配件"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选配件列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.ADD) as ColumnsType<IMaterialDetailModel>}
                dataSource={workDataSource}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={{
                    ...workPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workloadData({ pageNum, pageSize });
                    }
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
        <YhBox title="已选配件列表">
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id as string}
                columns={newSelectColumns(OPT_TYPE.REMOVE) as ColumnsType<IMaterialDetailModel>}
                dataSource={[...addDataSource]}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 200 }}
            />
        </YhBox>
    </YHModal>
}