import { httpRequest } from "@/utils/HttpRequest";
import { IPage, ISearchPage } from "@/types/ScmTypes";

//供应商信息
export interface IVRMSupplierInfo {
    contact: string,
    phone: string,
    note: string
}

//详情
export interface IVRMSupplierDetail {
    address?: string,     // "",
    attrCode?: string,     // "",
    attrName?: string,     // "",
    bank?: string,     // "",
    bankAcct?: string,     // "",
    bankPhone?: string,     // "",
    branch?: string,     // "",
    branchList?: string,     // [],
    businessScope?: string,     // "",
    categoryCode?: string,     // "",
    categoryName?: string,     // "",
    code?: string,     // "",
    contact?: string,     // "",
    corporation?: string,     // "",
    email?: string,     // "",
    enable?: number,     // 0,
    fax?: string,     // "",
    invoiceTitle?: string,     // "",
    isInternal?: string,     // "",
    level?: number,     // 0,
    licenseNo?: string,     // "",
    linkCode?: string,     // "",
    name?: string,     // "",
    note?: string,     // "",
    orgCode?: string,     // "",
    orgName?: string,     // "",
    phone?: string,     // "",
    postAddress?: string,     // "",
    postCode?: string,     // "",
    shopId?: string,     // "",
    shopName?: string,     // "",
    sourceApp?: string,     // "",
    sourceAuthKey?: string,     // "",
    staffCode?: string,     // "",
    staffName?: string,     // "",
    status?: number | string,     // 0,
    tel?: string,     // "",
    typeCode?: string,     // "",
    typeName?: string,     // "",
    ver?: number,     // 0,
    website?: string,     // ""
    id?: string,
}

//搜索条件
export interface IVRMSupplierSearchFields {
    shopId?: string,
    code?: string,
    name?: string,
    contact?: string,
    phone?: string,
    status?: string,
    typeCode?: string,
    linkCode?: string,
}

export interface IVRMSupplierSearch
    extends IVRMSupplierDetail, ISearchPage {

}

class SupplierService {
    async page(searchVo: IVRMSupplierSearch) {
        const apiUrl = "/bcm/base/supplier/findPage";
        return await httpRequest.post<IPage<IVRMSupplierDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/base/supplier/findById/${id}`;
        return await httpRequest.get<IVRMSupplierDetail>(apiUrl);
    }
    async insert(insertVo: IVRMSupplierDetail) {
        const apiUrl = "/bcm/base/supplier/insert";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }
    async update(updateVo: IVRMSupplierDetail) {
        const apiUrl = "/bcm/base/supplier/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //启用禁用
    async disable(params: object) {
        const apiUrl = `/bcm/base/supplier/change`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导入
    async import(file: { file: File }) {
        const appPageUrl = "/bcm/base/supplier/import";
        return await httpRequest.post<boolean>(appPageUrl, file);
    }
    //导出
    async export(searchVo: IVRMSupplierSearch) {
        const appPageUrl = "/bcm/base/supplier/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //供应商转客户
    async transfer(id: string) {
        const appPageUrl = `/bcm/base/supplier/transfer/${id}`;
        return await httpRequest.post<boolean>(appPageUrl);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/bcm/base/supplier/downloadTpl";
        return await httpRequest.getFile<boolean>(appPageUrl);
    }
}

export const supplierService = new SupplierService();
