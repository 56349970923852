import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { IStockDetail } from "../../../busBusinessType"
import { useStockModel } from "./stockModel"
import { Tooltip } from "antd";
import useNavModel, { RouterParamType } from "@/model/navModel";
export const StockDataTable = () => {
    const {
        dataSource, selectedRowKeys, pgCfg, descRows, initPgCfg, setSearchParam,
        loadData, setSelectedRowKeys, setDescRows
    } = useStockModel();
    const { getSelectedNavState, } = useNavModel();
    const [detailsInfo] = useState<RouterParamType>(getSelectedNavState() as RouterParamType);
    const { parentInfo } = detailsInfo;

    useEffect(() => {
        loadData(initPgCfg, {}, parentInfo.warehouseCode)
        // setSearchParam({})
    }, [])
    const columns: ColumnsType<IStockDetail> = [
        {
            align: "center",
            title: '序号',
            render: (t, r, i) => i + 1,
            width: 40,
            fixed: "left",
        },
        {
            align: "center",
            title: '公交编码',
            dataIndex: 'materialNo',
            width: 120,
            fixed: "left",
        },
        {
            align: "center",
            title: '商品编码',
            dataIndex: 'materialCode',
            width: 120,
            fixed: "left",
        },
        {
            align: "center",
            title: '替换编号',
            dataIndex: 'replaceCode',
            width: 120,
            fixed: "left",
        },
        {
            align: "center",
            title: '商品名称',
            dataIndex: 'materialName',
            width: 120,
            fixed: "left",
            render: (text: any) => <Tooltip placement="topLeft" title={text}><p className="ellipsis">{text}</p></Tooltip>,
        },
        {
            align: "center",
            title: '商品类型',
            dataIndex: 'typeName',
            width: 120,

        },
        {
            align: "center",
            title: '品牌',
            dataIndex: 'brandName',
            width: 120,
        },
        {
            align: "center",
            title: '初始库存',
            dataIndex: 'inventoryInitial',
            width: 120,
        },
        {
            align: "center",
            title: '库存数量',
            dataIndex: 'inventoryNum',
            width: 120,
        },
        {
            align: "center",
            title: '可用库存',
            dataIndex: 'availableStock',
            width: 120,
        }, {
            title: '预售单号',
            width: 120,
            dataIndex: 'stockCode',
        },
        {
            align: "center",
            title: '预售数量',
            dataIndex: 'pretsaleNum',
            width: 120,
        },
        {
            align: "center",
            title: '销售数量',
            dataIndex: 'saleNum',
            width: 120,
        },
        {
            align: "center",
            title: '调配数量',
            dataIndex: 'deploymentNum',
            width: 120,
        },
        {
            align: "center",
            title: '预退数量',
            dataIndex: 'preturnNum',
            width: 120,
        },
        {
            align: "center",
            title: '单位',
            dataIndex: 'unitName',
            width: 120,
        },
        {
            align: "center",
            title: '销售单价（最新进价）',
            dataIndex: 'salePrice',
            width: 160,
        },
        {
            align: "center",
            title: '进货价（最新进价）',
            dataIndex: 'latestPurPrice',
            width: 140,
        },
        {
            align: "center",
            title: '库存成本（最新进价）',
            dataIndex: 'latestPurCost',
            width: 155,
        },
        {
            align: "center",
            title: '进货价（平均价）',
            dataIndex: 'averagePrice',
            width: 140,
        },
        {
            align: "center",
            title: '库存成本（平均价）',
            dataIndex: 'averageCost',
            width: 140,
        },
    ]
    const onRow = (record: IStockDetail) => {
        let { id } = record as { id: string }
        let findIndexDesc = descRows.findIndex((item) => (item.id === id))
        let newDescRows = [...descRows];
        let newSelectedRowKeys = [...selectedRowKeys];

        if (findIndexDesc === -1) {
            //统计数据
            newDescRows.push(record)
            setDescRows(newDescRows)
            //表格选中行Key值
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            //统计数据
            newDescRows.splice(findIndexDesc, 1);
            setDescRows(newDescRows);
            //表格选中行Key值
            newSelectedRowKeys.splice(findIndexDesc, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    }
    const onRightClick = (record: IStockDetail) => {
        setDescRows([record])
        setSelectedRowKeys([record.id as string])
    }
    return (
        <Table
            style={{ cursor: "pointer" }}
            rowKey={record => record.id as string}
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
                onChange: (selectedRowKeys: React.Key[], selectedRows: IStockDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                },
                selectedRowKeys
            }}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                pageSizeOptions:[10,20,50,100,300],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    onRightClick(record)
                }

            })}
            scroll={{ x: 'max-content', y: 520 }}
        />

    )
}