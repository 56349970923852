import { useState, Key, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { ISearchPage } from "@/types/ScmTypes";
import { globalPrompt } from "@/components/message";
import { convertRes2Blob } from "@/utils/exportFile";
import { transPgToPagination, formatNum } from "@/utils/utils";
import { itemService } from "./itemService";
import { IItemSearch, IItemDetail, IItemFields } from "./itemType"
import useGlobalModel from "@/model/globalModel";
import { useLockFn } from "ahooks";
export const useItemModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    //切换门店清除缓存
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [shopAndOrg.shopCode])
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const initFormVal: IItemFields = {
        workHours: 1,
        price: 0,
        amount: 0,
    }
    //列表数据
    const [dataSource, setDataSource] = useState<IItemDetail[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IItemFields>({});
    //分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState<IItemFields>({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState<IItemFields>(initFormVal);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState<IItemFields>({});
    //设置搜索value
    const updateSearchParam = (updateParam: IItemFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //列表查询
    const loadData = async (page?: ISearchPage, searchValue: IItemFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
        let { retData } = await itemService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }
    //表单新增修改
    const updateAddFormVal = (val: IItemFields) => {
        let newVal = val
        if ("workHours" in val) {
            const workHours = val.workHours as number
            newVal = { ...val, amount: +formatNum(addFormVal.price as number * workHours) }
        }
        if ("price" in val) {
            const price = val.price as number
            newVal = { ...val, amount: +formatNum(addFormVal.workHours as number * price) }
        }
        Object.keys(val).length !== 0 ? setAddFormVal({ ...addFormVal, ...newVal, }) : setAddFormVal({});
    }
    //表单新增修改
    const updateEditFormVal = (val: IItemFields) => {
        let newVal = val
        if ("workHours" in val) {
            const workHours = val.workHours as number
            newVal = { ...val, amount: +formatNum(editFormVal.price as number * workHours) }
        }
        if ("price" in val) {
            const price = val.price as number
            newVal = { ...val, amount: +formatNum(editFormVal.workHours as number * price) }
        }
        Object.keys(val).length !== 0 ? setEditFormVal({ ...editFormVal, ...newVal }) : setEditFormVal({});
    }
    //获取一条详情数据 Api
    const getDetailOne = async (id: string, pattern: string) => {
        let { retData } = await itemService.one(id);
        // retData && setEditFormVal(retData);
        if (retData && pattern === "edit") {
            setEditFormVal(retData);
        } else if (retData && pattern === "look") {
            setLookFormVal(retData);
        }
    }
    //表单新增 Api
    const insertDetail = useLockFn(async (data: IItemDetail) => {
        let { retData } = await itemService.insert(data);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "维修项目新增成功", type: "success" })
        } else {
            globalPrompt("message", { text: "维修项目新增失败", type: "error" });
        }
        return retData;
    })

    //表单编辑 Api
    const editDetail = useLockFn(async (data: IItemDetail) => {
        let { retData } = await itemService.update(data);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "维修项目编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "维修项目编辑失败", type: "error" });
        }
        return retData;
    })

    //导入
    const importData = async (file: { file: File }) => {
        let { retData } = await itemService.import(file);
        if (retData) {
            loadData()
            globalPrompt("message", { text: "导入成功", type: "success" })
        } else {
            globalPrompt("message", { text: "导入失败", type: "error" });
        }
        return retData;
    }

    //导出
    const exportData = async (searchValue: IItemSearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            ...shopAndOrg,
            id: selectedRowKeys.join(),
        }
        let result = await itemService.export(searchParams);
        convertRes2Blob(result);
    }
    //下载模板
    const downloadTpl = async () => {
        let result = await itemService.downloadTpl();
        convertRes2Blob(result);
        // let { retData } = await itemService.downloadTpl();
        // retData ?
        //     globalPrompt("message", { text: "下载模板成功", type: "success" })
        //     : globalPrompt("message", { text: "下载模板失败", type: "error" });
        // return retData;
    }
    //项目禁用开启
    const onDisabled = async (id: string, checked: boolean) => {
        let { retData } = await itemService.disable({ id, enable: checked ? 1 : 0 })
        if (retData) {
            loadData()
            globalPrompt("message", { text: "项目编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "项目编辑失败", type: "error" })
        }
        return retData;
    }
    return {
        dataSource,
        searchParam,
        pgCfg,
        selectedRowKeys,
        addFormVal,
        editFormVal,
        initPgCfg,
        lookFormVal,
        initFormVal,
        setAddFormVal,
        setSelectedRowKeys,
        setPgCfg,
        setSearchParam,
        setDataSource,
        updateSearchParam,
        resetSearchParam,
        loadData,
        updateAddFormVal,
        updateEditFormVal,
        getDetailOne,
        insertDetail,
        editDetail,
        importData,
        exportData,
        downloadTpl,
        setLookFormVal,
        onDisabled
    }
})