import {
    IBillCancel,
    IBillCashDetail,
    IBillCashFields,
    IBillCashSearch,
    IBillDetail,
    IBIllFields,
    IBillLogDetail,
    IBillLogSearch,
    IBillSearch,
    IBillSummary,
    IBillWriteoffDetail,
    IBillWriteoffFields,
    IBillWriteoffSearch

} from "./BillTypes";
import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";

class BillService {
    //新增账单
    insert(param: IBIllFields) {
        const apiUrl = "/fms/bill/insert";
        return httpRequest
            .post<IBillDetail>(apiUrl, param);
    }

    //账单审核
    approval(param: IBillDetail) {
        const apiUrl = "/fms/bill/approval";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }

    //取消账单
    cancel(param: IBillCancel) {
        const apiUrl = "/fms/bill/cancel";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }

    //取消账单审核
    cancelApproval(param: IBillCancel) {
        const apiUrl = "/fms/bill/cancel/approval";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }

    //账单查询(分页)
    page(param: IBillSearch) {
        const apiUrl = "/fms/bill/page";
        return httpRequest
            .post<IPage<IBillDetail>>(apiUrl, param);
    }

    //账单查询(单条)
    one(code: string) {
        const apiUrl = "/fms/bill/one/" + code;
        return httpRequest
            .post<IBIllFields>(apiUrl);
    }

    //账单核销
    writeoff(param: IBillWriteoffFields) {
        const apiUrl = "/fms/bill/writeoff";
        return httpRequest
            .post<IBillWriteoffDetail>(apiUrl, param);
    }

    //账单统计
    async billSummary(param: IBillSearch) {
        const apiUrl = "/fms/bill/billSummary";
        return await httpRequest
            .post<IBillSummary>(apiUrl, param);
    }

    //账单核销记录查询(分页)
    writeoffPage(param: IBillWriteoffSearch) {
        const apiUrl = "/fms/bill/writeoff/page";
        return httpRequest
            .post<IPage<IBillWriteoffDetail>>(apiUrl, param);
    }

    //账单现金收款
    cash(param: IBillCashFields) {
        const apiUrl = "/fms/bill/cash";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }

    //新增账单现金收款明细
    cashInsert(param: IBillCashFields) {
        const apiUrl = "/fms/bill/cash/insert";
        return httpRequest
            .post<IBillCashDetail>(apiUrl, param);
    }

    //确认账单现金收款明细
    cashConfirm(code: string) {
        const apiUrl = "/fms/bill/cash/confirm/" + code;
        return httpRequest
            .post<boolean>(apiUrl);
    }

    //修改账单现金收款
    cashUpdate(param: IBillCashFields) {
        const apiUrl = "/fms/bill/cash/update";
        return httpRequest
            .post<boolean>(apiUrl, param);
    }

    //删除账单现金收款
    cashDelete(code: string) {
        const apiUrl = "/fms/bill/cash/delete/" + code;
        return httpRequest
            .post<boolean>(apiUrl);
    }

    //账单现金收款查询
    cashPage(param: IBillCashSearch) {
        const apiUrl = "/fms/bill/cash/page";
        return httpRequest
            .post<IPage<IBillCashDetail>>(apiUrl, param);
    }

    //账单日志查询
    logPage(param: IBillLogSearch) {
        const apiUrl = "/fms/bill/log/page";
        return httpRequest
            .post<IPage<IBillLogDetail>>(apiUrl, param);
    }

    //账单导出
    export(param: IBillLogSearch) {
        const apiUrl = "/fms/bill/export";
        return httpRequest
            .postFile(apiUrl, param, { timeout: 60000 });
    }
}

export const billService = new BillService();
