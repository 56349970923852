import { httpRequest } from "@/utils/HttpRequest";
import { ISearchPage, IPage } from "@/types/ScmTypes";

export interface IVRMSoListDetail {
    documentNumber: string,//"TJHXS2022011607",
    tradeTypeCode: string,//"trade-type.sale",
    customerName: string,//"白金库",
    customerTypeCode: string,//"customer-type.group",
    customerTypeName: string,//"集团客户",
    shopName: string,//"天津红岩品牌部",
    shopId: string,//"18059196cb7c4829ac35f23329ba2256",
    status: string,//"sale.stock-io",
    tradeTypeName: string,//"销售",
    type: string,//null,
    totalAmount: string,//787.5,
    paidAmount: string,//787.5,
    discountTotalAmount: string,//0,
    materialScienceAmount: string,//null,
    materialDiscountAmount: string,//null,
    workingHoursAmount: string,//null,
    workingDiscountAmount: string,//null,
    totalCost: string,//787.5,
    grossProfit: string,//0,
    createUser: string,//"张晓莹",
    settleTime: string,//"2022-01-166",
    createTime: string,//"2022-01-167",
    note: string,//""
    [key: string]: any
}

//搜索条件
export interface IVRMSoListSearchFields {
    [key: string]: any
}

export interface IVRMSoListSearch
    extends IVRMSoListSearchFields, ISearchPage {
}
export interface IStatistics {
    totalNum: number, //销售数量
    payAmount: number, //826.95,          // 销售金额
    receivableAmount: number, //633.15,   // 应收金额
    receivedAmount: number, //633.15,     // 已收金额
    paidAmount: number, //193.8,          // 已付金额
    costAmount: number, //826.95,         // 销售成本
    grossProfitAmount: number, //0        // 销售毛利
}

//采购报表
class SoListService {
    async page(searchVo: IVRMSoListSearch) {
        const apiUrl = "/bcm/sale/saleStatistics";
        return await httpRequest.post<IPage<IVRMSoListDetail>>(apiUrl, searchVo);
    }
    async export(searchVo: IVRMSoListSearch) {
        const apiUrl = "bcm/sale/saleStatistics/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }
    // 头部统计
    async statistics(searchVo: IVRMSoListSearch) {
        const apiUrl = "/bcm/sale/statistics/total";
        return await httpRequest.post<IStatistics>(apiUrl, searchVo);
    }
}

export const soListService = new SoListService();