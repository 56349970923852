import React from "react";
import { Button } from "antd";
import { YhForm } from "@/components/YhFormBox";
import { FormItemTypeEnum, IYhForm } from "@/components/YhFormBox/Types";
import { useDictModel } from "../dictModel";
import useGlobalModel from "@/model/globalModel";

export const FormBox = () => {
    const { mode, setSelectedNodes, onSubmit, onLocalSubmit, formVal, onChangeFormVal } = useDictModel();
    const { shopDs, } = useGlobalModel();


    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        formValues: formVal,
        onChange: onChangeFormVal,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "父级编码",
                fieldName: "pcode",
                span: 18,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "编码",
                fieldName: "code",
                span: 18,
                disable: mode === "edit" ? true : false,
                rules: [{ required: true, message: "该项为必填项" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "名称",
                fieldName: "name",
                span: 18,
                rules: [{ required: true, message: "该项为必填项" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "Value",
                fieldName: "value",
                span: 18,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 18,
            // }, {
            //     type: FormItemTypeEnum.Select,
            //     fieldLabel: "所属门店",
            //     fieldName: "shopCode",
            //     options: shopDs.map((item: any) => {
            //         let { label, code } = item
            //         return {
            //             label,
            //             value: code
            //         }
            //     }),
            //     mode:"multiple",
            //     span: 18,
            }, {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "状态",
                fieldName: "enable",
                span: 18,
            }
        ]
    };


    return (
        <>
            <div className="dict-list-header">
                <Button type="link" shape="round" onClick={onLocalSubmit}>本地保存</Button>
                <Button type="link" shape="round" onClick={onSubmit}>保存</Button>
                <Button type="link" shape="round" onClick={() => setSelectedNodes([])}>取消</Button>
            </div>
            <div className="dict-from-content">
                <YhForm {...formConfig} />
            </div>
        </>
    )
}