import React from "react";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/ScmDictEnums";
import useGlobalModel from "@/model/globalModel";
import { useAllocModel } from "../inventoryStatisticsModel";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useAllocModel();
    const { getSelectedDictOne, getTreeSelectOne } = useDictModel(() => []);

    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "是否积压",
            fieldName: "overstockFlag",
            options: [{ label: "是", value: 1 }, { label: "否", value: 0 }],
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品编码",
            fieldName: "materialCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "商品名称",
            fieldName: "materialName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "替换编号",
            fieldName: "replaceCode"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "货位",
            fieldName: "shelfCode",
        },

    ]

    return (
        <YhPageHeader
            title="销存率统计"
            size="small"
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}