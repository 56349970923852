import React from "react";
import { YhBox } from "@/components/YhBox";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
import { useBillModel } from "./billModel";
import { billStatusDs, ioFlagDs, tradeTypeDs } from "@/types/FmsDs";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useOrgModel } from "@/views/organization/org/orgModel";
import useGlobalModel from "@/model/globalModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";

export const BIllSearchBox = () => {
    const { getOrgTreeSelect } = useOrgModel();
    const { beDs } = useGlobalModel();
    const {
        onSearch,
        searchParam,
        onReset,
        updateSearchParam,loadData
    } = useBillModel()


    const searchFields = [
        {
            type: SearchTypeEnum.TreeSelect,
            placeholder: "组织机构",
            fieldName: "orgCode",
            treeData: getOrgTreeSelect(DictCodeEnmu.ORG)
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "实体",
            fieldName: "beCode",
            options: beDs
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "单据类型",
            fieldName: "tradeTypeCode",
            options: tradeTypeDs
        }, {
            type: SearchTypeEnum.Input,
            placeholder: "单据编码",
            fieldName: "tradeCode",
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "收支类型",
            fieldName: "ioFlag",
            options: ioFlagDs
        }, {
            type: SearchTypeEnum.Select,
            placeholder: "收款状态",
            fieldName: "status",
            options: billStatusDs
        }, {
            type: SearchTypeEnum.FmsRangePicker,
            fieldName: "billTimeRange",
            placeholder: ["起始日期", "结束日期"],
        },

    ]

    return (
        <YhBox style={{ paddingBottom: 0, margin: "15px 0px" }}>
            <YhPageHeader
                title=""
                justify="end"
                searchValues={searchParam as ISearchValue}
                searchFields={searchFields}
                onChange={updateSearchParam}
                onFmsChange={updateSearchParam}
                                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={onReset}
            />
        </YhBox>
    )
}
