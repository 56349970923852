import React from "react";
import { useSoListModel } from "../soListModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";



export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useSoListModel();
    const { getSelectedDictOne, getTreeSelectOne } = useDictModel(() => []);

    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            placeholder: "客户名称",
            fieldName: "customerName"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "销售单号",
            fieldName: "documentNumber"
        },
        {
            type: SearchTypeEnum.Input,
            placeholder: "制单员",
            fieldName: "createUser"
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["settlementStartTime", "settlementEndTime"],
            placeholder: ["结算起始日期", "结算结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },

    ]
    const searchMoreFields = [
        {
            type: SearchTypeEnum.Select,
            placeholder: "销售类型",
            fieldName: "saleTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.SALES_TYPE)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.PAYEE_TYPE)
        },
        {
            type: SearchTypeEnum.Select,
            placeholder: "单据类型",
            fieldName: "tradeTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE)
            ?.filter(
                item =>  `${item.value}`==='trade-type.sale-return'||`${item.value}`==='trade-type.sale'   
                ) ?? []
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            rangePickerFieldName: ["startTime", "endTime"],
            placeholder: ["开单起始日期", "开单结束日期"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },

    ]
    return (
        <YhPageHeader
            title="销售单报表"
            size="small"
            // justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}