import { httpRequest } from "../../utils/HttpRequest";
import {
    IAccountFields,
    IAccountDetail,
    ICustomerAccountSummary,
    IAccountSummary,
    ISupplierAccountSummary,
} from "./AccountTypes";
import { CreditFlagEnum } from "@/types/ScmEnums";
import { IMdCustomerSearch, IMdSupplierSearch, IMdCustomerFields, IMdSupplierFields } from "@/types/FmsCustomerTypes";
import { IPage } from "@/types/ScmTypes";


export const creditFlagDs = () => {
    return [{
        label: '记账',
        value: CreditFlagEnum.CREDIT.valueOf()
    }, {
        label: '现结',
        value: CreditFlagEnum.NON_CREDIT.valueOf()
    }]
}

class AccountShopService {
    //
    async customerSummaryPage(searchVo: IMdCustomerSearch) {
        const apiUrl = "/fms/accountShop/summary/customer/page";
        return await httpRequest
            .post<IPage<ICustomerAccountSummary>>(apiUrl, searchVo);
    }
    async customerSummary(searchVo: IMdCustomerSearch) {
        const apiUrl = "/fms/accountShop/summary/customer";
        return await httpRequest
            .post<IAccountSummary>(apiUrl, searchVo);
    }
    //
    async supplierSummaryPage(searchVo: IMdSupplierSearch) {
        const apiUrl = "/fms/accountShop/summary/supplier/page";
        return await httpRequest
            .post<IPage<ISupplierAccountSummary>>(apiUrl, searchVo);
    }
    //
    async supplierSummary(searchVo: IMdSupplierSearch) {
        const apiUrl = "/fms/accountShop/summary/supplier";
        return await httpRequest
            .post<IAccountSummary>(apiUrl, searchVo);
    }
    //账户查询
    async find(param: IAccountFields) {
        const apiUrl = "/fms/accountShop/find";
        return await httpRequest
            .post<IAccountDetail[]>(apiUrl, param)
    }
    //财务客户同步
    async customerSync(syncVo: IMdCustomerFields) {
        const appPageUrl = "/fms/customer/sync";
        syncVo.sourceApp = "fms";
        return await httpRequest.post<boolean>(appPageUrl, syncVo, { timeout: 500000 });
    }
    //财务供应商同步
    async supplierSync(syncVo: IMdSupplierFields) {
        const appPageUrl = "/fms/supplier/sync";
        syncVo.sourceApp = "fms";
        return await httpRequest.post<boolean>(appPageUrl, syncVo, { timeout: 500000 });
    }
}

export const accountShopService = new AccountShopService();
