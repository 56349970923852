import React from "react"
import { YhBox } from "@/components/YhBox";
import { Button, message, Dropdown, Menu, Upload, Input } from "antd";
import { SaleDataTable } from "./DataTable"
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { useSaleModel } from "./saleModel";
import { formatNum } from "@/utils/utils";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { DownOutlined } from "@ant-design/icons";
import { SalePrint } from "./SalePrint"
import { useSaleDetailModel } from "../view/detail/sale/detail/saleDetailModel";
import { SaleSearchBox } from "./SearchBox"
import { importPropsConfig } from "@/utils/importFile";
import useGlobalModel from "@/model/globalModel";
import { accountService } from "@/views/account/AccountService";

enum IBentityEnum {
    OUHAO = "bentity.bj-ouhao",
    QICHI = "bentity.bj-qichi",
    HENGYU = "bentity.bj-shenzhou-hengyu",
    TIANLI = "bentity.bj-sixth-ring-tianli",
    ZHIDA = "bentity.bj-dexing-zhida",
}
export const SaleAll = () => {
    const {
        selectedRowKeys,
        rowsDescTotal,
        descTotal,
        selectedRow,
        descRows,
        invalidateData,
        exportSaleSo,
        detailsExport,
        saleTransfer,
        print,
        setPrint,
        loadData,
        listDownloadTpl,
        saleBatchWarehousing,
        saleBatchSettle,
        settleLoading,
        setSettleLoading
    } = useSaleModel();
    const { user } = useGlobalModel()
    const { resetEditCatch } = useSaleDetailModel()
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.stock-io") {
            globalPrompt("message", { text: "该单据已出库，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.change") {
            globalPrompt("message", { text: "该单据已作废，无法进行编辑", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        let saleSoEditKey = "16-3-2";
        let isFindNav = findNav(saleSoEditKey);
        if (!isFindNav) {
            addNav(saleSoEditKey, { id: selectedRow?.saleCode as string, mode: "edit", parentInfo: { selectedNavId } });
        } else {
            globalPrompt("modal", {
                title: "销售记录跳转详情",
                type: "warning",
                text: "您还有销售记录详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(saleSoEditKey, { id: selectedRow?.saleCode as string, mode: "edit", parentInfo: { selectedNavId } });
                    // updateEditFormVal(null);
                    resetEditCatch()
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "16-3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.saleCode as string, mode: "look", parentInfo: { selectedNavId } },
            true
        );
    }
    const onInvalidate = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行作废", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "销售记录作废",
            type: "error",
            text: <><span>请输入作废原因</span>
            <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                let obj = {
                    id: selectedRow.id,
                    saleCode: selectedRow.saleCode as string,
                    cancelReason
                }
                invalidateData(obj)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onSaleTransfer = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "sale.transfer") {
            globalPrompt("message", { text: "该单据已转接，无法进行编辑", type: "warning" });
            return;
        }
        saleTransfer(selectedRow.saleCode as string)
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => exportSaleSo()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const onAdd = () => {
        addNav('16-3-1', { id: "", mode: "add", parentInfo: { source: "List", selectedNavId } })
    }
    const onWhConfirm = async () => {
        let params = descRows.map((item) => ({ id: item.id as string, saleCode: item.saleCode as string, storekeeper: user.username }))
        //debugger
        let res = await saleBatchWarehousing({ details: params })
        if (res) {
            message.warning(res.msg)
            loadData()
        }
    }
    const getPayMethod = (bentityCode: string) => {
        if (IBentityEnum.OUHAO === bentityCode) {
            return { payMethodCode: "pay-method.cash.tt-ouhao", payMethodName: "电汇-欧豪", payee: "yn" }
        } else if (IBentityEnum.QICHI === bentityCode) {
            return { payMethodCode: "pay-method.cash.tt-qichi", payMethodName: "电汇-綦齿", payee: "yn" }
        } else if (IBentityEnum.HENGYU === bentityCode) {
            return { payMethodCode: "pay-method.cash.tt-shenzhou", payMethodName: "电汇-神州", payee: "payee.guohuabing" }
        } else if (IBentityEnum.ZHIDA === bentityCode) {
            return { payMethodCode: "pay-method.cash.tt-dexing", payMethodName: "电汇-德兴", payee: "payee.liuyan" }
        } else {
            return { payMethodCode: "", payMethodName: "" }
        }
    }
    const onBatchSettle1 = async () => {    
        let filList = descRows.filter(item => item.status !== "sale.create")
        if (filList.length !== 0) {
            globalPrompt("message", { text: "请选择为已开单的状态", type: "warning" });
            return
        }

        //查找销售单客户名称对应的账户
        // let params = {
        //     partnerCode,  //客户/供应商编码 
        //     beCode: typeForm[mode].bentityCode,
        //     status: 0,
        //     creditFlag,  //为记账类型的  1挂账 2现结
        //     sourceApp: "bcm",
        // }
        // debugger
        let settleData: any[] = [];
        let customerNotAccount: string[] = []
        await Promise.all(descRows.map(async item => {
            let { id, saleCode, payType,shopId, receivableAmount, bentityCode, customerCode, customerName,status } = item as { id: string, saleCode: string, payType: string,shopId:string, receivableAmount: number, bentityCode: string, customerCode: string, customerName: string,status: string}
            // let { accountType, accountName, accountCode, } = await queryAccount(customerCode, bentityCode)
            // if (!accountName && !accountCode) {//没有账户的push 到数据里
            //     customerNotAccount.push(customerName)
            // }
            return {
                id: id,
                saleCode: saleCode,
                reckoner:user.userName,
                customerCode,
                bentityCode,
                shopId,
                receivableAmount,
                status,
                // note: '',
                // reckoner: user.username,
                // payType: "settle-type.cash",
                // payableAmount: receivableAmount,
                // accountType, accountName, accountCode,
                details: [{
                    // ...getPayMethod(bentityCode),
                    // accountName,
                    // accountCode,
                    // amount: receivableAmount
                    saleCode,id,
                    customerCode,
                    bentityCode,
                    shopId,
                    receivableAmount,
                    status
                }]
            }
           
        })).then(result => {
            settleData = result
        })
        if (customerNotAccount.length !== 0) {
            message.error(`该客户名称为${customerNotAccount.toString()}无现金账户，去客户账户管理开启对应的现金账户吧！`);
            return false
        }
        // 
        let res = await saleBatchSettle(settleData)
        if (res) {
            message.success(res.msg,10)
            await loadData()
        }
        // console.log(settleData)
    }
    const onBatchSettle = async () => {
        let filList = descRows.filter(item => item.status !== "sale.create")
        console.log(filList)
        if (filList.length !== 0) {
            globalPrompt("message", { text: "请选择为已开单的状态", type: "warning" });
            return
        }
        let settleData = descRows.map(item => {
            let { id, saleCode, payType, receivableAmount, bentityCode } = item as { id: string, saleCode: string, payType: string, receivableAmount: number, bentityCode: string }
            return {
                id: id,
                saleCode: saleCode,
                payType: "settle-type.cash",
                reckoner: user.username,
                note: '',
                details: [{
                    ...getPayMethod(bentityCode),
                    // payMethodCode:'',
                    // payMethodName:'',
                    // payee: "",
                    amount: receivableAmount
                }]
            }
        })
        let res = await saleBatchSettle(settleData)
        if (res) {
            message.error(res.msg)
            loadData()
        }
        // console.log(settleData)
    }
    const queryAccount = async (partnerCode: string, beCode: string) => {
        let params = {
            partnerCode,  //客户/供应商编码 
            beCode,
            status: 0,
            creditFlag: 0,  //为记账类型的  1挂账 2现结
            sourceApp: "bcm",
        }
        const { retData } = await accountService.accountFind(params);
        let cashData = retData.find(item => item.accountTypeCode === "account-type.cash")
        return {
            accountType: "account-type.cash",
            accountName: cashData?.name,
            accountCode: cashData?.code,
            available: cashData?.availableAmount,

        }
    }
    const action = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onAdd}
        >
            新增
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onEdit}
        >
            编辑
        </Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onLook}
        >
            查看
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onInvalidate}
        >
            作废
        </Button> */}
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onSaleTransfer}
        >
            转销退
        </Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => onPrint()}
        >
            打印
        </Button>
        <Dropdown overlay={exportMenu}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
            >导出<DownOutlined /></Button>
        </Dropdown>
        {/* <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/bcm/transit/sale/list/import/${user.username}`, loadData)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onWhConfirm}
        >
            批量出库
        </Button>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => listDownloadTpl().then()}
        >
            下载模板
        </Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            loading={settleLoading}
            onClick={() => onBatchSettle1()}
        >
            {/* 批量结算 */}
            {settleLoading ? '批量结算中' : '批量结算'}
        </Button>
    </>
    const desc: IDescription[] = [
        {
            label: "销售数量",
            value: selectedRowKeys.length === 0 ? descTotal?.saleNum??0 : rowsDescTotal?.saleNum??0,
            color: DescriptionColorEnum.red
        },
        {
            label: "销售金额",
            value: selectedRowKeys.length === 0 ? descTotal?.saleAmount??0 : formatNum(rowsDescTotal?.saleAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "优惠金额",
            value: selectedRowKeys.length === 0 ? descTotal?.preferentialAmount??0 : formatNum(rowsDescTotal?.preferentialAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "应收金额",
            value: selectedRowKeys.length === 0 ? descTotal?.receivedAmount??0 : formatNum(rowsDescTotal?.receivableAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "已收金额",
            value: selectedRowKeys.length === 0 ? descTotal?.receivableAmount??0 : formatNum(rowsDescTotal?.receivedAmount??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "销售成本",
            value: selectedRowKeys.length === 0 ? descTotal?.saleCost??0 : formatNum(rowsDescTotal?.saleCost??0),
            color: DescriptionColorEnum.red
        },
        {
            label: "销售毛利",
            value: selectedRowKeys.length === 0 ? descTotal?.grossProfit??0 : (rowsDescTotal?.grossProfit??0),
            color: DescriptionColorEnum.red
        },
    ]
    return <>
        <SaleSearchBox />
        <YhBox title="销售列表" action={action} descriptions={desc}>
            <SaleDataTable />
        </YhBox>
        {print && <SalePrint />}
    </>
}