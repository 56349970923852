import { IKey, IMdCmFields, IPage, ISearchPageField, ITreeData } from "@/types/ScmTypes";
import { httpRequest } from "@/utils/HttpRequest";

export interface IMdDictFields extends IMdCmFields {
    code?: string;
    name?: string;
    pcode?: string;
    value?: string,
    note?: string;
    children?: IMdDictDetail[];
}

export type IMdDictDetail = IKey & IMdCmFields & IMdDictFields;
export type IMdDictSearch = IMdDictFields & ISearchPageField;

interface ITreeDs<T> {
    enable?: number;
    code?: string;
    children?: T[]
}

export function trasDsToTreeData<T extends ITreeDs<T>>(
    ds: T[],
    keyField: keyof T,
    titleField: keyof T,
): ITreeData<T>[] {
    return ds.map(el => {
        const key = el[keyField] as any;
        const title = el[titleField] as any;
        const children = el.children && el.children.length > 0 ? trasDsToTreeData(el.children, keyField, titleField) : undefined;
        const disabled = el.enable === 0;
        return {
            el,
            key,
            title,
            disabled,
            value: el["code"],
            selectable: !disabled,
            children,
        }
    });
}

class DictService {
    async find(searchVo: IMdDictFields) {
        const apiUrl = "/fms/dict/find";
        return await httpRequest.post<IMdDictDetail[]>(apiUrl, searchVo)
    }

    async dictItemDs(pcode: string, ) {
        let { retData } = await this.find({ pcode, sourceApp: "vrm" })
        return retData.map(el => ({
            label: el.name ?? "",
            value: el.code ?? "",
            type: el.value ?? ""
            
        }))
    }
    async allTree(searchVo: IMdDictFields) {
        const apiUrl = "/fms/dict/allTree";
        searchVo.branchList = ["main", "fms"];
        return await httpRequest.post<IMdDictDetail[]>(apiUrl, { ...searchVo, sourceApp: "fms" })
    }

    async accountTreeOne(code: string) {
        let { retData } = await this.allTree({ code })
        // return retData.map(el => ({
        //     label: el.name ?? "",
        //     value: el.code ?? "",
        //     codes: el.value ?? "",
        // }))
        let { children } = retData[0]
        let treeData = trasDsToTreeData(children as IMdDictFields[], "code", "name");
        return treeData;
    }
}
export const fmsDictService = new DictService();
