import React from "react";
import { Button, Dropdown, Input, Menu } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/components/YhBox";
import { useAppointmentModel } from "../appointmentModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { useAppointmentDetailsModel } from "./details/appointmentDetailsModels";
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { DownOutlined } from "@ant-design/icons";
import useGlobalModel from "@/model/globalModel";
import { formatNum } from "@/utils/utils";
import { YhAuth } from "@/components/auth/YhAuth";
import { ResourceAuthEnum } from "@/types/VrmResoureAuthEnum"
export const DataBox = () => {
    const {
        selectedRowKeys,
        selectedRow,
        descTotal,
        rowsDescTotal,
        invalidateData,
        setPrint,
        exportAppointmentRo,
        setScaniaPrint,
        setSettleScaniaPrint,
        detailsExport,
        setSelectedRowKeys
    } = useAppointmentModel();
    const { shopAndOrg: { shopCode } } = useGlobalModel()
    const { resetEditCatch } = useAppointmentDetailsModel()
    const { selectedNavId, addNav, findNav, selectNav, switchNavState, replaceNav } = useNavModel();

    const desc: IDescription[] = [
        {
            label: "预约单数",
            value: selectedRowKeys.length === 0 ? descTotal.num : (rowsDescTotal.num),
            color: DescriptionColorEnum.red
        },
        {
            label: "费用合计",
            value: selectedRowKeys.length === 0 ? descTotal.totalAmount : formatNum(rowsDescTotal.totalAmount),
            color: DescriptionColorEnum.red
        },
        {
            label: "应收金额",
            value: selectedRowKeys.length === 0 ? descTotal.receivableTotalAmount : formatNum(rowsDescTotal.receivableTotalAmount),
            color: DescriptionColorEnum.red
        },
    ]
    const onEdit = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status == "appointment.change") {
            globalPrompt("message", { text: "该单据已作废,无法进行编辑", type: "warning" });
            return;
        }
        // if (selectedRow.status == "appointment.transfer") {
        //     globalPrompt("message", { text: "该单据已转接,无法进行编辑", type: "warning" });
        //     return;
        // }
        let appointmentEditKey = "3-2";
        let isFindNav = findNav(appointmentEditKey);
        if (!isFindNav) {
            addNav(appointmentEditKey, { id: selectedRow?.appointmentCode as string, mode: "edit" });
        } else {
            globalPrompt("modal", {
                title: "预约管理跳转详情",
                type: "warning",
                text: "您还有预约管理详情未保存，是否跳转",
                okText: "跳转未保存页",
                onOk: () => selectNav(isFindNav ? isFindNav.id : ""),
                cancelText: "打开新页签",
                onCancel: () => {
                    switchNavState(appointmentEditKey, { id: selectedRow?.appointmentCode as string, mode: "edit" });
                    resetEditCatch()
                    // updateEditFormVal(null);
                }
            })
        }
    }
    const onLook = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let supplierEditKey = "3-3";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            { id: selectedRow.appointmentCode as string, mode: "look", },
            true
        );
    }
    const onExport = () => {
        exportAppointmentRo().then()
    }
    const onPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setPrint(true)
    }
    const cancellation = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        let cancelReason: string = ""
        globalPrompt("modal", {
            title: "预约单作废",
            type: "error",
            text: <><span>请输入作废原因</span>
                <Input onChange={(e) => { cancelReason = e.target.value }} /></>,
            okText: "确定",
            onOk: () => {
                invalidateData(cancelReason)
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onTransfer = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        if (selectedRow.status === "appointment.transfer") {
            globalPrompt("message", { text: "该工单已转接，无法进行转工单核对", type: "warning" });
            return;
        }
        let supplierEditKey = "3-2";
        replaceNav(
            selectedNavId,
            supplierEditKey,
            {
                id: selectedRow.appointmentCode as string, mode: "edit", parentInfo: {
                    Transfer: "Transfer"
                }
            },
            true
        );
    }
    const onSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setScaniaPrint(true)
    }
    const onSettleSknyPrint = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        setSettleScaniaPrint(true)
    }
    const PrintMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={onSknyPrint}>工单打印</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={onSettleSknyPrint}>结算打印</span>
                </Menu.Item>
            </Menu>
        )
    }
    const exportMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => onExport()}>列表导出</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => detailsExport()}>详情导出</span>
                </Menu.Item>
            </Menu>
        )
    }
    const action = <>
        <YhAuth resCode={ResourceAuthEnum.AP_AD}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={() => addNav('3-1', { id: "", mode: "add" })}
            >新增</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.AP_ED}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onEdit}
            >编辑</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.AP_VW}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onLook}
            >查看</Button>
        </YhAuth>
        <YhAuth resCode={ResourceAuthEnum.AP_ED_VERIFY}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onTransfer}
            >转工单核对</Button>
        </YhAuth>
        {/* <YhAuth resCode={ResourceAuthEnum.AP_CANCEL}>
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={cancellation}
            >作废</Button>
        </YhAuth> */}
        <YhAuth resCode={ResourceAuthEnum.AP_EX}>
            <Dropdown overlay={exportMenu}>
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                >导出<DownOutlined /></Button>
            </Dropdown>
        </YhAuth>

        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onExport}
        >导出</Button>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={detailsExport}
        >详情导出</Button> */}
        <YhAuth resCode={ResourceAuthEnum.AP_PRINT}>
            {shopCode === "SHOP-M-00000018" || shopCode === "SHOP-M-00000017" || shopCode === "SHOP-M-00000020" ?
                <Dropdown overlay={PrintMenu}>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                    >打印<DownOutlined /></Button>
                </Dropdown> :
                <Button
                    className="mr-5"
                    type="link"
                    shape="round"
                    onClick={onPrint}
                >打印</Button>
            }
        </YhAuth>
    </>;

    return (
        <YhBox title={"预约列表"} descriptions={desc} action={action}>
            <DataTable
                // onLook={onLook}

            />
        </YhBox>
    )
}