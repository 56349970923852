import React, { useRef } from "react";
import { message } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText } from "@/utils/utils";
import { useHistoryQueryModel } from "../historyQueryModel";
import useGlobalModel, { transOptions } from "@/model/globalModel";

//结算
export function DispatchDrawer() {
    const { technicianData } = useGlobalModel()

    const { dispatchVisible: visible, setDispatchVisible, selectedRow } = useHistoryQueryModel();
    const formRef = useRef<IYhFormRef>();

    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        if ('isBatch' in changedValues) {
        }
    }

    const onCancel = () => {
        setDispatchVisible(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            formRef.current?.inst().submit();
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = (val: any) => {

    }

    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        disabled: true,
        formValues: { ...selectedRow, technicianCodes: selectedRow?.technicianCodes ? selectedRow.technicianCodes.split(",") : [] },
        labelCol: { span: 6 },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "工单单号",
                fieldName: "maintenanceCode",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "车牌号",
                fieldName: "licensePlate",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "底盘号",
                fieldName: "vin",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "维修技师",
                fieldName: "technicianCodes",
                rules: [{ required: true, message: "请输入维修技师" }],
                allowClear: true,
                mode: "multiple",
                span: 24,
                options: transOptions(technicianData)
            },
        ]
    };
    return (
        <YHDrawer
            {...{
                title: '车间主任派工',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit,
                width: 600,
                showFooter: false,
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
