import React, { useEffect, FC, useState } from "react";
import { ITopType } from "@/types/ScmTypes";
import { useSaleDetailModel } from "./saleDetailModel"
import useGlobalModel, { getFindItem, transOptions } from "@/model/globalModel";
import useNavModel, { } from "@/model/navModel";
import { DetailType } from "@/types/ScmEnums";
import { useDictModel } from "@/views/base/dict/dictModel";
import { globalPrompt } from "@/components/message";
import { bigNumber, formatNum } from "@/utils/utils"
import { Button, message, Input } from "antd";
import { YhFormBox } from "@/components/YhFormBox";
import { IYhFormBox, IYhFormItemsBox, FormItemTypeEnum, ISelectOption } from "@/components/YhFormBox/Types";
import { debounce } from "lodash";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { useDetailModel, ActionTypeEnum } from "../../detailModel";
import { useSaleModel } from "../saleModel"
import { IDescription, DescriptionColorEnum } from "@/components/YhBox/Types";
import { Description } from "@/components/YhBox/Description";
import { useSaleModel as useSaleAllModel } from '@/views/busBusiness/saleAll/saleModel'
import { busBusinesstService } from "@/views/busBusiness/busBusinessService";

export const FormBox: FC<ITopType> =  ({ id, mode, parentInfo, IFormRef }) => {
    const {
        detailAddForm,
        detailEditForm,
        detailLookForm,
        updateAddFormVal,
        updateEditFormVal,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        getDetailEditOne,
        detailMaterialAddList, setDetailMaterialAddList,
        detailMaterialEditList, setDetailMaterialEditList,
        detailMaterialLookList,
        setWsConfirmModal,
        setSettleVisible,
        setTopButsControl,
        topButsControl,
        formComputeRule
    } = useSaleDetailModel();
    const {
        setSelectedRowKeys,
        setDescRows,
        setSelectedRow,
    } = useSaleModel();
    const { resetRowKeys}  = useSaleAllModel()
    const [more, setMore] = useState<boolean>(true);  //控制更多按钮
    const { getTreeNodeName } = useDictModel();
    const { customerParams } = useDetailModel()
    const materialList=mode === "edit" ?  detailMaterialEditList : detailMaterialAddList
    const setMaterialList=mode === "edit" ?  setDetailMaterialEditList : setDetailMaterialAddList
    const { setActionType } = useDetailModel();
    const [bentity, setBentity] = useState<any>({});  
    const { selectedNavId, replaceNav, findNav } = useNavModel();
    const { user,CustomerData, beShopDs, shopAndOrg: { shopId, shopCode, shopName } } = useGlobalModel();
    useEffect(() => {
        if (!Object.keys(detailEditForm).length && mode === DetailType.edit) {
            getDetailEditOne(id, mode)
        }
        mode === DetailType.look && getDetailEditOne(id, mode)
        busBusinesstService.salePage(searchParams).then(({retData})=>{
         const { records, statistics, ...pg } = retData;
         setBentity({
            bentityCode:records.find((item)=>item.createUser==user.username)?.bentityCode,
            bentityName:records.find((item)=>item.createUser==user.username)?.bentityName
         })})
    }, [id, mode])

    useEffect(() => {
        return () => {
            !findNav("16-3-1") && resetAddCatch(mode);
            !findNav("16-3-2") && resetEditCatch(mode);
        }
    }, [selectedNavId, shopCode])
    const { getSelectedDictOne, getTreeSelectOne } = useDictModel();
    const onDiscount = () => {
        let discountVal: string = "";

        globalPrompt("modal", {
            width: 380,
            title: "折扣",
            type: "info",
            // text: "",
            text: <>
                <span>请输入折扣系数</span>
                <Input
                    onChange={(e) => { discountVal = e.target.value }}
                    suffix="%"
                />
            </>,
            okText: "确定",
            onOk: () => {
                var re = /^[0-9]+.?[0-9]*$/;
                return new Promise((resolve, reject) => {
                    if (!re.test(discountVal)) {
                        reject("输入的值不是数字，请重新输入")
                        message.error("输入的值不是数字，请重新输入")

                    } else {
                        resolve(discountVal)
                    }
                }).then(res => {
                    onDiscountMaterial(+discountVal);

                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    const onDiscountMaterial = (discount: number) => {
        let detailsMaterialList = mode === "add" ? detailMaterialAddList : detailMaterialEditList;
        let newDetailsMaterialList = detailsMaterialList.map((item) => {
            return {
                ...item,
                discountRate: discount,
                // amount: +formatNum(Number(item.partsCost) * discount / 100),
                amount:+bigNumber.toFixed(bigNumber.times(item.partsCost as number,bigNumber.dividedBy(discount,100)),2)
            }
        })
        mode === "add" ? setDetailMaterialAddList(newDetailsMaterialList) : setDetailMaterialEditList(newDetailsMaterialList)
    }
    //当列表跳转来的时候 对应客户信息为空 反之带预售销售详情的数据
    let newCustomerParams = parentInfo?.source ? {} : customerParams
    const searchParams = {
        shopCode,
        shopId,
        pageSize: 15,
        pageNum: 1,
    }

    const typeForm = {

        add: {  ...newCustomerParams,
            settlementPartyCode:"settle-party.self-pay",
            settlementPartyName:"客户自费",
            payType:"settle-type.cash",
            bentityCode:bentity.bentityCode??'',
            bentityName:bentity.bentityName??'',
            ...detailAddForm, shopId, shopCode, shopName, },
        edit: detailEditForm,
        look: detailLookForm
    }
    const verifyDetail = async () => {
        let list = detailMaterialAddList;
        if (mode === "edit") list = detailMaterialEditList

        const result = list.some(item => (item?.price ?? 0) < item.costPrice)

        if (result) {
            globalPrompt("modal", {
                title: "销售单保存提醒",
                type: 'warning',
                text: "当前销售单价低于采购价，是否确认",
                okText: "确认",
                cancelText: "取消",
                onOk: save,
                onCancel: () => { }
            })
        } else {
            save()
        }
    }
    const save = () => {
        console.log(detailAddForm)
        //if (!formComputeRule(mode)) return;

        IFormRef.current?.validateFields().then(async res => {
            if (mode === "add") {
                const retData = await insertDetail(res)
                if (retData) {
                    globalPrompt("message", { text: "新增成功", type: "success" });
                    setSelectedRowKeys([retData.id as string])
                    setDescRows([retData])
                    setSelectedRow(retData)
                    resetAddCatch(mode)
                    goBack()
                }
            } else if (mode === "edit") {
                const { id, saleCode } = detailEditForm
                const retData = await editDetail({ ...res, id, saleCode, favorableAmount: 0 })
                if (retData) {
                    globalPrompt("message", { text: "编辑成功", type: "success" })
                }
            }
        })

    }
    const goBack = () => {
        resetRowKeys()
        if (parentInfo && parentInfo.selectedNavId) {
            replaceNav(selectedNavId, parentInfo.selectedNavId);
        } else {
            replaceNav(selectedNavId, "16-1-2");
            setActionType(ActionTypeEnum.SALE)
        }
    }
    const onRefresh = () => {
        if (mode === "add") {
            IFormRef.current?.resetFields()
            resetAddCatch()
        } else if (mode === "edit") {
            IFormRef.current?.resetFields()
            getDetailEditOne(id, mode)
        }
        message.success("刷新成功")
    }
    const onWhConfirm = async () => {
        let materialNames: string[] = []
        let typeList = {
            add: [],
            edit: detailMaterialEditList,
            look: detailMaterialLookList
        }
        await typeList[mode].forEach(item => {
            if (!!!item.warehouseCode) {
                materialNames.push(item.materialName as string)
            }
        })
        if (materialNames.length === 0 && typeList[mode].length != 0) {
            await setWsConfirmModal(true)
        } else {
            await globalPrompt("message", { type: "error", text: `请选择${materialNames.toString()}的仓库` })
        }
    }
    const onSettle = () => {
        setSettleVisible(true)
    }
    const rightChildren = <>
        {mode === "edit" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl || detailEditForm.status === "sale.stock-io"}
                onClick={onWhConfirm}
            >库房出库</Button>
        }
        {mode === "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onWhConfirm}
            >出库查看</Button>
        }
        {mode === "edit" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                disabled={topButsControl || detailEditForm.status === "sale.settle" || detailEditForm.status === "sale.stock-io"}
                onClick={onSettle}
            >确认结算</Button>
        }
        {mode === "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onSettle}
            >结算查看</Button>
        }
        {/* {mode !== "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={onDiscount}
            >折扣</Button>
        } */}
        {mode !== "look" &&
            <Button
                className="mr-5"
                type="link"
                shape="round"
                onClick={debounce(() => { verifyDetail() }, 900)}
            >保存</Button>
        }
        {mode !== "look" && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onRefresh}
        >刷新</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={goBack}
        >返回</Button>
    </>
    const goCustomer = () => {
        replaceNav(
            selectedNavId,
            "13-5-1-1",
            {
                id: "", mode: "add", parentInfo: {
                    mode,
                    selectedNavId,
                    id
                }
            },
            true)
    }
    function clearGoodsList() {
        if (materialList.length > 0) globalPrompt('message', { text: '切换成功!需要重新选择商品信息', type: 'warning' })
        setMaterialList([])
}
    //客户名称
    const customerChange = (value: string, option: ISelectOption) => {
        if (value && option) {
            let { name, code, contact, phone, address, categoryCode, categoryName } = getFindItem(value, CustomerData)
            option && onChange({
                customerName: option.label,
                customerCode: option.value,
                contact,
                phone,
                address: address,
                customerTypeCode: categoryCode,
                customerTypeName: categoryName,
            });
            clearGoodsList()
        }
    }
    //客户类型
    const customerTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({
            customerTypeName:  option.label,
            customerTypeCode:  option.value,            
        });
    }
    //商品类型
    const productTypChange = (value: string, option: ISelectOption) => {
        option && onChange({
            productTypeName: option.label,
            productTypeCode: option.value,
        });
    }
    //发票类型
    const invoiceTypeChange = (value: string, option: ISelectOption) => {
        option && onChange({ invoiceTypeName: option.label, invoiceTypeCode: option.value });
    }
    //销售实体
    const bentityChange = (value: string, option: ISelectOption) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value });
    }
    //服务性质
    const serviceNatureChange = (value: string, option: ISelectOption) => {
        option && onChange({ serviceTypeName: option.label, serviceTypeCode: option.value });
    }
    //结算方
    const settlementPartyChange = (value: string, option: ISelectOption) => {
        option && onChange({ settlementPartyName: option.label, settlementPartyCode: option.value });
    }
    const orderStatus: IDescription[] = [
        {
            label: "销售单号",
            value: typeForm[mode].saleCode ?? "暂无",
            color: DescriptionColorEnum.blue,
            status: true
        },
        {
            label: "状态",
            value: (typeForm[mode]?.status && getTreeNodeName(typeForm[mode]?.status as string)) ?? "暂无",
            color: DescriptionColorEnum.green,
            status: true
        }
    ]
    const baseFormItem: IYhFormItemsBox = {
        title: <>基本信息&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mode !== "add" && orderStatus.map((item, index) => <Description key={index} {...item} />)}</>,
        style: { padding: "18px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: <span className="click-span" onClick={goCustomer}>客户名称</span>,
                fieldName: "customerCode",
                options: transOptions(CustomerData.filter((item:any)=>item.categoryCode === "customer-type.transit")),
                onSelectChange: customerChange,
                allowClear: true,
                showSearch: true,
                // disable: true,
                // disable: !!!parentInfo?.source,
                span: 12,
                labelCol: { span: 3},
                rules: [{ required: true, message: "请选择客户" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "客户类型",
                fieldName: "customerTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
                onSelectChange: customerTypeChange,
                rules: [{ required: true, message: "请输入客户类型" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "请输入联系人" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, 
            // {
            //     type: FormItemTypeEnum.Input,
            //     fieldLabel: "去零",
            //     fieldName: "favorableAmount",
            // },
             {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算方",
                fieldName: "settlementPartyCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
                onSelectChange: settlementPartyChange,
                rules: [{ required: true, message: "请选择结算方" }]
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                rules: [{ required: true, message: "请选择结算类型" }],
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "商品类型",
                fieldName: "productTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE),
                onSelectChange: productTypChange,
            }, {
                type: FormItemTypeEnum.Select,
                allowClear: true,
                showSearch: true,
                fieldLabel: "发票类型",
                fieldName: "invoiceTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
                onSelectChange: invoiceTypeChange,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "服务类型",
                fieldName: "serviceTypeCode",
                allowClear: true,
                showSearch: true,
                options: getSelectedDictOne(DictCodeEnmu.REPAIR_TYPE),
                onSelectChange: serviceNatureChange,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "销售实体",
                fieldName: "bentityCode",
                options: beShopDs,
                allowClear: true,
                showSearch: true,
                onSelectChange: bentityChange,
                rules: [{ required: true, message: "请输入销售实体" }],
                span: 5,
                labelCol: { offset: 1 },
            }, {
                type: FormItemTypeEnum.Button,
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "none",
                span: 1,
                labelCol: { offset: 5 },
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户地址",
                fieldName: "address",
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单员",
                fieldName: "createUser",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单时间",
                fieldName: "createTime",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "库管员",
                fieldName: "storekeeper",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "出库时间",
                fieldName: "stockIoTime",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算员",
                fieldName: "reckoner",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算时间",
                fieldName: "settleTime",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "核销时间",
                //fieldName: "createTime",
                fieldName: "writeOffTime",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                disable: true,
                hidden: more
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                hidden: more
            },
            //带出字段
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "销售实体名称",
                fieldName: "bentityName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户名称",
                fieldName: "customerName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "客户类型Name",
                fieldName: "customerTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "商品类型Name",
                fieldName: "productTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "发票类型Name",
                fieldName: "invoiceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "服务类型Name",
                fieldName: "serviceTypeName",
                hidden: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "结算方Name",
                fieldName: "settlementPartyName",
                hidden: true
            }
        ]
    }
    const onChange = (changedValues: { [key: string]: any }) => {
        mode === "add" ? updateAddFormVal(changedValues) : updateEditFormVal(changedValues)
        setTopButsControl(true)
    }
    const poFromConfig: IYhFormBox = {
        formRef: IFormRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        disabled: mode === "look",
        formValues: { ...typeForm[mode] },
        onChange,
        boxs: [baseFormItem]
    }
    return (
        <YhFormBox {...poFromConfig} />

    )
}