import React, { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IBillWriteoffDetail } from "../../../../../bill/BillTypes";
import { useWriteoffModel } from "./writeoffModel";


const columns: ColumnsType<IBillWriteoffDetail> = [
    {
        align: "center",
        title: '分支',
        dataIndex: 'branch',
    },
    {
        align: "center",
        title: '核销编号',
        dataIndex: 'code',
    },
    {
        align: "center",
        title: '本次核销总额',
        dataIndex: 'writeoffAmount',
    },
    {
        align: "center",
        title: '核销账户',
        dataIndex: 'accountName',
    },
    {
        align: "center",
        title: '账户实体',
        dataIndex: 'beName',
    },
    {
        align: "center",
        title: '核销人',
        dataIndex: 'createUser',
    },
    {
        align: "center",
        title: '核销时间',
        dataIndex: 'writeoffTime',
    },
    {
        align: "center",
        title: '从属机构',
        dataIndex: 'orgName',
    },
    // {
    //     title: '单据编号',
    //     dataIndex: '',
    // },
    // {
    //     title: '单据类型',
    //     dataIndex: '',
    // },
    // {
    //     title: '单据金额',
    //     dataIndex: '',
    // },
    // {
    //     title: '销售单位',
    //     dataIndex: '',
    // },
    // {
    //     title: '销售类型',
    //     dataIndex: '',
    // },
    // {
    //     title: '品牌',
    //     dataIndex: '',
    // },
    // {
    //     title: '授信类型',
    //     dataIndex: 'writeoffType',
    //     render: (v) => v === 0 ? '按账户自动核销' : v === 1 ? '单账单核销' : v === 2 ? '多账单核销' : ''
    // },
    // {
    //     title: '时间正序',
    //     dataIndex: 'timeAsc',
    // },
    // {
    //     title: '最大可用',
    //     dataIndex: 'mostAvailable',
    // },
    // {
    //     title: '状态',
    //     dataIndex: 'status',
    //     width: 80,
    //     render: (v) => v === 0 ? "正常" : "异常"
    // },
    {
        align: "center",
        title: '备注',
        dataIndex: 'note',
    },

];

export function WriteoffDataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useWriteoffModel();


    return (
        <Table
            rowSelection={{
                type: "radio",
                onChange: (selectedRowKeys: React.Key[], selectedRows: IBillWriteoffDetail[]) => {
                    setSelectedRowKeys(selectedRowKeys);
                },
                selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => {
                return {
                    onClick: event => {
                        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
                            setSelectedRowKeys([]);
                        } else {
                            setSelectedRowKeys([record.key]);
                        }
                    },
                };
            }}
            scroll={{ x: 'max-content' }}
        />
    )
}
