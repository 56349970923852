import React, { useRef, FC, useEffect, useState } from "react";
import { Button, Form, message, Modal, Upload, UploadFile } from "antd";
import { YHDrawer } from "@/components/YhDrawer";
import { FormItemTypeEnum, IYhForm, IYhFormRef, ISelectOption } from "@/components/YhFormBox/Types";
import { YhForm } from "@/components/YhFormBox";
import { getFormValidatorErrText, formatNum } from "@/utils/utils";
import { usePurchasePoDetailsModel } from "./purchasePoDetailsModel";
import { useDictModel } from "@/views/base/dict/dictModel";
import { DictCodeEnmu } from "@/types/VrmSaDictEnums";
import { RouterParamType } from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { accountService } from "@/views/account/AccountService";
import { IVRMSettleTypeEnum } from "@/types/FmsDs";
import { ISelectOptions } from "@/types/ScmTypes";
import useGlobalModel from "@/model/globalModel";
import FontIcon from "@/components/YhIcon";
import { getBaseImgLookUrl, getBaseImgUploadUrl, getBaseUploadData } from "@/utils/constants";
import { fileDownload } from "@/utils/exportFile";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";


export const SettleDrawer: FC<RouterParamType> = ({ id, mode }) => {
    const { settleVisible: visible, detailAddForm, detailEditForm, detailLookForm, setSettleVisible, onSettle, getDetailEditOne } = usePurchasePoDetailsModel();
    const { getSelectedDictOne } = useDictModel();
    const [settleType, setSettleType] = useState<IVRMSettleTypeEnum>(IVRMSettleTypeEnum.SETTLE_CASH);   //结算类型，  默认现金结账
    const [accountTypeDs, setAccountTypeDs] = useState<ISelectOption[]>([]) //实时存取收款账户类型值settle-type.cash
    const [editPayMethodOptions, setEditPayMethodOptions] = useState<ISelectOptions[]>(getSelectedDictOne(DictCodeEnmu.PAY_METHOD))//编辑根据结算类型展示结算方式
    const { shopAndOrg: { shopCode, shopId }, user } = useGlobalModel()
    const [cash, setCash] = useState(false);

    const formRef = useRef<IYhFormRef>();
    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm
    }
    //监听批量录入字段
    const onChange = (changedValues: { [key: string]: any }) => {
        if ('isBatch' in changedValues) {
        }
    }
    useEffect(() => {
        if (visible) {
            queryAccount(typeForm[mode].supplierCode as string, typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, typeForm[mode].payType as string);
        }


    }, [visible])


    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode: string, creditFlag: number, payType: string) => {
        // console.log(partnerCode, creditFlag)
        let params = {
            partnerCode,  //客户/供应商编码
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag,  //为记账类型的  1挂账 2现结
            sourceApp: "vrm",
            shopCode
        }
        const { retData } = await accountService.accountFind(params);
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter(item => {
            // if (creditFlag) {
            //     return typeForm[mode].payType !== IVRMSettleTypeEnum.SETTLE_CASH ?
            //         (item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE || item.accountTypeCode === DictCodeEnmu.ACC_TYPE_REBATE_OIL) :
            //         (item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE && item.accountTypeCode !== DictCodeEnmu.ACC_TYPE_REBATE_OIL)
            // }
            return item;
        })
        // debugger;
        //现金账户 或者 挂账账户。
        let newAccTypeDs: any = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        if (payType === IVRMSettleTypeEnum.SETTLE_CASH) {
            newAccTypeDs = filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0, accounttypecode: item.accountTypeCode as string, key: item.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_CREDIT) {//挂账  account-type.credit
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        } else if (payType === IVRMSettleTypeEnum.SETTLE_MONTHLY) {//月结  account-type.credit-month
            newAccTypeDs = filterResult.filter(item => {
                if (item.accountTypeCode == "account-type.credit-month") return { label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }
            }).map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accounttypecode: item1.accountTypeCode as string, key: item1.key ?? "" }));
        }
        setAccountTypeDs(newAccTypeDs);
        let accTypeDs: any = newAccTypeDs[0]
        if (newAccTypeDs.length > 0) {
            setCash(accTypeDs.accounttypecode === "account-type.cash");
            formRef.current?.setFieldsValue({ payMethodName: accTypeDs.label, payMethodCode: accTypeDs.value, payMethodValue: accTypeDs.accounttypecode, available: formatNum(accTypeDs?.available) });
        } else {
            setCash(false);
            message.warning(`该${typeForm[mode].supplierName}供应商没有对应账户`);
            formRef.current?.setFieldsValue({ payMethodName: undefined, payMethodCode: undefined, payMethodValue: undefined, available: undefined });
        }

    }
    const setPayMethodOptions = (payType: string) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions as ISelectOptions[])
    }
    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value: string, option: ISelectOption) => {
        if (option) {
            setSettleType(option.value as IVRMSettleTypeEnum);
            // option.value === ISCMSettleTypeEnum.SETTLE_CREDIT && queryAccount(editFormVal.customerCode);
            queryAccount(typeForm[mode].supplierCode as string, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value);
            setPayMethodOptions(value)

        }
    }
    const onCancel = () => {
        setSettleVisible(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current?.validateFields().then(res => {
            formRef.current?.inst().submit();
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const onFinish = async (val: any) => {
        // debugger
        // return console.log(val)
        // let { payType, payMethodName } = val
        // let retData = await onSettle({ accountCode: accountTypeDs[0]?.value, payType, payMethodName })
        if (settleType === IVRMSettleTypeEnum.SETTLE_CASH && accountTypeDs.length === 0) {
            message.warning("您的现金账户没有开户");
            return;
        }
        let { payType, payMethodName, payableAmount, purchaseCode, note, payMethodCode, payMethodValue, cashCode, cashName, cashFileList } = val
        let cashAttach = '',
            cashAttachFileName = ''
            if (cashFileList && cashFileList.length > 0) {
                const file = cashFileList[0]
                if (!file?.uid) {
                    console.log(file)
                    return message.error('文件信息有误，请重新上传文件')
                }
                cashAttach = file?.uid
                cashAttachFileName = file?.name
            }
        let retData = await onSettle({
            id: typeForm[mode].id as string,
            purchaseCode,
            note,
            reckoner: user.username,
            payType,
            accountCode: payMethodCode,
            accountType: payMethodValue,
            payableAmount,
            payMethodName,
            payMethodCode,
            details: payType === IVRMSettleTypeEnum.SETTLE_CASH && payMethodValue === "account-type.cash" ? [
                {
                    accountName: payMethodName,
                    accountCode: payMethodCode,
                    shopId,
                    tradeCode: typeForm[mode].id,
                    payMethodCode: cashCode,
                    payMethodName: cashName,
                    // accountType:cashCode,
                    accountType: payMethodValue,
                    amount: payableAmount,
                    payee: "",
                    tradeTypeName: "",
                    tradeTypeCode: "",
                    cashAttach,
                    cashAttachFileName
                }
            ] : []
        })
        if (retData) {
            await onCancel();
            await getDetailEditOne(id, mode);
            await globalPrompt("message", { text: "结算成功", type: "success" });

        } else {
            await onCancel()
            await globalPrompt("message", { text: "结算失败", type: "error" });
        }
    }
    const payMethodChange = (value: string, option: ISelectOption) => {
        if (option.accounttypecode === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true);
        } else {
            setCash(false);
        }
        option && formRef.current?.setFieldsValue({
            payMethodName: option.label,
            payMethodValue: option.accounttypecode,
            available: formatNum(option?.available),
            cashName: undefined,
            cashCode: undefined
        });
    }
    const cashSelectChange = (value: string, option: ISelectOption) => {
        option && formRef.current?.setFieldsValue({ cashName: option.label, cashCode: option.value });
    }

    const onPreview = (file: UploadFile<any>) => {
        const { uid, name } = file
        const type = uid.split('.')[1]
        if (['jpg', 'png', 'jpeg', 'gif'].includes(type)) {
            Modal.info({
                title: '图片查看',
                content: <img style={{ width: '100%' }} src={getBaseImgLookUrl(uid)} />,
                okText: '确定',
                cancelText: '取消',
                centered: true,
                width: 800,
            })
        } else {
            // window.open(getBaseImgLookUrl(uid))
            fileDownload(getBaseImgLookUrl(uid), name)
        }
    }

    const normFile = (e: UploadChangeParam) => {
        // let filetypes: string[] = ['.jpg', '.png', '.jpeg', 'svg', '.gif', '.pdf', '.doc']
        // let isType: boolean = false
        // let fileEnd: string = e?.file.name.substring(e?.file.name.lastIndexOf('.'))
        // isType = filetypes?.includes(fileEnd) ?? false
        if (e?.file.status === 'done') {
            if (e?.file?.response?.retCode == 0) {
                const data = {
                    name: e.file.name,
                    size: e.file.size,
                    type: e.file.type,
                    uid: e?.file?.response?.retData,
                }
                e.fileList = [data]
            } else {
                message.error('文件上传失败，请重新上传!')
                e.fileList?.pop()
            }
        }
        if (e.file.status === 'error') {
            message.error('文件上传失败，请重新上传!')
            e.fileList?.pop()
        }
        if (Array.isArray(e)) {
            return e
        }
        // if (!isType) {
        //     e.fileList?.pop()
        // }
        return e && e.fileList
    }
    const formConfig: IYhForm<any> = {
        formRef,
        onFinish,
        formValues: typeForm[mode],
        labelCol: { span: 6 },
        onChange,
        disabled: mode === "look",
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购单号",
                fieldName: "purchaseCode",
                span: 24,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "制单日期",
                fieldName: "createTime",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商名称",
                fieldName: "supplierName",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "供应商编码",
                fieldName: "supplierCode",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "采购实体",
                fieldName: "bentityName",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "所属门店",
                fieldName: "shopName",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "应付金额",
                fieldName: "payableAmount",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "已付金额",
                fieldName: "paidAmount",
                span: 24,
                disable: true,
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "结算类型",
                fieldName: "payType",
                onSelectChange: settleTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                span: 24,
                // }, {
                //     type: FormItemTypeEnum.Select,
                //     fieldLabel: "支付方式",
                //     fieldName: "payMethodName",
                //     options: editPayMethodOptions,//getSelectedDictOne(DictCodeEnmu.PAY_METHOD),
                //     span: 24,
                // },
            }, {
                type: FormItemTypeEnum.Select,
                fieldLabel: "支付方式",
                fieldName: "payMethodCode",
                onSelectChange: payMethodChange,
                options: accountTypeDs,
                span: 24,
                hidden: mode === "look"
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "支付方式",
                fieldName: "payMethodName",
                span: 24,
                hidden: !(mode === "look")
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "现金类支付",
                fieldName: "cashName",
                hidden: !(mode === "look"),
                span: 24,
            },
            ...((str) => {
                if (str) {
                    return [
                        {
                            type: FormItemTypeEnum.Select,
                            fieldLabel: "现金类支付",
                            fieldName: "cashCode",
                            span: 24,
                            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASH),
                            onSelectChange: cashSelectChange,
                            rules: [{ required: true, message: "请完善现金类支付" }],
                            // hidden: mode === "look"
                        },
                        {
                            type: FormItemTypeEnum.Other,
                            fieldLabel: '附件',
                            fieldName: 'cashAttach',
                            colClassName: 'ant-col-h-auto',
                            span: 24,
                            formItem: (
                                <Form.Item name="cashFileList" label="附件" valuePropName="fileList" getValueFromEvent={normFile} labelCol={{ span: 6 }}>
                                    <Upload
                                        disabled={mode === 'look'}
                                        className="upload-box"
                                        onPreview={onPreview}
                                        name="file"
                                        action={getBaseImgUploadUrl()}
                                        data={getBaseUploadData()}
                                        listType="picture"
                                        beforeUpload={(file:any) => {
                                            const isLt20M = file.size / 1024 / 1024 < 20
                                            if (!isLt20M) {
                                                message.error('上传到文件不能超过20M')
                                    file.status='done'
                                            }
                                            return isLt20M
                                        }}
                                        iconRender={(file) => {
                                            return (
                                                <FontIcon
                                                    onClick={() => {
                                                        onPreview(file)
                                                    }}
                                                    style={{ fontSize: '24px' }}
                                                    type="icon-tupian"
                                                />
                                            )
                                        }}
                                    >
                                        {mode !== 'look' && (
                                            <>
                                                <Button icon={<UploadOutlined />}>选择文件上传</Button>
                                                <span style={{ lineHeight: '25px', color: 'green', fontSize: '13px', marginLeft: '10px' }}>
                                                    提醒：上传单个文件不能超过20M,当前仅.jpg", ".png", ".jpeg", "svg", ".gif", ".pdf", ".doc等任何类型文件
                                                </span>
                                            </>
                                        )}
                                    </Upload>
                                </Form.Item>
                            ),
                        },
                    ]
                } else {
                    return [
                        {
                            type: FormItemTypeEnum.Input,
                            fieldLabel: "可用额度",
                            fieldName: "available",
                            span: 24,
                            hidden: mode === "look",
                            disable: true,
                        },
                    ]
                }
            })(cash),
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
                disable: true,
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "支付方式对应得账户类型",
                fieldName: "payMethodValue",
                hidden: true
            },

        ]
    };
    return (
        <YHDrawer
            {...{
                title: '采购付款',
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit,
                showFooter: detailEditForm.status === "purchase-status.bill" || mode == "look" ? false : true
                // width:600
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}
