import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/ScmTypes";
import { IStockBorrowDetail, IStockBorrowSearch, IStockBorrowField } from "./stockOutAndInListType"
class StockOutAndInListService {
    async page(searchVo: IStockBorrowSearch) {
        const apiUrl = "/bcm/borrow/findPage";
        return await httpRequest.post<IPage<IStockBorrowDetail>>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/borrow/findById/${id}`;
        return await httpRequest.get<IStockBorrowDetail>(apiUrl);
    }
    async insert(insertVo: IStockBorrowField) {
        const apiUrl = "/bcm/borrow/insert";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    async update(updateVo: IStockBorrowField) {
        const apiUrl = "/bcm/borrow/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //退料入库
    async storage(updateVo: IStockBorrowField) {
        const apiUrl = "/bcm/borrow/storage";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
}

export const stockOutAndInListService = new StockOutAndInListService();