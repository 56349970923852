import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useNavModel from "../model/navModel";
import menuData from "../data/menuData.json";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Tag } from "antd";
import { IMenuNode } from "./VrmMenu";

const NavBox = SortableContainer(
    (props: any) => (
        <div
            className="layout-section-nav"
            {...props}>
            {props.children}
        </div>
    )
);
const Nav = SortableElement(
    (props: any) => <>{props.children}</>
);

function getNavSelectedMenu(menuData: IMenuNode[], menuId: String) {
    function filterMenus(menus: IMenuNode[]): IMenuNode | undefined {
        for (const menu of menus) {
            if (menu.id === menuId) return menu;
            if (menu.children && menu.children.length > 0) {
                const ret = filterMenus(menu.children)
                if (ret) return ret;
            }

        }
    }

    return filterMenus(menuData)
}

function VrmNav() {
    const history = useHistory();
    const location = useLocation();

    const {
        navTabs,
        selectedNavId,
        getSelectedNavState,
        selectNav,
        closeNav,
        sortNav
    } = useNavModel();

    useEffect(() => {
        const page = getNavSelectedMenu(menuData, selectedNavId);
        if (page) {
            //路由是否变化
            if (location.pathname !== page.path && location.pathname !== "/login")
                // console.log(Nav.getSelectNode('state'), history, "nav切换")
                history.push({ pathname: page.path, state: getSelectedNavState() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNavId]);

    return (
        <NavBox
            distance={20}
            transitionDuration={200}
            axis="x"
            onSortEnd={sortNav}>
            {
                navTabs.map((item, index) => (
                    <Nav
                        key={item.id}
                        index={index}
                    >
                        <Tag
                            key={item.id}
                            closable={item.closable}
                            className={["non-copyable", item.id === selectedNavId ? "tag-checked" : ""].join(' ')}
                            onClick={() => selectNav(item.id)}
                            onClose={() => closeNav(item.id)}
                        >
                            {item.name}
                        </Tag>
                    </Nav>
                ))
            }
        </NavBox>
    )
}

export default VrmNav;