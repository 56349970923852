import { createModel } from 'hox';
import { useState, useEffect, Key } from 'react';
import useGlobalModel from '@/model/globalModel';
import {
	ISaleDetail,
	ISaleDetailsList,
	IStockDetail,
	IStockSearch,
	ISaleSettleParams,
	ISettleDetail,
} from '../../../../busBusinessType';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { DEFAULT_PAGE_SIZE, DEFAULT_FIRST_PAGE_NUM } from '@/utils/constants';
import { globalPrompt } from '@/components/message';
import { transPgToPagination, formatNum,bigNumber } from '@/utils/utils';
import { ISearchPage } from '@/types/ScmTypes';
import { useLockFn } from 'ahooks';
import { sourceAppOrBranch } from '@/types/VrmSaDictEnums';
import { busBusinesstService } from '@/views/busBusiness/busBusinessService';
import moment from 'moment';
import { convertRes2Blob } from '@/utils/exportFile';
import { useDetailModel } from '../../detailModel';
import { message } from 'antd';

//这里为了继承一个弹框列表的自定义属性值
export interface ISaleDetailModel extends IStockDetail {
	num: number;
	price?: number;
	amount?: number;
}
export type ISaleDesc = {
	numAll: number;
	itemNumAll: number;
	totalAll: number;
	discountAll: number;
	receivableAll: number;
	[key: string]: any;
};
export const useSaleDetailModel = createModel(function () {
	const { busBusiness, customerParams } = useDetailModel();
	const { user, shopAndOrg, warehouseList } = useGlobalModel();
	const { shopId } = shopAndOrg;
	const [materialModel, setMaterialModel] = useState<boolean>(false); //维修配件弹框
	const initDesc: ISaleDesc = {
		numAll: 0, //数量
		itemNumAll: 0, //项数
		totalAll: 0, //总计
		discountAll: 0, //折扣,
		receivableAll: 0, //应收
	};
	const initForm = {};
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const initMaterialSearchParam = {};

	//新增数据
	//新增详情form
	const [detailAddForm, setDetailAddForm] = useState<ISaleDetail>({ ...customerParams });
	//新增维修用料详情列表
	const [detailMaterialAddList, setDetailMaterialAddList] = useState<ISaleDetailsList[]>([]);
	//新增维修用料详情列表选中行
	const [detailMaterialAddDataRowKeys, setDetailMaterialAddDataRowKeys] = useState<Key[]>([]);
	const [detailMaterialAddDataRow, setDetailMaterialAddDataRow] = useState<ISaleDetailsList[]>([]);
	const [addDesc, setAddDesc] = useState<ISaleDesc>(initDesc);

	//编辑数据
	//编辑详情form
	const [detailEditForm, setDetailEditForm] = useState<ISaleDetail>({});
	//编辑维修用料详情列表
	const [detailMaterialEditList, setDetailMaterialEditList] = useState<ISaleDetailsList[]>([]);
	//编辑维修用料详情列表选中行
	const [detailMaterialEditDataRowKeys, setDetailMaterialEditDataRowKeys] = useState<Key[]>([]);
	const [detailMaterialEditDataRow, setDetailMaterialEditDataRow] = useState<ISaleDetailsList[]>(
		[]
	);
	const [editDesc, setEditDesc] = useState<ISaleDesc>(initDesc);
	//编辑结算详情
	const [editSettleDetails, setEditSettleDetails] = useState<ISettleDetail[]>([]);
	//查看数据
	//查看详情form
	const [detailLookForm, setDetailLookForm] = useState<ISaleDetail>({});
	//查看维修用料详情列表
	const [detailMaterialLookList, setDetailMaterialLookList] = useState<ISaleDetailsList[]>([]);
	const [lookDesc, setLookDesc] = useState<ISaleDesc>(initDesc);
	//查看结算详情
	const [lookSettleDetails, setLookSettleDetails] = useState<ISettleDetail[]>([]);

	//配件列表配置
	const [workMaterialDataSource, setWorkMaterialDataSource] = useState<ISaleDetailModel[]>([]);
	const [searchWorkMaterialData, setSearchWorkMaterialData] =
		useState<IStockSearch>(initMaterialSearchParam);
	const [workMaterialPgCfg, setWorkMaterialPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	//已选配件列表
	const [addDataMaterialSource, setAddDataMaterialSource] = useState<ISaleDetailModel[]>([]);
	//表单改变或表格数据发生改变时，控制功能按钮的启用禁用
	const [topButsControl, setTopButsControl] = useState<boolean>(false);
	const [checked, setChecked] = useState(true);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		let newDesc: ISaleDesc = initDesc;
		detailMaterialAddList &&
			detailMaterialAddList.forEach((item, index) => {
				newDesc.numAll = bigNumber.add(newDesc.numAll,Number(item.num))
				newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.partsCost))
				newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus(Number(item.partsCost),Number(item.amount)))
				newDesc.receivableAll  = bigNumber.add(newDesc.receivableAll,Number(item.amount))
				// newDesc.numAll += Number(item.num);
				// newDesc.totalAll += Number(item.partsCost);
				// newDesc.discountAll += Number(item.partsCost) - Number(item.amount);
				// newDesc.receivableAll += Number(item.amount);
			});
		if ('favorableAmount' in detailAddForm) {
			newDesc.receivableAll = bigNumber.minus(newDesc.receivableAll,Number(detailAddForm.favorableAmount))
			newDesc.discountAll =  bigNumber.add(newDesc.discountAll, Number(detailAddForm.favorableAmount))
			
			// newDesc.receivableAll = newDesc.receivableAll - Number(detailAddForm.favorableAmount);
			// newDesc.discountAll = newDesc.discountAll + Number(detailAddForm.favorableAmount);
		}
		newDesc.itemNumAll = detailMaterialAddList && detailMaterialAddList.length;
		setAddDesc(newDesc);
	}, [detailAddForm, detailMaterialAddList]);
	useEffect(() => {
		let newDesc: ISaleDesc = initDesc;
		detailMaterialEditList &&
			detailMaterialEditList.forEach((item, index) => {
				newDesc.numAll = bigNumber.add( newDesc.numAll,Number(item.num))
				newDesc.totalAll  = bigNumber.add(newDesc.totalAll,Number(item.partsCost))
				newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus( Number(item.partsCost),Number(item.amount)))
				newDesc.receivableAll = bigNumber.add(newDesc.receivableAll,Number(item.amount))
				// newDesc.numAll += Number(item.num);
				// newDesc.totalAll += Number(item.partsCost);
				// newDesc.discountAll += Number(item.partsCost) - Number(item.amount);
				// newDesc.receivableAll += Number(item.amount);
			});
		if ('favorableAmount' in detailEditForm) {
			newDesc.receivableAll = bigNumber.minus(newDesc.receivableAll, Number(detailEditForm.favorableAmount))
			newDesc.discountAll  = bigNumber.add(newDesc.discountAll ,Number(detailEditForm.favorableAmount))
			// newDesc.receivableAll = newDesc.receivableAll - Number(detailEditForm.favorableAmount);
			// newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount);
		}
		newDesc.itemNumAll = detailMaterialEditList && detailMaterialEditList.length;
		setEditDesc(newDesc);
	}, [detailEditForm, detailMaterialEditList]);
	useEffect(() => {
		let newDesc: ISaleDesc = initDesc;
		detailMaterialLookList &&
			detailMaterialLookList.forEach((item, index) => {
				newDesc.numAll  = bigNumber.add(newDesc.numAll,Number(item.num))
				newDesc.totalAll = bigNumber.add(newDesc.totalAll,Number(item.partsCost))
				newDesc.discountAll = bigNumber.add(newDesc.discountAll,bigNumber.minus(Number(item.partsCost), Number(item.amount)))
				newDesc.receivableAll = bigNumber.add(newDesc.receivableAll,Number(item.amount))
				// newDesc.numAll += Number(item.num);
				// newDesc.totalAll += Number(item.partsCost);
				// newDesc.discountAll += Number(item.partsCost) - Number(item.amount);
				// newDesc.receivableAll += Number(item.amount);
			});
		if ('favorableAmount' in detailLookForm) {
			newDesc.receivableAll = bigNumber.minus(newDesc.receivableAll,Number(detailLookForm.favorableAmount))
			newDesc.discountAll = bigNumber.add(newDesc.discountAll,Number(detailLookForm.favorableAmount))
			// newDesc.receivableAll = newDesc.receivableAll - Number(detailLookForm.favorableAmount);
			// newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount);
		}
		newDesc.itemNumAll = detailMaterialLookList && detailMaterialLookList.length;
		setLookDesc(newDesc);
	}, [detailLookForm, detailMaterialLookList]);

	//新增表单缓存
	const updateAddFormVal = (val: ISaleDetail) => {
		let newVal = val;
		setDetailAddForm({ ...detailAddForm, ...newVal });
	};
	//编辑表单缓存
	const updateEditFormVal = (val: ISaleDetail) => {
		let newVal = val;
		setDetailEditForm({ ...detailEditForm, ...newVal });
	};
	const getDetailEditOne = async (id: string, pattern: string) => {
		let { retData } = await busBusinesstService.saleOne(id);
		const { item, deliveryTime, enterTime, settleDetails, ...from } = retData;
		const newMaterialDetails = item?.map((item) => {
			return {
				...item,
				detailsId: item.id,
			};
		});
		const newFrom = {
			...from,
			enterTime: enterTime ? moment(enterTime) : null,
			deliveryTime: deliveryTime ? moment(deliveryTime) : null,
		};
		if (retData && pattern === 'edit') {
			setDetailMaterialEditList((newMaterialDetails as ISaleDetailsList[]) ?? []);
			setDetailEditForm(newFrom);
			setEditSettleDetails(settleDetails);
			setTopButsControl(false);
		} else if (retData && pattern === 'look') {
			setDetailLookForm(newFrom);
			setLookSettleDetails(settleDetails);
			setDetailMaterialLookList((newMaterialDetails as ISaleDetailsList[]) ?? []);
		}
	};
	//关闭维修用料弹框
	const resetWorkMaterialloadData = async () => {
		setSearchWorkMaterialData({});
		workMaterialloadData(initPgCfg, {});
	};
	const workMaterialloadData = async (
		page?: ISearchPage,
		searchValue: IStockSearch = searchWorkMaterialData
	) => {
		setLoading(true);
		const searchParams = {
			...searchValue,
			shopId,
			age: 1,
			pageSize: page?.pageSize ?? workMaterialPgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
			pageNum:
				(page?.pageNum || page?.current) ?? workMaterialPgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
		};
		let { retData } = await busBusinesstService.stockPagePresaleorder(searchParams);
		const { records, ...pg } = retData;
		const newRecords:any = records.map((item) => {
			let { salePrice, price } = item;
			let newPrice = !!salePrice ? salePrice : price;
			return {
				...item,
				num: item.availableNum??1,
				salePrice: newPrice,
				amount:bigNumber.toFixed(bigNumber.times(item.availableNum??1,(newPrice as number)),2) 
			};
		});
		setWorkMaterialPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
		setWorkMaterialDataSource(newRecords);
		setLoading(false);
	};
	//筛选维修用料模块详情 详情和新增的列表已有数据的方法
	const modelMaterialScreenMethod = async (
		modelList: ISaleDetailModel[],
		detailList: ISaleDetailsList[]
	) => {
		const repetition: string[] = [];
		const newDetailData: ISaleDetailsList[] = [];
		modelList.forEach((Item) => {
			let isSelect = detailList && detailList.find((item) => item.detailsId === Item.id);
			let {
				id,
				materialCode,
				materialName,
				inventoryNum,
				availableStock,
				num,
				salePrice,
				costPrice,
				lateSalePrice,
				latestPurPrice,
				averagePurchasePrice,
				purchasePrice,
				amount,
				price,
				...params
			} = Item;
			if (isSelect === undefined) {
				newDetailData.push({
					...params,
					discountRate: 100,
					id: '',
					inventoryNum: availableStock ?? inventoryNum,
					materialCode,
					materialName,
					num,
					price: salePrice ?? price,
					amount,
					partsCost: amount,
					stockId: id,
					detailsId: id,
					presaleId: id,
					costPrice: latestPurPrice,
					latestPurPrice,
					warehouseName: Item.storageWarehouseName,
					warehouseCode: Item.storageWarehouseCode,
					//costAmount: +formatNum(Number(latestPurPrice) * num),
					costAmount: bigNumber.toFixed(bigNumber.times(latestPurPrice as number, num), 2),
					stockCode: Item.orderCode,
				});
			} else {
				repetition.push(Item.materialName as string);
			}
		});
		return { newDetailData, repetition };
	};
	//添加到维修用料详情列表
	const transformsToPoMaterialDetailList = async (mode: string) => {
		//添加列表
		if (mode === 'add') {
			const { repetition, newDetailData } = await modelMaterialScreenMethod(
				addDataMaterialSource,
				detailMaterialAddList
			);
			setDetailMaterialAddList([...detailMaterialAddList, ...newDetailData]);
			repetition.length != 0 &&
				globalPrompt('message', {
					text: `物料名称：${repetition.join(',')}，重复的将不在添加。`,
					type: 'warning',
				});
		} else if (mode === 'edit') {
			//详情列表
			const { repetition, newDetailData } = await modelMaterialScreenMethod(
				addDataMaterialSource,
				detailMaterialEditList
			);
			setDetailMaterialEditList([...detailMaterialEditList, ...newDetailData]);
			repetition.length != 0 &&
				globalPrompt('message', {
					text: `物料名称：${repetition.join(',')}，重复的将不在添加。`,
					type: 'warning',
				});
		}
	};
	//删除维修用料详情列表数据 parentInfo是预约转工单的详情页的表标识，目的是有parentInfo时，对应维修项目和维修用料只做前端山删除
	const detailMaterialDetailList = async (mode: string) => {
		if (mode === 'add') {
			const filterDetailList = detailMaterialAddList.filter(
				(el) => !!!detailMaterialAddDataRowKeys.find((ele) => ele === el.detailsId)
			);
			setDetailMaterialAddList(filterDetailList);
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetailMaterialAddDataRowKeys([]);
			setDetailMaterialAddDataRow([]);
		} else if (mode === 'edit') {
			const filterDetailList = detailMaterialEditList.filter(
				(el) => !!!detailMaterialEditDataRowKeys.find((ele) => ele === el.detailsId)
			);
			setDetailMaterialEditList(filterDetailList);
			globalPrompt('message', { text: `删除成功`, type: 'success' });
			setDetailMaterialEditDataRowKeys([]);
			setDetailMaterialEditDataRow([]);
		}
	};
	//表单计算校验 (计算主单信息与配件每一项)
	const formComputeRule = (mode: string): boolean => {
		let goods = mode === 'edit' ? detailMaterialEditList : detailMaterialAddList;
		let { receivableAll } = mode === 'edit' ? editDesc : addDesc;
		let { favorableAmount } = (mode === 'edit' ? detailEditForm : detailAddForm) as {
			favorableAmount: number;
		};

		receivableAll = +formatNum(receivableAll); //应收
		favorableAmount = +formatNum(favorableAmount); //抹零

		let goodsTotal: number = 0;
		goods.forEach((item) => {
			let { amount, num } = item as { amount: number; num: number };
			//debugger
			// goodsTotal += amount
			goodsTotal += (amount - (+favorableAmount * (amount / (receivableAll + favorableAmount))) / num) * num;
			//goodsTotal = bigNumber.add(goodsTotal, bigNumber.toFixed(bigNumber.times(bigNumber.minus(amount, bigNumber.dividedBy(bigNumber.times(+favorableAmount,bigNumber.dividedBy(amount, bigNumber.add(receivableAll, favorableAmount))), num)), num), 2))
		});
		if (+formatNum(goodsTotal) !== receivableAll) {
			message.warning(
				`详情公交销售总金额金额${+formatNum(goodsTotal)}与表单公交销售总金额金额不符`
			);
			return false;
		}
		return true;
	};
	//新增预售单
	const insertDetail = useLockFn(async (params: ISaleDetail) => {
		let newParams = {
			...params,
			...shopAndOrg,
			...sourceAppOrBranch,
			item: detailMaterialAddList,
			createUser: user.username,
		};
		let { retData } = await busBusinesstService.saleInsert(newParams);
		return retData;
	});
	//编辑预售单
	const editDetail = useLockFn(async (params: ISaleDetail) => {
		let newParams = {
			...params,
			...shopAndOrg,
			...sourceAppOrBranch,
			item: detailMaterialEditList.map((item) => ({ ...item, saleCode: detailEditForm?.saleCode })),
			updateUser: user.username,
		};
		let { retData } = await busBusinesstService.saleUpdate(newParams);
		return retData;
	});

	//关闭页签清不同状态缓存
	const resetAddCatch = async (type?: string) => {
		setDetailAddForm(initForm);
		setDetailMaterialAddList([]);
	};
	const resetEditCatch = async (type?: string) => {
		setDetailEditForm({});
		setDetailMaterialEditList([]);
	};
	const downloadTpl = async () => {
		let result = await busBusinesstService.saleDownloadTpl();
		convertRes2Blob(result);
	};
	const [wsConfirmModal, setWsConfirmModal] = useState<boolean>(false);
	const [settleVisible, setSettleVisible] = useState<boolean>(false); //结算弹框
	const warehousing = useLockFn(async () => {
		let { id, saleCode } = detailEditForm as { id: string; saleCode: string };
		let { retData } = await busBusinesstService.saleWarehousing({
			id,
			saleCode,
			storekeeper: user.username,
		});
		return retData;
	});
	const onSettle = async (paramas: ISaleSettleParams) => {
		let { retData } = await busBusinesstService.saleSettle(paramas);
		return retData;
	};
	const [historyVisible, setHistoryVisible] = useState<boolean>(false);
	const [historyRow, setHistoryRow] = useState<ISaleDetailsList>({});
	return {
		initForm,
		materialModel,
		initPgCfg,
		initMaterialSearchParam,
		detailAddForm,
		detailMaterialAddList,
		detailMaterialAddDataRowKeys,
		detailMaterialAddDataRow,
		addDesc,
		detailEditForm,
		detailMaterialEditList,
		detailMaterialEditDataRowKeys,
		detailMaterialEditDataRow,
		editDesc,
		detailLookForm,
		detailMaterialLookList,
		lookDesc,
		workMaterialDataSource,
		searchWorkMaterialData,
		workMaterialPgCfg,
		addDataMaterialSource,
		setMaterialModel,
		setDetailAddForm,
		setDetailMaterialAddList,
		setDetailMaterialAddDataRowKeys,
		setDetailMaterialAddDataRow,
		setAddDesc,
		setDetailEditForm,
		setDetailMaterialEditList,
		setDetailMaterialEditDataRowKeys,
		setDetailMaterialEditDataRow,
		setEditDesc,
		setDetailLookForm,
		setDetailMaterialLookList,
		setLookDesc,
		setWorkMaterialDataSource,
		setSearchWorkMaterialData,
		setWorkMaterialPgCfg,
		setAddDataMaterialSource,
		updateAddFormVal,
		updateEditFormVal,
		getDetailEditOne,
		resetWorkMaterialloadData,
		workMaterialloadData,
		modelMaterialScreenMethod,
		transformsToPoMaterialDetailList,
		detailMaterialDetailList,
		insertDetail,
		editDetail,
		resetAddCatch,
		resetEditCatch,
		downloadTpl,
		wsConfirmModal,
		setWsConfirmModal,
		settleVisible,
		setSettleVisible,
		warehousing,
		onSettle,
		editSettleDetails,
		setEditSettleDetails,
		lookSettleDetails,
		setLookSettleDetails,
		topButsControl,
		setTopButsControl,
		historyVisible,
		setHistoryVisible,
		historyRow,
		setHistoryRow,
		formComputeRule,
		checked,
		setChecked,
		loading,
		setLoading,
	};
});
