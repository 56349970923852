import { createModel } from "hox";
import React, { useState, useEffect } from "react"
import { IOverStockField, IRemindField, IDefiniyionField, IRemindSearch, IDefiniyionSearch, ISCMSettingDetail } from "./settingsType"
import { settingservice } from "./settingsService"
import useGlobalModel from "@/model/globalModel";
export const useSettingsModel = createModel(function () {
    const { shopAndOrg } = useGlobalModel()
    const { shopCode } = shopAndOrg
    //采购积压
    const [purchaseOverStock, setPurchaseOverStock] = useState<IRemindField>({})
    //销售积压
    const [saleOverStock, setsaleOverStock] = useState<IRemindField>({})
    //积压定义
    const [definiyionOverStock, setdefiniyionOverStock] = useState<IDefiniyionField>({})
    //积压等级
    const [rankOverStock, setrankOverStock] = useState<IOverStockField[]>([])
    const [selectedNodes, setSelectedNodes] = useState<ISCMSettingDetail[]>([]);   //树选中的行信息
    //加载采购积压
    const loadPoData = async () => {
        let params = {
            shopCode,
            code: "overstock_purchase"
        }
        let { retData } = await settingservice.remind(params);
        setPurchaseOverStock(retData)
    }
    //加载销售积压
    const loadSoData = async () => {
        let params = {
            shopCode,
            code: "overstock_sale"
        }
        let { retData } = await settingservice.remind(params);
        setsaleOverStock(retData)
    }
    //加载积压定义
    const loadDefiniyionoData = async () => {
        // let params = {
        //     shopCode,
        // }
        // let { retData } = await settingservice.definiyion(params);
        // setsaleOverStock(retData)
    }
    //加载积压等级
    const loadRankData = async () => {
        let { retData } = await settingservice.overstock(shopCode);
        // let { retData } = await settingservice.overstock('SHOP-M-00000001');
        setrankOverStock(retData)
        setdefiniyionOverStock(retData[0])
    }
    //更新销售单
    const updateSo = async ()=>{
        let {shopCode,code,saleInventoryRatio,overstock} = saleOverStock
        let { retData } = await settingservice.remindUpdate({shopCode,code,saleInventoryRatio,overstock});
        return retData

    }
    //更新采购单
    const updatePo = async ()=>{
        let {shopCode,code,saleInventoryRatio,overstock} = purchaseOverStock
        let { retData } = await settingservice.remindUpdate({shopCode,code,saleInventoryRatio,overstock});
        return retData
    }
    //更新积压件定义
    const updateDefiniyion = async ()=>{
        let {shopCode,code,saleInventoryRatio,overstock} = definiyionOverStock
        let { retData } = await settingservice.definiyion({shopCode,code,saleInventoryRatio,overstock});
        return retData
    }
    // 更新积压件定义的所有数值
    const updateDefiniyionList = async ()=>{
        let { retData } = await settingservice.definiyionUpdate(rankOverStock);
        return retData
    }
    return {
        purchaseOverStock, setPurchaseOverStock,
        saleOverStock, setsaleOverStock,
        definiyionOverStock, setdefiniyionOverStock,
        rankOverStock, setrankOverStock,
        selectedNodes, setSelectedNodes,
        loadPoData,
        loadSoData,
        loadDefiniyionoData,
        loadRankData,
        updateSo,
        updatePo,
        updateDefiniyion,
        updateDefiniyionList
    }
})