import { useState, useEffect } from "react";
import { createModel } from "hox";
import { TablePaginationConfig } from "antd/es/table/interface";
import { IPgProps, ISearchPage } from "@/types/ScmTypes";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE, } from "@/utils/constants";
import { inventoryDetailsService, IInventoryDetailsSearchFields, IInventoryDetailsDetail, IInventoryDetailsTotal, IPoShift, IInventoryDetailsGoods, IPoShiftImportDetail } from "./InventoryDetailsService";
import useGlobalModel from "@/model/globalModel";
import { convertRes2Blob } from "@/utils/exportFile";
import { useLockFn } from "ahooks";
import { globalPrompt } from "@/components/message";
import { stockStatisticService, IRecordsFields, IRecordsDetail, IStockStatisticDetail } from "@/views/stock/statistic/stockStatisticService";
import { bigNumber, transPgToPagination } from "@/utils/utils";


export const useStockPoDetailModel = createModel(function () {
    const { shopAndOrg: { shopId }, setLoadingInfo,user } = useGlobalModel();
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }

    //查询条件
    const [searchParam, setSearchParam] = useState<IInventoryDetailsSearchFields>({});
    //列表数据源
    const [dataSource, setDataSource] = useState<IInventoryDetailsDetail[]>([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //列表统计展示数据
    const initStatistics = { stockAmount: 0, stockNum: 0,presaleAmount:0,presaleNum: 0,presaleTotalCost:0 };
    const [statistics, setStatistics] = useState<IInventoryDetailsTotal>(initStatistics);
    const [occupyStock, setOccupyStockModel] = useState<IStockStatisticDetail | null>(null)
    const [historyVisible, setHistoryVisible] = useState<boolean>(false);
    const [historyRow, setHistoryRow] = useState<IInventoryDetailsDetail>();

    const updateSearchParam = (updateParam: IInventoryDetailsSearchFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //切换门店重置缓存
    useEffect(() => {
        if(user.username){
        setSearchParam({});
        setSelectedRowKeys([]);
        resetSearchParam();
    }}, [shopId]);

    //重置
    const resetSearchParam = () => {
        //debugger
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page: ISearchPage = initPgCfg, searchValue: IInventoryDetailsSearchFields = searchParam) => {
        let newSelect={
            occupyNo:searchValue.occupy==1?0:undefined,
            occupy:searchValue.occupy==2?0:undefined
        }
        const searchParams = {
            shopId,
            ...searchValue,
            ...newSelect,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
        }
       // debugger
        let { retData } = await inventoryDetailsService.page(searchParams);
        // let { retData: statistics } = await inventoryDetailsService.amount(searchParams);
        let { retData: statistics } = await inventoryDetailsService.statistics(searchParams);
        const { records, ...pg } = retData;
        setStatistics({...statistics,
            availableNum:bigNumber.minus(Number(statistics?.presaleNum??0), Number(statistics?.occupyNum??1))
        });
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
    }

    //导出
    const exportStockOut = async (searchValue: IInventoryDetailsSearchFields = searchParam) => {
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                shopId,
                ioFlag: "0",  //出库标记
                ...searchValue,
                id: selectedRowKeys.join(),
                pageSize: -1,
                pageNum: 1,
            }
            let result = await inventoryDetailsService.export(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch{
            setLoadingInfo({ loadingVisible: false, txt: "" })
        }
    }
    /**
     *  //转移货位
     */
    const [shiftShelfModal, setShiftShelfModal] = useState<boolean>(false);
    //查询条件
    const [searchParamShelf, setSearchParamShelf] = useState<IInventoryDetailsSearchFields>({});
    //列表数据源
    const [dataSourceShelf, setDataSourceShelf] = useState<IPoShiftImportDetail[]>([]);
    //列表数据源分页参数
    const [pgCfgShelf, setPgCfgShelf] = useState<TablePaginationConfig>(initPgCfg);
    //已选中的
    const [selectedShelfRows, setSelectedShelfRows] = useState<IPoShiftImportDetail[]>([]);
    //移库记录列表
    const [recordsList, setRecordsList] = useState<IRecordsDetail[]>([])
    //移库记录检索
    const [recordsSearch, setRecordsSearch] = useState<IRecordsFields>({})
    //移库记录显示隐藏
    const [recordsVisible, setRecordsVisible] = useState<boolean>(false)

    const loadDataShelf = async (page?: ISearchPage, searchValue: IInventoryDetailsSearchFields = searchParamShelf) => {
        const searchParams = {
            shopId,
            pageSize: page?.pageSize ?? pgCfgShelf.pageSize ?? DEFAULT_PAGE_SIZE,
            pageNum: page?.pageNum ?? pgCfgShelf.current ?? DEFAULT_FIRST_PAGE_NUM,
            materialName: searchValue.materialName?.trim() ?? "",
            materialCode: searchValue.materialCode?.trim() ?? "",
        }
        let { retData } = await inventoryDetailsService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfgShelf(transPgToPagination(pg));
        setDataSourceShelf(records as any);
    }
    const shiftShelfApi = useLockFn(async () => {
        if (!selectedShelfRows.length) {
            globalPrompt("message", { text: "请选择你要转移的货位", type: "warning" });
            return;
        }
        setLoadingInfo({ loadingVisible: true, txt: "正在转移货位" });
        let arr: IPoShift[] = selectedShelfRows.map(item => ({
            id: item.id,
            warehouseCode: item.warehouseCode1 as string,
            warehouseName: item.warehouseName1 as string,
            shelfId: item.shelfId1 as string,
            shelfCode: item.shelfCode1 as string,
            moveNum: item.moveNum,

        }))
        let { retData } = await inventoryDetailsService.shift(arr);
        if (retData) {
            setShiftShelfModal(false);
            setSelectedShelfRows([]);
            loadData();
            setLoadingInfo({ loadingVisible: false, txt: "" });
            globalPrompt("message", { text: "货位转移成功", type: "success" })
        } else {
            globalPrompt("message", { text: "货位转移失败", type: "error" });
        }
    })

    const shelftDownloadTpl = async () => {
        // let result = await stockStatisticService.downloadTpl();
        // convertRes2Blob(result);
    }
    /**
     * 详情
     */
    const [formVal, setFormVal] = useState<object & { code: string }>({ code: "" });
    const [goodsDetail, setGoodsDetail] = useState<IInventoryDetailsGoods[]>([]);

    //获取一条数据
    const getDetailOne = async (id: string) => {
        // let { retData } = await inventoryDetailsService.one(id);
        // let { details, ...formVal } = retData;
        // if (retData) {
        //     setFormVal(formVal);
        //     setGoodsDetail(details);
        // }
    };
    //移库记录查看
    const records = async () => {
        let params = {
            ...recordsSearch,
            "pageNum": 1,
            "pageSize": 10000,
            shopId: shopId
        }
        let { retData } = await stockStatisticService.records(params);
        let { records } = retData
        setRecordsList(records)
    }
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        statistics,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportStockOut,
        formVal, goodsDetail,
        getDetailOne,
        shiftShelfModal, setShiftShelfModal,
        /***** 转移货位*******/
        searchParamShelf, setSearchParamShelf,
        dataSourceShelf,
        selectedShelfRows, setSelectedShelfRows,
        pgCfgShelf, setPgCfgShelf,
        loadDataShelf, shiftShelfApi,
        shelftDownloadTpl,
        recordsList, setRecordsList,
        records,
        recordsVisible, setRecordsVisible,
        recordsSearch, setRecordsSearch,
        occupyStock, setOccupyStockModel,
        historyVisible, setHistoryVisible,
        historyRow, setHistoryRow
    }
});
